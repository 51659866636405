import { useState } from 'react';

import SidebarItems from './SidebarItems';
import { Link } from 'react-router-dom';

export default function Sidebar(props) {

    const { defaultActive } = props;

    const [activeItem, setActiveItem] = useState(defaultActive || 1);

    

    const getItemStyle = (index) => {
        if (index == 0) {
            return { paddingTop: 81, paddingBottom: 50 };
        }
        
        if (index > 3 && index < 5) {
            return { paddingTop: 280 };

        }
        return {};
    };



    const handleClick = (index) => {
        setActiveItem(index);
    };



    return (

        <div style={{ margin: 0 }}>
            <div className='sidebar'>
                {SidebarItems.map((item, index) => {
                    return (
                        <Link to={item.route} key={index}>
                            <div
                                className={activeItem === index ? 'active' : 'inactive'}
                                style={getItemStyle(index)}

                                onClick={() => handleClick(index)}>
                                {index <= 2 &&
                                    <div className='sidebar-icons'>
                                        {item.icon}
                                    </div>
                                }
                                {index >= 3 &&
                                    <div className='sidebar-icons'>
                                        {item.icon}
                                    </div>
                                }
                            </div>
                        </Link>
                    )
                })}
            </div>
        </div>




    )
}


