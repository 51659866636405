export const mapJson = (data) => {
  const result = Object.values(data).map((video) => video);
  return result;
};

export const mergeData = (json1, json2) => {
    const result = [];

  // Crear un mapa de 'id' en el primer JSON para facilitar la comparación
  const json1Map = new Map(json1.map((item) => [item.id, item]));

  // Iterar sobre el segundo JSON y comparar con el primer JSON
  for (const item of json2) {
    const correspondingItem = json1Map.get(item.id);

    if (correspondingItem) {
      // Si se encuentra un objeto con el mismo 'id', puedes compararlos o realizar la acción que necesites
      if (correspondingItem.id !== item.id) {
        result.push(`Objeto con id ${item.id} modificado`);
      }
    } else {
      // Si no se encuentra un objeto con el mismo 'id', puedes realizar otra acción (como agregarlo)
      result.push(`Objeto con id ${item.id} añadido`);
    }
  }

  return result;
};



export const brandsImages = (brandName) => {

    const result = 
        brandName == 'Astana' ? 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fbotw-pd.s3.amazonaws.com%2Fstyles%2Flogo-thumbnail%2Fs3%2F032014%2Ffc_astana_2014_-_.gif%3Fitok%3DS7wRhqtr&f=1&nofb=1&ipt=1948c90af06982a08be298c0977a041b40433ff2d154d2509bc4901443499fc7&ipo=images' :
        null;

    return result;



}
