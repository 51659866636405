import { useContext } from "react";
import { ProjectContext } from "../config/config";

import Edit from "../SVG/Edit";
import SelectLabel from "./SelectLabel";
import Download_Button from "./Download_Button";

import { date, localization, social_network } from "../utils/data";

export default function MainMenu() {
  const { searchs } = useContext(ProjectContext);
  let actualSearch = searchs.currentSearch;

  return (
    <>
      <div className="row mt-4">
        <div className="col-lg-1"></div>
        <div className="col-lg-2">
          <h1>
            <strong>{actualSearch}</strong>
          </h1>
        </div>
        <div className="col-lg-2 align-self-center">
          <button className="edit-button">
            <Edit />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-2">
          <SelectLabel options={localization} defaultValue="Localización" />
        </div>
        <div className="col-lg-2">
          <SelectLabel options={date} defaultValue="Noviembre" />
        </div>
        <div className="col-lg-5"></div>
        <div className="col-lg-2 download-button">
          <Download_Button text="Descargar informe" id="disabled" />
        </div>
      </div>
    </>
  );
}
