export const headerStyles = {
  fontWeight: "700",
  fontSize: "12px",
  fontFamily: "Poppins",
  color: "#202124",
  border: "1px solid #DBDBDB",
  textAlign: "center",
};

export const bodyStyles = {
  fontWeight: "400",
  fontSize: "12px",
  fontFamily: "Open Sans",
  color: "#383838",
  border: "1px solid #DBDBDB",
  textAlign: "center",
};

export const sentimentContainer = {
  
  background: "red",
};

export const userColumnStyles = {
  fontWeight: "700",
  fontSize: "12px",
  fontFamily: "Poppins",
  color: "white",
  border: "1px solid #DBDBDB",
  textAlign: "center",
  background: "linear-gradient(90deg, #FF982F 0%, #FFC12F 100%)"
};


export const hashtagsColumnStyles = {
  fontWeight: "700",
  fontSize: "12px",
  fontFamily: "Poppins",
  color: "white",
  border: "1px solid #DBDBDB",
  textAlign: "center",
  background: "linear-gradient(90deg, #36D0FF 0%, #486CFF 100%)"

}