import React from "react";
import Edit from "../SVG/Edit";

export default function EditButton(props) {
  const { id } = props;
  return (
    <button id={id} className="edit-button-container">
      <i className="fas fa-plus d-inline-block mr-2">
        <Edit fill="#FFFF" />
      </i>
      <span className="d-inline-block">
        <p>Editar usuario</p>
      </span>
    </button>
  );
}
