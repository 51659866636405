import React from 'react'
import ReactPlayer from 'react-player'

export default function VideoPlayer(props) {

  const {url, title, description} = props;
  
  return (
    <div className='video-container'>
      <div className='d-flex justify-content-center player'>
          <ReactPlayer
            url={url}
            playing={false}
            controls={true}
            className="video-wrapper"

            
          />
      </div>
      <div className='row mt-5'>
        <div className='col-lg-1'></div>
        <div className='col-lg-5'>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      </div>
        
    </div>
  )
}
