import React from "react";

export default function Related_Words_Graphic(props) {
    
  const { data } = props;

  const getContainerWidth = (index) => {
    let result = index == 0 ? 'first-word-background' : index == 1 ? 'second-word-background' : index == 2 ? 'third-word-background' : index == 3 ? 'fourth-word-background' :
    index == 4 ? 'fifth-word-background' : index == 5 ? 'six-word-background' : null;
    return result;
  }

  const getContainerRadius = (index) => {
    let result = index == 0 ? 'related-row-container__first' : 'related-row-container__others';
    return result;
  }


  const text = data.map((item, index) => (
    <li key={index}>
      <div className={`related-row-container  ${getContainerWidth(index)} ${getContainerRadius(index)}`} style={{width: item.value}}>
        <p>{item.title}</p>
      </div>
    </li>
  ));

  return (
    <div className="related-container">
      <div className="row related-row">
        <p>Palabras relacionadas</p>
      </div>
      <div className="row related-list-row">
        <ul>{text}</ul>
      </div>
    </div>
  );
}
