import { useContext } from "react";


//Gráficos
import Total_Graphic from "../../Graphics/Total_Graphic";
import Tendency_Graphic from "../../Graphics/Tendency_Graphic/Tendency_Graphic";
import Interest_Graphic from "../../Graphics/Interest_Graphic";
import Sentiment_Analysis_Graphic from "../../Graphics/Sentiment_Analysis_Graphic";
import Related_Words_Graphic from "../../Graphics/Related_Words_Graphic";
import Table from "../../Graphics/Table/Table";

//Navigation functions
import { navigateToPage } from "../../utils/handleNavigationFunctions";
import { useNavigate } from "react-router-dom";

import { words } from "../../utils/data";

//Contexto del proyecto
import { ProjectContext } from "../../config/config";
import Network_Graphic from "../../Graphics/Network_Graphic";
import MainMenu from "../../Components/MainMenu";

export default function TwitterScreen() {
  const { searchs, graphics, dispatch_graphics } = useContext(ProjectContext);
  const navigate = useNavigate();
  let actualSearch = searchs.currentSearch;

  return (
    <>
      <div className="container mt-5">
        <MainMenu />
        <div className="row my-4">
          <div className="col-lg-1"></div>
          <div className="col-3-half">
            <div className="row mb-4">
              <Tendency_Graphic social_network="twitter" />
            </div>
            <div className="row mb-4">
              <Related_Words_Graphic data={words} />
            </div>
            <div className="row mb-4">
              <Total_Graphic text="tweets" total={graphics.total.tweets} />
            </div>
          </div>
          
          <div className="col-7-half">
            <div className="row mb-5">
              <div className="col-6 mb-2">
                <Interest_Graphic />
              </div>
              <div className="col-6 mb-2">
                <Sentiment_Analysis_Graphic />
              </div>
            </div>
            <div className="row ml-1 my-5">
              <Network_Graphic />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-3-half">
            <Total_Graphic text="tweets" total={graphics.total.tweets} />
          </div>
          <div className="col-3-half">
            <Total_Graphic text="tweets" total={graphics.total.tweets} />
          </div>
          <div className="col-3-half">
            <Total_Graphic text="tweets" total={graphics.total.tweets} />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <Table type="users"/>
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <Table type="hashtags"/>
          </div>
        </div>
      </div>
    </>
  );
}
