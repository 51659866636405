export const nodes = [
  { id: "@arelene25", icon: "person", multiplePath: false, group: 1 },
  { id: "node2", icon: "retweet", multiplePath: true, group: 1 },
  { id: "node3", icon: "heart", multiplePath: false, group: 1 },
  { id: "node4", icon: "comment", multiplePath: false, group: 1 },
  { id: "node5", icon: "share", multiplePath: false, group: 1 },

  { id: "@gorrge8", icon: 'person', multiplePath: false, group: 2},
  { id: "node7", icon: 'retweet', multiplePath: true, group: 2 },
  { id: "node8", icon: 'heart', multiplePath: false, group: 2 },
  { id: "node9", icon: 'comment', multiplePath: false, group: 2 },
  { id: "node10", icon: 'share',  multiplePath: false, group: 2 },

  { id: "@gladisu", icon: 'person', multiplePath: false, group: 3},
  { id: "node12", icon: 'retweet', multiplePath: true, group: 3 },
  { id: "node13", icon: 'heart', multiplePath: false, group: 3 },
  { id: "node14", icon: 'comment', multiplePath: false, group: 3 },
  { id: "node15", icon: 'share',  multiplePath: false, group: 3 },
];

export const links =  [
  ... nodes.slice(1, 5).map((node) => ({
    source: "@arelene25",
    target: node.id,
    value: 1,
    gradient: false,
    group: 1
  })),
  {
    source: "@arelene25",
    target: "@gorrge8",
    value: 1,
    gradient: true,
    group: 2
  },
  ... nodes.slice(5, 10).map((node) => ({
    source: "@gorrge8",
    target: node.id,
    value: 1,
    gradient: false,
    group: 2
  })),
  {
    source: "@gorrge8",
    target: "@gladisu",
    value: 1,
    gradient: true,
    group: 2
  },
  ... nodes.slice(10, 15).map((node) => ({
    source: "@gladisu",
    target: node.id,
    value: 1,
    gradient: false,
    group: 3
  })),
]

