import React from "react";

export default function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <g opacity="0.4">
        <path
          d="M5 32.575V37.5C5 50 10 55 22.5 55H37.5C50 55 55 50 55 37.5V22.5C55 10 50 5 37.5 5H22.5C10 5 5 10 5 22.5"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30 38.75C31.1491 38.75 32.2869 38.5237 33.3485 38.0839C34.4101 37.6442 35.3747 36.9997 36.1872 36.1872C36.9997 35.3747 37.6442 34.4101 38.0839 33.3485C38.5237 32.2869 38.75 31.1491 38.75 30C38.75 28.8509 38.5237 27.7131 38.0839 26.6515C37.6442 25.5899 36.9997 24.6253 36.1872 23.8128C35.3747 23.0003 34.4101 22.3558 33.3485 21.9161C32.2869 21.4763 31.1491 21.25 30 21.25C27.6794 21.25 25.4538 22.1719 23.8128 23.8128C22.1719 25.4538 21.25 27.6794 21.25 30C21.25 32.3206 22.1719 34.5462 23.8128 36.1872C25.4538 37.8281 27.6794 38.75 30 38.75Z"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.09 17.5H44.12"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
