//Convertir el JSON a un STRING
export const convertToString = (sentiments) => {
    let result = JSON.stringify(sentiments);
    let parse = JSON.parse(result);
    return parse;
}

//Pasar los datos del array a float
export const passToFloat = (array) => {
    let newArray = [];
    array.filter((actual, i) => {
        let data = Number.parseFloat(actual);
        if (typeof data == 'number') {
            newArray.push(data);
        }
    })
    return newArray;
}

//Filtrar los números del array
export const filterArray = (array) => {
    let result = array.filter((num) => !isNaN(num));
    return result;
}


//Sumar todos los valores 
export const sumValues = (array) => {
    let total = array.reduce((acumulador, actual) => acumulador + actual, 0);
    return total;
}

//Pasar los valores a enteros
export const getIntegerValue = (value) => {
    const result = Math.floor(value);
    return result;
}

//Calculate the percentage 
export const calculatePercentage = (array) => {
    const sumaTotal = array.reduce((acc, curr) => acc + curr, 0);
    const result = array.map(valor => (valor / sumaTotal) * 100);
    return result.map(item => Math.floor(item));
}