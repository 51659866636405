import React from 'react'
import Download from '../SVG/Download'

export default function Download_Button(props) {

    const { text, onClick, id } = props

    return (
        <button onClick={onClick} id={id} className="width-responsive" disabled>
            <i className="fas fa-plus d-inline-block mr-2">
                <Download fill='#FFFF'/>
            </i>
            <span className="d-inline-block">
                <p>{text}</p>
            </span>
        </button>
    )
}
