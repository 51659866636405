import { useContext, useRef, useState, useEffect } from 'react';
import { sentiments } from '../json/world_cup_week_01-08_november_22_sentiment_aws_clean.js';
import * as d3 from "d3";

import { ProjectContext } from '../config/config.js';

import { convertToString, passToFloat, filterArray, sumValues, calculatePercentage, getIntegerValue } from '../utils/sentimentGraphicFunctions.js';

export default function Sentiment_Analysis_Graphic() {

    const { graphics, dispatch_graphics } = useContext(ProjectContext);
    const [data, setData] = useState([]);
    const [currentNumber, setCurrentNumber] = useState(0);


    useEffect(() => {
        let result = dispatchTheSentiments();
        //Pasar el array de objetos al contexto global de la aplicación
        dispatch_graphics({ type: 'addSentiments', payload: result });
    }, [])

    const dispatchTheSentiments = () => {

        let newArray = [];
        let positiveArray = [];
        let neutralArray = [];
        let negativeArray = [];
        let numbers = [];

        //Convertir el JSON a String
        let parse = convertToString(sentiments)

        //Pasar el string a un array vacio 
        Object.entries(parse).forEach(item => {
            newArray.push(item[1])
        })

        //Filtrar los valores en tres arrays:
        newArray.filter(item => { positiveArray.push(item.Positive) });
        newArray.filter(item => { neutralArray.push(item.Neutral) });
        newArray.filter(item => { negativeArray.push(item.Negative) });


        //Pasar los datos del array a formato decimal:
        let positiveValues = passToFloat(positiveArray);
        let neutralValues = passToFloat(neutralArray);
        let negativeValues = passToFloat(negativeArray);


        //Filtrar los arrays, para verificar que todos los elementos del array son números
        const filteredArr_Positive = filterArray(positiveValues);
        const filteredArr_Neutral = filterArray(neutralValues)
        const filteredArr_Negative = filterArray(negativeValues)

        //Sumar todos los valores de los tres arrays 
        const positiveTotal = sumValues(filteredArr_Positive);
        const neutralTotal = sumValues(filteredArr_Neutral);
        const negativeTotal = sumValues(filteredArr_Negative);

        const positiveInteger = getIntegerValue(positiveTotal);
        const neutralInteger = getIntegerValue(neutralTotal);
        const negativeInteger = getIntegerValue(negativeTotal);

        numbers.push(positiveInteger, neutralInteger, negativeInteger);

        //Calcular el porcentaje de la suma de los tres sentimientos:
        let percentage = calculatePercentage(numbers);

        const object = [
            { label: 'Positivo', value: percentage[0], color: "#36D0FF" },
            { label: 'Neutro', value: percentage[1], color: "#FFC12F" },
            { label: 'Negativo', value: percentage[2], color: "#486CFF" }
        ];
        setData(object);
        return object;
    }

   

    useEffect(() => {
        drawChart();
    }, [data])



    function drawChart() {

        const outerRadius = 90;
        const innerRadius = 50;
        let number = 0;

        const margin = { top: 50, right: 50, bottom: 50, left: 50 };
        const width = 2 * outerRadius + margin.left + margin.right;
        const height = 2 * outerRadius + margin.top + margin.bottom;

        //Definimos la escala de colores que va a tener el gráfico
        const colorScale = d3.scaleOrdinal()
            .range(data.map(item => item.color));


        //Cuando los datos se actualicen, borramos el gráfico
        d3.select('#pie-container').select('svg').remove();

        //Creamos un nuevo svg
        const svg = d3
            .select('#pie-container')
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        //Cambiamos los ángulos de los gráficos
        const pieGenerator = d3.pie().startAngle(-0.5 * Math.PI).value((d) => d.value);

        const arc = svg
            .selectAll()
            .data(pieGenerator(data))
            .enter();

        const maxValue = data.reduce((cur, val) => Math.max(cur, val.value), 0);

        //Define the generators
        const arcGenerator = d3.arc().cornerRadius(5).innerRadius(innerRadius)
            .outerRadius((d) => { return 130 - (maxValue - d.value) });

        //Append sectors
        arc.append('path').attr('d', arcGenerator).style('fill', (_, i) => colorScale(i))

        
        //Mostrar un valor preterminado cuando se carga por primera vez el gráfico
        const text = svg.append("svg:text"); 
            number = maxValue
            text.text(`${number} %`)
            text.attr("text-anchor", "middle")
            text.attr("style", "font-family:Poppins")
            text.attr("font-size", "25")
            text.attr("fill", "black")

         
        //Cambiar el valor del porcentaje si el usuario hace click
        svg.on("click", function(d) {
            number = d.target.__data__.value; // Obtenga el valor del arco
            text.text(`${number} %`) // Establecer el valor como texto en el elemento text
            text.attr("text-anchor", "middle")
            text.attr("style", "font-family:Poppins")
            text.attr("font-size", "25")
            text.attr("fill", "black")
        });

        //Cambiar el valor del porcentaje si el usuario hace hover
        svg.on("mouseover", function(d) {
            number = d.target.__data__.value; // Obtenga el valor del arco
            text.text(`${number} %`) // Establecer el valor como texto en el elemento text
            text.attr("text-anchor", "middle")
            text.attr("style", "font-family:Poppins")
            text.attr("font-size", "25")
            text.attr("fill", "black")
        });    
        
    }




    return (
        <div className='sentiment-container'>
            <div className='row sentiment-text-row'>
                <p>Análisis de sentimentos</p>
            </div>
            <div className='sentiment-details-row'>
                <div className='details-item'>
                    <div className='details-rectangle positive-rectangle' />
                    <p>Positivo</p>
                </div>
                <div className='details-item'>
                    <div className='details-rectangle neutral-rectangle' />
                    <p>Neutral</p>
                </div>
                <div className='details-item'>
                    <div className='details-rectangle negative-rectangle' />
                    <p>Negativo</p>
                </div>
            </div>
            <div className='row sentiment-graphic-row'>
                <div id='pie-container' />
            </div>
        </div>
    )
}
