import React from "react";

import Sidebar from "../Components/Sidebar/Sidebar";
import EditButton from "../Components/EditButton";
import Label from "../Components/Label";

import es from '../translations/es.json';

export default function ConfigurationOfUserScreen(props) {

  const {lang} = props;
  const text = lang === "es" ? es.pages["user-configuration"] : null;

  return (
    <div className="background-image">
      <Sidebar defaultActive={1} />
      <div className="container content-container">
        <div className="row my-lg-5  my-md-5 my-sm-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <h1>{text.title}</h1>
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <h4 className="graphic-text">{text.preferencies} "usuario"</h4>
          </div>
          <div className="col-lg-8 d-flex justify-content-end">
            <EditButton id="primary" />
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Nombre" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Apellidos" className="input-container__secondary" />
            </div>
          </div>
          <div className="row my-lg-4">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Nombre del usuario" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Correo electrónico" className="input-container__secondary"/>
            </div>
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <h4 className="graphic-text">{text["change-password"]}</h4>
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Contraseña actual" className="input-container__secondary"/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Nueva contraseña" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Confirmar nueva contraseña" className="input-container__secondary"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
