import React from "react";

export default function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <g opacity="0.4">
        <path
          d="M5.33337 23.9998C5.33337 15.9998 10.6667 10.6665 18.6667 10.6665H45.3334C53.3334 10.6665 58.6667 15.9998 58.6667 23.9998V39.9998C58.6667 47.9998 53.3334 53.3332 45.3334 53.3332H18.6667C10.6667 53.3332 5.33337 47.9998 5.33337 39.9998V36.2398"
          stroke="white"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.4533 25.3068L37.04 29.2534C39.5466 30.7734 39.5466 33.2268 37.04 34.7468L30.4533 38.6934C27.7866 40.2934 25.6 39.0668 25.6 35.9468V28.0268C25.6 24.9334 27.7866 23.7068 30.4533 25.3068Z"
          stroke="white"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
