import { useMemo, useRef, useState, useEffect } from "react";
import { MaterialReactTable } from "material-react-table";
import { usersData, hashtagsData, commentsData } from "../../json/table_data";
import {
  bodyStyles,
  hashtagsColumnStyles,
  headerStyles,
  sentimentContainer,
  userColumnStyles,
} from "./styles";

import { Box } from "@mui/material";

export default function Table(props) {

  const {type} = props;


  function getColorForSentiment(sentiment) {
    if (sentiment === "positive") {
      return "#36D0FF";
    } else if (sentiment === "neutral") {
      return "#FFC12F";
    } else if (sentiment === "negative") {
      return "#486CFF";
    }
  }
  

  const usersColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "#",
        size: 40,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "user",
        header: "Usuario",
        size: 260,
        muiTableBodyCellProps: {
          sx: userColumnStyles,
        },
      },
      {
        accessorKey: "user_name",
        header: "Nombre",
        size: 150,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_tweets",
        header: "Tweets",
        size: 150,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_followers",
        header: "Followers",
        size: 150,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_following",
        header: "Following",
        size: 150,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
    ],
    []
  );

  const hashtagsColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "#",
        size: 50,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "hasthag",
        header: "Nombre del hashtag",
        size: 50,
        muiTableBodyCellProps: {
          sx: hashtagsColumnStyles,
        },
      },
      {
        accessorKey: "text",
        header: "Texto del tweet original",
        size: 400,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_tweets",
        header: "Total de tweets",
        size: 150,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_retweets",
        header: "Total de retweets",
        size: 100,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_users",
        header: "Total de usuarios",
        size: 100,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
    ],
    []
  );

  const commentsColumns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "#",
        size: 50,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "text",
        header: "Textos del comentario",
        size: 400,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
      {
        accessorKey: "total_likes",
        header: "Total de likes",
        size: 50,
        muiTableBodyCellProps: {
          sx: bodyStyles,
        },
      },
    
      {
        accessorFn: (row) => row.sentiment_analysis,
        id: "sentiment_analysis",
        header: "Análisis del sentimiento",
        size: 250,

        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {Object.keys(renderedCellValue).map((sentiment, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: getColorForSentiment(sentiment),
                        width: `${renderedCellValue[sentiment]}%`,
                        height: "11px",
                        borderTopLeftRadius: index == 0 ? '10px' : 0,
                        borderBottomLeftRadius: index == 0 ? '10px' : 0,
                        borderTopRightRadius: index == 2 ? '10px' : 0,
                        borderBottomRightRadius: index == 2 ? '10px' : 0,
                      }}
                    >
                    </div>
                  ))}
          </Box>
        ),
      },
    ],
    []
  );

  const renderTable = (columns, data) => {
    return (
      <>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          enableSorting={false}
          enableColumnActions={false}
          muiTablePaperProps={{
            sx: {
              boxShadow: "none",
            },
          }}
          muiTableProps={{
            sx: {
              border: "1px solid #DBDBDB",
              borderRadius: "4px",
              alignItems: "center",
              borderRight: "1px solid #DBDBDB",
              textAlign: "center",
            },
          }}
          muiTableHeadCellProps={{
            sx: headerStyles,
          }}
        />
      </>
    );
  };

  return (
    <div className="table-main-container">
      <div className="row top-row">
        {type === "users" ? (
          <p className="graphic-text">
            ¿Cuales son las personas de las que más se está hablando?
          </p>
        ) : type === "comments" ? (
          <p className="graphic-text">Comentarios del video</p>
        ) : (
          <p className="graphic-text"># más usados</p>
        )}
      </div>
      <div className="row table-row">
        {type === "users"
          ? renderTable(usersColumns, usersData)
          : type === "hashtags"
          ? renderTable(hashtagsColumns, hashtagsData)
          : type === "comments"
          ? renderTable(commentsColumns, commentsData)
          : null
          }
      </div>
    </div>
  );
}
