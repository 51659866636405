import { useContext } from 'react'
import { ProjectContext } from '../config/config';

//SVG
import Chart from '../SVG/Chart';


export default function Total_Graphic(props) {

  const {text, total} = props;

  return (
    <div className='container total-container mx-1'>
      <div className='row total-text-row'>
        <p>Total de {text}</p>
      </div>
      <div className='row number-total-row'>
          <p>{total}</p>
        <div className='decorative-chart'>
          <Chart />
        </div>
      </div>
    </div>
  )
}
