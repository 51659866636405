import {useState, useEffect} from "react";

import Sidebar from "../../Components/Sidebar/Sidebar";
import MainMenu from "../../Components/MainMenu";
import VideoPlayer from "../../Components/VideoPlayer";

import Total_Graphic from "../../Graphics/Total_Graphic";
import Sentiment_Analysis_Graphic from "../../Graphics/Sentiment_Analysis_Graphic";
import Tags_Graphic from "../../Graphics/Tags_Graphic";
import Brands_Graphic from "../../Graphics/Brands_Graphic";
import Table from "../../Graphics/Table/Table";

import { useLocation } from "react-router-dom";

import { mapJson, mergeData } from "../../utils/youtubeGraphicFunctions";

import videos from '../../json/videos.json';
import comments from '../../json/comments.json';
import brands from '../../json/brands.json';




export default function YoutubeScreen() {

  const location = useLocation();
  let currentPathName = location.pathname;

  const videosData = mapJson(videos);
  const commentsData = mapJson(comments);
  const brandsData = mapJson(brands);

  // const result = mergeData(videosData, commentsData);

  const [data, currentData] = useState();
  const [commentData, currentCommentData] = useState();
  const [brandData, currentBrandData] = useState();

 

  const videoDataMap = {
    '/video/1': videosData[0],
    '/video/2': videosData[1],
    '/video/3': videosData[2],
    '/video/4': videosData[3],
    '/video/5': videosData[4],
    '/video/6': videosData[5],
    '/video/7': videosData[6],
    '/video/8': videosData[7],
  };
  
  const commentDataMap = {
    '/video/1': commentsData[0],
    '/video/2': commentsData[1],
    '/video/3': commentsData[2],
    '/video/4': commentsData[3],
    '/video/5': commentsData[4],
    '/video/6': commentsData[5],
    '/video/7': commentsData[6],
    '/video/8': commentsData[7],
  };

  const brandDataMap = {
    '/video/1': brandsData[0],
    '/video/2': brandsData[1],
    '/video/3': brandsData[2],
    '/video/4': brandsData[3],
    '/video/5': brandsData[4],
    '/video/6': brandsData[5],
    '/video/7': brandsData[6],
    '/video/8': brandsData[7],
  };


  const renderData = (currentPathName) => {

    const selectedData = videoDataMap[currentPathName];
    if (selectedData) {
      currentData(selectedData);
    }

    const commentSelectedData = commentDataMap[currentPathName];
    if (commentSelectedData) {
      currentCommentData(commentSelectedData);
    }

    const brandSelectedData = brandDataMap[currentPathName];
    if (brandSelectedData) {
      currentBrandData(brandSelectedData);
    }
  }

  
  useEffect(() => {
    renderData(currentPathName);
  }, []);

  console.log(brandData)


  
  return (
    <>
      <Sidebar/>
      <div className="container mt-5">
        {/* <MainMenu /> */}
        <div className="row my-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-11 col-md-12 col-sm-12">
            <VideoPlayer url={data?.link} title={data?.title} description={data?.description}/>
          </div>
        </div>
        <div className="row my-5">
          <div className="col-lg-1"></div>
          <div className="col-3-half col-md-12 col-sm-12">
            <Total_Graphic text="me gustas" total={data?.FavoriteCount}/>
          </div>
          <div className="col-3-half col-md-12 col-sm-12">
            <Total_Graphic text="comentarios" total={data?.commentCount}/>
          </div>
          <div className="col-3-half col-md-12 col-sm-12">
            <Total_Graphic text="visualizaciones" total={data?.viewCount} />
          </div>
        </div>
        <div className="row my-5">
            <div className="col-lg-1"></div>
            <div className="col-3-half col-md-12 col-sm-12">
                <Sentiment_Analysis_Graphic/>
            </div>
            <div className="col">
                <Tags_Graphic tag={data?.tags}/>
            </div>

        </div>

        <div className="row my-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-11 col-md-12 col-sm-12">
                {/* <Brands_Graphic brandData={brandData}/> */}
            </div>
        </div>

        <div className="row my-5">
            <div className="col-lg-1"></div>
            <div className="col-lg-11 col-md-12 col-sm-12">
                <Table type="comments" commentsData={commentData}/>
            </div>
        </div>
      </div>
    </>
  );
}
