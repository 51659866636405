import {
  COMMENT,
  HEART,
  PERSON,
  RETWEET,
  SECOND_PATH,
  SHARE,
} from "../constants";

import * as d3 from "d3";

export function getIconPath(iconName) {
  switch (iconName) {
    case "person":
      return PERSON;
    case "retweet":
      return `${RETWEET} ${SECOND_PATH}`;
    case "heart":
      return HEART;
    case "comment":
      return COMMENT;
    case "share":
      return SHARE;
  }
}

export const targetNodes = ["@arelene25", "@gorrge8", "@gladisu"];


