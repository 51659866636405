import React from "react";

export default function CheckboxList() {

    const data = ['Instagram', 'Youtube', 'Twitter', 'Twitch', 'Blog'];

    const content = data.map((item, index) => (
        <li key={index}>
          <input type="checkbox"/> {item}
        </li>
      ));

    
  return (
    <div class="multiselect">
      <ul>{content}</ul>
    </div>
  );
}
