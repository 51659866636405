import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routesES from '../translations/es.json';
import routesEU from '../translations/eu.json';

//Screens
import SignUpScreen from '../Screens/SignUpScreen';
import HomeScreen from '../Screens/HomeSceen';
import SignInScreen from '../Screens/SignInScreen';
import Dashboard from '../Screens/Dashboard';
import TwitterScreen from '../Screens/Twitter/TwitterScreen';
import InstagramScreen from '../Screens/Instagram/InstagramScreen';
import YoutubeScreen from "../Screens/Youtube/YoutubeScreen";
import ProjectScreen from "../Screens/ProjectScreen";
import ConfigurationOfUserScreen from "../Screens/ConfigurationOfUserScreen";
import ConfigurationOfAppScreen from "../Screens/ConfigurationOfAppScreen";
import CreationOfUserScreen from "../Screens/CreationOfUserScreen";

export const AllRoutes = ({lang}) => {

    const routes = lang === "es" ? routesES.routes : routesEU.routes;

    return (
        <BrowserRouter>
            <Routes>
                <Route path={`/${routes["sign-in"]}` } element={<SignInScreen lang={lang}/>} />
                <Route path={`/${routes["sign-up"]}`} element={<SignUpScreen lang={lang}/>} />
                <Route path={`/${routes["home"]}`} element={<HomeScreen lang={lang}/>} />
                <Route path={`/${routes["project"]}`} element={<ProjectScreen lang={lang}/>} />
                <Route path={`/${routes["general-tendencies"]}`} element={<Dashboard lang={lang}/>} />
                <Route path={`/${routes["twitter-tendencies"]}`} element={<TwitterScreen lang={lang}/>} />
                <Route path={`/${routes["instagram-tendencies"]}`} element={<InstagramScreen lang={lang}/>} />

                <Route path={`/video/${routes["youtube-1"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-2"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-3"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-4"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-5"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-6"]}`} element={<YoutubeScreen lang={lang}/>} />
                <Route path={`/video/${routes["youtube-8"]}`} element={<YoutubeScreen lang={lang}/>} />


                <Route path={`/${routes["user-configuration"]}`} element={<ConfigurationOfUserScreen lang={lang}/>} />
                <Route path={`/${routes["app-configuration"]}`} element={<ConfigurationOfAppScreen lang={lang}/>} />
                <Route path={`/${routes["create-new-user"]}`} element={<CreationOfUserScreen lang={lang}/>} />
            </Routes>
        </BrowserRouter>

    );
}

