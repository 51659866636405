//Datos de los gráficos
export const initialState_Graphics = {
    tendency: [],
    interest: [],
    sentiments: [],
    total: {tweets: 0, retweets: 0, impressions: 0, likes: 0},
    related_words: [],
    tweets_retweets: [],
    tweets_likes: []
};

export const graphicsReducer = (state, action) => {
    switch (action.type) {
        case 'addTendency':
            return { ...state, tendency: [...state.tendency, action.payload] };
        case 'addInterest':
            return { ...state, interest: [...state.interest, action.payload] };
        case 'addSentiments':
            return { ...state, sentiments: [...state.sentiments, action.payload] };
        case 'update_total_tweets':
            return {...state, total: { ...state.total, tweets: action.payload}};
        case 'update_total_retweets':
             return {...state, total: { ...state.total, retweets: action.payload}};
        case 'update_total_impressions':
            return {...state, total: { ...state.total, impressions: action.payload}};
        case 'update_total_likes':
            return {...state, total: { ...state.total, likes: action.payload}};
        case 'addRelatedWords':
            return { ...state, related_words: [...state.related_words, action.payload] };
        case 'add_retweets':
            return { ...state, tweets_retweets : action.payload};
        case 'add_likes':
            return { ...state, tweets_likes: action.payload};
        default:
            throw new Error();
    };
}