import React from 'react'

export default function Logout () {
    return (
        <>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.25 20.3125C16.25 20.7612 15.8862 21.125 15.4375 21.125L2.4375 21.125C1.98877 21.125 1.625 20.7612 1.625 20.3125L1.625 5.6875C1.625 5.23877 1.98877 4.875 2.4375 4.875L15.4375 4.875C15.8862 4.875 16.25 5.23877 16.25 5.6875L16.25 8.9375C16.25 9.38623 16.6138 9.75 17.0625 9.75C17.5112 9.75 17.875 9.38623 17.875 8.9375L17.875 5.6875C17.875 4.3413 16.7837 3.25 15.4375 3.25L2.4375 3.25C1.09131 3.25 8.0467e-07 4.3413 7.45826e-07 5.6875L1.06546e-07 20.3125C4.77025e-08 21.6587 1.0913 22.75 2.4375 22.75L15.4375 22.75C16.7837 22.75 17.875 21.6587 17.875 20.3125L17.875 17.0625C17.875 16.6138 17.5112 16.25 17.0625 16.25C16.6138 16.25 16.25 16.6138 16.25 17.0625L16.25 20.3125Z" fill="black" />
                <path fillRule="evenodd" clipRule="evenodd" d="M25.762 13.5745C26.0793 13.2572 26.0793 12.7428 25.762 12.4255L20.887 7.55048C20.5697 7.23317 20.0553 7.23317 19.738 7.55048C19.4207 7.86778 19.4207 8.38222 19.738 8.69952L23.226 12.1875L8.9375 12.1875C8.48877 12.1875 8.125 12.5513 8.125 13C8.125 13.4487 8.48877 13.8125 8.9375 13.8125L23.226 13.8125L19.738 17.3005C19.4207 17.6178 19.4207 18.1322 19.738 18.4495C20.0553 18.7668 20.5697 18.7668 20.887 18.4495L25.762 13.5745Z" fill="#36D0FF" />
            </svg>

        </>
    )
}
