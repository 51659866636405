import React from "react";
import Sidebar from "../Components/Sidebar/Sidebar";
import EditButton from "../Components/EditButton";
import CheckboxList from "../Components/CheckboxList";

import User from "../SVG/User";
import NewUser from "../SVG/NewUser";

import es from '../translations/es.json';


export default function ConfigurationOfAppScreen(props) {

  const {lang} = props;
  const text = lang === "es" ? es.pages["app-configuration"] : null;

  return (
    <div className="background-image">
      <Sidebar defaultActive={1} />
      <div className="container content-container">
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <h1>{text.title}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-11 d-flex justify-content-end">
            <EditButton id="primary" />
          </div>
        </div>
        <div className="row" style={{ marginTop: "-0.5rem" }}>
          <div className="col-lg-1"></div>
          <div className="col-lg-4">
            <h4 className="graphic-text">{text["social-networks"]}</h4>
          </div>
          <div className="col-lg-4">
            <h4 className="graphic-text">{text["facts-that-i-like"]}</h4>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <CheckboxList />
          </div>
        </div>
        <div className="row" style={{ marginTop: "8.49rem" }}>
          <div className="col-lg-1"></div>
          <div className="col-lg-1 mb-3">
            <h4 className="graphic-text">{text.users}</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-1" style={{display: 'grid'}}>
            <div>
              <User />
            </div>
            <div>
              <p className="user-text">{text["name-of-user"]}</p>
            </div>
          </div>
          <div className="col-lg-1" style={{display: 'grid'}}>
            <div>
              <NewUser />
            </div>
            <div>
              <p className="user-text">{text["new-user"]}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
