import React from 'react'

export default function Chart() {
    return (
        <>
            <svg width="127" height="53" viewBox="0 0 127 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M32.2576 52.2853C28.1405 52.2853 24.803 48.9478 24.803 44.8308V14.4258C24.803 10.3087 28.1405 6.97119 32.2576 6.97119C36.3746 6.97119 39.7121 10.3087 39.7121 14.4258V44.8308C39.7121 48.9478 36.3746 52.2853 32.2576 52.2853Z" fill="#36D0FF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M8.28393 52.2855C4.16689 52.2855 0.829368 48.948 0.829368 44.831V31.8545C0.829368 27.7374 4.16689 24.3999 8.28393 24.3999C12.401 24.3999 15.7385 27.7374 15.7385 31.8545V44.831C15.7385 48.948 12.401 52.2855 8.28393 52.2855Z" fill="#FF982F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M75.4868 52.2856C71.3698 52.2856 68.0322 48.948 68.0322 44.831V7.45458C68.0322 3.33753 71.3698 0 75.4868 0C79.6039 0 82.9414 3.33752 82.9414 7.45457V44.831C82.9414 48.948 79.6039 52.2856 75.4868 52.2856Z" fill="#36D0FF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M51.5132 52.2853C47.3961 52.2853 44.0586 48.9478 44.0586 44.8308V14.4258C44.0586 10.3087 47.3961 6.97119 51.5132 6.97119C55.6302 6.97119 58.9678 10.3087 58.9678 14.4258V44.8308C58.9678 48.9478 55.6302 52.2853 51.5132 52.2853Z" fill="#FF982F" />
                <path fillRule="evenodd" clipRule="evenodd" d="M118.716 52.2855C114.599 52.2855 111.262 48.948 111.262 44.8309V17.9116C111.262 13.7945 114.599 10.457 118.716 10.457C122.833 10.457 126.171 13.7946 126.171 17.9116V44.8309C126.171 48.948 122.833 52.2855 118.716 52.2855Z" fill="#36D0FF" />
                <path fillRule="evenodd" clipRule="evenodd" d="M94.7427 52.2854C90.6256 52.2854 87.2881 48.9479 87.2881 44.8308V28.3686C87.2881 24.2516 90.6256 20.9141 94.7427 20.9141C98.8597 20.9141 102.197 24.2516 102.197 28.3686V44.8308C102.197 48.9479 98.8597 52.2854 94.7427 52.2854Z" fill="#FF982F" />
            </svg>
        </>
    )
}
