export const usersData = [
  {
    id: 1, 
    user: "@gladisu",
    user_name: "Gladis Obams",
    total_tweets: 16.250,
    total_followers: 132.954,
    total_following: 561.593
  },
  {
    id: 2, 
    user: "@arelene25",
    user_name: "Arelen Erlo",
    total_tweets: 44.158,
    total_followers: 113.506,
    total_following: 278.541
  },
  {
    id: 3, 
    user: "@gorrge8",
    user_name: "Jorge Ruiz",
    total_tweets: 38.320,
    total_followers: 108.006,
    total_following: 987
  },
];


export const hashtagsData = [
  {
    id: 1, 
    hasthag: "#MinifootballBsc",
    text: "@TheMoonCarl MINIFOOTBALL IS A  U.K.REGISTERED COMPANY...",
    total_tweets: 16.250,
    total_retweets: 132.954,
    total_users: 561.593
  },
  {
    id: 2, 
    hasthag: "#MinifootballBsc",
    text: "@TheMoonCarl MINIFOOTBALL IS A  U.K.REGISTERED COMPANY...",
    total_tweets: 16.250,
    total_retweets: 132.954,
    total_users: 561.593
  },
  {
    id: 3, 
    hasthag: "#MinifootballBsc",
    text: "@TheMoonCarl MINIFOOTBALL IS A  U.K.REGISTERED COMPANY...",
    total_tweets: 16.250,
    total_retweets: 132.954,
    total_users: 561.593
  },

]

export const commentsData = [
  {
    id: 1, 
    user_name: "#MinifootballBsc",
    text: "DIN WONG",
    total_likes: 2,
    sentiment_analysis: {
      positive: 50, 
      neutral: 10,
      negative: 40,
    },
  
  },

]
