export default function NewUser() {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="81"
        height="81"
        viewBox="0 0 81 81"
        fill="none"
      >
        <path
          d="M25 40.3333H55.6667M40.3333 55.6667V25M40.3333 78.6667C61.4167 78.6667 78.6667 61.4167 78.6667 40.3333C78.6667 19.25 61.4167 2 40.3333 2C19.25 2 2 19.25 2 40.3333C2 61.4167 19.25 78.6667 40.3333 78.6667Z"
          stroke="url(#paint0_linear_851_634)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_851_634"
            x1="40.3333"
            y1="2"
            x2="40.3333"
            y2="78.6667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#36D0FF" />
            <stop offset="1" stop-color="#486CFF" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
