export const initialState_User = {

        id: 0,
        user_name: '',
        user_last_name: '',
        user_rol: '',
        user_pricing_plan: '',
        user_username: '',
        user_email: '',
        user_password: '',
        validation_password: '',
        user_profile_img_url: ''
    
    

};

export const userReducer = (state, action) => {
    switch (action.type) {
      case 'SET_USER_ID':
        return { ...state, id: action.payload};
      case 'SET_NAME':
        return { ...state, user_name: action.payload };
      case 'SET_USER_EMAIL':
        return { ...state, user_email: action.payload};
      case 'SET_USER_PASSWORD':
        return { ...state, user_password: action.payload };
      case 'SET_VALIDATION_PASSWORD':
          return { ...state, validation_password: action.payload };
      default:
        return state;
    }
}
