export const sentiments = [
 {
   "tweet_id": "1590104343294148609",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590104320435191808",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590104294199808000",
   "Positive": "0.018010087311267853",
   "Negative": "0.17029863595962524",
   "Neutral": "0.7069876194000244",
   "Mixed": "0.10470366477966309"
 },
 {
   "tweet_id": "1590104280115339264",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590104257088622593",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590104245558124544",
   "Positive": "0.017291689291596413",
   "Negative": "0.1398378312587738",
   "Neutral": "0.8423757553100586",
   "Mixed": "0.0004946183180436492"
 },
 {
   "tweet_id": "1590104241817145344",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590104205309906944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590104194362798080",
   "Positive": "0.8545479774475098",
   "Negative": "0.0006211086292751133",
   "Neutral": "0.1446971893310547",
   "Mixed": "0.00013372433022595942"
 },
 {
   "tweet_id": "1590104178256666624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590104167007539201",
   "Positive": "0.0036547507625073195",
   "Negative": "0.008484253659844398",
   "Neutral": "0.9878552556037903",
   "Mixed": "5.789403530798154e-06"
 },
 {
   "tweet_id": "1590104156748275712",
   "Positive": "0.7288162708282471",
   "Negative": "0.05882429704070091",
   "Neutral": "0.21095429360866547",
   "Mixed": "0.0014051267644390464"
 },
 {
   "tweet_id": "1590104137571917824",
   "Positive": "0.0012033297680318356",
   "Negative": "0.00017578051483724266",
   "Neutral": "0.9986163377761841",
   "Mixed": "4.567075393424602e-06"
 },
 {
   "tweet_id": "1590104137378975744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590104113257160704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590104062481293312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590104055027994624",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590104051936792577",
   "Positive": "0.024970712140202522",
   "Negative": "0.000868681469000876",
   "Neutral": "0.9741551876068115",
   "Mixed": "5.425100425782148e-06"
 },
 {
   "tweet_id": "1590104048853999617",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590104010492874752",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590103960794566657",
   "Positive": "0.0031730306800454855",
   "Negative": "0.026469992473721504",
   "Neutral": "0.970343828201294",
   "Mixed": "1.3240548469184432e-05"
 },
 {
   "tweet_id": "1590103958860988416",
   "Positive": "0.0184264425188303",
   "Negative": "0.0015296261990442872",
   "Neutral": "0.980032205581665",
   "Mixed": "1.1827819434984121e-05"
 },
 {
   "tweet_id": "1590103958215094272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590103958198321152",
   "Positive": "0.04894905164837837",
   "Negative": "0.0002484548604115844",
   "Neutral": "0.9507482647895813",
   "Mixed": "5.4222495236899704e-05"
 },
 {
   "tweet_id": "1590103954767351808",
   "Positive": "0.017291689291596413",
   "Negative": "0.1398378312587738",
   "Neutral": "0.8423757553100586",
   "Mixed": "0.0004946183180436492"
 },
 {
   "tweet_id": "1590103945695096833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103941991526400",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590103921271635968",
   "Positive": "0.649944543838501",
   "Negative": "0.0003894829424098134",
   "Neutral": "0.34953898191452026",
   "Mixed": "0.0001270040520466864"
 },
 {
   "tweet_id": "1590103915357384705",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103912623005698",
   "Positive": "0.8823704719543457",
   "Negative": "0.00034683721605688334",
   "Neutral": "0.11529047042131424",
   "Mixed": "0.001992188859730959"
 },
 {
   "tweet_id": "1590103908806193152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103873016188928",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103859145211905",
   "Positive": "0.07615295052528381",
   "Negative": "0.0011709100799635053",
   "Neutral": "0.9213567972183228",
   "Mixed": "0.0013193791965022683"
 },
 {
   "tweet_id": "1590103841676357632",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1590103841672134656",
   "Positive": "0.07689923793077469",
   "Negative": "0.001389425015076995",
   "Neutral": "0.9216780066490173",
   "Mixed": "3.3423963031964377e-05"
 },
 {
   "tweet_id": "1590103833015111680",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590103814786322432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590103793223749633",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590103779302834177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103769491963904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590103720565805056",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590103685799215105",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590103670385152000",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590103660641804288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103654014472194",
   "Positive": "0.032417867332696915",
   "Negative": "0.9420297145843506",
   "Neutral": "0.02542060986161232",
   "Mixed": "0.00013183060218580067"
 },
 {
   "tweet_id": "1590103632795820032",
   "Positive": "0.0036349354777485132",
   "Negative": "0.07955421507358551",
   "Neutral": "0.9166507124900818",
   "Mixed": "0.00016016930749174207"
 },
 {
   "tweet_id": "1590103629297745920",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590103623882911744",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590103611195129857",
   "Positive": "0.9556389451026917",
   "Negative": "0.0005396191263571382",
   "Neutral": "0.043764472007751465",
   "Mixed": "5.6940400099847466e-05"
 },
 {
   "tweet_id": "1590103598134071297",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590103568207339520",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590103564009234433",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590103540470468608",
   "Positive": "0.0012033297680318356",
   "Negative": "0.00017578051483724266",
   "Neutral": "0.9986163377761841",
   "Mixed": "4.567075393424602e-06"
 },
 {
   "tweet_id": "1590103512280576000",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590103494874509312",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103485802221569",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590103485521199104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103458719621122",
   "Positive": "0.005938631482422352",
   "Negative": "0.13089227676391602",
   "Neutral": "0.8630298376083374",
   "Mixed": "0.0001392145495628938"
 },
 {
   "tweet_id": "1590103444849065984",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103443594563584",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1590103431196573696",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590103419435778048",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590103407401934848",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103399827062785",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590103381884166144",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590103342193479680",
   "Positive": "0.9911181330680847",
   "Negative": "0.00022225378779694438",
   "Neutral": "0.00864334125071764",
   "Mixed": "1.6249730833806098e-05"
 },
 {
   "tweet_id": "1590103309515268096",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590103298870497280",
   "Positive": "0.02324552647769451",
   "Negative": "0.15417112410068512",
   "Neutral": "0.09251552075147629",
   "Mixed": "0.7300678491592407"
 },
 {
   "tweet_id": "1590103297020817408",
   "Positive": "0.0018956080311909318",
   "Negative": "0.00033601271570660174",
   "Neutral": "0.9977461695671082",
   "Mixed": "2.2208247173693962e-05"
 },
 {
   "tweet_id": "1590103282672074752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103269065752576",
   "Positive": "0.0033552837558090687",
   "Negative": "0.949605405330658",
   "Neutral": "0.04672863334417343",
   "Mixed": "0.0003106416843365878"
 },
 {
   "tweet_id": "1590103234454392832",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590103201331965952",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103192016023552",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103115885596673",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590103115076108288",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590103107899248640",
   "Positive": "0.016159819439053535",
   "Negative": "0.0008705530781298876",
   "Neutral": "0.9829570055007935",
   "Mixed": "1.2614670595212374e-05"
 },
 {
   "tweet_id": "1590103090660876288",
   "Positive": "0.04258269444108009",
   "Negative": "0.0768522396683693",
   "Neutral": "0.6482741832733154",
   "Mixed": "0.2322908341884613"
 },
 {
   "tweet_id": "1590103064023007232",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590103039532072961",
   "Positive": "0.14312613010406494",
   "Negative": "0.03475829213857651",
   "Neutral": "0.772602915763855",
   "Mixed": "0.049512676894664764"
 },
 {
   "tweet_id": "1590103026655981569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590103009979404290",
   "Positive": "0.30237436294555664",
   "Negative": "0.0016273504588752985",
   "Neutral": "0.6959740519523621",
   "Mixed": "2.423350997560192e-05"
 },
 {
   "tweet_id": "1590103008905670657",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590102999330099200",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1590102991109263360",
   "Positive": "0.19345495104789734",
   "Negative": "0.00022637717484030873",
   "Neutral": "0.8062164783477783",
   "Mixed": "0.00010229292092844844"
 },
 {
   "tweet_id": "1590102959593263105",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590102955726082050",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590102913518817280",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1590102912197627906",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590102846242193408",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1590102836461072385",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590102815355310080",
   "Positive": "0.009942306205630302",
   "Negative": "0.40466049313545227",
   "Neutral": "0.16163542866706848",
   "Mixed": "0.423761785030365"
 },
 {
   "tweet_id": "1590102795885367297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102792735059968",
   "Positive": "0.029353132471442223",
   "Negative": "0.0045723081566393375",
   "Neutral": "0.9660657644271851",
   "Mixed": "8.793052984401584e-06"
 },
 {
   "tweet_id": "1590102790613135361",
   "Positive": "0.22603584825992584",
   "Negative": "0.0008006899151951075",
   "Neutral": "0.7730998396873474",
   "Mixed": "6.355282675940543e-05"
 },
 {
   "tweet_id": "1590102775743946752",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590102762729406465",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590102752020946945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102751190876160",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590102717502193664",
   "Positive": "0.03037234954535961",
   "Negative": "0.6158862113952637",
   "Neutral": "0.3533722162246704",
   "Mixed": "0.00036923171137459576"
 },
 {
   "tweet_id": "1590102714825945088",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590102710485155840",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590102692810326016",
   "Positive": "0.2501395344734192",
   "Negative": "0.00032038710196502507",
   "Neutral": "0.7494432926177979",
   "Mixed": "9.67458909144625e-05"
 },
 {
   "tweet_id": "1590102690063085568",
   "Positive": "0.9781410694122314",
   "Negative": "0.00017336582823190838",
   "Neutral": "0.0216586384922266",
   "Mixed": "2.6918816729448736e-05"
 },
 {
   "tweet_id": "1590102647314714624",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590102604658270209",
   "Positive": "0.047712814062833786",
   "Negative": "0.0003138571628369391",
   "Neutral": "0.9519557952880859",
   "Mixed": "1.753095602907706e-05"
 },
 {
   "tweet_id": "1590102594243788800",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590102585280630785",
   "Positive": "0.025024140253663063",
   "Negative": "0.00016025910736061633",
   "Neutral": "0.9747796058654785",
   "Mixed": "3.602363358368166e-05"
 },
 {
   "tweet_id": "1590102565286711297",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590102542629101568",
   "Positive": "0.00653365021571517",
   "Negative": "0.48763495683670044",
   "Neutral": "0.5050855875015259",
   "Mixed": "0.0007457924075424671"
 },
 {
   "tweet_id": "1590102541479464960",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590102511457013760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102505069117440",
   "Positive": "0.017291689291596413",
   "Negative": "0.1398378312587738",
   "Neutral": "0.8423757553100586",
   "Mixed": "0.0004946183180436492"
 },
 {
   "tweet_id": "1590102485569798145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102484357648384",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1590102476879171585",
   "Positive": "0.07507485896348953",
   "Negative": "0.023312589153647423",
   "Neutral": "0.9006701111793518",
   "Mixed": "0.0009425057214684784"
 },
 {
   "tweet_id": "1590102458365190144",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590102457312768000",
   "Positive": "0.16048404574394226",
   "Negative": "0.0002148362109437585",
   "Neutral": "0.8392689228057861",
   "Mixed": "3.220342114218511e-05"
 },
 {
   "tweet_id": "1590102446256181248",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590102440258392064",
   "Positive": "0.025577642023563385",
   "Negative": "0.7742846012115479",
   "Neutral": "0.18696868419647217",
   "Mixed": "0.013169065117835999"
 },
 {
   "tweet_id": "1590102439168204800",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590102431136120832",
   "Positive": "0.33082011342048645",
   "Negative": "0.0003637532063294202",
   "Neutral": "0.668101966381073",
   "Mixed": "0.0007141449023038149"
 },
 {
   "tweet_id": "1590102393181868032",
   "Positive": "0.005673953332006931",
   "Negative": "0.0010123025858774781",
   "Neutral": "0.9933086633682251",
   "Mixed": "5.056222562416224e-06"
 },
 {
   "tweet_id": "1590102387133362177",
   "Positive": "0.017291689291596413",
   "Negative": "0.1398378312587738",
   "Neutral": "0.8423757553100586",
   "Mixed": "0.0004946183180436492"
 },
 {
   "tweet_id": "1590102368120893440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102333673058304",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590102325305413633",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590102283257524224",
   "Positive": "0.003569561755284667",
   "Negative": "0.00014227016072254628",
   "Neutral": "0.996275782585144",
   "Mixed": "1.2485571460274514e-05"
 },
 {
   "tweet_id": "1590102272889212928",
   "Positive": "0.0021236464381217957",
   "Negative": "0.01612222008407116",
   "Neutral": "0.9817484021186829",
   "Mixed": "5.774149940407369e-06"
 },
 {
   "tweet_id": "1590102272364924929",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590102253930942464",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590102205629333507",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590102196401500160",
   "Positive": "0.0016986312111839652",
   "Negative": "0.3221393823623657",
   "Neutral": "0.6760111451148987",
   "Mixed": "0.00015088744112290442"
 },
 {
   "tweet_id": "1590102155385778176",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590102077736620032",
   "Positive": "0.007811849471181631",
   "Negative": "0.0012252875603735447",
   "Neutral": "0.9909566044807434",
   "Mixed": "6.25795655651018e-06"
 },
 {
   "tweet_id": "1590102039631400960",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590102026360393734",
   "Positive": "0.00015085436461959034",
   "Negative": "0.9904307723045349",
   "Neutral": "0.00939358863979578",
   "Mixed": "2.470865729264915e-05"
 },
 {
   "tweet_id": "1590102017397391361",
   "Positive": "0.1625126153230667",
   "Negative": "0.0018174514407292008",
   "Neutral": "0.8355892896652222",
   "Mixed": "8.070265175774693e-05"
 },
 {
   "tweet_id": "1590102014633316352",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590102010145427456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590102004671852544",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1590101997646069762",
   "Positive": "0.00854102149605751",
   "Negative": "0.6345880627632141",
   "Neutral": "0.3560875356197357",
   "Mixed": "0.0007833997369743884"
 },
 {
   "tweet_id": "1590101992122486785",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101987424886785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101987412312064",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590101972543475712",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590101958706475008",
   "Positive": "0.06675384938716888",
   "Negative": "0.0013993153115734458",
   "Neutral": "0.9314581751823425",
   "Mixed": "0.0003886053164023906"
 },
 {
   "tweet_id": "1590101925105610753",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101909083672577",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101903915900928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101898467897344",
   "Positive": "0.004465526435524225",
   "Negative": "0.00011693209671648219",
   "Neutral": "0.995406985282898",
   "Mixed": "1.0524139725021087e-05"
 },
 {
   "tweet_id": "1590101892486803457",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590101889051680769",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1590101871590801408",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590101866502709248",
   "Positive": "0.045225247740745544",
   "Negative": "0.001645062118768692",
   "Neutral": "0.9531205892562866",
   "Mixed": "9.133839739661198e-06"
 },
 {
   "tweet_id": "1590101830310117376",
   "Positive": "0.004581816960126162",
   "Negative": "0.37969812750816345",
   "Neutral": "0.6151058673858643",
   "Mixed": "0.0006142889033071697"
 },
 {
   "tweet_id": "1590101822294794240",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101818843213825",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590101791165022209",
   "Positive": "0.005244963802397251",
   "Negative": "0.00020933925407007337",
   "Neutral": "0.9945393204689026",
   "Mixed": "6.409691650333116e-06"
 },
 {
   "tweet_id": "1590101780154630144",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590101779609399296",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590101773133688832",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590101760646840320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101741533822976",
   "Positive": "0.28701576590538025",
   "Negative": "0.036052972078323364",
   "Neutral": "0.6749590039253235",
   "Mixed": "0.0019721700809895992"
 },
 {
   "tweet_id": "1590101738073489410",
   "Positive": "0.10945457965135574",
   "Negative": "0.0016165398992598057",
   "Neutral": "0.8889012336730957",
   "Mixed": "2.7638137908070348e-05"
 },
 {
   "tweet_id": "1590101737951866880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101730779619330",
   "Positive": "0.05562908202409744",
   "Negative": "0.061560019850730896",
   "Neutral": "0.8827522993087769",
   "Mixed": "5.862820034963079e-05"
 },
 {
   "tweet_id": "1590101718427406336",
   "Positive": "0.1996660828590393",
   "Negative": "0.1290772408246994",
   "Neutral": "0.6710160374641418",
   "Mixed": "0.00024068375932984054"
 },
 {
   "tweet_id": "1590101716024053762",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590101716019646465",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101700768956416",
   "Positive": "0.013414787128567696",
   "Negative": "0.002484077587723732",
   "Neutral": "0.984097421169281",
   "Mixed": "3.623681777753518e-06"
 },
 {
   "tweet_id": "1590101675230261248",
   "Positive": "0.1317014992237091",
   "Negative": "0.08625026047229767",
   "Neutral": "0.7819132208824158",
   "Mixed": "0.0001350555830867961"
 },
 {
   "tweet_id": "1590101656091635713",
   "Positive": "0.002165835117921233",
   "Negative": "0.000466247001895681",
   "Neutral": "0.9973655343055725",
   "Mixed": "2.3289414912142092e-06"
 },
 {
   "tweet_id": "1590101641155735553",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590101634650353664",
   "Positive": "0.14527705311775208",
   "Negative": "0.0004408609529491514",
   "Neutral": "0.8542295694351196",
   "Mixed": "5.254745701677166e-05"
 },
 {
   "tweet_id": "1590101619781570561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101604870807553",
   "Positive": "0.01874830760061741",
   "Negative": "0.47991693019866943",
   "Neutral": "0.030471282079815865",
   "Mixed": "0.47086355090141296"
 },
 {
   "tweet_id": "1590101602857553920",
   "Positive": "0.9970043301582336",
   "Negative": "0.00020211614901199937",
   "Neutral": "0.002769926330074668",
   "Mixed": "2.359796235396061e-05"
 },
 {
   "tweet_id": "1590101576332365824",
   "Positive": "0.10964430123567581",
   "Negative": "0.2875159978866577",
   "Neutral": "0.5076966881752014",
   "Mixed": "0.09514302015304565"
 },
 {
   "tweet_id": "1590101573136396289",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101565859598336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590101565695631361",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590101559517794304",
   "Positive": "0.05961480736732483",
   "Negative": "0.022661954164505005",
   "Neutral": "0.9175882339477539",
   "Mixed": "0.00013498711632564664"
 },
 {
   "tweet_id": "1590101555482873856",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590101522909536256",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1590101509513310208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101496875872256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101472980525056",
   "Positive": "0.011718589812517166",
   "Negative": "0.28450918197631836",
   "Neutral": "0.7037611603736877",
   "Mixed": "1.1079579962824937e-05"
 },
 {
   "tweet_id": "1590101468983750656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101454588891136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590101451837407232",
   "Positive": "0.0022795989643782377",
   "Negative": "0.0003204171662218869",
   "Neutral": "0.9973971843719482",
   "Mixed": "2.9021687169006327e-06"
 },
 {
   "tweet_id": "1590101442626736128",
   "Positive": "0.0025482955388724804",
   "Negative": "0.882488489151001",
   "Neutral": "0.11451724171638489",
   "Mixed": "0.00044598456588573754"
 },
 {
   "tweet_id": "1590101438000418817",
   "Positive": "0.010829707607626915",
   "Negative": "0.11176181584596634",
   "Neutral": "0.17917890846729279",
   "Mixed": "0.6982296109199524"
 },
 {
   "tweet_id": "1590101419096698880",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590101386691489792",
   "Positive": "0.8263427019119263",
   "Negative": "0.0017656446434557438",
   "Neutral": "0.17158234119415283",
   "Mixed": "0.0003093107370659709"
 },
 {
   "tweet_id": "1590101372871274497",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101324171182081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101323290402817",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590101298804031488",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101293150142464",
   "Positive": "0.3974592685699463",
   "Negative": "0.0005208550137467682",
   "Neutral": "0.6019412875175476",
   "Mixed": "7.861049380153418e-05"
 },
 {
   "tweet_id": "1590101291996680192",
   "Positive": "0.03774324804544449",
   "Negative": "0.017936857417225838",
   "Neutral": "0.944311797618866",
   "Mixed": "8.055359103309456e-06"
 },
 {
   "tweet_id": "1590101265098604545",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590101260019314688",
   "Positive": "0.014834418892860413",
   "Negative": "0.6758562326431274",
   "Neutral": "0.2799875736236572",
   "Mixed": "0.029321808367967606"
 },
 {
   "tweet_id": "1590101241996382208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590101239324639236",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590101227626721280",
   "Positive": "0.16035042703151703",
   "Negative": "0.28393885493278503",
   "Neutral": "0.5546595454216003",
   "Mixed": "0.0010511348955333233"
 },
 {
   "tweet_id": "1590101208362287104",
   "Positive": "0.0005103311850689352",
   "Negative": "0.9325870275497437",
   "Neutral": "0.06680826842784882",
   "Mixed": "9.44821658777073e-05"
 },
 {
   "tweet_id": "1590101200586047489",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1590101199893647361",
   "Positive": "0.0006117535522207618",
   "Negative": "0.00041661609429866076",
   "Neutral": "0.9989689588546753",
   "Mixed": "2.785298420349136e-06"
 },
 {
   "tweet_id": "1590101197192826880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590101181296091138",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590101161574821888",
   "Positive": "0.0009409173508174717",
   "Negative": "0.08036752790212631",
   "Neutral": "0.918666660785675",
   "Mixed": "2.48836640821537e-05"
 },
 {
   "tweet_id": "1590101117983080448",
   "Positive": "0.0015875725075602531",
   "Negative": "0.0005957406829111278",
   "Neutral": "0.9978145360946655",
   "Mixed": "2.1971970909362426e-06"
 },
 {
   "tweet_id": "1590101106344230914",
   "Positive": "0.018010087311267853",
   "Negative": "0.17029863595962524",
   "Neutral": "0.7069876194000244",
   "Mixed": "0.10470366477966309"
 },
 {
   "tweet_id": "1590101092293312512",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1590101081098715136",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590101073401761792",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590101061897187328",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590101052795211776",
   "Positive": "0.10282929986715317",
   "Negative": "0.003858069656416774",
   "Neutral": "0.8932557106018066",
   "Mixed": "5.6941240472951904e-05"
 },
 {
   "tweet_id": "1590101044914098176",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100997380001792",
   "Positive": "0.018010087311267853",
   "Negative": "0.17029863595962524",
   "Neutral": "0.7069876194000244",
   "Mixed": "0.10470366477966309"
 },
 {
   "tweet_id": "1590100945110958081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590100903993872384",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590100894611558400",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100892464078848",
   "Positive": "0.021620674058794975",
   "Negative": "0.23862355947494507",
   "Neutral": "0.6717627644538879",
   "Mixed": "0.06799299269914627"
 },
 {
   "tweet_id": "1590100842493136897",
   "Positive": "0.0031375931575894356",
   "Negative": "0.7043358087539673",
   "Neutral": "0.28647568821907043",
   "Mixed": "0.0060508400201797485"
 },
 {
   "tweet_id": "1590100829507563521",
   "Positive": "0.0005271477857604623",
   "Negative": "0.9908281564712524",
   "Neutral": "0.008527586236596107",
   "Mixed": "0.00011709686077665538"
 },
 {
   "tweet_id": "1590100820338827264",
   "Positive": "0.0017674039117991924",
   "Negative": "0.00025068552349694073",
   "Neutral": "0.9979792237281799",
   "Mixed": "2.773671440081671e-06"
 },
 {
   "tweet_id": "1590100814449745920",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590100804262068224",
   "Positive": "0.4914766550064087",
   "Negative": "0.0008725458174012601",
   "Neutral": "0.5075756311416626",
   "Mixed": "7.522133819293231e-05"
 },
 {
   "tweet_id": "1590100799597989888",
   "Positive": "0.0024003495927900076",
   "Negative": "0.0002807066193781793",
   "Neutral": "0.9972745776176453",
   "Mixed": "4.4369659008225426e-05"
 },
 {
   "tweet_id": "1590100769189298177",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590100760150216704",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590100752181391362",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590100735777464323",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100700390133760",
   "Positive": "0.0005097697721794248",
   "Negative": "0.0002839225926436484",
   "Neutral": "0.9992032647132874",
   "Mixed": "3.0621836231148336e-06"
 },
 {
   "tweet_id": "1590100659436519425",
   "Positive": "0.0013113678433001041",
   "Negative": "0.0002774455933831632",
   "Neutral": "0.9984089732170105",
   "Mixed": "2.2888461899128743e-06"
 },
 {
   "tweet_id": "1590100634849902592",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590100608346099712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590100606320246785",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100605271306240",
   "Positive": "0.23599998652935028",
   "Negative": "0.002004275331273675",
   "Neutral": "0.7619631886482239",
   "Mixed": "3.25459404848516e-05"
 },
 {
   "tweet_id": "1590100593539837952",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100552704086016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590100536564805633",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590100535251652608",
   "Positive": "0.08365694433450699",
   "Negative": "0.4421112835407257",
   "Neutral": "0.4732385575771332",
   "Mixed": "0.000993146444670856"
 },
 {
   "tweet_id": "1590100532718206976",
   "Positive": "0.9949729442596436",
   "Negative": "0.00016142749518621713",
   "Neutral": "0.004828186240047216",
   "Mixed": "3.7477595469681546e-05"
 },
 {
   "tweet_id": "1590100523315003392",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100519254884352",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590100513064120321",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1590100505183027200",
   "Positive": "0.029269443824887276",
   "Negative": "0.0023739426396787167",
   "Neutral": "0.9683271050453186",
   "Mixed": "2.950454654637724e-05"
 },
 {
   "tweet_id": "1590100419153653761",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590100411251560448",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590100371502157824",
   "Positive": "0.0005402689566835761",
   "Negative": "6.193652370711789e-05",
   "Neutral": "0.9993880987167358",
   "Mixed": "9.700503142084926e-06"
 },
 {
   "tweet_id": "1590100371170816001",
   "Positive": "0.2439543902873993",
   "Negative": "0.0010965577093884349",
   "Neutral": "0.754835307598114",
   "Mixed": "0.00011377753980923444"
 },
 {
   "tweet_id": "1590100348517380096",
   "Positive": "0.01554388739168644",
   "Negative": "0.3507361114025116",
   "Neutral": "0.4144476652145386",
   "Mixed": "0.21927233040332794"
 },
 {
   "tweet_id": "1590100339495084033",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590100319694123008",
   "Positive": "0.9609607458114624",
   "Negative": "6.871515506645665e-05",
   "Neutral": "0.038935475051403046",
   "Mixed": "3.501295577734709e-05"
 },
 {
   "tweet_id": "1590100306226204672",
   "Positive": "0.01805800572037697",
   "Negative": "0.20205365121364594",
   "Neutral": "0.19208170473575592",
   "Mixed": "0.5878067016601562"
 },
 {
   "tweet_id": "1590100303713828864",
   "Positive": "0.26885712146759033",
   "Negative": "0.01941693015396595",
   "Neutral": "0.6579360365867615",
   "Mixed": "0.05378998443484306"
 },
 {
   "tweet_id": "1590100301927022592",
   "Positive": "0.003660472808405757",
   "Negative": "0.00021119308075867593",
   "Neutral": "0.9961244463920593",
   "Mixed": "3.950617610826157e-06"
 },
 {
   "tweet_id": "1590100293206695936",
   "Positive": "0.3043118119239807",
   "Negative": "0.004047767259180546",
   "Neutral": "0.6916152834892273",
   "Mixed": "2.5168848878820427e-05"
 },
 {
   "tweet_id": "1590100273250254848",
   "Positive": "0.6462519764900208",
   "Negative": "0.006771354470402002",
   "Neutral": "0.3358568847179413",
   "Mixed": "0.011119718663394451"
 },
 {
   "tweet_id": "1590100268825182208",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100265004584964",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590100254657249280",
   "Positive": "0.20002420246601105",
   "Negative": "0.09590550512075424",
   "Neutral": "0.7028911709785461",
   "Mixed": "0.0011792171280831099"
 },
 {
   "tweet_id": "1590100243903053824",
   "Positive": "0.016538936644792557",
   "Negative": "0.011110649444162846",
   "Neutral": "0.012894479557871819",
   "Mixed": "0.9594558477401733"
 },
 {
   "tweet_id": "1590100232712622081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590100232708460545",
   "Positive": "0.12156299501657486",
   "Negative": "0.0005878116353414953",
   "Neutral": "0.8777629733085632",
   "Mixed": "8.620562584837899e-05"
 },
 {
   "tweet_id": "1590100212781289472",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590100205600661504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590100196859314177",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590100165297598465",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590100145685008385",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590100124914835456",
   "Positive": "0.5189213156700134",
   "Negative": "0.08118250221014023",
   "Neutral": "0.3469146192073822",
   "Mixed": "0.05298154428601265"
 },
 {
   "tweet_id": "1590100099283451904",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590100090924199937",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100084737249282",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590100082304880640",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100078970417153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590100059902734336",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590100046196137984",
   "Positive": "0.031474173069000244",
   "Negative": "0.8366438746452332",
   "Neutral": "0.12930536270141602",
   "Mixed": "0.0025765541940927505"
 },
 {
   "tweet_id": "1590100015762268160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590099985517142016",
   "Positive": "0.006200379226356745",
   "Negative": "0.7856259346008301",
   "Neutral": "0.20728148519992828",
   "Mixed": "0.0008922608103603125"
 },
 {
   "tweet_id": "1590099901978771456",
   "Positive": "0.04924727603793144",
   "Negative": "0.0004909071722067893",
   "Neutral": "0.9502431750297546",
   "Mixed": "1.871082349680364e-05"
 },
 {
   "tweet_id": "1590099896983777282",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590099887818821632",
   "Positive": "0.21126610040664673",
   "Negative": "0.2134310007095337",
   "Neutral": "0.5718600749969482",
   "Mixed": "0.003442833898589015"
 },
 {
   "tweet_id": "1590099882156912641",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590099879699021824",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590099858110574592",
   "Positive": "0.008810325525701046",
   "Negative": "0.009783963672816753",
   "Neutral": "0.9813217520713806",
   "Mixed": "8.403821266256273e-05"
 },
 {
   "tweet_id": "1590099820584148992",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590099791220183040",
   "Positive": "0.3254823684692383",
   "Negative": "0.042990829795598984",
   "Neutral": "0.6311163306236267",
   "Mixed": "0.0004104756808374077"
 },
 {
   "tweet_id": "1590099787076235264",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1590099764011753472",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590099759557378048",
   "Positive": "0.0031493152491748333",
   "Negative": "0.7095829844474792",
   "Neutral": "0.11444643139839172",
   "Mixed": "0.17282117903232574"
 },
 {
   "tweet_id": "1590099750140854273",
   "Positive": "0.6551279425621033",
   "Negative": "0.03859054297208786",
   "Neutral": "0.19690847396850586",
   "Mixed": "0.109373077750206"
 },
 {
   "tweet_id": "1590099742159040513",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590099713209995264",
   "Positive": "0.3708426356315613",
   "Negative": "0.0054413313046097755",
   "Neutral": "0.6235760450363159",
   "Mixed": "0.00014004645345266908"
 },
 {
   "tweet_id": "1590099702347108352",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590099696605081600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590099680943575040",
   "Positive": "0.18491230905056",
   "Negative": "0.16752028465270996",
   "Neutral": "0.6474393606185913",
   "Mixed": "0.00012804452853742987"
 },
 {
   "tweet_id": "1590099660144013312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590099613926977538",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590099602472304640",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1590099582402564096",
   "Positive": "0.07068155705928802",
   "Negative": "0.02552913874387741",
   "Neutral": "0.9032079577445984",
   "Mixed": "0.0005812689778394997"
 },
 {
   "tweet_id": "1590099580762619904",
   "Positive": "0.9781410694122314",
   "Negative": "0.00017336582823190838",
   "Neutral": "0.0216586384922266",
   "Mixed": "2.6918816729448736e-05"
 },
 {
   "tweet_id": "1590099578988400640",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590099571262164994",
   "Positive": "0.012142852880060673",
   "Negative": "0.24409496784210205",
   "Neutral": "0.7436605095863342",
   "Mixed": "0.00010169499728363007"
 },
 {
   "tweet_id": "1590099568125177857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590099542502002689",
   "Positive": "0.0031375931575894356",
   "Negative": "0.7043358087539673",
   "Neutral": "0.28647568821907043",
   "Mixed": "0.0060508400201797485"
 },
 {
   "tweet_id": "1590099540056903680",
   "Positive": "0.000462121533928439",
   "Negative": "0.9917629957199097",
   "Neutral": "0.007078076247125864",
   "Mixed": "0.0006968250963836908"
 },
 {
   "tweet_id": "1590099536709824515",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590099534558158849",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590099525468766214",
   "Positive": "0.0031375931575894356",
   "Negative": "0.7043358087539673",
   "Neutral": "0.28647568821907043",
   "Mixed": "0.0060508400201797485"
 },
 {
   "tweet_id": "1590099518464618498",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590099516119998465",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590099508721242112",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590099449577193473",
   "Positive": "0.022800762206315994",
   "Negative": "0.5046488046646118",
   "Neutral": "0.45442190766334534",
   "Mixed": "0.01812851056456566"
 },
 {
   "tweet_id": "1590099435597725697",
   "Positive": "0.12057808041572571",
   "Negative": "0.03856313228607178",
   "Neutral": "0.8408401608467102",
   "Mixed": "1.8628743418958038e-05"
 },
 {
   "tweet_id": "1590099428224143361",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590099422527905793",
   "Positive": "0.0022148224525153637",
   "Negative": "0.30338627099990845",
   "Neutral": "0.6619863510131836",
   "Mixed": "0.03241247683763504"
 },
 {
   "tweet_id": "1590099414047428608",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590099392585175041",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1590099385420894209",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590099348649836544",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590099343885103105",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590099341917982720",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590099339439140865",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590099332761812992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590099328952983552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590099327795757057",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590099314571120640",
   "Positive": "0.033687978982925415",
   "Negative": "0.31291186809539795",
   "Neutral": "0.6531815528869629",
   "Mixed": "0.00021861058485228568"
 },
 {
   "tweet_id": "1590099308983898112",
   "Positive": "0.8829144239425659",
   "Negative": "0.0001124642149079591",
   "Neutral": "0.1169503703713417",
   "Mixed": "2.263592068629805e-05"
 },
 {
   "tweet_id": "1590099306710978560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590099301564559360",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590099267087400961",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590099261395701761",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590099256383541250",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590099213399957505",
   "Positive": "0.23335951566696167",
   "Negative": "0.008563270792365074",
   "Neutral": "0.7579500079154968",
   "Mixed": "0.00012719252845272422"
 },
 {
   "tweet_id": "1590099177249574913",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590099132894834688",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590099093652926464",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590099062405345283",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590099028494389248",
   "Positive": "0.04015490412712097",
   "Negative": "0.0008929538307711482",
   "Neutral": "0.9589284062385559",
   "Mixed": "2.3810045604477637e-05"
 },
 {
   "tweet_id": "1590099011666874368",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590099007053127680",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590098999780212736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098997582393345",
   "Positive": "0.8807020783424377",
   "Negative": "0.0051965778693556786",
   "Neutral": "0.11387491971254349",
   "Mixed": "0.00022637017536908388"
 },
 {
   "tweet_id": "1590098993258057728",
   "Positive": "0.7845665216445923",
   "Negative": "0.0013599464436993003",
   "Neutral": "0.19913439452648163",
   "Mixed": "0.014939116314053535"
 },
 {
   "tweet_id": "1590098972500430849",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098969493135360",
   "Positive": "0.0014673339901492",
   "Negative": "0.00011494166392367333",
   "Neutral": "0.9984058737754822",
   "Mixed": "1.1858730431413278e-05"
 },
 {
   "tweet_id": "1590098964233490432",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590098953848360960",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590098951717662721",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098945451384833",
   "Positive": "0.0060889446176588535",
   "Negative": "0.00017277848382946104",
   "Neutral": "0.9937280416488647",
   "Mixed": "1.0150446541956626e-05"
 },
 {
   "tweet_id": "1590098943102550016",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590098929580118016",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098928120131585",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590098925448736769",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590098908612788225",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098906263609345",
   "Positive": "0.1209200993180275",
   "Negative": "0.0012242905795574188",
   "Neutral": "0.8778291344642639",
   "Mixed": "2.645759013830684e-05"
 },
 {
   "tweet_id": "1590098902782332929",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098891395198976",
   "Positive": "0.01962970569729805",
   "Negative": "0.3820144534111023",
   "Neutral": "0.5982323884963989",
   "Mixed": "0.00012355168291833252"
 },
 {
   "tweet_id": "1590098867621531648",
   "Positive": "0.0004931464209221303",
   "Negative": "0.0001962671085493639",
   "Neutral": "0.9993036985397339",
   "Mixed": "6.853923878225032e-06"
 },
 {
   "tweet_id": "1590098862328668160",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098852605894656",
   "Positive": "0.001657408196479082",
   "Negative": "0.01240011490881443",
   "Neutral": "0.9859235286712646",
   "Mixed": "1.895500099635683e-05"
 },
 {
   "tweet_id": "1590098837385117697",
   "Positive": "0.005519574508070946",
   "Negative": "0.06130019202828407",
   "Neutral": "0.9328693151473999",
   "Mixed": "0.0003109638055320829"
 },
 {
   "tweet_id": "1590098794183462913",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590098772423766016",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1590098762869145600",
   "Positive": "0.02726522833108902",
   "Negative": "0.0010796436108648777",
   "Neutral": "0.9715819954872131",
   "Mixed": "7.30844258214347e-05"
 },
 {
   "tweet_id": "1590098762717810688",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098743877316608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098735178346496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098624200863744",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590098588427702273",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590098564885028864",
   "Positive": "0.001569232321344316",
   "Negative": "0.8375064134597778",
   "Neutral": "0.1608920395374298",
   "Mixed": "3.2289884984493256e-05"
 },
 {
   "tweet_id": "1590098550658330624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098543897083904",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590098476519784448",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098469162987521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098452720996352",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098432609615872",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590098425881964545",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098423046627328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098409746464768",
   "Positive": "0.02033975161612034",
   "Negative": "0.00045667262747883797",
   "Neutral": "0.9791768193244934",
   "Mixed": "2.676807707757689e-05"
 },
 {
   "tweet_id": "1590098401873760257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098398488952833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590098395267739649",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590098379484565509",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590098375118311424",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590098359775559680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098322756636672",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590098320785301507",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098300442935297",
   "Positive": "0.02236872725188732",
   "Negative": "0.0016346912598237395",
   "Neutral": "0.9759917259216309",
   "Mixed": "4.872953923040768e-06"
 },
 {
   "tweet_id": "1590098297703702528",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1590098284353228800",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590098269002428416",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098250400694272",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590098238472073216",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590098206737960960",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098203504185345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098188069138433",
   "Positive": "0.0025482955388724804",
   "Negative": "0.882488489151001",
   "Neutral": "0.11451724171638489",
   "Mixed": "0.00044598456588573754"
 },
 {
   "tweet_id": "1590098168716623873",
   "Positive": "0.004907255992293358",
   "Negative": "0.0017922575352713466",
   "Neutral": "0.9932821989059448",
   "Mixed": "1.823128513933625e-05"
 },
 {
   "tweet_id": "1590098162059907072",
   "Positive": "0.035227566957473755",
   "Negative": "0.42536962032318115",
   "Neutral": "0.5391830205917358",
   "Mixed": "0.00021979915618430823"
 },
 {
   "tweet_id": "1590098160135077888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098156829650945",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098145765371906",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098142329860097",
   "Positive": "0.047283612191677094",
   "Negative": "0.07757799327373505",
   "Neutral": "0.8674091696739197",
   "Mixed": "0.007729275152087212"
 },
 {
   "tweet_id": "1590098133815480320",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590098127654109186",
   "Positive": "0.047283612191677094",
   "Negative": "0.07757799327373505",
   "Neutral": "0.8674091696739197",
   "Mixed": "0.007729275152087212"
 },
 {
   "tweet_id": "1590098117373743104",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098117025636352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590098108507033600",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098099158253569",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098091457523712",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098086025924609",
   "Positive": "0.03255613520741463",
   "Negative": "0.013449842110276222",
   "Neutral": "0.8693180680274963",
   "Mixed": "0.08467601239681244"
 },
 {
   "tweet_id": "1590098069710057478",
   "Positive": "0.0025482955388724804",
   "Negative": "0.882488489151001",
   "Neutral": "0.11451724171638489",
   "Mixed": "0.00044598456588573754"
 },
 {
   "tweet_id": "1590098062432645120",
   "Positive": "0.025704756379127502",
   "Negative": "0.0012253224849700928",
   "Neutral": "0.97296541929245",
   "Mixed": "0.00010455849405843765"
 },
 {
   "tweet_id": "1590098059736027136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590098059366567936",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590098059156856832",
   "Positive": "0.03761453926563263",
   "Negative": "0.0006724325940012932",
   "Neutral": "0.9597662091255188",
   "Mixed": "0.0019468303071334958"
 },
 {
   "tweet_id": "1590098058347675650",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590098044040581120",
   "Positive": "0.0011258679442107677",
   "Negative": "0.8061028122901917",
   "Neutral": "0.19268685579299927",
   "Mixed": "8.446232095593587e-05"
 },
 {
   "tweet_id": "1590098043587923969",
   "Positive": "0.008670195005834103",
   "Negative": "0.0002984794264193624",
   "Neutral": "0.9910209774971008",
   "Mixed": "1.0382031177869067e-05"
 },
 {
   "tweet_id": "1590098034113011712",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098032300724224",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590098023689830400",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590098011673460737",
   "Positive": "0.28230196237564087",
   "Negative": "0.0010935516329482198",
   "Neutral": "0.7165107727050781",
   "Mixed": "9.366438462166116e-05"
 },
 {
   "tweet_id": "1590098002651525120",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590098000956702721",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590097971693391875",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590097947290894336",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097913598070784",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590097904089567232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097896661475329",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590097889019465728",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590097888063164418",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590097884577660928",
   "Positive": "0.21557587385177612",
   "Negative": "0.006694525945931673",
   "Neutral": "0.7772713899612427",
   "Mixed": "0.0004582549736369401"
 },
 {
   "tweet_id": "1590097875094368256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590097871487250433",
   "Positive": "0.00013103867240715772",
   "Negative": "0.0022447470109909773",
   "Neutral": "0.9976154565811157",
   "Mixed": "8.815632099867798e-06"
 },
 {
   "tweet_id": "1590097866080784384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097844949880832",
   "Positive": "0.5283163189888",
   "Negative": "0.005319498013705015",
   "Neutral": "0.46583208441734314",
   "Mixed": "0.0005320474738255143"
 },
 {
   "tweet_id": "1590097838335488000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097832652177409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097810296573953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097798887747585",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590097788209360896",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097788020236289",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590097775563141121",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097772510064641",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590097764742221824",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097761621266432",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590097729249677312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590097719389212672",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097705204088832",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590097678565707776",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1590097673343811584",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590097638279421953",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590097629785923584",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1590097585133740034",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590097577063907330",
   "Positive": "0.0807364284992218",
   "Negative": "0.07599782943725586",
   "Neutral": "0.8432115912437439",
   "Mixed": "5.422156391432509e-05"
 },
 {
   "tweet_id": "1590097568100667392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097561427542016",
   "Positive": "0.016035886481404305",
   "Negative": "0.0004118919896427542",
   "Neutral": "0.9835456609725952",
   "Mixed": "6.517465862998506e-06"
 },
 {
   "tweet_id": "1590097538732130304",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590097528959414272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097520503685121",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097514954297345",
   "Positive": "0.24448592960834503",
   "Negative": "0.07049326598644257",
   "Neutral": "0.6847255825996399",
   "Mixed": "0.00029524817364290357"
 },
 {
   "tweet_id": "1590097512203157506",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097500547190784",
   "Positive": "0.4625561833381653",
   "Negative": "0.0008612702367827296",
   "Neutral": "0.005457159131765366",
   "Mixed": "0.5311253666877747"
 },
 {
   "tweet_id": "1590097488719261697",
   "Positive": "0.2935616374015808",
   "Negative": "0.0008464839775115252",
   "Neutral": "0.7055642008781433",
   "Mixed": "2.7657388272928074e-05"
 },
 {
   "tweet_id": "1590097443064270848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097438375051265",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590097355843346432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097355319439360",
   "Positive": "0.05604942888021469",
   "Negative": "0.022813856601715088",
   "Neutral": "0.9209370017051697",
   "Mixed": "0.0001996971113840118"
 },
 {
   "tweet_id": "1590097350508564481",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590097330790809600",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590097322071199746",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590097313678397441",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097276785287168",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1590097276231643137",
   "Positive": "0.05604942888021469",
   "Negative": "0.022813856601715088",
   "Neutral": "0.9209370017051697",
   "Mixed": "0.0001996971113840118"
 },
 {
   "tweet_id": "1590097267016761344",
   "Positive": "0.177382692694664",
   "Negative": "0.0103581128641963",
   "Neutral": "0.8122310638427734",
   "Mixed": "2.8100888812332414e-05"
 },
 {
   "tweet_id": "1590097253578182657",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590097239799496705",
   "Positive": "0.003121238434687257",
   "Negative": "0.0004851648409385234",
   "Neutral": "0.9963907599449158",
   "Mixed": "2.9023276510997675e-06"
 },
 {
   "tweet_id": "1590097224956276736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097208044531712",
   "Positive": "0.327359676361084",
   "Negative": "0.04785662144422531",
   "Neutral": "0.6065629124641418",
   "Mixed": "0.018220815807580948"
 },
 {
   "tweet_id": "1590097195478716416",
   "Positive": "0.5727435350418091",
   "Negative": "0.028414631262421608",
   "Neutral": "0.3986017107963562",
   "Mixed": "0.00024014580412767828"
 },
 {
   "tweet_id": "1590097184053407744",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590097142676615168",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097113400373250",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590097107288920065",
   "Positive": "0.0060889446176588535",
   "Negative": "0.00017277848382946104",
   "Neutral": "0.9937280416488647",
   "Mixed": "1.0150446541956626e-05"
 },
 {
   "tweet_id": "1590097093917827072",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590097015731625984",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1590097015509504002",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590097014033092608",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590097006558863360",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096945921789952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096918549794816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590096881933115392",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590096875041918976",
   "Positive": "0.046368613839149475",
   "Negative": "0.06749172508716583",
   "Neutral": "0.883335530757904",
   "Mixed": "0.0028041384648531675"
 },
 {
   "tweet_id": "1590096845036204032",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590096829810900992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590096828522864641",
   "Positive": "0.0007850309484638274",
   "Negative": "0.9684317111968994",
   "Neutral": "0.03074527531862259",
   "Mixed": "3.7957179301884025e-05"
 },
 {
   "tweet_id": "1590096828074438656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590096824735522818",
   "Positive": "0.13183283805847168",
   "Negative": "0.024205410853028297",
   "Neutral": "0.8411962389945984",
   "Mixed": "0.002765565412119031"
 },
 {
   "tweet_id": "1590096768439492608",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590096735560687622",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096716946341888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096710877208576",
   "Positive": "0.09030894190073013",
   "Negative": "0.08507858216762543",
   "Neutral": "0.1338900774717331",
   "Mixed": "0.6907224059104919"
 },
 {
   "tweet_id": "1590096694297100289",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590096691071700992",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590096688760619009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096681093464065",
   "Positive": "0.008377491496503353",
   "Negative": "5.3171988838585094e-05",
   "Neutral": "0.9914274215698242",
   "Mixed": "0.0001419076434103772"
 },
 {
   "tweet_id": "1590096678354554880",
   "Positive": "0.004336687736213207",
   "Negative": "0.8265935182571411",
   "Neutral": "0.11124593764543533",
   "Mixed": "0.05782395601272583"
 },
 {
   "tweet_id": "1590096648398843905",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590096617117716480",
   "Positive": "0.0018557984149083495",
   "Negative": "0.024824991822242737",
   "Neutral": "0.9733040928840637",
   "Mixed": "1.5078398973855656e-05"
 },
 {
   "tweet_id": "1590096601099665408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096580127780864",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1590096571970260994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096568186994688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096553930551297",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590096551107768320",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590096540080693250",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590096526822735875",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096524432003074",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590096524088082432",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590096523974836224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590096507814182913",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096495294152704",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590096466088906752",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096461454536705",
   "Positive": "0.0025482955388724804",
   "Negative": "0.882488489151001",
   "Neutral": "0.11451724171638489",
   "Mixed": "0.00044598456588573754"
 },
 {
   "tweet_id": "1590096461089632256",
   "Positive": "0.007561251521110535",
   "Negative": "0.8450057506561279",
   "Neutral": "0.13054214417934418",
   "Mixed": "0.016890792176127434"
 },
 {
   "tweet_id": "1590096454668148736",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590096445889445888",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096426918612992",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590096420639367168",
   "Positive": "0.0023030892480164766",
   "Negative": "0.07939115911722183",
   "Neutral": "0.9178394079208374",
   "Mixed": "0.0004663142026402056"
 },
 {
   "tweet_id": "1590096417787641857",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590096393175150593",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096391447400449",
   "Positive": "0.9181114435195923",
   "Negative": "0.0022455642465502024",
   "Neutral": "0.01241346076130867",
   "Mixed": "0.0672295019030571"
 },
 {
   "tweet_id": "1590096389761282048",
   "Positive": "0.9781410694122314",
   "Negative": "0.00017336582823190838",
   "Neutral": "0.0216586384922266",
   "Mixed": "2.6918816729448736e-05"
 },
 {
   "tweet_id": "1590096389140541441",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590096359696502784",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1590096355057610752",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096349655355393",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590096332303536131",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096310103085056",
   "Positive": "0.7340057492256165",
   "Negative": "0.03708807751536369",
   "Neutral": "0.22567971050739288",
   "Mixed": "0.003226523520424962"
 },
 {
   "tweet_id": "1590096309167407104",
   "Positive": "0.9258096218109131",
   "Negative": "0.060701340436935425",
   "Neutral": "0.01224276889115572",
   "Mixed": "0.0012462554732337594"
 },
 {
   "tweet_id": "1590096278742269952",
   "Positive": "0.00908159464597702",
   "Negative": "0.19128108024597168",
   "Neutral": "0.7995813488960266",
   "Mixed": "5.599074938800186e-05"
 },
 {
   "tweet_id": "1590096254138454016",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590096235050192898",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590096207237763072",
   "Positive": "0.015976430848240852",
   "Negative": "0.001981345470994711",
   "Neutral": "0.9820306301116943",
   "Mixed": "1.1585319043661002e-05"
 },
 {
   "tweet_id": "1590096204465307648",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590096196827480064",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590096194474487808",
   "Positive": "0.10432915389537811",
   "Negative": "0.00022282241843640804",
   "Neutral": "0.8954051733016968",
   "Mixed": "4.2754370952025056e-05"
 },
 {
   "tweet_id": "1590096188690563072",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590096174073073664",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590096156797042688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096150480445443",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1590096142569992192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096121430704128",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590096118029094912",
   "Positive": "0.012215090915560722",
   "Negative": "0.4343782663345337",
   "Neutral": "0.5290890336036682",
   "Mixed": "0.02431761845946312"
 },
 {
   "tweet_id": "1590096091135242240",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590096079596687361",
   "Positive": "0.0016278164694085717",
   "Negative": "0.8318471908569336",
   "Neutral": "0.16649240255355835",
   "Mixed": "3.269236913183704e-05"
 },
 {
   "tweet_id": "1590096077742833667",
   "Positive": "0.0025482955388724804",
   "Negative": "0.882488489151001",
   "Neutral": "0.11451724171638489",
   "Mixed": "0.00044598456588573754"
 },
 {
   "tweet_id": "1590096059686322176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590096057853444096",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590096048760188928",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1590096038492540928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590096023518486529",
   "Positive": "0.997697651386261",
   "Negative": "0.0003623248776420951",
   "Neutral": "0.0018993638223037124",
   "Mixed": "4.066764086019248e-05"
 },
 {
   "tweet_id": "1590096013804851200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095978228764672",
   "Positive": "0.006263598799705505",
   "Negative": "0.000283034605672583",
   "Neutral": "0.9934493899345398",
   "Mixed": "4.0565964809502475e-06"
 },
 {
   "tweet_id": "1590095951540408321",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590095935077781504",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1590095933391646720",
   "Positive": "0.007370113395154476",
   "Negative": "0.004316474311053753",
   "Neutral": "0.9883038997650146",
   "Mixed": "9.560564649291337e-06"
 },
 {
   "tweet_id": "1590095923614715904",
   "Positive": "0.009535040706396103",
   "Negative": "0.4241068363189697",
   "Neutral": "0.5311495661735535",
   "Mixed": "0.03520843759179115"
 },
 {
   "tweet_id": "1590095855092396033",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590095847760723968",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1590095839850278912",
   "Positive": "0.19686217606067657",
   "Negative": "0.21471764147281647",
   "Neutral": "0.5878559947013855",
   "Mixed": "0.0005642232717946172"
 },
 {
   "tweet_id": "1590095797613654016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095779427123200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095774645653504",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590095771285610497",
   "Positive": "0.1815827488899231",
   "Negative": "0.008351934142410755",
   "Neutral": "0.8100111484527588",
   "Mixed": "5.414084080257453e-05"
 },
 {
   "tweet_id": "1590095737982922752",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590095735256936448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095704248107008",
   "Positive": "0.0004142689285799861",
   "Negative": "0.00023119313118513674",
   "Neutral": "0.9993517994880676",
   "Mixed": "2.693223450478399e-06"
 },
 {
   "tweet_id": "1590095698770685952",
   "Positive": "0.5034376978874207",
   "Negative": "0.10768841207027435",
   "Neutral": "0.3130829930305481",
   "Mixed": "0.07579093426465988"
 },
 {
   "tweet_id": "1590095692928012288",
   "Positive": "0.000908593472559005",
   "Negative": "0.00047646547318436205",
   "Neutral": "0.998612642288208",
   "Mixed": "2.3052516553434543e-06"
 },
 {
   "tweet_id": "1590095683687620609",
   "Positive": "0.00046691298484802246",
   "Negative": "0.00028127187397331",
   "Neutral": "0.9992493987083435",
   "Mixed": "2.3577019874210237e-06"
 },
 {
   "tweet_id": "1590095674548199425",
   "Positive": "0.0003595543676055968",
   "Negative": "0.0005465710419230163",
   "Neutral": "0.9990918636322021",
   "Mixed": "2.081680122500984e-06"
 },
 {
   "tweet_id": "1590095665019117568",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590095661084528642",
   "Positive": "0.0003649163991212845",
   "Negative": "0.00017580125131644309",
   "Neutral": "0.9994563460350037",
   "Mixed": "3.023221097464557e-06"
 },
 {
   "tweet_id": "1590095641140940801",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590095636937904130",
   "Positive": "0.00032623723382130265",
   "Negative": "0.0003066700592171401",
   "Neutral": "0.9993647933006287",
   "Mixed": "2.247869360871846e-06"
 },
 {
   "tweet_id": "1590095626918060032",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590095617480531969",
   "Positive": "0.00027322827372699976",
   "Negative": "0.00030858174432069063",
   "Neutral": "0.9994156360626221",
   "Mixed": "2.565545173638384e-06"
 },
 {
   "tweet_id": "1590095608031109121",
   "Positive": "0.0008209507213905454",
   "Negative": "0.8078412413597107",
   "Neutral": "0.19128338992595673",
   "Mixed": "5.440507811727002e-05"
 },
 {
   "tweet_id": "1590095604231077889",
   "Positive": "0.0004452388093341142",
   "Negative": "0.00025870976969599724",
   "Neutral": "0.9992936849594116",
   "Mixed": "2.369056119277957e-06"
 },
 {
   "tweet_id": "1590095583255355393",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590095583196610560",
   "Positive": "0.11063192039728165",
   "Negative": "0.00018433522200211883",
   "Neutral": "0.8891600370407104",
   "Mixed": "2.3606678951182403e-05"
 },
 {
   "tweet_id": "1590095551840006145",
   "Positive": "0.036576662212610245",
   "Negative": "0.0009951649699360132",
   "Neutral": "0.9623134732246399",
   "Mixed": "0.00011471167817944661"
 },
 {
   "tweet_id": "1590095526799679489",
   "Positive": "0.09521055221557617",
   "Negative": "0.00905517302453518",
   "Neutral": "0.8956282138824463",
   "Mixed": "0.00010605339048197493"
 },
 {
   "tweet_id": "1590095506071785472",
   "Positive": "0.26984092593193054",
   "Negative": "0.0010348232463002205",
   "Neutral": "0.7288168668746948",
   "Mixed": "0.0003073596744798124"
 },
 {
   "tweet_id": "1590095502376587264",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095499440558080",
   "Positive": "0.003030849387869239",
   "Negative": "0.588091254234314",
   "Neutral": "0.4074854850769043",
   "Mixed": "0.0013924804516136646"
 },
 {
   "tweet_id": "1590095458520936448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095450610491393",
   "Positive": "0.0026122485287487507",
   "Negative": "0.7156602740287781",
   "Neutral": "0.2782512903213501",
   "Mixed": "0.0034762502182275057"
 },
 {
   "tweet_id": "1590095428707844096",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590095394620338178",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590095393047842817",
   "Positive": "0.0041397749446332455",
   "Negative": "0.56215900182724",
   "Neutral": "0.43304601311683655",
   "Mixed": "0.0006552261766046286"
 },
 {
   "tweet_id": "1590095378963398656",
   "Positive": "0.07026291638612747",
   "Negative": "0.0016396420542150736",
   "Neutral": "0.9280766248703003",
   "Mixed": "2.0812683942494914e-05"
 },
 {
   "tweet_id": "1590095374542589952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590095337192325121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590095324978483202",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095319764676608",
   "Positive": "0.0011674333363771439",
   "Negative": "0.00025920025655068457",
   "Neutral": "0.9985706806182861",
   "Mixed": "2.5997387638199143e-06"
 },
 {
   "tweet_id": "1590095317801742336",
   "Positive": "0.019415145739912987",
   "Negative": "0.00014458531222771853",
   "Neutral": "0.9804232120513916",
   "Mixed": "1.7081954865716398e-05"
 },
 {
   "tweet_id": "1590095299862990848",
   "Positive": "0.0007538706995546818",
   "Negative": "0.9677225351333618",
   "Neutral": "0.029393065720796585",
   "Mixed": "0.0021305137779563665"
 },
 {
   "tweet_id": "1590095292439105536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590095284386033665",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590095266711236608",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590095256657465344",
   "Positive": "0.037674430757761",
   "Negative": "0.002648798516020179",
   "Neutral": "0.95967036485672",
   "Mixed": "6.382094397849869e-06"
 },
 {
   "tweet_id": "1590095238336753664",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095236206039040",
   "Positive": "0.00217195856384933",
   "Negative": "0.7055720686912537",
   "Neutral": "0.2851502299308777",
   "Mixed": "0.007105703465640545"
 },
 {
   "tweet_id": "1590095234582863872",
   "Positive": "0.001506064203567803",
   "Negative": "0.001001055003143847",
   "Neutral": "0.9974908828735352",
   "Mixed": "2.030544237641152e-06"
 },
 {
   "tweet_id": "1590095203691794432",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590095173702549504",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590095170980413440",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590095161304166401",
   "Positive": "0.0002745238598436117",
   "Negative": "0.9814558029174805",
   "Neutral": "0.018214255571365356",
   "Mixed": "5.53818499611225e-05"
 },
 {
   "tweet_id": "1590095150164107264",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590095136884547584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095135840555008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095135064608768",
   "Positive": "0.47298383712768555",
   "Negative": "0.001533829839900136",
   "Neutral": "0.5172183513641357",
   "Mixed": "0.00826399028301239"
 },
 {
   "tweet_id": "1590095121051451393",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590095108099096576",
   "Positive": "0.12061208486557007",
   "Negative": "0.023342914879322052",
   "Neutral": "0.6083824634552002",
   "Mixed": "0.2476625293493271"
 },
 {
   "tweet_id": "1590095106702716928",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590095102055428096",
   "Positive": "0.48455458879470825",
   "Negative": "0.0009159158798865974",
   "Neutral": "0.5144780278205872",
   "Mixed": "5.1465656724758446e-05"
 },
 {
   "tweet_id": "1590095099392065536",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590095083315286019",
   "Positive": "0.23550228774547577",
   "Negative": "0.2081233561038971",
   "Neutral": "0.5458396077156067",
   "Mixed": "0.010534722357988358"
 },
 {
   "tweet_id": "1590095056140369921",
   "Positive": "0.009125487878918648",
   "Negative": "0.11382003128528595",
   "Neutral": "0.10661308467388153",
   "Mixed": "0.7704413533210754"
 },
 {
   "tweet_id": "1590095052013187074",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590095043544907776",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590095027178713089",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590095022128779265",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590095013358469121",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590095005384785920",
   "Positive": "0.0008209507213905454",
   "Negative": "0.8078412413597107",
   "Neutral": "0.19128338992595673",
   "Mixed": "5.440507811727002e-05"
 },
 {
   "tweet_id": "1590094933318569984",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590094925433311233",
   "Positive": "0.0018735306803137064",
   "Negative": "0.35909706354141235",
   "Neutral": "0.6385514140129089",
   "Mixed": "0.00047800273750908673"
 },
 {
   "tweet_id": "1590094914569674752",
   "Positive": "0.08509782701730728",
   "Negative": "0.020253770053386688",
   "Neutral": "0.07829596847295761",
   "Mixed": "0.8163524866104126"
 },
 {
   "tweet_id": "1590094889794301952",
   "Positive": "0.5573951005935669",
   "Negative": "0.0015626075910404325",
   "Neutral": "0.44033563137054443",
   "Mixed": "0.0007066864054650068"
 },
 {
   "tweet_id": "1590094877563695106",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590094863177252866",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590094840129519616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590094820043030528",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590094815173414913",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590094802674417664",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590094795141435392",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590094764799832064",
   "Positive": "0.0015789259923622012",
   "Negative": "0.06540320813655853",
   "Neutral": "0.9314851760864258",
   "Mixed": "0.0015327187720686197"
 },
 {
   "tweet_id": "1590094764229029888",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590094762044198913",
   "Positive": "0.029015854001045227",
   "Negative": "0.3104868233203888",
   "Neutral": "0.6604247093200684",
   "Mixed": "7.268261106219143e-05"
 },
 {
   "tweet_id": "1590094758604464128",
   "Positive": "0.4534795582294464",
   "Negative": "0.002742049051448703",
   "Neutral": "0.543691098690033",
   "Mixed": "8.721363701624796e-05"
 },
 {
   "tweet_id": "1590094733421879297",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590094724400312320",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590094723427225602",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590094717920088064",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590094717731020801",
   "Positive": "0.054663389921188354",
   "Negative": "0.0018188905669376254",
   "Neutral": "0.943498969078064",
   "Mixed": "1.8803277271217667e-05"
 },
 {
   "tweet_id": "1590094672663564289",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590094659568963584",
   "Positive": "0.0002209942467743531",
   "Negative": "0.06148915737867355",
   "Neutral": "0.9382756948471069",
   "Mixed": "1.412046913173981e-05"
 },
 {
   "tweet_id": "1590094648315244544",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590094635082608641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590094628774375425",
   "Positive": "0.4119287133216858",
   "Negative": "0.27934587001800537",
   "Neutral": "0.28580981492996216",
   "Mixed": "0.022915568202733994"
 },
 {
   "tweet_id": "1590094621434347520",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094577926828035",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094573145305088",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590094572050608130",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094552982908929",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590094548600254464",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590094453855129600",
   "Positive": "0.002175507601350546",
   "Negative": "5.922528725932352e-05",
   "Neutral": "0.9977560639381409",
   "Mixed": "9.335434697277378e-06"
 },
 {
   "tweet_id": "1590094445034508289",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590094443646185473",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590094426734755840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094401384370176",
   "Positive": "0.9973151087760925",
   "Negative": "0.00010505253885639831",
   "Neutral": "0.0025669278111308813",
   "Mixed": "1.2863973097410053e-05"
 },
 {
   "tweet_id": "1590094389053100034",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590094363048411136",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590094362041798658",
   "Positive": "0.0002209942467743531",
   "Negative": "0.06148915737867355",
   "Neutral": "0.9382756948471069",
   "Mixed": "1.412046913173981e-05"
 },
 {
   "tweet_id": "1590094338159415296",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590094335844188161",
   "Positive": "0.12485524266958237",
   "Negative": "0.0033469898626208305",
   "Neutral": "0.8717849850654602",
   "Mixed": "1.278959280170966e-05"
 },
 {
   "tweet_id": "1590094323487772672",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590094321394384896",
   "Positive": "0.12708905339241028",
   "Negative": "0.00028005242347717285",
   "Neutral": "0.8726129531860352",
   "Mixed": "1.7985707017942332e-05"
 },
 {
   "tweet_id": "1590094316415832064",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590094312154755074",
   "Positive": "0.22326382994651794",
   "Negative": "0.0020967924501746893",
   "Neutral": "0.7745130062103271",
   "Mixed": "0.00012634831364266574"
 },
 {
   "tweet_id": "1590094309738561536",
   "Positive": "0.39482057094573975",
   "Negative": "0.0025609140284359455",
   "Neutral": "0.6025910973548889",
   "Mixed": "2.7443355065770447e-05"
 },
 {
   "tweet_id": "1590094308127883264",
   "Positive": "0.008715125732123852",
   "Negative": "0.29941657185554504",
   "Neutral": "0.18528518080711365",
   "Mixed": "0.5065830945968628"
 },
 {
   "tweet_id": "1590094301505413120",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590094278071844864",
   "Positive": "0.11696956306695938",
   "Negative": "0.0011615410912781954",
   "Neutral": "0.8818603157997131",
   "Mixed": "8.663020707899705e-06"
 },
 {
   "tweet_id": "1590094276008239105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094242323406848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590094236988641280",
   "Positive": "0.008810325525701046",
   "Negative": "0.009783963672816753",
   "Neutral": "0.9813217520713806",
   "Mixed": "8.403821266256273e-05"
 },
 {
   "tweet_id": "1590094236808278016",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1590094212409982976",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590094210312675334",
   "Positive": "0.0011991140199825168",
   "Negative": "0.00015871164214331657",
   "Neutral": "0.9986391663551331",
   "Mixed": "2.9827588150510564e-06"
 },
 {
   "tweet_id": "1590094209209729026",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590094205631619072",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590094205207969792",
   "Positive": "0.20856653153896332",
   "Negative": "0.0011656576534733176",
   "Neutral": "0.7902568578720093",
   "Mixed": "1.1069058928114828e-05"
 },
 {
   "tweet_id": "1590094194995265538",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590094183863582721",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590094175105871873",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590094163013689344",
   "Positive": "0.07542512565851212",
   "Negative": "0.05045069381594658",
   "Neutral": "0.6506664156913757",
   "Mixed": "0.22345781326293945"
 },
 {
   "tweet_id": "1590094160794910720",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590094145917681664",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094143765680130",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590094143153635329",
   "Positive": "0.8091744780540466",
   "Negative": "0.009699431248009205",
   "Neutral": "0.18107689917087555",
   "Mixed": "4.915338649880141e-05"
 },
 {
   "tweet_id": "1590094129182412800",
   "Positive": "0.014744476415216923",
   "Negative": "0.04091624170541763",
   "Neutral": "0.9443212747573853",
   "Mixed": "1.8019385606748983e-05"
 },
 {
   "tweet_id": "1590094121900724224",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590094103383281666",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590094061985497088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590094049461301248",
   "Positive": "0.00043372673098929226",
   "Negative": "0.8937838077545166",
   "Neutral": "0.10576311498880386",
   "Mixed": "1.9260534827481024e-05"
 },
 {
   "tweet_id": "1590093999892987905",
   "Positive": "0.27011457085609436",
   "Negative": "0.009325041435658932",
   "Neutral": "0.7203618288040161",
   "Mixed": "0.00019855824939440936"
 },
 {
   "tweet_id": "1590093972764233728",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590093957652185088",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590093944465272833",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590093921954435072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093920725528576",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093906653298688",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590093898768343041",
   "Positive": "0.971758246421814",
   "Negative": "0.006676382385194302",
   "Neutral": "0.0060572694055736065",
   "Mixed": "0.015508079901337624"
 },
 {
   "tweet_id": "1590093877498609665",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590093869177528320",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590093866434465792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093862659203072",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590093839934844928",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590093822473949186",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590093809844908033",
   "Positive": "0.013995103538036346",
   "Negative": "0.139348566532135",
   "Neutral": "0.03717751055955887",
   "Mixed": "0.809478759765625"
 },
 {
   "tweet_id": "1590093803561418752",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093782279933953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093774856019968",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590093771932569600",
   "Positive": "0.0008209507213905454",
   "Negative": "0.8078412413597107",
   "Neutral": "0.19128338992595673",
   "Mixed": "5.440507811727002e-05"
 },
 {
   "tweet_id": "1590093757579677697",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590093753397968897",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590093747068735488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590093742803136512",
   "Positive": "0.0038853378500789404",
   "Negative": "0.000276160251814872",
   "Neutral": "0.9958357810974121",
   "Mixed": "2.7254336600890383e-06"
 },
 {
   "tweet_id": "1590093722162991104",
   "Positive": "0.0016084500821307302",
   "Negative": "0.002306853188201785",
   "Neutral": "0.9960822463035583",
   "Mixed": "2.517674602131592e-06"
 },
 {
   "tweet_id": "1590093703410233344",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590093701749288960",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093689694871553",
   "Positive": "0.044993627816438675",
   "Negative": "0.00010588955046841875",
   "Neutral": "0.954145073890686",
   "Mixed": "0.0007553694304078817"
 },
 {
   "tweet_id": "1590093685554757632",
   "Positive": "0.021435266360640526",
   "Negative": "0.010221519507467747",
   "Neutral": "0.9683278799057007",
   "Mixed": "1.5306683053495362e-05"
 },
 {
   "tweet_id": "1590093669193109504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590093667301150720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590093654529802241",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590093633734455296",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590093617871613952",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590093614981722112",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093614096715776",
   "Positive": "0.0013730628415942192",
   "Negative": "0.870419979095459",
   "Neutral": "0.12769994139671326",
   "Mixed": "0.0005069684702903032"
 },
 {
   "tweet_id": "1590093605297074176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590093600314257409",
   "Positive": "0.03904302045702934",
   "Negative": "0.26394104957580566",
   "Neutral": "0.517657458782196",
   "Mixed": "0.17935843765735626"
 },
 {
   "tweet_id": "1590093586036453376",
   "Positive": "0.9952806830406189",
   "Negative": "5.3724961617263034e-05",
   "Neutral": "0.004635240882635117",
   "Mixed": "3.0275963581516407e-05"
 },
 {
   "tweet_id": "1590093508656128000",
   "Positive": "0.7492506504058838",
   "Negative": "0.10254587978124619",
   "Neutral": "0.10226251929998398",
   "Mixed": "0.04594101011753082"
 },
 {
   "tweet_id": "1590093501672607745",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093480613007361",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093445875798017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093431682273280",
   "Positive": "0.008810325525701046",
   "Negative": "0.009783963672816753",
   "Neutral": "0.9813217520713806",
   "Mixed": "8.403821266256273e-05"
 },
 {
   "tweet_id": "1590093430147149825",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590093419736887297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093419409395713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590093413180854272",
   "Positive": "0.010432550683617592",
   "Negative": "0.014440241269767284",
   "Neutral": "0.9751012921333313",
   "Mixed": "2.59570952039212e-05"
 },
 {
   "tweet_id": "1590093407778574337",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093373138169856",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093360018370560",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590093340154163201",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590093288576778242",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590093283551629312",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590093238232567808",
   "Positive": "0.0028987389523535967",
   "Negative": "0.5356526970863342",
   "Neutral": "0.46142032742500305",
   "Mixed": "2.8242204280104488e-05"
 },
 {
   "tweet_id": "1590093232482181120",
   "Positive": "0.0029794787988066673",
   "Negative": "0.00013386458158493042",
   "Neutral": "0.9968818426132202",
   "Mixed": "4.915155386697734e-06"
 },
 {
   "tweet_id": "1590093183891148801",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093154350686209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590093052932415488",
   "Positive": "0.8889092206954956",
   "Negative": "0.0013112417655065656",
   "Neutral": "0.10968215763568878",
   "Mixed": "9.734011109685525e-05"
 },
 {
   "tweet_id": "1590093052609056768",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590093048691597312",
   "Positive": "0.9952032566070557",
   "Negative": "7.920079224277288e-05",
   "Neutral": "0.0046964180655777454",
   "Mixed": "2.1074669348308817e-05"
 },
 {
   "tweet_id": "1590093029746302976",
   "Positive": "0.01036197692155838",
   "Negative": "0.00019967069965787232",
   "Neutral": "0.9894329905509949",
   "Mixed": "5.26201665707049e-06"
 },
 {
   "tweet_id": "1590093025782341637",
   "Positive": "0.9860596060752869",
   "Negative": "0.0008214730187319219",
   "Neutral": "0.013041612692177296",
   "Mixed": "7.736923726042733e-05"
 },
 {
   "tweet_id": "1590092979251081218",
   "Positive": "0.00044622342102229595",
   "Negative": "6.04458327870816e-05",
   "Neutral": "0.9994845390319824",
   "Mixed": "8.812914529698901e-06"
 },
 {
   "tweet_id": "1590092952201666561",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590092951429865472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590092931716681729",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590092922896015360",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590092917892612097",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590092893213294592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092881964183552",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1590092851664199681",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092848963076102",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1590092837533937664",
   "Positive": "0.10713449120521545",
   "Negative": "0.028750380501151085",
   "Neutral": "0.8640563488006592",
   "Mixed": "5.880815297132358e-05"
 },
 {
   "tweet_id": "1590092829388177408",
   "Positive": "0.014927893877029419",
   "Negative": "0.14249257743358612",
   "Neutral": "0.8424328565597534",
   "Mixed": "0.0001465839013690129"
 },
 {
   "tweet_id": "1590092815408992256",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092814993719298",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590092808651943936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092799877476352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590092799654785024",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590092793271455744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092785293885440",
   "Positive": "0.04635386914014816",
   "Negative": "0.005251097492873669",
   "Neutral": "0.9483822584152222",
   "Mixed": "1.2742144463118166e-05"
 },
 {
   "tweet_id": "1590092755295907840",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092735843020800",
   "Positive": "0.2976013123989105",
   "Negative": "0.10325367003679276",
   "Neutral": "0.3999345600605011",
   "Mixed": "0.1992105096578598"
 },
 {
   "tweet_id": "1590092735104835584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092724840984576",
   "Positive": "0.8633788824081421",
   "Negative": "0.0013290452770888805",
   "Neutral": "0.13506335020065308",
   "Mixed": "0.00022880325559526682"
 },
 {
   "tweet_id": "1590092713021489152",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092710459113473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092700560547843",
   "Positive": "0.8968300819396973",
   "Negative": "0.001501397113315761",
   "Neutral": "0.10159814357757568",
   "Mixed": "7.044201629469171e-05"
 },
 {
   "tweet_id": "1590092697666457600",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092682793455616",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590092676938207234",
   "Positive": "0.00791638158261776",
   "Negative": "0.00017369263514410704",
   "Neutral": "0.9919037222862244",
   "Mixed": "6.2507137954526115e-06"
 },
 {
   "tweet_id": "1590092662711156737",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1590092652027920384",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590092611699806208",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1590092581006082049",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590092556616204290",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590092553298137088",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092543693557760",
   "Positive": "0.8974940776824951",
   "Negative": "0.006250806152820587",
   "Neutral": "0.09621137380599976",
   "Mixed": "4.3741696572396904e-05"
 },
 {
   "tweet_id": "1590092540841455616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092530582183936",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590092516837117952",
   "Positive": "0.0014242242323234677",
   "Negative": "0.00013825877977069467",
   "Neutral": "0.9984320998191833",
   "Mixed": "5.378321475291159e-06"
 },
 {
   "tweet_id": "1590092487120809984",
   "Positive": "0.406686007976532",
   "Negative": "0.0007866136147640646",
   "Neutral": "0.5924981236457825",
   "Mixed": "2.927527930296492e-05"
 },
 {
   "tweet_id": "1590092484830720001",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590092449221083136",
   "Positive": "0.0036349354777485132",
   "Negative": "0.07955421507358551",
   "Neutral": "0.9166507124900818",
   "Mixed": "0.00016016930749174207"
 },
 {
   "tweet_id": "1590092401217261568",
   "Positive": "0.004131809808313847",
   "Negative": "0.0015544703928753734",
   "Neutral": "0.9943100214004517",
   "Mixed": "3.6956269013899146e-06"
 },
 {
   "tweet_id": "1590092400386805760",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092397345525761",
   "Positive": "0.41027235984802246",
   "Negative": "0.03225197643041611",
   "Neutral": "0.5059539079666138",
   "Mixed": "0.0515216663479805"
 },
 {
   "tweet_id": "1590092384603607040",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590092383701852161",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092372163334147",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092369000820736",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590092367717355521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590092367008526336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590092346703872000",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092345672073218",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590092343251767296",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092275811745793",
   "Positive": "0.032512035220861435",
   "Negative": "0.006269413977861404",
   "Neutral": "0.9610979557037354",
   "Mixed": "0.00012061261077178642"
 },
 {
   "tweet_id": "1590092259114242048",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590092254756368385",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590092253644873728",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590092249635098625",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590092242420568064",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590092234514632704",
   "Positive": "0.0030355455819517374",
   "Negative": "0.08644220232963562",
   "Neutral": "0.9101935625076294",
   "Mixed": "0.00032861315412446856"
 },
 {
   "tweet_id": "1590092227505950722",
   "Positive": "0.00043364582234062254",
   "Negative": "0.9108871817588806",
   "Neutral": "0.08866187185049057",
   "Mixed": "1.72037398442626e-05"
 },
 {
   "tweet_id": "1590092199252807680",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590092198842085376",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1590092175047786497",
   "Positive": "0.24890634417533875",
   "Negative": "0.00046742151607759297",
   "Neutral": "0.7505334615707397",
   "Mixed": "9.275471529690549e-05"
 },
 {
   "tweet_id": "1590092163878379521",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590092159168004099",
   "Positive": "0.4818163216114044",
   "Negative": "0.044648148119449615",
   "Neutral": "0.4733034074306488",
   "Mixed": "0.00023206707555800676"
 },
 {
   "tweet_id": "1590092155863052289",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590092085075808260",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590092077601546241",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590092039362084864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590092024006742017",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590092017274527744",
   "Positive": "0.7252143621444702",
   "Negative": "0.007839910686016083",
   "Neutral": "0.26655685901641846",
   "Mixed": "0.0003889102954417467"
 },
 {
   "tweet_id": "1590092006545448960",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1590091997267869696",
   "Positive": "0.02013034000992775",
   "Negative": "6.853060040157288e-05",
   "Neutral": "0.9797421097755432",
   "Mixed": "5.897664595977403e-05"
 },
 {
   "tweet_id": "1590091995351257088",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590091976317493249",
   "Positive": "0.12027063220739365",
   "Negative": "0.48575031757354736",
   "Neutral": "0.2530003488063812",
   "Mixed": "0.1409786492586136"
 },
 {
   "tweet_id": "1590091974450634753",
   "Positive": "0.4119287133216858",
   "Negative": "0.27934587001800537",
   "Neutral": "0.28580981492996216",
   "Mixed": "0.022915568202733994"
 },
 {
   "tweet_id": "1590091966213419009",
   "Positive": "0.440906822681427",
   "Negative": "0.007938708178699017",
   "Neutral": "0.5509447455406189",
   "Mixed": "0.00020971425692550838"
 },
 {
   "tweet_id": "1590091953542426625",
   "Positive": "0.0011032979236915708",
   "Negative": "0.04303199425339699",
   "Neutral": "0.955851674079895",
   "Mixed": "1.3007869711145759e-05"
 },
 {
   "tweet_id": "1590091936530305024",
   "Positive": "0.595837414264679",
   "Negative": "0.0038183480501174927",
   "Neutral": "0.4003274440765381",
   "Mixed": "1.6755026081227697e-05"
 },
 {
   "tweet_id": "1590091936119296000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590091916850651137",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590091906532356097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590091871111770112",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590091843014127616",
   "Positive": "0.11984514445066452",
   "Negative": "0.014788012020289898",
   "Neutral": "0.865229606628418",
   "Mixed": "0.00013720770948566496"
 },
 {
   "tweet_id": "1590091807853281280",
   "Positive": "0.05225195735692978",
   "Negative": "0.022765828296542168",
   "Neutral": "0.9247292280197144",
   "Mixed": "0.0002529379562474787"
 },
 {
   "tweet_id": "1590091783186567168",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590091747006480385",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590091714022498304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590091713720512512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590091701888372737",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590091690270142464",
   "Positive": "0.031320273876190186",
   "Negative": "0.6225554347038269",
   "Neutral": "0.34557515382766724",
   "Mixed": "0.00054909911705181"
 },
 {
   "tweet_id": "1590091666110971904",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590091640105971712",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590091614059659264",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590091599031455745",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590091598280654848",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590091597294600193",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590091577010946048",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590091575602085889",
   "Positive": "0.000244516646489501",
   "Negative": "0.9955958724021912",
   "Neutral": "0.004110196605324745",
   "Mixed": "4.950517541146837e-05"
 },
 {
   "tweet_id": "1590091573702053888",
   "Positive": "0.0006830256897956133",
   "Negative": "0.6850150227546692",
   "Neutral": "0.31419265270233154",
   "Mixed": "0.00010936479520751163"
 },
 {
   "tweet_id": "1590091546442899456",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590091543272357889",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1590091529884160001",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590091462104223744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590091451324825600",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590091447906500610",
   "Positive": "0.004584070295095444",
   "Negative": "0.00030222057830542326",
   "Neutral": "0.9951068162918091",
   "Mixed": "6.881641184008913e-06"
 },
 {
   "tweet_id": "1590091392940146688",
   "Positive": "0.12111722677946091",
   "Negative": "0.12205041944980621",
   "Neutral": "0.6768408417701721",
   "Mixed": "0.07999156415462494"
 },
 {
   "tweet_id": "1590091391425687552",
   "Positive": "0.0014235101407393813",
   "Negative": "0.00045626406790688634",
   "Neutral": "0.99811851978302",
   "Mixed": "1.7711982991386321e-06"
 },
 {
   "tweet_id": "1590091382026162177",
   "Positive": "0.01478142011910677",
   "Negative": "0.0013731002109125257",
   "Neutral": "0.9838359355926514",
   "Mixed": "9.588019565853756e-06"
 },
 {
   "tweet_id": "1590091375961600000",
   "Positive": "0.00112785748206079",
   "Negative": "7.493633165722713e-05",
   "Neutral": "0.9987886548042297",
   "Mixed": "8.559788511774968e-06"
 },
 {
   "tweet_id": "1590091374996918273",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590091323843174400",
   "Positive": "0.4513321816921234",
   "Negative": "0.15815071761608124",
   "Neutral": "0.20924688875675201",
   "Mixed": "0.18127025663852692"
 },
 {
   "tweet_id": "1590091318969393152",
   "Positive": "0.0015184713993221521",
   "Negative": "0.00034565175883471966",
   "Neutral": "0.9981313347816467",
   "Mixed": "4.537041604635306e-06"
 },
 {
   "tweet_id": "1590091296706002944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590091281556189184",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590091280646041600",
   "Positive": "0.028271712362766266",
   "Negative": "0.37311360239982605",
   "Neutral": "0.5985044836997986",
   "Mixed": "0.000110171917185653"
 },
 {
   "tweet_id": "1590091247565185027",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590091229517467648",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590091213335822338",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590091202728144896",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1590091194562154496",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590091190728536064",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590091159984308227",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1590091154334572544",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590091111548133376",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590091081860775936",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590091057697411072",
   "Positive": "0.003486284986138344",
   "Negative": "0.022724119946360588",
   "Neutral": "0.9736921191215515",
   "Mixed": "9.752644109539688e-05"
 },
 {
   "tweet_id": "1590091036994732033",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590090992363114496",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590090980937502721",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590090923287150592",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590090918694043648",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590090906392485889",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590090887585214464",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090864483000320",
   "Positive": "0.16144156455993652",
   "Negative": "0.2836006283760071",
   "Neutral": "0.5536330938339233",
   "Mixed": "0.0013247444294393063"
 },
 {
   "tweet_id": "1590090861551194114",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590090857671450626",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590090847118585856",
   "Positive": "0.026274176314473152",
   "Negative": "0.00012643045920412987",
   "Neutral": "0.9734253883361816",
   "Mixed": "0.00017401274817530066"
 },
 {
   "tweet_id": "1590090837211623425",
   "Positive": "0.0006008964846841991",
   "Negative": "0.0005016528302803636",
   "Neutral": "0.9988825917243958",
   "Mixed": "1.4924644347047433e-05"
 },
 {
   "tweet_id": "1590090828755931136",
   "Positive": "0.18698398768901825",
   "Negative": "0.09139532595872879",
   "Neutral": "0.7215875387191772",
   "Mixed": "3.308059967821464e-05"
 },
 {
   "tweet_id": "1590090827493056512",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090827275309057",
   "Positive": "0.9797446727752686",
   "Negative": "0.00029678017017431557",
   "Neutral": "0.019924262538552284",
   "Mixed": "3.430405195103958e-05"
 },
 {
   "tweet_id": "1590090826163826688",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090823907291136",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090807922503681",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590090806844862464",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590090783197388800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090772204113921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090763316363264",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090759423684608",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590090730663743490",
   "Positive": "0.2096886932849884",
   "Negative": "0.006958184763789177",
   "Neutral": "0.7822701334953308",
   "Mixed": "0.0010830697137862444"
 },
 {
   "tweet_id": "1590090727325052928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090721268490240",
   "Positive": "0.010432550683617592",
   "Negative": "0.014440241269767284",
   "Neutral": "0.9751012921333313",
   "Mixed": "2.59570952039212e-05"
 },
 {
   "tweet_id": "1590090692822732800",
   "Positive": "0.0016759454738348722",
   "Negative": "0.0007431113626807928",
   "Neutral": "0.997576892375946",
   "Mixed": "4.0560917113907635e-06"
 },
 {
   "tweet_id": "1590090679509979136",
   "Positive": "0.0014817363116890192",
   "Negative": "0.0009792852215468884",
   "Neutral": "0.997536301612854",
   "Mixed": "2.5660549454187276e-06"
 },
 {
   "tweet_id": "1590090671838621697",
   "Positive": "0.0018123560585081577",
   "Negative": "0.9779880046844482",
   "Neutral": "0.020163962617516518",
   "Mixed": "3.5679153370438144e-05"
 },
 {
   "tweet_id": "1590090669212995584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090663475187714",
   "Positive": "0.007106043864041567",
   "Negative": "0.5707629323005676",
   "Neutral": "0.42142659425735474",
   "Mixed": "0.000704469159245491"
 },
 {
   "tweet_id": "1590090655199801346",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090628972826624",
   "Positive": "0.0013702523428946733",
   "Negative": "0.0010549728758633137",
   "Neutral": "0.9975717663764954",
   "Mixed": "2.92393087875098e-06"
 },
 {
   "tweet_id": "1590090595540033536",
   "Positive": "0.3080236613750458",
   "Negative": "0.0004389115783851594",
   "Neutral": "0.6914686560630798",
   "Mixed": "6.87403226038441e-05"
 },
 {
   "tweet_id": "1590090594629865472",
   "Positive": "0.0007177996449172497",
   "Negative": "0.05364161357283592",
   "Neutral": "0.9456179738044739",
   "Mixed": "2.2607835489907302e-05"
 },
 {
   "tweet_id": "1590090585570185217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590090533724004352",
   "Positive": "0.006142743397504091",
   "Negative": "0.0017512594349682331",
   "Neutral": "0.9921002984046936",
   "Mixed": "5.66360586162773e-06"
 },
 {
   "tweet_id": "1590090528669900800",
   "Positive": "0.00474529480561614",
   "Negative": "0.3952585756778717",
   "Neutral": "0.5975017547607422",
   "Mixed": "0.002494401764124632"
 },
 {
   "tweet_id": "1590090521233326080",
   "Positive": "0.07445904612541199",
   "Negative": "0.005962729454040527",
   "Neutral": "0.8977345824241638",
   "Mixed": "0.02184360660612583"
 },
 {
   "tweet_id": "1590090518876209152",
   "Positive": "0.0015385940205305815",
   "Negative": "0.0002851626486517489",
   "Neutral": "0.9981725215911865",
   "Mixed": "3.669317493404378e-06"
 },
 {
   "tweet_id": "1590090509712003074",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090479374585856",
   "Positive": "0.4336405396461487",
   "Negative": "0.0026112347841262817",
   "Neutral": "0.5636224150657654",
   "Mixed": "0.00012584567593876272"
 },
 {
   "tweet_id": "1590090428589969408",
   "Positive": "0.01017037034034729",
   "Negative": "0.02795221656560898",
   "Neutral": "0.9618431329727173",
   "Mixed": "3.433912934269756e-05"
 },
 {
   "tweet_id": "1590090420087709697",
   "Positive": "0.2502996325492859",
   "Negative": "0.014151062816381454",
   "Neutral": "0.735509991645813",
   "Mixed": "3.93161790270824e-05"
 },
 {
   "tweet_id": "1590090376450539520",
   "Positive": "0.00474529480561614",
   "Negative": "0.3952585756778717",
   "Neutral": "0.5975017547607422",
   "Mixed": "0.002494401764124632"
 },
 {
   "tweet_id": "1590090370880507909",
   "Positive": "0.00038116725045256317",
   "Negative": "0.0008890860481187701",
   "Neutral": "0.9987264275550842",
   "Mixed": "3.3755779895727756e-06"
 },
 {
   "tweet_id": "1590090370213302272",
   "Positive": "0.0015460449503734708",
   "Negative": "9.428937482880428e-05",
   "Neutral": "0.9983554482460022",
   "Mixed": "4.219828497298295e-06"
 },
 {
   "tweet_id": "1590090367277621249",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590090363418869760",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590090339431645184",
   "Positive": "0.5199272632598877",
   "Negative": "0.08919092267751694",
   "Neutral": "0.3887826204299927",
   "Mixed": "0.0020991978235542774"
 },
 {
   "tweet_id": "1590090329717637121",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590090314232258560",
   "Positive": "0.07658392190933228",
   "Negative": "0.001596391317434609",
   "Neutral": "0.9204110503196716",
   "Mixed": "0.0014085698639973998"
 },
 {
   "tweet_id": "1590090309438173184",
   "Positive": "0.12319322675466537",
   "Negative": "0.0006547164521180093",
   "Neutral": "0.8760135173797607",
   "Mixed": "0.0001385621726512909"
 },
 {
   "tweet_id": "1590090305520664576",
   "Positive": "0.00474529480561614",
   "Negative": "0.3952585756778717",
   "Neutral": "0.5975017547607422",
   "Mixed": "0.002494401764124632"
 },
 {
   "tweet_id": "1590090299941933056",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590090259743723520",
   "Positive": "0.5042833685874939",
   "Negative": "0.04086100682616234",
   "Neutral": "0.3954505920410156",
   "Mixed": "0.059405047446489334"
 },
 {
   "tweet_id": "1590090259303657473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090256153341952",
   "Positive": "0.21489785611629486",
   "Negative": "0.0020067375153303146",
   "Neutral": "0.7830230593681335",
   "Mixed": "7.235841621877626e-05"
 },
 {
   "tweet_id": "1590090254266273793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090246016102400",
   "Positive": "0.11564826965332031",
   "Negative": "0.008524843491613865",
   "Neutral": "0.8757753372192383",
   "Mixed": "5.158708881936036e-05"
 },
 {
   "tweet_id": "1590090217910054912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590090209877581824",
   "Positive": "0.07396677881479263",
   "Negative": "0.6503616571426392",
   "Neutral": "0.27516859769821167",
   "Mixed": "0.000502944749314338"
 },
 {
   "tweet_id": "1590090198809214976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090196451590144",
   "Positive": "0.24890634417533875",
   "Negative": "0.00046742151607759297",
   "Neutral": "0.7505334615707397",
   "Mixed": "9.275471529690549e-05"
 },
 {
   "tweet_id": "1590090195613143044",
   "Positive": "0.148202583193779",
   "Negative": "0.05851614102721214",
   "Neutral": "0.7920622825622559",
   "Mixed": "0.0012189755216240883"
 },
 {
   "tweet_id": "1590090194333880322",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590090193750872064",
   "Positive": "0.004351872950792313",
   "Negative": "4.6505734644597396e-05",
   "Neutral": "0.995564877986908",
   "Mixed": "3.662432573037222e-05"
 },
 {
   "tweet_id": "1590090185509081088",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590090172833890304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090165275746304",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590090155364585474",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590090148217180160",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590090146732707840",
   "Positive": "0.006231376435607672",
   "Negative": "4.761413947562687e-05",
   "Neutral": "0.9936822652816772",
   "Mixed": "3.8776008295826614e-05"
 },
 {
   "tweet_id": "1590090135785582593",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090127027896321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590090124888768512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590090097239920641",
   "Positive": "0.013407949358224869",
   "Negative": "0.4363729953765869",
   "Neutral": "0.5392552018165588",
   "Mixed": "0.01096386183053255"
 },
 {
   "tweet_id": "1590090087832129541",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590090082593443842",
   "Positive": "0.28976893424987793",
   "Negative": "0.3026202619075775",
   "Neutral": "0.40395161509513855",
   "Mixed": "0.0036592260003089905"
 },
 {
   "tweet_id": "1590090063249313794",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590090061907136512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590090056521641984",
   "Positive": "0.011808013543486595",
   "Negative": "0.019575662910938263",
   "Neutral": "0.026686524972319603",
   "Mixed": "0.9419297575950623"
 },
 {
   "tweet_id": "1590090053174579201",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590090035751444481",
   "Positive": "0.0015184713993221521",
   "Negative": "0.00034565175883471966",
   "Neutral": "0.9981313347816467",
   "Mixed": "4.537041604635306e-06"
 },
 {
   "tweet_id": "1590090017321668609",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590090002289287168",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089989626679297",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590089986682294272",
   "Positive": "0.37562263011932373",
   "Negative": "0.00040132098365575075",
   "Neutral": "0.6239481568336487",
   "Mixed": "2.789640893752221e-05"
 },
 {
   "tweet_id": "1590089982219255809",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089978075574273",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590089948417646593",
   "Positive": "0.1622842252254486",
   "Negative": "0.05356559157371521",
   "Neutral": "0.3473444879055023",
   "Mixed": "0.43680569529533386"
 },
 {
   "tweet_id": "1590089872190353408",
   "Positive": "0.006745602004230022",
   "Negative": "0.3437690734863281",
   "Neutral": "0.6494062542915344",
   "Mixed": "7.902612560428679e-05"
 },
 {
   "tweet_id": "1590089866179534849",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089854821752832",
   "Positive": "0.02358551137149334",
   "Negative": "0.0005701067857444286",
   "Neutral": "0.9758351445198059",
   "Mixed": "9.141973350779153e-06"
 },
 {
   "tweet_id": "1590089843111243776",
   "Positive": "0.07566910982131958",
   "Negative": "0.01415045466274023",
   "Neutral": "0.9099516868591309",
   "Mixed": "0.00022873279522173107"
 },
 {
   "tweet_id": "1590089837834809344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590089828498296832",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590089826153693184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590089817685393409",
   "Positive": "0.0006008964846841991",
   "Negative": "0.0005016528302803636",
   "Neutral": "0.9988825917243958",
   "Mixed": "1.4924644347047433e-05"
 },
 {
   "tweet_id": "1590089814891986945",
   "Positive": "0.001414015656337142",
   "Negative": "0.0021551831159740686",
   "Neutral": "0.9964174032211304",
   "Mixed": "1.3398078408499714e-05"
 },
 {
   "tweet_id": "1590089772277825536",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590089759598444544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590089748261244928",
   "Positive": "0.004212549421936274",
   "Negative": "0.00017925354768522084",
   "Neutral": "0.9956034421920776",
   "Mixed": "4.729963166028028e-06"
 },
 {
   "tweet_id": "1590089724839964677",
   "Positive": "0.0016759454738348722",
   "Negative": "0.0007431113626807928",
   "Neutral": "0.997576892375946",
   "Mixed": "4.0560917113907635e-06"
 },
 {
   "tweet_id": "1590089719542845440",
   "Positive": "0.018866287544369698",
   "Negative": "0.0018518995493650436",
   "Neutral": "0.9792714715003967",
   "Mixed": "1.0276607099513058e-05"
 },
 {
   "tweet_id": "1590089719287001088",
   "Positive": "0.5159252285957336",
   "Negative": "0.0022757446859031916",
   "Neutral": "0.39906835556030273",
   "Mixed": "0.082730732858181"
 },
 {
   "tweet_id": "1590089715159429120",
   "Positive": "0.17197740077972412",
   "Negative": "0.0003825297753792256",
   "Neutral": "0.8276141285896301",
   "Mixed": "2.5915973310475238e-05"
 },
 {
   "tweet_id": "1590089710718025728",
   "Positive": "0.4101855158805847",
   "Negative": "0.014183390885591507",
   "Neutral": "0.5732278227806091",
   "Mixed": "0.0024032939691096544"
 },
 {
   "tweet_id": "1590089710592229377",
   "Positive": "0.00020674278493970633",
   "Negative": "0.0008364631212316453",
   "Neutral": "0.9989538192749023",
   "Mixed": "2.975356210299651e-06"
 },
 {
   "tweet_id": "1590089705898803200",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590089700978876417",
   "Positive": "0.0019388938089832664",
   "Negative": "0.00010712639050325379",
   "Neutral": "0.9979442954063416",
   "Mixed": "9.59927183430409e-06"
 },
 {
   "tweet_id": "1590089678761230336",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089676953911296",
   "Positive": "0.31498101353645325",
   "Negative": "0.0010631493059918284",
   "Neutral": "0.6839120388031006",
   "Mixed": "4.3760010157711804e-05"
 },
 {
   "tweet_id": "1590089676639338497",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089673132900353",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1590089657848860673",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1590089637321592832",
   "Positive": "0.03225008398294449",
   "Negative": "0.0002622564497869462",
   "Neutral": "0.9674541354179382",
   "Mixed": "3.360783739481121e-05"
 },
 {
   "tweet_id": "1590089626349236224",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590089605080313856",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1590089558322184193",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590089510779768834",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089506417672192",
   "Positive": "0.13881634175777435",
   "Negative": "0.0003737299412023276",
   "Neutral": "0.8598601222038269",
   "Mixed": "0.0009498459403403103"
 },
 {
   "tweet_id": "1590089463979728897",
   "Positive": "0.0016873368294909596",
   "Negative": "0.00020142430730629712",
   "Neutral": "0.9981083869934082",
   "Mixed": "2.8859797112090746e-06"
 },
 {
   "tweet_id": "1590089443943534592",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590089430450438144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590089419524300801",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590089405389500417",
   "Positive": "0.001761758467182517",
   "Negative": "0.38838836550712585",
   "Neutral": "0.6098152995109558",
   "Mixed": "3.469049988780171e-05"
 },
 {
   "tweet_id": "1590089397944213504",
   "Positive": "0.022541293874382973",
   "Negative": "0.0017212434904649854",
   "Neutral": "0.9754495024681091",
   "Mixed": "0.00028804890462197363"
 },
 {
   "tweet_id": "1590089392835637248",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089374691360770",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590089367036792833",
   "Positive": "0.24634313583374023",
   "Negative": "0.1212550699710846",
   "Neutral": "0.6285523176193237",
   "Mixed": "0.003849427681416273"
 },
 {
   "tweet_id": "1590089366210510848",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089360816640000",
   "Positive": "0.9300937056541443",
   "Negative": "6.635140016442165e-05",
   "Neutral": "0.0698058232665062",
   "Mixed": "3.423748057684861e-05"
 },
 {
   "tweet_id": "1590089360254595072",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590089322681995264",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590089298493046784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590089298417659904",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590089267665330177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590089233011994624",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590089216104730627",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590089214053429248",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590089171435401217",
   "Positive": "0.03735203668475151",
   "Negative": "0.09769180417060852",
   "Neutral": "0.4946816861629486",
   "Mixed": "0.37027445435523987"
 },
 {
   "tweet_id": "1590089150270967808",
   "Positive": "0.0027605711948126554",
   "Negative": "0.0014581114519387484",
   "Neutral": "0.9957743287086487",
   "Mixed": "6.991479040152626e-06"
 },
 {
   "tweet_id": "1590089149339795458",
   "Positive": "0.29829680919647217",
   "Negative": "0.00939432717859745",
   "Neutral": "0.692176878452301",
   "Mixed": "0.00013193523045629263"
 },
 {
   "tweet_id": "1590089131798892545",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590089123649716224",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590089112102797313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590089103835508736",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590089068259708928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590089046252216320",
   "Positive": "0.5195014476776123",
   "Negative": "0.0004507314588408917",
   "Neutral": "0.480015367269516",
   "Mixed": "3.2448762794956565e-05"
 },
 {
   "tweet_id": "1590089045623083010",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590089043311988736",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590089019097985024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590089017307312128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088998289956864",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590088992514797568",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1590088989494517760",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1590088965490900992",
   "Positive": "0.022485295310616493",
   "Negative": "0.00023132820206228644",
   "Neutral": "0.9772771596908569",
   "Mixed": "6.206103535078e-06"
 },
 {
   "tweet_id": "1590088965205372928",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1590088913607008256",
   "Positive": "0.9906178116798401",
   "Negative": "3.50624650309328e-05",
   "Neutral": "0.009298084303736687",
   "Mixed": "4.9009864596882835e-05"
 },
 {
   "tweet_id": "1590088907995381760",
   "Positive": "0.9797446727752686",
   "Negative": "0.00029678017017431557",
   "Neutral": "0.019924262538552284",
   "Mixed": "3.430405195103958e-05"
 },
 {
   "tweet_id": "1590088878962376705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088866891206658",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088831562575872",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590088811035299840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088809865412608",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590088809630220289",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590088799371300864",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088783059652611",
   "Positive": "0.07225877046585083",
   "Negative": "0.0011448952136561275",
   "Neutral": "0.9265497326850891",
   "Mixed": "4.661988714360632e-05"
 },
 {
   "tweet_id": "1590088771982483457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088767129649152",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590088754949414912",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590088746204295168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088742013837312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088725517963265",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590088717133578240",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590088714902212608",
   "Positive": "0.004018597770482302",
   "Negative": "0.07208501547574997",
   "Neutral": "0.9237743020057678",
   "Mixed": "0.00012199821503600106"
 },
 {
   "tweet_id": "1590088692277792768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088643418677249",
   "Positive": "0.9550536870956421",
   "Negative": "0.003913940396159887",
   "Neutral": "0.04094145819544792",
   "Mixed": "9.08933361642994e-05"
 },
 {
   "tweet_id": "1590088630156288000",
   "Positive": "0.15137603878974915",
   "Negative": "0.27806633710861206",
   "Neutral": "0.5520014762878418",
   "Mixed": "0.018556149676442146"
 },
 {
   "tweet_id": "1590088621054644225",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590088613022535680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088595918196736",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590088580839649280",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590088570865274880",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590088557997064193",
   "Positive": "0.00914717372506857",
   "Negative": "0.0014841792872175574",
   "Neutral": "0.9893637895584106",
   "Mixed": "4.848066964768805e-06"
 },
 {
   "tweet_id": "1590088536652677120",
   "Positive": "0.029747530817985535",
   "Negative": "0.001618336420506239",
   "Neutral": "0.9686065316200256",
   "Mixed": "2.759059498202987e-05"
 },
 {
   "tweet_id": "1590088521070804993",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590088520042881026",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590088499788943360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088493942050816",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590088484240633856",
   "Positive": "0.00038402577047236264",
   "Negative": "0.0001465672394260764",
   "Neutral": "0.9994656443595886",
   "Mixed": "3.7585959944408387e-06"
 },
 {
   "tweet_id": "1590088462673514497",
   "Positive": "0.5941316485404968",
   "Negative": "0.004740556702017784",
   "Neutral": "0.40094420313835144",
   "Mixed": "0.00018356894725002348"
 },
 {
   "tweet_id": "1590088452934336512",
   "Positive": "0.15069516003131866",
   "Negative": "0.005132677964866161",
   "Neutral": "0.8435306549072266",
   "Mixed": "0.0006414363742806017"
 },
 {
   "tweet_id": "1590088438011006978",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590088431283343360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088425813979136",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590088414455828480",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590088392737714177",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590088375348125696",
   "Positive": "0.008945359848439693",
   "Negative": "0.014624054543673992",
   "Neutral": "0.976423442363739",
   "Mixed": "7.106933935574489e-06"
 },
 {
   "tweet_id": "1590088366011617280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088362920411136",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590088362916184065",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590088361926348800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590088342586109952",
   "Positive": "0.0002209942467743531",
   "Negative": "0.06148915737867355",
   "Neutral": "0.9382756948471069",
   "Mixed": "1.412046913173981e-05"
 },
 {
   "tweet_id": "1590088330862944257",
   "Positive": "0.0018719722284004092",
   "Negative": "0.15850698947906494",
   "Neutral": "0.8267142176628113",
   "Mixed": "0.012906770221889019"
 },
 {
   "tweet_id": "1590088330192252928",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590088313104658434",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590088297275363328",
   "Positive": "0.004683826584368944",
   "Negative": "0.2176838219165802",
   "Neutral": "0.7776095867156982",
   "Mixed": "2.2804799300502054e-05"
 },
 {
   "tweet_id": "1590088272214396928",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590088227217895424",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1590088220163072000",
   "Positive": "0.35081034898757935",
   "Negative": "0.04278848320245743",
   "Neutral": "0.6061580777168274",
   "Mixed": "0.00024305609986186028"
 },
 {
   "tweet_id": "1590088160385433602",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590088146112630784",
   "Positive": "0.0016084500821307302",
   "Negative": "0.002306853188201785",
   "Neutral": "0.9960822463035583",
   "Mixed": "2.517674602131592e-06"
 },
 {
   "tweet_id": "1590088117809467392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590088114416267265",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590088113736806402",
   "Positive": "0.0022171884775161743",
   "Negative": "0.0008304693619720638",
   "Neutral": "0.9969478249549866",
   "Mixed": "4.569836164591834e-06"
 },
 {
   "tweet_id": "1590088113732616194",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590088113543839744",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590088108691034113",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590088105482424320",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590088080769548288",
   "Positive": "0.8839239478111267",
   "Negative": "0.050670791417360306",
   "Neutral": "0.05633910745382309",
   "Mixed": "0.009066114202141762"
 },
 {
   "tweet_id": "1590088052629975040",
   "Positive": "0.050366297364234924",
   "Negative": "0.00018565591017249972",
   "Neutral": "0.9494339823722839",
   "Mixed": "1.4057016414881218e-05"
 },
 {
   "tweet_id": "1590088050318905344",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590088039342411776",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590088017393377282",
   "Positive": "0.0299249067902565",
   "Negative": "0.00011767488467739895",
   "Neutral": "0.9696747660636902",
   "Mixed": "0.0002826076524797827"
 },
 {
   "tweet_id": "1590088001925058561",
   "Positive": "0.018622396513819695",
   "Negative": "0.3547041118144989",
   "Neutral": "0.6261816024780273",
   "Mixed": "0.0004919464117847383"
 },
 {
   "tweet_id": "1590087996250128384",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590087972493602817",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590087948099555328",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087885143044097",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590087869867393027",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590087864909717504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590087856483340290",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590087842524696576",
   "Positive": "0.010578945279121399",
   "Negative": "0.6281149983406067",
   "Neutral": "0.35861948132514954",
   "Mixed": "0.002686625812202692"
 },
 {
   "tweet_id": "1590087829069365249",
   "Positive": "0.017021803185343742",
   "Negative": "0.0001801676262402907",
   "Neutral": "0.9827911257743835",
   "Mixed": "6.963759915379342e-06"
 },
 {
   "tweet_id": "1590087828557680640",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590087821720956930",
   "Positive": "0.06875091046094894",
   "Negative": "0.037154123187065125",
   "Neutral": "0.864193856716156",
   "Mixed": "0.029901085421442986"
 },
 {
   "tweet_id": "1590087816775487488",
   "Positive": "0.5813286304473877",
   "Negative": "0.0422537736594677",
   "Neutral": "0.3757942020893097",
   "Mixed": "0.0006233513122424483"
 },
 {
   "tweet_id": "1590087801990967296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590087798568415233",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087734001274881",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1590087729676976128",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590087722198511616",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590087714933645312",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087713662717952",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087704678920192",
   "Positive": "0.020375624299049377",
   "Negative": "9.672524174675345e-05",
   "Neutral": "0.9795076251029968",
   "Mixed": "2.005905844271183e-05"
 },
 {
   "tweet_id": "1590087695304646656",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590087693811212290",
   "Positive": "0.38518214225769043",
   "Negative": "0.0021612662822008133",
   "Neutral": "0.6125319600105286",
   "Mixed": "0.00012461186270229518"
 },
 {
   "tweet_id": "1590087676903899136",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590087634721722368",
   "Positive": "0.0015184713993221521",
   "Negative": "0.00034565175883471966",
   "Neutral": "0.9981313347816467",
   "Mixed": "4.537041604635306e-06"
 },
 {
   "tweet_id": "1590087628807753728",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590087600512983040",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590087599166697472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590087589251649536",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590087587175477250",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590087580301021184",
   "Positive": "0.0030328803695738316",
   "Negative": "5.429244629340246e-05",
   "Neutral": "0.9968944787979126",
   "Mixed": "1.834485010476783e-05"
 },
 {
   "tweet_id": "1590087533077331968",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590087523212349440",
   "Positive": "0.054226625710725784",
   "Negative": "0.001466033048927784",
   "Neutral": "0.9436403512954712",
   "Mixed": "0.0006669568247161806"
 },
 {
   "tweet_id": "1590087506934267905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590087483299332096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590087477414735872",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1590087455059111937",
   "Positive": "0.9896214008331299",
   "Negative": "0.00012170228001195937",
   "Neutral": "0.010247060097754002",
   "Mixed": "9.819868864724413e-06"
 },
 {
   "tweet_id": "1590087451355533314",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590087449392578561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590087445701279744",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087441372700672",
   "Positive": "0.011402523145079613",
   "Negative": "0.00016721284191589803",
   "Neutral": "0.9884234070777893",
   "Mixed": "6.857713287899969e-06"
 },
 {
   "tweet_id": "1590087435350069248",
   "Positive": "0.019356777891516685",
   "Negative": "0.09424630552530289",
   "Neutral": "0.8863117098808289",
   "Mixed": "8.512283966410905e-05"
 },
 {
   "tweet_id": "1590087432124309504",
   "Positive": "0.0019080600468441844",
   "Negative": "0.000502602371852845",
   "Neutral": "0.9975857734680176",
   "Mixed": "3.533794597387896e-06"
 },
 {
   "tweet_id": "1590087430778290176",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590087429578690560",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590087418405089281",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590087407453765632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590087404677132288",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590087374184538112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590087374125473792",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590087360053940224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590087315182977025",
   "Positive": "0.003175422316417098",
   "Negative": "0.00022316022659651935",
   "Neutral": "0.9965906143188477",
   "Mixed": "1.0811755601025652e-05"
 },
 {
   "tweet_id": "1590087300033437697",
   "Positive": "0.010771524161100388",
   "Negative": "0.05114589259028435",
   "Neutral": "0.07100566476583481",
   "Mixed": "0.8670769333839417"
 },
 {
   "tweet_id": "1590087266743242752",
   "Positive": "0.09116829186677933",
   "Negative": "0.002828296972438693",
   "Neutral": "0.008821140974760056",
   "Mixed": "0.8971823453903198"
 },
 {
   "tweet_id": "1590087257993932801",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590087225114779648",
   "Positive": "0.8031270503997803",
   "Negative": "0.0006388731417246163",
   "Neutral": "0.1961655467748642",
   "Mixed": "6.857530388515443e-05"
 },
 {
   "tweet_id": "1590087205925842944",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590087203610558465",
   "Positive": "0.007491877768188715",
   "Negative": "4.406688458402641e-05",
   "Neutral": "0.9924283623695374",
   "Mixed": "3.578102041501552e-05"
 },
 {
   "tweet_id": "1590087202599424000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590087175445811202",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590087122177781760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590087118763995136",
   "Positive": "0.20346364378929138",
   "Negative": "0.00018153608834836632",
   "Neutral": "0.7962357401847839",
   "Mixed": "0.00011915831419173628"
 },
 {
   "tweet_id": "1590087117145018369",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590087105262542848",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590087081094971392",
   "Positive": "0.025992518290877342",
   "Negative": "0.01614951901137829",
   "Neutral": "0.956187903881073",
   "Mixed": "0.0016699940897524357"
 },
 {
   "tweet_id": "1590087072576331776",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590087062732304384",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590087062279294978",
   "Positive": "0.007497597485780716",
   "Negative": "0.5956789255142212",
   "Neutral": "0.39675766229629517",
   "Mixed": "6.582041532965377e-05"
 },
 {
   "tweet_id": "1590087054666649600",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590087041496526850",
   "Positive": "0.390741765499115",
   "Negative": "0.0003447318740654737",
   "Neutral": "0.6088664531707764",
   "Mixed": "4.6926852519391105e-05"
 },
 {
   "tweet_id": "1590087033065611264",
   "Positive": "0.5856696367263794",
   "Negative": "0.24753937125205994",
   "Neutral": "0.16224446892738342",
   "Mixed": "0.004546539857983589"
 },
 {
   "tweet_id": "1590087023901102081",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590087020046561281",
   "Positive": "0.0006008964846841991",
   "Negative": "0.0005016528302803636",
   "Neutral": "0.9988825917243958",
   "Mixed": "1.4924644347047433e-05"
 },
 {
   "tweet_id": "1590086996277735424",
   "Positive": "0.9262032508850098",
   "Negative": "0.002163407625630498",
   "Neutral": "0.0708923265337944",
   "Mixed": "0.0007410405669361353"
 },
 {
   "tweet_id": "1590086990573473792",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590086961100132354",
   "Positive": "0.0015184713993221521",
   "Negative": "0.00034565175883471966",
   "Neutral": "0.9981313347816467",
   "Mixed": "4.537041604635306e-06"
 },
 {
   "tweet_id": "1590086958415773696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590086951440642048",
   "Positive": "0.0690636932849884",
   "Negative": "0.00848586205393076",
   "Neutral": "0.9223742485046387",
   "Mixed": "7.616175571456552e-05"
 },
 {
   "tweet_id": "1590086951050555392",
   "Positive": "0.18688669800758362",
   "Negative": "0.02128712832927704",
   "Neutral": "0.056829534471035004",
   "Mixed": "0.7349966168403625"
 },
 {
   "tweet_id": "1590086946403258368",
   "Positive": "0.43056946992874146",
   "Negative": "0.0009804262081161141",
   "Neutral": "0.5680325627326965",
   "Mixed": "0.00041754572885110974"
 },
 {
   "tweet_id": "1590086933262528512",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590086928288067584",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590086910382596096",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590086904753848321",
   "Positive": "0.10536980628967285",
   "Negative": "0.006937788799405098",
   "Neutral": "0.8876563310623169",
   "Mixed": "3.607841063058004e-05"
 },
 {
   "tweet_id": "1590086900467265536",
   "Positive": "0.001006101374514401",
   "Negative": "0.0007468693656846881",
   "Neutral": "0.9982314705848694",
   "Mixed": "1.5590307157253847e-05"
 },
 {
   "tweet_id": "1590086897862201344",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590086888446365697",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590086882498871296",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590086881722912768",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590086864895356928",
   "Positive": "0.0013469443656504154",
   "Negative": "6.130651308922097e-05",
   "Neutral": "0.9985529780387878",
   "Mixed": "3.885475598508492e-05"
 },
 {
   "tweet_id": "1590086855126843394",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086808209354753",
   "Positive": "0.0018024900928139687",
   "Negative": "0.00012580223847180605",
   "Neutral": "0.9980613589286804",
   "Mixed": "1.029697432386456e-05"
 },
 {
   "tweet_id": "1590086792006729728",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086789800550400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590086787686621184",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590086765766901760",
   "Positive": "0.02811008132994175",
   "Negative": "0.005725644063204527",
   "Neutral": "0.9659363031387329",
   "Mixed": "0.0002279596374137327"
 },
 {
   "tweet_id": "1590086765213261824",
   "Positive": "0.0025087588001042604",
   "Negative": "0.6416358351707458",
   "Neutral": "0.3554035723209381",
   "Mixed": "0.0004518604255281389"
 },
 {
   "tweet_id": "1590086764491902979",
   "Positive": "0.001298705697990954",
   "Negative": "0.00018708675634115934",
   "Neutral": "0.998511016368866",
   "Mixed": "3.157385663143941e-06"
 },
 {
   "tweet_id": "1590086759219482624",
   "Positive": "0.0003568872925825417",
   "Negative": "0.24542692303657532",
   "Neutral": "0.7541443705558777",
   "Mixed": "7.17997900210321e-05"
 },
 {
   "tweet_id": "1590086758703603714",
   "Positive": "0.6722787618637085",
   "Negative": "0.06893554329872131",
   "Neutral": "0.25851836800575256",
   "Mixed": "0.00026733684353530407"
 },
 {
   "tweet_id": "1590086752878088192",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590086717285216258",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086716110819330",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590086667528208384",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590086657646428160",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086634384809984",
   "Positive": "0.0010510128922760487",
   "Negative": "0.05047674849629402",
   "Neutral": "0.9484572410583496",
   "Mixed": "1.5076199815666769e-05"
 },
 {
   "tweet_id": "1590086621009182721",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590086620430360576",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590086596535398400",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086589367341056",
   "Positive": "0.8263427019119263",
   "Negative": "0.0017656446434557438",
   "Neutral": "0.17158234119415283",
   "Mixed": "0.0003093107370659709"
 },
 {
   "tweet_id": "1590086578444959745",
   "Positive": "0.01406211219727993",
   "Negative": "5.6910343118943274e-05",
   "Neutral": "0.9858428835868835",
   "Mixed": "3.812920840573497e-05"
 },
 {
   "tweet_id": "1590086573059559424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590086553313120256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590086539719376896",
   "Positive": "0.010829707607626915",
   "Negative": "0.11176181584596634",
   "Neutral": "0.17917890846729279",
   "Mixed": "0.6982296109199524"
 },
 {
   "tweet_id": "1590086537815134208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590086506588143618",
   "Positive": "0.0017614191165193915",
   "Negative": "0.00010761005250969902",
   "Neutral": "0.9981227517127991",
   "Mixed": "8.25402639748063e-06"
 },
 {
   "tweet_id": "1590086487881953280",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590086474321776640",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590086458345345024",
   "Positive": "0.10535725206136703",
   "Negative": "0.005811994429677725",
   "Neutral": "0.8881375789642334",
   "Mixed": "0.0006931425305083394"
 },
 {
   "tweet_id": "1590086457523572739",
   "Positive": "0.4560004472732544",
   "Negative": "0.0011295184958726168",
   "Neutral": "0.542824923992157",
   "Mixed": "4.509632344706915e-05"
 },
 {
   "tweet_id": "1590086447574704129",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590086444953272320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590086437327671296",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086414234185728",
   "Positive": "0.30791711807250977",
   "Negative": "0.00011089603503933176",
   "Neutral": "0.6917746663093567",
   "Mixed": "0.0001972886238945648"
 },
 {
   "tweet_id": "1590086399751249921",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590086386383671298",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590086370634403840",
   "Positive": "0.0008818354108370841",
   "Negative": "8.789698040345684e-05",
   "Neutral": "0.9989732503890991",
   "Mixed": "5.701865302398801e-05"
 },
 {
   "tweet_id": "1590086356893855745",
   "Positive": "8.15344974398613e-05",
   "Negative": "0.0163788590580225",
   "Neutral": "0.9835220575332642",
   "Mixed": "1.7599873899598606e-05"
 },
 {
   "tweet_id": "1590086345455988737",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590086341634953217",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590086327814365184",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590086325692399617",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590086317568061441",
   "Positive": "0.0020799420308321714",
   "Negative": "0.5085200071334839",
   "Neutral": "0.4589385688304901",
   "Mixed": "0.030461467802524567"
 },
 {
   "tweet_id": "1590086314778832899",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1590086302656974848",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590086294482604033",
   "Positive": "0.00042531732469797134",
   "Negative": "0.5106366872787476",
   "Neutral": "0.48890814185142517",
   "Mixed": "2.978431984956842e-05"
 },
 {
   "tweet_id": "1590086277717975040",
   "Positive": "0.0007407402154058218",
   "Negative": "0.004804952535778284",
   "Neutral": "0.9944506883621216",
   "Mixed": "3.673182845886913e-06"
 },
 {
   "tweet_id": "1590086275469803520",
   "Positive": "0.0003312215267214924",
   "Negative": "0.011788765899837017",
   "Neutral": "0.9878688454627991",
   "Mixed": "1.1182532944076229e-05"
 },
 {
   "tweet_id": "1590086258570952704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590086256050180096",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590086255051694082",
   "Positive": "0.001906343037262559",
   "Negative": "0.00013368637883104384",
   "Neutral": "0.9979559183120728",
   "Mixed": "4.072492174600484e-06"
 },
 {
   "tweet_id": "1590086251344195584",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590086242313842688",
   "Positive": "0.020943352952599525",
   "Negative": "0.00011887435539392754",
   "Neutral": "0.9789199829101562",
   "Mixed": "1.7908754671225324e-05"
 },
 {
   "tweet_id": "1590086234650861568",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086214543376385",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590086206238298112",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590086196654657536",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590086155890196480",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590086131093508098",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590086105533415424",
   "Positive": "0.20333026349544525",
   "Negative": "9.732937905937433e-05",
   "Neutral": "0.7964920401573181",
   "Mixed": "8.028859156183898e-05"
 },
 {
   "tweet_id": "1590086011627139073",
   "Positive": "0.07750441133975983",
   "Negative": "0.003902714466676116",
   "Neutral": "0.9184978604316711",
   "Mixed": "9.50090034166351e-05"
 },
 {
   "tweet_id": "1590085994485010433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590085987346288640",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590085982237650944",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590085968542851072",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590085966147964928",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590085962742501376",
   "Positive": "0.00015799907851032913",
   "Negative": "0.0009327539592050016",
   "Neutral": "0.9989007711410522",
   "Mixed": "8.458189768134616e-06"
 },
 {
   "tweet_id": "1590085955125641217",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590085947928240128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590085944363069440",
   "Positive": "0.8812301754951477",
   "Negative": "0.0006252621533349156",
   "Neutral": "0.11799789220094681",
   "Mixed": "0.00014663831098005176"
 },
 {
   "tweet_id": "1590085942530150402",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590085926583037952",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085907365113856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590085903707303937",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590085878587994112",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590085878223089664",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590085870136471553",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1590085864935534592",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590085852180647936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590085834262577152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590085795926650880",
   "Positive": "0.03471031039953232",
   "Negative": "0.02292977273464203",
   "Neutral": "0.9423372745513916",
   "Mixed": "2.266601222800091e-05"
 },
 {
   "tweet_id": "1590085764226125825",
   "Positive": "0.007815849035978317",
   "Negative": "0.8308895230293274",
   "Neutral": "0.159305140376091",
   "Mixed": "0.0019895415753126144"
 },
 {
   "tweet_id": "1590085762082803712",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085740670881793",
   "Positive": "0.002678049262613058",
   "Negative": "0.0766531229019165",
   "Neutral": "0.032568901777267456",
   "Mixed": "0.8880999088287354"
 },
 {
   "tweet_id": "1590085740272418817",
   "Positive": "0.8799458146095276",
   "Negative": "0.0006416098331101239",
   "Neutral": "0.11938533186912537",
   "Mixed": "2.7140667953062803e-05"
 },
 {
   "tweet_id": "1590085736816340992",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1590085730248036353",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590085730059313152",
   "Positive": "0.003860287833958864",
   "Negative": "0.8957686424255371",
   "Neutral": "0.05882090702652931",
   "Mixed": "0.04155027121305466"
 },
 {
   "tweet_id": "1590085720542416898",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1590085707170996224",
   "Positive": "0.04929378256201744",
   "Negative": "0.024135401472449303",
   "Neutral": "0.9262628555297852",
   "Mixed": "0.0003079335147049278"
 },
 {
   "tweet_id": "1590085700900491264",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085689743638532",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590085672559202304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590085666292973573",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590085654758625280",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1590085649851617282",
   "Positive": "0.25243476033210754",
   "Negative": "0.004131842404603958",
   "Neutral": "0.7420275211334229",
   "Mixed": "0.0014058295637369156"
 },
 {
   "tweet_id": "1590085634890567680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590085623544963074",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085591575642112",
   "Positive": "0.0030218136962503195",
   "Negative": "0.00021362035477068275",
   "Neutral": "0.9967370629310608",
   "Mixed": "2.7390746254241094e-05"
 },
 {
   "tweet_id": "1590085565285662720",
   "Positive": "0.262197345495224",
   "Negative": "0.00237862067297101",
   "Neutral": "0.7353954911231995",
   "Mixed": "2.8589814974111505e-05"
 },
 {
   "tweet_id": "1590085547607076864",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085502153412608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590085500177612808",
   "Positive": "0.07771816849708557",
   "Negative": "0.00523349130526185",
   "Neutral": "0.9169148206710815",
   "Mixed": "0.00013347776257432997"
 },
 {
   "tweet_id": "1590085476764901376",
   "Positive": "0.01936815306544304",
   "Negative": "0.00042064301669597626",
   "Neutral": "0.9801949262619019",
   "Mixed": "1.6294148736051284e-05"
 },
 {
   "tweet_id": "1590085454770364416",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590085451591086080",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085438924279808",
   "Positive": "0.6900147795677185",
   "Negative": "0.0007219729595817626",
   "Neutral": "0.30854684114456177",
   "Mixed": "0.0007164945709519088"
 },
 {
   "tweet_id": "1590085414844796928",
   "Positive": "0.262197345495224",
   "Negative": "0.00237862067297101",
   "Neutral": "0.7353954911231995",
   "Mixed": "2.8589814974111505e-05"
 },
 {
   "tweet_id": "1590085362352726016",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590085353003954177",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085351418499072",
   "Positive": "0.22306524217128754",
   "Negative": "0.00041692389640957117",
   "Neutral": "0.7764907479286194",
   "Mixed": "2.7087446142104454e-05"
 },
 {
   "tweet_id": "1590085337753489408",
   "Positive": "0.011366178281605244",
   "Negative": "6.808532634750009e-05",
   "Neutral": "0.9885104298591614",
   "Mixed": "5.538708865060471e-05"
 },
 {
   "tweet_id": "1590085334637088768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590085317889249281",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590085293649952768",
   "Positive": "0.011402523145079613",
   "Negative": "0.00016721284191589803",
   "Neutral": "0.9884234070777893",
   "Mixed": "6.857713287899969e-06"
 },
 {
   "tweet_id": "1590085281583362048",
   "Positive": "0.0006461875746026635",
   "Negative": "0.05956501513719559",
   "Neutral": "0.9397721290588379",
   "Mixed": "1.6711526768631302e-05"
 },
 {
   "tweet_id": "1590085244874788864",
   "Positive": "0.7352586984634399",
   "Negative": "0.0049372888170182705",
   "Neutral": "0.2596094608306885",
   "Mixed": "0.0001944476243807003"
 },
 {
   "tweet_id": "1590085237392179200",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085230844862465",
   "Positive": "0.021697012707591057",
   "Negative": "0.00017726985970512033",
   "Neutral": "0.9780822992324829",
   "Mixed": "4.332347816671245e-05"
 },
 {
   "tweet_id": "1590085198284083200",
   "Positive": "0.04797406122088432",
   "Negative": "0.0001214491348946467",
   "Neutral": "0.9518299698829651",
   "Mixed": "7.449788972735405e-05"
 },
 {
   "tweet_id": "1590085178528972800",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590085168081293312",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085143217115136",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590085138104610816",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1590085135101136896",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590085098321645568",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085047369232403",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590085044391260160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590085023474257922",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590085002502762496",
   "Positive": "0.020028453320264816",
   "Negative": "0.00037453259574249387",
   "Neutral": "0.9782695770263672",
   "Mixed": "0.0013274839147925377"
 },
 {
   "tweet_id": "1590084996143816704",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590084980033875970",
   "Positive": "0.050894688814878464",
   "Negative": "0.00010053982259705663",
   "Neutral": "0.9489842653274536",
   "Mixed": "2.0490659153438173e-05"
 },
 {
   "tweet_id": "1590084966821789696",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084962648088576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084927475036160",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084922819375104",
   "Positive": "0.0007407402154058218",
   "Negative": "0.004804952535778284",
   "Neutral": "0.9944506883621216",
   "Mixed": "3.673182845886913e-06"
 },
 {
   "tweet_id": "1590084905043898368",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590084895841611776",
   "Positive": "0.03281917795538902",
   "Negative": "0.0051125590689480305",
   "Neutral": "0.9619484543800354",
   "Mixed": "0.00011987451580353081"
 },
 {
   "tweet_id": "1590084895053086720",
   "Positive": "0.003992464859038591",
   "Negative": "0.00018064329924527556",
   "Neutral": "0.9958211183547974",
   "Mixed": "5.836544460180448e-06"
 },
 {
   "tweet_id": "1590084884457869313",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590084837368483841",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084831438073856",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590084827990339585",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590084817336807425",
   "Positive": "0.010517925955355167",
   "Negative": "0.0010586417047306895",
   "Neutral": "0.9884103536605835",
   "Mixed": "1.3069936358078849e-05"
 },
 {
   "tweet_id": "1590084799301312512",
   "Positive": "0.012951330281794071",
   "Negative": "0.0007926250691525638",
   "Neutral": "0.9862484335899353",
   "Mixed": "7.697536602790933e-06"
 },
 {
   "tweet_id": "1590084765150982146",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084760675635202",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084725833490432",
   "Positive": "0.0005155634717084467",
   "Negative": "0.00013170340389478952",
   "Neutral": "0.9993491768836975",
   "Mixed": "3.5723887776839547e-06"
 },
 {
   "tweet_id": "1590084708418785280",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590084686604558336",
   "Positive": "0.001006101374514401",
   "Negative": "0.0007468693656846881",
   "Neutral": "0.9982314705848694",
   "Mixed": "1.5590307157253847e-05"
 },
 {
   "tweet_id": "1590084678761213955",
   "Positive": "0.023416558280587196",
   "Negative": "0.0072426400147378445",
   "Neutral": "0.9692837595939636",
   "Mixed": "5.708829121431336e-05"
 },
 {
   "tweet_id": "1590084670368407552",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590084661216440320",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590084643130585089",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590084641708740608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084617486626816",
   "Positive": "0.002207373734563589",
   "Negative": "8.698149758856744e-05",
   "Neutral": "0.9976997971534729",
   "Mixed": "5.8319060372014064e-06"
 },
 {
   "tweet_id": "1590084609030881280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084601376309249",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590084593629401088",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590084560695758849",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590084553192124417",
   "Positive": "0.00081767508527264",
   "Negative": "7.848826498957351e-05",
   "Neutral": "0.9990924596786499",
   "Mixed": "1.1489807548059616e-05"
 },
 {
   "tweet_id": "1590084501048524801",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084496439005185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590084486255235072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590084485982302208",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590084469959950340",
   "Positive": "0.00058762077242136",
   "Negative": "0.00012927614443469793",
   "Neutral": "0.9992783665657043",
   "Mixed": "4.7743733375682496e-06"
 },
 {
   "tweet_id": "1590084465866706945",
   "Positive": "0.0005064912256784737",
   "Negative": "0.0019100746139883995",
   "Neutral": "0.9975782036781311",
   "Mixed": "5.224039341555908e-06"
 },
 {
   "tweet_id": "1590084424414408704",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590084420106473478",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590084419985235968",
   "Positive": "0.048206180334091187",
   "Negative": "0.07223396003246307",
   "Neutral": "0.6297786831855774",
   "Mixed": "0.24978117644786835"
 },
 {
   "tweet_id": "1590084409239408640",
   "Positive": "0.2416628897190094",
   "Negative": "0.18339216709136963",
   "Neutral": "0.538170576095581",
   "Mixed": "0.036774344742298126"
 },
 {
   "tweet_id": "1590084400796303360",
   "Positive": "0.016168875619769096",
   "Negative": "0.00026743116904981434",
   "Neutral": "0.9835542440414429",
   "Mixed": "9.528729606245179e-06"
 },
 {
   "tweet_id": "1590084398669393920",
   "Positive": "0.056392375379800797",
   "Negative": "0.10873144865036011",
   "Neutral": "0.8346092104911804",
   "Mixed": "0.00026690479717217386"
 },
 {
   "tweet_id": "1590084389027057664",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590084361411756032",
   "Positive": "0.26158416271209717",
   "Negative": "0.0046845488250255585",
   "Neutral": "0.7334733009338379",
   "Mixed": "0.0002579319116193801"
 },
 {
   "tweet_id": "1590084337382612993",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590084333863202816",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590084333007962112",
   "Positive": "0.9781410694122314",
   "Negative": "0.00017336582823190838",
   "Neutral": "0.0216586384922266",
   "Mixed": "2.6918816729448736e-05"
 },
 {
   "tweet_id": "1590084310958497792",
   "Positive": "0.0007407402154058218",
   "Negative": "0.004804952535778284",
   "Neutral": "0.9944506883621216",
   "Mixed": "3.673182845886913e-06"
 },
 {
   "tweet_id": "1590084305082265601",
   "Positive": "0.10616353154182434",
   "Negative": "0.016707146540284157",
   "Neutral": "0.8770724534988403",
   "Mixed": "5.687120938091539e-05"
 },
 {
   "tweet_id": "1590084296496549889",
   "Positive": "0.7442614436149597",
   "Negative": "0.002512796316295862",
   "Neutral": "0.2529844343662262",
   "Mixed": "0.00024131483223754913"
 },
 {
   "tweet_id": "1590084296047742976",
   "Positive": "0.0016247929306700826",
   "Negative": "0.02261653169989586",
   "Neutral": "0.9757450222969055",
   "Mixed": "1.366479045827873e-05"
 },
 {
   "tweet_id": "1590084285335470080",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590084269120290816",
   "Positive": "0.4560004472732544",
   "Negative": "0.0011295184958726168",
   "Neutral": "0.542824923992157",
   "Mixed": "4.509632344706915e-05"
 },
 {
   "tweet_id": "1590084241181708288",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590084236400205824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590084229391847424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590084220776779777",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590084220399255553",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590084218868027392",
   "Positive": "0.7216961979866028",
   "Negative": "0.00027487409533932805",
   "Neutral": "0.2778659164905548",
   "Mixed": "0.00016309462080243975"
 },
 {
   "tweet_id": "1590084212438073345",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590084212203585536",
   "Positive": "0.35136446356773376",
   "Negative": "0.0011146782198920846",
   "Neutral": "0.6474706530570984",
   "Mixed": "5.027955921832472e-05"
 },
 {
   "tweet_id": "1590084209896722433",
   "Positive": "0.006468344945460558",
   "Negative": "0.9004858136177063",
   "Neutral": "0.08741898089647293",
   "Mixed": "0.005626884754747152"
 },
 {
   "tweet_id": "1590084205936988161",
   "Positive": "0.0015978344017639756",
   "Negative": "0.00013984469114802778",
   "Neutral": "0.9982591271400452",
   "Mixed": "3.2586228826403385e-06"
 },
 {
   "tweet_id": "1590084177822896131",
   "Positive": "0.7660580277442932",
   "Negative": "0.001061209011822939",
   "Neutral": "0.23002088069915771",
   "Mixed": "0.0028598508797585964"
 },
 {
   "tweet_id": "1590084156159299585",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084142632685570",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590084142238425088",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590084115163856896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084109434769408",
   "Positive": "0.14742566645145416",
   "Negative": "0.006195429712533951",
   "Neutral": "0.8462158441543579",
   "Mixed": "0.00016304408200085163"
 },
 {
   "tweet_id": "1590084081458741248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590084073816739841",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590084069429510144",
   "Positive": "0.10891065001487732",
   "Negative": "0.07849808037281036",
   "Neutral": "0.8124470114707947",
   "Mixed": "0.00014434577315114439"
 },
 {
   "tweet_id": "1590084068494147584",
   "Positive": "0.000390845030779019",
   "Negative": "0.00017176619439851493",
   "Neutral": "0.999433696269989",
   "Mixed": "3.6402141176949954e-06"
 },
 {
   "tweet_id": "1590084053256278016",
   "Positive": "0.3126584589481354",
   "Negative": "0.0007237662794068456",
   "Neutral": "0.6865731477737427",
   "Mixed": "4.463165896595456e-05"
 },
 {
   "tweet_id": "1590084050550595584",
   "Positive": "0.06732214987277985",
   "Negative": "0.0006386934546753764",
   "Neutral": "0.9264954924583435",
   "Mixed": "0.005543654318898916"
 },
 {
   "tweet_id": "1590084034096693248",
   "Positive": "0.6615252494812012",
   "Negative": "0.060110386461019516",
   "Neutral": "0.2665472626686096",
   "Mixed": "0.011817093938589096"
 },
 {
   "tweet_id": "1590084027448709121",
   "Positive": "0.01678360439836979",
   "Negative": "0.3773241937160492",
   "Neutral": "0.6020612716674805",
   "Mixed": "0.0038309653755277395"
 },
 {
   "tweet_id": "1590084003167543297",
   "Positive": "0.9107910990715027",
   "Negative": "0.00011154020467074588",
   "Neutral": "0.08902610838413239",
   "Mixed": "7.129408186301589e-05"
 },
 {
   "tweet_id": "1590083998294110208",
   "Positive": "0.013764887116849422",
   "Negative": "0.0015373305650427938",
   "Neutral": "0.9846327304840088",
   "Mixed": "6.508086516987532e-05"
 },
 {
   "tweet_id": "1590083985631506432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590083969768251392",
   "Positive": "0.18308421969413757",
   "Negative": "0.0008289273828268051",
   "Neutral": "0.8160703182220459",
   "Mixed": "1.653882281971164e-05"
 },
 {
   "tweet_id": "1590083969365573632",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590083958955728897",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590083954534936577",
   "Positive": "0.5541945099830627",
   "Negative": "0.003560709534212947",
   "Neutral": "0.44063901901245117",
   "Mixed": "0.0016058131586760283"
 },
 {
   "tweet_id": "1590083927313874944",
   "Positive": "0.835861086845398",
   "Negative": "0.00013159272202756256",
   "Neutral": "0.1639431118965149",
   "Mixed": "6.42205704934895e-05"
 },
 {
   "tweet_id": "1590083926520799232",
   "Positive": "0.0004554609186016023",
   "Negative": "0.00017163385928142816",
   "Neutral": "0.9993687272071838",
   "Mixed": "4.121343863516813e-06"
 },
 {
   "tweet_id": "1590083912814198785",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590083909278367744",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590083896330575872",
   "Positive": "0.004606435541063547",
   "Negative": "0.000219303896301426",
   "Neutral": "0.9951704144477844",
   "Mixed": "3.7540858102147467e-06"
 },
 {
   "tweet_id": "1590083886234554370",
   "Positive": "0.6981273889541626",
   "Negative": "0.000804692623205483",
   "Neutral": "0.3009272813796997",
   "Mixed": "0.0001406271185260266"
 },
 {
   "tweet_id": "1590083874033655813",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590083843838865408",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590083836226187264",
   "Positive": "0.15499232709407806",
   "Negative": "0.23605117201805115",
   "Neutral": "0.6065899133682251",
   "Mixed": "0.0023665851913392544"
 },
 {
   "tweet_id": "1590083785093021696",
   "Positive": "0.3649260699748993",
   "Negative": "0.0005549827474169433",
   "Neutral": "0.6343738436698914",
   "Mixed": "0.00014506038860417902"
 },
 {
   "tweet_id": "1590083774951608320",
   "Positive": "0.9911181330680847",
   "Negative": "0.00022225378779694438",
   "Neutral": "0.00864334125071764",
   "Mixed": "1.6249730833806098e-05"
 },
 {
   "tweet_id": "1590083772858662912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083740789014528",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590083731414712321",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590083721126121473",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590083712917860353",
   "Positive": "0.0016247929306700826",
   "Negative": "0.02261653169989586",
   "Neutral": "0.9757450222969055",
   "Mixed": "1.366479045827873e-05"
 },
 {
   "tweet_id": "1590083707813363714",
   "Positive": "0.0538569875061512",
   "Negative": "0.00399582227692008",
   "Neutral": "0.9421345591545105",
   "Mixed": "1.2612304999493062e-05"
 },
 {
   "tweet_id": "1590083705137430528",
   "Positive": "0.4062184691429138",
   "Negative": "0.010238963179290295",
   "Neutral": "0.5834094285964966",
   "Mixed": "0.00013310609210748225"
 },
 {
   "tweet_id": "1590083702360780802",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083668227559424",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590083653572300800",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590083650145910786",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590083645896765440",
   "Positive": "0.6870253682136536",
   "Negative": "0.005043690092861652",
   "Neutral": "0.30778396129608154",
   "Mixed": "0.00014693036791868508"
 },
 {
   "tweet_id": "1590083626963984384",
   "Positive": "0.057126663625240326",
   "Negative": "0.0011737426975741982",
   "Neutral": "0.9416805505752563",
   "Mixed": "1.9051434719585814e-05"
 },
 {
   "tweet_id": "1590083621863317505",
   "Positive": "0.10704106837511063",
   "Negative": "0.15169917047023773",
   "Neutral": "0.6038728356361389",
   "Mixed": "0.13738691806793213"
 },
 {
   "tweet_id": "1590083620643172354",
   "Positive": "0.00043372673098929226",
   "Negative": "0.8937838077545166",
   "Neutral": "0.10576311498880386",
   "Mixed": "1.9260534827481024e-05"
 },
 {
   "tweet_id": "1590083604079865856",
   "Positive": "0.7250454425811768",
   "Negative": "0.00029368483228608966",
   "Neutral": "0.2745281755924225",
   "Mixed": "0.0001327200880041346"
 },
 {
   "tweet_id": "1590083549298053120",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590083544650747904",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590083504750358528",
   "Positive": "0.08040117472410202",
   "Negative": "0.013147784397006035",
   "Neutral": "0.9061837196350098",
   "Mixed": "0.00026733361301012337"
 },
 {
   "tweet_id": "1590083490078683138",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590083486383509505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083472827482112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083450710945793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590083425700315137",
   "Positive": "0.5180987119674683",
   "Negative": "0.010272035375237465",
   "Neutral": "0.47160324454307556",
   "Mixed": "2.604256951599382e-05"
 },
 {
   "tweet_id": "1590083424609796096",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590083413071253504",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590083356536221696",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1590083354174492672",
   "Positive": "0.02169688232243061",
   "Negative": "0.0016857872251421213",
   "Neutral": "0.9766018986701965",
   "Mixed": "1.54725512402365e-05"
 },
 {
   "tweet_id": "1590083347220680704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083345433567232",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590083339557298176",
   "Positive": "0.0015875725075602531",
   "Negative": "0.0005957406829111278",
   "Neutral": "0.9978145360946655",
   "Mixed": "2.1971970909362426e-06"
 },
 {
   "tweet_id": "1590083322948243457",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590083312336351232",
   "Positive": "0.006450821179896593",
   "Negative": "0.003179747611284256",
   "Neutral": "0.9903662204742432",
   "Mixed": "3.212261162843788e-06"
 },
 {
   "tweet_id": "1590083309681668097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590083309681643521",
   "Positive": "0.03942013904452324",
   "Negative": "0.15175430476665497",
   "Neutral": "0.805903971195221",
   "Mixed": "0.0029215198010206223"
 },
 {
   "tweet_id": "1590083279629479936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083220074561536",
   "Positive": "0.001247668289579451",
   "Negative": "0.10825872421264648",
   "Neutral": "0.8903848528862",
   "Mixed": "0.00010872495477087796"
 },
 {
   "tweet_id": "1590083184087404544",
   "Positive": "0.003624473698437214",
   "Negative": "0.187006413936615",
   "Neutral": "0.809347927570343",
   "Mixed": "2.1267045667627826e-05"
 },
 {
   "tweet_id": "1590083171152171008",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590083144807432192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083142874198016",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590083131801243650",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590083128353509378",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083120170438657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590083114058907648",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590083105276440576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590083102843731971",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590083101828743168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590083100771766274",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590083078042824705",
   "Positive": "0.0016247929306700826",
   "Negative": "0.02261653169989586",
   "Neutral": "0.9757450222969055",
   "Mixed": "1.366479045827873e-05"
 },
 {
   "tweet_id": "1590083072766410752",
   "Positive": "0.014314046129584312",
   "Negative": "0.6400694847106934",
   "Neutral": "0.34558627009391785",
   "Mixed": "3.0164550480549224e-05"
 },
 {
   "tweet_id": "1590083057541087234",
   "Positive": "0.5180987119674683",
   "Negative": "0.010272035375237465",
   "Neutral": "0.47160324454307556",
   "Mixed": "2.604256951599382e-05"
 },
 {
   "tweet_id": "1590083054500216832",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590083049722875905",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590083034547892224",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1590083032165552128",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590082990448967680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082990218301441",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590082985118019585",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590082973683982337",
   "Positive": "0.0429617315530777",
   "Negative": "0.033217690885066986",
   "Neutral": "0.9236623048782349",
   "Mixed": "0.0001582637632964179"
 },
 {
   "tweet_id": "1590082971180363776",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590082923377889282",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082919015813122",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082914422661122",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082907519225857",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590082877899026432",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590082876267429888",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082867983355904",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590082860849197056",
   "Positive": "0.0012159367324784398",
   "Negative": "0.04010065644979477",
   "Neutral": "0.958676278591156",
   "Mixed": "7.1865406425786205e-06"
 },
 {
   "tweet_id": "1590082857233711106",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590082855690211329",
   "Positive": "0.014829806983470917",
   "Negative": "0.0313616581261158",
   "Neutral": "0.03943534195423126",
   "Mixed": "0.9143732190132141"
 },
 {
   "tweet_id": "1590082843446632448",
   "Positive": "0.003905384335666895",
   "Negative": "0.8684030175209045",
   "Neutral": "0.09687144309282303",
   "Mixed": "0.030820239335298538"
 },
 {
   "tweet_id": "1590082830117502978",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590082753516933120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082750362845184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082748940955649",
   "Positive": "0.015487665310502052",
   "Negative": "0.000192709150724113",
   "Neutral": "0.9843118786811829",
   "Mixed": "7.709196324867662e-06"
 },
 {
   "tweet_id": "1590082716011110400",
   "Positive": "0.04420924559235573",
   "Negative": "0.12492899596691132",
   "Neutral": "0.6101690530776978",
   "Mixed": "0.22069264948368073"
 },
 {
   "tweet_id": "1590082712731549696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590082703386324993",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082685493735424",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082665365278720",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082660872769536",
   "Positive": "0.9806371927261353",
   "Negative": "0.00034795061219483614",
   "Neutral": "0.01900133304297924",
   "Mixed": "1.3539704013965093e-05"
 },
 {
   "tweet_id": "1590082654434885632",
   "Positive": "0.4191734492778778",
   "Negative": "0.0014826811384409666",
   "Neutral": "0.5788074135780334",
   "Mixed": "0.0005364399985410273"
 },
 {
   "tweet_id": "1590082652656537600",
   "Positive": "0.001100699882954359",
   "Negative": "0.4544447958469391",
   "Neutral": "0.5420018434524536",
   "Mixed": "0.0024526275228708982"
 },
 {
   "tweet_id": "1590082640061030400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082629272936448",
   "Positive": "0.7036436796188354",
   "Negative": "0.0012031301157549024",
   "Neutral": "0.2950775623321533",
   "Mixed": "7.557559001725167e-05"
 },
 {
   "tweet_id": "1590082616421908480",
   "Positive": "0.10264093428850174",
   "Negative": "0.1283925175666809",
   "Neutral": "0.7687876224517822",
   "Mixed": "0.00017890841991174966"
 },
 {
   "tweet_id": "1590082593042882561",
   "Positive": "0.0283866785466671",
   "Negative": "0.0002528962795622647",
   "Neutral": "0.9713529348373413",
   "Mixed": "7.557155640824931e-06"
 },
 {
   "tweet_id": "1590082587225370624",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082556267220992",
   "Positive": "0.7728766798973083",
   "Negative": "0.0017643546452745795",
   "Neutral": "0.22520481050014496",
   "Mixed": "0.0001541433302918449"
 },
 {
   "tweet_id": "1590082546439663616",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590082543432634368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590082536302317568",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590082474205663232",
   "Positive": "0.0049154553562402725",
   "Negative": "0.04301000013947487",
   "Neutral": "0.9520162343978882",
   "Mixed": "5.838024299009703e-05"
 },
 {
   "tweet_id": "1590082473714515968",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082467914219521",
   "Positive": "0.7058855891227722",
   "Negative": "0.006713018286973238",
   "Neutral": "0.28732359409332275",
   "Mixed": "7.784299668855965e-05"
 },
 {
   "tweet_id": "1590082432614604800",
   "Positive": "0.11312929540872574",
   "Negative": "0.01848846860229969",
   "Neutral": "0.8683168888092041",
   "Mixed": "6.536508590215817e-05"
 },
 {
   "tweet_id": "1590082421365805057",
   "Positive": "0.00013412314001470804",
   "Negative": "0.9959404468536377",
   "Neutral": "0.003910460043698549",
   "Mixed": "1.4950357581255957e-05"
 },
 {
   "tweet_id": "1590082414960775168",
   "Positive": "0.5551002025604248",
   "Negative": "0.0031155168544501066",
   "Neutral": "0.4403783082962036",
   "Mixed": "0.001406017574481666"
 },
 {
   "tweet_id": "1590082349462884352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082331318288385",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082287148097537",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590082275605385216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590082264766955521",
   "Positive": "0.001247668289579451",
   "Negative": "0.10825872421264648",
   "Neutral": "0.8903848528862",
   "Mixed": "0.00010872495477087796"
 },
 {
   "tweet_id": "1590082261197541376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082247608373253",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590082235264536576",
   "Positive": "0.8807020783424377",
   "Negative": "0.0051965778693556786",
   "Neutral": "0.11387491971254349",
   "Mixed": "0.00022637017536908388"
 },
 {
   "tweet_id": "1590082168835170304",
   "Positive": "0.19219645857810974",
   "Negative": "0.3709832429885864",
   "Neutral": "0.42340946197509766",
   "Mixed": "0.013410765677690506"
 },
 {
   "tweet_id": "1590082070193537025",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590082062119505921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082054041268225",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590082027445170176",
   "Positive": "0.012940577231347561",
   "Negative": "0.7701768279075623",
   "Neutral": "0.21457836031913757",
   "Mixed": "0.0023042818065732718"
 },
 {
   "tweet_id": "1590081977050624001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081962215362560",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590081933811519490",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590081916170293251",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081908687663104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590081907156713473",
   "Positive": "0.002105346182361245",
   "Negative": "0.00013051893620286137",
   "Neutral": "0.9977588653564453",
   "Mixed": "5.182545010029571e-06"
 },
 {
   "tweet_id": "1590081895773384706",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590081872260141056",
   "Positive": "0.006768844556063414",
   "Negative": "0.6818499565124512",
   "Neutral": "0.26183000206947327",
   "Mixed": "0.04955120384693146"
 },
 {
   "tweet_id": "1590081835983593473",
   "Positive": "0.7806294560432434",
   "Negative": "0.0003031390078831464",
   "Neutral": "0.21899929642677307",
   "Mixed": "6.813758955104277e-05"
 },
 {
   "tweet_id": "1590081819575480320",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590081800998903810",
   "Positive": "0.0006501612951979041",
   "Negative": "0.9469296336174011",
   "Neutral": "0.05197152867913246",
   "Mixed": "0.0004486084799282253"
 },
 {
   "tweet_id": "1590081772637032448",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590081764663648256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590081761899610112",
   "Positive": "0.03299399092793465",
   "Negative": "0.0008447934524156153",
   "Neutral": "0.9661523103713989",
   "Mixed": "8.892036930774339e-06"
 },
 {
   "tweet_id": "1590081741766918144",
   "Positive": "0.024715906009078026",
   "Negative": "0.6474270820617676",
   "Neutral": "0.3262760043144226",
   "Mixed": "0.0015810312470421195"
 },
 {
   "tweet_id": "1590081725954072577",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590081711722807296",
   "Positive": "0.49574708938598633",
   "Negative": "0.009777911007404327",
   "Neutral": "0.4561859369277954",
   "Mixed": "0.0382891446352005"
 },
 {
   "tweet_id": "1590081686288887808",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590081640558391296",
   "Positive": "0.5336453914642334",
   "Negative": "0.00039276425377465785",
   "Neutral": "0.4658331274986267",
   "Mixed": "0.00012871263606939465"
 },
 {
   "tweet_id": "1590081630621683712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081611022077952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590081610116116480",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590081608815894528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081607834435584",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590081604172468225",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081596794757120",
   "Positive": "0.0026172399520874023",
   "Negative": "0.014899257570505142",
   "Neutral": "0.9824746251106262",
   "Mixed": "8.879503184289206e-06"
 },
 {
   "tweet_id": "1590081594135433218",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590081574921703424",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590081542839148544",
   "Positive": "0.2738310694694519",
   "Negative": "0.0008220967720262706",
   "Neutral": "0.7252191305160522",
   "Mixed": "0.00012768608576152474"
 },
 {
   "tweet_id": "1590081534329262080",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590081517837225986",
   "Positive": "0.16774916648864746",
   "Negative": "0.03843769058585167",
   "Neutral": "0.7855815291404724",
   "Mixed": "0.008231574669480324"
 },
 {
   "tweet_id": "1590081503547256832",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081486950400000",
   "Positive": "0.024826090782880783",
   "Negative": "0.9351007342338562",
   "Neutral": "0.03992735594511032",
   "Mixed": "0.00014583133452106267"
 },
 {
   "tweet_id": "1590081485427855360",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590081450338320384",
   "Positive": "0.14461222290992737",
   "Negative": "0.01181353535503149",
   "Neutral": "0.8435441851615906",
   "Mixed": "3.0086643164395355e-05"
 },
 {
   "tweet_id": "1590081409133469696",
   "Positive": "0.6755528450012207",
   "Negative": "0.0019058848265558481",
   "Neutral": "0.31427282094955444",
   "Mixed": "0.008268434554338455"
 },
 {
   "tweet_id": "1590081397691416576",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590081387008126976",
   "Positive": "0.15715748071670532",
   "Negative": "0.0003773836069740355",
   "Neutral": "0.8424065709114075",
   "Mixed": "5.850290472153574e-05"
 },
 {
   "tweet_id": "1590081384760373248",
   "Positive": "0.03551320359110832",
   "Negative": "0.0003487234062049538",
   "Neutral": "0.9641284942626953",
   "Mixed": "9.707032404548954e-06"
 },
 {
   "tweet_id": "1590081347586248704",
   "Positive": "0.7350414991378784",
   "Negative": "0.0013463865034282207",
   "Neutral": "0.26096829771995544",
   "Mixed": "0.002643755404278636"
 },
 {
   "tweet_id": "1590081327743004672",
   "Positive": "0.9903049468994141",
   "Negative": "7.051714055705816e-05",
   "Neutral": "0.009588752873241901",
   "Mixed": "3.582762656151317e-05"
 },
 {
   "tweet_id": "1590081320369410049",
   "Positive": "0.8823704719543457",
   "Negative": "0.00034683721605688334",
   "Neutral": "0.11529047042131424",
   "Mixed": "0.001992188859730959"
 },
 {
   "tweet_id": "1590081310516973568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590081281660162049",
   "Positive": "0.8518894910812378",
   "Negative": "0.0002718436880968511",
   "Neutral": "0.147686168551445",
   "Mixed": "0.0001524659019196406"
 },
 {
   "tweet_id": "1590081280657719296",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1590081259954659328",
   "Positive": "0.0004142689285799861",
   "Negative": "0.00023119313118513674",
   "Neutral": "0.9993517994880676",
   "Mixed": "2.693223450478399e-06"
 },
 {
   "tweet_id": "1590081248344801280",
   "Positive": "0.000908593472559005",
   "Negative": "0.00047646547318436205",
   "Neutral": "0.998612642288208",
   "Mixed": "2.3052516553434543e-06"
 },
 {
   "tweet_id": "1590081239599689730",
   "Positive": "0.00046691298484802246",
   "Negative": "0.00028127187397331",
   "Neutral": "0.9992493987083435",
   "Mixed": "2.3577019874210237e-06"
 },
 {
   "tweet_id": "1590081230523224064",
   "Positive": "0.0003595543676055968",
   "Negative": "0.0005465710419230163",
   "Neutral": "0.9990918636322021",
   "Mixed": "2.081680122500984e-06"
 },
 {
   "tweet_id": "1590081218858536960",
   "Positive": "0.0003649163991212845",
   "Negative": "0.00017580125131644309",
   "Neutral": "0.9994563460350037",
   "Mixed": "3.023221097464557e-06"
 },
 {
   "tweet_id": "1590081214714519552",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590081205377982464",
   "Positive": "0.00032623723382130265",
   "Negative": "0.0003066700592171401",
   "Neutral": "0.9993647933006287",
   "Mixed": "2.247869360871846e-06"
 },
 {
   "tweet_id": "1590081196855545857",
   "Positive": "0.00027322827372699976",
   "Negative": "0.00030858174432069063",
   "Neutral": "0.9994156360626221",
   "Mixed": "2.565545173638384e-06"
 },
 {
   "tweet_id": "1590081196234780672",
   "Positive": "0.007438279688358307",
   "Negative": "0.0005090825725346804",
   "Neutral": "0.9920313358306885",
   "Mixed": "2.1279671273077838e-05"
 },
 {
   "tweet_id": "1590081187258986496",
   "Positive": "0.0004452388093341142",
   "Negative": "0.00025870976969599724",
   "Neutral": "0.9992936849594116",
   "Mixed": "2.369056119277957e-06"
 },
 {
   "tweet_id": "1590081179755368450",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590081175107706881",
   "Positive": "0.8031270503997803",
   "Negative": "0.0006388731417246163",
   "Neutral": "0.1961655467748642",
   "Mixed": "6.857530388515443e-05"
 },
 {
   "tweet_id": "1590081165309800448",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590081161136836608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590081159840792576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590081143709523968",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590081137572880385",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081134695968768",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590081124025651200",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590081116832026625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590081108963917824",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590081078060265472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590081072959983617",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590081062360973312",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590081025501466624",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590081020862533634",
   "Positive": "0.003162554232403636",
   "Negative": "0.013230745680630207",
   "Neutral": "0.9835969805717468",
   "Mixed": "9.739336746861227e-06"
 },
 {
   "tweet_id": "1590081008988090368",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590080982656647169",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590080958107389952",
   "Positive": "0.7522469758987427",
   "Negative": "0.011663168668746948",
   "Neutral": "0.23585832118988037",
   "Mixed": "0.0002314515004400164"
 },
 {
   "tweet_id": "1590080916407611395",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590080910589710336",
   "Positive": "0.10498793423175812",
   "Negative": "0.00364669575355947",
   "Neutral": "0.8909046649932861",
   "Mixed": "0.0004607540904544294"
 },
 {
   "tweet_id": "1590080904587710464",
   "Positive": "0.28286197781562805",
   "Negative": "0.039225343614816666",
   "Neutral": "0.6771039366722107",
   "Mixed": "0.0008088118047453463"
 },
 {
   "tweet_id": "1590080898841862144",
   "Positive": "0.019898731261491776",
   "Negative": "0.264443963766098",
   "Neutral": "0.12566477060317993",
   "Mixed": "0.5899925231933594"
 },
 {
   "tweet_id": "1590080898573434882",
   "Positive": "0.14864195883274078",
   "Negative": "0.006615334656089544",
   "Neutral": "0.8443517684936523",
   "Mixed": "0.0003909663646481931"
 },
 {
   "tweet_id": "1590080885063184384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590080880139472896",
   "Positive": "0.004236587323248386",
   "Negative": "0.037736039608716965",
   "Neutral": "0.9579940438270569",
   "Mixed": "3.335203291499056e-05"
 },
 {
   "tweet_id": "1590080860845637633",
   "Positive": "0.0015978344017639756",
   "Negative": "0.00013984469114802778",
   "Neutral": "0.9982591271400452",
   "Mixed": "3.2586228826403385e-06"
 },
 {
   "tweet_id": "1590080853262336000",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590080852884881409",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590080842184826880",
   "Positive": "0.8169563412666321",
   "Negative": "0.001462870161049068",
   "Neutral": "0.18153394758701324",
   "Mixed": "4.686224929173477e-05"
 },
 {
   "tweet_id": "1590080836258648064",
   "Positive": "0.37374547123908997",
   "Negative": "0.00014671693497803062",
   "Neutral": "0.6260328888893127",
   "Mixed": "7.486617687391117e-05"
 },
 {
   "tweet_id": "1590080824547758080",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590080783389462528",
   "Positive": "0.19219017028808594",
   "Negative": "0.22086869180202484",
   "Neutral": "0.5859746336936951",
   "Mixed": "0.0009665681282058358"
 },
 {
   "tweet_id": "1590080676359204865",
   "Positive": "0.9674394130706787",
   "Negative": "0.0025533263105899096",
   "Neutral": "0.028984809294342995",
   "Mixed": "0.001022408832795918"
 },
 {
   "tweet_id": "1590080660752191489",
   "Positive": "0.5211648344993591",
   "Negative": "0.0009010052308440208",
   "Neutral": "0.477864533662796",
   "Mixed": "6.962166662560776e-05"
 },
 {
   "tweet_id": "1590080653907079169",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590080648525803520",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590080612106661890",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590080590283681792",
   "Positive": "0.16099250316619873",
   "Negative": "0.0009442976443096995",
   "Neutral": "0.8380475640296936",
   "Mixed": "1.565003185532987e-05"
 },
 {
   "tweet_id": "1590080585569284096",
   "Positive": "0.0008864034898579121",
   "Negative": "0.00021360917889978737",
   "Neutral": "0.9988963603973389",
   "Mixed": "3.5647954064188525e-06"
 },
 {
   "tweet_id": "1590080582780096512",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590080550408441856",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590080539226411008",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590080521929097216",
   "Positive": "0.44907495379447937",
   "Negative": "0.011108342558145523",
   "Neutral": "0.5392904281616211",
   "Mixed": "0.0005262569175101817"
 },
 {
   "tweet_id": "1590080464005795841",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590080458229895168",
   "Positive": "0.008746642619371414",
   "Negative": "0.7145609855651855",
   "Neutral": "0.23220349848270416",
   "Mixed": "0.04448890686035156"
 },
 {
   "tweet_id": "1590080449145344001",
   "Positive": "0.0012353337369859219",
   "Negative": "0.9357166290283203",
   "Neutral": "0.06273356825113297",
   "Mixed": "0.0003143551293760538"
 },
 {
   "tweet_id": "1590080438462451712",
   "Positive": "0.8007373213768005",
   "Negative": "0.047765713185071945",
   "Neutral": "0.14628995954990387",
   "Mixed": "0.005207039415836334"
 },
 {
   "tweet_id": "1590080397647323137",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590080377565384706",
   "Positive": "0.0350005179643631",
   "Negative": "0.81191086769104",
   "Neutral": "0.1318565011024475",
   "Mixed": "0.02123210020363331"
 },
 {
   "tweet_id": "1590080376059629569",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590080353309691905",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590080315439341569",
   "Positive": "0.001287720981054008",
   "Negative": "0.907459557056427",
   "Neutral": "0.08202829211950302",
   "Mixed": "0.009224407374858856"
 },
 {
   "tweet_id": "1590080314906669056",
   "Positive": "0.8893374800682068",
   "Negative": "0.0007849044632166624",
   "Neutral": "0.10975155979394913",
   "Mixed": "0.00012599426554515958"
 },
 {
   "tweet_id": "1590080286401777664",
   "Positive": "0.5909238457679749",
   "Negative": "0.0041438015177845955",
   "Neutral": "0.4047803282737732",
   "Mixed": "0.00015210524725262076"
 },
 {
   "tweet_id": "1590080281272516608",
   "Positive": "0.6067046523094177",
   "Negative": "0.035957373678684235",
   "Neutral": "0.35704919695854187",
   "Mixed": "0.00028876319993287325"
 },
 {
   "tweet_id": "1590080277740945408",
   "Positive": "0.05113924294710159",
   "Negative": "0.19975155591964722",
   "Neutral": "0.20939572155475616",
   "Mixed": "0.5397135019302368"
 },
 {
   "tweet_id": "1590080272242208768",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590080244882735104",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590080229489659905",
   "Positive": "0.5687381029129028",
   "Negative": "0.00046430955990217626",
   "Neutral": "0.43040817975997925",
   "Mixed": "0.00038947895518504083"
 },
 {
   "tweet_id": "1590080228353015808",
   "Positive": "0.12166189402341843",
   "Negative": "0.028841009363532066",
   "Neutral": "0.45244354009628296",
   "Mixed": "0.3970535099506378"
 },
 {
   "tweet_id": "1590080168831651840",
   "Positive": "0.0270672757178545",
   "Negative": "0.09632029384374619",
   "Neutral": "0.8765357732772827",
   "Mixed": "7.661118434043601e-05"
 },
 {
   "tweet_id": "1590080162095566849",
   "Positive": "0.8830397129058838",
   "Negative": "0.00026972941122949123",
   "Neutral": "0.11665651947259903",
   "Mixed": "3.411154466448352e-05"
 },
 {
   "tweet_id": "1590080103106904065",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590080091190890497",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590080087311142912",
   "Positive": "0.0030858032405376434",
   "Negative": "0.6768665909767151",
   "Neutral": "0.3196568191051483",
   "Mixed": "0.00039079118869267404"
 },
 {
   "tweet_id": "1590080084903272448",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590080084366741504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590080066641625088",
   "Positive": "0.017726242542266846",
   "Negative": "0.0023644580505788326",
   "Neutral": "0.9798753261566162",
   "Mixed": "3.39446087309625e-05"
 },
 {
   "tweet_id": "1590080052523601920",
   "Positive": "0.9993594288825989",
   "Negative": "5.51808625459671e-05",
   "Neutral": "0.0005677086883224547",
   "Mixed": "1.767785033734981e-05"
 },
 {
   "tweet_id": "1590080039340871682",
   "Positive": "0.0002475112851243466",
   "Negative": "0.010156496427953243",
   "Neutral": "0.9895803332328796",
   "Mixed": "1.5704150428064167e-05"
 },
 {
   "tweet_id": "1590080033401753600",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590080025902350336",
   "Positive": "0.8854995369911194",
   "Negative": "0.0006457027047872543",
   "Neutral": "0.11370515823364258",
   "Mixed": "0.0001495895121479407"
 },
 {
   "tweet_id": "1590080014795825153",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590080008772632576",
   "Positive": "0.011402523145079613",
   "Negative": "0.00016721284191589803",
   "Neutral": "0.9884234070777893",
   "Mixed": "6.857713287899969e-06"
 },
 {
   "tweet_id": "1590080004100362241",
   "Positive": "0.0070818206295371056",
   "Negative": "0.4345511794090271",
   "Neutral": "0.5548651814460754",
   "Mixed": "0.0035018508788198233"
 },
 {
   "tweet_id": "1590079989483200513",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590079987486715906",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079979815309314",
   "Positive": "0.0017137655522674322",
   "Negative": "5.55729384359438e-05",
   "Neutral": "0.9982202649116516",
   "Mixed": "1.0373626537329983e-05"
 },
 {
   "tweet_id": "1590079960844488704",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079950320984066",
   "Positive": "0.020580701529979706",
   "Negative": "0.00032122188713401556",
   "Neutral": "0.9790654182434082",
   "Mixed": "3.266062049078755e-05"
 },
 {
   "tweet_id": "1590079936207130625",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079931769589761",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079914568744960",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590079894641606657",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079882234847232",
   "Positive": "0.9787428379058838",
   "Negative": "0.00023718543525319546",
   "Neutral": "0.02099725417792797",
   "Mixed": "2.2709675249643624e-05"
 },
 {
   "tweet_id": "1590079880196067333",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590079858759303171",
   "Positive": "0.9795006513595581",
   "Negative": "0.005436972714960575",
   "Neutral": "0.014689468778669834",
   "Mixed": "0.00037297449307516217"
 },
 {
   "tweet_id": "1590079857135734784",
   "Positive": "0.03903954103589058",
   "Negative": "0.10494443774223328",
   "Neutral": "0.7903065085411072",
   "Mixed": "0.06570950150489807"
 },
 {
   "tweet_id": "1590079856355684352",
   "Positive": "0.8908975720405579",
   "Negative": "0.0024935551919043064",
   "Neutral": "0.10656852275133133",
   "Mixed": "4.032609285786748e-05"
 },
 {
   "tweet_id": "1590079854611136513",
   "Positive": "0.0002890347095672041",
   "Negative": "0.0014375447062775493",
   "Neutral": "0.99826979637146",
   "Mixed": "3.533854169290862e-06"
 },
 {
   "tweet_id": "1590079850442018816",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1590079849758003201",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590079840476364801",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590079834494889984",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590079830934323200",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590079826891014147",
   "Positive": "0.0014921571128070354",
   "Negative": "0.05293795466423035",
   "Neutral": "0.9455647468566895",
   "Mixed": "5.148940545041114e-06"
 },
 {
   "tweet_id": "1590079824043069441",
   "Positive": "0.008978286758065224",
   "Negative": "0.469504177570343",
   "Neutral": "0.5190315246582031",
   "Mixed": "0.0024860501289367676"
 },
 {
   "tweet_id": "1590079822474399744",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590079822046564352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079798759391232",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590079775800176640",
   "Positive": "0.016452766954898834",
   "Negative": "6.459866563091055e-05",
   "Neutral": "0.9834511280059814",
   "Mixed": "3.1506569939665496e-05"
 },
 {
   "tweet_id": "1590079771639439360",
   "Positive": "0.9855002760887146",
   "Negative": "8.324195368913934e-05",
   "Neutral": "0.01439853198826313",
   "Mixed": "1.7846641640062444e-05"
 },
 {
   "tweet_id": "1590079771131924481",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079764915662848",
   "Positive": "0.024401802569627762",
   "Negative": "0.5081673264503479",
   "Neutral": "0.4673951268196106",
   "Mixed": "3.580760312615894e-05"
 },
 {
   "tweet_id": "1590079761690537984",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590079756938412032",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590079755478437889",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079750826909696",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079732736880640",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590079717893283840",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590079703268085760",
   "Positive": "0.004405177664011717",
   "Negative": "0.006570744793862104",
   "Neutral": "0.9890013933181763",
   "Mixed": "2.2683576389681548e-05"
 },
 {
   "tweet_id": "1590079685068607488",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079662855946240",
   "Positive": "0.020849764347076416",
   "Negative": "0.14800511300563812",
   "Neutral": "0.5730535984039307",
   "Mixed": "0.258091539144516"
 },
 {
   "tweet_id": "1590079637216169984",
   "Positive": "0.0024649561382830143",
   "Negative": "0.622755229473114",
   "Neutral": "0.3739722967147827",
   "Mixed": "0.0008075018413364887"
 },
 {
   "tweet_id": "1590079591456313344",
   "Positive": "0.053378328680992126",
   "Negative": "0.0002522853610571474",
   "Neutral": "0.9463337659835815",
   "Mixed": "3.5619545087683946e-05"
 },
 {
   "tweet_id": "1590079563086057473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079561177657344",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590079559923560448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079555305607168",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590079542655582209",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590079510627876864",
   "Positive": "0.00020208042406011373",
   "Negative": "0.0038272589445114136",
   "Neutral": "0.9959433674812317",
   "Mixed": "2.735343514359556e-05"
 },
 {
   "tweet_id": "1590079494509187072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590079485260402690",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079456492027904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079453379858433",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590079436077953026",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079410480156673",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079400288325632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079383754227712",
   "Positive": "0.11101047694683075",
   "Negative": "0.0005861457320861518",
   "Neutral": "0.8873555064201355",
   "Mixed": "0.001047870609909296"
 },
 {
   "tweet_id": "1590079366218022913",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590079361658806273",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079346915840001",
   "Positive": "0.8192746639251709",
   "Negative": "0.0008569755591452122",
   "Neutral": "0.1798095405101776",
   "Mixed": "5.8777663070941344e-05"
 },
 {
   "tweet_id": "1590079290250792961",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079281626943488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079281056870402",
   "Positive": "0.07783608138561249",
   "Negative": "0.004018175881356001",
   "Neutral": "0.9177666902542114",
   "Mixed": "0.00037901857285760343"
 },
 {
   "tweet_id": "1590079280788434945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590079277185523712",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079260064026624",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590079259699081216",
   "Positive": "0.11101047694683075",
   "Negative": "0.0005861457320861518",
   "Neutral": "0.8873555064201355",
   "Mixed": "0.001047870609909296"
 },
 {
   "tweet_id": "1590079242695757824",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590079241357774848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079233669595137",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590079211322081280",
   "Positive": "0.004927223548293114",
   "Negative": "0.0001941226510098204",
   "Neutral": "0.9948458671569824",
   "Mixed": "3.271829700679518e-05"
 },
 {
   "tweet_id": "1590079199670599680",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590079167303155713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079149070520320",
   "Positive": "0.002050084760412574",
   "Negative": "0.0001635655207792297",
   "Neutral": "0.9977814555168152",
   "Mixed": "4.819949026568793e-06"
 },
 {
   "tweet_id": "1590079146587467776",
   "Positive": "0.11101047694683075",
   "Negative": "0.0005861457320861518",
   "Neutral": "0.8873555064201355",
   "Mixed": "0.001047870609909296"
 },
 {
   "tweet_id": "1590079138668613632",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590079130267439105",
   "Positive": "0.000827706535346806",
   "Negative": "0.8394500017166138",
   "Neutral": "0.15966205298900604",
   "Mixed": "6.0220863815629855e-05"
 },
 {
   "tweet_id": "1590079120146567168",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590079104292093953",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590079104107577346",
   "Positive": "0.0006695439224131405",
   "Negative": "0.00034303145366720855",
   "Neutral": "0.9989847540855408",
   "Mixed": "2.646850134624401e-06"
 },
 {
   "tweet_id": "1590079097170186241",
   "Positive": "0.003866026410833001",
   "Negative": "0.0008630586671642959",
   "Neutral": "0.9952231049537659",
   "Mixed": "4.7825335059314966e-05"
 },
 {
   "tweet_id": "1590079056711921665",
   "Positive": "0.9075173139572144",
   "Negative": "0.000454635766800493",
   "Neutral": "0.09177076816558838",
   "Mixed": "0.0002573709061834961"
 },
 {
   "tweet_id": "1590079042727731201",
   "Positive": "0.5444622039794922",
   "Negative": "0.001622113399207592",
   "Neutral": "0.45390060544013977",
   "Mixed": "1.5085694940353278e-05"
 },
 {
   "tweet_id": "1590079034247241728",
   "Positive": "0.17571361362934113",
   "Negative": "0.0034822802990674973",
   "Neutral": "0.8205417394638062",
   "Mixed": "0.000262454675976187"
 },
 {
   "tweet_id": "1590079033274159105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590079024977838082",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590079006040559616",
   "Positive": "0.0055813267827034",
   "Negative": "0.11451751738786697",
   "Neutral": "0.8798145651817322",
   "Mixed": "8.650168456370011e-05"
 },
 {
   "tweet_id": "1590079004421156864",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590078993503776768",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078985614262272",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590078974923010049",
   "Positive": "0.05502472445368767",
   "Negative": "0.6083682179450989",
   "Neutral": "0.3362281620502472",
   "Mixed": "0.0003789020120166242"
 },
 {
   "tweet_id": "1590078974331621376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590078951770435587",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078941192425472",
   "Positive": "0.001465256791561842",
   "Negative": "0.9803404808044434",
   "Neutral": "0.017794350162148476",
   "Mixed": "0.00039984783506952226"
 },
 {
   "tweet_id": "1590078924645879808",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590078917603627008",
   "Positive": "0.9855002760887146",
   "Negative": "8.324195368913934e-05",
   "Neutral": "0.01439853198826313",
   "Mixed": "1.7846641640062444e-05"
 },
 {
   "tweet_id": "1590078901866594305",
   "Positive": "0.010706585831940174",
   "Negative": "0.494547039270401",
   "Neutral": "0.2605149447917938",
   "Mixed": "0.23423148691654205"
 },
 {
   "tweet_id": "1590078885772738560",
   "Positive": "0.038369812071323395",
   "Negative": "0.038126081228256226",
   "Neutral": "0.18212169408798218",
   "Mixed": "0.7413824200630188"
 },
 {
   "tweet_id": "1590078884082749440",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078881297403905",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590078859344760832",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590078858883403777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590078855045582848",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590078842302959616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078839740575746",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590078819163312128",
   "Positive": "0.0007856193697080016",
   "Negative": "0.014456712640821934",
   "Neutral": "0.9847515225410461",
   "Mixed": "6.050834144843975e-06"
 },
 {
   "tweet_id": "1590078806244880384",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078782509322241",
   "Positive": "0.08614429086446762",
   "Negative": "0.6818668246269226",
   "Neutral": "0.23076866567134857",
   "Mixed": "0.0012201821664348245"
 },
 {
   "tweet_id": "1590078773759995906",
   "Positive": "0.7522469758987427",
   "Negative": "0.011663168668746948",
   "Neutral": "0.23585832118988037",
   "Mixed": "0.0002314515004400164"
 },
 {
   "tweet_id": "1590078771457298432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078769817010176",
   "Positive": "0.1516498178243637",
   "Negative": "0.0037928209640085697",
   "Neutral": "0.8445324301719666",
   "Mixed": "2.4920012947404757e-05"
 },
 {
   "tweet_id": "1590078754004799488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590078697549496321",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078656491421696",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590078653626712065",
   "Positive": "0.1921122819185257",
   "Negative": "0.001803143648430705",
   "Neutral": "0.8054977059364319",
   "Mixed": "0.0005867652944289148"
 },
 {
   "tweet_id": "1590078647440117760",
   "Positive": "0.2886814773082733",
   "Negative": "0.0001461526844650507",
   "Neutral": "0.7111049890518188",
   "Mixed": "6.74367111059837e-05"
 },
 {
   "tweet_id": "1590078637394767875",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590078602049359872",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590078593585278976",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078559124611077",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590078536244555777",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590078525008072705",
   "Positive": "0.016865363344550133",
   "Negative": "0.0026556847151368856",
   "Neutral": "0.9801551699638367",
   "Mixed": "0.00032382510835304856"
 },
 {
   "tweet_id": "1590078515848056832",
   "Positive": "0.001657408196479082",
   "Negative": "0.01240011490881443",
   "Neutral": "0.9859235286712646",
   "Mixed": "1.895500099635683e-05"
 },
 {
   "tweet_id": "1590078513775665152",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078480464900096",
   "Positive": "0.5211648344993591",
   "Negative": "0.0009010052308440208",
   "Neutral": "0.477864533662796",
   "Mixed": "6.962166662560776e-05"
 },
 {
   "tweet_id": "1590078449577693184",
   "Positive": "0.16239245235919952",
   "Negative": "0.0002725430531427264",
   "Neutral": "0.8372431397438049",
   "Mixed": "9.185937233269215e-05"
 },
 {
   "tweet_id": "1590078431030497280",
   "Positive": "0.0013791125966235995",
   "Negative": "0.00019566662376746535",
   "Neutral": "0.9984225034713745",
   "Mixed": "2.7701071303454228e-06"
 },
 {
   "tweet_id": "1590078430585896960",
   "Positive": "0.000604462344199419",
   "Negative": "0.00012999029422644526",
   "Neutral": "0.9992590546607971",
   "Mixed": "6.402570306818234e-06"
 },
 {
   "tweet_id": "1590078419823661056",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078413238603776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590078406317977601",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078397631598593",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590078343692824576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078341125931008",
   "Positive": "0.44907495379447937",
   "Negative": "0.011108342558145523",
   "Neutral": "0.5392904281616211",
   "Mixed": "0.0005262569175101817"
 },
 {
   "tweet_id": "1590078324164165632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590078317063180288",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078311845498881",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078298968973313",
   "Positive": "0.2638721466064453",
   "Negative": "0.016174575313925743",
   "Neutral": "0.7198829650878906",
   "Mixed": "7.039160118438303e-05"
 },
 {
   "tweet_id": "1590078282472783872",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590078235856945152",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590078231193219072",
   "Positive": "0.004843319766223431",
   "Negative": "0.00023428660642821342",
   "Neutral": "0.9949160814285278",
   "Mixed": "6.327759365376551e-06"
 },
 {
   "tweet_id": "1590078223874142209",
   "Positive": "0.8893374800682068",
   "Negative": "0.0007849044632166624",
   "Neutral": "0.10975155979394913",
   "Mixed": "0.00012599426554515958"
 },
 {
   "tweet_id": "1590078174628499456",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590078161433133057",
   "Positive": "0.0011610123328864574",
   "Negative": "0.2437557876110077",
   "Neutral": "0.7546783685684204",
   "Mixed": "0.00040486323996447027"
 },
 {
   "tweet_id": "1590078161072828416",
   "Positive": "0.01339664962142706",
   "Negative": "0.19345347583293915",
   "Neutral": "0.7919941544532776",
   "Mixed": "0.0011557459365576506"
 },
 {
   "tweet_id": "1590078142655647744",
   "Positive": "0.00030724648968316615",
   "Negative": "0.003862113691866398",
   "Neutral": "0.995823860168457",
   "Mixed": "6.797630703658797e-06"
 },
 {
   "tweet_id": "1590078122787237889",
   "Positive": "0.9101063013076782",
   "Negative": "0.0014565802412107587",
   "Neutral": "0.08839509636163712",
   "Mixed": "4.202122363494709e-05"
 },
 {
   "tweet_id": "1590078103199834112",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590078090428166144",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590078083935371264",
   "Positive": "0.0010413676500320435",
   "Negative": "0.05360012501478195",
   "Neutral": "0.9453469514846802",
   "Mixed": "1.1600755897234194e-05"
 },
 {
   "tweet_id": "1590078074028457985",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590078027098357761",
   "Positive": "0.9234508275985718",
   "Negative": "0.00022180547239258885",
   "Neutral": "0.0762954130768776",
   "Mixed": "3.1895164283923805e-05"
 },
 {
   "tweet_id": "1590078020278419457",
   "Positive": "0.0010664890287443995",
   "Negative": "0.9360804557800293",
   "Neutral": "0.06283774971961975",
   "Mixed": "1.5351817637565546e-05"
 },
 {
   "tweet_id": "1590078019825446912",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590077990486306816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077989366419456",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077979388178435",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077967690256384",
   "Positive": "0.6424044370651245",
   "Negative": "0.00020497447985690087",
   "Neutral": "0.3573087751865387",
   "Mixed": "8.186832565115765e-05"
 },
 {
   "tweet_id": "1590077938523066368",
   "Positive": "0.001021701842546463",
   "Negative": "0.001320912386290729",
   "Neutral": "0.9976553916931152",
   "Mixed": "1.9688404790940695e-06"
 },
 {
   "tweet_id": "1590077890997104640",
   "Positive": "0.02120090276002884",
   "Negative": "0.46855977177619934",
   "Neutral": "0.5099706053733826",
   "Mixed": "0.00026872314629144967"
 },
 {
   "tweet_id": "1590077882952347648",
   "Positive": "0.125748872756958",
   "Negative": "0.008364538662135601",
   "Neutral": "0.8658342361450195",
   "Mixed": "5.228511872701347e-05"
 },
 {
   "tweet_id": "1590077878049288194",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590077874597695488",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590077860043427840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077827692793856",
   "Positive": "0.8854995369911194",
   "Negative": "0.0006457027047872543",
   "Neutral": "0.11370515823364258",
   "Mixed": "0.0001495895121479407"
 },
 {
   "tweet_id": "1590077816884068352",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590077798928257024",
   "Positive": "0.3337756395339966",
   "Negative": "0.31947454810142517",
   "Neutral": "0.3457927107810974",
   "Mixed": "0.0009571067057549953"
 },
 {
   "tweet_id": "1590077791684669442",
   "Positive": "0.8998268842697144",
   "Negative": "0.00022562241065315902",
   "Neutral": "0.09992316365242004",
   "Mixed": "2.4431932615698315e-05"
 },
 {
   "tweet_id": "1590077789281001472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077779394977792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077745715097601",
   "Positive": "0.007438279688358307",
   "Negative": "0.0005090825725346804",
   "Neutral": "0.9920313358306885",
   "Mixed": "2.1279671273077838e-05"
 },
 {
   "tweet_id": "1590077723434967041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590077714765336576",
   "Positive": "0.9038075804710388",
   "Negative": "0.010403024032711983",
   "Neutral": "0.08557789772748947",
   "Mixed": "0.00021149097301531583"
 },
 {
   "tweet_id": "1590077710914957312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077694594928640",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590077678346199040",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590077673157820417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590077667713617920",
   "Positive": "0.9038075804710388",
   "Negative": "0.010403024032711983",
   "Neutral": "0.08557789772748947",
   "Mixed": "0.00021149097301531583"
 },
 {
   "tweet_id": "1590077662759747584",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590077662093250560",
   "Positive": "0.012657662853598595",
   "Negative": "0.0009245797409676015",
   "Neutral": "0.9860430955886841",
   "Mixed": "0.00037461984902620316"
 },
 {
   "tweet_id": "1590077652182130688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590077642174496768",
   "Positive": "0.05545326694846153",
   "Negative": "0.00010240526898996904",
   "Neutral": "0.9443918466567993",
   "Mixed": "5.2428640628932044e-05"
 },
 {
   "tweet_id": "1590077625376346113",
   "Positive": "0.7611103057861328",
   "Negative": "0.04881324991583824",
   "Neutral": "0.1842835694551468",
   "Mixed": "0.0057928008027374744"
 },
 {
   "tweet_id": "1590077623501488129",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077606334173185",
   "Positive": "0.9787428379058838",
   "Negative": "0.00023718543525319546",
   "Neutral": "0.02099725417792797",
   "Mixed": "2.2709675249643624e-05"
 },
 {
   "tweet_id": "1590077576109649920",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077559269916672",
   "Positive": "0.012735611759126186",
   "Negative": "0.0006169755361042917",
   "Neutral": "0.9865990877151489",
   "Mixed": "4.8382349632447585e-05"
 },
 {
   "tweet_id": "1590077550835159040",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590077513396793346",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077506631380992",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077495025741825",
   "Positive": "0.20398540794849396",
   "Negative": "0.12287522107362747",
   "Neutral": "0.6730177402496338",
   "Mixed": "0.00012166523083578795"
 },
 {
   "tweet_id": "1590077488574894081",
   "Positive": "0.04960266873240471",
   "Negative": "0.0021947906352579594",
   "Neutral": "0.9481798410415649",
   "Mixed": "2.2772377633373253e-05"
 },
 {
   "tweet_id": "1590077482472181761",
   "Positive": "0.9855002760887146",
   "Negative": "8.324195368913934e-05",
   "Neutral": "0.01439853198826313",
   "Mixed": "1.7846641640062444e-05"
 },
 {
   "tweet_id": "1590077481914363904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077478563086338",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590077478248513539",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077442344902657",
   "Positive": "0.0005599005962722003",
   "Negative": "0.899726390838623",
   "Neutral": "0.09964178502559662",
   "Mixed": "7.191655458882451e-05"
 },
 {
   "tweet_id": "1590077441699368960",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077422770454528",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590077417082605571",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1590077414809694208",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590077410082291712",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590077400490336256",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590077392189816832",
   "Positive": "0.9421342611312866",
   "Negative": "0.0010751632507890463",
   "Neutral": "0.05674697086215019",
   "Mixed": "4.361287210485898e-05"
 },
 {
   "tweet_id": "1590077386451996672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077344286494720",
   "Positive": "0.00042102846782654524",
   "Negative": "9.385124576510862e-05",
   "Neutral": "0.9994741082191467",
   "Mixed": "1.1057171832362656e-05"
 },
 {
   "tweet_id": "1590077313366228992",
   "Positive": "0.003799671307206154",
   "Negative": "0.34055933356285095",
   "Neutral": "0.6550502777099609",
   "Mixed": "0.0005906408769078553"
 },
 {
   "tweet_id": "1590077306202357762",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590077297792782336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077289370292224",
   "Positive": "0.13374827802181244",
   "Negative": "0.008058609440922737",
   "Neutral": "0.8580607771873474",
   "Mixed": "0.00013232007040642202"
 },
 {
   "tweet_id": "1590077286229114880",
   "Positive": "0.9421342611312866",
   "Negative": "0.0010751632507890463",
   "Neutral": "0.05674697086215019",
   "Mixed": "4.361287210485898e-05"
 },
 {
   "tweet_id": "1590077252091662336",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590077246718386176",
   "Positive": "0.5727903842926025",
   "Negative": "0.001618466805666685",
   "Neutral": "0.4250549077987671",
   "Mixed": "0.0005361967487260699"
 },
 {
   "tweet_id": "1590077242159161344",
   "Positive": "0.5727903842926025",
   "Negative": "0.001618466805666685",
   "Neutral": "0.4250549077987671",
   "Mixed": "0.0005361967487260699"
 },
 {
   "tweet_id": "1590077234647224321",
   "Positive": "0.5727903842926025",
   "Negative": "0.001618466805666685",
   "Neutral": "0.4250549077987671",
   "Mixed": "0.0005361967487260699"
 },
 {
   "tweet_id": "1590077228712611840",
   "Positive": "0.11737077683210373",
   "Negative": "0.00017986471357289702",
   "Neutral": "0.8816344141960144",
   "Mixed": "0.000814932631328702"
 },
 {
   "tweet_id": "1590077214422286337",
   "Positive": "0.5727903842926025",
   "Negative": "0.001618466805666685",
   "Neutral": "0.4250549077987671",
   "Mixed": "0.0005361967487260699"
 },
 {
   "tweet_id": "1590077212589723648",
   "Positive": "0.04618297144770622",
   "Negative": "0.00016077322652563453",
   "Neutral": "0.9536243677139282",
   "Mixed": "3.194277815055102e-05"
 },
 {
   "tweet_id": "1590077206654439424",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077186534694912",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590077176640331776",
   "Positive": "0.00790666788816452",
   "Negative": "0.00010197191295446828",
   "Neutral": "0.9919623732566833",
   "Mixed": "2.8985321478103288e-05"
 },
 {
   "tweet_id": "1590077168482422785",
   "Positive": "0.025704756379127502",
   "Negative": "0.0012253224849700928",
   "Neutral": "0.97296541929245",
   "Mixed": "0.00010455849405843765"
 },
 {
   "tweet_id": "1590077166028742656",
   "Positive": "0.01494931522756815",
   "Negative": "0.41068559885025024",
   "Neutral": "0.5645838975906372",
   "Mixed": "0.009781142696738243"
 },
 {
   "tweet_id": "1590077156717367296",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590077130339409922",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077127357272068",
   "Positive": "0.0025107862893491983",
   "Negative": "0.0017054987838491797",
   "Neutral": "0.9957745671272278",
   "Mixed": "9.114810382016003e-06"
 },
 {
   "tweet_id": "1590077117693276160",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590077116665987072",
   "Positive": "0.033398065716028214",
   "Negative": "7.98783075879328e-05",
   "Neutral": "0.9664281606674194",
   "Mixed": "9.388916078023612e-05"
 },
 {
   "tweet_id": "1590077115416051713",
   "Positive": "0.8854995369911194",
   "Negative": "0.0006457027047872543",
   "Neutral": "0.11370515823364258",
   "Mixed": "0.0001495895121479407"
 },
 {
   "tweet_id": "1590077089348452352",
   "Positive": "0.04777098447084427",
   "Negative": "0.08456144481897354",
   "Neutral": "0.8558771014213562",
   "Mixed": "0.011790541931986809"
 },
 {
   "tweet_id": "1590077061737353216",
   "Positive": "0.053044211119413376",
   "Negative": "0.0012461355654522777",
   "Neutral": "0.9457023739814758",
   "Mixed": "7.35529874873464e-06"
 },
 {
   "tweet_id": "1590077055399776256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590077051536830465",
   "Positive": "0.12691067159175873",
   "Negative": "0.0016093883896246552",
   "Neutral": "0.8711895942687988",
   "Mixed": "0.0002904050634242594"
 },
 {
   "tweet_id": "1590077023409823744",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590077021157470210",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590076988253147137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590076974676213763",
   "Positive": "0.00012167816021246836",
   "Negative": "0.9776453971862793",
   "Neutral": "0.02220234088599682",
   "Mixed": "3.060828385059722e-05"
 },
 {
   "tweet_id": "1590076966132404225",
   "Positive": "0.026196081191301346",
   "Negative": "0.00045389929437078536",
   "Neutral": "0.9732674956321716",
   "Mixed": "8.246758079621941e-05"
 },
 {
   "tweet_id": "1590076956535853056",
   "Positive": "0.9814728498458862",
   "Negative": "9.652985318098217e-05",
   "Neutral": "0.018409036099910736",
   "Mixed": "2.1577290681307204e-05"
 },
 {
   "tweet_id": "1590076929709084672",
   "Positive": "0.36549755930900574",
   "Negative": "0.009389916434884071",
   "Neutral": "0.6248212456703186",
   "Mixed": "0.0002912971540354192"
 },
 {
   "tweet_id": "1590076927896739840",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590076926638841857",
   "Positive": "0.7960617542266846",
   "Negative": "0.024671150371432304",
   "Neutral": "0.17882755398750305",
   "Mixed": "0.0004395550931803882"
 },
 {
   "tweet_id": "1590076910994087936",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590076902127304706",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076889086930944",
   "Positive": "0.5064849853515625",
   "Negative": "0.0017987772589549422",
   "Neutral": "0.49161550402641296",
   "Mixed": "0.00010079823550768197"
 },
 {
   "tweet_id": "1590076874969198592",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590076865875959808",
   "Positive": "0.9957211017608643",
   "Negative": "8.601564331911504e-05",
   "Neutral": "0.004172551911324263",
   "Mixed": "2.029192000918556e-05"
 },
 {
   "tweet_id": "1590076844673421313",
   "Positive": "0.003081002738326788",
   "Negative": "0.4332488179206848",
   "Neutral": "0.5633810758590698",
   "Mixed": "0.0002891521726269275"
 },
 {
   "tweet_id": "1590076836582940672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076828307554304",
   "Positive": "0.9953429698944092",
   "Negative": "0.00010483880760148168",
   "Neutral": "0.0045290300622582436",
   "Mixed": "2.3118689568946138e-05"
 },
 {
   "tweet_id": "1590076810733449216",
   "Positive": "0.8666030764579773",
   "Negative": "0.0015408920589834452",
   "Neutral": "0.1317729651927948",
   "Mixed": "8.302964852191508e-05"
 },
 {
   "tweet_id": "1590076789426368512",
   "Positive": "0.16885165870189667",
   "Negative": "0.00012529756349977106",
   "Neutral": "0.8307530879974365",
   "Mixed": "0.00026994108338840306"
 },
 {
   "tweet_id": "1590076782883262464",
   "Positive": "0.0024812063202261925",
   "Negative": "0.00043022140744142234",
   "Neutral": "0.9970856308937073",
   "Mixed": "2.929437414422864e-06"
 },
 {
   "tweet_id": "1590076778659205120",
   "Positive": "0.24482160806655884",
   "Negative": "0.012673403136432171",
   "Neutral": "0.7423022985458374",
   "Mixed": "0.00020274851704016328"
 },
 {
   "tweet_id": "1590076753904820224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076749916016640",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076745583316992",
   "Positive": "0.9953808784484863",
   "Negative": "0.0001014143053907901",
   "Neutral": "0.004494288004934788",
   "Mixed": "2.3398097255267203e-05"
 },
 {
   "tweet_id": "1590076706383335427",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590076703480553472",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076695809511424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590076685642526720",
   "Positive": "0.055102307349443436",
   "Negative": "0.005287318490445614",
   "Neutral": "0.9333935976028442",
   "Mixed": "0.006216739304363728"
 },
 {
   "tweet_id": "1590076677518143488",
   "Positive": "0.5497332811355591",
   "Negative": "0.03960992768406868",
   "Neutral": "0.3854784667491913",
   "Mixed": "0.025178320705890656"
 },
 {
   "tweet_id": "1590076673403531265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590076668567515136",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590076660883533824",
   "Positive": "0.40904587507247925",
   "Negative": "0.00015632643771823496",
   "Neutral": "0.5907279253005981",
   "Mixed": "6.983347702771425e-05"
 },
 {
   "tweet_id": "1590076659012509696",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076656882180096",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590076638368522240",
   "Positive": "0.5497332811355591",
   "Negative": "0.03960992768406868",
   "Neutral": "0.3854784667491913",
   "Mixed": "0.025178320705890656"
 },
 {
   "tweet_id": "1590076605380300800",
   "Positive": "0.31679460406303406",
   "Negative": "0.0007702623261138797",
   "Neutral": "0.6818880438804626",
   "Mixed": "0.0005470464820973575"
 },
 {
   "tweet_id": "1590076604994441216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590076600598818816",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590076594093031424",
   "Positive": "0.0014235101407393813",
   "Negative": "0.00045626406790688634",
   "Neutral": "0.99811851978302",
   "Mixed": "1.7711982991386321e-06"
 },
 {
   "tweet_id": "1590076571930742784",
   "Positive": "0.9017607569694519",
   "Negative": "0.0002544450981076807",
   "Neutral": "0.09796026349067688",
   "Mixed": "2.452037915645633e-05"
 },
 {
   "tweet_id": "1590076552716292096",
   "Positive": "0.29895201325416565",
   "Negative": "0.024490341544151306",
   "Neutral": "0.6762555241584778",
   "Mixed": "0.00030215055448934436"
 },
 {
   "tweet_id": "1590076532084441088",
   "Positive": "0.9017607569694519",
   "Negative": "0.0002544450981076807",
   "Neutral": "0.09796026349067688",
   "Mixed": "2.452037915645633e-05"
 },
 {
   "tweet_id": "1590076511364984832",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590076491169435648",
   "Positive": "0.8558966517448425",
   "Negative": "0.0002736247843131423",
   "Neutral": "0.14380349218845367",
   "Mixed": "2.6205734684481286e-05"
 },
 {
   "tweet_id": "1590076489827246080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590076486752796672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076485293199360",
   "Positive": "0.00023095069627743214",
   "Negative": "0.0001027522812364623",
   "Neutral": "0.9996614456176758",
   "Mixed": "4.858474312641192e-06"
 },
 {
   "tweet_id": "1590076454343438336",
   "Positive": "0.0038853378500789404",
   "Negative": "0.000276160251814872",
   "Neutral": "0.9958357810974121",
   "Mixed": "2.7254336600890383e-06"
 },
 {
   "tweet_id": "1590076452804112384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590076450535006208",
   "Positive": "0.5211648344993591",
   "Negative": "0.0009010052308440208",
   "Neutral": "0.477864533662796",
   "Mixed": "6.962166662560776e-05"
 },
 {
   "tweet_id": "1590076420688338945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076395723452416",
   "Positive": "0.9953808784484863",
   "Negative": "0.0001014143053907901",
   "Neutral": "0.004494288004934788",
   "Mixed": "2.3398097255267203e-05"
 },
 {
   "tweet_id": "1590076389205905408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590076384436965378",
   "Positive": "0.6077394485473633",
   "Negative": "0.036374565213918686",
   "Neutral": "0.3475915491580963",
   "Mixed": "0.008294424042105675"
 },
 {
   "tweet_id": "1590076381773582337",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076373171048448",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076357266276352",
   "Positive": "0.028007950633764267",
   "Negative": "0.6987727880477905",
   "Neutral": "0.15381121635437012",
   "Mixed": "0.1194080039858818"
 },
 {
   "tweet_id": "1590076355026489349",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1590076354758049793",
   "Positive": "0.9953808784484863",
   "Negative": "0.0001014143053907901",
   "Neutral": "0.004494288004934788",
   "Mixed": "2.3398097255267203e-05"
 },
 {
   "tweet_id": "1590076326672621568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590076317785284610",
   "Positive": "0.02775280922651291",
   "Negative": "0.2450672835111618",
   "Neutral": "0.691417932510376",
   "Mixed": "0.03576196730136871"
 },
 {
   "tweet_id": "1590076314341769218",
   "Positive": "0.44907495379447937",
   "Negative": "0.011108342558145523",
   "Neutral": "0.5392904281616211",
   "Mixed": "0.0005262569175101817"
 },
 {
   "tweet_id": "1590076313444175872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076306313838592",
   "Positive": "0.0012864544987678528",
   "Negative": "7.127565913833678e-05",
   "Neutral": "0.9986317753791809",
   "Mixed": "1.0446558917465154e-05"
 },
 {
   "tweet_id": "1590076304569040896",
   "Positive": "0.9953808784484863",
   "Negative": "0.0001014143053907901",
   "Neutral": "0.004494288004934788",
   "Mixed": "2.3398097255267203e-05"
 },
 {
   "tweet_id": "1590076277075374081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590076236675821569",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076223019184128",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1590076221714472965",
   "Positive": "0.01225360669195652",
   "Negative": "0.0003008758940268308",
   "Neutral": "0.9874373078346252",
   "Mixed": "8.184740181604866e-06"
 },
 {
   "tweet_id": "1590076207600893953",
   "Positive": "0.49856117367744446",
   "Negative": "0.03757615014910698",
   "Neutral": "0.4324928820133209",
   "Mixed": "0.03136972710490227"
 },
 {
   "tweet_id": "1590076191494791169",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590076186545524737",
   "Positive": "0.5497332811355591",
   "Negative": "0.03960992768406868",
   "Neutral": "0.3854784667491913",
   "Mixed": "0.025178320705890656"
 },
 {
   "tweet_id": "1590076182996742144",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076182732890112",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590076174218457088",
   "Positive": "0.8893374800682068",
   "Negative": "0.0007849044632166624",
   "Neutral": "0.10975155979394913",
   "Mixed": "0.00012599426554515958"
 },
 {
   "tweet_id": "1590076172783669249",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590076153494396928",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590076139959382017",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590076134880083969",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590076133974106112",
   "Positive": "0.5497332811355591",
   "Negative": "0.03960992768406868",
   "Neutral": "0.3854784667491913",
   "Mixed": "0.025178320705890656"
 },
 {
   "tweet_id": "1590076123576426496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590076095747231744",
   "Positive": "0.9371323585510254",
   "Negative": "0.0015875085955485702",
   "Neutral": "0.06109198182821274",
   "Mixed": "0.00018821754201781005"
 },
 {
   "tweet_id": "1590076086796226561",
   "Positive": "0.25716808438301086",
   "Negative": "0.047900229692459106",
   "Neutral": "0.6728466749191284",
   "Mixed": "0.022085051983594894"
 },
 {
   "tweet_id": "1590076064503853057",
   "Positive": "0.0008595224353484809",
   "Negative": "6.442017911467701e-05",
   "Neutral": "0.9990676045417786",
   "Mixed": "8.460958270006813e-06"
 },
 {
   "tweet_id": "1590076056106864642",
   "Positive": "0.0002248470118502155",
   "Negative": "0.9875665903091431",
   "Neutral": "0.012136229313910007",
   "Mixed": "7.226831075968221e-05"
 },
 {
   "tweet_id": "1590076031847002112",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590076020790816768",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590076011986964480",
   "Positive": "0.864768385887146",
   "Negative": "0.016208423301577568",
   "Neutral": "0.11858794838190079",
   "Mixed": "0.0004353155964054167"
 },
 {
   "tweet_id": "1590076011357831169",
   "Positive": "0.9097748398780823",
   "Negative": "0.00022632860054727644",
   "Neutral": "0.0899626761674881",
   "Mixed": "3.6121997254667804e-05"
 },
 {
   "tweet_id": "1590076005254717440",
   "Positive": "0.0006646396359428763",
   "Negative": "0.0017638939898461103",
   "Neutral": "0.9975691437721252",
   "Mixed": "2.26722909246746e-06"
 },
 {
   "tweet_id": "1590075999512772608",
   "Positive": "0.6317914128303528",
   "Negative": "0.0012343400157988071",
   "Neutral": "0.3669099807739258",
   "Mixed": "6.428419146686792e-05"
 },
 {
   "tweet_id": "1590075998426763264",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590075987446083584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590075973415731201",
   "Positive": "0.864768385887146",
   "Negative": "0.016208423301577568",
   "Neutral": "0.11858794838190079",
   "Mixed": "0.0004353155964054167"
 },
 {
   "tweet_id": "1590075940402778112",
   "Positive": "0.006400331389158964",
   "Negative": "0.07182341068983078",
   "Neutral": "0.03234906122088432",
   "Mixed": "0.8894272446632385"
 },
 {
   "tweet_id": "1590075927744368640",
   "Positive": "0.18078124523162842",
   "Negative": "0.005846062675118446",
   "Neutral": "0.813336193561554",
   "Mixed": "3.655038381111808e-05"
 },
 {
   "tweet_id": "1590075922602131457",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590075907024490496",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590075899508326400",
   "Positive": "0.0013418260496109724",
   "Negative": "0.0001889961276901886",
   "Neutral": "0.9984666109085083",
   "Mixed": "2.6168916065216763e-06"
 },
 {
   "tweet_id": "1590075869636464640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590075868797206528",
   "Positive": "0.0018752056639641523",
   "Negative": "0.20089760422706604",
   "Neutral": "0.7971137166023254",
   "Mixed": "0.00011345898383297026"
 },
 {
   "tweet_id": "1590075867921018880",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590075862959153152",
   "Positive": "0.3119790554046631",
   "Negative": "0.0010529959108680487",
   "Neutral": "0.6867034435272217",
   "Mixed": "0.00026448906282894313"
 },
 {
   "tweet_id": "1590075850262650880",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590075844189294592",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1590075843283660800",
   "Positive": "0.9317052364349365",
   "Negative": "0.0003952693077735603",
   "Neutral": "0.06788291037082672",
   "Mixed": "1.660178168094717e-05"
 },
 {
   "tweet_id": "1590075838967734272",
   "Positive": "0.0221879743039608",
   "Negative": "0.27313032746315",
   "Neutral": "0.7046335935592651",
   "Mixed": "4.810620885109529e-05"
 },
 {
   "tweet_id": "1590075831770288129",
   "Positive": "0.46869319677352905",
   "Negative": "0.012849061749875546",
   "Neutral": "0.5183169841766357",
   "Mixed": "0.00014073924103286117"
 },
 {
   "tweet_id": "1590075825092976640",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075818923143169",
   "Positive": "0.964329719543457",
   "Negative": "0.00032830212148837745",
   "Neutral": "0.03531629964709282",
   "Mixed": "2.5590770746930502e-05"
 },
 {
   "tweet_id": "1590075800912801793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075779769331712",
   "Positive": "0.9607446193695068",
   "Negative": "0.00017315200238954276",
   "Neutral": "0.03892795369029045",
   "Mixed": "0.00015432377404067665"
 },
 {
   "tweet_id": "1590075763763863552",
   "Positive": "0.9569593667984009",
   "Negative": "0.0003107042284682393",
   "Neutral": "0.042702846229076385",
   "Mixed": "2.7063982997788116e-05"
 },
 {
   "tweet_id": "1590075758474821632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075755769131009",
   "Positive": "0.8416872024536133",
   "Negative": "0.008871178142726421",
   "Neutral": "0.14742319285869598",
   "Mixed": "0.002018444938585162"
 },
 {
   "tweet_id": "1590075749029249026",
   "Positive": "0.00816359557211399",
   "Negative": "0.6659629344940186",
   "Neutral": "0.24726559221744537",
   "Mixed": "0.0786079466342926"
 },
 {
   "tweet_id": "1590075731480309762",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590075729236353024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075710953361408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075695723872257",
   "Positive": "0.6640914678573608",
   "Negative": "0.015307474881410599",
   "Neutral": "0.2960132956504822",
   "Mixed": "0.024587713181972504"
 },
 {
   "tweet_id": "1590075688052174848",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075684827070464",
   "Positive": "0.012533617205917835",
   "Negative": "0.015778204426169395",
   "Neutral": "0.9716671109199524",
   "Mixed": "2.1087407731101848e-05"
 },
 {
   "tweet_id": "1590075674769096704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590075658566537216",
   "Positive": "0.04262160509824753",
   "Negative": "0.0013880659826099873",
   "Neutral": "0.9554091691970825",
   "Mixed": "0.0005811690352857113"
 },
 {
   "tweet_id": "1590075654217035776",
   "Positive": "0.6640914678573608",
   "Negative": "0.015307474881410599",
   "Neutral": "0.2960132956504822",
   "Mixed": "0.024587713181972504"
 },
 {
   "tweet_id": "1590075653889855489",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590075638022836224",
   "Positive": "0.9969199895858765",
   "Negative": "9.838744153967127e-05",
   "Neutral": "0.0029686104971915483",
   "Mixed": "1.3040377780271228e-05"
 },
 {
   "tweet_id": "1590075626975027200",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590075621564370945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075602714828801",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075594204942336",
   "Positive": "0.6640914678573608",
   "Negative": "0.015307474881410599",
   "Neutral": "0.2960132956504822",
   "Mixed": "0.024587713181972504"
 },
 {
   "tweet_id": "1590075592057454593",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075588999806976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075580644724737",
   "Positive": "0.04047994315624237",
   "Negative": "0.0014701606705784798",
   "Neutral": "0.9580373764038086",
   "Mixed": "1.2443189007171895e-05"
 },
 {
   "tweet_id": "1590075552719044608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075548713222144",
   "Positive": "0.004660108592361212",
   "Negative": "0.4771154820919037",
   "Neutral": "0.5101593732833862",
   "Mixed": "0.008064999245107174"
 },
 {
   "tweet_id": "1590075530384375808",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590075526974410752",
   "Positive": "0.9787428379058838",
   "Negative": "0.00023718543525319546",
   "Neutral": "0.02099725417792797",
   "Mixed": "2.2709675249643624e-05"
 },
 {
   "tweet_id": "1590075518367715331",
   "Positive": "0.9902366995811462",
   "Negative": "0.00018312565225642174",
   "Neutral": "0.009550023823976517",
   "Mixed": "3.0189970857463777e-05"
 },
 {
   "tweet_id": "1590075512482693121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590075491385454592",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590075479272591360",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590075464055259136",
   "Positive": "0.9898255467414856",
   "Negative": "0.00014108643517829478",
   "Neutral": "0.009995424188673496",
   "Mixed": "3.7898662412771955e-05"
 },
 {
   "tweet_id": "1590075453376983040",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590075449274929153",
   "Positive": "0.040755584836006165",
   "Negative": "0.4579366445541382",
   "Neutral": "0.4367320239543915",
   "Mixed": "0.06457579880952835"
 },
 {
   "tweet_id": "1590075449040064514",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590075431977246720",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590075418282840064",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075417314361345",
   "Positive": "0.987126886844635",
   "Negative": "0.00018208094115834683",
   "Neutral": "0.012662285007536411",
   "Mixed": "2.8851702154497616e-05"
 },
 {
   "tweet_id": "1590075410100146176",
   "Positive": "0.9855002760887146",
   "Negative": "8.324195368913934e-05",
   "Neutral": "0.01439853198826313",
   "Mixed": "1.7846641640062444e-05"
 },
 {
   "tweet_id": "1590075386427506688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590075355708428289",
   "Positive": "0.00989578478038311",
   "Negative": "0.8642138242721558",
   "Neutral": "0.09948547929525375",
   "Mixed": "0.02640490233898163"
 },
 {
   "tweet_id": "1590075337475379201",
   "Positive": "0.9022204279899597",
   "Negative": "0.0006519175949506462",
   "Neutral": "0.09703132510185242",
   "Mixed": "9.644470264902338e-05"
 },
 {
   "tweet_id": "1590075326906126336",
   "Positive": "0.005377455614507198",
   "Negative": "0.6957229375839233",
   "Neutral": "0.2987450957298279",
   "Mixed": "0.0001545548002468422"
 },
 {
   "tweet_id": "1590075305620013056",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590075301236989952",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075292801847296",
   "Positive": "0.934679388999939",
   "Negative": "0.0010013062274083495",
   "Neutral": "0.06427711993455887",
   "Mixed": "4.2160769226029515e-05"
 },
 {
   "tweet_id": "1590075252968947712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075252000059392",
   "Positive": "0.934679388999939",
   "Negative": "0.0010013062274083495",
   "Neutral": "0.06427711993455887",
   "Mixed": "4.2160769226029515e-05"
 },
 {
   "tweet_id": "1590075242818347008",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590075229204017152",
   "Positive": "0.9855002760887146",
   "Negative": "8.324195368913934e-05",
   "Neutral": "0.01439853198826313",
   "Mixed": "1.7846641640062444e-05"
 },
 {
   "tweet_id": "1590075219355795457",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075216017100801",
   "Positive": "0.002818620065227151",
   "Negative": "0.6040270328521729",
   "Neutral": "0.39306849241256714",
   "Mixed": "8.591735240770504e-05"
 },
 {
   "tweet_id": "1590075214616199168",
   "Positive": "0.007545171771198511",
   "Negative": "0.06568410247564316",
   "Neutral": "0.13832582533359528",
   "Mixed": "0.7884449362754822"
 },
 {
   "tweet_id": "1590075209318481920",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075200485306368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590075186606665728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075185470009345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075182211018752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075168227196928",
   "Positive": "0.9835330843925476",
   "Negative": "0.00016090630379039794",
   "Neutral": "0.01628388836979866",
   "Mixed": "2.2091189748607576e-05"
 },
 {
   "tweet_id": "1590075140003405824",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590075131539640320",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075124853919744",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075114384543744",
   "Positive": "0.9835330843925476",
   "Negative": "0.00016090630379039794",
   "Neutral": "0.01628388836979866",
   "Mixed": "2.2091189748607576e-05"
 },
 {
   "tweet_id": "1590075103559094273",
   "Positive": "0.0038780660834163427",
   "Negative": "0.5179900527000427",
   "Neutral": "0.47800156474113464",
   "Mixed": "0.00013026801752857864"
 },
 {
   "tweet_id": "1590075096148094976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075094772039681",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590075090980732928",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590075077449879552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590075070902603776",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590075053093576704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590075048416923648",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590075046919548928",
   "Positive": "0.29752644896507263",
   "Negative": "0.0013574494514614344",
   "Neutral": "0.7000300884246826",
   "Mixed": "0.0010860589100047946"
 },
 {
   "tweet_id": "1590075042909786113",
   "Positive": "0.6990228295326233",
   "Negative": "0.006480234209448099",
   "Neutral": "0.2944337725639343",
   "Mixed": "6.317783117992803e-05"
 },
 {
   "tweet_id": "1590075024483835909",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1590074978900226048",
   "Positive": "0.6877962350845337",
   "Negative": "0.009404654614627361",
   "Neutral": "0.3027207553386688",
   "Mixed": "7.82845017965883e-05"
 },
 {
   "tweet_id": "1590074938412904448",
   "Positive": "0.007594123482704163",
   "Negative": "0.00022612720204051584",
   "Neutral": "0.992174506187439",
   "Mixed": "5.274463546811603e-06"
 },
 {
   "tweet_id": "1590074925594746880",
   "Positive": "0.6877962350845337",
   "Negative": "0.009404654614627361",
   "Neutral": "0.3027207553386688",
   "Mixed": "7.82845017965883e-05"
 },
 {
   "tweet_id": "1590074906301321216",
   "Positive": "0.014639368280768394",
   "Negative": "0.010594089515507221",
   "Neutral": "0.14351071417331696",
   "Mixed": "0.8312557935714722"
 },
 {
   "tweet_id": "1590074900894871552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074896553775105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074880225337345",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590074879478407168",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590074869785714689",
   "Positive": "0.0002851480385288596",
   "Negative": "0.8906935453414917",
   "Neutral": "0.1089918240904808",
   "Mixed": "2.9467511922121048e-05"
 },
 {
   "tweet_id": "1590074854421983232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590074806149722112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074794019500032",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590074772473679872",
   "Positive": "0.054938506335020065",
   "Negative": "0.8200939893722534",
   "Neutral": "0.12323801964521408",
   "Mixed": "0.0017294699791818857"
 },
 {
   "tweet_id": "1590074766530314243",
   "Positive": "0.033922214061021805",
   "Negative": "0.016309382393956184",
   "Neutral": "0.9496731162071228",
   "Mixed": "9.530017268843949e-05"
 },
 {
   "tweet_id": "1590074763489456128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590074758921867264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074753578307584",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1590074742765064192",
   "Positive": "0.0036696039605885744",
   "Negative": "0.7183865904808044",
   "Neutral": "0.27791649103164673",
   "Mixed": "2.7344582122168504e-05"
 },
 {
   "tweet_id": "1590074703669960704",
   "Positive": "0.0005638087750412524",
   "Negative": "0.9144676327705383",
   "Neutral": "0.08493105322122574",
   "Mixed": "3.7550937122432515e-05"
 },
 {
   "tweet_id": "1590074698032828417",
   "Positive": "0.010147331282496452",
   "Negative": "0.7064114809036255",
   "Neutral": "0.28341320157051086",
   "Mixed": "2.797538400045596e-05"
 },
 {
   "tweet_id": "1590074678709665793",
   "Positive": "0.02101990021765232",
   "Negative": "0.051494576036930084",
   "Neutral": "0.6152875423431396",
   "Mixed": "0.3121979236602783"
 },
 {
   "tweet_id": "1590074665447600128",
   "Positive": "0.15637263655662537",
   "Negative": "0.0004726835177280009",
   "Neutral": "0.8430799841880798",
   "Mixed": "7.46614023228176e-05"
 },
 {
   "tweet_id": "1590074660590219264",
   "Positive": "0.49954333901405334",
   "Negative": "0.0006254322943277657",
   "Neutral": "0.4995565712451935",
   "Mixed": "0.00027467383188195527"
 },
 {
   "tweet_id": "1590074650960461824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590074648485826566",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590074589039972352",
   "Positive": "0.0014086606679484248",
   "Negative": "5.2374714869074523e-05",
   "Neutral": "0.998511016368866",
   "Mixed": "2.801884875225369e-05"
 },
 {
   "tweet_id": "1590074588855095296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074588289196032",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590074587630669824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590074580554551296",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074555657490432",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590074554843828226",
   "Positive": "0.005037615541368723",
   "Negative": "0.8493502140045166",
   "Neutral": "0.13728122413158417",
   "Mixed": "0.008331013843417168"
 },
 {
   "tweet_id": "1590074550389440512",
   "Positive": "0.20280325412750244",
   "Negative": "0.04898916557431221",
   "Neutral": "0.7480816841125488",
   "Mixed": "0.00012588479148689657"
 },
 {
   "tweet_id": "1590074550079086592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074537332572160",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590074509872496641",
   "Positive": "0.3712103068828583",
   "Negative": "0.025291915982961655",
   "Neutral": "0.6027140617370605",
   "Mixed": "0.0007836863514967263"
 },
 {
   "tweet_id": "1590074497000181761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074494000906240",
   "Positive": "0.0005064912256784737",
   "Negative": "0.0019100746139883995",
   "Neutral": "0.9975782036781311",
   "Mixed": "5.224039341555908e-06"
 },
 {
   "tweet_id": "1590074467103158272",
   "Positive": "0.9933995008468628",
   "Negative": "2.8366634069243446e-05",
   "Neutral": "0.006536620203405619",
   "Mixed": "3.546563311829232e-05"
 },
 {
   "tweet_id": "1590074463290535937",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590074460333551616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074448916676609",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590074392800698369",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074352640622592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074343522185216",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074319883096065",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074301021302784",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590074271384031232",
   "Positive": "0.0596194826066494",
   "Negative": "0.49260082840919495",
   "Neutral": "0.447320818901062",
   "Mixed": "0.0004588775336742401"
 },
 {
   "tweet_id": "1590074258948259841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074231718825984",
   "Positive": "0.05705101788043976",
   "Negative": "0.0009304651175625622",
   "Neutral": "0.941979706287384",
   "Mixed": "3.8840877095935866e-05"
 },
 {
   "tweet_id": "1590074210202058752",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590074192455938048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074175716302850",
   "Positive": "0.19947633147239685",
   "Negative": "0.00381585955619812",
   "Neutral": "0.7966724038124084",
   "Mixed": "3.539010140229948e-05"
 },
 {
   "tweet_id": "1590074175607263233",
   "Positive": "0.020457720384001732",
   "Negative": "0.00013078101619612426",
   "Neutral": "0.9793944954872131",
   "Mixed": "1.6968060663202778e-05"
 },
 {
   "tweet_id": "1590074155646390273",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074135052685312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074121467330561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590074100198051841",
   "Positive": "0.609551727771759",
   "Negative": "0.016927488148212433",
   "Neutral": "0.22855737805366516",
   "Mixed": "0.144963338971138"
 },
 {
   "tweet_id": "1590074087690207233",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1590074078693830656",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590074071265742848",
   "Positive": "0.0010254525113850832",
   "Negative": "6.475918780779466e-05",
   "Neutral": "0.9988920092582703",
   "Mixed": "1.776445424184203e-05"
 },
 {
   "tweet_id": "1590074056417882115",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590074018128101376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590074017301483520",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590073959126831110",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590073909575319552",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590073896379682816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073887186092033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073881444102144",
   "Positive": "0.0033047921024262905",
   "Negative": "0.4372393786907196",
   "Neutral": "0.5592959523200989",
   "Mixed": "0.00015989833627827466"
 },
 {
   "tweet_id": "1590073856894840834",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590073837336801280",
   "Positive": "0.030508868396282196",
   "Negative": "0.02638501487672329",
   "Neutral": "0.9430926442146301",
   "Mixed": "1.3532173397834413e-05"
 },
 {
   "tweet_id": "1590073833192841216",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073818244349952",
   "Positive": "0.0005486345035023987",
   "Negative": "0.9712637662887573",
   "Neutral": "0.028142035007476807",
   "Mixed": "4.557642387226224e-05"
 },
 {
   "tweet_id": "1590073804377985025",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073793909002240",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590073791350476800",
   "Positive": "0.130403071641922",
   "Negative": "0.02862474136054516",
   "Neutral": "0.7840663194656372",
   "Mixed": "0.05690580606460571"
 },
 {
   "tweet_id": "1590073762523000833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590073753324527616",
   "Positive": "0.0009551666444167495",
   "Negative": "0.00014424229448195547",
   "Neutral": "0.9988971948623657",
   "Mixed": "3.3167041237902595e-06"
 },
 {
   "tweet_id": "1590073749998825473",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590073744307159041",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073744029929472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590073735650095104",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590073734450540544",
   "Positive": "0.047439418733119965",
   "Negative": "0.059426456689834595",
   "Neutral": "0.8931118845939636",
   "Mixed": "2.2221411200007424e-05"
 },
 {
   "tweet_id": "1590073728356188161",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073718721904640",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590073716968681473",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073713331822592",
   "Positive": "0.00044911765144206583",
   "Negative": "0.000526044808793813",
   "Neutral": "0.9990172386169434",
   "Mixed": "7.602894129377091e-06"
 },
 {
   "tweet_id": "1590073711469940736",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073709108563971",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073680205209600",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1590073679605805056",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590073626547859457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073623288565760",
   "Positive": "0.014527945779263973",
   "Negative": "0.8324823975563049",
   "Neutral": "0.07892569154500961",
   "Mixed": "0.07406389713287354"
 },
 {
   "tweet_id": "1590073621824737281",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590073621522706432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073620399030272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590073618628648960",
   "Positive": "0.12154664844274521",
   "Negative": "0.00022694189101457596",
   "Neutral": "0.8751150965690613",
   "Mixed": "0.0031114190351217985"
 },
 {
   "tweet_id": "1590073595296092160",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073580666384384",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590073576216223744",
   "Positive": "0.8833826780319214",
   "Negative": "0.002797085791826248",
   "Neutral": "0.11375857889652252",
   "Mixed": "6.165193190099671e-05"
 },
 {
   "tweet_id": "1590073462349254657",
   "Positive": "0.14556251466274261",
   "Negative": "0.015456827357411385",
   "Neutral": "0.8388972282409668",
   "Mixed": "8.34019883768633e-05"
 },
 {
   "tweet_id": "1590073417768013824",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590073412596428800",
   "Positive": "0.4467729926109314",
   "Negative": "0.003426366951316595",
   "Neutral": "0.5497671365737915",
   "Mixed": "3.3500382414786145e-05"
 },
 {
   "tweet_id": "1590073398935584768",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073396150214656",
   "Positive": "0.7329467535018921",
   "Negative": "0.004508896265178919",
   "Neutral": "0.2623961567878723",
   "Mixed": "0.00014809545245952904"
 },
 {
   "tweet_id": "1590073390341455873",
   "Positive": "0.013715669512748718",
   "Negative": "0.03436312824487686",
   "Neutral": "0.951155424118042",
   "Mixed": "0.000765684642829001"
 },
 {
   "tweet_id": "1590073388504317952",
   "Positive": "0.16932238638401031",
   "Negative": "0.030550526455044746",
   "Neutral": "0.7998504042625427",
   "Mixed": "0.00027667064568959177"
 },
 {
   "tweet_id": "1590073369890021376",
   "Positive": "0.002190416445955634",
   "Negative": "0.00020724070782307535",
   "Neutral": "0.9975994229316711",
   "Mixed": "2.9172826998546952e-06"
 },
 {
   "tweet_id": "1590073365406285830",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590073347890896896",
   "Positive": "0.006129532586783171",
   "Negative": "0.0007372026448138058",
   "Neutral": "0.9928101301193237",
   "Mixed": "0.000323079148074612"
 },
 {
   "tweet_id": "1590073320258818048",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590073289137090560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590073280362270720",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073275714936832",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073248632688648",
   "Positive": "0.9492383003234863",
   "Negative": "0.0003103892377112061",
   "Neutral": "0.050413578748703",
   "Mixed": "3.77273463527672e-05"
 },
 {
   "tweet_id": "1590073246531350528",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073245751201793",
   "Positive": "0.0005660424940288067",
   "Negative": "0.9783113598823547",
   "Neutral": "0.021119385957717896",
   "Mixed": "3.192006261087954e-06"
 },
 {
   "tweet_id": "1590073242190217216",
   "Positive": "0.4401146471500397",
   "Negative": "0.002820371650159359",
   "Neutral": "0.5567917823791504",
   "Mixed": "0.0002731669519562274"
 },
 {
   "tweet_id": "1590073236036820993",
   "Positive": "0.0007181200198829174",
   "Negative": "0.0001239195844391361",
   "Neutral": "0.9991540908813477",
   "Mixed": "3.7557979339908343e-06"
 },
 {
   "tweet_id": "1590073230102237184",
   "Positive": "0.9855958223342896",
   "Negative": "8.388231799472123e-05",
   "Neutral": "0.014302725903689861",
   "Mixed": "1.7554859368829057e-05"
 },
 {
   "tweet_id": "1590073216139415552",
   "Positive": "0.051224593073129654",
   "Negative": "0.0002303228829987347",
   "Neutral": "0.9485104084014893",
   "Mixed": "3.4743388823699206e-05"
 },
 {
   "tweet_id": "1590073208996528130",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590073197881618432",
   "Positive": "0.000807281001470983",
   "Negative": "0.00014615000691264868",
   "Neutral": "0.9990413784980774",
   "Mixed": "5.073399734101258e-06"
 },
 {
   "tweet_id": "1590073186452123648",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073166613082112",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590073144542662656",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073140067336192",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590073139517542400",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073130214518784",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073126250893312",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590073123734683648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590073122778062848",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073121784356868",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590073079342202880",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590073078734028800",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073078171987968",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073069489750016",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590073061377970176",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590073051215179777",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073029962665984",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073022941392896",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590073016683491329",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590073008261304321",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1590073002359939074",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072974774001664",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072966695768066",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590072966221791232",
   "Positive": "0.8034282326698303",
   "Negative": "0.0009271786548197269",
   "Neutral": "0.19561293721199036",
   "Mixed": "3.160132473567501e-05"
 },
 {
   "tweet_id": "1590072943421579265",
   "Positive": "0.0011724644573405385",
   "Negative": "5.553427035920322e-05",
   "Neutral": "0.9987632036209106",
   "Mixed": "8.837953828333411e-06"
 },
 {
   "tweet_id": "1590072937100767233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072922701385728",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072920692633601",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072916682870784",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072911158575104",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072910760529920",
   "Positive": "0.8070449233055115",
   "Negative": "0.05868536978960037",
   "Neutral": "0.13308338820934296",
   "Mixed": "0.001186363399028778"
 },
 {
   "tweet_id": "1590072905400193024",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072884059262976",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072882058899456",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072881727574017",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072859128659968",
   "Positive": "0.981848955154419",
   "Negative": "0.00017249610391445458",
   "Neutral": "0.017959829419851303",
   "Mixed": "1.8714730686042458e-05"
 },
 {
   "tweet_id": "1590072838131965952",
   "Positive": "0.000807281001470983",
   "Negative": "0.00014615000691264868",
   "Neutral": "0.9990413784980774",
   "Mixed": "5.073399734101258e-06"
 },
 {
   "tweet_id": "1590072817797980160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072815910518785",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072812135669760",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590072760147271680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072756787630080",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072744238288899",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072731285934080",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072725485518848",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072724252430336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072723308695553",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590072693894053888",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072692279238657",
   "Positive": "0.06769825518131256",
   "Negative": "0.09340788424015045",
   "Neutral": "0.07416870445013046",
   "Mixed": "0.7647251486778259"
 },
 {
   "tweet_id": "1590072686960553984",
   "Positive": "0.004777383059263229",
   "Negative": "0.6396101713180542",
   "Neutral": "0.3555387258529663",
   "Mixed": "7.363923941738904e-05"
 },
 {
   "tweet_id": "1590072677452349440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072661870522368",
   "Positive": "0.051781024783849716",
   "Negative": "0.6574544906616211",
   "Neutral": "0.2742078900337219",
   "Mixed": "0.016556531190872192"
 },
 {
   "tweet_id": "1590072657302945792",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590072653536440321",
   "Positive": "0.007962986826896667",
   "Negative": "0.7023251056671143",
   "Neutral": "0.28960463404655457",
   "Mixed": "0.000107309126178734"
 },
 {
   "tweet_id": "1590072651057614848",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072634171359232",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072596397445120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072591317753856",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072585353826304",
   "Positive": "0.009966250509023666",
   "Negative": "0.2635353207588196",
   "Neutral": "0.7264441251754761",
   "Mixed": "5.433513433672488e-05"
 },
 {
   "tweet_id": "1590072578655145984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072572900958208",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072572057890817",
   "Positive": "0.016865363344550133",
   "Negative": "0.0026556847151368856",
   "Neutral": "0.9801551699638367",
   "Mixed": "0.00032382510835304856"
 },
 {
   "tweet_id": "1590072541804367872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072538830209024",
   "Positive": "0.23547610640525818",
   "Negative": "0.0002831059682648629",
   "Neutral": "0.7642094492912292",
   "Mixed": "3.131431367364712e-05"
 },
 {
   "tweet_id": "1590072530500718593",
   "Positive": "0.006223118863999844",
   "Negative": "0.7857440114021301",
   "Neutral": "0.19950948655605316",
   "Mixed": "0.008523396216332912"
 },
 {
   "tweet_id": "1590072527757660160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590072522157871106",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1590072504798023680",
   "Positive": "0.0007181200198829174",
   "Negative": "0.0001239195844391361",
   "Neutral": "0.9991540908813477",
   "Mixed": "3.7557979339908343e-06"
 },
 {
   "tweet_id": "1590072494261940224",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590072492093497344",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072482249445376",
   "Positive": "0.20870865881443024",
   "Negative": "0.006522655952721834",
   "Neutral": "0.7847315669059753",
   "Mixed": "3.7044446798972785e-05"
 },
 {
   "tweet_id": "1590072472702894080",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590072462766534658",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590072461772873730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072420286664705",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072402012418048",
   "Positive": "0.936095654964447",
   "Negative": "0.0024195348378270864",
   "Neutral": "0.061189260333776474",
   "Mixed": "0.00029550670296885073"
 },
 {
   "tweet_id": "1590072393611235328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072386732273669",
   "Positive": "0.00851826835423708",
   "Negative": "0.06088767945766449",
   "Neutral": "0.9304960370063782",
   "Mixed": "9.804068395169452e-05"
 },
 {
   "tweet_id": "1590072379459665920",
   "Positive": "0.03341490402817726",
   "Negative": "0.03109550289809704",
   "Neutral": "0.9354752898216248",
   "Mixed": "1.4304800060926937e-05"
 },
 {
   "tweet_id": "1590072370718724097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072359595429889",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590072303051628544",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590072299692392450",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072299667206144",
   "Positive": "0.2659972906112671",
   "Negative": "0.029339518398046494",
   "Neutral": "0.7020487189292908",
   "Mixed": "0.002614472759887576"
 },
 {
   "tweet_id": "1590072296760578049",
   "Positive": "0.0032486706040799618",
   "Negative": "0.00013171107275411487",
   "Neutral": "0.996605396270752",
   "Mixed": "1.424282254447462e-05"
 },
 {
   "tweet_id": "1590072269069783040",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072255387553792",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072238841409537",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590072238686232576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072230746394624",
   "Positive": "0.09536126255989075",
   "Negative": "0.001242506317794323",
   "Neutral": "0.9032450914382935",
   "Mixed": "0.00015119295858312398"
 },
 {
   "tweet_id": "1590072225557716992",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072223565778946",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072173431230465",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590072168951742465",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590072165986336768",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590072165407170560",
   "Positive": "0.039692047983407974",
   "Negative": "0.23695756494998932",
   "Neutral": "0.5888166427612305",
   "Mixed": "0.13453377783298492"
 },
 {
   "tweet_id": "1590072162957680640",
   "Positive": "0.0017564111622050405",
   "Negative": "0.8233401775360107",
   "Neutral": "0.17389491200447083",
   "Mixed": "0.0010085100075230002"
 },
 {
   "tweet_id": "1590072147426222081",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590072146889703424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072142678589442",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590072125670318080",
   "Positive": "0.8031270503997803",
   "Negative": "0.0006388731417246163",
   "Neutral": "0.1961655467748642",
   "Mixed": "6.857530388515443e-05"
 },
 {
   "tweet_id": "1590072122835337216",
   "Positive": "0.18587976694107056",
   "Negative": "0.0015784548595547676",
   "Neutral": "0.8124961256980896",
   "Mixed": "4.5588374632643536e-05"
 },
 {
   "tweet_id": "1590072107609645056",
   "Positive": "0.02147573232650757",
   "Negative": "0.028954941779375076",
   "Neutral": "0.09765244275331497",
   "Mixed": "0.8519168496131897"
 },
 {
   "tweet_id": "1590072105550610432",
   "Positive": "0.07219413667917252",
   "Negative": "0.0029771875124424696",
   "Neutral": "0.9248149394989014",
   "Mixed": "1.3691927961190231e-05"
 },
 {
   "tweet_id": "1590072098382577670",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072087615795200",
   "Positive": "0.011757362633943558",
   "Negative": "0.5697818994522095",
   "Neutral": "0.14534147083759308",
   "Mixed": "0.2731192409992218"
 },
 {
   "tweet_id": "1590072086289993728",
   "Positive": "0.0015978344017639756",
   "Negative": "0.00013984469114802778",
   "Neutral": "0.9982591271400452",
   "Mixed": "3.2586228826403385e-06"
 },
 {
   "tweet_id": "1590072072424017920",
   "Positive": "0.006741772871464491",
   "Negative": "0.4690995216369629",
   "Neutral": "0.5026998519897461",
   "Mixed": "0.02145886793732643"
 },
 {
   "tweet_id": "1590072047417569280",
   "Positive": "0.15637263655662537",
   "Negative": "0.0004726835177280009",
   "Neutral": "0.8430799841880798",
   "Mixed": "7.46614023228176e-05"
 },
 {
   "tweet_id": "1590072045643378689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590072040878657538",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590072036034228225",
   "Positive": "0.10999610275030136",
   "Negative": "0.06905452907085419",
   "Neutral": "0.8174906969070435",
   "Mixed": "0.0034586950205266476"
 },
 {
   "tweet_id": "1590072027230392320",
   "Positive": "0.0036631282418966293",
   "Negative": "0.6225988268852234",
   "Neutral": "0.37308070063591003",
   "Mixed": "0.0006573498249053955"
 },
 {
   "tweet_id": "1590072022939602944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071976340893697",
   "Positive": "0.0033750664442777634",
   "Negative": "8.824789256323129e-05",
   "Neutral": "0.996528685092926",
   "Mixed": "7.94177958596265e-06"
 },
 {
   "tweet_id": "1590071966282629121",
   "Positive": "0.00958183966577053",
   "Negative": "0.5880991220474243",
   "Neutral": "0.39788302779197693",
   "Mixed": "0.004435984417796135"
 },
 {
   "tweet_id": "1590071961551785985",
   "Positive": "0.003340153256431222",
   "Negative": "0.6012637615203857",
   "Neutral": "0.39405637979507446",
   "Mixed": "0.0013397186994552612"
 },
 {
   "tweet_id": "1590071918702391296",
   "Positive": "0.24698889255523682",
   "Negative": "0.0005649285158142447",
   "Neutral": "0.7520267963409424",
   "Mixed": "0.0004194180073682219"
 },
 {
   "tweet_id": "1590071910477729792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071910167367680",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071894807838721",
   "Positive": "0.05577930435538292",
   "Negative": "0.007001139223575592",
   "Neutral": "0.9372138977050781",
   "Mixed": "5.689226327376673e-06"
 },
 {
   "tweet_id": "1590071889502035968",
   "Positive": "0.19749777019023895",
   "Negative": "0.00038788781967014074",
   "Neutral": "0.802061140537262",
   "Mixed": "5.3241397836245596e-05"
 },
 {
   "tweet_id": "1590071888226967552",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071885811036162",
   "Positive": "0.0068464516662061214",
   "Negative": "0.08257149904966354",
   "Neutral": "0.9102679491043091",
   "Mixed": "0.0003141884517390281"
 },
 {
   "tweet_id": "1590071873349746688",
   "Positive": "0.0002209942467743531",
   "Negative": "0.06148915737867355",
   "Neutral": "0.9382756948471069",
   "Mixed": "1.412046913173981e-05"
 },
 {
   "tweet_id": "1590071838352498688",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590071828500058115",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590071805834067968",
   "Positive": "0.11502526700496674",
   "Negative": "0.0011457586660981178",
   "Neutral": "0.8837839961051941",
   "Mixed": "4.4945449189981446e-05"
 },
 {
   "tweet_id": "1590071800918339584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071790713581569",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590071774838157313",
   "Positive": "0.06407225131988525",
   "Negative": "0.0023314442951232195",
   "Neutral": "0.007972290739417076",
   "Mixed": "0.9256239533424377"
 },
 {
   "tweet_id": "1590071774045011968",
   "Positive": "0.002357723657041788",
   "Negative": "0.00022257230011746287",
   "Neutral": "0.9974167346954346",
   "Mixed": "2.9873526727897115e-06"
 },
 {
   "tweet_id": "1590071757448564736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071752893530112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071747550023681",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1590071737437519872",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590071731867504641",
   "Positive": "0.0019158048089593649",
   "Negative": "0.0001476739562349394",
   "Neutral": "0.9979264736175537",
   "Mixed": "9.998081623052713e-06"
 },
 {
   "tweet_id": "1590071725399900160",
   "Positive": "0.08025270700454712",
   "Negative": "0.0011250039096921682",
   "Neutral": "0.9186056852340698",
   "Mixed": "1.6577254427829757e-05"
 },
 {
   "tweet_id": "1590071712888111110",
   "Positive": "0.14314280450344086",
   "Negative": "0.006141503341495991",
   "Neutral": "0.8506917357444763",
   "Mixed": "2.399330332991667e-05"
 },
 {
   "tweet_id": "1590071711810355200",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590071676833628160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071668604817409",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590071667614703618",
   "Positive": "0.18438585102558136",
   "Negative": "0.028400802984833717",
   "Neutral": "0.7870171666145325",
   "Mixed": "0.00019614305347204208"
 },
 {
   "tweet_id": "1590071663076540417",
   "Positive": "0.7299208045005798",
   "Negative": "0.0013352006208151579",
   "Neutral": "0.26860788464546204",
   "Mixed": "0.00013611261965706944"
 },
 {
   "tweet_id": "1590071659603574784",
   "Positive": "0.010148868896067142",
   "Negative": "0.28206589818000793",
   "Neutral": "0.6711090207099915",
   "Mixed": "0.03667625039815903"
 },
 {
   "tweet_id": "1590071652448014336",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590071629949767680",
   "Positive": "0.00045004714047536254",
   "Negative": "0.00010173723421758041",
   "Neutral": "0.9994431138038635",
   "Mixed": "5.129124929226236e-06"
 },
 {
   "tweet_id": "1590071628167532544",
   "Positive": "0.02211834117770195",
   "Negative": "0.0005402171518653631",
   "Neutral": "0.9773331880569458",
   "Mixed": "8.28922293294454e-06"
 },
 {
   "tweet_id": "1590071613961437184",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071594835013632",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590071585557614592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071584248954881",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071579006103552",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1590071541873905666",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071534806532096",
   "Positive": "0.14803330600261688",
   "Negative": "0.008154146373271942",
   "Neutral": "0.8355720043182373",
   "Mixed": "0.008240600116550922"
 },
 {
   "tweet_id": "1590071532596133889",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590071527348703232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071504506880002",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071499460730880",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1590071480607707137",
   "Positive": "0.001288092928007245",
   "Negative": "0.00010176615614909679",
   "Neutral": "0.9986057877540588",
   "Mixed": "4.409034318086924e-06"
 },
 {
   "tweet_id": "1590071467743453185",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071439763607552",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590071433908334592",
   "Positive": "0.1611371487379074",
   "Negative": "0.513225257396698",
   "Neutral": "0.3173321485519409",
   "Mixed": "0.008305467665195465"
 },
 {
   "tweet_id": "1590071433165942784",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590071411858886656",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071407060275201",
   "Positive": "0.01627262495458126",
   "Negative": "0.7311682105064392",
   "Neutral": "0.2501344084739685",
   "Mixed": "0.002424728823825717"
 },
 {
   "tweet_id": "1590071399405981697",
   "Positive": "0.8558648824691772",
   "Negative": "0.0004629120521713048",
   "Neutral": "0.1432267129421234",
   "Mixed": "0.00044552749022841454"
 },
 {
   "tweet_id": "1590071390270816256",
   "Positive": "0.15489067137241364",
   "Negative": "0.0021354281343519688",
   "Neutral": "0.842963695526123",
   "Mixed": "1.0263836884405464e-05"
 },
 {
   "tweet_id": "1590071379961188352",
   "Positive": "0.0008355025202035904",
   "Negative": "0.9679173827171326",
   "Neutral": "0.031109469011425972",
   "Mixed": "0.00013768744247499853"
 },
 {
   "tweet_id": "1590071377260056576",
   "Positive": "0.6623578071594238",
   "Negative": "0.0002968566259369254",
   "Neutral": "0.33731651306152344",
   "Mixed": "2.8803835448343307e-05"
 },
 {
   "tweet_id": "1590071362131226624",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590071333194723328",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590071329952509952",
   "Positive": "0.004135404713451862",
   "Negative": "0.03646250441670418",
   "Neutral": "0.9593461155891418",
   "Mixed": "5.587192936218344e-05"
 },
 {
   "tweet_id": "1590071322779918336",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071307181633536",
   "Positive": "0.0011864217231050134",
   "Negative": "6.710558227496222e-05",
   "Neutral": "0.9987391829490662",
   "Mixed": "7.2179705057351384e-06"
 },
 {
   "tweet_id": "1590071275141332992",
   "Positive": "0.1622842252254486",
   "Negative": "0.05356559157371521",
   "Neutral": "0.3473444879055023",
   "Mixed": "0.43680569529533386"
 },
 {
   "tweet_id": "1590071256564793345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071255201624064",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071254069149696",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590071247211466755",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071217586741248",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1590071215687106561",
   "Positive": "0.026236657053232193",
   "Negative": "0.001046784920617938",
   "Neutral": "0.9727057814598083",
   "Mixed": "1.0841721632459667e-05"
 },
 {
   "tweet_id": "1590071211286925312",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590071188277334016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071143318589441",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590071125245333505",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590071107784421377",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590071104206385152",
   "Positive": "0.00013988587306812406",
   "Negative": "0.9970144033432007",
   "Neutral": "0.0028177464846521616",
   "Mixed": "2.793622115859762e-05"
 },
 {
   "tweet_id": "1590071085822709761",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590071077845504002",
   "Positive": "0.03457978740334511",
   "Negative": "0.38510817289352417",
   "Neutral": "0.5801919102668762",
   "Mixed": "0.0001201292616315186"
 },
 {
   "tweet_id": "1590071058576515072",
   "Positive": "0.9121055006980896",
   "Negative": "0.00010188875603489578",
   "Neutral": "0.08773694932460785",
   "Mixed": "5.5684147810097784e-05"
 },
 {
   "tweet_id": "1590071058056769536",
   "Positive": "0.0039726062677800655",
   "Negative": "0.29245486855506897",
   "Neutral": "0.7029632925987244",
   "Mixed": "0.0006092589464969933"
 },
 {
   "tweet_id": "1590071042160357377",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590071036594499586",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590071031221608448",
   "Positive": "0.5018821954727173",
   "Negative": "0.0022410384844988585",
   "Neutral": "0.02682604268193245",
   "Mixed": "0.46905073523521423"
 },
 {
   "tweet_id": "1590070981187358721",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590070947066675200",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590070942457221120",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590070941488672768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070939919994880",
   "Positive": "0.015390366315841675",
   "Negative": "0.19648006558418274",
   "Neutral": "0.7880763411521912",
   "Mixed": "5.3131669119466096e-05"
 },
 {
   "tweet_id": "1590070934173802496",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590070930507632640",
   "Positive": "0.005462869070470333",
   "Negative": "0.11438032239675522",
   "Neutral": "0.8799248933792114",
   "Mixed": "0.00023188214981928468"
 },
 {
   "tweet_id": "1590070926875709443",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070920630087681",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590070917891522566",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070903312109568",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070885297246208",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590070880146980864",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590070875541282817",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070873209573376",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070870449754113",
   "Positive": "0.4742512106895447",
   "Negative": "0.00026641605654731393",
   "Neutral": "0.5252972841262817",
   "Mixed": "0.00018509481742512435"
 },
 {
   "tweet_id": "1590070863197458432",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590070853022384129",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070844323422208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070811792412674",
   "Positive": "0.00491586048156023",
   "Negative": "6.754526839358732e-05",
   "Neutral": "0.9950057864189148",
   "Mixed": "1.0765478691610042e-05"
 },
 {
   "tweet_id": "1590070787842899968",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070787599634432",
   "Positive": "0.11765648424625397",
   "Negative": "0.17515769600868225",
   "Neutral": "0.7061373591423035",
   "Mixed": "0.001048541977070272"
 },
 {
   "tweet_id": "1590070777193254913",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070768418709504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070728786776064",
   "Positive": "0.033646535128355026",
   "Negative": "0.007871787995100021",
   "Neutral": "0.95847088098526",
   "Mixed": "1.082362541637849e-05"
 },
 {
   "tweet_id": "1590070713675026432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070708901552128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070699557019649",
   "Positive": "0.19271889328956604",
   "Negative": "0.0027381591498851776",
   "Neutral": "0.8041813373565674",
   "Mixed": "0.00036157231079414487"
 },
 {
   "tweet_id": "1590070677683703809",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590070675431378944",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070659505274880",
   "Positive": "0.2738310694694519",
   "Negative": "0.0008220967720262706",
   "Neutral": "0.7252191305160522",
   "Mixed": "0.00012768608576152474"
 },
 {
   "tweet_id": "1590070618564984832",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1590070591847305216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070548960538625",
   "Positive": "0.8058222532272339",
   "Negative": "0.003084228141233325",
   "Neutral": "0.19104404747486115",
   "Mixed": "4.943544263369404e-05"
 },
 {
   "tweet_id": "1590070544858484741",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590070510896832512",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590070494979854336",
   "Positive": "0.006358196027576923",
   "Negative": "0.2929883301258087",
   "Neutral": "0.6857765316963196",
   "Mixed": "0.01487683318555355"
 },
 {
   "tweet_id": "1590070489351065600",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070482644406272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070472556699648",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1590070464046829570",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070451799494656",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590070434397302785",
   "Positive": "0.000559891399461776",
   "Negative": "0.7215156555175781",
   "Neutral": "0.27789953351020813",
   "Mixed": "2.4907341867219657e-05"
 },
 {
   "tweet_id": "1590070428252659712",
   "Positive": "0.04870917648077011",
   "Negative": "0.0002008364099310711",
   "Neutral": "0.9509977102279663",
   "Mixed": "9.228361159330234e-05"
 },
 {
   "tweet_id": "1590070414562144256",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590070412591124483",
   "Positive": "0.5199272632598877",
   "Negative": "0.08919092267751694",
   "Neutral": "0.3887826204299927",
   "Mixed": "0.0020991978235542774"
 },
 {
   "tweet_id": "1590070409810284546",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590070408648491008",
   "Positive": "9.066954226000234e-05",
   "Negative": "0.9965512752532959",
   "Neutral": "0.0032851146534085274",
   "Mixed": "7.294108945643529e-05"
 },
 {
   "tweet_id": "1590070396245901314",
   "Positive": "0.0008515426306985319",
   "Negative": "0.9781815409660339",
   "Neutral": "0.020902378484606743",
   "Mixed": "6.445363396778703e-05"
 },
 {
   "tweet_id": "1590070374045462528",
   "Positive": "0.0030624731443822384",
   "Negative": "0.010516089387238026",
   "Neutral": "0.9862196445465088",
   "Mixed": "0.0002017192164203152"
 },
 {
   "tweet_id": "1590070368844144640",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070339148873728",
   "Positive": "0.003931306768208742",
   "Negative": "0.00032544342684559524",
   "Neutral": "0.9956978559494019",
   "Mixed": "4.546315176412463e-05"
 },
 {
   "tweet_id": "1590070336812261376",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070334585475072",
   "Positive": "0.036334771662950516",
   "Negative": "0.013078421354293823",
   "Neutral": "0.9505770802497864",
   "Mixed": "9.782811503100675e-06"
 },
 {
   "tweet_id": "1590070326846959616",
   "Positive": "0.8863736391067505",
   "Negative": "0.0026451516896486282",
   "Neutral": "0.11095923185348511",
   "Mixed": "2.1920584913459606e-05"
 },
 {
   "tweet_id": "1590070311701327872",
   "Positive": "0.02645941451191902",
   "Negative": "0.3083750903606415",
   "Neutral": "0.663765549659729",
   "Mixed": "0.0013999708462506533"
 },
 {
   "tweet_id": "1590070309897768960",
   "Positive": "0.01011961791664362",
   "Negative": "0.0003020654257852584",
   "Neutral": "0.9895748496055603",
   "Mixed": "3.5713078432308976e-06"
 },
 {
   "tweet_id": "1590070308828237824",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1590070308052299776",
   "Positive": "0.03726319596171379",
   "Negative": "0.05746028199791908",
   "Neutral": "0.8836216330528259",
   "Mixed": "0.02165488339960575"
 },
 {
   "tweet_id": "1590070299399114752",
   "Positive": "0.22888444364070892",
   "Negative": "0.09321728348731995",
   "Neutral": "0.4537174701690674",
   "Mixed": "0.22418075799942017"
 },
 {
   "tweet_id": "1590070291254116353",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590070262141431808",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590070228524072961",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070194768318464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590070194361470976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590070143258071041",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070125310672896",
   "Positive": "0.003590450156480074",
   "Negative": "0.6632863879203796",
   "Neutral": "0.32998883724212646",
   "Mixed": "0.003134252969175577"
 },
 {
   "tweet_id": "1590070114950729728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070099247267840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590070093320687617",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590070089394839552",
   "Positive": "0.014337998814880848",
   "Negative": "0.6195677518844604",
   "Neutral": "0.3654029667377472",
   "Mixed": "0.0006912195822224021"
 },
 {
   "tweet_id": "1590070082788818944",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590070070633697280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590070066787516416",
   "Positive": "0.03457978740334511",
   "Negative": "0.38510817289352417",
   "Neutral": "0.5801919102668762",
   "Mixed": "0.0001201292616315186"
 },
 {
   "tweet_id": "1590070057392300035",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590070024839909376",
   "Positive": "0.14925101399421692",
   "Negative": "0.001839898992329836",
   "Neutral": "0.8488994836807251",
   "Mixed": "9.662203410698567e-06"
 },
 {
   "tweet_id": "1590070018691457025",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069972780609536",
   "Positive": "0.03750765323638916",
   "Negative": "0.07079216837882996",
   "Neutral": "0.350668728351593",
   "Mixed": "0.5410314202308655"
 },
 {
   "tweet_id": "1590069967231528960",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590069950164926466",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069948013244416",
   "Positive": "0.13542285561561584",
   "Negative": "0.7130717635154724",
   "Neutral": "0.15110862255096436",
   "Mixed": "0.00039682610076852143"
 },
 {
   "tweet_id": "1590069946758766592",
   "Positive": "0.00649140914902091",
   "Negative": "0.3105306923389435",
   "Neutral": "0.6822989583015442",
   "Mixed": "0.0006789023173041642"
 },
 {
   "tweet_id": "1590069938701860866",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1590069934369144832",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590069918623752192",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590069917738733568",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069914962137088",
   "Positive": "0.0015506886411458254",
   "Negative": "0.023833954706788063",
   "Neutral": "0.9746110439300537",
   "Mixed": "4.292616722523235e-06"
 },
 {
   "tweet_id": "1590069909597605889",
   "Positive": "0.007438279688358307",
   "Negative": "0.0005090825725346804",
   "Neutral": "0.9920313358306885",
   "Mixed": "2.1279671273077838e-05"
 },
 {
   "tweet_id": "1590069897404420096",
   "Positive": "0.009455271065235138",
   "Negative": "0.5468717813491821",
   "Neutral": "0.3922077417373657",
   "Mixed": "0.05146528035402298"
 },
 {
   "tweet_id": "1590069888252801024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069864194244608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069857516941313",
   "Positive": "0.016865363344550133",
   "Negative": "0.0026556847151368856",
   "Neutral": "0.9801551699638367",
   "Mixed": "0.00032382510835304856"
 },
 {
   "tweet_id": "1590069832460161025",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069824495169537",
   "Positive": "0.0007104745018295944",
   "Negative": "0.0026090366300195456",
   "Neutral": "0.9966492056846619",
   "Mixed": "3.127750460407697e-05"
 },
 {
   "tweet_id": "1590069821752102912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069802596732928",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069791238127616",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1590069737652113408",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069736678703106",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590069733206142978",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590069728596611072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590069663635234816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069662926376960",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069654269009920",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069651094253568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069626830221312",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590069618017566720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069607926181889",
   "Positive": "0.011255988851189613",
   "Negative": "0.00034413704997859895",
   "Neutral": "0.9883954524993896",
   "Mixed": "4.468627139431192e-06"
 },
 {
   "tweet_id": "1590069603274588161",
   "Positive": "0.008669444359838963",
   "Negative": "0.0005959279951639473",
   "Neutral": "0.9907135963439941",
   "Mixed": "2.10529105970636e-05"
 },
 {
   "tweet_id": "1590069576234332160",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069573482872832",
   "Positive": "0.0001800784666556865",
   "Negative": "0.9934612512588501",
   "Neutral": "0.006340306717902422",
   "Mixed": "1.8321203242521733e-05"
 },
 {
   "tweet_id": "1590069536975294464",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069521708380160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069520026472448",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590069517031739392",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590069513458176000",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069463709536256",
   "Positive": "0.004987520631402731",
   "Negative": "0.39961305260658264",
   "Neutral": "0.5950941443443298",
   "Mixed": "0.0003053197870030999"
 },
 {
   "tweet_id": "1590069462723866626",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590069460555075584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069435280543744",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069431535022080",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1590069424870264833",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069412291543040",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1590069400635604992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590069387041837057",
   "Positive": "0.8777218461036682",
   "Negative": "0.005322344601154327",
   "Neutral": "0.11679999530315399",
   "Mixed": "0.00015580045874230564"
 },
 {
   "tweet_id": "1590069359799848962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590069347418275841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069345883148288",
   "Positive": "0.007737074978649616",
   "Negative": "0.0005515613011084497",
   "Neutral": "0.9917070269584656",
   "Mixed": "4.211019586364273e-06"
 },
 {
   "tweet_id": "1590069342393487362",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069332066807809",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069318221717504",
   "Positive": "0.000604554486926645",
   "Negative": "0.02649499475955963",
   "Neutral": "0.972894549369812",
   "Mixed": "5.857240466866642e-06"
 },
 {
   "tweet_id": "1590069305026441216",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069303118016512",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590069285447401473",
   "Positive": "0.12360686808824539",
   "Negative": "0.012030798010528088",
   "Neutral": "0.8642861843109131",
   "Mixed": "7.618079689564183e-05"
 },
 {
   "tweet_id": "1590069277276901376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069272499597312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069264148566025",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069249888120832",
   "Positive": "0.754996657371521",
   "Negative": "0.0005865335697308183",
   "Neutral": "0.24438469111919403",
   "Mixed": "3.208212001482025e-05"
 },
 {
   "tweet_id": "1590069247010828288",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069241499521024",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1590069233232510977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069232917950464",
   "Positive": "0.0008365479297935963",
   "Negative": "0.9484919309616089",
   "Neutral": "0.0506490133702755",
   "Mixed": "2.2461506887339056e-05"
 },
 {
   "tweet_id": "1590069227951558656",
   "Positive": "0.008532152511179447",
   "Negative": "0.21107634902000427",
   "Neutral": "0.08017101883888245",
   "Mixed": "0.70022052526474"
 },
 {
   "tweet_id": "1590069226118975488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069221459132416",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590069214651764736",
   "Positive": "0.0027720422949641943",
   "Negative": "0.6944943070411682",
   "Neutral": "0.3026379346847534",
   "Mixed": "9.570174006512389e-05"
 },
 {
   "tweet_id": "1590069209111097345",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069209018798080",
   "Positive": "0.002357723657041788",
   "Negative": "0.00022257230011746287",
   "Neutral": "0.9974167346954346",
   "Mixed": "2.9873526727897115e-06"
 },
 {
   "tweet_id": "1590069201049649152",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590069197828419584",
   "Positive": "0.2017580270767212",
   "Negative": "0.0025587158743292093",
   "Neutral": "0.7956672310829163",
   "Mixed": "1.601899930392392e-05"
 },
 {
   "tweet_id": "1590069179352162304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069145315741696",
   "Positive": "0.2738310694694519",
   "Negative": "0.0008220967720262706",
   "Neutral": "0.7252191305160522",
   "Mixed": "0.00012768608576152474"
 },
 {
   "tweet_id": "1590069142370996224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069138353197056",
   "Positive": "0.02765919640660286",
   "Negative": "0.28135716915130615",
   "Neutral": "0.665449857711792",
   "Mixed": "0.025533735752105713"
 },
 {
   "tweet_id": "1590069133106098177",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590069130975014912",
   "Positive": "0.0022695588413625956",
   "Negative": "0.0001258522243006155",
   "Neutral": "0.997588038444519",
   "Mixed": "1.6479558325954713e-05"
 },
 {
   "tweet_id": "1590069120632246272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590069102764523520",
   "Positive": "0.0014235101407393813",
   "Negative": "0.00045626406790688634",
   "Neutral": "0.99811851978302",
   "Mixed": "1.7711982991386321e-06"
 },
 {
   "tweet_id": "1590069072829775872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590069069364879361",
   "Positive": "0.2738310694694519",
   "Negative": "0.0008220967720262706",
   "Neutral": "0.7252191305160522",
   "Mixed": "0.00012768608576152474"
 },
 {
   "tweet_id": "1590069036125392897",
   "Positive": "0.0008607638301327825",
   "Negative": "0.8987235426902771",
   "Neutral": "0.10009758174419403",
   "Mixed": "0.0003181522188242525"
 },
 {
   "tweet_id": "1590069030672814080",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590069023542513664",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068995956543489",
   "Positive": "0.8031270503997803",
   "Negative": "0.0006388731417246163",
   "Neutral": "0.1961655467748642",
   "Mixed": "6.857530388515443e-05"
 },
 {
   "tweet_id": "1590068968139935744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068966428393474",
   "Positive": "0.0007426090887747705",
   "Negative": "0.00034619937650859356",
   "Neutral": "0.9989020824432373",
   "Mixed": "9.08145921130199e-06"
 },
 {
   "tweet_id": "1590068964096638977",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068955669897216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590068912384712704",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068806592139264",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068721934303232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590068707077652480",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068700488441856",
   "Positive": "0.009440228343009949",
   "Negative": "0.8932609558105469",
   "Neutral": "0.09599023312330246",
   "Mixed": "0.0013085975078865886"
 },
 {
   "tweet_id": "1590068690577678337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590068682524618752",
   "Positive": "0.04548228159546852",
   "Negative": "0.00034586957190185785",
   "Neutral": "0.9541577696800232",
   "Mixed": "1.4106572962191422e-05"
 },
 {
   "tweet_id": "1590068681882542080",
   "Positive": "0.0010397342266514897",
   "Negative": "0.8678176403045654",
   "Neutral": "0.13110892474651337",
   "Mixed": "3.374620064278133e-05"
 },
 {
   "tweet_id": "1590068680028823553",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590068676816162816",
   "Positive": "0.006171129178255796",
   "Negative": "0.0006831256323494017",
   "Neutral": "0.9931433200836182",
   "Mixed": "2.341041181352921e-06"
 },
 {
   "tweet_id": "1590068636307591169",
   "Positive": "0.08377818763256073",
   "Negative": "0.002420324133709073",
   "Neutral": "0.9135153889656067",
   "Mixed": "0.00028602470410987735"
 },
 {
   "tweet_id": "1590068627964772352",
   "Positive": "0.30726417899131775",
   "Negative": "0.0012014011153951287",
   "Neutral": "0.6893890500068665",
   "Mixed": "0.002145362552255392"
 },
 {
   "tweet_id": "1590068627033980928",
   "Positive": "0.03982372209429741",
   "Negative": "0.0002715291047934443",
   "Neutral": "0.9598954319953918",
   "Mixed": "9.251462870452087e-06"
 },
 {
   "tweet_id": "1590068610164477952",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590068576505004033",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068573065850881",
   "Positive": "0.5541985034942627",
   "Negative": "0.009394511580467224",
   "Neutral": "0.43624719977378845",
   "Mixed": "0.00015976988652255386"
 },
 {
   "tweet_id": "1590068568452136960",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590068548453691392",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590068513192173568",
   "Positive": "0.0002209942467743531",
   "Negative": "0.06148915737867355",
   "Neutral": "0.9382756948471069",
   "Mixed": "1.412046913173981e-05"
 },
 {
   "tweet_id": "1590068512168763392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068510369394688",
   "Positive": "0.012126973830163479",
   "Negative": "0.00016743189189583063",
   "Neutral": "0.9876937866210938",
   "Mixed": "1.1764344890252687e-05"
 },
 {
   "tweet_id": "1590068509119508480",
   "Positive": "0.12944242358207703",
   "Negative": "0.002186686499044299",
   "Neutral": "0.8668174147605896",
   "Mixed": "0.0015535459388047457"
 },
 {
   "tweet_id": "1590068508041232385",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590068507709882368",
   "Positive": "0.14863207936286926",
   "Negative": "0.28703248500823975",
   "Neutral": "0.557265043258667",
   "Mixed": "0.007070321124047041"
 },
 {
   "tweet_id": "1590068501607518208",
   "Positive": "0.11087550967931747",
   "Negative": "0.005134060047566891",
   "Neutral": "0.8839762210845947",
   "Mixed": "1.4233966794563457e-05"
 },
 {
   "tweet_id": "1590068498620829696",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068485186805760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068477553147904",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590068465632940032",
   "Positive": "0.002320882398635149",
   "Negative": "8.940274710766971e-05",
   "Neutral": "0.9975777268409729",
   "Mixed": "1.1980073395534419e-05"
 },
 {
   "tweet_id": "1590068462403342337",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068446469165057",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590068439007494144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068433978523649",
   "Positive": "0.037740014493465424",
   "Negative": "0.0018891424406319857",
   "Neutral": "0.9603606462478638",
   "Mixed": "1.0219870091532357e-05"
 },
 {
   "tweet_id": "1590068424797229056",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068420237996033",
   "Positive": "8.15344974398613e-05",
   "Negative": "0.0163788590580225",
   "Neutral": "0.9835220575332642",
   "Mixed": "1.7599873899598606e-05"
 },
 {
   "tweet_id": "1590068415418417152",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590068415385206785",
   "Positive": "0.00043665236444212496",
   "Negative": "9.937436698237434e-05",
   "Neutral": "0.9994568228721619",
   "Mixed": "7.144793926272541e-06"
 },
 {
   "tweet_id": "1590068402332188673",
   "Positive": "0.02768521010875702",
   "Negative": "0.002524154493585229",
   "Neutral": "0.9697270393371582",
   "Mixed": "6.355052755679935e-05"
 },
 {
   "tweet_id": "1590068392379416576",
   "Positive": "0.03552074730396271",
   "Negative": "0.0007856888114474714",
   "Neutral": "0.9636753797531128",
   "Mixed": "1.814047027437482e-05"
 },
 {
   "tweet_id": "1590068390739050496",
   "Positive": "0.995668888092041",
   "Negative": "0.00020664645126089454",
   "Neutral": "0.00411535007879138",
   "Mixed": "9.09553091332782e-06"
 },
 {
   "tweet_id": "1590068358309089280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068352885874688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590068323852902403",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1590068294345973760",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590068272879529985",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590068248250548224",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590068241145397249",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590068214096367616",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590068195867918336",
   "Positive": "0.01106876228004694",
   "Negative": "0.0013436259469017386",
   "Neutral": "0.9875807762145996",
   "Mixed": "6.8650338107545394e-06"
 },
 {
   "tweet_id": "1590068176058208256",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068175231913984",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068132852666369",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590068130243833856",
   "Positive": "0.6251183152198792",
   "Negative": "0.01223383005708456",
   "Neutral": "0.3625257611274719",
   "Mixed": "0.00012203067308291793"
 },
 {
   "tweet_id": "1590068127114874880",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068126515101701",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590068109645606914",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068107057717248",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068104138469376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068088510480384",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590068069401268224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068066062245888",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068062258352129",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590068052426911744",
   "Positive": "0.0015875725075602531",
   "Negative": "0.0005957406829111278",
   "Neutral": "0.9978145360946655",
   "Mixed": "2.1971970909362426e-06"
 },
 {
   "tweet_id": "1590068036828270592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590068028607430656",
   "Positive": "0.13555850088596344",
   "Negative": "0.0031240317039191723",
   "Neutral": "0.861270010471344",
   "Mixed": "4.73922336823307e-05"
 },
 {
   "tweet_id": "1590068027189760000",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590068017576017920",
   "Positive": "0.0016364249167963862",
   "Negative": "0.00011153670493513346",
   "Neutral": "0.9982452392578125",
   "Mixed": "6.731619123456767e-06"
 },
 {
   "tweet_id": "1590067988589613056",
   "Positive": "0.002366422675549984",
   "Negative": "0.17920948565006256",
   "Neutral": "0.8184130191802979",
   "Mixed": "1.1143100891786162e-05"
 },
 {
   "tweet_id": "1590067976983543808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067972713766912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067953395204097",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067940447002624",
   "Positive": "0.017344648018479347",
   "Negative": "0.00038586987648159266",
   "Neutral": "0.9822632670402527",
   "Mixed": "6.193741228344152e-06"
 },
 {
   "tweet_id": "1590067934285549568",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067926132195328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067918293041153",
   "Positive": "0.004195767920464277",
   "Negative": "0.5359370708465576",
   "Neutral": "0.2195044606924057",
   "Mixed": "0.2403627634048462"
 },
 {
   "tweet_id": "1590067910219038720",
   "Positive": "0.017170414328575134",
   "Negative": "0.0006834736559540033",
   "Neutral": "0.9820892810821533",
   "Mixed": "5.681332550011575e-05"
 },
 {
   "tweet_id": "1590067896985980928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067896113598465",
   "Positive": "0.0005717913154512644",
   "Negative": "0.0001605802244739607",
   "Neutral": "0.9992642998695374",
   "Mixed": "3.361464223416988e-06"
 },
 {
   "tweet_id": "1590067888232181761",
   "Positive": "0.1587197184562683",
   "Negative": "0.02212613634765148",
   "Neutral": "0.8102715015411377",
   "Mixed": "0.008882607333362103"
 },
 {
   "tweet_id": "1590067884079820800",
   "Positive": "0.09877188503742218",
   "Negative": "0.015100033953785896",
   "Neutral": "0.8860532641410828",
   "Mixed": "7.489975541830063e-05"
 },
 {
   "tweet_id": "1590067867604910080",
   "Positive": "0.8554198741912842",
   "Negative": "0.0010696648387238383",
   "Neutral": "0.1419721394777298",
   "Mixed": "0.0015383106656372547"
 },
 {
   "tweet_id": "1590067865037983744",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590067861049184256",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590067859786342402",
   "Positive": "0.0011674333363771439",
   "Negative": "0.00025920025655068457",
   "Neutral": "0.9985706806182861",
   "Mixed": "2.5997387638199143e-06"
 },
 {
   "tweet_id": "1590067856536137729",
   "Positive": "0.003468435723334551",
   "Negative": "0.3375319242477417",
   "Neutral": "0.658545732498169",
   "Mixed": "0.00045398325892165303"
 },
 {
   "tweet_id": "1590067844741763072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067823426273280",
   "Positive": "0.016317199915647507",
   "Negative": "0.013639422133564949",
   "Neutral": "0.969997227191925",
   "Mixed": "4.6169054257916287e-05"
 },
 {
   "tweet_id": "1590067821798903809",
   "Positive": "0.00198917998932302",
   "Negative": "0.00023505309945903718",
   "Neutral": "0.9977732300758362",
   "Mixed": "2.5273902792832814e-06"
 },
 {
   "tweet_id": "1590067812504305664",
   "Positive": "0.08908481150865555",
   "Negative": "0.003275383962318301",
   "Neutral": "0.9076308012008667",
   "Mixed": "8.928891475079581e-06"
 },
 {
   "tweet_id": "1590067701137178625",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067697861398528",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590067683844059139",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590067681331654656",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590067646300844032",
   "Positive": "0.12295272201299667",
   "Negative": "0.0005186108173802495",
   "Neutral": "0.8765056729316711",
   "Mixed": "2.302044777025003e-05"
 },
 {
   "tweet_id": "1590067643817787392",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1590067640747589633",
   "Positive": "0.2866560220718384",
   "Negative": "0.001661320449784398",
   "Neutral": "0.7089105248451233",
   "Mixed": "0.0027720166835933924"
 },
 {
   "tweet_id": "1590067632186609669",
   "Positive": "0.11485549062490463",
   "Negative": "0.00023445073748007417",
   "Neutral": "0.884884238243103",
   "Mixed": "2.587734707049094e-05"
 },
 {
   "tweet_id": "1590067602646519808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067591430930433",
   "Positive": "9.77113377302885e-05",
   "Negative": "0.0003843556623905897",
   "Neutral": "0.00025589033612050116",
   "Mixed": "0.9992620348930359"
 },
 {
   "tweet_id": "1590067572665618432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067569666715648",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590067559118045184",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590067558631165952",
   "Positive": "0.08314964920282364",
   "Negative": "0.04473402351140976",
   "Neutral": "0.7419491410255432",
   "Mixed": "0.13016727566719055"
 },
 {
   "tweet_id": "1590067556483670016",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067528977747969",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067522887639041",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067520794689536",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590067516142809089",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067493762396160",
   "Positive": "0.009605318307876587",
   "Negative": "0.5459522604942322",
   "Neutral": "0.22050854563713074",
   "Mixed": "0.2239338457584381"
 },
 {
   "tweet_id": "1590067481372393472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067477589168130",
   "Positive": "0.5499171018600464",
   "Negative": "0.027360325679183006",
   "Neutral": "0.421550989151001",
   "Mixed": "0.001171627314761281"
 },
 {
   "tweet_id": "1590067452360392706",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590067432085155840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067417170214912",
   "Positive": "0.6095963716506958",
   "Negative": "0.016214704141020775",
   "Neutral": "0.3531244397163391",
   "Mixed": "0.021064555272459984"
 },
 {
   "tweet_id": "1590067413684748288",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590067389609447424",
   "Positive": "0.00047052514855749905",
   "Negative": "0.0021176135633140802",
   "Neutral": "0.9974084496498108",
   "Mixed": "3.464362862359849e-06"
 },
 {
   "tweet_id": "1590067388846080000",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590067382323552256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067374769995777",
   "Positive": "0.08314964920282364",
   "Negative": "0.04473402351140976",
   "Neutral": "0.7419491410255432",
   "Mixed": "0.13016727566719055"
 },
 {
   "tweet_id": "1590067370865078272",
   "Positive": "0.004790788982063532",
   "Negative": "0.0006578843458555639",
   "Neutral": "0.994543194770813",
   "Mixed": "8.047294613788836e-06"
 },
 {
   "tweet_id": "1590067364024160259",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590067358059868160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067353957847040",
   "Positive": "0.1112721711397171",
   "Negative": "0.0653381273150444",
   "Neutral": "0.8198379874229431",
   "Mixed": "0.003551679430529475"
 },
 {
   "tweet_id": "1590067343501459456",
   "Positive": "0.04275412857532501",
   "Negative": "0.00801636092364788",
   "Neutral": "0.9492177963256836",
   "Mixed": "1.1618478310992941e-05"
 },
 {
   "tweet_id": "1590067343459512320",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067335859441664",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590067332290088962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590067324190879744",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067270146961408",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067259154006016",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067257924689920",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1590067215092436992",
   "Positive": "0.00045004714047536254",
   "Negative": "0.00010173723421758041",
   "Neutral": "0.9994431138038635",
   "Mixed": "5.129124929226236e-06"
 },
 {
   "tweet_id": "1590067214480453634",
   "Positive": "0.005385073833167553",
   "Negative": "0.6115872859954834",
   "Neutral": "0.3771340847015381",
   "Mixed": "0.005893660709261894"
 },
 {
   "tweet_id": "1590067209455689730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067205047476224",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590067199120531456",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1590067152799039488",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590067146901852160",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590067145735798785",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067140044132353",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067137116221440",
   "Positive": "0.16977538168430328",
   "Negative": "0.05753659829497337",
   "Neutral": "0.770435631275177",
   "Mixed": "0.0022523351944983006"
 },
 {
   "tweet_id": "1590067129193209861",
   "Positive": "0.00462869880720973",
   "Negative": "0.00011641619494184852",
   "Neutral": "0.9952384233474731",
   "Mixed": "1.6540547221666202e-05"
 },
 {
   "tweet_id": "1590067127108923393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590067111527084032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067084033404928",
   "Positive": "0.6090250015258789",
   "Negative": "0.009284255094826221",
   "Neutral": "0.3612155020236969",
   "Mixed": "0.020475246012210846"
 },
 {
   "tweet_id": "1590067083853049856",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067072947851264",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067070439690240",
   "Positive": "0.04534910246729851",
   "Negative": "0.5133354663848877",
   "Neutral": "0.44038739800453186",
   "Mixed": "0.0009280259255319834"
 },
 {
   "tweet_id": "1590067065066782720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067062881554432",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590067061841354752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067045768790016",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590067043185098752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067039237857280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067033252958209",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067007952924672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590067001351090176",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066997509132288",
   "Positive": "0.28803569078445435",
   "Negative": "0.0009642867371439934",
   "Neutral": "0.7109164595603943",
   "Mixed": "8.355068712262437e-05"
 },
 {
   "tweet_id": "1590066996376281089",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066987698638850",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066965955375104",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1590066957415780353",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066947668201475",
   "Positive": "0.0031372574158012867",
   "Negative": "0.6280495524406433",
   "Neutral": "0.3679881691932678",
   "Mixed": "0.0008250458631664515"
 },
 {
   "tweet_id": "1590066945659142144",
   "Positive": "0.002678978256881237",
   "Negative": "0.8501423001289368",
   "Neutral": "0.14618529379367828",
   "Mixed": "0.0009934923145920038"
 },
 {
   "tweet_id": "1590066918974976000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066908053000192",
   "Positive": "0.22434452176094055",
   "Negative": "0.0033252898138016462",
   "Neutral": "0.7723089456558228",
   "Mixed": "2.1280402506818064e-05"
 },
 {
   "tweet_id": "1590066905553211392",
   "Positive": "0.027349481359124184",
   "Negative": "0.0065621682442724705",
   "Neutral": "0.9646803140640259",
   "Mixed": "0.001408072654157877"
 },
 {
   "tweet_id": "1590066905154736128",
   "Positive": "0.09071946144104004",
   "Negative": "0.00030286810942925513",
   "Neutral": "0.908948540687561",
   "Mixed": "2.9116639780113474e-05"
 },
 {
   "tweet_id": "1590066894375374849",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066890424352768",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1590066889883287554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066886502678528",
   "Positive": "0.17892278730869293",
   "Negative": "0.0019078025361523032",
   "Neutral": "0.8191467523574829",
   "Mixed": "2.2665200958726928e-05"
 },
 {
   "tweet_id": "1590066878168584192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066855112486912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066828487057411",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590066827618816000",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1590066824817029120",
   "Positive": "0.1315811276435852",
   "Negative": "0.41540271043777466",
   "Neutral": "0.4524071216583252",
   "Mixed": "0.0006090400856919587"
 },
 {
   "tweet_id": "1590066809642029057",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066800657838080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066787944902656",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590066759008419841",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590066754319159296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066746538733568",
   "Positive": "0.16139467060565948",
   "Negative": "0.0006648519774898887",
   "Neutral": "0.8377509713172913",
   "Mixed": "0.00018953927792608738"
 },
 {
   "tweet_id": "1590066732059987969",
   "Positive": "0.16139467060565948",
   "Negative": "0.0006648519774898887",
   "Neutral": "0.8377509713172913",
   "Mixed": "0.00018953927792608738"
 },
 {
   "tweet_id": "1590066725525258241",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066722974859265",
   "Positive": "0.0011660481104627252",
   "Negative": "8.951274503488094e-05",
   "Neutral": "0.9987383484840393",
   "Mixed": "6.085449513193453e-06"
 },
 {
   "tweet_id": "1590066717413105664",
   "Positive": "0.16139467060565948",
   "Negative": "0.0006648519774898887",
   "Neutral": "0.8377509713172913",
   "Mixed": "0.00018953927792608738"
 },
 {
   "tweet_id": "1590066715110834176",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066711067492353",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590066708986834945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066699789037568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066688589971457",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066685289328641",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066678959730688",
   "Positive": "0.20134414732456207",
   "Negative": "0.00019441831682343036",
   "Neutral": "0.7983814477920532",
   "Mixed": "8.000566595001146e-05"
 },
 {
   "tweet_id": "1590066665584488448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066660857155585",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066659490140160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066648450375680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066640347336704",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066639453974529",
   "Positive": "0.01650768332183361",
   "Negative": "0.8985412120819092",
   "Neutral": "0.08189964294433594",
   "Mixed": "0.003051456529647112"
 },
 {
   "tweet_id": "1590066625645928448",
   "Positive": "0.4643772840499878",
   "Negative": "0.005215328652411699",
   "Neutral": "0.44513577222824097",
   "Mixed": "0.08527151495218277"
 },
 {
   "tweet_id": "1590066623443894272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066620168548353",
   "Positive": "0.014320463873445988",
   "Negative": "0.6160368323326111",
   "Neutral": "0.06848441064357758",
   "Mixed": "0.30115821957588196"
 },
 {
   "tweet_id": "1590066612618809345",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590066599205408768",
   "Positive": "0.0002668194065336138",
   "Negative": "0.8960588574409485",
   "Neutral": "0.10365751385688782",
   "Mixed": "1.6820333257783204e-05"
 },
 {
   "tweet_id": "1590066594897858560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066562459136000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066559887646723",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066550157225984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066533736148992",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1590066526622998528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066521908224000",
   "Positive": "0.046182990074157715",
   "Negative": "0.017497669905424118",
   "Neutral": "0.9362987875938416",
   "Mixed": "2.0528794266283512e-05"
 },
 {
   "tweet_id": "1590066514727927808",
   "Positive": "0.004440611228346825",
   "Negative": "0.00011018087388947606",
   "Neutral": "0.9954450130462646",
   "Mixed": "4.2605392991390545e-06"
 },
 {
   "tweet_id": "1590066491403423746",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066471170080770",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066462756335616",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066461590302722",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590066458654281730",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066450756435968",
   "Positive": "0.06789259612560272",
   "Negative": "0.0020264943595975637",
   "Neutral": "0.9300442934036255",
   "Mixed": "3.6642457416746765e-05"
 },
 {
   "tweet_id": "1590066415994015744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066405373640704",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066395571576833",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590066371790278656",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590066364668350464",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066363993055232",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066363535863810",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066356871135232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066314399617024",
   "Positive": "0.0029971045441925526",
   "Negative": "0.6624789834022522",
   "Neutral": "0.033656999468803406",
   "Mixed": "0.3008669912815094"
 },
 {
   "tweet_id": "1590066295290359808",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590066289779036161",
   "Positive": "0.2810480296611786",
   "Negative": "0.03759722784161568",
   "Neutral": "0.6812986135482788",
   "Mixed": "5.610561493085697e-05"
 },
 {
   "tweet_id": "1590066259697074176",
   "Positive": "0.03340250998735428",
   "Negative": "0.0005835712072439492",
   "Neutral": "0.9659945964813232",
   "Mixed": "1.933454223035369e-05"
 },
 {
   "tweet_id": "1590066245143261184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590066199991578626",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066190847971328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590066166550044673",
   "Positive": "0.018221627920866013",
   "Negative": "0.01071476936340332",
   "Neutral": "0.9710528254508972",
   "Mixed": "1.07262649180484e-05"
 },
 {
   "tweet_id": "1590066142353461248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590066091501719552",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590066039194148864",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066037546184706",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066035331567616",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590066032013897728",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590066022287290368",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590066019980443648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590066019397402624",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590066018529218560",
   "Positive": "0.003780699335038662",
   "Negative": "0.00022623119002673775",
   "Neutral": "0.9959885478019714",
   "Mixed": "4.5413989937515e-06"
 },
 {
   "tweet_id": "1590066013630255105",
   "Positive": "0.021880637854337692",
   "Negative": "0.6706252098083496",
   "Neutral": "0.27000901103019714",
   "Mixed": "0.037485092878341675"
 },
 {
   "tweet_id": "1590066003555540994",
   "Positive": "0.0003312215267214924",
   "Negative": "0.011788765899837017",
   "Neutral": "0.9878688454627991",
   "Mixed": "1.1182532944076229e-05"
 },
 {
   "tweet_id": "1590065996593008640",
   "Positive": "0.39113131165504456",
   "Negative": "0.00249119708314538",
   "Neutral": "0.5124353766441345",
   "Mixed": "0.0939420834183693"
 },
 {
   "tweet_id": "1590065994273361920",
   "Positive": "0.2814025580883026",
   "Negative": "0.0009886403568089008",
   "Neutral": "0.7175484299659729",
   "Mixed": "6.036015838617459e-05"
 },
 {
   "tweet_id": "1590065965911666688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065962828857344",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590065899700387840",
   "Positive": "0.005519574508070946",
   "Negative": "0.06130019202828407",
   "Neutral": "0.9328693151473999",
   "Mixed": "0.0003109638055320829"
 },
 {
   "tweet_id": "1590065898068783104",
   "Positive": "0.6754076480865479",
   "Negative": "0.004977104719728231",
   "Neutral": "0.3181319832801819",
   "Mixed": "0.0014833015156909823"
 },
 {
   "tweet_id": "1590065876224864256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065873976406017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065865948819457",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1590065862412627968",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065841043050498",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065830142050304",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065801851437056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065773695086592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065772042555393",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065771656679425",
   "Positive": "0.10579308867454529",
   "Negative": "0.0025793160311877728",
   "Neutral": "0.891543447971344",
   "Mixed": "8.412144961766899e-05"
 },
 {
   "tweet_id": "1590065701985087488",
   "Positive": "0.0538569875061512",
   "Negative": "0.00399582227692008",
   "Neutral": "0.9421345591545105",
   "Mixed": "1.2612304999493062e-05"
 },
 {
   "tweet_id": "1590065698327638016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065698088587264",
   "Positive": "0.0039506456814706326",
   "Negative": "0.0005043795681558549",
   "Neutral": "0.9955412745475769",
   "Mixed": "3.716354513016995e-06"
 },
 {
   "tweet_id": "1590065689557176321",
   "Positive": "0.02013034000992775",
   "Negative": "6.853060040157288e-05",
   "Neutral": "0.9797421097755432",
   "Mixed": "5.897664595977403e-05"
 },
 {
   "tweet_id": "1590065675992793089",
   "Positive": "0.02013034000992775",
   "Negative": "6.853060040157288e-05",
   "Neutral": "0.9797421097755432",
   "Mixed": "5.897664595977403e-05"
 },
 {
   "tweet_id": "1590065674009075712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065650885890048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590065648033759233",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590065647635267586",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065643055112192",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590065604454907905",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065598674804737",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065578869665792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065574931222528",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065566806835201",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590065566668427264",
   "Positive": "0.023995881900191307",
   "Negative": "5.373291060095653e-05",
   "Neutral": "0.975918710231781",
   "Mixed": "3.169175033690408e-05"
 },
 {
   "tweet_id": "1590065555591270400",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590065549341757440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065505632538624",
   "Positive": "0.5831870436668396",
   "Negative": "0.011940744705498219",
   "Neutral": "0.3729300796985626",
   "Mixed": "0.03194210305809975"
 },
 {
   "tweet_id": "1590065485936463872",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590065484371701760",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065468928561152",
   "Positive": "0.2505108118057251",
   "Negative": "0.00356566091068089",
   "Neutral": "0.7458808422088623",
   "Mixed": "4.2658954043872654e-05"
 },
 {
   "tweet_id": "1590065466613334016",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590065463077515264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065458719621120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065438838640640",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065438561816577",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065433234710528",
   "Positive": "0.0012733861804008484",
   "Negative": "7.720476423855871e-05",
   "Neutral": "0.9986429810523987",
   "Mixed": "6.446956831496209e-06"
 },
 {
   "tweet_id": "1590065410279604224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065409268797440",
   "Positive": "0.0017980423290282488",
   "Negative": "0.7322138547897339",
   "Neutral": "0.26584434509277344",
   "Mixed": "0.000143776458571665"
 },
 {
   "tweet_id": "1590065398535589889",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065390167949313",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065389995974656",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590065381057900545",
   "Positive": "0.002166729187592864",
   "Negative": "0.000342504441505298",
   "Neutral": "0.9974875450134277",
   "Mixed": "3.193162001480232e-06"
 },
 {
   "tweet_id": "1590065372774158337",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065369158275075",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065353501335552",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590065339630751746",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590065324984262656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590065288719962113",
   "Positive": "0.7138240933418274",
   "Negative": "0.007751686964184046",
   "Neutral": "0.27823808789253235",
   "Mixed": "0.00018609504331834614"
 },
 {
   "tweet_id": "1590065284009709568",
   "Positive": "0.005204199347645044",
   "Negative": "0.002075584139674902",
   "Neutral": "0.9927101135253906",
   "Mixed": "1.0116751582245342e-05"
 },
 {
   "tweet_id": "1590065269342621696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065248157184000",
   "Positive": "0.00038248664350248873",
   "Negative": "0.00029040523804724216",
   "Neutral": "0.999321699142456",
   "Mixed": "5.438844254967989e-06"
 },
 {
   "tweet_id": "1590065244474601473",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590065243593461760",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065236379201536",
   "Positive": "0.5556674599647522",
   "Negative": "0.0008200437878258526",
   "Neutral": "0.44247859716415405",
   "Mixed": "0.0010338793508708477"
 },
 {
   "tweet_id": "1590065232407580672",
   "Positive": "0.0497022345662117",
   "Negative": "0.012938214465975761",
   "Neutral": "0.9373194575309753",
   "Mixed": "4.012848512502387e-05"
 },
 {
   "tweet_id": "1590065227525066752",
   "Positive": "0.0023052108008414507",
   "Negative": "0.00011107089812867343",
   "Neutral": "0.9975797533988953",
   "Mixed": "4.023313977086218e-06"
 },
 {
   "tweet_id": "1590065213021523968",
   "Positive": "0.197361022233963",
   "Negative": "0.0049484651535749435",
   "Neutral": "0.7976146936416626",
   "Mixed": "7.577431824756786e-05"
 },
 {
   "tweet_id": "1590065204150538240",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065185959849985",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590065185649176576",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590065180066877440",
   "Positive": "0.006681019905954599",
   "Negative": "0.6112240552902222",
   "Neutral": "0.38160520792007446",
   "Mixed": "0.0004896618775092065"
 },
 {
   "tweet_id": "1590065174152892416",
   "Positive": "0.31898391246795654",
   "Negative": "0.0003000839496962726",
   "Neutral": "0.6806581616401672",
   "Mixed": "5.784674794995226e-05"
 },
 {
   "tweet_id": "1590065164338229248",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1590065163994279937",
   "Positive": "0.0032251104712486267",
   "Negative": "0.02651543915271759",
   "Neutral": "0.970063328742981",
   "Mixed": "0.00019609929586295038"
 },
 {
   "tweet_id": "1590065130502750209",
   "Positive": "0.00033552455715835094",
   "Negative": "0.0005603482131846249",
   "Neutral": "0.9991006851196289",
   "Mixed": "3.477409791230457e-06"
 },
 {
   "tweet_id": "1590065102086373376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590065101734051841",
   "Positive": "0.11289580166339874",
   "Negative": "0.000205366886802949",
   "Neutral": "0.8868813514709473",
   "Mixed": "1.7392490917700343e-05"
 },
 {
   "tweet_id": "1590065093697765376",
   "Positive": "0.9462135434150696",
   "Negative": "0.00017048628069460392",
   "Neutral": "0.053405724465847015",
   "Mixed": "0.0002103376464219764"
 },
 {
   "tweet_id": "1590065084939657216",
   "Positive": "0.004633351229131222",
   "Negative": "0.0007770807133056223",
   "Neutral": "0.9945869445800781",
   "Mixed": "2.6396726298116846e-06"
 },
 {
   "tweet_id": "1590065068938788865",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1590065046776074240",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590065041910697984",
   "Positive": "0.13161525130271912",
   "Negative": "0.20634543895721436",
   "Neutral": "0.34781211614608765",
   "Mixed": "0.31422722339630127"
 },
 {
   "tweet_id": "1590065039444414464",
   "Positive": "0.021071864292025566",
   "Negative": "0.0016375689301639795",
   "Neutral": "0.9772852659225464",
   "Mixed": "5.316572696756339e-06"
 },
 {
   "tweet_id": "1590065034730012672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590065029994643456",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590065015142219777",
   "Positive": "0.06807971745729446",
   "Negative": "0.003001609817147255",
   "Neutral": "0.9288893938064575",
   "Mixed": "2.9231474400148727e-05"
 },
 {
   "tweet_id": "1590065000395468800",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590064972633059330",
   "Positive": "0.2448088675737381",
   "Negative": "0.48192089796066284",
   "Neutral": "0.23704946041107178",
   "Mixed": "0.03622085228562355"
 },
 {
   "tweet_id": "1590064941629075456",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590064917016875008",
   "Positive": "0.5784322023391724",
   "Negative": "0.010957285761833191",
   "Neutral": "0.4103676378726959",
   "Mixed": "0.00024292129091918468"
 },
 {
   "tweet_id": "1590064898608103424",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064896074731520",
   "Positive": "0.6855987310409546",
   "Negative": "0.022804586216807365",
   "Neutral": "0.290754109621048",
   "Mixed": "0.000842612178530544"
 },
 {
   "tweet_id": "1590064884611690497",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064867482144768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064845055225856",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590064835341189120",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590064819851304961",
   "Positive": "0.004606954287737608",
   "Negative": "0.028754396364092827",
   "Neutral": "0.9666196703910828",
   "Mixed": "1.8866554455598816e-05"
 },
 {
   "tweet_id": "1590064815250493440",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590064785961668608",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064762746195968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064754097549312",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064750511415298",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064724380909568",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064693368205312",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064670421168129",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590064655246200834",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064630248132609",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590064620919742464",
   "Positive": "0.4139266610145569",
   "Negative": "0.0009330130997113883",
   "Neutral": "0.5850896239280701",
   "Mixed": "5.066706580691971e-05"
 },
 {
   "tweet_id": "1590064600867049472",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064585620721665",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590064584203055104",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064555098767360",
   "Positive": "0.005186025518923998",
   "Negative": "0.011147645302116871",
   "Neutral": "0.9836360812187195",
   "Mixed": "3.0247236281866208e-05"
 },
 {
   "tweet_id": "1590064532155613184",
   "Positive": "0.0013418260496109724",
   "Negative": "0.0001889961276901886",
   "Neutral": "0.9984666109085083",
   "Mixed": "2.6168916065216763e-06"
 },
 {
   "tweet_id": "1590064530562109440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064512971206656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064473301188608",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064460445945857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064456981106688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064433258467328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064391637999616",
   "Positive": "0.04960266873240471",
   "Negative": "0.0021947906352579594",
   "Neutral": "0.9481798410415649",
   "Mixed": "2.2772377633373253e-05"
 },
 {
   "tweet_id": "1590064389952262145",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590064387146272768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064379864969217",
   "Positive": "0.7590855360031128",
   "Negative": "0.06087533384561539",
   "Neutral": "0.17918485403060913",
   "Mixed": "0.0008542644209228456"
 },
 {
   "tweet_id": "1590064370390020098",
   "Positive": "0.10482429713010788",
   "Negative": "0.0008550994680263102",
   "Neutral": "0.8943032026290894",
   "Mixed": "1.7348631445202045e-05"
 },
 {
   "tweet_id": "1590064354690748416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064338710433792",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590064333631127552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064318758129664",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064314555432960",
   "Positive": "0.08830226212739944",
   "Negative": "0.012987574562430382",
   "Neutral": "0.8983717560768127",
   "Mixed": "0.0003383987641427666"
 },
 {
   "tweet_id": "1590064312688660480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064288731131905",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064275435196416",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590064269873229824",
   "Positive": "0.00038116725045256317",
   "Negative": "0.0008890860481187701",
   "Neutral": "0.9987264275550842",
   "Mixed": "3.3755779895727756e-06"
 },
 {
   "tweet_id": "1590064234918215681",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064233881882624",
   "Positive": "0.11684084683656693",
   "Negative": "0.00043252910836599767",
   "Neutral": "0.8827166557312012",
   "Mixed": "9.950213097909e-06"
 },
 {
   "tweet_id": "1590064232925892608",
   "Positive": "0.5746341347694397",
   "Negative": "0.00041453057201579213",
   "Neutral": "0.42487308382987976",
   "Mixed": "7.825404463801533e-05"
 },
 {
   "tweet_id": "1590064205096710146",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590064192517967873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590064191725264896",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064186939564032",
   "Positive": "0.0049115512520074844",
   "Negative": "0.006444765720516443",
   "Neutral": "0.9886329770088196",
   "Mixed": "1.0747499800345395e-05"
 },
 {
   "tweet_id": "1590064180601946112",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590064166978846720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064129704091648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590064111731310592",
   "Positive": "0.0019392890390008688",
   "Negative": "0.48707982897758484",
   "Neutral": "0.5108856558799744",
   "Mixed": "9.524005145067349e-05"
 },
 {
   "tweet_id": "1590064109780967430",
   "Positive": "0.5331394672393799",
   "Negative": "0.002524138195440173",
   "Neutral": "0.45999640226364136",
   "Mixed": "0.004340055398643017"
 },
 {
   "tweet_id": "1590064109680148480",
   "Positive": "0.00036115656257607043",
   "Negative": "0.0008203189354389906",
   "Neutral": "0.9988152980804443",
   "Mixed": "3.2662705962138716e-06"
 },
 {
   "tweet_id": "1590064103380627456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590064096476811264",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590064073785606146",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590064060888154113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590064028105453568",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590064026255773696",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590064020413112324",
   "Positive": "0.0005448260344564915",
   "Negative": "9.152491111308336e-05",
   "Neutral": "0.9993290901184082",
   "Mixed": "3.460538573563099e-05"
 },
 {
   "tweet_id": "1590064015962619904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590064008924893186",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590063985474535425",
   "Positive": "0.9905745387077332",
   "Negative": "0.00023983872961252928",
   "Neutral": "0.009159608744084835",
   "Mixed": "2.6015644834842533e-05"
 },
 {
   "tweet_id": "1590063963407990784",
   "Positive": "0.027669783681631088",
   "Negative": "0.00018059034482575953",
   "Neutral": "0.972098708152771",
   "Mixed": "5.09669043822214e-05"
 },
 {
   "tweet_id": "1590063959629234176",
   "Positive": "0.004841258283704519",
   "Negative": "0.4494992792606354",
   "Neutral": "0.5456257462501526",
   "Mixed": "3.367197496118024e-05"
 },
 {
   "tweet_id": "1590063943334395904",
   "Positive": "0.9023821949958801",
   "Negative": "0.00018720755178947002",
   "Neutral": "0.09734585136175156",
   "Mixed": "8.472609624732286e-05"
 },
 {
   "tweet_id": "1590063943313416193",
   "Positive": "0.3423025608062744",
   "Negative": "0.001947438926436007",
   "Neutral": "0.655634880065918",
   "Mixed": "0.00011512961646076292"
 },
 {
   "tweet_id": "1590063940490231808",
   "Positive": "0.9905745387077332",
   "Negative": "0.00023983872961252928",
   "Neutral": "0.009159608744084835",
   "Mixed": "2.6015644834842533e-05"
 },
 {
   "tweet_id": "1590063900657356800",
   "Positive": "0.12233452498912811",
   "Negative": "0.0662894994020462",
   "Neutral": "0.8109978437423706",
   "Mixed": "0.0003780836705118418"
 },
 {
   "tweet_id": "1590063898153000960",
   "Positive": "0.2075667828321457",
   "Negative": "0.00017998769180849195",
   "Neutral": "0.7921767234802246",
   "Mixed": "7.652745262021199e-05"
 },
 {
   "tweet_id": "1590063884907712513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063873461485569",
   "Positive": "0.09030065685510635",
   "Negative": "0.057197485119104385",
   "Neutral": "0.7472622394561768",
   "Mixed": "0.10523967444896698"
 },
 {
   "tweet_id": "1590063858097745920",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590063849453277185",
   "Positive": "0.5976389050483704",
   "Negative": "0.015209252946078777",
   "Neutral": "0.38656309247016907",
   "Mixed": "0.0005887311417609453"
 },
 {
   "tweet_id": "1590063823641534464",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590063822290972672",
   "Positive": "0.6060620546340942",
   "Negative": "0.00029432037263177335",
   "Neutral": "0.39357200264930725",
   "Mixed": "7.157648360589519e-05"
 },
 {
   "tweet_id": "1590063816376582144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063799746588672",
   "Positive": "0.039188455790281296",
   "Negative": "0.00021426369494292885",
   "Neutral": "0.9604249596595764",
   "Mixed": "0.0001722673769108951"
 },
 {
   "tweet_id": "1590063798496661505",
   "Positive": "0.04833190143108368",
   "Negative": "0.0002571967779658735",
   "Neutral": "0.9513887763023376",
   "Mixed": "2.212001709267497e-05"
 },
 {
   "tweet_id": "1590063776090374144",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063772248715266",
   "Positive": "0.47218477725982666",
   "Negative": "0.0013578011421486735",
   "Neutral": "0.5259763598442078",
   "Mixed": "0.0004810676909983158"
 },
 {
   "tweet_id": "1590063763784597504",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590063744293699585",
   "Positive": "0.7840369939804077",
   "Negative": "0.00017292005941271782",
   "Neutral": "0.2157214879989624",
   "Mixed": "6.85440973029472e-05"
 },
 {
   "tweet_id": "1590063741177327616",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590063739738673152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063738358751235",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063727755206656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590063708633726976",
   "Positive": "0.6663485169410706",
   "Negative": "0.006179403513669968",
   "Neutral": "0.32582366466522217",
   "Mixed": "0.001648411387577653"
 },
 {
   "tweet_id": "1590063681366556673",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1590063679953076226",
   "Positive": "0.21266542375087738",
   "Negative": "0.004936239216476679",
   "Neutral": "0.7823330163955688",
   "Mixed": "6.538169691339135e-05"
 },
 {
   "tweet_id": "1590063676316610560",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063675142201344",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590063673326075904",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590063651868004353",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1590063632628744192",
   "Positive": "0.11272232979536057",
   "Negative": "0.3101050853729248",
   "Neutral": "0.5701952576637268",
   "Mixed": "0.00697735883295536"
 },
 {
   "tweet_id": "1590063617994809344",
   "Positive": "0.0032755641732364893",
   "Negative": "0.2640390694141388",
   "Neutral": "0.7325617671012878",
   "Mixed": "0.00012353829515632242"
 },
 {
   "tweet_id": "1590063603016953856",
   "Positive": "0.21266542375087738",
   "Negative": "0.004936239216476679",
   "Neutral": "0.7823330163955688",
   "Mixed": "6.538169691339135e-05"
 },
 {
   "tweet_id": "1590063593974030336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063568648445952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063555445129216",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590063554417524736",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590063532921716736",
   "Positive": "0.21266542375087738",
   "Negative": "0.004936239216476679",
   "Neutral": "0.7823330163955688",
   "Mixed": "6.538169691339135e-05"
 },
 {
   "tweet_id": "1590063522540843008",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590063521660035072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063520179421184",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063517054685185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063493130379265",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590063492941635584",
   "Positive": "0.001972559606656432",
   "Negative": "0.6601065993309021",
   "Neutral": "0.3354933261871338",
   "Mixed": "0.0024276147596538067"
 },
 {
   "tweet_id": "1590063488457920512",
   "Positive": "0.007458856794983149",
   "Negative": "0.08219768851995468",
   "Neutral": "0.9098871350288391",
   "Mixed": "0.0004563388356473297"
 },
 {
   "tweet_id": "1590063481306644481",
   "Positive": "0.21266542375087738",
   "Negative": "0.004936239216476679",
   "Neutral": "0.7823330163955688",
   "Mixed": "6.538169691339135e-05"
 },
 {
   "tweet_id": "1590063470623731712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063462990106624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063454508920832",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590063453972344833",
   "Positive": "0.21266542375087738",
   "Negative": "0.004936239216476679",
   "Neutral": "0.7823330163955688",
   "Mixed": "6.538169691339135e-05"
 },
 {
   "tweet_id": "1590063445239828480",
   "Positive": "0.005182124208658934",
   "Negative": "0.08278007060289383",
   "Neutral": "0.020610429346561432",
   "Mixed": "0.8914272785186768"
 },
 {
   "tweet_id": "1590063424155045888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063416990785536",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590063415661596672",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590063411395981314",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063394765213697",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590063391544348672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063389342314496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063373198446594",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590063364382027778",
   "Positive": "0.3446056544780731",
   "Negative": "0.003249888541176915",
   "Neutral": "0.6520980596542358",
   "Mixed": "4.644161890610121e-05"
 },
 {
   "tweet_id": "1590063360564871168",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590063355439775750",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590063353807835137",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590063335583911936",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590063313022779392",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590063309520531461",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063297021124608",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063292093198336",
   "Positive": "0.01557831559330225",
   "Negative": "0.0019200809765607119",
   "Neutral": "0.9824924468994141",
   "Mixed": "9.120352842728607e-06"
 },
 {
   "tweet_id": "1590063261801906176",
   "Positive": "0.9905745387077332",
   "Negative": "0.00023983872961252928",
   "Neutral": "0.009159608744084835",
   "Mixed": "2.6015644834842533e-05"
 },
 {
   "tweet_id": "1590063247419670528",
   "Positive": "0.021260274574160576",
   "Negative": "0.0012078589061275125",
   "Neutral": "0.9775222539901733",
   "Mixed": "9.631028660805896e-06"
 },
 {
   "tweet_id": "1590063244072583170",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063228918562816",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063226896920576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063221297545217",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590063216415346689",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063204172206080",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063196957614081",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063193204076551",
   "Positive": "0.24876564741134644",
   "Negative": "0.004197241738438606",
   "Neutral": "0.7469983100891113",
   "Mixed": "3.883959652739577e-05"
 },
 {
   "tweet_id": "1590063174094843904",
   "Positive": "0.012669331394135952",
   "Negative": "0.025878416374325752",
   "Neutral": "0.9614335894584656",
   "Mixed": "1.8649403500603512e-05"
 },
 {
   "tweet_id": "1590063164728958977",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063158143901696",
   "Positive": "0.9721830487251282",
   "Negative": "0.0005081676063127816",
   "Neutral": "0.02729196473956108",
   "Mixed": "1.6721407519071363e-05"
 },
 {
   "tweet_id": "1590063141958078464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590063140284563457",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063128381095936",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063113197748224",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063094730219520",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063064023728128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590063061112872962",
   "Positive": "0.0006695439224131405",
   "Negative": "0.00034303145366720855",
   "Neutral": "0.9989847540855408",
   "Mixed": "2.646850134624401e-06"
 },
 {
   "tweet_id": "1590063023770988544",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590063002925297664",
   "Positive": "0.0031395459081977606",
   "Negative": "5.87087452004198e-05",
   "Neutral": "0.9967750906944275",
   "Mixed": "2.666006184881553e-05"
 },
 {
   "tweet_id": "1590062996646420480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062992129134596",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590062978233421825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062974453981184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062967332405248",
   "Positive": "0.007054787129163742",
   "Negative": "0.00020398366905283183",
   "Neutral": "0.9927254915237427",
   "Mixed": "1.5683126548537984e-05"
 },
 {
   "tweet_id": "1590062962467049472",
   "Positive": "0.005763539113104343",
   "Negative": "0.4469754993915558",
   "Neutral": "0.5472245216369629",
   "Mixed": "3.647171615739353e-05"
 },
 {
   "tweet_id": "1590062959245475840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062953289887744",
   "Positive": "0.19739393889904022",
   "Negative": "0.002861536107957363",
   "Neutral": "0.7979913353919983",
   "Mixed": "0.0017530694603919983"
 },
 {
   "tweet_id": "1590062950957535232",
   "Positive": "0.025149574503302574",
   "Negative": "0.14873214066028595",
   "Neutral": "0.825958251953125",
   "Mixed": "0.00016000718460418284"
 },
 {
   "tweet_id": "1590062942569254912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062935682215938",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062929751445504",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062926806687744",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1590062925221597184",
   "Positive": "0.8031270503997803",
   "Negative": "0.0006388731417246163",
   "Neutral": "0.1961655467748642",
   "Mixed": "6.857530388515443e-05"
 },
 {
   "tweet_id": "1590062920964374529",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062909320679424",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062909236776960",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062888844029953",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590062885111201794",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1590062869370273792",
   "Positive": "0.9531041383743286",
   "Negative": "0.0002911584742832929",
   "Neutral": "0.046507056802511215",
   "Mixed": "9.754904021974653e-05"
 },
 {
   "tweet_id": "1590062853524160513",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062853091909634",
   "Positive": "0.19355237483978271",
   "Negative": "0.006973833777010441",
   "Neutral": "0.7987492680549622",
   "Mixed": "0.0007245587767101824"
 },
 {
   "tweet_id": "1590062849946447872",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590062837883637760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062822003613698",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062799694495744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062783257022464",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590062769814245376",
   "Positive": "0.019354896619915962",
   "Negative": "0.3400852680206299",
   "Neutral": "0.6397313475608826",
   "Mixed": "0.0008285273797810078"
 },
 {
   "tweet_id": "1590062762264182784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062750361088000",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590062749966794752",
   "Positive": "0.0430331714451313",
   "Negative": "0.10081861168146133",
   "Neutral": "0.8561049699783325",
   "Mixed": "4.327623173594475e-05"
 },
 {
   "tweet_id": "1590062748133883904",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590062738835148800",
   "Positive": "0.2404812127351761",
   "Negative": "0.0067273350432515144",
   "Neutral": "0.7518449425697327",
   "Mixed": "0.0009464921895414591"
 },
 {
   "tweet_id": "1590062723513348097",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590062721302921216",
   "Positive": "0.2151453047990799",
   "Negative": "0.0018105906201526523",
   "Neutral": "0.7827120423316956",
   "Mixed": "0.00033207586966454983"
 },
 {
   "tweet_id": "1590062714524930048",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590062696330055680",
   "Positive": "0.002744543133303523",
   "Negative": "0.0020063139963895082",
   "Neutral": "0.9950315952301025",
   "Mixed": "0.00021748559083789587"
 },
 {
   "tweet_id": "1590062691649224704",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590062691376566272",
   "Positive": "0.9905745387077332",
   "Negative": "0.00023983872961252928",
   "Neutral": "0.009159608744084835",
   "Mixed": "2.6015644834842533e-05"
 },
 {
   "tweet_id": "1590062682413371392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062669599748096",
   "Positive": "0.9825191497802734",
   "Negative": "0.00137751002330333",
   "Neutral": "0.015960555523633957",
   "Mixed": "0.00014281422772910446"
 },
 {
   "tweet_id": "1590062664750751745",
   "Positive": "0.29495495557785034",
   "Negative": "0.0022162278182804585",
   "Neutral": "0.6948609948158264",
   "Mixed": "0.007967778481543064"
 },
 {
   "tweet_id": "1590062649668440065",
   "Positive": "0.4534795582294464",
   "Negative": "0.002742049051448703",
   "Neutral": "0.543691098690033",
   "Mixed": "8.721363701624796e-05"
 },
 {
   "tweet_id": "1590062648607277056",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062646250049536",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590062639052644353",
   "Positive": "0.028220031410455704",
   "Negative": "0.2886117100715637",
   "Neutral": "0.5215859413146973",
   "Mixed": "0.161582350730896"
 },
 {
   "tweet_id": "1590062610514608128",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062569204908032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062563014103040",
   "Positive": "0.0007181200198829174",
   "Negative": "0.0001239195844391361",
   "Neutral": "0.9991540908813477",
   "Mixed": "3.7557979339908343e-06"
 },
 {
   "tweet_id": "1590062547570679808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062540251619328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062540138029059",
   "Positive": "0.5851054191589355",
   "Negative": "0.003166215494275093",
   "Neutral": "0.017969174310564995",
   "Mixed": "0.39375919103622437"
 },
 {
   "tweet_id": "1590062528717271040",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590062526905356288",
   "Positive": "0.0028097713366150856",
   "Negative": "0.11046700179576874",
   "Neutral": "0.8866323828697205",
   "Mixed": "9.084340126719326e-05"
 },
 {
   "tweet_id": "1590062511247671296",
   "Positive": "0.9905745387077332",
   "Negative": "0.00023983872961252928",
   "Neutral": "0.009159608744084835",
   "Mixed": "2.6015644834842533e-05"
 },
 {
   "tweet_id": "1590062491497005056",
   "Positive": "0.000696725444868207",
   "Negative": "0.00016525496903341264",
   "Neutral": "0.9991317391395569",
   "Mixed": "6.3333113757835235e-06"
 },
 {
   "tweet_id": "1590062476997320705",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062474350546945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062465748172800",
   "Positive": "0.006053992081433535",
   "Negative": "0.8550962805747986",
   "Neutral": "0.10897635668516159",
   "Mixed": "0.02987344190478325"
 },
 {
   "tweet_id": "1590062465210949632",
   "Positive": "0.9969853758811951",
   "Negative": "0.0002579815045464784",
   "Neutral": "0.0027480183634907007",
   "Mixed": "8.61886564962333e-06"
 },
 {
   "tweet_id": "1590062460270424064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062450405429248",
   "Positive": "0.015576907433569431",
   "Negative": "0.7157433032989502",
   "Neutral": "0.2678124010562897",
   "Mixed": "0.000867445080075413"
 },
 {
   "tweet_id": "1590062449134567425",
   "Positive": "0.7283501029014587",
   "Negative": "0.0013393660774454474",
   "Neutral": "0.2702081501483917",
   "Mixed": "0.000102304395113606"
 },
 {
   "tweet_id": "1590062438161915905",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1590062438065770497",
   "Positive": "0.11289580166339874",
   "Negative": "0.000205366886802949",
   "Neutral": "0.8868813514709473",
   "Mixed": "1.7392490917700343e-05"
 },
 {
   "tweet_id": "1590062431405223936",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062425113788416",
   "Positive": "0.0012870057253167033",
   "Negative": "0.9722806215286255",
   "Neutral": "0.02396816946566105",
   "Mixed": "0.0024641219060868025"
 },
 {
   "tweet_id": "1590062416502874113",
   "Positive": "0.008227402344346046",
   "Negative": "0.000705659098457545",
   "Neutral": "0.991063117980957",
   "Mixed": "3.730738626472885e-06"
 },
 {
   "tweet_id": "1590062407099232256",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590062404842717184",
   "Positive": "0.8698536157608032",
   "Negative": "0.016167597845196724",
   "Neutral": "0.0902983620762825",
   "Mixed": "0.023680534213781357"
 },
 {
   "tweet_id": "1590062389009190914",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062386140282880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062377155731456",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590062341387083776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062319966777344",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590062304124899330",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062298068307969",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590062293949485056",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1590062290510180352",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062287238627328",
   "Positive": "0.21535514295101166",
   "Negative": "0.0012628238182514906",
   "Neutral": "0.7827383279800415",
   "Mixed": "0.0006437597912736237"
 },
 {
   "tweet_id": "1590062284360929280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062279093284864",
   "Positive": "0.1442318707704544",
   "Negative": "0.0008862955146469176",
   "Neutral": "0.8545779585838318",
   "Mixed": "0.00030392364715225995"
 },
 {
   "tweet_id": "1590062269723213824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590062249313726466",
   "Positive": "0.0026526362635195255",
   "Negative": "0.7386026382446289",
   "Neutral": "0.25867849588394165",
   "Mixed": "6.619123450946063e-05"
 },
 {
   "tweet_id": "1590062238039056384",
   "Positive": "0.02779773250222206",
   "Negative": "0.00033815400092862546",
   "Neutral": "0.9717622995376587",
   "Mixed": "0.00010187379666604102"
 },
 {
   "tweet_id": "1590062215797018624",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062206192082945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590062200638832645",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590062191075422208",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590062171480002560",
   "Positive": "0.10579308867454529",
   "Negative": "0.0025793160311877728",
   "Neutral": "0.891543447971344",
   "Mixed": "8.412144961766899e-05"
 },
 {
   "tweet_id": "1590062169814888449",
   "Positive": "0.07048095762729645",
   "Negative": "0.010625943541526794",
   "Neutral": "0.918440580368042",
   "Mixed": "0.00045247035450302064"
 },
 {
   "tweet_id": "1590062168883412994",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062163921895424",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590062154056896513",
   "Positive": "0.0007943263626657426",
   "Negative": "0.9504572153091431",
   "Neutral": "0.04847132787108421",
   "Mixed": "0.00027707492699846625"
 },
 {
   "tweet_id": "1590062147593465856",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590062143692365824",
   "Positive": "0.5804100036621094",
   "Negative": "0.003885876154527068",
   "Neutral": "0.41548553109169006",
   "Mixed": "0.00021852883219253272"
 },
 {
   "tweet_id": "1590062138311446529",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590062100801413120",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590062085303455746",
   "Positive": "0.26911672949790955",
   "Negative": "0.0019970915745943785",
   "Neutral": "0.7276758551597595",
   "Mixed": "0.0012103168992325664"
 },
 {
   "tweet_id": "1590062078634889216",
   "Positive": "0.03558114916086197",
   "Negative": "0.0017882719403132796",
   "Neutral": "0.9626087546348572",
   "Mixed": "2.1808911697007716e-05"
 },
 {
   "tweet_id": "1590062069990461441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590062061849309184",
   "Positive": "0.021675290539860725",
   "Negative": "0.002048454713076353",
   "Neutral": "0.9762589931488037",
   "Mixed": "1.7254174963454716e-05"
 },
 {
   "tweet_id": "1590062043465650176",
   "Positive": "0.03974400833249092",
   "Negative": "0.004862156230956316",
   "Neutral": "0.9553564786911011",
   "Mixed": "3.735382779268548e-05"
 },
 {
   "tweet_id": "1590062017557118976",
   "Positive": "0.030935782939195633",
   "Negative": "0.00139112479519099",
   "Neutral": "0.9676564335823059",
   "Mixed": "1.6615415006526746e-05"
 },
 {
   "tweet_id": "1590062006178287617",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590062002688626688",
   "Positive": "0.1442318707704544",
   "Negative": "0.0008862955146469176",
   "Neutral": "0.8545779585838318",
   "Mixed": "0.00030392364715225995"
 },
 {
   "tweet_id": "1590061994534895616",
   "Positive": "0.05725496634840965",
   "Negative": "0.0023319614119827747",
   "Neutral": "0.9403892755508423",
   "Mixed": "2.3722266632830724e-05"
 },
 {
   "tweet_id": "1590061990072180737",
   "Positive": "0.23243005573749542",
   "Negative": "0.023329712450504303",
   "Neutral": "0.7410336136817932",
   "Mixed": "0.0032065785489976406"
 },
 {
   "tweet_id": "1590061981113135105",
   "Positive": "0.44263556599617004",
   "Negative": "0.004156770650297403",
   "Neutral": "0.5481030344963074",
   "Mixed": "0.005104659590870142"
 },
 {
   "tweet_id": "1590061978382651392",
   "Positive": "0.042586974799633026",
   "Negative": "0.0037064971402287483",
   "Neutral": "0.9536678194999695",
   "Mixed": "3.878611823893152e-05"
 },
 {
   "tweet_id": "1590061963333472256",
   "Positive": "0.42050138115882874",
   "Negative": "0.0008069875184446573",
   "Neutral": "0.5782946348190308",
   "Mixed": "0.0003970008692704141"
 },
 {
   "tweet_id": "1590061961152458752",
   "Positive": "0.0566566176712513",
   "Negative": "0.0024713666643947363",
   "Neutral": "0.9408414363861084",
   "Mixed": "3.0624010832980275e-05"
 },
 {
   "tweet_id": "1590061959420186624",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061942387146752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061917447819264",
   "Positive": "0.034887753427028656",
   "Negative": "0.0018661444773897529",
   "Neutral": "0.9632179141044617",
   "Mixed": "2.8166992706246674e-05"
 },
 {
   "tweet_id": "1590061890402910208",
   "Positive": "0.007048900239169598",
   "Negative": "0.08179427683353424",
   "Neutral": "0.028916260227560997",
   "Mixed": "0.8822405934333801"
 },
 {
   "tweet_id": "1590061884853850113",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061866830950401",
   "Positive": "0.09330130368471146",
   "Negative": "0.6950491070747375",
   "Neutral": "0.134384423494339",
   "Mixed": "0.07726513594388962"
 },
 {
   "tweet_id": "1590061822668701696",
   "Positive": "0.029483018442988396",
   "Negative": "0.00023302799672819674",
   "Neutral": "0.9702516794204712",
   "Mixed": "3.2234696845989674e-05"
 },
 {
   "tweet_id": "1590061814339231746",
   "Positive": "0.0002291156124556437",
   "Negative": "0.000849772070068866",
   "Neutral": "0.9989161491394043",
   "Mixed": "5.0245776037627365e-06"
 },
 {
   "tweet_id": "1590061804322836480",
   "Positive": "0.06243661791086197",
   "Negative": "0.00014781141362618655",
   "Neutral": "0.9374039769172668",
   "Mixed": "1.1638770047284197e-05"
 },
 {
   "tweet_id": "1590061803660521472",
   "Positive": "0.03583703562617302",
   "Negative": "0.6222259998321533",
   "Neutral": "0.32734835147857666",
   "Mixed": "0.014588624238967896"
 },
 {
   "tweet_id": "1590061803283021824",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061782416371712",
   "Positive": "0.988349437713623",
   "Negative": "0.0002320147177670151",
   "Neutral": "0.01136133074760437",
   "Mixed": "5.729441181756556e-05"
 },
 {
   "tweet_id": "1590061779169980416",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590061768755539969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061759137996800",
   "Positive": "0.27792856097221375",
   "Negative": "0.010010438971221447",
   "Neutral": "0.7113661766052246",
   "Mixed": "0.0006948704249225557"
 },
 {
   "tweet_id": "1590061732600242176",
   "Positive": "0.010168149136006832",
   "Negative": "0.00798399094492197",
   "Neutral": "0.9818192720413208",
   "Mixed": "2.8504171496024355e-05"
 },
 {
   "tweet_id": "1590061721275678720",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061718100533249",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061713541328896",
   "Positive": "0.8470203876495361",
   "Negative": "0.0009237265330739319",
   "Neutral": "0.15186470746994019",
   "Mixed": "0.00019122223602607846"
 },
 {
   "tweet_id": "1590061706050297858",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590061703332757504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061683288182792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061680826155008",
   "Positive": "0.9830993413925171",
   "Negative": "0.0001320115552516654",
   "Neutral": "0.016758397221565247",
   "Mixed": "1.0325823495804798e-05"
 },
 {
   "tweet_id": "1590061679655546880",
   "Positive": "0.0027898119296878576",
   "Negative": "0.5400468707084656",
   "Neutral": "0.45711252093315125",
   "Mixed": "5.083857831778005e-05"
 },
 {
   "tweet_id": "1590061649339158528",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1590061624013901824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590061617613787136",
   "Positive": "0.9749506711959839",
   "Negative": "0.002807389013469219",
   "Neutral": "0.021993162110447884",
   "Mixed": "0.0002488417667336762"
 },
 {
   "tweet_id": "1590061616657498112",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590061609954992129",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061603441213442",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1590061591969828864",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590061589876862976",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590061581689573376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590061580850728960",
   "Positive": "0.2605159282684326",
   "Negative": "0.008006257936358452",
   "Neutral": "0.647858202457428",
   "Mixed": "0.083619624376297"
 },
 {
   "tweet_id": "1590061572885401600",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590061523124486144",
   "Positive": "0.02862738072872162",
   "Negative": "0.0008131297072395682",
   "Neutral": "0.9705506563186646",
   "Mixed": "8.802053343970329e-06"
 },
 {
   "tweet_id": "1590061502647922689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590061479650537472",
   "Positive": "0.9836814403533936",
   "Negative": "0.0001302122400375083",
   "Neutral": "0.016177846118807793",
   "Mixed": "1.0543556527409237e-05"
 },
 {
   "tweet_id": "1590061468309139456",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590061460058951682",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590061410754899968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590061402622132225",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590061402303385600",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590061386347839490",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590061383638347776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590061373257809920",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590061371345227776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061368216260609",
   "Positive": "0.02810485102236271",
   "Negative": "0.3420459032058716",
   "Neutral": "0.6294283270835876",
   "Mixed": "0.00042089473572559655"
 },
 {
   "tweet_id": "1590061358753927168",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590061355423641601",
   "Positive": "0.009949683211743832",
   "Negative": "0.06092113256454468",
   "Neutral": "0.030110886320471764",
   "Mixed": "0.8990183472633362"
 },
 {
   "tweet_id": "1590061353276182530",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590061349056708608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061314327867394",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590061296921509888",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590061285634322433",
   "Positive": "0.4590388536453247",
   "Negative": "0.009198032319545746",
   "Neutral": "0.31010672450065613",
   "Mixed": "0.2216564416885376"
 },
 {
   "tweet_id": "1590061264461770752",
   "Positive": "0.33923766016960144",
   "Negative": "0.0015166951343417168",
   "Neutral": "0.6592318415641785",
   "Mixed": "1.3765136827714741e-05"
 },
 {
   "tweet_id": "1590061258807869440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590061246493392896",
   "Positive": "0.00210840767249465",
   "Negative": "0.00011505379370646551",
   "Neutral": "0.9977675676345825",
   "Mixed": "9.055724149220623e-06"
 },
 {
   "tweet_id": "1590061233939820544",
   "Positive": "0.08311891555786133",
   "Negative": "0.004179560113698244",
   "Neutral": "0.912682294845581",
   "Mixed": "1.9208589947083965e-05"
 },
 {
   "tweet_id": "1590061204088950785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590061162955124736",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590061146131734528",
   "Positive": "0.9947697520256042",
   "Negative": "5.87688155064825e-05",
   "Neutral": "0.005142255686223507",
   "Mixed": "2.9112572519807145e-05"
 },
 {
   "tweet_id": "1590061129702993920",
   "Positive": "0.0018609750550240278",
   "Negative": "0.00014063232811167836",
   "Neutral": "0.9979919195175171",
   "Mixed": "6.5247695602010936e-06"
 },
 {
   "tweet_id": "1590061088586227712",
   "Positive": "0.6870253682136536",
   "Negative": "0.005043690092861652",
   "Neutral": "0.30778396129608154",
   "Mixed": "0.00014693036791868508"
 },
 {
   "tweet_id": "1590061083909574657",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590061077508739073",
   "Positive": "0.0002475112851243466",
   "Negative": "0.010156496427953243",
   "Neutral": "0.9895803332328796",
   "Mixed": "1.5704150428064167e-05"
 },
 {
   "tweet_id": "1590061028234395649",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1590061005237006336",
   "Positive": "0.4175746440887451",
   "Negative": "0.00714214239269495",
   "Neutral": "0.5493075251579285",
   "Mixed": "0.025975611060857773"
 },
 {
   "tweet_id": "1590061002082615298",
   "Positive": "0.0018377595115453005",
   "Negative": "0.9650739431381226",
   "Neutral": "0.03181390464305878",
   "Mixed": "0.001274397480301559"
 },
 {
   "tweet_id": "1590061000434548738",
   "Positive": "0.07452692836523056",
   "Negative": "0.16857178509235382",
   "Neutral": "0.41699662804603577",
   "Mixed": "0.3399046063423157"
 },
 {
   "tweet_id": "1590060976292126721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590060952812081152",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060942016274432",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590060931333361664",
   "Positive": "0.23260396718978882",
   "Negative": "0.010667755268514156",
   "Neutral": "0.756531834602356",
   "Mixed": "0.00019640727259684354"
 },
 {
   "tweet_id": "1590060911175557120",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060906037542913",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060851125706752",
   "Positive": "0.005827098153531551",
   "Negative": "0.01959233544766903",
   "Neutral": "0.008240523748099804",
   "Mixed": "0.9663401246070862"
 },
 {
   "tweet_id": "1590060844494508032",
   "Positive": "0.0017674039117991924",
   "Negative": "0.00025068552349694073",
   "Neutral": "0.9979792237281799",
   "Mixed": "2.773671440081671e-06"
 },
 {
   "tweet_id": "1590060827205599232",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060804778659841",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590060796595568640",
   "Positive": "0.00020208042406011373",
   "Negative": "0.0038272589445114136",
   "Neutral": "0.9959433674812317",
   "Mixed": "2.735343514359556e-05"
 },
 {
   "tweet_id": "1590060796352299008",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590060782683049984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590060781584121857",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1590060767969435648",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590060756066009088",
   "Positive": "0.0005551110953092575",
   "Negative": "0.006664837710559368",
   "Neutral": "0.9927757978439331",
   "Mixed": "4.294260634196689e-06"
 },
 {
   "tweet_id": "1590060752521801729",
   "Positive": "0.0005198211292736232",
   "Negative": "0.8873852491378784",
   "Neutral": "0.11100701987743378",
   "Mixed": "0.0010878926841542125"
 },
 {
   "tweet_id": "1590060747127926786",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060703100325888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590060690517397505",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590060683840098304",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590060662306516994",
   "Positive": "0.34164050221443176",
   "Negative": "0.3292107582092285",
   "Neutral": "0.32187604904174805",
   "Mixed": "0.007272687274962664"
 },
 {
   "tweet_id": "1590060653855002624",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590060651736535042",
   "Positive": "0.06093534082174301",
   "Negative": "0.0009503781911917031",
   "Neutral": "0.9380987882614136",
   "Mixed": "1.537699790787883e-05"
 },
 {
   "tweet_id": "1590060639812485120",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060635882422272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590060622238339072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060617008021504",
   "Positive": "0.0037131437566131353",
   "Negative": "0.9545783400535583",
   "Neutral": "0.04092461243271828",
   "Mixed": "0.0007839594618417323"
 },
 {
   "tweet_id": "1590060614818615296",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590060604018266112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060597252476928",
   "Positive": "0.004606435541063547",
   "Negative": "0.000219303896301426",
   "Neutral": "0.9951704144477844",
   "Mixed": "3.7540858102147467e-06"
 },
 {
   "tweet_id": "1590060591330099200",
   "Positive": "0.026081755757331848",
   "Negative": "0.0001597769296495244",
   "Neutral": "0.9737151861190796",
   "Mixed": "4.3278698285575956e-05"
 },
 {
   "tweet_id": "1590060590281949184",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590060585906950144",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1590060572787511297",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590060566798036992",
   "Positive": "0.21307986974716187",
   "Negative": "0.0007006750092841685",
   "Neutral": "0.7861394286155701",
   "Mixed": "8.007811265997589e-05"
 },
 {
   "tweet_id": "1590060540612997120",
   "Positive": "0.12746822834014893",
   "Negative": "0.00014886628196109086",
   "Neutral": "0.8722785711288452",
   "Mixed": "0.0001042712974594906"
 },
 {
   "tweet_id": "1590060520945905665",
   "Positive": "0.0037267778534442186",
   "Negative": "0.4354005455970764",
   "Neutral": "0.5607870817184448",
   "Mixed": "8.5490319179371e-05"
 },
 {
   "tweet_id": "1590060516776759296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060511735205889",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590060511173169152",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590060463760736256",
   "Positive": "0.03159963712096214",
   "Negative": "0.00011469798482721671",
   "Neutral": "0.968269407749176",
   "Mixed": "1.6248935935436748e-05"
 },
 {
   "tweet_id": "1590060449974079488",
   "Positive": "0.047885630279779434",
   "Negative": "0.059389207512140274",
   "Neutral": "0.872365415096283",
   "Mixed": "0.02035977877676487"
 },
 {
   "tweet_id": "1590060427266121728",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590060413865295872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590060405778698240",
   "Positive": "0.47298383712768555",
   "Negative": "0.001533829839900136",
   "Neutral": "0.5172183513641357",
   "Mixed": "0.00826399028301239"
 },
 {
   "tweet_id": "1590060387348930560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590060364351574016",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1590060324635693056",
   "Positive": "0.1148897185921669",
   "Negative": "0.2980712354183197",
   "Neutral": "0.5822099447250366",
   "Mixed": "0.0048290700651705265"
 },
 {
   "tweet_id": "1590060315592384512",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590060313164275712",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590060299402420224",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590060288749219840",
   "Positive": "0.0051918174140155315",
   "Negative": "0.14154580235481262",
   "Neutral": "0.8532072305679321",
   "Mixed": "5.510884147952311e-05"
 },
 {
   "tweet_id": "1590060280662601729",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590060263738200065",
   "Positive": "0.0016671482007950544",
   "Negative": "0.0001642099960008636",
   "Neutral": "0.998162567615509",
   "Mixed": "6.119940735516138e-06"
 },
 {
   "tweet_id": "1590060251784839169",
   "Positive": "0.12215413898229599",
   "Negative": "0.017790144309401512",
   "Neutral": "0.8598829507827759",
   "Mixed": "0.00017275367281399667"
 },
 {
   "tweet_id": "1590060234847838208",
   "Positive": "0.004235610831528902",
   "Negative": "0.6646323204040527",
   "Neutral": "0.33095940947532654",
   "Mixed": "0.0001726223126752302"
 },
 {
   "tweet_id": "1590060198831718401",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060185951043584",
   "Positive": "0.008488419465720654",
   "Negative": "0.000632373325061053",
   "Neutral": "0.9908478260040283",
   "Mixed": "3.1420695449924096e-05"
 },
 {
   "tweet_id": "1590060177591783424",
   "Positive": "0.0014235101407393813",
   "Negative": "0.00045626406790688634",
   "Neutral": "0.99811851978302",
   "Mixed": "1.7711982991386321e-06"
 },
 {
   "tweet_id": "1590060175758487552",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060169530314753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590060159598198785",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590060139046141953",
   "Positive": "0.8873226642608643",
   "Negative": "0.0002450266038067639",
   "Neutral": "0.112390898168087",
   "Mixed": "4.145128696109168e-05"
 },
 {
   "tweet_id": "1590060138442149888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060137930424320",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590060118875701248",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060093092921344",
   "Positive": "0.05228434503078461",
   "Negative": "0.7305959463119507",
   "Neutral": "0.2169937789440155",
   "Mixed": "0.00012585098738782108"
 },
 {
   "tweet_id": "1590060089914068992",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1590060087296811008",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060079847419904",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590060077918130176",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590060070003347457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590060056686784512",
   "Positive": "0.12963557243347168",
   "Negative": "0.000526120129507035",
   "Neutral": "0.8695686459541321",
   "Mixed": "0.00026960167451761663"
 },
 {
   "tweet_id": "1590060055604629505",
   "Positive": "0.03765439614653587",
   "Negative": "0.0005256672156974673",
   "Neutral": "0.9618016481399536",
   "Mixed": "1.8252125300932676e-05"
 },
 {
   "tweet_id": "1590060054664749057",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590060050139484160",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590060049497747457",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590059976000536576",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590059941167255552",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1590059939275628544",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059932795437057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059927598694401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590059916898680832",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059899668803585",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059879808765952",
   "Positive": "0.631112277507782",
   "Negative": "0.014320749789476395",
   "Neutral": "0.24743667244911194",
   "Mixed": "0.10713030397891998"
 },
 {
   "tweet_id": "1590059848728993792",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059836326416384",
   "Positive": "0.001095141051337123",
   "Negative": "6.06554931437131e-05",
   "Neutral": "0.99883633852005",
   "Mixed": "7.90599369793199e-06"
 },
 {
   "tweet_id": "1590059803715710977",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590059796631273475",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059790004547585",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059789106958336",
   "Positive": "0.00043372673098929226",
   "Negative": "0.8937838077545166",
   "Neutral": "0.10576311498880386",
   "Mixed": "1.9260534827481024e-05"
 },
 {
   "tweet_id": "1590059757213470720",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059752905904128",
   "Positive": "0.04085371643304825",
   "Negative": "0.4319709539413452",
   "Neutral": "0.5269594788551331",
   "Mixed": "0.00021583031048066914"
 },
 {
   "tweet_id": "1590059747906301952",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1590059730508034048",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1590059707489976320",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1590059698929405955",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059696110866432",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059695783702528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059687826685952",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059677236482048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059646777446401",
   "Positive": "0.0016896453453227878",
   "Negative": "0.00047981561510823667",
   "Neutral": "0.9978187084197998",
   "Mixed": "1.1833189091703389e-05"
 },
 {
   "tweet_id": "1590059644470583297",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590059641853349888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059629710438400",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590059599268564992",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590059567857438721",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590059546998890497",
   "Positive": "0.0023891630116850138",
   "Negative": "0.3900948464870453",
   "Neutral": "0.5957919955253601",
   "Mixed": "0.011724093928933144"
 },
 {
   "tweet_id": "1590059529739243520",
   "Positive": "0.0027426141314208508",
   "Negative": "0.19721053540706635",
   "Neutral": "0.7999984622001648",
   "Mixed": "4.8377820348832756e-05"
 },
 {
   "tweet_id": "1590059526048583681",
   "Positive": "0.021281719207763672",
   "Negative": "0.7374255657196045",
   "Neutral": "0.2397310733795166",
   "Mixed": "0.0015616801101714373"
 },
 {
   "tweet_id": "1590059498231648256",
   "Positive": "0.34553682804107666",
   "Negative": "0.00775765860453248",
   "Neutral": "0.6466855406761169",
   "Mixed": "2.001963002840057e-05"
 },
 {
   "tweet_id": "1590059493286883330",
   "Positive": "0.9266289472579956",
   "Negative": "0.009100330993533134",
   "Neutral": "0.06150294840335846",
   "Mixed": "0.0027677181642502546"
 },
 {
   "tweet_id": "1590059486798295040",
   "Positive": "0.004581670742481947",
   "Negative": "0.03569157049059868",
   "Neutral": "0.9596893191337585",
   "Mixed": "3.7397414416773245e-05"
 },
 {
   "tweet_id": "1590059467710042112",
   "Positive": "0.08288425207138062",
   "Negative": "0.00504540977999568",
   "Neutral": "0.9120453000068665",
   "Mixed": "2.4989536541397683e-05"
 },
 {
   "tweet_id": "1590059467236073472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059457941102592",
   "Positive": "0.0023891630116850138",
   "Negative": "0.3900948464870453",
   "Neutral": "0.5957919955253601",
   "Mixed": "0.011724093928933144"
 },
 {
   "tweet_id": "1590059452849586177",
   "Positive": "0.6266282200813293",
   "Negative": "0.0017446415731683373",
   "Neutral": "0.3712361454963684",
   "Mixed": "0.00039100166759453714"
 },
 {
   "tweet_id": "1590059378266161152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059372415455232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590059370859343872",
   "Positive": "0.03318484500050545",
   "Negative": "0.1070929765701294",
   "Neutral": "0.8596716523170471",
   "Mixed": "5.052808774053119e-05"
 },
 {
   "tweet_id": "1590059370796449796",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059341209432064",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590059323522441217",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590059320938532865",
   "Positive": "0.7746299505233765",
   "Negative": "0.0002411707682767883",
   "Neutral": "0.2249787598848343",
   "Mixed": "0.0001500592625234276"
 },
 {
   "tweet_id": "1590059278643367941",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590059270997176320",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1590059269403312128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590059261857783808",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590059252030529538",
   "Positive": "0.24588395655155182",
   "Negative": "0.07586480677127838",
   "Neutral": "0.6768863201141357",
   "Mixed": "0.0013649460161104798"
 },
 {
   "tweet_id": "1590059239162417152",
   "Positive": "0.9409735798835754",
   "Negative": "0.0005929141188971698",
   "Neutral": "0.058228183537721634",
   "Mixed": "0.0002053284551948309"
 },
 {
   "tweet_id": "1590059236947808257",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059233764331521",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590059227162488832",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590059194446925824",
   "Positive": "0.0032251104712486267",
   "Negative": "0.02651543915271759",
   "Neutral": "0.970063328742981",
   "Mixed": "0.00019609929586295038"
 },
 {
   "tweet_id": "1590059194107199488",
   "Positive": "0.010710961185395718",
   "Negative": "0.0012868717312812805",
   "Neutral": "0.9879933595657349",
   "Mixed": "8.890684512152802e-06"
 },
 {
   "tweet_id": "1590059193331249154",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590059178370142208",
   "Positive": "0.03662479668855667",
   "Negative": "0.11788788437843323",
   "Neutral": "0.8377537727355957",
   "Mixed": "0.007733464241027832"
 },
 {
   "tweet_id": "1590059167234289665",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590059125714874368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059109587767296",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590059091472584704",
   "Positive": "0.01440219022333622",
   "Negative": "0.001230268506333232",
   "Neutral": "0.9841740131378174",
   "Mixed": "0.0001935508771566674"
 },
 {
   "tweet_id": "1590059067111657472",
   "Positive": "0.010347774252295494",
   "Negative": "0.45668432116508484",
   "Neutral": "0.3072225749492645",
   "Mixed": "0.225745290517807"
 },
 {
   "tweet_id": "1590059057485795328",
   "Positive": "0.01478142011910677",
   "Negative": "0.0013731002109125257",
   "Neutral": "0.9838359355926514",
   "Mixed": "9.588019565853756e-06"
 },
 {
   "tweet_id": "1590059051244998657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590059046958030848",
   "Positive": "0.006649776827543974",
   "Negative": "0.07232441753149033",
   "Neutral": "0.9209375977516174",
   "Mixed": "8.820787479635328e-05"
 },
 {
   "tweet_id": "1590058968205774848",
   "Positive": "0.11495260149240494",
   "Negative": "0.27689453959465027",
   "Neutral": "0.3945585787296295",
   "Mixed": "0.21359436213970184"
 },
 {
   "tweet_id": "1590058961876578304",
   "Positive": "0.0014042290858924389",
   "Negative": "0.0002883142442442477",
   "Neutral": "0.9983049631118774",
   "Mixed": "2.551303623476997e-06"
 },
 {
   "tweet_id": "1590058947083264000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590058923880763392",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590058914191904768",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590058884475281411",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590058849494798336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590058839252299778",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590058833413804033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590058832302338049",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590058832050688000",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058829211107328",
   "Positive": "0.0020378772169351578",
   "Negative": "7.821508916094899e-05",
   "Neutral": "0.9978784322738647",
   "Mixed": "5.526213953999104e-06"
 },
 {
   "tweet_id": "1590058807316697098",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590058755391369216",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058746192965632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590058681160597504",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590058649640374272",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590058640354209792",
   "Positive": "0.12418702244758606",
   "Negative": "0.4066910445690155",
   "Neutral": "0.46846991777420044",
   "Mixed": "0.0006519526359625161"
 },
 {
   "tweet_id": "1590058617738493952",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590058580446973952",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590058579088019456",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1590058556468113408",
   "Positive": "0.10146394371986389",
   "Negative": "0.0010450480040162802",
   "Neutral": "0.896327793598175",
   "Mixed": "0.0011632575187832117"
 },
 {
   "tweet_id": "1590058549702713345",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058547177353216",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590058541238300673",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590058539619270656",
   "Positive": "0.035479288548231125",
   "Negative": "0.00011601572623476386",
   "Neutral": "0.9643254280090332",
   "Mixed": "7.933942833915353e-05"
 },
 {
   "tweet_id": "1590058539342786560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590058529913991168",
   "Positive": "0.2698810398578644",
   "Negative": "0.018554238602519035",
   "Neutral": "0.710736095905304",
   "Mixed": "0.0008285769145004451"
 },
 {
   "tweet_id": "1590058514571202563",
   "Positive": "0.00015843971050344408",
   "Negative": "0.000490302627440542",
   "Neutral": "0.9993459582328796",
   "Mixed": "5.22445043316111e-06"
 },
 {
   "tweet_id": "1590058482568695808",
   "Positive": "0.7100921273231506",
   "Negative": "0.07799842208623886",
   "Neutral": "0.16686953604221344",
   "Mixed": "0.04503989219665527"
 },
 {
   "tweet_id": "1590058463883034625",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590058442848600066",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590058438427840512",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590058414394122240",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590058408648089602",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590058401207578625",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590058395167772672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590058370966654976",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590058350485852161",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590058345754689536",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590058345695563777",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590058311902457857",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590058291274878977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590058289189920768",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058282861105152",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590058281535696896",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058259217805313",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590058255786442753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590058249541541893",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590058242352128000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590058230977163265",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590058226200240129",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590058215794184192",
   "Positive": "0.00023275200510397553",
   "Negative": "0.9856788516044617",
   "Neutral": "0.014047080650925636",
   "Mixed": "4.133957554586232e-05"
 },
 {
   "tweet_id": "1590058205023186944",
   "Positive": "0.11509736627340317",
   "Negative": "0.00011925896251341328",
   "Neutral": "0.8847365379333496",
   "Mixed": "4.6866894990671426e-05"
 },
 {
   "tweet_id": "1590058195892207617",
   "Positive": "0.0016034954460337758",
   "Negative": "0.8619419932365417",
   "Neutral": "0.13524378836154938",
   "Mixed": "0.0012107397196814418"
 },
 {
   "tweet_id": "1590058188539564032",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590058177042661376",
   "Positive": "0.9143619537353516",
   "Negative": "0.001975943800061941",
   "Neutral": "0.08361916989088058",
   "Mixed": "4.294028985896148e-05"
 },
 {
   "tweet_id": "1590058172617981952",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590058164283736064",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590058150719541249",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058135305146368",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590058101805154304",
   "Positive": "0.058940738439559937",
   "Negative": "0.2905842661857605",
   "Neutral": "0.4273785650730133",
   "Mixed": "0.22309640049934387"
 },
 {
   "tweet_id": "1590058072348950529",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590058071094853633",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057982666342400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590057980862824449",
   "Positive": "0.10598400235176086",
   "Negative": "0.2558949291706085",
   "Neutral": "0.6063411831855774",
   "Mixed": "0.03177988901734352"
 },
 {
   "tweet_id": "1590057969089384448",
   "Positive": "0.18650586903095245",
   "Negative": "0.029186254367232323",
   "Neutral": "0.2937483489513397",
   "Mixed": "0.49055957794189453"
 },
 {
   "tweet_id": "1590057967554297857",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057949543923712",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057933639151617",
   "Positive": "0.15478375554084778",
   "Negative": "0.2675412893295288",
   "Neutral": "0.5140637159347534",
   "Mixed": "0.06361124664545059"
 },
 {
   "tweet_id": "1590057883492024320",
   "Positive": "0.0195754561573267",
   "Negative": "0.8046848773956299",
   "Neutral": "0.09398583322763443",
   "Mixed": "0.08175390213727951"
 },
 {
   "tweet_id": "1590057866529886208",
   "Positive": "0.02477410063147545",
   "Negative": "0.00012182266073068604",
   "Neutral": "0.9750843644142151",
   "Mixed": "1.961587622645311e-05"
 },
 {
   "tweet_id": "1590057855960616960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590057846879969280",
   "Positive": "0.0034815368708223104",
   "Negative": "0.2131025195121765",
   "Neutral": "0.783223032951355",
   "Mixed": "0.00019293070363346487"
 },
 {
   "tweet_id": "1590057843964932097",
   "Positive": "0.005163578316569328",
   "Negative": "0.0005510399350896478",
   "Neutral": "0.9942812919616699",
   "Mixed": "4.152612291363766e-06"
 },
 {
   "tweet_id": "1590057836150542336",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590057827959476224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057823072792576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057818194726913",
   "Positive": "0.01784735918045044",
   "Negative": "0.0002038939855992794",
   "Neutral": "0.9819357991218567",
   "Mixed": "1.283871733903652e-05"
 },
 {
   "tweet_id": "1590057802390962176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057796669964288",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590057771093102592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057742714093569",
   "Positive": "0.1770954579114914",
   "Negative": "0.00038808261160738766",
   "Neutral": "0.8221721649169922",
   "Mixed": "0.00034419598523527384"
 },
 {
   "tweet_id": "1590057735705743360",
   "Positive": "0.011402523145079613",
   "Negative": "0.00016721284191589803",
   "Neutral": "0.9884234070777893",
   "Mixed": "6.857713287899969e-06"
 },
 {
   "tweet_id": "1590057709688086528",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057694353924098",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057687777091584",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1590057676976795648",
   "Positive": "0.0027722464874386787",
   "Negative": "0.007687194272875786",
   "Neutral": "0.9894850850105286",
   "Mixed": "5.538156256079674e-05"
 },
 {
   "tweet_id": "1590057653350567938",
   "Positive": "0.06786978989839554",
   "Negative": "0.010542478412389755",
   "Neutral": "0.9215799570083618",
   "Mixed": "7.819194252078887e-06"
 },
 {
   "tweet_id": "1590057620345614338",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590057593208451072",
   "Positive": "0.06558021903038025",
   "Negative": "0.16207355260849",
   "Neutral": "0.7722485065460205",
   "Mixed": "9.780137770576403e-05"
 },
 {
   "tweet_id": "1590057581221154817",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590057570307563520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590057540326678529",
   "Positive": "0.0047385841608047485",
   "Negative": "0.0001801974285626784",
   "Neutral": "0.9950764775276184",
   "Mixed": "4.76213881484e-06"
 },
 {
   "tweet_id": "1590057536958627841",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590057527886372864",
   "Positive": "0.003944505471736193",
   "Negative": "0.005539304576814175",
   "Neutral": "0.990423858165741",
   "Mixed": "9.231254080077633e-05"
 },
 {
   "tweet_id": "1590057521762697216",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590057520475013120",
   "Positive": "0.09851569682359695",
   "Negative": "0.0006231860024854541",
   "Neutral": "0.9001110196113586",
   "Mixed": "0.0007501671789214015"
 },
 {
   "tweet_id": "1590057480394248192",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590057477311434753",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057470860627969",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590057456159580160",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057444608466944",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590057418343739395",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590057410617802752",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057375960293376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057367299051520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590057352279232512",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1590057347958951938",
   "Positive": "0.11841641366481781",
   "Negative": "0.00595316756516695",
   "Neutral": "0.875601589679718",
   "Mixed": "2.879529529309366e-05"
 },
 {
   "tweet_id": "1590057325444104193",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590057322071531520",
   "Positive": "0.10628100484609604",
   "Negative": "0.2069832980632782",
   "Neutral": "0.25961026549339294",
   "Mixed": "0.42712539434432983"
 },
 {
   "tweet_id": "1590057279852011520",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590057272998494208",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057226768912384",
   "Positive": "0.039670541882514954",
   "Negative": "0.1478545069694519",
   "Neutral": "0.5817915201187134",
   "Mixed": "0.23068341612815857"
 },
 {
   "tweet_id": "1590057221869953024",
   "Positive": "0.01567530632019043",
   "Negative": "0.28053364157676697",
   "Neutral": "0.41798391938209534",
   "Mixed": "0.2858071029186249"
 },
 {
   "tweet_id": "1590057211690356737",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057201116549121",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590057157848096769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590057147131654144",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057136465530880",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057135668625409",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057115003293696",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590057103699619840",
   "Positive": "0.038378190249204636",
   "Negative": "0.2853790521621704",
   "Neutral": "0.6508578658103943",
   "Mixed": "0.02538491226732731"
 },
 {
   "tweet_id": "1590057103020130305",
   "Positive": "0.02859547734260559",
   "Negative": "0.1259465217590332",
   "Neutral": "0.8452362418174744",
   "Mixed": "0.00022175123740453273"
 },
 {
   "tweet_id": "1590057088721752064",
   "Positive": "0.0016626609722152352",
   "Negative": "0.00014873304462525994",
   "Neutral": "0.998177170753479",
   "Mixed": "1.1464402632554993e-05"
 },
 {
   "tweet_id": "1590057078986485760",
   "Positive": "0.004755061119794846",
   "Negative": "0.8940428495407104",
   "Neutral": "0.10105883330106735",
   "Mixed": "0.00014321683556772768"
 },
 {
   "tweet_id": "1590057053913239552",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1590057044010471425",
   "Positive": "0.005541677121073008",
   "Negative": "0.7519084811210632",
   "Neutral": "0.08808787912130356",
   "Mixed": "0.154462069272995"
 },
 {
   "tweet_id": "1590057042030759936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590057039018876928",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590057033260101632",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590056994186358785",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056955711627265",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056920047833088",
   "Positive": "0.07048095762729645",
   "Negative": "0.010625943541526794",
   "Neutral": "0.918440580368042",
   "Mixed": "0.00045247035450302064"
 },
 {
   "tweet_id": "1590056903354515456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590056866008403968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590056840053673984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056830172303362",
   "Positive": "0.00061223836382851",
   "Negative": "0.0017419265350326896",
   "Neutral": "0.9976358413696289",
   "Mixed": "9.984785720007494e-06"
 },
 {
   "tweet_id": "1590056824346415104",
   "Positive": "0.0012675925390794873",
   "Negative": "0.00029991482733748853",
   "Neutral": "0.9984304308891296",
   "Mixed": "2.032714746746933e-06"
 },
 {
   "tweet_id": "1590056816675020801",
   "Positive": "0.12418771535158157",
   "Negative": "0.36468732357025146",
   "Neutral": "0.305159330368042",
   "Mixed": "0.2059655636548996"
 },
 {
   "tweet_id": "1590056808126693376",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056778896928768",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056752187576320",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056718863826944",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056717257437185",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056710978547712",
   "Positive": "0.10295051336288452",
   "Negative": "0.007640805561095476",
   "Neutral": "0.8887820839881897",
   "Mixed": "0.0006265710690058768"
 },
 {
   "tweet_id": "1590056710282309632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056704284430336",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056695828729858",
   "Positive": "0.0025873498525470495",
   "Negative": "0.8424155116081238",
   "Neutral": "0.14629094302654266",
   "Mixed": "0.008706203661859035"
 },
 {
   "tweet_id": "1590056693244653568",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1590056668263763971",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590056661238304768",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590056656557453313",
   "Positive": "0.005850881803780794",
   "Negative": "0.00025583678507246077",
   "Neutral": "0.9938899278640747",
   "Mixed": "3.2979230581986485e-06"
 },
 {
   "tweet_id": "1590056639763447810",
   "Positive": "0.9482904672622681",
   "Negative": "0.0003011502849403769",
   "Neutral": "0.05104154720902443",
   "Mixed": "0.0003668427234515548"
 },
 {
   "tweet_id": "1590056635682422784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056633593651200",
   "Positive": "0.00412445142865181",
   "Negative": "0.0002863759873434901",
   "Neutral": "0.9955828785896301",
   "Mixed": "6.310454864433268e-06"
 },
 {
   "tweet_id": "1590056622868553730",
   "Positive": "0.005026310216635466",
   "Negative": "0.7182332277297974",
   "Neutral": "0.24566303193569183",
   "Mixed": "0.031077351421117783"
 },
 {
   "tweet_id": "1590056621874434048",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590056569902977025",
   "Positive": "0.3846795856952667",
   "Negative": "0.002888717921450734",
   "Neutral": "0.5070571303367615",
   "Mixed": "0.10537455976009369"
 },
 {
   "tweet_id": "1590056567386570752",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590056567315259395",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590056567298150400",
   "Positive": "0.001175701036117971",
   "Negative": "6.224982644198462e-05",
   "Neutral": "0.9987561702728271",
   "Mixed": "5.857108590134885e-06"
 },
 {
   "tweet_id": "1590056566845104128",
   "Positive": "0.2511187791824341",
   "Negative": "0.06491602212190628",
   "Neutral": "0.6790968775749207",
   "Mixed": "0.0048683383502066135"
 },
 {
   "tweet_id": "1590056565561708545",
   "Positive": "0.012935401871800423",
   "Negative": "0.003856631927192211",
   "Neutral": "0.9831790924072266",
   "Mixed": "2.877824954339303e-05"
 },
 {
   "tweet_id": "1590056565100277760",
   "Positive": "0.010675954632461071",
   "Negative": "0.5484340786933899",
   "Neutral": "0.429063081741333",
   "Mixed": "0.011826860718429089"
 },
 {
   "tweet_id": "1590056563661676549",
   "Positive": "0.001040613860823214",
   "Negative": "0.0016268137842416763",
   "Neutral": "0.997211754322052",
   "Mixed": "0.00012083534966222942"
 },
 {
   "tweet_id": "1590056549682417666",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056534960066560",
   "Positive": "0.007594123482704163",
   "Negative": "0.00022612720204051584",
   "Neutral": "0.992174506187439",
   "Mixed": "5.274463546811603e-06"
 },
 {
   "tweet_id": "1590056528345956352",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056524449480704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056521966424065",
   "Positive": "0.014130412600934505",
   "Negative": "0.00039573333924636245",
   "Neutral": "0.9854632616043091",
   "Mixed": "1.0586969438008964e-05"
 },
 {
   "tweet_id": "1590056479188742146",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590056478534426624",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590056472012288000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590056471194370048",
   "Positive": "0.9995110034942627",
   "Negative": "4.197186353849247e-05",
   "Neutral": "0.0004253138031344861",
   "Mixed": "2.1736477719969116e-05"
 },
 {
   "tweet_id": "1590056469982216197",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056451615391745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590056413338161152",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590056407680061440",
   "Positive": "0.18949587643146515",
   "Negative": "0.001081918366253376",
   "Neutral": "0.8091916441917419",
   "Mixed": "0.0002305354573763907"
 },
 {
   "tweet_id": "1590056324985139200",
   "Positive": "0.10675341635942459",
   "Negative": "0.0008996649994514883",
   "Neutral": "0.8922863602638245",
   "Mixed": "6.057431164663285e-05"
 },
 {
   "tweet_id": "1590056315677978624",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056304688914433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590056298300964864",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590056278738755584",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056270232358914",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590056269960073216",
   "Positive": "0.06869803369045258",
   "Negative": "0.10261616110801697",
   "Neutral": "0.5775842070579529",
   "Mixed": "0.2511015832424164"
 },
 {
   "tweet_id": "1590056266130657280",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590056263060140032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590056256064344066",
   "Positive": "0.09962746500968933",
   "Negative": "0.08376920968294144",
   "Neutral": "0.7876026630401611",
   "Mixed": "0.02900073118507862"
 },
 {
   "tweet_id": "1590056231900966912",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056224871305216",
   "Positive": "0.08753538131713867",
   "Negative": "0.48221588134765625",
   "Neutral": "0.4291873574256897",
   "Mixed": "0.0010613834019750357"
 },
 {
   "tweet_id": "1590056217598398466",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590056217501904896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590056141836341248",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056130130374656",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056119439085568",
   "Positive": "0.9457883238792419",
   "Negative": "0.005341433919966221",
   "Neutral": "0.04870494082570076",
   "Mixed": "0.00016520012286491692"
 },
 {
   "tweet_id": "1590056111117570049",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590056104545091586",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590056099964936192",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590056084491730944",
   "Positive": "0.012494954280555248",
   "Negative": "0.009056240320205688",
   "Neutral": "0.9784448742866516",
   "Mixed": "3.982705038652057e-06"
 },
 {
   "tweet_id": "1590056080427470848",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590056073330761728",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590056072580300800",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590056033204203521",
   "Positive": "0.010717076249420643",
   "Negative": "0.6523082256317139",
   "Neutral": "0.3367481529712677",
   "Mixed": "0.0002265864168293774"
 },
 {
   "tweet_id": "1590056010177470465",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590055990141292545",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590055977302495232",
   "Positive": "0.14964589476585388",
   "Negative": "0.0007519221398979425",
   "Neutral": "0.8495651483535767",
   "Mixed": "3.710115925059654e-05"
 },
 {
   "tweet_id": "1590055967903080448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055961955278848",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590055956196757504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055914446684160",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1590055906150330368",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590055888210956288",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590055864345726977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590055850206728192",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590055826387243010",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055806959247361",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590055779096465408",
   "Positive": "0.007132811471819878",
   "Negative": "0.9343190789222717",
   "Neutral": "0.058398813009262085",
   "Mixed": "0.0001492337032686919"
 },
 {
   "tweet_id": "1590055732321619969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055720468500480",
   "Positive": "0.8546097278594971",
   "Negative": "0.005119000095874071",
   "Neutral": "0.1399596482515335",
   "Mixed": "0.00031156509066931903"
 },
 {
   "tweet_id": "1590055712553832448",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590055705629044737",
   "Positive": "0.02468775026500225",
   "Negative": "0.00011053057096432894",
   "Neutral": "0.9751425385475159",
   "Mixed": "5.9171670727664605e-05"
 },
 {
   "tweet_id": "1590055702537670657",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590055692991627264",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590055669452836864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055639002550272",
   "Positive": "0.04356291890144348",
   "Negative": "0.0012181333731859922",
   "Neutral": "0.9552011489868164",
   "Mixed": "1.7809672499424778e-05"
 },
 {
   "tweet_id": "1590055601845202945",
   "Positive": "0.0009252353338524699",
   "Negative": "0.7050691246986389",
   "Neutral": "0.2939038574695587",
   "Mixed": "0.00010175831994274631"
 },
 {
   "tweet_id": "1590055588460990471",
   "Positive": "0.0008861134992912412",
   "Negative": "0.0002840529487002641",
   "Neutral": "0.9988148212432861",
   "Mixed": "1.5013221855042502e-05"
 },
 {
   "tweet_id": "1590055557075173377",
   "Positive": "0.009720485657453537",
   "Negative": "0.00048471178160980344",
   "Neutral": "0.9897805452346802",
   "Mixed": "1.4301711416919716e-05"
 },
 {
   "tweet_id": "1590055544739360768",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590055495641235456",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1590055491841163265",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590055490947805184",
   "Positive": "0.023024162277579308",
   "Negative": "7.83175346441567e-05",
   "Neutral": "0.9768131971359253",
   "Mixed": "8.436192001681775e-05"
 },
 {
   "tweet_id": "1590055473071460355",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590055467870363648",
   "Positive": "0.0022695588413625956",
   "Negative": "0.0001258522243006155",
   "Neutral": "0.997588038444519",
   "Mixed": "1.6479558325954713e-05"
 },
 {
   "tweet_id": "1590055467673587712",
   "Positive": "0.6125291585922241",
   "Negative": "0.0003343254211358726",
   "Neutral": "0.3870856463909149",
   "Mixed": "5.085153679829091e-05"
 },
 {
   "tweet_id": "1590055464469168128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055443518607361",
   "Positive": "0.3401280641555786",
   "Negative": "0.04118408262729645",
   "Neutral": "0.6185284852981567",
   "Mixed": "0.00015939502918627113"
 },
 {
   "tweet_id": "1590055437051002880",
   "Positive": "0.04427272826433182",
   "Negative": "0.008451875299215317",
   "Neutral": "0.9472256898880005",
   "Mixed": "4.9644582759356126e-05"
 },
 {
   "tweet_id": "1590055407426621441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055382424375296",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590055372618076162",
   "Positive": "0.2464790940284729",
   "Negative": "0.0007515708566643298",
   "Neutral": "0.7525259256362915",
   "Mixed": "0.00024347982252947986"
 },
 {
   "tweet_id": "1590055369505579010",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055256662343680",
   "Positive": "0.07676653563976288",
   "Negative": "0.0011383048258721828",
   "Neutral": "0.9220634698867798",
   "Mixed": "3.16417463182006e-05"
 },
 {
   "tweet_id": "1590055243660029952",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590055238169677824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055237976723458",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055225188323330",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590055223657398275",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055220150562816",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590055218812956672",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590055212123041793",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590055208318828545",
   "Positive": "0.20565097033977509",
   "Negative": "0.27722248435020447",
   "Neutral": "0.11849723756313324",
   "Mixed": "0.398629367351532"
 },
 {
   "tweet_id": "1590055196176285696",
   "Positive": "0.059901997447013855",
   "Negative": "0.0006352743366733193",
   "Neutral": "0.9391804933547974",
   "Mixed": "0.00028221571119502187"
 },
 {
   "tweet_id": "1590055181043240960",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590055155424116737",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590055127263547392",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590055111279403009",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590055110335414272",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590055108582453249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055106611142656",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1590055089816813570",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590055086377824258",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590055079381721088",
   "Positive": "0.001206594635732472",
   "Negative": "0.0001707138872006908",
   "Neutral": "0.9986069798469543",
   "Mixed": "1.575667738507036e-05"
 },
 {
   "tweet_id": "1590055051384397824",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590055043570753537",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1590055032149639169",
   "Positive": "0.004384471569210291",
   "Negative": "0.00021118848235346377",
   "Neutral": "0.9953839182853699",
   "Mixed": "2.045656947302632e-05"
 },
 {
   "tweet_id": "1590054991699795968",
   "Positive": "0.17380857467651367",
   "Negative": "0.012509610503911972",
   "Neutral": "0.8136441707611084",
   "Mixed": "3.7623001844622195e-05"
 },
 {
   "tweet_id": "1590054989028012034",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590054980450684928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054951266693120",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054938503442433",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590054929020096512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590054924658040832",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590054917657751552",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590054903497752576",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590054903237386241",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590054896187084801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054867649069056",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590054857721155585",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054853820440577",
   "Positive": "0.06786978989839554",
   "Negative": "0.010542478412389755",
   "Neutral": "0.9215799570083618",
   "Mixed": "7.819194252078887e-06"
 },
 {
   "tweet_id": "1590054852608282625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054818680557568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054815908139009",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054795813220353",
   "Positive": "0.014216999523341656",
   "Negative": "0.5216274261474609",
   "Neutral": "0.16957445442676544",
   "Mixed": "0.2945810854434967"
 },
 {
   "tweet_id": "1590054795435311105",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054793292427264",
   "Positive": "0.013669467531144619",
   "Negative": "0.0002903708955273032",
   "Neutral": "0.9860097169876099",
   "Mixed": "3.0492414225591347e-05"
 },
 {
   "tweet_id": "1590054781103767552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590054776456159232",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590054763307364352",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590054762908508160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054733926268929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054725340532736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590054707296612352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054670751641602",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590054649033551873",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590054632901877761",
   "Positive": "0.42242130637168884",
   "Negative": "0.0035454847384244204",
   "Neutral": "0.40255463123321533",
   "Mixed": "0.1714785397052765"
 },
 {
   "tweet_id": "1590054627118284801",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590054614430535680",
   "Positive": "0.0004687112523242831",
   "Negative": "0.9651193618774414",
   "Neutral": "0.03435155004262924",
   "Mixed": "6.040927110007033e-05"
 },
 {
   "tweet_id": "1590054568419033091",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590054558537244672",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054557438312448",
   "Positive": "0.010154392570257187",
   "Negative": "0.47541162371635437",
   "Neutral": "0.5069687366485596",
   "Mixed": "0.007465266156941652"
 },
 {
   "tweet_id": "1590054549544660992",
   "Positive": "0.007594123482704163",
   "Negative": "0.00022612720204051584",
   "Neutral": "0.992174506187439",
   "Mixed": "5.274463546811603e-06"
 },
 {
   "tweet_id": "1590054514224422912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054501486333956",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054495097978881",
   "Positive": "0.011640499345958233",
   "Negative": "0.00010091083822771907",
   "Neutral": "0.988243818283081",
   "Mixed": "1.474394684919389e-05"
 },
 {
   "tweet_id": "1590054483132026880",
   "Positive": "0.5302823781967163",
   "Negative": "0.003978471737354994",
   "Neutral": "0.465712308883667",
   "Mixed": "2.681318983377423e-05"
 },
 {
   "tweet_id": "1590054468434788352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054460549918720",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590054448453263360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054426374328320",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590054384188420096",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054378341531649",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590054373471977473",
   "Positive": "0.2802838981151581",
   "Negative": "0.003022377146407962",
   "Neutral": "0.7166268229484558",
   "Mixed": "6.687235872959718e-05"
 },
 {
   "tweet_id": "1590054352802439170",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590054334313922560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054328794222592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590054310901317638",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590054308355067904",
   "Positive": "0.03732472285628319",
   "Negative": "0.3093917667865753",
   "Neutral": "0.6468780040740967",
   "Mixed": "0.006405530031770468"
 },
 {
   "tweet_id": "1590054307168387072",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590054303154458625",
   "Positive": "0.42579588294029236",
   "Negative": "0.016965333372354507",
   "Neutral": "0.5368381142616272",
   "Mixed": "0.020400697365403175"
 },
 {
   "tweet_id": "1590054297307258880",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590054286834425856",
   "Positive": "0.0025481258053332567",
   "Negative": "0.00013337230484467",
   "Neutral": "0.9973153471946716",
   "Mixed": "3.0747928576602135e-06"
 },
 {
   "tweet_id": "1590054255062577152",
   "Positive": "0.007657030131667852",
   "Negative": "9.627221152186394e-05",
   "Neutral": "0.9922304749488831",
   "Mixed": "1.6174713891814463e-05"
 },
 {
   "tweet_id": "1590054232140705792",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590054224830005248",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590054223378796545",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054190910672896",
   "Positive": "0.0008378957863897085",
   "Negative": "0.7661256194114685",
   "Neutral": "0.23300695419311523",
   "Mixed": "2.9548207749030553e-05"
 },
 {
   "tweet_id": "1590054189287493633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054179942600704",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590054161328263169",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590054150167236608",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1590054147721932800",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590054138658054144",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590054123889889280",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590054119552622592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054111013064704",
   "Positive": "0.03482109680771828",
   "Negative": "0.6617353558540344",
   "Neutral": "0.3026826083660126",
   "Mixed": "0.0007609052117913961"
 },
 {
   "tweet_id": "1590054091845435393",
   "Positive": "0.4841519594192505",
   "Negative": "0.0001340752060059458",
   "Neutral": "0.515400767326355",
   "Mixed": "0.0003132857382297516"
 },
 {
   "tweet_id": "1590054091668889600",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590054086086623233",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1590054085738524675",
   "Positive": "0.0733371302485466",
   "Negative": "0.03784850612282753",
   "Neutral": "0.8885492086410522",
   "Mixed": "0.0002652684925124049"
 },
 {
   "tweet_id": "1590054085239398401",
   "Positive": "0.013893235474824905",
   "Negative": "0.006196051370352507",
   "Neutral": "0.011698161251842976",
   "Mixed": "0.9682125449180603"
 },
 {
   "tweet_id": "1590054076368420864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590054058848833537",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590054058546835457",
   "Positive": "0.291304349899292",
   "Negative": "0.43971121311187744",
   "Neutral": "0.23725365102291107",
   "Mixed": "0.031730812042951584"
 },
 {
   "tweet_id": "1590054036958347265",
   "Positive": "0.7286835312843323",
   "Negative": "0.0010801031021401286",
   "Neutral": "0.2702016532421112",
   "Mixed": "3.46815686498303e-05"
 },
 {
   "tweet_id": "1590054034802905088",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590054030881193986",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590054027773235203",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054022370955265",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590054015471345665",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590054012027834368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590054008563322880",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590054006726225920",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590053989722513408",
   "Positive": "0.0018768180161714554",
   "Negative": "0.9056609272956848",
   "Neutral": "0.09202059358358383",
   "Mixed": "0.00044173019705340266"
 },
 {
   "tweet_id": "1590053989185650689",
   "Positive": "0.7262865900993347",
   "Negative": "0.0005480308900587261",
   "Neutral": "0.27309510111808777",
   "Mixed": "7.023624493740499e-05"
 },
 {
   "tweet_id": "1590053986912329728",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053984450277376",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590053984043438082",
   "Positive": "0.9428809285163879",
   "Negative": "0.001041493029333651",
   "Neutral": "0.05595440790057182",
   "Mixed": "0.0001231823698617518"
 },
 {
   "tweet_id": "1590053982789304320",
   "Positive": "0.00017820476205088198",
   "Negative": "0.0002371615992160514",
   "Neutral": "0.9995810389518738",
   "Mixed": "3.5545278933568625e-06"
 },
 {
   "tweet_id": "1590053980788649986",
   "Positive": "0.0020202999003231525",
   "Negative": "0.0008909986936487257",
   "Neutral": "0.9970864653587341",
   "Mixed": "2.261373992951121e-06"
 },
 {
   "tweet_id": "1590053967463342082",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590053961515794432",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590053921275666434",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590053921258901504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053904326463488",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590053900606140416",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590053894868328448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053890174914561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590053878833197056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053873754177537",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590053860856324097",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590053855122771969",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590053854216716288",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590053852413583361",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590053848198287361",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590053838522056704",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590053833891545088",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590053832658386945",
   "Positive": "0.21973900496959686",
   "Negative": "0.030655018985271454",
   "Neutral": "0.7495236396789551",
   "Mixed": "8.2295824540779e-05"
 },
 {
   "tweet_id": "1590053826551480321",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053802304208896",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590053764647780352",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053746557714433",
   "Positive": "0.01907007023692131",
   "Negative": "0.017301494255661964",
   "Neutral": "0.9636236429214478",
   "Mixed": "4.8094639168994036e-06"
 },
 {
   "tweet_id": "1590053721912008705",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053707911004161",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053685320888320",
   "Positive": "0.01953975111246109",
   "Negative": "0.026650819927453995",
   "Neutral": "0.9532989263534546",
   "Mixed": "0.000510428799316287"
 },
 {
   "tweet_id": "1590053671391625219",
   "Positive": "0.025220077484846115",
   "Negative": "0.0018080227309837937",
   "Neutral": "0.9729328155517578",
   "Mixed": "3.906308484147303e-05"
 },
 {
   "tweet_id": "1590053655839137792",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053639611375617",
   "Positive": "0.3777422308921814",
   "Negative": "0.0007093471358530223",
   "Neutral": "0.6215071082115173",
   "Mixed": "4.125601481064223e-05"
 },
 {
   "tweet_id": "1590053628995592192",
   "Positive": "0.010943218134343624",
   "Negative": "0.007658710237592459",
   "Neutral": "0.9812918901443481",
   "Mixed": "0.00010624216520227492"
 },
 {
   "tweet_id": "1590053624134406144",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053615632547843",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053613904461824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590053591284580353",
   "Positive": "0.0014676244463771582",
   "Negative": "0.000287209521047771",
   "Neutral": "0.9982407093048096",
   "Mixed": "4.402482773002703e-06"
 },
 {
   "tweet_id": "1590053572166942722",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590053568706678785",
   "Positive": "0.04465622827410698",
   "Negative": "0.25113075971603394",
   "Neutral": "0.07027260214090347",
   "Mixed": "0.6339404582977295"
 },
 {
   "tweet_id": "1590053559952777217",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590053550196813825",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590053545554120704",
   "Positive": "0.03288045525550842",
   "Negative": "0.0009027486084960401",
   "Neutral": "0.9660463929176331",
   "Mixed": "0.00017043935076799244"
 },
 {
   "tweet_id": "1590053543578566656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590053514000367616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590053492101480449",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1590053485332299777",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590053482106859520",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590053429858402304",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053418097180672",
   "Positive": "8.15344974398613e-05",
   "Negative": "0.0163788590580225",
   "Neutral": "0.9835220575332642",
   "Mixed": "1.7599873899598606e-05"
 },
 {
   "tweet_id": "1590053407641202688",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590053366914486272",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590053350657380352",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590053335947964417",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053332881911808",
   "Positive": "0.0028212061151862144",
   "Negative": "0.14193971455097198",
   "Neutral": "0.011331316083669662",
   "Mixed": "0.8439077138900757"
 },
 {
   "tweet_id": "1590053331594280960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590053330457628672",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1590053326871494657",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590053325592223744",
   "Positive": "0.06831198930740356",
   "Negative": "0.5477970242500305",
   "Neutral": "0.3781237304210663",
   "Mixed": "0.0057671754620969296"
 },
 {
   "tweet_id": "1590053319795699712",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053317094572032",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053315324542976",
   "Positive": "0.08383729308843613",
   "Negative": "0.40247416496276855",
   "Neutral": "0.3875046372413635",
   "Mixed": "0.12618388235569"
 },
 {
   "tweet_id": "1590053312078155777",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590053311348371456",
   "Positive": "0.01665603369474411",
   "Negative": "0.0008056762744672596",
   "Neutral": "0.982528030872345",
   "Mixed": "1.0248764738207683e-05"
 },
 {
   "tweet_id": "1590053267656302592",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1590053267345924097",
   "Positive": "0.004666131921112537",
   "Negative": "5.228845475357957e-05",
   "Neutral": "0.9952507019042969",
   "Mixed": "3.0842129490338266e-05"
 },
 {
   "tweet_id": "1590053230280462340",
   "Positive": "0.0262502059340477",
   "Negative": "0.2588716149330139",
   "Neutral": "0.3581301271915436",
   "Mixed": "0.3567480742931366"
 },
 {
   "tweet_id": "1590053214581555200",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053206411079681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590053173682909185",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590053173170798592",
   "Positive": "0.0005842836108058691",
   "Negative": "0.15488170087337494",
   "Neutral": "0.8445023894309998",
   "Mixed": "3.15619217872154e-05"
 },
 {
   "tweet_id": "1590053172009394178",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590053167894777857",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053149871869952",
   "Positive": "0.0033081655856221914",
   "Negative": "0.0009322201367467642",
   "Neutral": "0.9957475066184998",
   "Mixed": "1.2111300748074427e-05"
 },
 {
   "tweet_id": "1590053116355182592",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590053109140971520",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590053101968715776",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053090220122112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590053080468697089",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590053075770740739",
   "Positive": "0.017759129405021667",
   "Negative": "0.38484588265419006",
   "Neutral": "0.4284600615501404",
   "Mixed": "0.1689348816871643"
 },
 {
   "tweet_id": "1590053075368091648",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590053071652294657",
   "Positive": "0.03944079577922821",
   "Negative": "0.17498910427093506",
   "Neutral": "0.7849574089050293",
   "Mixed": "0.0006127551314420998"
 },
 {
   "tweet_id": "1590053070742126592",
   "Positive": "0.16152285039424896",
   "Negative": "0.0013549266150221229",
   "Neutral": "0.8370819091796875",
   "Mixed": "4.033920413348824e-05"
 },
 {
   "tweet_id": "1590053048184774656",
   "Positive": "0.0003851185610983521",
   "Negative": "0.011027993634343147",
   "Neutral": "0.988569438457489",
   "Mixed": "1.7453496184316464e-05"
 },
 {
   "tweet_id": "1590053044980715520",
   "Positive": "0.004902326036244631",
   "Negative": "0.004019385669380426",
   "Neutral": "0.9907429814338684",
   "Mixed": "0.0003352701314724982"
 },
 {
   "tweet_id": "1590053037699047424",
   "Positive": "0.0002890347095672041",
   "Negative": "0.0014375447062775493",
   "Neutral": "0.99826979637146",
   "Mixed": "3.533854169290862e-06"
 },
 {
   "tweet_id": "1590053035761233920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590053032800456704",
   "Positive": "0.5990712642669678",
   "Negative": "0.002538534114137292",
   "Neutral": "0.3983533978462219",
   "Mixed": "3.683491740957834e-05"
 },
 {
   "tweet_id": "1590053030900428801",
   "Positive": "0.00061223836382851",
   "Negative": "0.0017419265350326896",
   "Neutral": "0.9976358413696289",
   "Mixed": "9.984785720007494e-06"
 },
 {
   "tweet_id": "1590053030388727808",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590053014509092865",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590053013154320386",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590053008116568064",
   "Positive": "0.9275429844856262",
   "Negative": "0.0011178208515048027",
   "Neutral": "0.07128085941076279",
   "Mixed": "5.840875383000821e-05"
 },
 {
   "tweet_id": "1590052948125810690",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052938025664512",
   "Positive": "0.43073031306266785",
   "Negative": "0.0024960676673799753",
   "Neutral": "0.566756546497345",
   "Mixed": "1.7007461792672984e-05"
 },
 {
   "tweet_id": "1590052930291654656",
   "Positive": "0.0002890347095672041",
   "Negative": "0.0014375447062775493",
   "Neutral": "0.99826979637146",
   "Mixed": "3.533854169290862e-06"
 },
 {
   "tweet_id": "1590052929847054338",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590052926697148416",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590052926260940800",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590052869138366464",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590052844719075328",
   "Positive": "0.5192423462867737",
   "Negative": "0.0059631685726344585",
   "Neutral": "0.47476500272750854",
   "Mixed": "2.952693284896668e-05"
 },
 {
   "tweet_id": "1590052824830078976",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052795868409863",
   "Positive": "0.42694520950317383",
   "Negative": "0.00019421178149059415",
   "Neutral": "0.5723986625671387",
   "Mixed": "0.0004618769162334502"
 },
 {
   "tweet_id": "1590052793012080640",
   "Positive": "0.598851203918457",
   "Negative": "0.00026790672563947737",
   "Neutral": "0.4008185863494873",
   "Mixed": "6.232265150174499e-05"
 },
 {
   "tweet_id": "1590052791225298946",
   "Positive": "0.0012293116888031363",
   "Negative": "0.8457307815551758",
   "Neutral": "0.152971088886261",
   "Mixed": "6.89153021085076e-05"
 },
 {
   "tweet_id": "1590052784635875330",
   "Positive": "0.000525657378602773",
   "Negative": "0.0004588674637489021",
   "Neutral": "0.9990118741989136",
   "Mixed": "3.617978791226051e-06"
 },
 {
   "tweet_id": "1590052773256912897",
   "Positive": "0.026217680424451828",
   "Negative": "0.0005655972054228187",
   "Neutral": "0.9732015132904053",
   "Mixed": "1.5157732377701905e-05"
 },
 {
   "tweet_id": "1590052772459999232",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590052766625718273",
   "Positive": "0.12091494351625443",
   "Negative": "0.01682925410568714",
   "Neutral": "0.8622354865074158",
   "Mixed": "2.036897603829857e-05"
 },
 {
   "tweet_id": "1590052749856890881",
   "Positive": "0.001691334880888462",
   "Negative": "0.8619697690010071",
   "Neutral": "0.13619831204414368",
   "Mixed": "0.00014059738896321505"
 },
 {
   "tweet_id": "1590052749118693376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590052748468572161",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590052747537453056",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590052738544508929",
   "Positive": "0.14425045251846313",
   "Negative": "0.0006820950075052679",
   "Neutral": "0.8541154861450195",
   "Mixed": "0.0009519812883809209"
 },
 {
   "tweet_id": "1590052717858549760",
   "Positive": "0.03214628994464874",
   "Negative": "0.7438885569572449",
   "Neutral": "0.22391948103904724",
   "Mixed": "4.569334487314336e-05"
 },
 {
   "tweet_id": "1590052715060547584",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1590052707683164161",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052702662164482",
   "Positive": "0.31120145320892334",
   "Negative": "0.00018196359451394528",
   "Neutral": "0.6885278820991516",
   "Mixed": "8.861823152983561e-05"
 },
 {
   "tweet_id": "1590052702184443904",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590052687302696960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052681238089728",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590052662829277185",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052659691917312",
   "Positive": "0.009454051032662392",
   "Negative": "0.0015446434263139963",
   "Neutral": "0.9889862537384033",
   "Mixed": "1.5037032426334918e-05"
 },
 {
   "tweet_id": "1590052653295230976",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590052651928289280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052642457546753",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590052641471889408",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590052637172715520",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590052609951698945",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590052553169211393",
   "Positive": "0.024575211107730865",
   "Negative": "0.0006528408848680556",
   "Neutral": "0.9747416377067566",
   "Mixed": "3.0306116968858987e-05"
 },
 {
   "tweet_id": "1590052544738623488",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590052542431776768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052529450385408",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052508142968832",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590052489201864704",
   "Positive": "0.8823704719543457",
   "Negative": "0.00034683721605688334",
   "Neutral": "0.11529047042131424",
   "Mixed": "0.001992188859730959"
 },
 {
   "tweet_id": "1590052469832581120",
   "Positive": "0.020473631098866463",
   "Negative": "0.3813930153846741",
   "Neutral": "0.24325454235076904",
   "Mixed": "0.35487884283065796"
 },
 {
   "tweet_id": "1590052463473995776",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052452245860352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590052445790834689",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052433639661568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052430489980929",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590052418624294912",
   "Positive": "0.5837457180023193",
   "Negative": "0.00019865710055455565",
   "Neutral": "0.41492390632629395",
   "Mixed": "0.0011316739255562425"
 },
 {
   "tweet_id": "1590052413352079361",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590052413108793344",
   "Positive": "0.5085994601249695",
   "Negative": "0.029263941571116447",
   "Neutral": "0.46020129323005676",
   "Mixed": "0.0019352901726961136"
 },
 {
   "tweet_id": "1590052412798423040",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590052410143408128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052383027261440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590052377025216512",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590052353125847045",
   "Positive": "0.4972688555717468",
   "Negative": "0.00037263325066305697",
   "Neutral": "0.4974744915962219",
   "Mixed": "0.004883975721895695"
 },
 {
   "tweet_id": "1590052323497496577",
   "Positive": "0.002230514772236347",
   "Negative": "0.4404520094394684",
   "Neutral": "0.5572850108146667",
   "Mixed": "3.256494164816104e-05"
 },
 {
   "tweet_id": "1590052318145175552",
   "Positive": "0.018623042851686478",
   "Negative": "0.0001377068692818284",
   "Neutral": "0.9812321066856384",
   "Mixed": "7.125354841264198e-06"
 },
 {
   "tweet_id": "1590052298973016064",
   "Positive": "0.03461523726582527",
   "Negative": "0.6194840669631958",
   "Neutral": "0.047550447285175323",
   "Mixed": "0.2983502149581909"
 },
 {
   "tweet_id": "1590052281869037568",
   "Positive": "0.8826870918273926",
   "Negative": "0.0007483409135602415",
   "Neutral": "0.11642947793006897",
   "Mixed": "0.00013512562145479023"
 },
 {
   "tweet_id": "1590052275447336960",
   "Positive": "0.4972688555717468",
   "Negative": "0.00037263325066305697",
   "Neutral": "0.4974744915962219",
   "Mixed": "0.004883975721895695"
 },
 {
   "tweet_id": "1590052265888337920",
   "Positive": "0.0053856573067605495",
   "Negative": "0.822504460811615",
   "Neutral": "0.1371820569038391",
   "Mixed": "0.034927744418382645"
 },
 {
   "tweet_id": "1590052264701403136",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1590052253091909632",
   "Positive": "0.034079574048519135",
   "Negative": "0.1920257955789566",
   "Neutral": "0.36180955171585083",
   "Mixed": "0.4120851457118988"
 },
 {
   "tweet_id": "1590052252181356544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590052237333913600",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590052228370694145",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590052219185164291",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052200247480320",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590052185072668673",
   "Positive": "0.4972688555717468",
   "Negative": "0.00037263325066305697",
   "Neutral": "0.4974744915962219",
   "Mixed": "0.004883975721895695"
 },
 {
   "tweet_id": "1590052181457399809",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590052178223583233",
   "Positive": "0.2552761137485504",
   "Negative": "0.001927104196511209",
   "Neutral": "0.7427530884742737",
   "Mixed": "4.3705938878702e-05"
 },
 {
   "tweet_id": "1590052178190041088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590052172019863552",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052161152778240",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052157176557569",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052137710817281",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590052132895719424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590052128479117312",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590052114247856128",
   "Positive": "0.9683788418769836",
   "Negative": "0.00014577206457033753",
   "Neutral": "0.031413428485393524",
   "Mixed": "6.186746031744406e-05"
 },
 {
   "tweet_id": "1590052113824223233",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590052102390566914",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052100423450625",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590052062687297536",
   "Positive": "0.9814728498458862",
   "Negative": "9.652985318098217e-05",
   "Neutral": "0.018409036099910736",
   "Mixed": "2.1577290681307204e-05"
 },
 {
   "tweet_id": "1590052032937066496",
   "Positive": "0.0033913974184542894",
   "Negative": "0.0012166397646069527",
   "Neutral": "0.9953851103782654",
   "Mixed": "6.827236120443558e-06"
 },
 {
   "tweet_id": "1590052022786887680",
   "Positive": "0.17717337608337402",
   "Negative": "0.0007586159044876695",
   "Neutral": "0.8220057487487793",
   "Mixed": "6.224880780791864e-05"
 },
 {
   "tweet_id": "1590052007187861504",
   "Positive": "0.05062306299805641",
   "Negative": "0.5185099244117737",
   "Neutral": "0.4058106541633606",
   "Mixed": "0.02505641058087349"
 },
 {
   "tweet_id": "1590052004348379136",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590051981456183296",
   "Positive": "0.0023052108008414507",
   "Negative": "0.00011107089812867343",
   "Neutral": "0.9975797533988953",
   "Mixed": "4.023313977086218e-06"
 },
 {
   "tweet_id": "1590051973365403648",
   "Positive": "0.02101990021765232",
   "Negative": "0.051494576036930084",
   "Neutral": "0.6152875423431396",
   "Mixed": "0.3121979236602783"
 },
 {
   "tweet_id": "1590051963827195905",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590051934425460737",
   "Positive": "0.13285723328590393",
   "Negative": "0.7365005016326904",
   "Neutral": "0.1280355304479599",
   "Mixed": "0.0026067306753247976"
 },
 {
   "tweet_id": "1590051927865593857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590051919560863744",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590051918633930752",
   "Positive": "0.38451212644577026",
   "Negative": "0.0007584925624541938",
   "Neutral": "0.6146788001060486",
   "Mixed": "5.052242340752855e-05"
 },
 {
   "tweet_id": "1590051898266382337",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590051896274063361",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590051883372412928",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590051860744142848",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590051827680448512",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590051824744419330",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590051803831631872",
   "Positive": "0.0011717673623934388",
   "Negative": "0.3548295795917511",
   "Neutral": "0.6439247131347656",
   "Mixed": "7.399633614113554e-05"
 },
 {
   "tweet_id": "1590051797263343616",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590051794604167168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590051761800478721",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590051748281884672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590051691218759680",
   "Positive": "0.02304217591881752",
   "Negative": "0.00016456122102681547",
   "Neutral": "0.9767855405807495",
   "Mixed": "7.652452950424049e-06"
 },
 {
   "tweet_id": "1590051673833365506",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590051666773958656",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590051636369506304",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590051632213266432",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590051605998886912",
   "Positive": "0.0002352110604988411",
   "Negative": "0.033492524176836014",
   "Neutral": "0.9662567377090454",
   "Mixed": "1.552788307890296e-05"
 },
 {
   "tweet_id": "1590051584196874241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590051577955745792",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590051509496348672",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590051496418168832",
   "Positive": "0.30586156249046326",
   "Negative": "0.012335646897554398",
   "Neutral": "0.6795482039451599",
   "Mixed": "0.002254647435620427"
 },
 {
   "tweet_id": "1590051495776768001",
   "Positive": "0.924103856086731",
   "Negative": "0.0007836060831323266",
   "Neutral": "0.0750453844666481",
   "Mixed": "6.725979619659483e-05"
 },
 {
   "tweet_id": "1590051476747194370",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590051456216092672",
   "Positive": "0.0032328309025615454",
   "Negative": "0.7820300459861755",
   "Neutral": "0.19627398252487183",
   "Mixed": "0.0184631384909153"
 },
 {
   "tweet_id": "1590051451510067200",
   "Positive": "0.10902852565050125",
   "Negative": "0.00013768707867711782",
   "Neutral": "0.8901505470275879",
   "Mixed": "0.0006831405335105956"
 },
 {
   "tweet_id": "1590051451320954881",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590051450440552448",
   "Positive": "0.44690442085266113",
   "Negative": "0.0009065056219696999",
   "Neutral": "0.5521694421768188",
   "Mixed": "1.9647615772555582e-05"
 },
 {
   "tweet_id": "1590051440923332608",
   "Positive": "0.12885400652885437",
   "Negative": "0.0005936165107414126",
   "Neutral": "0.8703209757804871",
   "Mixed": "0.00023140219855122268"
 },
 {
   "tweet_id": "1590051426616496128",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590051395784564736",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590051394748547074",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590051389006565376",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590051367992774656",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590051352163790848",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590051311134724097",
   "Positive": "0.0005320767522789538",
   "Negative": "0.00013492639118339866",
   "Neutral": "0.9993267059326172",
   "Mixed": "6.318486612144625e-06"
 },
 {
   "tweet_id": "1590051290729811968",
   "Positive": "0.0024214384611696005",
   "Negative": "0.6307075023651123",
   "Neutral": "0.36663228273391724",
   "Mixed": "0.00023872440215200186"
 },
 {
   "tweet_id": "1590051276959625216",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590051274917306369",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590051258324643840",
   "Positive": "0.2254573553800583",
   "Negative": "0.01566763035953045",
   "Neutral": "0.7587094306945801",
   "Mixed": "0.000165646881214343"
 },
 {
   "tweet_id": "1590051251131396096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590051242084274176",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590051210283069441",
   "Positive": "0.04510363191366196",
   "Negative": "0.1946636587381363",
   "Neutral": "0.19620788097381592",
   "Mixed": "0.564024806022644"
 },
 {
   "tweet_id": "1590051172240351232",
   "Positive": "0.001879898482002318",
   "Negative": "0.00023624900495633483",
   "Neutral": "0.997880220413208",
   "Mixed": "3.7453260119946208e-06"
 },
 {
   "tweet_id": "1590051157615214594",
   "Positive": "0.002433330984786153",
   "Negative": "3.932760955649428e-05",
   "Neutral": "0.9974954724311829",
   "Mixed": "3.181328429491259e-05"
 },
 {
   "tweet_id": "1590051141735571457",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590051107480678402",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590051099842875392",
   "Positive": "0.7454767823219299",
   "Negative": "0.00040737006929703057",
   "Neutral": "0.2540540397167206",
   "Mixed": "6.17756086285226e-05"
 },
 {
   "tweet_id": "1590051099326586881",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590051097653411841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590051055081254912",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590051042254651392",
   "Positive": "0.005663628689944744",
   "Negative": "0.00014064823335502297",
   "Neutral": "0.9941907525062561",
   "Mixed": "4.938269739795942e-06"
 },
 {
   "tweet_id": "1590051025754652672",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1590050985099268097",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050980074524673",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050978275164160",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590050966384312320",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590050948784984064",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590050936944496640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050923174563841",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050898394640384",
   "Positive": "0.41022929549217224",
   "Negative": "0.020389864221215248",
   "Neutral": "0.5691186189651489",
   "Mixed": "0.00026224442990496755"
 },
 {
   "tweet_id": "1590050896557543424",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590050895370522625",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050888361836544",
   "Positive": "0.005758732091635466",
   "Negative": "0.4049850404262543",
   "Neutral": "0.24254834651947021",
   "Mixed": "0.34670791029930115"
 },
 {
   "tweet_id": "1590050876953333760",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590050870300798977",
   "Positive": "0.13809362053871155",
   "Negative": "0.05361353978514671",
   "Neutral": "0.7402892112731934",
   "Mixed": "0.06800364702939987"
 },
 {
   "tweet_id": "1590050864907321344",
   "Positive": "0.26034674048423767",
   "Negative": "0.20144058763980865",
   "Neutral": "0.5223490595817566",
   "Mixed": "0.015863584354519844"
 },
 {
   "tweet_id": "1590050858037018624",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590050857441439746",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050850093039616",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590050837430439936",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1590050829544828931",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590050828030574592",
   "Positive": "0.019186023622751236",
   "Negative": "0.43054214119911194",
   "Neutral": "0.5484243631362915",
   "Mixed": "0.0018475073156878352"
 },
 {
   "tweet_id": "1590050825631825922",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590050820015665152",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590050817868173313",
   "Positive": "0.13809362053871155",
   "Negative": "0.05361353978514671",
   "Neutral": "0.7402892112731934",
   "Mixed": "0.06800364702939987"
 },
 {
   "tweet_id": "1590050813623537665",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050795038605312",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590050784116625408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050781696139265",
   "Positive": "0.8129183650016785",
   "Negative": "0.03179062157869339",
   "Neutral": "0.13173221051692963",
   "Mixed": "0.023558834567666054"
 },
 {
   "tweet_id": "1590050780975095810",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1590050739086184448",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050731419398144",
   "Positive": "0.13809362053871155",
   "Negative": "0.05361353978514671",
   "Neutral": "0.7402892112731934",
   "Mixed": "0.06800364702939987"
 },
 {
   "tweet_id": "1590050726960861185",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050721571143680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050717456543745",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050707444752384",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590050693997817856",
   "Positive": "0.3060080111026764",
   "Negative": "0.024614796042442322",
   "Neutral": "0.6027112603187561",
   "Mixed": "0.06666596978902817"
 },
 {
   "tweet_id": "1590050690784976897",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050680450215936",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050677426122754",
   "Positive": "0.29690247774124146",
   "Negative": "0.00023390464775729924",
   "Neutral": "0.7027835249900818",
   "Mixed": "8.017011714400724e-05"
 },
 {
   "tweet_id": "1590050637701865472",
   "Positive": "0.03147166594862938",
   "Negative": "0.6826679706573486",
   "Neutral": "0.2829950451850891",
   "Mixed": "0.0028652760665863752"
 },
 {
   "tweet_id": "1590050626700214273",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050619871891457",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050584337731584",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050549038452736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050539223814145",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590050526439575553",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1590050522559844352",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050520785293312",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590050519686471681",
   "Positive": "0.009953399188816547",
   "Negative": "5.201188105274923e-05",
   "Neutral": "0.9899789094924927",
   "Mixed": "1.5708108549006283e-05"
 },
 {
   "tweet_id": "1590050513076506624",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590050498438393856",
   "Positive": "0.6454356908798218",
   "Negative": "0.0031056683510541916",
   "Neutral": "0.3514089286327362",
   "Mixed": "4.969990914105438e-05"
 },
 {
   "tweet_id": "1590050494739025920",
   "Positive": "0.0014768028631806374",
   "Negative": "7.926209218567237e-05",
   "Neutral": "0.9984366297721863",
   "Mixed": "7.221263786050258e-06"
 },
 {
   "tweet_id": "1590050477127131137",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050474480533504",
   "Positive": "0.2039036899805069",
   "Negative": "0.003353714244440198",
   "Neutral": "0.7927260994911194",
   "Mixed": "1.648088982619811e-05"
 },
 {
   "tweet_id": "1590050461352361984",
   "Positive": "0.0003312215267214924",
   "Negative": "0.011788765899837017",
   "Neutral": "0.9878688454627991",
   "Mixed": "1.1182532944076229e-05"
 },
 {
   "tweet_id": "1590050444017274881",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590050441873993728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050438258511872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050437256085506",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590050428053606402",
   "Positive": "0.07379762083292007",
   "Negative": "0.03154093772172928",
   "Neutral": "0.8945049047470093",
   "Mixed": "0.00015657571202609688"
 },
 {
   "tweet_id": "1590050425344253953",
   "Positive": "0.46578073501586914",
   "Negative": "0.000958202697802335",
   "Neutral": "0.49261483550071716",
   "Mixed": "0.04064631462097168"
 },
 {
   "tweet_id": "1590050416850444288",
   "Positive": "0.0004637431411538273",
   "Negative": "0.9898084998130798",
   "Neutral": "0.009530394338071346",
   "Mixed": "0.0001974466722458601"
 },
 {
   "tweet_id": "1590050412505092096",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590050409724641281",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050396101545984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050377885368321",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050374135816194",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050367815159808",
   "Positive": "0.006522420793771744",
   "Negative": "0.05954824015498161",
   "Neutral": "0.9334783554077148",
   "Mixed": "0.00045096964458934963"
 },
 {
   "tweet_id": "1590050352325603328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050349246984192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050349175697408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590050326501294080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050316850196482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050314421690368",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590050297799266306",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050282125561856",
   "Positive": "0.0074355099350214005",
   "Negative": "0.35196807980537415",
   "Neutral": "0.6405230760574341",
   "Mixed": "7.331596134463325e-05"
 },
 {
   "tweet_id": "1590050274403840000",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590050273762119681",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590050271207784448",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590050270477979648",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590050266652770304",
   "Positive": "0.0015989858657121658",
   "Negative": "0.40948233008384705",
   "Neutral": "0.5888630151748657",
   "Mixed": "5.568844062509015e-05"
 },
 {
   "tweet_id": "1590050266170425346",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590050262965968896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050183395840000",
   "Positive": "0.007033151108771563",
   "Negative": "0.5613598823547363",
   "Neutral": "0.4307499825954437",
   "Mixed": "0.0008570199133828282"
 },
 {
   "tweet_id": "1590050175372099584",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590050137900220416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050135181926400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050128630382592",
   "Positive": "0.36583444476127625",
   "Negative": "0.047565754503011703",
   "Neutral": "0.586394727230072",
   "Mixed": "0.00020500500977504998"
 },
 {
   "tweet_id": "1590050119566884864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050105734070272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590050087392382977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050077862940674",
   "Positive": "0.0030403740238398314",
   "Negative": "0.012955416925251484",
   "Neutral": "0.9839901924133301",
   "Mixed": "1.4006253877596464e-05"
 },
 {
   "tweet_id": "1590050067402326019",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590050062734102529",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590050060552720385",
   "Positive": "0.8152037262916565",
   "Negative": "0.0002270437980769202",
   "Neutral": "0.18444977700710297",
   "Mixed": "0.0001194085125462152"
 },
 {
   "tweet_id": "1590050058116136960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590050057700925440",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590050029305487360",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590050017636937728",
   "Positive": "0.01925196684896946",
   "Negative": "0.14062702655792236",
   "Neutral": "0.8400996327400208",
   "Mixed": "2.1436670067487285e-05"
 },
 {
   "tweet_id": "1590050009956855808",
   "Positive": "0.34394463896751404",
   "Negative": "0.00061145139625296",
   "Neutral": "0.6553928852081299",
   "Mixed": "5.11211073899176e-05"
 },
 {
   "tweet_id": "1590050006404599808",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590050003648593920",
   "Positive": "0.19535315036773682",
   "Negative": "0.002257759217172861",
   "Neutral": "0.2008720487356186",
   "Mixed": "0.6015170216560364"
 },
 {
   "tweet_id": "1590049992462450696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049979460358144",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049968580349953",
   "Positive": "0.0538569875061512",
   "Negative": "0.00399582227692008",
   "Neutral": "0.9421345591545105",
   "Mixed": "1.2612304999493062e-05"
 },
 {
   "tweet_id": "1590049952797196288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049943490027520",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1590049938763059201",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590049921670868992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049893657509888",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590049892772503552",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590049880156012544",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1590049877366800384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049874417836032",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590049822090080256",
   "Positive": "0.30973467230796814",
   "Negative": "0.0005068156751804054",
   "Neutral": "0.6891437768936157",
   "Mixed": "0.0006147539243102074"
 },
 {
   "tweet_id": "1590049809452666882",
   "Positive": "0.0019376110285520554",
   "Negative": "8.064084249781445e-05",
   "Neutral": "0.997975766658783",
   "Mixed": "5.896398306504125e-06"
 },
 {
   "tweet_id": "1590049794990702592",
   "Positive": "0.010807737708091736",
   "Negative": "0.3639540672302246",
   "Neutral": "0.6239185333251953",
   "Mixed": "0.0013197545194998384"
 },
 {
   "tweet_id": "1590049789101584384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049768440745984",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049755908173825",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049750727811072",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590049731102662656",
   "Positive": "0.001374610117636621",
   "Negative": "0.0001303593162447214",
   "Neutral": "0.9984906911849976",
   "Mixed": "4.317650109442184e-06"
 },
 {
   "tweet_id": "1590049726207909891",
   "Positive": "0.9626981616020203",
   "Negative": "0.0034271366894245148",
   "Neutral": "0.033655110746622086",
   "Mixed": "0.00021959510922897607"
 },
 {
   "tweet_id": "1590049723603619840",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590049713646370816",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590049687263858688",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1590049664568823808",
   "Positive": "0.007045865058898926",
   "Negative": "0.07262399792671204",
   "Neutral": "0.04826202616095543",
   "Mixed": "0.8720681071281433"
 },
 {
   "tweet_id": "1590049664426205185",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590049664157765633",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049653873315840",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590049650543071232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049649515458560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049639876919297",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590049634692771840",
   "Positive": "0.004506099969148636",
   "Negative": "0.24227307736873627",
   "Neutral": "0.7532051205635071",
   "Mixed": "1.568469451740384e-05"
 },
 {
   "tweet_id": "1590049632536907776",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590049614002257920",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049610617311234",
   "Positive": "0.9467281699180603",
   "Negative": "0.00012697541387751698",
   "Neutral": "0.05312102288007736",
   "Mixed": "2.3805041564628482e-05"
 },
 {
   "tweet_id": "1590049610453569536",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590049552731582466",
   "Positive": "0.16239245235919952",
   "Negative": "0.0002725430531427264",
   "Neutral": "0.8372431397438049",
   "Mixed": "9.185937233269215e-05"
 },
 {
   "tweet_id": "1590049536910962688",
   "Positive": "0.0787491425871849",
   "Negative": "0.0004121198726352304",
   "Neutral": "0.9202972650527954",
   "Mixed": "0.0005414654151536524"
 },
 {
   "tweet_id": "1590049532750221313",
   "Positive": "0.0007223355351015925",
   "Negative": "0.00028190240846015513",
   "Neutral": "0.9989834427833557",
   "Mixed": "1.2312590115470812e-05"
 },
 {
   "tweet_id": "1590049527545098241",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049521106812928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049518963560450",
   "Positive": "0.10588407516479492",
   "Negative": "0.035762663930654526",
   "Neutral": "0.8359053134918213",
   "Mixed": "0.022447967901825905"
 },
 {
   "tweet_id": "1590049494024192011",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590049462898274305",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049459949694977",
   "Positive": "0.00061223836382851",
   "Negative": "0.0017419265350326896",
   "Neutral": "0.9976358413696289",
   "Mixed": "9.984785720007494e-06"
 },
 {
   "tweet_id": "1590049439447945217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049418887454720",
   "Positive": "0.240102156996727",
   "Negative": "0.0005478220409713686",
   "Neutral": "0.7592856287956238",
   "Mixed": "6.442516314564273e-05"
 },
 {
   "tweet_id": "1590049405998333952",
   "Positive": "0.001251211273483932",
   "Negative": "0.0012908772332593799",
   "Neutral": "0.9974556565284729",
   "Mixed": "2.311705429747235e-06"
 },
 {
   "tweet_id": "1590049404014428160",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590049401497866241",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590049371361783808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049370086735873",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590049359395160065",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590049333621030912",
   "Positive": "0.2363767921924591",
   "Negative": "0.0007289939094334841",
   "Neutral": "0.7627688050270081",
   "Mixed": "0.0001253462105523795"
 },
 {
   "tweet_id": "1590049332761624576",
   "Positive": "0.05836637318134308",
   "Negative": "0.20430496335029602",
   "Neutral": "0.37504327297210693",
   "Mixed": "0.36228540539741516"
 },
 {
   "tweet_id": "1590049328550146049",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590049318899437569",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049307188944896",
   "Positive": "0.00017160970310214907",
   "Negative": "0.0003241289232391864",
   "Neutral": "0.9995006322860718",
   "Mixed": "3.6222386370354798e-06"
 },
 {
   "tweet_id": "1590049291313504257",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590049244383436800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049239169912832",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049234988183552",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590049202822074368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049199722110979",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049190859927552",
   "Positive": "0.15177284181118011",
   "Negative": "0.0014958997489884496",
   "Neutral": "0.8467159271240234",
   "Mixed": "1.5380846889456734e-05"
 },
 {
   "tweet_id": "1590049184694308864",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049182815227904",
   "Positive": "0.0035936825443059206",
   "Negative": "0.0001187948219012469",
   "Neutral": "0.9962818026542664",
   "Mixed": "5.760108251706697e-06"
 },
 {
   "tweet_id": "1590049164964290560",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590049159440379904",
   "Positive": "0.0015640341443940997",
   "Negative": "0.9606956243515015",
   "Neutral": "0.03767956420779228",
   "Mixed": "6.072728865547106e-05"
 },
 {
   "tweet_id": "1590049157016072192",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590049142100807680",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590049131405639681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049127639179265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590049112673558528",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590049091454730241",
   "Positive": "0.15762007236480713",
   "Negative": "0.0005667861551046371",
   "Neutral": "0.8417883515357971",
   "Mixed": "2.4811059120111167e-05"
 },
 {
   "tweet_id": "1590049084840509442",
   "Positive": "0.9752721190452576",
   "Negative": "0.00011668281513266265",
   "Neutral": "0.024411024525761604",
   "Mixed": "0.00020011854940094054"
 },
 {
   "tweet_id": "1590049076845760513",
   "Positive": "0.001004877150990069",
   "Negative": "0.09210072457790375",
   "Neutral": "0.9068353772163391",
   "Mixed": "5.906801379751414e-05"
 },
 {
   "tweet_id": "1590049074723848192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049073574576129",
   "Positive": "0.3087170720100403",
   "Negative": "0.010668555274605751",
   "Neutral": "0.6676046252250671",
   "Mixed": "0.013009686954319477"
 },
 {
   "tweet_id": "1590049044466139136",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590049037885267969",
   "Positive": "0.005545726977288723",
   "Negative": "0.007215512916445732",
   "Neutral": "0.9872223734855652",
   "Mixed": "1.637635432416573e-05"
 },
 {
   "tweet_id": "1590049033053429761",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590049031723814913",
   "Positive": "0.05371746048331261",
   "Negative": "0.0021049766801297665",
   "Neutral": "0.9441609978675842",
   "Mixed": "1.658630935708061e-05"
 },
 {
   "tweet_id": "1590049015789649920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049012165615617",
   "Positive": "0.004582882858812809",
   "Negative": "5.111044447403401e-05",
   "Neutral": "0.9953563809394836",
   "Mixed": "9.668963684816845e-06"
 },
 {
   "tweet_id": "1590049010756292608",
   "Positive": "0.08341436833143234",
   "Negative": "0.10950438678264618",
   "Neutral": "0.5993200540542603",
   "Mixed": "0.20776116847991943"
 },
 {
   "tweet_id": "1590049010215419904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590049009976020992",
   "Positive": "8.15344974398613e-05",
   "Negative": "0.0163788590580225",
   "Neutral": "0.9835220575332642",
   "Mixed": "1.7599873899598606e-05"
 },
 {
   "tweet_id": "1590049003684892672",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590048981627068416",
   "Positive": "0.5439950227737427",
   "Negative": "0.0028146894183009863",
   "Neutral": "0.45313867926597595",
   "Mixed": "5.1550305215641856e-05"
 },
 {
   "tweet_id": "1590048973158776832",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590048961855127552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048950002028545",
   "Positive": "0.027324629947543144",
   "Negative": "0.007195748388767242",
   "Neutral": "0.9654648900032043",
   "Mixed": "1.4768933397135697e-05"
 },
 {
   "tweet_id": "1590048924475461632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048922629976065",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048902287196161",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048887850807296",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048852777705473",
   "Positive": "0.002166729187592864",
   "Negative": "0.000342504441505298",
   "Neutral": "0.9974875450134277",
   "Mixed": "3.193162001480232e-06"
 },
 {
   "tweet_id": "1590048823757664257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048798315008001",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048797442203648",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590048796477906944",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048775867076609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048750592208896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048715448152067",
   "Positive": "0.00495251314714551",
   "Negative": "0.17235057055950165",
   "Neutral": "0.8205606341362",
   "Mixed": "0.0021362388506531715"
 },
 {
   "tweet_id": "1590048697056133121",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590048683735003136",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590048676202045440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048676113973248",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590048656534953984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048653175316482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048652936228864",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048635030765568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048617838305281",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590048613132300288",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590048611315765248",
   "Positive": "0.46679335832595825",
   "Negative": "0.11728322505950928",
   "Neutral": "0.40802791714668274",
   "Mixed": "0.007895447313785553"
 },
 {
   "tweet_id": "1590048596191510528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048595935256576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048592924151808",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590048583705067520",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048581859565568",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590048579267473409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048562611908608",
   "Positive": "0.005128390621393919",
   "Negative": "0.003548727836459875",
   "Neutral": "0.9913143515586853",
   "Mixed": "8.510977750120219e-06"
 },
 {
   "tweet_id": "1590048544286986241",
   "Positive": "0.14803330600261688",
   "Negative": "0.008154146373271942",
   "Neutral": "0.8355720043182373",
   "Mixed": "0.008240600116550922"
 },
 {
   "tweet_id": "1590048537819373569",
   "Positive": "0.3784092366695404",
   "Negative": "0.00034090931876562536",
   "Neutral": "0.6211494207382202",
   "Mixed": "0.00010043650399893522"
 },
 {
   "tweet_id": "1590048533541158912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048531469193217",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590048513693724672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048482425200641",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048472342093824",
   "Positive": "0.0030481424182653427",
   "Negative": "0.00246448814868927",
   "Neutral": "0.994481086730957",
   "Mixed": "6.281028618104756e-06"
 },
 {
   "tweet_id": "1590048467397021697",
   "Positive": "0.4104417860507965",
   "Negative": "0.0016989371506497264",
   "Neutral": "0.5878348350524902",
   "Mixed": "2.435272472212091e-05"
 },
 {
   "tweet_id": "1590048459654299648",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590048459402670080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048458261819392",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590048449344327680",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048432512995328",
   "Positive": "0.4401146471500397",
   "Negative": "0.002820371650159359",
   "Neutral": "0.5567917823791504",
   "Mixed": "0.0002731669519562274"
 },
 {
   "tweet_id": "1590048424829014016",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048423176458240",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048413768642561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048387264843776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590048378276020226",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048367203454983",
   "Positive": "9.552718256600201e-05",
   "Negative": "0.00024245886015705764",
   "Neutral": "0.9996528625488281",
   "Mixed": "9.150222467724234e-06"
 },
 {
   "tweet_id": "1590048351986204673",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048349390241792",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590048309468880896",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048279366356993",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048253655257088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048244465537024",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590048227080163328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048209942245376",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590048192695275522",
   "Positive": "0.03266004100441933",
   "Negative": "0.000530614925082773",
   "Neutral": "0.9667843580245972",
   "Mixed": "2.4998193111969158e-05"
 },
 {
   "tweet_id": "1590048190488715271",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048189264302081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048178048757760",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590048177109217281",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590048170268315648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048169454632960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048155856347136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048153591349249",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590048116874842113",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590048108620107776",
   "Positive": "0.9668715000152588",
   "Negative": "0.00023811373102944344",
   "Neutral": "0.03263997659087181",
   "Mixed": "0.000250414275797084"
 },
 {
   "tweet_id": "1590048101024542720",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590048090220032001",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590048084658380801",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590048083320053760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048076382687232",
   "Positive": "0.0027306429110467434",
   "Negative": "0.00011228299263166264",
   "Neutral": "0.9971488118171692",
   "Mixed": "8.374453500437085e-06"
 },
 {
   "tweet_id": "1590048035836661761",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590048033378816000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590048020749783041",
   "Positive": "0.6850396394729614",
   "Negative": "0.004987309221178293",
   "Neutral": "0.3099379241466522",
   "Mixed": "3.5066397686023265e-05"
 },
 {
   "tweet_id": "1590048013908836352",
   "Positive": "0.0007323392783291638",
   "Negative": "5.993386366753839e-05",
   "Neutral": "0.9991870522499084",
   "Mixed": "2.078301076835487e-05"
 },
 {
   "tweet_id": "1590048011107041280",
   "Positive": "0.09915805608034134",
   "Negative": "0.00024472986115142703",
   "Neutral": "0.9005754590034485",
   "Mixed": "2.1831076082889922e-05"
 },
 {
   "tweet_id": "1590047997726887936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047994778615809",
   "Positive": "0.07604559510946274",
   "Negative": "0.0031239863019436598",
   "Neutral": "0.9207783937454224",
   "Mixed": "5.20149442309048e-05"
 },
 {
   "tweet_id": "1590047954089676801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047945944367104",
   "Positive": "0.00045124845928512514",
   "Negative": "0.9902074933052063",
   "Neutral": "0.008946875110268593",
   "Mixed": "0.00039439203101210296"
 },
 {
   "tweet_id": "1590047944635723777",
   "Positive": "0.010522193275392056",
   "Negative": "0.00043635282781906426",
   "Neutral": "0.9890367388725281",
   "Mixed": "4.673890089179622e-06"
 },
 {
   "tweet_id": "1590047912356347904",
   "Positive": "0.6842969655990601",
   "Negative": "0.0007973251631483436",
   "Neutral": "0.31486082077026367",
   "Mixed": "4.496879409998655e-05"
 },
 {
   "tweet_id": "1590047864478371846",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590047858052718592",
   "Positive": "0.3856450021266937",
   "Negative": "0.00017856407794170082",
   "Neutral": "0.6141051054000854",
   "Mixed": "7.138391811167821e-05"
 },
 {
   "tweet_id": "1590047844701913088",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590047820077092865",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047805049274369",
   "Positive": "0.0030698778573423624",
   "Negative": "0.9144772887229919",
   "Neutral": "0.08179821074008942",
   "Mixed": "0.0006546693039126694"
 },
 {
   "tweet_id": "1590047785222819840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047781112418304",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1590047776553209856",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047772073373696",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047769896529920",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590047755057393666",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047747830296576",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590047733976805377",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590047724594135040",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590047713563144193",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590047711424057344",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590047710870372353",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590047700938297345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047696844648448",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590047696806547456",
   "Positive": "0.001746744499541819",
   "Negative": "0.00010541618394199759",
   "Neutral": "0.998126208782196",
   "Mixed": "2.165415571653284e-05"
 },
 {
   "tweet_id": "1590047692545085440",
   "Positive": "0.0020914271008223295",
   "Negative": "0.00028444398776628077",
   "Neutral": "0.9976205229759216",
   "Mixed": "3.525377906044014e-06"
 },
 {
   "tweet_id": "1590047689211023360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047663952887809",
   "Positive": "0.0669952854514122",
   "Negative": "0.00039176372229121625",
   "Neutral": "0.9325847029685974",
   "Mixed": "2.819503060891293e-05"
 },
 {
   "tweet_id": "1590047631421902849",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047577952526336",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1590047575423733761",
   "Positive": "0.012975419871509075",
   "Negative": "0.0331672728061676",
   "Neutral": "0.9538017511367798",
   "Mixed": "5.560112913371995e-05"
 },
 {
   "tweet_id": "1590047563847434241",
   "Positive": "0.5439950227737427",
   "Negative": "0.0028146894183009863",
   "Neutral": "0.45313867926597595",
   "Mixed": "5.1550305215641856e-05"
 },
 {
   "tweet_id": "1590047550261776386",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047519605936128",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047499750105088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047484545748993",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047479458041856",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590047470767439872",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047466744750080",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047462898958336",
   "Positive": "0.0009038396528922021",
   "Negative": "6.717121868859977e-05",
   "Neutral": "0.9990154504776001",
   "Mixed": "1.361396789434366e-05"
 },
 {
   "tweet_id": "1590047454854250496",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590047427171864577",
   "Positive": "0.011361067183315754",
   "Negative": "0.00021281461522448808",
   "Neutral": "0.988410472869873",
   "Mixed": "1.5707351849414408e-05"
 },
 {
   "tweet_id": "1590047418854227968",
   "Positive": "0.0008780201897025108",
   "Negative": "0.00021969102090224624",
   "Neutral": "0.9988999366760254",
   "Mixed": "2.331248197151581e-06"
 },
 {
   "tweet_id": "1590047417369780224",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590047410914758656",
   "Positive": "0.42859360575675964",
   "Negative": "0.0013378182193264365",
   "Neutral": "0.5700487494468689",
   "Mixed": "1.983541005756706e-05"
 },
 {
   "tweet_id": "1590047384628645888",
   "Positive": "0.0014211836969479918",
   "Negative": "0.00019438074377831072",
   "Neutral": "0.9983817338943481",
   "Mixed": "2.7702476472768467e-06"
 },
 {
   "tweet_id": "1590047364211150849",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590047360029425664",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590047356493647872",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1590047354304233472",
   "Positive": "0.006452326662838459",
   "Negative": "0.05077289044857025",
   "Neutral": "0.017750844359397888",
   "Mixed": "0.9250239133834839"
 },
 {
   "tweet_id": "1590047344212705280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047340018094086",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047327083180033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047316592902144",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047312411525120",
   "Positive": "0.02625967748463154",
   "Negative": "0.0643097460269928",
   "Neutral": "0.9093997478485107",
   "Mixed": "3.077951623708941e-05"
 },
 {
   "tweet_id": "1590047299161690112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047282606804994",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590047259751682049",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047257277370368",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590047229485912064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590047215229480960",
   "Positive": "0.024902576580643654",
   "Negative": "0.14961837232112885",
   "Neutral": "0.10323675721883774",
   "Mixed": "0.7222422957420349"
 },
 {
   "tweet_id": "1590047214772289537",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047202134544384",
   "Positive": "0.3021780848503113",
   "Negative": "0.0013201335677877069",
   "Neutral": "0.6961878538131714",
   "Mixed": "0.0003139201726298779"
 },
 {
   "tweet_id": "1590047197629775872",
   "Positive": "0.011711986735463142",
   "Negative": "0.12108661234378815",
   "Neutral": "0.06729356944561005",
   "Mixed": "0.7999077439308167"
 },
 {
   "tweet_id": "1590047168383307776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047163408875520",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590047158941929473",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047156995756032",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590047150121299968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047149886410754",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047148124405760",
   "Positive": "0.2568751573562622",
   "Negative": "0.038573071360588074",
   "Neutral": "0.7038200497627258",
   "Mixed": "0.0007316926494240761"
 },
 {
   "tweet_id": "1590047145943781376",
   "Positive": "0.005232862196862698",
   "Negative": "0.4579372704029083",
   "Neutral": "0.5366387367248535",
   "Mixed": "0.00019114898168481886"
 },
 {
   "tweet_id": "1590047133692219392",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590047132366835712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047126373167104",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590047109394599936",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590047108953886720",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590047094337073152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047083264094208",
   "Positive": "0.0003009163774549961",
   "Negative": "0.9731554985046387",
   "Neutral": "0.026508495211601257",
   "Mixed": "3.512713374220766e-05"
 },
 {
   "tweet_id": "1590047082219732993",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590047054092718080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047053027364865",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590047049583497219",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590047037005139969",
   "Positive": "0.14552997052669525",
   "Negative": "0.0003764911671169102",
   "Neutral": "0.8539413213729858",
   "Mixed": "0.00015221232024487108"
 },
 {
   "tweet_id": "1590047031003086850",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590047016503377920",
   "Positive": "0.26158416271209717",
   "Negative": "0.0046845488250255585",
   "Neutral": "0.7334733009338379",
   "Mixed": "0.0002579319116193801"
 },
 {
   "tweet_id": "1590047006139256832",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590046998811533313",
   "Positive": "0.3562825322151184",
   "Negative": "0.001348339021205902",
   "Neutral": "0.6422898769378662",
   "Mixed": "7.916477625258267e-05"
 },
 {
   "tweet_id": "1590046998560149504",
   "Positive": "0.0018245725659653544",
   "Negative": "8.609921496827155e-05",
   "Neutral": "0.9980809688568115",
   "Mixed": "8.30625413072994e-06"
 },
 {
   "tweet_id": "1590046998337835009",
   "Positive": "0.03415139019489288",
   "Negative": "0.0004852171696256846",
   "Neutral": "0.9653520584106445",
   "Mixed": "1.1420902410463896e-05"
 },
 {
   "tweet_id": "1590046998220021760",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046993053020162",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046988661231616",
   "Positive": "0.010813508182764053",
   "Negative": "0.6342630386352539",
   "Neutral": "0.3547915518283844",
   "Mixed": "0.00013193016638979316"
 },
 {
   "tweet_id": "1590046959213379584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046956453494785",
   "Positive": "0.0028542063664644957",
   "Negative": "0.0010547077981755137",
   "Neutral": "0.9960854053497314",
   "Mixed": "5.7552720136300195e-06"
 },
 {
   "tweet_id": "1590046943124008960",
   "Positive": "0.73106849193573",
   "Negative": "0.005068250931799412",
   "Neutral": "0.26376873254776",
   "Mixed": "9.455288818571717e-05"
 },
 {
   "tweet_id": "1590046938031800321",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046927688982529",
   "Positive": "0.36475008726119995",
   "Negative": "0.14996828138828278",
   "Neutral": "0.47815173864364624",
   "Mixed": "0.007129979319870472"
 },
 {
   "tweet_id": "1590046921930207232",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046915772968960",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1590046899356446720",
   "Positive": "0.13724856078624725",
   "Negative": "0.00020969158504158258",
   "Neutral": "0.8624894618988037",
   "Mixed": "5.222309846431017e-05"
 },
 {
   "tweet_id": "1590046890959474689",
   "Positive": "0.0015127618098631501",
   "Negative": "0.0011651713866740465",
   "Neutral": "0.9973204731941223",
   "Mixed": "1.6579820112383459e-06"
 },
 {
   "tweet_id": "1590046877910978560",
   "Positive": "0.4401146471500397",
   "Negative": "0.002820371650159359",
   "Neutral": "0.5567917823791504",
   "Mixed": "0.0002731669519562274"
 },
 {
   "tweet_id": "1590046874484240384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590046861213446145",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046846180728833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046835074211840",
   "Positive": "0.0014355062739923596",
   "Negative": "0.11065997928380966",
   "Neutral": "0.8877589106559753",
   "Mixed": "0.00014562012802343816"
 },
 {
   "tweet_id": "1590046787779186688",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590046784600297472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046771496951808",
   "Positive": "0.9926512837409973",
   "Negative": "0.00015834362420719117",
   "Neutral": "0.007178997155278921",
   "Mixed": "1.1384616300347261e-05"
 },
 {
   "tweet_id": "1590046756989194241",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046745119305728",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046729596207104",
   "Positive": "0.005329634994268417",
   "Negative": "0.9768100380897522",
   "Neutral": "0.01771710254251957",
   "Mixed": "0.00014323819777928293"
 },
 {
   "tweet_id": "1590046728014569472",
   "Positive": "0.8566721081733704",
   "Negative": "0.00012518469884525985",
   "Neutral": "0.14317244291305542",
   "Mixed": "3.020768599526491e-05"
 },
 {
   "tweet_id": "1590046720473600000",
   "Positive": "0.42059653997421265",
   "Negative": "0.01104907039552927",
   "Neutral": "0.568311333656311",
   "Mixed": "4.302785964682698e-05"
 },
 {
   "tweet_id": "1590046687141433344",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590046686592000005",
   "Positive": "0.016708025708794594",
   "Negative": "0.9003936052322388",
   "Neutral": "0.06506211310625076",
   "Mixed": "0.01783624477684498"
 },
 {
   "tweet_id": "1590046685690204160",
   "Positive": "0.0006008964846841991",
   "Negative": "0.0005016528302803636",
   "Neutral": "0.9988825917243958",
   "Mixed": "1.4924644347047433e-05"
 },
 {
   "tweet_id": "1590046683278499840",
   "Positive": "0.0002220566093456",
   "Negative": "0.0025009331293404102",
   "Neutral": "0.9972708821296692",
   "Mixed": "6.03621629124973e-06"
 },
 {
   "tweet_id": "1590046672536494081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046645357793280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046643088654337",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046635090120705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590046615389474817",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046607877500928",
   "Positive": "0.03268307447433472",
   "Negative": "0.0450369231402874",
   "Neutral": "0.581468939781189",
   "Mixed": "0.3408111035823822"
 },
 {
   "tweet_id": "1590046605612572672",
   "Positive": "0.2732473611831665",
   "Negative": "0.010375709272921085",
   "Neutral": "0.7163084745407104",
   "Mixed": "6.850410863989964e-05"
 },
 {
   "tweet_id": "1590046571315748866",
   "Positive": "0.015976430848240852",
   "Negative": "0.001981345470994711",
   "Neutral": "0.9820306301116943",
   "Mixed": "1.1585319043661002e-05"
 },
 {
   "tweet_id": "1590046547278172160",
   "Positive": "0.008949805051088333",
   "Negative": "0.5320706367492676",
   "Neutral": "0.45316624641418457",
   "Mixed": "0.0058133420534431934"
 },
 {
   "tweet_id": "1590046541456502786",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590046536821792768",
   "Positive": "0.005490039009600878",
   "Negative": "0.010606490075588226",
   "Neutral": "0.9838786125183105",
   "Mixed": "2.480863440723624e-05"
 },
 {
   "tweet_id": "1590046536570109952",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046502596251649",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590046496111890432",
   "Positive": "0.04239960387349129",
   "Negative": "0.01280142366886139",
   "Neutral": "0.9447661638259888",
   "Mixed": "3.275728158769198e-05"
 },
 {
   "tweet_id": "1590046489010929666",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590046477979901952",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590046477241708544",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590046466009366528",
   "Positive": "0.3668793737888336",
   "Negative": "0.0010470504639670253",
   "Neutral": "0.631991446018219",
   "Mixed": "8.222158794524148e-05"
 },
 {
   "tweet_id": "1590046465467957248",
   "Positive": "0.4401146471500397",
   "Negative": "0.002820371650159359",
   "Neutral": "0.5567917823791504",
   "Mixed": "0.0002731669519562274"
 },
 {
   "tweet_id": "1590046457523933184",
   "Positive": "0.009813127107918262",
   "Negative": "0.9467788338661194",
   "Neutral": "0.026764897629618645",
   "Mixed": "0.016643112525343895"
 },
 {
   "tweet_id": "1590046442261221376",
   "Positive": "0.038481101393699646",
   "Negative": "0.0003292342007625848",
   "Neutral": "0.9611807465553284",
   "Mixed": "8.890497156244237e-06"
 },
 {
   "tweet_id": "1590046434984091648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590046430026424320",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046421331607552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046404353069056",
   "Positive": "0.022665031254291534",
   "Negative": "0.009790648706257343",
   "Neutral": "0.9674532413482666",
   "Mixed": "9.114594286074862e-05"
 },
 {
   "tweet_id": "1590046403950043136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590046374774845441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046356017917952",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046341132345348",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046324665507842",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590046314951081984",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590046312053211137",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590046308018327552",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590046263709298689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590046261650296833",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590046249079967744",
   "Positive": "0.08144424110651016",
   "Negative": "0.04321713745594025",
   "Neutral": "0.8753148913383484",
   "Mixed": "2.3810218408470973e-05"
 },
 {
   "tweet_id": "1590046230050394112",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590046221158449152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046217270353921",
   "Positive": "0.8611631393432617",
   "Negative": "0.00048228591913357377",
   "Neutral": "0.13832728564739227",
   "Mixed": "2.734577537921723e-05"
 },
 {
   "tweet_id": "1590046208118370304",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046201998872576",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590046194952462336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590046192213561345",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590046169669210112",
   "Positive": "0.22756247222423553",
   "Negative": "0.016227643936872482",
   "Neutral": "0.756193995475769",
   "Mixed": "1.5951041859807447e-05"
 },
 {
   "tweet_id": "1590046133438799873",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046102593892353",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046098869354496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590046095484551169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590046079739125760",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590046078283706368",
   "Positive": "0.005421073641628027",
   "Negative": "0.18202270567417145",
   "Neutral": "0.04120536893606186",
   "Mixed": "0.7713508605957031"
 },
 {
   "tweet_id": "1590046058004242434",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590046049250742272",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590046044901232641",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590046043177377793",
   "Positive": "0.008082848973572254",
   "Negative": "0.03668469563126564",
   "Neutral": "0.02473849430680275",
   "Mixed": "0.9304940104484558"
 },
 {
   "tweet_id": "1590046041729953792",
   "Positive": "0.9696069955825806",
   "Negative": "0.002275825012475252",
   "Neutral": "0.02807939238846302",
   "Mixed": "3.7908121157670394e-05"
 },
 {
   "tweet_id": "1590046008473694208",
   "Positive": "0.005992385093122721",
   "Negative": "0.0003413793456275016",
   "Neutral": "0.9936624765396118",
   "Mixed": "3.8010084608686157e-06"
 },
 {
   "tweet_id": "1590045998285746176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045998180859904",
   "Positive": "0.028220031410455704",
   "Negative": "0.2886117100715637",
   "Neutral": "0.5215859413146973",
   "Mixed": "0.161582350730896"
 },
 {
   "tweet_id": "1590045992107540480",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590045978949988352",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1590045975846203394",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045971551256578",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045955927465984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045932544217088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045908783112192",
   "Positive": "0.0071177096106112",
   "Negative": "0.059489112347364426",
   "Neutral": "0.051581840962171555",
   "Mixed": "0.8818113803863525"
 },
 {
   "tweet_id": "1590045905155420160",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045903284752386",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590045899174313985",
   "Positive": "0.0014090336626395583",
   "Negative": "0.889025866985321",
   "Neutral": "0.10943978279829025",
   "Mixed": "0.00012537164730019867"
 },
 {
   "tweet_id": "1590045884443947008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045877900611600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045876118249472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045856363077632",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1590045847374659584",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590045826172080128",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045810267258886",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045794551607297",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045787186073600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045772879663104",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590045771961094144",
   "Positive": "0.5680620074272156",
   "Negative": "0.002130691660568118",
   "Neutral": "0.42978841066360474",
   "Mixed": "1.8877275579143316e-05"
 },
 {
   "tweet_id": "1590045763794800640",
   "Positive": "0.04319652542471886",
   "Negative": "0.10407356917858124",
   "Neutral": "0.766640841960907",
   "Mixed": "0.08608907461166382"
 },
 {
   "tweet_id": "1590045756467318785",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045755817201664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045755678785537",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590045752369508352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045693581762560",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590045689253437440",
   "Positive": "0.0006203080993145704",
   "Negative": "0.00015204559895209968",
   "Neutral": "0.9992241859436035",
   "Mixed": "3.416718527660123e-06"
 },
 {
   "tweet_id": "1590045688859328519",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590045662150029313",
   "Positive": "0.055905524641275406",
   "Negative": "0.13317525386810303",
   "Neutral": "0.7517053484916687",
   "Mixed": "0.0592137947678566"
 },
 {
   "tweet_id": "1590045653031620608",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590045651303563265",
   "Positive": "0.44290080666542053",
   "Negative": "0.023698797449469566",
   "Neutral": "0.5330271124839783",
   "Mixed": "0.0003733356134034693"
 },
 {
   "tweet_id": "1590045564225609728",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045560240603139",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045556801298432",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590045538518695937",
   "Positive": "0.0028097713366150856",
   "Negative": "0.11046700179576874",
   "Neutral": "0.8866323828697205",
   "Mixed": "9.084340126719326e-05"
 },
 {
   "tweet_id": "1590045537080053760",
   "Positive": "0.0021414896473288536",
   "Negative": "0.0002353013987885788",
   "Neutral": "0.997516393661499",
   "Mixed": "0.00010675100929802284"
 },
 {
   "tweet_id": "1590045535745949696",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590045525403144193",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045503672455168",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045495422234624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045491215355905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045487012331520",
   "Positive": "0.12022161483764648",
   "Negative": "0.003091084770858288",
   "Neutral": "0.8744065761566162",
   "Mixed": "0.002280725399032235"
 },
 {
   "tweet_id": "1590045483648839680",
   "Positive": "0.0023863804526627064",
   "Negative": "6.017974010319449e-05",
   "Neutral": "0.997547447681427",
   "Mixed": "5.898050403629895e-06"
 },
 {
   "tweet_id": "1590045483019669504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045479135367169",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590045448773177345",
   "Positive": "0.007194782141596079",
   "Negative": "4.659933620132506e-05",
   "Neutral": "0.9927228093147278",
   "Mixed": "3.5916473279939964e-05"
 },
 {
   "tweet_id": "1590045442934722561",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590045438727852033",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045427059298305",
   "Positive": "0.006566934287548065",
   "Negative": "0.6633292436599731",
   "Neutral": "0.3151143491268158",
   "Mixed": "0.014989513903856277"
 },
 {
   "tweet_id": "1590045419220140032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045411473260545",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045389058895873",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590045373262766080",
   "Positive": "0.0008828256977722049",
   "Negative": "0.0026804327499121428",
   "Neutral": "0.9964336156845093",
   "Mixed": "3.147741381326341e-06"
 },
 {
   "tweet_id": "1590045373208604673",
   "Positive": "0.35531434416770935",
   "Negative": "0.004115559626370668",
   "Neutral": "0.6405296921730042",
   "Mixed": "4.036496102344245e-05"
 },
 {
   "tweet_id": "1590045368322248704",
   "Positive": "0.003992464859038591",
   "Negative": "0.00018064329924527556",
   "Neutral": "0.9958211183547974",
   "Mixed": "5.836544460180448e-06"
 },
 {
   "tweet_id": "1590045335266942977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590045318900781056",
   "Positive": "0.004632450174540281",
   "Negative": "7.697792898397893e-05",
   "Neutral": "0.9952777624130249",
   "Mixed": "1.2782536032318603e-05"
 },
 {
   "tweet_id": "1590045316690366464",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045311791423488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045289146376192",
   "Positive": "0.0013418260496109724",
   "Negative": "0.0001889961276901886",
   "Neutral": "0.9984666109085083",
   "Mixed": "2.6168916065216763e-06"
 },
 {
   "tweet_id": "1590045286185205760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045245215236096",
   "Positive": "0.09915805608034134",
   "Negative": "0.00024472986115142703",
   "Neutral": "0.9005754590034485",
   "Mixed": "2.1831076082889922e-05"
 },
 {
   "tweet_id": "1590045232124792833",
   "Positive": "0.012604132294654846",
   "Negative": "0.14214789867401123",
   "Neutral": "0.7689586281776428",
   "Mixed": "0.07628928869962692"
 },
 {
   "tweet_id": "1590045227850797058",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590045188306894848",
   "Positive": "0.1029118150472641",
   "Negative": "0.0012326657306402922",
   "Neutral": "0.8958265781402588",
   "Mixed": "2.8939495678059757e-05"
 },
 {
   "tweet_id": "1590045187128295424",
   "Positive": "0.004667054861783981",
   "Negative": "0.00025062900385819376",
   "Neutral": "0.9949796795845032",
   "Mixed": "0.000102646867162548"
 },
 {
   "tweet_id": "1590045129108520961",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045127556632576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045126759710725",
   "Positive": "0.020033013075590134",
   "Negative": "0.0053136092610657215",
   "Neutral": "0.9742254018783569",
   "Mixed": "0.00042790983570739627"
 },
 {
   "tweet_id": "1590045123655540736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045109097463809",
   "Positive": "0.043836966156959534",
   "Negative": "0.14311812818050385",
   "Neutral": "0.37758660316467285",
   "Mixed": "0.43545836210250854"
 },
 {
   "tweet_id": "1590045103028318208",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590045099815100417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590045091464622080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590045077581463552",
   "Positive": "0.0014817416667938232",
   "Negative": "0.48497214913368225",
   "Neutral": "0.5116522908210754",
   "Mixed": "0.0018938267603516579"
 },
 {
   "tweet_id": "1590045076885237760",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045037081292801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590045022967468032",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590045021755310080",
   "Positive": "0.01815015822649002",
   "Negative": "0.07423489540815353",
   "Neutral": "0.907528281211853",
   "Mixed": "8.669370436109602e-05"
 },
 {
   "tweet_id": "1590045013433782272",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590045005447823360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590044979426037760",
   "Positive": "0.0005552655784413218",
   "Negative": "9.284995758207515e-05",
   "Neutral": "0.9993471503257751",
   "Mixed": "4.711188012151979e-06"
 },
 {
   "tweet_id": "1590044970232471552",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590044967665569792",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590044941191098368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044933880442880",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590044907304951808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044900959346690",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044898782171136",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590044881149313025",
   "Positive": "0.005824194755405188",
   "Negative": "0.7513249516487122",
   "Neutral": "0.166237473487854",
   "Mixed": "0.07661332190036774"
 },
 {
   "tweet_id": "1590044837021097985",
   "Positive": "0.3737034201622009",
   "Negative": "0.0010280166752636433",
   "Neutral": "0.6252442598342896",
   "Mixed": "2.439696982037276e-05"
 },
 {
   "tweet_id": "1590044833665937408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044816414760960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044802003120130",
   "Positive": "0.11063192039728165",
   "Negative": "0.00018433522200211883",
   "Neutral": "0.8891600370407104",
   "Mixed": "2.3606678951182403e-05"
 },
 {
   "tweet_id": "1590044792808824833",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044749733728257",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590044746348924928",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044739122102273",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044713960472578",
   "Positive": "0.8776286244392395",
   "Negative": "0.0010002508061006665",
   "Neutral": "0.12133445590734482",
   "Mixed": "3.667187411338091e-05"
 },
 {
   "tweet_id": "1590044707098615808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044699620147200",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590044680762589184",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044642938322944",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590044640417292289",
   "Positive": "0.00960035901516676",
   "Negative": "0.3885853588581085",
   "Neutral": "0.6015565395355225",
   "Mixed": "0.0002576997212599963"
 },
 {
   "tweet_id": "1590044629813981184",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590044627327127552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044625037058048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044623027974144",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590044612621897728",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590044607681007616",
   "Positive": "0.02749018743634224",
   "Negative": "0.8071361780166626",
   "Neutral": "0.15741854906082153",
   "Mixed": "0.007955151610076427"
 },
 {
   "tweet_id": "1590044603834863621",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044586571091968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590044580959096832",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044554597920768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044544854523905",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590044542484377600",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590044527905366016",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590044522389831680",
   "Positive": "0.83967125415802",
   "Negative": "0.0002527000615373254",
   "Neutral": "0.15957018733024597",
   "Mixed": "0.0005058020469732583"
 },
 {
   "tweet_id": "1590044513577619456",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1590044503066677248",
   "Positive": "0.0008784478413872421",
   "Negative": "0.0026485358830541372",
   "Neutral": "0.9963935017585754",
   "Mixed": "7.944834942463785e-05"
 },
 {
   "tweet_id": "1590044480002031619",
   "Positive": "0.002983978483825922",
   "Negative": "0.0045145354233682156",
   "Neutral": "0.9924243688583374",
   "Mixed": "7.709101919317618e-05"
 },
 {
   "tweet_id": "1590044479775731713",
   "Positive": "0.015845995396375656",
   "Negative": "0.002302295994013548",
   "Neutral": "0.9818395972251892",
   "Mixed": "1.202492512675235e-05"
 },
 {
   "tweet_id": "1590044478538416130",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590044445562519554",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044438478622724",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590044432518504448",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590044422627946496",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1590044403238055936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044402940284928",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590044342336782337",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044309369556994",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044307092037633",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590044274485506048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044251395850241",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044229212200960",
   "Positive": "0.00015421905845869333",
   "Negative": "0.0567793995141983",
   "Neutral": "0.9430262446403503",
   "Mixed": "4.00959252147004e-05"
 },
 {
   "tweet_id": "1590044202510913536",
   "Positive": "0.025783086195588112",
   "Negative": "0.00020188196504022926",
   "Neutral": "0.9740009903907776",
   "Mixed": "1.4086167539062444e-05"
 },
 {
   "tweet_id": "1590044199654952960",
   "Positive": "0.47840026021003723",
   "Negative": "0.0027090825606137514",
   "Neutral": "0.04465318098664284",
   "Mixed": "0.47423747181892395"
 },
 {
   "tweet_id": "1590044190087745536",
   "Positive": "0.07065753638744354",
   "Negative": "0.011234858073294163",
   "Neutral": "0.9175778031349182",
   "Mixed": "0.0005298960022628307"
 },
 {
   "tweet_id": "1590044174031937536",
   "Positive": "0.8251715898513794",
   "Negative": "0.000354626274202019",
   "Neutral": "0.17443440854549408",
   "Mixed": "3.9339312934316695e-05"
 },
 {
   "tweet_id": "1590044105186607105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044102317727745",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590044094684069888",
   "Positive": "0.0008828256977722049",
   "Negative": "0.0026804327499121428",
   "Neutral": "0.9964336156845093",
   "Mixed": "3.147741381326341e-06"
 },
 {
   "tweet_id": "1590044083921113088",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1590044050819686400",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044044218241024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590044038153273344",
   "Positive": "0.3725443184375763",
   "Negative": "0.0025520985946059227",
   "Neutral": "0.6248535513877869",
   "Mixed": "4.998051008442417e-05"
 },
 {
   "tweet_id": "1590044028388921344",
   "Positive": "0.007354426197707653",
   "Negative": "0.0004112906171940267",
   "Neutral": "0.9922299981117249",
   "Mixed": "4.21231607106165e-06"
 },
 {
   "tweet_id": "1590044020671381504",
   "Positive": "9.468222560826689e-05",
   "Negative": "0.9970421195030212",
   "Neutral": "0.002854118589311838",
   "Mixed": "9.060383490577806e-06"
 },
 {
   "tweet_id": "1590044016976232448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590044014065381376",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590044011506831360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590043985502146561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590043959879159808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043957748432896",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043956905381888",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590043956506550272",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590043939624857601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043937125044225",
   "Positive": "0.001568676671013236",
   "Negative": "0.018862351775169373",
   "Neutral": "0.9795598983764648",
   "Mixed": "9.155328370979987e-06"
 },
 {
   "tweet_id": "1590043931961880576",
   "Positive": "0.0005595629336312413",
   "Negative": "0.00016400656022597104",
   "Neutral": "0.9992732405662537",
   "Mixed": "3.259439381508855e-06"
 },
 {
   "tweet_id": "1590043913016180736",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590043909274509312",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1590043902035496960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590043900852719616",
   "Positive": "0.006314112339168787",
   "Negative": "0.7733567953109741",
   "Neutral": "0.22025693953037262",
   "Mixed": "7.22101322025992e-05"
 },
 {
   "tweet_id": "1590043899078533123",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590043878312509441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043871672954882",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043850223259651",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590043845646987265",
   "Positive": "0.001568676671013236",
   "Negative": "0.018862351775169373",
   "Neutral": "0.9795598983764648",
   "Mixed": "9.155328370979987e-06"
 },
 {
   "tweet_id": "1590043820955435008",
   "Positive": "0.0030968324281275272",
   "Negative": "0.06968481838703156",
   "Neutral": "0.9272069334983826",
   "Mixed": "1.1385369361960329e-05"
 },
 {
   "tweet_id": "1590043805151301634",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590043802181697536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043798301986822",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590043792102502407",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590043788072071168",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590043769143193600",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590043754341486592",
   "Positive": "0.003394793253391981",
   "Negative": "0.0003377722459845245",
   "Neutral": "0.9962629675865173",
   "Mixed": "4.524845280684531e-06"
 },
 {
   "tweet_id": "1590043748020686848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043746573619201",
   "Positive": "0.06805097311735153",
   "Negative": "9.550647519063205e-05",
   "Neutral": "0.9315894246101379",
   "Mixed": "0.0002640800958033651"
 },
 {
   "tweet_id": "1590043740210876416",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590043714785005570",
   "Positive": "0.0002433172194287181",
   "Negative": "0.983080267906189",
   "Neutral": "0.016656100749969482",
   "Mixed": "2.0317325834184885e-05"
 },
 {
   "tweet_id": "1590043709613436929",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1590043698980884480",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590043697688678400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043694308429824",
   "Positive": "0.17885246872901917",
   "Negative": "0.18576695024967194",
   "Neutral": "0.2692747414112091",
   "Mixed": "0.36610591411590576"
 },
 {
   "tweet_id": "1590043673328504834",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590043668295352320",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590043654596743168",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590043637647568897",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043630743740416",
   "Positive": "0.006120743229985237",
   "Negative": "0.8455995321273804",
   "Neutral": "0.14800339937210083",
   "Mixed": "0.0002762921212706715"
 },
 {
   "tweet_id": "1590043626855608320",
   "Positive": "0.9215725660324097",
   "Negative": "8.733997674426064e-05",
   "Neutral": "0.07823580503463745",
   "Mixed": "0.0001042535004671663"
 },
 {
   "tweet_id": "1590043603111665664",
   "Positive": "0.3784092366695404",
   "Negative": "0.00034090931876562536",
   "Neutral": "0.6211494207382202",
   "Mixed": "0.00010043650399893522"
 },
 {
   "tweet_id": "1590043583989809152",
   "Positive": "0.17842590808868408",
   "Negative": "0.0007413104176521301",
   "Neutral": "0.8207566142082214",
   "Mixed": "7.611641922267154e-05"
 },
 {
   "tweet_id": "1590043578079727617",
   "Positive": "0.003697733860462904",
   "Negative": "0.7211053371429443",
   "Neutral": "0.2556297183036804",
   "Mixed": "0.019567232578992844"
 },
 {
   "tweet_id": "1590043577182085120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043571708891137",
   "Positive": "0.007363306358456612",
   "Negative": "8.074779179878533e-05",
   "Neutral": "0.9925094842910767",
   "Mixed": "4.642238491214812e-05"
 },
 {
   "tweet_id": "1590043565715226625",
   "Positive": "0.019995415583252907",
   "Negative": "0.0795683041214943",
   "Neutral": "0.09384375810623169",
   "Mixed": "0.8065924048423767"
 },
 {
   "tweet_id": "1590043546496954369",
   "Positive": "0.9693061709403992",
   "Negative": "0.0005575795657932758",
   "Neutral": "0.030109120532870293",
   "Mixed": "2.7202275305171497e-05"
 },
 {
   "tweet_id": "1590043522086105088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043517543669760",
   "Positive": "0.5901947021484375",
   "Negative": "0.00017211282101925462",
   "Neutral": "0.40950024127960205",
   "Mixed": "0.0001329388760495931"
 },
 {
   "tweet_id": "1590043508983070720",
   "Positive": "0.0008954532677307725",
   "Negative": "0.9719831347465515",
   "Neutral": "0.027010617777705193",
   "Mixed": "0.00011086577433161438"
 },
 {
   "tweet_id": "1590043508832104448",
   "Positive": "9.552718256600201e-05",
   "Negative": "0.00024245886015705764",
   "Neutral": "0.9996528625488281",
   "Mixed": "9.150222467724234e-06"
 },
 {
   "tweet_id": "1590043497574281217",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590043487847972865",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043470227705856",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043461771702273",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590043459838439424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043445426794496",
   "Positive": "0.0017174510285258293",
   "Negative": "0.5898597836494446",
   "Neutral": "0.40827640891075134",
   "Mixed": "0.00014648692740593106"
 },
 {
   "tweet_id": "1590043435330719746",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590043423901634561",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043399520153600",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590043382109569024",
   "Positive": "0.0270672757178545",
   "Negative": "0.09632029384374619",
   "Neutral": "0.8765357732772827",
   "Mixed": "7.661118434043601e-05"
 },
 {
   "tweet_id": "1590043374232698880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043296885534721",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590043286193917954",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590043282037698560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590043265243705344",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590043257815597056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590043242258903040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043235191500800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043221471567873",
   "Positive": "0.8035172820091248",
   "Negative": "0.008086747489869595",
   "Neutral": "0.18824918568134308",
   "Mixed": "0.00014672521501779556"
 },
 {
   "tweet_id": "1590043207672672257",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590043201833820160",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1590043194863259649",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043193344954368",
   "Positive": "0.13505353033542633",
   "Negative": "0.0005790450959466398",
   "Neutral": "0.864341139793396",
   "Mixed": "2.6312538466299884e-05"
 },
 {
   "tweet_id": "1590043181357608961",
   "Positive": "0.025256335735321045",
   "Negative": "0.7387252449989319",
   "Neutral": "0.230979785323143",
   "Mixed": "0.005038535222411156"
 },
 {
   "tweet_id": "1590043180669734912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043142686117889",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590043121857232896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590043109861511169",
   "Positive": "0.0004853909194935113",
   "Negative": "6.988416134845465e-05",
   "Neutral": "0.9994352459907532",
   "Mixed": "9.402564501215238e-06"
 },
 {
   "tweet_id": "1590043102609539072",
   "Positive": "0.16265438497066498",
   "Negative": "0.004124268889427185",
   "Neutral": "0.8331983685493469",
   "Mixed": "2.2980113499215804e-05"
 },
 {
   "tweet_id": "1590043100202045440",
   "Positive": "0.01778210885822773",
   "Negative": "0.00039904151344671845",
   "Neutral": "0.9817975759506226",
   "Mixed": "2.129669883288443e-05"
 },
 {
   "tweet_id": "1590043089066160129",
   "Positive": "0.00035478323115967214",
   "Negative": "0.9904376864433289",
   "Neutral": "0.009175639599561691",
   "Mixed": "3.1878291338216513e-05"
 },
 {
   "tweet_id": "1590043084670148608",
   "Positive": "0.0538569875061512",
   "Negative": "0.00399582227692008",
   "Neutral": "0.9421345591545105",
   "Mixed": "1.2612304999493062e-05"
 },
 {
   "tweet_id": "1590043077674430464",
   "Positive": "0.2586989104747772",
   "Negative": "0.0413801372051239",
   "Neutral": "0.5210767388343811",
   "Mixed": "0.178844153881073"
 },
 {
   "tweet_id": "1590043064105840641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590043054064689153",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590043051007021056",
   "Positive": "0.014836148358881474",
   "Negative": "0.7383911609649658",
   "Neutral": "0.2341846078634262",
   "Mixed": "0.012588120996952057"
 },
 {
   "tweet_id": "1590043048830201864",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590043035219681282",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590043017968504832",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1590043016898940928",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590042998158790656",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590042994560106497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042970304049152",
   "Positive": "0.002357282442972064",
   "Negative": "8.28992560855113e-05",
   "Neutral": "0.9975519776344299",
   "Mixed": "7.819005077180918e-06"
 },
 {
   "tweet_id": "1590042935198126081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042905334292480",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042895704154113",
   "Positive": "0.5040453672409058",
   "Negative": "0.03945256024599075",
   "Neutral": "0.4433330297470093",
   "Mixed": "0.01316910795867443"
 },
 {
   "tweet_id": "1590042893548654593",
   "Positive": "0.0007500766660086811",
   "Negative": "0.00013895462325308472",
   "Neutral": "0.9991075396537781",
   "Mixed": "3.4313852665945888e-06"
 },
 {
   "tweet_id": "1590042886347042817",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042883263864833",
   "Positive": "0.0017884888220578432",
   "Negative": "0.4072745740413666",
   "Neutral": "0.590729296207428",
   "Mixed": "0.00020763487555086613"
 },
 {
   "tweet_id": "1590042880424706048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042876611743745",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042862108160001",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590042858547216385",
   "Positive": "0.007908160798251629",
   "Negative": "0.41152751445770264",
   "Neutral": "0.15095902979373932",
   "Mixed": "0.4296053349971771"
 },
 {
   "tweet_id": "1590042842998927360",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590042835243663360",
   "Positive": "0.07056782394647598",
   "Negative": "0.0013516752514988184",
   "Neutral": "0.9280688166618347",
   "Mixed": "1.1770881428674329e-05"
 },
 {
   "tweet_id": "1590042825441562624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042823700930560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042777823633408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042777081217024",
   "Positive": "0.5120356678962708",
   "Negative": "0.07553812116384506",
   "Neutral": "0.32669350504875183",
   "Mixed": "0.08573270589113235"
 },
 {
   "tweet_id": "1590042774664929280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042759733579776",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590042754054492160",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590042752091586563",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042731283288065",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042725399015425",
   "Positive": "0.3886978328227997",
   "Negative": "0.0012409691698849201",
   "Neutral": "0.6100254058837891",
   "Mixed": "3.576829476514831e-05"
 },
 {
   "tweet_id": "1590042713340383232",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590042700958806017",
   "Positive": "0.645768940448761",
   "Negative": "0.0018688641721382737",
   "Neutral": "0.3506849706172943",
   "Mixed": "0.0016772212693467736"
 },
 {
   "tweet_id": "1590042694511841280",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042689181200385",
   "Positive": "0.0048418473452329636",
   "Negative": "0.26542040705680847",
   "Neutral": "0.08179234713315964",
   "Mixed": "0.6479454040527344"
 },
 {
   "tweet_id": "1590042687994200064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042677504245760",
   "Positive": "0.006500301882624626",
   "Negative": "0.7392936944961548",
   "Neutral": "0.2536037564277649",
   "Mixed": "0.0006022027228027582"
 },
 {
   "tweet_id": "1590042677009350656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042667085615105",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590042657988161537",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042649389826048",
   "Positive": "0.13944421708583832",
   "Negative": "0.0005786247202195227",
   "Neutral": "0.8591635823249817",
   "Mixed": "0.0008135191746987402"
 },
 {
   "tweet_id": "1590042644235046912",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590042632654557184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042610043097089",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042592586387457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042588379115521",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590042566325850119",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042555709693953",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042555085094912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042522675740673",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042517365731330",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1590042515931271170",
   "Positive": "0.0067592221312224865",
   "Negative": "0.6182646751403809",
   "Neutral": "0.2715401351451874",
   "Mixed": "0.10343596339225769"
 },
 {
   "tweet_id": "1590042509119750145",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1590042499590258688",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590042486311092225",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042484843122689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042480879157248",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590042477867606018",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590042476383207427",
   "Positive": "0.004484881646931171",
   "Negative": "0.054500699043273926",
   "Neutral": "0.941007137298584",
   "Mixed": "7.324401849473361e-06"
 },
 {
   "tweet_id": "1590042467810037762",
   "Positive": "0.08703958988189697",
   "Negative": "0.0010538622736930847",
   "Neutral": "0.9118422865867615",
   "Mixed": "6.424317689379677e-05"
 },
 {
   "tweet_id": "1590042466970791936",
   "Positive": "0.46811020374298096",
   "Negative": "0.17685970664024353",
   "Neutral": "0.35447871685028076",
   "Mixed": "0.0005512696225196123"
 },
 {
   "tweet_id": "1590042464651710466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042455675924480",
   "Positive": "0.6559531092643738",
   "Negative": "0.09343980252742767",
   "Neutral": "0.2503771483898163",
   "Mixed": "0.00022992028971202672"
 },
 {
   "tweet_id": "1590042447572500480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042446506778626",
   "Positive": "0.9972942471504211",
   "Negative": "0.00010430850670672953",
   "Neutral": "0.002568833064287901",
   "Mixed": "3.260710582253523e-05"
 },
 {
   "tweet_id": "1590042428501037058",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590042411644092417",
   "Positive": "0.028534330427646637",
   "Negative": "0.2594541907310486",
   "Neutral": "0.7082371711730957",
   "Mixed": "0.0037744035944342613"
 },
 {
   "tweet_id": "1590042409144320000",
   "Positive": "0.028037553653120995",
   "Negative": "8.572648948756978e-05",
   "Neutral": "0.9716846346855164",
   "Mixed": "0.00019210550817660987"
 },
 {
   "tweet_id": "1590042406929698817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042398914404352",
   "Positive": "0.0004076705372426659",
   "Negative": "0.9634886384010315",
   "Neutral": "0.036024730652570724",
   "Mixed": "7.896673196228221e-05"
 },
 {
   "tweet_id": "1590042397911945216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042385429716992",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590042383580033026",
   "Positive": "0.22251462936401367",
   "Negative": "0.0006091459072194993",
   "Neutral": "0.7768192291259766",
   "Mixed": "5.707250238629058e-05"
 },
 {
   "tweet_id": "1590042365997494273",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590042365628411904",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590042344833060865",
   "Positive": "0.028037553653120995",
   "Negative": "8.572648948756978e-05",
   "Neutral": "0.9716846346855164",
   "Mixed": "0.00019210550817660987"
 },
 {
   "tweet_id": "1590042338738700290",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042325686063104",
   "Positive": "0.016810186207294464",
   "Negative": "0.3668655455112457",
   "Neutral": "0.6155389547348022",
   "Mixed": "0.0007852882263250649"
 },
 {
   "tweet_id": "1590042301568782336",
   "Positive": "0.019855888560414314",
   "Negative": "0.0008956182864494622",
   "Neutral": "0.9792415499687195",
   "Mixed": "7.000215191510506e-06"
 },
 {
   "tweet_id": "1590042298477596674",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042277803876353",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042272779100160",
   "Positive": "0.1487356573343277",
   "Negative": "0.00018592420383356512",
   "Neutral": "0.8510453701019287",
   "Mixed": "3.303905396023765e-05"
 },
 {
   "tweet_id": "1590042265447460865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042262020698113",
   "Positive": "0.8333016633987427",
   "Negative": "0.0005596542032435536",
   "Neutral": "0.16414931416511536",
   "Mixed": "0.0019893322605639696"
 },
 {
   "tweet_id": "1590042253728567296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042249710415877",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1590042227547701248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042226276855809",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590042215468134402",
   "Positive": "0.18338364362716675",
   "Negative": "0.00020719737221952528",
   "Neutral": "0.8163672685623169",
   "Mixed": "4.186785008641891e-05"
 },
 {
   "tweet_id": "1590042210971840512",
   "Positive": "0.000714223540853709",
   "Negative": "7.750849181320518e-05",
   "Neutral": "0.9992040991783142",
   "Mixed": "4.207916845189175e-06"
 },
 {
   "tweet_id": "1590042192596254720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042177094447104",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590042166600298496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042166239277057",
   "Positive": "0.05629171058535576",
   "Negative": "0.003806137479841709",
   "Neutral": "0.9369109869003296",
   "Mixed": "0.0029912590980529785"
 },
 {
   "tweet_id": "1590042147205439488",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042131628191744",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590042124510449664",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590042121406656512",
   "Positive": "0.008025119081139565",
   "Negative": "0.0001348782971035689",
   "Neutral": "0.9918346405029297",
   "Mixed": "5.3340968406700995e-06"
 },
 {
   "tweet_id": "1590042116167958529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042111441014784",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042108987322369",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590042107225726976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590042105975803904",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590042096647671808",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042093837504512",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042075021869058",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042073222512642",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590042072949854208",
   "Positive": "0.034594982862472534",
   "Negative": "0.00018544966587796807",
   "Neutral": "0.9652066230773926",
   "Mixed": "1.2855729437433183e-05"
 },
 {
   "tweet_id": "1590042059653918722",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590042059020587010",
   "Positive": "0.012135226279497147",
   "Negative": "0.15670399367809296",
   "Neutral": "0.0601348914206028",
   "Mixed": "0.7710258960723877"
 },
 {
   "tweet_id": "1590042055086002176",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590042052632670208",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590042025151574018",
   "Positive": "0.040947649627923965",
   "Negative": "0.00019167238497175276",
   "Neutral": "0.9588497281074524",
   "Mixed": "1.0979554645018652e-05"
 },
 {
   "tweet_id": "1590042021980360704",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590042018016796674",
   "Positive": "0.35755184292793274",
   "Negative": "0.03174424171447754",
   "Neutral": "0.5565134882926941",
   "Mixed": "0.054190438240766525"
 },
 {
   "tweet_id": "1590042009280348160",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590041999184658432",
   "Positive": "0.0006857458502054214",
   "Negative": "0.9388722777366638",
   "Neutral": "0.059516508132219315",
   "Mixed": "0.0009254263713955879"
 },
 {
   "tweet_id": "1590041994881273857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041993627172864",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041992872226816",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041977424596992",
   "Positive": "0.040947649627923965",
   "Negative": "0.00019167238497175276",
   "Neutral": "0.9588497281074524",
   "Mixed": "1.0979554645018652e-05"
 },
 {
   "tweet_id": "1590041962727452672",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590041958206312448",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041946470330368",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590041921711648768",
   "Positive": "0.3264002501964569",
   "Negative": "0.00360481278039515",
   "Neutral": "0.6698671579360962",
   "Mixed": "0.0001277783012483269"
 },
 {
   "tweet_id": "1590041921225125891",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041915415986177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041908692545536",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590041887783587841",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590041884336197633",
   "Positive": "0.03143788129091263",
   "Negative": "0.0020617761183530092",
   "Neutral": "0.9664788246154785",
   "Mixed": "2.1537884094868787e-05"
 },
 {
   "tweet_id": "1590041882390073344",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590041874248912896",
   "Positive": "0.4703671932220459",
   "Negative": "0.0023688888177275658",
   "Neutral": "0.5272007584571838",
   "Mixed": "6.312203186098486e-05"
 },
 {
   "tweet_id": "1590041867462529024",
   "Positive": "0.35233449935913086",
   "Negative": "0.0035132586490362883",
   "Neutral": "0.6440916657447815",
   "Mixed": "6.0560956626432016e-05"
 },
 {
   "tweet_id": "1590041866946347008",
   "Positive": "0.31474873423576355",
   "Negative": "0.001816180651076138",
   "Neutral": "0.6834039092063904",
   "Mixed": "3.1240266253007576e-05"
 },
 {
   "tweet_id": "1590041858339934209",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590041857542717441",
   "Positive": "0.21904288232326508",
   "Negative": "0.00015616550808772445",
   "Neutral": "0.7806426286697388",
   "Mixed": "0.00015829229960218072"
 },
 {
   "tweet_id": "1590041853868449792",
   "Positive": "0.324390709400177",
   "Negative": "0.0160666536539793",
   "Neutral": "0.4184647500514984",
   "Mixed": "0.24107784032821655"
 },
 {
   "tweet_id": "1590041848739135489",
   "Positive": "0.018732504919171333",
   "Negative": "0.19894559681415558",
   "Neutral": "0.7821089625358582",
   "Mixed": "0.0002129334316123277"
 },
 {
   "tweet_id": "1590041846641631233",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1590041844876210182",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1590041836017840131",
   "Positive": "0.026065347716212273",
   "Negative": "0.42656922340393066",
   "Neutral": "0.5472179651260376",
   "Mixed": "0.00014750273840036243"
 },
 {
   "tweet_id": "1590041819974631424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041803390345216",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590041800605302785",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590041768468566016",
   "Positive": "0.21904288232326508",
   "Negative": "0.00015616550808772445",
   "Neutral": "0.7806426286697388",
   "Mixed": "0.00015829229960218072"
 },
 {
   "tweet_id": "1590041752282746882",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041751456124928",
   "Positive": "0.6755528450012207",
   "Negative": "0.0019058848265558481",
   "Neutral": "0.31427282094955444",
   "Mixed": "0.008268434554338455"
 },
 {
   "tweet_id": "1590041751255134209",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590041739066494977",
   "Positive": "0.0022695588413625956",
   "Negative": "0.0001258522243006155",
   "Neutral": "0.997588038444519",
   "Mixed": "1.6479558325954713e-05"
 },
 {
   "tweet_id": "1590041736747028480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041735815921664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041718627655680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041713896460289",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041692262264833",
   "Positive": "0.016759207472205162",
   "Negative": "0.3388325870037079",
   "Neutral": "0.13559816777706146",
   "Mixed": "0.5088100433349609"
 },
 {
   "tweet_id": "1590041687887605760",
   "Positive": "0.6831231117248535",
   "Negative": "0.0015629916451871395",
   "Neutral": "0.31443798542022705",
   "Mixed": "0.0008758930489420891"
 },
 {
   "tweet_id": "1590041675904471041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041639418236932",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041637774057473",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590041636922609664",
   "Positive": "0.007863192819058895",
   "Negative": "0.782478392124176",
   "Neutral": "0.2061275988817215",
   "Mixed": "0.003530756337568164"
 },
 {
   "tweet_id": "1590041625899978752",
   "Positive": "0.02110879123210907",
   "Negative": "0.00472220778465271",
   "Neutral": "0.9737446904182434",
   "Mixed": "0.0004243136791046709"
 },
 {
   "tweet_id": "1590041610061901826",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041593729683456",
   "Positive": "0.01388307474553585",
   "Negative": "0.00013891517301090062",
   "Neutral": "0.9859716296195984",
   "Mixed": "6.3614406826673076e-06"
 },
 {
   "tweet_id": "1590041592311971841",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590041591523467264",
   "Positive": "0.3265906274318695",
   "Negative": "0.0006162286153994501",
   "Neutral": "0.672680675983429",
   "Mixed": "0.0001124563641496934"
 },
 {
   "tweet_id": "1590041581167734784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041565975654400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041562935103489",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041553393061888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041548116602880",
   "Positive": "0.06643533706665039",
   "Negative": "0.011012429371476173",
   "Neutral": "0.9224978685379028",
   "Mixed": "5.432847319752909e-05"
 },
 {
   "tweet_id": "1590041533289762817",
   "Positive": "0.04537017643451691",
   "Negative": "0.2899421155452728",
   "Neutral": "0.6324547529220581",
   "Mixed": "0.03223292529582977"
 },
 {
   "tweet_id": "1590041522761699329",
   "Positive": "0.8197001218795776",
   "Negative": "0.0005446755676530302",
   "Neutral": "0.1796981692314148",
   "Mixed": "5.705100193154067e-05"
 },
 {
   "tweet_id": "1590041518433525762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041504713957379",
   "Positive": "0.06643533706665039",
   "Negative": "0.011012429371476173",
   "Neutral": "0.9224978685379028",
   "Mixed": "5.432847319752909e-05"
 },
 {
   "tweet_id": "1590041502339989504",
   "Positive": "0.040947649627923965",
   "Negative": "0.00019167238497175276",
   "Neutral": "0.9588497281074524",
   "Mixed": "1.0979554645018652e-05"
 },
 {
   "tweet_id": "1590041498070192128",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590041491514494977",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590041486011555840",
   "Positive": "0.04493804648518562",
   "Negative": "0.0007937436457723379",
   "Neutral": "0.954244077205658",
   "Mixed": "2.4163724447134882e-05"
 },
 {
   "tweet_id": "1590041472782708736",
   "Positive": "0.01044546626508236",
   "Negative": "0.015674058347940445",
   "Neutral": "0.9732662439346313",
   "Mixed": "0.0006142736529000103"
 },
 {
   "tweet_id": "1590041471020720128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041468814909440",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590041460858028033",
   "Positive": "0.2964758276939392",
   "Negative": "0.0703868567943573",
   "Neutral": "0.6326196193695068",
   "Mixed": "0.0005178056890144944"
 },
 {
   "tweet_id": "1590041458589171713",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041457892937734",
   "Positive": "0.06755264103412628",
   "Negative": "0.012504630722105503",
   "Neutral": "0.9198831915855408",
   "Mixed": "5.956568929832429e-05"
 },
 {
   "tweet_id": "1590041446111137792",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590041438888550402",
   "Positive": "0.13796401023864746",
   "Negative": "0.0006052429671399295",
   "Neutral": "0.8612420558929443",
   "Mixed": "0.00018868496408686042"
 },
 {
   "tweet_id": "1590041417380155393",
   "Positive": "0.1437678188085556",
   "Negative": "0.0001768278598319739",
   "Neutral": "0.85602205991745",
   "Mixed": "3.3219330362044275e-05"
 },
 {
   "tweet_id": "1590041415018766336",
   "Positive": "0.06270021200180054",
   "Negative": "0.013607524335384369",
   "Neutral": "0.9236409664154053",
   "Mixed": "5.13219456479419e-05"
 },
 {
   "tweet_id": "1590041404671393792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041400464506883",
   "Positive": "0.0013118486385792494",
   "Negative": "0.0001355887798126787",
   "Neutral": "0.9985398054122925",
   "Mixed": "1.2710393093584571e-05"
 },
 {
   "tweet_id": "1590041380067618817",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041366192496640",
   "Positive": "0.0039506456814706326",
   "Negative": "0.0005043795681558549",
   "Neutral": "0.9955412745475769",
   "Mixed": "3.716354513016995e-06"
 },
 {
   "tweet_id": "1590041364397711360",
   "Positive": "0.18338364362716675",
   "Negative": "0.00020719737221952528",
   "Neutral": "0.8163672685623169",
   "Mixed": "4.186785008641891e-05"
 },
 {
   "tweet_id": "1590041362107609089",
   "Positive": "0.832797110080719",
   "Negative": "0.00037036079447716475",
   "Neutral": "0.1662367284297943",
   "Mixed": "0.0005957586690783501"
 },
 {
   "tweet_id": "1590041354138419200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041333678235648",
   "Positive": "0.00012743928527925164",
   "Negative": "0.9990088939666748",
   "Neutral": "0.0008307775133289397",
   "Mixed": "3.291291432105936e-05"
 },
 {
   "tweet_id": "1590041327726891008",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590041325705244672",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590041320621768704",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590041315274027008",
   "Positive": "0.07647539675235748",
   "Negative": "0.021284153684973717",
   "Neutral": "0.9022285342216492",
   "Mixed": "1.1930940672755241e-05"
 },
 {
   "tweet_id": "1590041309821440001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041303865511936",
   "Positive": "0.05339095741510391",
   "Negative": "0.0002485279692336917",
   "Neutral": "0.9463360905647278",
   "Mixed": "2.43242993747117e-05"
 },
 {
   "tweet_id": "1590041303257341953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041299402784768",
   "Positive": "0.1844186633825302",
   "Negative": "0.0009549130336381495",
   "Neutral": "0.8136523962020874",
   "Mixed": "0.0009740609093569219"
 },
 {
   "tweet_id": "1590041292670918656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041275851763712",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590041262773927936",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590041250321043459",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590041238174330880",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590041235871653888",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590041235187994625",
   "Positive": "0.0027964317705482244",
   "Negative": "0.0002412072935840115",
   "Neutral": "0.9969596862792969",
   "Mixed": "2.7775206490332494e-06"
 },
 {
   "tweet_id": "1590041221531308032",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590041219605807107",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590041215017570306",
   "Positive": "0.028037553653120995",
   "Negative": "8.572648948756978e-05",
   "Neutral": "0.9716846346855164",
   "Mixed": "0.00019210550817660987"
 },
 {
   "tweet_id": "1590041212995932160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041212727484417",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1590041206805106689",
   "Positive": "0.06869611144065857",
   "Negative": "0.15583181381225586",
   "Neutral": "0.7735270261764526",
   "Mixed": "0.0019450889667496085"
 },
 {
   "tweet_id": "1590041204800258049",
   "Positive": "0.951185405254364",
   "Negative": "0.00014643579197581857",
   "Neutral": "0.04859748110175133",
   "Mixed": "7.075164467096329e-05"
 },
 {
   "tweet_id": "1590041198831362068",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590041196671668224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041195094642688",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590041182251671552",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590041176044077057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041172407291906",
   "Positive": "0.0066895210184156895",
   "Negative": "0.17135730385780334",
   "Neutral": "0.8217990398406982",
   "Mixed": "0.00015424330194946378"
 },
 {
   "tweet_id": "1590041146121912320",
   "Positive": "0.9719189405441284",
   "Negative": "0.00013010723341722041",
   "Neutral": "0.027917783707380295",
   "Mixed": "3.321616895846091e-05"
 },
 {
   "tweet_id": "1590041142917492737",
   "Positive": "0.022869525477290154",
   "Negative": "8.757872274145484e-05",
   "Neutral": "0.9769946336746216",
   "Mixed": "4.8268891987390816e-05"
 },
 {
   "tweet_id": "1590041141776637953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041126886862848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041126450630656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041116900225025",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041110529048576",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590041106531880960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041099506438144",
   "Positive": "0.0004142689285799861",
   "Negative": "0.00023119313118513674",
   "Neutral": "0.9993517994880676",
   "Mixed": "2.693223450478399e-06"
 },
 {
   "tweet_id": "1590041087972110336",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590041087309385730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590041086407630848",
   "Positive": "0.000908593472559005",
   "Negative": "0.00047646547318436205",
   "Neutral": "0.998612642288208",
   "Mixed": "2.3052516553434543e-06"
 },
 {
   "tweet_id": "1590041072918761472",
   "Positive": "0.00046691298484802246",
   "Negative": "0.00028127187397331",
   "Neutral": "0.9992493987083435",
   "Mixed": "2.3577019874210237e-06"
 },
 {
   "tweet_id": "1590041072482516992",
   "Positive": "0.0012335572391748428",
   "Negative": "0.000629129761364311",
   "Neutral": "0.9981285929679871",
   "Mixed": "8.630485353933182e-06"
 },
 {
   "tweet_id": "1590041057655681024",
   "Positive": "0.0003595543676055968",
   "Negative": "0.0005465710419230163",
   "Neutral": "0.9990918636322021",
   "Mixed": "2.081680122500984e-06"
 },
 {
   "tweet_id": "1590041042862379008",
   "Positive": "0.0003649163991212845",
   "Negative": "0.00017580125131644309",
   "Neutral": "0.9994563460350037",
   "Mixed": "3.023221097464557e-06"
 },
 {
   "tweet_id": "1590041041029468160",
   "Positive": "0.18304041028022766",
   "Negative": "0.0004423921927809715",
   "Neutral": "0.8164607882499695",
   "Mixed": "5.6421304179821163e-05"
 },
 {
   "tweet_id": "1590041038676439041",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590041037854380033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590041030543671297",
   "Positive": "0.08113306760787964",
   "Negative": "0.0010670418851077557",
   "Neutral": "0.9177574515342712",
   "Mixed": "4.249415724189021e-05"
 },
 {
   "tweet_id": "1590041030166183937",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590041028584538112",
   "Positive": "0.00032623723382130265",
   "Negative": "0.0003066700592171401",
   "Neutral": "0.9993647933006287",
   "Mixed": "2.247869360871846e-06"
 },
 {
   "tweet_id": "1590041014978621440",
   "Positive": "0.97533118724823",
   "Negative": "0.00016459128528367728",
   "Neutral": "0.024224741384387016",
   "Mixed": "0.0002794097235891968"
 },
 {
   "tweet_id": "1590041014047166465",
   "Positive": "0.00027322827372699976",
   "Negative": "0.00030858174432069063",
   "Neutral": "0.9994156360626221",
   "Mixed": "2.565545173638384e-06"
 },
 {
   "tweet_id": "1590041000827047936",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590040997752606720",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590040987921158144",
   "Positive": "0.0004452388093341142",
   "Negative": "0.00025870976969599724",
   "Neutral": "0.9992936849594116",
   "Mixed": "2.369056119277957e-06"
 },
 {
   "tweet_id": "1590040971815059456",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040970774863873",
   "Positive": "0.08703958988189697",
   "Negative": "0.0010538622736930847",
   "Neutral": "0.9118422865867615",
   "Mixed": "6.424317689379677e-05"
 },
 {
   "tweet_id": "1590040957302771712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040945961369601",
   "Positive": "0.011347303166985512",
   "Negative": "0.009333387017250061",
   "Neutral": "0.9790321588516235",
   "Mixed": "0.00028707156889140606"
 },
 {
   "tweet_id": "1590040916446019585",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040914604740609",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040913312899072",
   "Positive": "0.0002757121401373297",
   "Negative": "0.0013423811178654432",
   "Neutral": "0.9983748197555542",
   "Mixed": "7.063664725137642e-06"
 },
 {
   "tweet_id": "1590040909764505600",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040903561117697",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590040889594114049",
   "Positive": "0.06623703986406326",
   "Negative": "0.0006253172759898007",
   "Neutral": "0.9329715967178345",
   "Mixed": "0.00016601209063082933"
 },
 {
   "tweet_id": "1590040873236303872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040872779132928",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590040859395100675",
   "Positive": "0.08195683360099792",
   "Negative": "0.0009935314301401377",
   "Neutral": "0.9170240759849548",
   "Mixed": "2.5613040634198114e-05"
 },
 {
   "tweet_id": "1590040859093110784",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040853535371265",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040851048468481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040837173694466",
   "Positive": "0.0028043482452630997",
   "Negative": "4.871360943070613e-05",
   "Neutral": "0.9971278309822083",
   "Mixed": "1.9135997717967257e-05"
 },
 {
   "tweet_id": "1590040817158479874",
   "Positive": "0.4677429795265198",
   "Negative": "0.0006334442878141999",
   "Neutral": "0.5315446257591248",
   "Mixed": "7.896625902503729e-05"
 },
 {
   "tweet_id": "1590040814558015488",
   "Positive": "0.16558882594108582",
   "Negative": "0.005993280094116926",
   "Neutral": "0.8283870220184326",
   "Mixed": "3.095246211159974e-05"
 },
 {
   "tweet_id": "1590040811227713536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040797558484992",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040795062874113",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040793301254146",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040756273938433",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590040755061813248",
   "Positive": "0.8240657448768616",
   "Negative": "0.0013459020992740989",
   "Neutral": "0.17393267154693604",
   "Mixed": "0.0006556260632351041"
 },
 {
   "tweet_id": "1590040751354048513",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040736312963072",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590040725831700480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040719875792896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040716570689536",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040697037819904",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590040696345722880",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040675755511808",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040666725576704",
   "Positive": "0.990452766418457",
   "Negative": "0.0002674421120900661",
   "Neutral": "0.009264093823730946",
   "Mixed": "1.5684723621234298e-05"
 },
 {
   "tweet_id": "1590040666046107648",
   "Positive": "0.9929242134094238",
   "Negative": "0.00013229729665908962",
   "Neutral": "0.006846209522336721",
   "Mixed": "9.72923735389486e-05"
 },
 {
   "tweet_id": "1590040642717069312",
   "Positive": "0.002699014963582158",
   "Negative": "0.0001253945956705138",
   "Neutral": "0.9971715807914734",
   "Mixed": "4.049922154081287e-06"
 },
 {
   "tweet_id": "1590040637919092736",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590040620680515584",
   "Positive": "0.0011961874552071095",
   "Negative": "8.079518738668412e-05",
   "Neutral": "0.9987185001373291",
   "Mixed": "4.47830643679481e-06"
 },
 {
   "tweet_id": "1590040617861926912",
   "Positive": "0.02787875197827816",
   "Negative": "0.00021070790535304695",
   "Neutral": "0.9718865156173706",
   "Mixed": "2.400049015705008e-05"
 },
 {
   "tweet_id": "1590040605236768774",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040597259489280",
   "Positive": "0.9744545817375183",
   "Negative": "0.0002560198772698641",
   "Neutral": "0.025254597887396812",
   "Mixed": "3.483442560536787e-05"
 },
 {
   "tweet_id": "1590040585435774976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040583661551616",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040581463756802",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590040557535264768",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590040543815663616",
   "Positive": "0.026468368247151375",
   "Negative": "0.4571578800678253",
   "Neutral": "0.5147289037704468",
   "Mixed": "0.001644890638999641"
 },
 {
   "tweet_id": "1590040531891277824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040526489026560",
   "Positive": "0.9744545817375183",
   "Negative": "0.0002560198772698641",
   "Neutral": "0.025254597887396812",
   "Mixed": "3.483442560536787e-05"
 },
 {
   "tweet_id": "1590040526358994944",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040522248556545",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040518989578240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040514425806850",
   "Positive": "0.016317199915647507",
   "Negative": "0.013639422133564949",
   "Neutral": "0.969997227191925",
   "Mixed": "4.6169054257916287e-05"
 },
 {
   "tweet_id": "1590040513813811200",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590040511942864897",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040506666713089",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590040504301129728",
   "Positive": "0.0017654926050454378",
   "Negative": "0.9940012097358704",
   "Neutral": "0.003375324420630932",
   "Mixed": "0.0008580003050155938"
 },
 {
   "tweet_id": "1590040485896544257",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040473250721794",
   "Positive": "0.01752900704741478",
   "Negative": "0.0934082493185997",
   "Neutral": "0.8885185718536377",
   "Mixed": "0.0005441472749225795"
 },
 {
   "tweet_id": "1590040468355940352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040461129191424",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590040455768834048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040445283086338",
   "Positive": "0.9921938180923462",
   "Negative": "0.00011377867485862225",
   "Neutral": "0.0076196081936359406",
   "Mixed": "7.282508158823475e-05"
 },
 {
   "tweet_id": "1590040445249548289",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040416854081536",
   "Positive": "0.15118426084518433",
   "Negative": "0.033800531178712845",
   "Neutral": "0.8125418424606323",
   "Mixed": "0.002473415108397603"
 },
 {
   "tweet_id": "1590040408868126722",
   "Positive": "0.0009433111408725381",
   "Negative": "0.0003716358041856438",
   "Neutral": "0.9986820816993713",
   "Mixed": "2.953611556222313e-06"
 },
 {
   "tweet_id": "1590040397388353536",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040377813504003",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1590040364412698624",
   "Positive": "0.40326249599456787",
   "Negative": "0.00029000546783208847",
   "Neutral": "0.5962043404579163",
   "Mixed": "0.0002431064931442961"
 },
 {
   "tweet_id": "1590040359651811328",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040354702909440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040351641071616",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590040344938545152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040344397504513",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040342782709760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040338940702721",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040317524602881",
   "Positive": "0.9906468391418457",
   "Negative": "0.00011194206308573484",
   "Neutral": "0.0091716842725873",
   "Mixed": "6.956403376534581e-05"
 },
 {
   "tweet_id": "1590040303884722176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040293541232640",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590040292438466562",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040281759748096",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040261060874240",
   "Positive": "0.1781005561351776",
   "Negative": "0.011882762424647808",
   "Neutral": "0.8098689317703247",
   "Mixed": "0.0001477163314120844"
 },
 {
   "tweet_id": "1590040254857510912",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590040251711770625",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590040250046611456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590040239888031744",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040234527723522",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590040207327305728",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040190047092738",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040180148224000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040179095785472",
   "Positive": "0.9970021843910217",
   "Negative": "7.577164069516584e-05",
   "Neutral": "0.0029092037584632635",
   "Mixed": "1.2811157830583397e-05"
 },
 {
   "tweet_id": "1590040160145911808",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040141032476672",
   "Positive": "0.11716773360967636",
   "Negative": "0.0007177333463914692",
   "Neutral": "0.8820987939834595",
   "Mixed": "1.572819019202143e-05"
 },
 {
   "tweet_id": "1590040125270286336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040117280112640",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590040101173985280",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040100683272192",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590040076553052163",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040075173498880",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1590040072602419201",
   "Positive": "0.9662799835205078",
   "Negative": "0.00022254075156524777",
   "Neutral": "0.033474672585725784",
   "Mixed": "2.2751602955395356e-05"
 },
 {
   "tweet_id": "1590040070719176706",
   "Positive": "0.46103230118751526",
   "Negative": "0.028267722576856613",
   "Neutral": "0.5105900764465332",
   "Mixed": "0.00010986428242176771"
 },
 {
   "tweet_id": "1590040066772332545",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590040063936954368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040053551886336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590040044173430785",
   "Positive": "0.016317199915647507",
   "Negative": "0.013639422133564949",
   "Neutral": "0.969997227191925",
   "Mixed": "4.6169054257916287e-05"
 },
 {
   "tweet_id": "1590040037218926592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590040028297965569",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590040018910728192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590040015715045376",
   "Positive": "0.46103230118751526",
   "Negative": "0.028267722576856613",
   "Neutral": "0.5105900764465332",
   "Mixed": "0.00010986428242176771"
 },
 {
   "tweet_id": "1590040006760239105",
   "Positive": "0.22623011469841003",
   "Negative": "0.0028601149097085",
   "Neutral": "0.7705769538879395",
   "Mixed": "0.0003328115853946656"
 },
 {
   "tweet_id": "1590040000590401536",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590039986879238145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039986505940992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039982101893120",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590039980365471744",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590039979572736000",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039972324990977",
   "Positive": "0.46103230118751526",
   "Negative": "0.028267722576856613",
   "Neutral": "0.5105900764465332",
   "Mixed": "0.00010986428242176771"
 },
 {
   "tweet_id": "1590039951705399297",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039943040339968",
   "Positive": "0.9692919254302979",
   "Negative": "0.00025775679387152195",
   "Neutral": "0.030423158779740334",
   "Mixed": "2.7176876756129786e-05"
 },
 {
   "tweet_id": "1590039939206774784",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590039936035540992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590039908739321858",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590039898446516226",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039896101572608",
   "Positive": "0.36830732226371765",
   "Negative": "0.054560594260692596",
   "Neutral": "0.36152711510658264",
   "Mixed": "0.2156049758195877"
 },
 {
   "tweet_id": "1590039890796122117",
   "Positive": "0.06407372653484344",
   "Negative": "0.009567012079060078",
   "Neutral": "0.9263068437576294",
   "Mixed": "5.239820893621072e-05"
 },
 {
   "tweet_id": "1590039870424379392",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590039867572224002",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039863034007552",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039860420956161",
   "Positive": "0.847112238407135",
   "Negative": "0.0009061880991794169",
   "Neutral": "0.15170547366142273",
   "Mixed": "0.0002760132774710655"
 },
 {
   "tweet_id": "1590039842435760128",
   "Positive": "0.051588915288448334",
   "Negative": "0.009028700180351734",
   "Neutral": "0.9393342733383179",
   "Mixed": "4.817667286260985e-05"
 },
 {
   "tweet_id": "1590039837234835457",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039827734749184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039820759597056",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590039819669110785",
   "Positive": "0.9929068088531494",
   "Negative": "0.00010888424003496766",
   "Neutral": "0.006922252010554075",
   "Mixed": "6.204149394761771e-05"
 },
 {
   "tweet_id": "1590039818410819584",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590039802778619905",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039799355699201",
   "Positive": "0.0707101970911026",
   "Negative": "0.001769882976077497",
   "Neutral": "0.9274381995201111",
   "Mixed": "8.172211528290063e-05"
 },
 {
   "tweet_id": "1590039782784393216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039778661367808",
   "Positive": "0.06407372653484344",
   "Negative": "0.009567012079060078",
   "Neutral": "0.9263068437576294",
   "Mixed": "5.239820893621072e-05"
 },
 {
   "tweet_id": "1590039772265091072",
   "Positive": "0.001481543411500752",
   "Negative": "0.9152039885520935",
   "Neutral": "0.08198551833629608",
   "Mixed": "0.0013289084890857339"
 },
 {
   "tweet_id": "1590039767907196928",
   "Positive": "0.8926764130592346",
   "Negative": "0.0013927910476922989",
   "Neutral": "0.10587851703166962",
   "Mixed": "5.233539195614867e-05"
 },
 {
   "tweet_id": "1590039761565396993",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590039747552243713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039747048910850",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039746033897472",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590039745253765120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039743282450432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039736554762242",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039733912338433",
   "Positive": "0.06407372653484344",
   "Negative": "0.009567012079060078",
   "Neutral": "0.9263068437576294",
   "Mixed": "5.239820893621072e-05"
 },
 {
   "tweet_id": "1590039712986968064",
   "Positive": "0.9921938180923462",
   "Negative": "0.00011377867485862225",
   "Neutral": "0.0076196081936359406",
   "Mixed": "7.282508158823475e-05"
 },
 {
   "tweet_id": "1590039700534079488",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039697954607105",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590039695160770560",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590039692577501184",
   "Positive": "0.006171129178255796",
   "Negative": "0.0006831256323494017",
   "Neutral": "0.9931433200836182",
   "Mixed": "2.341041181352921e-06"
 },
 {
   "tweet_id": "1590039686487023616",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039684021104640",
   "Positive": "0.30011215806007385",
   "Negative": "0.016781507059931755",
   "Neutral": "0.6819159388542175",
   "Mixed": "0.0011903359554708004"
 },
 {
   "tweet_id": "1590039676911394817",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039667331989504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039659278917632",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039658284843009",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590039645899100161",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039640316125184",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590039634155012098",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039630123978752",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590039629201543168",
   "Positive": "0.035349149256944656",
   "Negative": "0.5447245240211487",
   "Neutral": "0.4187723696231842",
   "Mixed": "0.0011540304403752089"
 },
 {
   "tweet_id": "1590039628853440512",
   "Positive": "0.06407372653484344",
   "Negative": "0.009567012079060078",
   "Neutral": "0.9263068437576294",
   "Mixed": "5.239820893621072e-05"
 },
 {
   "tweet_id": "1590039609928736768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039609261817857",
   "Positive": "0.9929068088531494",
   "Negative": "0.00010888424003496766",
   "Neutral": "0.006922252010554075",
   "Mixed": "6.204149394761771e-05"
 },
 {
   "tweet_id": "1590039600269254657",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039591129870336",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590039588521005057",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039587577286656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590039585786310656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039583646810112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039550021500928",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590039546724745216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039541087600642",
   "Positive": "0.8518661260604858",
   "Negative": "0.001170507981441915",
   "Neutral": "0.1468915194272995",
   "Mixed": "7.185069989645854e-05"
 },
 {
   "tweet_id": "1590039527862996992",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590039524935360512",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039523937112064",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590039522863038464",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039506325237760",
   "Positive": "0.931474506855011",
   "Negative": "0.0006980071193538606",
   "Neutral": "0.06727740168571472",
   "Mixed": "0.0005500856204889715"
 },
 {
   "tweet_id": "1590039500469964801",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590039490659487745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039490433003521",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590039486020608000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039482560282625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039481922772994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039480542842885",
   "Positive": "0.0004602496628649533",
   "Negative": "0.01159658096730709",
   "Neutral": "0.987930417060852",
   "Mixed": "1.273445650440408e-05"
 },
 {
   "tweet_id": "1590039478001098755",
   "Positive": "0.8544403910636902",
   "Negative": "0.000953600334469229",
   "Neutral": "0.14451631903648376",
   "Mixed": "8.9654975454323e-05"
 },
 {
   "tweet_id": "1590039471093067776",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1590039468618022912",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590039440185249792",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590039433822498818",
   "Positive": "0.8217718005180359",
   "Negative": "0.0016994677716866136",
   "Neutral": "0.17650668323040009",
   "Mixed": "2.2096284737926908e-05"
 },
 {
   "tweet_id": "1590039430437687297",
   "Positive": "0.8518661260604858",
   "Negative": "0.001170507981441915",
   "Neutral": "0.1468915194272995",
   "Mixed": "7.185069989645854e-05"
 },
 {
   "tweet_id": "1590039413081317376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039409076088832",
   "Positive": "0.7259858250617981",
   "Negative": "0.000420347903855145",
   "Neutral": "0.2734859585762024",
   "Mixed": "0.000107871979707852"
 },
 {
   "tweet_id": "1590039404663697408",
   "Positive": "0.0011430960148572922",
   "Negative": "0.8091322183609009",
   "Neutral": "0.18889445066452026",
   "Mixed": "0.0008302490459755063"
 },
 {
   "tweet_id": "1590039398514851842",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039393452322816",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039371830693888",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039368387145728",
   "Positive": "0.8518661260604858",
   "Negative": "0.001170507981441915",
   "Neutral": "0.1468915194272995",
   "Mixed": "7.185069989645854e-05"
 },
 {
   "tweet_id": "1590039362959736832",
   "Positive": "0.0012308162404224277",
   "Negative": "0.9276316165924072",
   "Neutral": "0.07089265435934067",
   "Mixed": "0.00024500509607605636"
 },
 {
   "tweet_id": "1590039343045173249",
   "Positive": "0.0009514238336123526",
   "Negative": "0.9029752016067505",
   "Neutral": "0.0958007425069809",
   "Mixed": "0.00027259765192866325"
 },
 {
   "tweet_id": "1590039342638313472",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590039340151099393",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039320140079104",
   "Positive": "0.8544403910636902",
   "Negative": "0.000953600334469229",
   "Neutral": "0.14451631903648376",
   "Mixed": "8.9654975454323e-05"
 },
 {
   "tweet_id": "1590039319280234497",
   "Positive": "0.4625561833381653",
   "Negative": "0.0008612702367827296",
   "Neutral": "0.005457159131765366",
   "Mixed": "0.5311253666877747"
 },
 {
   "tweet_id": "1590039292231180289",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039284740165632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039284689821696",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590039272211771392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039271154802689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039269208645632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039267450855424",
   "Positive": "0.0013584368862211704",
   "Negative": "0.9221932291984558",
   "Neutral": "0.0497426837682724",
   "Mixed": "0.02670561708509922"
 },
 {
   "tweet_id": "1590039256059498497",
   "Positive": "0.1252674013376236",
   "Negative": "0.004850326105952263",
   "Neutral": "0.8696815371513367",
   "Mixed": "0.00020072335610166192"
 },
 {
   "tweet_id": "1590039252410458114",
   "Positive": "0.9725739359855652",
   "Negative": "0.00014872355677653104",
   "Neutral": "0.027235250920057297",
   "Mixed": "4.202452691970393e-05"
 },
 {
   "tweet_id": "1590039209041354752",
   "Positive": "0.0004602496628649533",
   "Negative": "0.01159658096730709",
   "Neutral": "0.987930417060852",
   "Mixed": "1.273445650440408e-05"
 },
 {
   "tweet_id": "1590039198425579560",
   "Positive": "0.9931114912033081",
   "Negative": "9.263651008950546e-05",
   "Neutral": "0.006741905584931374",
   "Mixed": "5.4064705182099715e-05"
 },
 {
   "tweet_id": "1590039188300509184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039181417644035",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039178422939648",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590039178141913089",
   "Positive": "0.009938887320458889",
   "Negative": "0.060186270624399185",
   "Neutral": "0.07060810178518295",
   "Mixed": "0.8592667579650879"
 },
 {
   "tweet_id": "1590039138493157376",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039138207559680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039122605137920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039122177314817",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039108113817602",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590039104389255168",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590039099209310209",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039092234182656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039078010904576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590039075360501761",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590039073603063808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039063746191364",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590039057132040192",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590039045765464064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039042682675200",
   "Positive": "0.9906951189041138",
   "Negative": "0.00015441981668118387",
   "Neutral": "0.009064844809472561",
   "Mixed": "8.554663509130478e-05"
 },
 {
   "tweet_id": "1590039042485518336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590039034755436544",
   "Positive": "0.0014044707641005516",
   "Negative": "0.49455007910728455",
   "Neutral": "0.503394603729248",
   "Mixed": "0.0006509280065074563"
 },
 {
   "tweet_id": "1590039034419892224",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590039024408100864",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590039024064167936",
   "Positive": "0.0017884888220578432",
   "Negative": "0.4072745740413666",
   "Neutral": "0.590729296207428",
   "Mixed": "0.00020763487555086613"
 },
 {
   "tweet_id": "1590039020759048192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590039010734243840",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590039001523978247",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590038986432868352",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038963972362242",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038957139857408",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038953490780160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038950399250433",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590038942107467776",
   "Positive": "0.9970021843910217",
   "Negative": "7.577164069516584e-05",
   "Neutral": "0.0029092037584632635",
   "Mixed": "1.2811157830583397e-05"
 },
 {
   "tweet_id": "1590038929767813123",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038916156882945",
   "Positive": "0.028813600540161133",
   "Negative": "0.048008210957050323",
   "Neutral": "0.1561102718114853",
   "Mixed": "0.7670679092407227"
 },
 {
   "tweet_id": "1590038913892380672",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590038912118185984",
   "Positive": "0.00015818070096429437",
   "Negative": "0.9978340268135071",
   "Neutral": "0.001996764447540045",
   "Mixed": "1.1066032129747327e-05"
 },
 {
   "tweet_id": "1590038907496067072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038905402773505",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038898599940096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038891041783808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038890529705984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038886893334528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038878505037824",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590038874289758208",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590038860696018944",
   "Positive": "0.4719850420951843",
   "Negative": "0.024818727746605873",
   "Neutral": "0.5030837655067444",
   "Mixed": "0.00011249089584453031"
 },
 {
   "tweet_id": "1590038856971485184",
   "Positive": "0.1598367542028427",
   "Negative": "0.0022845566272735596",
   "Neutral": "0.8211441040039062",
   "Mixed": "0.016734572127461433"
 },
 {
   "tweet_id": "1590038830681243648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038818677481473",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590038816831967232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038814000832514",
   "Positive": "0.4719850420951843",
   "Negative": "0.024818727746605873",
   "Neutral": "0.5030837655067444",
   "Mixed": "0.00011249089584453031"
 },
 {
   "tweet_id": "1590038808346513408",
   "Positive": "0.007986792363226414",
   "Negative": "0.811818540096283",
   "Neutral": "0.10865994542837143",
   "Mixed": "0.07153472304344177"
 },
 {
   "tweet_id": "1590038805226348551",
   "Positive": "0.01639854721724987",
   "Negative": "0.00015717896167188883",
   "Neutral": "0.9834348559379578",
   "Mixed": "9.387665159010794e-06"
 },
 {
   "tweet_id": "1590038801719885826",
   "Positive": "0.7094627618789673",
   "Negative": "0.0030791067983955145",
   "Neutral": "0.2873896360397339",
   "Mixed": "6.852178921690211e-05"
 },
 {
   "tweet_id": "1590038798263799809",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590038788516237312",
   "Positive": "0.13061228394508362",
   "Negative": "0.007368443999439478",
   "Neutral": "0.8619905710220337",
   "Mixed": "2.8643344194279052e-05"
 },
 {
   "tweet_id": "1590038756794724352",
   "Positive": "0.9397326707839966",
   "Negative": "0.00020725098147522658",
   "Neutral": "0.05989287421107292",
   "Mixed": "0.00016721307474654168"
 },
 {
   "tweet_id": "1590038750486462464",
   "Positive": "0.4383114278316498",
   "Negative": "0.02300262823700905",
   "Neutral": "0.5385780334472656",
   "Mixed": "0.00010789422231027856"
 },
 {
   "tweet_id": "1590038740344647684",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590038740235616256",
   "Positive": "0.006245243363082409",
   "Negative": "0.832295835018158",
   "Neutral": "0.15189975500106812",
   "Mixed": "0.009559232741594315"
 },
 {
   "tweet_id": "1590038732048314370",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038702117769216",
   "Positive": "0.4356355369091034",
   "Negative": "0.025311613455414772",
   "Neutral": "0.5389020442962646",
   "Mixed": "0.00015076306590344757"
 },
 {
   "tweet_id": "1590038679778906112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038678679977984",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038671163817989",
   "Positive": "0.16239245235919952",
   "Negative": "0.0002725430531427264",
   "Neutral": "0.8372431397438049",
   "Mixed": "9.185937233269215e-05"
 },
 {
   "tweet_id": "1590038667200167936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038661185536001",
   "Positive": "0.9848869442939758",
   "Negative": "0.00022293558868113905",
   "Neutral": "0.014810149557888508",
   "Mixed": "7.993706094566733e-05"
 },
 {
   "tweet_id": "1590038651760955392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038651547049984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038643884060672",
   "Positive": "0.0423709861934185",
   "Negative": "0.0038894980680197477",
   "Neutral": "0.9537209272384644",
   "Mixed": "1.850851549534127e-05"
 },
 {
   "tweet_id": "1590038640935129088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038617036292096",
   "Positive": "0.0017674039117991924",
   "Negative": "0.00025068552349694073",
   "Neutral": "0.9979792237281799",
   "Mixed": "2.773671440081671e-06"
 },
 {
   "tweet_id": "1590038601697734656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038601462874113",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038600745639937",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038594122813440",
   "Positive": "0.4719850420951843",
   "Negative": "0.024818727746605873",
   "Neutral": "0.5030837655067444",
   "Mixed": "0.00011249089584453031"
 },
 {
   "tweet_id": "1590038594026360832",
   "Positive": "0.08833809942007065",
   "Negative": "0.0013683517463505268",
   "Neutral": "0.9102262854576111",
   "Mixed": "6.732556357746944e-05"
 },
 {
   "tweet_id": "1590038593393004546",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038585583226880",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590038559687593985",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590038558341201921",
   "Positive": "0.8921368718147278",
   "Negative": "0.0010946894763037562",
   "Neutral": "0.10588831454515457",
   "Mixed": "0.0008801145013421774"
 },
 {
   "tweet_id": "1590038549793239041",
   "Positive": "0.4664366543292999",
   "Negative": "0.0007784287445247173",
   "Neutral": "0.5326606035232544",
   "Mixed": "0.00012424310261849314"
 },
 {
   "tweet_id": "1590038543945981958",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590038537701031939",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590038537449394177",
   "Positive": "0.4719850420951843",
   "Negative": "0.024818727746605873",
   "Neutral": "0.5030837655067444",
   "Mixed": "0.00011249089584453031"
 },
 {
   "tweet_id": "1590038536107229185",
   "Positive": "0.008667387068271637",
   "Negative": "0.0021976714488118887",
   "Neutral": "0.9891267418861389",
   "Mixed": "8.147891094267834e-06"
 },
 {
   "tweet_id": "1590038518017175552",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590038513357312000",
   "Positive": "0.08833809942007065",
   "Negative": "0.0013683517463505268",
   "Neutral": "0.9102262854576111",
   "Mixed": "6.732556357746944e-05"
 },
 {
   "tweet_id": "1590038488254382081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038484404035590",
   "Positive": "0.15216027200222015",
   "Negative": "0.02160489931702614",
   "Neutral": "0.8261944055557251",
   "Mixed": "4.03846352128312e-05"
 },
 {
   "tweet_id": "1590038468063035393",
   "Positive": "0.36894431710243225",
   "Negative": "0.01984298788011074",
   "Neutral": "0.6111568212509155",
   "Mixed": "5.5890519433887675e-05"
 },
 {
   "tweet_id": "1590038461339561984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038456335421440",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038455462993923",
   "Positive": "0.0017884888220578432",
   "Negative": "0.4072745740413666",
   "Neutral": "0.590729296207428",
   "Mixed": "0.00020763487555086613"
 },
 {
   "tweet_id": "1590038454607695872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038452950925313",
   "Positive": "0.09752553701400757",
   "Negative": "0.014917351305484772",
   "Neutral": "0.8875276446342468",
   "Mixed": "2.9444963729474694e-05"
 },
 {
   "tweet_id": "1590038427990622208",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590038413708623873",
   "Positive": "0.0538569875061512",
   "Negative": "0.00399582227692008",
   "Neutral": "0.9421345591545105",
   "Mixed": "1.2612304999493062e-05"
 },
 {
   "tweet_id": "1590038402254393346",
   "Positive": "0.9091905951499939",
   "Negative": "0.0002377713390160352",
   "Neutral": "0.09042513370513916",
   "Mixed": "0.0001464819215470925"
 },
 {
   "tweet_id": "1590038395538939904",
   "Positive": "0.00040697559597902",
   "Negative": "0.9795464873313904",
   "Neutral": "0.017827020958065987",
   "Mixed": "0.0022194834891706705"
 },
 {
   "tweet_id": "1590038382159499264",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038367747526657",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038366263087104",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038362744049664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038356150276096",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590038354166706176",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590038349930434562",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038344456876032",
   "Positive": "0.9848869442939758",
   "Negative": "0.00022293558868113905",
   "Neutral": "0.014810149557888508",
   "Mixed": "7.993706094566733e-05"
 },
 {
   "tweet_id": "1590038343357960192",
   "Positive": "0.0033306414261460304",
   "Negative": "0.00010593041224638",
   "Neutral": "0.9965583682060242",
   "Mixed": "5.024262009101221e-06"
 },
 {
   "tweet_id": "1590038342598791171",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590038337901178880",
   "Positive": "0.7216961979866028",
   "Negative": "0.00027487409533932805",
   "Neutral": "0.2778659164905548",
   "Mixed": "0.00016309462080243975"
 },
 {
   "tweet_id": "1590038322315157505",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038309929357312",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590038308775956480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038302241198081",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038301419122688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038295144443904",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038293726793729",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038293701615617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038284352520194",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038283748540416",
   "Positive": "0.0029745418578386307",
   "Negative": "0.44717836380004883",
   "Neutral": "0.5496871471405029",
   "Mixed": "0.000160000185132958"
 },
 {
   "tweet_id": "1590038283299753984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038282292801536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038280107872257",
   "Positive": "0.9299488067626953",
   "Negative": "0.000893893651664257",
   "Neutral": "0.06910336762666702",
   "Mixed": "5.39694337930996e-05"
 },
 {
   "tweet_id": "1590038267621437442",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038256544280577",
   "Positive": "0.9839782118797302",
   "Negative": "0.00012669037096202374",
   "Neutral": "0.015816841274499893",
   "Mixed": "7.828544767107815e-05"
 },
 {
   "tweet_id": "1590038252823937024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038248461856769",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038241574793216",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590038228949606400",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038225024086017",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590038222830465025",
   "Positive": "0.49547117948532104",
   "Negative": "0.0005955130327492952",
   "Neutral": "0.5038646459579468",
   "Mixed": "6.86632192810066e-05"
 },
 {
   "tweet_id": "1590038186394521601",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590038177871724545",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038176080756736",
   "Positive": "0.4920700192451477",
   "Negative": "0.03948330134153366",
   "Neutral": "0.41852572560310364",
   "Mixed": "0.049920935183763504"
 },
 {
   "tweet_id": "1590038173895495681",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038169860575232",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038152315834369",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038150641897473",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590038142874431488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038140819214336",
   "Positive": "0.13770833611488342",
   "Negative": "0.0005834323819726706",
   "Neutral": "0.8616830110549927",
   "Mixed": "2.530863093852531e-05"
 },
 {
   "tweet_id": "1590038139498033152",
   "Positive": "0.8871302604675293",
   "Negative": "0.0007190690375864506",
   "Neutral": "0.11195575445890427",
   "Mixed": "0.0001950120786204934"
 },
 {
   "tweet_id": "1590038139191853056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038133332004864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590038128546709505",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590038125254184961",
   "Positive": "0.051153477281332016",
   "Negative": "0.052796464413404465",
   "Neutral": "0.8959669470787048",
   "Mixed": "8.30871649668552e-05"
 },
 {
   "tweet_id": "1590038121617723392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038103779323904",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038098410602497",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590038090990915585",
   "Positive": "0.004028306808322668",
   "Negative": "0.0020411398727446795",
   "Neutral": "0.9938779473304749",
   "Mixed": "5.2611969294957817e-05"
 },
 {
   "tweet_id": "1590038090898608129",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590038087249580032",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590038084049326080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038080257658886",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038075396145152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038049400197120",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590038030026694656",
   "Positive": "0.40325799584388733",
   "Negative": "0.0001846806553658098",
   "Neutral": "0.5964974164962769",
   "Mixed": "5.9924273955402896e-05"
 },
 {
   "tweet_id": "1590038029003259905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038014709104640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590038012532232193",
   "Positive": "0.01929582841694355",
   "Negative": "0.20892298221588135",
   "Neutral": "0.7715383768081665",
   "Mixed": "0.000242773094214499"
 },
 {
   "tweet_id": "1590038004311027713",
   "Positive": "0.008874969556927681",
   "Negative": "0.5321164727210999",
   "Neutral": "0.3865503668785095",
   "Mixed": "0.07245814800262451"
 },
 {
   "tweet_id": "1590037999546675202",
   "Positive": "0.05201859027147293",
   "Negative": "0.7486106753349304",
   "Neutral": "0.19932010769844055",
   "Mixed": "5.056255031377077e-05"
 },
 {
   "tweet_id": "1590037969704222722",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037966201946112",
   "Positive": "0.007601926568895578",
   "Negative": "0.00039476732490584254",
   "Neutral": "0.9919648170471191",
   "Mixed": "3.837817348539829e-05"
 },
 {
   "tweet_id": "1590037965883215873",
   "Positive": "0.0046958522871136665",
   "Negative": "0.019303707405924797",
   "Neutral": "0.9759934544563293",
   "Mixed": "6.968444267840823e-06"
 },
 {
   "tweet_id": "1590037963224027137",
   "Positive": "0.31324490904808044",
   "Negative": "0.0003049780789297074",
   "Neutral": "0.6864180564880371",
   "Mixed": "3.2038940844358876e-05"
 },
 {
   "tweet_id": "1590037956865445890",
   "Positive": "0.03308717906475067",
   "Negative": "0.00036915999953635037",
   "Neutral": "0.9665349721908569",
   "Mixed": "8.787723345449194e-06"
 },
 {
   "tweet_id": "1590037949453770752",
   "Positive": "0.00894252024590969",
   "Negative": "0.5267696380615234",
   "Neutral": "0.1344471573829651",
   "Mixed": "0.329840749502182"
 },
 {
   "tweet_id": "1590037948468432896",
   "Positive": "0.22515909373760223",
   "Negative": "0.011563081294298172",
   "Neutral": "0.7631601691246033",
   "Mixed": "0.00011765083036152646"
 },
 {
   "tweet_id": "1590037944387371009",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037938536345600",
   "Positive": "0.8800796866416931",
   "Negative": "0.0005997951375320554",
   "Neutral": "0.1191008910536766",
   "Mixed": "0.00021963864855933934"
 },
 {
   "tweet_id": "1590037924493791232",
   "Positive": "0.062137700617313385",
   "Negative": "0.15760166943073273",
   "Neutral": "0.7465420365333557",
   "Mixed": "0.03371847793459892"
 },
 {
   "tweet_id": "1590037923134836739",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590037921327108097",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590037920928665600",
   "Positive": "0.015591840259730816",
   "Negative": "0.19831837713718414",
   "Neutral": "0.07924877107143402",
   "Mixed": "0.706840991973877"
 },
 {
   "tweet_id": "1590037919385161729",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590037912418390016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037911894110209",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590037891694342144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037889269768196",
   "Positive": "0.01929582841694355",
   "Negative": "0.20892298221588135",
   "Neutral": "0.7715383768081665",
   "Mixed": "0.000242773094214499"
 },
 {
   "tweet_id": "1590037875865063427",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590037874929696768",
   "Positive": "0.05512942746281624",
   "Negative": "0.0007354968693107367",
   "Neutral": "0.9441000819206238",
   "Mixed": "3.500252933008596e-05"
 },
 {
   "tweet_id": "1590037866037489664",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037860400648192",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037859385626625",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037840372858880",
   "Positive": "0.07483336329460144",
   "Negative": "0.004177033435553312",
   "Neutral": "0.9209644198417664",
   "Mixed": "2.5289349650847726e-05"
 },
 {
   "tweet_id": "1590037832646942720",
   "Positive": "0.0017706470098346472",
   "Negative": "0.4977757930755615",
   "Neutral": "0.5002111792564392",
   "Mixed": "0.0002423374680802226"
 },
 {
   "tweet_id": "1590037828356177922",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590037827894784000",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1590037810018279425",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590037793681588231",
   "Positive": "0.29816576838493347",
   "Negative": "0.0012170353438705206",
   "Neutral": "0.7005342245101929",
   "Mixed": "8.299032197101042e-05"
 },
 {
   "tweet_id": "1590037783275765760",
   "Positive": "0.13750575482845306",
   "Negative": "0.0009151787380687892",
   "Neutral": "0.8615095615386963",
   "Mixed": "6.95068301865831e-05"
 },
 {
   "tweet_id": "1590037767735906305",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590037767203192833",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590037762853736449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037762572709889",
   "Positive": "0.03308717906475067",
   "Negative": "0.00036915999953635037",
   "Neutral": "0.9665349721908569",
   "Mixed": "8.787723345449194e-06"
 },
 {
   "tweet_id": "1590037747917787142",
   "Positive": "0.039003338664770126",
   "Negative": "0.05115272104740143",
   "Neutral": "0.9097822308540344",
   "Mixed": "6.176328315632418e-05"
 },
 {
   "tweet_id": "1590037734584090625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037732369502208",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590037730901491712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037715114135555",
   "Positive": "0.9613953232765198",
   "Negative": "0.00021986794308759272",
   "Neutral": "0.03832865133881569",
   "Mixed": "5.618463910650462e-05"
 },
 {
   "tweet_id": "1590037714493009920",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1590037712983461888",
   "Positive": "0.08457978814840317",
   "Negative": "0.04887469857931137",
   "Neutral": "0.8665210008621216",
   "Mixed": "2.4487679183948785e-05"
 },
 {
   "tweet_id": "1590037701960826880",
   "Positive": "0.002320882398635149",
   "Negative": "8.940274710766971e-05",
   "Neutral": "0.9975777268409729",
   "Mixed": "1.1980073395534419e-05"
 },
 {
   "tweet_id": "1590037701251956736",
   "Positive": "0.03308717906475067",
   "Negative": "0.00036915999953635037",
   "Neutral": "0.9665349721908569",
   "Mixed": "8.787723345449194e-06"
 },
 {
   "tweet_id": "1590037681043832832",
   "Positive": "0.0044359066523611546",
   "Negative": "0.6368743181228638",
   "Neutral": "0.3583126366138458",
   "Mixed": "0.00037711550248786807"
 },
 {
   "tweet_id": "1590037680901226496",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590037677084401664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037665432600577",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590037661062164487",
   "Positive": "0.026464221999049187",
   "Negative": "0.054380785673856735",
   "Neutral": "0.9099923372268677",
   "Mixed": "0.009162569418549538"
 },
 {
   "tweet_id": "1590037655349530625",
   "Positive": "0.02882271818816662",
   "Negative": "0.0001816208241507411",
   "Neutral": "0.9709826111793518",
   "Mixed": "1.3117380149196833e-05"
 },
 {
   "tweet_id": "1590037643647389696",
   "Positive": "0.1393338292837143",
   "Negative": "0.0003745052090380341",
   "Neutral": "0.8602586984634399",
   "Mixed": "3.2886302506085485e-05"
 },
 {
   "tweet_id": "1590037622470344705",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037606515224576",
   "Positive": "0.37144935131073",
   "Negative": "0.007945267483592033",
   "Neutral": "0.6205589771270752",
   "Mixed": "4.641162740881555e-05"
 },
 {
   "tweet_id": "1590037581278121985",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590037573011148800",
   "Positive": "0.281137615442276",
   "Negative": "0.030279193073511124",
   "Neutral": "0.6885284781455994",
   "Mixed": "5.475837315316312e-05"
 },
 {
   "tweet_id": "1590037561971732480",
   "Positive": "0.46662062406539917",
   "Negative": "0.0023654308170080185",
   "Neutral": "0.5306675434112549",
   "Mixed": "0.0003464284527581185"
 },
 {
   "tweet_id": "1590037552945594369",
   "Positive": "0.3398634195327759",
   "Negative": "0.007735345512628555",
   "Neutral": "0.6522868275642395",
   "Mixed": "0.00011438559886300936"
 },
 {
   "tweet_id": "1590037519361798150",
   "Positive": "0.8355674743652344",
   "Negative": "0.0003390692872926593",
   "Neutral": "0.16399981081485748",
   "Mixed": "9.359731484437361e-05"
 },
 {
   "tweet_id": "1590037519013662721",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590037515402383362",
   "Positive": "0.47138187289237976",
   "Negative": "0.0011374172754585743",
   "Neutral": "0.5274131298065186",
   "Mixed": "6.751521141268313e-05"
 },
 {
   "tweet_id": "1590037505424130049",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1590037485304045569",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037480103084032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037478916096000",
   "Positive": "0.0330289825797081",
   "Negative": "0.00024568726075813174",
   "Neutral": "0.9667156338691711",
   "Mixed": "9.688765203463845e-06"
 },
 {
   "tweet_id": "1590037476345016320",
   "Positive": "0.8377882838249207",
   "Negative": "0.0009112149127759039",
   "Neutral": "0.15970003604888916",
   "Mixed": "0.0016004740027710795"
 },
 {
   "tweet_id": "1590037475019612160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037470237773824",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590037463506227200",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037448389963776",
   "Positive": "0.9397326707839966",
   "Negative": "0.00020725098147522658",
   "Neutral": "0.05989287421107292",
   "Mixed": "0.00016721307474654168"
 },
 {
   "tweet_id": "1590037433629843456",
   "Positive": "0.017403975129127502",
   "Negative": "0.7422136068344116",
   "Neutral": "0.24000944197177887",
   "Mixed": "0.00037301681004464626"
 },
 {
   "tweet_id": "1590037425161920512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037415866945536",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037409420701696",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037402281992192",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590037398473543681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037387677425665",
   "Positive": "0.02234439179301262",
   "Negative": "6.0794151067966595e-05",
   "Neutral": "0.9775455594062805",
   "Mixed": "4.9173857405548915e-05"
 },
 {
   "tweet_id": "1590037387278962688",
   "Positive": "0.3205885887145996",
   "Negative": "0.00020894152112305164",
   "Neutral": "0.6791378259658813",
   "Mixed": "6.459103315137327e-05"
 },
 {
   "tweet_id": "1590037379632750593",
   "Positive": "0.9848869442939758",
   "Negative": "0.00022293558868113905",
   "Neutral": "0.014810149557888508",
   "Mixed": "7.993706094566733e-05"
 },
 {
   "tweet_id": "1590037369214078977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037340311138311",
   "Positive": "0.37909331917762756",
   "Negative": "0.00022676053049508482",
   "Neutral": "0.6206425428390503",
   "Mixed": "3.735961581696756e-05"
 },
 {
   "tweet_id": "1590037333914816512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037328030228483",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590037314742648832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590037313543077888",
   "Positive": "0.9011315107345581",
   "Negative": "0.0008854292100295424",
   "Neutral": "0.09794314205646515",
   "Mixed": "3.994705912191421e-05"
 },
 {
   "tweet_id": "1590037313203351552",
   "Positive": "0.8377882838249207",
   "Negative": "0.0009112149127759039",
   "Neutral": "0.15970003604888916",
   "Mixed": "0.0016004740027710795"
 },
 {
   "tweet_id": "1590037297822830593",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590037280739438593",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590037277149126656",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590037272296321025",
   "Positive": "0.3377726674079895",
   "Negative": "0.0018151943804696202",
   "Neutral": "0.6602970361709595",
   "Mixed": "0.00011511709453770891"
 },
 {
   "tweet_id": "1590037253518413824",
   "Positive": "0.645616352558136",
   "Negative": "0.010406139306724072",
   "Neutral": "0.34284526109695435",
   "Mixed": "0.0011322401696816087"
 },
 {
   "tweet_id": "1590037250922131460",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590037247830921216",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590037234841194498",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037234425925632",
   "Positive": "0.9839782118797302",
   "Negative": "0.00012669037096202374",
   "Neutral": "0.015816841274499893",
   "Mixed": "7.828544767107815e-05"
 },
 {
   "tweet_id": "1590037228952375297",
   "Positive": "0.9857168197631836",
   "Negative": "0.00037192506715655327",
   "Neutral": "0.01381397619843483",
   "Mixed": "9.732762555358931e-05"
 },
 {
   "tweet_id": "1590037216423997441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037202196922369",
   "Positive": "0.001503290724940598",
   "Negative": "0.004159863106906414",
   "Neutral": "0.9943331480026245",
   "Mixed": "3.674622803373495e-06"
 },
 {
   "tweet_id": "1590037198765555713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037198057132032",
   "Positive": "0.06939531862735748",
   "Negative": "0.008436555974185467",
   "Neutral": "0.9221188426017761",
   "Mixed": "4.9309655878460035e-05"
 },
 {
   "tweet_id": "1590037192248037377",
   "Positive": "0.9741830229759216",
   "Negative": "0.0005260921316221356",
   "Neutral": "0.02527613937854767",
   "Mixed": "1.4751466551388148e-05"
 },
 {
   "tweet_id": "1590037190868086784",
   "Positive": "0.7995187044143677",
   "Negative": "0.0010064556263387203",
   "Neutral": "0.19765356183052063",
   "Mixed": "0.001821264624595642"
 },
 {
   "tweet_id": "1590037190360240128",
   "Positive": "0.026234399527311325",
   "Negative": "0.003428103867918253",
   "Neutral": "0.9703152179718018",
   "Mixed": "2.22946546273306e-05"
 },
 {
   "tweet_id": "1590037179241484288",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590037172576735234",
   "Positive": "0.7513924241065979",
   "Negative": "0.0003817584947682917",
   "Neutral": "0.2481425553560257",
   "Mixed": "8.324423833983019e-05"
 },
 {
   "tweet_id": "1590037169439412225",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590037169330085896",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1590037161294061569",
   "Positive": "0.05201859027147293",
   "Negative": "0.7486106753349304",
   "Neutral": "0.19932010769844055",
   "Mixed": "5.056255031377077e-05"
 },
 {
   "tweet_id": "1590037147603857408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037146135855105",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590037143803793408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590037132646658048",
   "Positive": "0.026234399527311325",
   "Negative": "0.003428103867918253",
   "Neutral": "0.9703152179718018",
   "Mixed": "2.22946546273306e-05"
 },
 {
   "tweet_id": "1590037125260800000",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590037122643525634",
   "Positive": "0.08458489179611206",
   "Negative": "0.0033475274685770273",
   "Neutral": "0.9119687676429749",
   "Mixed": "9.878597484203056e-05"
 },
 {
   "tweet_id": "1590037113843896320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590037107078467585",
   "Positive": "0.012405806221067905",
   "Negative": "0.6139054894447327",
   "Neutral": "0.3689113259315491",
   "Mixed": "0.004777349531650543"
 },
 {
   "tweet_id": "1590037106457726976",
   "Positive": "0.14526551961898804",
   "Negative": "0.030598578974604607",
   "Neutral": "0.8240435719490051",
   "Mixed": "9.235196921508759e-05"
 },
 {
   "tweet_id": "1590037095309266945",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590037078561423360",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590037064644722689",
   "Positive": "0.1684597134590149",
   "Negative": "0.00453600101172924",
   "Neutral": "0.8269811272621155",
   "Mixed": "2.314474477316253e-05"
 },
 {
   "tweet_id": "1590037058063831040",
   "Positive": "0.9162737727165222",
   "Negative": "0.00029547198209911585",
   "Neutral": "0.08338490128517151",
   "Mixed": "4.589591844705865e-05"
 },
 {
   "tweet_id": "1590037050149187584",
   "Positive": "0.026587674394249916",
   "Negative": "0.0006033243844285607",
   "Neutral": "0.9727426767349243",
   "Mixed": "6.628886330872774e-05"
 },
 {
   "tweet_id": "1590037048031088640",
   "Positive": "0.06690019369125366",
   "Negative": "0.012800630182027817",
   "Neutral": "0.9200475215911865",
   "Mixed": "0.000251629768172279"
 },
 {
   "tweet_id": "1590037029186064384",
   "Positive": "0.3398634195327759",
   "Negative": "0.007735345512628555",
   "Neutral": "0.6522868275642395",
   "Mixed": "0.00011438559886300936"
 },
 {
   "tweet_id": "1590037001872769024",
   "Positive": "0.040719617158174515",
   "Negative": "0.28126761317253113",
   "Neutral": "0.6237753033638",
   "Mixed": "0.054237399250268936"
 },
 {
   "tweet_id": "1590036984629985280",
   "Positive": "0.3398634195327759",
   "Negative": "0.007735345512628555",
   "Neutral": "0.6522868275642395",
   "Mixed": "0.00011438559886300936"
 },
 {
   "tweet_id": "1590036982167900161",
   "Positive": "0.040495749562978745",
   "Negative": "0.044588178396224976",
   "Neutral": "0.9148520827293396",
   "Mixed": "6.398852565325797e-05"
 },
 {
   "tweet_id": "1590036976920858633",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036965805953025",
   "Positive": "0.4920700192451477",
   "Negative": "0.03948330134153366",
   "Neutral": "0.41852572560310364",
   "Mixed": "0.049920935183763504"
 },
 {
   "tweet_id": "1590036963897135104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036959694446592",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590036958147141633",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590036942473039873",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036940208078848",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590036922092904449",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1590036917277851648",
   "Positive": "0.017470093443989754",
   "Negative": "0.00016785530897323042",
   "Neutral": "0.982353687286377",
   "Mixed": "8.257266927103046e-06"
 },
 {
   "tweet_id": "1590036903155273728",
   "Positive": "0.049707915633916855",
   "Negative": "0.003973755985498428",
   "Neutral": "0.9462950229644775",
   "Mixed": "2.335824137844611e-05"
 },
 {
   "tweet_id": "1590036896994177024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036891008913410",
   "Positive": "0.005465821363031864",
   "Negative": "0.0003169224946759641",
   "Neutral": "0.9938231706619263",
   "Mixed": "0.00039405212737619877"
 },
 {
   "tweet_id": "1590036883442372611",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036881710153728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036872570761219",
   "Positive": "0.10153762996196747",
   "Negative": "0.004432718735188246",
   "Neutral": "0.8939739465713501",
   "Mixed": "5.573864109464921e-05"
 },
 {
   "tweet_id": "1590036869789929472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036868942688256",
   "Positive": "0.07483336329460144",
   "Negative": "0.004177033435553312",
   "Neutral": "0.9209644198417664",
   "Mixed": "2.5289349650847726e-05"
 },
 {
   "tweet_id": "1590036857177636864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036843567153152",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036832372523008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036830933553152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036820825608192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036816480325632",
   "Positive": "0.40325799584388733",
   "Negative": "0.0001846806553658098",
   "Neutral": "0.5964974164962769",
   "Mixed": "5.9924273955402896e-05"
 },
 {
   "tweet_id": "1590036816006111233",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036794128887808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036782774890497",
   "Positive": "0.03225008398294449",
   "Negative": "0.0002622564497869462",
   "Neutral": "0.9674541354179382",
   "Mixed": "3.360783739481121e-05"
 },
 {
   "tweet_id": "1590036775493586945",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590036772268167168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036770598490112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036770468823042",
   "Positive": "0.018510127440094948",
   "Negative": "0.009154174476861954",
   "Neutral": "0.9723268747329712",
   "Mixed": "8.765791790210642e-06"
 },
 {
   "tweet_id": "1590036764403863552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036759785525248",
   "Positive": "0.017615729942917824",
   "Negative": "0.41048020124435425",
   "Neutral": "0.5303578972816467",
   "Mixed": "0.041546206921339035"
 },
 {
   "tweet_id": "1590036758548606976",
   "Positive": "0.08833809942007065",
   "Negative": "0.0013683517463505268",
   "Neutral": "0.9102262854576111",
   "Mixed": "6.732556357746944e-05"
 },
 {
   "tweet_id": "1590036745764376582",
   "Positive": "0.6993269324302673",
   "Negative": "0.0006643931264989078",
   "Neutral": "0.2991402745246887",
   "Mixed": "0.0008683113846927881"
 },
 {
   "tweet_id": "1590036729582714880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590036715292753921",
   "Positive": "0.0011674333363771439",
   "Negative": "0.00025920025655068457",
   "Neutral": "0.9985706806182861",
   "Mixed": "2.5997387638199143e-06"
 },
 {
   "tweet_id": "1590036703997493249",
   "Positive": "0.6541468501091003",
   "Negative": "0.0012537430739030242",
   "Neutral": "0.34454774856567383",
   "Mixed": "5.15767969773151e-05"
 },
 {
   "tweet_id": "1590036703905189890",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036694308642816",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590036690839928832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036681880915969",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036670896033796",
   "Positive": "0.8800796866416931",
   "Negative": "0.0005997951375320554",
   "Neutral": "0.1191008910536766",
   "Mixed": "0.00021963864855933934"
 },
 {
   "tweet_id": "1590036659680444418",
   "Positive": "0.00020208042406011373",
   "Negative": "0.0038272589445114136",
   "Neutral": "0.9959433674812317",
   "Mixed": "2.735343514359556e-05"
 },
 {
   "tweet_id": "1590036659248451584",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590036655301595137",
   "Positive": "0.08833809942007065",
   "Negative": "0.0013683517463505268",
   "Neutral": "0.9102262854576111",
   "Mixed": "6.732556357746944e-05"
 },
 {
   "tweet_id": "1590036647475056642",
   "Positive": "0.015325681306421757",
   "Negative": "0.00042779039358720183",
   "Neutral": "0.9838269352912903",
   "Mixed": "0.00041964580304920673"
 },
 {
   "tweet_id": "1590036637249310721",
   "Positive": "0.6993269324302673",
   "Negative": "0.0006643931264989078",
   "Neutral": "0.2991402745246887",
   "Mixed": "0.0008683113846927881"
 },
 {
   "tweet_id": "1590036636032987136",
   "Positive": "0.2420850396156311",
   "Negative": "0.001449479372240603",
   "Neutral": "0.7564380764961243",
   "Mixed": "2.7420572223491035e-05"
 },
 {
   "tweet_id": "1590036630421000193",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036623466848257",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036614449106946",
   "Positive": "0.2560219168663025",
   "Negative": "0.0013728626072406769",
   "Neutral": "0.7425753474235535",
   "Mixed": "2.9807906685164198e-05"
 },
 {
   "tweet_id": "1590036604454047744",
   "Positive": "0.12863901257514954",
   "Negative": "0.0009378156973980367",
   "Neutral": "0.8703618049621582",
   "Mixed": "6.128035602159798e-05"
 },
 {
   "tweet_id": "1590036593343352832",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590036591611088896",
   "Positive": "0.15282697975635529",
   "Negative": "0.04141634330153465",
   "Neutral": "0.8054960370063782",
   "Mixed": "0.00026062948745675385"
 },
 {
   "tweet_id": "1590036581897076737",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036573646905344",
   "Positive": "0.07811357825994492",
   "Negative": "0.00038749389932490885",
   "Neutral": "0.9214766025543213",
   "Mixed": "2.233425220765639e-05"
 },
 {
   "tweet_id": "1590036563949670400",
   "Positive": "0.053628500550985336",
   "Negative": "0.023948339745402336",
   "Neutral": "0.32413965463638306",
   "Mixed": "0.598283588886261"
 },
 {
   "tweet_id": "1590036554201944071",
   "Positive": "0.004420856945216656",
   "Negative": "0.010720651596784592",
   "Neutral": "0.9848408102989197",
   "Mixed": "1.7752247003954835e-05"
 },
 {
   "tweet_id": "1590036553136734210",
   "Positive": "0.4642861485481262",
   "Negative": "0.0047881705686450005",
   "Neutral": "0.5308099985122681",
   "Mixed": "0.00011564856686163694"
 },
 {
   "tweet_id": "1590036543028486145",
   "Positive": "0.9712095856666565",
   "Negative": "0.00021375015785451978",
   "Neutral": "0.028551140800118446",
   "Mixed": "2.5590536097297445e-05"
 },
 {
   "tweet_id": "1590036540188917760",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590036536061747200",
   "Positive": "0.12863901257514954",
   "Negative": "0.0009378156973980367",
   "Neutral": "0.8703618049621582",
   "Mixed": "6.128035602159798e-05"
 },
 {
   "tweet_id": "1590036530483335169",
   "Positive": "0.01792481727898121",
   "Negative": "0.0006533215055242181",
   "Neutral": "0.9814133644104004",
   "Mixed": "8.565325515519362e-06"
 },
 {
   "tweet_id": "1590036507515322368",
   "Positive": "0.07246461510658264",
   "Negative": "0.02550053782761097",
   "Neutral": "0.902006983757019",
   "Mixed": "2.7825861252495088e-05"
 },
 {
   "tweet_id": "1590036504109518850",
   "Positive": "0.8525013327598572",
   "Negative": "0.0014655503910034895",
   "Neutral": "0.14579015970230103",
   "Mixed": "0.00024301648954860866"
 },
 {
   "tweet_id": "1590036501319991296",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590036495271833600",
   "Positive": "0.5620341897010803",
   "Negative": "0.006216738373041153",
   "Neutral": "0.43169543147087097",
   "Mixed": "5.352548760129139e-05"
 },
 {
   "tweet_id": "1590036472161501184",
   "Positive": "0.0177213866263628",
   "Negative": "0.00028989050770178437",
   "Neutral": "0.9819808602333069",
   "Mixed": "7.849364010326099e-06"
 },
 {
   "tweet_id": "1590036467245813760",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036460958539779",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036445649326080",
   "Positive": "0.0034764001611620188",
   "Negative": "9.369068720843643e-05",
   "Neutral": "0.9964253306388855",
   "Mixed": "4.4831626837549265e-06"
 },
 {
   "tweet_id": "1590036444802056192",
   "Positive": "0.4642861485481262",
   "Negative": "0.0047881705686450005",
   "Neutral": "0.5308099985122681",
   "Mixed": "0.00011564856686163694"
 },
 {
   "tweet_id": "1590036444361691136",
   "Positive": "0.8273652791976929",
   "Negative": "0.0010915853781625628",
   "Neutral": "0.1714099943637848",
   "Mixed": "0.00013303887681104243"
 },
 {
   "tweet_id": "1590036426242265088",
   "Positive": "0.012926340103149414",
   "Negative": "0.24035555124282837",
   "Neutral": "0.7386488914489746",
   "Mixed": "0.00806923396885395"
 },
 {
   "tweet_id": "1590036419372027904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036419107749889",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590036415861387266",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036405384007680",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1590036384496353280",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036380138504192",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590036352305106946",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036348823830529",
   "Positive": "0.08769445866346359",
   "Negative": "0.0003495168057270348",
   "Neutral": "0.9119405150413513",
   "Mixed": "1.5547988368780352e-05"
 },
 {
   "tweet_id": "1590036334319927297",
   "Positive": "0.9652954936027527",
   "Negative": "0.0002280610060552135",
   "Neutral": "0.03444835543632507",
   "Mixed": "2.803543975460343e-05"
 },
 {
   "tweet_id": "1590036324291342336",
   "Positive": "0.08708219230175018",
   "Negative": "0.0026800676714628935",
   "Neutral": "0.910228967666626",
   "Mixed": "8.749574590183329e-06"
 },
 {
   "tweet_id": "1590036316422803456",
   "Positive": "0.4642861485481262",
   "Negative": "0.0047881705686450005",
   "Neutral": "0.5308099985122681",
   "Mixed": "0.00011564856686163694"
 },
 {
   "tweet_id": "1590036273217298432",
   "Positive": "0.9744545817375183",
   "Negative": "0.0002560198772698641",
   "Neutral": "0.025254597887396812",
   "Mixed": "3.483442560536787e-05"
 },
 {
   "tweet_id": "1590036256842723328",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590036248626106368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036236903022593",
   "Positive": "0.732982873916626",
   "Negative": "0.003719212720170617",
   "Neutral": "0.2627165913581848",
   "Mixed": "0.0005813412717543542"
 },
 {
   "tweet_id": "1590036230993252352",
   "Positive": "0.17557264864444733",
   "Negative": "0.04764822870492935",
   "Neutral": "0.77191162109375",
   "Mixed": "0.00486757280305028"
 },
 {
   "tweet_id": "1590036219001712641",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590036217437229057",
   "Positive": "0.023667966946959496",
   "Negative": "0.00020266750652808696",
   "Neutral": "0.9761213064193726",
   "Mixed": "8.062882443482522e-06"
 },
 {
   "tweet_id": "1590036214060830720",
   "Positive": "0.634733259677887",
   "Negative": "0.005324847064912319",
   "Neutral": "0.359907329082489",
   "Mixed": "3.461116284597665e-05"
 },
 {
   "tweet_id": "1590036209581293570",
   "Positive": "0.016924038529396057",
   "Negative": "0.0004060354840476066",
   "Neutral": "0.9824458360671997",
   "Mixed": "0.00022400562011171132"
 },
 {
   "tweet_id": "1590036203687923712",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590036202899800064",
   "Positive": "0.9929242134094238",
   "Negative": "0.00013229729665908962",
   "Neutral": "0.006846209522336721",
   "Mixed": "9.72923735389486e-05"
 },
 {
   "tweet_id": "1590036188232323074",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036188114845697",
   "Positive": "0.016512097790837288",
   "Negative": "0.00011386475671315566",
   "Neutral": "0.983347475528717",
   "Mixed": "2.649017005751375e-05"
 },
 {
   "tweet_id": "1590036187179544577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036187095666688",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036186420359168",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590036158775713792",
   "Positive": "0.04728733375668526",
   "Negative": "0.04442412033677101",
   "Neutral": "0.45046839118003845",
   "Mixed": "0.45782023668289185"
 },
 {
   "tweet_id": "1590036153641881601",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590036146666745857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036126395686917",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590036123904282626",
   "Positive": "0.009845086373388767",
   "Negative": "0.004225534852594137",
   "Neutral": "0.9859203696250916",
   "Mixed": "8.959026672528125e-06"
 },
 {
   "tweet_id": "1590036121366716416",
   "Positive": "0.0841185599565506",
   "Negative": "0.0002715685695875436",
   "Neutral": "0.9155678153038025",
   "Mixed": "4.202974741929211e-05"
 },
 {
   "tweet_id": "1590036117323411458",
   "Positive": "0.4369001090526581",
   "Negative": "0.15077877044677734",
   "Neutral": "0.39815640449523926",
   "Mixed": "0.014164653606712818"
 },
 {
   "tweet_id": "1590036115092037633",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590036115016544261",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590036108318216194",
   "Positive": "0.11693641543388367",
   "Negative": "0.0009342409903183579",
   "Neutral": "0.8820779323577881",
   "Mixed": "5.141401197761297e-05"
 },
 {
   "tweet_id": "1590036104983371776",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036101968072704",
   "Positive": "0.8273652791976929",
   "Negative": "0.0010915853781625628",
   "Neutral": "0.1714099943637848",
   "Mixed": "0.00013303887681104243"
 },
 {
   "tweet_id": "1590036092937711616",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590036086981808128",
   "Positive": "0.15687040984630585",
   "Negative": "0.061379436403512955",
   "Neutral": "0.4764063060283661",
   "Mixed": "0.3053438663482666"
 },
 {
   "tweet_id": "1590036086835015681",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590036086113599488",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590036063061696512",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590036060725481473",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590036059840446464",
   "Positive": "0.36894431710243225",
   "Negative": "0.01984298788011074",
   "Neutral": "0.6111568212509155",
   "Mixed": "5.5890519433887675e-05"
 },
 {
   "tweet_id": "1590036049690255362",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590036047823794176",
   "Positive": "0.652198076248169",
   "Negative": "0.0005241260514594615",
   "Neutral": "0.346627801656723",
   "Mixed": "0.0006500030285678804"
 },
 {
   "tweet_id": "1590036043063267328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590036030434181120",
   "Positive": "0.8273652791976929",
   "Negative": "0.0010915853781625628",
   "Neutral": "0.1714099943637848",
   "Mixed": "0.00013303887681104243"
 },
 {
   "tweet_id": "1590036026751614983",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590036021080920065",
   "Positive": "0.8248481154441833",
   "Negative": "0.0004280787834431976",
   "Neutral": "0.17461709678173065",
   "Mixed": "0.00010661670239642262"
 },
 {
   "tweet_id": "1590035999903862784",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590035987287379969",
   "Positive": "0.1170683279633522",
   "Negative": "0.001312710577622056",
   "Neutral": "0.8815884590148926",
   "Mixed": "3.049548286071513e-05"
 },
 {
   "tweet_id": "1590035985840369664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035983650926594",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035948137779200",
   "Positive": "0.9396247267723083",
   "Negative": "0.00022632506443187594",
   "Neutral": "0.059987638145685196",
   "Mixed": "0.0001612846681382507"
 },
 {
   "tweet_id": "1590035941535907840",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035932778201088",
   "Positive": "0.06329389661550522",
   "Negative": "0.015074947848916054",
   "Neutral": "0.921587347984314",
   "Mixed": "4.381713370094076e-05"
 },
 {
   "tweet_id": "1590035929225654273",
   "Positive": "0.00020208042406011373",
   "Negative": "0.0038272589445114136",
   "Neutral": "0.9959433674812317",
   "Mixed": "2.735343514359556e-05"
 },
 {
   "tweet_id": "1590035925236862977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035919423549440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035908447072257",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035906563407873",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035904512786432",
   "Positive": "0.9937509894371033",
   "Negative": "0.00010699423000914976",
   "Neutral": "0.006049050018191338",
   "Mixed": "9.293409675592557e-05"
 },
 {
   "tweet_id": "1590035898422657024",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590035897067929600",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590035886594400256",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1590035883792924672",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590035874104094720",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035867124785152",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035855904997376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035850519199746",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035844358111234",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035826947551233",
   "Positive": "0.9839782118797302",
   "Negative": "0.00012669037096202374",
   "Neutral": "0.015816841274499893",
   "Mixed": "7.828544767107815e-05"
 },
 {
   "tweet_id": "1590035824099610625",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590035812854697984",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035808601636864",
   "Positive": "0.652198076248169",
   "Negative": "0.0005241260514594615",
   "Neutral": "0.346627801656723",
   "Mixed": "0.0006500030285678804"
 },
 {
   "tweet_id": "1590035807418863616",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1590035788687085569",
   "Positive": "0.9848869442939758",
   "Negative": "0.00022293558868113905",
   "Neutral": "0.014810149557888508",
   "Mixed": "7.993706094566733e-05"
 },
 {
   "tweet_id": "1590035784501194754",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035783310008321",
   "Positive": "0.00018469443602953106",
   "Negative": "0.002120215678587556",
   "Neutral": "0.9976868629455566",
   "Mixed": "8.167298801708966e-06"
 },
 {
   "tweet_id": "1590035782395662337",
   "Positive": "0.020070556551218033",
   "Negative": "0.11613791435956955",
   "Neutral": "0.30518338084220886",
   "Mixed": "0.5586081147193909"
 },
 {
   "tweet_id": "1590035771310100481",
   "Positive": "0.0031307346653193235",
   "Negative": "0.00015516753774136305",
   "Neutral": "0.9967091083526611",
   "Mixed": "4.983853614248801e-06"
 },
 {
   "tweet_id": "1590035759947743232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035759205355520",
   "Positive": "0.4920700192451477",
   "Negative": "0.03948330134153366",
   "Neutral": "0.41852572560310364",
   "Mixed": "0.049920935183763504"
 },
 {
   "tweet_id": "1590035747822002176",
   "Positive": "0.00345514714717865",
   "Negative": "0.005929228384047747",
   "Neutral": "0.9904909133911133",
   "Mixed": "0.00012471685477066785"
 },
 {
   "tweet_id": "1590035740272254977",
   "Positive": "0.571632444858551",
   "Negative": "0.0004579087835736573",
   "Neutral": "0.42757776379585266",
   "Mixed": "0.0003318243252579123"
 },
 {
   "tweet_id": "1590035737029660672",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035731631976449",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590035730486947840",
   "Positive": "0.14830416440963745",
   "Negative": "0.0034636561758816242",
   "Neutral": "0.8472418189048767",
   "Mixed": "0.000990356900729239"
 },
 {
   "tweet_id": "1590035729790300160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035713885863940",
   "Positive": "0.9299488067626953",
   "Negative": "0.000893893651664257",
   "Neutral": "0.06910336762666702",
   "Mixed": "5.39694337930996e-05"
 },
 {
   "tweet_id": "1590035704075104256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035697993682945",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590035694923448320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035693870657537",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590035686060879873",
   "Positive": "0.914613664150238",
   "Negative": "0.0002481513365637511",
   "Neutral": "0.08505292981863022",
   "Mixed": "8.528144826414064e-05"
 },
 {
   "tweet_id": "1590035678720831488",
   "Positive": "0.0076925246976315975",
   "Negative": "0.3412519097328186",
   "Neutral": "0.6506896018981934",
   "Mixed": "0.00036600191378965974"
 },
 {
   "tweet_id": "1590035675004702720",
   "Positive": "0.10588407516479492",
   "Negative": "0.035762663930654526",
   "Neutral": "0.8359053134918213",
   "Mixed": "0.022447967901825905"
 },
 {
   "tweet_id": "1590035672500666368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035668226699267",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035658424594432",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590035657916678144",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035649541070848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035647221608448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035637079773185",
   "Positive": "0.0470549501478672",
   "Negative": "0.0001656591921346262",
   "Neutral": "0.9526762962341309",
   "Mixed": "0.00010313270468031988"
 },
 {
   "tweet_id": "1590035633585950720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035631681720320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035614845440000",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035614837002240",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035606603956224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035598139879424",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035597808525312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035593748447232",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590035578023686146",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035570608459777",
   "Positive": "0.5240821242332458",
   "Negative": "0.041568655520677567",
   "Neutral": "0.41818082332611084",
   "Mixed": "0.01616840250790119"
 },
 {
   "tweet_id": "1590035566913277953",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590035560093351937",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590035545409089536",
   "Positive": "0.0024111177772283554",
   "Negative": "5.316061287885532e-05",
   "Neutral": "0.9975214600563049",
   "Mixed": "1.4315286534838378e-05"
 },
 {
   "tweet_id": "1590035542863130624",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590035529940467713",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035522566909953",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035518955614210",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035514807025669",
   "Positive": "0.9785692691802979",
   "Negative": "7.92767241364345e-05",
   "Neutral": "0.021307537332177162",
   "Mixed": "4.387179797049612e-05"
 },
 {
   "tweet_id": "1590035511540088837",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035495727534080",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590035489956200449",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035488035196928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035486969511937",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590035484880916481",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035477968883713",
   "Positive": "0.07483336329460144",
   "Negative": "0.004177033435553312",
   "Neutral": "0.9209644198417664",
   "Mixed": "2.5289349650847726e-05"
 },
 {
   "tweet_id": "1590035477662695424",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035474391117824",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035465683759109",
   "Positive": "0.0646153911948204",
   "Negative": "0.00012551284453365952",
   "Neutral": "0.9352138042449951",
   "Mixed": "4.521604205365293e-05"
 },
 {
   "tweet_id": "1590035459060953089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035457060265984",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035454635954176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035452840771586",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590035451481849857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035445848887297",
   "Positive": "0.005741620436310768",
   "Negative": "0.08246640861034393",
   "Neutral": "0.9114072918891907",
   "Mixed": "0.00038472001324407756"
 },
 {
   "tweet_id": "1590035441948176385",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035432255160321",
   "Positive": "0.9967676401138306",
   "Negative": "8.442611579084769e-05",
   "Neutral": "0.003134173573926091",
   "Mixed": "1.3685576050193049e-05"
 },
 {
   "tweet_id": "1590035427553030147",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035421467402240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035406770573315",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1590035403062403072",
   "Positive": "0.0479714460670948",
   "Negative": "0.00018943182658404112",
   "Neutral": "0.9517767429351807",
   "Mixed": "6.240307993721217e-05"
 },
 {
   "tweet_id": "1590035401028567041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035392610570240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035387304800257",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035377049718784",
   "Positive": "0.6218847036361694",
   "Negative": "0.0004937989870086312",
   "Neutral": "0.3770832419395447",
   "Mixed": "0.0005382735398598015"
 },
 {
   "tweet_id": "1590035363480842243",
   "Positive": "0.4625561833381653",
   "Negative": "0.0008612702367827296",
   "Neutral": "0.005457159131765366",
   "Mixed": "0.5311253666877747"
 },
 {
   "tweet_id": "1590035352106172418",
   "Positive": "0.12794864177703857",
   "Negative": "0.008428293280303478",
   "Neutral": "0.8636031150817871",
   "Mixed": "1.9924051230191253e-05"
 },
 {
   "tweet_id": "1590035351841951745",
   "Positive": "0.4050208330154419",
   "Negative": "0.0028238329105079174",
   "Neutral": "0.5915980339050293",
   "Mixed": "0.000557307037524879"
 },
 {
   "tweet_id": "1590035343893725187",
   "Positive": "0.13872745633125305",
   "Negative": "0.018317798152565956",
   "Neutral": "0.8427963256835938",
   "Mixed": "0.00015846587484702468"
 },
 {
   "tweet_id": "1590035343419404290",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035339371941888",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590035338868985856",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035333277954048",
   "Positive": "0.04840642586350441",
   "Negative": "0.05022401735186577",
   "Neutral": "0.9012606739997864",
   "Mixed": "0.00010898817708948627"
 },
 {
   "tweet_id": "1590035320917356545",
   "Positive": "0.7391126751899719",
   "Negative": "0.00033181734033860266",
   "Neutral": "0.26029738783836365",
   "Mixed": "0.00025809978251345456"
 },
 {
   "tweet_id": "1590035310192185344",
   "Positive": "0.003717190818861127",
   "Negative": "0.02881571464240551",
   "Neutral": "0.017671411857008934",
   "Mixed": "0.9497957229614258"
 },
 {
   "tweet_id": "1590035308317671426",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590035298406510593",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035291611754496",
   "Positive": "0.16901648044586182",
   "Negative": "0.1206168383359909",
   "Neutral": "0.1061207726597786",
   "Mixed": "0.6042459607124329"
 },
 {
   "tweet_id": "1590035281641893888",
   "Positive": "0.7069424390792847",
   "Negative": "0.022179843857884407",
   "Neutral": "0.2707254886627197",
   "Mixed": "0.00015223149966914207"
 },
 {
   "tweet_id": "1590035280370995201",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035276793278469",
   "Positive": "0.3064191937446594",
   "Negative": "0.003979137632995844",
   "Neutral": "0.6845923662185669",
   "Mixed": "0.0050093200989067554"
 },
 {
   "tweet_id": "1590035260515192832",
   "Positive": "0.4642861485481262",
   "Negative": "0.0047881705686450005",
   "Neutral": "0.5308099985122681",
   "Mixed": "0.00011564856686163694"
 },
 {
   "tweet_id": "1590035254836068352",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035251811995650",
   "Positive": "0.0009694570326246321",
   "Negative": "0.0009484352776780725",
   "Neutral": "0.9980794191360474",
   "Mixed": "2.773854703264078e-06"
 },
 {
   "tweet_id": "1590035239392641024",
   "Positive": "0.0008321242057718337",
   "Negative": "4.1891398723237216e-05",
   "Neutral": "0.9991077780723572",
   "Mixed": "1.826760490075685e-05"
 },
 {
   "tweet_id": "1590035231352172544",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590035229179523073",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035227967389696",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590035225744375808",
   "Positive": "0.8598335385322571",
   "Negative": "0.0006166653474792838",
   "Neutral": "0.1395043432712555",
   "Mixed": "4.5538938138633966e-05"
 },
 {
   "tweet_id": "1590035221357162496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035216877637634",
   "Positive": "0.050746701657772064",
   "Negative": "0.0005342570366337895",
   "Neutral": "0.9487072229385376",
   "Mixed": "1.1727124729077332e-05"
 },
 {
   "tweet_id": "1590035216344961024",
   "Positive": "0.13628613948822021",
   "Negative": "0.000339247053489089",
   "Neutral": "0.8628549575805664",
   "Mixed": "0.0005196975544095039"
 },
 {
   "tweet_id": "1590035214872768518",
   "Positive": "0.7842749357223511",
   "Negative": "0.001328532351180911",
   "Neutral": "0.2143443375825882",
   "Mixed": "5.217834041104652e-05"
 },
 {
   "tweet_id": "1590035211764793344",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035210892345345",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590035183516147712",
   "Positive": "0.0073845526203513145",
   "Negative": "0.0001842874044086784",
   "Neutral": "0.9924112558364868",
   "Mixed": "1.987384894164279e-05"
 },
 {
   "tweet_id": "1590035180190064640",
   "Positive": "0.00134128681384027",
   "Negative": "0.013117706403136253",
   "Neutral": "0.9855338335037231",
   "Mixed": "7.130071480787592e-06"
 },
 {
   "tweet_id": "1590035179762245634",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035179602841602",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590035170459279360",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590035168982622211",
   "Positive": "0.148323193192482",
   "Negative": "0.0009521603933535516",
   "Neutral": "0.8507018685340881",
   "Mixed": "2.2673688363283873e-05"
 },
 {
   "tweet_id": "1590035167019925504",
   "Positive": "0.9918805956840515",
   "Negative": "6.730634777341038e-05",
   "Neutral": "0.008032003417611122",
   "Mixed": "2.0115274310228415e-05"
 },
 {
   "tweet_id": "1590035166684413952",
   "Positive": "0.6081445217132568",
   "Negative": "0.00045285720261745155",
   "Neutral": "0.3910661041736603",
   "Mixed": "0.0003364623698871583"
 },
 {
   "tweet_id": "1590035163584471040",
   "Positive": "0.013818212784826756",
   "Negative": "0.07112755626440048",
   "Neutral": "0.8826135396957397",
   "Mixed": "0.032440681010484695"
 },
 {
   "tweet_id": "1590035159973494784",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590035151803019265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035144072523776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035121587228672",
   "Positive": "0.453058660030365",
   "Negative": "0.002898578532040119",
   "Neutral": "0.5439372062683105",
   "Mixed": "0.00010556228517089039"
 },
 {
   "tweet_id": "1590035115790721024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590035098917044225",
   "Positive": "0.652198076248169",
   "Negative": "0.0005241260514594615",
   "Neutral": "0.346627801656723",
   "Mixed": "0.0006500030285678804"
 },
 {
   "tweet_id": "1590035092315201538",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590035089874116608",
   "Positive": "0.03128861263394356",
   "Negative": "0.00034654163755476475",
   "Neutral": "0.9683419466018677",
   "Mixed": "2.2841570171294734e-05"
 },
 {
   "tweet_id": "1590035081779118080",
   "Positive": "0.0262768492102623",
   "Negative": "0.00019295420497655869",
   "Neutral": "0.9735167622566223",
   "Mixed": "1.341622555628419e-05"
 },
 {
   "tweet_id": "1590035080981794818",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590035078662721536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035076452331523",
   "Positive": "0.3267081379890442",
   "Negative": "0.0059735276736319065",
   "Neutral": "0.5204212069511414",
   "Mixed": "0.14689719676971436"
 },
 {
   "tweet_id": "1590035050224943105",
   "Positive": "0.4818163216114044",
   "Negative": "0.044648148119449615",
   "Neutral": "0.4733034074306488",
   "Mixed": "0.00023206707555800676"
 },
 {
   "tweet_id": "1590035036300283904",
   "Positive": "0.21412672102451324",
   "Negative": "0.0066783432848751545",
   "Neutral": "0.7786348462104797",
   "Mixed": "0.0005600874428637326"
 },
 {
   "tweet_id": "1590035022626492417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590035021792178178",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590035018864214016",
   "Positive": "0.020824529230594635",
   "Negative": "0.3360309302806854",
   "Neutral": "0.6412588357925415",
   "Mixed": "0.0018857333343476057"
 },
 {
   "tweet_id": "1590034994361434113",
   "Positive": "0.012786750681698322",
   "Negative": "0.705064058303833",
   "Neutral": "0.09270723164081573",
   "Mixed": "0.1894419938325882"
 },
 {
   "tweet_id": "1590034993472233472",
   "Positive": "0.6706950664520264",
   "Negative": "0.000568580231629312",
   "Neutral": "0.3282666802406311",
   "Mixed": "0.00046967738308012486"
 },
 {
   "tweet_id": "1590034989667995648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034986123792384",
   "Positive": "0.0022604181431233883",
   "Negative": "0.0002648280933499336",
   "Neutral": "0.9974716901779175",
   "Mixed": "3.140831950076972e-06"
 },
 {
   "tweet_id": "1590034982424444928",
   "Positive": "0.7739495038986206",
   "Negative": "0.0009411789360456169",
   "Neutral": "0.2250523865222931",
   "Mixed": "5.698179666069336e-05"
 },
 {
   "tweet_id": "1590034971053686784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034970915266560",
   "Positive": "0.004411242436617613",
   "Negative": "4.450552660273388e-05",
   "Neutral": "0.9955233335494995",
   "Mixed": "2.0932597180944867e-05"
 },
 {
   "tweet_id": "1590034956503646208",
   "Positive": "0.007054596208035946",
   "Negative": "0.00019388461078051478",
   "Neutral": "0.9927384257316589",
   "Mixed": "1.311511641688412e-05"
 },
 {
   "tweet_id": "1590034950866493443",
   "Positive": "0.0071490672416985035",
   "Negative": "0.519866943359375",
   "Neutral": "0.4510800540447235",
   "Mixed": "0.02190394513309002"
 },
 {
   "tweet_id": "1590034934214713344",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1590034918494851073",
   "Positive": "0.023667966946959496",
   "Negative": "0.00020266750652808696",
   "Neutral": "0.9761213064193726",
   "Mixed": "8.062882443482522e-06"
 },
 {
   "tweet_id": "1590034902686498816",
   "Positive": "0.9264723062515259",
   "Negative": "0.00018929109501186758",
   "Neutral": "0.073314368724823",
   "Mixed": "2.4088049030979164e-05"
 },
 {
   "tweet_id": "1590034900194762753",
   "Positive": "0.025123627856373787",
   "Negative": "0.014466866850852966",
   "Neutral": "0.958524227142334",
   "Mixed": "0.0018852963112294674"
 },
 {
   "tweet_id": "1590034898165051392",
   "Positive": "0.0996471419930458",
   "Negative": "0.23189088702201843",
   "Neutral": "0.5105209946632385",
   "Mixed": "0.15794101357460022"
 },
 {
   "tweet_id": "1590034893031231488",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590034888278712320",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1590034883824713728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034880175706113",
   "Positive": "0.0126500790938735",
   "Negative": "0.5205252766609192",
   "Neutral": "0.4355561137199402",
   "Mixed": "0.03126857429742813"
 },
 {
   "tweet_id": "1590034867534069760",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590034863775961088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034852254220288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034838723375105",
   "Positive": "0.25000619888305664",
   "Negative": "0.00041427111136727035",
   "Neutral": "0.749559223651886",
   "Mixed": "2.030731957347598e-05"
 },
 {
   "tweet_id": "1590034828397023232",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1590034826023034880",
   "Positive": "0.12885400652885437",
   "Negative": "0.0005936165107414126",
   "Neutral": "0.8703209757804871",
   "Mixed": "0.00023140219855122268"
 },
 {
   "tweet_id": "1590034822860525568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034806846656513",
   "Positive": "0.00437024375423789",
   "Negative": "0.05823662877082825",
   "Neutral": "0.9371815323829651",
   "Mixed": "0.00021161179756745696"
 },
 {
   "tweet_id": "1590034801440194561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034783568277504",
   "Positive": "0.6767637133598328",
   "Negative": "0.0006212432053871453",
   "Neutral": "0.3219958543777466",
   "Mixed": "0.0006192444707266986"
 },
 {
   "tweet_id": "1590034779805995010",
   "Positive": "0.002194773405790329",
   "Negative": "6.23449741397053e-05",
   "Neutral": "0.9977266192436218",
   "Mixed": "1.6352993043255992e-05"
 },
 {
   "tweet_id": "1590034779407540224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034775255187457",
   "Positive": "0.17380857467651367",
   "Negative": "0.012509610503911972",
   "Neutral": "0.8136441707611084",
   "Mixed": "3.7623001844622195e-05"
 },
 {
   "tweet_id": "1590034765939625985",
   "Positive": "0.001550678862258792",
   "Negative": "0.00026264635380357504",
   "Neutral": "0.9981842637062073",
   "Mixed": "2.3752481865813024e-06"
 },
 {
   "tweet_id": "1590034758998052865",
   "Positive": "0.045860499143600464",
   "Negative": "0.00015006412286311388",
   "Neutral": "0.9538020491600037",
   "Mixed": "0.00018738456128630787"
 },
 {
   "tweet_id": "1590034726882283520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034726462828545",
   "Positive": "0.0004428013344295323",
   "Negative": "5.3310588555177674e-05",
   "Neutral": "0.9994878768920898",
   "Mixed": "1.597508162376471e-05"
 },
 {
   "tweet_id": "1590034715834449920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034712726474753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034710222475264",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590034707915608064",
   "Positive": "0.27668654918670654",
   "Negative": "0.01633266545832157",
   "Neutral": "0.7068789601325989",
   "Mixed": "0.00010185513383476064"
 },
 {
   "tweet_id": "1590034707651366914",
   "Positive": "0.2975289821624756",
   "Negative": "0.01381912361830473",
   "Neutral": "0.6885464191436768",
   "Mixed": "0.00010547821875661612"
 },
 {
   "tweet_id": "1590034682959532032",
   "Positive": "0.13960668444633484",
   "Negative": "0.0015551678370684385",
   "Neutral": "0.8587908744812012",
   "Mixed": "4.724788959720172e-05"
 },
 {
   "tweet_id": "1590034673081929729",
   "Positive": "0.9915566444396973",
   "Negative": "9.674082684796304e-05",
   "Neutral": "0.00833120197057724",
   "Mixed": "1.5479770809179172e-05"
 },
 {
   "tweet_id": "1590034672289198080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034663812526081",
   "Positive": "0.10345646739006042",
   "Negative": "0.542490541934967",
   "Neutral": "0.35390618443489075",
   "Mixed": "0.00014676677528768778"
 },
 {
   "tweet_id": "1590034659291037696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034659022229505",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034648104828928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034619608727552",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590034614487171072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034612537143297",
   "Positive": "0.0018676025792956352",
   "Negative": "0.001349505502730608",
   "Neutral": "0.9967807531356812",
   "Mixed": "2.0931261133227963e-06"
 },
 {
   "tweet_id": "1590034610167386112",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590034607004868609",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034605943721986",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590034603246776320",
   "Positive": "0.7397059798240662",
   "Negative": "0.0010967854177579284",
   "Neutral": "0.2590957581996918",
   "Mixed": "0.00010152795584872365"
 },
 {
   "tweet_id": "1590034598922420225",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590034590080856064",
   "Positive": "0.20209628343582153",
   "Negative": "0.06473185122013092",
   "Neutral": "0.7329169511795044",
   "Mixed": "0.00025491206906735897"
 },
 {
   "tweet_id": "1590034582950535169",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1590034554320203778",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590034537471684609",
   "Positive": "0.2713916599750519",
   "Negative": "0.03202907741069794",
   "Neutral": "0.6964653134346008",
   "Mixed": "0.00011392345913918689"
 },
 {
   "tweet_id": "1590034513648037889",
   "Positive": "0.5541945099830627",
   "Negative": "0.003560709534212947",
   "Neutral": "0.44063901901245117",
   "Mixed": "0.0016058131586760283"
 },
 {
   "tweet_id": "1590034512935014400",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590034500129812480",
   "Positive": "0.0028601980302482843",
   "Negative": "0.04166111722588539",
   "Neutral": "0.9514402747154236",
   "Mixed": "0.0040384987369179726"
 },
 {
   "tweet_id": "1590034485206454272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034477367332869",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590034444370726912",
   "Positive": "0.941334068775177",
   "Negative": "0.00018546423234511167",
   "Neutral": "0.058462709188461304",
   "Mixed": "1.7709910025587305e-05"
 },
 {
   "tweet_id": "1590034443557011462",
   "Positive": "0.001029551262035966",
   "Negative": "0.00393526628613472",
   "Neutral": "0.9950249791145325",
   "Mixed": "1.018560396914836e-05"
 },
 {
   "tweet_id": "1590034439144456193",
   "Positive": "0.0015974516281858087",
   "Negative": "0.25072619318962097",
   "Neutral": "0.7476139068603516",
   "Mixed": "6.249419675441459e-05"
 },
 {
   "tweet_id": "1590034425479589889",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034420685504512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034407527952386",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034383163228163",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590034382034984960",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590034376846241792",
   "Positive": "0.0027426141314208508",
   "Negative": "0.19721053540706635",
   "Neutral": "0.7999984622001648",
   "Mixed": "4.8377820348832756e-05"
 },
 {
   "tweet_id": "1590034354776190981",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034350456066049",
   "Positive": "0.2883250117301941",
   "Negative": "0.014105548150837421",
   "Neutral": "0.697481632232666",
   "Mixed": "8.776288450462744e-05"
 },
 {
   "tweet_id": "1590034348971274240",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590034333720793091",
   "Positive": "0.11015373468399048",
   "Negative": "0.0030305928084999323",
   "Neutral": "0.8863028287887573",
   "Mixed": "0.0005128873744979501"
 },
 {
   "tweet_id": "1590034326443282432",
   "Positive": "0.0016893550055101514",
   "Negative": "0.8801746368408203",
   "Neutral": "0.110563725233078",
   "Mixed": "0.007572232745587826"
 },
 {
   "tweet_id": "1590034320647127040",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590034311096729601",
   "Positive": "0.9774290323257446",
   "Negative": "0.0004021944187115878",
   "Neutral": "0.022118911147117615",
   "Mixed": "4.98208319186233e-05"
 },
 {
   "tweet_id": "1590034283636588544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034281539457024",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590034276590161920",
   "Positive": "0.001742684980854392",
   "Negative": "0.0002463442215230316",
   "Neutral": "0.9980078339576721",
   "Mixed": "3.2327400276699336e-06"
 },
 {
   "tweet_id": "1590034276313362433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034257552248832",
   "Positive": "0.9988318085670471",
   "Negative": "2.7253778171143495e-05",
   "Neutral": "0.0011209318181499839",
   "Mixed": "1.995098682527896e-05"
 },
 {
   "tweet_id": "1590034251210428416",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590034250698756097",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590034250468061185",
   "Positive": "0.371681272983551",
   "Negative": "0.00017188163474202156",
   "Neutral": "0.6252498626708984",
   "Mixed": "0.0028969920240342617"
 },
 {
   "tweet_id": "1590034240020058115",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590034230775463936",
   "Positive": "0.021795740351080894",
   "Negative": "0.4290846288204193",
   "Neutral": "0.45002567768096924",
   "Mixed": "0.09909400343894958"
 },
 {
   "tweet_id": "1590034229718831105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034223079256064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034201604411392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590034190816661505",
   "Positive": "0.5610248446464539",
   "Negative": "0.00719302985817194",
   "Neutral": "0.4315573275089264",
   "Mixed": "0.0002249109384138137"
 },
 {
   "tweet_id": "1590034186244878336",
   "Positive": "0.0033500418066978455",
   "Negative": "0.4945894777774811",
   "Neutral": "0.5020332932472229",
   "Mixed": "2.7181504265172407e-05"
 },
 {
   "tweet_id": "1590034186202906625",
   "Positive": "0.0024501171428710222",
   "Negative": "0.00011964392615482211",
   "Neutral": "0.9974247217178345",
   "Mixed": "5.641549705615034e-06"
 },
 {
   "tweet_id": "1590034184260956161",
   "Positive": "0.9774290323257446",
   "Negative": "0.0004021944187115878",
   "Neutral": "0.022118911147117615",
   "Mixed": "4.98208319186233e-05"
 },
 {
   "tweet_id": "1590034179219402752",
   "Positive": "0.7069424390792847",
   "Negative": "0.022179843857884407",
   "Neutral": "0.2707254886627197",
   "Mixed": "0.00015223149966914207"
 },
 {
   "tweet_id": "1590034172546273287",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590034167290482691",
   "Positive": "0.18252144753932953",
   "Negative": "0.0043305205181241035",
   "Neutral": "0.8130784034729004",
   "Mixed": "6.957691948628053e-05"
 },
 {
   "tweet_id": "1590034163926962176",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590034157664862208",
   "Positive": "0.01818639226257801",
   "Negative": "0.7361242771148682",
   "Neutral": "0.21338093280792236",
   "Mixed": "0.03230833262205124"
 },
 {
   "tweet_id": "1590034135934173184",
   "Positive": "0.5610248446464539",
   "Negative": "0.00719302985817194",
   "Neutral": "0.4315573275089264",
   "Mixed": "0.0002249109384138137"
 },
 {
   "tweet_id": "1590034121031458816",
   "Positive": "0.1264345794916153",
   "Negative": "0.0008613759418949485",
   "Neutral": "0.872651994228363",
   "Mixed": "5.197590871830471e-05"
 },
 {
   "tweet_id": "1590034100332957697",
   "Positive": "0.941334068775177",
   "Negative": "0.00018546423234511167",
   "Neutral": "0.058462709188461304",
   "Mixed": "1.7709910025587305e-05"
 },
 {
   "tweet_id": "1590034083748675586",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590034056150147074",
   "Positive": "0.002320882398635149",
   "Negative": "8.940274710766971e-05",
   "Neutral": "0.9975777268409729",
   "Mixed": "1.1980073395534419e-05"
 },
 {
   "tweet_id": "1590034051036913674",
   "Positive": "0.09083928912878036",
   "Negative": "0.0007725190371274948",
   "Neutral": "0.9083772897720337",
   "Mixed": "1.0978746104228776e-05"
 },
 {
   "tweet_id": "1590034040748662784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590034040740249600",
   "Positive": "0.7703697681427002",
   "Negative": "0.014733802527189255",
   "Neutral": "0.21448659896850586",
   "Mixed": "0.0004098762001376599"
 },
 {
   "tweet_id": "1590034030657179649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590034026512908290",
   "Positive": "0.6191098690032959",
   "Negative": "0.0014481638791039586",
   "Neutral": "0.37940603494644165",
   "Mixed": "3.592836583266035e-05"
 },
 {
   "tweet_id": "1590034020519538688",
   "Positive": "0.49147671461105347",
   "Negative": "0.020542927086353302",
   "Neutral": "0.487724244594574",
   "Mixed": "0.00025615887716412544"
 },
 {
   "tweet_id": "1590034016547532800",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590034007567532033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033988894490624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033984540794881",
   "Positive": "0.12839223444461823",
   "Negative": "0.0035903656389564276",
   "Neutral": "0.8679522275924683",
   "Mixed": "6.519951421068981e-05"
 },
 {
   "tweet_id": "1590033973975339008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033958582226944",
   "Positive": "0.8288864493370056",
   "Negative": "0.0011010452872142196",
   "Neutral": "0.16996654868125916",
   "Mixed": "4.593533230945468e-05"
 },
 {
   "tweet_id": "1590033957089083392",
   "Positive": "0.07715499401092529",
   "Negative": "0.0014276652364060283",
   "Neutral": "0.921373188495636",
   "Mixed": "4.4104119297116995e-05"
 },
 {
   "tweet_id": "1590033953490362368",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590033919898181632",
   "Positive": "0.8764971494674683",
   "Negative": "0.000183714393642731",
   "Neutral": "0.12303430587053299",
   "Mixed": "0.00028487693634815514"
 },
 {
   "tweet_id": "1590033914327859202",
   "Positive": "0.769054651260376",
   "Negative": "0.0010999616933986545",
   "Neutral": "0.22981323301792145",
   "Mixed": "3.221010774723254e-05"
 },
 {
   "tweet_id": "1590033913539198976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033913527021568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033912817889280",
   "Positive": "0.018791409209370613",
   "Negative": "0.0005129797500558197",
   "Neutral": "0.9806893467903137",
   "Mixed": "6.220358500286238e-06"
 },
 {
   "tweet_id": "1590033896766607360",
   "Positive": "0.007148261182010174",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9927327036857605",
   "Mixed": "4.479822382563725e-05"
 },
 {
   "tweet_id": "1590033890131206144",
   "Positive": "0.21159084141254425",
   "Negative": "0.025856880471110344",
   "Neutral": "0.762414276599884",
   "Mixed": "0.00013796442362945527"
 },
 {
   "tweet_id": "1590033883416133632",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1590033878039015425",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590033870342459392",
   "Positive": "0.3383976221084595",
   "Negative": "0.02178741991519928",
   "Neutral": "0.6393679976463318",
   "Mixed": "0.00044691425864584744"
 },
 {
   "tweet_id": "1590033865242210304",
   "Positive": "0.016311950981616974",
   "Negative": "0.10467949509620667",
   "Neutral": "0.24403849244117737",
   "Mixed": "0.6349700093269348"
 },
 {
   "tweet_id": "1590033863702896641",
   "Positive": "0.7259858250617981",
   "Negative": "0.000420347903855145",
   "Neutral": "0.2734859585762024",
   "Mixed": "0.000107871979707852"
 },
 {
   "tweet_id": "1590033852873207808",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590033852340502533",
   "Positive": "0.9918805956840515",
   "Negative": "6.730634777341038e-05",
   "Neutral": "0.008032003417611122",
   "Mixed": "2.0115274310228415e-05"
 },
 {
   "tweet_id": "1590033836397953025",
   "Positive": "0.9477658271789551",
   "Negative": "8.895651990314946e-05",
   "Neutral": "0.05211419612169266",
   "Mixed": "3.1043502531247213e-05"
 },
 {
   "tweet_id": "1590033823097851904",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033818156572672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033817494249475",
   "Positive": "0.00028786552138626575",
   "Negative": "9.919491276377812e-05",
   "Neutral": "0.9995995163917542",
   "Mixed": "1.3476418644131627e-05"
 },
 {
   "tweet_id": "1590033796346548224",
   "Positive": "0.00978726428002119",
   "Negative": "6.936751742614433e-05",
   "Neutral": "0.9900287985801697",
   "Mixed": "0.00011464989074738696"
 },
 {
   "tweet_id": "1590033763408699393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033756953665536",
   "Positive": "0.016345646232366562",
   "Negative": "5.05014177178964e-05",
   "Neutral": "0.9834977388381958",
   "Mixed": "0.00010611351171974093"
 },
 {
   "tweet_id": "1590033755682795520",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033746602135553",
   "Positive": "0.7669378519058228",
   "Negative": "0.0006427562911994755",
   "Neutral": "0.23234091699123383",
   "Mixed": "7.85553565947339e-05"
 },
 {
   "tweet_id": "1590033744747823104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033743406063616",
   "Positive": "0.0070810155011713505",
   "Negative": "0.01476843748241663",
   "Neutral": "0.223780557513237",
   "Mixed": "0.7543699741363525"
 },
 {
   "tweet_id": "1590033741036281856",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033731993358342",
   "Positive": "0.002320882398635149",
   "Negative": "8.940274710766971e-05",
   "Neutral": "0.9975777268409729",
   "Mixed": "1.1980073395534419e-05"
 },
 {
   "tweet_id": "1590033730604658688",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033728252043264",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590033727891337219",
   "Positive": "0.9302131533622742",
   "Negative": "0.00032058043871074915",
   "Neutral": "0.06832243502140045",
   "Mixed": "0.0011438220972195268"
 },
 {
   "tweet_id": "1590033725957767169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033719007510528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033718290575361",
   "Positive": "0.8261157870292664",
   "Negative": "0.057595279067754745",
   "Neutral": "0.09375336766242981",
   "Mixed": "0.02253560908138752"
 },
 {
   "tweet_id": "1590033706479411200",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590033693749706753",
   "Positive": "0.011402523145079613",
   "Negative": "0.00016721284191589803",
   "Neutral": "0.9884234070777893",
   "Mixed": "6.857713287899969e-06"
 },
 {
   "tweet_id": "1590033680617340928",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590033676251074561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033661243850753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033644428865538",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590033644126900224",
   "Positive": "0.9158371090888977",
   "Negative": "0.00027632497949525714",
   "Neutral": "0.08360739797353745",
   "Mixed": "0.0002792262239381671"
 },
 {
   "tweet_id": "1590033639156633600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033636728115200",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033629765586944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033609427386369",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033598635442176",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590033597184225280",
   "Positive": "0.005715962499380112",
   "Negative": "0.02635771781206131",
   "Neutral": "0.9655360579490662",
   "Mixed": "0.0023903523106127977"
 },
 {
   "tweet_id": "1590033590175531008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033588250370049",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033584429027329",
   "Positive": "0.0013979277573525906",
   "Negative": "0.00021661390201188624",
   "Neutral": "0.9983820915222168",
   "Mixed": "3.3108960906247376e-06"
 },
 {
   "tweet_id": "1590033578385346560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033576417890304",
   "Positive": "0.006316356360912323",
   "Negative": "0.00010564210242591798",
   "Neutral": "0.9935332536697388",
   "Mixed": "4.473735316423699e-05"
 },
 {
   "tweet_id": "1590033573209571329",
   "Positive": "0.2975289821624756",
   "Negative": "0.01381912361830473",
   "Neutral": "0.6885464191436768",
   "Mixed": "0.00010547821875661612"
 },
 {
   "tweet_id": "1590033553404088320",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590033545418117121",
   "Positive": "0.009226529859006405",
   "Negative": "0.017635753378272057",
   "Neutral": "0.973109781742096",
   "Mixed": "2.7964204491581768e-05"
 },
 {
   "tweet_id": "1590033538388471809",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033508562763777",
   "Positive": "0.025937438011169434",
   "Negative": "0.00018346169963479042",
   "Neutral": "0.9738646149635315",
   "Mixed": "1.4535530681314413e-05"
 },
 {
   "tweet_id": "1590033507275132930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033503655448576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033502812000257",
   "Positive": "0.12885400652885437",
   "Negative": "0.0005936165107414126",
   "Neutral": "0.8703209757804871",
   "Mixed": "0.00023140219855122268"
 },
 {
   "tweet_id": "1590033500119642112",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033496122146817",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590033492410519552",
   "Positive": "0.2975289821624756",
   "Negative": "0.01381912361830473",
   "Neutral": "0.6885464191436768",
   "Mixed": "0.00010547821875661612"
 },
 {
   "tweet_id": "1590033489768120322",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033482604253185",
   "Positive": "0.9903752207756042",
   "Negative": "0.00010198201198363677",
   "Neutral": "0.009506640955805779",
   "Mixed": "1.6084797607618384e-05"
 },
 {
   "tweet_id": "1590033476728020992",
   "Positive": "0.4395138919353485",
   "Negative": "0.07363476604223251",
   "Neutral": "0.48653891682624817",
   "Mixed": "0.0003125296498183161"
 },
 {
   "tweet_id": "1590033475478130689",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590033456117223426",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590033449045602314",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590033422289772545",
   "Positive": "0.001029551262035966",
   "Negative": "0.00393526628613472",
   "Neutral": "0.9950249791145325",
   "Mixed": "1.018560396914836e-05"
 },
 {
   "tweet_id": "1590033411741454337",
   "Positive": "0.1076686903834343",
   "Negative": "0.00030675597372464836",
   "Neutral": "0.8919551372528076",
   "Mixed": "6.94323462084867e-05"
 },
 {
   "tweet_id": "1590033402862145536",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033397438902274",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033395358535687",
   "Positive": "0.17203858494758606",
   "Negative": "0.19255025684833527",
   "Neutral": "0.6339777112007141",
   "Mixed": "0.0014334700535982847"
 },
 {
   "tweet_id": "1590033393806643200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033392871309313",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033388303704064",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033386844094464",
   "Positive": "0.0006149971159175038",
   "Negative": "0.9048299789428711",
   "Neutral": "0.09447918832302094",
   "Mixed": "7.579057273687795e-05"
 },
 {
   "tweet_id": "1590033386491772928",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590033386416275456",
   "Positive": "0.5917067527770996",
   "Negative": "0.006501723080873489",
   "Neutral": "0.40154778957366943",
   "Mixed": "0.00024370188475586474"
 },
 {
   "tweet_id": "1590033376131813378",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033371060523008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033370213666817",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590033366816260096",
   "Positive": "0.005460332613438368",
   "Negative": "0.6692896485328674",
   "Neutral": "0.3249971866607666",
   "Mixed": "0.00025279956753365695"
 },
 {
   "tweet_id": "1590033362546089984",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033357114863616",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590033351926505475",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033345307893760",
   "Positive": "0.3702470660209656",
   "Negative": "0.02793804742395878",
   "Neutral": "0.6017003655433655",
   "Mixed": "0.00011454868945293128"
 },
 {
   "tweet_id": "1590033337590362113",
   "Positive": "0.5599923133850098",
   "Negative": "0.001318632741458714",
   "Neutral": "0.4381886422634125",
   "Mixed": "0.0005003857659175992"
 },
 {
   "tweet_id": "1590033337086640129",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033336243990529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033333396058113",
   "Positive": "0.07962150126695633",
   "Negative": "0.0001983272668439895",
   "Neutral": "0.9201533198356628",
   "Mixed": "2.687289634195622e-05"
 },
 {
   "tweet_id": "1590033325812768770",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590033316832739328",
   "Positive": "0.20728664100170135",
   "Negative": "0.00019473482097964734",
   "Neutral": "0.7922444343566895",
   "Mixed": "0.00027420843252912164"
 },
 {
   "tweet_id": "1590033316618858496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033308494299137",
   "Positive": "0.0828421488404274",
   "Negative": "0.0006374395452439785",
   "Neutral": "0.9163146018981934",
   "Mixed": "0.0002058018435491249"
 },
 {
   "tweet_id": "1590033304623149058",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033293357244416",
   "Positive": "0.6079046726226807",
   "Negative": "0.004881421569734812",
   "Neutral": "0.3870212137699127",
   "Mixed": "0.00019261181296315044"
 },
 {
   "tweet_id": "1590033285887168512",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590033271756591104",
   "Positive": "0.9034816026687622",
   "Negative": "0.000448955106548965",
   "Neutral": "0.0960254967212677",
   "Mixed": "4.4005133531754836e-05"
 },
 {
   "tweet_id": "1590033270573760512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033264152309760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033257810518016",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590033257634369536",
   "Positive": "0.9424256682395935",
   "Negative": "0.00018802235717885196",
   "Neutral": "0.05736817419528961",
   "Mixed": "1.8075717889587395e-05"
 },
 {
   "tweet_id": "1590033231331876865",
   "Positive": "0.030265653505921364",
   "Negative": "0.032935064285993576",
   "Neutral": "0.9367570877075195",
   "Mixed": "4.225908196531236e-05"
 },
 {
   "tweet_id": "1590033218862223360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033204043730945",
   "Positive": "0.24047888815402985",
   "Negative": "0.0002784058451652527",
   "Neutral": "0.7571205496788025",
   "Mixed": "0.0021221537608653307"
 },
 {
   "tweet_id": "1590033202701537280",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590033202596700162",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590033199597760512",
   "Positive": "0.0024771648459136486",
   "Negative": "0.000289417861495167",
   "Neutral": "0.9972298741340637",
   "Mixed": "3.5106133964291075e-06"
 },
 {
   "tweet_id": "1590033196661735424",
   "Positive": "0.08876846730709076",
   "Negative": "0.001818114542402327",
   "Neutral": "0.9093629717826843",
   "Mixed": "5.03726550959982e-05"
 },
 {
   "tweet_id": "1590033181532905474",
   "Positive": "0.07904977351427078",
   "Negative": "0.004161111079156399",
   "Neutral": "0.9167681336402893",
   "Mixed": "2.0978734028176405e-05"
 },
 {
   "tweet_id": "1590033154576125952",
   "Positive": "0.0010805982165038586",
   "Negative": "0.0024936904665082693",
   "Neutral": "0.9963752627372742",
   "Mixed": "5.0510258006397635e-05"
 },
 {
   "tweet_id": "1590033149786206208",
   "Positive": "0.024467425420880318",
   "Negative": "0.0007207001908682287",
   "Neutral": "0.9748029112815857",
   "Mixed": "8.995265488920268e-06"
 },
 {
   "tweet_id": "1590033133835268096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033133117636608",
   "Positive": "0.32822462916374207",
   "Negative": "0.0003335844085086137",
   "Neutral": "0.6713839769363403",
   "Mixed": "5.7824276154860854e-05"
 },
 {
   "tweet_id": "1590033121428533248",
   "Positive": "0.5580341219902039",
   "Negative": "0.00492860097438097",
   "Neutral": "0.43690359592437744",
   "Mixed": "0.0001336926652584225"
 },
 {
   "tweet_id": "1590033105259151360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590033105058136064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033101623013377",
   "Positive": "0.9944512248039246",
   "Negative": "0.0002203161857323721",
   "Neutral": "0.005285175982862711",
   "Mixed": "4.3365536839701235e-05"
 },
 {
   "tweet_id": "1590033093322493952",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590033091200167936",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590033072405483521",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590033070450933760",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590033063622631425",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033061747781632",
   "Positive": "0.9255449175834656",
   "Negative": "0.0002333196607651189",
   "Neutral": "0.07334811985492706",
   "Mixed": "0.0008735681185498834"
 },
 {
   "tweet_id": "1590033042525270019",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590033041694785536",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590033013789761537",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590032995549073410",
   "Positive": "0.0027680296916514635",
   "Negative": "0.18541093170642853",
   "Neutral": "0.811742901802063",
   "Mixed": "7.80709451646544e-05"
 },
 {
   "tweet_id": "1590032991015034880",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590032986573279233",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590032975290593281",
   "Positive": "0.9838297963142395",
   "Negative": "0.00031833216780796647",
   "Neutral": "0.015778519213199615",
   "Mixed": "7.336177804972976e-05"
 },
 {
   "tweet_id": "1590032959146721281",
   "Positive": "0.5610248446464539",
   "Negative": "0.00719302985817194",
   "Neutral": "0.4315573275089264",
   "Mixed": "0.0002249109384138137"
 },
 {
   "tweet_id": "1590032955493453824",
   "Positive": "0.016885098069906235",
   "Negative": "0.012342014349997044",
   "Neutral": "0.9706464409828186",
   "Mixed": "0.00012648764823097736"
 },
 {
   "tweet_id": "1590032948174389249",
   "Positive": "0.3665650486946106",
   "Negative": "0.006933963391929865",
   "Neutral": "0.6264102458953857",
   "Mixed": "9.072344982996583e-05"
 },
 {
   "tweet_id": "1590032941752909827",
   "Positive": "0.6960294246673584",
   "Negative": "0.02961435727775097",
   "Neutral": "0.27423977851867676",
   "Mixed": "0.00011638269643299282"
 },
 {
   "tweet_id": "1590032931799846913",
   "Positive": "0.38922709226608276",
   "Negative": "0.0021490035578608513",
   "Neutral": "0.6084702014923096",
   "Mixed": "0.000153701999806799"
 },
 {
   "tweet_id": "1590032929354559490",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032904150650880",
   "Positive": "0.0023052108008414507",
   "Negative": "0.00011107089812867343",
   "Neutral": "0.9975797533988953",
   "Mixed": "4.023313977086218e-06"
 },
 {
   "tweet_id": "1590032898559995904",
   "Positive": "0.04185224696993828",
   "Negative": "0.15851528942584991",
   "Neutral": "0.7986308932304382",
   "Mixed": "0.0010015483712777495"
 },
 {
   "tweet_id": "1590032887944183809",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032887621242884",
   "Positive": "0.0006013429956510663",
   "Negative": "5.617403076030314e-05",
   "Neutral": "0.99933260679245",
   "Mixed": "9.945117199094966e-06"
 },
 {
   "tweet_id": "1590032887474446336",
   "Positive": "0.9492760300636292",
   "Negative": "0.0002566679904703051",
   "Neutral": "0.050453074276447296",
   "Mixed": "1.4233036381483544e-05"
 },
 {
   "tweet_id": "1590032886753005568",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032883544395776",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590032873436110850",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590032866964307973",
   "Positive": "0.0015452227089554071",
   "Negative": "0.04264255613088608",
   "Neutral": "0.008136607706546783",
   "Mixed": "0.9476756453514099"
 },
 {
   "tweet_id": "1590032851558608896",
   "Positive": "0.5917067527770996",
   "Negative": "0.006501723080873489",
   "Neutral": "0.40154778957366943",
   "Mixed": "0.00024370188475586474"
 },
 {
   "tweet_id": "1590032851114012672",
   "Positive": "0.8116610050201416",
   "Negative": "0.001084754941985011",
   "Neutral": "0.18489187955856323",
   "Mixed": "0.0023624340537935495"
 },
 {
   "tweet_id": "1590032841613922304",
   "Positive": "0.7616215348243713",
   "Negative": "0.0006538936286233366",
   "Neutral": "0.23710207641124725",
   "Mixed": "0.0006224754615686834"
 },
 {
   "tweet_id": "1590032821175087104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590032814510333952",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590032814409658370",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590032802946613248",
   "Positive": "0.8038081526756287",
   "Negative": "0.0006100184982642531",
   "Neutral": "0.19458763301372528",
   "Mixed": "0.0009942350443452597"
 },
 {
   "tweet_id": "1590032796810379264",
   "Positive": "0.19053880870342255",
   "Negative": "0.07063499093055725",
   "Neutral": "0.7379083037376404",
   "Mixed": "0.0009178832406178117"
 },
 {
   "tweet_id": "1590032792209223680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032790103691265",
   "Positive": "0.9988318085670471",
   "Negative": "2.7253778171143495e-05",
   "Neutral": "0.0011209318181499839",
   "Mixed": "1.995098682527896e-05"
 },
 {
   "tweet_id": "1590032767458635777",
   "Positive": "0.0009204323869198561",
   "Negative": "0.6232684254646301",
   "Neutral": "0.37535175681114197",
   "Mixed": "0.00045945445890538394"
 },
 {
   "tweet_id": "1590032766913384448",
   "Positive": "0.9944512248039246",
   "Negative": "0.0002203161857323721",
   "Neutral": "0.005285175982862711",
   "Mixed": "4.3365536839701235e-05"
 },
 {
   "tweet_id": "1590032766753992705",
   "Positive": "0.09758563339710236",
   "Negative": "0.1634763479232788",
   "Neutral": "0.7353789806365967",
   "Mixed": "0.0035589674953371286"
 },
 {
   "tweet_id": "1590032764635873280",
   "Positive": "0.0011674333363771439",
   "Negative": "0.00025920025655068457",
   "Neutral": "0.9985706806182861",
   "Mixed": "2.5997387638199143e-06"
 },
 {
   "tweet_id": "1590032739566485505",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590032721803644928",
   "Positive": "0.5610248446464539",
   "Negative": "0.00719302985817194",
   "Neutral": "0.4315573275089264",
   "Mixed": "0.0002249109384138137"
 },
 {
   "tweet_id": "1590032718397865985",
   "Positive": "0.03247116506099701",
   "Negative": "0.23619979619979858",
   "Neutral": "0.1728980839252472",
   "Mixed": "0.5584309697151184"
 },
 {
   "tweet_id": "1590032715214356480",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590032697354698752",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590032696889454594",
   "Positive": "0.019291197881102562",
   "Negative": "0.00017961465346161276",
   "Neutral": "0.9805206656455994",
   "Mixed": "8.546255230612587e-06"
 },
 {
   "tweet_id": "1590032678770073600",
   "Positive": "0.17966225743293762",
   "Negative": "0.004356963559985161",
   "Neutral": "0.8157941102981567",
   "Mixed": "0.00018657671171240509"
 },
 {
   "tweet_id": "1590032672780587008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590032670205317124",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032651641319424",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590032645588914181",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032615267962880",
   "Positive": "0.33697301149368286",
   "Negative": "0.047194045037031174",
   "Neutral": "0.6061263084411621",
   "Mixed": "0.009706638753414154"
 },
 {
   "tweet_id": "1590032614580424705",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590032607592734720",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590032604182753280",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590032593399185410",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032577393758208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032575237877760",
   "Positive": "0.01679202727973461",
   "Negative": "0.042847566306591034",
   "Neutral": "0.9403519630432129",
   "Mixed": "8.361991604033392e-06"
 },
 {
   "tweet_id": "1590032574206054400",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590032569802051585",
   "Positive": "0.9915566444396973",
   "Negative": "9.674082684796304e-05",
   "Neutral": "0.00833120197057724",
   "Mixed": "1.5479770809179172e-05"
 },
 {
   "tweet_id": "1590032568212402176",
   "Positive": "0.7397059798240662",
   "Negative": "0.0010967854177579284",
   "Neutral": "0.2590957581996918",
   "Mixed": "0.00010152795584872365"
 },
 {
   "tweet_id": "1590032557537890306",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590032551347097605",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590032545764503552",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590032521403969538",
   "Positive": "0.35983261466026306",
   "Negative": "0.03474236652255058",
   "Neutral": "0.3959019184112549",
   "Mixed": "0.20952309668064117"
 },
 {
   "tweet_id": "1590032481822347265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032478101975040",
   "Positive": "0.38948896527290344",
   "Negative": "0.00022843884653411806",
   "Neutral": "0.6102361083030701",
   "Mixed": "4.65482626168523e-05"
 },
 {
   "tweet_id": "1590032452000813056",
   "Positive": "0.3064191937446594",
   "Negative": "0.003979137632995844",
   "Neutral": "0.6845923662185669",
   "Mixed": "0.0050093200989067554"
 },
 {
   "tweet_id": "1590032445969420289",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590032433793368064",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590032423232081920",
   "Positive": "0.755484402179718",
   "Negative": "0.0009903534082695842",
   "Neutral": "0.2434263974428177",
   "Mixed": "9.880228026304394e-05"
 },
 {
   "tweet_id": "1590032415514185728",
   "Positive": "0.0021553002297878265",
   "Negative": "0.0004163526464253664",
   "Neutral": "0.9974241256713867",
   "Mixed": "4.268358679837547e-06"
 },
 {
   "tweet_id": "1590032406203240448",
   "Positive": "0.9988318085670471",
   "Negative": "2.7253778171143495e-05",
   "Neutral": "0.0011209318181499839",
   "Mixed": "1.995098682527896e-05"
 },
 {
   "tweet_id": "1590032404152225793",
   "Positive": "0.0817657858133316",
   "Negative": "0.0015155712608247995",
   "Neutral": "0.9166820645332336",
   "Mixed": "3.661114169517532e-05"
 },
 {
   "tweet_id": "1590032403434975232",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590032394379460608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032382228582401",
   "Positive": "0.03551320359110832",
   "Negative": "0.0003487234062049538",
   "Neutral": "0.9641284942626953",
   "Mixed": "9.707032404548954e-06"
 },
 {
   "tweet_id": "1590032373227622400",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590032369545007106",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590032354667479041",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032343993319424",
   "Positive": "0.755484402179718",
   "Negative": "0.0009903534082695842",
   "Neutral": "0.2434263974428177",
   "Mixed": "9.880228026304394e-05"
 },
 {
   "tweet_id": "1590032342512701440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032342365908992",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032341619335169",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590032334862299136",
   "Positive": "0.9312300682067871",
   "Negative": "0.00022031986736692488",
   "Neutral": "0.06852814555168152",
   "Mixed": "2.140772994607687e-05"
 },
 {
   "tweet_id": "1590032323860627456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032321973219330",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590032310338211840",
   "Positive": "0.009691172279417515",
   "Negative": "0.360358864068985",
   "Neutral": "0.28118300437927246",
   "Mixed": "0.348766952753067"
 },
 {
   "tweet_id": "1590032295947534336",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590032294672494592",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590032293913333760",
   "Positive": "0.011848324909806252",
   "Negative": "0.010393739677965641",
   "Neutral": "0.9776146411895752",
   "Mixed": "0.00014333076251205057"
 },
 {
   "tweet_id": "1590032246421225473",
   "Positive": "0.0007855843286961317",
   "Negative": "0.00015554243873339146",
   "Neutral": "0.9990553259849548",
   "Mixed": "3.538699957061908e-06"
 },
 {
   "tweet_id": "1590032239378968576",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1590032231942483969",
   "Positive": "0.000839170825202018",
   "Negative": "0.9624028205871582",
   "Neutral": "0.03592776879668236",
   "Mixed": "0.0008302797796204686"
 },
 {
   "tweet_id": "1590032224455647232",
   "Positive": "0.47118493914604187",
   "Negative": "0.0342462956905365",
   "Neutral": "0.471294105052948",
   "Mixed": "0.02327468991279602"
 },
 {
   "tweet_id": "1590032215089766401",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590032214271569921",
   "Positive": "0.9574980735778809",
   "Negative": "0.0003778827958740294",
   "Neutral": "0.042018767446279526",
   "Mixed": "0.00010529254359425977"
 },
 {
   "tweet_id": "1590032190213324801",
   "Positive": "0.9312300682067871",
   "Negative": "0.00022031986736692488",
   "Neutral": "0.06852814555168152",
   "Mixed": "2.140772994607687e-05"
 },
 {
   "tweet_id": "1590032189399642112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032188770496514",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590032187159908352",
   "Positive": "0.7552757263183594",
   "Negative": "0.004351717885583639",
   "Neutral": "0.24004723131656647",
   "Mixed": "0.00032534674392081797"
 },
 {
   "tweet_id": "1590032162409283585",
   "Positive": "0.9158371090888977",
   "Negative": "0.00027632497949525714",
   "Neutral": "0.08360739797353745",
   "Mixed": "0.0002792262239381671"
 },
 {
   "tweet_id": "1590032145581412352",
   "Positive": "0.00434521958231926",
   "Negative": "0.0005296631716191769",
   "Neutral": "0.9951203465461731",
   "Mixed": "4.730545242637163e-06"
 },
 {
   "tweet_id": "1590032143874654208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032140473110528",
   "Positive": "0.23325195908546448",
   "Negative": "0.0070946356281638145",
   "Neutral": "0.7595919370651245",
   "Mixed": "6.14590899203904e-05"
 },
 {
   "tweet_id": "1590032132000616450",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1590032130775875584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032128963936257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590032125491052544",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590032109103898625",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590032108663472129",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590032093060673537",
   "Positive": "0.5580437183380127",
   "Negative": "0.003232470015063882",
   "Neutral": "0.4385358989238739",
   "Mixed": "0.00018790575268212706"
 },
 {
   "tweet_id": "1590032090019819520",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590032066896629760",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590032060005355521",
   "Positive": "0.9312300682067871",
   "Negative": "0.00022031986736692488",
   "Neutral": "0.06852814555168152",
   "Mixed": "2.140772994607687e-05"
 },
 {
   "tweet_id": "1590032054808637440",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590032047237914624",
   "Positive": "0.07647614181041718",
   "Negative": "0.27124762535095215",
   "Neutral": "0.2789214253425598",
   "Mixed": "0.37335479259490967"
 },
 {
   "tweet_id": "1590032038228549632",
   "Positive": "0.3325112760066986",
   "Negative": "0.01960545964539051",
   "Neutral": "0.17815397679805756",
   "Mixed": "0.46972930431365967"
 },
 {
   "tweet_id": "1590032033224744961",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590032030615490560",
   "Positive": "0.6173816323280334",
   "Negative": "0.003197810612618923",
   "Neutral": "0.3793826699256897",
   "Mixed": "3.7907218938926235e-05"
 },
 {
   "tweet_id": "1590032025460740097",
   "Positive": "0.00022420416644308716",
   "Negative": "0.9607324004173279",
   "Neutral": "0.03889114782214165",
   "Mixed": "0.0001522528618806973"
 },
 {
   "tweet_id": "1590032017793900544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590032009887641602",
   "Positive": "0.060995034873485565",
   "Negative": "0.0005664113559760153",
   "Neutral": "0.9383910894393921",
   "Mixed": "4.750584048451856e-05"
 },
 {
   "tweet_id": "1590031988630913024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031985959145472",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590031984809902081",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590031982289117184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031981328609280",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590031980561051649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031973074235393",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590031956418662400",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590031927725395968",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590031924550021120",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590031915876515840",
   "Positive": "0.9312300682067871",
   "Negative": "0.00022031986736692488",
   "Neutral": "0.06852814555168152",
   "Mixed": "2.140772994607687e-05"
 },
 {
   "tweet_id": "1590031898604351489",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590031897626759168",
   "Positive": "0.0010379122104495764",
   "Negative": "0.8951242566108704",
   "Neutral": "0.10369995981454849",
   "Mixed": "0.00013779723667539656"
 },
 {
   "tweet_id": "1590031892824625153",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031891943796739",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590031890324811777",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031878287151105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031876848504832",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590031844174856192",
   "Positive": "0.9918805956840515",
   "Negative": "6.730634777341038e-05",
   "Neutral": "0.008032003417611122",
   "Mixed": "2.0115274310228415e-05"
 },
 {
   "tweet_id": "1590031823094284288",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590031813380308993",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590031809651564544",
   "Positive": "0.6120654344558716",
   "Negative": "0.013397968374192715",
   "Neutral": "0.35912367701530457",
   "Mixed": "0.015412832610309124"
 },
 {
   "tweet_id": "1590031807176912896",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590031799773974532",
   "Positive": "0.018567444756627083",
   "Negative": "0.00040524473297409713",
   "Neutral": "0.9810160994529724",
   "Mixed": "1.1260191058681812e-05"
 },
 {
   "tweet_id": "1590031798104625154",
   "Positive": "0.013259809464216232",
   "Negative": "0.4381190836429596",
   "Neutral": "0.5484561324119568",
   "Mixed": "0.00016494742885697633"
 },
 {
   "tweet_id": "1590031792836612097",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590031790919782400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031789157847040",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590031742752411650",
   "Positive": "0.3860761225223541",
   "Negative": "0.02853512205183506",
   "Neutral": "0.5852860808372498",
   "Mixed": "0.00010266996832797304"
 },
 {
   "tweet_id": "1590031742559453185",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590031740621688833",
   "Positive": "0.009612217545509338",
   "Negative": "0.0001343755575362593",
   "Neutral": "0.9902396202087402",
   "Mixed": "1.3868705536879133e-05"
 },
 {
   "tweet_id": "1590031739283382272",
   "Positive": "0.7216961979866028",
   "Negative": "0.00027487409533932805",
   "Neutral": "0.2778659164905548",
   "Mixed": "0.00016309462080243975"
 },
 {
   "tweet_id": "1590031734946816000",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590031729343221760",
   "Positive": "0.8288864493370056",
   "Negative": "0.0011010452872142196",
   "Neutral": "0.16996654868125916",
   "Mixed": "4.593533230945468e-05"
 },
 {
   "tweet_id": "1590031715938205697",
   "Positive": "0.00028786552138626575",
   "Negative": "9.919491276377812e-05",
   "Neutral": "0.9995995163917542",
   "Mixed": "1.3476418644131627e-05"
 },
 {
   "tweet_id": "1590031714310819840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031710171070464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031707234713600",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590031707134361600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031705234366466",
   "Positive": "0.0039040991105139256",
   "Negative": "0.0012588952668011189",
   "Neutral": "0.9948235750198364",
   "Mixed": "1.3370437045523431e-05"
 },
 {
   "tweet_id": "1590031700511592449",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031687739904000",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590031685965733888",
   "Positive": "0.0034985707607120275",
   "Negative": "0.6169463992118835",
   "Neutral": "0.3794962763786316",
   "Mixed": "5.877602598047815e-05"
 },
 {
   "tweet_id": "1590031660875415552",
   "Positive": "0.0006853298400528729",
   "Negative": "0.5755460858345032",
   "Neutral": "0.4237409830093384",
   "Mixed": "2.7597307052928954e-05"
 },
 {
   "tweet_id": "1590031654604910592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031641535082496",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590031628797378560",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1590031628080156672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031619037220864",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590031618760396800",
   "Positive": "0.538679838180542",
   "Negative": "0.006928011775016785",
   "Neutral": "0.454122930765152",
   "Mixed": "0.00026919893571175635"
 },
 {
   "tweet_id": "1590031613462990848",
   "Positive": "0.9915566444396973",
   "Negative": "9.674082684796304e-05",
   "Neutral": "0.00833120197057724",
   "Mixed": "1.5479770809179172e-05"
 },
 {
   "tweet_id": "1590031600657788929",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031596920647680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031595985309697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031590390128641",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031584643940353",
   "Positive": "0.27331042289733887",
   "Negative": "0.036192480474710464",
   "Neutral": "0.6903990507125854",
   "Mixed": "9.798875544220209e-05"
 },
 {
   "tweet_id": "1590031581707919361",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031556483371008",
   "Positive": "0.9838297963142395",
   "Negative": "0.00031833216780796647",
   "Neutral": "0.015778519213199615",
   "Mixed": "7.336177804972976e-05"
 },
 {
   "tweet_id": "1590031554189103105",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1590031537143427072",
   "Positive": "0.008448959328234196",
   "Negative": "0.26417383551597595",
   "Neutral": "0.7056812644004822",
   "Mixed": "0.021696049720048904"
 },
 {
   "tweet_id": "1590031536841437186",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590031530700996609",
   "Positive": "0.9880109429359436",
   "Negative": "0.0002871826582122594",
   "Neutral": "0.01167790126055479",
   "Mixed": "2.3995340598048642e-05"
 },
 {
   "tweet_id": "1590031529782415362",
   "Positive": "0.004625851288437843",
   "Negative": "0.533860445022583",
   "Neutral": "0.46139058470726013",
   "Mixed": "0.00012311719183344394"
 },
 {
   "tweet_id": "1590031526796066818",
   "Positive": "0.10052204132080078",
   "Negative": "0.00032946947612799704",
   "Neutral": "0.8991292715072632",
   "Mixed": "1.9234736100770533e-05"
 },
 {
   "tweet_id": "1590031523734224896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031522962501633",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1590031514288652288",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1590031507984646145",
   "Positive": "0.538679838180542",
   "Negative": "0.006928011775016785",
   "Neutral": "0.454122930765152",
   "Mixed": "0.00026919893571175635"
 },
 {
   "tweet_id": "1590031506206253061",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031505514201088",
   "Positive": "0.40984833240509033",
   "Negative": "0.00012938787403982133",
   "Neutral": "0.5896720290184021",
   "Mixed": "0.00035020773066207767"
 },
 {
   "tweet_id": "1590031504029417473",
   "Positive": "0.0018977205036208034",
   "Negative": "7.584296690765768e-05",
   "Neutral": "0.9980177879333496",
   "Mixed": "8.706802873348352e-06"
 },
 {
   "tweet_id": "1590031485645778944",
   "Positive": "0.998635470867157",
   "Negative": "3.0122819225653075e-05",
   "Neutral": "0.0013148658908903599",
   "Mixed": "1.9521019567037e-05"
 },
 {
   "tweet_id": "1590031473406803968",
   "Positive": "0.9919507503509521",
   "Negative": "9.981881885323673e-05",
   "Neutral": "0.007934575900435448",
   "Mixed": "1.483763480791822e-05"
 },
 {
   "tweet_id": "1590031467580895232",
   "Positive": "0.1209104135632515",
   "Negative": "0.0005671590333804488",
   "Neutral": "0.878487229347229",
   "Mixed": "3.5227276384830475e-05"
 },
 {
   "tweet_id": "1590031447108513794",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590031445883772928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031442255699972",
   "Positive": "0.7990302443504333",
   "Negative": "0.0014242589240893722",
   "Neutral": "0.19920305907726288",
   "Mixed": "0.0003424113674554974"
 },
 {
   "tweet_id": "1590031435817418755",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031416490102786",
   "Positive": "0.7053119540214539",
   "Negative": "0.0011043630074709654",
   "Neutral": "0.2934795320034027",
   "Mixed": "0.00010414066491648555"
 },
 {
   "tweet_id": "1590031407686234112",
   "Positive": "0.9322757720947266",
   "Negative": "0.00020084546122234315",
   "Neutral": "0.06750479340553284",
   "Mixed": "1.8572873159428127e-05"
 },
 {
   "tweet_id": "1590031405160886272",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590031403621974016",
   "Positive": "0.9919507503509521",
   "Negative": "9.981881885323673e-05",
   "Neutral": "0.007934575900435448",
   "Mixed": "1.483763480791822e-05"
 },
 {
   "tweet_id": "1590031384445583360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031376211853312",
   "Positive": "0.21084651350975037",
   "Negative": "0.03568005561828613",
   "Neutral": "0.7492567896842957",
   "Mixed": "0.004216664005070925"
 },
 {
   "tweet_id": "1590031369740382208",
   "Positive": "0.7053119540214539",
   "Negative": "0.0011043630074709654",
   "Neutral": "0.2934795320034027",
   "Mixed": "0.00010414066491648555"
 },
 {
   "tweet_id": "1590031357560131592",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590031352245944323",
   "Positive": "0.07454846054315567",
   "Negative": "0.002021083142608404",
   "Neutral": "0.9234113097190857",
   "Mixed": "1.9156685084453784e-05"
 },
 {
   "tweet_id": "1590031348152303618",
   "Positive": "0.03769044205546379",
   "Negative": "0.0003943087358493358",
   "Neutral": "0.961857259273529",
   "Mixed": "5.800472354167141e-05"
 },
 {
   "tweet_id": "1590031347242131458",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590031340850020353",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590031336752156672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031329709920259",
   "Positive": "0.013393625617027283",
   "Negative": "0.42747214436531067",
   "Neutral": "0.5072532892227173",
   "Mixed": "0.05188092216849327"
 },
 {
   "tweet_id": "1590031322315390977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031318615994368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031311221428224",
   "Positive": "0.005419441964477301",
   "Negative": "0.024222249165177345",
   "Neutral": "0.9702534079551697",
   "Mixed": "0.00010485038365004584"
 },
 {
   "tweet_id": "1590031304091127808",
   "Positive": "0.05292549356818199",
   "Negative": "0.0036476063542068005",
   "Neutral": "0.9432972073554993",
   "Mixed": "0.00012969753879588097"
 },
 {
   "tweet_id": "1590031292229304320",
   "Positive": "0.028515249490737915",
   "Negative": "0.0003314361674711108",
   "Neutral": "0.9711467027664185",
   "Mixed": "6.649524948443286e-06"
 },
 {
   "tweet_id": "1590031288269824000",
   "Positive": "0.0012337906518951058",
   "Negative": "0.0003025994810741395",
   "Neutral": "0.9984612464904785",
   "Mixed": "2.3859251996327657e-06"
 },
 {
   "tweet_id": "1590031286147903488",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590031283908145153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031283698413569",
   "Positive": "0.008902599103748798",
   "Negative": "0.00016006447549443692",
   "Neutral": "0.9909225106239319",
   "Mixed": "1.4726625522598624e-05"
 },
 {
   "tweet_id": "1590031277239185410",
   "Positive": "0.3702470660209656",
   "Negative": "0.02793804742395878",
   "Neutral": "0.6017003655433655",
   "Mixed": "0.00011454868945293128"
 },
 {
   "tweet_id": "1590031274634510336",
   "Positive": "0.006555232685059309",
   "Negative": "0.00014410828589461744",
   "Neutral": "0.9932957291603088",
   "Mixed": "4.986263320461148e-06"
 },
 {
   "tweet_id": "1590031268477276160",
   "Positive": "0.04185224696993828",
   "Negative": "0.15851528942584991",
   "Neutral": "0.7986308932304382",
   "Mixed": "0.0010015483712777495"
 },
 {
   "tweet_id": "1590031260503904257",
   "Positive": "0.2622555196285248",
   "Negative": "0.01406005583703518",
   "Neutral": "0.723603367805481",
   "Mixed": "8.095691009657457e-05"
 },
 {
   "tweet_id": "1590031257886679040",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590031247623221250",
   "Positive": "0.0023868221323937178",
   "Negative": "0.048967208713293076",
   "Neutral": "0.9486398696899414",
   "Mixed": "6.126639618742047e-06"
 },
 {
   "tweet_id": "1590031238932594689",
   "Positive": "0.22027525305747986",
   "Negative": "0.018297104164958",
   "Neutral": "0.7588149309158325",
   "Mixed": "0.002612718380987644"
 },
 {
   "tweet_id": "1590031237842108418",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590031228480389120",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590031219542351873",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590031216841207808",
   "Positive": "0.04046710580587387",
   "Negative": "0.03277317434549332",
   "Neutral": "0.9267269372940063",
   "Mixed": "3.276559436926618e-05"
 },
 {
   "tweet_id": "1590031207147786240",
   "Positive": "0.0046702497638762",
   "Negative": "0.5647961497306824",
   "Neutral": "0.41614481806755066",
   "Mixed": "0.0143887372687459"
 },
 {
   "tweet_id": "1590031198872829952",
   "Positive": "0.7619490623474121",
   "Negative": "0.0004901463980786502",
   "Neutral": "0.23675930500030518",
   "Mixed": "0.000801523623522371"
 },
 {
   "tweet_id": "1590031184343740418",
   "Positive": "0.27311578392982483",
   "Negative": "0.013520031236112118",
   "Neutral": "0.7132656574249268",
   "Mixed": "9.85864971880801e-05"
 },
 {
   "tweet_id": "1590031176135213056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031158649229318",
   "Positive": "0.0010664890287443995",
   "Negative": "0.9360804557800293",
   "Neutral": "0.06283774971961975",
   "Mixed": "1.5351817637565546e-05"
 },
 {
   "tweet_id": "1590031139758280704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031135593336832",
   "Positive": "0.08217481523752213",
   "Negative": "0.18950742483139038",
   "Neutral": "0.7187722325325012",
   "Mixed": "0.009545573964715004"
 },
 {
   "tweet_id": "1590031118975500289",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590031115754287106",
   "Positive": "0.0006070666131563485",
   "Negative": "0.9606966376304626",
   "Neutral": "0.038689035922288895",
   "Mixed": "7.214272045530379e-06"
 },
 {
   "tweet_id": "1590031114458267649",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590031114214985728",
   "Positive": "0.8167781233787537",
   "Negative": "0.0011518559185788035",
   "Neutral": "0.18203209340572357",
   "Mixed": "3.790084520005621e-05"
 },
 {
   "tweet_id": "1590031104610033664",
   "Positive": "0.27311578392982483",
   "Negative": "0.013520031236112118",
   "Neutral": "0.7132656574249268",
   "Mixed": "9.85864971880801e-05"
 },
 {
   "tweet_id": "1590031102038937601",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031085513379841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031078961872896",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590031074318589953",
   "Positive": "0.1716035008430481",
   "Negative": "0.0003285935672465712",
   "Neutral": "0.8279909491539001",
   "Mixed": "7.695204112678766e-05"
 },
 {
   "tweet_id": "1590031064311164928",
   "Positive": "0.3064191937446594",
   "Negative": "0.003979137632995844",
   "Neutral": "0.6845923662185669",
   "Mixed": "0.0050093200989067554"
 },
 {
   "tweet_id": "1590031040793673728",
   "Positive": "0.8167781233787537",
   "Negative": "0.0011518559185788035",
   "Neutral": "0.18203209340572357",
   "Mixed": "3.790084520005621e-05"
 },
 {
   "tweet_id": "1590031035139776512",
   "Positive": "0.008810325525701046",
   "Negative": "0.009783963672816753",
   "Neutral": "0.9813217520713806",
   "Mixed": "8.403821266256273e-05"
 },
 {
   "tweet_id": "1590031031914332166",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590031027912572929",
   "Positive": "0.00584601704031229",
   "Negative": "0.2436220496892929",
   "Neutral": "0.7468902468681335",
   "Mixed": "0.0036417848896235228"
 },
 {
   "tweet_id": "1590031026637901824",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590031018534522880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590031010812788737",
   "Positive": "0.3961377739906311",
   "Negative": "0.0002062198327621445",
   "Neutral": "0.6036149859428406",
   "Mixed": "4.100623482372612e-05"
 },
 {
   "tweet_id": "1590031005297303553",
   "Positive": "0.6624751687049866",
   "Negative": "0.002738275798037648",
   "Neutral": "0.3347046673297882",
   "Mixed": "8.188577339751646e-05"
 },
 {
   "tweet_id": "1590031002637778944",
   "Positive": "0.016587641090154648",
   "Negative": "0.11086393892765045",
   "Neutral": "0.8699084520339966",
   "Mixed": "0.002640057122334838"
 },
 {
   "tweet_id": "1590031001404993536",
   "Positive": "0.27311578392982483",
   "Negative": "0.013520031236112118",
   "Neutral": "0.7132656574249268",
   "Mixed": "9.85864971880801e-05"
 },
 {
   "tweet_id": "1590030997592354817",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590030996497657856",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590030994257899521",
   "Positive": "0.9915566444396973",
   "Negative": "9.674082684796304e-05",
   "Neutral": "0.00833120197057724",
   "Mixed": "1.5479770809179172e-05"
 },
 {
   "tweet_id": "1590030983176523777",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030982195085313",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590030971319226368",
   "Positive": "0.006194861605763435",
   "Negative": "0.0002382399106863886",
   "Neutral": "0.9935562014579773",
   "Mixed": "1.0704199667088687e-05"
 },
 {
   "tweet_id": "1590030969008013312",
   "Positive": "0.7309486865997314",
   "Negative": "0.0006413925439119339",
   "Neutral": "0.26837220788002014",
   "Mixed": "3.7762540159747005e-05"
 },
 {
   "tweet_id": "1590030963119362050",
   "Positive": "0.9888416528701782",
   "Negative": "8.19637716631405e-05",
   "Neutral": "0.011056581512093544",
   "Mixed": "1.9789280486293137e-05"
 },
 {
   "tweet_id": "1590030944114987008",
   "Positive": "0.9944512248039246",
   "Negative": "0.0002203161857323721",
   "Neutral": "0.005285175982862711",
   "Mixed": "4.3365536839701235e-05"
 },
 {
   "tweet_id": "1590030941606404096",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590030938393964544",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590030929351016449",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030924325867520",
   "Positive": "0.08660861104726791",
   "Negative": "0.09398306161165237",
   "Neutral": "0.36209312081336975",
   "Mixed": "0.45731526613235474"
 },
 {
   "tweet_id": "1590030916432596992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030915757305857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030913089388544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030912678670336",
   "Positive": "0.3029928505420685",
   "Negative": "0.0023072015028446913",
   "Neutral": "0.6943982243537903",
   "Mixed": "0.0003017064300365746"
 },
 {
   "tweet_id": "1590030907263823874",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590030894257307651",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590030893858852865",
   "Positive": "0.9927546381950378",
   "Negative": "6.575838779099286e-05",
   "Neutral": "0.007161277811974287",
   "Mixed": "1.8282124074175954e-05"
 },
 {
   "tweet_id": "1590030891208028160",
   "Positive": "0.27311578392982483",
   "Negative": "0.013520031236112118",
   "Neutral": "0.7132656574249268",
   "Mixed": "9.85864971880801e-05"
 },
 {
   "tweet_id": "1590030884904005632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030874225291265",
   "Positive": "0.7069424390792847",
   "Negative": "0.022179843857884407",
   "Neutral": "0.2707254886627197",
   "Mixed": "0.00015223149966914207"
 },
 {
   "tweet_id": "1590030860111482880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030856675946496",
   "Positive": "0.633114218711853",
   "Negative": "0.07389470189809799",
   "Neutral": "0.2926967144012451",
   "Mixed": "0.00029427677509374917"
 },
 {
   "tweet_id": "1590030832194187265",
   "Positive": "0.06233716383576393",
   "Negative": "0.00024196386220864952",
   "Neutral": "0.9373628497123718",
   "Mixed": "5.8137487940257415e-05"
 },
 {
   "tweet_id": "1590030830138961920",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590030829220397056",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030816071258112",
   "Positive": "0.3860761225223541",
   "Negative": "0.02853512205183506",
   "Neutral": "0.5852860808372498",
   "Mixed": "0.00010266996832797304"
 },
 {
   "tweet_id": "1590030792839028738",
   "Positive": "0.05525832623243332",
   "Negative": "0.0002657705917954445",
   "Neutral": "0.9444567561149597",
   "Mixed": "1.9117442207061686e-05"
 },
 {
   "tweet_id": "1590030792050475008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590030788879585280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030768704999424",
   "Positive": "0.330798864364624",
   "Negative": "0.0004341504827607423",
   "Neutral": "0.6686392426490784",
   "Mixed": "0.00012776892981491983"
 },
 {
   "tweet_id": "1590030767471882240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030763374018560",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590030758185664512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030740536066048",
   "Positive": "0.9424256682395935",
   "Negative": "0.00018802235717885196",
   "Neutral": "0.05736817419528961",
   "Mixed": "1.8075717889587395e-05"
 },
 {
   "tweet_id": "1590030735955886080",
   "Positive": "0.2536633610725403",
   "Negative": "0.033900655806064606",
   "Neutral": "0.7123537063598633",
   "Mixed": "8.231497486121953e-05"
 },
 {
   "tweet_id": "1590030725407191040",
   "Positive": "0.006693079601973295",
   "Negative": "0.00034111860441043973",
   "Neutral": "0.9929201006889343",
   "Mixed": "4.566423376672901e-05"
 },
 {
   "tweet_id": "1590030713688297472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030691047452672",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590030687629103104",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030679949344768",
   "Positive": "0.552215576171875",
   "Negative": "0.005004464648663998",
   "Neutral": "0.4426041543483734",
   "Mixed": "0.00017579003178980201"
 },
 {
   "tweet_id": "1590030670868668416",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590030656599633920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030653013512193",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030644054482944",
   "Positive": "0.0126502038910985",
   "Negative": "0.002901180414482951",
   "Neutral": "0.9844419360160828",
   "Mixed": "6.745613063685596e-06"
 },
 {
   "tweet_id": "1590030630448140288",
   "Positive": "0.006555232685059309",
   "Negative": "0.00014410828589461744",
   "Neutral": "0.9932957291603088",
   "Mixed": "4.986263320461148e-06"
 },
 {
   "tweet_id": "1590030625075261440",
   "Positive": "0.7397297620773315",
   "Negative": "0.024711649864912033",
   "Neutral": "0.23544588685035706",
   "Mixed": "0.00011268477828707546"
 },
 {
   "tweet_id": "1590030620645736450",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590030605403578368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030604770643968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030587242610689",
   "Positive": "0.0672832801938057",
   "Negative": "0.00013715092791244388",
   "Neutral": "0.9324943423271179",
   "Mixed": "8.526760211680084e-05"
 },
 {
   "tweet_id": "1590030585824960512",
   "Positive": "0.48156875371932983",
   "Negative": "0.03063742257654667",
   "Neutral": "0.48081865906715393",
   "Mixed": "0.006975061725825071"
 },
 {
   "tweet_id": "1590030567617482754",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030560428457986",
   "Positive": "0.9988318085670471",
   "Negative": "2.7253778171143495e-05",
   "Neutral": "0.0011209318181499839",
   "Mixed": "1.995098682527896e-05"
 },
 {
   "tweet_id": "1590030549502267392",
   "Positive": "0.7397297620773315",
   "Negative": "0.024711649864912033",
   "Neutral": "0.23544588685035706",
   "Mixed": "0.00011268477828707546"
 },
 {
   "tweet_id": "1590030545983266816",
   "Positive": "0.47857698798179626",
   "Negative": "0.0005123377195559442",
   "Neutral": "0.5188367366790771",
   "Mixed": "0.002074004150927067"
 },
 {
   "tweet_id": "1590030534918705152",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590030525732835328",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590030522318979073",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590030498369523712",
   "Positive": "0.27459174394607544",
   "Negative": "0.0006994070135988295",
   "Neutral": "0.7240844964981079",
   "Mixed": "0.0006243123207241297"
 },
 {
   "tweet_id": "1590030484587048960",
   "Positive": "0.0005897629889659584",
   "Negative": "0.979512095451355",
   "Neutral": "0.016416894271969795",
   "Mixed": "0.003481211606413126"
 },
 {
   "tweet_id": "1590030472083812352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030468166012928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030467277156353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030458418769920",
   "Positive": "0.27331042289733887",
   "Negative": "0.036192480474710464",
   "Neutral": "0.6903990507125854",
   "Mixed": "9.798875544220209e-05"
 },
 {
   "tweet_id": "1590030458150346752",
   "Positive": "0.3800201714038849",
   "Negative": "0.0007154378108680248",
   "Neutral": "0.6190958023071289",
   "Mixed": "0.00016856360889505595"
 },
 {
   "tweet_id": "1590030444095238144",
   "Positive": "0.6874695420265198",
   "Negative": "0.002968053100630641",
   "Neutral": "0.30951306223869324",
   "Mixed": "4.9264286644756794e-05"
 },
 {
   "tweet_id": "1590030420992987137",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590030410209464320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030396414373888",
   "Positive": "0.061183907091617584",
   "Negative": "0.0018324939301237464",
   "Neutral": "0.9369202852249146",
   "Mixed": "6.333614874165505e-05"
 },
 {
   "tweet_id": "1590030387308367872",
   "Positive": "0.006128818728029728",
   "Negative": "0.08413417637348175",
   "Neutral": "0.7179355025291443",
   "Mixed": "0.1918015331029892"
 },
 {
   "tweet_id": "1590030375501561857",
   "Positive": "0.3860761225223541",
   "Negative": "0.02853512205183506",
   "Neutral": "0.5852860808372498",
   "Mixed": "0.00010266996832797304"
 },
 {
   "tweet_id": "1590030373437997056",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590030363535241217",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590030363199672321",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590030357021483008",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590030352978182146",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030350071521280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030337446645762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590030324897308673",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030304823382017",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590030297701421056",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590030295293898752",
   "Positive": "0.04193215072154999",
   "Negative": "0.02421812154352665",
   "Neutral": "0.030922941863536835",
   "Mixed": "0.9029268026351929"
 },
 {
   "tweet_id": "1590030285382774787",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030281075216384",
   "Positive": "0.00934488233178854",
   "Negative": "0.9142417907714844",
   "Neutral": "0.05980328470468521",
   "Mixed": "0.016610048711299896"
 },
 {
   "tweet_id": "1590030281062289408",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1590030277178712064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030275148648448",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590030269142425600",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590030263945670656",
   "Positive": "0.9414569139480591",
   "Negative": "0.0002032402844633907",
   "Neutral": "0.05832291021943092",
   "Mixed": "1.6943677110248245e-05"
 },
 {
   "tweet_id": "1590030258824437760",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590030240650498048",
   "Positive": "0.29724711179733276",
   "Negative": "0.0028921887278556824",
   "Neutral": "0.6979484558105469",
   "Mixed": "0.0019123476231470704"
 },
 {
   "tweet_id": "1590030229309108224",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590030205179269120",
   "Positive": "0.0007424759096466005",
   "Negative": "0.0003376878157723695",
   "Neutral": "0.9989148378372192",
   "Mixed": "5.032493390899617e-06"
 },
 {
   "tweet_id": "1590030202188726273",
   "Positive": "0.0006183538353070617",
   "Negative": "0.9920873045921326",
   "Neutral": "0.00709217693656683",
   "Mixed": "0.00020221252634655684"
 },
 {
   "tweet_id": "1590030198539706368",
   "Positive": "0.9414569139480591",
   "Negative": "0.0002032402844633907",
   "Neutral": "0.05832291021943092",
   "Mixed": "1.6943677110248245e-05"
 },
 {
   "tweet_id": "1590030196107010056",
   "Positive": "0.0002063334104605019",
   "Negative": "0.010983479209244251",
   "Neutral": "0.9888021945953369",
   "Mixed": "8.049440111790318e-06"
 },
 {
   "tweet_id": "1590030192353103873",
   "Positive": "0.4897101819515228",
   "Negative": "0.002968808636069298",
   "Neutral": "0.5071405172348022",
   "Mixed": "0.00018044072203338146"
 },
 {
   "tweet_id": "1590030191648047105",
   "Positive": "0.08717159181833267",
   "Negative": "0.000677564472425729",
   "Neutral": "0.9098904132843018",
   "Mixed": "0.00226043164730072"
 },
 {
   "tweet_id": "1590030184828522496",
   "Positive": "0.002641129307448864",
   "Negative": "0.8731239438056946",
   "Neutral": "0.12312854826450348",
   "Mixed": "0.001106428331695497"
 },
 {
   "tweet_id": "1590030180075986944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590030179526934528",
   "Positive": "0.2620745599269867",
   "Negative": "0.0079389913007617",
   "Neutral": "0.7299641966819763",
   "Mixed": "2.2194048142409883e-05"
 },
 {
   "tweet_id": "1590030174133051397",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030171662581761",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1590030159759147008",
   "Positive": "0.4194985330104828",
   "Negative": "0.12993723154067993",
   "Neutral": "0.0779617428779602",
   "Mixed": "0.3726024925708771"
 },
 {
   "tweet_id": "1590030159369097216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030151815172096",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590030140045955073",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590030136123879426",
   "Positive": "0.4132036864757538",
   "Negative": "0.002098596189171076",
   "Neutral": "0.5846772193908691",
   "Mixed": "2.051353112619836e-05"
 },
 {
   "tweet_id": "1590030135423631361",
   "Positive": "0.17065033316612244",
   "Negative": "0.003171397838741541",
   "Neutral": "0.8124432563781738",
   "Mixed": "0.013734995387494564"
 },
 {
   "tweet_id": "1590030121226113026",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1590030121217720321",
   "Positive": "0.37451171875",
   "Negative": "0.004384207539260387",
   "Neutral": "0.6210848689079285",
   "Mixed": "1.9140090444125235e-05"
 },
 {
   "tweet_id": "1590030121121222657",
   "Positive": "0.3029928505420685",
   "Negative": "0.0023072015028446913",
   "Neutral": "0.6943982243537903",
   "Mixed": "0.0003017064300365746"
 },
 {
   "tweet_id": "1590030111537262593",
   "Positive": "0.035828959196805954",
   "Negative": "0.0036081273574382067",
   "Neutral": "0.9605486989021301",
   "Mixed": "1.4209606888471171e-05"
 },
 {
   "tweet_id": "1590030109494636545",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590030067555774466",
   "Positive": "0.11138149350881577",
   "Negative": "0.0004227929166518152",
   "Neutral": "0.8881707787513733",
   "Mixed": "2.4829754693200812e-05"
 },
 {
   "tweet_id": "1590030061352407040",
   "Positive": "0.0817657858133316",
   "Negative": "0.0015155712608247995",
   "Neutral": "0.9166820645332336",
   "Mixed": "3.661114169517532e-05"
 },
 {
   "tweet_id": "1590030037524574208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590030026292211713",
   "Positive": "0.538679838180542",
   "Negative": "0.006928011775016785",
   "Neutral": "0.454122930765152",
   "Mixed": "0.00026919893571175635"
 },
 {
   "tweet_id": "1590030004859334656",
   "Positive": "0.08876846730709076",
   "Negative": "0.001818114542402327",
   "Neutral": "0.9093629717826843",
   "Mixed": "5.03726550959982e-05"
 },
 {
   "tweet_id": "1590030004829581312",
   "Positive": "0.05622963234782219",
   "Negative": "0.0001705303875496611",
   "Neutral": "0.943514883518219",
   "Mixed": "8.494326175423339e-05"
 },
 {
   "tweet_id": "1590029999373156352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029997968101376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029996273242114",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1590029976132521984",
   "Positive": "0.7636266946792603",
   "Negative": "0.0004222718416713178",
   "Neutral": "0.23591284453868866",
   "Mixed": "3.818830373347737e-05"
 },
 {
   "tweet_id": "1590029963855818754",
   "Positive": "0.9194915890693665",
   "Negative": "0.0006614122539758682",
   "Neutral": "0.04938279092311859",
   "Mixed": "0.030464299023151398"
 },
 {
   "tweet_id": "1590029955400101888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029951541317632",
   "Positive": "0.538679838180542",
   "Negative": "0.006928011775016785",
   "Neutral": "0.454122930765152",
   "Mixed": "0.00026919893571175635"
 },
 {
   "tweet_id": "1590029948479471616",
   "Positive": "0.39710450172424316",
   "Negative": "0.00894756056368351",
   "Neutral": "0.5938847661018372",
   "Mixed": "6.314987695077434e-05"
 },
 {
   "tweet_id": "1590029943857348608",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590029943584747520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029932326834176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029904359620608",
   "Positive": "0.010418553836643696",
   "Negative": "0.40536168217658997",
   "Neutral": "0.2280907779932022",
   "Mixed": "0.3561290204524994"
 },
 {
   "tweet_id": "1590029897552252928",
   "Positive": "0.053200434893369675",
   "Negative": "0.20342347025871277",
   "Neutral": "0.6978160738945007",
   "Mixed": "0.04556002840399742"
 },
 {
   "tweet_id": "1590029896130396160",
   "Positive": "0.014634918421506882",
   "Negative": "0.008837343193590641",
   "Neutral": "0.966292142868042",
   "Mixed": "0.01023565698415041"
 },
 {
   "tweet_id": "1590029876114792449",
   "Positive": "0.1299924999475479",
   "Negative": "0.009510540403425694",
   "Neutral": "0.8604640364646912",
   "Mixed": "3.289233427494764e-05"
 },
 {
   "tweet_id": "1590029873955098624",
   "Positive": "0.3665650486946106",
   "Negative": "0.006933963391929865",
   "Neutral": "0.6264102458953857",
   "Mixed": "9.072344982996583e-05"
 },
 {
   "tweet_id": "1590029871329464323",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029829789093889",
   "Positive": "0.3212435841560364",
   "Negative": "0.004423904698342085",
   "Neutral": "0.6741851568222046",
   "Mixed": "0.00014737300807610154"
 },
 {
   "tweet_id": "1590029817575264257",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590029813431271425",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029807458586624",
   "Positive": "0.7927534580230713",
   "Negative": "0.0009688995778560638",
   "Neutral": "0.20293782651424408",
   "Mixed": "0.0033397353254258633"
 },
 {
   "tweet_id": "1590029806556811265",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029789892857856",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590029784889061376",
   "Positive": "0.03474916145205498",
   "Negative": "0.005691072903573513",
   "Neutral": "0.9595479369163513",
   "Mixed": "1.1810188880190253e-05"
 },
 {
   "tweet_id": "1590029780283707392",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590029763766521857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029738948845568",
   "Positive": "0.8038081526756287",
   "Negative": "0.0006100184982642531",
   "Neutral": "0.19458763301372528",
   "Mixed": "0.0009942350443452597"
 },
 {
   "tweet_id": "1590029738508439552",
   "Positive": "0.552215576171875",
   "Negative": "0.005004464648663998",
   "Neutral": "0.4426041543483734",
   "Mixed": "0.00017579003178980201"
 },
 {
   "tweet_id": "1590029735530094592",
   "Positive": "0.33082011342048645",
   "Negative": "0.0003637532063294202",
   "Neutral": "0.668101966381073",
   "Mixed": "0.0007141449023038149"
 },
 {
   "tweet_id": "1590029703209189376",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590029693147033600",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590029686176088064",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590029669084319744",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590029645189349381",
   "Positive": "0.004350481554865837",
   "Negative": "0.8099106550216675",
   "Neutral": "0.18564768135547638",
   "Mixed": "9.119392052525654e-05"
 },
 {
   "tweet_id": "1590029642869927937",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590029635907366913",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590029634942693377",
   "Positive": "0.09883307665586472",
   "Negative": "0.00024321430828422308",
   "Neutral": "0.9008075594902039",
   "Mixed": "0.00011622547026490793"
 },
 {
   "tweet_id": "1590029626650529793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029614839369728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029614751305728",
   "Positive": "0.24037043750286102",
   "Negative": "0.013494042679667473",
   "Neutral": "0.7460125088691711",
   "Mixed": "0.00012303079711273313"
 },
 {
   "tweet_id": "1590029611626135552",
   "Positive": "0.058153606951236725",
   "Negative": "0.04164021462202072",
   "Neutral": "0.8266997337341309",
   "Mixed": "0.07350648939609528"
 },
 {
   "tweet_id": "1590029610497892353",
   "Positive": "0.0024771648459136486",
   "Negative": "0.000289417861495167",
   "Neutral": "0.9972298741340637",
   "Mixed": "3.5106133964291075e-06"
 },
 {
   "tweet_id": "1590029610385051653",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029594731905025",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590029572527230976",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590029562020532224",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590029557943635968",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590029555993317376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590029545616609281",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590029541971750916",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590029539547426816",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590029528973574150",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029526524104704",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590029507687518209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029502784352256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029502725652480",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590029491984027650",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029491929513984",
   "Positive": "0.0904533788561821",
   "Negative": "0.003939477726817131",
   "Neutral": "0.9055719375610352",
   "Mixed": "3.515768185025081e-05"
 },
 {
   "tweet_id": "1590029482525872130",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029478943920129",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590029455015440384",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590029450913386497",
   "Positive": "0.38948896527290344",
   "Negative": "0.00022843884653411806",
   "Neutral": "0.6102361083030701",
   "Mixed": "4.65482626168523e-05"
 },
 {
   "tweet_id": "1590029450246500352",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590029450099699714",
   "Positive": "0.016716940328478813",
   "Negative": "0.0080589409917593",
   "Neutral": "0.975186824798584",
   "Mixed": "3.7285259168129414e-05"
 },
 {
   "tweet_id": "1590029446303846402",
   "Positive": "0.6874695420265198",
   "Negative": "0.002968053100630641",
   "Neutral": "0.30951306223869324",
   "Mixed": "4.9264286644756794e-05"
 },
 {
   "tweet_id": "1590029438275964929",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590029426057940993",
   "Positive": "0.09989028424024582",
   "Negative": "0.08576077967882156",
   "Neutral": "0.8141587972640991",
   "Mixed": "0.00019013798737432808"
 },
 {
   "tweet_id": "1590029421595222016",
   "Positive": "0.9897714257240295",
   "Negative": "0.00010878621105803177",
   "Neutral": "0.010103002190589905",
   "Mixed": "1.6817199139040895e-05"
 },
 {
   "tweet_id": "1590029421192568832",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590029412594257921",
   "Positive": "0.32681185007095337",
   "Negative": "0.00037072194390930235",
   "Neutral": "0.6724932193756104",
   "Mixed": "0.00032423477387055755"
 },
 {
   "tweet_id": "1590029388862853120",
   "Positive": "0.3469375669956207",
   "Negative": "0.0002293163415743038",
   "Neutral": "0.6527904272079468",
   "Mixed": "4.266259566065855e-05"
 },
 {
   "tweet_id": "1590029379710902272",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590029374644183040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590029371875549184",
   "Positive": "0.00038116725045256317",
   "Negative": "0.0008890860481187701",
   "Neutral": "0.9987264275550842",
   "Mixed": "3.3755779895727756e-06"
 },
 {
   "tweet_id": "1590029367794888705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029362417786880",
   "Positive": "0.001165158930234611",
   "Negative": "0.0015098400181159377",
   "Neutral": "0.9973216652870178",
   "Mixed": "3.3721419185894774e-06"
 },
 {
   "tweet_id": "1590029360953987073",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590029358244442113",
   "Positive": "0.5456540584564209",
   "Negative": "0.03427617996931076",
   "Neutral": "0.3882637619972229",
   "Mixed": "0.03180595487356186"
 },
 {
   "tweet_id": "1590029347704143872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029337545572353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029331429851138",
   "Positive": "0.0030832102056592703",
   "Negative": "0.0007183853886090219",
   "Neutral": "0.9961798191070557",
   "Mixed": "1.8695343896979466e-05"
 },
 {
   "tweet_id": "1590029323414962178",
   "Positive": "0.08876846730709076",
   "Negative": "0.001818114542402327",
   "Neutral": "0.9093629717826843",
   "Mixed": "5.03726550959982e-05"
 },
 {
   "tweet_id": "1590029320399237120",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590029317878165504",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590029313512202240",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590029308768419841",
   "Positive": "0.028218261897563934",
   "Negative": "0.0004245248856022954",
   "Neutral": "0.9713118076324463",
   "Mixed": "4.534844993031584e-05"
 },
 {
   "tweet_id": "1590029306595799041",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590029292200947712",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590029282004258816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029266842157058",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590029266628284416",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590029266154323968",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590029259451793409",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029256695828481",
   "Positive": "0.0009292059694416821",
   "Negative": "0.00021234742598608136",
   "Neutral": "0.9988467693328857",
   "Mixed": "1.1697779882524628e-05"
 },
 {
   "tweet_id": "1590029246755639296",
   "Positive": "0.6874695420265198",
   "Negative": "0.002968053100630641",
   "Neutral": "0.30951306223869324",
   "Mixed": "4.9264286644756794e-05"
 },
 {
   "tweet_id": "1590029224207069184",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590029222067998722",
   "Positive": "0.0888001099228859",
   "Negative": "0.0016900086775422096",
   "Neutral": "0.9094830751419067",
   "Mixed": "2.6779964173329063e-05"
 },
 {
   "tweet_id": "1590029196042317826",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590029190732353539",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029186739376129",
   "Positive": "0.05060510337352753",
   "Negative": "0.0030040275305509567",
   "Neutral": "0.9463777542114258",
   "Mixed": "1.3101733202347532e-05"
 },
 {
   "tweet_id": "1590029176400404480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029174173224960",
   "Positive": "0.08451192080974579",
   "Negative": "0.0001332426763838157",
   "Neutral": "0.9148706197738647",
   "Mixed": "0.00048420700477436185"
 },
 {
   "tweet_id": "1590029157458583552",
   "Positive": "0.05715512856841087",
   "Negative": "0.375357985496521",
   "Neutral": "0.5670529007911682",
   "Mixed": "0.00043398182606324553"
 },
 {
   "tweet_id": "1590029146960560135",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029145504768000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590029131848482816",
   "Positive": "0.4642395079135895",
   "Negative": "0.003620826406404376",
   "Neutral": "0.5320131778717041",
   "Mixed": "0.00012650206917896867"
 },
 {
   "tweet_id": "1590029112336601089",
   "Positive": "0.10153762996196747",
   "Negative": "0.004432718735188246",
   "Neutral": "0.8939739465713501",
   "Mixed": "5.573864109464921e-05"
 },
 {
   "tweet_id": "1590029097128067072",
   "Positive": "0.09123745560646057",
   "Negative": "0.00028165869298391044",
   "Neutral": "0.9084518551826477",
   "Mixed": "2.8985783501411788e-05"
 },
 {
   "tweet_id": "1590029069550514176",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590029054023208960",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590029048373477378",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590029043470331909",
   "Positive": "0.056970302015542984",
   "Negative": "0.0006034682737663388",
   "Neutral": "0.9424185752868652",
   "Mixed": "7.660561095690355e-06"
 },
 {
   "tweet_id": "1590029039674490880",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590028998423498753",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590028974695948289",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590028957172523008",
   "Positive": "0.9841029644012451",
   "Negative": "0.0002463042619638145",
   "Neutral": "0.015628350898623466",
   "Mixed": "2.225755997642409e-05"
 },
 {
   "tweet_id": "1590028951698952192",
   "Positive": "0.08876846730709076",
   "Negative": "0.001818114542402327",
   "Neutral": "0.9093629717826843",
   "Mixed": "5.03726550959982e-05"
 },
 {
   "tweet_id": "1590028946779033602",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028940869267457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028937538990080",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590028937153110016",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1590028921877442560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028914285740032",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590028907511971840",
   "Positive": "0.018294071778655052",
   "Negative": "0.0020137897226959467",
   "Neutral": "0.9796767830848694",
   "Mixed": "1.5285180779756047e-05"
 },
 {
   "tweet_id": "1590028900658479105",
   "Positive": "0.08876846730709076",
   "Negative": "0.001818114542402327",
   "Neutral": "0.9093629717826843",
   "Mixed": "5.03726550959982e-05"
 },
 {
   "tweet_id": "1590028900255830017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028896355119104",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590028887651938305",
   "Positive": "0.9632375836372375",
   "Negative": "0.00021742070384789258",
   "Neutral": "0.03651873394846916",
   "Mixed": "2.6348016035626642e-05"
 },
 {
   "tweet_id": "1590028856135942144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028854906982400",
   "Positive": "0.0817657858133316",
   "Negative": "0.0015155712608247995",
   "Neutral": "0.9166820645332336",
   "Mixed": "3.661114169517532e-05"
 },
 {
   "tweet_id": "1590028844249276416",
   "Positive": "0.9939466118812561",
   "Negative": "0.00019948408589698374",
   "Neutral": "0.005828412249684334",
   "Mixed": "2.5485936930635944e-05"
 },
 {
   "tweet_id": "1590028821503578117",
   "Positive": "0.006246970035135746",
   "Negative": "0.0001124499540310353",
   "Neutral": "0.993621289730072",
   "Mixed": "1.933138264575973e-05"
 },
 {
   "tweet_id": "1590028820433620992",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590028815426007042",
   "Positive": "0.2994314432144165",
   "Negative": "0.012884235940873623",
   "Neutral": "0.6874542236328125",
   "Mixed": "0.000230115168960765"
 },
 {
   "tweet_id": "1590028814574571520",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1590028798002855936",
   "Positive": "0.40762442350387573",
   "Negative": "0.00021722412202507257",
   "Neutral": "0.5921075344085693",
   "Mixed": "5.079839320387691e-05"
 },
 {
   "tweet_id": "1590028796417421312",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590028789786214400",
   "Positive": "0.6806077361106873",
   "Negative": "0.004117551259696484",
   "Neutral": "0.3149621784687042",
   "Mixed": "0.00031248413142748177"
 },
 {
   "tweet_id": "1590028763768975360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028760757456896",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590028754256289794",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028753400262656",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590028746592948224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028733666430976",
   "Positive": "0.0860263928771019",
   "Negative": "0.004175552166998386",
   "Neutral": "0.9097346663475037",
   "Mixed": "6.33419185760431e-05"
 },
 {
   "tweet_id": "1590028732651032576",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590028728847196160",
   "Positive": "0.8921368718147278",
   "Negative": "0.0010946894763037562",
   "Neutral": "0.10588831454515457",
   "Mixed": "0.0008801145013421774"
 },
 {
   "tweet_id": "1590028722438295553",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590028721159036928",
   "Positive": "0.1879892796278",
   "Negative": "0.00217764126136899",
   "Neutral": "0.8096298575401306",
   "Mixed": "0.00020326739468146116"
 },
 {
   "tweet_id": "1590028721041600513",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590028720395329537",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590028714683039744",
   "Positive": "0.9696474075317383",
   "Negative": "0.0005392874008975923",
   "Neutral": "0.029756149277091026",
   "Mixed": "5.714904182241298e-05"
 },
 {
   "tweet_id": "1590028711927377920",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590028711604076544",
   "Positive": "0.009926908649504185",
   "Negative": "0.20280946791172028",
   "Neutral": "0.787245512008667",
   "Mixed": "1.812411028367933e-05"
 },
 {
   "tweet_id": "1590028703668400128",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590028683594858497",
   "Positive": "0.10153762996196747",
   "Negative": "0.004432718735188246",
   "Neutral": "0.8939739465713501",
   "Mixed": "5.573864109464921e-05"
 },
 {
   "tweet_id": "1590028681040494594",
   "Positive": "0.044676631689071655",
   "Negative": "0.0001370437821606174",
   "Neutral": "0.9550362825393677",
   "Mixed": "0.00015007871843408793"
 },
 {
   "tweet_id": "1590028668033970179",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590028662795300864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028656889704448",
   "Positive": "0.07302384078502655",
   "Negative": "0.0008169705397449434",
   "Neutral": "0.9261415004730225",
   "Mixed": "1.7615371689316817e-05"
 },
 {
   "tweet_id": "1590028655589462016",
   "Positive": "0.8960756063461304",
   "Negative": "0.001551149063743651",
   "Neutral": "0.10229679197072983",
   "Mixed": "7.653728971490636e-05"
 },
 {
   "tweet_id": "1590028643333328896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028636622848000",
   "Positive": "0.06637527793645859",
   "Negative": "0.09411074966192245",
   "Neutral": "0.8394415974617004",
   "Mixed": "7.237086538225412e-05"
 },
 {
   "tweet_id": "1590028621963726848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028600958685186",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590028593278906369",
   "Positive": "0.14998100697994232",
   "Negative": "0.0011819250648841262",
   "Neutral": "0.8487818837165833",
   "Mixed": "5.5201162467710674e-05"
 },
 {
   "tweet_id": "1590028586547056642",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590028574391926785",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028573095899136",
   "Positive": "0.014944297261536121",
   "Negative": "0.0002862399851437658",
   "Neutral": "0.984747052192688",
   "Mixed": "2.2365547920344397e-05"
 },
 {
   "tweet_id": "1590028566724415488",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590028542947250176",
   "Positive": "0.035316452383995056",
   "Negative": "0.000245816569076851",
   "Neutral": "0.9644253253936768",
   "Mixed": "1.2399625120451674e-05"
 },
 {
   "tweet_id": "1590028538841026560",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590028529919733761",
   "Positive": "0.2750169634819031",
   "Negative": "0.010509718209505081",
   "Neutral": "0.7143603563308716",
   "Mixed": "0.00011290318798273802"
 },
 {
   "tweet_id": "1590028525636984832",
   "Positive": "0.9897118806838989",
   "Negative": "5.979137131362222e-05",
   "Neutral": "0.010215582326054573",
   "Mixed": "1.2763017366523854e-05"
 },
 {
   "tweet_id": "1590028511234101251",
   "Positive": "0.015016859397292137",
   "Negative": "7.463377551175654e-05",
   "Neutral": "0.9848936200141907",
   "Mixed": "1.493877516622888e-05"
 },
 {
   "tweet_id": "1590028505332318210",
   "Positive": "0.009125487878918648",
   "Negative": "0.11382003128528595",
   "Neutral": "0.10661308467388153",
   "Mixed": "0.7704413533210754"
 },
 {
   "tweet_id": "1590028492691091456",
   "Positive": "0.10614791512489319",
   "Negative": "0.001421883818693459",
   "Neutral": "0.8924015760421753",
   "Mixed": "2.862314067897387e-05"
 },
 {
   "tweet_id": "1590028484570931200",
   "Positive": "0.044674862176179886",
   "Negative": "0.0008613390964455903",
   "Neutral": "0.9544439911842346",
   "Mixed": "1.9763157979468815e-05"
 },
 {
   "tweet_id": "1590028471396618240",
   "Positive": "0.15349389612674713",
   "Negative": "0.0013279792619869113",
   "Neutral": "0.8451569676399231",
   "Mixed": "2.1122012185514905e-05"
 },
 {
   "tweet_id": "1590028468301205505",
   "Positive": "0.005093996413052082",
   "Negative": "0.6643849611282349",
   "Neutral": "0.3296566903591156",
   "Mixed": "0.0008644268964417279"
 },
 {
   "tweet_id": "1590028465583296512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028453352419330",
   "Positive": "0.0011405034456402063",
   "Negative": "0.0010315511608496308",
   "Neutral": "0.9978190660476685",
   "Mixed": "8.935510777519085e-06"
 },
 {
   "tweet_id": "1590028447954661376",
   "Positive": "0.0258070956915617",
   "Negative": "0.0001419564796378836",
   "Neutral": "0.9739245772361755",
   "Mixed": "0.00012641715875361115"
 },
 {
   "tweet_id": "1590028442137157634",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028437666033664",
   "Positive": "0.0030347041320055723",
   "Negative": "9.799190593184903e-05",
   "Neutral": "0.9968630075454712",
   "Mixed": "4.234172592987306e-06"
 },
 {
   "tweet_id": "1590028434281222145",
   "Positive": "0.8116610050201416",
   "Negative": "0.001084754941985011",
   "Neutral": "0.18489187955856323",
   "Mixed": "0.0023624340537935495"
 },
 {
   "tweet_id": "1590028425871650817",
   "Positive": "0.0692516565322876",
   "Negative": "0.0005122118745930493",
   "Neutral": "0.9302069544792175",
   "Mixed": "2.9178896511439234e-05"
 },
 {
   "tweet_id": "1590028411787001860",
   "Positive": "0.017367586493492126",
   "Negative": "0.0034556598402559757",
   "Neutral": "0.9791704416275024",
   "Mixed": "6.261262115003774e-06"
 },
 {
   "tweet_id": "1590028411434827776",
   "Positive": "0.9849798083305359",
   "Negative": "0.00010965149704134092",
   "Neutral": "0.014825861901044846",
   "Mixed": "8.470116881653666e-05"
 },
 {
   "tweet_id": "1590028409245401088",
   "Positive": "0.016924038529396057",
   "Negative": "0.0004060354840476066",
   "Neutral": "0.9824458360671997",
   "Mixed": "0.00022400562011171132"
 },
 {
   "tweet_id": "1590028407739650048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028407244746752",
   "Positive": "0.2840530574321747",
   "Negative": "0.016431087628006935",
   "Neutral": "0.699123740196228",
   "Mixed": "0.0003921503957826644"
 },
 {
   "tweet_id": "1590028391272812544",
   "Positive": "0.006263598799705505",
   "Negative": "0.000283034605672583",
   "Neutral": "0.9934493899345398",
   "Mixed": "4.0565964809502475e-06"
 },
 {
   "tweet_id": "1590028387346976768",
   "Positive": "0.00837408285588026",
   "Negative": "0.5602741837501526",
   "Neutral": "0.42268455028533936",
   "Mixed": "0.008667130023241043"
 },
 {
   "tweet_id": "1590028384343851010",
   "Positive": "0.2420850396156311",
   "Negative": "0.001449479372240603",
   "Neutral": "0.7564380764961243",
   "Mixed": "2.7420572223491035e-05"
 },
 {
   "tweet_id": "1590028378245332994",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590028373874675712",
   "Positive": "0.11931009590625763",
   "Negative": "0.00015906599583104253",
   "Neutral": "0.8804756999015808",
   "Mixed": "5.505767694558017e-05"
 },
 {
   "tweet_id": "1590028369592455170",
   "Positive": "0.00016564455290790647",
   "Negative": "0.0014449945883825421",
   "Neutral": "0.9983831644058228",
   "Mixed": "6.210755600477569e-06"
 },
 {
   "tweet_id": "1590028364337008640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028351007510528",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590028347953725440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028345160667136",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590028338089066496",
   "Positive": "0.8064562082290649",
   "Negative": "0.0031627302523702383",
   "Neutral": "0.19032011926174164",
   "Mixed": "6.0959897382417694e-05"
 },
 {
   "tweet_id": "1590028332141535232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028332028301312",
   "Positive": "0.06397558003664017",
   "Negative": "0.6001954078674316",
   "Neutral": "0.32635754346847534",
   "Mixed": "0.009471556171774864"
 },
 {
   "tweet_id": "1590028327531995137",
   "Positive": "0.5354813933372498",
   "Negative": "0.04502903297543526",
   "Neutral": "0.40610358119010925",
   "Mixed": "0.013386044651269913"
 },
 {
   "tweet_id": "1590028323077632000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028313267142656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590028312373760000",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590028307868700672",
   "Positive": "0.07991532236337662",
   "Negative": "0.666934609413147",
   "Neutral": "0.25219830870628357",
   "Mixed": "0.0009517458383925259"
 },
 {
   "tweet_id": "1590028295051300865",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590028274100744195",
   "Positive": "0.0177213866263628",
   "Negative": "0.00028989050770178437",
   "Neutral": "0.9819808602333069",
   "Mixed": "7.849364010326099e-06"
 },
 {
   "tweet_id": "1590028258988666880",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590028233931722752",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590028230207377408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028222968000512",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590028214872969220",
   "Positive": "0.17232799530029297",
   "Negative": "0.02869022637605667",
   "Neutral": "0.7989605665206909",
   "Mixed": "2.128610503859818e-05"
 },
 {
   "tweet_id": "1590028203762253824",
   "Positive": "0.028220031410455704",
   "Negative": "0.2886117100715637",
   "Neutral": "0.5215859413146973",
   "Mixed": "0.161582350730896"
 },
 {
   "tweet_id": "1590028200574615553",
   "Positive": "0.3412551283836365",
   "Negative": "0.006860509514808655",
   "Neutral": "0.6517807245254517",
   "Mixed": "0.00010362043394707143"
 },
 {
   "tweet_id": "1590028199794475009",
   "Positive": "0.1580297350883484",
   "Negative": "0.32037413120269775",
   "Neutral": "0.5173271298408508",
   "Mixed": "0.004269011318683624"
 },
 {
   "tweet_id": "1590028197038821376",
   "Positive": "0.4818163216114044",
   "Negative": "0.044648148119449615",
   "Neutral": "0.4733034074306488",
   "Mixed": "0.00023206707555800676"
 },
 {
   "tweet_id": "1590028188440465410",
   "Positive": "0.5456540584564209",
   "Negative": "0.03427617996931076",
   "Neutral": "0.3882637619972229",
   "Mixed": "0.03180595487356186"
 },
 {
   "tweet_id": "1590028179447902210",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590028174351814657",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028166755921920",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028145151082496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028136166850560",
   "Positive": "0.8064562082290649",
   "Negative": "0.0031627302523702383",
   "Neutral": "0.19032011926174164",
   "Mixed": "6.0959897382417694e-05"
 },
 {
   "tweet_id": "1590028130034782208",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1590028116604628993",
   "Positive": "0.8801583647727966",
   "Negative": "0.001662477501668036",
   "Neutral": "0.11812882870435715",
   "Mixed": "5.032050466979854e-05"
 },
 {
   "tweet_id": "1590028116306849792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028105015783424",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590028098812391425",
   "Positive": "0.017267636954784393",
   "Negative": "0.0017611662624403834",
   "Neutral": "0.980964720249176",
   "Mixed": "6.429357199522201e-06"
 },
 {
   "tweet_id": "1590028095511494656",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590028087521165312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590028084375597056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590028072136642565",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1590028071213858816",
   "Positive": "0.0012308162404224277",
   "Negative": "0.9276316165924072",
   "Neutral": "0.07089265435934067",
   "Mixed": "0.00024500509607605636"
 },
 {
   "tweet_id": "1590028071197085697",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028063919984640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590028029782552577",
   "Positive": "0.05671079829335213",
   "Negative": "0.03179026022553444",
   "Neutral": "0.9114207625389099",
   "Mixed": "7.819651364115998e-05"
 },
 {
   "tweet_id": "1590028020773183488",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590027991932801025",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590027991786012673",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027959083356161",
   "Positive": "0.995046854019165",
   "Negative": "0.00010863598436117172",
   "Neutral": "0.004813999403268099",
   "Mixed": "3.049534461752046e-05"
 },
 {
   "tweet_id": "1590027957015564289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027940628434946",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027934156587009",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590027919115825153",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1590027915412271105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027913487077378",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027910857240578",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027901394878464",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590027888791019522",
   "Positive": "0.7115408182144165",
   "Negative": "0.0038650031201541424",
   "Neutral": "0.2845103442668915",
   "Mixed": "8.382983651245013e-05"
 },
 {
   "tweet_id": "1590027873246916608",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590027870604181505",
   "Positive": "0.006128818728029728",
   "Negative": "0.08413417637348175",
   "Neutral": "0.7179355025291443",
   "Mixed": "0.1918015331029892"
 },
 {
   "tweet_id": "1590027870478352385",
   "Positive": "0.7837576270103455",
   "Negative": "0.0025368728674948215",
   "Neutral": "0.21207314729690552",
   "Mixed": "0.0016323690069839358"
 },
 {
   "tweet_id": "1590027869983768577",
   "Positive": "0.006431330926716328",
   "Negative": "0.0007461377535946667",
   "Neutral": "0.9927998781204224",
   "Mixed": "2.2553840608452447e-05"
 },
 {
   "tweet_id": "1590027865310916608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027853638553600",
   "Positive": "0.43825048208236694",
   "Negative": "0.002487086458131671",
   "Neutral": "0.5592402219772339",
   "Mixed": "2.2101961803855374e-05"
 },
 {
   "tweet_id": "1590027845350621186",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027841500221441",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027831140298752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027826744680448",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590027816946761730",
   "Positive": "0.08965706825256348",
   "Negative": "0.0002480616676621139",
   "Neutral": "0.910038948059082",
   "Mixed": "5.588580825133249e-05"
 },
 {
   "tweet_id": "1590027816254730241",
   "Positive": "0.2779451012611389",
   "Negative": "0.01998332142829895",
   "Neutral": "0.7016766667366028",
   "Mixed": "0.00039490204653702676"
 },
 {
   "tweet_id": "1590027807685750784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027790304178176",
   "Positive": "0.0013734683161601424",
   "Negative": "0.0002278301544720307",
   "Neutral": "0.9983918070793152",
   "Mixed": "6.9290945248212665e-06"
 },
 {
   "tweet_id": "1590027786013458432",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590027781672697862",
   "Positive": "0.9534616470336914",
   "Negative": "0.0004996310453861952",
   "Neutral": "0.04518371447920799",
   "Mixed": "0.000854983925819397"
 },
 {
   "tweet_id": "1590027781467148290",
   "Positive": "0.0030674724839627743",
   "Negative": "0.44968828558921814",
   "Neutral": "0.5472250580787659",
   "Mixed": "1.9202938347007148e-05"
 },
 {
   "tweet_id": "1590027775033102336",
   "Positive": "0.22384023666381836",
   "Negative": "0.0016656846273690462",
   "Neutral": "0.7744691371917725",
   "Mixed": "2.495584703865461e-05"
 },
 {
   "tweet_id": "1590027766333726721",
   "Positive": "0.13012559711933136",
   "Negative": "0.008818448521196842",
   "Neutral": "0.8609574437141418",
   "Mixed": "9.850611968431622e-05"
 },
 {
   "tweet_id": "1590027757698027522",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590027750798413824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027736697176064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027723703226369",
   "Positive": "0.0016419639578089118",
   "Negative": "4.9414516979595646e-05",
   "Neutral": "0.9982959628105164",
   "Mixed": "1.2626544958038721e-05"
 },
 {
   "tweet_id": "1590027718045077504",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590027716803592192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027715272675329",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590027705302814720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027704971431936",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590027704773971968",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590027703612108801",
   "Positive": "0.0003301030083093792",
   "Negative": "0.00035024326643906534",
   "Neutral": "0.9993058443069458",
   "Mixed": "1.3812326869810931e-05"
 },
 {
   "tweet_id": "1590027695609741313",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590027679566528513",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590027670813040640",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590027647043915778",
   "Positive": "0.8781094551086426",
   "Negative": "0.00015618263569194824",
   "Neutral": "0.12169788032770157",
   "Mixed": "3.642403316916898e-05"
 },
 {
   "tweet_id": "1590027641981394944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027633571794944",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027622775685120",
   "Positive": "0.2531265616416931",
   "Negative": "0.008151964284479618",
   "Neutral": "0.738659679889679",
   "Mixed": "6.174817099235952e-05"
 },
 {
   "tweet_id": "1590027614185721857",
   "Positive": "0.0010664890287443995",
   "Negative": "0.9360804557800293",
   "Neutral": "0.06283774971961975",
   "Mixed": "1.5351817637565546e-05"
 },
 {
   "tweet_id": "1590027601913196544",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590027593335865346",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1590027586993807360",
   "Positive": "0.13339656591415405",
   "Negative": "0.2977105379104614",
   "Neutral": "0.3269815444946289",
   "Mixed": "0.2419114112854004"
 },
 {
   "tweet_id": "1590027568916627457",
   "Positive": "0.9968791007995605",
   "Negative": "6.441344885388389e-05",
   "Neutral": "0.0030365886632353067",
   "Mixed": "1.9919923943234608e-05"
 },
 {
   "tweet_id": "1590027566332903425",
   "Positive": "0.008766758255660534",
   "Negative": "0.005585299339145422",
   "Neutral": "0.9852206707000732",
   "Mixed": "0.0004272621590644121"
 },
 {
   "tweet_id": "1590027560284749824",
   "Positive": "0.08747788518667221",
   "Negative": "0.49604377150535583",
   "Neutral": "0.13988429307937622",
   "Mixed": "0.27659404277801514"
 },
 {
   "tweet_id": "1590027556124000256",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027549408514050",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027542517657600",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027537119608832",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027531763478530",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027526373801985",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027521785237505",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590027520514351114",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1590027508711567361",
   "Positive": "0.008810325525701046",
   "Negative": "0.009783963672816753",
   "Neutral": "0.9813217520713806",
   "Mixed": "8.403821266256273e-05"
 },
 {
   "tweet_id": "1590027505624580096",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590027502558511106",
   "Positive": "0.024720067158341408",
   "Negative": "0.0006302253459580243",
   "Neutral": "0.974643886089325",
   "Mixed": "5.855217295902548e-06"
 },
 {
   "tweet_id": "1590027502453686272",
   "Positive": "0.001061897142790258",
   "Negative": "0.981508195400238",
   "Neutral": "0.017421597614884377",
   "Mixed": "8.313265425385907e-06"
 },
 {
   "tweet_id": "1590027471558127616",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590027429187182592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027420584660993",
   "Positive": "0.03165650740265846",
   "Negative": "0.08160346746444702",
   "Neutral": "0.8851141929626465",
   "Mixed": "0.0016258584801107645"
 },
 {
   "tweet_id": "1590027420236939266",
   "Positive": "0.058085255324840546",
   "Negative": "0.2980440557003021",
   "Neutral": "0.6409781575202942",
   "Mixed": "0.0028925444930791855"
 },
 {
   "tweet_id": "1590027400519507969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027384371130368",
   "Positive": "0.0013822197215631604",
   "Negative": "0.7214931845664978",
   "Neutral": "0.2718547284603119",
   "Mixed": "0.005269867368042469"
 },
 {
   "tweet_id": "1590027369485856768",
   "Positive": "0.9972195625305176",
   "Negative": "5.88713628530968e-05",
   "Neutral": "0.002708153799176216",
   "Mixed": "1.3402472177403979e-05"
 },
 {
   "tweet_id": "1590027358991704064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027345678983168",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027326888509440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027324367724544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027314091679746",
   "Positive": "0.4818163216114044",
   "Negative": "0.044648148119449615",
   "Neutral": "0.4733034074306488",
   "Mixed": "0.00023206707555800676"
 },
 {
   "tweet_id": "1590027281074130944",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590027279438344193",
   "Positive": "0.8714563846588135",
   "Negative": "0.0009139584726653993",
   "Neutral": "0.12752436101436615",
   "Mixed": "0.00010522621596464887"
 },
 {
   "tweet_id": "1590027274052866048",
   "Positive": "0.0024424216244369745",
   "Negative": "0.00020853955356869847",
   "Neutral": "0.9973456263542175",
   "Mixed": "3.5010034480364993e-06"
 },
 {
   "tweet_id": "1590027269103587328",
   "Positive": "0.1102840006351471",
   "Negative": "0.002899325918406248",
   "Neutral": "0.8867784738540649",
   "Mixed": "3.8110338209662586e-05"
 },
 {
   "tweet_id": "1590027262333964289",
   "Positive": "0.021824998781085014",
   "Negative": "0.005976038984954357",
   "Neutral": "0.9718958735466003",
   "Mixed": "0.00030313912429846823"
 },
 {
   "tweet_id": "1590027261868412928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027236765159424",
   "Positive": "0.9972195625305176",
   "Negative": "5.88713628530968e-05",
   "Neutral": "0.002708153799176216",
   "Mixed": "1.3402472177403979e-05"
 },
 {
   "tweet_id": "1590027220923592704",
   "Positive": "0.6980983018875122",
   "Negative": "0.0016432367265224457",
   "Neutral": "0.3001181483268738",
   "Mixed": "0.00014032010221853852"
 },
 {
   "tweet_id": "1590027217421369344",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590027215340994562",
   "Positive": "0.0008434991468675435",
   "Negative": "0.8262884020805359",
   "Neutral": "0.17281049489974976",
   "Mixed": "5.7630772062111646e-05"
 },
 {
   "tweet_id": "1590027190259052544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590027169027493889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590027148244692992",
   "Positive": "0.43831226229667664",
   "Negative": "0.0037842357996851206",
   "Neutral": "0.5551629066467285",
   "Mixed": "0.0027405109722167253"
 },
 {
   "tweet_id": "1590027140875292672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027135238144000",
   "Positive": "0.9952334761619568",
   "Negative": "5.8350953622721136e-05",
   "Neutral": "0.004693570081144571",
   "Mixed": "1.4613567145715933e-05"
 },
 {
   "tweet_id": "1590027132402794501",
   "Positive": "0.2285342514514923",
   "Negative": "0.002473188331350684",
   "Neutral": "0.7689741253852844",
   "Mixed": "1.8396649466012605e-05"
 },
 {
   "tweet_id": "1590027105769000961",
   "Positive": "0.8493844270706177",
   "Negative": "0.008845788426697254",
   "Neutral": "0.12900176644325256",
   "Mixed": "0.012767987325787544"
 },
 {
   "tweet_id": "1590027101696323585",
   "Positive": "0.05901940166950226",
   "Negative": "0.0007611981709487736",
   "Neutral": "0.9402035474777222",
   "Mixed": "1.581172000442166e-05"
 },
 {
   "tweet_id": "1590027092389167106",
   "Positive": "0.18793310225009918",
   "Negative": "0.12102990597486496",
   "Neutral": "0.669708788394928",
   "Mixed": "0.021328255534172058"
 },
 {
   "tweet_id": "1590027090836918275",
   "Positive": "0.04500362277030945",
   "Negative": "0.003550443332642317",
   "Neutral": "0.9514373540878296",
   "Mixed": "8.576043910579756e-06"
 },
 {
   "tweet_id": "1590027075309932550",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590027060957057025",
   "Positive": "0.7124523520469666",
   "Negative": "0.0006412954535335302",
   "Neutral": "0.2868313491344452",
   "Mixed": "7.498031482100487e-05"
 },
 {
   "tweet_id": "1590027053394718720",
   "Positive": "0.9662553071975708",
   "Negative": "0.00031402503373101354",
   "Neutral": "0.033417172729969025",
   "Mixed": "1.355515360046411e-05"
 },
 {
   "tweet_id": "1590027039465443330",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590027017109766144",
   "Positive": "0.000417862058384344",
   "Negative": "0.0002862878027372062",
   "Neutral": "0.9992889165878296",
   "Mixed": "6.871905952721136e-06"
 },
 {
   "tweet_id": "1590026994754162688",
   "Positive": "0.9279724955558777",
   "Negative": "0.00031704254797659814",
   "Neutral": "0.07115184515714645",
   "Mixed": "0.0005585416802205145"
 },
 {
   "tweet_id": "1590026987506405377",
   "Positive": "0.8064562082290649",
   "Negative": "0.0031627302523702383",
   "Neutral": "0.19032011926174164",
   "Mixed": "6.0959897382417694e-05"
 },
 {
   "tweet_id": "1590026973622910976",
   "Positive": "0.9963441491127014",
   "Negative": "4.872353019891307e-05",
   "Neutral": "0.0035932958126068115",
   "Mixed": "1.3809423762722872e-05"
 },
 {
   "tweet_id": "1590026969886121987",
   "Positive": "0.022490035742521286",
   "Negative": "0.2501862943172455",
   "Neutral": "0.7119136452674866",
   "Mixed": "0.015410002321004868"
 },
 {
   "tweet_id": "1590026964588367872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026938110050305",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026934746247170",
   "Positive": "0.0167800672352314",
   "Negative": "0.12765219807624817",
   "Neutral": "0.6072115302085876",
   "Mixed": "0.24835622310638428"
 },
 {
   "tweet_id": "1590026934485803008",
   "Positive": "0.006170324515551329",
   "Negative": "0.09710637480020523",
   "Neutral": "0.8966885805130005",
   "Mixed": "3.474228651612066e-05"
 },
 {
   "tweet_id": "1590026923941695491",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026899778338818",
   "Positive": "0.0009934219997376204",
   "Negative": "0.954524576663971",
   "Neutral": "0.044345613569021225",
   "Mixed": "0.0001365123753203079"
 },
 {
   "tweet_id": "1590026895357263874",
   "Positive": "0.0010983776301145554",
   "Negative": "0.0010534237371757627",
   "Neutral": "0.9978463649749756",
   "Mixed": "1.8288500314156408e-06"
 },
 {
   "tweet_id": "1590026894111821825",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026892006273025",
   "Positive": "0.001472652773372829",
   "Negative": "0.000113207584945485",
   "Neutral": "0.998407781124115",
   "Mixed": "6.376242708938662e-06"
 },
 {
   "tweet_id": "1590026888063643649",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026881537302529",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026873626849280",
   "Positive": "0.019344070926308632",
   "Negative": "0.0004391537222545594",
   "Neutral": "0.9802088737487793",
   "Mixed": "7.89592922956217e-06"
 },
 {
   "tweet_id": "1590026867687706625",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590026858758033409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026830480044032",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590026807297724419",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590026804999643139",
   "Positive": "0.4867969751358032",
   "Negative": "0.0030130967497825623",
   "Neutral": "0.5099663138389587",
   "Mixed": "0.0002236214349977672"
 },
 {
   "tweet_id": "1590026797349244929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026788427960321",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026787173826561",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026787006083072",
   "Positive": "0.22631756961345673",
   "Negative": "0.33582401275634766",
   "Neutral": "0.4268450438976288",
   "Mixed": "0.011013349518179893"
 },
 {
   "tweet_id": "1590026776704880640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026769478082560",
   "Positive": "0.01719040796160698",
   "Negative": "0.8158992528915405",
   "Neutral": "0.1666961908340454",
   "Mixed": "0.00021406495943665504"
 },
 {
   "tweet_id": "1590026768253349889",
   "Positive": "0.004465526435524225",
   "Negative": "0.00011693209671648219",
   "Neutral": "0.995406985282898",
   "Mixed": "1.0524139725021087e-05"
 },
 {
   "tweet_id": "1590026766307176448",
   "Positive": "0.001659694593399763",
   "Negative": "0.000521139067132026",
   "Neutral": "0.997799813747406",
   "Mixed": "1.939446883625351e-05"
 },
 {
   "tweet_id": "1590026722824814592",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1590026722581544960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026721826607106",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590026712687181824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026700166864896",
   "Positive": "0.11339215934276581",
   "Negative": "0.09440415352582932",
   "Neutral": "0.5899121165275574",
   "Mixed": "0.2022915482521057"
 },
 {
   "tweet_id": "1590026695641550849",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026686011101188",
   "Positive": "0.0001573294139234349",
   "Negative": "0.9854968190193176",
   "Neutral": "0.014338243752717972",
   "Mixed": "7.67180335969897e-06"
 },
 {
   "tweet_id": "1590026681561288706",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026678818189313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026674556796928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026674308927488",
   "Positive": "0.9082012176513672",
   "Negative": "0.0007010324625298381",
   "Neutral": "0.09098067134618759",
   "Mixed": "0.00011710781836882234"
 },
 {
   "tweet_id": "1590026636652859394",
   "Positive": "0.8548576831817627",
   "Negative": "0.00018167264352086931",
   "Neutral": "0.14470228552818298",
   "Mixed": "0.00025837370776571333"
 },
 {
   "tweet_id": "1590026628020961280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026621099966464",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1590026600774791176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026597037244416",
   "Positive": "0.015710990875959396",
   "Negative": "0.3626432716846466",
   "Neutral": "0.6212528944015503",
   "Mixed": "0.00039284068043343723"
 },
 {
   "tweet_id": "1590026593858387971",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590026580621168640",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026580080103425",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026566737997824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026558122885122",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590026543124054016",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590026532092715008",
   "Positive": "0.00944232102483511",
   "Negative": "0.00036084497696720064",
   "Neutral": "0.9901935458183289",
   "Mixed": "3.3414630706829485e-06"
 },
 {
   "tweet_id": "1590026510991515648",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1590026501021659136",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590026494113218561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026492201013248",
   "Positive": "0.3653293251991272",
   "Negative": "0.018459919840097427",
   "Neutral": "0.6159781813621521",
   "Mixed": "0.00023258922738023102"
 },
 {
   "tweet_id": "1590026479156355074",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026477172838400",
   "Positive": "0.684502899646759",
   "Negative": "0.004183873068541288",
   "Neutral": "0.3112240135669708",
   "Mixed": "8.923071436583996e-05"
 },
 {
   "tweet_id": "1590026453386948609",
   "Positive": "0.024773254990577698",
   "Negative": "0.0006520167225971818",
   "Neutral": "0.9745332598686218",
   "Mixed": "4.1418010368943214e-05"
 },
 {
   "tweet_id": "1590026442846662657",
   "Positive": "0.07349757105112076",
   "Negative": "0.009990466758608818",
   "Neutral": "0.9024478197097778",
   "Mixed": "0.014064216986298561"
 },
 {
   "tweet_id": "1590026401075580930",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590026395308421123",
   "Positive": "0.010953477583825588",
   "Negative": "0.0012692303862422705",
   "Neutral": "0.987762451171875",
   "Mixed": "1.4831823136773892e-05"
 },
 {
   "tweet_id": "1590026379575558145",
   "Positive": "0.006222269497811794",
   "Negative": "0.6713811755180359",
   "Neutral": "0.3066052198410034",
   "Mixed": "0.015791382640600204"
 },
 {
   "tweet_id": "1590026375876218880",
   "Positive": "0.05902750417590141",
   "Negative": "0.018536297604441643",
   "Neutral": "0.9223893284797668",
   "Mixed": "4.6815523091936484e-05"
 },
 {
   "tweet_id": "1590026372570906626",
   "Positive": "0.0018519045552238822",
   "Negative": "0.0863204374909401",
   "Neutral": "0.9116898775100708",
   "Mixed": "0.00013779463188257068"
 },
 {
   "tweet_id": "1590026371220221954",
   "Positive": "0.6023133993148804",
   "Negative": "0.0021517062559723854",
   "Neutral": "0.39549121260643005",
   "Mixed": "4.374097989057191e-05"
 },
 {
   "tweet_id": "1590026367025954819",
   "Positive": "0.5854874849319458",
   "Negative": "0.0022126492112874985",
   "Neutral": "0.41226625442504883",
   "Mixed": "3.3683365472825244e-05"
 },
 {
   "tweet_id": "1590026364215734276",
   "Positive": "0.00036513927625492215",
   "Negative": "7.744382310193032e-05",
   "Neutral": "0.9995508790016174",
   "Mixed": "6.4947139435389545e-06"
 },
 {
   "tweet_id": "1590026362370535425",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590026360373682178",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590026351071100928",
   "Positive": "0.017717769369482994",
   "Negative": "0.7878684401512146",
   "Neutral": "0.1939578503370285",
   "Mixed": "0.00045593298273161054"
 },
 {
   "tweet_id": "1590026345131966464",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026339687755776",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590026335589912576",
   "Positive": "0.007216654252260923",
   "Negative": "0.2942920923233032",
   "Neutral": "0.6830461025238037",
   "Mixed": "0.015445064753293991"
 },
 {
   "tweet_id": "1590026331609530369",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590026328962895874",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026325909467136",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590026324483411968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026322843414528",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590026300143546368",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1590026297589534720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026292115963904",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1590026290585014277",
   "Positive": "0.0035125070717185736",
   "Negative": "0.06695427000522614",
   "Neutral": "0.2562708556652069",
   "Mixed": "0.673262357711792"
 },
 {
   "tweet_id": "1590026290132045824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026286411689985",
   "Positive": "0.45654207468032837",
   "Negative": "0.002449398161843419",
   "Neutral": "0.5409465432167053",
   "Mixed": "6.195047899382189e-05"
 },
 {
   "tweet_id": "1590026275493916674",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590026262499987456",
   "Positive": "0.9944639801979065",
   "Negative": "0.0001038074551615864",
   "Neutral": "0.005362085998058319",
   "Mixed": "7.007625390542671e-05"
 },
 {
   "tweet_id": "1590026254681800704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026254027481088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026249136910336",
   "Positive": "0.06769094616174698",
   "Negative": "0.021270129829645157",
   "Neutral": "0.910943865776062",
   "Mixed": "9.502073953626677e-05"
 },
 {
   "tweet_id": "1590026228538699776",
   "Positive": "0.0880860909819603",
   "Negative": "0.09227756410837173",
   "Neutral": "0.8191761374473572",
   "Mixed": "0.0004601114196702838"
 },
 {
   "tweet_id": "1590026222884786176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590026220053614592",
   "Positive": "0.8413893580436707",
   "Negative": "0.00018030908540822566",
   "Neutral": "0.15839582681655884",
   "Mixed": "3.449401265243068e-05"
 },
 {
   "tweet_id": "1590026197408567296",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590026184913719297",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026179989639168",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590026169134747649",
   "Positive": "0.0009219296625815332",
   "Negative": "0.89726722240448",
   "Neutral": "0.1009766235947609",
   "Mixed": "0.0008341834763996303"
 },
 {
   "tweet_id": "1590026168857919489",
   "Positive": "0.9875643849372864",
   "Negative": "4.278023698134348e-05",
   "Neutral": "0.012365633621811867",
   "Mixed": "2.7255706299911253e-05"
 },
 {
   "tweet_id": "1590026147303395328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590026137941725184",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590026131159535619",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590026097621893120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590026087517786112",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590026080345550851",
   "Positive": "0.03288813680410385",
   "Negative": "0.010359854437410831",
   "Neutral": "0.024223199114203453",
   "Mixed": "0.9325288534164429"
 },
 {
   "tweet_id": "1590026076952358913",
   "Positive": "0.27119824290275574",
   "Negative": "0.0011387255508452654",
   "Neutral": "0.7275590300559998",
   "Mixed": "0.00010399848542874679"
 },
 {
   "tweet_id": "1590026061282439168",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1590026051467771904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590026048686948355",
   "Positive": "0.056970302015542984",
   "Negative": "0.0006034682737663388",
   "Neutral": "0.9424185752868652",
   "Mixed": "7.660561095690355e-06"
 },
 {
   "tweet_id": "1590026047978098689",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590026044991733762",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025997319303170",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1590025991506001920",
   "Positive": "0.22384023666381836",
   "Negative": "0.0016656846273690462",
   "Neutral": "0.7744691371917725",
   "Mixed": "2.495584703865461e-05"
 },
 {
   "tweet_id": "1590025979631923200",
   "Positive": "0.1743571013212204",
   "Negative": "0.0045745340175926685",
   "Neutral": "0.8210480809211731",
   "Mixed": "2.0279807358747348e-05"
 },
 {
   "tweet_id": "1590025972824571904",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025968714158081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025948375961600",
   "Positive": "0.027671001851558685",
   "Negative": "0.5745271444320679",
   "Neutral": "0.39710670709609985",
   "Mixed": "0.0006952119874767959"
 },
 {
   "tweet_id": "1590025944990994436",
   "Positive": "0.21920496225357056",
   "Negative": "0.027021126821637154",
   "Neutral": "0.753596305847168",
   "Mixed": "0.00017759685579221696"
 },
 {
   "tweet_id": "1590025941518258177",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025939148165120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025918856458240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025896735682561",
   "Positive": "0.004369774367660284",
   "Negative": "0.2215045690536499",
   "Neutral": "0.7516204118728638",
   "Mixed": "0.022505182772874832"
 },
 {
   "tweet_id": "1590025896513404929",
   "Positive": "0.02988816611468792",
   "Negative": "0.00015586562221869826",
   "Neutral": "0.9699381589889526",
   "Mixed": "1.784896812750958e-05"
 },
 {
   "tweet_id": "1590025890225745920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025882743476225",
   "Positive": "0.1729508340358734",
   "Negative": "0.021800648421049118",
   "Neutral": "0.8050528764724731",
   "Mixed": "0.00019566674018278718"
 },
 {
   "tweet_id": "1590025878209449984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025849889509376",
   "Positive": "0.8781094551086426",
   "Negative": "0.00015618263569194824",
   "Neutral": "0.12169788032770157",
   "Mixed": "3.642403316916898e-05"
 },
 {
   "tweet_id": "1590025828863447042",
   "Positive": "0.007905498147010803",
   "Negative": "0.24475345015525818",
   "Neutral": "0.729827344417572",
   "Mixed": "0.017513684928417206"
 },
 {
   "tweet_id": "1590025811486470144",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590025781484617732",
   "Positive": "0.8064562082290649",
   "Negative": "0.0031627302523702383",
   "Neutral": "0.19032011926174164",
   "Mixed": "6.0959897382417694e-05"
 },
 {
   "tweet_id": "1590025768230219778",
   "Positive": "0.012838871218264103",
   "Negative": "0.8035365343093872",
   "Neutral": "0.1809692084789276",
   "Mixed": "0.0026553410571068525"
 },
 {
   "tweet_id": "1590025764896145411",
   "Positive": "0.00033494658418931067",
   "Negative": "0.09716366231441498",
   "Neutral": "0.9024901390075684",
   "Mixed": "1.1343659934937023e-05"
 },
 {
   "tweet_id": "1590025758252363776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025757493170179",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025755551240194",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025754339074048",
   "Positive": "0.14998100697994232",
   "Negative": "0.0011819250648841262",
   "Neutral": "0.8487818837165833",
   "Mixed": "5.5201162467710674e-05"
 },
 {
   "tweet_id": "1590025742733414400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025733782441984",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025729462661120",
   "Positive": "0.0006533469422720373",
   "Negative": "0.6777242422103882",
   "Neutral": "0.3212193250656128",
   "Mixed": "0.0004030331620015204"
 },
 {
   "tweet_id": "1590025722893979648",
   "Positive": "9.552718256600201e-05",
   "Negative": "0.00024245886015705764",
   "Neutral": "0.9996528625488281",
   "Mixed": "9.150222467724234e-06"
 },
 {
   "tweet_id": "1590025700928409600",
   "Positive": "0.0012034147512167692",
   "Negative": "0.2173573523759842",
   "Neutral": "0.7812281847000122",
   "Mixed": "0.00021103098697494715"
 },
 {
   "tweet_id": "1590025699540099074",
   "Positive": "0.02252062037587166",
   "Negative": "0.12655162811279297",
   "Neutral": "0.8509043455123901",
   "Mixed": "2.345388929825276e-05"
 },
 {
   "tweet_id": "1590025693638717440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025652316434432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025651033378816",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590025642891825157",
   "Positive": "0.02126419171690941",
   "Negative": "8.845686534186825e-05",
   "Neutral": "0.9785934686660767",
   "Mixed": "5.38782769581303e-05"
 },
 {
   "tweet_id": "1590025637250494464",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590025636797878272",
   "Positive": "0.008448959328234196",
   "Negative": "0.26417383551597595",
   "Neutral": "0.7056812644004822",
   "Mixed": "0.021696049720048904"
 },
 {
   "tweet_id": "1590025631521476608",
   "Positive": "0.04451413452625275",
   "Negative": "0.36218777298927307",
   "Neutral": "0.5808576345443726",
   "Mixed": "0.012440462596714497"
 },
 {
   "tweet_id": "1590025619324436480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025615339835394",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590025615209816065",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590025614400294915",
   "Positive": "0.9849798083305359",
   "Negative": "0.00010965149704134092",
   "Neutral": "0.014825861901044846",
   "Mixed": "8.470116881653666e-05"
 },
 {
   "tweet_id": "1590025611095183360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025604811759617",
   "Positive": "0.0007941170479170978",
   "Negative": "0.002318189712241292",
   "Neutral": "0.9957373142242432",
   "Mixed": "0.0011503000278025866"
 },
 {
   "tweet_id": "1590025589074731009",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590025587149590528",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590025577536552960",
   "Positive": "0.008448959328234196",
   "Negative": "0.26417383551597595",
   "Neutral": "0.7056812644004822",
   "Mixed": "0.021696049720048904"
 },
 {
   "tweet_id": "1590025573874962434",
   "Positive": "0.5101143717765808",
   "Negative": "0.009272219613194466",
   "Neutral": "0.480551540851593",
   "Mixed": "6.188680708874017e-05"
 },
 {
   "tweet_id": "1590025570322378754",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025566303895552",
   "Positive": "0.020994845777750015",
   "Negative": "0.00137483817525208",
   "Neutral": "0.9775429368019104",
   "Mixed": "8.742370118852705e-05"
 },
 {
   "tweet_id": "1590025564840394754",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590025561774387200",
   "Positive": "0.9660684466362",
   "Negative": "0.00010701205610530451",
   "Neutral": "0.03380080312490463",
   "Mixed": "2.3777429305482656e-05"
 },
 {
   "tweet_id": "1590025547870048257",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590025532691054592",
   "Positive": "0.4293190538883209",
   "Negative": "0.026738211512565613",
   "Neutral": "0.4925777018070221",
   "Mixed": "0.05136498808860779"
 },
 {
   "tweet_id": "1590025523148853249",
   "Positive": "0.0022875277791172266",
   "Negative": "8.594101382186636e-05",
   "Neutral": "0.9976209998130798",
   "Mixed": "5.480602339957841e-06"
 },
 {
   "tweet_id": "1590025515892887552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025505260335106",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025484846628866",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025481851899904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025457206202368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025447278284800",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590025445072044032",
   "Positive": "0.4641444683074951",
   "Negative": "0.0001941881637321785",
   "Neutral": "0.5355833172798157",
   "Mixed": "7.805036875652149e-05"
 },
 {
   "tweet_id": "1590025444979781632",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1590025440856801280",
   "Positive": "0.054046470671892166",
   "Negative": "0.0011274805292487144",
   "Neutral": "0.9447205066680908",
   "Mixed": "0.00010553218453424051"
 },
 {
   "tweet_id": "1590025432413634560",
   "Positive": "0.0639725998044014",
   "Negative": "0.0027272524312138557",
   "Neutral": "0.9332822561264038",
   "Mixed": "1.7914966520038433e-05"
 },
 {
   "tweet_id": "1590025424578678786",
   "Positive": "0.04376545548439026",
   "Negative": "0.0003617125912569463",
   "Neutral": "0.9558547735214233",
   "Mixed": "1.810530920920428e-05"
 },
 {
   "tweet_id": "1590025412083847169",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025403087093760",
   "Positive": "0.7966354489326477",
   "Negative": "0.0032980546820908785",
   "Neutral": "0.20000804960727692",
   "Mixed": "5.835611227666959e-05"
 },
 {
   "tweet_id": "1590025389573033984",
   "Positive": "0.24876564741134644",
   "Negative": "0.004197241738438606",
   "Neutral": "0.7469983100891113",
   "Mixed": "3.883959652739577e-05"
 },
 {
   "tweet_id": "1590025389321072640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025378453950465",
   "Positive": "0.021056419238448143",
   "Negative": "0.00013667120947502553",
   "Neutral": "0.9787836074829102",
   "Mixed": "2.331662290089298e-05"
 },
 {
   "tweet_id": "1590025371373576193",
   "Positive": "0.017240211367607117",
   "Negative": "0.0015133994165807962",
   "Neutral": "0.9812213182449341",
   "Mixed": "2.5165238184854388e-05"
 },
 {
   "tweet_id": "1590025361550557184",
   "Positive": "0.0016299292910844088",
   "Negative": "0.00012120122119085863",
   "Neutral": "0.9982442855834961",
   "Mixed": "4.6350087359314784e-06"
 },
 {
   "tweet_id": "1590025350284996609",
   "Positive": "0.058372728526592255",
   "Negative": "0.013622761704027653",
   "Neutral": "0.927975594997406",
   "Mixed": "2.8879401725134812e-05"
 },
 {
   "tweet_id": "1590025349001187329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025338578694150",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025328474591234",
   "Positive": "0.025220077484846115",
   "Negative": "0.0018080227309837937",
   "Neutral": "0.9729328155517578",
   "Mixed": "3.906308484147303e-05"
 },
 {
   "tweet_id": "1590025323378528256",
   "Positive": "0.00034138941555283964",
   "Negative": "0.014721549116075039",
   "Neutral": "0.9849317073822021",
   "Mixed": "5.450885964819463e-06"
 },
 {
   "tweet_id": "1590025321931501569",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590025321558204418",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025304788983808",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590025304600244224",
   "Positive": "0.04451413452625275",
   "Negative": "0.36218777298927307",
   "Neutral": "0.5808576345443726",
   "Mixed": "0.012440462596714497"
 },
 {
   "tweet_id": "1590025300854722560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025290876870656",
   "Positive": "0.006128818728029728",
   "Negative": "0.08413417637348175",
   "Neutral": "0.7179355025291443",
   "Mixed": "0.1918015331029892"
 },
 {
   "tweet_id": "1590025278554001409",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590025274506498049",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025267556519936",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025261075951617",
   "Positive": "0.0006117535522207618",
   "Negative": "0.00041661609429866076",
   "Neutral": "0.9989689588546753",
   "Mixed": "2.785298420349136e-06"
 },
 {
   "tweet_id": "1590025255480750081",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590025240209674241",
   "Positive": "0.4333550035953522",
   "Negative": "0.0005551844369620085",
   "Neutral": "0.5660494565963745",
   "Mixed": "4.0335304220207036e-05"
 },
 {
   "tweet_id": "1590025238070587393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025234845171712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025215962402816",
   "Positive": "0.16982373595237732",
   "Negative": "0.0026611650828272104",
   "Neutral": "0.8274900317192078",
   "Mixed": "2.50525408773683e-05"
 },
 {
   "tweet_id": "1590025213278060544",
   "Positive": "0.008448959328234196",
   "Negative": "0.26417383551597595",
   "Neutral": "0.7056812644004822",
   "Mixed": "0.021696049720048904"
 },
 {
   "tweet_id": "1590025210186850304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025210107133952",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590025209415086081",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590025201764696064",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590025192797274112",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590025185981497345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025163164164097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025152842313730",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590025150799675392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025143598080000",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590025130989989888",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590025125117988865",
   "Positive": "0.33672505617141724",
   "Negative": "0.013894234783947468",
   "Neutral": "0.6493026614189148",
   "Mixed": "7.80582195147872e-05"
 },
 {
   "tweet_id": "1590025124799209474",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590025102728761344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025102263033860",
   "Positive": "0.056023817509412766",
   "Negative": "0.05189720168709755",
   "Neutral": "0.4074210524559021",
   "Mixed": "0.4846579432487488"
 },
 {
   "tweet_id": "1590025086144114689",
   "Positive": "0.0066895210184156895",
   "Negative": "0.17135730385780334",
   "Neutral": "0.8217990398406982",
   "Mixed": "0.00015424330194946378"
 },
 {
   "tweet_id": "1590025085636997120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590025085196599296",
   "Positive": "0.4642395079135895",
   "Negative": "0.003620826406404376",
   "Neutral": "0.5320131778717041",
   "Mixed": "0.00012650206917896867"
 },
 {
   "tweet_id": "1590025060643131392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590025056641392640",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1590025038140346368",
   "Positive": "0.7002385854721069",
   "Negative": "0.0017503275303170085",
   "Neutral": "0.2956557869911194",
   "Mixed": "0.0023553872015327215"
 },
 {
   "tweet_id": "1590025026707017729",
   "Positive": "0.3901975750923157",
   "Negative": "0.001371233374811709",
   "Neutral": "0.6083628535270691",
   "Mixed": "6.83718899381347e-05"
 },
 {
   "tweet_id": "1590025026103017472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590025015818616833",
   "Positive": "0.022304946556687355",
   "Negative": "0.0012117924634367228",
   "Neutral": "0.9764743447303772",
   "Mixed": "8.96784240467241e-06"
 },
 {
   "tweet_id": "1590025011896913920",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590024999557267456",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590024989679374337",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590024976979365888",
   "Positive": "0.002364303218200803",
   "Negative": "9.658138878876343e-05",
   "Neutral": "0.9975323677062988",
   "Mixed": "6.7216433308203705e-06"
 },
 {
   "tweet_id": "1590024974869594113",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590024971325407233",
   "Positive": "0.056970302015542984",
   "Negative": "0.0006034682737663388",
   "Neutral": "0.9424185752868652",
   "Mixed": "7.660561095690355e-06"
 },
 {
   "tweet_id": "1590024969840627713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024949422764032",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590024931894767616",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590024929004883968",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590024917994524673",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590024908113084417",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024858150531073",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590024846578450433",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590024841373323266",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024838462439424",
   "Positive": "0.3921690285205841",
   "Negative": "0.003443050431087613",
   "Neutral": "0.5982779860496521",
   "Mixed": "0.006109853740781546"
 },
 {
   "tweet_id": "1590024801279946758",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590024795810586627",
   "Positive": "0.4188496470451355",
   "Negative": "0.0005959845730103552",
   "Neutral": "0.5800524950027466",
   "Mixed": "0.0005018346128053963"
 },
 {
   "tweet_id": "1590024789712048128",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590024787669430272",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590024783793917952",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024778563219456",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590024772003725312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024765221527553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024764482953220",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024764068081666",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024757508190215",
   "Positive": "0.0028097713366150856",
   "Negative": "0.11046700179576874",
   "Neutral": "0.8866323828697205",
   "Mixed": "9.084340126719326e-05"
 },
 {
   "tweet_id": "1590024749844865029",
   "Positive": "0.002737518399953842",
   "Negative": "0.12615200877189636",
   "Neutral": "0.8710305690765381",
   "Mixed": "7.985161937540397e-05"
 },
 {
   "tweet_id": "1590024749656469504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024746087108608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024735496491008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024721546227714",
   "Positive": "0.08806246519088745",
   "Negative": "0.14218109846115112",
   "Neutral": "0.7670890092849731",
   "Mixed": "0.0026674550026655197"
 },
 {
   "tweet_id": "1590024693209509888",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590024671688527872",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590024664331726849",
   "Positive": "0.03448738530278206",
   "Negative": "0.00011603815073613077",
   "Neutral": "0.9653105735778809",
   "Mixed": "8.596332190791145e-05"
 },
 {
   "tweet_id": "1590024660120633344",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590024638482231296",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024634686091264",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024628269092864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024623638224896",
   "Positive": "0.020776957273483276",
   "Negative": "0.3980501592159271",
   "Neutral": "0.5440502166748047",
   "Mixed": "0.037122659385204315"
 },
 {
   "tweet_id": "1590024619976974336",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590024612905373696",
   "Positive": "0.25244057178497314",
   "Negative": "0.00412756996229291",
   "Neutral": "0.743310272693634",
   "Mixed": "0.00012157203309470788"
 },
 {
   "tweet_id": "1590024601383292929",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1590024581880086528",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590024567707570176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024552142147584",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590024539865436160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024536430645248",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590024534761132035",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590024532295049217",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590024523801563136",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590024517656907776",
   "Positive": "0.04172081500291824",
   "Negative": "0.00011394052853574976",
   "Neutral": "0.9581460356712341",
   "Mixed": "1.923247145896312e-05"
 },
 {
   "tweet_id": "1590024512120459265",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024487894159360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024484337033216",
   "Positive": "0.0009542203042656183",
   "Negative": "0.00018933007959276438",
   "Neutral": "0.9988534450531006",
   "Mixed": "2.9999439448147314e-06"
 },
 {
   "tweet_id": "1590024475252510720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024471733473280",
   "Positive": "0.1561838984489441",
   "Negative": "0.09558442234992981",
   "Neutral": "0.698570728302002",
   "Mixed": "0.04966098070144653"
 },
 {
   "tweet_id": "1590024469539885057",
   "Positive": "0.005995914340019226",
   "Negative": "0.4823426902294159",
   "Neutral": "0.5090972185134888",
   "Mixed": "0.0025641026441007853"
 },
 {
   "tweet_id": "1590024464565440512",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590024463856578560",
   "Positive": "0.0015517635038122535",
   "Negative": "0.00014308594109024853",
   "Neutral": "0.9983019828796387",
   "Mixed": "3.087459845119156e-06"
 },
 {
   "tweet_id": "1590024445296799744",
   "Positive": "0.5541945099830627",
   "Negative": "0.003560709534212947",
   "Neutral": "0.44063901901245117",
   "Mixed": "0.0016058131586760283"
 },
 {
   "tweet_id": "1590024442654388224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024440393662464",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024437658963968",
   "Positive": "0.0014161171857267618",
   "Negative": "0.00010313781240256503",
   "Neutral": "0.998477041721344",
   "Mixed": "3.709880047608749e-06"
 },
 {
   "tweet_id": "1590024436807528448",
   "Positive": "0.5476177334785461",
   "Negative": "0.06281585246324539",
   "Neutral": "0.2986420691013336",
   "Mixed": "0.09092435240745544"
 },
 {
   "tweet_id": "1590024434345443329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024421254627328",
   "Positive": "0.0015742687974125147",
   "Negative": "0.12091182917356491",
   "Neutral": "0.8773536086082458",
   "Mixed": "0.00016023381613194942"
 },
 {
   "tweet_id": "1590024415194275840",
   "Positive": "0.003421573666855693",
   "Negative": "0.3507274389266968",
   "Neutral": "0.6456442475318909",
   "Mixed": "0.0002067214372800663"
 },
 {
   "tweet_id": "1590024407279632385",
   "Positive": "0.24876564741134644",
   "Negative": "0.004197241738438606",
   "Neutral": "0.7469983100891113",
   "Mixed": "3.883959652739577e-05"
 },
 {
   "tweet_id": "1590024403831914496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024357199327232",
   "Positive": "0.6927584409713745",
   "Negative": "0.006802172400057316",
   "Neutral": "0.3003682494163513",
   "Mixed": "7.110673323040828e-05"
 },
 {
   "tweet_id": "1590024353885745153",
   "Positive": "0.004952855408191681",
   "Negative": "0.4446476995944977",
   "Neutral": "0.5501344203948975",
   "Mixed": "0.0002650148526299745"
 },
 {
   "tweet_id": "1590024330875875328",
   "Positive": "0.007594123482704163",
   "Negative": "0.00022612720204051584",
   "Neutral": "0.992174506187439",
   "Mixed": "5.274463546811603e-06"
 },
 {
   "tweet_id": "1590024319429914625",
   "Positive": "0.03270522505044937",
   "Negative": "0.00022512763098347932",
   "Neutral": "0.9670549631118774",
   "Mixed": "1.4665919479739387e-05"
 },
 {
   "tweet_id": "1590024301226303488",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1590024293181947905",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024289293463552",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024285313454080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024280431296513",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590024271967158272",
   "Positive": "0.002357723657041788",
   "Negative": "0.00022257230011746287",
   "Neutral": "0.9974167346954346",
   "Mixed": "2.9873526727897115e-06"
 },
 {
   "tweet_id": "1590024252580716545",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1590024209031626752",
   "Positive": "0.006983660161495209",
   "Negative": "0.46454092860221863",
   "Neutral": "0.4792904257774353",
   "Mixed": "0.049184948205947876"
 },
 {
   "tweet_id": "1590024201569980416",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590024189767217152",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1590024188794122240",
   "Positive": "0.13131561875343323",
   "Negative": "0.022761734202504158",
   "Neutral": "0.8458924293518066",
   "Mixed": "3.0198001695680432e-05"
 },
 {
   "tweet_id": "1590024183731589120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590024178123825152",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590024165855485953",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590024163728584704",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1590024151720660992",
   "Positive": "0.5634727478027344",
   "Negative": "0.0002468601451255381",
   "Neutral": "0.4362051784992218",
   "Mixed": "7.521051884396002e-05"
 },
 {
   "tweet_id": "1590024107240083456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590024095626047488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024090404130818",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590024068849623040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024042832351232",
   "Positive": "0.8896811008453369",
   "Negative": "0.00023908303410280496",
   "Neutral": "0.10998530685901642",
   "Mixed": "9.454217070015147e-05"
 },
 {
   "tweet_id": "1590024038944223232",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590024029016293377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590024028831764480",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590024004336656384",
   "Positive": "0.13878700137138367",
   "Negative": "0.00023993772629182786",
   "Neutral": "0.8605568408966064",
   "Mixed": "0.0004162934492342174"
 },
 {
   "tweet_id": "1590023984443113472",
   "Positive": "0.006019794847816229",
   "Negative": "0.44051268696784973",
   "Neutral": "0.5534365177154541",
   "Mixed": "3.095353167736903e-05"
 },
 {
   "tweet_id": "1590023980144267264",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023934111809537",
   "Positive": "0.28887495398521423",
   "Negative": "0.025818703696131706",
   "Neutral": "0.6852356195449829",
   "Mixed": "7.062836812110618e-05"
 },
 {
   "tweet_id": "1590023914633269250",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023908522016768",
   "Positive": "0.0008400239748880267",
   "Negative": "0.00022162022651173174",
   "Neutral": "0.9989350438117981",
   "Mixed": "3.3494848139525857e-06"
 },
 {
   "tweet_id": "1590023885789204481",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023879371952128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023875982680065",
   "Positive": "0.0008106722962111235",
   "Negative": "0.00017176184337586164",
   "Neutral": "0.9990100860595703",
   "Mixed": "7.526951776526403e-06"
 },
 {
   "tweet_id": "1590023866839363585",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023863072882693",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590023850439606274",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023838662029312",
   "Positive": "0.28887495398521423",
   "Negative": "0.025818703696131706",
   "Neutral": "0.6852356195449829",
   "Mixed": "7.062836812110618e-05"
 },
 {
   "tweet_id": "1590023835046543360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023813424902145",
   "Positive": "0.023760126903653145",
   "Negative": "0.0002551089564803988",
   "Neutral": "0.9759216904640198",
   "Mixed": "6.30869617452845e-05"
 },
 {
   "tweet_id": "1590023804168044544",
   "Positive": "0.0008518644026480615",
   "Negative": "0.00024267658591270447",
   "Neutral": "0.9989020824432373",
   "Mixed": "3.2806281069497345e-06"
 },
 {
   "tweet_id": "1590023764355739650",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023755476381696",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023739374460931",
   "Positive": "0.007032486610114574",
   "Negative": "0.0011534568620845675",
   "Neutral": "0.9918072819709778",
   "Mixed": "6.747575753252022e-06"
 },
 {
   "tweet_id": "1590023735821508609",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1590023730105053184",
   "Positive": "0.28887495398521423",
   "Negative": "0.025818703696131706",
   "Neutral": "0.6852356195449829",
   "Mixed": "7.062836812110618e-05"
 },
 {
   "tweet_id": "1590023717635391488",
   "Positive": "0.00041962278191931546",
   "Negative": "0.0005382618983276188",
   "Neutral": "0.9990311861038208",
   "Mixed": "1.1005320629919879e-05"
 },
 {
   "tweet_id": "1590023702036779009",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590023677571391488",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590023675994320898",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023673947512833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023670764032008",
   "Positive": "0.020131168887019157",
   "Negative": "0.10021603107452393",
   "Neutral": "0.8796088695526123",
   "Mixed": "4.390967296785675e-05"
 },
 {
   "tweet_id": "1590023665206595584",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590023659678146565",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023650329399296",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023637633208320",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023623737495554",
   "Positive": "0.9446789026260376",
   "Negative": "0.00018696329789236188",
   "Neutral": "0.05511810630559921",
   "Mixed": "1.6081616195151582e-05"
 },
 {
   "tweet_id": "1590023616619778048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023612119277568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023604066209793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023599179837440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023586546298880",
   "Positive": "0.0015875725075602531",
   "Negative": "0.0005957406829111278",
   "Neutral": "0.9978145360946655",
   "Mixed": "2.1971970909362426e-06"
 },
 {
   "tweet_id": "1590023558012760064",
   "Positive": "0.9446789026260376",
   "Negative": "0.00018696329789236188",
   "Neutral": "0.05511810630559921",
   "Mixed": "1.6081616195151582e-05"
 },
 {
   "tweet_id": "1590023542569316353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023524420186112",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023510155358213",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1590023500055867392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023496859586560",
   "Positive": "0.22901788353919983",
   "Negative": "0.01947573758661747",
   "Neutral": "0.6500924229621887",
   "Mixed": "0.10141394287347794"
 },
 {
   "tweet_id": "1590023496645881856",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1590023474583519232",
   "Positive": "0.006375722121447325",
   "Negative": "0.6066546440124512",
   "Neutral": "0.3865068852901459",
   "Mixed": "0.0004627562593668699"
 },
 {
   "tweet_id": "1590023462713974784",
   "Positive": "0.00017106714949477464",
   "Negative": "0.9889982342720032",
   "Neutral": "0.010817810893058777",
   "Mixed": "1.2874222193204332e-05"
 },
 {
   "tweet_id": "1590023456330219520",
   "Positive": "0.0012175766751170158",
   "Negative": "0.9668315052986145",
   "Neutral": "0.01958443969488144",
   "Mixed": "0.012366528622806072"
 },
 {
   "tweet_id": "1590023444762341378",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023440022769665",
   "Positive": "0.001277739997021854",
   "Negative": "0.6326572299003601",
   "Neutral": "0.3642769455909729",
   "Mixed": "0.001788067165762186"
 },
 {
   "tweet_id": "1590023435883016192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023432242331653",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023404874502144",
   "Positive": "0.00959061924368143",
   "Negative": "0.009641295298933983",
   "Neutral": "0.9807522296905518",
   "Mixed": "1.594443710928317e-05"
 },
 {
   "tweet_id": "1590023392312594433",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023388218945538",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023383454216194",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590023377628155910",
   "Positive": "0.22885943949222565",
   "Negative": "0.007163612637668848",
   "Neutral": "0.7639263272285461",
   "Mixed": "5.0558304792502895e-05"
 },
 {
   "tweet_id": "1590023368690262016",
   "Positive": "0.045974839478731155",
   "Negative": "0.010421359911561012",
   "Neutral": "0.9435845017433167",
   "Mixed": "1.919785063364543e-05"
 },
 {
   "tweet_id": "1590023355843084290",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590023355570454529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023354857422850",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023345315393539",
   "Positive": "0.10186987370252609",
   "Negative": "0.0037198432255536318",
   "Neutral": "0.8943777084350586",
   "Mixed": "3.262603422626853e-05"
 },
 {
   "tweet_id": "1590023345290215425",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590023342727176192",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590023340143497216",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590023336192487425",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1590023334476976128",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590023325241470977",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590023322192203777",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023315183345667",
   "Positive": "0.4534795582294464",
   "Negative": "0.002742049051448703",
   "Neutral": "0.543691098690033",
   "Mixed": "8.721363701624796e-05"
 },
 {
   "tweet_id": "1590023305695989760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023304173486081",
   "Positive": "0.004418381489813328",
   "Negative": "0.5378182530403137",
   "Neutral": "0.4558127224445343",
   "Mixed": "0.0019506918033584952"
 },
 {
   "tweet_id": "1590023299891097600",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590023279217344512",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590023278013583360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590023268831985666",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590023268093685760",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1590023255200788481",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590023250339598337",
   "Positive": "0.07914086431264877",
   "Negative": "0.007246682420372963",
   "Neutral": "0.9135814309120178",
   "Mixed": "3.1069583201315254e-05"
 },
 {
   "tweet_id": "1590023249823424514",
   "Positive": "0.07914086431264877",
   "Negative": "0.007246682420372963",
   "Neutral": "0.9135814309120178",
   "Mixed": "3.1069583201315254e-05"
 },
 {
   "tweet_id": "1590023235722412033",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590023228030058501",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023226348163072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023218550960129",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1590023193456439296",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590023188117094400",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590023186594533379",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023185617260545",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023183146848256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023172094853120",
   "Positive": "0.2244943380355835",
   "Negative": "0.07789666205644608",
   "Neutral": "0.6705726981163025",
   "Mixed": "0.027036335319280624"
 },
 {
   "tweet_id": "1590023169028788224",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590023155078533121",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590023150578077696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023148774510593",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590023147050668032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023135621177345",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590023131322011649",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590023130009206784",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590023128020709377",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023125088935938",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590023123134738434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023111331950593",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590023109696192520",
   "Positive": "0.19948861002922058",
   "Negative": "0.033740948885679245",
   "Neutral": "0.7567909955978394",
   "Mixed": "0.009979461319744587"
 },
 {
   "tweet_id": "1590023109561548800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023084941410306",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590023045582049281",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590023026774806528",
   "Positive": "0.010522193275392056",
   "Negative": "0.00043635282781906426",
   "Neutral": "0.9890367388725281",
   "Mixed": "4.673890089179622e-06"
 },
 {
   "tweet_id": "1590023008714125312",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590022981325328384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022976699006976",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590022950891450368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022941156466691",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022937301684224",
   "Positive": "7.651797204744071e-05",
   "Negative": "0.9990405440330505",
   "Neutral": "0.0008225413621403277",
   "Mixed": "6.047097849659622e-05"
 },
 {
   "tweet_id": "1590022930049957889",
   "Positive": "0.0020992399659007788",
   "Negative": "0.7821612358093262",
   "Neutral": "0.21034829318523407",
   "Mixed": "0.0053912922739982605"
 },
 {
   "tweet_id": "1590022927898259456",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022915743174656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022914891735040",
   "Positive": "0.04796161130070686",
   "Negative": "0.01585938036441803",
   "Neutral": "0.33019012212753296",
   "Mixed": "0.6059888601303101"
 },
 {
   "tweet_id": "1590022877939580928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022876304117762",
   "Positive": "0.007765095215290785",
   "Negative": "0.00014054187340661883",
   "Neutral": "0.9920594692230225",
   "Mixed": "3.485997876850888e-05"
 },
 {
   "tweet_id": "1590022866237812736",
   "Positive": "0.9987577199935913",
   "Negative": "2.9583221476059407e-05",
   "Neutral": "0.0011945187579840422",
   "Mixed": "1.825857543735765e-05"
 },
 {
   "tweet_id": "1590022857970810881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022842556768256",
   "Positive": "0.006681496277451515",
   "Negative": "0.04729803651571274",
   "Neutral": "0.9458784461021423",
   "Mixed": "0.0001420223998138681"
 },
 {
   "tweet_id": "1590022840832905216",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022835019612161",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022831177629696",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022824617725953",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590022816946323456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022812567482368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022809849589761",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022803038035969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022796939522049",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590022788211171328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022783664214016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022779503783936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022760239333378",
   "Positive": "0.008340446278452873",
   "Negative": "4.460913623915985e-05",
   "Neutral": "0.9915459752082825",
   "Mixed": "6.895605474710464e-05"
 },
 {
   "tweet_id": "1590022758167379968",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022751242579968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022748293967872",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022744045150208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022738672259072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022731646767104",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590022719198072833",
   "Positive": "0.0001721571752568707",
   "Negative": "0.9894098043441772",
   "Neutral": "0.010413301177322865",
   "Mixed": "4.750860171043314e-06"
 },
 {
   "tweet_id": "1590022718157885441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022709056253953",
   "Positive": "0.7651493549346924",
   "Negative": "0.012763687409460545",
   "Neutral": "0.22200240194797516",
   "Mixed": "8.457815420115367e-05"
 },
 {
   "tweet_id": "1590022701951123458",
   "Positive": "0.238074392080307",
   "Negative": "0.13600578904151917",
   "Neutral": "0.6238583922386169",
   "Mixed": "0.002061435952782631"
 },
 {
   "tweet_id": "1590022699006693376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022698167848962",
   "Positive": "0.4867969751358032",
   "Negative": "0.0030130967497825623",
   "Neutral": "0.5099663138389587",
   "Mixed": "0.0002236214349977672"
 },
 {
   "tweet_id": "1590022695647076356",
   "Positive": "0.0017014159820973873",
   "Negative": "0.8482473492622375",
   "Neutral": "0.15001976490020752",
   "Mixed": "3.14974422508385e-05"
 },
 {
   "tweet_id": "1590022690915913730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022690274172928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022671240396801",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022647068659718",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022646820995077",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590022633739124737",
   "Positive": "0.7651493549346924",
   "Negative": "0.012763687409460545",
   "Neutral": "0.22200240194797516",
   "Mixed": "8.457815420115367e-05"
 },
 {
   "tweet_id": "1590022631369355265",
   "Positive": "0.995728075504303",
   "Negative": "5.0851765990955755e-05",
   "Neutral": "0.004204070195555687",
   "Mixed": "1.7000880689010955e-05"
 },
 {
   "tweet_id": "1590022624263827458",
   "Positive": "0.0012308162404224277",
   "Negative": "0.9276316165924072",
   "Neutral": "0.07089265435934067",
   "Mixed": "0.00024500509607605636"
 },
 {
   "tweet_id": "1590022616756391936",
   "Positive": "0.9938440322875977",
   "Negative": "0.002704115118831396",
   "Neutral": "0.0033921441063284874",
   "Mixed": "5.973886436549947e-05"
 },
 {
   "tweet_id": "1590022614986424321",
   "Positive": "0.5580437183380127",
   "Negative": "0.003232470015063882",
   "Neutral": "0.4385358989238739",
   "Mixed": "0.00018790575268212706"
 },
 {
   "tweet_id": "1590022610154590209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022607755415552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022592660123649",
   "Positive": "0.8515632152557373",
   "Negative": "0.003005101578310132",
   "Neutral": "0.14403316378593445",
   "Mixed": "0.001398503314703703"
 },
 {
   "tweet_id": "1590022585726603264",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022585529651201",
   "Positive": "0.6930030584335327",
   "Negative": "0.00034024607157334685",
   "Neutral": "0.30538588762283325",
   "Mixed": "0.001270774519070983"
 },
 {
   "tweet_id": "1590022564814155776",
   "Positive": "0.7639237642288208",
   "Negative": "0.013814454898238182",
   "Neutral": "0.22218386828899384",
   "Mixed": "7.790011295583099e-05"
 },
 {
   "tweet_id": "1590022560011677697",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022546266935297",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022534782943234",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022524146192385",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022519272407045",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022516797734912",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590022512179826690",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022500272197632",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022496124010497",
   "Positive": "0.004000382497906685",
   "Negative": "0.0005593443056568503",
   "Neutral": "0.9954379200935364",
   "Mixed": "2.3583918391523184e-06"
 },
 {
   "tweet_id": "1590022493753917440",
   "Positive": "0.01478142011910677",
   "Negative": "0.0013731002109125257",
   "Neutral": "0.9838359355926514",
   "Mixed": "9.588019565853756e-06"
 },
 {
   "tweet_id": "1590022493699715073",
   "Positive": "0.7651493549346924",
   "Negative": "0.012763687409460545",
   "Neutral": "0.22200240194797516",
   "Mixed": "8.457815420115367e-05"
 },
 {
   "tweet_id": "1590022481733378049",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022463756566528",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022448325750786",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022445851119617",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022429111648257",
   "Positive": "0.018294746056199074",
   "Negative": "0.02329857274889946",
   "Neutral": "0.9583225846290588",
   "Mixed": "8.40256325318478e-05"
 },
 {
   "tweet_id": "1590022428021100546",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022423839412224",
   "Positive": "0.0036349354777485132",
   "Negative": "0.07955421507358551",
   "Neutral": "0.9166507124900818",
   "Mixed": "0.00016016930749174207"
 },
 {
   "tweet_id": "1590022420362334208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022409809461248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022409750740993",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022401144029184",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022391924948997",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590022390729560064",
   "Positive": "0.017681602388620377",
   "Negative": "0.06165865436196327",
   "Neutral": "0.9206492304801941",
   "Mixed": "1.050460650731111e-05"
 },
 {
   "tweet_id": "1590022387977793536",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1590022386338123776",
   "Positive": "0.007763925474137068",
   "Negative": "0.268454372882843",
   "Neutral": "0.701269805431366",
   "Mixed": "0.02251184917986393"
 },
 {
   "tweet_id": "1590022382701666304",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590022382701654016",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590022380033712128",
   "Positive": "0.002166729187592864",
   "Negative": "0.000342504441505298",
   "Neutral": "0.9974875450134277",
   "Mixed": "3.193162001480232e-06"
 },
 {
   "tweet_id": "1590022367526662144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590022366679412737",
   "Positive": "0.39189180731773376",
   "Negative": "0.000498375971801579",
   "Neutral": "0.6072690486907959",
   "Mixed": "0.0003407186595723033"
 },
 {
   "tweet_id": "1590022362518388738",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022354083536896",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590022340833853440",
   "Positive": "0.2235260307788849",
   "Negative": "0.08881904184818268",
   "Neutral": "0.6876097321510315",
   "Mixed": "4.515405453275889e-05"
 },
 {
   "tweet_id": "1590022337101205504",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590022336547549184",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1590022333422796801",
   "Positive": "0.5580437183380127",
   "Negative": "0.003232470015063882",
   "Neutral": "0.4385358989238739",
   "Mixed": "0.00018790575268212706"
 },
 {
   "tweet_id": "1590022321846484994",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590022304775692288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022283489587200",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590022265512820736",
   "Positive": "0.36911293864250183",
   "Negative": "0.0003756787336897105",
   "Neutral": "0.6304453611373901",
   "Mixed": "6.600799679290503e-05"
 },
 {
   "tweet_id": "1590022208583503872",
   "Positive": "0.006237454246729612",
   "Negative": "0.002129464177414775",
   "Neutral": "0.991601288318634",
   "Mixed": "3.1775976822245866e-05"
 },
 {
   "tweet_id": "1590022204913520642",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022187117080576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022165990354946",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022160365805569",
   "Positive": "0.04109911248087883",
   "Negative": "0.18259653449058533",
   "Neutral": "0.7761925458908081",
   "Mixed": "0.00011181883019162342"
 },
 {
   "tweet_id": "1590022157433597953",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022137301323776",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590022136802193408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590022136487612416",
   "Positive": "0.24515731632709503",
   "Negative": "0.004794251173734665",
   "Neutral": "0.7499728202819824",
   "Mixed": "7.565760461147875e-05"
 },
 {
   "tweet_id": "1590022126886686722",
   "Positive": "0.029827570542693138",
   "Negative": "0.0006243922980502248",
   "Neutral": "0.9695429801940918",
   "Mixed": "5.137487278261688e-06"
 },
 {
   "tweet_id": "1590022104493494272",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590022100344897537",
   "Positive": "0.008377491496503353",
   "Negative": "5.3171988838585094e-05",
   "Neutral": "0.9914274215698242",
   "Mixed": "0.0001419076434103772"
 },
 {
   "tweet_id": "1590022095383457793",
   "Positive": "0.5580437183380127",
   "Negative": "0.003232470015063882",
   "Neutral": "0.4385358989238739",
   "Mixed": "0.00018790575268212706"
 },
 {
   "tweet_id": "1590022088710316032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590022086268923904",
   "Positive": "0.510193943977356",
   "Negative": "0.12453198432922363",
   "Neutral": "0.3627990484237671",
   "Mixed": "0.0024749392177909613"
 },
 {
   "tweet_id": "1590022038185725957",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590022032082997249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590022025577639938",
   "Positive": "0.9446789026260376",
   "Negative": "0.00018696329789236188",
   "Neutral": "0.05511810630559921",
   "Mixed": "1.6081616195151582e-05"
 },
 {
   "tweet_id": "1590022010394259456",
   "Positive": "0.24629758298397064",
   "Negative": "0.0005495585501194",
   "Neutral": "0.7531225681304932",
   "Mixed": "3.0324434192152694e-05"
 },
 {
   "tweet_id": "1590021998297894913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021995353493509",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021984842571778",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590021980841201665",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021974897864705",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021973757022210",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590021971068489728",
   "Positive": "0.09188301861286163",
   "Negative": "0.09591776132583618",
   "Neutral": "0.8120437860488892",
   "Mixed": "0.00015545175119768828"
 },
 {
   "tweet_id": "1590021964605059072",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590021957864812544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021955331055616",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590021927284125697",
   "Positive": "0.9446789026260376",
   "Negative": "0.00018696329789236188",
   "Neutral": "0.05511810630559921",
   "Mixed": "1.6081616195151582e-05"
 },
 {
   "tweet_id": "1590021876029751298",
   "Positive": "0.26802322268486023",
   "Negative": "0.0008722783531993628",
   "Neutral": "0.7309616208076477",
   "Mixed": "0.00014279494644142687"
 },
 {
   "tweet_id": "1590021869209780224",
   "Positive": "0.03266004100441933",
   "Negative": "0.000530614925082773",
   "Neutral": "0.9667843580245972",
   "Mixed": "2.4998193111969158e-05"
 },
 {
   "tweet_id": "1590021866370269184",
   "Positive": "0.003710248740389943",
   "Negative": "0.0003490939852781594",
   "Neutral": "0.9959379434585571",
   "Mixed": "2.7347143713996047e-06"
 },
 {
   "tweet_id": "1590021866252808194",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590021860510818305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021846019506178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021839933562880",
   "Positive": "0.051619432866573334",
   "Negative": "0.11783678084611893",
   "Neutral": "0.8301138281822205",
   "Mixed": "0.00043001098674722016"
 },
 {
   "tweet_id": "1590021834384080896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021831276531712",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590021828759912448",
   "Positive": "0.9899896383285522",
   "Negative": "9.602136560715735e-05",
   "Neutral": "0.00989948958158493",
   "Mixed": "1.4823914170847274e-05"
 },
 {
   "tweet_id": "1590021812850950144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021803791241216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021803539591169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021801673101312",
   "Positive": "0.003278453601524234",
   "Negative": "0.00010927047696895897",
   "Neutral": "0.9965971112251282",
   "Mixed": "1.522729689895641e-05"
 },
 {
   "tweet_id": "1590021801312399364",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590021786095480832",
   "Positive": "0.01792481727898121",
   "Negative": "0.0006533215055242181",
   "Neutral": "0.9814133644104004",
   "Mixed": "8.565325515519362e-06"
 },
 {
   "tweet_id": "1590021766961065984",
   "Positive": "0.010096567682921886",
   "Negative": "0.18942461907863617",
   "Neutral": "0.8003707528114319",
   "Mixed": "0.00010807204671436921"
 },
 {
   "tweet_id": "1590021748745207816",
   "Positive": "0.0177213866263628",
   "Negative": "0.00028989050770178437",
   "Neutral": "0.9819808602333069",
   "Mixed": "7.849364010326099e-06"
 },
 {
   "tweet_id": "1590021719385079808",
   "Positive": "0.0014238411094993353",
   "Negative": "0.00012031035294057801",
   "Neutral": "0.99845290184021",
   "Mixed": "3.0064179554756265e-06"
 },
 {
   "tweet_id": "1590021718089007106",
   "Positive": "0.9916875958442688",
   "Negative": "9.435029642190784e-05",
   "Neutral": "0.008204475976526737",
   "Mixed": "1.3564452274295036e-05"
 },
 {
   "tweet_id": "1590021711407501318",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021708974432256",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590021704562380800",
   "Positive": "0.007601926568895578",
   "Negative": "0.00039476732490584254",
   "Neutral": "0.9919648170471191",
   "Mixed": "3.837817348539829e-05"
 },
 {
   "tweet_id": "1590021703778070529",
   "Positive": "0.0036349354777485132",
   "Negative": "0.07955421507358551",
   "Neutral": "0.9166507124900818",
   "Mixed": "0.00016016930749174207"
 },
 {
   "tweet_id": "1590021687395110912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021687319597056",
   "Positive": "0.0004452388093341142",
   "Negative": "0.00025870976969599724",
   "Neutral": "0.9992936849594116",
   "Mixed": "2.369056119277957e-06"
 },
 {
   "tweet_id": "1590021686752989184",
   "Positive": "0.0006117535522207618",
   "Negative": "0.00041661609429866076",
   "Neutral": "0.9989689588546753",
   "Mixed": "2.785298420349136e-06"
 },
 {
   "tweet_id": "1590021672987684866",
   "Positive": "0.00027322827372699976",
   "Negative": "0.00030858174432069063",
   "Neutral": "0.9994156360626221",
   "Mixed": "2.565545173638384e-06"
 },
 {
   "tweet_id": "1590021663101329408",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590021658844139520",
   "Positive": "0.00032623723382130265",
   "Negative": "0.0003066700592171401",
   "Neutral": "0.9993647933006287",
   "Mixed": "2.247869360871846e-06"
 },
 {
   "tweet_id": "1590021647398219777",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590021642373464064",
   "Positive": "0.0003649163991212845",
   "Negative": "0.00017580125131644309",
   "Neutral": "0.9994563460350037",
   "Mixed": "3.023221097464557e-06"
 },
 {
   "tweet_id": "1590021639596494848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021638874988544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021627919888385",
   "Positive": "0.0003595543676055968",
   "Negative": "0.0005465710419230163",
   "Neutral": "0.9990918636322021",
   "Mixed": "2.081680122500984e-06"
 },
 {
   "tweet_id": "1590021622316294144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021615899013120",
   "Positive": "0.10893195122480392",
   "Negative": "0.010917654260993004",
   "Neutral": "0.8800619840621948",
   "Mixed": "8.847728167893365e-05"
 },
 {
   "tweet_id": "1590021614808502272",
   "Positive": "0.00046691298484802246",
   "Negative": "0.00028127187397331",
   "Neutral": "0.9992493987083435",
   "Mixed": "2.3577019874210237e-06"
 },
 {
   "tweet_id": "1590021603412221954",
   "Positive": "0.33495190739631653",
   "Negative": "0.17720995843410492",
   "Neutral": "0.4853064715862274",
   "Mixed": "0.002531718695536256"
 },
 {
   "tweet_id": "1590021595153604608",
   "Positive": "0.000908593472559005",
   "Negative": "0.00047646547318436205",
   "Neutral": "0.998612642288208",
   "Mixed": "2.3052516553434543e-06"
 },
 {
   "tweet_id": "1590021590586392579",
   "Positive": "0.06344795227050781",
   "Negative": "0.015396509319543839",
   "Neutral": "0.921052098274231",
   "Mixed": "0.0001034232263918966"
 },
 {
   "tweet_id": "1590021589328068609",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021588761858048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021582264860673",
   "Positive": "0.0004142689285799861",
   "Negative": "0.00023119313118513674",
   "Neutral": "0.9993517994880676",
   "Mixed": "2.693223450478399e-06"
 },
 {
   "tweet_id": "1590021573083529217",
   "Positive": "0.08025843650102615",
   "Negative": "0.007387297693639994",
   "Neutral": "0.9122796058654785",
   "Mixed": "7.471764547517523e-05"
 },
 {
   "tweet_id": "1590021558856462336",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590021553961709569",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590021553626173441",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590021545208205312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021523540414464",
   "Positive": "0.34227001667022705",
   "Negative": "0.009880529716610909",
   "Neutral": "0.6082024574279785",
   "Mixed": "0.03964697942137718"
 },
 {
   "tweet_id": "1590021517806403585",
   "Positive": "0.010589960031211376",
   "Negative": "0.0010799919255077839",
   "Neutral": "0.9883235692977905",
   "Mixed": "6.427375410567038e-06"
 },
 {
   "tweet_id": "1590021509913120769",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590021509182935040",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1590021468452450305",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021462450384896",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590021455060029440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021451922362368",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590021445769658368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021425251110912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021422940028930",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590021420431839234",
   "Positive": "0.018125643953680992",
   "Negative": "0.0020351435523480177",
   "Neutral": "0.9798251986503601",
   "Mixed": "1.4062349691812415e-05"
 },
 {
   "tweet_id": "1590021410831077378",
   "Positive": "0.820199191570282",
   "Negative": "0.0007355257403105497",
   "Neutral": "0.17903153598308563",
   "Mixed": "3.368938996572979e-05"
 },
 {
   "tweet_id": "1590021391889596418",
   "Positive": "0.9922542572021484",
   "Negative": "4.653254654840566e-05",
   "Neutral": "0.0076730032451450825",
   "Mixed": "2.6210478608845733e-05"
 },
 {
   "tweet_id": "1590021366551842816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021361757720577",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590021350470881280",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590021344607227904",
   "Positive": "0.9946305751800537",
   "Negative": "4.108344000997022e-05",
   "Neutral": "0.005301757715642452",
   "Mixed": "2.6620240532793105e-05"
 },
 {
   "tweet_id": "1590021338768748544",
   "Positive": "0.820199191570282",
   "Negative": "0.0007355257403105497",
   "Neutral": "0.17903153598308563",
   "Mixed": "3.368938996572979e-05"
 },
 {
   "tweet_id": "1590021332271763456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021328425615360",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590021327750311936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021324952723456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021320388943873",
   "Positive": "0.0031359426211565733",
   "Negative": "9.1362620878499e-05",
   "Neutral": "0.9967688322067261",
   "Mixed": "3.880173608195037e-06"
 },
 {
   "tweet_id": "1590021309349900289",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021303758901248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590021297165463554",
   "Positive": "0.0002287035167682916",
   "Negative": "0.0001666108291829005",
   "Neutral": "0.9995949864387512",
   "Mixed": "9.815176781557966e-06"
 },
 {
   "tweet_id": "1590021294669852672",
   "Positive": "0.04109911248087883",
   "Negative": "0.18259653449058533",
   "Neutral": "0.7761925458908081",
   "Mixed": "0.00011181883019162342"
 },
 {
   "tweet_id": "1590021291905802241",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590021289393393664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021280694423552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021280249806848",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590021266723209218",
   "Positive": "0.002070050686597824",
   "Negative": "0.00013894900621380657",
   "Neutral": "0.997787356376648",
   "Mixed": "3.619804147092509e-06"
 },
 {
   "tweet_id": "1590021252995223552",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1590021246879924226",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021223077273601",
   "Positive": "0.820199191570282",
   "Negative": "0.0007355257403105497",
   "Neutral": "0.17903153598308563",
   "Mixed": "3.368938996572979e-05"
 },
 {
   "tweet_id": "1590021208728555520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021204026732544",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590021181478154242",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021172871442434",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021159365783554",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1590021147806298113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021130857107456",
   "Positive": "0.004063504282385111",
   "Negative": "0.23775869607925415",
   "Neutral": "0.7578468322753906",
   "Mixed": "0.0003310225438326597"
 },
 {
   "tweet_id": "1590021115535327232",
   "Positive": "0.17380857467651367",
   "Negative": "0.012509610503911972",
   "Neutral": "0.8136441707611084",
   "Mixed": "3.7623001844622195e-05"
 },
 {
   "tweet_id": "1590021099966042112",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021092072378370",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021089941680128",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590021043422650368",
   "Positive": "0.8039052486419678",
   "Negative": "0.0012843532022088766",
   "Neutral": "0.19435559213161469",
   "Mixed": "0.0004548243305180222"
 },
 {
   "tweet_id": "1590021039068942336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590021036510416897",
   "Positive": "0.0013579500373452902",
   "Negative": "0.5233729481697083",
   "Neutral": "0.47182199358940125",
   "Mixed": "0.0034471086692065"
 },
 {
   "tweet_id": "1590021034404909056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590021033494745089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590021030571311104",
   "Positive": "0.3463132083415985",
   "Negative": "0.0007358182338066399",
   "Neutral": "0.652816891670227",
   "Mixed": "0.00013412980479188263"
 },
 {
   "tweet_id": "1590021004272992256",
   "Positive": "0.003197287442162633",
   "Negative": "7.298979471670464e-05",
   "Neutral": "0.9967241883277893",
   "Mixed": "5.581608093052637e-06"
 },
 {
   "tweet_id": "1590020990930915329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020990762815489",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020965802463233",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020940074979329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020927475298305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020927336906753",
   "Positive": "0.020922841504216194",
   "Negative": "0.00014161683793645352",
   "Neutral": "0.9789205193519592",
   "Mixed": "1.509051799075678e-05"
 },
 {
   "tweet_id": "1590020914602614784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020909267849216",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020905564274689",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020899126013952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020896009621504",
   "Positive": "0.3482793867588043",
   "Negative": "0.0010341104352846742",
   "Neutral": "0.6506413221359253",
   "Mixed": "4.5170061639510095e-05"
 },
 {
   "tweet_id": "1590020895082708992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020879593148416",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1590020874782273542",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020873846607873",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020861641527296",
   "Positive": "0.380060076713562",
   "Negative": "0.0006986345397308469",
   "Neutral": "0.618982195854187",
   "Mixed": "0.00025912243290804327"
 },
 {
   "tweet_id": "1590020860039303168",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590020853861085186",
   "Positive": "0.021102676168084145",
   "Negative": "0.00014750381524208933",
   "Neutral": "0.9787322878837585",
   "Mixed": "1.754801814968232e-05"
 },
 {
   "tweet_id": "1590020851604541442",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020831157301248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020827940282368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020811163066369",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590020806910046208",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590020802795429888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020790971695110",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590020790040530944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020784957054976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020779034677248",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020770755117056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020739239149568",
   "Positive": "0.8117520213127136",
   "Negative": "0.0010542460950091481",
   "Neutral": "0.18715687096118927",
   "Mixed": "3.6850531614618376e-05"
 },
 {
   "tweet_id": "1590020730766647297",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020728946331648",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590020719106469888",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020710189379585",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590020703247638528",
   "Positive": "0.01096076238900423",
   "Negative": "0.0019205509452149272",
   "Neutral": "0.9871123433113098",
   "Mixed": "6.384222160704667e-06"
 },
 {
   "tweet_id": "1590020696952156162",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020684612218881",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020682368184324",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020682322104320",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020680942342147",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020679742951424",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020679109419009",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020678354628608",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020677440245760",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020677272223748",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020676932587523",
   "Positive": "0.09720857441425323",
   "Negative": "0.00021206708333920687",
   "Neutral": "0.9024789929389954",
   "Mixed": "0.00010036014282377437"
 },
 {
   "tweet_id": "1590020675938680832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020674395201537",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020662407892992",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590020655139164160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020643466412032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020641620889600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020630938017793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020618485137409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020606719762433",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020593449316352",
   "Positive": "0.05953377112746239",
   "Negative": "0.0017131937202066183",
   "Neutral": "0.9385517835617065",
   "Mixed": "0.00020129633776377887"
 },
 {
   "tweet_id": "1590020593193455616",
   "Positive": "0.9917225241661072",
   "Negative": "6.679509533569217e-05",
   "Neutral": "0.008188346400856972",
   "Mixed": "2.2336183974402957e-05"
 },
 {
   "tweet_id": "1590020591842889729",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020583080988672",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590020581604618241",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020572301340673",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590020572133486592",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020568635830273",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020556883394560",
   "Positive": "0.017987091094255447",
   "Negative": "0.0019074634183198214",
   "Neutral": "0.980097770690918",
   "Mixed": "7.56515782995848e-06"
 },
 {
   "tweet_id": "1590020556677873664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020550394793984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020545953026050",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020534963560448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020529616228352",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590020526264619009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020521013673984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020511320674305",
   "Positive": "0.8359889388084412",
   "Negative": "0.00023968744790181518",
   "Neutral": "0.16353778541088104",
   "Mixed": "0.0002336145262233913"
 },
 {
   "tweet_id": "1590020487975161864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020477510070272",
   "Positive": "0.031776949763298035",
   "Negative": "0.0422368049621582",
   "Neutral": "0.9259649515151978",
   "Mixed": "2.1279101929394528e-05"
 },
 {
   "tweet_id": "1590020470291673091",
   "Positive": "0.0027749815490096807",
   "Negative": "0.9089014530181885",
   "Neutral": "0.08690142631530762",
   "Mixed": "0.0014221337623894215"
 },
 {
   "tweet_id": "1590020455741521921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020454919835649",
   "Positive": "0.003710248740389943",
   "Negative": "0.0003490939852781594",
   "Neutral": "0.9959379434585571",
   "Mixed": "2.7347143713996047e-06"
 },
 {
   "tweet_id": "1590020452767784960",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590020428814503936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020423802302466",
   "Positive": "0.9186611175537109",
   "Negative": "0.0010096534388139844",
   "Neutral": "0.07986026257276535",
   "Mixed": "0.00046901690075173974"
 },
 {
   "tweet_id": "1590020403178926080",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590020388075237376",
   "Positive": "0.8986209034919739",
   "Negative": "0.0008204300538636744",
   "Neutral": "0.10046113282442093",
   "Mixed": "9.747238800628111e-05"
 },
 {
   "tweet_id": "1590020368328052736",
   "Positive": "0.00944232102483511",
   "Negative": "0.00036084497696720064",
   "Neutral": "0.9901935458183289",
   "Mixed": "3.3414630706829485e-06"
 },
 {
   "tweet_id": "1590020328579043328",
   "Positive": "0.015315820463001728",
   "Negative": "9.605038212612271e-05",
   "Neutral": "0.9845784306526184",
   "Mixed": "9.786091140995268e-06"
 },
 {
   "tweet_id": "1590020312628101120",
   "Positive": "0.8538619875907898",
   "Negative": "0.00025238533271476626",
   "Neutral": "0.14583173394203186",
   "Mixed": "5.3833289712201804e-05"
 },
 {
   "tweet_id": "1590020312342888452",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590020311642406912",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590020309604003840",
   "Positive": "0.986134946346283",
   "Negative": "0.00012217374751344323",
   "Neutral": "0.013721713796257973",
   "Mixed": "2.125352511939127e-05"
 },
 {
   "tweet_id": "1590020277043605504",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590020261478551554",
   "Positive": "0.9429914951324463",
   "Negative": "0.00013949726417195052",
   "Neutral": "0.05676128342747688",
   "Mixed": "0.00010778177238535136"
 },
 {
   "tweet_id": "1590020257200340995",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020248098721794",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590020247826100224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020247725408256",
   "Positive": "0.26103922724723816",
   "Negative": "0.00084828888066113",
   "Neutral": "0.7378079295158386",
   "Mixed": "0.0003046185593120754"
 },
 {
   "tweet_id": "1590020243082338304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020242587410432",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590020233427054592",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590020228733292544",
   "Positive": "0.007601926568895578",
   "Negative": "0.00039476732490584254",
   "Neutral": "0.9919648170471191",
   "Mixed": "3.837817348539829e-05"
 },
 {
   "tweet_id": "1590020210886463488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020202741497856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020196084977665",
   "Positive": "0.015348311513662338",
   "Negative": "0.00011536207603057846",
   "Neutral": "0.9845277667045593",
   "Mixed": "8.555596650694497e-06"
 },
 {
   "tweet_id": "1590020195426660352",
   "Positive": "0.681702733039856",
   "Negative": "0.0009029327775351703",
   "Neutral": "0.317366361618042",
   "Mixed": "2.7948966817348264e-05"
 },
 {
   "tweet_id": "1590020195313389568",
   "Positive": "0.02948528528213501",
   "Negative": "0.0008736205054447055",
   "Neutral": "0.9696338772773743",
   "Mixed": "7.328283572860528e-06"
 },
 {
   "tweet_id": "1590020189948882944",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020187105161217",
   "Positive": "0.0184879619628191",
   "Negative": "0.8205124735832214",
   "Neutral": "0.16085481643676758",
   "Mixed": "0.0001446878886781633"
 },
 {
   "tweet_id": "1590020177240154112",
   "Positive": "0.14300109446048737",
   "Negative": "0.003322871634736657",
   "Neutral": "0.8536270260810852",
   "Mixed": "4.9075661081587896e-05"
 },
 {
   "tweet_id": "1590020176040587268",
   "Positive": "0.006938195321708918",
   "Negative": "0.6258252263069153",
   "Neutral": "0.34993407130241394",
   "Mixed": "0.017302541062235832"
 },
 {
   "tweet_id": "1590020175247863808",
   "Positive": "0.23916177451610565",
   "Negative": "0.0005342828808352351",
   "Neutral": "0.7602325081825256",
   "Mixed": "7.141844980651513e-05"
 },
 {
   "tweet_id": "1590020173813407744",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020161020776448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020152765997056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020134458232832",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590020101402947584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020093572186112",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590020090950742016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020075893174275",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590020069857206272",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590020048235950081",
   "Positive": "0.01792481727898121",
   "Negative": "0.0006533215055242181",
   "Neutral": "0.9814133644104004",
   "Mixed": "8.565325515519362e-06"
 },
 {
   "tweet_id": "1590020046495313920",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020045115383808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590020031621976064",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590020017466540033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590020004103458817",
   "Positive": "0.004721360746771097",
   "Negative": "0.001419898122549057",
   "Neutral": "0.9936973452568054",
   "Mixed": "0.00016149799921549857"
 },
 {
   "tweet_id": "1590020002543198208",
   "Positive": "0.23381295800209045",
   "Negative": "0.004062861204147339",
   "Neutral": "0.7580217719078064",
   "Mixed": "0.0041023800149559975"
 },
 {
   "tweet_id": "1590019970037338112",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590019968833585152",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590019950663831552",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590019939515387904",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590019934733537280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019928077533185",
   "Positive": "0.18526910245418549",
   "Negative": "0.00046692733303643763",
   "Neutral": "0.81411212682724",
   "Mixed": "0.00015188365068752319"
 },
 {
   "tweet_id": "1590019926970224645",
   "Positive": "0.01928751915693283",
   "Negative": "0.11472615599632263",
   "Neutral": "0.15381744503974915",
   "Mixed": "0.7121689319610596"
 },
 {
   "tweet_id": "1590019925888077825",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590019923962519552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019917390438401",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019914362163200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019914039164934",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590019910759251970",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590019887744708609",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019873446727681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019864563179523",
   "Positive": "0.005940731149166822",
   "Negative": "8.080684347078204e-05",
   "Neutral": "0.9939696788787842",
   "Mixed": "8.715053809282836e-06"
 },
 {
   "tweet_id": "1590019852349345795",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019845453930496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019842102669313",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019837837082625",
   "Positive": "0.9380873441696167",
   "Negative": "0.001120437285862863",
   "Neutral": "0.060767799615859985",
   "Mixed": "2.4441078494419344e-05"
 },
 {
   "tweet_id": "1590019833898598401",
   "Positive": "0.00015799907851032913",
   "Negative": "0.0009327539592050016",
   "Neutral": "0.9989007711410522",
   "Mixed": "8.458189768134616e-06"
 },
 {
   "tweet_id": "1590019833785040897",
   "Positive": "0.01792481727898121",
   "Negative": "0.0006533215055242181",
   "Neutral": "0.9814133644104004",
   "Mixed": "8.565325515519362e-06"
 },
 {
   "tweet_id": "1590019827674263552",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590019821944864768",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590019815443501059",
   "Positive": "0.24135826528072357",
   "Negative": "0.0003809761838056147",
   "Neutral": "0.7579482197761536",
   "Mixed": "0.00031249967287294567"
 },
 {
   "tweet_id": "1590019799656325122",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019790005235717",
   "Positive": "0.007663254160434008",
   "Negative": "0.0018655098974704742",
   "Neutral": "0.9904385209083557",
   "Mixed": "3.2733631087467074e-05"
 },
 {
   "tweet_id": "1590019785727037440",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590019778693201921",
   "Positive": "0.007779156789183617",
   "Negative": "0.013780004344880581",
   "Neutral": "0.9784135818481445",
   "Mixed": "2.733965084189549e-05"
 },
 {
   "tweet_id": "1590019776658952192",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019772145893376",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590019765917331456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019764143128576",
   "Positive": "0.04718605801463127",
   "Negative": "0.008436311036348343",
   "Neutral": "0.9442901611328125",
   "Mixed": "8.745824015932158e-05"
 },
 {
   "tweet_id": "1590019743502983168",
   "Positive": "0.010220187716186047",
   "Negative": "0.46555188298225403",
   "Neutral": "0.45503684878349304",
   "Mixed": "0.069191113114357"
 },
 {
   "tweet_id": "1590019735865159681",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590019702440411137",
   "Positive": "0.010220187716186047",
   "Negative": "0.46555188298225403",
   "Neutral": "0.45503684878349304",
   "Mixed": "0.069191113114357"
 },
 {
   "tweet_id": "1590019694261841921",
   "Positive": "0.9926950931549072",
   "Negative": "6.647613918175921e-05",
   "Neutral": "0.007220777217298746",
   "Mixed": "1.7637048586038873e-05"
 },
 {
   "tweet_id": "1590019684082257921",
   "Positive": "0.005368907004594803",
   "Negative": "0.00016050675185397267",
   "Neutral": "0.9944654107093811",
   "Mixed": "5.176059858058579e-06"
 },
 {
   "tweet_id": "1590019667997118464",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019643896659969",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590019637827506176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019612640694274",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019609058762752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019594357714945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019588254990336",
   "Positive": "0.4392860531806946",
   "Negative": "0.00021798606030642986",
   "Neutral": "0.5604237914085388",
   "Mixed": "7.223844295367599e-05"
 },
 {
   "tweet_id": "1590019584643723265",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019562216775680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019561151397888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019549809717248",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019541807280128",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590019537541296128",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590019521133572097",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590019520978386944",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590019506659004416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019479660277761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019477198233601",
   "Positive": "0.5542981028556824",
   "Negative": "0.0010909094708040357",
   "Neutral": "0.44456955790519714",
   "Mixed": "4.135094422963448e-05"
 },
 {
   "tweet_id": "1590019474920382467",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1590019474115043331",
   "Positive": "0.03340250998735428",
   "Negative": "0.0005835712072439492",
   "Neutral": "0.9659945964813232",
   "Mixed": "1.933454223035369e-05"
 },
 {
   "tweet_id": "1590019469078061058",
   "Positive": "0.002087341621518135",
   "Negative": "5.599929136224091e-05",
   "Neutral": "0.9978347420692444",
   "Mixed": "2.1885136447963305e-05"
 },
 {
   "tweet_id": "1590019442628792327",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019415881691137",
   "Positive": "0.0035309840459376574",
   "Negative": "0.00021607219241559505",
   "Neutral": "0.996248185634613",
   "Mixed": "4.7224084482877515e-06"
 },
 {
   "tweet_id": "1590019413667110915",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019380774961154",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019365461970944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019355001389058",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019339679592448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019331873988608",
   "Positive": "0.5264543890953064",
   "Negative": "0.002836372936144471",
   "Neutral": "0.4705495536327362",
   "Mixed": "0.00015972601249814034"
 },
 {
   "tweet_id": "1590019327687737344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019315318722561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019314454700032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019296846958592",
   "Positive": "0.31627437472343445",
   "Negative": "0.003110962687060237",
   "Neutral": "0.6795061826705933",
   "Mixed": "0.0011084828292950988"
 },
 {
   "tweet_id": "1590019294657908736",
   "Positive": "0.9235292077064514",
   "Negative": "0.0005530911148525774",
   "Neutral": "0.07588791102170944",
   "Mixed": "2.980786121042911e-05"
 },
 {
   "tweet_id": "1590019279491325953",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019279067697153",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590019272176435200",
   "Positive": "0.12557578086853027",
   "Negative": "0.0002656962024047971",
   "Neutral": "0.8741258978843689",
   "Mixed": "3.2636045943945646e-05"
 },
 {
   "tweet_id": "1590019260138803200",
   "Positive": "0.01647050864994526",
   "Negative": "0.0025596278719604015",
   "Neutral": "0.980962872505188",
   "Mixed": "7.07419576428947e-06"
 },
 {
   "tweet_id": "1590019250462543873",
   "Positive": "0.0017361569916829467",
   "Negative": "0.8961538672447205",
   "Neutral": "0.08865385502576828",
   "Mixed": "0.013456025160849094"
 },
 {
   "tweet_id": "1590019243684556800",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590019238021910528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019234834227200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019230162112514",
   "Positive": "0.8963847756385803",
   "Negative": "0.0007143450784496963",
   "Neutral": "0.1027529165148735",
   "Mixed": "0.00014794339949730784"
 },
 {
   "tweet_id": "1590019227343532032",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590019215314255873",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019213686865921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019213506523138",
   "Positive": "0.0012002669973298907",
   "Negative": "0.7240497469902039",
   "Neutral": "0.2741941213607788",
   "Mixed": "0.0005558609263971448"
 },
 {
   "tweet_id": "1590019210029461504",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590019188273590273",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019187342442499",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019152961736705",
   "Positive": "0.23697753250598907",
   "Negative": "0.0005465507274493575",
   "Neutral": "0.7624586224555969",
   "Mixed": "1.722912202239968e-05"
 },
 {
   "tweet_id": "1590019150285795329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019146162786304",
   "Positive": "0.07577157765626907",
   "Negative": "0.017735332250595093",
   "Neutral": "0.9064738750457764",
   "Mixed": "1.9215238353353925e-05"
 },
 {
   "tweet_id": "1590019126604750849",
   "Positive": "0.03377608209848404",
   "Negative": "0.020300578325986862",
   "Neutral": "0.9458045363426208",
   "Mixed": "0.00011887995060533285"
 },
 {
   "tweet_id": "1590019121491906560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590019121055662080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019117817696256",
   "Positive": "0.1572723537683487",
   "Negative": "0.0007231025374494493",
   "Neutral": "0.8418610095977783",
   "Mixed": "0.0001435298181604594"
 },
 {
   "tweet_id": "1590019113757585408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019105998143488",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590019105372794881",
   "Positive": "0.03574046492576599",
   "Negative": "0.0011988315964117646",
   "Neutral": "0.9630458354949951",
   "Mixed": "1.479681031923974e-05"
 },
 {
   "tweet_id": "1590019103150211075",
   "Positive": "0.06240946799516678",
   "Negative": "0.00014416959311347455",
   "Neutral": "0.9374203085899353",
   "Mixed": "2.598419268906582e-05"
 },
 {
   "tweet_id": "1590019085009649669",
   "Positive": "0.3211034834384918",
   "Negative": "0.03257346525788307",
   "Neutral": "0.5510897040367126",
   "Mixed": "0.09523340314626694"
 },
 {
   "tweet_id": "1590019069625110528",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590019058740887553",
   "Positive": "0.07030650973320007",
   "Negative": "0.02113381400704384",
   "Neutral": "0.7233970165252686",
   "Mixed": "0.18516267836093903"
 },
 {
   "tweet_id": "1590019046782930944",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590019045897940992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590019044677021696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590019041129029632",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590019040621527040",
   "Positive": "0.17380915582180023",
   "Negative": "0.0003022075106855482",
   "Neutral": "0.8258287310600281",
   "Mixed": "5.985190000501461e-05"
 },
 {
   "tweet_id": "1590019038608228356",
   "Positive": "0.0207055676728487",
   "Negative": "0.001822995487600565",
   "Neutral": "0.977465033531189",
   "Mixed": "6.445125109166838e-06"
 },
 {
   "tweet_id": "1590019033793200128",
   "Positive": "0.2330717146396637",
   "Negative": "0.009179926477372646",
   "Neutral": "0.7577311992645264",
   "Mixed": "1.7151993233710527e-05"
 },
 {
   "tweet_id": "1590019008689864706",
   "Positive": "0.000734085391741246",
   "Negative": "0.0001305324403801933",
   "Neutral": "0.9991324543952942",
   "Mixed": "2.9207933494035387e-06"
 },
 {
   "tweet_id": "1590018990872883201",
   "Positive": "0.18196667730808258",
   "Negative": "0.2811032831668854",
   "Neutral": "0.2108396738767624",
   "Mixed": "0.32609036564826965"
 },
 {
   "tweet_id": "1590018986930212865",
   "Positive": "0.380060076713562",
   "Negative": "0.0006986345397308469",
   "Neutral": "0.618982195854187",
   "Mixed": "0.00025912243290804327"
 },
 {
   "tweet_id": "1590018970371121152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018968017944576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018967623831552",
   "Positive": "0.0030339842196553946",
   "Negative": "0.0004168433661106974",
   "Neutral": "0.996543824672699",
   "Mixed": "5.317107479640981e-06"
 },
 {
   "tweet_id": "1590018931921936390",
   "Positive": "0.16852609813213348",
   "Negative": "0.002074748044833541",
   "Neutral": "0.8290240168571472",
   "Mixed": "0.0003751761687453836"
 },
 {
   "tweet_id": "1590018929904087040",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018916083937281",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1590018913307619331",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018910698414080",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018906965827586",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590018906085027840",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590018904469839873",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590018902612115456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018890998124544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018890935177216",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590018861583069185",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590018851701673989",
   "Positive": "0.007644714787602425",
   "Negative": "0.0008735484443604946",
   "Neutral": "0.9914781451225281",
   "Mixed": "3.508359668558114e-06"
 },
 {
   "tweet_id": "1590018844646862848",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590018837658755074",
   "Positive": "0.07914086431264877",
   "Negative": "0.007246682420372963",
   "Neutral": "0.9135814309120178",
   "Mixed": "3.1069583201315254e-05"
 },
 {
   "tweet_id": "1590018828510994433",
   "Positive": "0.00028786552138626575",
   "Negative": "9.919491276377812e-05",
   "Neutral": "0.9995995163917542",
   "Mixed": "1.3476418644131627e-05"
 },
 {
   "tweet_id": "1590018810320670720",
   "Positive": "0.7639237642288208",
   "Negative": "0.013814454898238182",
   "Neutral": "0.22218386828899384",
   "Mixed": "7.790011295583099e-05"
 },
 {
   "tweet_id": "1590018809838333952",
   "Positive": "0.0032139981631189585",
   "Negative": "0.32755959033966064",
   "Neutral": "0.664615273475647",
   "Mixed": "0.004611089825630188"
 },
 {
   "tweet_id": "1590018806742933511",
   "Positive": "0.03266004100441933",
   "Negative": "0.000530614925082773",
   "Neutral": "0.9667843580245972",
   "Mixed": "2.4998193111969158e-05"
 },
 {
   "tweet_id": "1590018793367302144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018763214454784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018762774020098",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018755761176576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018754770968576",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590018751235493888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018735246815232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018720696795138",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590018716976443397",
   "Positive": "0.2269839644432068",
   "Negative": "0.014849918894469738",
   "Neutral": "0.7578039169311523",
   "Mixed": "0.0003622299409471452"
 },
 {
   "tweet_id": "1590018715592298496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018692284264448",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590018680523743232",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590018679835889664",
   "Positive": "0.016035886481404305",
   "Negative": "0.0004118919896427542",
   "Neutral": "0.9835456609725952",
   "Mixed": "6.517465862998506e-06"
 },
 {
   "tweet_id": "1590018678107516928",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590018674953687040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018662152691713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018657866113032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018644213653506",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590018642980524033",
   "Positive": "0.01912468485534191",
   "Negative": "0.03584003821015358",
   "Neutral": "0.9450057148933411",
   "Mixed": "2.9532777261920273e-05"
 },
 {
   "tweet_id": "1590018637087510529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018633937215488",
   "Positive": "0.009798501618206501",
   "Negative": "0.4432731568813324",
   "Neutral": "0.5465230941772461",
   "Mixed": "0.0004052617878187448"
 },
 {
   "tweet_id": "1590018630498279424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018630456332289",
   "Positive": "0.18390929698944092",
   "Negative": "0.38955363631248474",
   "Neutral": "0.4264094829559326",
   "Mixed": "0.00012758394586853683"
 },
 {
   "tweet_id": "1590018626723401728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018622935961600",
   "Positive": "0.0012335572391748428",
   "Negative": "0.000629129761364311",
   "Neutral": "0.9981285929679871",
   "Mixed": "8.630485353933182e-06"
 },
 {
   "tweet_id": "1590018620666839040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018618125094913",
   "Positive": "0.0029853456653654575",
   "Negative": "0.2408819943666458",
   "Neutral": "0.7549810409545898",
   "Mixed": "0.0011515538208186626"
 },
 {
   "tweet_id": "1590018601721171968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018598105673732",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590018555990675458",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018551741812737",
   "Positive": "0.019854459911584854",
   "Negative": "0.01109001599252224",
   "Neutral": "0.9682626128196716",
   "Mixed": "0.0007928973063826561"
 },
 {
   "tweet_id": "1590018539066642434",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018538747535360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018533861515265",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590018526399836160",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590018511803678720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018490991521792",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018470200344576",
   "Positive": "0.9925389885902405",
   "Negative": "3.66398089681752e-05",
   "Neutral": "0.0073991515673696995",
   "Mixed": "2.5185654521919787e-05"
 },
 {
   "tweet_id": "1590018459857223680",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590018426143010816",
   "Positive": "0.10067065805196762",
   "Negative": "0.0021774505730718374",
   "Neutral": "0.8971261978149414",
   "Mixed": "2.5661553081590682e-05"
 },
 {
   "tweet_id": "1590018425791053826",
   "Positive": "0.9723837971687317",
   "Negative": "0.00020625136676244438",
   "Neutral": "0.027385199442505836",
   "Mixed": "2.4802604457363486e-05"
 },
 {
   "tweet_id": "1590018404635009025",
   "Positive": "0.9856562614440918",
   "Negative": "8.151590736815706e-05",
   "Neutral": "0.014246459119021893",
   "Mixed": "1.5702049495303072e-05"
 },
 {
   "tweet_id": "1590018398448414721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018394677719041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018392010153986",
   "Positive": "0.9989803433418274",
   "Negative": "2.6732324840850197e-05",
   "Neutral": "0.0009744964772835374",
   "Mixed": "1.8455781173543073e-05"
 },
 {
   "tweet_id": "1590018380811358208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018366814572544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018365653135363",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018364726181888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018356710895616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018355146412033",
   "Positive": "0.004625851288437843",
   "Negative": "0.533860445022583",
   "Neutral": "0.46139058470726013",
   "Mixed": "0.00012311719183344394"
 },
 {
   "tweet_id": "1590018354097463297",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018320673443840",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590018319670980614",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018313349840896",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590018310267363329",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590018292609331206",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018282073264130",
   "Positive": "0.03575654700398445",
   "Negative": "0.0009177450556308031",
   "Neutral": "0.9633092880249023",
   "Mixed": "1.6456906450912356e-05"
 },
 {
   "tweet_id": "1590018265363140610",
   "Positive": "0.09106489270925522",
   "Negative": "0.08670841157436371",
   "Neutral": "0.8219885230064392",
   "Mixed": "0.0002382584207225591"
 },
 {
   "tweet_id": "1590018236565041152",
   "Positive": "0.9723837971687317",
   "Negative": "0.00020625136676244438",
   "Neutral": "0.027385199442505836",
   "Mixed": "2.4802604457363486e-05"
 },
 {
   "tweet_id": "1590018235109629952",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018230743367680",
   "Positive": "0.14777034521102905",
   "Negative": "0.023619303479790688",
   "Neutral": "0.8285128474235535",
   "Mixed": "9.74792055785656e-05"
 },
 {
   "tweet_id": "1590018222241116161",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590018219490050049",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018202469568514",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590018200141692930",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590018186698596352",
   "Positive": "0.2249876856803894",
   "Negative": "0.0017296153819188476",
   "Neutral": "0.7729607820510864",
   "Mixed": "0.00032199342967942357"
 },
 {
   "tweet_id": "1590018184022986752",
   "Positive": "0.19549022614955902",
   "Negative": "0.6496926546096802",
   "Neutral": "0.12713253498077393",
   "Mixed": "0.027684643864631653"
 },
 {
   "tweet_id": "1590018176410341377",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590018171205214209",
   "Positive": "0.055390555411577225",
   "Negative": "0.006125190760940313",
   "Neutral": "0.9382358193397522",
   "Mixed": "0.0002483805874362588"
 },
 {
   "tweet_id": "1590018164070711296",
   "Positive": "0.0013972234446555376",
   "Negative": "0.007554341107606888",
   "Neutral": "0.9910354614257812",
   "Mixed": "1.3036302334512584e-05"
 },
 {
   "tweet_id": "1590018158664232960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590018147675156480",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590018146835972096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018135050313729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018133007699969",
   "Positive": "0.5242847800254822",
   "Negative": "0.0015917322598397732",
   "Neutral": "0.47409310936927795",
   "Mixed": "3.0413526474148966e-05"
 },
 {
   "tweet_id": "1590018104872304643",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018080893460480",
   "Positive": "0.024720067158341408",
   "Negative": "0.0006302253459580243",
   "Neutral": "0.974643886089325",
   "Mixed": "5.855217295902548e-06"
 },
 {
   "tweet_id": "1590018056071176192",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590018051419742208",
   "Positive": "0.0004442507342901081",
   "Negative": "0.0007361486786976457",
   "Neutral": "0.9988043308258057",
   "Mixed": "1.5280420484486967e-05"
 },
 {
   "tweet_id": "1590018041433444353",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590018033136771072",
   "Positive": "0.061781611293554306",
   "Negative": "0.0015107723884284496",
   "Neutral": "0.9366444945335388",
   "Mixed": "6.303547706920654e-05"
 },
 {
   "tweet_id": "1590018016980652032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590018016863215616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590018009447661568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017989264691201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017987024936960",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590017974576230400",
   "Positive": "0.3205885887145996",
   "Negative": "0.00020894152112305164",
   "Neutral": "0.6791378259658813",
   "Mixed": "6.459103315137327e-05"
 },
 {
   "tweet_id": "1590017966351220738",
   "Positive": "0.12729477882385254",
   "Negative": "0.01996087282896042",
   "Neutral": "0.8527153134346008",
   "Mixed": "2.907552152464632e-05"
 },
 {
   "tweet_id": "1590017964320751619",
   "Positive": "0.0039506456814706326",
   "Negative": "0.0005043795681558549",
   "Neutral": "0.9955412745475769",
   "Mixed": "3.716354513016995e-06"
 },
 {
   "tweet_id": "1590017939373436930",
   "Positive": "0.8631800413131714",
   "Negative": "0.00043343540164642036",
   "Neutral": "0.13145187497138977",
   "Mixed": "0.004934671800583601"
 },
 {
   "tweet_id": "1590017920020934657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017919911854081",
   "Positive": "0.3212435841560364",
   "Negative": "0.004423904698342085",
   "Neutral": "0.6741851568222046",
   "Mixed": "0.00014737300807610154"
 },
 {
   "tweet_id": "1590017918120910848",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590017904363573249",
   "Positive": "0.0022695588413625956",
   "Negative": "0.0001258522243006155",
   "Neutral": "0.997588038444519",
   "Mixed": "1.6479558325954713e-05"
 },
 {
   "tweet_id": "1590017902639714305",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590017902585196544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017891302518784",
   "Positive": "0.07156705111265182",
   "Negative": "0.0025680253747850657",
   "Neutral": "0.925757884979248",
   "Mixed": "0.00010706244938774034"
 },
 {
   "tweet_id": "1590017889574473728",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590017882540638208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017881445904385",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017881235812353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017872306536448",
   "Positive": "0.12126541137695312",
   "Negative": "0.0012040683068335056",
   "Neutral": "0.8775043487548828",
   "Mixed": "2.6148052711505443e-05"
 },
 {
   "tweet_id": "1590017844410200064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017840308183041",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017838932455424",
   "Positive": "0.022778404876589775",
   "Negative": "0.0007273665978573263",
   "Neutral": "0.9764852523803711",
   "Mixed": "8.983889529190492e-06"
 },
 {
   "tweet_id": "1590017830799671301",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017818153865217",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017814282506240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017799988334592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017793478758400",
   "Positive": "0.043255243450403214",
   "Negative": "0.000825059658382088",
   "Neutral": "0.9559102654457092",
   "Mixed": "9.359878276882228e-06"
 },
 {
   "tweet_id": "1590017791494873088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590017790375006208",
   "Positive": "0.0007234197692014277",
   "Negative": "0.005287193693220615",
   "Neutral": "0.9939683675765991",
   "Mixed": "2.097665856126696e-05"
 },
 {
   "tweet_id": "1590017781352710145",
   "Positive": "0.02075330726802349",
   "Negative": "0.010716493241488934",
   "Neutral": "0.9678434729576111",
   "Mixed": "0.0006867008050903678"
 },
 {
   "tweet_id": "1590017778375069697",
   "Positive": "0.0007234197692014277",
   "Negative": "0.005287193693220615",
   "Neutral": "0.9939683675765991",
   "Mixed": "2.097665856126696e-05"
 },
 {
   "tweet_id": "1590017767918669824",
   "Positive": "0.0036816750653088093",
   "Negative": "0.0005571941728703678",
   "Neutral": "0.9957512617111206",
   "Mixed": "9.834442607825622e-06"
 },
 {
   "tweet_id": "1590017745659518982",
   "Positive": "0.0024771648459136486",
   "Negative": "0.000289417861495167",
   "Neutral": "0.9972298741340637",
   "Mixed": "3.5106133964291075e-06"
 },
 {
   "tweet_id": "1590017717704495105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017699866107904",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590017696628080643",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017696514867202",
   "Positive": "0.000734085391741246",
   "Negative": "0.0001305324403801933",
   "Neutral": "0.9991324543952942",
   "Mixed": "2.9207933494035387e-06"
 },
 {
   "tweet_id": "1590017694560292866",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017693817929730",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017690177269766",
   "Positive": "0.823642373085022",
   "Negative": "0.00019142952805850655",
   "Neutral": "0.17611844837665558",
   "Mixed": "4.7733308747410774e-05"
 },
 {
   "tweet_id": "1590017685966196737",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590017661085560833",
   "Positive": "0.004131809808313847",
   "Negative": "0.0015544703928753734",
   "Neutral": "0.9943100214004517",
   "Mixed": "3.6956269013899146e-06"
 },
 {
   "tweet_id": "1590017612087689216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017602713440256",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1590017570681540608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017554667687936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017553350262785",
   "Positive": "0.34665048122406006",
   "Negative": "0.04069065302610397",
   "Neutral": "0.4577704071998596",
   "Mixed": "0.1548883616924286"
 },
 {
   "tweet_id": "1590017543598919681",
   "Positive": "0.9677762389183044",
   "Negative": "7.19419913366437e-05",
   "Neutral": "0.03213369846343994",
   "Mixed": "1.805126157705672e-05"
 },
 {
   "tweet_id": "1590017529610530816",
   "Positive": "0.00237870030105114",
   "Negative": "0.8745644688606262",
   "Neutral": "0.12286488711833954",
   "Mixed": "0.0001919550559250638"
 },
 {
   "tweet_id": "1590017525693419527",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590017522438647808",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590017519624278017",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017491589558272",
   "Positive": "0.0014656977728009224",
   "Negative": "0.06577297300100327",
   "Neutral": "0.9327446222305298",
   "Mixed": "1.6684462025295943e-05"
 },
 {
   "tweet_id": "1590017489920233474",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590017475064000512",
   "Positive": "0.41798195242881775",
   "Negative": "0.003449673531576991",
   "Neutral": "0.5763985514640808",
   "Mixed": "0.0021697701886296272"
 },
 {
   "tweet_id": "1590017470722895872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017459758956545",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017457133355011",
   "Positive": "0.44415855407714844",
   "Negative": "0.0014669832307845354",
   "Neutral": "0.5538148283958435",
   "Mixed": "0.0005596681730821729"
 },
 {
   "tweet_id": "1590017452322455555",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017447142490115",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017418847748098",
   "Positive": "0.406615674495697",
   "Negative": "0.000462075142422691",
   "Neutral": "0.592640221118927",
   "Mixed": "0.00028200758970342577"
 },
 {
   "tweet_id": "1590017414041071616",
   "Positive": "0.9446789026260376",
   "Negative": "0.00018696329789236188",
   "Neutral": "0.05511810630559921",
   "Mixed": "1.6081616195151582e-05"
 },
 {
   "tweet_id": "1590017401029992448",
   "Positive": "0.05083379149436951",
   "Negative": "0.03522314131259918",
   "Neutral": "0.9138776063919067",
   "Mixed": "6.551443948410451e-05"
 },
 {
   "tweet_id": "1590017391664455681",
   "Positive": "0.016035886481404305",
   "Negative": "0.0004118919896427542",
   "Neutral": "0.9835456609725952",
   "Mixed": "6.517465862998506e-06"
 },
 {
   "tweet_id": "1590017370034438146",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017361989767168",
   "Positive": "0.8675772547721863",
   "Negative": "0.00018793053459376097",
   "Neutral": "0.13215483725070953",
   "Mixed": "7.999830995686352e-05"
 },
 {
   "tweet_id": "1590017291059490816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017287494709249",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1590017278892199936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017260806369280",
   "Positive": "0.003469081362709403",
   "Negative": "0.7640554904937744",
   "Neutral": "0.22989268600940704",
   "Mixed": "0.002582796150818467"
 },
 {
   "tweet_id": "1590017248286375936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017236135092224",
   "Positive": "0.00495251314714551",
   "Negative": "0.17235057055950165",
   "Neutral": "0.8205606341362",
   "Mixed": "0.0021362388506531715"
 },
 {
   "tweet_id": "1590017234478714880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590017219404378114",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017215109431296",
   "Positive": "0.0733371302485466",
   "Negative": "0.03784850612282753",
   "Neutral": "0.8885492086410522",
   "Mixed": "0.0002652684925124049"
 },
 {
   "tweet_id": "1590017189142466561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590017160356630528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017133609906180",
   "Positive": "0.5547393560409546",
   "Negative": "0.001490452908910811",
   "Neutral": "0.44368505477905273",
   "Mixed": "8.518532558809966e-05"
 },
 {
   "tweet_id": "1590017128471859202",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017124575383554",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590017100307124224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590017091712987136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590017066584535041",
   "Positive": "0.15954738855361938",
   "Negative": "0.0007777572609484196",
   "Neutral": "0.8395542502403259",
   "Mixed": "0.00012058467109454796"
 },
 {
   "tweet_id": "1590017065359851520",
   "Positive": "0.2860133647918701",
   "Negative": "0.0019630775786936283",
   "Neutral": "0.7119759917259216",
   "Mixed": "4.7542707761749625e-05"
 },
 {
   "tweet_id": "1590017043570782211",
   "Positive": "0.32456520199775696",
   "Negative": "0.02054150216281414",
   "Neutral": "0.6548181772232056",
   "Mixed": "7.506945985369384e-05"
 },
 {
   "tweet_id": "1590017018119364608",
   "Positive": "0.0064320010133087635",
   "Negative": "0.00014071783516556025",
   "Neutral": "0.9933934807777405",
   "Mixed": "3.37601377395913e-05"
 },
 {
   "tweet_id": "1590017010234462209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016984548507648",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590016976193085440",
   "Positive": "0.04750494286417961",
   "Negative": "0.023991873487830162",
   "Neutral": "0.9281013607978821",
   "Mixed": "0.0004018067556899041"
 },
 {
   "tweet_id": "1590016974243115009",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590016969310621696",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590016952663441408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016948338716672",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590016944014753794",
   "Positive": "0.00040162570076063275",
   "Negative": "0.000170158309629187",
   "Neutral": "0.9993938207626343",
   "Mixed": "3.4454820706741884e-05"
 },
 {
   "tweet_id": "1590016931356344321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016918739906560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016912800768001",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590016911269470210",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016890264776704",
   "Positive": "0.0024771648459136486",
   "Negative": "0.000289417861495167",
   "Neutral": "0.9972298741340637",
   "Mixed": "3.5106133964291075e-06"
 },
 {
   "tweet_id": "1590016883197374465",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590016880529788928",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016878184890368",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590016874687135745",
   "Positive": "0.023416558280587196",
   "Negative": "0.0072426400147378445",
   "Neutral": "0.9692837595939636",
   "Mixed": "5.708829121431336e-05"
 },
 {
   "tweet_id": "1590016864674930688",
   "Positive": "0.016035886481404305",
   "Negative": "0.0004118919896427542",
   "Neutral": "0.9835456609725952",
   "Mixed": "6.517465862998506e-06"
 },
 {
   "tweet_id": "1590016848304963586",
   "Positive": "0.2598969340324402",
   "Negative": "0.07044331729412079",
   "Neutral": "0.6694464087486267",
   "Mixed": "0.00021328346338123083"
 },
 {
   "tweet_id": "1590016839895371776",
   "Positive": "0.01247783750295639",
   "Negative": "0.7065746188163757",
   "Neutral": "0.28007984161376953",
   "Mixed": "0.0008676539291627705"
 },
 {
   "tweet_id": "1590016814989205509",
   "Positive": "0.03565836325287819",
   "Negative": "0.0004481004143599421",
   "Neutral": "0.9636386036872864",
   "Mixed": "0.00025491189444437623"
 },
 {
   "tweet_id": "1590016813068300288",
   "Positive": "0.00867684930562973",
   "Negative": "0.00012036506086587906",
   "Neutral": "0.9911466240882874",
   "Mixed": "5.6160064559662715e-05"
 },
 {
   "tweet_id": "1590016812523356160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016801823657985",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016791899959296",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1590016790029307905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016789752451072",
   "Positive": "0.07882611453533173",
   "Negative": "0.0005699496832676232",
   "Neutral": "0.9205403327941895",
   "Mixed": "6.355771620292217e-05"
 },
 {
   "tweet_id": "1590016786757750784",
   "Positive": "0.0006118127494119108",
   "Negative": "0.0009221930522471666",
   "Neutral": "0.998450756072998",
   "Mixed": "1.518792942079017e-05"
 },
 {
   "tweet_id": "1590016784778022912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016779363180544",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590016761021501440",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1590016758647525377",
   "Positive": "0.034441541880369186",
   "Negative": "0.0023633555974811316",
   "Neutral": "0.9630520343780518",
   "Mixed": "0.00014298941823653877"
 },
 {
   "tweet_id": "1590016746832158720",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590016741912215553",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590016716800946176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016707950624768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016675956813824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016656436527105",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590016655778000896",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590016650816126979",
   "Positive": "0.0009118688758462667",
   "Negative": "0.9779274463653564",
   "Neutral": "0.020944412797689438",
   "Mixed": "0.00021621327323373407"
 },
 {
   "tweet_id": "1590016636815544320",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016627441274882",
   "Positive": "0.8292680978775024",
   "Negative": "0.00032917511998675764",
   "Neutral": "0.1702605038881302",
   "Mixed": "0.00014225274208001792"
 },
 {
   "tweet_id": "1590016625058926596",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016623628673026",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016621078536192",
   "Positive": "0.9416372179985046",
   "Negative": "0.004506657365709543",
   "Neutral": "0.05374126881361008",
   "Mixed": "0.00011489022290334105"
 },
 {
   "tweet_id": "1590016608797601795",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016599343652864",
   "Positive": "0.009213658049702644",
   "Negative": "0.6650005578994751",
   "Neutral": "0.2873865067958832",
   "Mixed": "0.038399290293455124"
 },
 {
   "tweet_id": "1590016595577155586",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016585083006978",
   "Positive": "0.013116617687046528",
   "Negative": "0.00011546134919626638",
   "Neutral": "0.9867451786994934",
   "Mixed": "2.269641299790237e-05"
 },
 {
   "tweet_id": "1590016581903388674",
   "Positive": "0.0471763089299202",
   "Negative": "0.0004292454104870558",
   "Neutral": "0.9523830413818359",
   "Mixed": "1.1381602234905586e-05"
 },
 {
   "tweet_id": "1590016573649358849",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016567672467457",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016550928781313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016518690963456",
   "Positive": "0.6910392045974731",
   "Negative": "0.0003797018143814057",
   "Neutral": "0.308541864156723",
   "Mixed": "3.9206879591802135e-05"
 },
 {
   "tweet_id": "1590016511191977985",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590016499749900293",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016498046672898",
   "Positive": "0.0030866984743624926",
   "Negative": "0.8672010898590088",
   "Neutral": "0.1276545226573944",
   "Mixed": "0.0020576606038957834"
 },
 {
   "tweet_id": "1590016494108553217",
   "Positive": "0.012993176467716694",
   "Negative": "0.0007789506926201284",
   "Neutral": "0.9862179160118103",
   "Mixed": "1.00244942586869e-05"
 },
 {
   "tweet_id": "1590016481722761221",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016481529835521",
   "Positive": "0.0003561826888471842",
   "Negative": "0.0003000185824930668",
   "Neutral": "0.9993351101875305",
   "Mixed": "8.637853170512244e-06"
 },
 {
   "tweet_id": "1590016469584457730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590016461523021825",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590016455059574785",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590016433416966144",
   "Positive": "0.0049154553562402725",
   "Negative": "0.04301000013947487",
   "Neutral": "0.9520162343978882",
   "Mixed": "5.838024299009703e-05"
 },
 {
   "tweet_id": "1590016430942347265",
   "Positive": "0.001036912202835083",
   "Negative": "0.0013740353751927614",
   "Neutral": "0.997547447681427",
   "Mixed": "4.157919829594903e-05"
 },
 {
   "tweet_id": "1590016428098621441",
   "Positive": "0.31159672141075134",
   "Negative": "0.0024315298069268465",
   "Neutral": "0.6831462383270264",
   "Mixed": "0.0028255414217710495"
 },
 {
   "tweet_id": "1590016412591960065",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016411422064640",
   "Positive": "0.060520004481077194",
   "Negative": "0.27832847833633423",
   "Neutral": "0.4927654266357422",
   "Mixed": "0.16838610172271729"
 },
 {
   "tweet_id": "1590016374486679552",
   "Positive": "0.007930913008749485",
   "Negative": "0.00012499229342211038",
   "Neutral": "0.9919004440307617",
   "Mixed": "4.364188862382434e-05"
 },
 {
   "tweet_id": "1590016372914163713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016336436297729",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016322934652932",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590016297479593986",
   "Positive": "0.006120441015809774",
   "Negative": "0.4382840692996979",
   "Neutral": "0.5481380820274353",
   "Mixed": "0.007457403931766748"
 },
 {
   "tweet_id": "1590016296498139136",
   "Positive": "0.047147806733846664",
   "Negative": "0.00031388047500513494",
   "Neutral": "0.9523297548294067",
   "Mixed": "0.00020849888096563518"
 },
 {
   "tweet_id": "1590016280425529344",
   "Positive": "0.024323003366589546",
   "Negative": "0.15806470811367035",
   "Neutral": "0.8141981959342957",
   "Mixed": "0.003414096776396036"
 },
 {
   "tweet_id": "1590016268408881152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016264084545536",
   "Positive": "0.04030944034457207",
   "Negative": "0.00033416241058148444",
   "Neutral": "0.9593226313591003",
   "Mixed": "3.3788364817155525e-05"
 },
 {
   "tweet_id": "1590016218916073472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016217183813633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016216923803650",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016214738141185",
   "Positive": "0.022660275921225548",
   "Negative": "0.12404832988977432",
   "Neutral": "0.853256344795227",
   "Mixed": "3.511441173031926e-05"
 },
 {
   "tweet_id": "1590016169226174464",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590016163740016640",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590016163404447744",
   "Positive": "0.8120607733726501",
   "Negative": "0.002709128661081195",
   "Neutral": "0.1850484162569046",
   "Mixed": "0.0001817508600652218"
 },
 {
   "tweet_id": "1590016135847874561",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590016115996258306",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016105363681280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016087328194561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016055577300993",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590016032588324864",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590016028788273152",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590016004847194113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015976778891265",
   "Positive": "0.9194915890693665",
   "Negative": "0.0006614122539758682",
   "Neutral": "0.04938279092311859",
   "Mixed": "0.030464299023151398"
 },
 {
   "tweet_id": "1590015964049215489",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590015955371175941",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590015943790710784",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590015937167908864",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1590015930222141441",
   "Positive": "0.5505046248435974",
   "Negative": "0.1102772057056427",
   "Neutral": "0.2623406946659088",
   "Mixed": "0.07687743008136749"
 },
 {
   "tweet_id": "1590015919862222848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015917479833600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015914984243201",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015896965500929",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590015895258411009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015890103627776",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590015874366582785",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015872793739265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015869240848384",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1590015864816168960",
   "Positive": "0.9745862483978271",
   "Negative": "0.00021446550090331584",
   "Neutral": "0.025159813463687897",
   "Mixed": "3.949504389311187e-05"
 },
 {
   "tweet_id": "1590015855085367296",
   "Positive": "0.9907153844833374",
   "Negative": "9.77189774857834e-05",
   "Neutral": "0.009172799997031689",
   "Mixed": "1.419144155079266e-05"
 },
 {
   "tweet_id": "1590015844616400898",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590015840334012416",
   "Positive": "0.17450860142707825",
   "Negative": "0.0002229668607469648",
   "Neutral": "0.8250176906585693",
   "Mixed": "0.0002507849712856114"
 },
 {
   "tweet_id": "1590015819677073409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015819437965312",
   "Positive": "0.000958818185608834",
   "Negative": "0.00016609756858088076",
   "Neutral": "0.9988705515861511",
   "Mixed": "4.4656635509454645e-06"
 },
 {
   "tweet_id": "1590015817131122689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015808323063809",
   "Positive": "0.04812941700220108",
   "Negative": "0.001315640052780509",
   "Neutral": "0.9505417346954346",
   "Mixed": "1.3108325219945982e-05"
 },
 {
   "tweet_id": "1590015796549410816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015794532225024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015788513394689",
   "Positive": "0.017954232171177864",
   "Negative": "0.0023444509133696556",
   "Neutral": "0.9796916842460632",
   "Mixed": "9.710423000797164e-06"
 },
 {
   "tweet_id": "1590015787787759616",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1590015784008310785",
   "Positive": "0.016317199915647507",
   "Negative": "0.013639422133564949",
   "Neutral": "0.969997227191925",
   "Mixed": "4.6169054257916287e-05"
 },
 {
   "tweet_id": "1590015754971541505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015750890147842",
   "Positive": "0.12544454634189606",
   "Negative": "0.008108305744826794",
   "Neutral": "0.8663744330406189",
   "Mixed": "7.270390779012814e-05"
 },
 {
   "tweet_id": "1590015719495782400",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1590015718086479873",
   "Positive": "0.012116723693907261",
   "Negative": "0.005879951175302267",
   "Neutral": "0.9610687494277954",
   "Mixed": "0.02093464322388172"
 },
 {
   "tweet_id": "1590015717688369152",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590015717549621248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015716258107393",
   "Positive": "0.037445440888404846",
   "Negative": "0.4599969685077667",
   "Neutral": "0.4349333643913269",
   "Mixed": "0.06762413680553436"
 },
 {
   "tweet_id": "1590015714219261954",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015714060283905",
   "Positive": "0.018586095422506332",
   "Negative": "0.1906510442495346",
   "Neutral": "0.7854987382888794",
   "Mixed": "0.0052641998045146465"
 },
 {
   "tweet_id": "1590015710692265985",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015694430965761",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015691360722944",
   "Positive": "0.0019417001167312264",
   "Negative": "0.0002237797452835366",
   "Neutral": "0.9978297352790833",
   "Mixed": "4.787307716469513e-06"
 },
 {
   "tweet_id": "1590015684666626048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015679331471360",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1590015678018621441",
   "Positive": "0.006686417851597071",
   "Negative": "0.5648874640464783",
   "Neutral": "0.42826247215270996",
   "Mixed": "0.00016366397903766483"
 },
 {
   "tweet_id": "1590015667671273472",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590015664374558721",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590015652353683457",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015643071676416",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015640647397378",
   "Positive": "0.3840671479701996",
   "Negative": "0.0004947265842929482",
   "Neutral": "0.615186870098114",
   "Mixed": "0.0002512633509468287"
 },
 {
   "tweet_id": "1590015636700565506",
   "Positive": "0.406615674495697",
   "Negative": "0.000462075142422691",
   "Neutral": "0.592640221118927",
   "Mixed": "0.00028200758970342577"
 },
 {
   "tweet_id": "1590015632132952064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015609739546624",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590015608233816064",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590015602923819011",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015602370170881",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1590015599786487808",
   "Positive": "0.013209897093474865",
   "Negative": "0.008570292964577675",
   "Neutral": "0.9777772426605225",
   "Mixed": "0.0004425423685461283"
 },
 {
   "tweet_id": "1590015585781530625",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590015582266875904",
   "Positive": "0.021137768402695656",
   "Negative": "0.003472890704870224",
   "Neutral": "0.9753817915916443",
   "Mixed": "7.613754860358313e-06"
 },
 {
   "tweet_id": "1590015580744331264",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015575937273857",
   "Positive": "0.022811612114310265",
   "Negative": "0.035499539226293564",
   "Neutral": "0.941575825214386",
   "Mixed": "0.00011299942707410082"
 },
 {
   "tweet_id": "1590015571210678273",
   "Positive": "0.10383576154708862",
   "Negative": "0.05904819443821907",
   "Neutral": "0.7915908694267273",
   "Mixed": "0.04552515596151352"
 },
 {
   "tweet_id": "1590015559814742017",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015541506637824",
   "Positive": "0.004966727923601866",
   "Negative": "0.13347101211547852",
   "Neutral": "0.8344399929046631",
   "Mixed": "0.027122199535369873"
 },
 {
   "tweet_id": "1590015536637018112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015500582469632",
   "Positive": "0.022683007642626762",
   "Negative": "0.18439386785030365",
   "Neutral": "0.11250133812427521",
   "Mixed": "0.6804218292236328"
 },
 {
   "tweet_id": "1590015489207857155",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590015488288923649",
   "Positive": "0.0007937630289234221",
   "Negative": "0.0033035019878298044",
   "Neutral": "0.9957472681999207",
   "Mixed": "0.0001555455819470808"
 },
 {
   "tweet_id": "1590015483755245575",
   "Positive": "0.018409110605716705",
   "Negative": "0.09266950935125351",
   "Neutral": "0.8831066489219666",
   "Mixed": "0.005814740434288979"
 },
 {
   "tweet_id": "1590015447717449729",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015428554027010",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015428130410498",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590015427929047040",
   "Positive": "0.05215897411108017",
   "Negative": "0.0030584021005779505",
   "Neutral": "0.9447641372680664",
   "Mixed": "1.8490341972210445e-05"
 },
 {
   "tweet_id": "1590015426867912704",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590015406949138432",
   "Positive": "0.002197295194491744",
   "Negative": "0.5316352248191833",
   "Neutral": "0.46592020988464355",
   "Mixed": "0.0002473499334882945"
 },
 {
   "tweet_id": "1590015405845663744",
   "Positive": "0.022811612114310265",
   "Negative": "0.035499539226293564",
   "Neutral": "0.941575825214386",
   "Mixed": "0.00011299942707410082"
 },
 {
   "tweet_id": "1590015387076526080",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590015386678067201",
   "Positive": "0.2039036899805069",
   "Negative": "0.003353714244440198",
   "Neutral": "0.7927260994911194",
   "Mixed": "1.648088982619811e-05"
 },
 {
   "tweet_id": "1590015379547762689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015353014595584",
   "Positive": "0.30563804507255554",
   "Negative": "0.006911686155945063",
   "Neutral": "0.6874138116836548",
   "Mixed": "3.651631050161086e-05"
 },
 {
   "tweet_id": "1590015352926515201",
   "Positive": "0.39189180731773376",
   "Negative": "0.000498375971801579",
   "Neutral": "0.6072690486907959",
   "Mixed": "0.0003407186595723033"
 },
 {
   "tweet_id": "1590015352628736000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015348455395328",
   "Positive": "0.03578925505280495",
   "Negative": "0.028660695999860764",
   "Neutral": "0.9353182911872864",
   "Mixed": "0.00023170463100541383"
 },
 {
   "tweet_id": "1590015326569508866",
   "Positive": "0.5541945099830627",
   "Negative": "0.003560709534212947",
   "Neutral": "0.44063901901245117",
   "Mixed": "0.0016058131586760283"
 },
 {
   "tweet_id": "1590015316926803969",
   "Positive": "0.06869803369045258",
   "Negative": "0.10261616110801697",
   "Neutral": "0.5775842070579529",
   "Mixed": "0.2511015832424164"
 },
 {
   "tweet_id": "1590015316238950403",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015304759119872",
   "Positive": "0.17380857467651367",
   "Negative": "0.012509610503911972",
   "Neutral": "0.8136441707611084",
   "Mixed": "3.7623001844622195e-05"
 },
 {
   "tweet_id": "1590015282546094083",
   "Positive": "0.2077425718307495",
   "Negative": "0.0639360100030899",
   "Neutral": "0.7036026120185852",
   "Mixed": "0.024718785658478737"
 },
 {
   "tweet_id": "1590015265966030850",
   "Positive": "0.5725815296173096",
   "Negative": "0.00477746594697237",
   "Neutral": "0.4225970506668091",
   "Mixed": "4.3922824261244386e-05"
 },
 {
   "tweet_id": "1590015263269097472",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590015251491479554",
   "Positive": "0.005342684220522642",
   "Negative": "0.00020373622828628868",
   "Neutral": "0.9944503307342529",
   "Mixed": "3.262048267060891e-06"
 },
 {
   "tweet_id": "1590015242272378881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015238673674240",
   "Positive": "0.5359868407249451",
   "Negative": "0.0012270386796444654",
   "Neutral": "0.4627576470375061",
   "Mixed": "2.8502006898634136e-05"
 },
 {
   "tweet_id": "1590015225528750081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015225352552449",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590015212018880512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015208466309124",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590015203768676352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015198488047617",
   "Positive": "0.004727558232843876",
   "Negative": "0.10132532566785812",
   "Neutral": "0.8920630812644958",
   "Mixed": "0.0018840424017980695"
 },
 {
   "tweet_id": "1590015198483873795",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015169895493633",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590015164119941120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015157786542080",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590015138698256384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015131010076675",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015130648997890",
   "Positive": "0.0038853378500789404",
   "Negative": "0.000276160251814872",
   "Neutral": "0.9958357810974121",
   "Mixed": "2.7254336600890383e-06"
 },
 {
   "tweet_id": "1590015128287657985",
   "Positive": "0.4243520498275757",
   "Negative": "0.0004693444352596998",
   "Neutral": "0.574827253818512",
   "Mixed": "0.00035131495678797364"
 },
 {
   "tweet_id": "1590015110441226240",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015105370324992",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590015093005520896",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015064538746881",
   "Positive": "0.01133788377046585",
   "Negative": "0.7161466479301453",
   "Neutral": "0.2709703743457794",
   "Mixed": "0.0015450860373675823"
 },
 {
   "tweet_id": "1590015063502761984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015053277057025",
   "Positive": "0.004385636653751135",
   "Negative": "0.00011733090650523081",
   "Neutral": "0.9954859614372253",
   "Mixed": "1.1011412425432354e-05"
 },
 {
   "tweet_id": "1590015047061090304",
   "Positive": "0.011481290683150291",
   "Negative": "0.08035605400800705",
   "Neutral": "0.9079115390777588",
   "Mixed": "0.00025114708114415407"
 },
 {
   "tweet_id": "1590015046004150272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590015018871160833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590015005495214080",
   "Positive": "0.9755902886390686",
   "Negative": "0.00014611470396630466",
   "Neutral": "0.02387099340558052",
   "Mixed": "0.00039263488724827766"
 },
 {
   "tweet_id": "1590014972641574912",
   "Positive": "0.023416558280587196",
   "Negative": "0.0072426400147378445",
   "Neutral": "0.9692837595939636",
   "Mixed": "5.708829121431336e-05"
 },
 {
   "tweet_id": "1590014958812528640",
   "Positive": "0.08889856189489365",
   "Negative": "0.0003228142741136253",
   "Neutral": "0.9095723032951355",
   "Mixed": "0.0012062930036336184"
 },
 {
   "tweet_id": "1590014955922681856",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590014953037398018",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014951644884992",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590014951351279618",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1590014942090235910",
   "Positive": "0.278143048286438",
   "Negative": "0.005417493171989918",
   "Neutral": "0.716215193271637",
   "Mixed": "0.00022431265097111464"
 },
 {
   "tweet_id": "1590014909903163392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014901296443395",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014896695308288",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014880051970048",
   "Positive": "0.12096651643514633",
   "Negative": "0.00021124802879057825",
   "Neutral": "0.8787747621536255",
   "Mixed": "4.7458899643970653e-05"
 },
 {
   "tweet_id": "1590014874700378112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014874251206656",
   "Positive": "0.003992464859038591",
   "Negative": "0.00018064329924527556",
   "Neutral": "0.9958211183547974",
   "Mixed": "5.836544460180448e-06"
 },
 {
   "tweet_id": "1590014872611606528",
   "Positive": "0.9158371090888977",
   "Negative": "0.00027632497949525714",
   "Neutral": "0.08360739797353745",
   "Mixed": "0.0002792262239381671"
 },
 {
   "tweet_id": "1590014823609565184",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590014817925885954",
   "Positive": "0.00019573306781239808",
   "Negative": "0.9940540194511414",
   "Neutral": "0.005716998595744371",
   "Mixed": "3.329231549287215e-05"
 },
 {
   "tweet_id": "1590014801308442625",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590014771021373442",
   "Positive": "0.1732519119977951",
   "Negative": "0.001712706172838807",
   "Neutral": "0.824349045753479",
   "Mixed": "0.000686359591782093"
 },
 {
   "tweet_id": "1590014763899138048",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590014744349806592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014727278632965",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590014724510744576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014724196151296",
   "Positive": "0.005906958598643541",
   "Negative": "0.39529716968536377",
   "Neutral": "0.5987410545349121",
   "Mixed": "5.484671055455692e-05"
 },
 {
   "tweet_id": "1590014713752334340",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590014713458757633",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014694752149506",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014693284155392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014691635757056",
   "Positive": "0.6175010800361633",
   "Negative": "0.014211835339665413",
   "Neutral": "0.36818864941596985",
   "Mixed": "9.847772889770567e-05"
 },
 {
   "tweet_id": "1590014678842822659",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014673260191750",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590014672983711744",
   "Positive": "0.9269401431083679",
   "Negative": "0.0001944898976944387",
   "Neutral": "0.07284300774335861",
   "Mixed": "2.237938497273717e-05"
 },
 {
   "tweet_id": "1590014667677585408",
   "Positive": "0.06301417201757431",
   "Negative": "0.015420235693454742",
   "Neutral": "0.9213816523551941",
   "Mixed": "0.00018389719480182976"
 },
 {
   "tweet_id": "1590014655749320704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014648627384321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014640729137152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014624300404737",
   "Positive": "0.22120805084705353",
   "Negative": "0.019350629299879074",
   "Neutral": "0.7593291401863098",
   "Mixed": "0.00011211084347451106"
 },
 {
   "tweet_id": "1590014612719927296",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014604310351873",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590014588242006016",
   "Positive": "0.0038766665384173393",
   "Negative": "6.417968688765541e-05",
   "Neutral": "0.9960424900054932",
   "Mixed": "1.6623931514914148e-05"
 },
 {
   "tweet_id": "1590014579613974529",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014564170493953",
   "Positive": "0.0018895688699558377",
   "Negative": "7.456748426193371e-05",
   "Neutral": "0.9979612827301025",
   "Mixed": "7.464895315933973e-05"
 },
 {
   "tweet_id": "1590014556289798145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014549910261761",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014523846844416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014523771342848",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590014522789875713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014515844087813",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014515412103168",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014512824225792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014492980961280",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590014482948182018",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014471111835648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014468616224769",
   "Positive": "0.011718589812517166",
   "Negative": "0.28450918197631836",
   "Neutral": "0.7037611603736877",
   "Mixed": "1.1079579962824937e-05"
 },
 {
   "tweet_id": "1590014450589118466",
   "Positive": "0.00016549904830753803",
   "Negative": "0.05360225960612297",
   "Neutral": "0.9462166428565979",
   "Mixed": "1.5538695151917636e-05"
 },
 {
   "tweet_id": "1590014380829446145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014371744583680",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590014352987656194",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590014350898888705",
   "Positive": "0.9755902886390686",
   "Negative": "0.00014611470396630466",
   "Neutral": "0.02387099340558052",
   "Mixed": "0.00039263488724827766"
 },
 {
   "tweet_id": "1590014344225787904",
   "Positive": "0.016317199915647507",
   "Negative": "0.013639422133564949",
   "Neutral": "0.969997227191925",
   "Mixed": "4.6169054257916287e-05"
 },
 {
   "tweet_id": "1590014329671151617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014321228410881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014319617769472",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590014312990789632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014311141085184",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590014309245259777",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014306628022272",
   "Positive": "0.9071170091629028",
   "Negative": "0.00012389740732032806",
   "Neutral": "0.09265153110027313",
   "Mixed": "0.00010754870163509622"
 },
 {
   "tweet_id": "1590014297723531265",
   "Positive": "0.013017346151173115",
   "Negative": "0.0008035540231503546",
   "Neutral": "0.9854345321655273",
   "Mixed": "0.0007444875664077699"
 },
 {
   "tweet_id": "1590014284440166400",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590014281038405632",
   "Positive": "0.31967324018478394",
   "Negative": "0.007641463074833155",
   "Neutral": "0.6725706458091736",
   "Mixed": "0.00011468382581369951"
 },
 {
   "tweet_id": "1590014270036594688",
   "Positive": "0.004907302558422089",
   "Negative": "0.00011125048331450671",
   "Neutral": "0.9949764013290405",
   "Mixed": "5.077101377537474e-06"
 },
 {
   "tweet_id": "1590014267566460928",
   "Positive": "0.9878625869750977",
   "Negative": "0.00018771685427054763",
   "Neutral": "0.011930655688047409",
   "Mixed": "1.9034348952118307e-05"
 },
 {
   "tweet_id": "1590014257164582913",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590014250256576512",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590014239980556290",
   "Positive": "0.820199191570282",
   "Negative": "0.0007355257403105497",
   "Neutral": "0.17903153598308563",
   "Mixed": "3.368938996572979e-05"
 },
 {
   "tweet_id": "1590014237736603650",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014216122998784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014214298402817",
   "Positive": "0.04465622827410698",
   "Negative": "0.25113075971603394",
   "Neutral": "0.07027260214090347",
   "Mixed": "0.6339404582977295"
 },
 {
   "tweet_id": "1590014201501974529",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590014183969808385",
   "Positive": "0.0002491681952960789",
   "Negative": "0.004024090711027384",
   "Neutral": "0.9957194924354553",
   "Mixed": "7.197272680059541e-06"
 },
 {
   "tweet_id": "1590014168027258885",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590014165103804416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014160225865728",
   "Positive": "0.5359868407249451",
   "Negative": "0.0012270386796444654",
   "Neutral": "0.4627576470375061",
   "Mixed": "2.8502006898634136e-05"
 },
 {
   "tweet_id": "1590014149358419969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590014036015722496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014033163616256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590014032513466368",
   "Positive": "0.07272826880216599",
   "Negative": "0.00015805967268534005",
   "Neutral": "0.9271031022071838",
   "Mixed": "1.0486679457244463e-05"
 },
 {
   "tweet_id": "1590014031037104131",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590014021595697153",
   "Positive": "0.6502676010131836",
   "Negative": "0.00260562589392066",
   "Neutral": "0.34649017453193665",
   "Mixed": "0.0006366216694004834"
 },
 {
   "tweet_id": "1590013991690326019",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013990771781634",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013982517391361",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013980982259713",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590013971574427648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013971280834560",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013929866297347",
   "Positive": "0.00331880827434361",
   "Negative": "0.31726178526878357",
   "Neutral": "0.6787329316139221",
   "Mixed": "0.0006864326423965394"
 },
 {
   "tweet_id": "1590013915433684992",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013912841621504",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590013905904238593",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013886933004289",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013883863138304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013854435901441",
   "Positive": "0.9027677178382874",
   "Negative": "0.000608010683208704",
   "Neutral": "0.09460222721099854",
   "Mixed": "0.0020220514852553606"
 },
 {
   "tweet_id": "1590013854272163840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013851218894850",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013844772245506",
   "Positive": "0.07460923492908478",
   "Negative": "0.0005089342594146729",
   "Neutral": "0.9243613481521606",
   "Mixed": "0.0005204049521125853"
 },
 {
   "tweet_id": "1590013836022939649",
   "Positive": "0.004769559949636459",
   "Negative": "0.9095345735549927",
   "Neutral": "0.058521389961242676",
   "Mixed": "0.02717451937496662"
 },
 {
   "tweet_id": "1590013822919913472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013820596260864",
   "Positive": "0.03362598270177841",
   "Negative": "0.12593427300453186",
   "Neutral": "0.8400298953056335",
   "Mixed": "0.00040990629349835217"
 },
 {
   "tweet_id": "1590013795442622466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013788384034817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590013785045364739",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590013773401960450",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590013771615211520",
   "Positive": "0.019295256584882736",
   "Negative": "0.29595717787742615",
   "Neutral": "0.6080231666564941",
   "Mixed": "0.07672440260648727"
 },
 {
   "tweet_id": "1590013768892751872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013764656828416",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590013761062342656",
   "Positive": "0.8224040269851685",
   "Negative": "0.01327473670244217",
   "Neutral": "0.16364037990570068",
   "Mixed": "0.0006808965699747205"
 },
 {
   "tweet_id": "1590013761015906304",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013760395411462",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013755152535552",
   "Positive": "0.1332784742116928",
   "Negative": "0.006560747046023607",
   "Neutral": "0.8601319193840027",
   "Mixed": "2.876684993680101e-05"
 },
 {
   "tweet_id": "1590013754187845632",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590013741059698688",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590013740107239425",
   "Positive": "0.8823704719543457",
   "Negative": "0.00034683721605688334",
   "Neutral": "0.11529047042131424",
   "Mixed": "0.001992188859730959"
 },
 {
   "tweet_id": "1590013725704359936",
   "Positive": "0.005036899819970131",
   "Negative": "0.00011325742525514215",
   "Neutral": "0.9948051571846008",
   "Mixed": "4.473805893212557e-05"
 },
 {
   "tweet_id": "1590013721564225537",
   "Positive": "0.9878625869750977",
   "Negative": "0.00018771685427054763",
   "Neutral": "0.011930655688047409",
   "Mixed": "1.9034348952118307e-05"
 },
 {
   "tweet_id": "1590013719857496064",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013715616739329",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013701192835072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013697212436480",
   "Positive": "0.017538120970129967",
   "Negative": "0.00023019294894766062",
   "Neutral": "0.9822250008583069",
   "Mixed": "6.66096138957073e-06"
 },
 {
   "tweet_id": "1590013678371639297",
   "Positive": "0.9421139359474182",
   "Negative": "0.00042989710345864296",
   "Neutral": "0.05742944777011871",
   "Mixed": "2.671181209734641e-05"
 },
 {
   "tweet_id": "1590013676521918465",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013675171381248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013661375913985",
   "Positive": "0.6083188652992249",
   "Negative": "0.001890051644295454",
   "Neutral": "0.3896711766719818",
   "Mixed": "0.00011994277156190947"
 },
 {
   "tweet_id": "1590013659379806214",
   "Positive": "0.019295256584882736",
   "Negative": "0.29595717787742615",
   "Neutral": "0.6080231666564941",
   "Mixed": "0.07672440260648727"
 },
 {
   "tweet_id": "1590013653893681152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013634872320003",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013622490923013",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590013604224385024",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013604094672896",
   "Positive": "0.044239483773708344",
   "Negative": "0.5586865544319153",
   "Neutral": "0.15686847269535065",
   "Mixed": "0.24020549654960632"
 },
 {
   "tweet_id": "1590013600454029314",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590013591796600832",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590013567427674112",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1590013561526706176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013530484637696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013527322169344",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1590013477313449984",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1590013467267776512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013449580732416",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013432610590720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013409755795460",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590013402076057600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013399987294209",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590013390130655232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013370983673857",
   "Positive": "0.09928600490093231",
   "Negative": "0.5877797603607178",
   "Neutral": "0.10647313296794891",
   "Mixed": "0.20646105706691742"
 },
 {
   "tweet_id": "1590013361982701569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013358014869504",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1590013338708488192",
   "Positive": "0.41522181034088135",
   "Negative": "0.0010607739677652717",
   "Neutral": "0.5836634039878845",
   "Mixed": "5.389742000261322e-05"
 },
 {
   "tweet_id": "1590013309012840449",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1590013284010303489",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590013271398313986",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590013271293104129",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590013265517891584",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1590013251823505410",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590013248958783491",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013245032890369",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013237252456448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013208911577088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590013208592781317",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013202540400640",
   "Positive": "0.9904993176460266",
   "Negative": "0.00013091957953292876",
   "Neutral": "0.009251263923943043",
   "Mixed": "0.00011844529944937676"
 },
 {
   "tweet_id": "1590013169917104128",
   "Positive": "0.9926950931549072",
   "Negative": "6.647613918175921e-05",
   "Neutral": "0.007220777217298746",
   "Mixed": "1.7637048586038873e-05"
 },
 {
   "tweet_id": "1590013169485090818",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590013154729529344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013144545792000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013143904047104",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013141047709696",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013140229505024",
   "Positive": "0.8598864078521729",
   "Negative": "0.00012384666479192674",
   "Neutral": "0.1395343840122223",
   "Mixed": "0.00045534511446021497"
 },
 {
   "tweet_id": "1590013131254030336",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1590013108533485568",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590013107841077249",
   "Positive": "0.004730759654194117",
   "Negative": "0.008272123523056507",
   "Neutral": "0.9868893623352051",
   "Mixed": "0.00010776110138976946"
 },
 {
   "tweet_id": "1590013104620187648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590013085657358337",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590013077818605569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013058117963776",
   "Positive": "0.0007234197692014277",
   "Negative": "0.005287193693220615",
   "Neutral": "0.9939683675765991",
   "Mixed": "2.097665856126696e-05"
 },
 {
   "tweet_id": "1590013034835345408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590013033925210114",
   "Positive": "0.0014495989307761192",
   "Negative": "0.00026770972181111574",
   "Neutral": "0.998272180557251",
   "Mixed": "1.044707005348755e-05"
 },
 {
   "tweet_id": "1590013021472329730",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590013009958948865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590013001905864711",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590012999838072832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012988639313922",
   "Positive": "0.0036413788329809904",
   "Negative": "9.676102490630001e-05",
   "Neutral": "0.9962446093559265",
   "Mixed": "1.7255662896786816e-05"
 },
 {
   "tweet_id": "1590012982389796865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012978375843842",
   "Positive": "0.0066895210184156895",
   "Negative": "0.17135730385780334",
   "Neutral": "0.8217990398406982",
   "Mixed": "0.00015424330194946378"
 },
 {
   "tweet_id": "1590012973481095168",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590012963423154178",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590012959778304000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012959111405568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012934234984453",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590012913766793216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012912378445824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012903746600963",
   "Positive": "0.9212225079536438",
   "Negative": "0.002436076756566763",
   "Neutral": "0.07630657404661179",
   "Mixed": "3.4849541407311335e-05"
 },
 {
   "tweet_id": "1590012884645720064",
   "Positive": "0.015253785997629166",
   "Negative": "0.13850629329681396",
   "Neutral": "0.846085250377655",
   "Mixed": "0.00015460352005902678"
 },
 {
   "tweet_id": "1590012864882167810",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590012858854944768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012854962647041",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012807034327042",
   "Positive": "0.009479586035013199",
   "Negative": "0.0005992988008074462",
   "Neutral": "0.9899107813835144",
   "Mixed": "1.0275527529302053e-05"
 },
 {
   "tweet_id": "1590012784917770241",
   "Positive": "0.2244943380355835",
   "Negative": "0.07789666205644608",
   "Neutral": "0.6705726981163025",
   "Mixed": "0.027036335319280624"
 },
 {
   "tweet_id": "1590012774507511808",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590012769948291072",
   "Positive": "0.00028786552138626575",
   "Negative": "9.919491276377812e-05",
   "Neutral": "0.9995995163917542",
   "Mixed": "1.3476418644131627e-05"
 },
 {
   "tweet_id": "1590012759005335553",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012749773668359",
   "Positive": "0.43454810976982117",
   "Negative": "0.19918452203273773",
   "Neutral": "0.36322176456451416",
   "Mixed": "0.003045593621209264"
 },
 {
   "tweet_id": "1590012734464479232",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590012727757402112",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590012710585921536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012696111742977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012693242843137",
   "Positive": "0.2534678280353546",
   "Negative": "0.10655396431684494",
   "Neutral": "0.6350163817405701",
   "Mixed": "0.004961858037859201"
 },
 {
   "tweet_id": "1590012688431812611",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590012685143650304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012672183267329",
   "Positive": "0.9186611175537109",
   "Negative": "0.0010096534388139844",
   "Neutral": "0.07986026257276535",
   "Mixed": "0.00046901690075173974"
 },
 {
   "tweet_id": "1590012672053248000",
   "Positive": "0.004373227246105671",
   "Negative": "0.31550872325897217",
   "Neutral": "0.6799952983856201",
   "Mixed": "0.00012281305680517107"
 },
 {
   "tweet_id": "1590012671763828736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012671264706560",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590012663760814080",
   "Positive": "0.00020202217274345458",
   "Negative": "0.00052989786490798",
   "Neutral": "0.9992625117301941",
   "Mixed": "5.553642040467821e-06"
 },
 {
   "tweet_id": "1590012661286473730",
   "Positive": "0.06859011203050613",
   "Negative": "0.23499035835266113",
   "Neutral": "0.6956709027290344",
   "Mixed": "0.0007485827663913369"
 },
 {
   "tweet_id": "1590012659004760066",
   "Positive": "0.9857473969459534",
   "Negative": "0.00036591265234164894",
   "Neutral": "0.013849975541234016",
   "Mixed": "3.671277954708785e-05"
 },
 {
   "tweet_id": "1590012653527003137",
   "Positive": "0.9689364433288574",
   "Negative": "0.0002113850205205381",
   "Neutral": "0.03082868456840515",
   "Mixed": "2.3452865207218565e-05"
 },
 {
   "tweet_id": "1590012651014615042",
   "Positive": "0.0039856527000665665",
   "Negative": "0.5803926587104797",
   "Neutral": "0.4154518246650696",
   "Mixed": "0.00016979027714114636"
 },
 {
   "tweet_id": "1590012640621125635",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012637659955200",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012627438092290",
   "Positive": "0.0880860909819603",
   "Negative": "0.09227756410837173",
   "Neutral": "0.8191761374473572",
   "Mixed": "0.0004601114196702838"
 },
 {
   "tweet_id": "1590012622690451457",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622229114880",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622208131075",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622182973442",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622124232708",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622107471873",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622107471872",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622069723136",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012622061305856",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012613400096768",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590012608634986496",
   "Positive": "0.000734085391741246",
   "Negative": "0.0001305324403801933",
   "Neutral": "0.9991324543952942",
   "Mixed": "2.9207933494035387e-06"
 },
 {
   "tweet_id": "1590012601362415616",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590012595570118664",
   "Positive": "0.010519101284444332",
   "Negative": "0.06350433081388474",
   "Neutral": "0.9255855679512024",
   "Mixed": "0.0003910633095074445"
 },
 {
   "tweet_id": "1590012594848698370",
   "Positive": "0.0006057816208340228",
   "Negative": "0.9936968684196472",
   "Neutral": "0.004748125560581684",
   "Mixed": "0.0009491800446994603"
 },
 {
   "tweet_id": "1590012587303112706",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012586493612033",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590012582714572800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012578922926081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012558198853632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012546857439232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012536136830976",
   "Positive": "0.9632375836372375",
   "Negative": "0.00021742070384789258",
   "Neutral": "0.03651873394846916",
   "Mixed": "2.6348016035626642e-05"
 },
 {
   "tweet_id": "1590012534907891713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012520537804801",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012491362631684",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590012481979977734",
   "Positive": "0.010993673466145992",
   "Negative": "0.0001509607391199097",
   "Neutral": "0.9888472557067871",
   "Mixed": "8.10486926638987e-06"
 },
 {
   "tweet_id": "1590012468390400006",
   "Positive": "0.0052163065411150455",
   "Negative": "0.00039872442721389234",
   "Neutral": "0.9943663477897644",
   "Mixed": "1.8647506294655614e-05"
 },
 {
   "tweet_id": "1590012437788790784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012425486864385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012424186638338",
   "Positive": "0.006328835617750883",
   "Negative": "0.0030502500012516975",
   "Neutral": "0.9906160235404968",
   "Mixed": "4.895930032944307e-06"
 },
 {
   "tweet_id": "1590012423834308608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012412828454914",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1590012411117207552",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590012408420265984",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590012397699620864",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012394155147265",
   "Positive": "0.026509461924433708",
   "Negative": "0.0056130471639335155",
   "Neutral": "0.9670897722244263",
   "Mixed": "0.0007877930765971541"
 },
 {
   "tweet_id": "1590012381157261313",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590012380029018112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012379747602433",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012374245068803",
   "Positive": "0.6924695372581482",
   "Negative": "0.001245015300810337",
   "Neutral": "0.3052118420600891",
   "Mixed": "0.0010735881514847279"
 },
 {
   "tweet_id": "1590012360521318401",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012352266588161",
   "Positive": "0.2824869453907013",
   "Negative": "0.0007826596265658736",
   "Neutral": "0.7162796258926392",
   "Mixed": "0.000450778374215588"
 },
 {
   "tweet_id": "1590012340816121856",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012337926201345",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590012328904658947",
   "Positive": "0.5608309507369995",
   "Negative": "0.0012454716488718987",
   "Neutral": "0.437409371137619",
   "Mixed": "0.0005141634028404951"
 },
 {
   "tweet_id": "1590012323292655616",
   "Positive": "0.046444859355688095",
   "Negative": "0.024884074926376343",
   "Neutral": "0.928625762462616",
   "Mixed": "4.524707037489861e-05"
 },
 {
   "tweet_id": "1590012321812074496",
   "Positive": "0.06436149775981903",
   "Negative": "0.006927029229700565",
   "Neutral": "0.9275774359703064",
   "Mixed": "0.0011339965276420116"
 },
 {
   "tweet_id": "1590012319614259201",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1590012318775406594",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318725050372",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318662131712",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318620200961",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318234333185",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318154657792",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318129479680",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318125281282",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318108512257",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012318091726848",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012312349331457",
   "Positive": "0.020455285906791687",
   "Negative": "0.16816478967666626",
   "Neutral": "0.7132518887519836",
   "Mixed": "0.0981280729174614"
 },
 {
   "tweet_id": "1590012310432927744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012306875826177",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1590012302266634241",
   "Positive": "0.1258569061756134",
   "Negative": "0.006559050176292658",
   "Neutral": "0.8675225377082825",
   "Mixed": "6.149667024146765e-05"
 },
 {
   "tweet_id": "1590012285313257478",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012274768777216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012266161704962",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590012255495950336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012253667233792",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590012247623241729",
   "Positive": "0.973886251449585",
   "Negative": "6.18071062490344e-05",
   "Neutral": "0.026030024513602257",
   "Mixed": "2.1938940335530788e-05"
 },
 {
   "tweet_id": "1590012222537076736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012222075711488",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590012183429386240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012171358212096",
   "Positive": "0.48077625036239624",
   "Negative": "0.00015751429600641131",
   "Neutral": "0.5186459422111511",
   "Mixed": "0.0004203033458907157"
 },
 {
   "tweet_id": "1590012167729729538",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1590012159035342850",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012152764854273",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1590012144984395778",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590012142555926529",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1590012100256370688",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590012100017258496",
   "Positive": "0.3042960464954376",
   "Negative": "0.0027990080416202545",
   "Neutral": "0.6928107738494873",
   "Mixed": "9.421460708836094e-05"
 },
 {
   "tweet_id": "1590012099077746688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012095000907777",
   "Positive": "0.0005190834635868669",
   "Negative": "0.22100089490413666",
   "Neutral": "0.777432918548584",
   "Mixed": "0.00104708899743855"
 },
 {
   "tweet_id": "1590012091943260160",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590012084586450947",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012082266996736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012081524596739",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012078408208385",
   "Positive": "0.07811582088470459",
   "Negative": "0.15985442698001862",
   "Neutral": "0.4278508424758911",
   "Mixed": "0.3341788649559021"
 },
 {
   "tweet_id": "1590012077737140224",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590012069851828224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590012067125555206",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012060192366593",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012051321413633",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012050386071553",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012043150884864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590012042010054661",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012036813295617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590012035466932224",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1590012034145751040",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590012031176175616",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590012023450267648",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590012022112280578",
   "Positive": "0.0010685911402106285",
   "Negative": "0.0003375097003299743",
   "Neutral": "0.9985907673835754",
   "Mixed": "3.110848183496273e-06"
 },
 {
   "tweet_id": "1590012015594328066",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1590012006996021248",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590011989115695104",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1590011973118623744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011965510148098",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011961676566528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011961504239617",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011956831965184",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590011945830477825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011937232125953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011920299356160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011919968370688",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590011918328074241",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590011905925840897",
   "Positive": "0.2857809066772461",
   "Negative": "0.0029897599015384912",
   "Neutral": "0.7111919522285461",
   "Mixed": "3.739830935955979e-05"
 },
 {
   "tweet_id": "1590011904520749056",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011903434461188",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590011899361755136",
   "Positive": "0.0049154553562402725",
   "Negative": "0.04301000013947487",
   "Neutral": "0.9520162343978882",
   "Mixed": "5.838024299009703e-05"
 },
 {
   "tweet_id": "1590011865975095297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011861004873728",
   "Positive": "0.11938870698213577",
   "Negative": "0.01428315695375204",
   "Neutral": "0.8660438656806946",
   "Mixed": "0.00028433470288291574"
 },
 {
   "tweet_id": "1590011859637501952",
   "Positive": "0.09804385155439377",
   "Negative": "0.00021982754697091877",
   "Neutral": "0.9012590050697327",
   "Mixed": "0.00047735031694173813"
 },
 {
   "tweet_id": "1590011856386920450",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590011818952765441",
   "Positive": "0.38341858983039856",
   "Negative": "0.011238405480980873",
   "Neutral": "0.6051490902900696",
   "Mixed": "0.00019393987895455211"
 },
 {
   "tweet_id": "1590011814771068928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011813382737921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011813256581121",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590011808764792832",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590011794499993604",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590011774581211136",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590011768637915137",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590011760773595137",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011747280486401",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1590011742918434817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011727101714434",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590011725373648896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011723024850944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011714913075200",
   "Positive": "0.729647159576416",
   "Negative": "0.0008785407990217209",
   "Neutral": "0.2694300413131714",
   "Mixed": "4.430558328749612e-05"
 },
 {
   "tweet_id": "1590011701977841664",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011699578703873",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011686224035840",
   "Positive": "0.04154084995388985",
   "Negative": "0.2739826738834381",
   "Neutral": "0.6838886737823486",
   "Mixed": "0.0005878272349946201"
 },
 {
   "tweet_id": "1590011685070581760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011677998665728",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590011677604732928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011665869058048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590011662576517122",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590011657568518145",
   "Positive": "0.002320882398635149",
   "Negative": "8.940274710766971e-05",
   "Neutral": "0.9975777268409729",
   "Mixed": "1.1980073395534419e-05"
 },
 {
   "tweet_id": "1590011652031754240",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590011649754218500",
   "Positive": "0.004131809808313847",
   "Negative": "0.0015544703928753734",
   "Neutral": "0.9943100214004517",
   "Mixed": "3.6956269013899146e-06"
 },
 {
   "tweet_id": "1590011648760160257",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011646344572928",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590011637641392128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011630557212672",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590011588521910273",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590011572088639488",
   "Positive": "0.38341858983039856",
   "Negative": "0.011238405480980873",
   "Neutral": "0.6051490902900696",
   "Mixed": "0.00019393987895455211"
 },
 {
   "tweet_id": "1590011562789851136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011554199932929",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590011533882724353",
   "Positive": "0.5852948427200317",
   "Negative": "0.0009925924241542816",
   "Neutral": "0.41367143392562866",
   "Mixed": "4.115408955840394e-05"
 },
 {
   "tweet_id": "1590011527767412736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011513317646337",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011504556142592",
   "Positive": "0.002015887526795268",
   "Negative": "0.00014748300600331277",
   "Neutral": "0.9978335499763489",
   "Mixed": "3.063093799937633e-06"
 },
 {
   "tweet_id": "1590011494468530176",
   "Positive": "0.02575048990547657",
   "Negative": "0.2037089765071869",
   "Neutral": "0.7700011134147644",
   "Mixed": "0.0005393363535404205"
 },
 {
   "tweet_id": "1590011470934290433",
   "Positive": "0.0007855843286961317",
   "Negative": "0.00015554243873339146",
   "Neutral": "0.9990553259849548",
   "Mixed": "3.538699957061908e-06"
 },
 {
   "tweet_id": "1590011466018881537",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1590011396510871552",
   "Positive": "0.0006533469422720373",
   "Negative": "0.6777242422103882",
   "Neutral": "0.3212193250656128",
   "Mixed": "0.0004030331620015204"
 },
 {
   "tweet_id": "1590011392094265344",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011378496327680",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590011374448824327",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011373526081537",
   "Positive": "0.02528887800872326",
   "Negative": "0.13223278522491455",
   "Neutral": "0.8376531004905701",
   "Mixed": "0.004825226496905088"
 },
 {
   "tweet_id": "1590011372737552386",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590011361144504320",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011346619625472",
   "Positive": "0.08458489179611206",
   "Negative": "0.0033475274685770273",
   "Neutral": "0.9119687676429749",
   "Mixed": "9.878597484203056e-05"
 },
 {
   "tweet_id": "1590011346351198210",
   "Positive": "0.009003402665257454",
   "Negative": "0.27148112654685974",
   "Neutral": "0.7193811535835266",
   "Mixed": "0.00013429520186036825"
 },
 {
   "tweet_id": "1590011319834804224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011318580281344",
   "Positive": "0.0014042290858924389",
   "Negative": "0.0002883142442442477",
   "Neutral": "0.9983049631118774",
   "Mixed": "2.551303623476997e-06"
 },
 {
   "tweet_id": "1590011272359297024",
   "Positive": "0.5089301466941833",
   "Negative": "0.0033513731323182583",
   "Neutral": "0.4875209331512451",
   "Mixed": "0.0001975912309717387"
 },
 {
   "tweet_id": "1590011262922104843",
   "Positive": "0.07043270021677017",
   "Negative": "0.00028932405984960496",
   "Neutral": "0.9292652010917664",
   "Mixed": "1.273606267204741e-05"
 },
 {
   "tweet_id": "1590011262074867715",
   "Positive": "0.00043849454959854484",
   "Negative": "0.00033834538771770895",
   "Neutral": "0.9992204904556274",
   "Mixed": "2.6120853817701573e-06"
 },
 {
   "tweet_id": "1590011261130981376",
   "Positive": "0.1170077994465828",
   "Negative": "0.00016885233344510198",
   "Neutral": "0.8822001218795776",
   "Mixed": "0.000623173255007714"
 },
 {
   "tweet_id": "1590011253061476353",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1590011250133856258",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011219880316929",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590011216893997059",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590011198107369473",
   "Positive": "0.0002642629260662943",
   "Negative": "0.9822423458099365",
   "Neutral": "0.017443358898162842",
   "Mixed": "5.00411115353927e-05"
 },
 {
   "tweet_id": "1590011193980092416",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011185940008960",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590011185533190146",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590011183930945537",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590011172916527104",
   "Positive": "0.008497420698404312",
   "Negative": "0.00011035922943847254",
   "Neutral": "0.9913759827613831",
   "Mixed": "1.633712963666767e-05"
 },
 {
   "tweet_id": "1590011165585084418",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590011165182394368",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590011144718073856",
   "Positive": "0.48582834005355835",
   "Negative": "0.010798517614603043",
   "Neutral": "0.5032141804695129",
   "Mixed": "0.00015895802062004805"
 },
 {
   "tweet_id": "1590011141790789632",
   "Positive": "0.012092429213225842",
   "Negative": "0.12841935455799103",
   "Neutral": "0.8039699792861938",
   "Mixed": "0.055518265813589096"
 },
 {
   "tweet_id": "1590011131258888192",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590011121226092544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011117710888960",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590011116033560577",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590011108395712513",
   "Positive": "0.17380857467651367",
   "Negative": "0.012509610503911972",
   "Neutral": "0.8136441707611084",
   "Mixed": "3.7623001844622195e-05"
 },
 {
   "tweet_id": "1590011078976897024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011077278191616",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011052540186624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590011044403236865",
   "Positive": "0.0028133527375757694",
   "Negative": "0.00034053370472975075",
   "Neutral": "0.9968372583389282",
   "Mixed": "8.820582479529548e-06"
 },
 {
   "tweet_id": "1590011035674890242",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011030234894338",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011026132848642",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590011021682692096",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590011009515032576",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590010988048175104",
   "Positive": "0.19841723144054413",
   "Negative": "0.004701728466898203",
   "Neutral": "0.796841561794281",
   "Mixed": "3.9447226299671456e-05"
 },
 {
   "tweet_id": "1590010979198603264",
   "Positive": "0.0005595629336312413",
   "Negative": "0.00016400656022597104",
   "Neutral": "0.9992732405662537",
   "Mixed": "3.259439381508855e-06"
 },
 {
   "tweet_id": "1590010976178667520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010973884407808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010936475078656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010934135001089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010922189193216",
   "Positive": "0.011029645800590515",
   "Negative": "0.00012500002048909664",
   "Neutral": "0.9888307452201843",
   "Mixed": "1.4567764992534649e-05"
 },
 {
   "tweet_id": "1590010892229685248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010881077051396",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010880653426688",
   "Positive": "0.00028786552138626575",
   "Negative": "9.919491276377812e-05",
   "Neutral": "0.9995995163917542",
   "Mixed": "1.3476418644131627e-05"
 },
 {
   "tweet_id": "1590010866799620096",
   "Positive": "0.0010624376591295004",
   "Negative": "0.9314539432525635",
   "Neutral": "0.06697998940944672",
   "Mixed": "0.0005036905058659613"
 },
 {
   "tweet_id": "1590010860365176832",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590010836592259073",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590010812516945920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010809610293248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010809534775303",
   "Positive": "0.6596682667732239",
   "Negative": "0.0033728915732353926",
   "Neutral": "0.33674976229667664",
   "Mixed": "0.00020909680461045355"
 },
 {
   "tweet_id": "1590010801070702592",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010777309949954",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010775925452800",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590010766039842816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010755730255873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010736327413760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010733391400961",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010728945418241",
   "Positive": "0.006957519333809614",
   "Negative": "0.024168673902750015",
   "Neutral": "0.968864917755127",
   "Mixed": "8.950375558924861e-06"
 },
 {
   "tweet_id": "1590010723639627776",
   "Positive": "0.8921368718147278",
   "Negative": "0.0010946894763037562",
   "Neutral": "0.10588831454515457",
   "Mixed": "0.0008801145013421774"
 },
 {
   "tweet_id": "1590010723044061185",
   "Positive": "0.03189680725336075",
   "Negative": "8.786149555817246e-05",
   "Neutral": "0.9679856300354004",
   "Mixed": "2.970126661239192e-05"
 },
 {
   "tweet_id": "1590010722951778304",
   "Positive": "0.0005486272857524455",
   "Negative": "6.85235281707719e-05",
   "Neutral": "0.9993600249290466",
   "Mixed": "2.2909229301149026e-05"
 },
 {
   "tweet_id": "1590010720477151232",
   "Positive": "0.02087750844657421",
   "Negative": "0.40316495299339294",
   "Neutral": "0.5753602981567383",
   "Mixed": "0.0005972908693365753"
 },
 {
   "tweet_id": "1590010713619435521",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590010705604141057",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590010696968069122",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590010683927986177",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590010675782639618",
   "Positive": "0.04437876492738724",
   "Negative": "0.00017005759582389146",
   "Neutral": "0.9554280638694763",
   "Mixed": "2.315582605660893e-05"
 },
 {
   "tweet_id": "1590010657197682689",
   "Positive": "0.0032956928480416536",
   "Negative": "0.00020144284644629806",
   "Neutral": "0.9964967370033264",
   "Mixed": "6.212348125700373e-06"
 },
 {
   "tweet_id": "1590010650633601025",
   "Positive": "0.045484598726034164",
   "Negative": "0.007283694576472044",
   "Neutral": "0.947191596031189",
   "Mixed": "4.010707198176533e-05"
 },
 {
   "tweet_id": "1590010646015676419",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010644979666945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010624800878593",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010612859699200",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590010611307802624",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590010576327299072",
   "Positive": "0.004543526563793421",
   "Negative": "4.3979405745631084e-05",
   "Neutral": "0.995359480381012",
   "Mixed": "5.2991545089753345e-05"
 },
 {
   "tweet_id": "1590010572715982849",
   "Positive": "0.5744592547416687",
   "Negative": "0.0013514877064153552",
   "Neutral": "0.42411983013153076",
   "Mixed": "6.944032793398947e-05"
 },
 {
   "tweet_id": "1590010572355035136",
   "Positive": "0.1800120770931244",
   "Negative": "0.0004334762634243816",
   "Neutral": "0.8195338249206543",
   "Mixed": "2.0592222426785156e-05"
 },
 {
   "tweet_id": "1590010567242436608",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590010561148104705",
   "Positive": "0.0005070113111287355",
   "Negative": "0.6431931853294373",
   "Neutral": "0.3560369312763214",
   "Mixed": "0.00026280281599611044"
 },
 {
   "tweet_id": "1590010556584689664",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590010543242641409",
   "Positive": "0.006545520853251219",
   "Negative": "0.1044289842247963",
   "Neutral": "0.888985276222229",
   "Mixed": "4.022392022307031e-05"
 },
 {
   "tweet_id": "1590010541237735424",
   "Positive": "0.005249166861176491",
   "Negative": "4.736906703328714e-05",
   "Neutral": "0.9946550130844116",
   "Mixed": "4.846178489970043e-05"
 },
 {
   "tweet_id": "1590010525085478913",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590010520375275521",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590010515417243648",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1590010514306134017",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590010511743397888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010509990203393",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590010509625290754",
   "Positive": "0.8960756063461304",
   "Negative": "0.001551149063743651",
   "Neutral": "0.10229679197072983",
   "Mixed": "7.653728971490636e-05"
 },
 {
   "tweet_id": "1590010507607826432",
   "Positive": "0.004543526563793421",
   "Negative": "4.3979405745631084e-05",
   "Neutral": "0.995359480381012",
   "Mixed": "5.2991545089753345e-05"
 },
 {
   "tweet_id": "1590010493493981185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010481225629696",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590010472295981059",
   "Positive": "0.005480706691741943",
   "Negative": "5.1703904318856075e-05",
   "Neutral": "0.9944182634353638",
   "Mixed": "4.925863686366938e-05"
 },
 {
   "tweet_id": "1590010469745844224",
   "Positive": "0.10086211562156677",
   "Negative": "0.019409935921430588",
   "Neutral": "0.8787916898727417",
   "Mixed": "0.000936231401283294"
 },
 {
   "tweet_id": "1590010468282040323",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590010465509593088",
   "Positive": "0.056392375379800797",
   "Negative": "0.10873144865036011",
   "Neutral": "0.8346092104911804",
   "Mixed": "0.00026690479717217386"
 },
 {
   "tweet_id": "1590010438515036161",
   "Positive": "0.6870253682136536",
   "Negative": "0.005043690092861652",
   "Neutral": "0.30778396129608154",
   "Mixed": "0.00014693036791868508"
 },
 {
   "tweet_id": "1590010429363064832",
   "Positive": "0.004543526563793421",
   "Negative": "4.3979405745631084e-05",
   "Neutral": "0.995359480381012",
   "Mixed": "5.2991545089753345e-05"
 },
 {
   "tweet_id": "1590010428444528640",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590010427156877313",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590010426083143680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010417900048386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010411457576965",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010384756658177",
   "Positive": "0.090586356818676",
   "Negative": "0.008812537416815758",
   "Neutral": "0.9004811644554138",
   "Mixed": "0.00011991416977252811"
 },
 {
   "tweet_id": "1590010380285157376",
   "Positive": "0.7226148843765259",
   "Negative": "0.00047691192594356835",
   "Neutral": "0.27687591314315796",
   "Mixed": "3.23183776345104e-05"
 },
 {
   "tweet_id": "1590010372773535744",
   "Positive": "0.005249166861176491",
   "Negative": "4.736906703328714e-05",
   "Neutral": "0.9946550130844116",
   "Mixed": "4.846178489970043e-05"
 },
 {
   "tweet_id": "1590010322655772674",
   "Positive": "0.005480706691741943",
   "Negative": "5.1703904318856075e-05",
   "Neutral": "0.9944182634353638",
   "Mixed": "4.925863686366938e-05"
 },
 {
   "tweet_id": "1590010298236559366",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010291479511041",
   "Positive": "0.045484598726034164",
   "Negative": "0.007283694576472044",
   "Neutral": "0.947191596031189",
   "Mixed": "4.010707198176533e-05"
 },
 {
   "tweet_id": "1590010285246787585",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1590010283254480897",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010279437692929",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590010275448901633",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590010274731687936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010271590133761",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590010242544578560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010236429295618",
   "Positive": "0.01270230207592249",
   "Negative": "7.503791130147874e-05",
   "Neutral": "0.9871472120285034",
   "Mixed": "7.542548701167107e-05"
 },
 {
   "tweet_id": "1590010233489076226",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010228019712003",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590010220457394176",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590010217517191170",
   "Positive": "0.005480706691741943",
   "Negative": "5.1703904318856075e-05",
   "Neutral": "0.9944182634353638",
   "Mixed": "4.925863686366938e-05"
 },
 {
   "tweet_id": "1590010191327952896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010188576460800",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010184315072515",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590010161548386304",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590010143382831105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010142313312256",
   "Positive": "0.1856929361820221",
   "Negative": "0.0006867170450277627",
   "Neutral": "0.8134771585464478",
   "Mixed": "0.00014320631453301758"
 },
 {
   "tweet_id": "1590010140614602754",
   "Positive": "0.21687757968902588",
   "Negative": "0.003305984428152442",
   "Neutral": "0.7797534465789795",
   "Mixed": "6.290867895586416e-05"
 },
 {
   "tweet_id": "1590010121119498240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010116308598784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010107483787266",
   "Positive": "0.007528580259531736",
   "Negative": "0.00943329744040966",
   "Neutral": "0.9830302596092224",
   "Mixed": "7.89797559264116e-06"
 },
 {
   "tweet_id": "1590010102073135106",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590010097388105729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010080589930496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590010077930754048",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590010077817470978",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590010075900710912",
   "Positive": "0.5121442675590515",
   "Negative": "0.002633259864524007",
   "Neutral": "0.48514655232429504",
   "Mixed": "7.587878644699231e-05"
 },
 {
   "tweet_id": "1590010067281408001",
   "Positive": "0.0007057923940010369",
   "Negative": "0.9567758440971375",
   "Neutral": "0.04218005761504173",
   "Mixed": "0.0003382573777344078"
 },
 {
   "tweet_id": "1590010056543997952",
   "Positive": "0.12962454557418823",
   "Negative": "0.00029845727840438485",
   "Neutral": "0.8699994087219238",
   "Mixed": "7.75682638050057e-05"
 },
 {
   "tweet_id": "1590010029016424448",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590010025652912128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010021492199424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590010020456173568",
   "Positive": "0.07951374351978302",
   "Negative": "0.02154083363711834",
   "Neutral": "0.8988918662071228",
   "Mixed": "5.3583422413794324e-05"
 },
 {
   "tweet_id": "1590010013422333953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009984158687232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009982107660289",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009979196837898",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009976147546112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009975451316224",
   "Positive": "0.5768183469772339",
   "Negative": "0.0008768857805989683",
   "Neutral": "0.42216721177101135",
   "Mixed": "0.00013758803834207356"
 },
 {
   "tweet_id": "1590009972816953344",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590009970917281794",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009962985840643",
   "Positive": "0.48499050736427307",
   "Negative": "0.005603705532848835",
   "Neutral": "0.5093855261802673",
   "Mixed": "2.0239685909473337e-05"
 },
 {
   "tweet_id": "1590009958569234432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009955968376832",
   "Positive": "0.031740475445985794",
   "Negative": "0.085773766040802",
   "Neutral": "0.8818079233169556",
   "Mixed": "0.0006777772796340287"
 },
 {
   "tweet_id": "1590009953372483584",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590009949786353665",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009946833555456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009945411702784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009945025454080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009944937406465",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009944010797061",
   "Positive": "0.008081869222223759",
   "Negative": "0.0003271348832640797",
   "Neutral": "0.9915878176689148",
   "Mixed": "3.136854729746119e-06"
 },
 {
   "tweet_id": "1590009927858544640",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1590009927841746944",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009913962823680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009897265295371",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009894085615616",
   "Positive": "0.13415874540805817",
   "Negative": "0.03808560222387314",
   "Neutral": "0.6740221977233887",
   "Mixed": "0.15373346209526062"
 },
 {
   "tweet_id": "1590009894056656896",
   "Positive": "0.7454767823219299",
   "Negative": "0.00040737006929703057",
   "Neutral": "0.2540540397167206",
   "Mixed": "6.17756086285226e-05"
 },
 {
   "tweet_id": "1590009828545818624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009825232310272",
   "Positive": "0.4041770398616791",
   "Negative": "0.0004188446037005633",
   "Neutral": "0.5952029228210449",
   "Mixed": "0.0002011936594499275"
 },
 {
   "tweet_id": "1590009812737486848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009791841071104",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1590009789064818690",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009764293259264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009755896250369",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1590009744009596930",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009735348383744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009733347278848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009732835971072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009712699150338",
   "Positive": "0.002535790903493762",
   "Negative": "0.5201433300971985",
   "Neutral": "0.47351911664009094",
   "Mixed": "0.0038017244078218937"
 },
 {
   "tweet_id": "1590009691798900736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009664431067138",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009652389228545",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590009640276066304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009632503627776",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590009630507532289",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009624811692033",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009615668109315",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009608873332736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009604691615746",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009597338693632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009595715469313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009590040899585",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590009583871098883",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009578540109826",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590009578242342912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009562677248000",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590009559682191360",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1590009513838800896",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590009469471424514",
   "Positive": "0.5262985229492188",
   "Negative": "0.0017099141841754317",
   "Neutral": "0.471951425075531",
   "Mixed": "4.0143127989722416e-05"
 },
 {
   "tweet_id": "1590009467910832128",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590009465834995713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009442158129153",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590009437678620672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009436692971521",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009430137094144",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590009413599121408",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590009407924207616",
   "Positive": "0.2673853933811188",
   "Negative": "0.08443351089954376",
   "Neutral": "0.6441674828529358",
   "Mixed": "0.004013614263385534"
 },
 {
   "tweet_id": "1590009387678322688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009382993264643",
   "Positive": "0.043178580701351166",
   "Negative": "0.023614894598722458",
   "Neutral": "0.9331925511360168",
   "Mixed": "1.3855410543328617e-05"
 },
 {
   "tweet_id": "1590009371635122176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009349862494208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009341788446726",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590009316802973696",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009303288909824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009298146693122",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590009292509224960",
   "Positive": "0.32758817076683044",
   "Negative": "0.017991211265325546",
   "Neutral": "0.6435813903808594",
   "Mixed": "0.010839234106242657"
 },
 {
   "tweet_id": "1590009279716937730",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009275036086272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009271558627328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009262172147713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009262075678720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009259299074048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009254190411776",
   "Positive": "0.0031410453375428915",
   "Negative": "0.0104844244197011",
   "Neutral": "0.9863460659980774",
   "Mixed": "2.8455946448957548e-05"
 },
 {
   "tweet_id": "1590009247496273920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009235878076416",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590009233742782465",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590009227082620933",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590009205347749889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009188599885824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009163417260034",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1590009153187373057",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590009151559979008",
   "Positive": "0.09083928912878036",
   "Negative": "0.0007725190371274948",
   "Neutral": "0.9083772897720337",
   "Mixed": "1.0978746104228776e-05"
 },
 {
   "tweet_id": "1590009151165722624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009144043790336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009141460094976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009136632459264",
   "Positive": "0.057319775223731995",
   "Negative": "0.08048516511917114",
   "Neutral": "0.8620384931564331",
   "Mixed": "0.00015646983229089528"
 },
 {
   "tweet_id": "1590009131083399168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009121134497792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590009097331814400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590009088368611330",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1590009085747134464",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590009079799631874",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009059021062145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590009033359925249",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590009006776807425",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590009003421016064",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1590008994852401152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008987520749569",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590008983162880002",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008981283799040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008972559659008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008969149702144",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008966771142657",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590008944311033856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008916125298688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008914250452992",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590008901050585089",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590008897888485376",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590008897477439488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008895858446338",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008884269580289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008882147258372",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008873452437504",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1590008870327717891",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590008866120814594",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590008864631848960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008862132039680",
   "Positive": "0.009231260046362877",
   "Negative": "0.4425959289073944",
   "Neutral": "0.5480985045433044",
   "Mixed": "7.426054071402177e-05"
 },
 {
   "tweet_id": "1590008861842624513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008849964363776",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590008840162250753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008835858903040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008831995543552",
   "Positive": "0.11180159449577332",
   "Negative": "0.0002321068459423259",
   "Neutral": "0.8873723745346069",
   "Mixed": "0.0005939124966971576"
 },
 {
   "tweet_id": "1590008826572722178",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1590008818880385024",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590008813306130432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008802421936128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008794658246656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008787444043776",
   "Positive": "0.9371585249900818",
   "Negative": "0.0002632756077218801",
   "Neutral": "0.06240115687251091",
   "Mixed": "0.00017697532894089818"
 },
 {
   "tweet_id": "1590008785959288833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008784847765504",
   "Positive": "0.2112332284450531",
   "Negative": "0.00018970071687363088",
   "Neutral": "0.7883859872817993",
   "Mixed": "0.0001911861909320578"
 },
 {
   "tweet_id": "1590008778933796864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008778606673922",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590008774982766593",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008767844089857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008763918217217",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1590008761430978560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008744427294720",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590008743965921280",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590008743219302400",
   "Positive": "0.028730949386954308",
   "Negative": "0.018427401781082153",
   "Neutral": "0.9528335928916931",
   "Mixed": "8.013899787329137e-06"
 },
 {
   "tweet_id": "1590008734012645378",
   "Positive": "0.0007344246259890497",
   "Negative": "7.723394082859159e-05",
   "Neutral": "0.9991833567619324",
   "Mixed": "5.038870313001098e-06"
 },
 {
   "tweet_id": "1590008724261068801",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008711019663362",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590008701460807681",
   "Positive": "0.008960999548435211",
   "Negative": "0.0014998560072854161",
   "Neutral": "0.9895312786102295",
   "Mixed": "7.851592272345442e-06"
 },
 {
   "tweet_id": "1590008685643735040",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590008680711606273",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008662491566081",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590008649413316608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008637921329153",
   "Positive": "0.07732705026865005",
   "Negative": "0.010843547061085701",
   "Neutral": "0.9118090867996216",
   "Mixed": "2.0338398826424964e-05"
 },
 {
   "tweet_id": "1590008616987553793",
   "Positive": "0.23748064041137695",
   "Negative": "0.010610477067530155",
   "Neutral": "0.7515188455581665",
   "Mixed": "0.0003901076561305672"
 },
 {
   "tweet_id": "1590008603649667074",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008599677665281",
   "Positive": "0.2553219199180603",
   "Negative": "0.0017880614614114165",
   "Neutral": "0.7427158355712891",
   "Mixed": "0.00017418144852854311"
 },
 {
   "tweet_id": "1590008587317022721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008586113253376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008581705039872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008572355964933",
   "Positive": "0.6487762928009033",
   "Negative": "0.0006453756941482425",
   "Neutral": "0.35054194927215576",
   "Mixed": "3.633481901488267e-05"
 },
 {
   "tweet_id": "1590008560930664448",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590008553930358786",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008540168867840",
   "Positive": "0.8112040758132935",
   "Negative": "0.001892151078209281",
   "Neutral": "0.18666312098503113",
   "Mixed": "0.00024061440490186214"
 },
 {
   "tweet_id": "1590008540089180160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008532417409024",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590008532120014849",
   "Positive": "0.027430899441242218",
   "Negative": "0.263599693775177",
   "Neutral": "0.7083450555801392",
   "Mixed": "0.0006243210518732667"
 },
 {
   "tweet_id": "1590008514734624771",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1590008514680061953",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008501442465793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008496011227137",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008474746097664",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590008462939160579",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008460300939266",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008451752951809",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590008449177649153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008443582427137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008440478654466",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590008431993573377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008430953377792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008426997764097",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590008420052398083",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1590008408379645954",
   "Positive": "0.031464073807001114",
   "Negative": "0.00017046670836862177",
   "Neutral": "0.9680895209312439",
   "Mixed": "0.00027595245046541095"
 },
 {
   "tweet_id": "1590008394928164864",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590008392185438208",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1590008391002640384",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008387752038403",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008381800321030",
   "Positive": "0.8665363192558289",
   "Negative": "0.0004424131475389004",
   "Neutral": "0.13293996453285217",
   "Mixed": "8.131865615723655e-05"
 },
 {
   "tweet_id": "1590008380462370816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008369418768384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008369099988992",
   "Positive": "0.3880705237388611",
   "Negative": "0.0032272946555167437",
   "Neutral": "0.6085397601127625",
   "Mixed": "0.0001624376600375399"
 },
 {
   "tweet_id": "1590008361965481984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008361025929222",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590008359939604480",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590008359172083714",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008359117541378",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008359108763648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008354633809920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008352096284672",
   "Positive": "0.16408762335777283",
   "Negative": "0.002012318931519985",
   "Neutral": "0.8337904214859009",
   "Mixed": "0.00010964023385895416"
 },
 {
   "tweet_id": "1590008349244149760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008342058975233",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590008335658496000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008325793775616",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590008321909567488",
   "Positive": "0.04295258969068527",
   "Negative": "0.0007777870050631464",
   "Neutral": "0.9562597870826721",
   "Mixed": "9.897014024318196e-06"
 },
 {
   "tweet_id": "1590008319334576130",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008318340509698",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008305870860289",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1590008301173231618",
   "Positive": "0.8729339241981506",
   "Negative": "0.00030963277094997466",
   "Neutral": "0.12664422392845154",
   "Mixed": "0.00011228785297134891"
 },
 {
   "tweet_id": "1590008298824400896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008278552977409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008276254879744",
   "Positive": "0.3436771333217621",
   "Negative": "0.0064648897387087345",
   "Neutral": "0.6497897505760193",
   "Mixed": "6.813128129579127e-05"
 },
 {
   "tweet_id": "1590008264594714628",
   "Positive": "0.006375722121447325",
   "Negative": "0.6066546440124512",
   "Neutral": "0.3865068852901459",
   "Mixed": "0.0004627562593668699"
 },
 {
   "tweet_id": "1590008263529369602",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008253169078274",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008246059745280",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1590008243740635137",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590008238430629890",
   "Positive": "0.8335438966751099",
   "Negative": "0.0003549796820152551",
   "Neutral": "0.1660262942314148",
   "Mixed": "7.485674723284319e-05"
 },
 {
   "tweet_id": "1590008232680263683",
   "Positive": "0.17416144907474518",
   "Negative": "0.0044876630418002605",
   "Neutral": "0.8213319182395935",
   "Mixed": "1.8855729649658315e-05"
 },
 {
   "tweet_id": "1590008230834753536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008227890360320",
   "Positive": "0.06822279840707779",
   "Negative": "0.00688142329454422",
   "Neutral": "0.9248754978179932",
   "Mixed": "2.0259718439774588e-05"
 },
 {
   "tweet_id": "1590008220785217539",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008217274580992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008143706488832",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008135955410944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008134088544256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008124248756225",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590008124035203072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590008118775549957",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590008117777272832",
   "Positive": "0.09832540899515152",
   "Negative": "0.011738116852939129",
   "Neutral": "0.8897445797920227",
   "Mixed": "0.00019184475240763277"
 },
 {
   "tweet_id": "1590008082830356481",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590008047921172480",
   "Positive": "0.0054105836898088455",
   "Negative": "0.00044969585724174976",
   "Neutral": "0.9941370487213135",
   "Mixed": "2.6532320589467417e-06"
 },
 {
   "tweet_id": "1590008045123559426",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590008043613618176",
   "Positive": "0.8700053095817566",
   "Negative": "0.0009847283363342285",
   "Neutral": "0.12888824939727783",
   "Mixed": "0.00012169452384114265"
 },
 {
   "tweet_id": "1590008016530997248",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1590008001418924032",
   "Positive": "0.021932655945420265",
   "Negative": "0.008774958550930023",
   "Neutral": "0.9675833582878113",
   "Mixed": "0.0017090640030801296"
 },
 {
   "tweet_id": "1590007998767849472",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590007998239617024",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007975154188290",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007973845569536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007969932267522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007959022895104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007950281953280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007947438219264",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007945357824000",
   "Positive": "0.003347482532262802",
   "Negative": "0.0001260248973267153",
   "Neutral": "0.9965152740478516",
   "Mixed": "1.1128231562906876e-05"
 },
 {
   "tweet_id": "1590007943835287552",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590007942014988288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007935308267521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007918782730242",
   "Positive": "0.13847342133522034",
   "Negative": "0.0003353622159920633",
   "Neutral": "0.8608081340789795",
   "Mixed": "0.00038303056498989463"
 },
 {
   "tweet_id": "1590007904580833281",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590007888948625408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007884221681666",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590007882992717824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007878806818816",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590007870082678785",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590007863145271296",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590007859756302336",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590007848796577792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007846627790848",
   "Positive": "0.006375722121447325",
   "Negative": "0.6066546440124512",
   "Neutral": "0.3865068852901459",
   "Mixed": "0.0004627562593668699"
 },
 {
   "tweet_id": "1590007831670898688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007827401420800",
   "Positive": "0.05311359465122223",
   "Negative": "0.018215464428067207",
   "Neutral": "0.9286409616470337",
   "Mixed": "3.0023225917830132e-05"
 },
 {
   "tweet_id": "1590007823932731395",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007800729833472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007788784455680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007787211591682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007786662162435",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007777954758656",
   "Positive": "0.001462198211811483",
   "Negative": "0.9585747122764587",
   "Neutral": "0.03950365632772446",
   "Mixed": "0.0004594289348460734"
 },
 {
   "tweet_id": "1590007770316611584",
   "Positive": "0.39191192388534546",
   "Negative": "0.04135982692241669",
   "Neutral": "0.5664921402931213",
   "Mixed": "0.00023618226987309754"
 },
 {
   "tweet_id": "1590007768131387393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007759378198528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007737966104578",
   "Positive": "0.4627796411514282",
   "Negative": "0.00018338665540795773",
   "Neutral": "0.5369332432746887",
   "Mixed": "0.0001037865731632337"
 },
 {
   "tweet_id": "1590007735382253569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007731460931584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007729422495744",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590007692281905152",
   "Positive": "0.5710931420326233",
   "Negative": "0.0021768000442534685",
   "Neutral": "0.42667654156684875",
   "Mixed": "5.35169274371583e-05"
 },
 {
   "tweet_id": "1590007692080603138",
   "Positive": "0.0005167626077309251",
   "Negative": "0.00020154127560090274",
   "Neutral": "0.999278724193573",
   "Mixed": "2.9542238735302817e-06"
 },
 {
   "tweet_id": "1590007669896916992",
   "Positive": "0.914613664150238",
   "Negative": "0.0002481513365637511",
   "Neutral": "0.08505292981863022",
   "Mixed": "8.528144826414064e-05"
 },
 {
   "tweet_id": "1590007665509683201",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590007653014851589",
   "Positive": "0.005390400066971779",
   "Negative": "5.850222078152001e-05",
   "Neutral": "0.9944922924041748",
   "Mixed": "5.880135722691193e-05"
 },
 {
   "tweet_id": "1590007646454628352",
   "Positive": "0.0007042199140414596",
   "Negative": "0.00010404500062577426",
   "Neutral": "0.9991874098777771",
   "Mixed": "4.290552624297561e-06"
 },
 {
   "tweet_id": "1590007623499231234",
   "Positive": "0.0900115817785263",
   "Negative": "0.09052931517362595",
   "Neutral": "0.7839857935905457",
   "Mixed": "0.03547336533665657"
 },
 {
   "tweet_id": "1590007613114048512",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1590007606785212416",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1590007577798389760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007577076994048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007573323075587",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590007559464722433",
   "Positive": "0.10383576154708862",
   "Negative": "0.05904819443821907",
   "Neutral": "0.7915908694267273",
   "Mixed": "0.04552515596151352"
 },
 {
   "tweet_id": "1590007554792624129",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007553626632194",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007545070243840",
   "Positive": "0.001389739685691893",
   "Negative": "9.99624899122864e-05",
   "Neutral": "0.998506486415863",
   "Mixed": "3.842467322101584e-06"
 },
 {
   "tweet_id": "1590007544940228613",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590007544499834881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007542432038913",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007541886746629",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007536551444482",
   "Positive": "0.03551320359110832",
   "Negative": "0.0003487234062049538",
   "Neutral": "0.9641284942626953",
   "Mixed": "9.707032404548954e-06"
 },
 {
   "tweet_id": "1590007526455508992",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590007524488806402",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007523566030848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007505295269888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007497070608386",
   "Positive": "0.01103206817060709",
   "Negative": "0.00010768820357043296",
   "Neutral": "0.9886823892593384",
   "Mixed": "0.00017780860071070492"
 },
 {
   "tweet_id": "1590007495522938880",
   "Positive": "0.002666512504220009",
   "Negative": "0.00046059652231633663",
   "Neutral": "0.9968683123588562",
   "Mixed": "4.4735661504091695e-06"
 },
 {
   "tweet_id": "1590007490418073605",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007489621536769",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1590007486282694657",
   "Positive": "0.4627796411514282",
   "Negative": "0.00018338665540795773",
   "Neutral": "0.5369332432746887",
   "Mixed": "0.0001037865731632337"
 },
 {
   "tweet_id": "1590007478850572288",
   "Positive": "0.0028989799320697784",
   "Negative": "0.37021464109420776",
   "Neutral": "0.6268629431724548",
   "Mixed": "2.3359392798738554e-05"
 },
 {
   "tweet_id": "1590007469853806593",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007466108293120",
   "Positive": "0.028425978496670723",
   "Negative": "0.00017660067533142865",
   "Neutral": "0.9713848829269409",
   "Mixed": "1.2491438610595651e-05"
 },
 {
   "tweet_id": "1590007464195657730",
   "Positive": "0.00495251314714551",
   "Negative": "0.17235057055950165",
   "Neutral": "0.8205606341362",
   "Mixed": "0.0021362388506531715"
 },
 {
   "tweet_id": "1590007450450939906",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007438908194817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007429433274368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007422013558784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007412467335168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007411896877057",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007404275826688",
   "Positive": "0.00044622342102229595",
   "Negative": "6.04458327870816e-05",
   "Neutral": "0.9994845390319824",
   "Mixed": "8.812914529698901e-06"
 },
 {
   "tweet_id": "1590007396658663424",
   "Positive": "0.0025873498525470495",
   "Negative": "0.8424155116081238",
   "Neutral": "0.14629094302654266",
   "Mixed": "0.008706203661859035"
 },
 {
   "tweet_id": "1590007393878171648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007389704814597",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007376089710592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007368783663105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007366816518146",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007353768050690",
   "Positive": "0.1936822235584259",
   "Negative": "0.10687815397977829",
   "Neutral": "0.6987762451171875",
   "Mixed": "0.0006633782177232206"
 },
 {
   "tweet_id": "1590007352782385152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007350173503489",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590007331819245568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007326123384834",
   "Positive": "0.7235326170921326",
   "Negative": "0.037740375846624374",
   "Neutral": "0.23662830889225006",
   "Mixed": "0.0020987566094845533"
 },
 {
   "tweet_id": "1590007325414367234",
   "Positive": "0.03378399834036827",
   "Negative": "0.00022112374426797032",
   "Neutral": "0.9659832119941711",
   "Mixed": "1.1620580153248738e-05"
 },
 {
   "tweet_id": "1590007322742763520",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590007312466710529",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007307404201984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007299657330688",
   "Positive": "0.0025873498525470495",
   "Negative": "0.8424155116081238",
   "Neutral": "0.14629094302654266",
   "Mixed": "0.008706203661859035"
 },
 {
   "tweet_id": "1590007281365954560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007272335282176",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590007248410943488",
   "Positive": "0.0024501171428710222",
   "Negative": "0.00011964392615482211",
   "Neutral": "0.9974247217178345",
   "Mixed": "5.641549705615034e-06"
 },
 {
   "tweet_id": "1590007225275535362",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590007211077820416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007196007665664",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007190060163073",
   "Positive": "0.8448566794395447",
   "Negative": "0.02397347241640091",
   "Neutral": "0.12833641469478607",
   "Mixed": "0.002833523089066148"
 },
 {
   "tweet_id": "1590007183533821954",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007176755818496",
   "Positive": "0.08072313666343689",
   "Negative": "0.037369824945926666",
   "Neutral": "0.8808602094650269",
   "Mixed": "0.0010467867832630873"
 },
 {
   "tweet_id": "1590007169046704128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007168551780352",
   "Positive": "0.00643532257527113",
   "Negative": "0.0001894025772344321",
   "Neutral": "0.9933716058731079",
   "Mixed": "3.7131173939997097e-06"
 },
 {
   "tweet_id": "1590007152831520769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007146951102464",
   "Positive": "0.9666414260864258",
   "Negative": "0.00018131440447177738",
   "Neutral": "0.0331357941031456",
   "Mixed": "4.1555220377631485e-05"
 },
 {
   "tweet_id": "1590007141326151680",
   "Positive": "0.004463434685021639",
   "Negative": "0.7787702679634094",
   "Neutral": "0.20877033472061157",
   "Mixed": "0.007996032014489174"
 },
 {
   "tweet_id": "1590007137731649536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007130207449088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590007129846398976",
   "Positive": "0.0437481589615345",
   "Negative": "0.017689019441604614",
   "Neutral": "0.9384832382202148",
   "Mixed": "7.953781459946185e-05"
 },
 {
   "tweet_id": "1590007105909837827",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007097949052928",
   "Positive": "0.058302294462919235",
   "Negative": "0.6805342435836792",
   "Neutral": "0.2355956882238388",
   "Mixed": "0.025567781180143356"
 },
 {
   "tweet_id": "1590007090881646595",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007084833460227",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590007079309570050",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007075085570048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007074934726658",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590007055532064768",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590007046719655938",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007041791172608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590007040491278338",
   "Positive": "0.34569719433784485",
   "Negative": "0.0002501849958207458",
   "Neutral": "0.6540192365646362",
   "Mixed": "3.337772795930505e-05"
 },
 {
   "tweet_id": "1590007037773377536",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590007021268795393",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007020429533184",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590007019389345792",
   "Positive": "0.252666711807251",
   "Negative": "0.00011099395487690344",
   "Neutral": "0.747093141078949",
   "Mixed": "0.00012923686881549656"
 },
 {
   "tweet_id": "1590007007884775424",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590007003182956544",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590007000922193920",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590007000498581504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590007000431464450",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590006999412264961",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006996992163841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006992890138624",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590006988108599296",
   "Positive": "0.005205409601330757",
   "Negative": "0.0008604564936831594",
   "Neutral": "0.9939290285110474",
   "Mixed": "5.148796390130883e-06"
 },
 {
   "tweet_id": "1590006985486802944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006985352949760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006978914709505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006977492557829",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006962678550528",
   "Positive": "0.04730049520730972",
   "Negative": "0.11956756561994553",
   "Neutral": "0.8248149156570435",
   "Mixed": "0.008316999301314354"
 },
 {
   "tweet_id": "1590006952872251393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006931070259204",
   "Positive": "0.003452223725616932",
   "Negative": "0.6956947445869446",
   "Neutral": "0.3007199466228485",
   "Mixed": "0.00013306752953212708"
 },
 {
   "tweet_id": "1590006917656870913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006908827897859",
   "Positive": "0.009776896797120571",
   "Negative": "0.00015691376756876707",
   "Neutral": "0.9900610446929932",
   "Mixed": "5.181238975637825e-06"
 },
 {
   "tweet_id": "1590006899898191873",
   "Positive": "0.039477959275245667",
   "Negative": "0.5690180063247681",
   "Neutral": "0.36971592903137207",
   "Mixed": "0.021788103505969048"
 },
 {
   "tweet_id": "1590006887071612928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006886669381632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006884261847045",
   "Positive": "0.01936488226056099",
   "Negative": "0.5046223402023315",
   "Neutral": "0.47558829188346863",
   "Mixed": "0.0004244212177582085"
 },
 {
   "tweet_id": "1590006878138163200",
   "Positive": "0.08781983703374863",
   "Negative": "0.042000945657491684",
   "Neutral": "0.8698831796646118",
   "Mixed": "0.0002960620622616261"
 },
 {
   "tweet_id": "1590006872366796801",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006871033012224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006870148018177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006862766014470",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590006860262039557",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006850325737472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006840313917440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006836002172929",
   "Positive": "0.0012344263959676027",
   "Negative": "0.8037735223770142",
   "Neutral": "0.19462378323078156",
   "Mixed": "0.00036828534211963415"
 },
 {
   "tweet_id": "1590006832193425408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006820961398785",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006818138619910",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590006817954107392",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590006796349222912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006787406954497",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006777503809536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006770646204418",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590006768041857024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006767857315842",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006755727376384",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006754871369728",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590006753881907204",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006743727472640",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590006739256377346",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590006732927168512",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590006728967745538",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006719987736577",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590006712370532352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006700991733760",
   "Positive": "0.9066516160964966",
   "Negative": "0.0003165947855450213",
   "Neutral": "0.09296021610498428",
   "Mixed": "7.162572728702798e-05"
 },
 {
   "tweet_id": "1590006694339178496",
   "Positive": "0.8213857412338257",
   "Negative": "0.007320260629057884",
   "Neutral": "0.17120563983917236",
   "Mixed": "8.837758650770411e-05"
 },
 {
   "tweet_id": "1590006693794308097",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590006688031330306",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006665432428545",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006659635892224",
   "Positive": "0.1236535906791687",
   "Negative": "0.09613826870918274",
   "Neutral": "0.7762438654899597",
   "Mixed": "0.003964340314269066"
 },
 {
   "tweet_id": "1590006633677344769",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006631290765313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006626374758401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006608238882818",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006600244559873",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006597560172545",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006595010064384",
   "Positive": "0.0025873498525470495",
   "Negative": "0.8424155116081238",
   "Neutral": "0.14629094302654266",
   "Mixed": "0.008706203661859035"
 },
 {
   "tweet_id": "1590006591818182656",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590006587279679488",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590006583886761984",
   "Positive": "0.9875643849372864",
   "Negative": "4.278023698134348e-05",
   "Neutral": "0.012365633621811867",
   "Mixed": "2.7255706299911253e-05"
 },
 {
   "tweet_id": "1590006573619105792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006569261223939",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006566228746241",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590006553838780422",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006532019990528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006522733817856",
   "Positive": "0.0011033073533326387",
   "Negative": "0.001108297030441463",
   "Neutral": "0.9977824091911316",
   "Mixed": "5.927954589424189e-06"
 },
 {
   "tweet_id": "1590006503309643776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006491230375936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006485651968000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006478194507777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006473073233921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006469440995329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006467226390532",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006459257229315",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590006451216723971",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006403342938113",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590006378902728705",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590006369666887680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006368739942400",
   "Positive": "0.010621525347232819",
   "Negative": "0.3112477660179138",
   "Neutral": "0.08699467778205872",
   "Mixed": "0.5911360383033752"
 },
 {
   "tweet_id": "1590006362742087680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006353221029888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006345415405568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006334799634432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006316528910338",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006307511468032",
   "Positive": "0.02662370540201664",
   "Negative": "0.0021491677034646273",
   "Neutral": "0.9711414575576782",
   "Mixed": "8.565169264329597e-05"
 },
 {
   "tweet_id": "1590006298242080769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006293393453057",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006280952815616",
   "Positive": "0.01920785941183567",
   "Negative": "6.80415250826627e-05",
   "Neutral": "0.9806206822395325",
   "Mixed": "0.00010341427696403116"
 },
 {
   "tweet_id": "1590006277362831362",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006262242357249",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006252717101056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006249827217408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006232831889408",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590006231481331713",
   "Positive": "0.01665637455880642",
   "Negative": "0.08950991928577423",
   "Neutral": "0.893734872341156",
   "Mixed": "9.890543879009783e-05"
 },
 {
   "tweet_id": "1590006228167856128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006227752263680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006224040652802",
   "Positive": "0.06593435257673264",
   "Negative": "0.0023748627863824368",
   "Neutral": "0.9316604137420654",
   "Mixed": "3.0337232601596043e-05"
 },
 {
   "tweet_id": "1590006220408389632",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590006220068651010",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006216130203649",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006209343803395",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006183687229440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006176145547264",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006164594778113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006164116639747",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590006151311429635",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006117635362816",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006109691031553",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006097422987265",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006091429326848",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006088375881730",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006078770909184",
   "Positive": "0.055951159447431564",
   "Negative": "0.015708008781075478",
   "Neutral": "0.9019163846969604",
   "Mixed": "0.02642439678311348"
 },
 {
   "tweet_id": "1590006063608532992",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006060345360385",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006058889932800",
   "Positive": "0.04801841080188751",
   "Negative": "0.001343712443485856",
   "Neutral": "0.949614942073822",
   "Mixed": "0.0010228636674582958"
 },
 {
   "tweet_id": "1590006056323022853",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590006056260100096",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006055157006339",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006054347509760",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006052325842944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006043228385280",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590006031295614977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006026308579328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590006025150926850",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590006021891575808",
   "Positive": "0.0005402689566835761",
   "Negative": "6.193652370711789e-05",
   "Neutral": "0.9993880987167358",
   "Mixed": "9.700503142084926e-06"
 },
 {
   "tweet_id": "1590006015449497602",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590006015105601536",
   "Positive": "0.6053591370582581",
   "Negative": "0.0011691899271681905",
   "Neutral": "0.39345496892929077",
   "Mixed": "1.6691425116732717e-05"
 },
 {
   "tweet_id": "1590005998655508480",
   "Positive": "0.10383576154708862",
   "Negative": "0.05904819443821907",
   "Neutral": "0.7915908694267273",
   "Mixed": "0.04552515596151352"
 },
 {
   "tweet_id": "1590005993781735424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005993576235009",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005989386121216",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590005985698988042",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005981278527491",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005978355101697",
   "Positive": "0.0001841819757828489",
   "Negative": "0.9784370064735413",
   "Neutral": "0.02136414870619774",
   "Mixed": "1.4701032341690734e-05"
 },
 {
   "tweet_id": "1590005970612416512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005961431085056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005958365048834",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005956150456320",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005955328368643",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590005947057209355",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005926660280321",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1590005922629570560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005903193157632",
   "Positive": "0.23547610640525818",
   "Negative": "0.0002831059682648629",
   "Neutral": "0.7642094492912292",
   "Mixed": "3.131431367364712e-05"
 },
 {
   "tweet_id": "1590005900420710401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005900328464385",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590005896427765761",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590005893638533120",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590005868497883136",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590005865993863168",
   "Positive": "0.02990010939538479",
   "Negative": "0.06664624810218811",
   "Neutral": "0.060913532972335815",
   "Mixed": "0.8425401449203491"
 },
 {
   "tweet_id": "1590005858653835264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005853528412161",
   "Positive": "0.557358980178833",
   "Negative": "0.032829586416482925",
   "Neutral": "0.40951523184776306",
   "Mixed": "0.00029619858833029866"
 },
 {
   "tweet_id": "1590005847941599232",
   "Positive": "0.03551320359110832",
   "Negative": "0.0003487234062049538",
   "Neutral": "0.9641284942626953",
   "Mixed": "9.707032404548954e-06"
 },
 {
   "tweet_id": "1590005828329046017",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590005825556578306",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005822830317569",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005820695392257",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590005755557859328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005750558257153",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005747433103360",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1590005733625679872",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590005722296762369",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590005721823084545",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590005714025693185",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590005711643508736",
   "Positive": "0.00237412191927433",
   "Negative": "0.4714389741420746",
   "Neutral": "0.5261296033859253",
   "Mixed": "5.7249275414505973e-05"
 },
 {
   "tweet_id": "1590005709768626183",
   "Positive": "0.032313473522663116",
   "Negative": "0.007639098912477493",
   "Neutral": "0.9599590301513672",
   "Mixed": "8.832843013806269e-05"
 },
 {
   "tweet_id": "1590005699349975041",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1590005682279190532",
   "Positive": "0.00024747292627580464",
   "Negative": "0.0001794565177988261",
   "Neutral": "0.9995617270469666",
   "Mixed": "1.1289693247817922e-05"
 },
 {
   "tweet_id": "1590005681993953283",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590005677753532417",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005672875556865",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005664528883712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005662452690945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005657155276800",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590005651216150530",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005648645066754",
   "Positive": "0.0680614784359932",
   "Negative": "0.00034409924410283566",
   "Neutral": "0.931581974029541",
   "Mixed": "1.2476046322262846e-05"
 },
 {
   "tweet_id": "1590005644677251072",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005641346969600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005641187569664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005639891542016",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590005639514050561",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590005632283082752",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590005628545552384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005628239740929",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005622015426560",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590005617204551680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005604428677121",
   "Positive": "0.09301795065402985",
   "Negative": "0.5538985133171082",
   "Neutral": "0.35290637612342834",
   "Mixed": "0.00017713139823172241"
 },
 {
   "tweet_id": "1590005592382664704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005589631209472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005588456800256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005578390470656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005574917189632",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1590005567975657473",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005567422353408",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590005565434253312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005556898848771",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005555925745665",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005554847813633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005551232352256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005550259265537",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1590005544060071936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005525470924800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005510019117056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005493514129408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005487118225408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005486581329920",
   "Positive": "0.0003348476311657578",
   "Negative": "0.0011206191265955567",
   "Neutral": "0.9985418319702148",
   "Mixed": "2.7063617835665355e-06"
 },
 {
   "tweet_id": "1590005481866924033",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590005479887052802",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590005474212343809",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005472714973185",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590005466905473024",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005465769201664",
   "Positive": "0.2555960714817047",
   "Negative": "0.06522262096405029",
   "Neutral": "0.6609203219413757",
   "Mixed": "0.018260980024933815"
 },
 {
   "tweet_id": "1590005452347441153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005448362500096",
   "Positive": "0.7947226166725159",
   "Negative": "0.001064647571183741",
   "Neutral": "0.20418410003185272",
   "Mixed": "2.859503547369968e-05"
 },
 {
   "tweet_id": "1590005448295714816",
   "Positive": "0.023425037041306496",
   "Negative": "0.0019419435411691666",
   "Neutral": "0.9746280312538147",
   "Mixed": "5.049568244430702e-06"
 },
 {
   "tweet_id": "1590005429517848576",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590005425424007168",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1590005424136531970",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005420760141824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005406470135808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005401948663808",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005383728623616",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005382117986304",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005376333754368",
   "Positive": "0.0009298232616856694",
   "Negative": "0.8247898817062378",
   "Neutral": "0.17411191761493683",
   "Mixed": "0.00016838376177474856"
 },
 {
   "tweet_id": "1590005373653889026",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005368864002048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005365374332930",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005356167499776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005345916977153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005345807892484",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005334579773441",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005313633423361",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005304103927808",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590005284667523072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005280641011713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005271744897025",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005271354814465",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005268255236098",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005264367112192",
   "Positive": "0.0008413420291617513",
   "Negative": "8.994530799100175e-05",
   "Neutral": "0.9990622401237488",
   "Mixed": "6.450506589317229e-06"
 },
 {
   "tweet_id": "1590005258155364357",
   "Positive": "0.006888563744723797",
   "Negative": "0.2179369330406189",
   "Neutral": "0.7720448970794678",
   "Mixed": "0.003129656193777919"
 },
 {
   "tweet_id": "1590005257782063105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005236797952000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005220964454401",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005217135067136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005205889822721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005190585114626",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005190304092160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005183651905536",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005175921840128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005174760001537",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005159656292352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005153268371457",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005135358296064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590005103947550721",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005101456166912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590005093906415616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005064982466561",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590005063929696256",
   "Positive": "0.6576142311096191",
   "Negative": "0.0003267124993726611",
   "Neutral": "0.3419513404369354",
   "Mixed": "0.00010767630737973377"
 },
 {
   "tweet_id": "1590005056791023617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590005031323185153",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004989203984385",
   "Positive": "0.16263258457183838",
   "Negative": "0.0015221129870042205",
   "Neutral": "0.8358029127120972",
   "Mixed": "4.23298952227924e-05"
 },
 {
   "tweet_id": "1590004968568020993",
   "Positive": "0.0008751314016990364",
   "Negative": "6.109740206738934e-05",
   "Neutral": "0.9990189075469971",
   "Mixed": "4.493488449952565e-05"
 },
 {
   "tweet_id": "1590004965913006081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004932153069569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004923370209282",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004905724768256",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590004890411364352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004875517362178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004870014435328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004858773729288",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004854206107651",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004841740664833",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004839064698881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004832248922113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004804872699905",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004799243976704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004797570445317",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1590004788716273664",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788531703808",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788112289795",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788091322368",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788057755649",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788049371137",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004788011622400",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004787990663168",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004787982241792",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004787944493059",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004780231192577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004776049479682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004774828937216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004771192127488",
   "Positive": "0.16609928011894226",
   "Negative": "0.0033369003795087337",
   "Neutral": "0.8303354978561401",
   "Mixed": "0.00022840093879494816"
 },
 {
   "tweet_id": "1590004769573449729",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590004756709535745",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004747393650688",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590004740297224192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004732860698624",
   "Positive": "0.002442815573886037",
   "Negative": "0.6682666540145874",
   "Neutral": "0.32385823130607605",
   "Mixed": "0.005432347767055035"
 },
 {
   "tweet_id": "1590004731916693504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004730847432704",
   "Positive": "0.14346858859062195",
   "Negative": "0.0006529471138492227",
   "Neutral": "0.8557414412498474",
   "Mixed": "0.00013713075895793736"
 },
 {
   "tweet_id": "1590004724497276928",
   "Positive": "0.00495251314714551",
   "Negative": "0.17235057055950165",
   "Neutral": "0.8205606341362",
   "Mixed": "0.0021362388506531715"
 },
 {
   "tweet_id": "1590004719736737792",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590004703056003072",
   "Positive": "0.0003561826888471842",
   "Negative": "0.0003000185824930668",
   "Neutral": "0.9993351101875305",
   "Mixed": "8.637853170512244e-06"
 },
 {
   "tweet_id": "1590004696202506240",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590004688979914752",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004680645435393",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004656042045440",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590004655454461952",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004655420891136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004644364681217",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590004631207579648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004618775646208",
   "Positive": "0.9863110184669495",
   "Negative": "6.723080150550231e-05",
   "Neutral": "0.01352179329842329",
   "Mixed": "9.993686398956925e-05"
 },
 {
   "tweet_id": "1590004617642852352",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590004603277684736",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590004601549651970",
   "Positive": "0.05060510337352753",
   "Negative": "0.0030040275305509567",
   "Neutral": "0.9463777542114258",
   "Mixed": "1.3101733202347532e-05"
 },
 {
   "tweet_id": "1590004587708448769",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590004573133246465",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004563179773952",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1590004556884488193",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004553793310732",
   "Positive": "0.8116556406021118",
   "Negative": "0.002406385028734803",
   "Neutral": "0.18584997951984406",
   "Mixed": "8.799588249530643e-05"
 },
 {
   "tweet_id": "1590004549061726209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004546004475904",
   "Positive": "0.018558315932750702",
   "Negative": "0.23714731633663177",
   "Neutral": "0.7435390949249268",
   "Mixed": "0.000755264307372272"
 },
 {
   "tweet_id": "1590004535346745345",
   "Positive": "0.1529613882303238",
   "Negative": "0.0020264629274606705",
   "Neutral": "0.8449771404266357",
   "Mixed": "3.498731894069351e-05"
 },
 {
   "tweet_id": "1590004534872801284",
   "Positive": "0.6410760879516602",
   "Negative": "0.0006347306771203876",
   "Neutral": "0.35816478729248047",
   "Mixed": "0.00012436819088179618"
 },
 {
   "tweet_id": "1590004533262192640",
   "Positive": "0.004465526435524225",
   "Negative": "0.00011693209671648219",
   "Neutral": "0.995406985282898",
   "Mixed": "1.0524139725021087e-05"
 },
 {
   "tweet_id": "1590004520444366850",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004510956847104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004502324969477",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004500781486080",
   "Positive": "0.12950900197029114",
   "Negative": "0.02632463350892067",
   "Neutral": "0.8438138365745544",
   "Mixed": "0.0003525257925502956"
 },
 {
   "tweet_id": "1590004491041939456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004475338854403",
   "Positive": "0.10307767987251282",
   "Negative": "0.0011311981361359358",
   "Neutral": "0.8957064747810364",
   "Mixed": "8.467043517157435e-05"
 },
 {
   "tweet_id": "1590004474050813953",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004468824690688",
   "Positive": "0.012789196334779263",
   "Negative": "0.1406971663236618",
   "Neutral": "0.8338100910186768",
   "Mixed": "0.012703503482043743"
 },
 {
   "tweet_id": "1590004468552437762",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004463657373696",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1590004451364179969",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004449564512256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004421643358208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004412982132736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004406573215745",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004400764121094",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004397517733888",
   "Positive": "0.609590470790863",
   "Negative": "0.0007587633444927633",
   "Neutral": "0.389552503824234",
   "Mixed": "9.827257599681616e-05"
 },
 {
   "tweet_id": "1590004393889660929",
   "Positive": "0.9117993712425232",
   "Negative": "0.0006900841253809631",
   "Neutral": "0.08747652918100357",
   "Mixed": "3.400464265723713e-05"
 },
 {
   "tweet_id": "1590004392035758081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004388017631232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004380438532097",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380346253313",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380304289792",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380266549249",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380191055881",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380098768897",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380086173696",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380061040640",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004380056825856",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590004370837770240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004336259903488",
   "Positive": "0.020807893946766853",
   "Negative": "0.19750754535198212",
   "Neutral": "0.7794098854064941",
   "Mixed": "0.0022745891474187374"
 },
 {
   "tweet_id": "1590004325698658304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004319197093888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590004299727515649",
   "Positive": "0.0005871509783901274",
   "Negative": "0.0006404352025128901",
   "Neutral": "0.998762845993042",
   "Mixed": "9.60775105340872e-06"
 },
 {
   "tweet_id": "1590004298825728000",
   "Positive": "0.0012369228061288595",
   "Negative": "0.025089871138334274",
   "Neutral": "0.9718301892280579",
   "Mixed": "0.0018429480260238051"
 },
 {
   "tweet_id": "1590004293368958977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004269385940994",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590004248326332416",
   "Positive": "0.1605571210384369",
   "Negative": "0.000462154159322381",
   "Neutral": "0.8388367295265198",
   "Mixed": "0.00014398749044630677"
 },
 {
   "tweet_id": "1590004241367986177",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590004230198546434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004220471963649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004220274839553",
   "Positive": "0.010936432518064976",
   "Negative": "0.00016335518739651889",
   "Neutral": "0.9888935685157776",
   "Mixed": "6.638245849899249e-06"
 },
 {
   "tweet_id": "1590004206794342402",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004200544821251",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590004187534102528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004174288490497",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004169716502529",
   "Positive": "0.11502526700496674",
   "Negative": "0.0011457586660981178",
   "Neutral": "0.8837839961051941",
   "Mixed": "4.4945449189981446e-05"
 },
 {
   "tweet_id": "1590004169473417217",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590004162917707776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004162875777027",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590004160707321857",
   "Positive": "0.0005871509783901274",
   "Negative": "0.0006404352025128901",
   "Neutral": "0.998762845993042",
   "Mixed": "9.60775105340872e-06"
 },
 {
   "tweet_id": "1590004159587450880",
   "Positive": "0.0005871509783901274",
   "Negative": "0.0006404352025128901",
   "Neutral": "0.998762845993042",
   "Mixed": "9.60775105340872e-06"
 },
 {
   "tweet_id": "1590004159142453248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004154139045888",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1590004126968328192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590004125533868035",
   "Positive": "0.00988234393298626",
   "Negative": "0.004586158785969019",
   "Neutral": "0.98539137840271",
   "Mixed": "0.0001401667104801163"
 },
 {
   "tweet_id": "1590004100523249665",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004096819683329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004084291272704",
   "Positive": "0.03167393058538437",
   "Negative": "0.0006045151385478675",
   "Neutral": "0.967714250087738",
   "Mixed": "7.286623713298468e-06"
 },
 {
   "tweet_id": "1590004076049502208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590004062879375361",
   "Positive": "0.9010674357414246",
   "Negative": "0.00046294103958643973",
   "Neutral": "0.09823516756296158",
   "Mixed": "0.00023448171850759536"
 },
 {
   "tweet_id": "1590004044676100097",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590004039399313410",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590004017425702912",
   "Positive": "0.025204092264175415",
   "Negative": "0.06136363372206688",
   "Neutral": "0.9131147265434265",
   "Mixed": "0.00031755570671521127"
 },
 {
   "tweet_id": "1590003999251783683",
   "Positive": "0.7699216604232788",
   "Negative": "0.011589758098125458",
   "Neutral": "0.2173231989145279",
   "Mixed": "0.0011654114350676537"
 },
 {
   "tweet_id": "1590003989613252608",
   "Positive": "0.3409499228000641",
   "Negative": "0.0035427436232566833",
   "Neutral": "0.6554473638534546",
   "Mixed": "5.99707527726423e-05"
 },
 {
   "tweet_id": "1590003988023619586",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1590003987767783430",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003976996794371",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1590003976086630400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003967605747712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003923406172160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003912244723713",
   "Positive": "0.0007855843286961317",
   "Negative": "0.00015554243873339146",
   "Neutral": "0.9990553259849548",
   "Mixed": "3.538699957061908e-06"
 },
 {
   "tweet_id": "1590003909392994305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003883971342336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003881584779265",
   "Positive": "0.0004491181462071836",
   "Negative": "0.9522900581359863",
   "Neutral": "0.04722471535205841",
   "Mixed": "3.617516267695464e-05"
 },
 {
   "tweet_id": "1590003877520498689",
   "Positive": "0.4793739914894104",
   "Negative": "0.0010878924513235688",
   "Neutral": "0.5181711912155151",
   "Mixed": "0.0013669285690411925"
 },
 {
   "tweet_id": "1590003872839667712",
   "Positive": "0.9096162915229797",
   "Negative": "0.002985133323818445",
   "Neutral": "0.08736197650432587",
   "Mixed": "3.662187009467743e-05"
 },
 {
   "tweet_id": "1590003866904702978",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590003866900525056",
   "Positive": "0.7741291522979736",
   "Negative": "0.0024892326910048723",
   "Neutral": "0.2232854962348938",
   "Mixed": "9.615998715162277e-05"
 },
 {
   "tweet_id": "1590003854745403398",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003853898186752",
   "Positive": "0.019289713352918625",
   "Negative": "0.31294453144073486",
   "Neutral": "0.018191559240221977",
   "Mixed": "0.6495742201805115"
 },
 {
   "tweet_id": "1590003845572497410",
   "Positive": "0.004493436776101589",
   "Negative": "0.0002852951583918184",
   "Neutral": "0.9952174425125122",
   "Mixed": "3.775619688894949e-06"
 },
 {
   "tweet_id": "1590003829013360641",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590003816845705218",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003815323152384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003814337511425",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003813012090881",
   "Positive": "0.0769476667046547",
   "Negative": "0.0006528551457449794",
   "Neutral": "0.9222811460494995",
   "Mixed": "0.00011829996219603345"
 },
 {
   "tweet_id": "1590003801620353024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003789687558147",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003772461301762",
   "Positive": "0.8329936265945435",
   "Negative": "0.0007926281541585922",
   "Neutral": "0.1661182940006256",
   "Mixed": "9.547640365781263e-05"
 },
 {
   "tweet_id": "1590003764282343425",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590003730761809920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003730631778312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003730480783360",
   "Positive": "0.14663130044937134",
   "Negative": "0.00023655500262975693",
   "Neutral": "0.8530823588371277",
   "Mixed": "4.984800034435466e-05"
 },
 {
   "tweet_id": "1590003730090692609",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003729142403074",
   "Positive": "0.03240826353430748",
   "Negative": "0.00016465560474898666",
   "Neutral": "0.9673126935958862",
   "Mixed": "0.00011436380736995488"
 },
 {
   "tweet_id": "1590003724260638722",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003712323252225",
   "Positive": "0.49606412649154663",
   "Negative": "0.0017851589946076274",
   "Neutral": "0.5021184086799622",
   "Mixed": "3.225713226129301e-05"
 },
 {
   "tweet_id": "1590003702886453248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003695085060097",
   "Positive": "0.06704751402139664",
   "Negative": "0.2135409414768219",
   "Neutral": "0.6915386319160461",
   "Mixed": "0.027872873470187187"
 },
 {
   "tweet_id": "1590003676076466178",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1590003674209652736",
   "Positive": "0.003295829752460122",
   "Negative": "0.6128363609313965",
   "Neutral": "0.3777444362640381",
   "Mixed": "0.006123420782387257"
 },
 {
   "tweet_id": "1590003669004857345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003661609918466",
   "Positive": "0.0019871117547154427",
   "Negative": "0.7768269777297974",
   "Neutral": "0.21980984508991241",
   "Mixed": "0.0013760521542280912"
 },
 {
   "tweet_id": "1590003660301340673",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590003658024185857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003656149340160",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1590003654752612352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003648234651651",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003644602421248",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003643973275648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003641112760325",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590003631583264768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003628483698689",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003605725413376",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003596464119808",
   "Positive": "0.01799689047038555",
   "Negative": "0.00012857037654612213",
   "Neutral": "0.9818419218063354",
   "Mixed": "3.267262945882976e-05"
 },
 {
   "tweet_id": "1590003593259929601",
   "Positive": "0.055951159447431564",
   "Negative": "0.015708008781075478",
   "Neutral": "0.9019163846969604",
   "Mixed": "0.02642439678311348"
 },
 {
   "tweet_id": "1590003592844693505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003571583770625",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003567813095424",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003559860695041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003545352572930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003529925939206",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003518521344001",
   "Positive": "0.9981988072395325",
   "Negative": "4.8219859309028834e-05",
   "Neutral": "0.0017388095147907734",
   "Mixed": "1.4240175914892461e-05"
 },
 {
   "tweet_id": "1590003517582106624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003516978114560",
   "Positive": "0.06322870403528214",
   "Negative": "0.0014999790582805872",
   "Neutral": "0.9350993633270264",
   "Mixed": "0.0001719477731967345"
 },
 {
   "tweet_id": "1590003496518287360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590003493922037761",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590003489450921984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003488792379396",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590003488070979584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003476951539719",
   "Positive": "0.7016639709472656",
   "Negative": "0.06506790965795517",
   "Neutral": "0.1926746815443039",
   "Mixed": "0.04059341549873352"
 },
 {
   "tweet_id": "1590003449206558722",
   "Positive": "0.8499743938446045",
   "Negative": "0.00022911802807357162",
   "Neutral": "0.1496848613023758",
   "Mixed": "0.00011170932702952996"
 },
 {
   "tweet_id": "1590003448480948225",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590003434693873665",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1590003400137404417",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003390163349504",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003383787978752",
   "Positive": "0.002635066630318761",
   "Negative": "0.07165783643722534",
   "Neutral": "0.9256930351257324",
   "Mixed": "1.4169667338137515e-05"
 },
 {
   "tweet_id": "1590003383498289155",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1590003372014571521",
   "Positive": "0.9187346696853638",
   "Negative": "0.00010599437518976629",
   "Neutral": "0.0811108946800232",
   "Mixed": "4.8412985051982105e-05"
 },
 {
   "tweet_id": "1590003371272200192",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1590003367883206657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003366738145282",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003347498860546",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590003346030854144",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003340087537665",
   "Positive": "0.020941894501447678",
   "Negative": "0.005537009797990322",
   "Neutral": "0.9735124707221985",
   "Mixed": "8.569348210585304e-06"
 },
 {
   "tweet_id": "1590003318272638977",
   "Positive": "0.022660275921225548",
   "Negative": "0.12404832988977432",
   "Neutral": "0.853256344795227",
   "Mixed": "3.511441173031926e-05"
 },
 {
   "tweet_id": "1590003309888561158",
   "Positive": "0.09814585745334625",
   "Negative": "0.03993295133113861",
   "Neutral": "0.8618273735046387",
   "Mixed": "9.383675205754116e-05"
 },
 {
   "tweet_id": "1590003303186075648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003280335470593",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590003279035236353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003253349330945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003245749276672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003235460640769",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003218301751303",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590003213755092994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003211108118528",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003203059613697",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590003196331970561",
   "Positive": "0.024720067158341408",
   "Negative": "0.0006302253459580243",
   "Neutral": "0.974643886089325",
   "Mixed": "5.855217295902548e-06"
 },
 {
   "tweet_id": "1590003183354777601",
   "Positive": "0.0007591155590489507",
   "Negative": "0.9708898663520813",
   "Neutral": "0.027803238481283188",
   "Mixed": "0.0005477291415445507"
 },
 {
   "tweet_id": "1590003160122535936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003159552110592",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003158540902400",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590003155974385665",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003153562644480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003136512466944",
   "Positive": "0.004625851288437843",
   "Negative": "0.533860445022583",
   "Neutral": "0.46139058470726013",
   "Mixed": "0.00012311719183344394"
 },
 {
   "tweet_id": "1590003094188077059",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590003077985497088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590003047929085958",
   "Positive": "0.0005871509783901274",
   "Negative": "0.0006404352025128901",
   "Neutral": "0.998762845993042",
   "Mixed": "9.60775105340872e-06"
 },
 {
   "tweet_id": "1590003035618816000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590003018254422019",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003012336246785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590003011207958530",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002984796434436",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002983361994753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002970644557825",
   "Positive": "0.006437502335757017",
   "Negative": "0.7968078255653381",
   "Neutral": "0.19633163511753082",
   "Mixed": "0.00042293587466701865"
 },
 {
   "tweet_id": "1590002970069938178",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002967075524608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002963174809602",
   "Positive": "0.010298415087163448",
   "Negative": "0.9210493564605713",
   "Neutral": "0.06862717866897583",
   "Mixed": "2.496098204574082e-05"
 },
 {
   "tweet_id": "1590002962268848133",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002957101465603",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002956073848832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002951937925120",
   "Positive": "0.004698638338595629",
   "Negative": "0.00013186443538870662",
   "Neutral": "0.9951648712158203",
   "Mixed": "4.576395895128371e-06"
 },
 {
   "tweet_id": "1590002939284054017",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1590002928534056962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002914915151874",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002911903637504",
   "Positive": "0.015985926613211632",
   "Negative": "0.04627491533756256",
   "Neutral": "0.9377167820930481",
   "Mixed": "2.2336153051583096e-05"
 },
 {
   "tweet_id": "1590002910330748928",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590002907403149313",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002898632679424",
   "Positive": "0.26735374331474304",
   "Negative": "0.0008555082022212446",
   "Neutral": "0.7317644357681274",
   "Mixed": "2.6353291104896925e-05"
 },
 {
   "tweet_id": "1590002892798251008",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590002888319053826",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002886674898944",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002883504005120",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1590002871361470464",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002869910274048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002846552186880",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590002829154189312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002829082886144",
   "Positive": "0.07341922074556351",
   "Negative": "0.0014214395778253675",
   "Neutral": "0.9251429438591003",
   "Mixed": "1.6478044926770963e-05"
 },
 {
   "tweet_id": "1590002827275141121",
   "Positive": "0.059286121279001236",
   "Negative": "0.05990730598568916",
   "Neutral": "0.8147642016410828",
   "Mixed": "0.06604230403900146"
 },
 {
   "tweet_id": "1590002806890852353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002806823735297",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002806135496704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002805057540097",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1590002804386836480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002791950733313",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002786582032384",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1590002784086417410",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002782987177984",
   "Positive": "0.015083298087120056",
   "Negative": "0.36694130301475525",
   "Neutral": "0.6175726652145386",
   "Mixed": "0.0004027106042485684"
 },
 {
   "tweet_id": "1590002778621235201",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002775660068873",
   "Positive": "0.0025458424352109432",
   "Negative": "0.8761560320854187",
   "Neutral": "0.11769936978816986",
   "Mixed": "0.003598704468458891"
 },
 {
   "tweet_id": "1590002766373851136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002747277193216",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1590002745326829570",
   "Positive": "0.032953500747680664",
   "Negative": "0.08566831052303314",
   "Neutral": "0.8812166452407837",
   "Mixed": "0.00016156338097061962"
 },
 {
   "tweet_id": "1590002736598118400",
   "Positive": "0.0733371302485466",
   "Negative": "0.03784850612282753",
   "Neutral": "0.8885492086410522",
   "Mixed": "0.0002652684925124049"
 },
 {
   "tweet_id": "1590002720819539968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002713118781443",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002697067167746",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002695355924484",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002674279538690",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002669401550849",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590002665869938689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002655052845056",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002649109176320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002648157106176",
   "Positive": "0.5707780718803406",
   "Negative": "0.0005872386391274631",
   "Neutral": "0.42813611030578613",
   "Mixed": "0.0004985548439435661"
 },
 {
   "tweet_id": "1590002646823628800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002640808968192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002632151945216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002630977212416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002626250174464",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002625873072128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002622123364352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002618373353472",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590002613587939329",
   "Positive": "0.15546251833438873",
   "Negative": "0.003439063671976328",
   "Neutral": "0.8410141468048096",
   "Mixed": "8.434405754087493e-05"
 },
 {
   "tweet_id": "1590002607804018690",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002594881359875",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002592427696128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002584579817472",
   "Positive": "0.6638821959495544",
   "Negative": "0.0004976813797838986",
   "Neutral": "0.33527547121047974",
   "Mixed": "0.00034463024348951876"
 },
 {
   "tweet_id": "1590002573591056385",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002565387005952",
   "Positive": "0.9761294722557068",
   "Negative": "0.00011841233936138451",
   "Neutral": "0.023732956498861313",
   "Mixed": "1.912954576255288e-05"
 },
 {
   "tweet_id": "1590002551646130177",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1590002550073589760",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002547431215104",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002539176816640",
   "Positive": "0.8955929279327393",
   "Negative": "0.00020213218522258103",
   "Neutral": "0.1041642352938652",
   "Mixed": "4.0715578506933525e-05"
 },
 {
   "tweet_id": "1590002526589718530",
   "Positive": "0.0378761850297451",
   "Negative": "0.0005293001886457205",
   "Neutral": "0.9614961743354797",
   "Mixed": "9.839650010690093e-05"
 },
 {
   "tweet_id": "1590002520545701888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002501151264768",
   "Positive": "0.1839851588010788",
   "Negative": "0.0005722636706195772",
   "Neutral": "0.8154182434082031",
   "Mixed": "2.4264514649985358e-05"
 },
 {
   "tweet_id": "1590002498366210050",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002497170853889",
   "Positive": "0.0011686140205711126",
   "Negative": "0.8970972299575806",
   "Neutral": "0.10127762705087662",
   "Mixed": "0.0004564572009257972"
 },
 {
   "tweet_id": "1590002484671844353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002477868646402",
   "Positive": "0.11875779926776886",
   "Negative": "0.0017496886430308223",
   "Neutral": "0.8794728517532349",
   "Mixed": "1.9714967493200675e-05"
 },
 {
   "tweet_id": "1590002472076312576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002462869839872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002452929335296",
   "Positive": "0.009510939940810204",
   "Negative": "0.744813084602356",
   "Neutral": "0.24138011038303375",
   "Mixed": "0.004295889288187027"
 },
 {
   "tweet_id": "1590002448227500035",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002443097493515",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002441210466308",
   "Positive": "0.4822312295436859",
   "Negative": "0.0012554751010611653",
   "Neutral": "0.5164064764976501",
   "Mixed": "0.00010688592738006264"
 },
 {
   "tweet_id": "1590002439096537088",
   "Positive": "0.9546181559562683",
   "Negative": "0.0005966841126792133",
   "Neutral": "0.044714197516441345",
   "Mixed": "7.09863961674273e-05"
 },
 {
   "tweet_id": "1590002437188120576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002411854516225",
   "Positive": "0.276344895362854",
   "Negative": "0.08848945796489716",
   "Neutral": "0.33214718103408813",
   "Mixed": "0.3030184507369995"
 },
 {
   "tweet_id": "1590002394934702083",
   "Positive": "0.9546829462051392",
   "Negative": "0.00019114416500087827",
   "Neutral": "0.045089252293109894",
   "Mixed": "3.6569992516888306e-05"
 },
 {
   "tweet_id": "1590002372004425735",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002359442505732",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002350957432832",
   "Positive": "0.018049294129014015",
   "Negative": "0.00033776729833334684",
   "Neutral": "0.9806631207466125",
   "Mixed": "0.0009498329018242657"
 },
 {
   "tweet_id": "1590002347828445185",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002328132030465",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590002325493805056",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590002320041213952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002298155335682",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590002293298327553",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002290366509058",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002286331572224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002285978886146",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590002283538173953",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590002277783580672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590002276068130816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002267637567489",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590002267125846016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002254496804867",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002238700691456",
   "Positive": "0.10851547867059708",
   "Negative": "0.005011631175875664",
   "Neutral": "0.886416494846344",
   "Mixed": "5.6422341003781185e-05"
 },
 {
   "tweet_id": "1590002238520717312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002224834711553",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002220145463298",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590002217641472000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002211597463553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002208875065344",
   "Positive": "0.0005070113111287355",
   "Negative": "0.6431931853294373",
   "Neutral": "0.3560369312763214",
   "Mixed": "0.00026280281599611044"
 },
 {
   "tweet_id": "1590002202994946048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002199169376256",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1590002197424660481",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590002187144658949",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002181926981632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002165002964992",
   "Positive": "0.04205089434981346",
   "Negative": "0.03025451861321926",
   "Neutral": "0.9276587963104248",
   "Mixed": "3.578994801500812e-05"
 },
 {
   "tweet_id": "1590002162314407936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002141673926656",
   "Positive": "0.002038948703557253",
   "Negative": "0.9508584141731262",
   "Neutral": "0.04691118746995926",
   "Mixed": "0.0001913852902362123"
 },
 {
   "tweet_id": "1590002135940243456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002131976998912",
   "Positive": "0.023628568276762962",
   "Negative": "0.0015403383877128363",
   "Neutral": "0.9748091697692871",
   "Mixed": "2.1947671484667808e-05"
 },
 {
   "tweet_id": "1590002129137455105",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590002109650735105",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002104512712705",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1590002102512029697",
   "Positive": "0.0014453220646828413",
   "Negative": "0.00017553295765537769",
   "Neutral": "0.9983747005462646",
   "Mixed": "4.414021532284096e-06"
 },
 {
   "tweet_id": "1590002091765817344",
   "Positive": "0.0014042290858924389",
   "Negative": "0.0002883142442442477",
   "Neutral": "0.9983049631118774",
   "Mixed": "2.551303623476997e-06"
 },
 {
   "tweet_id": "1590002088284917762",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002084493299713",
   "Positive": "0.6926999092102051",
   "Negative": "0.0011795549653470516",
   "Neutral": "0.3040507733821869",
   "Mixed": "0.0020697512663900852"
 },
 {
   "tweet_id": "1590002056756355075",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002056575979520",
   "Positive": "0.016512097790837288",
   "Negative": "0.00011386475671315566",
   "Neutral": "0.983347475528717",
   "Mixed": "2.649017005751375e-05"
 },
 {
   "tweet_id": "1590002050057744384",
   "Positive": "0.19727356731891632",
   "Negative": "0.000506565731484443",
   "Neutral": "0.8021550178527832",
   "Mixed": "6.496501009678468e-05"
 },
 {
   "tweet_id": "1590002049546354688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590002045171691522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002043254878210",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002041531031553",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590002032546840576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590002032328384512",
   "Positive": "0.012593749910593033",
   "Negative": "0.00025696418015286326",
   "Neutral": "0.9871429800987244",
   "Mixed": "6.229855898709502e-06"
 },
 {
   "tweet_id": "1590002018478747654",
   "Positive": "0.022032946348190308",
   "Negative": "0.00013327039778232574",
   "Neutral": "0.9778133630752563",
   "Mixed": "2.0453120669117197e-05"
 },
 {
   "tweet_id": "1590002017673842690",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001994445770753",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001988888309760",
   "Positive": "0.04205089434981346",
   "Negative": "0.03025451861321926",
   "Neutral": "0.9276587963104248",
   "Mixed": "3.578994801500812e-05"
 },
 {
   "tweet_id": "1590001980424196096",
   "Positive": "0.3860095739364624",
   "Negative": "0.002649198053404689",
   "Neutral": "0.6113113760948181",
   "Mixed": "2.987686821143143e-05"
 },
 {
   "tweet_id": "1590001974636077056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001969472864256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001965379252225",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001958861295617",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590001935134130178",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590001928527749121",
   "Positive": "0.01450415886938572",
   "Negative": "0.38768336176872253",
   "Neutral": "0.5581587553024292",
   "Mixed": "0.03965369611978531"
 },
 {
   "tweet_id": "1590001923528470529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001915748028417",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1590001914313605124",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001907925684224",
   "Positive": "0.00046447647036984563",
   "Negative": "9.801761189009994e-05",
   "Neutral": "0.9994332194328308",
   "Mixed": "4.24691188527504e-06"
 },
 {
   "tweet_id": "1590001902926061568",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001898903719936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001898291367936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001895812509697",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001889915305984",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590001872387309569",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1590001867316424706",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001861343727616",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001858747461634",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590001835875921920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001818041733120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001813629337600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001811397562374",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001810575855617",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590001804984881152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001798605307906",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001781676744704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001781198979072",
   "Positive": "0.047147806733846664",
   "Negative": "0.00031388047500513494",
   "Neutral": "0.9523297548294067",
   "Mixed": "0.00020849888096563518"
 },
 {
   "tweet_id": "1590001776874622976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001771405271040",
   "Positive": "0.05323636904358864",
   "Negative": "0.0004920025821775198",
   "Neutral": "0.9462264776229858",
   "Mixed": "4.5093293010722846e-05"
 },
 {
   "tweet_id": "1590001770205679617",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001767986925568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001751788515331",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590001749158678529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001748802154496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001746339762177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001740472287232",
   "Positive": "0.00682380236685276",
   "Negative": "0.005869792774319649",
   "Neutral": "0.9873017072677612",
   "Mixed": "4.7547196118102875e-06"
 },
 {
   "tweet_id": "1590001737896951808",
   "Positive": "0.968865156173706",
   "Negative": "6.784913421142846e-05",
   "Neutral": "0.031038761138916016",
   "Mixed": "2.8264932552701794e-05"
 },
 {
   "tweet_id": "1590001727990018048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001726571937792",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590001717839818752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001715813945348",
   "Positive": "0.19727356731891632",
   "Negative": "0.000506565731484443",
   "Neutral": "0.8021550178527832",
   "Mixed": "6.496501009678468e-05"
 },
 {
   "tweet_id": "1590001707949649920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001691986124802",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001689431797761",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001673904484354",
   "Positive": "0.276407390832901",
   "Negative": "0.0007946894038468599",
   "Neutral": "0.7226871252059937",
   "Mixed": "0.00011082918354077265"
 },
 {
   "tweet_id": "1590001667776602113",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590001665415204864",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590001664282755072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001657944821761",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590001647530708994",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001636700979202",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001600256696320",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590001594766045184",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001590760779777",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590001579671040000",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590001579322912768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001563166130176",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001561677484033",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001559534174208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001555214041089",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590001552496136192",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590001547437813761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001540051656705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001533881831431",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001524910215170",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1590001520732700674",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001518702649344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001515749847042",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001500956528640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001491028242433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001486058381315",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001485303386113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001472980520961",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001446228934657",
   "Positive": "0.004698638338595629",
   "Negative": "0.00013186443538870662",
   "Neutral": "0.9951648712158203",
   "Mixed": "4.576395895128371e-06"
 },
 {
   "tweet_id": "1590001438033575936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001437622566912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001436519444480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001436418797570",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001433369513986",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001410858680320",
   "Positive": "0.0011769670527428389",
   "Negative": "6.088698864914477e-05",
   "Neutral": "0.998755693435669",
   "Mixed": "6.401939572242554e-06"
 },
 {
   "tweet_id": "1590001389421592576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001389148995584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001388809244672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001388721180672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001388045885443",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590001386737258498",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001378906120192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001377685950464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001365270794242",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1590001351408619520",
   "Positive": "0.2811577618122101",
   "Negative": "0.003170181065797806",
   "Neutral": "0.7154855728149414",
   "Mixed": "0.00018654206360224634"
 },
 {
   "tweet_id": "1590001350968246273",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001349877706753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001343812743169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001330596511746",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001323067731970",
   "Positive": "0.012113750912249088",
   "Negative": "0.00038569371099583805",
   "Neutral": "0.9874931573867798",
   "Mixed": "7.397684385068715e-06"
 },
 {
   "tweet_id": "1590001312481284097",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001283624468480",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590001268101382150",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001241152970753",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1590001238317600768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001232193593344",
   "Positive": "0.026495445519685745",
   "Negative": "0.11976654827594757",
   "Neutral": "0.8537127375602722",
   "Mixed": "2.5284431103500538e-05"
 },
 {
   "tweet_id": "1590001229941596167",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001228356153345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001225902149632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001222895153153",
   "Positive": "0.07486769556999207",
   "Negative": "0.0002324852393940091",
   "Neutral": "0.9248788952827454",
   "Mixed": "2.094067895086482e-05"
 },
 {
   "tweet_id": "1590001215492222979",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001208764563457",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1590001179362488321",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590001169526837249",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1590001165680672768",
   "Positive": "0.7190747261047363",
   "Negative": "0.001422217465005815",
   "Neutral": "0.27944236993789673",
   "Mixed": "6.0686048527713865e-05"
 },
 {
   "tweet_id": "1590001159712178178",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001157661155328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001151902388224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001149192830976",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001147515113474",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590001134273703937",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1590001129177632768",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590001123188183040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001115520958464",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001112782077952",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001112001585153",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590001108554219527",
   "Positive": "0.021102676168084145",
   "Negative": "0.00014750381524208933",
   "Neutral": "0.9787322878837585",
   "Mixed": "1.754801814968232e-05"
 },
 {
   "tweet_id": "1590001104716431360",
   "Positive": "0.0006775608635507524",
   "Negative": "0.0001266978360945359",
   "Neutral": "0.9991903901100159",
   "Mixed": "5.308053459884832e-06"
 },
 {
   "tweet_id": "1590001104125038592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001101352632320",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590001097468698626",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1590001096701153280",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590001092058054657",
   "Positive": "0.9233934879302979",
   "Negative": "0.0009653256856836379",
   "Neutral": "0.0756010189652443",
   "Mixed": "4.011923374491744e-05"
 },
 {
   "tweet_id": "1590001087020666880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001077080195072",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590001075431821314",
   "Positive": "0.19546057283878326",
   "Negative": "0.10517236590385437",
   "Neutral": "0.5536431074142456",
   "Mixed": "0.14572392404079437"
 },
 {
   "tweet_id": "1590001073082597376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001061082767361",
   "Positive": "0.0900115817785263",
   "Negative": "0.09052931517362595",
   "Neutral": "0.7839857935905457",
   "Mixed": "0.03547336533665657"
 },
 {
   "tweet_id": "1590001059103002625",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1590001045245427715",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001043492212737",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1590001042791727104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590001038786199554",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590001022847815680",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1590001002690007040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000999972098051",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000994041364486",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000985845686272",
   "Positive": "0.026495445519685745",
   "Negative": "0.11976654827594757",
   "Neutral": "0.8537127375602722",
   "Mixed": "2.5284431103500538e-05"
 },
 {
   "tweet_id": "1590000983178096641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000959304130562",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000955181117443",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000941360893953",
   "Positive": "0.03298255056142807",
   "Negative": "0.00032514057238586247",
   "Neutral": "0.966646671295166",
   "Mixed": "4.5603836042573676e-05"
 },
 {
   "tweet_id": "1590000936751370241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000932632154113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000916853248001",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000913489752066",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000899333976065",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000890769190912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000866014420992",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1590000858766286850",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000856967319552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000848532549633",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590000831860211712",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590000819126272003",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590000812860014592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000805759053824",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590000798247059456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000797584347138",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000770497544193",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1590000760477343744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000755121197057",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000747076206593",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1590000745210085376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000744635461632",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000736397832192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000736158773253",
   "Positive": "0.024324210360646248",
   "Negative": "0.00023688811052124947",
   "Neutral": "0.9754309058189392",
   "Mixed": "8.02657177700894e-06"
 },
 {
   "tweet_id": "1590000733214380032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000727266856962",
   "Positive": "0.0006221322691999376",
   "Negative": "0.00016202581173274666",
   "Neutral": "0.9992008805274963",
   "Mixed": "1.4989562259870581e-05"
 },
 {
   "tweet_id": "1590000722493722624",
   "Positive": "0.858757495880127",
   "Negative": "0.0006485781632363796",
   "Neutral": "0.1403414011001587",
   "Mixed": "0.00025247843586839736"
 },
 {
   "tweet_id": "1590000720454877184",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000713262059520",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000703413833729",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000688947671042",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1590000687077023744",
   "Positive": "0.08465077728033066",
   "Negative": "0.04917098954319954",
   "Neutral": "0.3384699523448944",
   "Mixed": "0.5277082324028015"
 },
 {
   "tweet_id": "1590000686015860736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000680084787200",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1590000678252183552",
   "Positive": "0.10588407516479492",
   "Negative": "0.035762663930654526",
   "Neutral": "0.8359053134918213",
   "Mixed": "0.022447967901825905"
 },
 {
   "tweet_id": "1590000674867404800",
   "Positive": "0.5633478164672852",
   "Negative": "0.0003470249648671597",
   "Neutral": "0.43627598881721497",
   "Mixed": "2.9269107471918687e-05"
 },
 {
   "tweet_id": "1590000669234434048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000666688524288",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1590000641472331777",
   "Positive": "0.0028133527375757694",
   "Negative": "0.00034053370472975075",
   "Neutral": "0.9968372583389282",
   "Mixed": "8.820582479529548e-06"
 },
 {
   "tweet_id": "1590000621276758016",
   "Positive": "0.0018917853012681007",
   "Negative": "4.363305924925953e-05",
   "Neutral": "0.9980300068855286",
   "Mixed": "3.455644036876038e-05"
 },
 {
   "tweet_id": "1590000614310051840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000613601185792",
   "Positive": "0.015725551173090935",
   "Negative": "0.00073389703175053",
   "Neutral": "0.9835347533226013",
   "Mixed": "5.8079176596947946e-06"
 },
 {
   "tweet_id": "1590000612661694464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000612657500160",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000609729867776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000595616010242",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000579446984706",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000578117398528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000569858818049",
   "Positive": "0.00329285254701972",
   "Negative": "0.6185538172721863",
   "Neutral": "0.37771692872047424",
   "Mixed": "0.00043638746137730777"
 },
 {
   "tweet_id": "1590000565022789633",
   "Positive": "0.5086051225662231",
   "Negative": "0.00039604143239557743",
   "Neutral": "0.4909636080265045",
   "Mixed": "3.523783743730746e-05"
 },
 {
   "tweet_id": "1590000548266532865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000544168710145",
   "Positive": "0.04124193266034126",
   "Negative": "0.5376321077346802",
   "Neutral": "0.36719927191734314",
   "Mixed": "0.053926605731248856"
 },
 {
   "tweet_id": "1590000533947154435",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000530457128960",
   "Positive": "0.1040906235575676",
   "Negative": "0.05986825004220009",
   "Neutral": "0.8357082009315491",
   "Mixed": "0.00033293713931925595"
 },
 {
   "tweet_id": "1590000516360044544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000515492220929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000515064025089",
   "Positive": "0.046369265764951706",
   "Negative": "0.00033394363708794117",
   "Neutral": "0.9532831907272339",
   "Mixed": "1.3666997801919933e-05"
 },
 {
   "tweet_id": "1590000511884804096",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590000502934499329",
   "Positive": "0.031616244465112686",
   "Negative": "0.00043361957068555057",
   "Neutral": "0.9679412841796875",
   "Mixed": "8.930142030294519e-06"
 },
 {
   "tweet_id": "1590000497053704194",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1590000486119510018",
   "Positive": "0.03800271451473236",
   "Negative": "0.0008615810656920075",
   "Neutral": "0.9611265659332275",
   "Mixed": "9.219262210535817e-06"
 },
 {
   "tweet_id": "1590000478116794369",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000475335794691",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590000470625775616",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000464346550272",
   "Positive": "0.20770350098609924",
   "Negative": "0.007394765038043261",
   "Neutral": "0.7848606109619141",
   "Mixed": "4.118511060369201e-05"
 },
 {
   "tweet_id": "1590000461666734080",
   "Positive": "0.004453101195394993",
   "Negative": "8.945686568040401e-05",
   "Neutral": "0.9954485297203064",
   "Mixed": "8.881282155925874e-06"
 },
 {
   "tweet_id": "1590000459997417472",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1590000449532604416",
   "Positive": "0.2326105833053589",
   "Negative": "0.012436139397323132",
   "Neutral": "0.7549322843551636",
   "Mixed": "2.107050022459589e-05"
 },
 {
   "tweet_id": "1590000446692741120",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1590000444050661387",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000434693169153",
   "Positive": "0.3967176675796509",
   "Negative": "0.020740969106554985",
   "Neutral": "0.5824891328811646",
   "Mixed": "5.2226980187697336e-05"
 },
 {
   "tweet_id": "1590000417647124481",
   "Positive": "0.03919075429439545",
   "Negative": "0.001663415925577283",
   "Neutral": "0.9591373801231384",
   "Mixed": "8.457364856440108e-06"
 },
 {
   "tweet_id": "1590000415818801153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000413826117632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000411452182533",
   "Positive": "0.2265961617231369",
   "Negative": "0.004922655876725912",
   "Neutral": "0.7684527635574341",
   "Mixed": "2.841472451109439e-05"
 },
 {
   "tweet_id": "1590000401616883712",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000397368037377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000394822115330",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1590000392565202944",
   "Positive": "0.012272312305867672",
   "Negative": "0.5768586993217468",
   "Neutral": "0.36335650086402893",
   "Mixed": "0.04751242324709892"
 },
 {
   "tweet_id": "1590000392280035333",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1590000391172653057",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1590000388971061248",
   "Positive": "0.16179624199867249",
   "Negative": "0.0003868949133902788",
   "Neutral": "0.8373656272888184",
   "Mixed": "0.00045134156243875623"
 },
 {
   "tweet_id": "1590000368246661122",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000365017391104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000358566289411",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1590000356867465216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000354392883200",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1590000351918587904",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000339205652480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000336114442242",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000327734222849",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000323535712258",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1590000317957308417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000316052692992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000311959445505",
   "Positive": "0.00020445739210117608",
   "Negative": "0.1405898779630661",
   "Neutral": "0.8587942719459534",
   "Mixed": "0.0004113258619327098"
 },
 {
   "tweet_id": "1590000310768242689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000303453061121",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000302861979649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000297023541249",
   "Positive": "0.030849602073431015",
   "Negative": "0.005118488799780607",
   "Neutral": "0.9637274742126465",
   "Mixed": "0.0003044413751922548"
 },
 {
   "tweet_id": "1590000294930571264",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000287666036736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000287619874816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000283807252482",
   "Positive": "0.16179624199867249",
   "Negative": "0.0003868949133902788",
   "Neutral": "0.8373656272888184",
   "Mixed": "0.00045134156243875623"
 },
 {
   "tweet_id": "1590000282968023041",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590000263498465282",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000251280457728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000247811747841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000230086295552",
   "Positive": "0.6576926708221436",
   "Negative": "0.004128767643123865",
   "Neutral": "0.336586058139801",
   "Mixed": "0.0015925696352496743"
 },
 {
   "tweet_id": "1590000219458265088",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1590000199543705600",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000190760501249",
   "Positive": "0.0002883445704355836",
   "Negative": "0.0006535067223012447",
   "Neutral": "0.9990518689155579",
   "Mixed": "6.254276286199456e-06"
 },
 {
   "tweet_id": "1590000189854875649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000187187302401",
   "Positive": "0.16179624199867249",
   "Negative": "0.0003868949133902788",
   "Neutral": "0.8373656272888184",
   "Mixed": "0.00045134156243875623"
 },
 {
   "tweet_id": "1590000175434838023",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000168933658625",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1590000165632761856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000159018348547",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1590000156380131329",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000155587411968",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000114801995776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000112105058305",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000107319349248",
   "Positive": "0.004783228039741516",
   "Negative": "0.6938799619674683",
   "Neutral": "0.2998712956905365",
   "Mixed": "0.0014655329287052155"
 },
 {
   "tweet_id": "1590000097005555713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000090533736450",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1590000072636628992",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1590000072384983041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1590000064285769733",
   "Positive": "0.5086051225662231",
   "Negative": "0.00039604143239557743",
   "Neutral": "0.4909636080265045",
   "Mixed": "3.523783743730746e-05"
 },
 {
   "tweet_id": "1590000061442043904",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000052013273089",
   "Positive": "0.03459160774946213",
   "Negative": "0.028910556808114052",
   "Neutral": "0.9364640712738037",
   "Mixed": "3.3833774068625644e-05"
 },
 {
   "tweet_id": "1590000051585454080",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1590000042597060611",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000030295134210",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000029972193281",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1590000027648544768",
   "Positive": "0.21369391679763794",
   "Negative": "0.015473810024559498",
   "Neutral": "0.7706152200698853",
   "Mixed": "0.00021706626284867525"
 },
 {
   "tweet_id": "1590000025412997122",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1590000014444867584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000007830069248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1590000002889584641",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589999997831217152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999996212252673",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999990730289154",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589999978847485953",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589999976884862982",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999976092168193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999961042989059",
   "Positive": "0.16179624199867249",
   "Negative": "0.0003868949133902788",
   "Neutral": "0.8373656272888184",
   "Mixed": "0.00045134156243875623"
 },
 {
   "tweet_id": "1589999959860183041",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999952922824709",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999949793882116",
   "Positive": "0.028515249490737915",
   "Negative": "0.0003314361674711108",
   "Neutral": "0.9711467027664185",
   "Mixed": "6.649524948443286e-06"
 },
 {
   "tweet_id": "1589999946111279104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999945628938240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999936917196800",
   "Positive": "0.03772423788905144",
   "Negative": "0.010028904303908348",
   "Neutral": "0.9522413015365601",
   "Mixed": "5.531249826162821e-06"
 },
 {
   "tweet_id": "1589999936560861190",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999903631372290",
   "Positive": "0.05033688247203827",
   "Negative": "0.0004365247441455722",
   "Neutral": "0.9491561651229858",
   "Mixed": "7.041337084956467e-05"
 },
 {
   "tweet_id": "1589999902993498112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999900862734337",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1589999887198085123",
   "Positive": "0.046369265764951706",
   "Negative": "0.00033394363708794117",
   "Neutral": "0.9532831907272339",
   "Mixed": "1.3666997801919933e-05"
 },
 {
   "tweet_id": "1589999884526288898",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589999883087388673",
   "Positive": "0.6439546942710876",
   "Negative": "0.015218955464661121",
   "Neutral": "0.3403460383415222",
   "Mixed": "0.00048029411118477583"
 },
 {
   "tweet_id": "1589999872979374080",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999871687528448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999859855429635",
   "Positive": "0.33345475792884827",
   "Negative": "0.002389494562521577",
   "Neutral": "0.6639682650566101",
   "Mixed": "0.00018748786533251405"
 },
 {
   "tweet_id": "1589999852452446209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999837227147264",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999835490709505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999823260119042",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589999816440180737",
   "Positive": "0.031616244465112686",
   "Negative": "0.00043361957068555057",
   "Neutral": "0.9679412841796875",
   "Mixed": "8.930142030294519e-06"
 },
 {
   "tweet_id": "1589999804058566656",
   "Positive": "0.03800271451473236",
   "Negative": "0.0008615810656920075",
   "Neutral": "0.9611265659332275",
   "Mixed": "9.219262210535817e-06"
 },
 {
   "tweet_id": "1589999799998500865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999794314846208",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589999790083145728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999785612038146",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999781115756544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999780884754432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999770730344449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999770352828416",
   "Positive": "0.028220031410455704",
   "Negative": "0.2886117100715637",
   "Neutral": "0.5215859413146973",
   "Mixed": "0.161582350730896"
 },
 {
   "tweet_id": "1589999769036140544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999768276979714",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999764883771393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999756834926594",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589999752502206469",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999743425720322",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1589999742024839171",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589999739256573955",
   "Positive": "0.20770350098609924",
   "Negative": "0.007394765038043261",
   "Neutral": "0.7848606109619141",
   "Mixed": "4.118511060369201e-05"
 },
 {
   "tweet_id": "1589999724110966784",
   "Positive": "0.2326105833053589",
   "Negative": "0.012436139397323132",
   "Neutral": "0.7549322843551636",
   "Mixed": "2.107050022459589e-05"
 },
 {
   "tweet_id": "1589999716275982336",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589999711133794305",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999709648977922",
   "Positive": "0.3967176675796509",
   "Negative": "0.020740969106554985",
   "Neutral": "0.5824891328811646",
   "Mixed": "5.2226980187697336e-05"
 },
 {
   "tweet_id": "1589999681073197056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999680498589697",
   "Positive": "0.2265961617231369",
   "Negative": "0.004922655876725912",
   "Neutral": "0.7684527635574341",
   "Mixed": "2.841472451109439e-05"
 },
 {
   "tweet_id": "1589999674852638720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999673913520128",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589999671430504449",
   "Positive": "0.05122029781341553",
   "Negative": "0.0026483116671442986",
   "Neutral": "0.9460992813110352",
   "Mixed": "3.213174204574898e-05"
 },
 {
   "tweet_id": "1589999661364187137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999660172660737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999654363860992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999647069966337",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999642800189441",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999638462930944",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1589999631827886081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999628963151875",
   "Positive": "0.16977538168430328",
   "Negative": "0.05753659829497337",
   "Neutral": "0.770435631275177",
   "Mixed": "0.0022523351944983006"
 },
 {
   "tweet_id": "1589999615545597953",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999602702635008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999590904074242",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999590191034373",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1589999589897404417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999586617462787",
   "Positive": "0.2402603179216385",
   "Negative": "0.26324498653411865",
   "Neutral": "0.4961490333080292",
   "Mixed": "0.0003455889818724245"
 },
 {
   "tweet_id": "1589999576937017345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999561460027392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999555340537856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999550671966208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999530485125120",
   "Positive": "0.013151266612112522",
   "Negative": "0.005820367950946093",
   "Neutral": "0.9623627066612244",
   "Mixed": "0.018665557727217674"
 },
 {
   "tweet_id": "1589999521668685826",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999517499240448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999513020018688",
   "Positive": "0.20564596354961395",
   "Negative": "0.0003443932218942791",
   "Neutral": "0.7937677502632141",
   "Mixed": "0.00024194756406359375"
 },
 {
   "tweet_id": "1589999508070731776",
   "Positive": "0.9936809539794922",
   "Negative": "5.676220098393969e-05",
   "Neutral": "0.006250896956771612",
   "Mixed": "1.1346627616148908e-05"
 },
 {
   "tweet_id": "1589999505826795526",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999504081981440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999498411274241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999496603500544",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589999492971245570",
   "Positive": "0.06776747852563858",
   "Negative": "0.6528411507606506",
   "Neutral": "0.2791324555873871",
   "Mixed": "0.0002589503419585526"
 },
 {
   "tweet_id": "1589999489158643712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999487468310529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999484226134017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999477003550726",
   "Positive": "0.9606027007102966",
   "Negative": "0.0005083158030174673",
   "Neutral": "0.038841571658849716",
   "Mixed": "4.737375638796948e-05"
 },
 {
   "tweet_id": "1589999475514544130",
   "Positive": "0.23182904720306396",
   "Negative": "0.0007044374360702932",
   "Neutral": "0.7674046754837036",
   "Mixed": "6.188597035361454e-05"
 },
 {
   "tweet_id": "1589999473773916160",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589999472821821441",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999466526167043",
   "Positive": "0.019520554691553116",
   "Negative": "0.0011323700891807675",
   "Neutral": "0.9792709350585938",
   "Mixed": "7.609539898112416e-05"
 },
 {
   "tweet_id": "1589999453858983936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999453632868356",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589999445810491393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999445202337792",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589999443247783936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999440148189185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999435823874054",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999434607505409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999429503025153",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1589999425610711042",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999410188267521",
   "Positive": "0.0033494445960968733",
   "Negative": "0.6574777364730835",
   "Neutral": "0.33886605501174927",
   "Mixed": "0.00030674898880533874"
 },
 {
   "tweet_id": "1589999408770580480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999401782587392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999391477493760",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999390126936064",
   "Positive": "0.019520554691553116",
   "Negative": "0.0011323700891807675",
   "Neutral": "0.9792709350585938",
   "Mixed": "7.609539898112416e-05"
 },
 {
   "tweet_id": "1589999385257312256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999384585961475",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1589999372007526400",
   "Positive": "0.020994845777750015",
   "Negative": "0.00137483817525208",
   "Neutral": "0.9775429368019104",
   "Mixed": "8.742370118852705e-05"
 },
 {
   "tweet_id": "1589999370292060160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999363136552961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999341372346368",
   "Positive": "0.054217491298913956",
   "Negative": "0.0003286633873358369",
   "Neutral": "0.9452556371688843",
   "Mixed": "0.00019816703570540994"
 },
 {
   "tweet_id": "1589999340285988871",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999338465677312",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589999335861018624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999331377295365",
   "Positive": "0.07391707599163055",
   "Negative": "0.10215248912572861",
   "Neutral": "0.8237175941467285",
   "Mixed": "0.0002128559717675671"
 },
 {
   "tweet_id": "1589999324259176449",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1589999320245612544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999319196741632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999319016701955",
   "Positive": "0.8573065996170044",
   "Negative": "0.00017503801791463047",
   "Neutral": "0.14245305955410004",
   "Mixed": "6.52639937470667e-05"
 },
 {
   "tweet_id": "1589999316709834755",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999299618017280",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999290575114240",
   "Positive": "0.17871147394180298",
   "Negative": "0.025346342474222183",
   "Neutral": "0.7958491444587708",
   "Mixed": "9.307453001383692e-05"
 },
 {
   "tweet_id": "1589999286116286465",
   "Positive": "0.027171459048986435",
   "Negative": "7.448425458278507e-05",
   "Neutral": "0.9726927280426025",
   "Mixed": "6.129992834758013e-05"
 },
 {
   "tweet_id": "1589999279674109953",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999271256129537",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999268622139392",
   "Positive": "0.00326110003516078",
   "Negative": "0.00014594350068364292",
   "Neutral": "0.9965894222259521",
   "Mixed": "3.529046807670966e-06"
 },
 {
   "tweet_id": "1589999250595016704",
   "Positive": "0.008617574349045753",
   "Negative": "0.7306711077690125",
   "Neutral": "0.25977829098701477",
   "Mixed": "0.0009329591994173825"
 },
 {
   "tweet_id": "1589999233952022530",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999232056164352",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589999231854850049",
   "Positive": "0.054217491298913956",
   "Negative": "0.0003286633873358369",
   "Neutral": "0.9452556371688843",
   "Mixed": "0.00019816703570540994"
 },
 {
   "tweet_id": "1589999226502926338",
   "Positive": "0.01270230207592249",
   "Negative": "7.503791130147874e-05",
   "Neutral": "0.9871472120285034",
   "Mixed": "7.542548701167107e-05"
 },
 {
   "tweet_id": "1589999222010810371",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589999207028776962",
   "Positive": "0.0015973987756296992",
   "Negative": "0.8721352219581604",
   "Neutral": "0.12268859148025513",
   "Mixed": "0.0035788225941359997"
 },
 {
   "tweet_id": "1589999206173134848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999181196062720",
   "Positive": "0.0003491459647193551",
   "Negative": "0.9344366788864136",
   "Neutral": "0.06516843289136887",
   "Mixed": "4.581217945087701e-05"
 },
 {
   "tweet_id": "1589999165081542656",
   "Positive": "0.09987271577119827",
   "Negative": "0.0018985066562891006",
   "Neutral": "0.8960914015769958",
   "Mixed": "0.0021374442148953676"
 },
 {
   "tweet_id": "1589999164397879297",
   "Positive": "0.004493436776101589",
   "Negative": "0.0002852951583918184",
   "Neutral": "0.9952174425125122",
   "Mixed": "3.775619688894949e-06"
 },
 {
   "tweet_id": "1589999162929864706",
   "Positive": "0.0026862872764468193",
   "Negative": "8.80511142895557e-05",
   "Neutral": "0.9972134232521057",
   "Mixed": "1.2209463420731481e-05"
 },
 {
   "tweet_id": "1589999161935794184",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999150711869443",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999141475663872",
   "Positive": "0.9758145213127136",
   "Negative": "0.0004105285042896867",
   "Neutral": "0.023720519617199898",
   "Mixed": "5.441687608254142e-05"
 },
 {
   "tweet_id": "1589999134861557761",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589999110631067650",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999109494124544",
   "Positive": "0.0007181200198829174",
   "Negative": "0.0001239195844391361",
   "Neutral": "0.9991540908813477",
   "Mixed": "3.7557979339908343e-06"
 },
 {
   "tweet_id": "1589999107493736449",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589999098740244480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999096504659973",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589999094634012672",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589999089210380288",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1589999077118595072",
   "Positive": "0.04461144655942917",
   "Negative": "0.001081274007447064",
   "Neutral": "0.9542674422264099",
   "Mixed": "3.9859223761595786e-05"
 },
 {
   "tweet_id": "1589999065320009728",
   "Positive": "0.08115919679403305",
   "Negative": "0.002056647324934602",
   "Neutral": "0.9167684316635132",
   "Mixed": "1.5727642676210962e-05"
 },
 {
   "tweet_id": "1589999053953437696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589999043664805892",
   "Positive": "0.0007326011545956135",
   "Negative": "0.613266110420227",
   "Neutral": "0.38533177971839905",
   "Mixed": "0.00066946487640962"
 },
 {
   "tweet_id": "1589999041722470401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589999039227244557",
   "Positive": "0.22686372697353363",
   "Negative": "0.01579960063099861",
   "Neutral": "0.7573014497756958",
   "Mixed": "3.522470433381386e-05"
 },
 {
   "tweet_id": "1589999036647424000",
   "Positive": "0.44695353507995605",
   "Negative": "0.0007089526625350118",
   "Neutral": "0.552290141582489",
   "Mixed": "4.7342749894596636e-05"
 },
 {
   "tweet_id": "1589999018763227137",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589999017081335811",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998999595274245",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998993211559936",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589998984415698946",
   "Positive": "0.3517386317253113",
   "Negative": "0.00033248928957618773",
   "Neutral": "0.6478533148765564",
   "Mixed": "7.563835242763162e-05"
 },
 {
   "tweet_id": "1589998972974006273",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998970507776007",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998963012571136",
   "Positive": "0.004493436776101589",
   "Negative": "0.0002852951583918184",
   "Neutral": "0.9952174425125122",
   "Mixed": "3.775619688894949e-06"
 },
 {
   "tweet_id": "1589998949964079106",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998949796286464",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998949141975040",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998943534215169",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589998938878533632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998924554981379",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589998918863310849",
   "Positive": "0.03322521969676018",
   "Negative": "0.0001486332475906238",
   "Neutral": "0.9666061997413635",
   "Mixed": "1.9941489881603047e-05"
 },
 {
   "tweet_id": "1589998917508554753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998904216780801",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1589998886743343105",
   "Positive": "0.00044622342102229595",
   "Negative": "6.04458327870816e-05",
   "Neutral": "0.9994845390319824",
   "Mixed": "8.812914529698901e-06"
 },
 {
   "tweet_id": "1589998851401797633",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998846733877248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998845953708032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998843286130690",
   "Positive": "0.04950687661767006",
   "Negative": "0.5959110856056213",
   "Neutral": "0.2451653629541397",
   "Mixed": "0.10941667854785919"
 },
 {
   "tweet_id": "1589998840748605440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998832657408000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998811736604672",
   "Positive": "0.0020386178512126207",
   "Negative": "0.0002588460920378566",
   "Neutral": "0.9976962208747864",
   "Mixed": "6.323500656435499e-06"
 },
 {
   "tweet_id": "1589998804115533824",
   "Positive": "0.36065173149108887",
   "Negative": "0.005208255723118782",
   "Neutral": "0.6323476433753967",
   "Mixed": "0.0017923230770975351"
 },
 {
   "tweet_id": "1589998797706645506",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589998795106160642",
   "Positive": "0.0014516259543597698",
   "Negative": "0.8056628704071045",
   "Neutral": "0.19174423813819885",
   "Mixed": "0.001141257118433714"
 },
 {
   "tweet_id": "1589998789242552320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998781126541313",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998777766928385",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1589998775032246273",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998764160212993",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998759878225921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998754203303937",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589998740710232064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998736192655361",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589998727196205061",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998716613586944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998716337160192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998716244885504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998712805552128",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998710955839493",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998703947157506",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998692727418880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998678991056896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998667444129792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998654546661376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998652424355840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998623257161729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998601664880640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998600914104322",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998598829527041",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998591699210241",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998589010661377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998588620591104",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589998588377333763",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589998554696675328",
   "Positive": "0.058940738439559937",
   "Negative": "0.2905842661857605",
   "Neutral": "0.4273785650730133",
   "Mixed": "0.22309640049934387"
 },
 {
   "tweet_id": "1589998554520891392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998550913806337",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589998547461898240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998535176769536",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1589998529740967936",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589998525450182656",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589998514092019713",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589998501005774848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998490981404673",
   "Positive": "0.03630485385656357",
   "Negative": "0.00015239966160152107",
   "Neutral": "0.9635240435600281",
   "Mixed": "1.877822978713084e-05"
 },
 {
   "tweet_id": "1589998481984598016",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998478842699776",
   "Positive": "0.014529195614159107",
   "Negative": "0.00010285429016221315",
   "Neutral": "0.9853496551513672",
   "Mixed": "1.8321905372431502e-05"
 },
 {
   "tweet_id": "1589998475667976192",
   "Positive": "0.45486995577812195",
   "Negative": "0.1999630182981491",
   "Neutral": "0.34449127316474915",
   "Mixed": "0.0006757857045158744"
 },
 {
   "tweet_id": "1589998474392895488",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998470718685186",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998460069347328",
   "Positive": "0.13697963953018188",
   "Negative": "0.09788331389427185",
   "Neutral": "0.7648040652275085",
   "Mixed": "0.0003329578903503716"
 },
 {
   "tweet_id": "1589998459926777856",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998457137541121",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1589998456357388290",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589998456026075136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998447868141572",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589998447603556352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998445196374018",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998443875139587",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589998434769305600",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1589998428280737792",
   "Positive": "0.21159029006958008",
   "Negative": "0.000451667292509228",
   "Neutral": "0.7879104018211365",
   "Mixed": "4.766209895024076e-05"
 },
 {
   "tweet_id": "1589998419258789888",
   "Positive": "0.04304320737719536",
   "Negative": "0.0015482499729841948",
   "Neutral": "0.9553071856498718",
   "Mixed": "0.00010140002996195108"
 },
 {
   "tweet_id": "1589998418134724608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998411335761920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998404234809345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998396982820866",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998393455443972",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589998390879825920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998389886078976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998387726004227",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1589998385750495232",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1589998379702325249",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998377290584064",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998377059901440",
   "Positive": "0.031201310455799103",
   "Negative": "6.504825432784855e-05",
   "Neutral": "0.9685039520263672",
   "Mixed": "0.00022963817173149437"
 },
 {
   "tweet_id": "1589998373742182401",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589998336274485249",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589998323746082817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998311171584000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998308336230401",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998304808820738",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998286630711297",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589998273225687040",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589998268557459459",
   "Positive": "0.012339561246335506",
   "Negative": "0.06214273348450661",
   "Neutral": "0.9254393577575684",
   "Mixed": "7.828533125575632e-05"
 },
 {
   "tweet_id": "1589998266917171205",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589998265713692673",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589998246096928769",
   "Positive": "0.0008377031772397459",
   "Negative": "0.012397210113704205",
   "Neutral": "0.9867568016052246",
   "Mixed": "8.184240869013593e-06"
 },
 {
   "tweet_id": "1589998243517431808",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589998236206780416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998232553533443",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998231865667586",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998211766587393",
   "Positive": "0.02905414067208767",
   "Negative": "7.523244858020917e-05",
   "Neutral": "0.9706341028213501",
   "Mixed": "0.0002364968677284196"
 },
 {
   "tweet_id": "1589998199980556288",
   "Positive": "0.06360246986150742",
   "Negative": "0.0006673376192338765",
   "Neutral": "0.9356966018676758",
   "Mixed": "3.359633046784438e-05"
 },
 {
   "tweet_id": "1589998195857588225",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998189008257024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998180909076482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998175448109058",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998167910912001",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998164958146560",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589998160805785601",
   "Positive": "0.0024501171428710222",
   "Negative": "0.00011964392615482211",
   "Neutral": "0.9974247217178345",
   "Mixed": "5.641549705615034e-06"
 },
 {
   "tweet_id": "1589998160390545408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998133995790336",
   "Positive": "0.01647050864994526",
   "Negative": "0.0025596278719604015",
   "Neutral": "0.980962872505188",
   "Mixed": "7.07419576428947e-06"
 },
 {
   "tweet_id": "1589998126999384064",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1589998123317096448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998113825038338",
   "Positive": "0.002763723023235798",
   "Negative": "0.7600032687187195",
   "Neutral": "0.23413687944412231",
   "Mixed": "0.003096160478889942"
 },
 {
   "tweet_id": "1589998113326247936",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1589998107768811521",
   "Positive": "0.0011625580955296755",
   "Negative": "0.00011999811249552295",
   "Neutral": "0.9987130165100098",
   "Mixed": "4.384143721836153e-06"
 },
 {
   "tweet_id": "1589998102949548034",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589998096108650497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998085744529410",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998085500903424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998085207621632",
   "Positive": "0.03242391347885132",
   "Negative": "6.506987847387791e-05",
   "Neutral": "0.9673256278038025",
   "Mixed": "0.00018538451695349067"
 },
 {
   "tweet_id": "1589998085081804800",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998084268109826",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589998081831231490",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998077569646596",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589998074222751749",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998070078771200",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998067310563328",
   "Positive": "0.0008271976257674396",
   "Negative": "4.277864354662597e-05",
   "Neutral": "0.9991187453269958",
   "Mixed": "1.129123575083213e-05"
 },
 {
   "tweet_id": "1589998065402138626",
   "Positive": "0.321208119392395",
   "Negative": "0.008153717033565044",
   "Neutral": "0.6697403788566589",
   "Mixed": "0.0008977992692962289"
 },
 {
   "tweet_id": "1589998045340798976",
   "Positive": "0.03367936983704567",
   "Negative": "6.471870437962934e-05",
   "Neutral": "0.9660512208938599",
   "Mixed": "0.00020471752213779837"
 },
 {
   "tweet_id": "1589998043562401792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998034494328833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998031247937538",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589998029112999937",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998025514291204",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589998022691520513",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589998021739442177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998016723062784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589998015485743105",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589997997865435139",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997987190538241",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1589997978877825024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997967272206337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997965569323015",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997962373238785",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589997957356851200",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1589997956949688321",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997950293676032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997948288782337",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997943301754880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997938180513794",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997936884461570",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997933856194561",
   "Positive": "0.06939531862735748",
   "Negative": "0.008436555974185467",
   "Neutral": "0.9221188426017761",
   "Mixed": "4.9309655878460035e-05"
 },
 {
   "tweet_id": "1589997932740489216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997931221786624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997931116912647",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997928340459520",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997906882613249",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997904793862147",
   "Positive": "0.043178580701351166",
   "Negative": "0.023614894598722458",
   "Neutral": "0.9331925511360168",
   "Mixed": "1.3855410543328617e-05"
 },
 {
   "tweet_id": "1589997903023837185",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997895331508227",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997887748214786",
   "Positive": "0.028372224420309067",
   "Negative": "6.594842852791771e-05",
   "Neutral": "0.9713864922523499",
   "Mixed": "0.00017534565995447338"
 },
 {
   "tweet_id": "1589997885747499008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997885386809344",
   "Positive": "0.04297930374741554",
   "Negative": "0.6720666289329529",
   "Neutral": "0.2681051790714264",
   "Mixed": "0.016848815605044365"
 },
 {
   "tweet_id": "1589997881469329408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997872648704001",
   "Positive": "0.0034734238870441914",
   "Negative": "9.334873175248504e-05",
   "Neutral": "0.9964271187782288",
   "Mixed": "6.061271506041521e-06"
 },
 {
   "tweet_id": "1589997872531279881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997865413509120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997861059858434",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589997856379002881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997848636329986",
   "Positive": "0.027059132233262062",
   "Negative": "6.243178359000012e-05",
   "Neutral": "0.9727014899253845",
   "Mixed": "0.00017699864110909402"
 },
 {
   "tweet_id": "1589997845297655808",
   "Positive": "0.4158588647842407",
   "Negative": "0.0005788631387986243",
   "Neutral": "0.5785483121871948",
   "Mixed": "0.005013932473957539"
 },
 {
   "tweet_id": "1589997842311315456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997839291387904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997837034876931",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997822127939584",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997804625465344",
   "Positive": "0.9815858602523804",
   "Negative": "0.00012710539158433676",
   "Neutral": "0.018247241154313087",
   "Mixed": "3.9848680899012834e-05"
 },
 {
   "tweet_id": "1589997801471356928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997797021200385",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997791753179137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997786535464962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997786048888832",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997770831953921",
   "Positive": "0.04155001789331436",
   "Negative": "0.18727976083755493",
   "Neutral": "0.13320909440517426",
   "Mixed": "0.6379610300064087"
 },
 {
   "tweet_id": "1589997742881140741",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589997734379294721",
   "Positive": "0.06337976455688477",
   "Negative": "0.0628952756524086",
   "Neutral": "0.22902990877628326",
   "Mixed": "0.6446950435638428"
 },
 {
   "tweet_id": "1589997730143039489",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997730134642690",
   "Positive": "0.3221256136894226",
   "Negative": "0.011959424242377281",
   "Neutral": "0.6656967997550964",
   "Mixed": "0.0002181865565944463"
 },
 {
   "tweet_id": "1589997727248977921",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997717186805760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997716884848641",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997711260282882",
   "Positive": "0.006758633069694042",
   "Negative": "0.8768466114997864",
   "Neutral": "0.11532797664403915",
   "Mixed": "0.0010668901959434152"
 },
 {
   "tweet_id": "1589997707326001153",
   "Positive": "0.1822858452796936",
   "Negative": "0.021324455738067627",
   "Neutral": "0.6898128986358643",
   "Mixed": "0.10657677799463272"
 },
 {
   "tweet_id": "1589997704360304640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997700862328832",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997688493600768",
   "Positive": "0.026273440569639206",
   "Negative": "6.106343062128872e-05",
   "Neutral": "0.9734932780265808",
   "Mixed": "0.00017221359303221107"
 },
 {
   "tweet_id": "1589997682625753088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997676103622656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997642486255618",
   "Positive": "0.390741765499115",
   "Negative": "0.0003447318740654737",
   "Neutral": "0.6088664531707764",
   "Mixed": "4.6926852519391105e-05"
 },
 {
   "tweet_id": "1589997623574167553",
   "Positive": "0.03215279430150986",
   "Negative": "0.6481311321258545",
   "Neutral": "0.3076964318752289",
   "Mixed": "0.012019695714116096"
 },
 {
   "tweet_id": "1589997621388902403",
   "Positive": "0.00495251314714551",
   "Negative": "0.17235057055950165",
   "Neutral": "0.8205606341362",
   "Mixed": "0.0021362388506531715"
 },
 {
   "tweet_id": "1589997611359928321",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997610290802694",
   "Positive": "0.7138240933418274",
   "Negative": "0.007751686964184046",
   "Neutral": "0.27823808789253235",
   "Mixed": "0.00018609504331834614"
 },
 {
   "tweet_id": "1589997603949010944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997598106320898",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997596399259648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997593903640576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997589385998336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997588522340352",
   "Positive": "0.0285941231995821",
   "Negative": "6.224102253327146e-05",
   "Neutral": "0.9711518883705139",
   "Mixed": "0.00019165861885994673"
 },
 {
   "tweet_id": "1589997576908312576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997569463439363",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997567500488705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997561750130688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997540208173056",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589997538895360002",
   "Positive": "0.028733456507325172",
   "Negative": "6.603805377380922e-05",
   "Neutral": "0.9711716771125793",
   "Mixed": "2.877822407754138e-05"
 },
 {
   "tweet_id": "1589997528749064192",
   "Positive": "0.04005102440714836",
   "Negative": "0.12469569593667984",
   "Neutral": "0.8352112770080566",
   "Mixed": "4.20631913584657e-05"
 },
 {
   "tweet_id": "1589997524211077120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997522474655745",
   "Positive": "0.029579095542430878",
   "Negative": "6.864647730253637e-05",
   "Neutral": "0.9701667428016663",
   "Mixed": "0.00018553153495304286"
 },
 {
   "tweet_id": "1589997508977381376",
   "Positive": "0.01270230207592249",
   "Negative": "7.503791130147874e-05",
   "Neutral": "0.9871472120285034",
   "Mixed": "7.542548701167107e-05"
 },
 {
   "tweet_id": "1589997504862781442",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997495613939713",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997494104358913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997485975830528",
   "Positive": "0.07436459511518478",
   "Negative": "0.036462754011154175",
   "Neutral": "0.8891536593437195",
   "Mixed": "1.8972687030327506e-05"
 },
 {
   "tweet_id": "1589997483979333634",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997482230312961",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1589997467185352704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997461405577217",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589997458431410177",
   "Positive": "0.003676641033962369",
   "Negative": "0.41997480392456055",
   "Neutral": "0.5755025744438171",
   "Mixed": "0.000846056907903403"
 },
 {
   "tweet_id": "1589997455583563776",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997455428685827",
   "Positive": "0.011111925356090069",
   "Negative": "0.04162569344043732",
   "Neutral": "0.9472509622573853",
   "Mixed": "1.1392638043616898e-05"
 },
 {
   "tweet_id": "1589997447149154305",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589997445345251328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997442145009666",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997432255172609",
   "Positive": "0.002571385819464922",
   "Negative": "0.00021391328482422978",
   "Neutral": "0.9972009658813477",
   "Mixed": "1.384731694997754e-05"
 },
 {
   "tweet_id": "1589997432170946560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997427200651264",
   "Positive": "0.11063192039728165",
   "Negative": "0.00018433522200211883",
   "Neutral": "0.8891600370407104",
   "Mixed": "2.3606678951182403e-05"
 },
 {
   "tweet_id": "1589997426718683136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997411212365824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997404501463041",
   "Positive": "0.3097372353076935",
   "Negative": "0.037589963525533676",
   "Neutral": "0.6483902335166931",
   "Mixed": "0.004282529000192881"
 },
 {
   "tweet_id": "1589997403780022275",
   "Positive": "0.027266833931207657",
   "Negative": "6.883528840262443e-05",
   "Neutral": "0.9725271463394165",
   "Mixed": "0.0001372024416923523"
 },
 {
   "tweet_id": "1589997401376722944",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589997388168835073",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997381629935618",
   "Positive": "0.0013113678433001041",
   "Negative": "0.0002774455933831632",
   "Neutral": "0.9984089732170105",
   "Mixed": "2.2888461899128743e-06"
 },
 {
   "tweet_id": "1589997373228740608",
   "Positive": "0.04005102440714836",
   "Negative": "0.12469569593667984",
   "Neutral": "0.8352112770080566",
   "Mixed": "4.20631913584657e-05"
 },
 {
   "tweet_id": "1589997373014409218",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997369013448704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997365813207040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997359404318722",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997344858476546",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997340328611841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997339594428417",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997337669414912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997335693914115",
   "Positive": "0.02869071066379547",
   "Negative": "6.329746975097805e-05",
   "Neutral": "0.9710784554481506",
   "Mixed": "0.00016745862376410514"
 },
 {
   "tweet_id": "1589997330039984129",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589997328265465856",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997315682897920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997306002444288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997302617636865",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589997298141908992",
   "Positive": "0.004676715936511755",
   "Negative": "0.1171589344739914",
   "Neutral": "0.8780902028083801",
   "Mixed": "7.421868940582499e-05"
 },
 {
   "tweet_id": "1589997294107398145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997285328330754",
   "Positive": "0.0897199958562851",
   "Negative": "0.004288620315492153",
   "Neutral": "0.9059592485427856",
   "Mixed": "3.213538366253488e-05"
 },
 {
   "tweet_id": "1589997269872693248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997251963023362",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997246124204032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997243515293702",
   "Positive": "0.10730387270450592",
   "Negative": "0.0012753107585012913",
   "Neutral": "0.891355037689209",
   "Mixed": "6.579732871614397e-05"
 },
 {
   "tweet_id": "1589997232539197440",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589997226813784070",
   "Positive": "0.7371131181716919",
   "Negative": "0.010820001363754272",
   "Neutral": "0.25151288509368896",
   "Mixed": "0.0005541042191907763"
 },
 {
   "tweet_id": "1589997218714779649",
   "Positive": "0.03108394518494606",
   "Negative": "6.591944838874042e-05",
   "Neutral": "0.9686667919158936",
   "Mixed": "0.00018342628027312458"
 },
 {
   "tweet_id": "1589997217703923714",
   "Positive": "0.007188246585428715",
   "Negative": "0.0001239153352798894",
   "Neutral": "0.9926831126213074",
   "Mixed": "4.674334832088789e-06"
 },
 {
   "tweet_id": "1589997191443419137",
   "Positive": "0.001625546021386981",
   "Negative": "0.8819766640663147",
   "Neutral": "0.11020829528570175",
   "Mixed": "0.006189427804201841"
 },
 {
   "tweet_id": "1589997183759417344",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997173403398145",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1589997162741764099",
   "Positive": "0.02704598940908909",
   "Negative": "6.476215639850125e-05",
   "Neutral": "0.972748875617981",
   "Mixed": "0.00014032880426384509"
 },
 {
   "tweet_id": "1589997156613910528",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589997152516075521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997148422037504",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589997142210641921",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997131552952320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997129941934082",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997129107636225",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997124728803329",
   "Positive": "0.011111925356090069",
   "Negative": "0.04162569344043732",
   "Neutral": "0.9472509622573853",
   "Mixed": "1.1392638043616898e-05"
 },
 {
   "tweet_id": "1589997108039675906",
   "Positive": "0.02771698497235775",
   "Negative": "6.282146932790056e-05",
   "Neutral": "0.9720391035079956",
   "Mixed": "0.00018108473159372807"
 },
 {
   "tweet_id": "1589997102998126593",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997095318355968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997082039177216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997080650874881",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589997060283338752",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589997053899591680",
   "Positive": "0.029665399342775345",
   "Negative": "6.875531835248694e-05",
   "Neutral": "0.9701105356216431",
   "Mixed": "0.00015533041732851416"
 },
 {
   "tweet_id": "1589997038967853056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589997036065017859",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1589997022819778561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589997022584922112",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589997020936536067",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589997018834796544",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589997018780676096",
   "Positive": "0.9901846647262573",
   "Negative": "4.136893039685674e-05",
   "Neutral": "0.009739532135426998",
   "Mixed": "3.449936775723472e-05"
 },
 {
   "tweet_id": "1589997009897164800",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589997005816094720",
   "Positive": "0.029922515153884888",
   "Negative": "6.837253022240475e-05",
   "Neutral": "0.9698458313941956",
   "Mixed": "0.00016328692436218262"
 },
 {
   "tweet_id": "1589997000317026306",
   "Positive": "0.0007615585927851498",
   "Negative": "0.00029799353796988726",
   "Neutral": "0.9989381432533264",
   "Mixed": "2.203177245974075e-06"
 },
 {
   "tweet_id": "1589996978179489796",
   "Positive": "0.19768553972244263",
   "Negative": "0.01674654707312584",
   "Neutral": "0.7854538559913635",
   "Mixed": "0.00011404429824324325"
 },
 {
   "tweet_id": "1589996977780948993",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996977768792065",
   "Positive": "0.0022925613448023796",
   "Negative": "0.8661629557609558",
   "Neutral": "0.13133026659488678",
   "Mixed": "0.00021417546668089926"
 },
 {
   "tweet_id": "1589996972127453185",
   "Positive": "0.0020060439128428698",
   "Negative": "8.96806304808706e-05",
   "Neutral": "0.9978984594345093",
   "Mixed": "5.859729299118044e-06"
 },
 {
   "tweet_id": "1589996960026877952",
   "Positive": "0.028108619153499603",
   "Negative": "6.7418601247482e-05",
   "Neutral": "0.9716652631759644",
   "Mixed": "0.0001586893486091867"
 },
 {
   "tweet_id": "1589996952217071616",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589996950472241152",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589996949960527872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996927717765122",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996924744372224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996911427080192",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996904049692674",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996892708286466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996886668500994",
   "Positive": "0.002038764301687479",
   "Negative": "0.00013761257287114859",
   "Neutral": "0.9978158473968506",
   "Mixed": "7.723117050772998e-06"
 },
 {
   "tweet_id": "1589996874463084545",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996873871667201",
   "Positive": "0.0052769118919968605",
   "Negative": "0.6105647683143616",
   "Neutral": "0.384055495262146",
   "Mixed": "0.0001028531914926134"
 },
 {
   "tweet_id": "1589996872504324096",
   "Positive": "0.028634048998355865",
   "Negative": "6.082080290070735e-05",
   "Neutral": "0.9710891842842102",
   "Mixed": "0.00021602118795271963"
 },
 {
   "tweet_id": "1589996861783695362",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996854707884033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996853600423939",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996813528223744",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589996792653189121",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996786374316032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996785669476353",
   "Positive": "0.043178580701351166",
   "Negative": "0.023614894598722458",
   "Neutral": "0.9331925511360168",
   "Mixed": "1.3855410543328617e-05"
 },
 {
   "tweet_id": "1589996777729843201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996776605462529",
   "Positive": "0.001201304723508656",
   "Negative": "0.00010929359268629923",
   "Neutral": "0.99868243932724",
   "Mixed": "6.875461622257717e-06"
 },
 {
   "tweet_id": "1589996774756085760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996774470520833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996767101480960",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996763062374400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996756921901057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996754325639170",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996750139707393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996748843667460",
   "Positive": "0.18304631114006042",
   "Negative": "0.1260043829679489",
   "Neutral": "0.676079273223877",
   "Mixed": "0.014870035462081432"
 },
 {
   "tweet_id": "1589996742661263362",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996739167391745",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589996729830887425",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996729298194438",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996726311845888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996722255958018",
   "Positive": "0.027212345972657204",
   "Negative": "7.12697219569236e-05",
   "Neutral": "0.9725748896598816",
   "Mixed": "0.0001415509032085538"
 },
 {
   "tweet_id": "1589996721215795202",
   "Positive": "0.9324617981910706",
   "Negative": "0.0005782325752079487",
   "Neutral": "0.0661347433924675",
   "Mixed": "0.0008252558181993663"
 },
 {
   "tweet_id": "1589996715779948546",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589996712093188097",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996710964887552",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996705831084032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996702982733824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996687913005059",
   "Positive": "0.0026507163420319557",
   "Negative": "0.17905621230602264",
   "Neutral": "0.8158484697341919",
   "Mixed": "0.0024445827584713697"
 },
 {
   "tweet_id": "1589996683030822916",
   "Positive": "0.0009055399568751454",
   "Negative": "0.08045921474695206",
   "Neutral": "0.9186137318611145",
   "Mixed": "2.1490262952283956e-05"
 },
 {
   "tweet_id": "1589996666828238850",
   "Positive": "0.8798246383666992",
   "Negative": "0.0008300484041683376",
   "Neutral": "0.0918666422367096",
   "Mixed": "0.027478627860546112"
 },
 {
   "tweet_id": "1589996666786320385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996665166958593",
   "Positive": "0.0587557815015316",
   "Negative": "0.013305388391017914",
   "Neutral": "0.9279260039329529",
   "Mixed": "1.2884921488875989e-05"
 },
 {
   "tweet_id": "1589996664278126595",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996655675609089",
   "Positive": "0.030063025653362274",
   "Negative": "5.3450912673724815e-05",
   "Neutral": "0.9694628715515137",
   "Mixed": "0.0004206301528029144"
 },
 {
   "tweet_id": "1589996649270874114",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996630610415617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996628080926720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996612281315330",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996608888147968",
   "Positive": "0.7464601993560791",
   "Negative": "0.008250327780842781",
   "Neutral": "0.24486733973026276",
   "Mixed": "0.0004221171548124403"
 },
 {
   "tweet_id": "1589996601250304000",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589996591985102850",
   "Positive": "0.02329045906662941",
   "Negative": "5.581442383117974e-05",
   "Neutral": "0.9764659404754639",
   "Mixed": "0.0001877464965218678"
 },
 {
   "tweet_id": "1589996591355600901",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996583994941442",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996583403548672",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589996575539212288",
   "Positive": "0.2871193587779999",
   "Negative": "0.12126153707504272",
   "Neutral": "0.5898480415344238",
   "Mixed": "0.0017710794927552342"
 },
 {
   "tweet_id": "1589996573622439937",
   "Positive": "0.03544049710035324",
   "Negative": "0.00470077246427536",
   "Neutral": "0.9598401188850403",
   "Mixed": "1.86971046787221e-05"
 },
 {
   "tweet_id": "1589996571806281728",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996555494625282",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589996554529935363",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1589996553426841603",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996551568785413",
   "Positive": "0.004749205894768238",
   "Negative": "0.00031182088423520327",
   "Neutral": "0.9949140548706055",
   "Mixed": "2.494430373189971e-05"
 },
 {
   "tweet_id": "1589996539715686400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996538113437696",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996537744166912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996531028922368",
   "Positive": "0.14013968408107758",
   "Negative": "0.03557169809937477",
   "Neutral": "0.824155330657959",
   "Mixed": "0.0001332927349722013"
 },
 {
   "tweet_id": "1589996525933166593",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996516525375488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996514709237760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996506782007297",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996495813545984",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589996488960413696",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996485432684544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996483289710592",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589996482174005248",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996478243934209",
   "Positive": "0.02939344383776188",
   "Negative": "6.780070543754846e-05",
   "Neutral": "0.9703542590141296",
   "Mixed": "0.00018445424211677164"
 },
 {
   "tweet_id": "1589996475672858626",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996474913259520",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996471134609408",
   "Positive": "0.1061411201953888",
   "Negative": "0.5997077822685242",
   "Neutral": "0.2881547808647156",
   "Mixed": "0.005996328312903643"
 },
 {
   "tweet_id": "1589996467581726720",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996465518440451",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996464205606913",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996460602380289",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996459122126848",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589996456089640960",
   "Positive": "0.06529293209314346",
   "Negative": "0.08249688148498535",
   "Neutral": "0.8521766662597656",
   "Mixed": "3.3527514460729435e-05"
 },
 {
   "tweet_id": "1589996453434654726",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589996452075700225",
   "Positive": "0.13847342133522034",
   "Negative": "0.0003353622159920633",
   "Neutral": "0.8608081340789795",
   "Mixed": "0.00038303056498989463"
 },
 {
   "tweet_id": "1589996433544949760",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589996429564858369",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589996414922547200",
   "Positive": "0.03258368745446205",
   "Negative": "7.033949077595025e-05",
   "Neutral": "0.9671820402145386",
   "Mixed": "0.00016396243881899863"
 },
 {
   "tweet_id": "1589996411307053056",
   "Positive": "0.9386336207389832",
   "Negative": "0.0003173385339323431",
   "Neutral": "0.06102779135107994",
   "Mixed": "2.1238023691694252e-05"
 },
 {
   "tweet_id": "1589996394051670016",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589996393460281344",
   "Positive": "0.011900357902050018",
   "Negative": "0.0003245101834181696",
   "Neutral": "0.987712562084198",
   "Mixed": "6.267717253649607e-05"
 },
 {
   "tweet_id": "1589996378113339394",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996373730267139",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996370185682945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996364758282241",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996364368252928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996360841187328",
   "Positive": "0.02368908002972603",
   "Negative": "6.54097311780788e-05",
   "Neutral": "0.9760271906852722",
   "Mixed": "0.00021836350788362324"
 },
 {
   "tweet_id": "1589996352419041287",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589996343569022976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996338389086208",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589996337869000705",
   "Positive": "0.0004870674747508019",
   "Negative": "0.00036071555223315954",
   "Neutral": "0.9991480112075806",
   "Mixed": "4.1445250644756015e-06"
 },
 {
   "tweet_id": "1589996323188912128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996319632150531",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996313869189120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996312673800192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589996295376490499",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996282097315842",
   "Positive": "0.0003849842760246247",
   "Negative": "0.0009352772030979395",
   "Neutral": "0.9986728429794312",
   "Mixed": "6.914961886650417e-06"
 },
 {
   "tweet_id": "1589996275625529344",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996271930310656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996269984190465",
   "Positive": "0.2485465109348297",
   "Negative": "0.0009007803164422512",
   "Neutral": "0.7503501176834106",
   "Mixed": "0.0002026399306487292"
 },
 {
   "tweet_id": "1589996260517621760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996255266021376",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1589996254293274626",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996249021034498",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996247326523392",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589996244100739072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996243094507520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996229978902530",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996216192241666",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996211637211142",
   "Positive": "0.12708905339241028",
   "Negative": "0.00028005242347717285",
   "Neutral": "0.8726129531860352",
   "Mixed": "1.7985707017942332e-05"
 },
 {
   "tweet_id": "1589996204385251330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996199905722370",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996199846629377",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996182784598017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996177839493120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996170994221056",
   "Positive": "0.012870825827121735",
   "Negative": "0.12312252819538116",
   "Neutral": "0.8638839721679688",
   "Mixed": "0.00012261953088454902"
 },
 {
   "tweet_id": "1589996170755342342",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996164937846786",
   "Positive": "0.034428130835294724",
   "Negative": "6.728041626047343e-05",
   "Neutral": "0.9653352499008179",
   "Mixed": "0.00016923605289775878"
 },
 {
   "tweet_id": "1589996163201003520",
   "Positive": "0.0007357620052061975",
   "Negative": "0.00016445943037979305",
   "Neutral": "0.9990963935852051",
   "Mixed": "3.4497875276429113e-06"
 },
 {
   "tweet_id": "1589996163138215936",
   "Positive": "0.5150303840637207",
   "Negative": "0.0008273771381936967",
   "Neutral": "0.4841064214706421",
   "Mixed": "3.584877049433999e-05"
 },
 {
   "tweet_id": "1589996160760311813",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589996156603760641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996138618552321",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589996128090853376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996120431656961",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996114966564865",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996099636695043",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589996095975100416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996091264888833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996080019607552",
   "Positive": "0.0897199958562851",
   "Negative": "0.004288620315492153",
   "Neutral": "0.9059592485427856",
   "Mixed": "3.213538366253488e-05"
 },
 {
   "tweet_id": "1589996068431081475",
   "Positive": "0.04651155322790146",
   "Negative": "0.013929630629718304",
   "Neutral": "0.9395164847373962",
   "Mixed": "4.236202221363783e-05"
 },
 {
   "tweet_id": "1589996056364056576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996039171641349",
   "Positive": "0.12199428677558899",
   "Negative": "0.04142018035054207",
   "Neutral": "0.8364637494087219",
   "Mixed": "0.00012175628216937184"
 },
 {
   "tweet_id": "1589996038915764227",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996028102836224",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589996026043461633",
   "Positive": "0.04094682261347771",
   "Negative": "7.23022167221643e-05",
   "Neutral": "0.9581494331359863",
   "Mixed": "0.0008314501028507948"
 },
 {
   "tweet_id": "1589996025779224576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589996023539470341",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589996019114467329",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1589996017176694786",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589996015960354818",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995997966798850",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995979155341312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995976408076290",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995975556620289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995968925437960",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589995965871947776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995965020504064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995963355394048",
   "Positive": "0.044986434280872345",
   "Negative": "5.6801749451551586e-05",
   "Neutral": "0.9544199705123901",
   "Mixed": "0.0005367946578189731"
 },
 {
   "tweet_id": "1589995963242131463",
   "Positive": "0.03327446058392525",
   "Negative": "0.05365248769521713",
   "Neutral": "0.9128240942955017",
   "Mixed": "0.00024894284433685243"
 },
 {
   "tweet_id": "1589995961753161728",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995954153095174",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589995940634832897",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995931750961152",
   "Positive": "0.18949587643146515",
   "Negative": "0.001081918366253376",
   "Neutral": "0.8091916441917419",
   "Mixed": "0.0002305354573763907"
 },
 {
   "tweet_id": "1589995930358796290",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995926277742592",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589995918530838528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995896866938880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995896452046849",
   "Positive": "0.03096938133239746",
   "Negative": "5.962455543340184e-05",
   "Neutral": "0.968761682510376",
   "Mixed": "0.00020937048248015344"
 },
 {
   "tweet_id": "1589995894669074433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995875677634562",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995860712361984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995856727531522",
   "Positive": "0.16838152706623077",
   "Negative": "0.12872891128063202",
   "Neutral": "0.7027896046638489",
   "Mixed": "9.996267181122676e-05"
 },
 {
   "tweet_id": "1589995836741910530",
   "Positive": "0.0026703402400016785",
   "Negative": "0.8489999175071716",
   "Neutral": "0.14823196828365326",
   "Mixed": "9.780570690054446e-05"
 },
 {
   "tweet_id": "1589995834237939712",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995812553379845",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995812104589312",
   "Positive": "0.048898596316576004",
   "Negative": "0.0013766777701675892",
   "Neutral": "0.9497112035751343",
   "Mixed": "1.3602095350506715e-05"
 },
 {
   "tweet_id": "1589995795230916609",
   "Positive": "0.0289683248847723",
   "Negative": "5.212841278989799e-05",
   "Neutral": "0.970565915107727",
   "Mixed": "0.00041364249773323536"
 },
 {
   "tweet_id": "1589995795188617217",
   "Positive": "0.23335951566696167",
   "Negative": "0.008563270792365074",
   "Neutral": "0.7579500079154968",
   "Mixed": "0.00012719252845272422"
 },
 {
   "tweet_id": "1589995781838495745",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995779217051651",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995776683708416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995771155599360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995766449205249",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995759839379456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995758916620288",
   "Positive": "0.7629749178886414",
   "Negative": "0.12417584657669067",
   "Neutral": "0.11230378597974777",
   "Mixed": "0.0005454677739180624"
 },
 {
   "tweet_id": "1589995756479729665",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995739337588736",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1589995737257234432",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995735080398849",
   "Positive": "0.024735741317272186",
   "Negative": "5.115897874929942e-05",
   "Neutral": "0.9749705791473389",
   "Mixed": "0.00024244865926448256"
 },
 {
   "tweet_id": "1589995728138801154",
   "Positive": "0.012339561246335506",
   "Negative": "0.06214273348450661",
   "Neutral": "0.9254393577575684",
   "Mixed": "7.828533125575632e-05"
 },
 {
   "tweet_id": "1589995721549565953",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589995716239568896",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589995708584988675",
   "Positive": "0.011111925356090069",
   "Negative": "0.04162569344043732",
   "Neutral": "0.9472509622573853",
   "Mixed": "1.1392638043616898e-05"
 },
 {
   "tweet_id": "1589995695154814976",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589995693221240833",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995688024473600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995686917214210",
   "Positive": "0.207356259226799",
   "Negative": "0.0019348987843841314",
   "Neutral": "0.7906830310821533",
   "Mixed": "2.5840248781605624e-05"
 },
 {
   "tweet_id": "1589995661893996545",
   "Positive": "0.043084025382995605",
   "Negative": "5.552719812840223e-05",
   "Neutral": "0.9565553665161133",
   "Mixed": "0.00030508669442497194"
 },
 {
   "tweet_id": "1589995660564377601",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995656546258945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995646576390144",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589995643594211331",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995641249615872",
   "Positive": "0.016022969037294388",
   "Negative": "0.0015680744545534253",
   "Neutral": "0.9822675585746765",
   "Mixed": "0.00014144448505248874"
 },
 {
   "tweet_id": "1589995638875254784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995638212943872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995630671572992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995630344429569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995628712857602",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995628612182018",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995624879230976",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995615597268993",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995604746596354",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995603228266500",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995599717244928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995597381406722",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995596487983106",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995596244750338",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589995579765305344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995556012969986",
   "Positive": "0.10730387270450592",
   "Negative": "0.0012753107585012913",
   "Neutral": "0.891355037689209",
   "Mixed": "6.579732871614397e-05"
 },
 {
   "tweet_id": "1589995538468208640",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589995528191762433",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995527089029120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995522487906306",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995515860910081",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1589995508013334528",
   "Positive": "0.0018317741341888905",
   "Negative": "0.042987775057554245",
   "Neutral": "0.9547736644744873",
   "Mixed": "0.0004068410489708185"
 },
 {
   "tweet_id": "1589995486697902080",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995483132440576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995480968474624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995479709777920",
   "Positive": "0.01790364645421505",
   "Negative": "0.005377186927944422",
   "Neutral": "0.9765880107879639",
   "Mixed": "0.0001311005762545392"
 },
 {
   "tweet_id": "1589995468150693891",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995466523279362",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589995457673310208",
   "Positive": "0.000549126707483083",
   "Negative": "0.0008480358519591391",
   "Neutral": "0.9985541701316833",
   "Mixed": "4.867673123953864e-05"
 },
 {
   "tweet_id": "1589995452837285888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995445908303873",
   "Positive": "0.08926592767238617",
   "Negative": "0.7178150415420532",
   "Neutral": "0.17370983958244324",
   "Mixed": "0.019209176301956177"
 },
 {
   "tweet_id": "1589995445635670016",
   "Positive": "0.2512972354888916",
   "Negative": "0.0007730101351626217",
   "Neutral": "0.7477096319198608",
   "Mixed": "0.0002201880415668711"
 },
 {
   "tweet_id": "1589995431630868480",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589995424835739651",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995421908496384",
   "Positive": "0.00043631173321045935",
   "Negative": "0.00010577560897218063",
   "Neutral": "0.9994521737098694",
   "Mixed": "5.753808181907516e-06"
 },
 {
   "tweet_id": "1589995406330503168",
   "Positive": "0.0016872634878382087",
   "Negative": "0.0007296691183000803",
   "Neutral": "0.997484564781189",
   "Mixed": "9.846099419519305e-05"
 },
 {
   "tweet_id": "1589995402488520704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995400882454528",
   "Positive": "0.02948061004281044",
   "Negative": "0.0005016548093408346",
   "Neutral": "0.9700098037719727",
   "Mixed": "7.936138899822254e-06"
 },
 {
   "tweet_id": "1589995397971607554",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995394204766208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995393332707328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995390954528770",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995388815015937",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589995381701881856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995380493938688",
   "Positive": "0.00015843971050344408",
   "Negative": "0.000490302627440542",
   "Neutral": "0.9993459582328796",
   "Mixed": "5.22445043316111e-06"
 },
 {
   "tweet_id": "1589995380279648257",
   "Positive": "0.6754076480865479",
   "Negative": "0.004977104719728231",
   "Neutral": "0.3181319832801819",
   "Mixed": "0.0014833015156909823"
 },
 {
   "tweet_id": "1589995369269989377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995365486694400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995362814930944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995358217990145",
   "Positive": "0.7434786558151245",
   "Negative": "0.00017217622371390462",
   "Neutral": "0.25623562932014465",
   "Mixed": "0.00011349004489602521"
 },
 {
   "tweet_id": "1589995355151925249",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995348038033408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995343982497793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995336420196352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995331093426181",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995316652412935",
   "Positive": "0.14877383410930634",
   "Negative": "0.07122933864593506",
   "Neutral": "0.7723739147186279",
   "Mixed": "0.007622907869517803"
 },
 {
   "tweet_id": "1589995295609610243",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995295110500355",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995288319885313",
   "Positive": "0.9008784294128418",
   "Negative": "0.0025609126314520836",
   "Neutral": "0.09627652913331985",
   "Mixed": "0.0002840919478330761"
 },
 {
   "tweet_id": "1589995281009213441",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995280191352832",
   "Positive": "0.5425968170166016",
   "Negative": "0.0001923426752910018",
   "Neutral": "0.45712992548942566",
   "Mixed": "8.0891877587419e-05"
 },
 {
   "tweet_id": "1589995279633485826",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995269181308928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995263237984258",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589995261270843396",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995258469060608",
   "Positive": "0.6782761812210083",
   "Negative": "0.0008963731816038489",
   "Neutral": "0.32063111662864685",
   "Mixed": "0.00019630799943115562"
 },
 {
   "tweet_id": "1589995257122283521",
   "Positive": "0.017335282638669014",
   "Negative": "0.5769298076629639",
   "Neutral": "0.405464768409729",
   "Mixed": "0.00027014236547984183"
 },
 {
   "tweet_id": "1589995242115444738",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995238227345414",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995230505619456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995229436076032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995228878245888",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995227095633920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995221466894337",
   "Positive": "0.004749205894768238",
   "Negative": "0.00031182088423520327",
   "Neutral": "0.9949140548706055",
   "Mixed": "2.494430373189971e-05"
 },
 {
   "tweet_id": "1589995221332688897",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589995210502967296",
   "Positive": "0.7202640771865845",
   "Negative": "0.000610144401434809",
   "Neutral": "0.27902671694755554",
   "Mixed": "9.905698971124366e-05"
 },
 {
   "tweet_id": "1589995207109795844",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995193654444034",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995192106778624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995191733477376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995187673391105",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995187346235394",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589995173853147136",
   "Positive": "0.413205087184906",
   "Negative": "0.0010604062117636204",
   "Neutral": "0.5855480432510376",
   "Mixed": "0.00018641821225173771"
 },
 {
   "tweet_id": "1589995170858438657",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589995161718706176",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589995156924944384",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589995130836357120",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995130051710978",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995126679797765",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589995123164618752",
   "Positive": "0.002950312802568078",
   "Negative": "0.6529351472854614",
   "Neutral": "0.34252652525901794",
   "Mixed": "0.0015879764687269926"
 },
 {
   "tweet_id": "1589995121264963584",
   "Positive": "0.034090444445610046",
   "Negative": "0.026508983224630356",
   "Neutral": "0.27385208010673523",
   "Mixed": "0.6655485033988953"
 },
 {
   "tweet_id": "1589995108535267329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995105498210304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995103418187776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995095688122371",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589995094106857472",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995093595156480",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589995092584333312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995087425339392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995070887178241",
   "Positive": "0.07281343638896942",
   "Negative": "0.002422219840809703",
   "Neutral": "0.9247390031814575",
   "Mixed": "2.5379949875059538e-05"
 },
 {
   "tweet_id": "1589995054873350144",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995050439610369",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589995045813649408",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589995021906087936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589995018793943043",
   "Positive": "0.007689758203923702",
   "Negative": "0.6521428823471069",
   "Neutral": "0.3400997519493103",
   "Mixed": "6.765502621419728e-05"
 },
 {
   "tweet_id": "1589995017791496192",
   "Positive": "0.3473761975765228",
   "Negative": "0.0011301557533442974",
   "Neutral": "0.6514672040939331",
   "Mixed": "2.6436568077770062e-05"
 },
 {
   "tweet_id": "1589995015912423425",
   "Positive": "0.04334879666566849",
   "Negative": "5.562051956076175e-05",
   "Neutral": "0.9563900232315063",
   "Mixed": "0.00020552224304992706"
 },
 {
   "tweet_id": "1589995014444441601",
   "Positive": "0.0015819628024473786",
   "Negative": "0.8212917447090149",
   "Neutral": "0.17705367505550385",
   "Mixed": "7.261121936608106e-05"
 },
 {
   "tweet_id": "1589995006924034048",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589994999298465793",
   "Positive": "0.00014330208068713546",
   "Negative": "0.00021942755847703665",
   "Neutral": "0.9996259212493896",
   "Mixed": "1.1377278497093357e-05"
 },
 {
   "tweet_id": "1589994994798297088",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589994977576509441",
   "Positive": "0.9945420622825623",
   "Negative": "0.00018391554476693273",
   "Neutral": "0.005262622144073248",
   "Mixed": "1.1428841389715672e-05"
 },
 {
   "tweet_id": "1589994974657286151",
   "Positive": "0.6093133091926575",
   "Negative": "0.045284248888492584",
   "Neutral": "0.33783841133117676",
   "Mixed": "0.007564073894172907"
 },
 {
   "tweet_id": "1589994942067539968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994941077655552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994933670531072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994932118650882",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589994930231205891",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994930206052353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994929371348994",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994926716387330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994922845032448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994916461314050",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589994911956205569",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994911629447170",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994905967132674",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994900468436992",
   "Positive": "0.024219198152422905",
   "Negative": "0.014444655738770962",
   "Neutral": "0.9613011479377747",
   "Mixed": "3.493931581033394e-05"
 },
 {
   "tweet_id": "1589994898920726528",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994894596411393",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994890624368640",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589994890259496960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994880750657536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994878012096519",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994875055144960",
   "Positive": "0.03347982466220856",
   "Negative": "5.329517080099322e-05",
   "Neutral": "0.9662638306617737",
   "Mixed": "0.00020302132179494947"
 },
 {
   "tweet_id": "1589994849595334656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994843513950211",
   "Positive": "0.7671600580215454",
   "Negative": "0.0003433974925428629",
   "Neutral": "0.23224475979804993",
   "Mixed": "0.0002517866378184408"
 },
 {
   "tweet_id": "1589994821502238726",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589994810060181504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994804431421440",
   "Positive": "0.017726242542266846",
   "Negative": "0.0023644580505788326",
   "Neutral": "0.9798753261566162",
   "Mixed": "3.39446087309625e-05"
 },
 {
   "tweet_id": "1589994796533551105",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994788304351235",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994783887732741",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994773573959686",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994771657154560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994764858175488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994763641851904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994760596357121",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589994759019692034",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994750559780864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994742196350977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994741713641473",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589994740656635905",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994732583022592",
   "Positive": "0.08968836069107056",
   "Negative": "0.00025523253134451807",
   "Neutral": "0.9100267291069031",
   "Mixed": "2.9678489227080718e-05"
 },
 {
   "tweet_id": "1589994731798691840",
   "Positive": "0.027149837464094162",
   "Negative": "0.007147195748984814",
   "Neutral": "0.9654859304428101",
   "Mixed": "0.00021701856167055666"
 },
 {
   "tweet_id": "1589994731358277632",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589994711338491905",
   "Positive": "0.001990139251574874",
   "Negative": "0.5182235240936279",
   "Neutral": "0.46532580256462097",
   "Mixed": "0.014460489153862"
 },
 {
   "tweet_id": "1589994695597641729",
   "Positive": "0.008073071949183941",
   "Negative": "0.271607905626297",
   "Neutral": "0.720126211643219",
   "Mixed": "0.0001928221754496917"
 },
 {
   "tweet_id": "1589994693664083975",
   "Positive": "0.9546346068382263",
   "Negative": "0.0005132025689817965",
   "Neutral": "0.04478774592280388",
   "Mixed": "6.441232835641131e-05"
 },
 {
   "tweet_id": "1589994692544192513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994692003131394",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994688710250496",
   "Positive": "0.0007181200198829174",
   "Negative": "0.0001239195844391361",
   "Neutral": "0.9991540908813477",
   "Mixed": "3.7557979339908343e-06"
 },
 {
   "tweet_id": "1589994684973473793",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994668213010433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994663012085760",
   "Positive": "0.024518392980098724",
   "Negative": "5.219852027948946e-05",
   "Neutral": "0.9749950766563416",
   "Mixed": "0.00043433130485937"
 },
 {
   "tweet_id": "1589994661854478337",
   "Positive": "0.4149744212627411",
   "Negative": "0.008007408119738102",
   "Neutral": "0.5768284797668457",
   "Mixed": "0.00018969058874063194"
 },
 {
   "tweet_id": "1589994651838287873",
   "Positive": "0.058907900005578995",
   "Negative": "0.05096542090177536",
   "Neutral": "0.890109658241272",
   "Mixed": "1.7005155314109288e-05"
 },
 {
   "tweet_id": "1589994649170890754",
   "Positive": "0.0026338931638747454",
   "Negative": "0.8527485132217407",
   "Neutral": "0.13675375282764435",
   "Mixed": "0.007863825187087059"
 },
 {
   "tweet_id": "1589994644976603136",
   "Positive": "0.017726242542266846",
   "Negative": "0.0023644580505788326",
   "Neutral": "0.9798753261566162",
   "Mixed": "3.39446087309625e-05"
 },
 {
   "tweet_id": "1589994623988301825",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994623560474624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994622612574208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994622348333056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994620616077315",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589994603939524608",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589994594254876672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994592623284225",
   "Positive": "0.03467307612299919",
   "Negative": "6.26606706646271e-05",
   "Neutral": "0.9649489521980286",
   "Mixed": "0.0003152584540657699"
 },
 {
   "tweet_id": "1589994592380018688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994590538727424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994568531218432",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1589994568074022912",
   "Positive": "0.8713623285293579",
   "Negative": "0.00012553013220895082",
   "Neutral": "0.12845845520496368",
   "Mixed": "5.3706520702689886e-05"
 },
 {
   "tweet_id": "1589994563506417666",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994549283532800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994545181515777",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994544854368256",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589994539410165760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994536759353344",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994524830740483",
   "Positive": "0.03145162761211395",
   "Negative": "6.234718603082001e-05",
   "Neutral": "0.9681410193443298",
   "Mixed": "0.00034493833663873374"
 },
 {
   "tweet_id": "1589994524813983744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994524138692609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994522930356224",
   "Positive": "0.3012615144252777",
   "Negative": "0.0027077686972916126",
   "Neutral": "0.6960093975067139",
   "Mixed": "2.1345373170333914e-05"
 },
 {
   "tweet_id": "1589994521311727616",
   "Positive": "0.33385103940963745",
   "Negative": "0.00023706255888100713",
   "Neutral": "0.6657480001449585",
   "Mixed": "0.00016391390818171203"
 },
 {
   "tweet_id": "1589994515099979776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994511446421511",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1589994480601792513",
   "Positive": "0.028421230614185333",
   "Negative": "0.00018937433196697384",
   "Neutral": "0.9713782668113708",
   "Mixed": "1.1060110409744084e-05"
 },
 {
   "tweet_id": "1589994480601468928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994480245305344",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994460905345025",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994448171454465",
   "Positive": "0.03292347118258476",
   "Negative": "6.614113226532936e-05",
   "Neutral": "0.9667872786521912",
   "Mixed": "0.0002231448161182925"
 },
 {
   "tweet_id": "1589994445369245696",
   "Positive": "0.0006372627103701234",
   "Negative": "0.00011726420052582398",
   "Neutral": "0.9992389678955078",
   "Mixed": "6.5008221099560615e-06"
 },
 {
   "tweet_id": "1589994441246244866",
   "Positive": "0.016117166727781296",
   "Negative": "0.00014680103049613535",
   "Neutral": "0.9837275743484497",
   "Mixed": "8.406418601225596e-06"
 },
 {
   "tweet_id": "1589994438071586817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994436502511619",
   "Positive": "0.0005595629336312413",
   "Negative": "0.00016400656022597104",
   "Neutral": "0.9992732405662537",
   "Mixed": "3.259439381508855e-06"
 },
 {
   "tweet_id": "1589994429649412096",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994423362162688",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994420669403139",
   "Positive": "0.07603764533996582",
   "Negative": "0.13844509422779083",
   "Neutral": "0.7853654623031616",
   "Mixed": "0.00015171829727478325"
 },
 {
   "tweet_id": "1589994400209174529",
   "Positive": "0.17676344513893127",
   "Negative": "0.0002962539147119969",
   "Neutral": "0.8228871822357178",
   "Mixed": "5.314468580763787e-05"
 },
 {
   "tweet_id": "1589994398477332481",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994397579767810",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589994386364174337",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994377929277440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994365799518208",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994357406728192",
   "Positive": "0.03573456406593323",
   "Negative": "6.946198845980689e-05",
   "Neutral": "0.9638473391532898",
   "Mixed": "0.0003487727080937475"
 },
 {
   "tweet_id": "1589994339887120386",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994339446685700",
   "Positive": "0.021224448457360268",
   "Negative": "0.003633374348282814",
   "Neutral": "0.975117564201355",
   "Mixed": "2.467376361892093e-05"
 },
 {
   "tweet_id": "1589994336481345538",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589994333423669249",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994329053224960",
   "Positive": "0.8827705383300781",
   "Negative": "0.0003657281049527228",
   "Neutral": "0.11677348613739014",
   "Mixed": "9.036323899636045e-05"
 },
 {
   "tweet_id": "1589994320899506176",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994313362329600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994306588528641",
   "Positive": "0.00230012577958405",
   "Negative": "0.0006100806640461087",
   "Neutral": "0.9970288872718811",
   "Mixed": "6.10222305112984e-05"
 },
 {
   "tweet_id": "1589994302050107392",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994298531274753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994297189109761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994295091941378",
   "Positive": "0.5976389050483704",
   "Negative": "0.015209252946078777",
   "Neutral": "0.38656309247016907",
   "Mixed": "0.0005887311417609453"
 },
 {
   "tweet_id": "1589994291057004544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994289274425344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994282857156609",
   "Positive": "0.03177056089043617",
   "Negative": "6.456099799834192e-05",
   "Neutral": "0.9679728746414185",
   "Mixed": "0.0001920428330777213"
 },
 {
   "tweet_id": "1589994261445218305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994251785752576",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1589994243900477441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994236153565185",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994224782831616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994221037301764",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589994218558480384",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994215869599744",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994215211433987",
   "Positive": "0.9630018472671509",
   "Negative": "0.0008495762012898922",
   "Neutral": "0.036090053617954254",
   "Mixed": "5.848670843988657e-05"
 },
 {
   "tweet_id": "1589994207644880896",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589994206738911233",
   "Positive": "0.01661703735589981",
   "Negative": "0.002211557002738118",
   "Neutral": "0.9805296659469604",
   "Mixed": "0.0006417558179236948"
 },
 {
   "tweet_id": "1589994204167835649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994201630244865",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994198908166146",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994186019082240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994182210637824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589994176477003777",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994175889801216",
   "Positive": "0.11317872256040573",
   "Negative": "0.009154957719147205",
   "Neutral": "0.8773676753044128",
   "Mixed": "0.00029861179064027965"
 },
 {
   "tweet_id": "1589994169329946626",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994158726746112",
   "Positive": "0.6852316856384277",
   "Negative": "0.0017368373228237033",
   "Neutral": "0.3129447102546692",
   "Mixed": "8.684620843268931e-05"
 },
 {
   "tweet_id": "1589994157036404737",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589994139802042368",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589994131954487297",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589994128431288321",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589994106528624640",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589994101147340801",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994092381245440",
   "Positive": "0.0008109518093988299",
   "Negative": "0.00010942974768113345",
   "Neutral": "0.9990744590759277",
   "Mixed": "5.076346042187652e-06"
 },
 {
   "tweet_id": "1589994091194257410",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994088308539397",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589994082293907458",
   "Positive": "0.022240614518523216",
   "Negative": "0.009635744616389275",
   "Neutral": "0.9053066968917847",
   "Mixed": "0.06281690299510956"
 },
 {
   "tweet_id": "1589994080406474753",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589994077663428608",
   "Positive": "0.7351023554801941",
   "Negative": "0.0017383649246767163",
   "Neutral": "0.26307088136672974",
   "Mixed": "8.843802061164752e-05"
 },
 {
   "tweet_id": "1589994061746044931",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1589994061343391745",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994056452820992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994042158616576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994040313122816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994040304762880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589994030091603975",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994028808175616",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589994012962095104",
   "Positive": "0.03026989847421646",
   "Negative": "6.038374704075977e-05",
   "Neutral": "0.9693790674209595",
   "Mixed": "0.0002906283480115235"
 },
 {
   "tweet_id": "1589993999053754369",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993989914382336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993989222334466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993982104580098",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589993964978872320",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993956003434496",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1589993944934645762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993936822861824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993920712540163",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589993919789830146",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993918820909056",
   "Positive": "0.029273565858602524",
   "Negative": "6.440230208681896e-05",
   "Neutral": "0.9705252647399902",
   "Mixed": "0.00013675677473656833"
 },
 {
   "tweet_id": "1589993911640281089",
   "Positive": "0.013966551050543785",
   "Negative": "0.00030691514257341623",
   "Neutral": "0.9856730699539185",
   "Mixed": "5.345616955310106e-05"
 },
 {
   "tweet_id": "1589993911602540544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993897664872451",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993895991336961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993891209805824",
   "Positive": "0.5600337386131287",
   "Negative": "0.00018448261835146695",
   "Neutral": "0.4397217333316803",
   "Mixed": "5.999340646667406e-05"
 },
 {
   "tweet_id": "1589993882439520260",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993879696465922",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993863220862976",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993860239089664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993852404109312",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1589993843776114690",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993828588851206",
   "Positive": "0.0013418260496109724",
   "Negative": "0.0001889961276901886",
   "Neutral": "0.9984666109085083",
   "Mixed": "2.6168916065216763e-06"
 },
 {
   "tweet_id": "1589993822444195840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993810712743939",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589993797408403457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993787702804481",
   "Positive": "0.6031859517097473",
   "Negative": "0.0005006384453736246",
   "Neutral": "0.39617976546287537",
   "Mixed": "0.00013371254317462444"
 },
 {
   "tweet_id": "1589993783482937344",
   "Positive": "0.04213098809123039",
   "Negative": "0.42902010679244995",
   "Neutral": "0.3852168619632721",
   "Mixed": "0.14363205432891846"
 },
 {
   "tweet_id": "1589993782111768577",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993779888807942",
   "Positive": "0.0020582391880452633",
   "Negative": "0.00013713476073462516",
   "Neutral": "0.9977976083755493",
   "Mixed": "6.997358013904886e-06"
 },
 {
   "tweet_id": "1589993776902471680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993771579904000",
   "Positive": "0.0271528959274292",
   "Negative": "6.369900074787438e-05",
   "Neutral": "0.9725924730300903",
   "Mixed": "0.0001909896091092378"
 },
 {
   "tweet_id": "1589993760485953536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993757302460418",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993749798563841",
   "Positive": "0.001311077969148755",
   "Negative": "0.022593682631850243",
   "Neutral": "0.976088285446167",
   "Mixed": "6.979137651796918e-06"
 },
 {
   "tweet_id": "1589993726327554048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993725153120257",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589993723320225795",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993723190202368",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589993714143080455",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589993713987555328",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993696153698305",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993694077530113",
   "Positive": "0.031424567103385925",
   "Negative": "6.45331820123829e-05",
   "Neutral": "0.9683054089546204",
   "Mixed": "0.00020543840946629643"
 },
 {
   "tweet_id": "1589993693788123136",
   "Positive": "0.5410900712013245",
   "Negative": "0.005534827709197998",
   "Neutral": "0.4533079266548157",
   "Mixed": "6.724450213368982e-05"
 },
 {
   "tweet_id": "1589993688264212481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993687005949952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993685760233472",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993642672140291",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993640419823616",
   "Positive": "0.4822312295436859",
   "Negative": "0.0012554751010611653",
   "Neutral": "0.5164064764976501",
   "Mixed": "0.00010688592738006264"
 },
 {
   "tweet_id": "1589993632094113794",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993622463971334",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993622061338624",
   "Positive": "0.024477414786815643",
   "Negative": "0.4952562749385834",
   "Neutral": "0.4767135977745056",
   "Mixed": "0.0035527527797967196"
 },
 {
   "tweet_id": "1589993613819539456",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589993602742374401",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993584413249537",
   "Positive": "0.11523948609828949",
   "Negative": "0.019763167947530746",
   "Neutral": "0.8647343516349792",
   "Mixed": "0.0002629704540595412"
 },
 {
   "tweet_id": "1589993582546808832",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993581556953092",
   "Positive": "0.06394392251968384",
   "Negative": "0.0003111442783847451",
   "Neutral": "0.9357190728187561",
   "Mixed": "2.5855906642391346e-05"
 },
 {
   "tweet_id": "1589993576737689600",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993566428069893",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993562321879040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993559822053376",
   "Positive": "0.024819999933242798",
   "Negative": "0.8140367269515991",
   "Neutral": "0.11778543144464493",
   "Mixed": "0.043357767164707184"
 },
 {
   "tweet_id": "1589993555887783937",
   "Positive": "0.004026756156235933",
   "Negative": "0.506669819355011",
   "Neutral": "0.48925626277923584",
   "Mixed": "4.722131779999472e-05"
 },
 {
   "tweet_id": "1589993553261867008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993550238081024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993541149024258",
   "Positive": "0.8788013458251953",
   "Negative": "0.0010063982335850596",
   "Neutral": "0.11969749629497528",
   "Mixed": "0.0004947202396579087"
 },
 {
   "tweet_id": "1589993533523775488",
   "Positive": "0.042906973510980606",
   "Negative": "0.00046224318793974817",
   "Neutral": "0.9560261964797974",
   "Mixed": "0.0006045446498319507"
 },
 {
   "tweet_id": "1589993530776162305",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589993530763923456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993516188721153",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1589993511075844098",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993509905641473",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993504540745728",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993500355211267",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993477667856385",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589993473486487553",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993472844390400",
   "Positive": "0.0010880125919356942",
   "Negative": "0.0002961337740998715",
   "Neutral": "0.998613715171814",
   "Mixed": "2.1602950255328324e-06"
 },
 {
   "tweet_id": "1589993466846932992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993453202870278",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1589993446647140354",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993440708026373",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993424882925572",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993424048263169",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589993421581606912",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1589993417383165954",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993415152136192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993412815884289",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993404049813504",
   "Positive": "0.03919075429439545",
   "Negative": "0.001663415925577283",
   "Neutral": "0.9591373801231384",
   "Mixed": "8.457364856440108e-06"
 },
 {
   "tweet_id": "1589993403013824512",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589993401948475393",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993391399800832",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993390300880896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993389009014786",
   "Positive": "0.056261096149683",
   "Negative": "0.0021829018369317055",
   "Neutral": "0.9415475726127625",
   "Mixed": "8.375265679205768e-06"
 },
 {
   "tweet_id": "1589993388635721728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993388539285506",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993385078951936",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993380339417089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993378921734144",
   "Positive": "0.006747521460056305",
   "Negative": "0.31444650888442993",
   "Neutral": "0.014596503227949142",
   "Mixed": "0.6642094254493713"
 },
 {
   "tweet_id": "1589993377164320769",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993373548826626",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993372747698178",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589993371984367616",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993370793172992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993369752993793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993365046988801",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993352782831616",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993351612231680",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993351390302209",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993343941242881",
   "Positive": "0.15236210823059082",
   "Negative": "0.00045970879727974534",
   "Neutral": "0.8471664190292358",
   "Mixed": "1.1693888154695742e-05"
 },
 {
   "tweet_id": "1589993339101011969",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993336206565377",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993333866508288",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993330829824002",
   "Positive": "0.8658090829849243",
   "Negative": "0.00037336783134378493",
   "Neutral": "0.13378913700580597",
   "Mixed": "2.847849827958271e-05"
 },
 {
   "tweet_id": "1589993328644591616",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589993321271001088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993320151121922",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993315214462976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993300022681602",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993297577414656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993285262934016",
   "Positive": "0.00283050280995667",
   "Negative": "0.5025343894958496",
   "Neutral": "0.4945381283760071",
   "Mixed": "9.697268978925422e-05"
 },
 {
   "tweet_id": "1589993284814110720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993282863788032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993274210914304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993266795028480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993253885341696",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589993252299898880",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993251276480512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993242917240834",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993242908848129",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993238714519552",
   "Positive": "0.004805150907486677",
   "Negative": "0.492572546005249",
   "Neutral": "0.5024654269218445",
   "Mixed": "0.00015687965787947178"
 },
 {
   "tweet_id": "1589993237913436161",
   "Positive": "0.0396336205303669",
   "Negative": "7.387269579339772e-05",
   "Neutral": "0.9600586891174316",
   "Mixed": "0.00023380439961329103"
 },
 {
   "tweet_id": "1589993224755900417",
   "Positive": "0.4281352758407593",
   "Negative": "0.0017736249137669802",
   "Neutral": "0.5699262022972107",
   "Mixed": "0.00016486941603943706"
 },
 {
   "tweet_id": "1589993213515157505",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993207798325248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993196842807298",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993187095220225",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993186864562178",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993184981323777",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589993182305320960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993181403557890",
   "Positive": "0.035566043108701706",
   "Negative": "6.368206231854856e-05",
   "Neutral": "0.9640358686447144",
   "Mixed": "0.0003344777796883136"
 },
 {
   "tweet_id": "1589993179566477313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993173048209408",
   "Positive": "0.00043386651668697596",
   "Negative": "0.0001242983853444457",
   "Neutral": "0.9994383454322815",
   "Mixed": "3.4680813314480474e-06"
 },
 {
   "tweet_id": "1589993167755292674",
   "Positive": "0.0008264806820079684",
   "Negative": "0.0031423638574779034",
   "Neutral": "0.9960228204727173",
   "Mixed": "8.295262887259014e-06"
 },
 {
   "tweet_id": "1589993155692482560",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589993151028396032",
   "Positive": "0.6439418792724609",
   "Negative": "0.00031489497632719576",
   "Neutral": "0.35571491718292236",
   "Mixed": "2.832102654792834e-05"
 },
 {
   "tweet_id": "1589993134653865985",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993123023052800",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993115150348288",
   "Positive": "0.47747349739074707",
   "Negative": "0.007741969078779221",
   "Neutral": "0.5127715468406677",
   "Mixed": "0.002013042801991105"
 },
 {
   "tweet_id": "1589993114458259458",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589993111677440002",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993107533467649",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589993101019709440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589993097995640832",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993096376639491",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993095759904768",
   "Positive": "0.0009551666444167495",
   "Negative": "0.00014424229448195547",
   "Neutral": "0.9988971948623657",
   "Mixed": "3.3167041237902595e-06"
 },
 {
   "tweet_id": "1589993093918756866",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993086700044288",
   "Positive": "0.013937189243733883",
   "Negative": "0.0001709140051389113",
   "Neutral": "0.985882043838501",
   "Mixed": "9.854358722805046e-06"
 },
 {
   "tweet_id": "1589993083793739776",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589993067150733314",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589993049899569154",
   "Positive": "0.0024501171428710222",
   "Negative": "0.00011964392615482211",
   "Neutral": "0.9974247217178345",
   "Mixed": "5.641549705615034e-06"
 },
 {
   "tweet_id": "1589993034539991044",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993028483440641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589993024993386496",
   "Positive": "0.008584603667259216",
   "Negative": "0.00019232614431530237",
   "Neutral": "0.9912176132202148",
   "Mixed": "5.416100066213403e-06"
 },
 {
   "tweet_id": "1589993023043428353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589993017380724736",
   "Positive": "0.0028389403596520424",
   "Negative": "0.00010602019028738141",
   "Neutral": "0.9970491528511047",
   "Mixed": "5.992429123580223e-06"
 },
 {
   "tweet_id": "1589993015263006721",
   "Positive": "0.0026307757943868637",
   "Negative": "0.9511565566062927",
   "Neutral": "0.03706803917884827",
   "Mixed": "0.009144587442278862"
 },
 {
   "tweet_id": "1589992994572468224",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992992253050880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992986984996869",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1589992982731984898",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589992977526853632",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589992972954656769",
   "Positive": "0.017726242542266846",
   "Negative": "0.0023644580505788326",
   "Neutral": "0.9798753261566162",
   "Mixed": "3.39446087309625e-05"
 },
 {
   "tweet_id": "1589992966864916480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992962351472641",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992959810113536",
   "Positive": "0.04200155287981033",
   "Negative": "0.002102881669998169",
   "Neutral": "0.9558900594711304",
   "Mixed": "5.598389179795049e-06"
 },
 {
   "tweet_id": "1589992944156954624",
   "Positive": "9.87634775810875e-05",
   "Negative": "0.0010665631853044033",
   "Neutral": "0.9988271594047546",
   "Mixed": "7.559178811789025e-06"
 },
 {
   "tweet_id": "1589992942244352000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992932991717376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992925131571201",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589992912586440704",
   "Positive": "0.09083928912878036",
   "Negative": "0.0007725190371274948",
   "Neutral": "0.9083772897720337",
   "Mixed": "1.0978746104228776e-05"
 },
 {
   "tweet_id": "1589992911739162625",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992911177125889",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992890763468804",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589992889597440001",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992885910654976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992883733790720",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589992877182324736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992875839737857",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589992873394860032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992869900664833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992865308250112",
   "Positive": "0.028796182945370674",
   "Negative": "6.561475311173126e-05",
   "Neutral": "0.9709661602973938",
   "Mixed": "0.00017204655159730464"
 },
 {
   "tweet_id": "1589992863668273152",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992861688541184",
   "Positive": "0.0014070107135921717",
   "Negative": "0.28573527932167053",
   "Neutral": "0.7127042412757874",
   "Mixed": "0.00015340755635406822"
 },
 {
   "tweet_id": "1589992856693125123",
   "Positive": "0.45887491106987",
   "Negative": "0.0011038822121918201",
   "Neutral": "0.5395916104316711",
   "Mixed": "0.00042964331805706024"
 },
 {
   "tweet_id": "1589992853585133571",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992836443033600",
   "Positive": "0.0015640067867934704",
   "Negative": "0.00024059275165200233",
   "Neutral": "0.998191773891449",
   "Mixed": "3.557490572347888e-06"
 },
 {
   "tweet_id": "1589992832269701122",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992822031413248",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992808395739136",
   "Positive": "0.023125676438212395",
   "Negative": "5.258714372757822e-05",
   "Neutral": "0.9766150712966919",
   "Mixed": "0.0002066839369945228"
 },
 {
   "tweet_id": "1589992807452008449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992806277599232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992800011321344",
   "Positive": "0.0030414180364459753",
   "Negative": "0.6452987194061279",
   "Neutral": "0.3463195562362671",
   "Mixed": "0.005340309347957373"
 },
 {
   "tweet_id": "1589992786274963456",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589992774950359041",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1589992772047892482",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992768260419585",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992764670115840",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589992764103888896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992763671863296",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992751223164929",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992747746103297",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589992745757978624",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589992740305375233",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992737537142784",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589992730901745665",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992720457560064",
   "Positive": "0.5959954261779785",
   "Negative": "0.0043494743295013905",
   "Neutral": "0.39949992299079895",
   "Mixed": "0.00015515985433012247"
 },
 {
   "tweet_id": "1589992719564570624",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589992719216414723",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992706742554624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992686395985921",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992682113597440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992679399895040",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589992678288420864",
   "Positive": "0.28500938415527344",
   "Negative": "0.0013347200583666563",
   "Neutral": "0.7017581462860107",
   "Mixed": "0.01189776137471199"
 },
 {
   "tweet_id": "1589992677231075328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992661980942336",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992660001239040",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992656326692864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992656176033793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992648743727109",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589992643786076162",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992630644928512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992628980174849",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992626811392000",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1589992623728574465",
   "Positive": "0.23912957310676575",
   "Negative": "0.0006004271563142538",
   "Neutral": "0.7602503299713135",
   "Mixed": "1.9689219698193483e-05"
 },
 {
   "tweet_id": "1589992619400368129",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992612202971136",
   "Positive": "0.07890529185533524",
   "Negative": "0.7584301829338074",
   "Neutral": "0.11368878930807114",
   "Mixed": "0.04897577688097954"
 },
 {
   "tweet_id": "1589992606679044097",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992601201086466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992597116055553",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589992593974210560",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589992591143358466",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992590665187333",
   "Positive": "0.00020022403623443097",
   "Negative": "0.9855875968933105",
   "Neutral": "0.014200468547642231",
   "Mixed": "1.1699045899149496e-05"
 },
 {
   "tweet_id": "1589992579084713984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992576891125760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992571233013760",
   "Positive": "0.22200897336006165",
   "Negative": "0.09156735986471176",
   "Neutral": "0.684577226638794",
   "Mixed": "0.0018464882159605622"
 },
 {
   "tweet_id": "1589992554652930050",
   "Positive": "0.031462062150239944",
   "Negative": "6.883508467581123e-05",
   "Neutral": "0.9682664275169373",
   "Mixed": "0.00020270093227736652"
 },
 {
   "tweet_id": "1589992547145121794",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992544695246848",
   "Positive": "0.02241642214357853",
   "Negative": "0.5686066746711731",
   "Neutral": "0.4083288013935089",
   "Mixed": "0.0006481135496869683"
 },
 {
   "tweet_id": "1589992535828889602",
   "Positive": "0.027848070487380028",
   "Negative": "0.15002495050430298",
   "Neutral": "0.822073221206665",
   "Mixed": "5.368752681533806e-05"
 },
 {
   "tweet_id": "1589992517176807424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992516409229315",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1589992512093319171",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992508838547458",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992508142288898",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589992507739635713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992502492540935",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992498155646976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992493864849408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992489133703169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992480996397057",
   "Positive": "0.003602382028475404",
   "Negative": "0.38332441449165344",
   "Neutral": "0.6130500435829163",
   "Mixed": "2.319694249308668e-05"
 },
 {
   "tweet_id": "1589992478522101760",
   "Positive": "0.004031365271657705",
   "Negative": "0.00020144526206422597",
   "Neutral": "0.9957601428031921",
   "Mixed": "7.000092409725767e-06"
 },
 {
   "tweet_id": "1589992476026163201",
   "Positive": "0.0015958377625793219",
   "Negative": "0.11048362404108047",
   "Neutral": "0.887794554233551",
   "Mixed": "0.0001260631688637659"
 },
 {
   "tweet_id": "1589992473253670912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992469214924803",
   "Positive": "0.003870630171149969",
   "Negative": "0.00032061440288089216",
   "Neutral": "0.9958017468452454",
   "Mixed": "6.9521370278380346e-06"
 },
 {
   "tweet_id": "1589992455968985088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992449354911746",
   "Positive": "0.19852319359779358",
   "Negative": "0.007116666529327631",
   "Neutral": "0.794339656829834",
   "Mixed": "2.042031701421365e-05"
 },
 {
   "tweet_id": "1589992448218255360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992443952664578",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589992410821840897",
   "Positive": "0.023841243237257004",
   "Negative": "5.6123513786587864e-05",
   "Neutral": "0.9758179187774658",
   "Mixed": "0.0002848206495400518"
 },
 {
   "tweet_id": "1589992403712507909",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992397462966272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992395504222208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992394824744960",
   "Positive": "0.020101230591535568",
   "Negative": "8.280509064206854e-05",
   "Neutral": "0.9794843792915344",
   "Mixed": "0.0003315942594781518"
 },
 {
   "tweet_id": "1589992392173965314",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992384414482437",
   "Positive": "0.28746500611305237",
   "Negative": "0.000978811294771731",
   "Neutral": "0.711467444896698",
   "Mixed": "8.872976468410343e-05"
 },
 {
   "tweet_id": "1589992381394276353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992381172285441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992375598075904",
   "Positive": "0.024785444140434265",
   "Negative": "0.04012417048215866",
   "Neutral": "0.07114607095718384",
   "Mixed": "0.863944411277771"
 },
 {
   "tweet_id": "1589992375577104385",
   "Positive": "0.2137085646390915",
   "Negative": "0.000985729624517262",
   "Neutral": "0.7851158380508423",
   "Mixed": "0.00018985610222443938"
 },
 {
   "tweet_id": "1589992373123428352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992371659616258",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589992361186426880",
   "Positive": "0.015496441163122654",
   "Negative": "5.8589688705978915e-05",
   "Neutral": "0.9842479825019836",
   "Mixed": "0.0001969937002286315"
 },
 {
   "tweet_id": "1589992357575151617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992351950569473",
   "Positive": "0.03349483013153076",
   "Negative": "7.352152169914916e-05",
   "Neutral": "0.9661624431610107",
   "Mixed": "0.00026917250943370163"
 },
 {
   "tweet_id": "1589992346602844160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992341821345793",
   "Positive": "0.016413841396570206",
   "Negative": "5.842396421940066e-05",
   "Neutral": "0.9833078384399414",
   "Mixed": "0.00021991561516188085"
 },
 {
   "tweet_id": "1589992324788269056",
   "Positive": "0.01652875915169716",
   "Negative": "5.659944508806802e-05",
   "Neutral": "0.9832026362419128",
   "Mixed": "0.00021200628543738276"
 },
 {
   "tweet_id": "1589992314986188800",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589992310628323328",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992305108582402",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992303464415233",
   "Positive": "0.017145728692412376",
   "Negative": "5.6161577958846465e-05",
   "Neutral": "0.9826012253761292",
   "Mixed": "0.00019685794541146606"
 },
 {
   "tweet_id": "1589992301002358784",
   "Positive": "0.02080283872783184",
   "Negative": "5.360522845876403e-05",
   "Neutral": "0.9788476824760437",
   "Mixed": "0.0002959186094813049"
 },
 {
   "tweet_id": "1589992294580879362",
   "Positive": "0.4108431041240692",
   "Negative": "0.0063237412832677364",
   "Neutral": "0.5819886922836304",
   "Mixed": "0.0008444401319138706"
 },
 {
   "tweet_id": "1589992279229763584",
   "Positive": "0.014880399219691753",
   "Negative": "5.4482323321281e-05",
   "Neutral": "0.9849512577056885",
   "Mixed": "0.00011393142631277442"
 },
 {
   "tweet_id": "1589992277748826112",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992265120100354",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992263794708480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992255557099520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992243909517313",
   "Positive": "0.016268832609057426",
   "Negative": "6.574813596671447e-05",
   "Neutral": "0.983489990234375",
   "Mixed": "0.0001753972755977884"
 },
 {
   "tweet_id": "1589992242307272706",
   "Positive": "0.030346546322107315",
   "Negative": "7.83338473411277e-05",
   "Neutral": "0.9693441390991211",
   "Mixed": "0.00023094365315046161"
 },
 {
   "tweet_id": "1589992225811083264",
   "Positive": "0.015414455905556679",
   "Negative": "8.38045816635713e-05",
   "Neutral": "0.9843059778213501",
   "Mixed": "0.0001956975320354104"
 },
 {
   "tweet_id": "1589992214041890818",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992213739884549",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992209872392193",
   "Positive": "0.015847278758883476",
   "Negative": "6.507686339318752e-05",
   "Neutral": "0.9839133620262146",
   "Mixed": "0.00017433419998269528"
 },
 {
   "tweet_id": "1589992197830868992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992192810307587",
   "Positive": "0.01514129713177681",
   "Negative": "5.806081753689796e-05",
   "Neutral": "0.9846262335777283",
   "Mixed": "0.00017445285629946738"
 },
 {
   "tweet_id": "1589992185260564482",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589992181867380737",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992175030652933",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589992169867476992",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992150628184064",
   "Positive": "0.0018088128417730331",
   "Negative": "0.17213045060634613",
   "Neutral": "0.826029360294342",
   "Mixed": "3.1441824830835685e-05"
 },
 {
   "tweet_id": "1589992146278359040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992142931652610",
   "Positive": "0.055396344512701035",
   "Negative": "0.4135042726993561",
   "Neutral": "0.19058288633823395",
   "Mixed": "0.34051650762557983"
 },
 {
   "tweet_id": "1589992135335751681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992125483331584",
   "Positive": "0.0016931837890297174",
   "Negative": "0.0003905765188392252",
   "Neutral": "0.9979140162467957",
   "Mixed": "2.2873603029438527e-06"
 },
 {
   "tweet_id": "1589992123654627328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992115517657088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992106873204736",
   "Positive": "0.02718914858996868",
   "Negative": "6.515452696476132e-05",
   "Neutral": "0.9725927710533142",
   "Mixed": "0.00015292275929823518"
 },
 {
   "tweet_id": "1589992104318894083",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992089496203265",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992083452198912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992076225425408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992066284916740",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992063499931648",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992063273426945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992051441295360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589992050933792768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589992047964225536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992046827225089",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992040775159808",
   "Positive": "0.02645450085401535",
   "Negative": "6.13233059993945e-05",
   "Neutral": "0.9733197689056396",
   "Mixed": "0.00016440496256109327"
 },
 {
   "tweet_id": "1589992039630123008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992038988382209",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589992020730605569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589992020306972672",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589992011729620992",
   "Positive": "0.02331962250173092",
   "Negative": "7.533168536610901e-05",
   "Neutral": "0.9765059351921082",
   "Mixed": "9.911384404404089e-05"
 },
 {
   "tweet_id": "1589992011201155072",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991993228525568",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991992368324608",
   "Positive": "0.014624198898673058",
   "Negative": "7.664972508791834e-05",
   "Neutral": "0.9851308465003967",
   "Mixed": "0.00016823482292238623"
 },
 {
   "tweet_id": "1589991980482060288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991975792496641",
   "Positive": "0.014269650913774967",
   "Negative": "7.778209692332894e-05",
   "Neutral": "0.9854678511619568",
   "Mixed": "0.00018468641792424023"
 },
 {
   "tweet_id": "1589991972290572289",
   "Positive": "0.02035587653517723",
   "Negative": "4.51476989837829e-05",
   "Neutral": "0.9793835282325745",
   "Mixed": "0.0002153859823010862"
 },
 {
   "tweet_id": "1589991970168246276",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991959619600384",
   "Positive": "0.012342613190412521",
   "Negative": "6.053578545106575e-05",
   "Neutral": "0.9874081611633301",
   "Mixed": "0.0001887239486677572"
 },
 {
   "tweet_id": "1589991942695178245",
   "Positive": "0.016367711126804352",
   "Negative": "6.760085670975968e-05",
   "Neutral": "0.9833785891532898",
   "Mixed": "0.00018600656767375767"
 },
 {
   "tweet_id": "1589991936156635136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991935317798912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991934030143488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991920805511169",
   "Positive": "0.031003428623080254",
   "Negative": "7.594563066959381e-05",
   "Neutral": "0.9686749577522278",
   "Mixed": "0.0002456427610013634"
 },
 {
   "tweet_id": "1589991911938744322",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991902707060736",
   "Positive": "0.03133471682667732",
   "Negative": "7.011632987996563e-05",
   "Neutral": "0.9683910608291626",
   "Mixed": "0.0002040526014752686"
 },
 {
   "tweet_id": "1589991898391150593",
   "Positive": "0.016872398555278778",
   "Negative": "5.5921467719599605e-05",
   "Neutral": "0.9829005599021912",
   "Mixed": "0.00017112083151005208"
 },
 {
   "tweet_id": "1589991888169619456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991882461171719",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991867332333568",
   "Positive": "0.8616544008255005",
   "Negative": "0.00011775553866755217",
   "Neutral": "0.13803333044052124",
   "Mixed": "0.00019453451386652887"
 },
 {
   "tweet_id": "1589991867118014464",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1589991847853961218",
   "Positive": "0.9831104278564453",
   "Negative": "0.000162503682076931",
   "Neutral": "0.01666400022804737",
   "Mixed": "6.296131323324516e-05"
 },
 {
   "tweet_id": "1589991847405182976",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589991843689025536",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589991831810416640",
   "Positive": "0.006451273802667856",
   "Negative": "0.0004882927751168609",
   "Neutral": "0.9930570125579834",
   "Mixed": "3.4544500522315502e-06"
 },
 {
   "tweet_id": "1589991828446924804",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589991820343537664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991814748332039",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991813150289920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991806921760768",
   "Positive": "0.027149837464094162",
   "Negative": "0.007147195748984814",
   "Neutral": "0.9654859304428101",
   "Mixed": "0.00021701856167055666"
 },
 {
   "tweet_id": "1589991791495118850",
   "Positive": "0.025102248415350914",
   "Negative": "6.984045467106625e-05",
   "Neutral": "0.9746767282485962",
   "Mixed": "0.00015113344124983996"
 },
 {
   "tweet_id": "1589991789020446721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991787686662144",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589991777775124480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991777599361024",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991773962919936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991771265970177",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589991764894846978",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991744686682113",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991739582222336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991731042615297",
   "Positive": "0.028944887220859528",
   "Negative": "6.230369763216004e-05",
   "Neutral": "0.9708105325698853",
   "Mixed": "0.00018227397231385112"
 },
 {
   "tweet_id": "1589991718882975744",
   "Positive": "0.0019055739976465702",
   "Negative": "0.31458720564842224",
   "Neutral": "0.6822815537452698",
   "Mixed": "0.001225674757733941"
 },
 {
   "tweet_id": "1589991712273100805",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991704484270085",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991703414722560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991686327140358",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991683663728640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991666441932805",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991662595760129",
   "Positive": "0.002705118153244257",
   "Negative": "0.0017290234100073576",
   "Neutral": "0.9955518841743469",
   "Mixed": "1.3986254998599179e-05"
 },
 {
   "tweet_id": "1589991661282545664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991660725075968",
   "Positive": "0.04534698277711868",
   "Negative": "0.5670174956321716",
   "Neutral": "0.3753413259983063",
   "Mixed": "0.012294147163629532"
 },
 {
   "tweet_id": "1589991657285767172",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991654421037057",
   "Positive": "0.030518431216478348",
   "Negative": "6.45383624942042e-05",
   "Neutral": "0.9691693782806396",
   "Mixed": "0.000247609248617664"
 },
 {
   "tweet_id": "1589991646682574851",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991640609230848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991635722850305",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991635374723072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991633868980227",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991630287011841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991623337062401",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589991617792180225",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589991598695202816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991573831708673",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589991573642969089",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991566013521921",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589991561936666624",
   "Positive": "0.04699835181236267",
   "Negative": "0.0004405990184750408",
   "Neutral": "0.9525543451309204",
   "Mixed": "6.744086931576021e-06"
 },
 {
   "tweet_id": "1589991555049615362",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991553443188739",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991550117097474",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991541065424896",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991541010882561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991540113707013",
   "Positive": "0.04392482340335846",
   "Negative": "0.0006578677566722035",
   "Neutral": "0.9552915692329407",
   "Mixed": "0.0001257057156180963"
 },
 {
   "tweet_id": "1589991520614371328",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991504789278723",
   "Positive": "0.032581627368927",
   "Negative": "0.00973536167293787",
   "Neutral": "0.957557201385498",
   "Mixed": "0.00012579193571582437"
 },
 {
   "tweet_id": "1589991504625299457",
   "Positive": "0.019167397171258926",
   "Negative": "0.0002862559340428561",
   "Neutral": "0.9805379509925842",
   "Mixed": "8.470917236991227e-06"
 },
 {
   "tweet_id": "1589991490721558529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991490193063936",
   "Positive": "0.042173534631729126",
   "Negative": "6.210077117430046e-05",
   "Neutral": "0.9574080109596252",
   "Mixed": "0.0003563021309673786"
 },
 {
   "tweet_id": "1589991485545775106",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991468764377088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991461038460928",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589991453119631360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991443992805378",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991435998179330",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991428830425089",
   "Positive": "0.03138801455497742",
   "Negative": "7.268586341524497e-05",
   "Neutral": "0.9682650566101074",
   "Mixed": "0.0002742999931797385"
 },
 {
   "tweet_id": "1589991428796870658",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991411860279296",
   "Positive": "0.0013418260496109724",
   "Negative": "0.0001889961276901886",
   "Neutral": "0.9984666109085083",
   "Mixed": "2.6168916065216763e-06"
 },
 {
   "tweet_id": "1589991395347271680",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589991385687805952",
   "Positive": "0.000868593982886523",
   "Negative": "0.00021089520305395126",
   "Neutral": "0.998916506767273",
   "Mixed": "4.0078316487779375e-06"
 },
 {
   "tweet_id": "1589991382071975938",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991380709158913",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991375759880192",
   "Positive": "0.03138801455497742",
   "Negative": "7.268586341524497e-05",
   "Neutral": "0.9682650566101074",
   "Mixed": "0.0002742999931797385"
 },
 {
   "tweet_id": "1589991373050368000",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991371066474497",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1589991364355575810",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991353106059264",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991326350991361",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1589991322743877632",
   "Positive": "0.4645293354988098",
   "Negative": "0.001538792042993009",
   "Neutral": "0.5338788032531738",
   "Mixed": "5.311014683684334e-05"
 },
 {
   "tweet_id": "1589991319493308416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991317937205250",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991313726115841",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991310072905729",
   "Positive": "0.025452693924307823",
   "Negative": "6.266017589950934e-05",
   "Neutral": "0.974309504032135",
   "Mixed": "0.00017515338549856097"
 },
 {
   "tweet_id": "1589991303475245056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991301038346240",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589991289596284930",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991284923846656",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589991282478546946",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589991280725360641",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991275000139777",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991264094916608",
   "Positive": "0.0747234970331192",
   "Negative": "0.0010376197751611471",
   "Neutral": "0.9242271184921265",
   "Mixed": "1.1800244465121068e-05"
 },
 {
   "tweet_id": "1589991261007908864",
   "Positive": "0.014415011741220951",
   "Negative": "6.186857353895903e-05",
   "Neutral": "0.9853531718254089",
   "Mixed": "0.00016995426267385483"
 },
 {
   "tweet_id": "1589991253390729216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991247900737539",
   "Positive": "0.030532389879226685",
   "Negative": "6.488500366685912e-05",
   "Neutral": "0.9692376255989075",
   "Mixed": "0.00016515344032086432"
 },
 {
   "tweet_id": "1589991243689660417",
   "Positive": "0.015883786603808403",
   "Negative": "6.060900341253728e-05",
   "Neutral": "0.9838742017745972",
   "Mixed": "0.0001814408169593662"
 },
 {
   "tweet_id": "1589991229219295232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991220901998593",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991219236855808",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589991218100178951",
   "Positive": "0.02661747857928276",
   "Negative": "0.23838162422180176",
   "Neutral": "0.7347055077552795",
   "Mixed": "0.0002954040246549994"
 },
 {
   "tweet_id": "1589991217760460805",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991217424904192",
   "Positive": "0.020288974046707153",
   "Negative": "7.086229015840217e-05",
   "Neutral": "0.9795097708702087",
   "Mixed": "0.00013043006765656173"
 },
 {
   "tweet_id": "1589991207060799488",
   "Positive": "0.11123935878276825",
   "Negative": "0.0008162289741449058",
   "Neutral": "0.887582540512085",
   "Mixed": "0.00036182411713525653"
 },
 {
   "tweet_id": "1589991198357598208",
   "Positive": "0.02393418550491333",
   "Negative": "9.43700215429999e-05",
   "Neutral": "0.9758607149124146",
   "Mixed": "0.0001107523639802821"
 },
 {
   "tweet_id": "1589991196566622210",
   "Positive": "0.6317914128303528",
   "Negative": "0.0012343400157988071",
   "Neutral": "0.3669099807739258",
   "Mixed": "6.428419146686792e-05"
 },
 {
   "tweet_id": "1589991187699863557",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991178099126272",
   "Positive": "0.013570917770266533",
   "Negative": "4.327468923293054e-05",
   "Neutral": "0.9862316250801086",
   "Mixed": "0.00015424306911882013"
 },
 {
   "tweet_id": "1589991166409584643",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991160180711424",
   "Positive": "0.022927042096853256",
   "Negative": "8.464273560093716e-05",
   "Neutral": "0.9765928983688354",
   "Mixed": "0.00039537943666800857"
 },
 {
   "tweet_id": "1589991155793817602",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991141164089345",
   "Positive": "0.018745534121990204",
   "Negative": "6.918066355865449e-05",
   "Neutral": "0.9809936285018921",
   "Mixed": "0.00019168345897924155"
 },
 {
   "tweet_id": "1589991140870475777",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589991135434674176",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589991134721642497",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991134318985216",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991134298009601",
   "Positive": "0.0009260876686312258",
   "Negative": "0.0006143765640445054",
   "Neutral": "0.9984579086303711",
   "Mixed": "1.7183494946948485e-06"
 },
 {
   "tweet_id": "1589991133832450049",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991122574913537",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589991122470076419",
   "Positive": "0.023285502567887306",
   "Negative": "6.245471013244241e-05",
   "Neutral": "0.9763950109481812",
   "Mixed": "0.00025703341816551983"
 },
 {
   "tweet_id": "1589991121878650881",
   "Positive": "0.02370269037783146",
   "Negative": "0.01532469317317009",
   "Neutral": "0.9570885300636292",
   "Mixed": "0.0038840770721435547"
 },
 {
   "tweet_id": "1589991119559217152",
   "Positive": "0.0010880125919356942",
   "Negative": "0.0002961337740998715",
   "Neutral": "0.998613715171814",
   "Mixed": "2.1602950255328324e-06"
 },
 {
   "tweet_id": "1589991101901205505",
   "Positive": "0.020418759435415268",
   "Negative": "5.687382144969888e-05",
   "Neutral": "0.9792078137397766",
   "Mixed": "0.00031657086219638586"
 },
 {
   "tweet_id": "1589991090115186692",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991085035909127",
   "Positive": "0.022510673850774765",
   "Negative": "6.0939193645026535e-05",
   "Neutral": "0.9771900773048401",
   "Mixed": "0.00023833801969885826"
 },
 {
   "tweet_id": "1589991075913297922",
   "Positive": "0.028393330052495003",
   "Negative": "6.505476630991325e-05",
   "Neutral": "0.9713268876075745",
   "Mixed": "0.00021482890588231385"
 },
 {
   "tweet_id": "1589991075120574467",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991069265321991",
   "Positive": "0.016126243397593498",
   "Negative": "5.047030936111696e-05",
   "Neutral": "0.9836852550506592",
   "Mixed": "0.00013804904301650822"
 },
 {
   "tweet_id": "1589991066249596928",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991063150014466",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589991057944883200",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589991055793221635",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589991051871551488",
   "Positive": "0.01722530834376812",
   "Negative": "5.084495569462888e-05",
   "Neutral": "0.9825535416603088",
   "Mixed": "0.0001702520385151729"
 },
 {
   "tweet_id": "1589991050571284482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991036994347008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991035614027776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991028177924097",
   "Positive": "0.0018751497846096754",
   "Negative": "0.0002216801658505574",
   "Neutral": "0.9978996515274048",
   "Mixed": "3.509200951157254e-06"
 },
 {
   "tweet_id": "1589991028060479491",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991016475467776",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991015842074625",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991015251079168",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991013732737026",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991012411539458",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589991008728653824",
   "Positive": "0.0007057923940010369",
   "Negative": "0.9567758440971375",
   "Neutral": "0.04218005761504173",
   "Mixed": "0.0003382573777344078"
 },
 {
   "tweet_id": "1589991003813195776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589991001653116929",
   "Positive": "0.004459760617464781",
   "Negative": "0.6561991572380066",
   "Neutral": "0.3372345566749573",
   "Mixed": "0.0021065121982246637"
 },
 {
   "tweet_id": "1589990998012485632",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990989921681409",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589990989057622017",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990985203093505",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990984586211328",
   "Positive": "0.041957613080739975",
   "Negative": "0.0024420698173344135",
   "Neutral": "0.9555890560150146",
   "Mixed": "1.1309291949146427e-05"
 },
 {
   "tweet_id": "1589990980933255169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990979788247040",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990967092064256",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589990941427134464",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990925136105478",
   "Positive": "0.07517693936824799",
   "Negative": "0.00015026541950646788",
   "Neutral": "0.9245955944061279",
   "Mixed": "7.717302651144564e-05"
 },
 {
   "tweet_id": "1589990918777892865",
   "Positive": "0.02349926345050335",
   "Negative": "6.159643089631572e-05",
   "Neutral": "0.9762628674507141",
   "Mixed": "0.00017637167184147984"
 },
 {
   "tweet_id": "1589990910846468096",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990909063892992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990903573516288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990902755651584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990891330371584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990890923520002",
   "Positive": "0.09083928912878036",
   "Negative": "0.0007725190371274948",
   "Neutral": "0.9083772897720337",
   "Mixed": "1.0978746104228776e-05"
 },
 {
   "tweet_id": "1589990888935026688",
   "Positive": "0.5046666860580444",
   "Negative": "0.07311368733644485",
   "Neutral": "0.40721288323402405",
   "Mixed": "0.015006765723228455"
 },
 {
   "tweet_id": "1589990882014826498",
   "Positive": "0.017698466777801514",
   "Negative": "0.00013270907220430672",
   "Neutral": "0.9821292757987976",
   "Mixed": "3.95628776459489e-05"
 },
 {
   "tweet_id": "1589990881826070529",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589990855766867971",
   "Positive": "0.021867599338293076",
   "Negative": "5.8645142416935414e-05",
   "Neutral": "0.9779399037361145",
   "Mixed": "0.00013386650243774056"
 },
 {
   "tweet_id": "1589990851790671873",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589990848842043392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990844261859329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990827593699329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990819582578690",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990815115653121",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990810820685826",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990802247536640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990794882347008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990787970142208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990782819532801",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589990780558774272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990780416167937",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990775840206848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990746475884544",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990740209565696",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990737591926786",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1589990722455076864",
   "Positive": "0.0028389403596520424",
   "Negative": "0.00010602019028738141",
   "Neutral": "0.9970491528511047",
   "Mixed": "5.992429123580223e-06"
 },
 {
   "tweet_id": "1589990711780577281",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990711432466433",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990710807523329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990709779918848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990706390929408",
   "Positive": "0.5015510320663452",
   "Negative": "0.000997473718598485",
   "Neutral": "0.49739181995391846",
   "Mixed": "5.957735265837982e-05"
 },
 {
   "tweet_id": "1589990705279119361",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990702166863873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990697738043394",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990692398698496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990691521720321",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990685427761152",
   "Positive": "0.12856025993824005",
   "Negative": "0.0016507524996995926",
   "Neutral": "0.8599294424057007",
   "Mixed": "0.009859547950327396"
 },
 {
   "tweet_id": "1589990674883317761",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589990663894204417",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990655673380864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990649214152704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990612073598976",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589990604096032768",
   "Positive": "0.4422052800655365",
   "Negative": "0.005498489830642939",
   "Neutral": "0.552018940448761",
   "Mixed": "0.0002772956504486501"
 },
 {
   "tweet_id": "1589990602187640832",
   "Positive": "0.05171849951148033",
   "Negative": "5.641864117933437e-05",
   "Neutral": "0.9477404356002808",
   "Mixed": "0.0004846265655942261"
 },
 {
   "tweet_id": "1589990591672496130",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990587083608064",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589990583279718402",
   "Positive": "0.001311077969148755",
   "Negative": "0.022593682631850243",
   "Neutral": "0.976088285446167",
   "Mixed": "6.979137651796918e-06"
 },
 {
   "tweet_id": "1589990576744812547",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990575646068736",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589990559342477312",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990549955973121",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589990543303770112",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589990519203332101",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990516753846275",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990515352948737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990512697679872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990493307695104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990488387436544",
   "Positive": "0.027149837464094162",
   "Negative": "0.007147195748984814",
   "Neutral": "0.9654859304428101",
   "Mixed": "0.00021701856167055666"
 },
 {
   "tweet_id": "1589990487586639872",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589990485393051648",
   "Positive": "0.016647420823574066",
   "Negative": "0.5782229900360107",
   "Neutral": "0.3940562605857849",
   "Mixed": "0.011073351837694645"
 },
 {
   "tweet_id": "1589990471497285633",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990470410989569",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990464408915968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990459547394048",
   "Positive": "0.07793921232223511",
   "Negative": "0.0011334848823025823",
   "Neutral": "0.920539140701294",
   "Mixed": "0.0003881874727085233"
 },
 {
   "tweet_id": "1589990442661466113",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589990423418015744",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589990422843383813",
   "Positive": "0.0028389403596520424",
   "Negative": "0.00010602019028738141",
   "Neutral": "0.9970491528511047",
   "Mixed": "5.992429123580223e-06"
 },
 {
   "tweet_id": "1589990419471142912",
   "Positive": "0.02648746222257614",
   "Negative": "5.0427435780875385e-05",
   "Neutral": "0.9731830358505249",
   "Mixed": "0.00027911009965464473"
 },
 {
   "tweet_id": "1589990394741526529",
   "Positive": "0.057319775223731995",
   "Negative": "0.08048516511917114",
   "Neutral": "0.8620384931564331",
   "Mixed": "0.00015646983229089528"
 },
 {
   "tweet_id": "1589990394309541891",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589990394083028992",
   "Positive": "0.9546181559562683",
   "Negative": "0.0005966841126792133",
   "Neutral": "0.044714197516441345",
   "Mixed": "7.09863961674273e-05"
 },
 {
   "tweet_id": "1589990390949883904",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990389775499264",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990388060000258",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990383354007558",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990377066749953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990364878090240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990362265030658",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990358171066368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990347693731840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990345806606337",
   "Positive": "0.9666414260864258",
   "Negative": "0.00018131440447177738",
   "Neutral": "0.0331357941031456",
   "Mixed": "4.1555220377631485e-05"
 },
 {
   "tweet_id": "1589990345752076288",
   "Positive": "0.07793921232223511",
   "Negative": "0.0011334848823025823",
   "Neutral": "0.920539140701294",
   "Mixed": "0.0003881874727085233"
 },
 {
   "tweet_id": "1589990344447643649",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990332619378690",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589990324700860417",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990316098359297",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1589990314361901058",
   "Positive": "0.045661285519599915",
   "Negative": "5.4516582167707384e-05",
   "Neutral": "0.9538186192512512",
   "Mixed": "0.000465512799564749"
 },
 {
   "tweet_id": "1589990304874393600",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589990302500392960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990298062819329",
   "Positive": "0.8042823076248169",
   "Negative": "0.002205334370955825",
   "Neutral": "0.1933521032333374",
   "Mixed": "0.0001603524578968063"
 },
 {
   "tweet_id": "1589990297211396097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990294304755715",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589990285995433985",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990285295357954",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589990280824254465",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990278290866177",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990276667707393",
   "Positive": "0.010480046272277832",
   "Negative": "0.241502046585083",
   "Neutral": "0.0864119827747345",
   "Mixed": "0.6616058945655823"
 },
 {
   "tweet_id": "1589990271621951488",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589990270128754688",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589990256111415302",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990246330273794",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990244996509698",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990241368428544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990229028798464",
   "Positive": "0.0696318969130516",
   "Negative": "5.74684381717816e-05",
   "Neutral": "0.9297018647193909",
   "Mixed": "0.0006087682559154928"
 },
 {
   "tweet_id": "1589990224905797632",
   "Positive": "0.04426877200603485",
   "Negative": "0.0005134220118634403",
   "Neutral": "0.9552008509635925",
   "Mixed": "1.693482590781059e-05"
 },
 {
   "tweet_id": "1589990199681224704",
   "Positive": "0.03158823028206825",
   "Negative": "0.004497426562011242",
   "Neutral": "0.9638953804969788",
   "Mixed": "1.8910373910330236e-05"
 },
 {
   "tweet_id": "1589990198909480961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990192219574272",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990183218614272",
   "Positive": "0.01200193166732788",
   "Negative": "0.29580411314964294",
   "Neutral": "0.6919596195220947",
   "Mixed": "0.00023436387709807605"
 },
 {
   "tweet_id": "1589990175349669888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990173584297984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990172162416640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990165657059330",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589990144370937857",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589990143628562432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990138461188097",
   "Positive": "0.05622167885303497",
   "Negative": "5.620339288725518e-05",
   "Neutral": "0.9431644082069397",
   "Mixed": "0.0005576338153332472"
 },
 {
   "tweet_id": "1589990136951242754",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990133679300608",
   "Positive": "0.002165835117921233",
   "Negative": "0.000466247001895681",
   "Neutral": "0.9973655343055725",
   "Mixed": "2.3289414912142092e-06"
 },
 {
   "tweet_id": "1589990132249407488",
   "Positive": "0.015440776012837887",
   "Negative": "0.0006163300713524222",
   "Neutral": "0.9839374423027039",
   "Mixed": "5.394470463215839e-06"
 },
 {
   "tweet_id": "1589990129753473024",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990127811833857",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990125806948352",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990125181620225",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990115136671744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990105682706432",
   "Positive": "0.0033512432128190994",
   "Negative": "0.00010641948028933257",
   "Neutral": "0.9965364933013916",
   "Mixed": "5.795041488454444e-06"
 },
 {
   "tweet_id": "1589990096971137025",
   "Positive": "0.6576926708221436",
   "Negative": "0.004128767643123865",
   "Neutral": "0.336586058139801",
   "Mixed": "0.0015925696352496743"
 },
 {
   "tweet_id": "1589990091103285249",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990089169727489",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990063554711553",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990062917562370",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589990060174512129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589990058891018240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990044311306240",
   "Positive": "0.0897199958562851",
   "Negative": "0.004288620315492153",
   "Neutral": "0.9059592485427856",
   "Mixed": "3.213538366253488e-05"
 },
 {
   "tweet_id": "1589990038409908226",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589990030285893633",
   "Positive": "0.17888522148132324",
   "Negative": "0.002373146591708064",
   "Neutral": "0.8186177015304565",
   "Mixed": "0.00012389187759254128"
 },
 {
   "tweet_id": "1589990015031209984",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589990007703769088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990004432211973",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589990003785887745",
   "Positive": "0.0024420307017862797",
   "Negative": "0.00024680246133357286",
   "Neutral": "0.9972749352455139",
   "Mixed": "3.625002864282578e-05"
 },
 {
   "tweet_id": "1589989997926838272",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989990351896576",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989989613731840",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989986732212224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989977429270528",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989976489725956",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989975592177666",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989974145110017",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989973327228929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989967723651073",
   "Positive": "0.05863059684634209",
   "Negative": "5.509225229616277e-05",
   "Neutral": "0.9407658576965332",
   "Mixed": "0.0005483946297317743"
 },
 {
   "tweet_id": "1589989964502413312",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989963038265345",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589989955769880577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989953483988992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989951399432192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989944508157953",
   "Positive": "0.09235148876905441",
   "Negative": "0.0005818928475491703",
   "Neutral": "0.9070559740066528",
   "Mixed": "1.0607040167087689e-05"
 },
 {
   "tweet_id": "1589989925906419712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989916569894912",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589989900086308868",
   "Positive": "0.030302342027425766",
   "Negative": "0.10224268585443497",
   "Neutral": "0.8673700094223022",
   "Mixed": "8.490219624945894e-05"
 },
 {
   "tweet_id": "1589989897414512640",
   "Positive": "0.05917461961507797",
   "Negative": "5.7188906794181094e-05",
   "Neutral": "0.9399750232696533",
   "Mixed": "0.0007930679712444544"
 },
 {
   "tweet_id": "1589989892201021440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989889755746305",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989889378246661",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989861708005376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989861456744448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989852107665411",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589989846860582912",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989829303230469",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989827856171009",
   "Positive": "0.030792610719799995",
   "Negative": "8.398784848395735e-05",
   "Neutral": "0.9690729975700378",
   "Mixed": "5.0358910812065005e-05"
 },
 {
   "tweet_id": "1589989822248398848",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589989818942951424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989817735352320",
   "Positive": "0.09042108803987503",
   "Negative": "0.03856972977519035",
   "Neutral": "0.8701806664466858",
   "Mixed": "0.0008285570656880736"
 },
 {
   "tweet_id": "1589989814593781762",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989813331333124",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989811011866628",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989810068156418",
   "Positive": "0.3656032681465149",
   "Negative": "0.002766846679151058",
   "Neutral": "0.6315956115722656",
   "Mixed": "3.422142981435172e-05"
 },
 {
   "tweet_id": "1589989804380676097",
   "Positive": "0.06790465116500854",
   "Negative": "5.922254058532417e-05",
   "Neutral": "0.9313105940818787",
   "Mixed": "0.0007255685632117093"
 },
 {
   "tweet_id": "1589989799334932480",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989794842411008",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989792091017216",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589989786353565702",
   "Positive": "0.9818750023841858",
   "Negative": "0.0001021582429530099",
   "Neutral": "0.01784183830022812",
   "Mixed": "0.00018094042025040835"
 },
 {
   "tweet_id": "1589989784763912193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989772944355329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989772420079617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989766178934791",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989756930490369",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589989753000427522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989751356260358",
   "Positive": "0.5334969758987427",
   "Negative": "0.0030029157642275095",
   "Neutral": "0.4633809030056",
   "Mixed": "0.00011926805746043101"
 },
 {
   "tweet_id": "1589989744985133057",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589989731945046018",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989720431292416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989719529918466",
   "Positive": "0.05531953647732735",
   "Negative": "5.613559915218502e-05",
   "Neutral": "0.944034993648529",
   "Mixed": "0.0005893486668355763"
 },
 {
   "tweet_id": "1589989708133986305",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589989700508737537",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989696998080512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989696553488385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989687707701254",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989681361719296",
   "Positive": "0.005703248083591461",
   "Negative": "0.24744334816932678",
   "Neutral": "0.5776506066322327",
   "Mixed": "0.1692027747631073"
 },
 {
   "tweet_id": "1589989681240113152",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989680044740609",
   "Positive": "0.7016639709472656",
   "Negative": "0.06506790965795517",
   "Neutral": "0.1926746815443039",
   "Mixed": "0.04059341549873352"
 },
 {
   "tweet_id": "1589989670695624705",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989658645393409",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989654555942913",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589989652358139915",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989646452555777",
   "Positive": "0.00015799907851032913",
   "Negative": "0.0009327539592050016",
   "Neutral": "0.9989007711410522",
   "Mixed": "8.458189768134616e-06"
 },
 {
   "tweet_id": "1589989646444146691",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989643625279489",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989636797263874",
   "Positive": "0.04757452383637428",
   "Negative": "5.2344228606671095e-05",
   "Neutral": "0.9518572092056274",
   "Mixed": "0.000515974999871105"
 },
 {
   "tweet_id": "1589989636633694209",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989635849322502",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989634310012928",
   "Positive": "0.050325144082307816",
   "Negative": "0.0008298536413349211",
   "Neutral": "0.9487404823303223",
   "Mixed": "0.00010451602429384366"
 },
 {
   "tweet_id": "1589989629650141185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989629494976512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989623476162560",
   "Positive": "0.09963417798280716",
   "Negative": "0.07617884129285812",
   "Neutral": "0.8240674734115601",
   "Mixed": "0.00011958044342463836"
 },
 {
   "tweet_id": "1589989619801767936",
   "Positive": "0.0008300802437588573",
   "Negative": "0.9527828693389893",
   "Neutral": "0.04623209685087204",
   "Mixed": "0.0001550426532048732"
 },
 {
   "tweet_id": "1589989618614960129",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989616102567936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989607600717825",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989606497619971",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989593663045633",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989592861929473",
   "Positive": "0.001253621419891715",
   "Negative": "0.6779018044471741",
   "Neutral": "0.3206928074359894",
   "Mixed": "0.00015167973469942808"
 },
 {
   "tweet_id": "1589989589955276801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989587757469702",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989575090266112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989573446103040",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989565775089666",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589989561727606785",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989558615408640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989555943673861",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989552198144000",
   "Positive": "0.042561158537864685",
   "Negative": "5.227109068073332e-05",
   "Neutral": "0.9568483233451843",
   "Mixed": "0.0005382351228035986"
 },
 {
   "tweet_id": "1589989545323696132",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989510531907584",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989509558857730",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989509248462848",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989508975833088",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989508585750530",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989506320502784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989495222304768",
   "Positive": "0.5645677447319031",
   "Negative": "0.0008941673440858722",
   "Neutral": "0.43448683619499207",
   "Mixed": "5.122477523400448e-05"
 },
 {
   "tweet_id": "1589989494048317450",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989494018965510",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989494014750722",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989493997961220",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989493989601282",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989481868066819",
   "Positive": "0.010037491098046303",
   "Negative": "0.00033464047010056674",
   "Neutral": "0.9895804524421692",
   "Mixed": "4.7478184569627047e-05"
 },
 {
   "tweet_id": "1589989475836649473",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589989471608786945",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989453728460800",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989442156363776",
   "Positive": "0.005638152826577425",
   "Negative": "0.005458841100335121",
   "Neutral": "0.9884454011917114",
   "Mixed": "0.00045765741379000247"
 },
 {
   "tweet_id": "1589989425315864577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989422757744640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989413760962560",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989392848154626",
   "Positive": "0.07709559053182602",
   "Negative": "6.02278123551514e-05",
   "Neutral": "0.921927273273468",
   "Mixed": "0.000916958088055253"
 },
 {
   "tweet_id": "1589989388372824064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989378793050113",
   "Positive": "0.00016549904830753803",
   "Negative": "0.05360225960612297",
   "Neutral": "0.9462166428565979",
   "Mixed": "1.5538695151917636e-05"
 },
 {
   "tweet_id": "1589989369494241281",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989367283847168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989344668168192",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589989340783919104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989315614240771",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989303366877184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989288183500801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989287906267141",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989286556102658",
   "Positive": "0.0007615585927851498",
   "Negative": "0.00029799353796988726",
   "Neutral": "0.9989381432533264",
   "Mixed": "2.203177245974075e-06"
 },
 {
   "tweet_id": "1589989279694229506",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989274686218242",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589989265479700481",
   "Positive": "0.006711738649755716",
   "Negative": "0.33416062593460083",
   "Neutral": "0.6577260494232178",
   "Mixed": "0.0014016326749697328"
 },
 {
   "tweet_id": "1589989261000183810",
   "Positive": "0.038698527961969376",
   "Negative": "5.385338590713218e-05",
   "Neutral": "0.9609748721122742",
   "Mixed": "0.00027275944012217224"
 },
 {
   "tweet_id": "1589989256537067521",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989256172572672",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989252066312192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989250795442177",
   "Positive": "0.21781349182128906",
   "Negative": "0.0010966237168759108",
   "Neutral": "0.7810509204864502",
   "Mixed": "3.894057954312302e-05"
 },
 {
   "tweet_id": "1589989236131844097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989235263959040",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989221980602368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989220160274432",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989204028948484",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989199167762432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989193127989250",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589989190284234752",
   "Positive": "0.07613760232925415",
   "Negative": "5.976270040264353e-05",
   "Neutral": "0.9231352210044861",
   "Mixed": "0.0006674145697616041"
 },
 {
   "tweet_id": "1589989188203851777",
   "Positive": "0.009112056344747543",
   "Negative": "0.3412063419818878",
   "Neutral": "0.649506151676178",
   "Mixed": "0.00017545379523653537"
 },
 {
   "tweet_id": "1589989184311525376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989167509180419",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989166443810817",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589989155324702721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989154326482944",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989147208392704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989130561552384",
   "Positive": "0.2733899652957916",
   "Negative": "0.04108721390366554",
   "Neutral": "0.6845180988311768",
   "Mixed": "0.0010046723764389753"
 },
 {
   "tweet_id": "1589989124198797313",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1589989121577037824",
   "Positive": "0.7259858250617981",
   "Negative": "0.000420347903855145",
   "Neutral": "0.2734859585762024",
   "Mixed": "0.000107871979707852"
 },
 {
   "tweet_id": "1589989117676638208",
   "Positive": "0.028923342004418373",
   "Negative": "7.3343537223991e-05",
   "Neutral": "0.9708141684532166",
   "Mixed": "0.0001892114814836532"
 },
 {
   "tweet_id": "1589989116858748930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989105156648960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589989096856104961",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989087125340162",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989085690884097",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989084797497344",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589989084382269440",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589989080053710849",
   "Positive": "0.03275825455784798",
   "Negative": "0.00022675193031318486",
   "Neutral": "0.9669675827026367",
   "Mixed": "4.7399553295690566e-05"
 },
 {
   "tweet_id": "1589989077314850816",
   "Positive": "0.14519789814949036",
   "Negative": "0.4110216200351715",
   "Neutral": "0.4389488697052002",
   "Mixed": "0.004831583704799414"
 },
 {
   "tweet_id": "1589989074546601984",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989070343909377",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989070343909376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989069731565568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989063775637506",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989063096139776",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589989054686564353",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1589989054380408837",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989051066900480",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989049796034561",
   "Positive": "0.2956930994987488",
   "Negative": "0.00037415369297377765",
   "Neutral": "0.703904926776886",
   "Mixed": "2.7822843549074605e-05"
 },
 {
   "tweet_id": "1589989033492766720",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589989029189423104",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589989029160050688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589989026052075521",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589989024193605632",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1589989017369849856",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589989014844887045",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988977377153024",
   "Positive": "0.013849512673914433",
   "Negative": "0.1534586101770401",
   "Neutral": "0.8321353197097778",
   "Mixed": "0.0005565357278101146"
 },
 {
   "tweet_id": "1589988974277578755",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988970754342913",
   "Positive": "0.005490039009600878",
   "Negative": "0.010606490075588226",
   "Neutral": "0.9838786125183105",
   "Mixed": "2.480863440723624e-05"
 },
 {
   "tweet_id": "1589988969470922753",
   "Positive": "0.0032091664616018534",
   "Negative": "0.5578493475914001",
   "Neutral": "0.43811652064323425",
   "Mixed": "0.0008249318925663829"
 },
 {
   "tweet_id": "1589988947350138880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988947056562176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988944065998849",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988933542506496",
   "Positive": "0.06080738827586174",
   "Negative": "5.5644079111516476e-05",
   "Neutral": "0.9386488199234009",
   "Mixed": "0.00048805339611135423"
 },
 {
   "tweet_id": "1589988923685867522",
   "Positive": "0.11716733127832413",
   "Negative": "0.00018435441597830504",
   "Neutral": "0.880232036113739",
   "Mixed": "0.002416264032945037"
 },
 {
   "tweet_id": "1589988905813954562",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589988902219440128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988898310352901",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988881164042241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988879410823168",
   "Positive": "0.47441044449806213",
   "Negative": "0.007262048311531544",
   "Neutral": "0.5182790160179138",
   "Mixed": "4.8469501052750275e-05"
 },
 {
   "tweet_id": "1589988878278356993",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988876223119361",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589988875073880065",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988871177375745",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988863975751680",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589988863334060035",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589988859190050816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988852911206400",
   "Positive": "0.05134833976626396",
   "Negative": "0.008361272513866425",
   "Neutral": "0.940274178981781",
   "Mixed": "1.6230334949796088e-05"
 },
 {
   "tweet_id": "1589988850965049345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988844971372544",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589988821835583489",
   "Positive": "0.021067187190055847",
   "Negative": "5.272628550301306e-05",
   "Neutral": "0.9782228469848633",
   "Mixed": "0.0006571796839125454"
 },
 {
   "tweet_id": "1589988821344870400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988809042956289",
   "Positive": "0.0014235101407393813",
   "Negative": "0.00045626406790688634",
   "Neutral": "0.99811851978302",
   "Mixed": "1.7711982991386321e-06"
 },
 {
   "tweet_id": "1589988797441544193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988792307699712",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589988789359095808",
   "Positive": "0.0645887553691864",
   "Negative": "5.759592022513971e-05",
   "Neutral": "0.9348527789115906",
   "Mixed": "0.0005007765139453113"
 },
 {
   "tweet_id": "1589988785541984256",
   "Positive": "0.0897199958562851",
   "Negative": "0.004288620315492153",
   "Neutral": "0.9059592485427856",
   "Mixed": "3.213538366253488e-05"
 },
 {
   "tweet_id": "1589988781066973186",
   "Positive": "0.023930765688419342",
   "Negative": "5.869748201803304e-05",
   "Neutral": "0.9757277965545654",
   "Mixed": "0.000282725493889302"
 },
 {
   "tweet_id": "1589988773861158913",
   "Positive": "0.0019675688818097115",
   "Negative": "0.8049333691596985",
   "Neutral": "0.1836490035057068",
   "Mixed": "0.00945013202726841"
 },
 {
   "tweet_id": "1589988771071922177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988770706702336",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988770400858118",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988770233069568",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988767448076290",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589988766940561408",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988763421532160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988761404047360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988759776657409",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988745230503937",
   "Positive": "0.00944232102483511",
   "Negative": "0.00036084497696720064",
   "Neutral": "0.9901935458183289",
   "Mixed": "3.3414630706829485e-06"
 },
 {
   "tweet_id": "1589988736145969153",
   "Positive": "0.0018895688699558377",
   "Negative": "7.456748426193371e-05",
   "Neutral": "0.9979612827301025",
   "Mixed": "7.464895315933973e-05"
 },
 {
   "tweet_id": "1589988729694744577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988708752642048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988703744970752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988701727526913",
   "Positive": "0.0016780720325186849",
   "Negative": "0.6984689831733704",
   "Neutral": "0.2998170256614685",
   "Mixed": "3.590886262827553e-05"
 },
 {
   "tweet_id": "1589988699525509120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988698971844609",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988693032722434",
   "Positive": "0.03412289917469025",
   "Negative": "5.444760972750373e-05",
   "Neutral": "0.9654656052589417",
   "Mixed": "0.0003570473927538842"
 },
 {
   "tweet_id": "1589988690423865344",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589988681770618881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988681448058881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988679585787904",
   "Positive": "0.43612194061279297",
   "Negative": "0.031026285141706467",
   "Neutral": "0.5327281951904297",
   "Mixed": "0.00012355460785329342"
 },
 {
   "tweet_id": "1589988661130833921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988660304572419",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988655967657987",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988654642253826",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988644387172353",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988641484722177",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589988641442390016",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589988628477845509",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988623864442882",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988613466750979",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988612275331072",
   "Positive": "0.050904810428619385",
   "Negative": "0.088496632874012",
   "Neutral": "0.8603870868682861",
   "Mixed": "0.00021153903799131513"
 },
 {
   "tweet_id": "1589988612027944961",
   "Positive": "0.007417615037411451",
   "Negative": "0.0001291414664592594",
   "Neutral": "0.992447018623352",
   "Mixed": "6.143720383988693e-06"
 },
 {
   "tweet_id": "1589988609138253824",
   "Positive": "0.027526341378688812",
   "Negative": "4.9589933041715994e-05",
   "Neutral": "0.9721747040748596",
   "Mixed": "0.0002493190113455057"
 },
 {
   "tweet_id": "1589988606705553409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988602637086721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988587596316672",
   "Positive": "0.00360693596303463",
   "Negative": "0.27621105313301086",
   "Neutral": "0.7200798988342285",
   "Mixed": "0.00010219241812592372"
 },
 {
   "tweet_id": "1589988569917296640",
   "Positive": "0.011130564846098423",
   "Negative": "5.084143049316481e-05",
   "Neutral": "0.9888020753860474",
   "Mixed": "1.6459331163787283e-05"
 },
 {
   "tweet_id": "1589988557074337795",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988550136991744",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589988547448418307",
   "Positive": "0.005390569567680359",
   "Negative": "0.00014681580069009215",
   "Neutral": "0.9944576025009155",
   "Mixed": "5.002795205655275e-06"
 },
 {
   "tweet_id": "1589988546185932800",
   "Positive": "0.4854165315628052",
   "Negative": "0.028200339525938034",
   "Neutral": "0.48628169298171997",
   "Mixed": "0.00010151714377570897"
 },
 {
   "tweet_id": "1589988540946882561",
   "Positive": "0.9755902886390686",
   "Negative": "0.00014611470396630466",
   "Neutral": "0.02387099340558052",
   "Mixed": "0.00039263488724827766"
 },
 {
   "tweet_id": "1589988539147915266",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988532793528320",
   "Positive": "0.15489067137241364",
   "Negative": "0.0021354281343519688",
   "Neutral": "0.842963695526123",
   "Mixed": "1.0263836884405464e-05"
 },
 {
   "tweet_id": "1589988531535253504",
   "Positive": "0.05471472442150116",
   "Negative": "6.210184801602736e-05",
   "Neutral": "0.9448897242546082",
   "Mixed": "0.0003334462526254356"
 },
 {
   "tweet_id": "1589988521598910466",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1589988518188634113",
   "Positive": "0.02145322971045971",
   "Negative": "0.001185168861411512",
   "Neutral": "0.9773579835891724",
   "Mixed": "3.577478310035076e-06"
 },
 {
   "tweet_id": "1589988503898947584",
   "Positive": "0.19127938151359558",
   "Negative": "0.045230161398649216",
   "Neutral": "0.7633915543556213",
   "Mixed": "9.89149120869115e-05"
 },
 {
   "tweet_id": "1589988503793766400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988494193348610",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988488149340162",
   "Positive": "0.48835840821266174",
   "Negative": "0.0029360917396843433",
   "Neutral": "0.506083071231842",
   "Mixed": "0.0026224448811262846"
 },
 {
   "tweet_id": "1589988487671197699",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988477025677315",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988475134447617",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988455966453762",
   "Positive": "0.4750109016895294",
   "Negative": "0.002062056912109256",
   "Neutral": "0.5185592770576477",
   "Mixed": "0.004367764573544264"
 },
 {
   "tweet_id": "1589988449486278656",
   "Positive": "0.02450616843998432",
   "Negative": "5.864004197064787e-05",
   "Neutral": "0.9752992987632751",
   "Mixed": "0.0001359535672236234"
 },
 {
   "tweet_id": "1589988433686302721",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589988433329786881",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988431786303490",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988425184456704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988407580954624",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988397141348353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988395161628676",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589988390077767680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988382473883648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988370734014467",
   "Positive": "0.08644498139619827",
   "Negative": "0.043671831488609314",
   "Neutral": "0.8697620630264282",
   "Mixed": "0.00012115106073906645"
 },
 {
   "tweet_id": "1589988360340504582",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589988357060571136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988347229114374",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589988346582880257",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988342980304897",
   "Positive": "0.03695583716034889",
   "Negative": "4.840319888899103e-05",
   "Neutral": "0.9626769423484802",
   "Mixed": "0.00031879308517090976"
 },
 {
   "tweet_id": "1589988342904815620",
   "Positive": "0.6562807559967041",
   "Negative": "0.004196514841169119",
   "Neutral": "0.3394457995891571",
   "Mixed": "7.695694512221962e-05"
 },
 {
   "tweet_id": "1589988338010042368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988330808446978",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988330057650177",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988327356510208",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589988325011914752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988324911251457",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988306041077760",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589988296972996608",
   "Positive": "0.08330415189266205",
   "Negative": "0.00010838059097295627",
   "Neutral": "0.9164886474609375",
   "Mixed": "9.878239507088438e-05"
 },
 {
   "tweet_id": "1589988285543485440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988274986438656",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988270167195648",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589988266497175557",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988247324983296",
   "Positive": "0.05899086967110634",
   "Negative": "5.5862161389086396e-05",
   "Neutral": "0.9404100179672241",
   "Mixed": "0.0005432977341115475"
 },
 {
   "tweet_id": "1589988239146110976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988226848419842",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589988220112351232",
   "Positive": "0.026468368247151375",
   "Negative": "0.4571578800678253",
   "Neutral": "0.5147289037704468",
   "Mixed": "0.001644890638999641"
 },
 {
   "tweet_id": "1589988200856313857",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1589988188310757376",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988187073835008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988177619873792",
   "Positive": "0.040102578699588776",
   "Negative": "5.3268369811121374e-05",
   "Neutral": "0.9595287442207336",
   "Mixed": "0.00031550490530207753"
 },
 {
   "tweet_id": "1589988176356995072",
   "Positive": "0.0004664049483835697",
   "Negative": "0.00012151396367698908",
   "Neutral": "0.999403715133667",
   "Mixed": "8.316707862832118e-06"
 },
 {
   "tweet_id": "1589988175619186690",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988163979972608",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988157726294017",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589988152911228929",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988152382717953",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988135022505986",
   "Positive": "0.01827258989214897",
   "Negative": "4.720661308965646e-05",
   "Neutral": "0.9814451336860657",
   "Mixed": "0.00023509202583227307"
 },
 {
   "tweet_id": "1589988121193877504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988113002409987",
   "Positive": "0.03183163329958916",
   "Negative": "5.028546365792863e-05",
   "Neutral": "0.9677851796150208",
   "Mixed": "0.000333006726577878"
 },
 {
   "tweet_id": "1589988111802839040",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988081557725184",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589988080425242626",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589988077803810818",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988065451585536",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988064092639232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988064000372738",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589988054189895681",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988050741776384",
   "Positive": "0.001993471058085561",
   "Negative": "0.00034927946398966014",
   "Neutral": "0.9976533055305481",
   "Mixed": "3.969402769143926e-06"
 },
 {
   "tweet_id": "1589988050574389248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589988048611442691",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589988044094197760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988043653779459",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589988038079545347",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589988030974406656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589988017485516801",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1589988009864499201",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589988006899122178",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987999546486785",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589987990969147393",
   "Positive": "0.019122662022709846",
   "Negative": "0.5357078313827515",
   "Neutral": "0.4449806809425354",
   "Mixed": "0.00018887798069044948"
 },
 {
   "tweet_id": "1589987985696894977",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987984165998594",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987983960444928",
   "Positive": "0.0019770744256675243",
   "Negative": "0.001565670478157699",
   "Neutral": "0.9964311122894287",
   "Mixed": "2.6073474145960063e-05"
 },
 {
   "tweet_id": "1589987983541014529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987977383772160",
   "Positive": "0.01846807822585106",
   "Negative": "4.7303921746788546e-05",
   "Neutral": "0.9812459945678711",
   "Mixed": "0.000238670822000131"
 },
 {
   "tweet_id": "1589987959733776385",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987958069026816",
   "Positive": "0.02942138910293579",
   "Negative": "0.0014452257892116904",
   "Neutral": "0.9691288471221924",
   "Mixed": "4.515947239269735e-06"
 },
 {
   "tweet_id": "1589987945200914434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987944240414720",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987940260012034",
   "Positive": "0.0410253182053566",
   "Negative": "0.0007916000904515386",
   "Neutral": "0.9579705595970154",
   "Mixed": "0.00021247140830382705"
 },
 {
   "tweet_id": "1589987931519070208",
   "Positive": "0.0019770744256675243",
   "Negative": "0.001565670478157699",
   "Neutral": "0.9964311122894287",
   "Mixed": "2.6073474145960063e-05"
 },
 {
   "tweet_id": "1589987929664892929",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987927412867075",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987923893837827",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987905384386560",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987905342431233",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987904843288577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987902750347266",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987890712702977",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589987886300303360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987880306294784",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1589987879681675265",
   "Positive": "0.6058818697929382",
   "Negative": "0.005928029306232929",
   "Neutral": "0.3860665559768677",
   "Mixed": "0.002123576123267412"
 },
 {
   "tweet_id": "1589987879232876545",
   "Positive": "0.6225774884223938",
   "Negative": "0.0038430283311754465",
   "Neutral": "0.36642658710479736",
   "Mixed": "0.007152935024350882"
 },
 {
   "tweet_id": "1589987874988261376",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589987873536999424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987866775388161",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1589987866444435456",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987862556332034",
   "Positive": "0.0019770744256675243",
   "Negative": "0.001565670478157699",
   "Neutral": "0.9964311122894287",
   "Mixed": "2.6073474145960063e-05"
 },
 {
   "tweet_id": "1589987862434709505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987845300580353",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589987842381316096",
   "Positive": "0.029637018218636513",
   "Negative": "0.00020707368094008416",
   "Neutral": "0.969611644744873",
   "Mixed": "0.0005442029796540737"
 },
 {
   "tweet_id": "1589987837654749184",
   "Positive": "0.7699216604232788",
   "Negative": "0.011589758098125458",
   "Neutral": "0.2173231989145279",
   "Mixed": "0.0011654114350676537"
 },
 {
   "tweet_id": "1589987833720483840",
   "Positive": "0.12763220071792603",
   "Negative": "0.00015035353135317564",
   "Neutral": "0.8720837235450745",
   "Mixed": "0.00013366955681703985"
 },
 {
   "tweet_id": "1589987831984029696",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987830607978496",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589987829706547202",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987822421028864",
   "Positive": "0.0019770744256675243",
   "Negative": "0.001565670478157699",
   "Neutral": "0.9964311122894287",
   "Mixed": "2.6073474145960063e-05"
 },
 {
   "tweet_id": "1589987821070471169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987810542780421",
   "Positive": "0.0019770744256675243",
   "Negative": "0.001565670478157699",
   "Neutral": "0.9964311122894287",
   "Mixed": "2.6073474145960063e-05"
 },
 {
   "tweet_id": "1589987807652519936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987796105986048",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987793690058752",
   "Positive": "0.004887547809630632",
   "Negative": "0.0013787717325612903",
   "Neutral": "0.9937278032302856",
   "Mixed": "5.933205102337524e-06"
 },
 {
   "tweet_id": "1589987781245571074",
   "Positive": "0.04173734784126282",
   "Negative": "6.622361252084374e-05",
   "Neutral": "0.9578200578689575",
   "Mixed": "0.00037629762664437294"
 },
 {
   "tweet_id": "1589987776375947265",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987761511337984",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589987757807788033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987756536901632",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987755320147969",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589987748001501185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987743425499136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987743068979200",
   "Positive": "0.00047940106014721096",
   "Negative": "0.40412572026252747",
   "Neutral": "0.5953420996665955",
   "Mixed": "5.2808100008405745e-05"
 },
 {
   "tweet_id": "1589987742007824388",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987727407476737",
   "Positive": "0.8923323154449463",
   "Negative": "0.0015855433885008097",
   "Neutral": "0.10604899376630783",
   "Mixed": "3.319331517559476e-05"
 },
 {
   "tweet_id": "1589987723771015169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987721573199873",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987721434759169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987720545603584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987719526371329",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987717261426688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987715432722432",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589987714132512768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987703487369216",
   "Positive": "0.04303450137376785",
   "Negative": "0.24363495409488678",
   "Neutral": "0.7112146615982056",
   "Mixed": "0.0021158186718821526"
 },
 {
   "tweet_id": "1589987696013094913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987691763945473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987687997767680",
   "Positive": "0.015884237363934517",
   "Negative": "4.783358235727064e-05",
   "Neutral": "0.9839153289794922",
   "Mixed": "0.0001525987172499299"
 },
 {
   "tweet_id": "1589987687666135041",
   "Positive": "0.547311007976532",
   "Negative": "0.0009944610064849257",
   "Neutral": "0.45154064893722534",
   "Mixed": "0.0001539110962767154"
 },
 {
   "tweet_id": "1589987683233075202",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987678707404803",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589987675452633089",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987672525017089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987662697746432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987654997020672",
   "Positive": "0.015753617510199547",
   "Negative": "4.4979449739912525e-05",
   "Neutral": "0.9840572476387024",
   "Mixed": "0.00014421495143324137"
 },
 {
   "tweet_id": "1589987649934479360",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987642871263234",
   "Positive": "0.10417590290307999",
   "Negative": "0.05776191130280495",
   "Neutral": "0.647867739200592",
   "Mixed": "0.1901945024728775"
 },
 {
   "tweet_id": "1589987637158633472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987629554024448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987624181125120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987622914789379",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987619253137408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987618435248128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987615981600768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987612823277568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987611204284417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987610973589506",
   "Positive": "0.04484272748231888",
   "Negative": "6.404081068467349e-05",
   "Neutral": "0.9546368718147278",
   "Mixed": "0.0004563375550787896"
 },
 {
   "tweet_id": "1589987605843628032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987604132696064",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987601850970114",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987588190113792",
   "Positive": "0.06377751380205154",
   "Negative": "0.045721251517534256",
   "Neutral": "0.03428611904382706",
   "Mixed": "0.8562150597572327"
 },
 {
   "tweet_id": "1589987581911273473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987570502733825",
   "Positive": "0.9892027974128723",
   "Negative": "5.4297895985655487e-05",
   "Neutral": "0.010678973980247974",
   "Mixed": "6.397196557372808e-05"
 },
 {
   "tweet_id": "1589987570444046336",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987562076372992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987557139697665",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589987554912534531",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589987547077148672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987545601179652",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987544393216000",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987527716667392",
   "Positive": "0.0007684982847422361",
   "Negative": "0.9742178320884705",
   "Neutral": "0.02443903684616089",
   "Mixed": "0.0005746046081185341"
 },
 {
   "tweet_id": "1589987523199393793",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987508162809861",
   "Positive": "0.029486527666449547",
   "Negative": "6.052173921489157e-05",
   "Neutral": "0.9702267050743103",
   "Mixed": "0.00022626011923421174"
 },
 {
   "tweet_id": "1589987493239468032",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987454584750080",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987452152053761",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987445743185921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987443398578176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987440575795201",
   "Positive": "0.03836191073060036",
   "Negative": "6.376079545589164e-05",
   "Neutral": "0.9612630605697632",
   "Mixed": "0.0003113274578936398"
 },
 {
   "tweet_id": "1589987438919045124",
   "Positive": "0.707284152507782",
   "Negative": "0.005614373832941055",
   "Neutral": "0.28489965200424194",
   "Mixed": "0.0022017741575837135"
 },
 {
   "tweet_id": "1589987437241335808",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987435341316102",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987434338873345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987414613037056",
   "Positive": "0.016301028430461884",
   "Negative": "5.3307921916712075e-05",
   "Neutral": "0.9835208654403687",
   "Mixed": "0.00012477148266043514"
 },
 {
   "tweet_id": "1589987413740621824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987407444656128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987406715158528",
   "Positive": "0.8666702508926392",
   "Negative": "0.0020715573336929083",
   "Neutral": "0.13121718168258667",
   "Mixed": "4.099122452316806e-05"
 },
 {
   "tweet_id": "1589987405125529600",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589987405117153280",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589987395386347520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987393524072451",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589987389954752512",
   "Positive": "0.01270230207592249",
   "Negative": "7.503791130147874e-05",
   "Neutral": "0.9871472120285034",
   "Mixed": "7.542548701167107e-05"
 },
 {
   "tweet_id": "1589987388503490561",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987388444790784",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987387681435652",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589987373169139712",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987369784344576",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589987368115007495",
   "Positive": "0.033236369490623474",
   "Negative": "6.094484342611395e-05",
   "Neutral": "0.9664719104766846",
   "Mixed": "0.00023071918985806406"
 },
 {
   "tweet_id": "1589987362473664513",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987361957765120",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987357985763329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987353606733826",
   "Positive": "0.09877188503742218",
   "Negative": "0.015100033953785896",
   "Neutral": "0.8860532641410828",
   "Mixed": "7.489975541830063e-05"
 },
 {
   "tweet_id": "1589987349664268290",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987348607283200",
   "Positive": "0.0010707264300435781",
   "Negative": "0.42815306782722473",
   "Neutral": "0.5707538723945618",
   "Mixed": "2.24052037083311e-05"
 },
 {
   "tweet_id": "1589987346073935873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987340734590977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987332815728640",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987330781491200",
   "Positive": "0.5541945099830627",
   "Negative": "0.003560709534212947",
   "Neutral": "0.44063901901245117",
   "Mixed": "0.0016058131586760283"
 },
 {
   "tweet_id": "1589987327933571075",
   "Positive": "0.0036434237845242023",
   "Negative": "0.7014610171318054",
   "Neutral": "0.2910009026527405",
   "Mixed": "0.00389465712942183"
 },
 {
   "tweet_id": "1589987307117228032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987304248311814",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987302365069312",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589987299609415681",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987298418241538",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589987296908312577",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1589987296518209540",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987277283143680",
   "Positive": "0.12022680789232254",
   "Negative": "0.0001430669944966212",
   "Neutral": "0.8794957995414734",
   "Mixed": "0.0001343368348898366"
 },
 {
   "tweet_id": "1589987275827740672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987274246467584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987273483112449",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589987263618121728",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987261437087744",
   "Positive": "0.03582439944148064",
   "Negative": "6.575822771992534e-05",
   "Neutral": "0.9638302326202393",
   "Mixed": "0.0002795992186293006"
 },
 {
   "tweet_id": "1589987250687070208",
   "Positive": "0.047147806733846664",
   "Negative": "0.00031388047500513494",
   "Neutral": "0.9523297548294067",
   "Mixed": "0.00020849888096563518"
 },
 {
   "tweet_id": "1589987246190788609",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589987243451908096",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1589987236455813122",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987235944103936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987229526818823",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987220651642880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987212451794945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987210946019330",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987205317296128",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589987205040472064",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589987203115286528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987200347033600",
   "Positive": "0.0005295648006722331",
   "Negative": "0.00027733691968023777",
   "Neutral": "0.9991893172264099",
   "Mixed": "3.764992698052083e-06"
 },
 {
   "tweet_id": "1589987196580528128",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589987192281366528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987186245439490",
   "Positive": "0.008061403408646584",
   "Negative": "0.0019444766221567988",
   "Neutral": "0.9899623990058899",
   "Mixed": "3.174525045324117e-05"
 },
 {
   "tweet_id": "1589987179513925633",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589987177852964864",
   "Positive": "0.9893084764480591",
   "Negative": "0.00010491526336409152",
   "Neutral": "0.010531063191592693",
   "Mixed": "5.545601743506268e-05"
 },
 {
   "tweet_id": "1589987172199059456",
   "Positive": "0.01145110558718443",
   "Negative": "0.002029197523370385",
   "Neutral": "0.986504316329956",
   "Mixed": "1.537360185466241e-05"
 },
 {
   "tweet_id": "1589987171922251776",
   "Positive": "0.016463318839669228",
   "Negative": "5.280567711452022e-05",
   "Neutral": "0.983323872089386",
   "Mixed": "0.00016003170458134264"
 },
 {
   "tweet_id": "1589987169799909376",
   "Positive": "0.012592418119311333",
   "Negative": "4.540365989669226e-05",
   "Neutral": "0.9872494339942932",
   "Mixed": "0.00011275665747234598"
 },
 {
   "tweet_id": "1589987167786659840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987163076456448",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987157699334145",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987144051093505",
   "Positive": "0.3585428297519684",
   "Negative": "0.082060806453228",
   "Neutral": "0.5591396689414978",
   "Mixed": "0.0002567639166954905"
 },
 {
   "tweet_id": "1589987137944195072",
   "Positive": "0.011981490068137646",
   "Negative": "4.279747008695267e-05",
   "Neutral": "0.9878567457199097",
   "Mixed": "0.00011894832277903333"
 },
 {
   "tweet_id": "1589987133716045826",
   "Positive": "0.015253647230565548",
   "Negative": "0.830301821231842",
   "Neutral": "0.14512759447097778",
   "Mixed": "0.009316932410001755"
 },
 {
   "tweet_id": "1589987130490904577",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987126472740865",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589987114170871813",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987113646559232",
   "Positive": "0.0410253182053566",
   "Negative": "0.0007916000904515386",
   "Neutral": "0.9579705595970154",
   "Mixed": "0.00021247140830382705"
 },
 {
   "tweet_id": "1589987111666864129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987108844101635",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987108340760578",
   "Positive": "0.01286426279693842",
   "Negative": "4.3110227124998346e-05",
   "Neutral": "0.9869592785835266",
   "Mixed": "0.00013326076441444457"
 },
 {
   "tweet_id": "1589987093132218371",
   "Positive": "0.03658604249358177",
   "Negative": "6.406394095392898e-05",
   "Neutral": "0.9630758166313171",
   "Mixed": "0.0002740127092693001"
 },
 {
   "tweet_id": "1589987078913159169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987078468947969",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589987076795420673",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987071078600707",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987068658462721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987066716516352",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589987049075265539",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589987044801253376",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589987043345850368",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987041772974081",
   "Positive": "0.022658787667751312",
   "Negative": "5.779967250418849e-05",
   "Neutral": "0.9771241545677185",
   "Mixed": "0.00015922637248877436"
 },
 {
   "tweet_id": "1589987039491280896",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589987038979567616",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589987036341374976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987034172489728",
   "Positive": "0.0004083089588675648",
   "Negative": "0.00015657434414606541",
   "Neutral": "0.9994321465492249",
   "Mixed": "3.011516810147441e-06"
 },
 {
   "tweet_id": "1589987027168415745",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987018876276738",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589987013029400576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589987008822546433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589987007249682433",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986995505627137",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986990103330816",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589986985896472577",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986984281657344",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589986966426112001",
   "Positive": "0.003667406039312482",
   "Negative": "0.000248508236836642",
   "Neutral": "0.9960814118385315",
   "Mixed": "2.7009652967535658e-06"
 },
 {
   "tweet_id": "1589986965440851968",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986961947004929",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986957790445571",
   "Positive": "0.003984909038990736",
   "Negative": "0.23400457203388214",
   "Neutral": "0.7619020342826843",
   "Mixed": "0.0001084317482309416"
 },
 {
   "tweet_id": "1589986952941830144",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986943173283840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986938257567744",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986935283814402",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986926635147264",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986926450208769",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986913519566848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986912261279744",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986910055075841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986909727883264",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986907790135296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986903415173120",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589986883022782465",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986880921403394",
   "Positive": "0.000353379815351218",
   "Negative": "0.9676474332809448",
   "Neutral": "0.03198039531707764",
   "Mixed": "1.8885786630562507e-05"
 },
 {
   "tweet_id": "1589986879403065344",
   "Positive": "0.07199354469776154",
   "Negative": "0.0001983598922379315",
   "Neutral": "0.927071750164032",
   "Mixed": "0.0007363567128777504"
 },
 {
   "tweet_id": "1589986879252070401",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986876647419904",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986876609683457",
   "Positive": "0.973818302154541",
   "Negative": "9.932722605299205e-05",
   "Neutral": "0.02605573832988739",
   "Mixed": "2.6607578547555022e-05"
 },
 {
   "tweet_id": "1589986869970104326",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1589986869328367618",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986863485685762",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986857429110786",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986855503925248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986851695493121",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986827834122241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986825128800256",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986821886578688",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986816559828993",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986815867428911",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986810880741376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986806363467781",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986799216365570",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589986798331400193",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986795789643776",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986781868740610",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589986779528302593",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986775480795138",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986772628680704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986771558838272",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986768853819398",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986759701856257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986756556124160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986747639025664",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986745914834945",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589986742308065280",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986736926781440",
   "Positive": "0.019177109003067017",
   "Negative": "5.0108217692468315e-05",
   "Neutral": "0.980614960193634",
   "Mixed": "0.00015785884170327336"
 },
 {
   "tweet_id": "1589986730114879489",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986724838789121",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986723521769473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986722623807488",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986715254812675",
   "Positive": "0.07199354469776154",
   "Negative": "0.0001983598922379315",
   "Neutral": "0.927071750164032",
   "Mixed": "0.0007363567128777504"
 },
 {
   "tweet_id": "1589986713031835648",
   "Positive": "0.019267702475190163",
   "Negative": "5.1709372201003134e-05",
   "Neutral": "0.9805101156234741",
   "Mixed": "0.00017047900473698974"
 },
 {
   "tweet_id": "1589986711169220608",
   "Positive": "0.5600337386131287",
   "Negative": "0.00018448261835146695",
   "Neutral": "0.4397217333316803",
   "Mixed": "5.999340646667406e-05"
 },
 {
   "tweet_id": "1589986693364736000",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1589986686317907968",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986684888027136",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986682283360256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986659114053632",
   "Positive": "0.013044887222349644",
   "Negative": "0.4848008453845978",
   "Neutral": "0.4992147982120514",
   "Mixed": "0.002939449157565832"
 },
 {
   "tweet_id": "1589986655267885057",
   "Positive": "0.020628347992897034",
   "Negative": "5.271864574751817e-05",
   "Neutral": "0.9791468977928162",
   "Mixed": "0.0001720934233162552"
 },
 {
   "tweet_id": "1589986650104692736",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986645625180166",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986640759762944",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589986636498350081",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589986635160375297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986634162122753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986626545291265",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986625169522688",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589986616101466115",
   "Positive": "0.0005595629336312413",
   "Negative": "0.00016400656022597104",
   "Neutral": "0.9992732405662537",
   "Mixed": "3.259439381508855e-06"
 },
 {
   "tweet_id": "1589986615501680641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986613689458688",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986605498269696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986602272845824",
   "Positive": "0.7566168904304504",
   "Negative": "0.00016307005716953427",
   "Neutral": "0.2431626319885254",
   "Mixed": "5.734066871809773e-05"
 },
 {
   "tweet_id": "1589986598221156352",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986594978955265",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589986593045352448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986590553612288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986585851793408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986582555406338",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986571276910598",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589986570320236544",
   "Positive": "0.24353022873401642",
   "Negative": "0.0010467246174812317",
   "Neutral": "0.7553831338882446",
   "Mixed": "3.9966715121408924e-05"
 },
 {
   "tweet_id": "1589986569838288901",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986569813123072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986568860995584",
   "Positive": "0.019340282306075096",
   "Negative": "4.7795965656405315e-05",
   "Neutral": "0.980354368686676",
   "Mixed": "0.0002575851685833186"
 },
 {
   "tweet_id": "1589986554000207872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986544982425600",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589986541476384768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986540394250240",
   "Positive": "0.017462873831391335",
   "Negative": "4.8448058805661276e-05",
   "Neutral": "0.982367753982544",
   "Mixed": "0.00012093992700101808"
 },
 {
   "tweet_id": "1589986517296246786",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986515131981825",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986494030450694",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589986491782291461",
   "Positive": "0.07112450897693634",
   "Negative": "0.4909440577030182",
   "Neutral": "0.4359204173088074",
   "Mixed": "0.002011100295931101"
 },
 {
   "tweet_id": "1589986487231139841",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986485595701250",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986485096583170",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986485063016449",
   "Positive": "0.7704932689666748",
   "Negative": "0.00021660287166014314",
   "Neutral": "0.22926399111747742",
   "Mixed": "2.622100873850286e-05"
 },
 {
   "tweet_id": "1589986476993187843",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589986476020072453",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589986465303658500",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986464716460032",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986460966719489",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986452892700672",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986448627101696",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986447112929280",
   "Positive": "0.00237412191927433",
   "Negative": "0.4714389741420746",
   "Neutral": "0.5261296033859253",
   "Mixed": "5.7249275414505973e-05"
 },
 {
   "tweet_id": "1589986446374752256",
   "Positive": "0.005263263825327158",
   "Negative": "0.004700988531112671",
   "Neutral": "0.9900256395339966",
   "Mixed": "1.019497631205013e-05"
 },
 {
   "tweet_id": "1589986440783749120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986430025359361",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986422161047552",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589986414141530114",
   "Positive": "0.024808235466480255",
   "Negative": "6.0350219428073615e-05",
   "Neutral": "0.9749339818954468",
   "Mixed": "0.0001974648766918108"
 },
 {
   "tweet_id": "1589986413877284864",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1589986410823839745",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986408244326400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986395812421633",
   "Positive": "0.01140708290040493",
   "Negative": "4.528837962425314e-05",
   "Neutral": "0.9884612560272217",
   "Mixed": "8.635220001451671e-05"
 },
 {
   "tweet_id": "1589986391790088192",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589986381497257984",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589986380834557953",
   "Positive": "0.011631217785179615",
   "Negative": "0.8017932772636414",
   "Neutral": "0.17378397285938263",
   "Mixed": "0.012791546061635017"
 },
 {
   "tweet_id": "1589986377646559233",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986376535023616",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986368973078529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986368008384515",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589986367248834560",
   "Positive": "0.0013113678433001041",
   "Negative": "0.0002774455933831632",
   "Neutral": "0.9984089732170105",
   "Mixed": "2.2888461899128743e-06"
 },
 {
   "tweet_id": "1589986365932179457",
   "Positive": "0.012245350517332554",
   "Negative": "4.3632291635731235e-05",
   "Neutral": "0.9876070618629456",
   "Mixed": "0.00010394554556114599"
 },
 {
   "tweet_id": "1589986343698194432",
   "Positive": "0.006019596476107836",
   "Negative": "0.0032824533991515636",
   "Neutral": "0.9906911253929138",
   "Mixed": "6.895599653944373e-06"
 },
 {
   "tweet_id": "1589986342658011142",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589986336614010880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986336383328256",
   "Positive": "0.01442008838057518",
   "Negative": "5.1603983592940494e-05",
   "Neutral": "0.9854492545127869",
   "Mixed": "7.911361899459735e-05"
 },
 {
   "tweet_id": "1589986329752129538",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589986326094680064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986321132843010",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986311850819586",
   "Positive": "0.027004757896065712",
   "Negative": "6.087771544116549e-05",
   "Neutral": "0.9727379679679871",
   "Mixed": "0.0001964045804925263"
 },
 {
   "tweet_id": "1589986310739333121",
   "Positive": "0.016039671376347542",
   "Negative": "4.742323653772473e-05",
   "Neutral": "0.9838148951530457",
   "Mixed": "9.803792636375874e-05"
 },
 {
   "tweet_id": "1589986309124521984",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986307929149444",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589986304338841601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986301503496193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986288731815936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986285594513409",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986280355794946",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589986266019696643",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986251138289669",
   "Positive": "0.020893024280667305",
   "Negative": "5.631110616377555e-05",
   "Neutral": "0.9788891077041626",
   "Mixed": "0.0001615137589396909"
 },
 {
   "tweet_id": "1589986240837095424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986235313197059",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986227616632832",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986227151073281",
   "Positive": "0.036738213151693344",
   "Negative": "0.07683878391981125",
   "Neutral": "0.8862519860267639",
   "Mixed": "0.00017105872393585742"
 },
 {
   "tweet_id": "1589986223384186881",
   "Positive": "0.007376911584287882",
   "Negative": "0.046448882669210434",
   "Neutral": "0.9461665153503418",
   "Mixed": "7.708768862357829e-06"
 },
 {
   "tweet_id": "1589986222210191362",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986220230475776",
   "Positive": "0.0049755629152059555",
   "Negative": "0.1686585396528244",
   "Neutral": "0.8260539770126343",
   "Mixed": "0.0003118868626188487"
 },
 {
   "tweet_id": "1589986215851622400",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986201435791360",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589986195424956416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986189616222210",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986186000756738",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986181865177088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986180342644737",
   "Positive": "0.1342664659023285",
   "Negative": "0.00016684376168996096",
   "Neutral": "0.865405797958374",
   "Mixed": "0.00016084704839158803"
 },
 {
   "tweet_id": "1589986172763504642",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589986167474511877",
   "Positive": "0.010521819815039635",
   "Negative": "0.00055266625713557",
   "Neutral": "0.9889149069786072",
   "Mixed": "1.0648456736817025e-05"
 },
 {
   "tweet_id": "1589986167185117185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986165775806465",
   "Positive": "0.9971293807029724",
   "Negative": "7.306014595087618e-05",
   "Neutral": "0.0027841986157000065",
   "Mixed": "1.3290339666127693e-05"
 },
 {
   "tweet_id": "1589986162940473345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986162005118979",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986156770656261",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589986147970985986",
   "Positive": "0.07566910982131958",
   "Negative": "0.01415045466274023",
   "Neutral": "0.9099516868591309",
   "Mixed": "0.00022873279522173107"
 },
 {
   "tweet_id": "1589986143684415488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986137732714496",
   "Positive": "0.002821873174980283",
   "Negative": "0.009216894395649433",
   "Neutral": "0.9878684282302856",
   "Mixed": "9.283872350351885e-05"
 },
 {
   "tweet_id": "1589986130338144256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986126974291970",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589986126114480128",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986123186843648",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986120544444416",
   "Positive": "0.016524294391274452",
   "Negative": "4.313915633247234e-05",
   "Neutral": "0.9832721948623657",
   "Mixed": "0.00016039394540712237"
 },
 {
   "tweet_id": "1589986108334813185",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986107630182400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986099585515522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986097454796800",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986092417097728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986090680602632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986082900578304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986078320373762",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986077049491462",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589986071323938817",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986069780787201",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986063195705344",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986062121996288",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589986059483766785",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589986047009894401",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589986046431072259",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986039938306048",
   "Positive": "0.007827783934772015",
   "Negative": "0.002643877174705267",
   "Neutral": "0.9895067811012268",
   "Mixed": "2.1576905055553652e-05"
 },
 {
   "tweet_id": "1589986038902312963",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1589986029335097344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589986027372175362",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589986021445632000",
   "Positive": "0.20975136756896973",
   "Negative": "0.012220862321555614",
   "Neutral": "0.7779837250709534",
   "Mixed": "4.407159212860279e-05"
 },
 {
   "tweet_id": "1589986020187340801",
   "Positive": "0.04392482340335846",
   "Negative": "0.0006578677566722035",
   "Neutral": "0.9552915692329407",
   "Mixed": "0.0001257057156180963"
 },
 {
   "tweet_id": "1589986018874490880",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589986011274424320",
   "Positive": "0.0014867499703541398",
   "Negative": "0.00045060008415021",
   "Neutral": "0.9980587363243103",
   "Mixed": "3.967540578742046e-06"
 },
 {
   "tweet_id": "1589986006450651136",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589986003862769664",
   "Positive": "0.05705101788043976",
   "Negative": "0.0009304651175625622",
   "Neutral": "0.941979706287384",
   "Mixed": "3.8840877095935866e-05"
 },
 {
   "tweet_id": "1589985998985121792",
   "Positive": "0.0201557669788599",
   "Negative": "5.151068762643263e-05",
   "Neutral": "0.9795224666595459",
   "Mixed": "0.0002702281635720283"
 },
 {
   "tweet_id": "1589985988742631426",
   "Positive": "0.014580031856894493",
   "Negative": "0.049545180052518845",
   "Neutral": "0.9356429576873779",
   "Mixed": "0.0002318463084520772"
 },
 {
   "tweet_id": "1589985985294921729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985982111428608",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985981423579136",
   "Positive": "0.08606867492198944",
   "Negative": "0.05663859471678734",
   "Neutral": "0.856853723526001",
   "Mixed": "0.0004390432150103152"
 },
 {
   "tweet_id": "1589985980672798722",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589985975111147523",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985967406186496",
   "Positive": "0.019425103440880775",
   "Negative": "5.220547609496862e-05",
   "Neutral": "0.9803849458694458",
   "Mixed": "0.00013782409951090813"
 },
 {
   "tweet_id": "1589985962310119425",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985959357329410",
   "Positive": "0.1880001425743103",
   "Negative": "0.0005750808632001281",
   "Neutral": "0.8113827705383301",
   "Mixed": "4.193498898530379e-05"
 },
 {
   "tweet_id": "1589985956618276866",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985955355983872",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985955238191106",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985955150458881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985952851951619",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985946250129411",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985937584697345",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985935982088192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985930982862851",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985927950401537",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985926897598465",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985920157372417",
   "Positive": "0.17209278047084808",
   "Negative": "0.0007854761788621545",
   "Neutral": "0.8270978927612305",
   "Mixed": "2.3879461878095753e-05"
 },
 {
   "tweet_id": "1589985907679297539",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985906844643328",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985901081681920",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985896832847875",
   "Positive": "0.17494885623455048",
   "Negative": "0.006294099614024162",
   "Neutral": "0.8187357783317566",
   "Mixed": "2.130936991306953e-05"
 },
 {
   "tweet_id": "1589985894987333635",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985893016014849",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985881251016704",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589985880919674880",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985875840356353",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985874775011329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985873931931648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985865060999169",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985849172963328",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985833108795393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985828629282817",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589985821834158081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985814658023424",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985807246692352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985802213158912",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985800930103296",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985799919259648",
   "Positive": "0.0186099074780941",
   "Negative": "0.008027087897062302",
   "Neutral": "0.9733201265335083",
   "Mixed": "4.2888594180112705e-05"
 },
 {
   "tweet_id": "1589985798329626624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985795712364544",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985788854272002",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985784098328576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985782114439170",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985775562919937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985771192455169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985768336150537",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985761113169920",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985753094057985",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985752863363072",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985750254522368",
   "Positive": "0.16400761902332306",
   "Negative": "0.00019696295203175396",
   "Neutral": "0.8356329798698425",
   "Mixed": "0.00016242187120951712"
 },
 {
   "tweet_id": "1589985749432401927",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985731375951874",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985730503520263",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985730180571138",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985729853427712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985727475257344",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985724300165121",
   "Positive": "0.0005203850450925529",
   "Negative": "0.0001293411332881078",
   "Neutral": "0.9993470311164856",
   "Mixed": "3.213253194189747e-06"
 },
 {
   "tweet_id": "1589985720999227392",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985716989497346",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985716536111104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985715932499969",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985714426761218",
   "Positive": "0.14529666304588318",
   "Negative": "0.0007009481196291745",
   "Neutral": "0.8539313077926636",
   "Mixed": "7.108455611160025e-05"
 },
 {
   "tweet_id": "1589985714321915904",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589985710328909824",
   "Positive": "0.00367253040894866",
   "Negative": "0.04054173827171326",
   "Neutral": "0.9557618498802185",
   "Mixed": "2.389607107033953e-05"
 },
 {
   "tweet_id": "1589985709091606531",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985693505581059",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985692922568704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985690120773633",
   "Positive": "0.025746390223503113",
   "Negative": "5.4608248319709674e-05",
   "Neutral": "0.9739505648612976",
   "Mixed": "0.00024844618747010827"
 },
 {
   "tweet_id": "1589985686203293698",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985685217644546",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985671347056646",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589985671124766720",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985668767567877",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985663591804928",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985658575388672",
   "Positive": "0.016926420852541924",
   "Negative": "5.0306523917242885e-05",
   "Neutral": "0.9828231930732727",
   "Mixed": "0.0002001237589865923"
 },
 {
   "tweet_id": "1589985658403434497",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985658235670528",
   "Positive": "0.003667406039312482",
   "Negative": "0.000248508236836642",
   "Neutral": "0.9960814118385315",
   "Mixed": "2.7009652967535658e-06"
 },
 {
   "tweet_id": "1589985655182233600",
   "Positive": "0.013522505760192871",
   "Negative": "0.003536548698320985",
   "Neutral": "0.982913076877594",
   "Mixed": "2.7890342607861385e-05"
 },
 {
   "tweet_id": "1589985644235091971",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985643651686401",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1589985641932021761",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985641903042561",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985638493089792",
   "Positive": "0.003644108073785901",
   "Negative": "0.3111817240715027",
   "Neutral": "0.6850813031196594",
   "Mixed": "9.283524559577927e-05"
 },
 {
   "tweet_id": "1589985636077178880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985623011909632",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589985622126911489",
   "Positive": "0.01670094020664692",
   "Negative": "4.8780235374579206e-05",
   "Neutral": "0.9830507636070251",
   "Mixed": "0.00019955293100792915"
 },
 {
   "tweet_id": "1589985620059123713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985619991986178",
   "Positive": "0.6317914128303528",
   "Negative": "0.0012343400157988071",
   "Neutral": "0.3669099807739258",
   "Mixed": "6.428419146686792e-05"
 },
 {
   "tweet_id": "1589985616392962048",
   "Positive": "0.028332052752375603",
   "Negative": "0.0001655789092183113",
   "Neutral": "0.9714529514312744",
   "Mixed": "4.948945570504293e-05"
 },
 {
   "tweet_id": "1589985609955016705",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985607916621827",
   "Positive": "0.013983211480081081",
   "Negative": "0.007506723050028086",
   "Neutral": "0.9768196940422058",
   "Mixed": "0.001690370263531804"
 },
 {
   "tweet_id": "1589985606205345793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985602677919744",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985602258100231",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985599347642369",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985589511979008",
   "Positive": "0.01913182996213436",
   "Negative": "5.0174327043350786e-05",
   "Neutral": "0.9805911183357239",
   "Mixed": "0.0002268975367769599"
 },
 {
   "tweet_id": "1589985584302653443",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985582553305090",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985578296430594",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985577621147650",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985570557943811",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985568817287171",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985559141023744",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985559069745153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985553281585154",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985550374948866",
   "Positive": "0.0008224160992540419",
   "Negative": "0.9928523898124695",
   "Neutral": "0.006317040883004665",
   "Mixed": "8.038840860535856e-06"
 },
 {
   "tweet_id": "1589985538391429120",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985536571105280",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985536420515840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985531546697731",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985527348203520",
   "Positive": "0.01977061666548252",
   "Negative": "4.764586265082471e-05",
   "Neutral": "0.9799932837486267",
   "Mixed": "0.00018838464166037738"
 },
 {
   "tweet_id": "1589985512131289093",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985500215267328",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985497988091905",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985497363144708",
   "Positive": "0.021024737507104874",
   "Negative": "5.351828440325335e-05",
   "Neutral": "0.9786459803581238",
   "Mixed": "0.0002757814945653081"
 },
 {
   "tweet_id": "1589985483534536705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985479834759168",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985478924996609",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589985466983776256",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985463422844931",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985463028568064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985433462898688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985427515400193",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985427364384768",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985425283698688",
   "Positive": "0.0015958377625793219",
   "Negative": "0.11048362404108047",
   "Neutral": "0.887794554233551",
   "Mixed": "0.0001260631688637659"
 },
 {
   "tweet_id": "1589985395194105856",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589985393365389312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985391482114049",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985389947006983",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589985388386746368",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589985380551786499",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985380052348928",
   "Positive": "0.6317914128303528",
   "Negative": "0.0012343400157988071",
   "Neutral": "0.3669099807739258",
   "Mixed": "6.428419146686792e-05"
 },
 {
   "tweet_id": "1589985377217286147",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985370267348999",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985363304423425",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985360976941058",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985357692809216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985347131564033",
   "Positive": "0.02156902477145195",
   "Negative": "5.175512706045993e-05",
   "Neutral": "0.9782026410102844",
   "Mixed": "0.0001765423803590238"
 },
 {
   "tweet_id": "1589985344187170817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985343759323136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985334557040641",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985325065306112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985315808477186",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985315112226816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985314868985861",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985298603462656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985297257095169",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985292433649665",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985288260308994",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985287350128640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985285408186371",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985280760909825",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985278638587904",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985275685793794",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985269230764034",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985268970717185",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985260066177026",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985257767702530",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589985253971881989",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985244236902400",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589985240436858881",
   "Positive": "0.0184915941208601",
   "Negative": "4.687900218414143e-05",
   "Neutral": "0.981276273727417",
   "Mixed": "0.00018530151282902807"
 },
 {
   "tweet_id": "1589985239677689857",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985238696210436",
   "Positive": "0.11566650122404099",
   "Negative": "0.01837853156030178",
   "Neutral": "0.8659196496009827",
   "Mixed": "3.5337532608537003e-05"
 },
 {
   "tweet_id": "1589985237463093248",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985235537899521",
   "Positive": "0.9546181559562683",
   "Negative": "0.0005966841126792133",
   "Neutral": "0.044714197516441345",
   "Mixed": "7.09863961674273e-05"
 },
 {
   "tweet_id": "1589985235143626755",
   "Positive": "0.1368175446987152",
   "Negative": "0.003596136113628745",
   "Neutral": "0.8595640659332275",
   "Mixed": "2.219116322521586e-05"
 },
 {
   "tweet_id": "1589985228835422208",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985226432077826",
   "Positive": "0.0007065647514536977",
   "Negative": "0.9600922465324402",
   "Neutral": "0.036623671650886536",
   "Mixed": "0.0025776217225939035"
 },
 {
   "tweet_id": "1589985214088237056",
   "Positive": "0.8830761313438416",
   "Negative": "0.00011093359353253618",
   "Neutral": "0.11666850000619888",
   "Mixed": "0.00014440745871979743"
 },
 {
   "tweet_id": "1589985213706567681",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589985209034100736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985206802739200",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985199345250305",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985193213190145",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985189975175168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985180009500673",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985175873941504",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985173973897216",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985173235695617",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985163454590977",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985162820993029",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985161386819584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985158509522944",
   "Positive": "0.351180762052536",
   "Negative": "0.00014066090807318687",
   "Neutral": "0.6485201120376587",
   "Mixed": "0.00015847662871237844"
 },
 {
   "tweet_id": "1589985158459187203",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589985157725163526",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985156429156352",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985151110762499",
   "Positive": "0.0003803989675361663",
   "Negative": "0.00012491180677898228",
   "Neutral": "0.9994909763336182",
   "Mixed": "3.7182046526140766e-06"
 },
 {
   "tweet_id": "1589985148346707969",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985148044718082",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589985124766035969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985119636721665",
   "Positive": "0.020011737942695618",
   "Negative": "0.20920968055725098",
   "Neutral": "0.770740807056427",
   "Mixed": "3.776793528231792e-05"
 },
 {
   "tweet_id": "1589985118839791616",
   "Positive": "0.019160661846399307",
   "Negative": "4.8451376642333344e-05",
   "Neutral": "0.980297327041626",
   "Mixed": "0.000493650499265641"
 },
 {
   "tweet_id": "1589985114855202819",
   "Positive": "0.9215356111526489",
   "Negative": "0.00010256753012072295",
   "Neutral": "0.07831967622041702",
   "Mixed": "4.20618052885402e-05"
 },
 {
   "tweet_id": "1589985113034543104",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985111289765891",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985107984543747",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985099340451840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985098098962433",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985096316375042",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985091258036231",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1589985084643606529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985084484243457",
   "Positive": "0.023431645706295967",
   "Negative": "5.097030589240603e-05",
   "Neutral": "0.9762804508209229",
   "Mixed": "0.00023697530559729785"
 },
 {
   "tweet_id": "1589985082164400128",
   "Positive": "0.0013113678433001041",
   "Negative": "0.0002774455933831632",
   "Neutral": "0.9984089732170105",
   "Mixed": "2.2888461899128743e-06"
 },
 {
   "tweet_id": "1589985073088311296",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985067694444546",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589985063571460096",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985057951076354",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985051038449667",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589985049889628166",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985045196214274",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589985035670949888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589985034643312641",
   "Positive": "0.0051356214098632336",
   "Negative": "0.00010998724610544741",
   "Neutral": "0.9947082996368408",
   "Mixed": "4.61843483208213e-05"
 },
 {
   "tweet_id": "1589985015152410624",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985013801824257",
   "Positive": "0.4822312295436859",
   "Negative": "0.0012554751010611653",
   "Neutral": "0.5164064764976501",
   "Mixed": "0.00010688592738006264"
 },
 {
   "tweet_id": "1589985012409339905",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589985012052480001",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589985009997205505",
   "Positive": "0.0004278113192413002",
   "Negative": "0.00014543966972269118",
   "Neutral": "0.999423623085022",
   "Mixed": "3.0516832794091897e-06"
 },
 {
   "tweet_id": "1589985007334215681",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589985001147203584",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984995694628865",
   "Positive": "0.390741765499115",
   "Negative": "0.0003447318740654737",
   "Neutral": "0.6088664531707764",
   "Mixed": "4.6926852519391105e-05"
 },
 {
   "tweet_id": "1589984995203899392",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984990833803265",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589984976468312065",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984968998277120",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589984966557200390",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984959183613953",
   "Positive": "0.026340721175074577",
   "Negative": "5.45677867194172e-05",
   "Neutral": "0.9731379747390747",
   "Mixed": "0.0004666753811761737"
 },
 {
   "tweet_id": "1589984955593273345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984953991057414",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984953085091842",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984952762118144",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984950333616128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984939541291010",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984933334089729",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589984931119497217",
   "Positive": "0.03031737543642521",
   "Negative": "0.054091401398181915",
   "Neutral": "0.49542689323425293",
   "Mixed": "0.4201642572879791"
 },
 {
   "tweet_id": "1589984928472928258",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589984923968241665",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589984923511054336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984919996223489",
   "Positive": "0.03516325354576111",
   "Negative": "5.8917874412145466e-05",
   "Neutral": "0.9644694328308105",
   "Mixed": "0.0003083486226387322"
 },
 {
   "tweet_id": "1589984915126640641",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984903709732865",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984892875866113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984880834011136",
   "Positive": "0.03072763793170452",
   "Negative": "5.937075547990389e-05",
   "Neutral": "0.9686822295188904",
   "Mixed": "0.0005307836690917611"
 },
 {
   "tweet_id": "1589984879994753025",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589984878569062402",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984874622255104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984865327648774",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589984864589471746",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984861447950339",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984854992916482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984850437890048",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984846587187201",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589984839046139905",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984837460529154",
   "Positive": "0.08321845531463623",
   "Negative": "0.0003590267733670771",
   "Neutral": "0.9164066910743713",
   "Mixed": "1.5786914445925504e-05"
 },
 {
   "tweet_id": "1589984837028429825",
   "Positive": "0.5229352712631226",
   "Negative": "0.0012105923378840089",
   "Neutral": "0.4758113920688629",
   "Mixed": "4.266274117981084e-05"
 },
 {
   "tweet_id": "1589984835858501635",
   "Positive": "0.025320276618003845",
   "Negative": "5.585448525380343e-05",
   "Neutral": "0.9742273688316345",
   "Mixed": "0.00039643567288294435"
 },
 {
   "tweet_id": "1589984827755069442",
   "Positive": "0.44597747921943665",
   "Negative": "0.0010153207695111632",
   "Neutral": "0.5529685020446777",
   "Mixed": "3.870933142025024e-05"
 },
 {
   "tweet_id": "1589984825498537985",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589984822667390979",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984817520988162",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984814140362753",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984809295941633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984799779094529",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984795450576896",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984780648845313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984765905887232",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984764811186176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984758800715777",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589984757668282369",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589984747622920192",
   "Positive": "0.020300114527344704",
   "Negative": "4.812856423086487e-05",
   "Neutral": "0.9793314933776855",
   "Mixed": "0.00032026355620473623"
 },
 {
   "tweet_id": "1589984743960891393",
   "Positive": "0.15790289640426636",
   "Negative": "0.00022350414656102657",
   "Neutral": "0.841727614402771",
   "Mixed": "0.00014600642316509038"
 },
 {
   "tweet_id": "1589984740962357248",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1589984730056982528",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984713141555200",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589984711203786753",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984704006000640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984703813062657",
   "Positive": "0.0006730235763825476",
   "Negative": "0.3068383038043976",
   "Neutral": "0.6924754977226257",
   "Mixed": "1.3240787666290998e-05"
 },
 {
   "tweet_id": "1589984702538317824",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984701078720520",
   "Positive": "0.05806272104382515",
   "Negative": "0.13465428352355957",
   "Neutral": "0.13665471971035004",
   "Mixed": "0.6706281900405884"
 },
 {
   "tweet_id": "1589984700856406017",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984699963015170",
   "Positive": "0.27590399980545044",
   "Negative": "0.015989655628800392",
   "Neutral": "0.7078015208244324",
   "Mixed": "0.0003048499929718673"
 },
 {
   "tweet_id": "1589984688432910336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984686524502017",
   "Positive": "0.0007603307603858411",
   "Negative": "8.289729157695547e-05",
   "Neutral": "0.9991125464439392",
   "Mixed": "4.422301935846917e-05"
 },
 {
   "tweet_id": "1589984685970817024",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589984675510255618",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984672389697536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984665565540354",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984665561362433",
   "Positive": "0.025030003860592842",
   "Negative": "5.248033266980201e-05",
   "Neutral": "0.9745813012123108",
   "Mixed": "0.00033620503381825984"
 },
 {
   "tweet_id": "1589984665380999170",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984664206585858",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984661513830400",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984657978036226",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984653233954816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984649723678721",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984638428405763",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984632736387072",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984631981756427",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984631704915972",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984619256217601",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984615355535361",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984611223801856",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984609630294016",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984608862732290",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1589984607533158401",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589984599744335872",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984591355744256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984588851732480",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984573081157632",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984563992104960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984563211964417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984553787363329",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984536414539778",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984528084635649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984525769408513",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984514327314438",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984509491314688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984509134770177",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984508471713792",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589984507217973248",
   "Positive": "0.35117217898368835",
   "Negative": "0.01179684977978468",
   "Neutral": "0.6367590427398682",
   "Mixed": "0.00027197637245990336"
 },
 {
   "tweet_id": "1589984502495186944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984497227165696",
   "Positive": "0.5958027243614197",
   "Negative": "0.016223197802901268",
   "Neutral": "0.3877829313278198",
   "Mixed": "0.00019111194706056267"
 },
 {
   "tweet_id": "1589984488792412162",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589984478679769089",
   "Positive": "0.4534795582294464",
   "Negative": "0.002742049051448703",
   "Neutral": "0.543691098690033",
   "Mixed": "8.721363701624796e-05"
 },
 {
   "tweet_id": "1589984468873646080",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984465157500932",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984461525258243",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984460476649474",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984453115641859",
   "Positive": "0.033254340291023254",
   "Negative": "0.003355432068929076",
   "Neutral": "0.9633789658546448",
   "Mixed": "1.120186789194122e-05"
 },
 {
   "tweet_id": "1589984452922740738",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984452562022402",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984451773497344",
   "Positive": "0.37839391827583313",
   "Negative": "0.0023408865090459585",
   "Neutral": "0.6191736459732056",
   "Mixed": "9.150381811195984e-05"
 },
 {
   "tweet_id": "1589984446476062721",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984446153134080",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589984439052169222",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984427727532034",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589984421926817793",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984418911096833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984416734261248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984415765397505",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984414259642370",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984400762376193",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984394336698369",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984379702771713",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589984362761969666",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984361486901249",
   "Positive": "0.000904823187738657",
   "Negative": "0.0020828284323215485",
   "Neutral": "0.9970080256462097",
   "Mixed": "4.3833301788254175e-06"
 },
 {
   "tweet_id": "1589984358626365440",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984350548144129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984347725393921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984345221365760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984338564698113",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984332277743616",
   "Positive": "0.0018618565518409014",
   "Negative": "0.0001286844490095973",
   "Neutral": "0.9980048537254333",
   "Mixed": "4.64679305878235e-06"
 },
 {
   "tweet_id": "1589984328909729792",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984324472168449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984319132807168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984317698355203",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589984315878043649",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984311117508610",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589984310891016192",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984303739731968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984303517421568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984271741353984",
   "Positive": "0.03296855464577675",
   "Negative": "5.096575478091836e-05",
   "Neutral": "0.9666973352432251",
   "Mixed": "0.00028313216171227396"
 },
 {
   "tweet_id": "1589984267375108099",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984265986805762",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984262979129345",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984262492631040",
   "Positive": "0.07623741775751114",
   "Negative": "0.0008389622089453042",
   "Neutral": "0.9229108691215515",
   "Mixed": "1.2803128811356146e-05"
 },
 {
   "tweet_id": "1589984259695341569",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589984256050487296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984238174343169",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984234835709953",
   "Positive": "0.6317914128303528",
   "Negative": "0.0012343400157988071",
   "Neutral": "0.3669099807739258",
   "Mixed": "6.428419146686792e-05"
 },
 {
   "tweet_id": "1589984229152423936",
   "Positive": "0.040509194135665894",
   "Negative": "0.00018009752966463566",
   "Neutral": "0.9592980742454529",
   "Mixed": "1.2635416169359814e-05"
 },
 {
   "tweet_id": "1589984226853945344",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984212559724544",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984208172519431",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984203055431680",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984202917040129",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1589984202530779136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984201864269825",
   "Positive": "0.033911846578121185",
   "Negative": "4.858497413806617e-05",
   "Neutral": "0.9658020734786987",
   "Mixed": "0.000237513508182019"
 },
 {
   "tweet_id": "1589984191810506752",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984185854595074",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984174483861505",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984169311887362",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1589984161070481408",
   "Positive": "0.6212942600250244",
   "Negative": "0.0006497023859992623",
   "Neutral": "0.3780060410499573",
   "Mixed": "5.002123361919075e-05"
 },
 {
   "tweet_id": "1589984158968795136",
   "Positive": "0.25193923711776733",
   "Negative": "0.010622983798384666",
   "Neutral": "0.7075056433677673",
   "Mixed": "0.02993212826550007"
 },
 {
   "tweet_id": "1589984158289256448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984152904138755",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589984151268360194",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984150811193345",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589984149792002048",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984148671803393",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984148571455488",
   "Positive": "0.020887339487671852",
   "Negative": "0.19182544946670532",
   "Neutral": "0.7869170904159546",
   "Mixed": "0.0003700547676999122"
 },
 {
   "tweet_id": "1589984144939180032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984129491537920",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984127075618816",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984125553111042",
   "Positive": "0.025500504299998283",
   "Negative": "0.001144086360000074",
   "Neutral": "0.9726793169975281",
   "Mixed": "0.0006760800606571138"
 },
 {
   "tweet_id": "1589984124592586752",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589984123770515461",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984101087744000",
   "Positive": "0.022928625345230103",
   "Negative": "4.9664173275232315e-05",
   "Neutral": "0.9768816828727722",
   "Mixed": "0.0001400047040078789"
 },
 {
   "tweet_id": "1589984099628113921",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984099368067074",
   "Positive": "0.0009433111408725381",
   "Negative": "0.0003716358041856438",
   "Neutral": "0.9986820816993713",
   "Mixed": "2.953611556222313e-06"
 },
 {
   "tweet_id": "1589984095106633732",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984091956740096",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984064815370240",
   "Positive": "0.15908682346343994",
   "Negative": "0.18827086687088013",
   "Neutral": "0.5927636027336121",
   "Mixed": "0.05987873300909996"
 },
 {
   "tweet_id": "1589984059966754816",
   "Positive": "0.3506641089916229",
   "Negative": "0.0010275726672261953",
   "Neutral": "0.6479105353355408",
   "Mixed": "0.00039776231278665364"
 },
 {
   "tweet_id": "1589984058578468865",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984056527458304",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984055000715264",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589984047681327104",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984047002189824",
   "Positive": "0.0009494819096289575",
   "Negative": "0.0005750908167101443",
   "Neutral": "0.9984287619590759",
   "Mixed": "4.6702229155926034e-05"
 },
 {
   "tweet_id": "1589984043705458689",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984042560389128",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984042312953857",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984041713139715",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984038261252096",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984029180596225",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984028991827968",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589984028647915525",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589984013544206341",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589984006355156992",
   "Positive": "0.04423064365983009",
   "Negative": "6.959956226637587e-05",
   "Neutral": "0.955415666103363",
   "Mixed": "0.00028405815828591585"
 },
 {
   "tweet_id": "1589984004002185218",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983997072809987",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983995697037313",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983991540875264",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589983959223783428",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983954853322753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983953867673600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983948163416066",
   "Positive": "0.04651516675949097",
   "Negative": "0.0012146536028012633",
   "Neutral": "0.9521986246109009",
   "Mixed": "7.156832725740969e-05"
 },
 {
   "tweet_id": "1589983946540199939",
   "Positive": "0.07343356311321259",
   "Negative": "5.7889646996045485e-05",
   "Neutral": "0.925743818283081",
   "Mixed": "0.000764785276260227"
 },
 {
   "tweet_id": "1589983941351866372",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983938063527937",
   "Positive": "0.032929427921772",
   "Negative": "0.05448240786790848",
   "Neutral": "0.03741946443915367",
   "Mixed": "0.8751687407493591"
 },
 {
   "tweet_id": "1589983929658126337",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983925744852994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983921730568192",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983919289831425",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589983915150016512",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983907554164737",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983907214430209",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983904051912704",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983902885871623",
   "Positive": "0.021113738417625427",
   "Negative": "0.00021768294391222298",
   "Neutral": "0.9786620736122131",
   "Mixed": "6.544899861182785e-06"
 },
 {
   "tweet_id": "1589983896388927488",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589983893243199490",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983889850003457",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983889417965568",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983882874494976",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983868148658176",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983856962449414",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983850478080000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983849693708288",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983845084196864",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589983844866088960",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983844492771329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983843448414208",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983841036677120",
   "Positive": "0.6223730444908142",
   "Negative": "0.0014765971573069692",
   "Neutral": "0.3761295974254608",
   "Mixed": "2.0685638446593657e-05"
 },
 {
   "tweet_id": "1589983840235585536",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589983825593274368",
   "Positive": "0.12162735313177109",
   "Negative": "0.00035085828858427703",
   "Neutral": "0.8779559135437012",
   "Mixed": "6.585437949979678e-05"
 },
 {
   "tweet_id": "1589983806974742530",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983806165258243",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983804902768641",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983799936700416",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983792806367233",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983782966525958",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983781087481856",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983778134335488",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983777006440448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983770312343553",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589983763110727683",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983762427019267",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983762133045248",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983758635368448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983750850768897",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983748384518145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983736116170752",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983734199357442",
   "Positive": "0.1769532710313797",
   "Negative": "0.004491952247917652",
   "Neutral": "0.8185291886329651",
   "Mixed": "2.559011227276642e-05"
 },
 {
   "tweet_id": "1589983734073556994",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983728251863043",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983725843931138",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983722316537858",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983715798978561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983715408871424",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983712430944256",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983711202013184",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589983711109713921",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983708253409285",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983704780525568",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1589983701404094464",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983692549914625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983679933476864",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983676540280833",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983668944392194",
   "Positive": "0.6826430559158325",
   "Negative": "0.007065739016979933",
   "Neutral": "0.3097933232784271",
   "Mixed": "0.000497841858305037"
 },
 {
   "tweet_id": "1589983664209014784",
   "Positive": "0.030460383743047714",
   "Negative": "7.83917639637366e-05",
   "Neutral": "0.9692599773406982",
   "Mixed": "0.00020131950441282243"
 },
 {
   "tweet_id": "1589983662547664898",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983656483119104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983654474027009",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983648904019969",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983647863828480",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589983644881653766",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1589983634249101312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983629144653824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983620714090498",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983617169915904",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983610408296448",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983609766957056",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983607992426496",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983601692528640",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983598207434756",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983594369683458",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589983593216212992",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983590657720322",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983584831823873",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589983584747933700",
   "Positive": "0.009899434633553028",
   "Negative": "0.4898703396320343",
   "Neutral": "0.4941364824771881",
   "Mixed": "0.006093797739595175"
 },
 {
   "tweet_id": "1589983581845143557",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589983577990922240",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983571401658368",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983569635860482",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983568843145217",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983566339133440",
   "Positive": "0.006272288504987955",
   "Negative": "0.31297287344932556",
   "Neutral": "0.6434128284454346",
   "Mixed": "0.03734198585152626"
 },
 {
   "tweet_id": "1589983564304875525",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983554486042625",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983552149499906",
   "Positive": "0.20897109806537628",
   "Negative": "0.000294256373308599",
   "Neutral": "0.7903484106063843",
   "Mixed": "0.000386181432986632"
 },
 {
   "tweet_id": "1589983549213462528",
   "Positive": "0.0011674333363771439",
   "Negative": "0.00025920025655068457",
   "Neutral": "0.9985706806182861",
   "Mixed": "2.5997387638199143e-06"
 },
 {
   "tweet_id": "1589983538526400512",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983536437952513",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983535641034753",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983535280291840",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983534231719936",
   "Positive": "0.46124380826950073",
   "Negative": "0.0005662901676259935",
   "Neutral": "0.5381180644035339",
   "Mixed": "7.184803689597175e-05"
 },
 {
   "tweet_id": "1589983530834350081",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983528951087105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983522496077824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983513222447106",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589983513054703617",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983510097371136",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1589983509510180867",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983508126380032",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983506566111233",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983503898542080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983503470690304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983502715727873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983500605607936",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983488874229760",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983486462812162",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983485732999168",
   "Positive": "0.07173573970794678",
   "Negative": "0.5628747940063477",
   "Neutral": "0.3643128573894501",
   "Mixed": "0.001076666871085763"
 },
 {
   "tweet_id": "1589983474177691648",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983471128018944",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983466195931136",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983460290330626",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983456418988034",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983455898525699",
   "Positive": "0.00035367230884730816",
   "Negative": "0.00028269615722820163",
   "Neutral": "0.9993602633476257",
   "Mixed": "3.3262479064433137e-06"
 },
 {
   "tweet_id": "1589983455781453824",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983453671436291",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983445769682947",
   "Positive": "0.390741765499115",
   "Negative": "0.0003447318740654737",
   "Neutral": "0.6088664531707764",
   "Mixed": "4.6926852519391105e-05"
 },
 {
   "tweet_id": "1589983443097903105",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983441877356552",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983438492569600",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983435409743872",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983433635164160",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983423384297472",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983420272107521",
   "Positive": "0.997633695602417",
   "Negative": "0.00010131738963536918",
   "Neutral": "0.002255437895655632",
   "Mixed": "9.558835699863266e-06"
 },
 {
   "tweet_id": "1589983415608446976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983409413423107",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589983405764378625",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983404707418112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983391046569985",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589983381789773825",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983378782457856",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983375745748992",
   "Positive": "0.9990155696868896",
   "Negative": "7.867534441174939e-05",
   "Neutral": "0.0008961528656072915",
   "Mixed": "9.490577213000506e-06"
 },
 {
   "tweet_id": "1589983374101590016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983356556431363",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589983350629969920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983343449284608",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983342916956163",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589983338676498432",
   "Positive": "0.005188649520277977",
   "Negative": "0.0003150792617816478",
   "Neutral": "0.9944873452186584",
   "Mixed": "8.863200491759926e-06"
 },
 {
   "tweet_id": "1589983337057488899",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589983334402510850",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983327729364998",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983307361816576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983297077379074",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983281923076097",
   "Positive": "0.00038123998092487454",
   "Negative": "0.004120436031371355",
   "Neutral": "0.9954956769943237",
   "Mixed": "2.6027230433101067e-06"
 },
 {
   "tweet_id": "1589983279654244352",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589983274537209856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983273866125312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983263762022400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983262143025152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983256593969154",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983234041188353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983209944940545",
   "Positive": "0.006633515004068613",
   "Negative": "0.031186101958155632",
   "Neutral": "0.962160050868988",
   "Mixed": "2.024794594035484e-05"
 },
 {
   "tweet_id": "1589983208632127489",
   "Positive": "0.02495616488158703",
   "Negative": "7.923061639303342e-05",
   "Neutral": "0.9748879671096802",
   "Mixed": "7.653346983715892e-05"
 },
 {
   "tweet_id": "1589983205842878465",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589983198473523200",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1589983190835290112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983185521508352",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589983182211776512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983180190519296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983159395192833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983153720283137",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589983144773840897",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1589983132488708096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983117821235200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983115124305922",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589983103476707331",
   "Positive": "0.002443584380671382",
   "Negative": "0.23749253153800964",
   "Neutral": "0.7591390609741211",
   "Mixed": "0.0009248880669474602"
 },
 {
   "tweet_id": "1589983094937104384",
   "Positive": "0.0021770235616713762",
   "Negative": "0.0001609392202226445",
   "Neutral": "0.9976565837860107",
   "Mixed": "5.488602255354635e-06"
 },
 {
   "tweet_id": "1589983093917908993",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589983088641454080",
   "Positive": "0.014863673597574234",
   "Negative": "0.00081600685371086",
   "Neutral": "0.9843119978904724",
   "Mixed": "8.355797945114318e-06"
 },
 {
   "tweet_id": "1589983081670508547",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589983075479392256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589983074292756480",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589983070324920321",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589983060799684609",
   "Positive": "0.0007994396728463471",
   "Negative": "0.00025524874217808247",
   "Neutral": "0.998939573764801",
   "Mixed": "5.703932856704341e-06"
 },
 {
   "tweet_id": "1589983045238812675",
   "Positive": "0.16924670338630676",
   "Negative": "0.00018187443492934108",
   "Neutral": "0.8303727507591248",
   "Mixed": "0.00019867790979333222"
 },
 {
   "tweet_id": "1589983037248655360",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589983014310023169",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589983011495636994",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589983005694603265",
   "Positive": "0.005067754071205854",
   "Negative": "0.019151821732521057",
   "Neutral": "0.975760281085968",
   "Mixed": "2.0069002857781015e-05"
 },
 {
   "tweet_id": "1589982975424606211",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589982947410874368",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589982932600762368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982919288061952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982913923551234",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982911163666433",
   "Positive": "0.0027120732702314854",
   "Negative": "0.00022149686992634088",
   "Neutral": "0.9970640540122986",
   "Mixed": "2.4266980744869215e-06"
 },
 {
   "tweet_id": "1589982886706704385",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982886362419200",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589982879903543303",
   "Positive": "0.004230699967592955",
   "Negative": "0.00024719780776649714",
   "Neutral": "0.995514452457428",
   "Mixed": "7.603865469718585e-06"
 },
 {
   "tweet_id": "1589982875599847427",
   "Positive": "0.010681651532649994",
   "Negative": "0.9344478845596313",
   "Neutral": "0.052588365972042084",
   "Mixed": "0.0022820166777819395"
 },
 {
   "tweet_id": "1589982875407245312",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589982860962041856",
   "Positive": "0.2480052411556244",
   "Negative": "0.0008460292592644691",
   "Neutral": "0.7509002089500427",
   "Mixed": "0.00024858940741978586"
 },
 {
   "tweet_id": "1589982857099087878",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982856910364673",
   "Positive": "0.004991811700165272",
   "Negative": "0.9710137248039246",
   "Neutral": "0.023874402046203613",
   "Mixed": "0.000120066775707528"
 },
 {
   "tweet_id": "1589982846470750214",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589982842255474689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982819450691584",
   "Positive": "0.0473177433013916",
   "Negative": "0.41541367769241333",
   "Neutral": "0.5370877981185913",
   "Mixed": "0.00018080520385410637"
 },
 {
   "tweet_id": "1589982798567583745",
   "Positive": "0.011058911681175232",
   "Negative": "0.0005189395160414279",
   "Neutral": "0.9884126782417297",
   "Mixed": "9.436010259378236e-06"
 },
 {
   "tweet_id": "1589982798332702722",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982795317010434",
   "Positive": "0.03143788129091263",
   "Negative": "0.0020617761183530092",
   "Neutral": "0.9664788246154785",
   "Mixed": "2.1537884094868787e-05"
 },
 {
   "tweet_id": "1589982789692461061",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982784164331523",
   "Positive": "0.06422945111989975",
   "Negative": "0.6929227113723755",
   "Neutral": "0.2419729083776474",
   "Mixed": "0.000874898920301348"
 },
 {
   "tweet_id": "1589982776811745280",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589982774815260673",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982769379438593",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982766321786882",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982762026819592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982742758191104",
   "Positive": "0.006518546491861343",
   "Negative": "0.013852881267666817",
   "Neutral": "0.9794124364852905",
   "Mixed": "0.0002161590673495084"
 },
 {
   "tweet_id": "1589982737565626368",
   "Positive": "0.8621442317962646",
   "Negative": "0.0006045096670277417",
   "Neutral": "0.13722646236419678",
   "Mixed": "2.4822908017085865e-05"
 },
 {
   "tweet_id": "1589982729512574977",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982725221457920",
   "Positive": "0.0733371302485466",
   "Negative": "0.03784850612282753",
   "Neutral": "0.8885492086410522",
   "Mixed": "0.0002652684925124049"
 },
 {
   "tweet_id": "1589982710441082880",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589982707970285568",
   "Positive": "0.2476910501718521",
   "Negative": "0.0008053214987739921",
   "Neutral": "0.7495374083518982",
   "Mixed": "0.0019662745762616396"
 },
 {
   "tweet_id": "1589982706435489797",
   "Positive": "0.47972577810287476",
   "Negative": "0.029968366026878357",
   "Neutral": "0.4900026321411133",
   "Mixed": "0.0003032066160812974"
 },
 {
   "tweet_id": "1589982698096906241",
   "Positive": "0.9473990201950073",
   "Negative": "0.0001636871020309627",
   "Neutral": "0.051946237683296204",
   "Mixed": "0.000491068116389215"
 },
 {
   "tweet_id": "1589982689691828224",
   "Positive": "0.01845433935523033",
   "Negative": "4.768720827996731e-05",
   "Neutral": "0.9812871813774109",
   "Mixed": "0.0002107725595124066"
 },
 {
   "tweet_id": "1589982642015178752",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982638659756032",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982637711851520",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982634817781760",
   "Positive": "0.2476910501718521",
   "Negative": "0.0008053214987739921",
   "Neutral": "0.7495374083518982",
   "Mixed": "0.0019662745762616396"
 },
 {
   "tweet_id": "1589982616362823686",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982613577805827",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589982596372779009",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982588319723522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982580178554881",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982578777686018",
   "Positive": "0.40873274207115173",
   "Negative": "0.009025252424180508",
   "Neutral": "0.582169771194458",
   "Mixed": "7.226793968584388e-05"
 },
 {
   "tweet_id": "1589982574738542592",
   "Positive": "0.018434882164001465",
   "Negative": "0.0009152879938483238",
   "Neutral": "0.9806220531463623",
   "Mixed": "2.772874904621858e-05"
 },
 {
   "tweet_id": "1589982572117135360",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589982570720428032",
   "Positive": "0.020480113103985786",
   "Negative": "0.0034109300468117",
   "Neutral": "0.9760817885398865",
   "Mixed": "2.7059042622568086e-05"
 },
 {
   "tweet_id": "1589982570070278147",
   "Positive": "0.0012959451414644718",
   "Negative": "0.8728393912315369",
   "Neutral": "0.12561088800430298",
   "Mixed": "0.000253749341936782"
 },
 {
   "tweet_id": "1589982567331401728",
   "Positive": "0.002869312884286046",
   "Negative": "0.05179876089096069",
   "Neutral": "0.9449893236160278",
   "Mixed": "0.00034253339981660247"
 },
 {
   "tweet_id": "1589982552294830081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982552152223744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982548951994372",
   "Positive": "0.7469437718391418",
   "Negative": "0.0010837579611688852",
   "Neutral": "0.22640161216259003",
   "Mixed": "0.02557087130844593"
 },
 {
   "tweet_id": "1589982539779047427",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982536502980609",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1589982535978995712",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982526101409792",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589982511681396737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982504001613824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589982502864977921",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589982470686277634",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589982466953351170",
   "Positive": "0.19396346807479858",
   "Negative": "0.0011775975581258535",
   "Neutral": "0.8048148155212402",
   "Mixed": "4.409974644659087e-05"
 },
 {
   "tweet_id": "1589982447428861952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982443452633088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982434182848513",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589982427279413251",
   "Positive": "0.004503054544329643",
   "Negative": "0.9372949600219727",
   "Neutral": "0.05775236338376999",
   "Mixed": "0.00044973421609029174"
 },
 {
   "tweet_id": "1589982425215475713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982415836979201",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589982407230623745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982391862714370",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589982389362601984",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589982386254909441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982379367890945",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982378147352577",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589982376054382592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982362867486721",
   "Positive": "0.7021828293800354",
   "Negative": "0.016078267246484756",
   "Neutral": "0.26795804500579834",
   "Mixed": "0.013780835084617138"
 },
 {
   "tweet_id": "1589982341623320577",
   "Positive": "0.006821545772254467",
   "Negative": "0.00011089965846622363",
   "Neutral": "0.9930540919303894",
   "Mixed": "1.3458377907227259e-05"
 },
 {
   "tweet_id": "1589982334241366016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982333880668160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982320635031552",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982310568378368",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1589982297969033218",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589982289723019264",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589982275638538240",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982274258624512",
   "Positive": "0.08115919679403305",
   "Negative": "0.002056647324934602",
   "Neutral": "0.9167684316635132",
   "Mixed": "1.5727642676210962e-05"
 },
 {
   "tweet_id": "1589982265622564864",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1589982254247596032",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589982251181568001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982250179137536",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589982239936614400",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982238728650754",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589982235780067329",
   "Positive": "0.002087668050080538",
   "Negative": "0.0009213634766638279",
   "Neutral": "0.9969396591186523",
   "Mixed": "5.1360646466491744e-05"
 },
 {
   "tweet_id": "1589982234018471938",
   "Positive": "0.012196853756904602",
   "Negative": "6.637043406954035e-05",
   "Neutral": "0.9876037836074829",
   "Mixed": "0.0001329036895185709"
 },
 {
   "tweet_id": "1589982219593953281",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1589982216762757120",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1589982215232188418",
   "Positive": "0.012902084738016129",
   "Negative": "7.104137330316007e-05",
   "Neutral": "0.9868820309638977",
   "Mixed": "0.0001447884424123913"
 },
 {
   "tweet_id": "1589982211943837702",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982196773031936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982196575920128",
   "Positive": "0.012302834540605545",
   "Negative": "6.723951082676649e-05",
   "Neutral": "0.9875024557113647",
   "Mixed": "0.00012745443382300436"
 },
 {
   "tweet_id": "1589982196550377473",
   "Positive": "0.2679648697376251",
   "Negative": "0.0008979574777185917",
   "Neutral": "0.7311103940010071",
   "Mixed": "2.6799516490427777e-05"
 },
 {
   "tweet_id": "1589982196328468481",
   "Positive": "0.018290694802999496",
   "Negative": "4.80940070701763e-05",
   "Neutral": "0.9813583493232727",
   "Mixed": "0.0003028844657819718"
 },
 {
   "tweet_id": "1589982189181374464",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589982177818988544",
   "Positive": "0.01720779947936535",
   "Negative": "9.932447574101388e-05",
   "Neutral": "0.9825405478477478",
   "Mixed": "0.00015231300494633615"
 },
 {
   "tweet_id": "1589982172790001664",
   "Positive": "0.006170324515551329",
   "Negative": "0.09710637480020523",
   "Neutral": "0.8966885805130005",
   "Mixed": "3.474228651612066e-05"
 },
 {
   "tweet_id": "1589982157212372994",
   "Positive": "0.01751687377691269",
   "Negative": "6.712697359034792e-05",
   "Neutral": "0.9822983741760254",
   "Mixed": "0.0001176618316094391"
 },
 {
   "tweet_id": "1589982149448708096",
   "Positive": "0.6043013334274292",
   "Negative": "0.0003774701035581529",
   "Neutral": "0.39529114961624146",
   "Mixed": "3.007424129464198e-05"
 },
 {
   "tweet_id": "1589982140384808961",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982139927314433",
   "Positive": "0.01482759602367878",
   "Negative": "4.925157190882601e-05",
   "Neutral": "0.985054612159729",
   "Mixed": "6.857966218376532e-05"
 },
 {
   "tweet_id": "1589982139852161029",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982127869001728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589982118884823040",
   "Positive": "0.01334948930889368",
   "Negative": "4.960376463714056e-05",
   "Neutral": "0.9865358471870422",
   "Mixed": "6.50601796223782e-05"
 },
 {
   "tweet_id": "1589982110185504769",
   "Positive": "0.9458559155464172",
   "Negative": "0.00027307559503242373",
   "Neutral": "0.05377231538295746",
   "Mixed": "9.868924098554999e-05"
 },
 {
   "tweet_id": "1589982107430191105",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589982101977575426",
   "Positive": "0.013950000517070293",
   "Negative": "4.92368453706149e-05",
   "Neutral": "0.9859309196472168",
   "Mixed": "6.978759483899921e-05"
 },
 {
   "tweet_id": "1589982097481281538",
   "Positive": "0.05448709800839424",
   "Negative": "0.00044905630056746304",
   "Neutral": "0.9443546533584595",
   "Mixed": "0.0007092804880812764"
 },
 {
   "tweet_id": "1589982085414277121",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589982085267103744",
   "Positive": "0.013543377630412579",
   "Negative": "7.701150025241077e-05",
   "Neutral": "0.9862404465675354",
   "Mixed": "0.00013925750681664795"
 },
 {
   "tweet_id": "1589982078455926786",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982071745048576",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589982068188286979",
   "Positive": "0.013134759850800037",
   "Negative": "7.141738024074584e-05",
   "Neutral": "0.986653745174408",
   "Mixed": "0.0001400429173372686"
 },
 {
   "tweet_id": "1589982054363852800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982049825624064",
   "Positive": "0.017941463738679886",
   "Negative": "7.131082384148613e-05",
   "Neutral": "0.981855571269989",
   "Mixed": "0.0001316205452894792"
 },
 {
   "tweet_id": "1589982049473265664",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589982044523999235",
   "Positive": "0.020273441448807716",
   "Negative": "4.888576586381532e-05",
   "Neutral": "0.9791669249534607",
   "Mixed": "0.0005107430042698979"
 },
 {
   "tweet_id": "1589982035044577281",
   "Positive": "0.018693873658776283",
   "Negative": "5.7470770116196945e-05",
   "Neutral": "0.9811732172966003",
   "Mixed": "7.541735976701602e-05"
 },
 {
   "tweet_id": "1589982030531792898",
   "Positive": "0.0019134227186441422",
   "Negative": "0.0009509914671070874",
   "Neutral": "0.9971334934234619",
   "Mixed": "2.047356929324451e-06"
 },
 {
   "tweet_id": "1589982027130208256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982017458176000",
   "Positive": "0.0057045514695346355",
   "Negative": "0.7662756443023682",
   "Neutral": "0.22785070538520813",
   "Mixed": "0.0001690645149210468"
 },
 {
   "tweet_id": "1589982015541374976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589982014643777539",
   "Positive": "0.027500828728079796",
   "Negative": "8.487967716064304e-05",
   "Neutral": "0.9723702669143677",
   "Mixed": "4.4003219954902306e-05"
 },
 {
   "tweet_id": "1589981998105649153",
   "Positive": "0.017038248479366302",
   "Negative": "5.221313767833635e-05",
   "Neutral": "0.9828234910964966",
   "Mixed": "8.601466834079474e-05"
 },
 {
   "tweet_id": "1589981993789714432",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589981988244844544",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981980879618058",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981972813996033",
   "Positive": "0.01671711355447769",
   "Negative": "4.9700698582455516e-05",
   "Neutral": "0.9831200838088989",
   "Mixed": "0.00011309448746033013"
 },
 {
   "tweet_id": "1589981964651855873",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981962328223757",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589981948906459137",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1589981942170411008",
   "Positive": "0.017385799437761307",
   "Negative": "4.752543827635236e-05",
   "Neutral": "0.9823797941207886",
   "Mixed": "0.0001869099505711347"
 },
 {
   "tweet_id": "1589981927301603328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981918166396928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981884611981314",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589981881558523906",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589981867004264449",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981858879528960",
   "Positive": "0.22897067666053772",
   "Negative": "0.0006909409421496093",
   "Neutral": "0.7703208923339844",
   "Mixed": "1.7495956853963435e-05"
 },
 {
   "tweet_id": "1589981847156822017",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981805301886976",
   "Positive": "0.01836053468286991",
   "Negative": "5.2495361160254106e-05",
   "Neutral": "0.9813582301139832",
   "Mixed": "0.00022874581918586046"
 },
 {
   "tweet_id": "1589981803502530566",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981782040276992",
   "Positive": "0.000868593982886523",
   "Negative": "0.00021089520305395126",
   "Neutral": "0.998916506767273",
   "Mixed": "4.0078316487779375e-06"
 },
 {
   "tweet_id": "1589981779427221506",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981778755788800",
   "Positive": "0.9814304709434509",
   "Negative": "0.00016180911916308105",
   "Neutral": "0.018280334770679474",
   "Mixed": "0.0001274361420655623"
 },
 {
   "tweet_id": "1589981768836608001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981767477653507",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981749756321792",
   "Positive": "0.005825099069625139",
   "Negative": "0.00034584608511067927",
   "Neutral": "0.9938255548477173",
   "Mixed": "3.5369473607715918e-06"
 },
 {
   "tweet_id": "1589981747294265345",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589981720535003137",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589981718924398595",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981709033820161",
   "Positive": "0.06998670101165771",
   "Negative": "0.0022186555434018373",
   "Neutral": "0.9277735352516174",
   "Mixed": "2.1119145458214916e-05"
 },
 {
   "tweet_id": "1589981707700416512",
   "Positive": "0.09910900145769119",
   "Negative": "0.023014849051833153",
   "Neutral": "0.8776066899299622",
   "Mixed": "0.0002694799331948161"
 },
 {
   "tweet_id": "1589981703619383303",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589981699772846080",
   "Positive": "0.23411095142364502",
   "Negative": "0.0005475864163599908",
   "Neutral": "0.7634777426719666",
   "Mixed": "0.0018636410823091865"
 },
 {
   "tweet_id": "1589981696304500737",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981680705896448",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981678029901825",
   "Positive": "0.00016549904830753803",
   "Negative": "0.05360225960612297",
   "Neutral": "0.9462166428565979",
   "Mixed": "1.5538695151917636e-05"
 },
 {
   "tweet_id": "1589981650922115072",
   "Positive": "0.010711245238780975",
   "Negative": "0.022615356370806694",
   "Neutral": "0.9664013385772705",
   "Mixed": "0.0002721673808991909"
 },
 {
   "tweet_id": "1589981646685884419",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589981631427018753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981617417728002",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1589981591446892544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981566087725058",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589981565739745283",
   "Positive": "0.007774858735501766",
   "Negative": "0.23067942261695862",
   "Neutral": "0.7615283727645874",
   "Mixed": "1.7353126168018207e-05"
 },
 {
   "tweet_id": "1589981561826717697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981560702664704",
   "Positive": "0.02510935626924038",
   "Negative": "0.5359777212142944",
   "Neutral": "0.42637866735458374",
   "Mixed": "0.012534251436591148"
 },
 {
   "tweet_id": "1589981550237843456",
   "Positive": "0.020617332309484482",
   "Negative": "0.8018408417701721",
   "Neutral": "0.16470752656459808",
   "Mixed": "0.012834297493100166"
 },
 {
   "tweet_id": "1589981543053000706",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589981538053414918",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981527852847105",
   "Positive": "0.18334068357944489",
   "Negative": "0.00042291017598472536",
   "Neutral": "0.8154478073120117",
   "Mixed": "0.0007885897648520768"
 },
 {
   "tweet_id": "1589981509099737089",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981465483223041",
   "Positive": "0.0015875725075602531",
   "Negative": "0.0005957406829111278",
   "Neutral": "0.9978145360946655",
   "Mixed": "2.1971970909362426e-06"
 },
 {
   "tweet_id": "1589981463621283840",
   "Positive": "0.00016549904830753803",
   "Negative": "0.05360225960612297",
   "Neutral": "0.9462166428565979",
   "Mixed": "1.5538695151917636e-05"
 },
 {
   "tweet_id": "1589981459057905670",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589981446164598784",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981438832947200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981426795282433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981416577970176",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981412991848449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981412085891072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981393073090562",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589981390413565952",
   "Positive": "0.26903200149536133",
   "Negative": "0.0005783644737675786",
   "Neutral": "0.7303710579872131",
   "Mixed": "1.855842310760636e-05"
 },
 {
   "tweet_id": "1589981384625770496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981382163726337",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981370377723904",
   "Positive": "0.23411095142364502",
   "Negative": "0.0005475864163599908",
   "Neutral": "0.7634777426719666",
   "Mixed": "0.0018636410823091865"
 },
 {
   "tweet_id": "1589981364849643521",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981349892743169",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981347078373380",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981333182615553",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589981322684289025",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589981314052026368",
   "Positive": "0.009897486306726933",
   "Negative": "0.04088268429040909",
   "Neutral": "0.9488628506660461",
   "Mixed": "0.0003569320251699537"
 },
 {
   "tweet_id": "1589981309619036162",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981305814814720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981282863546370",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981256867262465",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981252958171136",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1589981247807270913",
   "Positive": "0.21364806592464447",
   "Negative": "0.005341449286788702",
   "Neutral": "0.7692483067512512",
   "Mixed": "0.011762232519686222"
 },
 {
   "tweet_id": "1589981244883980290",
   "Positive": "0.01150682382285595",
   "Negative": "0.042818181216716766",
   "Neutral": "0.9456346035003662",
   "Mixed": "4.050486677442677e-05"
 },
 {
   "tweet_id": "1589981238794006529",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589981236830695427",
   "Positive": "0.0008787253755144775",
   "Negative": "0.6973291635513306",
   "Neutral": "0.3017199635505676",
   "Mixed": "7.212033960968256e-05"
 },
 {
   "tweet_id": "1589981227486162945",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981227255492613",
   "Positive": "0.01892917789518833",
   "Negative": "4.8189162043854594e-05",
   "Neutral": "0.9808170795440674",
   "Mixed": "0.00020552561909426004"
 },
 {
   "tweet_id": "1589981219164684288",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981208125280256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981205797441538",
   "Positive": "0.004088917747139931",
   "Negative": "0.5569682717323303",
   "Neutral": "0.43889111280441284",
   "Mixed": "5.1713584980461746e-05"
 },
 {
   "tweet_id": "1589981195353608193",
   "Positive": "0.6570026278495789",
   "Negative": "0.0013660455588251352",
   "Neutral": "0.32622504234313965",
   "Mixed": "0.0154062295332551"
 },
 {
   "tweet_id": "1589981191775846400",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589981180656766977",
   "Positive": "0.07566910982131958",
   "Negative": "0.01415045466274023",
   "Neutral": "0.9099516868591309",
   "Mixed": "0.00022873279522173107"
 },
 {
   "tweet_id": "1589981176726720514",
   "Positive": "0.0017863127868622541",
   "Negative": "9.751982724992558e-05",
   "Neutral": "0.9981085062026978",
   "Mixed": "7.6699207056663e-06"
 },
 {
   "tweet_id": "1589981175468429312",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1589981172997967872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981171517378563",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981170582028288",
   "Positive": "0.000711594067979604",
   "Negative": "8.373212040169165e-05",
   "Neutral": "0.999196469783783",
   "Mixed": "8.193607754947152e-06"
 },
 {
   "tweet_id": "1589981164906987520",
   "Positive": "0.0275935847312212",
   "Negative": "0.0004954003379680216",
   "Neutral": "0.9718621969223022",
   "Mixed": "4.8900274123298004e-05"
 },
 {
   "tweet_id": "1589981155763580929",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981127783378944",
   "Positive": "0.4822312295436859",
   "Negative": "0.0012554751010611653",
   "Neutral": "0.5164064764976501",
   "Mixed": "0.00010688592738006264"
 },
 {
   "tweet_id": "1589981125426180097",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981115359850496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981105205415936",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589981087266373632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981073567453192",
   "Positive": "0.001756914658471942",
   "Negative": "7.992725295480341e-05",
   "Neutral": "0.9981556534767151",
   "Mixed": "7.5528146226133686e-06"
 },
 {
   "tweet_id": "1589981071852052482",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981069842882561",
   "Positive": "0.6427288055419922",
   "Negative": "0.0004670796624850482",
   "Neutral": "0.3392295241355896",
   "Mixed": "0.017574578523635864"
 },
 {
   "tweet_id": "1589981068081471488",
   "Positive": "0.00018954330880660564",
   "Negative": "0.019442813470959663",
   "Neutral": "0.980358362197876",
   "Mixed": "9.237580343324225e-06"
 },
 {
   "tweet_id": "1589981065438822400",
   "Positive": "0.39482057094573975",
   "Negative": "0.0025609140284359455",
   "Neutral": "0.6025910973548889",
   "Mixed": "2.7443355065770447e-05"
 },
 {
   "tweet_id": "1589981063732162560",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589981063627309057",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589981059013554176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589981053623861248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589981047437266946",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589981047340814338",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981039786872833",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589981019524202496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980992345108481",
   "Positive": "0.23411095142364502",
   "Negative": "0.0005475864163599908",
   "Neutral": "0.7634777426719666",
   "Mixed": "0.0018636410823091865"
 },
 {
   "tweet_id": "1589980985546137602",
   "Positive": "0.08949977159500122",
   "Negative": "0.632798433303833",
   "Neutral": "0.19431725144386292",
   "Mixed": "0.08338449895381927"
 },
 {
   "tweet_id": "1589980975844712449",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589980975303622658",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980970933186562",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589980968596619265",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589980967426756611",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980966499807234",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980958471917569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980949810647040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980944420966401",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980943657598976",
   "Positive": "0.3255603611469269",
   "Negative": "0.0020318396855145693",
   "Neutral": "0.6715214252471924",
   "Mixed": "0.0008863004040904343"
 },
 {
   "tweet_id": "1589980927941541888",
   "Positive": "0.0014711259864270687",
   "Negative": "0.0012323585106059909",
   "Neutral": "0.9972737431526184",
   "Mixed": "2.2823411200079136e-05"
 },
 {
   "tweet_id": "1589980914674987008",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980908740018177",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589980905862406145",
   "Positive": "0.046182990074157715",
   "Negative": "0.017497669905424118",
   "Neutral": "0.9362987875938416",
   "Mixed": "2.0528794266283512e-05"
 },
 {
   "tweet_id": "1589980905808224261",
   "Positive": "0.07566910982131958",
   "Negative": "0.01415045466274023",
   "Neutral": "0.9099516868591309",
   "Mixed": "0.00022873279522173107"
 },
 {
   "tweet_id": "1589980904256339970",
   "Positive": "0.06742002069950104",
   "Negative": "0.00018737182836048305",
   "Neutral": "0.9322901368141174",
   "Mixed": "0.00010250692866975442"
 },
 {
   "tweet_id": "1589980901823610881",
   "Positive": "0.4822312295436859",
   "Negative": "0.0012554751010611653",
   "Neutral": "0.5164064764976501",
   "Mixed": "0.00010688592738006264"
 },
 {
   "tweet_id": "1589980901617827843",
   "Positive": "0.0222515556961298",
   "Negative": "0.5593283176422119",
   "Neutral": "0.41650423407554626",
   "Mixed": "0.0019159386865794659"
 },
 {
   "tweet_id": "1589980890377355264",
   "Positive": "0.017283840104937553",
   "Negative": "4.8679299652576447e-05",
   "Neutral": "0.9825128316879272",
   "Mixed": "0.00015461797011084855"
 },
 {
   "tweet_id": "1589980882663718913",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589980862120357888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980856449630209",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980846416875521",
   "Positive": "0.6899182200431824",
   "Negative": "0.0003092980768997222",
   "Neutral": "0.30501148104667664",
   "Mixed": "0.004760977812111378"
 },
 {
   "tweet_id": "1589980843317284865",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589980829199261697",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980825608945664",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980825051082755",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589980813411569666",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1589980808634564609",
   "Positive": "0.23411095142364502",
   "Negative": "0.0005475864163599908",
   "Neutral": "0.7634777426719666",
   "Mixed": "0.0018636410823091865"
 },
 {
   "tweet_id": "1589980800795430913",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980786115383297",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980782625689600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980773553434624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980767584911361",
   "Positive": "0.7115167379379272",
   "Negative": "0.0030037646647542715",
   "Neutral": "0.2853226363658905",
   "Mixed": "0.00015680448268540204"
 },
 {
   "tweet_id": "1589980759712239623",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589980754985250818",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980752321863681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980730817671168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980716854841345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980710257164288",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980707304378369",
   "Positive": "0.007368597202003002",
   "Negative": "0.0005087353056296706",
   "Neutral": "0.9921188354492188",
   "Mixed": "3.7710537981183734e-06"
 },
 {
   "tweet_id": "1589980707069505537",
   "Positive": "0.049313150346279144",
   "Negative": "0.0011421937961131334",
   "Neutral": "0.9455183148384094",
   "Mixed": "0.004026341252028942"
 },
 {
   "tweet_id": "1589980701440741376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980695715536897",
   "Positive": "0.00673819612711668",
   "Negative": "0.19241118431091309",
   "Neutral": "0.8007780313491821",
   "Mixed": "7.250352791743353e-05"
 },
 {
   "tweet_id": "1589980695681982466",
   "Positive": "0.0019052280113101006",
   "Negative": "0.26761502027511597",
   "Neutral": "0.7304255962371826",
   "Mixed": "5.4102107242215425e-05"
 },
 {
   "tweet_id": "1589980693408665600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980671002701826",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589980667001319424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980654384861186",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980648177307650",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980641634156545",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589980635145596929",
   "Positive": "0.054779306054115295",
   "Negative": "0.00012766466534230858",
   "Neutral": "0.9450435042381287",
   "Mixed": "4.951160735799931e-05"
 },
 {
   "tweet_id": "1589980634318979072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980623694807040",
   "Positive": "0.009434624575078487",
   "Negative": "0.1980682611465454",
   "Neutral": "0.7924561500549316",
   "Mixed": "4.094882751815021e-05"
 },
 {
   "tweet_id": "1589980622046777344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980611414196224",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980588689174528",
   "Positive": "0.0040924279019236565",
   "Negative": "0.0002465773723088205",
   "Neutral": "0.9956576824188232",
   "Mixed": "3.356481556693325e-06"
 },
 {
   "tweet_id": "1589980586176757761",
   "Positive": "0.03914494812488556",
   "Negative": "0.3570759892463684",
   "Neutral": "0.6037493944168091",
   "Mixed": "2.960475831059739e-05"
 },
 {
   "tweet_id": "1589980578304389123",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980575217377281",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589980569571508224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980568330305540",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980566585495552",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980566140911620",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980558347882497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980545140027392",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980519760289792",
   "Positive": "0.4191734492778778",
   "Negative": "0.0014826811384409666",
   "Neutral": "0.5788074135780334",
   "Mixed": "0.0005364399985410273"
 },
 {
   "tweet_id": "1589980517394366466",
   "Positive": "0.6599288582801819",
   "Negative": "0.0008031455217860639",
   "Neutral": "0.33901694416999817",
   "Mixed": "0.0002510630583856255"
 },
 {
   "tweet_id": "1589980514957819904",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589980512948396032",
   "Positive": "0.8601663708686829",
   "Negative": "0.0007616222719661891",
   "Neutral": "0.13892515003681183",
   "Mixed": "0.00014680575986858457"
 },
 {
   "tweet_id": "1589980508976357377",
   "Positive": "0.0005621786694973707",
   "Negative": "0.9682654142379761",
   "Neutral": "0.031123889610171318",
   "Mixed": "4.8615893319947645e-05"
 },
 {
   "tweet_id": "1589980503146647553",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980501326319617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980497685671936",
   "Positive": "0.11218984425067902",
   "Negative": "0.00033123433240689337",
   "Neutral": "0.8874462842941284",
   "Mixed": "3.2725751225370914e-05"
 },
 {
   "tweet_id": "1589980484448432131",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589980474445037571",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1589980470355582977",
   "Positive": "0.0008976113749668002",
   "Negative": "0.0014118930557742715",
   "Neutral": "0.9975662231445312",
   "Mixed": "0.00012428344052750617"
 },
 {
   "tweet_id": "1589980470196199425",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589980446880071680",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980442354384896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980441171619842",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589980439347068928",
   "Positive": "0.11772952228784561",
   "Negative": "0.00014230293163564056",
   "Neutral": "0.881999671459198",
   "Mixed": "0.0001285352191189304"
 },
 {
   "tweet_id": "1589980426890022912",
   "Positive": "0.008952229283750057",
   "Negative": "0.6888786554336548",
   "Neutral": "0.24801167845726013",
   "Mixed": "0.05415748059749603"
 },
 {
   "tweet_id": "1589980412293836800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980406249844738",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589980403078946817",
   "Positive": "0.004987729247659445",
   "Negative": "4.686936517828144e-05",
   "Neutral": "0.994954526424408",
   "Mixed": "1.0909219781751744e-05"
 },
 {
   "tweet_id": "1589980401342513158",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980374087921664",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980372527616000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980372418560003",
   "Positive": "0.0006008964846841991",
   "Negative": "0.0005016528302803636",
   "Neutral": "0.9988825917243958",
   "Mixed": "1.4924644347047433e-05"
 },
 {
   "tweet_id": "1589980371646820353",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589980368673071107",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980368559820802",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980358548008961",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589980356517986304",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589980355733639169",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589980343880544256",
   "Positive": "0.0007684982847422361",
   "Negative": "0.9742178320884705",
   "Neutral": "0.02443903684616089",
   "Mixed": "0.0005746046081185341"
 },
 {
   "tweet_id": "1589980339015135233",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980335441612805",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980334669836290",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980312930746368",
   "Positive": "0.007115740329027176",
   "Negative": "0.0003232297895010561",
   "Neutral": "0.9919412136077881",
   "Mixed": "0.0006197367911227047"
 },
 {
   "tweet_id": "1589980312842678280",
   "Positive": "0.3602951467037201",
   "Negative": "0.042984236031770706",
   "Neutral": "0.5755894184112549",
   "Mixed": "0.021131105720996857"
 },
 {
   "tweet_id": "1589980309520797697",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589980307071049729",
   "Positive": "0.04781103506684303",
   "Negative": "0.5883861780166626",
   "Neutral": "0.36334502696990967",
   "Mixed": "0.00045769772259518504"
 },
 {
   "tweet_id": "1589980288142102528",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589980286947065858",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980285634244609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980269876219904",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589980256748081153",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980245817720834",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980244991045632",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589980240931344386",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1589980225164967938",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980212254547968",
   "Positive": "0.013317038305103779",
   "Negative": "4.7195619117701426e-05",
   "Neutral": "0.9864896535873413",
   "Mixed": "0.00014609155186917633"
 },
 {
   "tweet_id": "1589980202977071106",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589980188808335360",
   "Positive": "0.014270205982029438",
   "Negative": "5.0526330596767366e-05",
   "Neutral": "0.9855870008468628",
   "Mixed": "9.217412298312411e-05"
 },
 {
   "tweet_id": "1589980180709507073",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980176904953861",
   "Positive": "0.13579882681369781",
   "Negative": "0.05522675812244415",
   "Neutral": "0.8088253736495972",
   "Mixed": "0.00014904591080266982"
 },
 {
   "tweet_id": "1589980175793782788",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589980172845223937",
   "Positive": "0.014489485882222652",
   "Negative": "4.958135468768887e-05",
   "Neutral": "0.9853493571281433",
   "Mixed": "0.00011154465028084815"
 },
 {
   "tweet_id": "1589980167124189189",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980161591869440",
   "Positive": "0.23454077541828156",
   "Negative": "0.010277478024363518",
   "Neutral": "0.7550979852676392",
   "Mixed": "8.375790639547631e-05"
 },
 {
   "tweet_id": "1589980156596482048",
   "Positive": "0.014871985651552677",
   "Negative": "5.1532155339373276e-05",
   "Neutral": "0.9849733114242554",
   "Mixed": "0.00010324232425773516"
 },
 {
   "tweet_id": "1589980131866865665",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980129408663552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980101369729025",
   "Positive": "0.013066058978438377",
   "Negative": "0.0001047777768690139",
   "Neutral": "0.9867925643920898",
   "Mixed": "3.654776082839817e-05"
 },
 {
   "tweet_id": "1589980098207576065",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589980097653911554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980096810872833",
   "Positive": "0.00043629002175293863",
   "Negative": "7.32754633645527e-05",
   "Neutral": "0.9994301199913025",
   "Mixed": "6.023854439263232e-05"
 },
 {
   "tweet_id": "1589980093442830337",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980087382052866",
   "Positive": "0.01805426925420761",
   "Negative": "4.710186840384267e-05",
   "Neutral": "0.981623649597168",
   "Mixed": "0.0002750097482930869"
 },
 {
   "tweet_id": "1589980080776019970",
   "Positive": "0.0011743460781872272",
   "Negative": "0.9270938038825989",
   "Neutral": "0.07167068123817444",
   "Mixed": "6.116773147368804e-05"
 },
 {
   "tweet_id": "1589980076619485184",
   "Positive": "0.009247071109712124",
   "Negative": "4.2804244003491476e-05",
   "Neutral": "0.9906435608863831",
   "Mixed": "6.655649485765025e-05"
 },
 {
   "tweet_id": "1589980063076077569",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589980059821305856",
   "Positive": "0.011660942807793617",
   "Negative": "4.411363261169754e-05",
   "Neutral": "0.9882319569587708",
   "Mixed": "6.300128006841987e-05"
 },
 {
   "tweet_id": "1589980055916384256",
   "Positive": "0.7877449989318848",
   "Negative": "0.00022679923858959228",
   "Neutral": "0.21154920756816864",
   "Mixed": "0.00047906191321089864"
 },
 {
   "tweet_id": "1589980055522140161",
   "Positive": "0.03012017160654068",
   "Negative": "5.143232556292787e-05",
   "Neutral": "0.969553530216217",
   "Mixed": "0.0002748956030700356"
 },
 {
   "tweet_id": "1589980055475658752",
   "Positive": "0.001906343037262559",
   "Negative": "0.00013368637883104384",
   "Neutral": "0.9979559183120728",
   "Mixed": "4.072492174600484e-06"
 },
 {
   "tweet_id": "1589980051885690880",
   "Positive": "0.7339399456977844",
   "Negative": "0.0003992183192167431",
   "Neutral": "0.2655521035194397",
   "Mixed": "0.00010867335367947817"
 },
 {
   "tweet_id": "1589980049494913026",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980046131097601",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589980043580952578",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980039634092033",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589980035326545920",
   "Positive": "0.014596770517528057",
   "Negative": "5.278974276734516e-05",
   "Neutral": "0.9852910041809082",
   "Mixed": "5.943398718954995e-05"
 },
 {
   "tweet_id": "1589980015567208453",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589980015495901192",
   "Positive": "0.01670755259692669",
   "Negative": "5.519547266885638e-05",
   "Neutral": "0.9831516742706299",
   "Mixed": "8.554584201192483e-05"
 },
 {
   "tweet_id": "1589980014719938560",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589980013222572033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589980013029642242",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979992745988097",
   "Positive": "0.9682515263557434",
   "Negative": "0.0005970531492494047",
   "Neutral": "0.03090229071676731",
   "Mixed": "0.00024905806640163064"
 },
 {
   "tweet_id": "1589979991722582018",
   "Positive": "0.008672156371176243",
   "Negative": "4.280445136828348e-05",
   "Neutral": "0.9912248253822327",
   "Mixed": "6.0174279497005045e-05"
 },
 {
   "tweet_id": "1589979991571279872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979973880012800",
   "Positive": "0.0113098518922925",
   "Negative": "5.3464984375750646e-05",
   "Neutral": "0.988575279712677",
   "Mixed": "6.138267053756863e-05"
 },
 {
   "tweet_id": "1589979971413741568",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979968330936325",
   "Positive": "0.019964510574936867",
   "Negative": "4.578916195896454e-05",
   "Neutral": "0.9798015356063843",
   "Mixed": "0.00018813362112268806"
 },
 {
   "tweet_id": "1589979957841002497",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589979953059487745",
   "Positive": "0.013416583649814129",
   "Negative": "5.3313251555664465e-05",
   "Neutral": "0.9864581227302551",
   "Mixed": "7.199846004368737e-05"
 },
 {
   "tweet_id": "1589979952040271873",
   "Positive": "0.006316356360912323",
   "Negative": "0.00010564210242591798",
   "Neutral": "0.9935332536697388",
   "Mixed": "4.473735316423699e-05"
 },
 {
   "tweet_id": "1589979939985838081",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979933925052416",
   "Positive": "0.01284736767411232",
   "Negative": "5.163418245501816e-05",
   "Neutral": "0.9870326519012451",
   "Mixed": "6.842016591690481e-05"
 },
 {
   "tweet_id": "1589979923435114499",
   "Positive": "0.05752812325954437",
   "Negative": "0.0006409278721548617",
   "Neutral": "0.9418043494224548",
   "Mixed": "2.6605526727507822e-05"
 },
 {
   "tweet_id": "1589979922248118272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979917126889472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979914694184961",
   "Positive": "0.013156816363334656",
   "Negative": "5.092497303849086e-05",
   "Neutral": "0.9867217540740967",
   "Mixed": "7.049576379358768e-05"
 },
 {
   "tweet_id": "1589979905412198400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979905256980480",
   "Positive": "0.3529196083545685",
   "Negative": "0.15426896512508392",
   "Neutral": "0.49083995819091797",
   "Mixed": "0.001971385208889842"
 },
 {
   "tweet_id": "1589979901297557505",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589979900420947968",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979898797780993",
   "Positive": "0.025026720017194748",
   "Negative": "5.893148045288399e-05",
   "Neutral": "0.9747195243835449",
   "Mixed": "0.00019474625878501683"
 },
 {
   "tweet_id": "1589979898386739200",
   "Positive": "0.030467405915260315",
   "Negative": "0.001165208057500422",
   "Neutral": "0.9683389663696289",
   "Mixed": "2.8391430532792583e-05"
 },
 {
   "tweet_id": "1589979896998432769",
   "Positive": "0.017261799424886703",
   "Negative": "5.318202602211386e-05",
   "Neutral": "0.9825943112373352",
   "Mixed": "9.066249913303182e-05"
 },
 {
   "tweet_id": "1589979887112425474",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979880266924033",
   "Positive": "0.014997037127614021",
   "Negative": "5.4098130931379274e-05",
   "Neutral": "0.9848672747612",
   "Mixed": "8.164041355485097e-05"
 },
 {
   "tweet_id": "1589979875917824001",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979871706742785",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979859652325376",
   "Positive": "0.015146796591579914",
   "Negative": "5.2992771088611335e-05",
   "Neutral": "0.9847255349159241",
   "Mixed": "7.467329123755917e-05"
 },
 {
   "tweet_id": "1589979849594003456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979846197014533",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979837388554240",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1589979837271531521",
   "Positive": "0.020728858187794685",
   "Negative": "8.122473809635267e-05",
   "Neutral": "0.9790401458740234",
   "Mixed": "0.00014975405065342784"
 },
 {
   "tweet_id": "1589979832682975233",
   "Positive": "0.027096718549728394",
   "Negative": "6.631349242525175e-05",
   "Neutral": "0.9726569652557373",
   "Mixed": "0.00017992900393437594"
 },
 {
   "tweet_id": "1589979830774550528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979829772111872",
   "Positive": "0.0221374724060297",
   "Negative": "5.1978477131342515e-05",
   "Neutral": "0.9776779413223267",
   "Mixed": "0.0001325878401985392"
 },
 {
   "tweet_id": "1589979827188424705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979826055938048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979821526102018",
   "Positive": "0.014217027463018894",
   "Negative": "6.51429218123667e-05",
   "Neutral": "0.9856083393096924",
   "Mixed": "0.00010948439739877358"
 },
 {
   "tweet_id": "1589979818330042370",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979812218961923",
   "Positive": "0.8794732689857483",
   "Negative": "0.00022689254547003657",
   "Neutral": "0.11973415315151215",
   "Mixed": "0.0005656861467286944"
 },
 {
   "tweet_id": "1589979805650649090",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589979804337438720",
   "Positive": "0.01714407466351986",
   "Negative": "7.172563346102834e-05",
   "Neutral": "0.9826506972312927",
   "Mixed": "0.0001335824781563133"
 },
 {
   "tweet_id": "1589979797580845062",
   "Positive": "0.020827651023864746",
   "Negative": "5.17592670803424e-05",
   "Neutral": "0.9783070087432861",
   "Mixed": "0.0008135835523717105"
 },
 {
   "tweet_id": "1589979782737186823",
   "Positive": "0.03257821500301361",
   "Negative": "0.00010807778016896918",
   "Neutral": "0.9672724008560181",
   "Mixed": "4.130016532144509e-05"
 },
 {
   "tweet_id": "1589979780694278146",
   "Positive": "0.2725793719291687",
   "Negative": "0.0007199124083854258",
   "Neutral": "0.7266629338264465",
   "Mixed": "3.772553100134246e-05"
 },
 {
   "tweet_id": "1589979779822157824",
   "Positive": "0.04136250540614128",
   "Negative": "0.008446842432022095",
   "Neutral": "0.9501715898513794",
   "Mixed": "1.9073693692917004e-05"
 },
 {
   "tweet_id": "1589979774717669376",
   "Positive": "0.025220077484846115",
   "Negative": "0.0018080227309837937",
   "Neutral": "0.9729328155517578",
   "Mixed": "3.906308484147303e-05"
 },
 {
   "tweet_id": "1589979767662874627",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979764772962306",
   "Positive": "0.023531274870038033",
   "Negative": "7.54773136577569e-05",
   "Neutral": "0.9763337969779968",
   "Mixed": "5.949840851826593e-05"
 },
 {
   "tweet_id": "1589979758745759745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979756728291328",
   "Positive": "0.01592327281832695",
   "Negative": "4.49587132607121e-05",
   "Neutral": "0.983878493309021",
   "Mixed": "0.00015333128976635635"
 },
 {
   "tweet_id": "1589979741167431680",
   "Positive": "0.547311007976532",
   "Negative": "0.0009944610064849257",
   "Neutral": "0.45154064893722534",
   "Mixed": "0.0001539110962767154"
 },
 {
   "tweet_id": "1589979741079031808",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589979738919284739",
   "Positive": "0.025521401315927505",
   "Negative": "8.996779070002958e-05",
   "Neutral": "0.9743251204490662",
   "Mixed": "6.347809539875016e-05"
 },
 {
   "tweet_id": "1589979726722240518",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979717209247751",
   "Positive": "0.024434253573417664",
   "Negative": "6.81738238199614e-05",
   "Neutral": "0.9754300713539124",
   "Mixed": "6.750023749191314e-05"
 },
 {
   "tweet_id": "1589979713539563523",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979704442114048",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979699861950465",
   "Positive": "0.02353338897228241",
   "Negative": "6.416723044821993e-05",
   "Neutral": "0.9763084053993225",
   "Mixed": "9.403635340277106e-05"
 },
 {
   "tweet_id": "1589979694484815873",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979683873263617",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589979683265064961",
   "Positive": "0.02490423619747162",
   "Negative": "6.54247123748064e-05",
   "Neutral": "0.974951982498169",
   "Mixed": "7.82783463364467e-05"
 },
 {
   "tweet_id": "1589979682908557312",
   "Positive": "0.022766942158341408",
   "Negative": "5.8461169828660786e-05",
   "Neutral": "0.9770220518112183",
   "Mixed": "0.00015258022176567465"
 },
 {
   "tweet_id": "1589979671864934405",
   "Positive": "0.02011626400053501",
   "Negative": "5.246567525318824e-05",
   "Neutral": "0.9792592525482178",
   "Mixed": "0.000572014250792563"
 },
 {
   "tweet_id": "1589979667628699648",
   "Positive": "0.028080539777874947",
   "Negative": "9.084144403459504e-05",
   "Neutral": "0.971780002117157",
   "Mixed": "4.862318019149825e-05"
 },
 {
   "tweet_id": "1589979664583651329",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979659298811909",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979657729761281",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589979651736506375",
   "Positive": "0.0230106208473444",
   "Negative": "7.288502820301801e-05",
   "Neutral": "0.9768622517585754",
   "Mixed": "5.42852831131313e-05"
 },
 {
   "tweet_id": "1589979650985725952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979646632038401",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979636653785088",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979631582859265",
   "Positive": "0.032528314739465714",
   "Negative": "6.228937854757532e-05",
   "Neutral": "0.9672812223434448",
   "Mixed": "0.00012825298472307622"
 },
 {
   "tweet_id": "1589979614793060359",
   "Positive": "0.0279100202023983",
   "Negative": "8.858350338414311e-05",
   "Neutral": "0.9719452857971191",
   "Mixed": "5.604496254818514e-05"
 },
 {
   "tweet_id": "1589979609595944961",
   "Positive": "0.7481666207313538",
   "Negative": "0.00033134198747575283",
   "Neutral": "0.2514702379703522",
   "Mixed": "3.1787825719220564e-05"
 },
 {
   "tweet_id": "1589979608610660352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979606756777986",
   "Positive": "0.023457350209355354",
   "Negative": "4.696289397543296e-05",
   "Neutral": "0.9762142300605774",
   "Mixed": "0.00028139978530816734"
 },
 {
   "tweet_id": "1589979605683044356",
   "Positive": "0.14526551961898804",
   "Negative": "0.030598578974604607",
   "Neutral": "0.8240435719490051",
   "Mixed": "9.235196921508759e-05"
 },
 {
   "tweet_id": "1589979601996251136",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979596660781056",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1589979589480443911",
   "Positive": "0.012537463568150997",
   "Negative": "5.219904414843768e-05",
   "Neutral": "0.9873440265655518",
   "Mixed": "6.633405428146943e-05"
 },
 {
   "tweet_id": "1589979583973294081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979575274332163",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979568697643010",
   "Positive": "0.011457028798758984",
   "Negative": "0.001600058632902801",
   "Neutral": "0.9869363903999329",
   "Mixed": "6.444826794904657e-06"
 },
 {
   "tweet_id": "1589979567359680513",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979564520144896",
   "Positive": "0.0015803335700184107",
   "Negative": "0.0657745897769928",
   "Neutral": "0.9301087260246277",
   "Mixed": "0.002536292187869549"
 },
 {
   "tweet_id": "1589979559273070594",
   "Positive": "0.03479314222931862",
   "Negative": "0.0033423590939491987",
   "Neutral": "0.9618327617645264",
   "Mixed": "3.171376374666579e-05"
 },
 {
   "tweet_id": "1589979558513897472",
   "Positive": "0.14953693747520447",
   "Negative": "0.00017102262063417584",
   "Neutral": "0.8501883745193481",
   "Mixed": "0.00010364245827076957"
 },
 {
   "tweet_id": "1589979558404837377",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589979557389807618",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979555351367681",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979543863189505",
   "Positive": "0.03067377209663391",
   "Negative": "0.003829139517620206",
   "Neutral": "0.9654644727706909",
   "Mixed": "3.2599360565654933e-05"
 },
 {
   "tweet_id": "1589979537949196297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979530135236609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979529212485641",
   "Positive": "0.03407721966505051",
   "Negative": "0.0038960324600338936",
   "Neutral": "0.9619925618171692",
   "Mixed": "3.415928586036898e-05"
 },
 {
   "tweet_id": "1589979527564099584",
   "Positive": "0.0010627288138493896",
   "Negative": "0.12424414604902267",
   "Neutral": "0.8746811151504517",
   "Mixed": "1.20212052934221e-05"
 },
 {
   "tweet_id": "1589979526016413697",
   "Positive": "0.018436841666698456",
   "Negative": "4.670001726481132e-05",
   "Neutral": "0.9813088774681091",
   "Mixed": "0.00020766403758898377"
 },
 {
   "tweet_id": "1589979519871782912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979518177267719",
   "Positive": "0.024157844483852386",
   "Negative": "4.685739986598492e-05",
   "Neutral": "0.9755468368530273",
   "Mixed": "0.00024851446505635977"
 },
 {
   "tweet_id": "1589979515119628290",
   "Positive": "0.0009561474435031414",
   "Negative": "0.00026507145958021283",
   "Neutral": "0.9987749457359314",
   "Mixed": "3.868978637910914e-06"
 },
 {
   "tweet_id": "1589979514284937216",
   "Positive": "0.01280368585139513",
   "Negative": "5.2694529586005956e-05",
   "Neutral": "0.987092137336731",
   "Mixed": "5.151373989065178e-05"
 },
 {
   "tweet_id": "1589979514024919044",
   "Positive": "0.03239024430513382",
   "Negative": "0.003920763731002808",
   "Neutral": "0.9636560082435608",
   "Mixed": "3.299158561276272e-05"
 },
 {
   "tweet_id": "1589979503677173760",
   "Positive": "0.0010627288138493896",
   "Negative": "0.12424414604902267",
   "Neutral": "0.8746811151504517",
   "Mixed": "1.20212052934221e-05"
 },
 {
   "tweet_id": "1589979497943937024",
   "Positive": "0.01390383392572403",
   "Negative": "5.3242674766806886e-05",
   "Neutral": "0.9859699606895447",
   "Mixed": "7.293446833500639e-05"
 },
 {
   "tweet_id": "1589979496907964420",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979493288247297",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979490264186882",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589979483385507843",
   "Positive": "0.03327939286828041",
   "Negative": "0.003882154356688261",
   "Neutral": "0.962806761264801",
   "Mixed": "3.166158057865687e-05"
 },
 {
   "tweet_id": "1589979473319202817",
   "Positive": "0.011568163521587849",
   "Negative": "6.21789222350344e-05",
   "Neutral": "0.9883297681808472",
   "Mixed": "3.994446888100356e-05"
 },
 {
   "tweet_id": "1589979469519159297",
   "Positive": "0.034425038844347",
   "Negative": "0.004008432384580374",
   "Neutral": "0.9615317583084106",
   "Mixed": "3.468353679636493e-05"
 },
 {
   "tweet_id": "1589979468902252544",
   "Positive": "0.0015187518438324332",
   "Negative": "5.56943123228848e-05",
   "Neutral": "0.998418927192688",
   "Mixed": "6.705831765430048e-06"
 },
 {
   "tweet_id": "1589979459226337281",
   "Positive": "0.01647050864994526",
   "Negative": "0.0025596278719604015",
   "Neutral": "0.980962872505188",
   "Mixed": "7.07419576428947e-06"
 },
 {
   "tweet_id": "1589979458655555585",
   "Positive": "0.01880476623773575",
   "Negative": "4.8758669436210766e-05",
   "Neutral": "0.9810328483581543",
   "Mixed": "0.00011351162538630888"
 },
 {
   "tweet_id": "1589979456026050560",
   "Positive": "0.03229648992419243",
   "Negative": "0.004159590229392052",
   "Neutral": "0.9635116457939148",
   "Mixed": "3.227226989110932e-05"
 },
 {
   "tweet_id": "1589979450841911296",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979446647615489",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979443111813120",
   "Positive": "0.013752602972090244",
   "Negative": "4.295006510801613e-05",
   "Neutral": "0.9860985279083252",
   "Mixed": "0.00010588638542685658"
 },
 {
   "tweet_id": "1589979442092576768",
   "Positive": "0.03265853971242905",
   "Negative": "0.0039049056358635426",
   "Neutral": "0.9634039998054504",
   "Mixed": "3.265345731051639e-05"
 },
 {
   "tweet_id": "1589979435071328256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979428175478784",
   "Positive": "0.03388573229312897",
   "Negative": "0.004318873398005962",
   "Neutral": "0.9617636203765869",
   "Mixed": "3.174076846335083e-05"
 },
 {
   "tweet_id": "1589979423104962560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979421121081346",
   "Positive": "0.016042251139879227",
   "Negative": "5.4757805628469214e-05",
   "Neutral": "0.9838347434997559",
   "Mixed": "6.825991295045242e-05"
 },
 {
   "tweet_id": "1589979418315067393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979416146620416",
   "Positive": "0.019705623388290405",
   "Negative": "4.489536149776541e-05",
   "Neutral": "0.9800804853439331",
   "Mixed": "0.00016893137944862247"
 },
 {
   "tweet_id": "1589979414745739265",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979413659414530",
   "Positive": "0.034367311745882034",
   "Negative": "0.004191279876977205",
   "Neutral": "0.9614090323448181",
   "Mixed": "3.2391944841947407e-05"
 },
 {
   "tweet_id": "1589979408776863745",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589979401277804545",
   "Positive": "0.004116515628993511",
   "Negative": "0.0006649421411566436",
   "Neutral": "0.9951562881469727",
   "Mixed": "6.224773096619174e-05"
 },
 {
   "tweet_id": "1589979399461699584",
   "Positive": "0.034655146300792694",
   "Negative": "0.003643810050562024",
   "Neutral": "0.9616692066192627",
   "Mixed": "3.18503261951264e-05"
 },
 {
   "tweet_id": "1589979399189049345",
   "Positive": "0.015030012466013432",
   "Negative": "5.1662813348229975e-05",
   "Neutral": "0.9848589301109314",
   "Mixed": "5.9457750467117876e-05"
 },
 {
   "tweet_id": "1589979395363852288",
   "Positive": "0.022916074842214584",
   "Negative": "5.1975774113088846e-05",
   "Neutral": "0.976285457611084",
   "Mixed": "0.0007465147064067423"
 },
 {
   "tweet_id": "1589979390993371139",
   "Positive": "0.03145454078912735",
   "Negative": "8.110990893328562e-05",
   "Neutral": "0.968299150466919",
   "Mixed": "0.0001652313076192513"
 },
 {
   "tweet_id": "1589979386425790466",
   "Positive": "0.1447996348142624",
   "Negative": "0.0003465884074103087",
   "Neutral": "0.8548089861869812",
   "Mixed": "4.487923433771357e-05"
 },
 {
   "tweet_id": "1589979381535211521",
   "Positive": "0.03205481916666031",
   "Negative": "0.004060867242515087",
   "Neutral": "0.9638504981994629",
   "Mixed": "3.3829619496827945e-05"
 },
 {
   "tweet_id": "1589979378959921153",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979375985754114",
   "Positive": "0.02250414341688156",
   "Negative": "8.789817366050556e-05",
   "Neutral": "0.9773672223091125",
   "Mixed": "4.0797898691380396e-05"
 },
 {
   "tweet_id": "1589979359204749312",
   "Positive": "0.018681177869439125",
   "Negative": "4.7037119657034054e-05",
   "Neutral": "0.9809532761573792",
   "Mixed": "0.00031854069675318897"
 },
 {
   "tweet_id": "1589979354200936453",
   "Positive": "0.023254334926605225",
   "Negative": "6.847155600553378e-05",
   "Neutral": "0.9765911102294922",
   "Mixed": "8.60586078488268e-05"
 },
 {
   "tweet_id": "1589979353190141953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979352514842626",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589979351378198528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979350946156544",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589979347888529411",
   "Positive": "0.024376729503273964",
   "Negative": "4.523209645412862e-05",
   "Neutral": "0.9753163456916809",
   "Mixed": "0.0002616738493088633"
 },
 {
   "tweet_id": "1589979330192760833",
   "Positive": "0.016047323122620583",
   "Negative": "5.955991946393624e-05",
   "Neutral": "0.983851969242096",
   "Mixed": "4.118483775528148e-05"
 },
 {
   "tweet_id": "1589979316623880193",
   "Positive": "0.03145454078912735",
   "Negative": "8.110990893328562e-05",
   "Neutral": "0.968299150466919",
   "Mixed": "0.0001652313076192513"
 },
 {
   "tweet_id": "1589979315068080129",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979314669641728",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589979312014651393",
   "Positive": "0.015670057386159897",
   "Negative": "5.3997180657461286e-05",
   "Neutral": "0.9842236638069153",
   "Mixed": "5.2358725952217355e-05"
 },
 {
   "tweet_id": "1589979307631599617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979295367458818",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979293610053634",
   "Positive": "0.014543234370648861",
   "Negative": "5.0042359362123534e-05",
   "Neutral": "0.9853367209434509",
   "Mixed": "6.999794277362525e-05"
 },
 {
   "tweet_id": "1589979291844235264",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979288719458305",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589979282209931266",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979277646532609",
   "Positive": "0.021694239228963852",
   "Negative": "8.093520591501147e-05",
   "Neutral": "0.9781849384307861",
   "Mixed": "3.990308687207289e-05"
 },
 {
   "tweet_id": "1589979274525970434",
   "Positive": "0.0018044745083898306",
   "Negative": "0.00021919285063631833",
   "Neutral": "0.9979720711708069",
   "Mixed": "4.2940559978887904e-06"
 },
 {
   "tweet_id": "1589979271191498753",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979266242207745",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589979261808832512",
   "Positive": "0.0010627288138493896",
   "Negative": "0.12424414604902267",
   "Neutral": "0.8746811151504517",
   "Mixed": "1.20212052934221e-05"
 },
 {
   "tweet_id": "1589979258591809538",
   "Positive": "0.02595546469092369",
   "Negative": "5.0210928748128936e-05",
   "Neutral": "0.9736939668655396",
   "Mixed": "0.0003003831079695374"
 },
 {
   "tweet_id": "1589979258210127873",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589979257488683009",
   "Positive": "0.0009932584362104535",
   "Negative": "0.0009977108566090465",
   "Neutral": "0.9979669451713562",
   "Mixed": "4.2069677874678746e-05"
 },
 {
   "tweet_id": "1589979257337696257",
   "Positive": "0.22132357954978943",
   "Negative": "0.013936924748122692",
   "Neutral": "0.7646698355674744",
   "Mixed": "6.968474917812273e-05"
 },
 {
   "tweet_id": "1589979251226574848",
   "Positive": "0.021326201036572456",
   "Negative": "7.513447781093419e-05",
   "Neutral": "0.9785553216934204",
   "Mixed": "4.330294905230403e-05"
 },
 {
   "tweet_id": "1589979242934448140",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979241349021696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979219781877761",
   "Positive": "0.01773262768983841",
   "Negative": "5.8198769693262875e-05",
   "Neutral": "0.9821479320526123",
   "Mixed": "6.1176331655588e-05"
 },
 {
   "tweet_id": "1589979204174905344",
   "Positive": "0.026616912335157394",
   "Negative": "7.242089486680925e-05",
   "Neutral": "0.9732258319854736",
   "Mixed": "8.479789539705962e-05"
 },
 {
   "tweet_id": "1589979190337892352",
   "Positive": "0.02044527418911457",
   "Negative": "5.0205249863211066e-05",
   "Neutral": "0.979239821434021",
   "Mixed": "0.0002648037625476718"
 },
 {
   "tweet_id": "1589979187875819522",
   "Positive": "0.04106181859970093",
   "Negative": "5.553145456360653e-05",
   "Neutral": "0.9585162997245789",
   "Mixed": "0.0003663870447780937"
 },
 {
   "tweet_id": "1589979187007586304",
   "Positive": "0.022466938942670822",
   "Negative": "5.833483737660572e-05",
   "Neutral": "0.9773226976394653",
   "Mixed": "0.0001520131336292252"
 },
 {
   "tweet_id": "1589979186906959872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979171564158976",
   "Positive": "0.020015910267829895",
   "Negative": "7.147647556848824e-05",
   "Neutral": "0.9798415899276733",
   "Mixed": "7.097985508153215e-05"
 },
 {
   "tweet_id": "1589979170687569925",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979155990740993",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589979154279432200",
   "Positive": "0.020468829199671745",
   "Negative": "7.016937888693064e-05",
   "Neutral": "0.979387640953064",
   "Mixed": "7.336120324907824e-05"
 },
 {
   "tweet_id": "1589979153184718850",
   "Positive": "0.3514038622379303",
   "Negative": "0.014111286029219627",
   "Neutral": "0.6344097256660461",
   "Mixed": "7.513481250498444e-05"
 },
 {
   "tweet_id": "1589979147220455425",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589979142954844160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979140383715328",
   "Positive": "0.02046152576804161",
   "Negative": "6.716154894093052e-05",
   "Neutral": "0.9793761968612671",
   "Mixed": "9.520815365249291e-05"
 },
 {
   "tweet_id": "1589979135895834625",
   "Positive": "0.000258500047493726",
   "Negative": "0.0002372095623286441",
   "Neutral": "0.9994971752166748",
   "Mixed": "7.16596423444571e-06"
 },
 {
   "tweet_id": "1589979131961565184",
   "Positive": "0.033061955124139786",
   "Negative": "0.003734130645170808",
   "Neutral": "0.9631708860397339",
   "Mixed": "3.2988034945447e-05"
 },
 {
   "tweet_id": "1589979125623595008",
   "Positive": "0.021570533514022827",
   "Negative": "6.891964585520327e-05",
   "Neutral": "0.9782826900482178",
   "Mixed": "7.777266000630334e-05"
 },
 {
   "tweet_id": "1589979117784825857",
   "Positive": "0.030268538743257523",
   "Negative": "5.183000030228868e-05",
   "Neutral": "0.9694041609764099",
   "Mixed": "0.0002755040768533945"
 },
 {
   "tweet_id": "1589979117411520513",
   "Positive": "0.03438474237918854",
   "Negative": "0.004089018329977989",
   "Neutral": "0.9614951014518738",
   "Mixed": "3.1226245482685044e-05"
 },
 {
   "tweet_id": "1589979115234668545",
   "Positive": "0.019268833100795746",
   "Negative": "5.0037706387229264e-05",
   "Neutral": "0.9802852869033813",
   "Mixed": "0.0003958406741730869"
 },
 {
   "tweet_id": "1589979113288531971",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589979109824008194",
   "Positive": "0.012890593148767948",
   "Negative": "7.20020107110031e-05",
   "Neutral": "0.9869347810745239",
   "Mixed": "0.00010255217057419941"
 },
 {
   "tweet_id": "1589979107625906176",
   "Positive": "0.07315046340227127",
   "Negative": "0.0024071941152215004",
   "Neutral": "0.9244268536567688",
   "Mixed": "1.546481689729262e-05"
 },
 {
   "tweet_id": "1589979101523476487",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979100860813314",
   "Positive": "0.03347664698958397",
   "Negative": "0.003657888388261199",
   "Neutral": "0.9628332853317261",
   "Mixed": "3.211386501789093e-05"
 },
 {
   "tweet_id": "1589979096532254721",
   "Positive": "0.00033501689904369414",
   "Negative": "0.008792523294687271",
   "Neutral": "0.9908684492111206",
   "Mixed": "4.072800493304385e-06"
 },
 {
   "tweet_id": "1589979093554323457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979092140498944",
   "Positive": "0.01655142568051815",
   "Negative": "7.57745438022539e-05",
   "Neutral": "0.9832776188850403",
   "Mixed": "9.52171249082312e-05"
 },
 {
   "tweet_id": "1589979089104142336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979085567975424",
   "Positive": "0.033668309450149536",
   "Negative": "0.003616961417719722",
   "Neutral": "0.9626769423484802",
   "Mixed": "3.7726662412751466e-05"
 },
 {
   "tweet_id": "1589979083160817665",
   "Positive": "0.017204279080033302",
   "Negative": "4.45070254500024e-05",
   "Neutral": "0.9824167490005493",
   "Mixed": "0.00033451622584834695"
 },
 {
   "tweet_id": "1589979082472972289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589979072238882818",
   "Positive": "0.014092076569795609",
   "Negative": "4.497226109378971e-05",
   "Neutral": "0.9857681393623352",
   "Mixed": "9.481431334279478e-05"
 },
 {
   "tweet_id": "1589979067985510400",
   "Positive": "0.03175024315714836",
   "Negative": "0.004032209515571594",
   "Neutral": "0.9641860723495483",
   "Mixed": "3.149117037537508e-05"
 },
 {
   "tweet_id": "1589979063632134148",
   "Positive": "0.007236781995743513",
   "Negative": "0.7947205305099487",
   "Neutral": "0.19770072400569916",
   "Mixed": "0.0003420047287363559"
 },
 {
   "tweet_id": "1589979057474928640",
   "Positive": "0.016078533604741096",
   "Negative": "4.519972571870312e-05",
   "Neutral": "0.9836984872817993",
   "Mixed": "0.00017772905994206667"
 },
 {
   "tweet_id": "1589979054434050048",
   "Positive": "0.03327423706650734",
   "Negative": "0.0035249844659119844",
   "Neutral": "0.9631686806678772",
   "Mixed": "3.205888424417935e-05"
 },
 {
   "tweet_id": "1589979050071977987",
   "Positive": "0.01321687363088131",
   "Negative": "5.020042590331286e-05",
   "Neutral": "0.9866577982902527",
   "Mixed": "7.512985757784918e-05"
 },
 {
   "tweet_id": "1589979044715851777",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589979042849390592",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979040709943296",
   "Positive": "0.03493580222129822",
   "Negative": "0.003961397800594568",
   "Neutral": "0.9610695242881775",
   "Mixed": "3.325420766486786e-05"
 },
 {
   "tweet_id": "1589979037409357826",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979033542209539",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589979033210585088",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589979026843529216",
   "Positive": "0.022054610773921013",
   "Negative": "9.134069841820747e-05",
   "Neutral": "0.9778227210044861",
   "Mixed": "3.140903208986856e-05"
 },
 {
   "tweet_id": "1589979024876785665",
   "Positive": "0.034142304211854935",
   "Negative": "0.6770356297492981",
   "Neutral": "0.28401967883110046",
   "Mixed": "0.004802430979907513"
 },
 {
   "tweet_id": "1589979024834850816",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979014693019649",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589979009647280129",
   "Positive": "0.030344264581799507",
   "Negative": "0.00012928045180160552",
   "Neutral": "0.9694957733154297",
   "Mixed": "3.0604558560298756e-05"
 },
 {
   "tweet_id": "1589979009248808960",
   "Positive": "0.0009433111408725381",
   "Negative": "0.0003716358041856438",
   "Neutral": "0.9986820816993713",
   "Mixed": "2.953611556222313e-06"
 },
 {
   "tweet_id": "1589979003540369408",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589979000566603776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978994317103104",
   "Positive": "0.144370436668396",
   "Negative": "0.00015848349721636623",
   "Neutral": "0.8553329110145569",
   "Mixed": "0.00013819329615216702"
 },
 {
   "tweet_id": "1589978993234939905",
   "Positive": "0.02393335849046707",
   "Negative": "7.670292688999325e-05",
   "Neutral": "0.9759317636489868",
   "Mixed": "5.820100341225043e-05"
 },
 {
   "tweet_id": "1589978985064456194",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589978974457036805",
   "Positive": "0.021192148327827454",
   "Negative": "6.0080725234001875e-05",
   "Neutral": "0.9786313772201538",
   "Mixed": "0.0001164454297395423"
 },
 {
   "tweet_id": "1589978974108921856",
   "Positive": "0.01887802593410015",
   "Negative": "4.70091836177744e-05",
   "Neutral": "0.9808457493782043",
   "Mixed": "0.00022918915783520788"
 },
 {
   "tweet_id": "1589978972791545859",
   "Positive": "0.0006541932234540582",
   "Negative": "0.0003130644035991281",
   "Neutral": "0.999009370803833",
   "Mixed": "2.338503509236034e-05"
 },
 {
   "tweet_id": "1589978964914675712",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1589978964650471425",
   "Positive": "0.7175388932228088",
   "Negative": "0.005781198851764202",
   "Neutral": "0.27666327357292175",
   "Mixed": "1.6634019630146213e-05"
 },
 {
   "tweet_id": "1589978961442111489",
   "Positive": "0.03493580222129822",
   "Negative": "0.003961397800594568",
   "Neutral": "0.9610695242881775",
   "Mixed": "3.325420766486786e-05"
 },
 {
   "tweet_id": "1589978957197508608",
   "Positive": "0.02163364551961422",
   "Negative": "6.046713679097593e-05",
   "Neutral": "0.9781885147094727",
   "Mixed": "0.00011736816668417305"
 },
 {
   "tweet_id": "1589978951791030272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978943582797831",
   "Positive": "0.03295790031552315",
   "Negative": "0.004083292093127966",
   "Neutral": "0.9629285335540771",
   "Mixed": "3.0347140636877157e-05"
 },
 {
   "tweet_id": "1589978942622273539",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978941443706880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589978941078790146",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589978938784481285",
   "Positive": "0.015658238902688026",
   "Negative": "5.390142177930102e-05",
   "Neutral": "0.984211266040802",
   "Mixed": "7.65975346439518e-05"
 },
 {
   "tweet_id": "1589978930752413697",
   "Positive": "0.0002822275855578482",
   "Negative": "0.293819785118103",
   "Neutral": "0.7058789730072021",
   "Mixed": "1.899557355500292e-05"
 },
 {
   "tweet_id": "1589978927019462656",
   "Positive": "0.03555891290307045",
   "Negative": "0.0038636086974292994",
   "Neutral": "0.9605445265769958",
   "Mixed": "3.2937728974502534e-05"
 },
 {
   "tweet_id": "1589978924507099136",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589978922959400961",
   "Positive": "0.2506672739982605",
   "Negative": "0.08527643233537674",
   "Neutral": "0.6211933493614197",
   "Mixed": "0.0428629107773304"
 },
 {
   "tweet_id": "1589978921604632576",
   "Positive": "0.021786730736494064",
   "Negative": "6.630729330936447e-05",
   "Neutral": "0.9780500531196594",
   "Mixed": "9.683161624707282e-05"
 },
 {
   "tweet_id": "1589978919109001217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978917414535169",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589978916865073154",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978911999692804",
   "Positive": "0.018561696633696556",
   "Negative": "4.5545395551016554e-05",
   "Neutral": "0.981155276298523",
   "Mixed": "0.0002374727337155491"
 },
 {
   "tweet_id": "1589978911412486144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978898884079616",
   "Positive": "0.03409591317176819",
   "Negative": "0.004292671103030443",
   "Neutral": "0.9615810513496399",
   "Mixed": "3.038263093912974e-05"
 },
 {
   "tweet_id": "1589978897055350786",
   "Positive": "0.024245666339993477",
   "Negative": "6.689353176625445e-05",
   "Neutral": "0.9755850434303284",
   "Mixed": "0.00010234792716801167"
 },
 {
   "tweet_id": "1589978894530007041",
   "Positive": "0.29981178045272827",
   "Negative": "0.0002044439606834203",
   "Neutral": "0.6999362111091614",
   "Mixed": "4.758673458127305e-05"
 },
 {
   "tweet_id": "1589978893368594432",
   "Positive": "0.5196846723556519",
   "Negative": "0.002032710937783122",
   "Neutral": "0.4781956076622009",
   "Mixed": "8.6997686594259e-05"
 },
 {
   "tweet_id": "1589978892907196416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978888289267713",
   "Positive": "0.019545936957001686",
   "Negative": "5.0077956984750926e-05",
   "Neutral": "0.9799647331237793",
   "Mixed": "0.0004392537521198392"
 },
 {
   "tweet_id": "1589978885885931520",
   "Positive": "0.009538923390209675",
   "Negative": "5.222102481639013e-05",
   "Neutral": "0.990342378616333",
   "Mixed": "6.643259257543832e-05"
 },
 {
   "tweet_id": "1589978883155075073",
   "Positive": "0.03409591317176819",
   "Negative": "0.004292671103030443",
   "Neutral": "0.9615810513496399",
   "Mixed": "3.038263093912974e-05"
 },
 {
   "tweet_id": "1589978877232717824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589978866164330496",
   "Positive": "0.03274885192513466",
   "Negative": "0.0037563631776720285",
   "Neutral": "0.9634658098220825",
   "Mixed": "2.8951006243005395e-05"
 },
 {
   "tweet_id": "1589978858039971842",
   "Positive": "0.0006541932234540582",
   "Negative": "0.0003130644035991281",
   "Neutral": "0.999009370803833",
   "Mixed": "2.338503509236034e-05"
 },
 {
   "tweet_id": "1589978856978812928",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589978852482519041",
   "Positive": "0.03341555967926979",
   "Negative": "0.003702861722558737",
   "Neutral": "0.9628511667251587",
   "Mixed": "3.0362012694240548e-05"
 },
 {
   "tweet_id": "1589978845675155456",
   "Positive": "0.016940545290708542",
   "Negative": "4.940614235238172e-05",
   "Neutral": "0.9829081296920776",
   "Mixed": "0.00010191912588197738"
 },
 {
   "tweet_id": "1589978844634939394",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589978840390324224",
   "Positive": "0.021037869155406952",
   "Negative": "4.808624362340197e-05",
   "Neutral": "0.9786924719810486",
   "Mixed": "0.0002215036511188373"
 },
 {
   "tweet_id": "1589978838930710528",
   "Positive": "0.03083568438887596",
   "Negative": "0.0032642590813338757",
   "Neutral": "0.9658693075180054",
   "Mixed": "3.0686405807500705e-05"
 },
 {
   "tweet_id": "1589978838884405251",
   "Positive": "0.053572896867990494",
   "Negative": "0.00014681160973850638",
   "Neutral": "0.9462504982948303",
   "Mixed": "2.9819077099091373e-05"
 },
 {
   "tweet_id": "1589978836284116992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978834547638273",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589978830470807556",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978829954592769",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589978826465243136",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978824074481664",
   "Positive": "0.03454067185521126",
   "Negative": "0.0030429281760007143",
   "Neutral": "0.962383508682251",
   "Mixed": "3.2901738450163975e-05"
 },
 {
   "tweet_id": "1589978816340189185",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978812842115072",
   "Positive": "0.9121055006980896",
   "Negative": "0.00010188875603489578",
   "Neutral": "0.08773694932460785",
   "Mixed": "5.5684147810097784e-05"
 },
 {
   "tweet_id": "1589978810698825730",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978810208092160",
   "Positive": "0.0363151840865612",
   "Negative": "0.0038335155695676804",
   "Neutral": "0.9598222970962524",
   "Mixed": "2.8923330319230445e-05"
 },
 {
   "tweet_id": "1589978795259224064",
   "Positive": "0.034768372774124146",
   "Negative": "0.003204511245712638",
   "Neutral": "0.9619967937469482",
   "Mixed": "3.0423319913097657e-05"
 },
 {
   "tweet_id": "1589978781326143488",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978778851500032",
   "Positive": "0.07386047393083572",
   "Negative": "0.003501105820760131",
   "Neutral": "0.9226152300834656",
   "Mixed": "2.3255150154000148e-05"
 },
 {
   "tweet_id": "1589978778167836672",
   "Positive": "0.03511171415448189",
   "Negative": "0.003918124362826347",
   "Neutral": "0.9609399437904358",
   "Mixed": "3.0218747269827873e-05"
 },
 {
   "tweet_id": "1589978765924667392",
   "Positive": "0.023005232214927673",
   "Negative": "4.5730990677839145e-05",
   "Neutral": "0.9766567349433899",
   "Mixed": "0.0002922946587204933"
 },
 {
   "tweet_id": "1589978730428260355",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589978723759292419",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978721422696449",
   "Positive": "0.01615571416914463",
   "Negative": "6.957622827030718e-05",
   "Neutral": "0.9836369156837463",
   "Mixed": "0.00013780614244751632"
 },
 {
   "tweet_id": "1589978711561945088",
   "Positive": "0.0006541932234540582",
   "Negative": "0.0003130644035991281",
   "Neutral": "0.999009370803833",
   "Mixed": "2.338503509236034e-05"
 },
 {
   "tweet_id": "1589978710341361667",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589978702971949056",
   "Positive": "0.015431111678481102",
   "Negative": "5.524536391021684e-05",
   "Neutral": "0.9843931794166565",
   "Mixed": "0.0001204108411911875"
 },
 {
   "tweet_id": "1589978695032152066",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978687247888384",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589978683439472642",
   "Positive": "0.016777558252215385",
   "Negative": "6.015707549522631e-05",
   "Neutral": "0.9830067753791809",
   "Mixed": "0.00015554514538962394"
 },
 {
   "tweet_id": "1589978664015663106",
   "Positive": "0.015967752784490585",
   "Negative": "6.69689616188407e-05",
   "Neutral": "0.9838358163833618",
   "Mixed": "0.00012951147800777107"
 },
 {
   "tweet_id": "1589978648974856192",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978645581680642",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589978639453462529",
   "Positive": "0.01976899243891239",
   "Negative": "6.046043199603446e-05",
   "Neutral": "0.979981005191803",
   "Mixed": "0.00018945969350170344"
 },
 {
   "tweet_id": "1589978637457321984",
   "Positive": "0.022097475826740265",
   "Negative": "4.7752371756359935e-05",
   "Neutral": "0.9776684045791626",
   "Mixed": "0.00018632995488587767"
 },
 {
   "tweet_id": "1589978635255296002",
   "Positive": "0.01792892999947071",
   "Negative": "4.942884697811678e-05",
   "Neutral": "0.98182213306427",
   "Mixed": "0.0001994080957956612"
 },
 {
   "tweet_id": "1589978619195314177",
   "Positive": "0.01891927979886532",
   "Negative": "5.8365916629554704e-05",
   "Neutral": "0.9808312058448792",
   "Mixed": "0.00019122452067676932"
 },
 {
   "tweet_id": "1589978601071710211",
   "Positive": "0.019154971465468407",
   "Negative": "7.346149504883215e-05",
   "Neutral": "0.9805872440338135",
   "Mixed": "0.00018435662786941975"
 },
 {
   "tweet_id": "1589978595409399808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978580846792704",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978580351881216",
   "Positive": "0.1845145970582962",
   "Negative": "0.0007614186033606529",
   "Neutral": "0.8147111535072327",
   "Mixed": "1.2799290743714664e-05"
 },
 {
   "tweet_id": "1589978579898535936",
   "Positive": "0.014158882200717926",
   "Negative": "5.849725494044833e-05",
   "Neutral": "0.9856207370758057",
   "Mixed": "0.0001619428803678602"
 },
 {
   "tweet_id": "1589978577642360838",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589978577470390275",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978576396652547",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978575826227202",
   "Positive": "0.018417099490761757",
   "Negative": "4.689631532528438e-05",
   "Neutral": "0.9812090992927551",
   "Mixed": "0.0003269458538852632"
 },
 {
   "tweet_id": "1589978574022643712",
   "Positive": "0.0005695440922863781",
   "Negative": "0.0008706203661859035",
   "Neutral": "0.998465895652771",
   "Mixed": "9.392124047735706e-05"
 },
 {
   "tweet_id": "1589978569576706049",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978563130044422",
   "Positive": "0.020245293155312538",
   "Negative": "6.804530858062208e-05",
   "Neutral": "0.9795237183570862",
   "Mixed": "0.00016287363541778177"
 },
 {
   "tweet_id": "1589978557241262080",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978545828548608",
   "Positive": "0.017224738374352455",
   "Negative": "5.848202999914065e-05",
   "Neutral": "0.9825631380081177",
   "Mixed": "0.0001536999479867518"
 },
 {
   "tweet_id": "1589978544343756801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978537154387968",
   "Positive": "0.005141992587596178",
   "Negative": "0.00029840265051461756",
   "Neutral": "0.9945536851882935",
   "Mixed": "5.957887424301589e-06"
 },
 {
   "tweet_id": "1589978535221157892",
   "Positive": "0.026070863008499146",
   "Negative": "4.758320937980898e-05",
   "Neutral": "0.9737070798873901",
   "Mixed": "0.00017449773440603167"
 },
 {
   "tweet_id": "1589978525187985410",
   "Positive": "0.007629549130797386",
   "Negative": "0.8071482181549072",
   "Neutral": "0.18456712365150452",
   "Mixed": "0.0006551921833306551"
 },
 {
   "tweet_id": "1589978523871371264",
   "Positive": "0.025474855676293373",
   "Negative": "7.124462717911229e-05",
   "Neutral": "0.9743533134460449",
   "Mixed": "0.00010063203080790117"
 },
 {
   "tweet_id": "1589978512735494145",
   "Positive": "0.01887907087802887",
   "Negative": "4.913589509669691e-05",
   "Neutral": "0.9807065725326538",
   "Mixed": "0.00036518825800158083"
 },
 {
   "tweet_id": "1589978508159520768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978507945574400",
   "Positive": "0.00047242018627002835",
   "Negative": "0.9660132527351379",
   "Neutral": "0.033407244831323624",
   "Mixed": "0.00010716597898863256"
 },
 {
   "tweet_id": "1589978499498250240",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589978499028520960",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978496948113408",
   "Positive": "0.0005690953694283962",
   "Negative": "0.0011564184678718448",
   "Neutral": "0.9982455968856812",
   "Mixed": "2.890002724598162e-05"
 },
 {
   "tweet_id": "1589978494850990081",
   "Positive": "0.0009626406827010214",
   "Negative": "0.4039148986339569",
   "Neutral": "0.5935900211334229",
   "Mixed": "0.0015324457781389356"
 },
 {
   "tweet_id": "1589978493001297925",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978492003061761",
   "Positive": "0.019673557952046394",
   "Negative": "5.893455818295479e-05",
   "Neutral": "0.9801677465438843",
   "Mixed": "9.983641211874783e-05"
 },
 {
   "tweet_id": "1589978489415159808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978481877979142",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978480489697283",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978479160070145",
   "Positive": "0.020961368456482887",
   "Negative": "5.204199624131434e-05",
   "Neutral": "0.9781961441040039",
   "Mixed": "0.000790448219049722"
 },
 {
   "tweet_id": "1589978477482033152",
   "Positive": "0.5196846723556519",
   "Negative": "0.002032710937783122",
   "Neutral": "0.4781956076622009",
   "Mixed": "8.6997686594259e-05"
 },
 {
   "tweet_id": "1589978470167511040",
   "Positive": "0.013618768192827702",
   "Negative": "5.2845040045212954e-05",
   "Neutral": "0.9862083196640015",
   "Mixed": "0.00012004642485408112"
 },
 {
   "tweet_id": "1589978453977485313",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978452157136898",
   "Positive": "0.013612634502351284",
   "Negative": "5.109642370371148e-05",
   "Neutral": "0.9862498044967651",
   "Mixed": "8.647024515084922e-05"
 },
 {
   "tweet_id": "1589978450823368704",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589978447140773888",
   "Positive": "0.03296855464577675",
   "Negative": "5.096575478091836e-05",
   "Neutral": "0.9666973352432251",
   "Mixed": "0.00028313216171227396"
 },
 {
   "tweet_id": "1589978444150231040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978433563824128",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589978428522266629",
   "Positive": "0.01598677597939968",
   "Negative": "5.9838912420673296e-05",
   "Neutral": "0.9837630987167358",
   "Mixed": "0.00019031044212169945"
 },
 {
   "tweet_id": "1589978426651611136",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978421295476736",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589978410734190594",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978410134011904",
   "Positive": "0.021290116012096405",
   "Negative": "6.636750913457945e-05",
   "Neutral": "0.9784752130508423",
   "Mixed": "0.00016826028877403587"
 },
 {
   "tweet_id": "1589978407747858432",
   "Positive": "0.020857106894254684",
   "Negative": "4.7080451622605324e-05",
   "Neutral": "0.9787782430648804",
   "Mixed": "0.00031756900716573"
 },
 {
   "tweet_id": "1589978396066746371",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978391616573442",
   "Positive": "0.020030483603477478",
   "Negative": "5.2258004870964214e-05",
   "Neutral": "0.9796900749206543",
   "Mixed": "0.0002270951954415068"
 },
 {
   "tweet_id": "1589978373463609344",
   "Positive": "0.026287322863936424",
   "Negative": "8.208911458496004e-05",
   "Neutral": "0.9735633134841919",
   "Mixed": "6.727244181092829e-05"
 },
 {
   "tweet_id": "1589978369785225218",
   "Positive": "0.02464080974459648",
   "Negative": "4.962713137501851e-05",
   "Neutral": "0.9751574993133545",
   "Mixed": "0.00015200013876892626"
 },
 {
   "tweet_id": "1589978365477675010",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978363686682624",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978356392800257",
   "Positive": "0.022482948377728462",
   "Negative": "6.393566582119092e-05",
   "Neutral": "0.9773536920547485",
   "Mixed": "9.94731963146478e-05"
 },
 {
   "tweet_id": "1589978355730108416",
   "Positive": "0.0002555187966208905",
   "Negative": "0.9858214259147644",
   "Neutral": "0.013917060568928719",
   "Mixed": "5.973993211227935e-06"
 },
 {
   "tweet_id": "1589978339418468352",
   "Positive": "0.0252113938331604",
   "Negative": "7.211900083348155e-05",
   "Neutral": "0.9744681715965271",
   "Mixed": "0.0002484076248947531"
 },
 {
   "tweet_id": "1589978337543589890",
   "Positive": "0.017902150750160217",
   "Negative": "5.3702144214184955e-05",
   "Neutral": "0.9819575548171997",
   "Mixed": "8.66426489665173e-05"
 },
 {
   "tweet_id": "1589978331985768451",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589978331130507264",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589978322527981569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978304085635074",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589978302701506560",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1589978300126224388",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978296896610304",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978296292634626",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978291733426176",
   "Positive": "0.017946945503354073",
   "Negative": "4.469331179279834e-05",
   "Neutral": "0.9818264245986938",
   "Mixed": "0.00018196886230725795"
 },
 {
   "tweet_id": "1589978291725008899",
   "Positive": "0.977985143661499",
   "Negative": "0.002611681120470166",
   "Neutral": "0.01878700777888298",
   "Mixed": "0.000616056437138468"
 },
 {
   "tweet_id": "1589978285672652801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978284812832769",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589978277753806849",
   "Positive": "0.012026717886328697",
   "Negative": "0.6509580016136169",
   "Neutral": "0.14676660299301147",
   "Mixed": "0.19024865329265594"
 },
 {
   "tweet_id": "1589978255054229504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978252252438528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978249555496962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978233302573058",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978225736060928",
   "Positive": "0.01623709313571453",
   "Negative": "7.93406943557784e-05",
   "Neutral": "0.9836693406105042",
   "Mixed": "1.4281426956586074e-05"
 },
 {
   "tweet_id": "1589978224821665796",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589978221067796480",
   "Positive": "0.05752812325954437",
   "Negative": "0.0006409278721548617",
   "Neutral": "0.9418043494224548",
   "Mixed": "2.6605526727507822e-05"
 },
 {
   "tweet_id": "1589978216193613824",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1589978210942734338",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589978207364997120",
   "Positive": "0.03472612053155899",
   "Negative": "5.304812657414004e-05",
   "Neutral": "0.9648174047470093",
   "Mixed": "0.0004034086305182427"
 },
 {
   "tweet_id": "1589978187991089153",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589978185768534017",
   "Positive": "0.06261710077524185",
   "Negative": "0.017338493838906288",
   "Neutral": "0.9199673533439636",
   "Mixed": "7.707144686719403e-05"
 },
 {
   "tweet_id": "1589978180827635720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978180206874625",
   "Positive": "0.04056357592344284",
   "Negative": "0.018516337499022484",
   "Neutral": "0.9407331943511963",
   "Mixed": "0.00018694513710215688"
 },
 {
   "tweet_id": "1589978176918548482",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978168253120513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978164209385472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978163949764608",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589978159856128000",
   "Positive": "0.019388703629374504",
   "Negative": "5.925286313868128e-05",
   "Neutral": "0.9804389476776123",
   "Mixed": "0.00011312096467008814"
 },
 {
   "tweet_id": "1589978148200124418",
   "Positive": "0.03252337500452995",
   "Negative": "0.00300762802362442",
   "Neutral": "0.9644393920898438",
   "Mixed": "2.9569098842330277e-05"
 },
 {
   "tweet_id": "1589978147037945856",
   "Positive": "0.0009932584362104535",
   "Negative": "0.0009977108566090465",
   "Neutral": "0.9979669451713562",
   "Mixed": "4.2069677874678746e-05"
 },
 {
   "tweet_id": "1589978139153043456",
   "Positive": "0.015137804672122002",
   "Negative": "4.862900823354721e-05",
   "Neutral": "0.984673261642456",
   "Mixed": "0.00014028044824954122"
 },
 {
   "tweet_id": "1589978133666861057",
   "Positive": "0.03246157243847847",
   "Negative": "0.003271553199738264",
   "Neutral": "0.9642389416694641",
   "Mixed": "2.7987158318865113e-05"
 },
 {
   "tweet_id": "1589978126326861825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978116784820225",
   "Positive": "0.015357169322669506",
   "Negative": "4.998621079721488e-05",
   "Neutral": "0.984460175037384",
   "Mixed": "0.00013262502034194767"
 },
 {
   "tweet_id": "1589978116252110848",
   "Positive": "0.03613070771098137",
   "Negative": "0.003105936339125037",
   "Neutral": "0.9607306122779846",
   "Mixed": "3.280512828496285e-05"
 },
 {
   "tweet_id": "1589978103043891200",
   "Positive": "0.001035452471114695",
   "Negative": "0.8777607679367065",
   "Neutral": "0.12095226347446442",
   "Mixed": "0.00025143910897895694"
 },
 {
   "tweet_id": "1589978102058602498",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589978099667865601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589978098984189952",
   "Positive": "0.034606337547302246",
   "Negative": "0.0034525147639214993",
   "Neutral": "0.9619090557098389",
   "Mixed": "3.208662019460462e-05"
 },
 {
   "tweet_id": "1589978090171936768",
   "Positive": "0.018595878034830093",
   "Negative": "4.36891641584225e-05",
   "Neutral": "0.9811650514602661",
   "Mixed": "0.00019529140263330191"
 },
 {
   "tweet_id": "1589978089031098368",
   "Positive": "0.35136446356773376",
   "Negative": "0.0011146782198920846",
   "Neutral": "0.6474706530570984",
   "Mixed": "5.027955921832472e-05"
 },
 {
   "tweet_id": "1589978087600816129",
   "Positive": "0.014823869802057743",
   "Negative": "5.312645225785673e-05",
   "Neutral": "0.9850156307220459",
   "Mixed": "0.00010734725219663233"
 },
 {
   "tweet_id": "1589978084765503491",
   "Positive": "0.03638731315732002",
   "Negative": "0.003272098256275058",
   "Neutral": "0.9603086113929749",
   "Mixed": "3.195844328729436e-05"
 },
 {
   "tweet_id": "1589978079333863425",
   "Positive": "0.0024830421898514032",
   "Negative": "0.00023259803128894418",
   "Neutral": "0.9972816705703735",
   "Mixed": "2.6691664061218034e-06"
 },
 {
   "tweet_id": "1589978077836505088",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589978077719048192",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589978072841072641",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589978072019001344",
   "Positive": "0.03294084966182709",
   "Negative": "0.002965551568195224",
   "Neutral": "0.964061439037323",
   "Mixed": "3.218068741261959e-05"
 },
 {
   "tweet_id": "1589978057309552646",
   "Positive": "0.03318355605006218",
   "Negative": "0.0032677880953997374",
   "Neutral": "0.9635210633277893",
   "Mixed": "2.761947689577937e-05"
 },
 {
   "tweet_id": "1589978044063944704",
   "Positive": "0.024324210360646248",
   "Negative": "0.00023688811052124947",
   "Neutral": "0.9754309058189392",
   "Mixed": "8.02657177700894e-06"
 },
 {
   "tweet_id": "1589978043753598976",
   "Positive": "0.032764732837677",
   "Negative": "0.0031462458427995443",
   "Neutral": "0.9640582203865051",
   "Mixed": "3.076229404541664e-05"
 },
 {
   "tweet_id": "1589978042960875521",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589978032667709441",
   "Positive": "0.12760983407497406",
   "Negative": "0.00014736047887708992",
   "Neutral": "0.8720535039901733",
   "Mixed": "0.00018931693921331316"
 },
 {
   "tweet_id": "1589978026347200514",
   "Positive": "0.033782389014959335",
   "Negative": "0.0032577402889728546",
   "Neutral": "0.9629293084144592",
   "Mixed": "3.0498702471959405e-05"
 },
 {
   "tweet_id": "1589978023310548995",
   "Positive": "0.026873089373111725",
   "Negative": "5.088096440886147e-05",
   "Neutral": "0.9727492332458496",
   "Mixed": "0.0003267635474912822"
 },
 {
   "tweet_id": "1589978019581825025",
   "Positive": "0.07008559256792068",
   "Negative": "0.009396571666002274",
   "Neutral": "0.9158008098602295",
   "Mixed": "0.004717034287750721"
 },
 {
   "tweet_id": "1589978012509868032",
   "Positive": "0.03371648117899895",
   "Negative": "0.003497743746265769",
   "Neutral": "0.9627574682235718",
   "Mixed": "2.8217877115821466e-05"
 },
 {
   "tweet_id": "1589978006411685889",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977999700807682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977999474331649",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977999327494148",
   "Positive": "0.03491797298192978",
   "Negative": "0.002762935357168317",
   "Neutral": "0.9622907638549805",
   "Mixed": "2.8314641895121895e-05"
 },
 {
   "tweet_id": "1589977994982223872",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589977994927702018",
   "Positive": "0.03394841030240059",
   "Negative": "0.0005187430651858449",
   "Neutral": "0.9655267000198364",
   "Mixed": "6.137268883321667e-06"
 },
 {
   "tweet_id": "1589977992067153921",
   "Positive": "0.0018768180161714554",
   "Negative": "0.9056609272956848",
   "Neutral": "0.09202059358358383",
   "Mixed": "0.00044173019705340266"
 },
 {
   "tweet_id": "1589977991840694273",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977987336007680",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977984923942914",
   "Positive": "0.033505965024232864",
   "Negative": "0.0035089394077658653",
   "Neutral": "0.9629548788070679",
   "Mixed": "3.018569441337604e-05"
 },
 {
   "tweet_id": "1589977974488862721",
   "Positive": "0.00026152023929171264",
   "Negative": "0.00028180080698803067",
   "Neutral": "0.9994200468063354",
   "Mixed": "3.6650762922363356e-05"
 },
 {
   "tweet_id": "1589977970676215809",
   "Positive": "0.033533111214637756",
   "Negative": "0.0032843644730746746",
   "Neutral": "0.9631527662277222",
   "Mixed": "2.9728464141953737e-05"
 },
 {
   "tweet_id": "1589977970260987904",
   "Positive": "0.35355666279792786",
   "Negative": "0.0064088208600878716",
   "Neutral": "0.6013042330741882",
   "Mixed": "0.0387301929295063"
 },
 {
   "tweet_id": "1589977969803792386",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589977952019972097",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977950434512896",
   "Positive": "0.6390655040740967",
   "Negative": "0.00012062276800861582",
   "Neutral": "0.3605356812477112",
   "Mixed": "0.0002781267976388335"
 },
 {
   "tweet_id": "1589977946676396033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977946047262721",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977944013017088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977938346528769",
   "Positive": "0.03507711738348007",
   "Negative": "0.003567649982869625",
   "Neutral": "0.961324155330658",
   "Mixed": "3.103874041698873e-05"
 },
 {
   "tweet_id": "1589977934982705154",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1589977927684620289",
   "Positive": "0.9775621294975281",
   "Negative": "0.0008471724577248096",
   "Neutral": "0.021437378600239754",
   "Mixed": "0.00015330503811128438"
 },
 {
   "tweet_id": "1589977924815695872",
   "Positive": "0.03511879965662956",
   "Negative": "0.002977642696350813",
   "Neutral": "0.961875319480896",
   "Mixed": "2.822285023285076e-05"
 },
 {
   "tweet_id": "1589977922320101377",
   "Positive": "0.03471830487251282",
   "Negative": "6.818449037382379e-05",
   "Neutral": "0.9649204015731812",
   "Mixed": "0.0002931084018200636"
 },
 {
   "tweet_id": "1589977921804173312",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977919824465921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977914506096641",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977912299900930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977911360376832",
   "Positive": "0.034238941967487335",
   "Negative": "0.0032012967858463526",
   "Neutral": "0.9625301361083984",
   "Mixed": "2.9615077437483706e-05"
 },
 {
   "tweet_id": "1589977907623260160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977897309044736",
   "Positive": "0.03502553328871727",
   "Negative": "0.0033046617172658443",
   "Neutral": "0.9616394639015198",
   "Mixed": "3.0408709790208377e-05"
 },
 {
   "tweet_id": "1589977881681461248",
   "Positive": "0.03684091940522194",
   "Negative": "0.0034193338360637426",
   "Neutral": "0.9597055912017822",
   "Mixed": "3.415248283999972e-05"
 },
 {
   "tweet_id": "1589977879781453824",
   "Positive": "0.31504446268081665",
   "Negative": "0.014829082414507866",
   "Neutral": "0.6261899471282959",
   "Mixed": "0.043936584144830704"
 },
 {
   "tweet_id": "1589977869102780417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977867173392384",
   "Positive": "0.014916394837200642",
   "Negative": "5.212788892094977e-05",
   "Neutral": "0.9849061369895935",
   "Mixed": "0.00012542058539111167"
 },
 {
   "tweet_id": "1589977862396071938",
   "Positive": "0.03545556589961052",
   "Negative": "0.003542855614796281",
   "Neutral": "0.9609701037406921",
   "Mixed": "3.155883678118698e-05"
 },
 {
   "tweet_id": "1589977859166466048",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589977857903984642",
   "Positive": "0.02909492515027523",
   "Negative": "0.028654253110289574",
   "Neutral": "0.9421893954277039",
   "Mixed": "6.147230305941775e-05"
 },
 {
   "tweet_id": "1589977855815200768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977846315114496",
   "Positive": "0.02590707689523697",
   "Negative": "7.148880831664428e-05",
   "Neutral": "0.9738895297050476",
   "Mixed": "0.00013188892626203597"
 },
 {
   "tweet_id": "1589977842968064000",
   "Positive": "0.02745186910033226",
   "Negative": "6.053359538782388e-05",
   "Neutral": "0.9722407460212708",
   "Mixed": "0.000246868614340201"
 },
 {
   "tweet_id": "1589977841504227330",
   "Positive": "0.3607783317565918",
   "Negative": "0.007392415311187506",
   "Neutral": "0.5862864255905151",
   "Mixed": "0.04554278403520584"
 },
 {
   "tweet_id": "1589977839470018560",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977836269735938",
   "Positive": "0.00026152023929171264",
   "Negative": "0.00028180080698803067",
   "Neutral": "0.9994200468063354",
   "Mixed": "3.6650762922363356e-05"
 },
 {
   "tweet_id": "1589977828040536065",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589977825201000451",
   "Positive": "0.013976865448057652",
   "Negative": "4.915886529488489e-05",
   "Neutral": "0.9858631491661072",
   "Mixed": "0.00011077634553657845"
 },
 {
   "tweet_id": "1589977808256008192",
   "Positive": "0.010483741760253906",
   "Negative": "0.00011293425632175058",
   "Neutral": "0.9893968105316162",
   "Mixed": "6.517711426567985e-06"
 },
 {
   "tweet_id": "1589977807836581889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977804099452928",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977802370994176",
   "Positive": "0.014318273402750492",
   "Negative": "4.834166611544788e-05",
   "Neutral": "0.9855006337165833",
   "Mixed": "0.00013273961667437106"
 },
 {
   "tweet_id": "1589977797174624263",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589977795140411392",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589977786646953984",
   "Positive": "0.06153278797864914",
   "Negative": "0.00010083935922011733",
   "Neutral": "0.9383320808410645",
   "Mixed": "3.433131496421993e-05"
 },
 {
   "tweet_id": "1589977785996840961",
   "Positive": "0.020012175664305687",
   "Negative": "6.13262236583978e-05",
   "Neutral": "0.979790449142456",
   "Mixed": "0.00013603274419438094"
 },
 {
   "tweet_id": "1589977785325719552",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589977776987475969",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589977770104614912",
   "Positive": "0.013440015725791454",
   "Negative": "4.953335883328691e-05",
   "Neutral": "0.9864066243171692",
   "Mixed": "0.00010386802023276687"
 },
 {
   "tweet_id": "1589977769345454085",
   "Positive": "0.03108472004532814",
   "Negative": "6.041906453901902e-05",
   "Neutral": "0.9685899615287781",
   "Mixed": "0.0002648650552146137"
 },
 {
   "tweet_id": "1589977769160888320",
   "Positive": "0.01231645792722702",
   "Negative": "0.0002992443914990872",
   "Neutral": "0.9873720407485962",
   "Mixed": "1.2255641195224598e-05"
 },
 {
   "tweet_id": "1589977768477208576",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977765868371968",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977753730035712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977752513708032",
   "Positive": "0.020329413935542107",
   "Negative": "6.243688403628767e-05",
   "Neutral": "0.9794937968254089",
   "Mixed": "0.0001143899789894931"
 },
 {
   "tweet_id": "1589977747878993921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977747320758274",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589977744343199745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977733727424516",
   "Positive": "0.013454326428472996",
   "Negative": "4.0850136429071426e-05",
   "Neutral": "0.9863854050636292",
   "Mixed": "0.00011945921869482845"
 },
 {
   "tweet_id": "1589977728757166081",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977721521963008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977717617065989",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589977715095924737",
   "Positive": "0.015107427723705769",
   "Negative": "4.55434710602276e-05",
   "Neutral": "0.984721839427948",
   "Mixed": "0.00012517320283222944"
 },
 {
   "tweet_id": "1589977712198029312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977707366211590",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977697668980737",
   "Positive": "0.033911846578121185",
   "Negative": "4.858497413806617e-05",
   "Neutral": "0.9658020734786987",
   "Mixed": "0.000237513508182019"
 },
 {
   "tweet_id": "1589977695894765571",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977694053490690",
   "Positive": "0.012112854048609734",
   "Negative": "5.011276516597718e-05",
   "Neutral": "0.9877614974975586",
   "Mixed": "7.552858005510643e-05"
 },
 {
   "tweet_id": "1589977689141972993",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977685664878598",
   "Positive": "0.31154918670654297",
   "Negative": "0.015879275277256966",
   "Neutral": "0.6252087950706482",
   "Mixed": "0.0473628006875515"
 },
 {
   "tweet_id": "1589977675141353474",
   "Positive": "0.026079067960381508",
   "Negative": "6.140196637716144e-05",
   "Neutral": "0.9737209677696228",
   "Mixed": "0.0001385544746881351"
 },
 {
   "tweet_id": "1589977664462327808",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589977662315180033",
   "Positive": "0.0009932584362104535",
   "Negative": "0.0009977108566090465",
   "Neutral": "0.9979669451713562",
   "Mixed": "4.2069677874678746e-05"
 },
 {
   "tweet_id": "1589977654576705539",
   "Positive": "0.03432084619998932",
   "Negative": "0.0033375518396496773",
   "Neutral": "0.9623132944107056",
   "Mixed": "2.83246481558308e-05"
 },
 {
   "tweet_id": "1589977653402284033",
   "Positive": "0.024437252432107925",
   "Negative": "5.588978820014745e-05",
   "Neutral": "0.9752811789512634",
   "Mixed": "0.0002256632869830355"
 },
 {
   "tweet_id": "1589977642820071424",
   "Positive": "0.29939934611320496",
   "Negative": "0.00708403903990984",
   "Neutral": "0.6881399750709534",
   "Mixed": "0.005376688204705715"
 },
 {
   "tweet_id": "1589977639930195968",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589977638533484546",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589977635924295680",
   "Positive": "0.0026878940407186747",
   "Negative": "0.6738008260726929",
   "Neutral": "0.3232819139957428",
   "Mixed": "0.00022941264614928514"
 },
 {
   "tweet_id": "1589977629381513217",
   "Positive": "0.023289797827601433",
   "Negative": "6.422192382160574e-05",
   "Neutral": "0.9764605760574341",
   "Mixed": "0.00018544247723184526"
 },
 {
   "tweet_id": "1589977626751692803",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589977619256475649",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977616836349954",
   "Positive": "0.05362483486533165",
   "Negative": "5.1893617637688294e-05",
   "Neutral": "0.9459859728813171",
   "Mixed": "0.00033724840614013374"
 },
 {
   "tweet_id": "1589977604052127746",
   "Positive": "0.32394757866859436",
   "Negative": "0.012815640307962894",
   "Neutral": "0.6549116969108582",
   "Mixed": "0.00832511205226183"
 },
 {
   "tweet_id": "1589977595931947008",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589977590563246081",
   "Positive": "0.6576926708221436",
   "Negative": "0.004128767643123865",
   "Neutral": "0.336586058139801",
   "Mixed": "0.0015925696352496743"
 },
 {
   "tweet_id": "1589977585303572481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977569608478725",
   "Positive": "0.4354490637779236",
   "Negative": "0.0038508856669068336",
   "Neutral": "0.5510807633399963",
   "Mixed": "0.009619289077818394"
 },
 {
   "tweet_id": "1589977562435895296",
   "Positive": "0.0003987720119766891",
   "Negative": "0.0002251121331937611",
   "Neutral": "0.9993732571601868",
   "Mixed": "2.8601064059330383e-06"
 },
 {
   "tweet_id": "1589977558611013638",
   "Positive": "0.022368552163243294",
   "Negative": "6.620444764848799e-05",
   "Neutral": "0.9774535298347473",
   "Mixed": "0.00011176695261383429"
 },
 {
   "tweet_id": "1589977557826695170",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589977556308361216",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977555469488128",
   "Positive": "0.08698961138725281",
   "Negative": "0.0005846629501320422",
   "Neutral": "0.9124141335487366",
   "Mixed": "1.1498514140839688e-05"
 },
 {
   "tweet_id": "1589977553603039234",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589977548955725825",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589977545214427138",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977537761148929",
   "Positive": "0.026261862367391586",
   "Negative": "7.760803418932483e-05",
   "Neutral": "0.9735678434371948",
   "Mixed": "9.273649629903957e-05"
 },
 {
   "tweet_id": "1589977532451131392",
   "Positive": "0.32788264751434326",
   "Negative": "0.00655193580314517",
   "Neutral": "0.6620200276374817",
   "Mixed": "0.003545437240973115"
 },
 {
   "tweet_id": "1589977529477378050",
   "Positive": "0.03868306055665016",
   "Negative": "5.426745337899774e-05",
   "Neutral": "0.9610384702682495",
   "Mixed": "0.00022418622393161058"
 },
 {
   "tweet_id": "1589977516059807744",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589977515460030465",
   "Positive": "0.01641683466732502",
   "Negative": "4.826369331567548e-05",
   "Neutral": "0.9834176898002625",
   "Mixed": "0.00011728609388228506"
 },
 {
   "tweet_id": "1589977504357711872",
   "Positive": "0.10116463899612427",
   "Negative": "0.00013361552555579692",
   "Neutral": "0.8985353112220764",
   "Mixed": "0.00016641952970530838"
 },
 {
   "tweet_id": "1589977499320332291",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589977497390964736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977492215189505",
   "Positive": "0.019307376816868782",
   "Negative": "4.852204074268229e-05",
   "Neutral": "0.9804508686065674",
   "Mixed": "0.0001932291779667139"
 },
 {
   "tweet_id": "1589977488163504130",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589977485458178050",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589977485206515717",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977481423257601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977479938445314",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977471122046977",
   "Positive": "0.02597237378358841",
   "Negative": "7.156292849685997e-05",
   "Neutral": "0.9738556146621704",
   "Mixed": "0.0001004339792416431"
 },
 {
   "tweet_id": "1589977457587007491",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977455817023493",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1589977452113457153",
   "Positive": "0.025780009105801582",
   "Negative": "6.462860619649291e-05",
   "Neutral": "0.9739680886268616",
   "Mixed": "0.00018732661555986851"
 },
 {
   "tweet_id": "1589977448967389184",
   "Positive": "0.02381581813097",
   "Negative": "5.971535210846923e-05",
   "Neutral": "0.9759581089019775",
   "Mixed": "0.00016638520173728466"
 },
 {
   "tweet_id": "1589977446719586304",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977438066741250",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589977425068556289",
   "Positive": "0.023187685757875443",
   "Negative": "6.062420652597211e-05",
   "Neutral": "0.9766142964363098",
   "Mixed": "0.00013742499868385494"
 },
 {
   "tweet_id": "1589977422329688065",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977418236039168",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589977405510549505",
   "Positive": "0.35786765813827515",
   "Negative": "0.008318870328366756",
   "Neutral": "0.6167126297950745",
   "Mixed": "0.01710077002644539"
 },
 {
   "tweet_id": "1589977392650469376",
   "Positive": "0.025459526106715202",
   "Negative": "6.822648720117286e-05",
   "Neutral": "0.9743494391441345",
   "Mixed": "0.00012282608076930046"
 },
 {
   "tweet_id": "1589977390511706114",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977375697039361",
   "Positive": "0.025988832116127014",
   "Negative": "6.694739568047225e-05",
   "Neutral": "0.9738134145736694",
   "Mixed": "0.00013078846677672118"
 },
 {
   "tweet_id": "1589977369389203457",
   "Positive": "0.08115919679403305",
   "Negative": "0.002056647324934602",
   "Neutral": "0.9167684316635132",
   "Mixed": "1.5727642676210962e-05"
 },
 {
   "tweet_id": "1589977368323829761",
   "Positive": "0.012679223902523518",
   "Negative": "4.178094968665391e-05",
   "Neutral": "0.9870955944061279",
   "Mixed": "0.00018337636720389128"
 },
 {
   "tweet_id": "1589977358647205890",
   "Positive": "0.013959907926619053",
   "Negative": "5.3287145419744775e-05",
   "Neutral": "0.9858960509300232",
   "Mixed": "9.07416979316622e-05"
 },
 {
   "tweet_id": "1589977357552844800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977352540651523",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977350963625985",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977343158013953",
   "Positive": "0.3474951386451721",
   "Negative": "0.01109023205935955",
   "Neutral": "0.5989696383476257",
   "Mixed": "0.042444974184036255"
 },
 {
   "tweet_id": "1589977339706105856",
   "Positive": "0.02003365382552147",
   "Negative": "7.349410589085892e-05",
   "Neutral": "0.979804277420044",
   "Mixed": "8.847741992212832e-05"
 },
 {
   "tweet_id": "1589977319011065857",
   "Positive": "0.15362894535064697",
   "Negative": "0.45348578691482544",
   "Neutral": "0.39164432883262634",
   "Mixed": "0.001240950427018106"
 },
 {
   "tweet_id": "1589977318390632451",
   "Positive": "0.014098229818046093",
   "Negative": "4.965449625160545e-05",
   "Neutral": "0.9857532978057861",
   "Mixed": "9.886424959404394e-05"
 },
 {
   "tweet_id": "1589977312396992513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977310610223104",
   "Positive": "0.8284575939178467",
   "Negative": "0.0004613910859916359",
   "Neutral": "0.17086975276470184",
   "Mixed": "0.0002112310758093372"
 },
 {
   "tweet_id": "1589977298308300800",
   "Positive": "0.019533725455403328",
   "Negative": "6.674328324152157e-05",
   "Neutral": "0.9803380966186523",
   "Mixed": "6.149582623038441e-05"
 },
 {
   "tweet_id": "1589977292063014912",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589977289647063040",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589977289579958272",
   "Positive": "0.022928625345230103",
   "Negative": "4.9664173275232315e-05",
   "Neutral": "0.9768816828727722",
   "Mixed": "0.0001400047040078789"
 },
 {
   "tweet_id": "1589977287348391936",
   "Positive": "0.0038548423908650875",
   "Negative": "0.030315203592181206",
   "Neutral": "0.9658019542694092",
   "Mixed": "2.7982310712104663e-05"
 },
 {
   "tweet_id": "1589977287050526722",
   "Positive": "0.011801778338849545",
   "Negative": "0.00037961636553518474",
   "Neutral": "0.9871020913124084",
   "Mixed": "0.0007164549897424877"
 },
 {
   "tweet_id": "1589977282655195137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977280637734915",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977276611170304",
   "Positive": "0.021932629868388176",
   "Negative": "6.906990893185139e-05",
   "Neutral": "0.9778953194618225",
   "Mixed": "0.00010289296915289015"
 },
 {
   "tweet_id": "1589977274946052097",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589977269468286976",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977259536191488",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589977255903494145",
   "Positive": "0.016454841941595078",
   "Negative": "5.748621697421186e-05",
   "Neutral": "0.9834044575691223",
   "Mixed": "8.32515288493596e-05"
 },
 {
   "tweet_id": "1589977255761313793",
   "Positive": "0.0007684982847422361",
   "Negative": "0.9742178320884705",
   "Neutral": "0.02443903684616089",
   "Mixed": "0.0005746046081185341"
 },
 {
   "tweet_id": "1589977253441519617",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1589977251906740229",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977247829856257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977235825790977",
   "Positive": "0.02663281559944153",
   "Negative": "9.428690100321546e-05",
   "Neutral": "0.9731962084770203",
   "Mixed": "7.670319610042498e-05"
 },
 {
   "tweet_id": "1589977234349395968",
   "Positive": "0.038538750261068344",
   "Negative": "5.1892227929783985e-05",
   "Neutral": "0.9610094428062439",
   "Mixed": "0.0003999305481556803"
 },
 {
   "tweet_id": "1589977226556342272",
   "Positive": "0.07079969346523285",
   "Negative": "0.0003755752113647759",
   "Neutral": "0.9287689924240112",
   "Mixed": "5.5841548601165414e-05"
 },
 {
   "tweet_id": "1589977223964282882",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589977220713689088",
   "Positive": "0.0668702945113182",
   "Negative": "0.001973434817045927",
   "Neutral": "0.9310665726661682",
   "Mixed": "8.965924644144252e-05"
 },
 {
   "tweet_id": "1589977215965724673",
   "Positive": "0.025465957820415497",
   "Negative": "7.528016431024298e-05",
   "Neutral": "0.9743391275405884",
   "Mixed": "0.00011968801118200645"
 },
 {
   "tweet_id": "1589977198328705025",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977192640876545",
   "Positive": "0.019473949447274208",
   "Negative": "5.7372537412447855e-05",
   "Neutral": "0.9803857803344727",
   "Mixed": "8.282503404188901e-05"
 },
 {
   "tweet_id": "1589977188807610368",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977182402580481",
   "Positive": "0.8331324458122253",
   "Negative": "0.0011766009265556931",
   "Neutral": "0.16534633934497833",
   "Mixed": "0.0003445914771873504"
 },
 {
   "tweet_id": "1589977181857656833",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589977168758509573",
   "Positive": "0.029329614713788033",
   "Negative": "8.05563322501257e-05",
   "Neutral": "0.9705039262771606",
   "Mixed": "8.586247713537887e-05"
 },
 {
   "tweet_id": "1589977152187142145",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977147736985600",
   "Positive": "0.019783981144428253",
   "Negative": "6.23743180767633e-05",
   "Neutral": "0.9800775051116943",
   "Mixed": "7.622971315868199e-05"
 },
 {
   "tweet_id": "1589977146113806338",
   "Positive": "0.04484272748231888",
   "Negative": "6.404081068467349e-05",
   "Neutral": "0.9546368718147278",
   "Mixed": "0.0004563375550787896"
 },
 {
   "tweet_id": "1589977142011793411",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589977141705592832",
   "Positive": "0.00022665495635010302",
   "Negative": "0.00031089389813132584",
   "Neutral": "0.9994437098503113",
   "Mixed": "1.8700693544815294e-05"
 },
 {
   "tweet_id": "1589977133275025408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977127306526721",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589977122881536000",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589977121090592769",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977117626101762",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589977115101134848",
   "Positive": "0.02848694659769535",
   "Negative": "7.34814748284407e-05",
   "Neutral": "0.971345067024231",
   "Mixed": "9.449147910345346e-05"
 },
 {
   "tweet_id": "1589977099728982017",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977098248400896",
   "Positive": "0.025365358218550682",
   "Negative": "6.68903740006499e-05",
   "Neutral": "0.9744548201560974",
   "Mixed": "0.00011290770635241643"
 },
 {
   "tweet_id": "1589977087494217730",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977076731637762",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977073770119168",
   "Positive": "0.00829343218356371",
   "Negative": "0.00015933428949210793",
   "Neutral": "0.9915423393249512",
   "Mixed": "4.83471148982062e-06"
 },
 {
   "tweet_id": "1589977073434886145",
   "Positive": "0.026766546070575714",
   "Negative": "7.08344450686127e-05",
   "Neutral": "0.9730203747749329",
   "Mixed": "0.00014219467993825674"
 },
 {
   "tweet_id": "1589977060637671425",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977057848864769",
   "Positive": "0.033911846578121185",
   "Negative": "4.858497413806617e-05",
   "Neutral": "0.9658020734786987",
   "Mixed": "0.000237513508182019"
 },
 {
   "tweet_id": "1589977055386832896",
   "Positive": "0.027467453852295876",
   "Negative": "7.087778794812039e-05",
   "Neutral": "0.9723500609397888",
   "Mixed": "0.00011160769645357504"
 },
 {
   "tweet_id": "1589977053037998082",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589977052790521858",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977049070194689",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977047858020354",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589977047744774144",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589977044288700417",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589977032645312513",
   "Positive": "0.01210400927811861",
   "Negative": "4.113930481253192e-05",
   "Neutral": "0.9877451062202454",
   "Mixed": "0.00010976749763358384"
 },
 {
   "tweet_id": "1589977029092728833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589977002307878916",
   "Positive": "0.012609249912202358",
   "Negative": "5.0052363803843036e-05",
   "Neutral": "0.9872618317604065",
   "Mixed": "7.887773244874552e-05"
 },
 {
   "tweet_id": "1589976998033903617",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589976985367105537",
   "Positive": "0.028592269867658615",
   "Negative": "6.695073534501716e-05",
   "Neutral": "0.9711686372756958",
   "Mixed": "0.00017217353160958737"
 },
 {
   "tweet_id": "1589976980350373890",
   "Positive": "0.006171129178255796",
   "Negative": "0.0006831256323494017",
   "Neutral": "0.9931433200836182",
   "Mixed": "2.341041181352921e-06"
 },
 {
   "tweet_id": "1589976972117315584",
   "Positive": "0.016350682824850082",
   "Negative": "6.216888868948445e-05",
   "Neutral": "0.9834929704666138",
   "Mixed": "9.410921484231949e-05"
 },
 {
   "tweet_id": "1589976970540224512",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976965943283712",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589976965607718912",
   "Positive": "0.015417302027344704",
   "Negative": "4.7469566197833046e-05",
   "Neutral": "0.9844510555267334",
   "Mixed": "8.413680188823491e-05"
 },
 {
   "tweet_id": "1589976947974885376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976944564543488",
   "Positive": "0.02032625488936901",
   "Negative": "5.578571290243417e-05",
   "Neutral": "0.9794957041740417",
   "Mixed": "0.00012220795906614512"
 },
 {
   "tweet_id": "1589976934150459393",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976928370688003",
   "Positive": "0.13090890645980835",
   "Negative": "0.0011556053068488836",
   "Neutral": "0.8679233193397522",
   "Mixed": "1.2167144632257987e-05"
 },
 {
   "tweet_id": "1589976923132026884",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976921693380610",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976918735998977",
   "Positive": "0.02526697888970375",
   "Negative": "6.34527241345495e-05",
   "Neutral": "0.9745590686798096",
   "Mixed": "0.00011045137944165617"
 },
 {
   "tweet_id": "1589976912583352320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976909286608896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976909232099328",
   "Positive": "0.0015844444278627634",
   "Negative": "0.011868610046803951",
   "Neutral": "0.985619306564331",
   "Mixed": "0.0009276150958612561"
 },
 {
   "tweet_id": "1589976905608220673",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589976897823584257",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976897794211840",
   "Positive": "0.02110944129526615",
   "Negative": "5.797563790110871e-05",
   "Neutral": "0.9786866307258606",
   "Mixed": "0.00014599933638237417"
 },
 {
   "tweet_id": "1589976883579727873",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976877531561984",
   "Positive": "0.02582402341067791",
   "Negative": "6.934273551451042e-05",
   "Neutral": "0.9739636778831482",
   "Mixed": "0.00014287082012742758"
 },
 {
   "tweet_id": "1589976874360668160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976873093644288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976865145430022",
   "Positive": "0.2857809066772461",
   "Negative": "0.0029897599015384912",
   "Neutral": "0.7111919522285461",
   "Mixed": "3.739830935955979e-05"
 },
 {
   "tweet_id": "1589976863753261058",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589976861676752896",
   "Positive": "0.009970943443477154",
   "Negative": "0.0008024628623388708",
   "Neutral": "0.9892184138298035",
   "Mixed": "8.150690518959891e-06"
 },
 {
   "tweet_id": "1589976858543919105",
   "Positive": "0.02093173936009407",
   "Negative": "6.443825259339064e-05",
   "Neutral": "0.9789025783538818",
   "Mixed": "0.0001011881249723956"
 },
 {
   "tweet_id": "1589976854781624320",
   "Positive": "0.022928625345230103",
   "Negative": "4.9664173275232315e-05",
   "Neutral": "0.9768816828727722",
   "Mixed": "0.0001400047040078789"
 },
 {
   "tweet_id": "1589976854282526721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976846736953345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976841334706176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976837673091073",
   "Positive": "0.02478790283203125",
   "Negative": "6.039393338141963e-05",
   "Neutral": "0.9749983549118042",
   "Mixed": "0.00015325364074669778"
 },
 {
   "tweet_id": "1589976822011174913",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976820358991873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976820166062082",
   "Positive": "0.027833668515086174",
   "Negative": "6.566144293174148e-05",
   "Neutral": "0.9719963073730469",
   "Mixed": "0.00010442636266816407"
 },
 {
   "tweet_id": "1589976816705757185",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589976796740845571",
   "Positive": "0.009843467734754086",
   "Negative": "5.7260320318164304e-05",
   "Neutral": "0.9899746775627136",
   "Mixed": "0.00012459779100026935"
 },
 {
   "tweet_id": "1589976794119417856",
   "Positive": "0.036847714334726334",
   "Negative": "5.145153045305051e-05",
   "Neutral": "0.9627789258956909",
   "Mixed": "0.0003219351056031883"
 },
 {
   "tweet_id": "1589976788784283648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976768470867968",
   "Positive": "0.012326688505709171",
   "Negative": "5.6107637647073716e-05",
   "Neutral": "0.9874978065490723",
   "Mixed": "0.00011940339027205482"
 },
 {
   "tweet_id": "1589976756458774528",
   "Positive": "0.02239827811717987",
   "Negative": "8.258139860117808e-05",
   "Neutral": "0.9775010347366333",
   "Mixed": "1.8009084669756703e-05"
 },
 {
   "tweet_id": "1589976750494453761",
   "Positive": "0.26975682377815247",
   "Negative": "0.47788140177726746",
   "Neutral": "0.1295437067747116",
   "Mixed": "0.12281812727451324"
 },
 {
   "tweet_id": "1589976746857992195",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589976741250236416",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1589976737873821696",
   "Positive": "0.014200898818671703",
   "Negative": "5.384406540542841e-05",
   "Neutral": "0.9856104254722595",
   "Mixed": "0.00013487301475834101"
 },
 {
   "tweet_id": "1589976733213929472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976727773540354",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976718328025089",
   "Positive": "0.07017014920711517",
   "Negative": "0.0030548793729394674",
   "Neutral": "0.926762044429779",
   "Mixed": "1.2953732039022725e-05"
 },
 {
   "tweet_id": "1589976716839387136",
   "Positive": "0.0022779449354857206",
   "Negative": "0.061634451150894165",
   "Neutral": "0.9356105923652649",
   "Mixed": "0.00047701821313239634"
 },
 {
   "tweet_id": "1589976714918383619",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589976702197071878",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976699319746560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976694877999112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976694282407936",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589976686791372800",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976682437672960",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976671553478660",
   "Positive": "0.002869312884286046",
   "Negative": "0.05179876089096069",
   "Neutral": "0.9449893236160278",
   "Mixed": "0.00034253339981660247"
 },
 {
   "tweet_id": "1589976665681453057",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589976665358479366",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589976664758714369",
   "Positive": "0.020516812801361084",
   "Negative": "4.800966416951269e-05",
   "Neutral": "0.9792197942733765",
   "Mixed": "0.00021537585416808724"
 },
 {
   "tweet_id": "1589976654075813890",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589976648216375296",
   "Positive": "0.005490039009600878",
   "Negative": "0.010606490075588226",
   "Neutral": "0.9838786125183105",
   "Mixed": "2.480863440723624e-05"
 },
 {
   "tweet_id": "1589976626921893890",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976618592014338",
   "Positive": "0.012052565813064575",
   "Negative": "5.8205685490975156e-05",
   "Neutral": "0.9876912832260132",
   "Mixed": "0.0001979775697691366"
 },
 {
   "tweet_id": "1589976616788447232",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976610991902720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976607825227781",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589976602515247106",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1589976599130431489",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976592226594816",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976590712471553",
   "Positive": "0.023559805005788803",
   "Negative": "0.048823032528162",
   "Neutral": "0.15536245703697205",
   "Mixed": "0.7722547054290771"
 },
 {
   "tweet_id": "1589976590217543680",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589976590074937350",
   "Positive": "0.020317841321229935",
   "Negative": "5.809509093523957e-05",
   "Neutral": "0.9794366955757141",
   "Mixed": "0.00018734646437224"
 },
 {
   "tweet_id": "1589976575751356416",
   "Positive": "0.014022242277860641",
   "Negative": "0.5510441064834595",
   "Neutral": "0.4346589148044586",
   "Mixed": "0.0002747815160546452"
 },
 {
   "tweet_id": "1589976574119792640",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589976572593070081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976568864313347",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976567232724992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976561889206273",
   "Positive": "0.01998252607882023",
   "Negative": "6.073661643313244e-05",
   "Neutral": "0.9797959327697754",
   "Mixed": "0.00016080921341199428"
 },
 {
   "tweet_id": "1589976558676373505",
   "Positive": "0.019705623388290405",
   "Negative": "4.489536149776541e-05",
   "Neutral": "0.9800804853439331",
   "Mixed": "0.00016893137944862247"
 },
 {
   "tweet_id": "1589976550589747202",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589976544621260804",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976539659403265",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976539508404224",
   "Positive": "0.014201289974153042",
   "Negative": "5.240310201770626e-05",
   "Neutral": "0.9856479167938232",
   "Mixed": "9.83116842689924e-05"
 },
 {
   "tweet_id": "1589976536974753794",
   "Positive": "0.1194860190153122",
   "Negative": "0.00128331093583256",
   "Neutral": "0.8791645169258118",
   "Mixed": "6.610137643292546e-05"
 },
 {
   "tweet_id": "1589976530574540801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976516410347522",
   "Positive": "0.013772710226476192",
   "Negative": "7.69861726439558e-05",
   "Neutral": "0.9860975742340088",
   "Mixed": "5.269538451102562e-05"
 },
 {
   "tweet_id": "1589976502040686592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976496248324102",
   "Positive": "0.027096649631857872",
   "Negative": "6.9352776336018e-05",
   "Neutral": "0.9726816415786743",
   "Mixed": "0.00015236301987897605"
 },
 {
   "tweet_id": "1589976483245985795",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976481119494144",
   "Positive": "0.023005232214927673",
   "Negative": "4.5730990677839145e-05",
   "Neutral": "0.9766567349433899",
   "Mixed": "0.0002922946587204933"
 },
 {
   "tweet_id": "1589976473041260546",
   "Positive": "0.01835867576301098",
   "Negative": "5.3617633966496214e-05",
   "Neutral": "0.9813776612281799",
   "Mixed": "0.000210118610993959"
 },
 {
   "tweet_id": "1589976472395341824",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976467198578688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976456691879936",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589976453047005188",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976446780702721",
   "Positive": "0.028978366404771805",
   "Negative": "6.749328167643398e-05",
   "Neutral": "0.970782458782196",
   "Mixed": "0.00017165577446576208"
 },
 {
   "tweet_id": "1589976424974540800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976418632753153",
   "Positive": "0.10576523840427399",
   "Negative": "0.10931563377380371",
   "Neutral": "0.7845913171768188",
   "Mixed": "0.0003278346557635814"
 },
 {
   "tweet_id": "1589976416715952128",
   "Positive": "0.013843221589922905",
   "Negative": "5.29889584868215e-05",
   "Neutral": "0.9859806299209595",
   "Mixed": "0.00012319879897404462"
 },
 {
   "tweet_id": "1589976412340965376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976399347322880",
   "Positive": "0.005158668849617243",
   "Negative": "0.0003054242115467787",
   "Neutral": "0.994532585144043",
   "Mixed": "3.2697932965675136e-06"
 },
 {
   "tweet_id": "1589976396721713152",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976395354345472",
   "Positive": "0.020292071625590324",
   "Negative": "7.118520443327725e-05",
   "Neutral": "0.9795017838478088",
   "Mixed": "0.0001349689846392721"
 },
 {
   "tweet_id": "1589976385707446274",
   "Positive": "0.021037869155406952",
   "Negative": "4.808624362340197e-05",
   "Neutral": "0.9786924719810486",
   "Mixed": "0.0002215036511188373"
 },
 {
   "tweet_id": "1589976378023481347",
   "Positive": "0.022527700290083885",
   "Negative": "7.588961307192221e-05",
   "Neutral": "0.977238655090332",
   "Mixed": "0.00015774711209814996"
 },
 {
   "tweet_id": "1589976372575080449",
   "Positive": "0.002692262642085552",
   "Negative": "0.0006261593080125749",
   "Neutral": "0.9966784715652466",
   "Mixed": "3.0726712338946527e-06"
 },
 {
   "tweet_id": "1589976364144525313",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589976364077428736",
   "Positive": "0.0018216768512502313",
   "Negative": "0.00021040323190391064",
   "Neutral": "0.9979634284973145",
   "Mixed": "4.465992788027506e-06"
 },
 {
   "tweet_id": "1589976360730390530",
   "Positive": "0.027449779212474823",
   "Negative": "6.996999582042918e-05",
   "Neutral": "0.9723660945892334",
   "Mixed": "0.00011415346671128646"
 },
 {
   "tweet_id": "1589976355902717957",
   "Positive": "0.0011501582339406013",
   "Negative": "0.00023313326528295875",
   "Neutral": "0.9986141920089722",
   "Mixed": "2.5496392481727526e-06"
 },
 {
   "tweet_id": "1589976354379862018",
   "Positive": "0.005571738816797733",
   "Negative": "0.0006064233602955937",
   "Neutral": "0.9938156604766846",
   "Mixed": "6.192538421601057e-06"
 },
 {
   "tweet_id": "1589976341147193344",
   "Positive": "0.02406860701739788",
   "Negative": "6.724258128087968e-05",
   "Neutral": "0.9757182598114014",
   "Mixed": "0.00014591454237233847"
 },
 {
   "tweet_id": "1589976341058756608",
   "Positive": "0.05938128009438515",
   "Negative": "0.000507310323882848",
   "Neutral": "0.9400959610939026",
   "Mixed": "1.542896825412754e-05"
 },
 {
   "tweet_id": "1589976336059494403",
   "Positive": "0.0011438135989010334",
   "Negative": "0.001762528670951724",
   "Neutral": "0.9970908164978027",
   "Mixed": "2.8232409476913745e-06"
 },
 {
   "tweet_id": "1589976331437375488",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976328346140672",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1589976325775060993",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976324596461570",
   "Positive": "0.02355996146798134",
   "Negative": "6.927103822818026e-05",
   "Neutral": "0.9762423038482666",
   "Mixed": "0.0001285086909774691"
 },
 {
   "tweet_id": "1589976322075668485",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589976321169719301",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976313020157952",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589976311271165955",
   "Positive": "0.032235294580459595",
   "Negative": "5.050536128692329e-05",
   "Neutral": "0.967301070690155",
   "Mixed": "0.00041312756366096437"
 },
 {
   "tweet_id": "1589976307563393025",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589976306040844290",
   "Positive": "0.015409860759973526",
   "Negative": "4.892166907666251e-05",
   "Neutral": "0.9843527674674988",
   "Mixed": "0.00018847470346372575"
 },
 {
   "tweet_id": "1589976304212140032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976299132850178",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589976299132837890",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589976294066098176",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589976279688032257",
   "Positive": "0.013144802302122116",
   "Negative": "4.6760695113334805e-05",
   "Neutral": "0.9866987466812134",
   "Mixed": "0.00010959905193885788"
 },
 {
   "tweet_id": "1589976279344111618",
   "Positive": "0.078068807721138",
   "Negative": "0.0029516546055674553",
   "Neutral": "0.9167051911354065",
   "Mixed": "0.0022743598092347383"
 },
 {
   "tweet_id": "1589976271765004288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976269009346563",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589976267168051201",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589976262009040896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976252483764230",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976250415984640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976236679647232",
   "Positive": "0.023251792415976524",
   "Negative": "6.574340659426525e-05",
   "Neutral": "0.9765816926956177",
   "Mixed": "0.00010081794607685879"
 },
 {
   "tweet_id": "1589976234905436160",
   "Positive": "0.024157844483852386",
   "Negative": "4.685739986598492e-05",
   "Neutral": "0.9755468368530273",
   "Mixed": "0.00024851446505635977"
 },
 {
   "tweet_id": "1589976232577314816",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1589976227288612864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976224985939968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976219294265346",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976210620448768",
   "Positive": "0.10078005492687225",
   "Negative": "0.0033961115404963493",
   "Neutral": "0.8957163691520691",
   "Mixed": "0.00010740049765445292"
 },
 {
   "tweet_id": "1589976208993054721",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976199132217345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976197362233344",
   "Positive": "0.015564179047942162",
   "Negative": "4.8993777454597875e-05",
   "Neutral": "0.9842104911804199",
   "Mixed": "0.0001762949541443959"
 },
 {
   "tweet_id": "1589976176164212737",
   "Positive": "0.04137170687317848",
   "Negative": "0.003721837420016527",
   "Neutral": "0.9548588991165161",
   "Mixed": "4.753157190862112e-05"
 },
 {
   "tweet_id": "1589976174918537217",
   "Positive": "0.01824222318828106",
   "Negative": "5.0161492254119366e-05",
   "Neutral": "0.9816200733184814",
   "Mixed": "8.756357419770211e-05"
 },
 {
   "tweet_id": "1589976168165699586",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589976166684798976",
   "Positive": "0.004878118168562651",
   "Negative": "0.0005376912304200232",
   "Neutral": "0.9945815205574036",
   "Mixed": "2.61173795479408e-06"
 },
 {
   "tweet_id": "1589976161308004352",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589976160532074496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976155750535169",
   "Positive": "0.04592902585864067",
   "Negative": "5.6680437410250306e-05",
   "Neutral": "0.9536576271057129",
   "Mixed": "0.0003566673840396106"
 },
 {
   "tweet_id": "1589976155586965508",
   "Positive": "0.019511813297867775",
   "Negative": "5.070761835668236e-05",
   "Neutral": "0.9803295135498047",
   "Mixed": "0.00010793582623591647"
 },
 {
   "tweet_id": "1589976150591565825",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589976149182283776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976147361943552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976140768481281",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976136611926016",
   "Positive": "0.7234653234481812",
   "Negative": "0.0006366824964061379",
   "Neutral": "0.27574488520622253",
   "Mixed": "0.00015317030192818493"
 },
 {
   "tweet_id": "1589976127946518528",
   "Positive": "0.019257809966802597",
   "Negative": "5.235422941041179e-05",
   "Neutral": "0.9806061387062073",
   "Mixed": "8.3722552517429e-05"
 },
 {
   "tweet_id": "1589976121374015488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976112796700672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589976107306356738",
   "Positive": "0.016443360596895218",
   "Negative": "4.919761704513803e-05",
   "Neutral": "0.9834228754043579",
   "Mixed": "8.446838182862848e-05"
 },
 {
   "tweet_id": "1589976105003683841",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589976096757678081",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589976092114575371",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589976080878047232",
   "Positive": "0.01660134643316269",
   "Negative": "4.890015770797618e-05",
   "Neutral": "0.9832483530044556",
   "Mixed": "0.00010134185140486807"
 },
 {
   "tweet_id": "1589976076822122501",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976075815522304",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589976056559435776",
   "Positive": "0.015381351113319397",
   "Negative": "4.919659477309324e-05",
   "Neutral": "0.9844881296157837",
   "Mixed": "8.135371172102168e-05"
 },
 {
   "tweet_id": "1589976054013526016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976051996065793",
   "Positive": "0.025728750973939896",
   "Negative": "6.31808943580836e-05",
   "Neutral": "0.9740176200866699",
   "Mixed": "0.0001903688389575109"
 },
 {
   "tweet_id": "1589976038326820865",
   "Positive": "0.023371338844299316",
   "Negative": "6.422642036341131e-05",
   "Neutral": "0.9764432311058044",
   "Mixed": "0.00012114587298128754"
 },
 {
   "tweet_id": "1589976032408645632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976031318126600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976029598486530",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589976024926027777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976017010974722",
   "Positive": "0.015381351113319397",
   "Negative": "4.919659477309324e-05",
   "Neutral": "0.9844881296157837",
   "Mixed": "8.135371172102168e-05"
 },
 {
   "tweet_id": "1589976016814211074",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589976012959678464",
   "Positive": "0.025086041539907455",
   "Negative": "0.0003924657648894936",
   "Neutral": "0.9745039343833923",
   "Mixed": "1.754505865392275e-05"
 },
 {
   "tweet_id": "1589976012929933312",
   "Positive": "0.013529293239116669",
   "Negative": "0.00014424412802327424",
   "Neutral": "0.9862841367721558",
   "Mixed": "4.234489460941404e-05"
 },
 {
   "tweet_id": "1589976004940136448",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975999348805633",
   "Positive": "0.017685648053884506",
   "Negative": "5.08415796502959e-05",
   "Neutral": "0.9821659922599792",
   "Mixed": "9.748562297318131e-05"
 },
 {
   "tweet_id": "1589975993078665216",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975990146826241",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589975979585572864",
   "Positive": "0.017631109803915024",
   "Negative": "5.0813487177947536e-05",
   "Neutral": "0.9822198152542114",
   "Mixed": "9.828774636844173e-05"
 },
 {
   "tweet_id": "1589975967921246208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975957347393536",
   "Positive": "0.015557236969470978",
   "Negative": "4.773144974024035e-05",
   "Neutral": "0.9842885732650757",
   "Mixed": "0.00010654232028173283"
 },
 {
   "tweet_id": "1589975956680495104",
   "Positive": "0.024376729503273964",
   "Negative": "4.523209645412862e-05",
   "Neutral": "0.9753163456916809",
   "Mixed": "0.0002616738493088633"
 },
 {
   "tweet_id": "1589975949961224192",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1589975948820377602",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975935469916160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975930738724864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975928767393795",
   "Positive": "0.0001477257173974067",
   "Negative": "0.9607317447662354",
   "Neutral": "0.03911363705992699",
   "Mixed": "6.860590019641677e-06"
 },
 {
   "tweet_id": "1589975920571547648",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589975919040811009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975918306820097",
   "Positive": "0.013212859630584717",
   "Negative": "0.00017504786956124008",
   "Neutral": "0.9866004586219788",
   "Mixed": "1.158837039838545e-05"
 },
 {
   "tweet_id": "1589975917803188225",
   "Positive": "0.02214127592742443",
   "Negative": "5.7807650591712445e-05",
   "Neutral": "0.9776598811149597",
   "Mixed": "0.00014104451111052185"
 },
 {
   "tweet_id": "1589975904604000257",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975894969700353",
   "Positive": "0.0033284679520875216",
   "Negative": "0.5116787552833557",
   "Neutral": "0.4848180115222931",
   "Mixed": "0.00017479187226854265"
 },
 {
   "tweet_id": "1589975892327297027",
   "Positive": "0.023132670670747757",
   "Negative": "6.142550410004333e-05",
   "Neutral": "0.9766550064086914",
   "Mixed": "0.00015092013927642256"
 },
 {
   "tweet_id": "1589975891832360965",
   "Positive": "0.0015958377625793219",
   "Negative": "0.11048362404108047",
   "Neutral": "0.887794554233551",
   "Mixed": "0.0001260631688637659"
 },
 {
   "tweet_id": "1589975878410600455",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975876657348608",
   "Positive": "0.04106181859970093",
   "Negative": "5.553145456360653e-05",
   "Neutral": "0.9585162997245789",
   "Mixed": "0.0003663870447780937"
 },
 {
   "tweet_id": "1589975872638832640",
   "Positive": "0.02384178899228573",
   "Negative": "6.425838364521042e-05",
   "Neutral": "0.975923478603363",
   "Mixed": "0.0001704251189948991"
 },
 {
   "tweet_id": "1589975859632701441",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975858848366592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975856759574529",
   "Positive": "0.002869312884286046",
   "Negative": "0.05179876089096069",
   "Neutral": "0.9449893236160278",
   "Mixed": "0.00034253339981660247"
 },
 {
   "tweet_id": "1589975854092021760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975852942757890",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975852938571777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975843597869056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975842561531905",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975829404356614",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589975824438296577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975822752153602",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975813424021504",
   "Positive": "0.03679550811648369",
   "Negative": "5.185723057365976e-05",
   "Neutral": "0.9626959562301636",
   "Mixed": "0.0004566446878015995"
 },
 {
   "tweet_id": "1589975804792164353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975796827189252",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975794323013634",
   "Positive": "0.014022242277860641",
   "Negative": "0.5510441064834595",
   "Neutral": "0.4346589148044586",
   "Mixed": "0.0002747815160546452"
 },
 {
   "tweet_id": "1589975772269543426",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975759132950529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975759028125698",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975745946083328",
   "Positive": "0.08607909828424454",
   "Negative": "0.001338158966973424",
   "Neutral": "0.9125652313232422",
   "Mixed": "1.7469459635321982e-05"
 },
 {
   "tweet_id": "1589975742401900544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975732465590273",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975730750124032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975722378268674",
   "Positive": "0.4214911162853241",
   "Negative": "0.006193852052092552",
   "Neutral": "0.4994373619556427",
   "Mixed": "0.07287760078907013"
 },
 {
   "tweet_id": "1589975708780359681",
   "Positive": "0.023457350209355354",
   "Negative": "4.696289397543296e-05",
   "Neutral": "0.9762142300605774",
   "Mixed": "0.00028139978530816734"
 },
 {
   "tweet_id": "1589975689607815173",
   "Positive": "0.006651486270129681",
   "Negative": "0.000620936683844775",
   "Neutral": "0.9927160143852234",
   "Mixed": "1.1558058758964762e-05"
 },
 {
   "tweet_id": "1589975681131479040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975680695300099",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975674248658944",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975666208157696",
   "Positive": "0.9933918118476868",
   "Negative": "0.001644845586270094",
   "Neutral": "0.004888304974883795",
   "Mixed": "7.499386265408248e-05"
 },
 {
   "tweet_id": "1589975662798209026",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975661653131271",
   "Positive": "0.014696134254336357",
   "Negative": "0.2967365086078644",
   "Neutral": "0.6839706897735596",
   "Mixed": "0.00459668505936861"
 },
 {
   "tweet_id": "1589975649116389378",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975639662395394",
   "Positive": "0.030268538743257523",
   "Negative": "5.183000030228868e-05",
   "Neutral": "0.9694041609764099",
   "Mixed": "0.0002755040768533945"
 },
 {
   "tweet_id": "1589975636281790464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975635929493505",
   "Positive": "0.9934031367301941",
   "Negative": "0.0016564946854487062",
   "Neutral": "0.00486763147637248",
   "Mixed": "7.272301445482299e-05"
 },
 {
   "tweet_id": "1589975623577243652",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975622725808133",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975614815367168",
   "Positive": "0.9933903217315674",
   "Negative": "0.0015758826630190015",
   "Neutral": "0.00496310880407691",
   "Mixed": "7.06909122527577e-05"
 },
 {
   "tweet_id": "1589975591650222081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975589062324226",
   "Positive": "0.9931938052177429",
   "Negative": "0.0016990837175399065",
   "Neutral": "0.005030158441513777",
   "Mixed": "7.696604734519497e-05"
 },
 {
   "tweet_id": "1589975578555592705",
   "Positive": "0.019719356670975685",
   "Negative": "0.8278070092201233",
   "Neutral": "0.09397390484809875",
   "Mixed": "0.05849970504641533"
 },
 {
   "tweet_id": "1589975577507028993",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975569919508485",
   "Positive": "0.9934579730033875",
   "Negative": "0.0017159553244709969",
   "Neutral": "0.004748190753161907",
   "Mixed": "7.792863470967859e-05"
 },
 {
   "tweet_id": "1589975563024105476",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975554304139267",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975550751551489",
   "Positive": "0.9934272766113281",
   "Negative": "0.0017268355004489422",
   "Neutral": "0.004769717343151569",
   "Mixed": "7.609072781633586e-05"
 },
 {
   "tweet_id": "1589975546876002309",
   "Positive": "0.004727122373878956",
   "Negative": "5.6203971325885504e-05",
   "Neutral": "0.9951980710029602",
   "Mixed": "1.862707722466439e-05"
 },
 {
   "tweet_id": "1589975539628277760",
   "Positive": "0.024172093719244003",
   "Negative": "0.753678023815155",
   "Neutral": "0.22125975787639618",
   "Mixed": "0.0008901600958779454"
 },
 {
   "tweet_id": "1589975538785218560",
   "Positive": "0.02595546469092369",
   "Negative": "5.0210928748128936e-05",
   "Neutral": "0.9736939668655396",
   "Mixed": "0.0003003831079695374"
 },
 {
   "tweet_id": "1589975531118034945",
   "Positive": "0.9932430386543274",
   "Negative": "0.0017430252628400922",
   "Neutral": "0.004937721416354179",
   "Mixed": "7.624483259860426e-05"
 },
 {
   "tweet_id": "1589975510222000128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975509274071040",
   "Positive": "0.9937979578971863",
   "Negative": "0.0015954019036144018",
   "Neutral": "0.00453583849593997",
   "Mixed": "7.078266935423017e-05"
 },
 {
   "tweet_id": "1589975499912384512",
   "Positive": "0.7244318127632141",
   "Negative": "0.0010364024201408029",
   "Neutral": "0.2742893695831299",
   "Mixed": "0.00024247159308288246"
 },
 {
   "tweet_id": "1589975490408091648",
   "Positive": "0.9938090443611145",
   "Negative": "0.0016438346356153488",
   "Neutral": "0.004473594948649406",
   "Mixed": "7.360294694080949e-05"
 },
 {
   "tweet_id": "1589975476675948544",
   "Positive": "0.30575716495513916",
   "Negative": "0.001746702822856605",
   "Neutral": "0.6908980011940002",
   "Mixed": "0.0015981245087459683"
 },
 {
   "tweet_id": "1589975470443225089",
   "Positive": "0.9941091537475586",
   "Negative": "0.001544816535897553",
   "Neutral": "0.004275760613381863",
   "Mixed": "7.03057085047476e-05"
 },
 {
   "tweet_id": "1589975469063274497",
   "Positive": "0.0681043490767479",
   "Negative": "0.027183203026652336",
   "Neutral": "0.9045166969299316",
   "Mixed": "0.00019580477965064347"
 },
 {
   "tweet_id": "1589975468568371203",
   "Positive": "0.03062492609024048",
   "Negative": "6.37083503534086e-05",
   "Neutral": "0.9690714478492737",
   "Mixed": "0.00023990900081116706"
 },
 {
   "tweet_id": "1589975445138989058",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975444723740674",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589975441120501760",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589975438226755584",
   "Positive": "0.022318029776215553",
   "Negative": "0.0006238757050596178",
   "Neutral": "0.977048933506012",
   "Mixed": "9.22003346204292e-06"
 },
 {
   "tweet_id": "1589975438063185920",
   "Positive": "0.9938203692436218",
   "Negative": "0.0015347545268014073",
   "Neutral": "0.0045731146819889545",
   "Mixed": "7.174615166150033e-05"
 },
 {
   "tweet_id": "1589975430718984192",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589975430203084801",
   "Positive": "0.04033222422003746",
   "Negative": "0.4271107614040375",
   "Neutral": "0.5314096808433533",
   "Mixed": "0.0011473277118057013"
 },
 {
   "tweet_id": "1589975430064656385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975419050029056",
   "Positive": "0.5562394857406616",
   "Negative": "0.0037105553783476353",
   "Neutral": "0.43995168805122375",
   "Mixed": "9.821810090215877e-05"
 },
 {
   "tweet_id": "1589975415887892480",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589975410057482240",
   "Positive": "0.16930225491523743",
   "Negative": "0.02431338094174862",
   "Neutral": "0.8062912225723267",
   "Mixed": "9.311897156294435e-05"
 },
 {
   "tweet_id": "1589975407939694597",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589975406085804032",
   "Positive": "0.9936923980712891",
   "Negative": "0.0016250039916485548",
   "Neutral": "0.004611619748175144",
   "Mixed": "7.104165706550702e-05"
 },
 {
   "tweet_id": "1589975395386150914",
   "Positive": "0.06653246283531189",
   "Negative": "0.2430037260055542",
   "Neutral": "0.6903179287910461",
   "Mixed": "0.00014586723409593105"
 },
 {
   "tweet_id": "1589975387404406785",
   "Positive": "0.9933643341064453",
   "Negative": "0.001699501764960587",
   "Neutral": "0.004865413065999746",
   "Mixed": "7.07079452695325e-05"
 },
 {
   "tweet_id": "1589975386913648640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975381502988294",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975378596343810",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975368815247360",
   "Positive": "0.9941614270210266",
   "Negative": "0.0015073856338858604",
   "Neutral": "0.0042641740292310715",
   "Mixed": "6.697540084132925e-05"
 },
 {
   "tweet_id": "1589975359415783426",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589975350108647424",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589975349194260483",
   "Positive": "0.9935865998268127",
   "Negative": "0.0016643760027363896",
   "Neutral": "0.004679450765252113",
   "Mixed": "6.964177009649575e-05"
 },
 {
   "tweet_id": "1589975330600923136",
   "Positive": "0.9938907623291016",
   "Negative": "0.0015066576888784766",
   "Neutral": "0.004534841515123844",
   "Mixed": "6.780563853681087e-05"
 },
 {
   "tweet_id": "1589975311558782978",
   "Positive": "0.9936339855194092",
   "Negative": "0.0016910149715840816",
   "Neutral": "0.004601190332323313",
   "Mixed": "7.380005263257772e-05"
 },
 {
   "tweet_id": "1589975305246371841",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975299835703296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975298711265280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975294244372480",
   "Positive": "0.003660472808405757",
   "Negative": "0.00021119308075867593",
   "Neutral": "0.9961244463920593",
   "Mixed": "3.950617610826157e-06"
 },
 {
   "tweet_id": "1589975283008147457",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975282722951168",
   "Positive": "0.9940720200538635",
   "Negative": "0.0014732356648892164",
   "Neutral": "0.004390866030007601",
   "Mixed": "6.39343197690323e-05"
 },
 {
   "tweet_id": "1589975279002619907",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975270685315076",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975260061138949",
   "Positive": "0.9941099286079407",
   "Negative": "0.0014928508317098022",
   "Neutral": "0.004327370785176754",
   "Mixed": "6.98325238772668e-05"
 },
 {
   "tweet_id": "1589975257305460737",
   "Positive": "0.025780009105801582",
   "Negative": "6.462860619649291e-05",
   "Neutral": "0.9739680886268616",
   "Mixed": "0.00018732661555986851"
 },
 {
   "tweet_id": "1589975255971663873",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589975245896970246",
   "Positive": "0.8257790803909302",
   "Negative": "0.007753101177513599",
   "Neutral": "0.16610562801361084",
   "Mixed": "0.00036225185613147914"
 },
 {
   "tweet_id": "1589975244525080576",
   "Positive": "0.8263427019119263",
   "Negative": "0.0017656446434557438",
   "Neutral": "0.17158234119415283",
   "Mixed": "0.0003093107370659709"
 },
 {
   "tweet_id": "1589975242885431298",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975240486301697",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975234085801984",
   "Positive": "0.9941294193267822",
   "Negative": "0.0014304749201983213",
   "Neutral": "0.004375391639769077",
   "Mixed": "6.470687367254868e-05"
 },
 {
   "tweet_id": "1589975233641205762",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589975224849952768",
   "Positive": "0.05938128009438515",
   "Negative": "0.000507310323882848",
   "Neutral": "0.9400959610939026",
   "Mixed": "1.542896825412754e-05"
 },
 {
   "tweet_id": "1589975223717466112",
   "Positive": "0.0823136642575264",
   "Negative": "0.0003010579093825072",
   "Neutral": "0.9173643589019775",
   "Mixed": "2.0836247131228447e-05"
 },
 {
   "tweet_id": "1589975222807298048",
   "Positive": "0.043199360370635986",
   "Negative": "0.16324514150619507",
   "Neutral": "0.7934718132019043",
   "Mixed": "8.371002331841737e-05"
 },
 {
   "tweet_id": "1589975216775495681",
   "Positive": "0.005571738816797733",
   "Negative": "0.0006064233602955937",
   "Neutral": "0.9938156604766846",
   "Mixed": "6.192538421601057e-06"
 },
 {
   "tweet_id": "1589975215295336450",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975205925228544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975204583047178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975198719430656",
   "Positive": "0.0018216768512502313",
   "Negative": "0.00021040323190391064",
   "Neutral": "0.9979634284973145",
   "Mixed": "4.465992788027506e-06"
 },
 {
   "tweet_id": "1589975172005892096",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975170995073026",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975167245357057",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975160589021184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975159116795905",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589975154230464512",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589975149159546880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975123297456128",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975121007349760",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589975111758909440",
   "Positive": "0.012679223902523518",
   "Negative": "4.178094968665391e-05",
   "Neutral": "0.9870955944061279",
   "Mixed": "0.00018337636720389128"
 },
 {
   "tweet_id": "1589975097846435841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975094692282369",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1589975093685653504",
   "Positive": "0.0053258733823895454",
   "Negative": "0.0003364046278875321",
   "Neutral": "0.9943315982818604",
   "Mixed": "6.135208877822151e-06"
 },
 {
   "tweet_id": "1589975082751111170",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975077726015488",
   "Positive": "0.03549369052052498",
   "Negative": "0.13828767836093903",
   "Neutral": "0.8260648846626282",
   "Mixed": "0.00015375086513813585"
 },
 {
   "tweet_id": "1589975067571937282",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589975066376163329",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1589975064765956097",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975060231905280",
   "Positive": "0.43367135524749756",
   "Negative": "0.0010341277811676264",
   "Neutral": "0.5652573704719543",
   "Mixed": "3.710952660185285e-05"
 },
 {
   "tweet_id": "1589975048215203840",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589975034810204160",
   "Positive": "0.05257847532629967",
   "Negative": "5.3180669056018814e-05",
   "Neutral": "0.9469312429428101",
   "Mixed": "0.0004371117684058845"
 },
 {
   "tweet_id": "1589975031513198593",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975029638639619",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589975018876047360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975016481116165",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589975009724076034",
   "Positive": "0.053459350019693375",
   "Negative": "0.009038180112838745",
   "Neutral": "0.9374369978904724",
   "Mixed": "6.55163312330842e-05"
 },
 {
   "tweet_id": "1589975005362028549",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974998990848004",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974995765428227",
   "Positive": "0.15288347005844116",
   "Negative": "0.004704170860350132",
   "Neutral": "0.8417870402336121",
   "Mixed": "0.0006253314786590636"
 },
 {
   "tweet_id": "1589974956573876226",
   "Positive": "0.2503187656402588",
   "Negative": "0.07202238589525223",
   "Neutral": "0.6766805052757263",
   "Mixed": "0.0009783543646335602"
 },
 {
   "tweet_id": "1589974954631925760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974952010096641",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974951200968704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974945870016512",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974942497771520",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974933714612225",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974918837719040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974903658541056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974890513592320",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974885979533317",
   "Positive": "0.20448037981987",
   "Negative": "0.00059245724696666",
   "Neutral": "0.7942526340484619",
   "Mixed": "0.000674476264975965"
 },
 {
   "tweet_id": "1589974883131617282",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974881747472384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974880770199555",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589974867004502016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974862851805212",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1589974861317025793",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974853385191425",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589974839582150657",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974837040414720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974833546526720",
   "Positive": "0.05863059684634209",
   "Negative": "5.509225229616277e-05",
   "Neutral": "0.9407658576965332",
   "Mixed": "0.0005483946297317743"
 },
 {
   "tweet_id": "1589974826579435524",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589974825027923969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974819202011138",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974815687204864",
   "Positive": "0.0029036609921604395",
   "Negative": "0.4782761037349701",
   "Neutral": "0.5187472105026245",
   "Mixed": "7.3062619776465e-05"
 },
 {
   "tweet_id": "1589974810977009667",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589974809265737731",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974799215796224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974793628983299",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974792341684224",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974781851758593",
   "Positive": "0.05823669582605362",
   "Negative": "0.2207435667514801",
   "Neutral": "0.5325323939323425",
   "Mixed": "0.18848739564418793"
 },
 {
   "tweet_id": "1589974778097848321",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974756056784908",
   "Positive": "0.0018446218455210328",
   "Negative": "0.0003915890702046454",
   "Neutral": "0.9977611303329468",
   "Mixed": "2.633828898979118e-06"
 },
 {
   "tweet_id": "1589974755289227264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974752692957184",
   "Positive": "0.003925992175936699",
   "Negative": "0.19393184781074524",
   "Neutral": "0.8004099130630493",
   "Mixed": "0.0017322858329862356"
 },
 {
   "tweet_id": "1589974747403939840",
   "Positive": "0.0239772517234087",
   "Negative": "5.213842814555392e-05",
   "Neutral": "0.9756919145584106",
   "Mixed": "0.0002786650729831308"
 },
 {
   "tweet_id": "1589974743913943040",
   "Positive": "0.18306005001068115",
   "Negative": "0.00016053588478825986",
   "Neutral": "0.8161293268203735",
   "Mixed": "0.000650074565783143"
 },
 {
   "tweet_id": "1589974741846495233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974741657739265",
   "Positive": "0.17904113233089447",
   "Negative": "0.0002789457212202251",
   "Neutral": "0.8202491998672485",
   "Mixed": "0.00043075415305793285"
 },
 {
   "tweet_id": "1589974738507812866",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974736360312833",
   "Positive": "0.011634771712124348",
   "Negative": "0.5600555539131165",
   "Neutral": "0.36851823329925537",
   "Mixed": "0.05979152023792267"
 },
 {
   "tweet_id": "1589974735470796801",
   "Positive": "0.01776321977376938",
   "Negative": "0.605347216129303",
   "Neutral": "0.3478103578090668",
   "Mixed": "0.029079221189022064"
 },
 {
   "tweet_id": "1589974730479894528",
   "Positive": "0.8772279620170593",
   "Negative": "0.005198017694056034",
   "Neutral": "0.10669606178998947",
   "Mixed": "0.01087801344692707"
 },
 {
   "tweet_id": "1589974724687581184",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974722481369088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974720145129475",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974706727550976",
   "Positive": "0.13249868154525757",
   "Negative": "0.00025988544803112745",
   "Neutral": "0.8669208884239197",
   "Mixed": "0.0003205468237865716"
 },
 {
   "tweet_id": "1589974703133044736",
   "Positive": "0.3735322058200836",
   "Negative": "0.0008283858769573271",
   "Neutral": "0.6255230903625488",
   "Mixed": "0.00011632491805357859"
 },
 {
   "tweet_id": "1589974702336118784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974692517285888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974682656460801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974681263935488",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589974680332795909",
   "Positive": "0.0053258733823895454",
   "Negative": "0.0003364046278875321",
   "Neutral": "0.9943315982818604",
   "Mixed": "6.135208877822151e-06"
 },
 {
   "tweet_id": "1589974669268246531",
   "Positive": "0.036847714334726334",
   "Negative": "5.145153045305051e-05",
   "Neutral": "0.9627789258956909",
   "Mixed": "0.0003219351056031883"
 },
 {
   "tweet_id": "1589974668575809536",
   "Positive": "0.9723837971687317",
   "Negative": "0.00020625136676244438",
   "Neutral": "0.027385199442505836",
   "Mixed": "2.4802604457363486e-05"
 },
 {
   "tweet_id": "1589974666130903040",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589974655389302784",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974652512014336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974648397377537",
   "Positive": "0.01953975111246109",
   "Negative": "0.026650819927453995",
   "Neutral": "0.9532989263534546",
   "Mixed": "0.000510428799316287"
 },
 {
   "tweet_id": "1589974637458640896",
   "Positive": "0.011156684719026089",
   "Negative": "0.001045810990035534",
   "Neutral": "0.9877947568893433",
   "Mixed": "2.711865363380639e-06"
 },
 {
   "tweet_id": "1589974632144470018",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974611613007872",
   "Positive": "0.003913411870598793",
   "Negative": "0.00013142998795956373",
   "Neutral": "0.9959496259689331",
   "Mixed": "5.460046850203071e-06"
 },
 {
   "tweet_id": "1589974605753896960",
   "Positive": "0.3017731010913849",
   "Negative": "0.00995414424687624",
   "Neutral": "0.6882361769676208",
   "Mixed": "3.661505616037175e-05"
 },
 {
   "tweet_id": "1589974600230006789",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974599613452289",
   "Positive": "0.022954171523451805",
   "Negative": "5.744463123846799e-05",
   "Neutral": "0.9768000245094299",
   "Mixed": "0.00018835226364899427"
 },
 {
   "tweet_id": "1589974584153243649",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974569921630208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974567438913537",
   "Positive": "0.2391236275434494",
   "Negative": "0.0026713369879871607",
   "Neutral": "0.7580667734146118",
   "Mixed": "0.00013827643124386668"
 },
 {
   "tweet_id": "1589974554528845824",
   "Positive": "0.0010600740788504481",
   "Negative": "0.8662967681884766",
   "Neutral": "0.13256442546844482",
   "Mixed": "7.871384150348604e-05"
 },
 {
   "tweet_id": "1589974547461451777",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589974535788728321",
   "Positive": "0.9830046892166138",
   "Negative": "0.000451512896688655",
   "Neutral": "0.01649424061179161",
   "Mixed": "4.961928789271042e-05"
 },
 {
   "tweet_id": "1589974528004087813",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589974516255821824",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974494789406732",
   "Positive": "0.038538750261068344",
   "Negative": "5.1892227929783985e-05",
   "Neutral": "0.9610094428062439",
   "Mixed": "0.0003999305481556803"
 },
 {
   "tweet_id": "1589974491450724355",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974486396600321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974480923025408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974450107469824",
   "Positive": "0.00798672903329134",
   "Negative": "0.6241022348403931",
   "Neutral": "0.3676561117172241",
   "Mixed": "0.00025497254682704806"
 },
 {
   "tweet_id": "1589974446671941633",
   "Positive": "0.5510576367378235",
   "Negative": "0.0009686361299827695",
   "Neutral": "0.44633370637893677",
   "Mixed": "0.001640038681216538"
 },
 {
   "tweet_id": "1589974432235552768",
   "Positive": "0.10116608440876007",
   "Negative": "0.004821894224733114",
   "Neutral": "0.8921445608139038",
   "Mixed": "0.00186744867824018"
 },
 {
   "tweet_id": "1589974409443700739",
   "Positive": "0.04071804881095886",
   "Negative": "0.0009068323415704072",
   "Neutral": "0.9583061933517456",
   "Mixed": "6.887664494570345e-05"
 },
 {
   "tweet_id": "1589974409192038400",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974404347617281",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974388614770690",
   "Positive": "0.0015958377625793219",
   "Negative": "0.11048362404108047",
   "Neutral": "0.887794554233551",
   "Mixed": "0.0001260631688637659"
 },
 {
   "tweet_id": "1589974387251613696",
   "Positive": "0.03472612053155899",
   "Negative": "5.304812657414004e-05",
   "Neutral": "0.9648174047470093",
   "Mixed": "0.0004034086305182427"
 },
 {
   "tweet_id": "1589974377768288256",
   "Positive": "0.009926908649504185",
   "Negative": "0.20280946791172028",
   "Neutral": "0.787245512008667",
   "Mixed": "1.812411028367933e-05"
 },
 {
   "tweet_id": "1589974369757196289",
   "Positive": "0.7961921691894531",
   "Negative": "0.01197118405252695",
   "Neutral": "0.19181963801383972",
   "Mixed": "1.7072759874281473e-05"
 },
 {
   "tweet_id": "1589974366544343041",
   "Positive": "0.011284681968390942",
   "Negative": "0.005083788186311722",
   "Neutral": "0.9836275577545166",
   "Mixed": "3.944202489947202e-06"
 },
 {
   "tweet_id": "1589974362190671873",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974354720591873",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589974353525243905",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589974342661967874",
   "Positive": "0.03485918045043945",
   "Negative": "0.003024592064321041",
   "Neutral": "0.9620883464813232",
   "Mixed": "2.791278348013293e-05"
 },
 {
   "tweet_id": "1589974341294645249",
   "Positive": "0.45708590745925903",
   "Negative": "0.0004898774204775691",
   "Neutral": "0.5423845648765564",
   "Mixed": "3.961749098380096e-05"
 },
 {
   "tweet_id": "1589974335292604418",
   "Positive": "0.003843761747702956",
   "Negative": "0.13855358958244324",
   "Neutral": "0.8572577238082886",
   "Mixed": "0.00034491618862375617"
 },
 {
   "tweet_id": "1589974334663438337",
   "Positive": "0.7234653234481812",
   "Negative": "0.0006366824964061379",
   "Neutral": "0.27574488520622253",
   "Mixed": "0.00015317030192818493"
 },
 {
   "tweet_id": "1589974332562112512",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974330582388737",
   "Positive": "0.11108232289552689",
   "Negative": "0.06064905971288681",
   "Neutral": "0.6727874279022217",
   "Mixed": "0.15548120439052582"
 },
 {
   "tweet_id": "1589974322214756354",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589974314425929729",
   "Positive": "0.0010600740788504481",
   "Negative": "0.8662967681884766",
   "Neutral": "0.13256442546844482",
   "Mixed": "7.871384150348604e-05"
 },
 {
   "tweet_id": "1589974282725371907",
   "Positive": "0.004574955906718969",
   "Negative": "0.08205011487007141",
   "Neutral": "0.9131641387939453",
   "Mixed": "0.00021071777155157179"
 },
 {
   "tweet_id": "1589974278392664066",
   "Positive": "0.03079746849834919",
   "Negative": "0.013384372927248478",
   "Neutral": "0.9557802677154541",
   "Mixed": "3.798981924774125e-05"
 },
 {
   "tweet_id": "1589974266715705346",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974258176102401",
   "Positive": "0.0010600740788504481",
   "Negative": "0.8662967681884766",
   "Neutral": "0.13256442546844482",
   "Mixed": "7.871384150348604e-05"
 },
 {
   "tweet_id": "1589974257584734208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974253696212992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974250844487681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974243579940864",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974233878495232",
   "Positive": "0.042271118611097336",
   "Negative": "5.203331966185942e-05",
   "Neutral": "0.9571452736854553",
   "Mixed": "0.0005316499737091362"
 },
 {
   "tweet_id": "1589974220934901760",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974215725547523",
   "Positive": "0.42415860295295715",
   "Negative": "0.00023618782870471478",
   "Neutral": "0.5755597949028015",
   "Mixed": "4.5451215555658564e-05"
 },
 {
   "tweet_id": "1589974211585794048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974204728107008",
   "Positive": "0.0010600740788504481",
   "Negative": "0.8662967681884766",
   "Neutral": "0.13256442546844482",
   "Mixed": "7.871384150348604e-05"
 },
 {
   "tweet_id": "1589974193273442306",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974182645100545",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974181755891718",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974174671699969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974171903488002",
   "Positive": "0.044772811233997345",
   "Negative": "0.007479761727154255",
   "Neutral": "0.947234570980072",
   "Mixed": "0.000512856466230005"
 },
 {
   "tweet_id": "1589974166488649730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974155520544768",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974153461125120",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589974148478291969",
   "Positive": "0.0402047298848629",
   "Negative": "6.742057303199545e-05",
   "Neutral": "0.9593515992164612",
   "Mixed": "0.00037625557160936296"
 },
 {
   "tweet_id": "1589974148004327424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974142933438464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974135874387969",
   "Positive": "0.001089691068045795",
   "Negative": "0.9648155570030212",
   "Neutral": "0.03243891894817352",
   "Mixed": "0.001655833562836051"
 },
 {
   "tweet_id": "1589974128635023361",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974128362418183",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589974127380951040",
   "Positive": "0.001445916946977377",
   "Negative": "0.7763909697532654",
   "Neutral": "0.22209829092025757",
   "Mixed": "6.47262713755481e-05"
 },
 {
   "tweet_id": "1589974121060139008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974111945887744",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589974105927069699",
   "Positive": "0.06392063200473785",
   "Negative": "0.00047806117800064385",
   "Neutral": "0.9355812072753906",
   "Mixed": "2.0095862055313773e-05"
 },
 {
   "tweet_id": "1589974103037194240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974102898806784",
   "Positive": "0.190678209066391",
   "Negative": "0.012318317778408527",
   "Neutral": "0.7964556813240051",
   "Mixed": "0.0005478317616507411"
 },
 {
   "tweet_id": "1589974098503151616",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589974095411937284",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589974094711508993",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974091112775680",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974085576294400",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589974081998585857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589974066932645888",
   "Positive": "0.04173734784126282",
   "Negative": "6.622361252084374e-05",
   "Neutral": "0.9578200578689575",
   "Mixed": "0.00037629762664437294"
 },
 {
   "tweet_id": "1589974059357712385",
   "Positive": "0.006486847531050444",
   "Negative": "0.8041561841964722",
   "Neutral": "0.189120814204216",
   "Mixed": "0.0002362308878218755"
 },
 {
   "tweet_id": "1589974058434965507",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974054018351104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974046795395072",
   "Positive": "0.03041502833366394",
   "Negative": "0.00153130863327533",
   "Neutral": "0.9680356979370117",
   "Mixed": "1.803859595383983e-05"
 },
 {
   "tweet_id": "1589974034128961536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974032681959425",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589974031205568512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974022556913664",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589974022296834048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974013048414209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589974004286492672",
   "Positive": "0.07017014920711517",
   "Negative": "0.0030548793729394674",
   "Neutral": "0.926762044429779",
   "Mixed": "1.2953732039022725e-05"
 },
 {
   "tweet_id": "1589974001614745600",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589973998162837505",
   "Positive": "0.005903224460780621",
   "Negative": "0.0032920108642429113",
   "Neutral": "0.9908009171485901",
   "Mixed": "3.898344857589109e-06"
 },
 {
   "tweet_id": "1589973997256859648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973994182443009",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589973992584380416",
   "Positive": "0.8915908336639404",
   "Negative": "0.01227428950369358",
   "Neutral": "0.09592010080814362",
   "Mixed": "0.0002147491177311167"
 },
 {
   "tweet_id": "1589973969523789824",
   "Positive": "0.00035472086165100336",
   "Negative": "9.606112871551886e-05",
   "Neutral": "0.9995429515838623",
   "Mixed": "6.340656909742393e-06"
 },
 {
   "tweet_id": "1589973968215502849",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973966365810688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973961542369281",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589973952843386881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973951023042560",
   "Positive": "0.04484272748231888",
   "Negative": "6.404081068467349e-05",
   "Neutral": "0.9546368718147278",
   "Mixed": "0.0004563375550787896"
 },
 {
   "tweet_id": "1589973938989596672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973936011628546",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973935348912128",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589973929325920256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973926012416000",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589973906546634752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973905208676356",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973905149947905",
   "Positive": "0.014949217438697815",
   "Negative": "0.003808396402746439",
   "Neutral": "0.9812167882919312",
   "Mixed": "2.560750363045372e-05"
 },
 {
   "tweet_id": "1589973897105256454",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973894693519360",
   "Positive": "0.028220031410455704",
   "Negative": "0.2886117100715637",
   "Neutral": "0.5215859413146973",
   "Mixed": "0.161582350730896"
 },
 {
   "tweet_id": "1589973883020771329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973881900896257",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589973878574837760",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973872656363520",
   "Positive": "0.003710248740389943",
   "Negative": "0.0003490939852781594",
   "Neutral": "0.9959379434585571",
   "Mixed": "2.7347143713996047e-06"
 },
 {
   "tweet_id": "1589973851416723459",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973849848025090",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589973830772350978",
   "Positive": "0.41870030760765076",
   "Negative": "0.00020411272998899221",
   "Neutral": "0.5810431838035583",
   "Mixed": "5.23940434504766e-05"
 },
 {
   "tweet_id": "1589973826271866882",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973823239385091",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589973810132176900",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589973806700908544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973798606213120",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973789903056896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973774354780160",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589973774094700544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973771997556739",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589973751688761345",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973749629358081",
   "Positive": "0.03335477039217949",
   "Negative": "4.802139301318675e-05",
   "Neutral": "0.9663184881210327",
   "Mixed": "0.0002786522381938994"
 },
 {
   "tweet_id": "1589973737721724930",
   "Positive": "0.007265569642186165",
   "Negative": "8.167239866452292e-05",
   "Neutral": "0.9926151037216187",
   "Mixed": "3.763476343010552e-05"
 },
 {
   "tweet_id": "1589973732973764612",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973729723183105",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973727961575424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973718465671169",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589973713893863424",
   "Positive": "0.001352220308035612",
   "Negative": "0.7831206917762756",
   "Neutral": "0.21543271839618683",
   "Mixed": "9.432410297449678e-05"
 },
 {
   "tweet_id": "1589973713038225411",
   "Positive": "0.00643532257527113",
   "Negative": "0.0001894025772344321",
   "Neutral": "0.9933716058731079",
   "Mixed": "3.7131173939997097e-06"
 },
 {
   "tweet_id": "1589973708688719872",
   "Positive": "0.7034022212028503",
   "Negative": "0.0009136981097981334",
   "Neutral": "0.29558274149894714",
   "Mixed": "0.0001013371511362493"
 },
 {
   "tweet_id": "1589973706679685122",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1589973699272507393",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973693555695618",
   "Positive": "0.08072313666343689",
   "Negative": "0.037369824945926666",
   "Neutral": "0.8808602094650269",
   "Mixed": "0.0010467867832630873"
 },
 {
   "tweet_id": "1589973692892971008",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973674647781380",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973653533229058",
   "Positive": "0.0016440064646303654",
   "Negative": "0.00030186332878656685",
   "Neutral": "0.9980518817901611",
   "Mixed": "2.236914951936342e-06"
 },
 {
   "tweet_id": "1589973649314172928",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973641575673858",
   "Positive": "0.001352220308035612",
   "Negative": "0.7831206917762756",
   "Neutral": "0.21543271839618683",
   "Mixed": "9.432410297449678e-05"
 },
 {
   "tweet_id": "1589973641307258885",
   "Positive": "0.17282050848007202",
   "Negative": "0.0010164770064875484",
   "Neutral": "0.8260012269020081",
   "Mixed": "0.000161789808771573"
 },
 {
   "tweet_id": "1589973629760307205",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973627730264064",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589973608327426050",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973601616551936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973598395314184",
   "Positive": "0.014505472965538502",
   "Negative": "0.1054873913526535",
   "Neutral": "0.19058045744895935",
   "Mixed": "0.6894266605377197"
 },
 {
   "tweet_id": "1589973568842260480",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1589973567848214528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973565189009408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973564434055170",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973555797970946",
   "Positive": "0.7190371155738831",
   "Negative": "0.012760846875607967",
   "Neutral": "0.09806015342473984",
   "Mixed": "0.17014180123806"
 },
 {
   "tweet_id": "1589973550328610818",
   "Positive": "0.001267399755306542",
   "Negative": "8.81099549587816e-05",
   "Neutral": "0.9986395239830017",
   "Mixed": "5.027274255553493e-06"
 },
 {
   "tweet_id": "1589973545916194818",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973516333776897",
   "Positive": "0.0012436161050572991",
   "Negative": "0.7637335062026978",
   "Neutral": "0.2349313646554947",
   "Mixed": "9.160545596387237e-05"
 },
 {
   "tweet_id": "1589973515310346240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973514383228933",
   "Positive": "0.15314993262290955",
   "Negative": "0.0026500634849071503",
   "Neutral": "0.8441714644432068",
   "Mixed": "2.844993468897883e-05"
 },
 {
   "tweet_id": "1589973513498112002",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973512122351617",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973503423709187",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973502807150593",
   "Positive": "0.6402280330657959",
   "Negative": "0.004694940522313118",
   "Neutral": "0.35499659180641174",
   "Mixed": "8.035689097596332e-05"
 },
 {
   "tweet_id": "1589973499011297280",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589973498751221760",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973495001542658",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973485647892480",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589973484779630593",
   "Positive": "0.11977288872003555",
   "Negative": "0.006924103945493698",
   "Neutral": "0.873282253742218",
   "Mixed": "2.0666999262175523e-05"
 },
 {
   "tweet_id": "1589973478798635008",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589973477263839233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973477041504256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973475741282304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973468099248128",
   "Positive": "0.48568102717399597",
   "Negative": "0.02758767083287239",
   "Neutral": "0.48663344979286194",
   "Mixed": "9.786525333765894e-05"
 },
 {
   "tweet_id": "1589973467839213569",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973454937210880",
   "Positive": "0.017698466777801514",
   "Negative": "0.00013270907220430672",
   "Neutral": "0.9821292757987976",
   "Mixed": "3.95628776459489e-05"
 },
 {
   "tweet_id": "1589973439385067520",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589973439137599489",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973435371118597",
   "Positive": "0.025403568521142006",
   "Negative": "0.0002399554941803217",
   "Neutral": "0.9743287563323975",
   "Mixed": "2.7741338271880522e-05"
 },
 {
   "tweet_id": "1589973427410317313",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589973423157313536",
   "Positive": "0.28095781803131104",
   "Negative": "0.00026796781457960606",
   "Neutral": "0.7187366485595703",
   "Mixed": "3.7605037505272776e-05"
 },
 {
   "tweet_id": "1589973412319232000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973400294150144",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973395688783874",
   "Positive": "0.024464556947350502",
   "Negative": "4.770458326674998e-05",
   "Neutral": "0.9752519130706787",
   "Mixed": "0.00023580431297887117"
 },
 {
   "tweet_id": "1589973369071730689",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589973362637672448",
   "Positive": "0.9186611175537109",
   "Negative": "0.0010096534388139844",
   "Neutral": "0.07986026257276535",
   "Mixed": "0.00046901690075173974"
 },
 {
   "tweet_id": "1589973356119752704",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973354085482496",
   "Positive": "0.12295392900705338",
   "Negative": "0.0032499779481440783",
   "Neutral": "0.873776376247406",
   "Mixed": "1.970705488929525e-05"
 },
 {
   "tweet_id": "1589973329926311936",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1589973327288111106",
   "Positive": "0.9083552360534668",
   "Negative": "0.0003182202926836908",
   "Neutral": "0.09075676649808884",
   "Mixed": "0.0005697119631804526"
 },
 {
   "tweet_id": "1589973326453432321",
   "Positive": "0.08611323684453964",
   "Negative": "5.865625644219108e-05",
   "Neutral": "0.912961483001709",
   "Mixed": "0.0008666013600304723"
 },
 {
   "tweet_id": "1589973318131597312",
   "Positive": "0.4191734492778778",
   "Negative": "0.0014826811384409666",
   "Neutral": "0.5788074135780334",
   "Mixed": "0.0005364399985410273"
 },
 {
   "tweet_id": "1589973313606275072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973308510203907",
   "Positive": "0.9593721628189087",
   "Negative": "0.00036279839696362615",
   "Neutral": "0.04015563800930977",
   "Mixed": "0.00010950335126835853"
 },
 {
   "tweet_id": "1589973302105477121",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589973272590168064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973266013507584",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973259876900865",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973258564403205",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1589973256144310272",
   "Positive": "0.10658245533704758",
   "Negative": "6.716772622894496e-05",
   "Neutral": "0.8927008509635925",
   "Mixed": "0.0006495325942523777"
 },
 {
   "tweet_id": "1589973229535637504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973219045670912",
   "Positive": "0.06209639459848404",
   "Negative": "9.939395386027172e-05",
   "Neutral": "0.937767744064331",
   "Mixed": "3.645558899734169e-05"
 },
 {
   "tweet_id": "1589973217455747072",
   "Positive": "0.019833989441394806",
   "Negative": "6.368150934576988e-05",
   "Neutral": "0.9800589680671692",
   "Mixed": "4.3339343392290175e-05"
 },
 {
   "tweet_id": "1589973209927274496",
   "Positive": "0.7996845841407776",
   "Negative": "0.0003831351932603866",
   "Neutral": "0.1998114436864853",
   "Mixed": "0.00012089232041034847"
 },
 {
   "tweet_id": "1589973205766524928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973205695225857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973176888397824",
   "Positive": "0.004029653500765562",
   "Negative": "0.9319562315940857",
   "Neutral": "0.05931369960308075",
   "Mixed": "0.004700467921793461"
 },
 {
   "tweet_id": "1589973176695803907",
   "Positive": "0.033013250678777695",
   "Negative": "6.646421388722956e-05",
   "Neutral": "0.9666900038719177",
   "Mixed": "0.00023020195658318698"
 },
 {
   "tweet_id": "1589973167845830656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973158001790977",
   "Positive": "0.03344613313674927",
   "Negative": "0.00025150872534140944",
   "Neutral": "0.9662593007087708",
   "Mixed": "4.3096817535115406e-05"
 },
 {
   "tweet_id": "1589973152649842688",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589973150674358273",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973149210533888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973148753330176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973145326616576",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973138364059648",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973125659512832",
   "Positive": "0.020258178934454918",
   "Negative": "6.0370835853973404e-05",
   "Neutral": "0.9795193076133728",
   "Mixed": "0.0001621717237867415"
 },
 {
   "tweet_id": "1589973123017093120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973119837818880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973110979432448",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973110639714304",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589973105308766209",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973099361218561",
   "Positive": "0.014412221498787403",
   "Negative": "0.00010012953862315044",
   "Neutral": "0.9854297637939453",
   "Mixed": "5.795263496111147e-05"
 },
 {
   "tweet_id": "1589973098971168769",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973098886897665",
   "Positive": "0.006171129178255796",
   "Negative": "0.0006831256323494017",
   "Neutral": "0.9931433200836182",
   "Mixed": "2.341041181352921e-06"
 },
 {
   "tweet_id": "1589973098799181825",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589973094370013192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973093782814722",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589973087453577218",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589973085435752450",
   "Positive": "0.024651391431689262",
   "Negative": "5.209706068853848e-05",
   "Neutral": "0.9750051498413086",
   "Mixed": "0.0002913982607424259"
 },
 {
   "tweet_id": "1589973079559901184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589973075491454977",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589973070017859586",
   "Positive": "0.06732552498579025",
   "Negative": "0.0031348858028650284",
   "Neutral": "0.9295197129249573",
   "Mixed": "1.9884222638211213e-05"
 },
 {
   "tweet_id": "1589973068029755394",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973063247880195",
   "Positive": "0.0062935142777860165",
   "Negative": "0.00025933366850949824",
   "Neutral": "0.9934428930282593",
   "Mixed": "4.233535946696065e-06"
 },
 {
   "tweet_id": "1589973059746025474",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589973058961670145",
   "Positive": "0.17818237841129303",
   "Negative": "0.00020355005108285695",
   "Neutral": "0.8214234709739685",
   "Mixed": "0.00019069472909905016"
 },
 {
   "tweet_id": "1589973045770588160",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589973042805231616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589973028766875649",
   "Positive": "0.0009521536994725466",
   "Negative": "0.017543377354741096",
   "Neutral": "0.9814981818199158",
   "Mixed": "6.2834174059389625e-06"
 },
 {
   "tweet_id": "1589973006721638400",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589972982910242816",
   "Positive": "0.03935575857758522",
   "Negative": "5.808205241919495e-05",
   "Neutral": "0.9603018164634705",
   "Mixed": "0.00028430638485588133"
 },
 {
   "tweet_id": "1589972980796620802",
   "Positive": "0.4358890950679779",
   "Negative": "0.0026712764520198107",
   "Neutral": "0.560805082321167",
   "Mixed": "0.0006345082656480372"
 },
 {
   "tweet_id": "1589972979492204545",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972978015821824",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589972973905383426",
   "Positive": "0.9609607458114624",
   "Negative": "6.871515506645665e-05",
   "Neutral": "0.038935475051403046",
   "Mixed": "3.501295577734709e-05"
 },
 {
   "tweet_id": "1589972971090718720",
   "Positive": "0.25000256299972534",
   "Negative": "0.0007562328828498721",
   "Neutral": "0.7491691708564758",
   "Mixed": "7.210806506918743e-05"
 },
 {
   "tweet_id": "1589972962828255232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972949783957505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972946151690242",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972943345704960",
   "Positive": "0.027600865811109543",
   "Negative": "6.477445276686922e-05",
   "Neutral": "0.9721202254295349",
   "Mixed": "0.00021414714865386486"
 },
 {
   "tweet_id": "1589972935540113409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972932142727169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972919668862983",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972913146720257",
   "Positive": "0.07752452045679092",
   "Negative": "0.0008781035430729389",
   "Neutral": "0.9215400815010071",
   "Mixed": "5.732299905503169e-05"
 },
 {
   "tweet_id": "1589972909123960834",
   "Positive": "0.03053462877869606",
   "Negative": "7.627929153386503e-05",
   "Neutral": "0.9691690802574158",
   "Mixed": "0.0002199590962845832"
 },
 {
   "tweet_id": "1589972908738502657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972900488286210",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972876916293633",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589972874299060224",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972867319762944",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972867286183937",
   "Positive": "0.025282565504312515",
   "Negative": "5.1497467211447656e-05",
   "Neutral": "0.974437952041626",
   "Mixed": "0.00022802804596722126"
 },
 {
   "tweet_id": "1589972866497679362",
   "Positive": "0.002720261225476861",
   "Negative": "0.12087485194206238",
   "Neutral": "0.8763843178749084",
   "Mixed": "2.0562254576361738e-05"
 },
 {
   "tweet_id": "1589972863163174913",
   "Positive": "0.001179689192213118",
   "Negative": "0.8723704814910889",
   "Neutral": "0.12643571197986603",
   "Mixed": "1.4061617548577487e-05"
 },
 {
   "tweet_id": "1589972850886463490",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972839448588289",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589972838643294210",
   "Positive": "0.0008362516527995467",
   "Negative": "0.13635176420211792",
   "Neutral": "0.8624306917190552",
   "Mixed": "0.00038128523738123477"
 },
 {
   "tweet_id": "1589972836885880832",
   "Positive": "0.07566910982131958",
   "Negative": "0.01415045466274023",
   "Neutral": "0.9099516868591309",
   "Mixed": "0.00022873279522173107"
 },
 {
   "tweet_id": "1589972826848915456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972822041862146",
   "Positive": "0.018469931557774544",
   "Negative": "5.177605999051593e-05",
   "Neutral": "0.9813477993011475",
   "Mixed": "0.00013050557754468173"
 },
 {
   "tweet_id": "1589972818036658176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972808666611713",
   "Positive": "0.001170571893453598",
   "Negative": "0.05423835664987564",
   "Neutral": "0.9445779919624329",
   "Mixed": "1.3023052815697156e-05"
 },
 {
   "tweet_id": "1589972808226205696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972797698498560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972778916413441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972777049944064",
   "Positive": "0.03285345807671547",
   "Negative": "6.069385199225508e-05",
   "Neutral": "0.9668928980827332",
   "Mixed": "0.00019295825040899217"
 },
 {
   "tweet_id": "1589972776143978496",
   "Positive": "0.8834183812141418",
   "Negative": "0.009351085871458054",
   "Neutral": "0.1059354618191719",
   "Mixed": "0.0012950662057846785"
 },
 {
   "tweet_id": "1589972775347027968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972775183126528",
   "Positive": "0.6376650929450989",
   "Negative": "0.0015491492813453078",
   "Neutral": "0.3607037365436554",
   "Mixed": "8.203084144042805e-05"
 },
 {
   "tweet_id": "1589972758691074049",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972756074201090",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972752173498368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972749585637377",
   "Positive": "0.08377818763256073",
   "Negative": "0.002420324133709073",
   "Neutral": "0.9135153889656067",
   "Mixed": "0.00028602470410987735"
 },
 {
   "tweet_id": "1589972748931338242",
   "Positive": "0.9938144683837891",
   "Negative": "0.0015588211826980114",
   "Neutral": "0.00455468287691474",
   "Mixed": "7.201285188784823e-05"
 },
 {
   "tweet_id": "1589972741184458752",
   "Positive": "0.0025962302461266518",
   "Negative": "0.6477957963943481",
   "Neutral": "0.34687677025794983",
   "Mixed": "0.002731223590672016"
 },
 {
   "tweet_id": "1589972736017063937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972731017461760",
   "Positive": "0.993861198425293",
   "Negative": "0.0016558808274567127",
   "Neutral": "0.004406696185469627",
   "Mixed": "7.625795115018263e-05"
 },
 {
   "tweet_id": "1589972721311846402",
   "Positive": "0.015050443820655346",
   "Negative": "4.4302560127107427e-05",
   "Neutral": "0.9847850799560547",
   "Mixed": "0.00012016011896776035"
 },
 {
   "tweet_id": "1589972705134055424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972702160650241",
   "Positive": "0.9936782717704773",
   "Negative": "0.0016562447417527437",
   "Neutral": "0.004589315038174391",
   "Mixed": "7.616934453835711e-05"
 },
 {
   "tweet_id": "1589972698222190595",
   "Positive": "0.012211733497679234",
   "Negative": "0.8791626691818237",
   "Neutral": "0.1002260148525238",
   "Mixed": "0.008399602957069874"
 },
 {
   "tweet_id": "1589972694497628162",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972691884605442",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972689774862336",
   "Positive": "0.04402825981378555",
   "Negative": "0.013520385138690472",
   "Neutral": "0.942385196685791",
   "Mixed": "6.620668864343315e-05"
 },
 {
   "tweet_id": "1589972685609914368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972682514526210",
   "Positive": "0.993889570236206",
   "Negative": "0.0015361947007477283",
   "Neutral": "0.004501925315707922",
   "Mixed": "7.230058690765873e-05"
 },
 {
   "tweet_id": "1589972681566609410",
   "Positive": "0.033924974501132965",
   "Negative": "0.002625773660838604",
   "Neutral": "0.9634363055229187",
   "Mixed": "1.2993284144613426e-05"
 },
 {
   "tweet_id": "1589972680631267328",
   "Positive": "0.009519507177174091",
   "Negative": "0.16465964913368225",
   "Neutral": "0.825452983379364",
   "Mixed": "0.00036780015216208994"
 },
 {
   "tweet_id": "1589972671433183234",
   "Positive": "0.0021930604707449675",
   "Negative": "0.0006622055661864579",
   "Neutral": "0.9971423745155334",
   "Mixed": "2.364381771258195e-06"
 },
 {
   "tweet_id": "1589972668971094022",
   "Positive": "0.01886790245771408",
   "Negative": "5.189316289033741e-05",
   "Neutral": "0.9809462428092957",
   "Mixed": "0.00013392558321356773"
 },
 {
   "tweet_id": "1589972667238862850",
   "Positive": "0.993780791759491",
   "Negative": "0.0015648305416107178",
   "Neutral": "0.004576387815177441",
   "Mixed": "7.798667502356693e-05"
 },
 {
   "tweet_id": "1589972662851207169",
   "Positive": "0.09226454049348831",
   "Negative": "0.011576870456337929",
   "Neutral": "0.8959710001945496",
   "Mixed": "0.00018769926100503653"
 },
 {
   "tweet_id": "1589972657923330049",
   "Positive": "0.06857168674468994",
   "Negative": "0.0010149878216907382",
   "Neutral": "0.930206835269928",
   "Mixed": "0.00020642655726987869"
 },
 {
   "tweet_id": "1589972652214878208",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589972652005142529",
   "Positive": "0.9944726824760437",
   "Negative": "0.0014199053402990103",
   "Neutral": "0.004034978337585926",
   "Mixed": "7.24688870832324e-05"
 },
 {
   "tweet_id": "1589972645684318208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972643440373760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972632338067456",
   "Positive": "0.9940032362937927",
   "Negative": "0.0015568544622510672",
   "Neutral": "0.00436767004430294",
   "Mixed": "7.217725942609832e-05"
 },
 {
   "tweet_id": "1589972626415714306",
   "Positive": "0.028568025678396225",
   "Negative": "5.096308814245276e-05",
   "Neutral": "0.9710465669631958",
   "Mixed": "0.0003344164870213717"
 },
 {
   "tweet_id": "1589972618597527552",
   "Positive": "0.871892511844635",
   "Negative": "0.0012418809346854687",
   "Neutral": "0.12663055956363678",
   "Mixed": "0.00023506749130319804"
 },
 {
   "tweet_id": "1589972615091093505",
   "Positive": "0.9940136075019836",
   "Negative": "0.0014952345518395305",
   "Neutral": "0.004419445525854826",
   "Mixed": "7.179179374361411e-05"
 },
 {
   "tweet_id": "1589972598691368965",
   "Positive": "0.9941904544830322",
   "Negative": "0.0015284239780157804",
   "Neutral": "0.004211279097944498",
   "Mixed": "6.994976138230413e-05"
 },
 {
   "tweet_id": "1589972576495095810",
   "Positive": "0.9943169951438904",
   "Negative": "0.001494547352194786",
   "Neutral": "0.004114727023988962",
   "Mixed": "7.372382970061153e-05"
 },
 {
   "tweet_id": "1589972568869863425",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972568429461505",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589972551044071424",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589972548129021953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972540252131331",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589972535575470080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972527962820608",
   "Positive": "0.7380483150482178",
   "Negative": "0.014872388914227486",
   "Neutral": "0.1527175009250641",
   "Mixed": "0.09436171501874924"
 },
 {
   "tweet_id": "1589972524456370181",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972523541991426",
   "Positive": "0.02702583186328411",
   "Negative": "8.201415766961873e-05",
   "Neutral": "0.9726169109344482",
   "Mixed": "0.00027521979063749313"
 },
 {
   "tweet_id": "1589972519133810689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972517237977090",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972504831197185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972500804698112",
   "Positive": "0.980933427810669",
   "Negative": "0.0002910886541940272",
   "Neutral": "0.01874394156038761",
   "Mixed": "3.1649924494558945e-05"
 },
 {
   "tweet_id": "1589972480277774336",
   "Positive": "0.01189311221241951",
   "Negative": "0.0008169464999809861",
   "Neutral": "0.9872010946273804",
   "Mixed": "8.880103268893436e-05"
 },
 {
   "tweet_id": "1589972474347024385",
   "Positive": "0.042484041303396225",
   "Negative": "5.31149489688687e-05",
   "Neutral": "0.9570534825325012",
   "Mixed": "0.0004093047755304724"
 },
 {
   "tweet_id": "1589972467262504962",
   "Positive": "0.029392864555120468",
   "Negative": "5.4740954510634765e-05",
   "Neutral": "0.9702914357185364",
   "Mixed": "0.00026096924557350576"
 },
 {
   "tweet_id": "1589972453862027270",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589972447419564033",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589972428155125760",
   "Positive": "0.036348868161439896",
   "Negative": "5.215708370087668e-05",
   "Neutral": "0.9631595015525818",
   "Mixed": "0.00043941347394138575"
 },
 {
   "tweet_id": "1589972424967081985",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589972420655734784",
   "Positive": "0.007194782141596079",
   "Negative": "4.659933620132506e-05",
   "Neutral": "0.9927228093147278",
   "Mixed": "3.5916473279939964e-05"
 },
 {
   "tweet_id": "1589972420617965569",
   "Positive": "0.00026950144092552364",
   "Negative": "0.6780498623847961",
   "Neutral": "0.32164546847343445",
   "Mixed": "3.511659087962471e-05"
 },
 {
   "tweet_id": "1589972412086439936",
   "Positive": "0.016582313925027847",
   "Negative": "0.4890473484992981",
   "Neutral": "0.494274765253067",
   "Mixed": "9.56162839429453e-05"
 },
 {
   "tweet_id": "1589972411017211906",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589972410924929025",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589972402213380097",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972386191142913",
   "Positive": "0.029708396643400192",
   "Negative": "6.263406248763204e-05",
   "Neutral": "0.9700483083724976",
   "Mixed": "0.0001806311629479751"
 },
 {
   "tweet_id": "1589972384513425409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972380268404736",
   "Positive": "0.0630011111497879",
   "Negative": "0.043643344193696976",
   "Neutral": "0.6912897825241089",
   "Mixed": "0.20206572115421295"
 },
 {
   "tweet_id": "1589972375105605633",
   "Positive": "0.03335477039217949",
   "Negative": "4.802139301318675e-05",
   "Neutral": "0.9663184881210327",
   "Mixed": "0.0002786522381938994"
 },
 {
   "tweet_id": "1589972370500227072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972368189161472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972365571936259",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972361214054402",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589972357778935808",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589972349641584640",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589972333477122050",
   "Positive": "0.021000025793910027",
   "Negative": "6.779981777071953e-05",
   "Neutral": "0.9787730574607849",
   "Mixed": "0.0001590380270499736"
 },
 {
   "tweet_id": "1589972328968237056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972324308389889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972321552699392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972312539160577",
   "Positive": "0.11502526700496674",
   "Negative": "0.0011457586660981178",
   "Neutral": "0.8837839961051941",
   "Mixed": "4.4945449189981446e-05"
 },
 {
   "tweet_id": "1589972300874797058",
   "Positive": "0.024925587698817253",
   "Negative": "0.0004229231853969395",
   "Neutral": "0.9746246337890625",
   "Mixed": "2.681477963051293e-05"
 },
 {
   "tweet_id": "1589972298878312448",
   "Positive": "0.01659270003437996",
   "Negative": "4.926715701003559e-05",
   "Neutral": "0.9832073450088501",
   "Mixed": "0.00015069627261254936"
 },
 {
   "tweet_id": "1589972297628405760",
   "Positive": "0.002337716519832611",
   "Negative": "7.032749272184446e-05",
   "Neutral": "0.9975841045379639",
   "Mixed": "7.885072591307107e-06"
 },
 {
   "tweet_id": "1589972283615227910",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972276342321157",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589972267064496130",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972264820568065",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972263868436480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972245971365888",
   "Positive": "0.09783170372247696",
   "Negative": "0.22209931910037994",
   "Neutral": "0.6785241365432739",
   "Mixed": "0.0015448718331754208"
 },
 {
   "tweet_id": "1589972238681640962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972236978786304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972226140680193",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589972220549672961",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972217139724288",
   "Positive": "0.994025707244873",
   "Negative": "0.0015669934218749404",
   "Neutral": "0.004333270248025656",
   "Mixed": "7.398554589599371e-05"
 },
 {
   "tweet_id": "1589972216749633536",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1589972214027550720",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972211947163648",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589972209006960640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972201872429056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972200794525696",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589972199850803200",
   "Positive": "0.004465526435524225",
   "Negative": "0.00011693209671648219",
   "Neutral": "0.995406985282898",
   "Mixed": "1.0524139725021087e-05"
 },
 {
   "tweet_id": "1589972198844137472",
   "Positive": "0.9936326146125793",
   "Negative": "0.001588228391483426",
   "Neutral": "0.004705450963228941",
   "Mixed": "7.377180008916184e-05"
 },
 {
   "tweet_id": "1589972194222047232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972189314699265",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972186395443201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972178191388673",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972174458490882",
   "Positive": "0.9938012957572937",
   "Negative": "0.0015364487189799547",
   "Neutral": "0.004589736461639404",
   "Mixed": "7.260085112648085e-05"
 },
 {
   "tweet_id": "1589972165654630400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972162299191297",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589972160936038400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972155978362880",
   "Positive": "0.9938569664955139",
   "Negative": "0.001472792704589665",
   "Neutral": "0.004596036393195391",
   "Mixed": "7.424048817483708e-05"
 },
 {
   "tweet_id": "1589972139410862082",
   "Positive": "0.9937976002693176",
   "Negative": "0.0015658026095479727",
   "Neutral": "0.004561539273709059",
   "Mixed": "7.506965630454943e-05"
 },
 {
   "tweet_id": "1589972134574837761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972122121928705",
   "Positive": "0.9937787652015686",
   "Negative": "0.0015558201121166348",
   "Neutral": "0.004591937642544508",
   "Mixed": "7.342088065342978e-05"
 },
 {
   "tweet_id": "1589972106514927617",
   "Positive": "0.11886200308799744",
   "Negative": "0.00368775543756783",
   "Neutral": "0.8773366808891296",
   "Mixed": "0.00011355755850672722"
 },
 {
   "tweet_id": "1589972105877426177",
   "Positive": "0.8626396059989929",
   "Negative": "0.0004701536090578884",
   "Neutral": "0.13526202738285065",
   "Mixed": "0.001628122292459011"
 },
 {
   "tweet_id": "1589972103138533376",
   "Positive": "0.9933938384056091",
   "Negative": "0.001683408278040588",
   "Neutral": "0.004845779854804277",
   "Mixed": "7.701943832216784e-05"
 },
 {
   "tweet_id": "1589972093029863424",
   "Positive": "0.1944965273141861",
   "Negative": "0.007103993557393551",
   "Neutral": "0.7977973818778992",
   "Mixed": "0.0006021277513355017"
 },
 {
   "tweet_id": "1589972087816749104",
   "Positive": "0.9938209652900696",
   "Negative": "0.0015567827504128218",
   "Neutral": "0.004549696575850248",
   "Mixed": "7.260277197929099e-05"
 },
 {
   "tweet_id": "1589972082766774274",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972077859459072",
   "Positive": "0.8673521876335144",
   "Negative": "0.0010326883057132363",
   "Neutral": "0.13109679520130157",
   "Mixed": "0.0005183284520171583"
 },
 {
   "tweet_id": "1589972073145053184",
   "Positive": "0.9941430687904358",
   "Negative": "0.0014523067511618137",
   "Neutral": "0.004332027863711119",
   "Mixed": "7.252635987242684e-05"
 },
 {
   "tweet_id": "1589972069680574464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589972064156672001",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589972056745336833",
   "Positive": "0.9940847754478455",
   "Negative": "0.0015046942280605435",
   "Neutral": "0.00433584488928318",
   "Mixed": "7.476486644009128e-05"
 },
 {
   "tweet_id": "1589972051359854594",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589972048272510977",
   "Positive": "0.7862513065338135",
   "Negative": "0.000301884749205783",
   "Neutral": "0.21320436894893646",
   "Mixed": "0.00024244125233963132"
 },
 {
   "tweet_id": "1589972046737715205",
   "Positive": "0.3656032681465149",
   "Negative": "0.002766846679151058",
   "Neutral": "0.6315956115722656",
   "Mixed": "3.422142981435172e-05"
 },
 {
   "tweet_id": "1589972040098140160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589972036591681539",
   "Positive": "0.00015580344188492745",
   "Negative": "0.9884665012359619",
   "Neutral": "0.011355401948094368",
   "Mixed": "2.2345251636579633e-05"
 },
 {
   "tweet_id": "1589972036348416001",
   "Positive": "0.9940306544303894",
   "Negative": "0.0014649945078417659",
   "Neutral": "0.004430547822266817",
   "Mixed": "7.381092291325331e-05"
 },
 {
   "tweet_id": "1589972030606090240",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589972026273726464",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589972024541253633",
   "Positive": "0.3893233835697174",
   "Negative": "0.0011713745770975947",
   "Neutral": "0.6086445450782776",
   "Mixed": "0.0008607169729657471"
 },
 {
   "tweet_id": "1589972020309426181",
   "Positive": "0.9935643076896667",
   "Negative": "0.0016199827659875154",
   "Neutral": "0.004736470989882946",
   "Mixed": "7.929078856250271e-05"
 },
 {
   "tweet_id": "1589972020049367045",
   "Positive": "0.05553010106086731",
   "Negative": "0.015029153786599636",
   "Neutral": "0.9293584823608398",
   "Mixed": "8.230471576098353e-05"
 },
 {
   "tweet_id": "1589972013946654720",
   "Positive": "0.14118298888206482",
   "Negative": "0.06108785793185234",
   "Neutral": "0.7975839376449585",
   "Mixed": "0.00014532321074511856"
 },
 {
   "tweet_id": "1589972003326681088",
   "Positive": "0.9937153458595276",
   "Negative": "0.0015647094696760178",
   "Neutral": "0.0046431138180196285",
   "Mixed": "7.67751844250597e-05"
 },
 {
   "tweet_id": "1589971995298783233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971990408228867",
   "Positive": "0.024813853204250336",
   "Negative": "0.006910285446792841",
   "Neutral": "0.9682118892669678",
   "Mixed": "6.389329064404592e-05"
 },
 {
   "tweet_id": "1589971987761623041",
   "Positive": "0.9935964345932007",
   "Negative": "0.0016996986232697964",
   "Neutral": "0.00462723383679986",
   "Mixed": "7.666210876777768e-05"
 },
 {
   "tweet_id": "1589971982145445890",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589971981558231040",
   "Positive": "0.0360896997153759",
   "Negative": "5.15064675710164e-05",
   "Neutral": "0.9635587930679321",
   "Mixed": "0.00030004302971065044"
 },
 {
   "tweet_id": "1589971980740366336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971976730607616",
   "Positive": "0.8900970220565796",
   "Negative": "0.0005718634929507971",
   "Neutral": "0.1092604547739029",
   "Mixed": "7.074051245581359e-05"
 },
 {
   "tweet_id": "1589971972783767553",
   "Positive": "0.03433915972709656",
   "Negative": "0.016212504357099533",
   "Neutral": "0.9494022727012634",
   "Mixed": "4.6105073124635965e-05"
 },
 {
   "tweet_id": "1589971971668049922",
   "Positive": "0.9929526448249817",
   "Negative": "0.0018160538747906685",
   "Neutral": "0.00514764990657568",
   "Mixed": "8.371248259209096e-05"
 },
 {
   "tweet_id": "1589971966689423361",
   "Positive": "0.1431649625301361",
   "Negative": "0.0003882332530338317",
   "Neutral": "0.8562728762626648",
   "Mixed": "0.00017397008195985109"
 },
 {
   "tweet_id": "1589971963531100160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971955947810816",
   "Positive": "0.9939725995063782",
   "Negative": "0.0015472921077162027",
   "Neutral": "0.0044046794064342976",
   "Mixed": "7.54521825001575e-05"
 },
 {
   "tweet_id": "1589971953539915777",
   "Positive": "0.002357723657041788",
   "Negative": "0.00022257230011746287",
   "Neutral": "0.9974167346954346",
   "Mixed": "2.9873526727897115e-06"
 },
 {
   "tweet_id": "1589971952487510016",
   "Positive": "0.03422022983431816",
   "Negative": "0.0031551190186291933",
   "Neutral": "0.9625934362411499",
   "Mixed": "3.1237454095389694e-05"
 },
 {
   "tweet_id": "1589971950646210561",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589971949320802305",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971947332702209",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589971940626038785",
   "Positive": "0.9934953451156616",
   "Negative": "0.0017276967409998178",
   "Neutral": "0.004696366842836142",
   "Mixed": "8.053399506025016e-05"
 },
 {
   "tweet_id": "1589971929146195968",
   "Positive": "0.03403943032026291",
   "Negative": "0.002835721243172884",
   "Neutral": "0.96309894323349",
   "Mixed": "2.5902178094838746e-05"
 },
 {
   "tweet_id": "1589971928038531072",
   "Positive": "0.9033516049385071",
   "Negative": "0.0005720000481233001",
   "Neutral": "0.09605184197425842",
   "Mixed": "2.4536619093851186e-05"
 },
 {
   "tweet_id": "1589971919230889985",
   "Positive": "0.9940215945243835",
   "Negative": "0.0014801501529291272",
   "Neutral": "0.004427601583302021",
   "Mixed": "7.063815428409725e-05"
 },
 {
   "tweet_id": "1589971914621358081",
   "Positive": "0.05503946170210838",
   "Negative": "0.002094316529110074",
   "Neutral": "0.9427881836891174",
   "Mixed": "7.800617458997294e-05"
 },
 {
   "tweet_id": "1589971904500211713",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971903787466753",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589971902705324032",
   "Positive": "0.9941717982292175",
   "Negative": "0.0014717025915160775",
   "Neutral": "0.004287228919565678",
   "Mixed": "6.919730367371812e-05"
 },
 {
   "tweet_id": "1589971894182477825",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589971892416712704",
   "Positive": "0.03485918045043945",
   "Negative": "0.003024592064321041",
   "Neutral": "0.9620883464813232",
   "Mixed": "2.791278348013293e-05"
 },
 {
   "tweet_id": "1589971891305213952",
   "Positive": "0.07110666483640671",
   "Negative": "0.001537302159704268",
   "Neutral": "0.9273130893707275",
   "Mixed": "4.2897801904473454e-05"
 },
 {
   "tweet_id": "1589971887714865152",
   "Positive": "0.3727342188358307",
   "Negative": "0.008277714252471924",
   "Neutral": "0.5682170987129211",
   "Mixed": "0.05077099800109863"
 },
 {
   "tweet_id": "1589971886318182400",
   "Positive": "0.9943115711212158",
   "Negative": "0.0014409967698156834",
   "Neutral": "0.0041783940978348255",
   "Mixed": "6.9029389123898e-05"
 },
 {
   "tweet_id": "1589971875614294016",
   "Positive": "0.03079746849834919",
   "Negative": "0.013384372927248478",
   "Neutral": "0.9557802677154541",
   "Mixed": "3.798981924774125e-05"
 },
 {
   "tweet_id": "1589971860305088514",
   "Positive": "0.9941684007644653",
   "Negative": "0.0013586104614660144",
   "Neutral": "0.0044032372534275055",
   "Mixed": "6.970601680222899e-05"
 },
 {
   "tweet_id": "1589971859549736960",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589971856643461120",
   "Positive": "0.8809042572975159",
   "Negative": "0.0003729340969584882",
   "Neutral": "0.11839095503091812",
   "Mixed": "0.0003319431853014976"
 },
 {
   "tweet_id": "1589971843997663233",
   "Positive": "0.3727342188358307",
   "Negative": "0.008277714252471924",
   "Neutral": "0.5682170987129211",
   "Mixed": "0.05077099800109863"
 },
 {
   "tweet_id": "1589971839702695936",
   "Positive": "0.9938445091247559",
   "Negative": "0.0015305376145988703",
   "Neutral": "0.004548071417957544",
   "Mixed": "7.681064744247124e-05"
 },
 {
   "tweet_id": "1589971838071115776",
   "Positive": "0.04769400879740715",
   "Negative": "6.801034760428593e-05",
   "Neutral": "0.9517502784729004",
   "Mixed": "0.00048770493594929576"
 },
 {
   "tweet_id": "1589971827023314944",
   "Positive": "0.0045218258164823055",
   "Negative": "0.9078498482704163",
   "Neutral": "0.06429863721132278",
   "Mixed": "0.023329678922891617"
 },
 {
   "tweet_id": "1589971824217292801",
   "Positive": "0.00851474143564701",
   "Negative": "0.24189114570617676",
   "Neutral": "0.7284284830093384",
   "Mixed": "0.02116565592586994"
 },
 {
   "tweet_id": "1589971819003777028",
   "Positive": "0.9934720993041992",
   "Negative": "0.001660826150327921",
   "Neutral": "0.004786740988492966",
   "Mixed": "8.029575838008896e-05"
 },
 {
   "tweet_id": "1589971809562427392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971800892772354",
   "Positive": "0.9936628341674805",
   "Negative": "0.0016684505390003324",
   "Neutral": "0.004585577640682459",
   "Mixed": "8.31404177006334e-05"
 },
 {
   "tweet_id": "1589971797054980097",
   "Positive": "0.31392183899879456",
   "Negative": "0.01341672707349062",
   "Neutral": "0.5286924242973328",
   "Mixed": "0.1439690738916397"
 },
 {
   "tweet_id": "1589971784279158784",
   "Positive": "0.9932625889778137",
   "Negative": "0.0016735972603783011",
   "Neutral": "0.004984505008906126",
   "Mixed": "7.93276631156914e-05"
 },
 {
   "tweet_id": "1589971780474929154",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1589971780407824384",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589971773227175937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971771700424705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971767548051457",
   "Positive": "0.9936926364898682",
   "Negative": "0.001639168243855238",
   "Neutral": "0.004591049626469612",
   "Mixed": "7.716348773101345e-05"
 },
 {
   "tweet_id": "1589971751911686145",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971750787637248",
   "Positive": "0.9936191439628601",
   "Negative": "0.0016165509587153792",
   "Neutral": "0.00469187693670392",
   "Mixed": "7.235442899400368e-05"
 },
 {
   "tweet_id": "1589971748157808642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971746941440000",
   "Positive": "0.33323749899864197",
   "Negative": "0.005131516605615616",
   "Neutral": "0.5209541320800781",
   "Mixed": "0.14067679643630981"
 },
 {
   "tweet_id": "1589971745213415424",
   "Positive": "0.09279321134090424",
   "Negative": "0.0012156468583270907",
   "Neutral": "0.9059696793556213",
   "Mixed": "2.1536894564633258e-05"
 },
 {
   "tweet_id": "1589971734316433409",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589971734241120256",
   "Positive": "0.9935821294784546",
   "Negative": "0.0016061842907220125",
   "Neutral": "0.004739798605442047",
   "Mixed": "7.193734199972823e-05"
 },
 {
   "tweet_id": "1589971717191249920",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971716423700480",
   "Positive": "0.9937795996665955",
   "Negative": "0.0016287369653582573",
   "Neutral": "0.004519980400800705",
   "Mixed": "7.170216122176498e-05"
 },
 {
   "tweet_id": "1589971708340903938",
   "Positive": "0.006202672608196735",
   "Negative": "0.6577496528625488",
   "Neutral": "0.33595895767211914",
   "Mixed": "8.870221063261852e-05"
 },
 {
   "tweet_id": "1589971707489837056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971704809676800",
   "Positive": "0.3727342188358307",
   "Negative": "0.008277714252471924",
   "Neutral": "0.5682170987129211",
   "Mixed": "0.05077099800109863"
 },
 {
   "tweet_id": "1589971702725095425",
   "Positive": "0.1293066143989563",
   "Negative": "0.18564681708812714",
   "Neutral": "0.6844984889030457",
   "Mixed": "0.0005479779792949557"
 },
 {
   "tweet_id": "1589971699063492609",
   "Positive": "0.9940451383590698",
   "Negative": "0.0014507835730910301",
   "Neutral": "0.004437846131622791",
   "Mixed": "6.62112288409844e-05"
 },
 {
   "tweet_id": "1589971691253673989",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971681086697473",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1589971680440750082",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971679065026560",
   "Positive": "0.9937973618507385",
   "Negative": "0.0015632687136530876",
   "Neutral": "0.004567617084830999",
   "Mixed": "7.176227518357337e-05"
 },
 {
   "tweet_id": "1589971667043991552",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971665790042114",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971662082314240",
   "Positive": "0.993341863155365",
   "Negative": "0.001689666765742004",
   "Neutral": "0.004895103629678488",
   "Mixed": "7.341337914112955e-05"
 },
 {
   "tweet_id": "1589971659876106244",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971658391318528",
   "Positive": "0.3727342188358307",
   "Negative": "0.008277714252471924",
   "Neutral": "0.5682170987129211",
   "Mixed": "0.05077099800109863"
 },
 {
   "tweet_id": "1589971657333960704",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971647418994690",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589971646408187905",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971640234168324",
   "Positive": "0.9935234785079956",
   "Negative": "0.0016481996281072497",
   "Neutral": "0.004754990339279175",
   "Mixed": "7.33645138097927e-05"
 },
 {
   "tweet_id": "1589971637562380288",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971634974527489",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971633879617537",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971625041985536",
   "Positive": "0.0024038124829530716",
   "Negative": "0.20185759663581848",
   "Neutral": "0.7951839566230774",
   "Mixed": "0.0005546131869778037"
 },
 {
   "tweet_id": "1589971623100420098",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971622215172096",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971619661090816",
   "Positive": "0.9935412406921387",
   "Negative": "0.0016482361825183034",
   "Neutral": "0.004738708026707172",
   "Mixed": "7.184463174780831e-05"
 },
 {
   "tweet_id": "1589971618016555011",
   "Positive": "0.021032925695180893",
   "Negative": "0.19237497448921204",
   "Neutral": "0.7861218452453613",
   "Mixed": "0.0004702663281932473"
 },
 {
   "tweet_id": "1589971612941656064",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971609892564993",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971605832466440",
   "Positive": "0.003846342908218503",
   "Negative": "0.00010364169429522008",
   "Neutral": "0.9960455298423767",
   "Mixed": "4.4760254240827635e-06"
 },
 {
   "tweet_id": "1589971599641698306",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971596629880834",
   "Positive": "0.0014042290858924389",
   "Negative": "0.0002883142442442477",
   "Neutral": "0.9983049631118774",
   "Mixed": "2.551303623476997e-06"
 },
 {
   "tweet_id": "1589971596173008897",
   "Positive": "0.36599257588386536",
   "Negative": "0.010273163206875324",
   "Neutral": "0.5944531559944153",
   "Mixed": "0.029281049966812134"
 },
 {
   "tweet_id": "1589971593287323649",
   "Positive": "0.9933738708496094",
   "Negative": "0.0016824115300551057",
   "Neutral": "0.004870271310210228",
   "Mixed": "7.349616498686373e-05"
 },
 {
   "tweet_id": "1589971588447076352",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589971573280493568",
   "Positive": "0.01775115169584751",
   "Negative": "0.025063268840312958",
   "Neutral": "0.9571613669395447",
   "Mixed": "2.421987483103294e-05"
 },
 {
   "tweet_id": "1589971568498970628",
   "Positive": "0.9934189915657043",
   "Negative": "0.0015972998226061463",
   "Neutral": "0.004908397793769836",
   "Mixed": "7.526919216616079e-05"
 },
 {
   "tweet_id": "1589971560873758724",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589971557622992898",
   "Positive": "0.8204713463783264",
   "Negative": "0.0002560779976192862",
   "Neutral": "0.17864596843719482",
   "Mixed": "0.0006266266573220491"
 },
 {
   "tweet_id": "1589971557107236867",
   "Positive": "0.3830977976322174",
   "Negative": "0.007059579249471426",
   "Neutral": "0.5849840641021729",
   "Mixed": "0.024858549237251282"
 },
 {
   "tweet_id": "1589971551717560321",
   "Positive": "0.9932355284690857",
   "Negative": "0.0017637246055528522",
   "Neutral": "0.004924028180539608",
   "Mixed": "7.665984594495967e-05"
 },
 {
   "tweet_id": "1589971550782259207",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1589971550362800128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971533480726530",
   "Positive": "0.9936872124671936",
   "Negative": "0.001496502896770835",
   "Neutral": "0.0047464859671890736",
   "Mixed": "6.978998135309666e-05"
 },
 {
   "tweet_id": "1589971527499669506",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589971520570691584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971516036636676",
   "Positive": "0.9936475157737732",
   "Negative": "0.0015381468692794442",
   "Neutral": "0.004746137652546167",
   "Mixed": "6.82398458593525e-05"
 },
 {
   "tweet_id": "1589971504569397249",
   "Positive": "0.007031390909105539",
   "Negative": "0.005479323212057352",
   "Neutral": "0.9874541759490967",
   "Mixed": "3.51032467733603e-05"
 },
 {
   "tweet_id": "1589971498592530433",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971494939283456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971493953634304",
   "Positive": "0.994067907333374",
   "Negative": "0.001519724610261619",
   "Neutral": "0.0043427483178675175",
   "Mixed": "6.962469342397526e-05"
 },
 {
   "tweet_id": "1589971492259106817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971489549594626",
   "Positive": "0.02731490321457386",
   "Negative": "0.0045813946053385735",
   "Neutral": "0.9680793285369873",
   "Mixed": "2.43547710851999e-05"
 },
 {
   "tweet_id": "1589971482985521152",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1589971482092138497",
   "Positive": "0.917695939540863",
   "Negative": "0.00028212968027219176",
   "Neutral": "0.08162843436002731",
   "Mixed": "0.00039351757732219994"
 },
 {
   "tweet_id": "1589971480687022080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971478396559360",
   "Positive": "0.07848798483610153",
   "Negative": "0.00017891281459014863",
   "Neutral": "0.9213113188743591",
   "Mixed": "2.176542329834774e-05"
 },
 {
   "tweet_id": "1589971474466902016",
   "Positive": "0.028542038053274155",
   "Negative": "0.005927384365350008",
   "Neutral": "0.9655030369758606",
   "Mixed": "2.7520820367499255e-05"
 },
 {
   "tweet_id": "1589971468821368832",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971461242228737",
   "Positive": "0.028302887454628944",
   "Negative": "0.0054521579295396805",
   "Neutral": "0.9662176370620728",
   "Mixed": "2.7285090254736133e-05"
 },
 {
   "tweet_id": "1589971453323415554",
   "Positive": "0.4829047620296478",
   "Negative": "0.0014859064249321818",
   "Neutral": "0.5153494477272034",
   "Mixed": "0.00025988323614001274"
 },
 {
   "tweet_id": "1589971447560437760",
   "Positive": "0.11078359931707382",
   "Negative": "0.0010718230623751879",
   "Neutral": "0.8881286978721619",
   "Mixed": "1.5848972907406278e-05"
 },
 {
   "tweet_id": "1589971435132706817",
   "Positive": "0.029090646654367447",
   "Negative": "0.004900796338915825",
   "Neutral": "0.9659830927848816",
   "Mixed": "2.5496818125247955e-05"
 },
 {
   "tweet_id": "1589971422633693184",
   "Positive": "0.026790808886289597",
   "Negative": "0.00539429672062397",
   "Neutral": "0.9677886366844177",
   "Mixed": "2.6246962079312652e-05"
 },
 {
   "tweet_id": "1589971410646372358",
   "Positive": "0.04769400879740715",
   "Negative": "6.801034760428593e-05",
   "Neutral": "0.9517502784729004",
   "Mixed": "0.00048770493594929576"
 },
 {
   "tweet_id": "1589971406129094656",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589971402647834627",
   "Positive": "0.9495711326599121",
   "Negative": "0.002413290087133646",
   "Neutral": "0.047796979546546936",
   "Mixed": "0.0002186623605666682"
 },
 {
   "tweet_id": "1589971399535636482",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971384385822720",
   "Positive": "0.02831466868519783",
   "Negative": "0.005544588435441256",
   "Neutral": "0.9661127328872681",
   "Mixed": "2.7933314413530752e-05"
 },
 {
   "tweet_id": "1589971379893731330",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971370175508481",
   "Positive": "0.030948393046855927",
   "Negative": "0.0060517690144479275",
   "Neutral": "0.9629695415496826",
   "Mixed": "3.029768049600534e-05"
 },
 {
   "tweet_id": "1589971365112999937",
   "Positive": "0.011042432859539986",
   "Negative": "0.009030044078826904",
   "Neutral": "0.9799208045005798",
   "Mixed": "6.819651844125474e-06"
 },
 {
   "tweet_id": "1589971359521976321",
   "Positive": "0.0058998181484639645",
   "Negative": "0.005131223704665899",
   "Neutral": "0.9889594316482544",
   "Mixed": "9.591673006070778e-06"
 },
 {
   "tweet_id": "1589971359391965184",
   "Positive": "0.004397612530738115",
   "Negative": "0.6537593603134155",
   "Neutral": "0.34111908078193665",
   "Mixed": "0.0007239620317704976"
 },
 {
   "tweet_id": "1589971351389220865",
   "Positive": "0.0298929326236248",
   "Negative": "0.005953323561698198",
   "Neutral": "0.9641258120536804",
   "Mixed": "2.7891715944861062e-05"
 },
 {
   "tweet_id": "1589971351313735683",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971322905694208",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589971308787675137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971308603146245",
   "Positive": "0.029104728251695633",
   "Negative": "0.006454832386225462",
   "Neutral": "0.9644115567207336",
   "Mixed": "2.882067383325193e-05"
 },
 {
   "tweet_id": "1589971303263789057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971299493117954",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589971288386605057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971287090565120",
   "Positive": "0.0008786883554421365",
   "Negative": "0.807246208190918",
   "Neutral": "0.19177015125751495",
   "Mixed": "0.00010498414485482499"
 },
 {
   "tweet_id": "1589971281067528193",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971279532417024",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589971256912510977",
   "Positive": "0.48389315605163574",
   "Negative": "0.00034144753590226173",
   "Neutral": "0.5145359635353088",
   "Mixed": "0.0012294285697862506"
 },
 {
   "tweet_id": "1589971221407748097",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971216097742848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971210691305472",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589971203380621313",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589971200742395906",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971183608672258",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971158539304962",
   "Positive": "0.06237565353512764",
   "Negative": "0.0012293485924601555",
   "Neutral": "0.9331005811691284",
   "Mixed": "0.003294378286227584"
 },
 {
   "tweet_id": "1589971142491897856",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589971105791774722",
   "Positive": "0.13724856078624725",
   "Negative": "0.00020969158504158258",
   "Neutral": "0.8624894618988037",
   "Mixed": "5.222309846431017e-05"
 },
 {
   "tweet_id": "1589971071222304768",
   "Positive": "0.9937354922294617",
   "Negative": "0.0015348122688010335",
   "Neutral": "0.004660465754568577",
   "Mixed": "6.925941852387041e-05"
 },
 {
   "tweet_id": "1589971070207266817",
   "Positive": "0.0016965508693829179",
   "Negative": "6.586719246115535e-05",
   "Neutral": "0.9982320666313171",
   "Mixed": "5.584073733189143e-06"
 },
 {
   "tweet_id": "1589971066340143106",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971044806590464",
   "Positive": "0.9928970336914062",
   "Negative": "0.0017820983193814754",
   "Neutral": "0.0052451761439442635",
   "Mixed": "7.56873341742903e-05"
 },
 {
   "tweet_id": "1589971043925446656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971043472793602",
   "Positive": "0.021242106333374977",
   "Negative": "6.436432158807293e-05",
   "Neutral": "0.9785350561141968",
   "Mixed": "0.00015847828763071448"
 },
 {
   "tweet_id": "1589971043367600129",
   "Positive": "0.2717757225036621",
   "Negative": "0.10117483884096146",
   "Neutral": "0.626965343952179",
   "Mixed": "8.405347034567967e-05"
 },
 {
   "tweet_id": "1589971043359551488",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589971038804512768",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589971037546221569",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1589971036283768833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589971021108776960",
   "Positive": "0.9936508536338806",
   "Negative": "0.001637882087379694",
   "Neutral": "0.004640251863747835",
   "Mixed": "7.109866419341415e-05"
 },
 {
   "tweet_id": "1589971004797124608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589971003605946370",
   "Positive": "0.9934647679328918",
   "Negative": "0.0016926830867305398",
   "Neutral": "0.004767011385411024",
   "Mixed": "7.544644176959991e-05"
 },
 {
   "tweet_id": "1589970997427728385",
   "Positive": "0.8865751028060913",
   "Negative": "0.0003927826473955065",
   "Neutral": "0.11273673921823502",
   "Mixed": "0.0002953984949272126"
 },
 {
   "tweet_id": "1589970993820610560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970985805303808",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589970983431327748",
   "Positive": "0.018557121977210045",
   "Negative": "4.7831505071371794e-05",
   "Neutral": "0.9809850454330444",
   "Mixed": "0.0004099851939827204"
 },
 {
   "tweet_id": "1589970968260530176",
   "Positive": "0.9934751391410828",
   "Negative": "0.0016612049657851458",
   "Neutral": "0.004788625054061413",
   "Mixed": "7.496325997635722e-05"
 },
 {
   "tweet_id": "1589970965110611969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970953614028801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970949990125568",
   "Positive": "0.993424654006958",
   "Negative": "0.0017630354268476367",
   "Neutral": "0.004736837465316057",
   "Mixed": "7.544634718215093e-05"
 },
 {
   "tweet_id": "1589970946504683522",
   "Positive": "0.0017169393831864",
   "Negative": "0.7378062605857849",
   "Neutral": "0.2603421211242676",
   "Mixed": "0.000134617745061405"
 },
 {
   "tweet_id": "1589970937180717058",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970934336999426",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970932864790528",
   "Positive": "0.9932771325111389",
   "Negative": "0.0018196295714005828",
   "Neutral": "0.0048238541930913925",
   "Mixed": "7.937127520563081e-05"
 },
 {
   "tweet_id": "1589970931170304003",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970920516464640",
   "Positive": "0.046312686055898666",
   "Negative": "0.007548685651272535",
   "Neutral": "0.9459508657455444",
   "Mixed": "0.0001877304894151166"
 },
 {
   "tweet_id": "1589970918402465792",
   "Positive": "0.4625561833381653",
   "Negative": "0.0008612702367827296",
   "Neutral": "0.005457159131765366",
   "Mixed": "0.5311253666877747"
 },
 {
   "tweet_id": "1589970916704161797",
   "Positive": "0.9937013387680054",
   "Negative": "0.0015985348727554083",
   "Neutral": "0.004626875277608633",
   "Mixed": "7.330042717512697e-05"
 },
 {
   "tweet_id": "1589970910991503361",
   "Positive": "0.004902326036244631",
   "Negative": "0.004019385669380426",
   "Neutral": "0.9907429814338684",
   "Mixed": "0.0003352701314724982"
 },
 {
   "tweet_id": "1589970907954819078",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970905572470785",
   "Positive": "0.024714909493923187",
   "Negative": "0.019814342260360718",
   "Neutral": "0.9552330374717712",
   "Mixed": "0.00023780518677085638"
 },
 {
   "tweet_id": "1589970900111458304",
   "Positive": "0.9936347007751465",
   "Negative": "0.0017275214195251465",
   "Neutral": "0.004564862232655287",
   "Mixed": "7.293668750207871e-05"
 },
 {
   "tweet_id": "1589970898572161024",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970891366359044",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970877122478082",
   "Positive": "0.9938532114028931",
   "Negative": "0.001616599503904581",
   "Neutral": "0.004457706119865179",
   "Mixed": "7.248482143040746e-05"
 },
 {
   "tweet_id": "1589970855580557314",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589970854896881665",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970854347411463",
   "Positive": "0.9936155080795288",
   "Negative": "0.0016344754258170724",
   "Neutral": "0.004677427466958761",
   "Mixed": "7.25250065443106e-05"
 },
 {
   "tweet_id": "1589970849108725763",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970848802557952",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1589970848215334912",
   "Positive": "0.07506634294986725",
   "Negative": "0.034253064543008804",
   "Neutral": "0.8884449005126953",
   "Mixed": "0.0022356929257512093"
 },
 {
   "tweet_id": "1589970845853962240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970844847345665",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970834307055617",
   "Positive": "0.0015704930992797017",
   "Negative": "0.20833474397659302",
   "Neutral": "0.7899214029312134",
   "Mixed": "0.00017334679432678968"
 },
 {
   "tweet_id": "1589970833472389120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970833392697350",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970822076432385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970820415500290",
   "Positive": "0.9933156371116638",
   "Negative": "0.0017804903909564018",
   "Neutral": "0.004823912400752306",
   "Mixed": "7.99714180175215e-05"
 },
 {
   "tweet_id": "1589970815239737345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970808973430784",
   "Positive": "0.023739734664559364",
   "Negative": "0.0029700561426579952",
   "Neutral": "0.9732864499092102",
   "Mixed": "3.766434019780718e-06"
 },
 {
   "tweet_id": "1589970805550874624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970803780898816",
   "Positive": "0.018304405733942986",
   "Negative": "4.90363163407892e-05",
   "Neutral": "0.981351912021637",
   "Mixed": "0.0002947076573036611"
 },
 {
   "tweet_id": "1589970802979790848",
   "Positive": "0.9936989545822144",
   "Negative": "0.0016651969635859132",
   "Neutral": "0.004561564885079861",
   "Mixed": "7.427570380968973e-05"
 },
 {
   "tweet_id": "1589970802027671553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970800568041474",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589970778220826626",
   "Positive": "0.9939244389533997",
   "Negative": "0.001594807836227119",
   "Neutral": "0.004411208909004927",
   "Mixed": "6.950763781787828e-05"
 },
 {
   "tweet_id": "1589970763049709568",
   "Positive": "0.031331248581409454",
   "Negative": "0.29959747195243835",
   "Neutral": "0.5077734589576721",
   "Mixed": "0.16129788756370544"
 },
 {
   "tweet_id": "1589970761120636928",
   "Positive": "0.17152179777622223",
   "Negative": "0.006111299153417349",
   "Neutral": "0.822094738483429",
   "Mixed": "0.00027218463947065175"
 },
 {
   "tweet_id": "1589970761108058115",
   "Positive": "0.9934273958206177",
   "Negative": "0.0016916407039389014",
   "Neutral": "0.00480695953592658",
   "Mixed": "7.412761624436826e-05"
 },
 {
   "tweet_id": "1589970757748424705",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970753344397314",
   "Positive": "0.0058998181484639645",
   "Negative": "0.005131223704665899",
   "Neutral": "0.9889594316482544",
   "Mixed": "9.591673006070778e-06"
 },
 {
   "tweet_id": "1589970753205985283",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589970739700334592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970738253291523",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970737276014593",
   "Positive": "0.9938181042671204",
   "Negative": "0.0016014289576560259",
   "Neutral": "0.004503529518842697",
   "Mixed": "7.695245585637167e-05"
 },
 {
   "tweet_id": "1589970721908084736",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589970721404747783",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970719659941891",
   "Positive": "0.9931106567382812",
   "Negative": "0.0018603606149554253",
   "Neutral": "0.004948378540575504",
   "Mixed": "8.056755905272439e-05"
 },
 {
   "tweet_id": "1589970701880295430",
   "Positive": "0.9930711388587952",
   "Negative": "0.0017155195819213986",
   "Neutral": "0.005136523861438036",
   "Mixed": "7.68034442444332e-05"
 },
 {
   "tweet_id": "1589970700605231109",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970697610465281",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589970684612325376",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589970680912941056",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970680346312707",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970675871404035",
   "Positive": "0.993633508682251",
   "Negative": "0.001608866616152227",
   "Neutral": "0.0046858456917107105",
   "Mixed": "7.165846909629181e-05"
 },
 {
   "tweet_id": "1589970665658286085",
   "Positive": "0.005263263825327158",
   "Negative": "0.004700988531112671",
   "Neutral": "0.9900256395339966",
   "Mixed": "1.019497631205013e-05"
 },
 {
   "tweet_id": "1589970652668526592",
   "Positive": "0.9940885305404663",
   "Negative": "0.001610113075003028",
   "Neutral": "0.004229423124343157",
   "Mixed": "7.196775550255552e-05"
 },
 {
   "tweet_id": "1589970650231631873",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970647454978048",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589970635501211649",
   "Positive": "0.9938603639602661",
   "Negative": "0.001572375069372356",
   "Neutral": "0.004495593253523111",
   "Mixed": "7.158943481044844e-05"
 },
 {
   "tweet_id": "1589970634872086531",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970610809376769",
   "Positive": "0.9939471483230591",
   "Negative": "0.0017047474393621087",
   "Neutral": "0.004271581303328276",
   "Mixed": "7.64753931434825e-05"
 },
 {
   "tweet_id": "1589970590240473088",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970589183545348",
   "Positive": "0.9939162135124207",
   "Negative": "0.0016099890926852822",
   "Neutral": "0.004405946470797062",
   "Mixed": "6.793177453801036e-05"
 },
 {
   "tweet_id": "1589970573408743425",
   "Positive": "0.9938979744911194",
   "Negative": "0.001594707602635026",
   "Neutral": "0.004438103176653385",
   "Mixed": "6.914659024914727e-05"
 },
 {
   "tweet_id": "1589970571668131840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970571176992768",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589970571097702401",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970558791618560",
   "Positive": "0.799519419670105",
   "Negative": "0.0002177870919695124",
   "Neutral": "0.2001018524169922",
   "Mixed": "0.00016097514890134335"
 },
 {
   "tweet_id": "1589970555960426502",
   "Positive": "0.9940965175628662",
   "Negative": "0.0015432110521942377",
   "Neutral": "0.004287692718207836",
   "Mixed": "7.261570863192901e-05"
 },
 {
   "tweet_id": "1589970554684993536",
   "Positive": "0.063985176384449",
   "Negative": "0.0013340438017621636",
   "Neutral": "0.9346596002578735",
   "Mixed": "2.1202833522693254e-05"
 },
 {
   "tweet_id": "1589970551216672769",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970550822436872",
   "Positive": "0.8664299845695496",
   "Negative": "0.0005478408420458436",
   "Neutral": "0.1322503387928009",
   "Mixed": "0.0007718628039583564"
 },
 {
   "tweet_id": "1589970542580600832",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589970539015471106",
   "Positive": "0.9940515160560608",
   "Negative": "0.0014061547117307782",
   "Neutral": "0.0044725979678332806",
   "Mixed": "6.979459431022406e-05"
 },
 {
   "tweet_id": "1589970538872836097",
   "Positive": "0.00360693596303463",
   "Negative": "0.27621105313301086",
   "Neutral": "0.7200798988342285",
   "Mixed": "0.00010219241812592372"
 },
 {
   "tweet_id": "1589970529439846401",
   "Positive": "0.07058127224445343",
   "Negative": "0.6712172031402588",
   "Neutral": "0.2575266361236572",
   "Mixed": "0.0006749102030880749"
 },
 {
   "tweet_id": "1589970520917020672",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589970516785639424",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589970516391362561",
   "Positive": "0.9936478734016418",
   "Negative": "0.0016812057001516223",
   "Neutral": "0.004603817593306303",
   "Mixed": "6.708459841320291e-05"
 },
 {
   "tweet_id": "1589970515749265411",
   "Positive": "0.799519419670105",
   "Negative": "0.0002177870919695124",
   "Neutral": "0.2001018524169922",
   "Mixed": "0.00016097514890134335"
 },
 {
   "tweet_id": "1589970506618634240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970500092309505",
   "Positive": "0.9939490556716919",
   "Negative": "0.001515493611805141",
   "Neutral": "0.004464096389710903",
   "Mixed": "7.14385460014455e-05"
 },
 {
   "tweet_id": "1589970492076994561",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970491594641409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970489270710273",
   "Positive": "0.799519419670105",
   "Negative": "0.0002177870919695124",
   "Neutral": "0.2001018524169922",
   "Mixed": "0.00016097514890134335"
 },
 {
   "tweet_id": "1589970483789070336",
   "Positive": "0.9939994812011719",
   "Negative": "0.0016163232503458858",
   "Neutral": "0.004316775128245354",
   "Mixed": "6.739122909493744e-05"
 },
 {
   "tweet_id": "1589970481628995584",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970481079554048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970478365839361",
   "Positive": "0.3167784810066223",
   "Negative": "0.1343352347612381",
   "Neutral": "0.5487855076789856",
   "Mixed": "0.00010077616752823815"
 },
 {
   "tweet_id": "1589970478026076160",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589970465359286274",
   "Positive": "0.9941017031669617",
   "Negative": "0.001537021598778665",
   "Neutral": "0.0042933025397360325",
   "Mixed": "6.793577631469816e-05"
 },
 {
   "tweet_id": "1589970447055343616",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970426893307905",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589970426520047617",
   "Positive": "0.06438961625099182",
   "Negative": "0.00015228263509925455",
   "Neutral": "0.9352623224258423",
   "Mixed": "0.00019579564104788005"
 },
 {
   "tweet_id": "1589970425660203010",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1589970424917815296",
   "Positive": "0.011044538579881191",
   "Negative": "0.00017352536087855697",
   "Neutral": "0.9887744188308716",
   "Mixed": "7.539804755651858e-06"
 },
 {
   "tweet_id": "1589970424531931140",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970423789555713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970402591543297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970391841505284",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589970367908524032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970366839283713",
   "Positive": "0.2717757225036621",
   "Negative": "0.10117483884096146",
   "Neutral": "0.626965343952179",
   "Mixed": "8.405347034567967e-05"
 },
 {
   "tweet_id": "1589970362540118016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970355401396224",
   "Positive": "0.8840335011482239",
   "Negative": "0.00027919496642425656",
   "Neutral": "0.11546288430690765",
   "Mixed": "0.00022442953195422888"
 },
 {
   "tweet_id": "1589970333674897408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970329036017667",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589970328440406017",
   "Positive": "0.9933098554611206",
   "Negative": "0.0016433043638244271",
   "Neutral": "0.004977500066161156",
   "Mixed": "6.935802230145782e-05"
 },
 {
   "tweet_id": "1589970321591136256",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970312543993856",
   "Positive": "0.9931835532188416",
   "Negative": "0.0017774172592908144",
   "Neutral": "0.004964001942425966",
   "Mixed": "7.511340663768351e-05"
 },
 {
   "tweet_id": "1589970312250413058",
   "Positive": "0.8822691440582275",
   "Negative": "0.0006289531011134386",
   "Neutral": "0.11703517287969589",
   "Mixed": "6.670878065051511e-05"
 },
 {
   "tweet_id": "1589970296484016129",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970295691292673",
   "Positive": "0.9936656355857849",
   "Negative": "0.0016528313281014562",
   "Neutral": "0.0046091084368526936",
   "Mixed": "7.249166810652241e-05"
 },
 {
   "tweet_id": "1589970282067787778",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970278503022594",
   "Positive": "0.9936892986297607",
   "Negative": "0.001606037258170545",
   "Neutral": "0.004634045530110598",
   "Mixed": "7.061420183163136e-05"
 },
 {
   "tweet_id": "1589970261759381510",
   "Positive": "0.03403327614068985",
   "Negative": "0.00012271870218683034",
   "Neutral": "0.9658297300338745",
   "Mixed": "1.423900539521128e-05"
 },
 {
   "tweet_id": "1589970261683863554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970259271831553",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589970257888030726",
   "Positive": "0.9937270879745483",
   "Negative": "0.0015317396027967334",
   "Neutral": "0.004673444665968418",
   "Mixed": "6.776150985388085e-05"
 },
 {
   "tweet_id": "1589970255212052481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970253748269056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970248887078913",
   "Positive": "0.001935073989443481",
   "Negative": "0.8235013484954834",
   "Neutral": "0.17444083094596863",
   "Mixed": "0.00012272449384909123"
 },
 {
   "tweet_id": "1589970248647970817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970240364216320",
   "Positive": "0.99347984790802",
   "Negative": "0.0014544352889060974",
   "Neutral": "0.004998079501092434",
   "Mixed": "6.765670696040615e-05"
 },
 {
   "tweet_id": "1589970239554736128",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970237977690112",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970236794875904",
   "Positive": "0.0025304395239800215",
   "Negative": "9.925066842697561e-05",
   "Neutral": "0.9973658919334412",
   "Mixed": "4.390435151435668e-06"
 },
 {
   "tweet_id": "1589970235972784129",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970235360411648",
   "Positive": "0.015976430848240852",
   "Negative": "0.001981345470994711",
   "Neutral": "0.9820306301116943",
   "Mixed": "1.1585319043661002e-05"
 },
 {
   "tweet_id": "1589970234659987456",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589970221221449730",
   "Positive": "0.993668258190155",
   "Negative": "0.0016291766660287976",
   "Neutral": "0.004637610167264938",
   "Mixed": "6.48663699394092e-05"
 },
 {
   "tweet_id": "1589970219086536704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970214967738368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970210580488192",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970204033155072",
   "Positive": "0.9935566782951355",
   "Negative": "0.0015607483219355345",
   "Neutral": "0.0048145451582968235",
   "Mixed": "6.80821540299803e-05"
 },
 {
   "tweet_id": "1589970192821813249",
   "Positive": "0.001998960506170988",
   "Negative": "0.7744432091712952",
   "Neutral": "0.22348037362098694",
   "Mixed": "7.747976633254439e-05"
 },
 {
   "tweet_id": "1589970183648862208",
   "Positive": "0.9935398697853088",
   "Negative": "0.0016088492702692747",
   "Neutral": "0.0047860764898359776",
   "Mixed": "6.517122528748587e-05"
 },
 {
   "tweet_id": "1589970168322863105",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589970166817095681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970165642711040",
   "Positive": "0.9933571219444275",
   "Negative": "0.0017162745352834463",
   "Neutral": "0.004857266787439585",
   "Mixed": "6.933235272299498e-05"
 },
 {
   "tweet_id": "1589970165609168898",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970155450564610",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970150526451713",
   "Positive": "0.001998960506170988",
   "Negative": "0.7744432091712952",
   "Neutral": "0.22348037362098694",
   "Mixed": "7.747976633254439e-05"
 },
 {
   "tweet_id": "1589970150287364096",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970148525764608",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970146923544579",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970146181148673",
   "Positive": "0.9934074878692627",
   "Negative": "0.0015116655267775059",
   "Neutral": "0.005014922469854355",
   "Mixed": "6.592550198547542e-05"
 },
 {
   "tweet_id": "1589970143626481664",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589970142964088832",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589970142745616385",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589970140481064960",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589970135779246082",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970135141715970",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589970129982398464",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589970122739179523",
   "Positive": "0.01822824962437153",
   "Negative": "4.675587479141541e-05",
   "Neutral": "0.9815086722373962",
   "Mixed": "0.00021628709509968758"
 },
 {
   "tweet_id": "1589970120675569665",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589970116351234048",
   "Positive": "0.993366539478302",
   "Negative": "0.0018066582269966602",
   "Neutral": "0.004752575885504484",
   "Mixed": "7.412632839987054e-05"
 },
 {
   "tweet_id": "1589970113952120833",
   "Positive": "0.48199453949928284",
   "Negative": "0.0004872154095210135",
   "Neutral": "0.5159935355186462",
   "Mixed": "0.0015247641131281853"
 },
 {
   "tweet_id": "1589970111582343170",
   "Positive": "0.08377818763256073",
   "Negative": "0.002420324133709073",
   "Neutral": "0.9135153889656067",
   "Mixed": "0.00028602470410987735"
 },
 {
   "tweet_id": "1589970110415982595",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970104070332420",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970101138522112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970100681342977",
   "Positive": "0.005263263825327158",
   "Negative": "0.004700988531112671",
   "Neutral": "0.9900256395339966",
   "Mixed": "1.019497631205013e-05"
 },
 {
   "tweet_id": "1589970099943112704",
   "Positive": "0.9934612512588501",
   "Negative": "0.001655671512708068",
   "Neutral": "0.004811159800738096",
   "Mixed": "7.19164454494603e-05"
 },
 {
   "tweet_id": "1589970097166516225",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589970095501377537",
   "Positive": "0.9723837971687317",
   "Negative": "0.00020625136676244438",
   "Neutral": "0.027385199442505836",
   "Mixed": "2.4802604457363486e-05"
 },
 {
   "tweet_id": "1589970090711453696",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970079407800320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970066137026562",
   "Positive": "0.15059135854244232",
   "Negative": "0.061494115740060806",
   "Neutral": "0.7772237658500671",
   "Mixed": "0.010690822266042233"
 },
 {
   "tweet_id": "1589970062236319744",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970061842087936",
   "Positive": "0.9937644004821777",
   "Negative": "0.0016937621403485537",
   "Neutral": "0.004468733444809914",
   "Mixed": "7.309306965908036e-05"
 },
 {
   "tweet_id": "1589970058268139520",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589970057157046272",
   "Positive": "0.03044629469513893",
   "Negative": "0.34914258122444153",
   "Neutral": "0.3067307472229004",
   "Mixed": "0.31368038058280945"
 },
 {
   "tweet_id": "1589970044926455808",
   "Positive": "0.9932116270065308",
   "Negative": "0.0017633778043091297",
   "Neutral": "0.004952507559210062",
   "Mixed": "7.253889634739608e-05"
 },
 {
   "tweet_id": "1589970033135886342",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589970026790268929",
   "Positive": "0.9928579330444336",
   "Negative": "0.0019328354392200708",
   "Neutral": "0.005128874909132719",
   "Mixed": "8.040867396630347e-05"
 },
 {
   "tweet_id": "1589970012798070785",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589970008373088256",
   "Positive": "0.9930031895637512",
   "Negative": "0.0018773394403979182",
   "Neutral": "0.0050384607166051865",
   "Mixed": "8.103009167825803e-05"
 },
 {
   "tweet_id": "1589969999493763074",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969995920207872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969992766091267",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969989788139520",
   "Positive": "0.9932636022567749",
   "Negative": "0.0017851440934464335",
   "Neutral": "0.004873421974480152",
   "Mixed": "7.780549640301615e-05"
 },
 {
   "tweet_id": "1589969986801451008",
   "Positive": "0.9784142971038818",
   "Negative": "0.0050666797906160355",
   "Neutral": "0.016313228756189346",
   "Mixed": "0.0002057927631540224"
 },
 {
   "tweet_id": "1589969975401672704",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969970641113089",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589969966019014656",
   "Positive": "0.9893802404403687",
   "Negative": "0.00017222050519194454",
   "Neutral": "0.010434610769152641",
   "Mixed": "1.2982076441403478e-05"
 },
 {
   "tweet_id": "1589969962130903041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969958330851328",
   "Positive": "0.06857773661613464",
   "Negative": "0.03138843923807144",
   "Neutral": "0.9000030755996704",
   "Mixed": "3.083777482970618e-05"
 },
 {
   "tweet_id": "1589969955269017600",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589969950537814017",
   "Positive": "0.9931883811950684",
   "Negative": "0.0019001429900527",
   "Neutral": "0.0048262691125273705",
   "Mixed": "8.517855167156085e-05"
 },
 {
   "tweet_id": "1589969948981727232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969935568019456",
   "Positive": "0.0039313980378210545",
   "Negative": "0.005609325598925352",
   "Neutral": "0.9904481768608093",
   "Mixed": "1.1123674084956292e-05"
 },
 {
   "tweet_id": "1589969920691167232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969913598599171",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969907844022272",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589969894577442816",
   "Positive": "0.06744839996099472",
   "Negative": "0.0004791549581568688",
   "Neutral": "0.9320451021194458",
   "Mixed": "2.7360787498764694e-05"
 },
 {
   "tweet_id": "1589969893994418177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969887396761601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969887312900098",
   "Positive": "0.003590065287426114",
   "Negative": "0.0002882406406570226",
   "Neutral": "0.9961131811141968",
   "Mixed": "8.420553058385849e-06"
 },
 {
   "tweet_id": "1589969887149293569",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969886323015680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969882325843968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969880706875393",
   "Positive": "0.004176275338977575",
   "Negative": "0.01286139152944088",
   "Neutral": "0.9829515814781189",
   "Mixed": "1.0756531082734e-05"
 },
 {
   "tweet_id": "1589969873962422276",
   "Positive": "0.15519219636917114",
   "Negative": "0.0008000134839676321",
   "Neutral": "0.8439546823501587",
   "Mixed": "5.316575334290974e-05"
 },
 {
   "tweet_id": "1589969868178493441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969868027142146",
   "Positive": "0.13480475544929504",
   "Negative": "0.002237738110125065",
   "Neutral": "0.8629211783409119",
   "Mixed": "3.63463259418495e-05"
 },
 {
   "tweet_id": "1589969866894999554",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589969861480189954",
   "Positive": "0.060279443860054016",
   "Negative": "0.763227641582489",
   "Neutral": "0.08743413537740707",
   "Mixed": "0.08905883133411407"
 },
 {
   "tweet_id": "1589969859991187456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969852365930503",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589969848586891265",
   "Positive": "0.005978829693049192",
   "Negative": "4.352350879344158e-05",
   "Neutral": "0.9939181804656982",
   "Mixed": "5.946655073785223e-05"
 },
 {
   "tweet_id": "1589969841540468736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969839292317697",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969830404558850",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969825237184518",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969811215650822",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969802130784257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969793314361345",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969790356975616",
   "Positive": "0.06361142545938492",
   "Negative": "0.0016426541842520237",
   "Neutral": "0.9347074031829834",
   "Mixed": "3.851926157949492e-05"
 },
 {
   "tweet_id": "1589969788713193473",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969780488142848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969765103464450",
   "Positive": "0.9623917937278748",
   "Negative": "0.00010777956777019426",
   "Neutral": "0.03746675327420235",
   "Mixed": "3.369181649759412e-05"
 },
 {
   "tweet_id": "1589969761701892097",
   "Positive": "0.2717757225036621",
   "Negative": "0.10117483884096146",
   "Neutral": "0.626965343952179",
   "Mixed": "8.405347034567967e-05"
 },
 {
   "tweet_id": "1589969739316699141",
   "Positive": "0.29495495557785034",
   "Negative": "0.0022162278182804585",
   "Neutral": "0.6948609948158264",
   "Mixed": "0.007967778481543064"
 },
 {
   "tweet_id": "1589969738133901317",
   "Positive": "0.07849621027708054",
   "Negative": "0.00010932498844340444",
   "Neutral": "0.9213079810142517",
   "Mixed": "8.640428859507665e-05"
 },
 {
   "tweet_id": "1589969725949632512",
   "Positive": "0.8146640062332153",
   "Negative": "0.0029343259520828724",
   "Neutral": "0.17645074427127838",
   "Mixed": "0.005950964987277985"
 },
 {
   "tweet_id": "1589969725697970177",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589969703757574144",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969697659047936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969691808006144",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969689882812418",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969679426408448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969676788170754",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969671520145408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969671083954177",
   "Positive": "0.12372558563947678",
   "Negative": "0.04485809803009033",
   "Neutral": "0.822563648223877",
   "Mixed": "0.008852648548781872"
 },
 {
   "tweet_id": "1589969654327685121",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969641413414912",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589969637672124416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969632081104898",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969627899383808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969624573304832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969611860365312",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969609628622848",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589969605828939777",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589969602771316737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969602045362176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969587541790720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969582248587264",
   "Positive": "0.0003652356972452253",
   "Negative": "0.9871612191200256",
   "Neutral": "0.012461381033062935",
   "Mixed": "1.2131356015743222e-05"
 },
 {
   "tweet_id": "1589969568306728960",
   "Positive": "0.9649531841278076",
   "Negative": "0.00025887542869895697",
   "Neutral": "0.034696049988269806",
   "Mixed": "9.203019726555794e-05"
 },
 {
   "tweet_id": "1589969564594745344",
   "Positive": "0.009461387991905212",
   "Negative": "0.21827548742294312",
   "Neutral": "0.7722086906433105",
   "Mixed": "5.4498290410265326e-05"
 },
 {
   "tweet_id": "1589969549289750528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969544399163394",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969541056016384",
   "Positive": "0.22705267369747162",
   "Negative": "0.0003581563651096076",
   "Neutral": "0.7725579738616943",
   "Mixed": "3.1257353839464486e-05"
 },
 {
   "tweet_id": "1589969535926693890",
   "Positive": "0.08726707845926285",
   "Negative": "0.2580503821372986",
   "Neutral": "0.6528531908988953",
   "Mixed": "0.0018293338362127542"
 },
 {
   "tweet_id": "1589969517958295554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969503164981248",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969501218803712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969493530669056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969452896235521",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969440325521409",
   "Positive": "0.022574210539460182",
   "Negative": "5.9390578826423734e-05",
   "Neutral": "0.9772503972053528",
   "Mixed": "0.00011602511222008616"
 },
 {
   "tweet_id": "1589969440078442496",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969424043630593",
   "Positive": "0.3970320522785187",
   "Negative": "0.00047326087951660156",
   "Neutral": "0.6024476885795593",
   "Mixed": "4.705969331553206e-05"
 },
 {
   "tweet_id": "1589969422017363969",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1589969417810882563",
   "Positive": "0.03720325604081154",
   "Negative": "0.04077893868088722",
   "Neutral": "0.9204441905021667",
   "Mixed": "0.0015735382912680507"
 },
 {
   "tweet_id": "1589969414988128258",
   "Positive": "0.022708451375365257",
   "Negative": "6.068155562388711e-05",
   "Neutral": "0.9771225452423096",
   "Mixed": "0.00010837431909749284"
 },
 {
   "tweet_id": "1589969413478182914",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969407799103489",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969393966252032",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589969392246214656",
   "Positive": "0.02206387370824814",
   "Negative": "5.8553501730784774e-05",
   "Neutral": "0.9777647852897644",
   "Mixed": "0.00011276606528554112"
 },
 {
   "tweet_id": "1589969375410675712",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969373972008960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969370788560896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969369576398849",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589969369056301056",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969368481673218",
   "Positive": "0.023526914417743683",
   "Negative": "6.107835361035541e-05",
   "Neutral": "0.976280689239502",
   "Mixed": "0.00013132630556356162"
 },
 {
   "tweet_id": "1589969366619394050",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589969362739687427",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969352496803840",
   "Positive": "0.1278103142976761",
   "Negative": "0.0003289019805379212",
   "Neutral": "0.8717412948608398",
   "Mixed": "0.00011948322935495526"
 },
 {
   "tweet_id": "1589969345442369541",
   "Positive": "0.02202562242746353",
   "Negative": "5.8468001952860504e-05",
   "Neutral": "0.9777776002883911",
   "Mixed": "0.00013838773884344846"
 },
 {
   "tweet_id": "1589969336386846721",
   "Positive": "0.015475899912416935",
   "Negative": "5.022044570068829e-05",
   "Neutral": "0.9843946695327759",
   "Mixed": "7.922273653093725e-05"
 },
 {
   "tweet_id": "1589969335459934208",
   "Positive": "0.16376766562461853",
   "Negative": "0.0099731320515275",
   "Neutral": "0.8258651494979858",
   "Mixed": "0.00039401021786034107"
 },
 {
   "tweet_id": "1589969320733331457",
   "Positive": "0.024540407583117485",
   "Negative": "6.167026003822684e-05",
   "Neutral": "0.9752681255340576",
   "Mixed": "0.00012971549585927278"
 },
 {
   "tweet_id": "1589969318150049792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969315742507008",
   "Positive": "0.9186611175537109",
   "Negative": "0.0010096534388139844",
   "Neutral": "0.07986026257276535",
   "Mixed": "0.00046901690075173974"
 },
 {
   "tweet_id": "1589969314500997120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969308226301955",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589969300063854593",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969294238322690",
   "Positive": "0.2991355061531067",
   "Negative": "0.0003377182292751968",
   "Neutral": "0.6999643445014954",
   "Mixed": "0.0005624403129331768"
 },
 {
   "tweet_id": "1589969294028574721",
   "Positive": "0.00360693596303463",
   "Negative": "0.27621105313301086",
   "Neutral": "0.7200798988342285",
   "Mixed": "0.00010219241812592372"
 },
 {
   "tweet_id": "1589969294011826176",
   "Positive": "0.02383381314575672",
   "Negative": "6.04786655458156e-05",
   "Neutral": "0.9759697318077087",
   "Mixed": "0.00013600278180092573"
 },
 {
   "tweet_id": "1589969291142926336",
   "Positive": "0.9596382975578308",
   "Negative": "0.0005687452503480017",
   "Neutral": "0.03971494734287262",
   "Mixed": "7.79625479481183e-05"
 },
 {
   "tweet_id": "1589969284755001344",
   "Positive": "0.1278103142976761",
   "Negative": "0.0003289019805379212",
   "Neutral": "0.8717412948608398",
   "Mixed": "0.00011948322935495526"
 },
 {
   "tweet_id": "1589969274831241219",
   "Positive": "0.005795604549348354",
   "Negative": "0.23051267862319946",
   "Neutral": "0.7634536027908325",
   "Mixed": "0.0002381306403549388"
 },
 {
   "tweet_id": "1589969271685517312",
   "Positive": "0.023270519450306892",
   "Negative": "6.0819333157269284e-05",
   "Neutral": "0.97654128074646",
   "Mixed": "0.00012734833580907434"
 },
 {
   "tweet_id": "1589969271165431808",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589969259278794752",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969253570347009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969252190400516",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969249652846594",
   "Positive": "0.024615895003080368",
   "Negative": "6.157111056381837e-05",
   "Neutral": "0.9751944541931152",
   "Mixed": "0.0001281304139411077"
 },
 {
   "tweet_id": "1589969235492864002",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589969230107410433",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969228056375296",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589969223555899393",
   "Positive": "0.024628683924674988",
   "Negative": "6.283567199716344e-05",
   "Neutral": "0.9751801490783691",
   "Mixed": "0.00012833104119636118"
 },
 {
   "tweet_id": "1589969214886273024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969212952707072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969210717138945",
   "Positive": "0.019160421565175056",
   "Negative": "4.891834032605402e-05",
   "Neutral": "0.9805026650428772",
   "Mixed": "0.0002879714302252978"
 },
 {
   "tweet_id": "1589969208699662336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969206950625280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969206686404619",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969201200259072",
   "Positive": "0.02424582839012146",
   "Negative": "5.919885734329e-05",
   "Neutral": "0.9755478501319885",
   "Mixed": "0.00014708236267324537"
 },
 {
   "tweet_id": "1589969180425875460",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969179104645120",
   "Positive": "0.023696832358837128",
   "Negative": "6.107720400905237e-05",
   "Neutral": "0.9760994911193848",
   "Mixed": "0.0001425012160325423"
 },
 {
   "tweet_id": "1589969172683165698",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969171173249024",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589969155465232389",
   "Positive": "0.023033764213323593",
   "Negative": "6.0315458540571854e-05",
   "Neutral": "0.9767672419548035",
   "Mixed": "0.00013871317787561566"
 },
 {
   "tweet_id": "1589969155167784962",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589969147882274816",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589969140261212161",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969138851741697",
   "Positive": "0.04122396185994148",
   "Negative": "0.00012747205619234592",
   "Neutral": "0.9586293697357178",
   "Mixed": "1.9192195395589806e-05"
 },
 {
   "tweet_id": "1589969137484599296",
   "Positive": "0.05752812325954437",
   "Negative": "0.0006409278721548617",
   "Neutral": "0.9418043494224548",
   "Mixed": "2.6605526727507822e-05"
 },
 {
   "tweet_id": "1589969136104673281",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969130228449281",
   "Positive": "0.10024750232696533",
   "Negative": "0.002638774225488305",
   "Neutral": "0.8970026969909668",
   "Mixed": "0.00011101546260761097"
 },
 {
   "tweet_id": "1589969130140340224",
   "Positive": "0.02319917269051075",
   "Negative": "5.912229244131595e-05",
   "Neutral": "0.9766061305999756",
   "Mixed": "0.00013564806431531906"
 },
 {
   "tweet_id": "1589969129414725633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969128244539399",
   "Positive": "0.02028529718518257",
   "Negative": "5.195883568376303e-05",
   "Neutral": "0.9794872403144836",
   "Mixed": "0.00017553174984641373"
 },
 {
   "tweet_id": "1589969106568351745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969101824614400",
   "Positive": "0.02136155776679516",
   "Negative": "5.769914059783332e-05",
   "Neutral": "0.9784414172172546",
   "Mixed": "0.00013922576908953488"
 },
 {
   "tweet_id": "1589969092085428224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969086767075330",
   "Positive": "0.9933098554611206",
   "Negative": "0.0016433043638244271",
   "Neutral": "0.004977500066161156",
   "Mixed": "6.935802230145782e-05"
 },
 {
   "tweet_id": "1589969082040061955",
   "Positive": "0.016029417514801025",
   "Negative": "4.6510151150869206e-05",
   "Neutral": "0.9837734699249268",
   "Mixed": "0.00015056361735332757"
 },
 {
   "tweet_id": "1589969074003800065",
   "Positive": "0.022865375503897667",
   "Negative": "6.065690831746906e-05",
   "Neutral": "0.9769482612609863",
   "Mixed": "0.0001258071861229837"
 },
 {
   "tweet_id": "1589969069884993536",
   "Positive": "0.9931835532188416",
   "Negative": "0.0017774172592908144",
   "Neutral": "0.004964001942425966",
   "Mixed": "7.511340663768351e-05"
 },
 {
   "tweet_id": "1589969069838864394",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589969060208709634",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589969053418164225",
   "Positive": "0.9936656355857849",
   "Negative": "0.0016528313281014562",
   "Neutral": "0.0046091084368526936",
   "Mixed": "7.249166810652241e-05"
 },
 {
   "tweet_id": "1589969049735540737",
   "Positive": "0.0026412643492221832",
   "Negative": "0.15754002332687378",
   "Neutral": "0.8398024439811707",
   "Mixed": "1.6247211533482186e-05"
 },
 {
   "tweet_id": "1589969049022529541",
   "Positive": "0.021887199953198433",
   "Negative": "6.073790791560896e-05",
   "Neutral": "0.9779422879219055",
   "Mixed": "0.00010976838530041277"
 },
 {
   "tweet_id": "1589969038146691072",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589969036703854593",
   "Positive": "0.9936892986297607",
   "Negative": "0.001606037258170545",
   "Neutral": "0.004634045530110598",
   "Mixed": "7.061420183163136e-05"
 },
 {
   "tweet_id": "1589969028390744064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969027258253312",
   "Positive": "0.021431421861052513",
   "Negative": "5.929043982177973e-05",
   "Neutral": "0.9783959984779358",
   "Mixed": "0.00011328503751428798"
 },
 {
   "tweet_id": "1589969026822082560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969025228234752",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969024318066688",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589969016646692866",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589969014352392192",
   "Positive": "0.9937270879745483",
   "Negative": "0.0015317396027967334",
   "Neutral": "0.004673444665968418",
   "Mixed": "6.776150985388085e-05"
 },
 {
   "tweet_id": "1589969003765985281",
   "Positive": "0.023182453587651253",
   "Negative": "6.0739675973309204e-05",
   "Neutral": "0.9766415953636169",
   "Mixed": "0.00011521628039190546"
 },
 {
   "tweet_id": "1589968995499003909",
   "Positive": "0.99347984790802",
   "Negative": "0.0014544352889060974",
   "Neutral": "0.004998079501092434",
   "Mixed": "6.765670696040615e-05"
 },
 {
   "tweet_id": "1589968994110697472",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968993267638272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968992755933185",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968988536442881",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589968979220889601",
   "Positive": "0.993668258190155",
   "Negative": "0.0016291766660287976",
   "Neutral": "0.004637610167264938",
   "Mixed": "6.48663699394092e-05"
 },
 {
   "tweet_id": "1589968977375432704",
   "Positive": "0.0238121896982193",
   "Negative": "5.9814381529577076e-05",
   "Neutral": "0.9760022759437561",
   "Mixed": "0.00012559826427605003"
 },
 {
   "tweet_id": "1589968963647475712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968961235734528",
   "Positive": "0.9935566782951355",
   "Negative": "0.0015607483219355345",
   "Neutral": "0.0048145451582968235",
   "Mixed": "6.80821540299803e-05"
 },
 {
   "tweet_id": "1589968956504543232",
   "Positive": "0.2717757225036621",
   "Negative": "0.10117483884096146",
   "Neutral": "0.626965343952179",
   "Mixed": "8.405347034567967e-05"
 },
 {
   "tweet_id": "1589968949525217282",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968947176407041",
   "Positive": "0.012016824446618557",
   "Negative": "0.49770596623420715",
   "Neutral": "0.49017590284347534",
   "Mixed": "0.00010131378803635016"
 },
 {
   "tweet_id": "1589968946916384769",
   "Positive": "0.02727837860584259",
   "Negative": "6.334677891572937e-05",
   "Neutral": "0.9724922180175781",
   "Mixed": "0.00016595488705206662"
 },
 {
   "tweet_id": "1589968939983200256",
   "Positive": "0.9935398697853088",
   "Negative": "0.0016088492702692747",
   "Neutral": "0.0047860764898359776",
   "Mixed": "6.517122528748587e-05"
 },
 {
   "tweet_id": "1589968938418728967",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968923247923200",
   "Positive": "0.9933571219444275",
   "Negative": "0.0017162745352834463",
   "Neutral": "0.004857266787439585",
   "Mixed": "6.933235272299498e-05"
 },
 {
   "tweet_id": "1589968922044174339",
   "Positive": "0.025427868589758873",
   "Negative": "5.913249697186984e-05",
   "Neutral": "0.9743576049804688",
   "Mixed": "0.00015539598825853318"
 },
 {
   "tweet_id": "1589968909809364992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968906860793857",
   "Positive": "0.9934074878692627",
   "Negative": "0.0015116655267775059",
   "Neutral": "0.005014922469854355",
   "Mixed": "6.592550198547542e-05"
 },
 {
   "tweet_id": "1589968902603558914",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589968899164221441",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589968898304413697",
   "Positive": "0.024109680205583572",
   "Negative": "0.7104238271713257",
   "Neutral": "0.20891840755939484",
   "Mixed": "0.05654806271195412"
 },
 {
   "tweet_id": "1589968898174382081",
   "Positive": "0.02437753975391388",
   "Negative": "5.676315413438715e-05",
   "Neutral": "0.9754122495651245",
   "Mixed": "0.00015338246885221452"
 },
 {
   "tweet_id": "1589968892105220103",
   "Positive": "0.4546477496623993",
   "Negative": "0.0002959025732707232",
   "Neutral": "0.5411638021469116",
   "Mixed": "0.0038925795815885067"
 },
 {
   "tweet_id": "1589968887075856384",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968886413553666",
   "Positive": "0.993366539478302",
   "Negative": "0.0018066582269966602",
   "Neutral": "0.004752575885504484",
   "Mixed": "7.412632839987054e-05"
 },
 {
   "tweet_id": "1589968884496760833",
   "Positive": "0.13365423679351807",
   "Negative": "0.00018603462376631796",
   "Neutral": "0.8660874962806702",
   "Mixed": "7.231243216665462e-05"
 },
 {
   "tweet_id": "1589968876632432640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968875663171584",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1589968873024954368",
   "Positive": "0.025904009118676186",
   "Negative": "6.133180431788787e-05",
   "Neutral": "0.9738715291023254",
   "Mixed": "0.00016306726320181042"
 },
 {
   "tweet_id": "1589968866691928064",
   "Positive": "0.9934612512588501",
   "Negative": "0.001655671512708068",
   "Neutral": "0.004811159800738096",
   "Mixed": "7.19164454494603e-05"
 },
 {
   "tweet_id": "1589968862220812291",
   "Positive": "0.15030132234096527",
   "Negative": "0.005060288123786449",
   "Neutral": "0.8445923924446106",
   "Mixed": "4.5998494897503406e-05"
 },
 {
   "tweet_id": "1589968854985232384",
   "Positive": "0.006319054402410984",
   "Negative": "0.566302478313446",
   "Neutral": "0.40093719959259033",
   "Mixed": "0.026441263034939766"
 },
 {
   "tweet_id": "1589968850980073478",
   "Positive": "0.005826523527503014",
   "Negative": "0.8218479156494141",
   "Neutral": "0.12494493275880814",
   "Mixed": "0.047380734235048294"
 },
 {
   "tweet_id": "1589968844332072960",
   "Positive": "0.9937644004821777",
   "Negative": "0.0016937621403485537",
   "Neutral": "0.004468733444809914",
   "Mixed": "7.309306965908036e-05"
 },
 {
   "tweet_id": "1589968843677798400",
   "Positive": "0.11196224391460419",
   "Negative": "0.378093421459198",
   "Neutral": "0.5071284174919128",
   "Mixed": "0.002815946703776717"
 },
 {
   "tweet_id": "1589968840917934082",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589968819606671360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968817907970048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968810224021506",
   "Positive": "0.9932116270065308",
   "Negative": "0.0017633778043091297",
   "Neutral": "0.004952507559210062",
   "Mixed": "7.253889634739608e-05"
 },
 {
   "tweet_id": "1589968809158643713",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968799838916613",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589968796055638016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968790582087681",
   "Positive": "0.9928579330444336",
   "Negative": "0.0019328354392200708",
   "Neutral": "0.005128874909132719",
   "Mixed": "8.040867396630347e-05"
 },
 {
   "tweet_id": "1589968789709672448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968789646737408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968778393440256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968776581505024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968774853451776",
   "Positive": "0.9930031895637512",
   "Negative": "0.0018773394403979182",
   "Neutral": "0.0050384607166051865",
   "Mixed": "8.103009167825803e-05"
 },
 {
   "tweet_id": "1589968773628702722",
   "Positive": "0.7428883910179138",
   "Negative": "0.0006479154690168798",
   "Neutral": "0.2563895583152771",
   "Mixed": "7.415829895762727e-05"
 },
 {
   "tweet_id": "1589968771653193731",
   "Positive": "0.0006235127220861614",
   "Negative": "0.00035482837120071054",
   "Neutral": "0.9990123510360718",
   "Mixed": "9.239700375474058e-06"
 },
 {
   "tweet_id": "1589968765047173122",
   "Positive": "0.3752214312553406",
   "Negative": "0.0028855460695922375",
   "Neutral": "0.6218690872192383",
   "Mixed": "2.3964579668245278e-05"
 },
 {
   "tweet_id": "1589968755748397056",
   "Positive": "0.9932636022567749",
   "Negative": "0.0017851440934464335",
   "Neutral": "0.004873421974480152",
   "Mixed": "7.780549640301615e-05"
 },
 {
   "tweet_id": "1589968736106459139",
   "Positive": "0.0224164966493845",
   "Negative": "5.411512029240839e-05",
   "Neutral": "0.9773439764976501",
   "Mixed": "0.00018535499111749232"
 },
 {
   "tweet_id": "1589968731043958787",
   "Positive": "0.993319034576416",
   "Negative": "0.0012979380553588271",
   "Neutral": "0.0050420076586306095",
   "Mixed": "0.00034108353429473937"
 },
 {
   "tweet_id": "1589968723691319296",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589968712748400640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968712588984321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968709921439744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968709783027714",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968709367758848",
   "Positive": "0.024689018726348877",
   "Negative": "5.898469316889532e-05",
   "Neutral": "0.9750880002975464",
   "Mixed": "0.00016401974426116794"
 },
 {
   "tweet_id": "1589968706482098183",
   "Positive": "0.025158246979117393",
   "Negative": "6.500083691207692e-05",
   "Neutral": "0.9746156930923462",
   "Mixed": "0.00016106881957966834"
 },
 {
   "tweet_id": "1589968702677843968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968698936524800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968693702037504",
   "Positive": "0.9933775067329407",
   "Negative": "0.0017999134724959731",
   "Neutral": "0.004748818464577198",
   "Mixed": "7.383603951893747e-05"
 },
 {
   "tweet_id": "1589968685828960256",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589968685124685824",
   "Positive": "0.02222631126642227",
   "Negative": "5.9382673498475924e-05",
   "Neutral": "0.9776050448417664",
   "Mixed": "0.00010926750837825239"
 },
 {
   "tweet_id": "1589968683950276615",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968681987354625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968681773469698",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968676497018881",
   "Positive": "0.22442875802516937",
   "Negative": "0.003317734692245722",
   "Neutral": "0.7722035646438599",
   "Mixed": "4.99055786349345e-05"
 },
 {
   "tweet_id": "1589968675398090754",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968665516003329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968658612498432",
   "Positive": "0.023371541872620583",
   "Negative": "5.937873720540665e-05",
   "Neutral": "0.9764453768730164",
   "Mixed": "0.0001237471733475104"
 },
 {
   "tweet_id": "1589968652925014017",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968648814596097",
   "Positive": "0.9154927730560303",
   "Negative": "0.0003834178496617824",
   "Neutral": "0.08409284055233002",
   "Mixed": "3.1013027182780206e-05"
 },
 {
   "tweet_id": "1589968636793749507",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589968635082440704",
   "Positive": "0.3228782117366791",
   "Negative": "0.0025603328831493855",
   "Neutral": "0.6745151281356812",
   "Mixed": "4.634888318832964e-05"
 },
 {
   "tweet_id": "1589968634868535297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968629780869121",
   "Positive": "0.023778431117534637",
   "Negative": "6.02009313297458e-05",
   "Neutral": "0.9760363698005676",
   "Mixed": "0.00012501122546382248"
 },
 {
   "tweet_id": "1589968622315003906",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589968606397202432",
   "Positive": "0.024340391159057617",
   "Negative": "6.17538025835529e-05",
   "Neutral": "0.97548907995224",
   "Mixed": "0.00010883310460485518"
 },
 {
   "tweet_id": "1589968601200885760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968600064208897",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589968597866381312",
   "Positive": "0.004848349839448929",
   "Negative": "0.3782505691051483",
   "Neutral": "0.6167675852775574",
   "Mixed": "0.00013349432265385985"
 },
 {
   "tweet_id": "1589968592690638849",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968591990173696",
   "Positive": "0.7743092775344849",
   "Negative": "0.00047710014041513205",
   "Neutral": "0.22504070401191711",
   "Mixed": "0.0001729614014038816"
 },
 {
   "tweet_id": "1589968590673182721",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968581948997632",
   "Positive": "0.02293304353952408",
   "Negative": "5.941329800407402e-05",
   "Neutral": "0.9768683910369873",
   "Mixed": "0.00013913404836785048"
 },
 {
   "tweet_id": "1589968574499926016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968570049785858",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968569353523200",
   "Positive": "0.01626943238079548",
   "Negative": "4.903932131128386e-05",
   "Neutral": "0.9835914373397827",
   "Mixed": "9.007633343571797e-05"
 },
 {
   "tweet_id": "1589968558850994176",
   "Positive": "0.022297129034996033",
   "Negative": "5.762173168477602e-05",
   "Neutral": "0.9775117635726929",
   "Mixed": "0.0001335019915131852"
 },
 {
   "tweet_id": "1589968553801060353",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968548700762113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968548063219712",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589968537774587904",
   "Positive": "0.0227570328861475",
   "Negative": "5.780017454526387e-05",
   "Neutral": "0.9770469069480896",
   "Mixed": "0.00013828539522364736"
 },
 {
   "tweet_id": "1589968530627493888",
   "Positive": "0.020873315632343292",
   "Negative": "7.127747085178271e-05",
   "Neutral": "0.9789025187492371",
   "Mixed": "0.00015289749717339873"
 },
 {
   "tweet_id": "1589968529104998401",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589968528907862016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968513447657473",
   "Positive": "0.02461148239672184",
   "Negative": "6.258420762605965e-05",
   "Neutral": "0.9752019047737122",
   "Mixed": "0.00012401390995364636"
 },
 {
   "tweet_id": "1589968508833890311",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968501217062913",
   "Positive": "0.022591333836317062",
   "Negative": "4.895108213531785e-05",
   "Neutral": "0.9770066142082214",
   "Mixed": "0.0003531028050929308"
 },
 {
   "tweet_id": "1589968497018253313",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968490144100353",
   "Positive": "0.02293093502521515",
   "Negative": "5.33715974597726e-05",
   "Neutral": "0.976823091506958",
   "Mixed": "0.00019260823319200426"
 },
 {
   "tweet_id": "1589968485320339459",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589968477632491520",
   "Positive": "0.02373952977359295",
   "Negative": "5.757715189247392e-05",
   "Neutral": "0.9758440256118774",
   "Mixed": "0.0003589071857277304"
 },
 {
   "tweet_id": "1589968471190011905",
   "Positive": "0.8894274234771729",
   "Negative": "0.0005655256099998951",
   "Neutral": "0.10992691665887833",
   "Mixed": "8.016682841116562e-05"
 },
 {
   "tweet_id": "1589968470858686464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968467960418305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968466492018688",
   "Positive": "0.022179974243044853",
   "Negative": "5.3435840527527034e-05",
   "Neutral": "0.9775531888008118",
   "Mixed": "0.00021331978496164083"
 },
 {
   "tweet_id": "1589968460649746433",
   "Positive": "0.0030401479452848434",
   "Negative": "0.0029425518587231636",
   "Neutral": "0.9940081834793091",
   "Mixed": "9.133068488154095e-06"
 },
 {
   "tweet_id": "1589968460356149249",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968454962286594",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589968444338081792",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968442538733569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968440043130882",
   "Positive": "0.023747628554701805",
   "Negative": "5.849790250067599e-05",
   "Neutral": "0.9760596752166748",
   "Mixed": "0.0001342341856798157"
 },
 {
   "tweet_id": "1589968439422382081",
   "Positive": "0.03445930406451225",
   "Negative": "5.11491707584355e-05",
   "Neutral": "0.9651651978492737",
   "Mixed": "0.000324303429806605"
 },
 {
   "tweet_id": "1589968429364432897",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589968428139679744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968424960405509",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968421219098625",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968411953868802",
   "Positive": "0.02350962720811367",
   "Negative": "5.944077929598279e-05",
   "Neutral": "0.9762964844703674",
   "Mixed": "0.00013444587239064276"
 },
 {
   "tweet_id": "1589968411018526720",
   "Positive": "0.04101552441716194",
   "Negative": "8.047142182476819e-05",
   "Neutral": "0.9587411284446716",
   "Mixed": "0.00016284504090435803"
 },
 {
   "tweet_id": "1589968405360431104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968395935440896",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589968388843270145",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968387224268800",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968384342786049",
   "Positive": "0.020876185968518257",
   "Negative": "4.709999848273583e-05",
   "Neutral": "0.9788606762886047",
   "Mixed": "0.00021604739595204592"
 },
 {
   "tweet_id": "1589968383118036992",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589968381331267584",
   "Positive": "0.023222029209136963",
   "Negative": "6.061528983991593e-05",
   "Neutral": "0.9765881299972534",
   "Mixed": "0.00012921942106913775"
 },
 {
   "tweet_id": "1589968373705998336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968372217044992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968362175504384",
   "Positive": "0.4337169826030731",
   "Negative": "0.005202040076255798",
   "Neutral": "0.5610392093658447",
   "Mixed": "4.182901466265321e-05"
 },
 {
   "tweet_id": "1589968359265042433",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968356416708609",
   "Positive": "0.022351181134581566",
   "Negative": "6.046098860679194e-05",
   "Neutral": "0.9774764180183411",
   "Mixed": "0.0001118403670261614"
 },
 {
   "tweet_id": "1589968353502035968",
   "Positive": "0.018153848126530647",
   "Negative": "4.9397141992812976e-05",
   "Neutral": "0.9816603660583496",
   "Mixed": "0.0001362810580758378"
 },
 {
   "tweet_id": "1589968353103601665",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589968347248357376",
   "Positive": "0.03208009526133537",
   "Negative": "0.004003859590739012",
   "Neutral": "0.9638839960098267",
   "Mixed": "3.204636595910415e-05"
 },
 {
   "tweet_id": "1589968341292449792",
   "Positive": "0.5997135043144226",
   "Negative": "0.001079616486094892",
   "Neutral": "0.3991076350212097",
   "Mixed": "9.924655023496598e-05"
 },
 {
   "tweet_id": "1589968336632221696",
   "Positive": "0.11863865703344345",
   "Negative": "0.0019278406398370862",
   "Neutral": "0.8794206380844116",
   "Mixed": "1.2928425348945893e-05"
 },
 {
   "tweet_id": "1589968330643083266",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968326352334848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968324632674305",
   "Positive": "0.04305232688784599",
   "Negative": "0.0006003900780342519",
   "Neutral": "0.9563330411911011",
   "Mixed": "1.4268893210100941e-05"
 },
 {
   "tweet_id": "1589968322996899841",
   "Positive": "0.033842943608760834",
   "Negative": "0.004722625948488712",
   "Neutral": "0.9614028334617615",
   "Mixed": "3.165493762935512e-05"
 },
 {
   "tweet_id": "1589968316931903488",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968316646686720",
   "Positive": "0.5240795612335205",
   "Negative": "0.0014215388800948858",
   "Neutral": "0.4744049608707428",
   "Mixed": "9.387938189320266e-05"
 },
 {
   "tweet_id": "1589968315908517888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968302562242560",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589968294345601029",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968292563013633",
   "Positive": "0.16408762335777283",
   "Negative": "0.002012318931519985",
   "Neutral": "0.8337904214859009",
   "Mixed": "0.00010964023385895416"
 },
 {
   "tweet_id": "1589968290486845442",
   "Positive": "0.08035609871149063",
   "Negative": "0.0005769097479060292",
   "Neutral": "0.9190362095832825",
   "Mixed": "3.07813570543658e-05"
 },
 {
   "tweet_id": "1589968289853509633",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968289756721154",
   "Positive": "0.022965097799897194",
   "Negative": "0.003440514672547579",
   "Neutral": "0.9735805988311768",
   "Mixed": "1.3785282135359012e-05"
 },
 {
   "tweet_id": "1589968281313587201",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1589968276456886272",
   "Positive": "0.05598624423146248",
   "Negative": "0.00015143761993385851",
   "Neutral": "0.9436403512954712",
   "Mixed": "0.00022196800273377448"
 },
 {
   "tweet_id": "1589968262661799936",
   "Positive": "0.03472160920500755",
   "Negative": "9.971272083930671e-05",
   "Neutral": "0.9651456475257874",
   "Mixed": "3.3059892302844673e-05"
 },
 {
   "tweet_id": "1589968256684929025",
   "Positive": "0.022991791367530823",
   "Negative": "5.8743062254507095e-05",
   "Neutral": "0.9767705202102661",
   "Mixed": "0.00017892000323627144"
 },
 {
   "tweet_id": "1589968248635678720",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589968248053071873",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589968243653238785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968229559980033",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589968229224833025",
   "Positive": "0.035843390971422195",
   "Negative": "0.0036087515763938427",
   "Neutral": "0.9605160355567932",
   "Mixed": "3.179563282174058e-05"
 },
 {
   "tweet_id": "1589968210132336643",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589968199537545218",
   "Positive": "0.16408762335777283",
   "Negative": "0.002012318931519985",
   "Neutral": "0.8337904214859009",
   "Mixed": "0.00010964023385895416"
 },
 {
   "tweet_id": "1589968198803546114",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589968193090916353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968184521945088",
   "Positive": "0.3880705237388611",
   "Negative": "0.0032272946555167437",
   "Neutral": "0.6085397601127625",
   "Mixed": "0.0001624376600375399"
 },
 {
   "tweet_id": "1589968181908869120",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589968178368884738",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968175432880129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968175030235137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968168776519682",
   "Positive": "0.8665363192558289",
   "Negative": "0.0004424131475389004",
   "Neutral": "0.13293996453285217",
   "Mixed": "8.131865615723655e-05"
 },
 {
   "tweet_id": "1589968154033524744",
   "Positive": "0.022574210539460182",
   "Negative": "5.9390578826423734e-05",
   "Neutral": "0.9772503972053528",
   "Mixed": "0.00011602511222008616"
 },
 {
   "tweet_id": "1589968152259342337",
   "Positive": "0.019411619752645493",
   "Negative": "5.480101754073985e-05",
   "Neutral": "0.9804021716117859",
   "Mixed": "0.00013143461546860635"
 },
 {
   "tweet_id": "1589968146802569221",
   "Positive": "0.8445008993148804",
   "Negative": "0.00042416510405018926",
   "Neutral": "0.1550062596797943",
   "Mixed": "6.87669962644577e-05"
 },
 {
   "tweet_id": "1589968132047015939",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589968130889375744",
   "Positive": "0.022708451375365257",
   "Negative": "6.068155562388711e-05",
   "Neutral": "0.9771225452423096",
   "Mixed": "0.00010837431909749284"
 },
 {
   "tweet_id": "1589968113906647041",
   "Positive": "0.028591010719537735",
   "Negative": "5.798667189083062e-05",
   "Neutral": "0.9710978269577026",
   "Mixed": "0.00025312008801847696"
 },
 {
   "tweet_id": "1589968112283439105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968106549825542",
   "Positive": "0.02206387370824814",
   "Negative": "5.8553501730784774e-05",
   "Neutral": "0.9777647852897644",
   "Mixed": "0.00011276606528554112"
 },
 {
   "tweet_id": "1589968105739939840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968096810655745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968096298926082",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589968083225284609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968083195932673",
   "Positive": "0.02817045897245407",
   "Negative": "5.879419040866196e-05",
   "Neutral": "0.9715198874473572",
   "Mixed": "0.0002507998142391443"
 },
 {
   "tweet_id": "1589968082122207232",
   "Positive": "0.023526914417743683",
   "Negative": "6.107835361035541e-05",
   "Neutral": "0.976280689239502",
   "Mixed": "0.00013132630556356162"
 },
 {
   "tweet_id": "1589968081346236417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968081165881345",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1589968072776904705",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968059401281537",
   "Positive": "0.02202562242746353",
   "Negative": "5.8468001952860504e-05",
   "Neutral": "0.9777776002883911",
   "Mixed": "0.00013838773884344846"
 },
 {
   "tweet_id": "1589968057451282434",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589968042443743233",
   "Positive": "0.8113875985145569",
   "Negative": "0.00035919586662203074",
   "Neutral": "0.18792422115802765",
   "Mixed": "0.0003288934822194278"
 },
 {
   "tweet_id": "1589968036240719873",
   "Positive": "0.024540407583117485",
   "Negative": "6.167026003822684e-05",
   "Neutral": "0.9752681255340576",
   "Mixed": "0.00012971549585927278"
 },
 {
   "tweet_id": "1589968033556344832",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589968023477456897",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589968020927283202",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589968012719030272",
   "Positive": "0.02383381314575672",
   "Negative": "6.04786655458156e-05",
   "Neutral": "0.9759697318077087",
   "Mixed": "0.00013600278180092573"
 },
 {
   "tweet_id": "1589968010689024002",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589967996378025985",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967995715350528",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589967991529406465",
   "Positive": "0.0004954393953084946",
   "Negative": "0.9826034903526306",
   "Neutral": "0.01686192862689495",
   "Mixed": "3.9089583879103884e-05"
 },
 {
   "tweet_id": "1589967988958302209",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589967967361855488",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589967966070005760",
   "Positive": "0.023270519450306892",
   "Negative": "6.0819333157269284e-05",
   "Neutral": "0.97654128074646",
   "Mixed": "0.00012734833580907434"
 },
 {
   "tweet_id": "1589967964098265088",
   "Positive": "0.01944025233387947",
   "Negative": "0.7710250616073608",
   "Neutral": "0.20313942432403564",
   "Mixed": "0.006395253352820873"
 },
 {
   "tweet_id": "1589967958725758976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967954950909952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967942921297921",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589967941831098368",
   "Positive": "0.00028464291244745255",
   "Negative": "0.0003688134893309325",
   "Neutral": "0.9993403553962708",
   "Mixed": "6.1579748944495805e-06"
 },
 {
   "tweet_id": "1589967925649100800",
   "Positive": "0.024615895003080368",
   "Negative": "6.157111056381837e-05",
   "Neutral": "0.9751944541931152",
   "Mixed": "0.0001281304139411077"
 },
 {
   "tweet_id": "1589967902391689219",
   "Positive": "0.024628683924674988",
   "Negative": "6.283567199716344e-05",
   "Neutral": "0.9751801490783691",
   "Mixed": "0.00012833104119636118"
 },
 {
   "tweet_id": "1589967887590383616",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589967876911292417",
   "Positive": "0.02424582839012146",
   "Negative": "5.919885734329e-05",
   "Neutral": "0.9755478501319885",
   "Mixed": "0.00014708236267324537"
 },
 {
   "tweet_id": "1589967870074966016",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589967860339978241",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967853104480256",
   "Positive": "0.023696832358837128",
   "Negative": "6.107720400905237e-05",
   "Neutral": "0.9760994911193848",
   "Mixed": "0.0001425012160325423"
 },
 {
   "tweet_id": "1589967852056227844",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967850697265154",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589967848008736769",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589967827217571840",
   "Positive": "0.023033764213323593",
   "Negative": "6.0315458540571854e-05",
   "Neutral": "0.9767672419548035",
   "Mixed": "0.00013871317787561566"
 },
 {
   "tweet_id": "1589967804094361601",
   "Positive": "0.02319917269051075",
   "Negative": "5.912229244131595e-05",
   "Neutral": "0.9766061305999756",
   "Mixed": "0.00013564806431531906"
 },
 {
   "tweet_id": "1589967798595645440",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589967779599642625",
   "Positive": "0.02136155776679516",
   "Negative": "5.769914059783332e-05",
   "Neutral": "0.9784414172172546",
   "Mixed": "0.00013922576908953488"
 },
 {
   "tweet_id": "1589967761350217728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967760200638465",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589967756551938049",
   "Positive": "0.022865375503897667",
   "Negative": "6.065690831746906e-05",
   "Neutral": "0.9769482612609863",
   "Mixed": "0.0001258071861229837"
 },
 {
   "tweet_id": "1589967755201347584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967747483832324",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967746443653122",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967731767808001",
   "Positive": "0.021887199953198433",
   "Negative": "6.073790791560896e-05",
   "Neutral": "0.9779422879219055",
   "Mixed": "0.00010976838530041277"
 },
 {
   "tweet_id": "1589967718882873344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967706039914496",
   "Positive": "0.021431421861052513",
   "Negative": "5.929043982177973e-05",
   "Neutral": "0.9783959984779358",
   "Mixed": "0.00011328503751428798"
 },
 {
   "tweet_id": "1589967704790032391",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967699139915776",
   "Positive": "0.17521892488002777",
   "Negative": "0.3802262246608734",
   "Neutral": "0.38811343908309937",
   "Mixed": "0.056441403925418854"
 },
 {
   "tweet_id": "1589967697651314688",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589967696233639936",
   "Positive": "0.4065564274787903",
   "Negative": "0.004498036578297615",
   "Neutral": "0.5883427262306213",
   "Mixed": "0.0006028036586940289"
 },
 {
   "tweet_id": "1589967684929982465",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967683902402561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967683139047430",
   "Positive": "0.023182453587651253",
   "Negative": "6.0739675973309204e-05",
   "Neutral": "0.9766415953636169",
   "Mixed": "0.00011521628039190546"
 },
 {
   "tweet_id": "1589967678768570368",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589967676801437697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967675803193344",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589967661722923010",
   "Positive": "0.0238121896982193",
   "Negative": "5.9814381529577076e-05",
   "Neutral": "0.9760022759437561",
   "Mixed": "0.00012559826427605003"
 },
 {
   "tweet_id": "1589967656085749760",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589967644710801408",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589967637341409281",
   "Positive": "0.02727837860584259",
   "Negative": "6.334677891572937e-05",
   "Neutral": "0.9724922180175781",
   "Mixed": "0.00016595488705206662"
 },
 {
   "tweet_id": "1589967634564788225",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1589967631351951362",
   "Positive": "0.6584538817405701",
   "Negative": "0.008206117898225784",
   "Neutral": "0.3331272304058075",
   "Mixed": "0.00021277823543641716"
 },
 {
   "tweet_id": "1589967629296730114",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1589967626620768257",
   "Positive": "0.0805571973323822",
   "Negative": "0.004230362828820944",
   "Neutral": "0.9151538014411926",
   "Mixed": "5.873854024684988e-05"
 },
 {
   "tweet_id": "1589967618487644160",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589967612683096064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967610040692736",
   "Positive": "0.025427868589758873",
   "Negative": "5.913249697186984e-05",
   "Neutral": "0.9743576049804688",
   "Mixed": "0.00015539598825853318"
 },
 {
   "tweet_id": "1589967604227375106",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967601819492353",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1589967584388321280",
   "Positive": "0.02437753975391388",
   "Negative": "5.676315413438715e-05",
   "Neutral": "0.9754122495651245",
   "Mixed": "0.00015338246885221452"
 },
 {
   "tweet_id": "1589967570593263616",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589967568671866880",
   "Positive": "0.022778404876589775",
   "Negative": "0.0007273665978573263",
   "Neutral": "0.9764852523803711",
   "Mixed": "8.983889529190492e-06"
 },
 {
   "tweet_id": "1589967567422377986",
   "Positive": "0.5747087001800537",
   "Negative": "0.0013821792090311646",
   "Neutral": "0.4238264560699463",
   "Mixed": "8.265810902230442e-05"
 },
 {
   "tweet_id": "1589967564444401665",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589967558090035200",
   "Positive": "0.025904009118676186",
   "Negative": "6.133180431788787e-05",
   "Neutral": "0.9738715291023254",
   "Mixed": "0.00016306726320181042"
 },
 {
   "tweet_id": "1589967545276456960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967544735084546",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967544571822080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967531703312385",
   "Positive": "0.0224164966493845",
   "Negative": "5.411512029240839e-05",
   "Neutral": "0.9773439764976501",
   "Mixed": "0.00018535499111749232"
 },
 {
   "tweet_id": "1589967529614905347",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589967523742900225",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967511893991427",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967507745820672",
   "Positive": "0.0018605784280225635",
   "Negative": "0.8388760685920715",
   "Neutral": "0.1584462672472",
   "Mixed": "0.0008170754881575704"
 },
 {
   "tweet_id": "1589967504906280962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967503434092553",
   "Positive": "0.03266004100441933",
   "Negative": "0.000530614925082773",
   "Neutral": "0.9667843580245972",
   "Mixed": "2.4998193111969158e-05"
 },
 {
   "tweet_id": "1589967499751137280",
   "Positive": "0.024689018726348877",
   "Negative": "5.898469316889532e-05",
   "Neutral": "0.9750880002975464",
   "Mixed": "0.00016401974426116794"
 },
 {
   "tweet_id": "1589967495192285192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967486669058051",
   "Positive": "0.003929635509848595",
   "Negative": "0.0005160077125765383",
   "Neutral": "0.9955518841743469",
   "Mixed": "2.546318910390255e-06"
 },
 {
   "tweet_id": "1589967474531127296",
   "Positive": "0.02222631126642227",
   "Negative": "5.9382673498475924e-05",
   "Neutral": "0.9776050448417664",
   "Mixed": "0.00010926750837825239"
 },
 {
   "tweet_id": "1589967473289605125",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589967463651094528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967456071979013",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589967450246111232",
   "Positive": "0.9692977666854858",
   "Negative": "0.00035800354089587927",
   "Neutral": "0.030327703803777695",
   "Mixed": "1.6537906049052253e-05"
 },
 {
   "tweet_id": "1589967450002444288",
   "Positive": "0.023371541872620583",
   "Negative": "5.937873720540665e-05",
   "Neutral": "0.9764453768730164",
   "Mixed": "0.0001237471733475104"
 },
 {
   "tweet_id": "1589967442780258312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967438158106624",
   "Positive": "0.035843390971422195",
   "Negative": "0.0036087515763938427",
   "Neutral": "0.9605160355567932",
   "Mixed": "3.179563282174058e-05"
 },
 {
   "tweet_id": "1589967423956193281",
   "Positive": "0.023778431117534637",
   "Negative": "6.02009313297458e-05",
   "Neutral": "0.9760363698005676",
   "Mixed": "0.00012501122546382248"
 },
 {
   "tweet_id": "1589967423658414082",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967421657731072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967416419037185",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589967398614228992",
   "Positive": "0.024340391159057617",
   "Negative": "6.17538025835529e-05",
   "Neutral": "0.97548907995224",
   "Mixed": "0.00010883310460485518"
 },
 {
   "tweet_id": "1589967394596098049",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967386249424902",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967378829389824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967376946454528",
   "Positive": "0.035843390971422195",
   "Negative": "0.0036087515763938427",
   "Neutral": "0.9605160355567932",
   "Mixed": "3.179563282174058e-05"
 },
 {
   "tweet_id": "1589967372559200256",
   "Positive": "0.8113875985145569",
   "Negative": "0.00035919586662203074",
   "Neutral": "0.18792422115802765",
   "Mixed": "0.0003288934822194278"
 },
 {
   "tweet_id": "1589967367442173953",
   "Positive": "0.0013822197215631604",
   "Negative": "0.7214931845664978",
   "Neutral": "0.2718547284603119",
   "Mixed": "0.005269867368042469"
 },
 {
   "tweet_id": "1589967357308723201",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589967351872905217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967350497173505",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967331471790081",
   "Positive": "0.02293304353952408",
   "Negative": "5.941329800407402e-05",
   "Neutral": "0.9768683910369873",
   "Mixed": "0.00013913404836785048"
 },
 {
   "tweet_id": "1589967325968883714",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589967318393982976",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967317769031680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967310441574401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967300257787904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967293928263682",
   "Positive": "0.604518473148346",
   "Negative": "0.007705769035965204",
   "Neutral": "0.38758164644241333",
   "Mixed": "0.0001941141381394118"
 },
 {
   "tweet_id": "1589967292435431424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967292250529795",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589967287594860545",
   "Positive": "0.022297129034996033",
   "Negative": "5.762173168477602e-05",
   "Neutral": "0.9775117635726929",
   "Mixed": "0.0001335019915131852"
 },
 {
   "tweet_id": "1589967285346705408",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589967278745227264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967273863020547",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967272680239107",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1589967272118190081",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589967269236707329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967260697137154",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967259812134912",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967255965925378",
   "Positive": "0.0227570328861475",
   "Negative": "5.780017454526387e-05",
   "Neutral": "0.9770469069480896",
   "Mixed": "0.00013828539522364736"
 },
 {
   "tweet_id": "1589967252321079296",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967237951037442",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589967228891725824",
   "Positive": "0.02461148239672184",
   "Negative": "6.258420762605965e-05",
   "Neutral": "0.9752019047737122",
   "Mixed": "0.00012401390995364636"
 },
 {
   "tweet_id": "1589967227495010304",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589967222294077445",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589967219441946624",
   "Positive": "0.743085503578186",
   "Negative": "0.0012078877771273255",
   "Neutral": "0.25566554069519043",
   "Mixed": "4.1084731492446736e-05"
 },
 {
   "tweet_id": "1589967216635617280",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967216141008897",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967211913179136",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589967209581117440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967207270076417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967196822077440",
   "Positive": "0.02293093502521515",
   "Negative": "5.33715974597726e-05",
   "Neutral": "0.976823091506958",
   "Mixed": "0.00019260823319200426"
 },
 {
   "tweet_id": "1589967194603270144",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967188181803008",
   "Positive": "0.022965097799897194",
   "Negative": "0.003440514672547579",
   "Neutral": "0.9735805988311768",
   "Mixed": "1.3785282135359012e-05"
 },
 {
   "tweet_id": "1589967182926319616",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589967180107747328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967175913439232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967169471000577",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967167428395008",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589967166530781184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967164060336128",
   "Positive": "0.022179974243044853",
   "Negative": "5.3435840527527034e-05",
   "Neutral": "0.9775531888008118",
   "Mixed": "0.00021331978496164083"
 },
 {
   "tweet_id": "1589967142942040064",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589967141021048834",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967132531789824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967120120840193",
   "Positive": "0.03591938689351082",
   "Negative": "6.781741103623062e-05",
   "Neutral": "0.9638127684593201",
   "Mixed": "0.00020005165424663574"
 },
 {
   "tweet_id": "1589967107474984961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967107126874112",
   "Positive": "0.017987718805670738",
   "Negative": "4.8874429921852425e-05",
   "Neutral": "0.9817380309104919",
   "Mixed": "0.00022535408788826317"
 },
 {
   "tweet_id": "1589967095340859394",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589967093109137408",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589967086172143618",
   "Positive": "0.023747628554701805",
   "Negative": "5.849790250067599e-05",
   "Neutral": "0.9760596752166748",
   "Mixed": "0.0001342341856798157"
 },
 {
   "tweet_id": "1589967086172139523",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967080945655810",
   "Positive": "0.021786503493785858",
   "Negative": "0.5316638350486755",
   "Neutral": "0.44330641627311707",
   "Mixed": "0.003243256825953722"
 },
 {
   "tweet_id": "1589967072448380932",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967067515453441",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967063766142977",
   "Positive": "0.0022380573209375143",
   "Negative": "0.0002123968442901969",
   "Neutral": "0.997546374797821",
   "Mixed": "3.1687593491369626e-06"
 },
 {
   "tweet_id": "1589967053339123713",
   "Positive": "0.02406669594347477",
   "Negative": "5.275399234960787e-05",
   "Neutral": "0.9755870699882507",
   "Mixed": "0.00029346859082579613"
 },
 {
   "tweet_id": "1589967053003554817",
   "Positive": "0.02350962720811367",
   "Negative": "5.944077929598279e-05",
   "Neutral": "0.9762964844703674",
   "Mixed": "0.00013444587239064276"
 },
 {
   "tweet_id": "1589967052332478464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967045084745729",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589967023224025088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589967021483380737",
   "Positive": "0.47712475061416626",
   "Negative": "0.0007128416909836233",
   "Neutral": "0.5220598578453064",
   "Mixed": "0.00010251766070723534"
 },
 {
   "tweet_id": "1589967019545616386",
   "Positive": "0.023222029209136963",
   "Negative": "6.061528983991593e-05",
   "Neutral": "0.9765881299972534",
   "Mixed": "0.00012921942106913775"
 },
 {
   "tweet_id": "1589967015464546304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589967014483095552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589967011962327042",
   "Positive": "0.042173534631729126",
   "Negative": "6.210077117430046e-05",
   "Neutral": "0.9574080109596252",
   "Mixed": "0.0003563021309673786"
 },
 {
   "tweet_id": "1589967004328681472",
   "Positive": "0.024464556947350502",
   "Negative": "4.770458326674998e-05",
   "Neutral": "0.9752519130706787",
   "Mixed": "0.00023580431297887117"
 },
 {
   "tweet_id": "1589966996900577280",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589966986938699776",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1589966986351874048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966985773060096",
   "Positive": "0.022351181134581566",
   "Negative": "6.046098860679194e-05",
   "Neutral": "0.9774764180183411",
   "Mixed": "0.0001118403670261614"
 },
 {
   "tweet_id": "1589966973877690368",
   "Positive": "0.26084646582603455",
   "Negative": "0.30818358063697815",
   "Neutral": "0.16582933068275452",
   "Mixed": "0.26514068245887756"
 },
 {
   "tweet_id": "1589966969503158275",
   "Positive": "0.04297921434044838",
   "Negative": "0.00013114186003804207",
   "Neutral": "0.9568566083908081",
   "Mixed": "3.299216041341424e-05"
 },
 {
   "tweet_id": "1589966969482399745",
   "Positive": "0.03342004492878914",
   "Negative": "5.890673492103815e-05",
   "Neutral": "0.966132640838623",
   "Mixed": "0.0003884123871102929"
 },
 {
   "tweet_id": "1589966969432047616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966963270262784",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589966962272403456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966950545121280",
   "Positive": "0.1161036491394043",
   "Negative": "0.004471351392567158",
   "Neutral": "0.8794103860855103",
   "Mixed": "1.4599737369280774e-05"
 },
 {
   "tweet_id": "1589966938817515520",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966929753944065",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966928835403777",
   "Positive": "0.1194545179605484",
   "Negative": "0.004098998848348856",
   "Neutral": "0.8759117126464844",
   "Mixed": "0.0005347859696485102"
 },
 {
   "tweet_id": "1589966926541094914",
   "Positive": "0.03189639747142792",
   "Negative": "5.968688492430374e-05",
   "Neutral": "0.967786967754364",
   "Mixed": "0.00025696493685245514"
 },
 {
   "tweet_id": "1589966911651344385",
   "Positive": "0.5196846723556519",
   "Negative": "0.002032710937783122",
   "Neutral": "0.4781956076622009",
   "Mixed": "8.6997686594259e-05"
 },
 {
   "tweet_id": "1589966909159616514",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966902935289859",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966900913643525",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966896677654528",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589966895251623938",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966895113195521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966894613733376",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966893166665730",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966892734849024",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966891157966851",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966890935328768",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966890692378624",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966890272960512",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966890247475200",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966890021314561",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966889727426560",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966889660317698",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966889614282753",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966889094381569",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966889018871811",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966879086739456",
   "Positive": "0.3895649015903473",
   "Negative": "0.0061072492972016335",
   "Neutral": "0.5907695293426514",
   "Mixed": "0.013558396138250828"
 },
 {
   "tweet_id": "1589966874603057152",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589966874598846464",
   "Positive": "0.002332017058506608",
   "Negative": "0.6407212615013123",
   "Neutral": "0.3460124135017395",
   "Mixed": "0.010934275574982166"
 },
 {
   "tweet_id": "1589966871545413633",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966871436169218",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966870240587777",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966869951565824",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966869473427457",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966868961529856",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966868328194052",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966857511268353",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966849890222082",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966836791410688",
   "Positive": "0.07343356311321259",
   "Negative": "5.7889646996045485e-05",
   "Neutral": "0.925743818283081",
   "Mixed": "0.000764785276260227"
 },
 {
   "tweet_id": "1589966831980544003",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966825257070592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966824292356096",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589966824019759105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966821800968194",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589966800359690240",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589966796668678144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966792558272512",
   "Positive": "0.0019544297829270363",
   "Negative": "0.0001575494243297726",
   "Neutral": "0.9978805780410767",
   "Mixed": "7.525923592766048e-06"
 },
 {
   "tweet_id": "1589966791044108290",
   "Positive": "0.0001935299951583147",
   "Negative": "0.8629935383796692",
   "Neutral": "0.13677172362804413",
   "Mixed": "4.1178751416737214e-05"
 },
 {
   "tweet_id": "1589966752750120961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966749969313795",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966738464346113",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966734509092864",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589966725306806273",
   "Positive": "0.04490772634744644",
   "Negative": "0.00022423057816922665",
   "Neutral": "0.9548540711402893",
   "Mixed": "1.3951788787380792e-05"
 },
 {
   "tweet_id": "1589966722714730499",
   "Positive": "0.7894002795219421",
   "Negative": "0.0005248984089121222",
   "Neutral": "0.21002677083015442",
   "Mixed": "4.80460403196048e-05"
 },
 {
   "tweet_id": "1589966722584674304",
   "Positive": "0.022723136469721794",
   "Negative": "0.00014956184895709157",
   "Neutral": "0.9769511818885803",
   "Mixed": "0.00017605909670237452"
 },
 {
   "tweet_id": "1589966706591825921",
   "Positive": "0.5196846723556519",
   "Negative": "0.002032710937783122",
   "Neutral": "0.4781956076622009",
   "Mixed": "8.6997686594259e-05"
 },
 {
   "tweet_id": "1589966705417392130",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589966696894279680",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1589966688962781185",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966678729052166",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966663554076672",
   "Positive": "0.026404134929180145",
   "Negative": "7.030532287899405e-05",
   "Neutral": "0.9733840227127075",
   "Mixed": "0.00014159735292196274"
 },
 {
   "tweet_id": "1589966656528592898",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966654665940993",
   "Positive": "0.04465622827410698",
   "Negative": "0.25113075971603394",
   "Neutral": "0.07027260214090347",
   "Mixed": "0.6339404582977295"
 },
 {
   "tweet_id": "1589966651444715520",
   "Positive": "0.0012308162404224277",
   "Negative": "0.9276316165924072",
   "Neutral": "0.07089265435934067",
   "Mixed": "0.00024500509607605636"
 },
 {
   "tweet_id": "1589966637125763072",
   "Positive": "0.03269724175333977",
   "Negative": "6.546406802954152e-05",
   "Neutral": "0.9669650197029114",
   "Mixed": "0.0002723076904658228"
 },
 {
   "tweet_id": "1589966631878676480",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589966628061876225",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966627847966721",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589966620700835840",
   "Positive": "0.04769400879740715",
   "Negative": "6.801034760428593e-05",
   "Neutral": "0.9517502784729004",
   "Mixed": "0.00048770493594929576"
 },
 {
   "tweet_id": "1589966617370583041",
   "Positive": "0.08401333540678024",
   "Negative": "0.0025424500927329063",
   "Neutral": "0.9134082198143005",
   "Mixed": "3.593290239223279e-05"
 },
 {
   "tweet_id": "1589966613457297410",
   "Positive": "0.8590452075004578",
   "Negative": "0.0008224013145081699",
   "Neutral": "0.14004729688167572",
   "Mixed": "8.512437489116564e-05"
 },
 {
   "tweet_id": "1589966613289512962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966602451456000",
   "Positive": "0.2678431272506714",
   "Negative": "0.015258518978953362",
   "Neutral": "0.7015790343284607",
   "Mixed": "0.01531925518065691"
 },
 {
   "tweet_id": "1589966598781427717",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589966596042555394",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966585544216579",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589966581454753793",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966573905018881",
   "Positive": "0.0615195594727993",
   "Negative": "8.641328167868778e-05",
   "Neutral": "0.9379894733428955",
   "Mixed": "0.00040457601426169276"
 },
 {
   "tweet_id": "1589966556389597186",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966547384008706",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966547115966469",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966547086462976",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966546147110915",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966542711635968",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966536160092160",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966535996870658",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966533652287488",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966530905014273",
   "Positive": "0.05501175299286842",
   "Negative": "6.500353629235178e-05",
   "Neutral": "0.944475531578064",
   "Mixed": "0.0004477103066165"
 },
 {
   "tweet_id": "1589966522717704192",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966522679582720",
   "Positive": "0.002043681452050805",
   "Negative": "0.0001296917034778744",
   "Neutral": "0.9978196620941162",
   "Mixed": "7.0423811848741025e-06"
 },
 {
   "tweet_id": "1589966520385294336",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966517847920641",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966517449261056",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966516271083523",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966514496544768",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966513833771008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966513062039552",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966512395255813",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966512227602433",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966511782887424",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966511049150468",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966510818492419",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966510746918912",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966510671511554",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966510646505472",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966510268743680",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966509639704576",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966509488721924",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966509476302850",
   "Positive": "0.02363547496497631",
   "Negative": "6.859713903395459e-05",
   "Neutral": "0.9761659502983093",
   "Mixed": "0.00012994949065614492"
 },
 {
   "tweet_id": "1589966508695977984",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966508247027712",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966507928604681",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966505063911424",
   "Positive": "0.01863119751214981",
   "Negative": "5.9101319493493065e-05",
   "Neutral": "0.9811729788780212",
   "Mixed": "0.0001367654331261292"
 },
 {
   "tweet_id": "1589966500089450496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966489427546113",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966486118203393",
   "Positive": "0.01748846471309662",
   "Negative": "5.7947250752476975e-05",
   "Neutral": "0.9823311567306519",
   "Mixed": "0.00012244244862813503"
 },
 {
   "tweet_id": "1589966485082210309",
   "Positive": "0.06328892707824707",
   "Negative": "8.706726657692343e-05",
   "Neutral": "0.9360439777374268",
   "Mixed": "0.0005799932987429202"
 },
 {
   "tweet_id": "1589966484788641793",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589966472683876354",
   "Positive": "0.020508205518126488",
   "Negative": "6.0559006669791415e-05",
   "Neutral": "0.9792664647102356",
   "Mixed": "0.000164844561368227"
 },
 {
   "tweet_id": "1589966466157228035",
   "Positive": "0.31992819905281067",
   "Negative": "0.009050874970853329",
   "Neutral": "0.6708890199661255",
   "Mixed": "0.00013188701996114105"
 },
 {
   "tweet_id": "1589966457244635137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966456992960512",
   "Positive": "0.01811813935637474",
   "Negative": "5.708634853363037e-05",
   "Neutral": "0.9816734790802002",
   "Mixed": "0.00015135345165617764"
 },
 {
   "tweet_id": "1589966456091201541",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966444812730369",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966433181929472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966428282982401",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966426076770304",
   "Positive": "0.004552981350570917",
   "Negative": "7.986200944287702e-05",
   "Neutral": "0.9953616261482239",
   "Mixed": "5.622808657790301e-06"
 },
 {
   "tweet_id": "1589966418170511362",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589966411006611458",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589966406531309568",
   "Positive": "0.021511390805244446",
   "Negative": "6.173575820866972e-05",
   "Neutral": "0.9782377481460571",
   "Mixed": "0.00018916722910944372"
 },
 {
   "tweet_id": "1589966406271270913",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589966396200742912",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966393545719809",
   "Positive": "0.017888763919472694",
   "Negative": "5.8347664889879525e-05",
   "Neutral": "0.9819093942642212",
   "Mixed": "0.00014346920943353325"
 },
 {
   "tweet_id": "1589966389561155587",
   "Positive": "0.022965097799897194",
   "Negative": "0.003440514672547579",
   "Neutral": "0.9735805988311768",
   "Mixed": "1.3785282135359012e-05"
 },
 {
   "tweet_id": "1589966383525564418",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589966379670994945",
   "Positive": "0.021206650882959366",
   "Negative": "6.13575684837997e-05",
   "Neutral": "0.9785668849945068",
   "Mixed": "0.00016509952547494322"
 },
 {
   "tweet_id": "1589966376349097987",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966375560179713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966375191457792",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589966366689595392",
   "Positive": "0.01947743259370327",
   "Negative": "5.925233563175425e-05",
   "Neutral": "0.9803292751312256",
   "Mixed": "0.00013409610255621374"
 },
 {
   "tweet_id": "1589966346280136706",
   "Positive": "0.1267261505126953",
   "Negative": "0.22454901039600372",
   "Neutral": "0.6480370759963989",
   "Mixed": "0.0006877714185975492"
 },
 {
   "tweet_id": "1589966341024665605",
   "Positive": "0.0013113678433001041",
   "Negative": "0.0002774455933831632",
   "Neutral": "0.9984089732170105",
   "Mixed": "2.2888461899128743e-06"
 },
 {
   "tweet_id": "1589966338738761730",
   "Positive": "0.9069679975509644",
   "Negative": "0.0031471482943743467",
   "Neutral": "0.0898459181189537",
   "Mixed": "3.892767199431546e-05"
 },
 {
   "tweet_id": "1589966332451500032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966330002046976",
   "Positive": "0.017394155263900757",
   "Negative": "4.785240889759734e-05",
   "Neutral": "0.9823417067527771",
   "Mixed": "0.00021629278489854187"
 },
 {
   "tweet_id": "1589966318052048896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966315921756160",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966304995573762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966278684381185",
   "Positive": "0.07015116512775421",
   "Negative": "0.012966658920049667",
   "Neutral": "0.9166799783706665",
   "Mixed": "0.00020219212456140667"
 },
 {
   "tweet_id": "1589966272665571328",
   "Positive": "0.024126185104250908",
   "Negative": "0.0020794039592146873",
   "Neutral": "0.9737832546234131",
   "Mixed": "1.1201618690392934e-05"
 },
 {
   "tweet_id": "1589966270551973888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966267179757568",
   "Positive": "0.011732418090105057",
   "Negative": "0.0006107587250880897",
   "Neutral": "0.9876493811607361",
   "Mixed": "7.432929123751819e-06"
 },
 {
   "tweet_id": "1589966253372100609",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589966228936101888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966223290531841",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589966175223844865",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589966174103941121",
   "Positive": "0.42579588294029236",
   "Negative": "0.016965333372354507",
   "Neutral": "0.5368381142616272",
   "Mixed": "0.020400697365403175"
 },
 {
   "tweet_id": "1589966166919114753",
   "Positive": "0.026310821995139122",
   "Negative": "7.183935667853802e-05",
   "Neutral": "0.9734169840812683",
   "Mixed": "0.0002002754044951871"
 },
 {
   "tweet_id": "1589966151790231554",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966142541811713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966129404977153",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966128511848448",
   "Positive": "0.2559923231601715",
   "Negative": "0.002090752124786377",
   "Neutral": "0.741879940032959",
   "Mixed": "3.6960249417461455e-05"
 },
 {
   "tweet_id": "1589966124804112384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966117854138368",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966117681958913",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966116776185862",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589966116759404544",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966116486774785",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966116423880704",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966115886993408",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589966114850689024",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966111595962370",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966111516561411",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966110136205312",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966110031761408",
   "Positive": "0.006866788491606712",
   "Negative": "0.003144176211208105",
   "Neutral": "0.9899380207061768",
   "Mixed": "5.097970279166475e-05"
 },
 {
   "tweet_id": "1589966109671059459",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966109599420416",
   "Positive": "0.0043469322845339775",
   "Negative": "0.0001876342430477962",
   "Neutral": "0.9954551458358765",
   "Mixed": "1.0348305295337923e-05"
 },
 {
   "tweet_id": "1589966108559216640",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966106759815168",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966106546302976",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966106449559553",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966106290434050",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966105841377284",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966105606443008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966105204109315",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966103987605505",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966103153115139",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966091769597952",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966091400654848",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966086547640320",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966086329753604",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966086203899904",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966085909946369",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966085515841536",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966085075394561",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966084630892545",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966084295299075",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966083963994112",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966071221846016",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589966067937718272",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589966060786446336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589966049499557889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589966046538366977",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589966033783500801",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589966027240411137",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966010517708807",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589966009632686081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589966006268866569",
   "Positive": "0.9443896412849426",
   "Negative": "0.0016412126133218408",
   "Neutral": "0.05393616110086441",
   "Mixed": "3.2974490750348195e-05"
 },
 {
   "tweet_id": "1589965997066555394",
   "Positive": "0.10195695608854294",
   "Negative": "0.004435644950717688",
   "Neutral": "0.8933990597724915",
   "Mixed": "0.00020833410962950438"
 },
 {
   "tweet_id": "1589965994814210048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965987696476161",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965984731107328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965975327477764",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965974056620033",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589965973511020544",
   "Positive": "0.27267566323280334",
   "Negative": "0.0005205919151194394",
   "Neutral": "0.7267712950706482",
   "Mixed": "3.247942004236393e-05"
 },
 {
   "tweet_id": "1589965969731903488",
   "Positive": "0.14007018506526947",
   "Negative": "0.003773632925003767",
   "Neutral": "0.8540700674057007",
   "Mixed": "0.002086129505187273"
 },
 {
   "tweet_id": "1589965967156826113",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965966762733569",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965966007357442",
   "Positive": "0.0095177311450243",
   "Negative": "0.0003044912882614881",
   "Neutral": "0.9901690483093262",
   "Mixed": "8.782618351688143e-06"
 },
 {
   "tweet_id": "1589965964984074241",
   "Positive": "0.8337277770042419",
   "Negative": "0.00012408793554641306",
   "Neutral": "0.1660473644733429",
   "Mixed": "0.00010067730181617662"
 },
 {
   "tweet_id": "1589965952879579137",
   "Positive": "0.14176799356937408",
   "Negative": "0.00342385470867157",
   "Neutral": "0.8547446727752686",
   "Mixed": "6.349354225676507e-05"
 },
 {
   "tweet_id": "1589965932113588225",
   "Positive": "0.1855485588312149",
   "Negative": "0.00048534051165916026",
   "Neutral": "0.8138896822929382",
   "Mixed": "7.638074748683721e-05"
 },
 {
   "tweet_id": "1589965926216372224",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965925000032256",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965923771121664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965903764271104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965899263782912",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589965891722436609",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965886294630400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965873166839809",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965845941596160",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965835585847296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965833752940545",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965833102659584",
   "Positive": "0.0005910730105824769",
   "Negative": "0.00021168478997424245",
   "Neutral": "0.999194324016571",
   "Mixed": "2.962836106235045e-06"
 },
 {
   "tweet_id": "1589965813607387137",
   "Positive": "0.11063192039728165",
   "Negative": "0.00018433522200211883",
   "Neutral": "0.8891600370407104",
   "Mixed": "2.3606678951182403e-05"
 },
 {
   "tweet_id": "1589965804942295041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965791621189632",
   "Positive": "0.026465587317943573",
   "Negative": "0.008565502241253853",
   "Neutral": "0.9649391770362854",
   "Mixed": "2.967982436530292e-05"
 },
 {
   "tweet_id": "1589965791226908673",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589965789154922503",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589965768099508225",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589965764983156736",
   "Positive": "0.02663797326385975",
   "Negative": "8.92842945177108e-05",
   "Neutral": "0.9732334017753601",
   "Mixed": "3.9327955164480954e-05"
 },
 {
   "tweet_id": "1589965755172679681",
   "Positive": "0.024681255221366882",
   "Negative": "0.004000301007181406",
   "Neutral": "0.9713058471679688",
   "Mixed": "1.2628644071810413e-05"
 },
 {
   "tweet_id": "1589965751657844737",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965738659323904",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965732934078467",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965730577289218",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965730237366273",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965727502475265",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965719051325440",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589965718971248640",
   "Positive": "0.9966118931770325",
   "Negative": "0.00010286085307598114",
   "Neutral": "0.0032788212411105633",
   "Mixed": "6.43812882117345e-06"
 },
 {
   "tweet_id": "1589965716941602816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965715221745665",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965713187692544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965710033158144",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965705499537408",
   "Positive": "0.026861216872930527",
   "Negative": "0.004325510933995247",
   "Neutral": "0.9687908887863159",
   "Mixed": "2.2359734430210665e-05"
 },
 {
   "tweet_id": "1589965705373310976",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965705222684672",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589965701074149377",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965699933687809",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965698071425024",
   "Positive": "0.7234653234481812",
   "Negative": "0.0006366824964061379",
   "Neutral": "0.27574488520622253",
   "Mixed": "0.00015317030192818493"
 },
 {
   "tweet_id": "1589965696703770624",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965696095899649",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695877537792",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695823056897",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695755788289",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695500034054",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695399231488",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695374303232",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695139610625",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965695026364418",
   "Positive": "0.8546433448791504",
   "Negative": "0.0009226855472661555",
   "Neutral": "0.14429347217082977",
   "Mixed": "0.0001405391376465559"
 },
 {
   "tweet_id": "1589965694577393664",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965691397947393",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965684960022529",
   "Positive": "0.026442069560289383",
   "Negative": "0.0029456918127834797",
   "Neutral": "0.9705902934074402",
   "Mixed": "2.1958663637633435e-05"
 },
 {
   "tweet_id": "1589965682854150144",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965681323560960",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965679901683712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965678492033026",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965676978081795",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965676558835712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965676420079617",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965674394185728",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965674193252352",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965674192969731",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965673928720385",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965668103098371",
   "Positive": "0.026857024058699608",
   "Negative": "0.0039399852976202965",
   "Neutral": "0.9691818356513977",
   "Mixed": "2.1128969819983467e-05"
 },
 {
   "tweet_id": "1589965661849411591",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965646133342211",
   "Positive": "0.01905435509979725",
   "Negative": "0.004486412275582552",
   "Neutral": "0.9764449000358582",
   "Mixed": "1.4302804629551247e-05"
 },
 {
   "tweet_id": "1589965639464407041",
   "Positive": "0.0122219892218709",
   "Negative": "0.13227711617946625",
   "Neutral": "0.8551883697509766",
   "Mixed": "0.0003124749637208879"
 },
 {
   "tweet_id": "1589965630564085760",
   "Positive": "0.03169780597090721",
   "Negative": "0.0038261215668171644",
   "Neutral": "0.9644565582275391",
   "Mixed": "1.9538887499948032e-05"
 },
 {
   "tweet_id": "1589965629779775488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965615942742017",
   "Positive": "0.0465419702231884",
   "Negative": "0.7532477378845215",
   "Neutral": "0.18795832991600037",
   "Mixed": "0.012251944281160831"
 },
 {
   "tweet_id": "1589965611333201920",
   "Positive": "0.028195057064294815",
   "Negative": "0.0014706796500831842",
   "Neutral": "0.9703196883201599",
   "Mixed": "1.455341225664597e-05"
 },
 {
   "tweet_id": "1589965610330783751",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589965609185726468",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965600109252608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965595998838789",
   "Positive": "0.027717670425772667",
   "Negative": "5.289094042382203e-05",
   "Neutral": "0.9720640778541565",
   "Mixed": "0.00016536784823983908"
 },
 {
   "tweet_id": "1589965592387538944",
   "Positive": "0.028247660025954247",
   "Negative": "0.0037669276352971792",
   "Neutral": "0.9679692983627319",
   "Mixed": "1.607246849744115e-05"
 },
 {
   "tweet_id": "1589965583264931840",
   "Positive": "0.0032486184500157833",
   "Negative": "0.6860052347183228",
   "Neutral": "0.310485303401947",
   "Mixed": "0.00026076872018165886"
 },
 {
   "tweet_id": "1589965576054898690",
   "Positive": "0.029308848083019257",
   "Negative": "0.00341573404148221",
   "Neutral": "0.9672511219978333",
   "Mixed": "2.433565168757923e-05"
 },
 {
   "tweet_id": "1589965572782968833",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589965560682807297",
   "Positive": "0.0313851535320282",
   "Negative": "0.003378486493602395",
   "Neutral": "0.9652167558670044",
   "Mixed": "1.9636438082670793e-05"
 },
 {
   "tweet_id": "1589965550356426753",
   "Positive": "0.02738586626946926",
   "Negative": "6.417454278562218e-05",
   "Neutral": "0.9723634719848633",
   "Mixed": "0.00018652388826012611"
 },
 {
   "tweet_id": "1589965546518642688",
   "Positive": "0.030964991077780724",
   "Negative": "0.003326358972117305",
   "Neutral": "0.9656887650489807",
   "Mixed": "1.99367968889419e-05"
 },
 {
   "tweet_id": "1589965546459537408",
   "Positive": "0.012080220505595207",
   "Negative": "0.0003539709432516247",
   "Neutral": "0.9875600934028625",
   "Mixed": "5.774959390691947e-06"
 },
 {
   "tweet_id": "1589965545436516352",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589965533159772163",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589965531523977218",
   "Positive": "0.030253242701292038",
   "Negative": "0.002619373146444559",
   "Neutral": "0.9671083688735962",
   "Mixed": "1.9034485376323573e-05"
 },
 {
   "tweet_id": "1589965518802681860",
   "Positive": "0.00036068627377972007",
   "Negative": "0.9647887945175171",
   "Neutral": "0.03479254990816116",
   "Mixed": "5.795026299892925e-05"
 },
 {
   "tweet_id": "1589965515199762434",
   "Positive": "0.02741183340549469",
   "Negative": "0.004111602436751127",
   "Neutral": "0.9684592485427856",
   "Mixed": "1.736046942824032e-05"
 },
 {
   "tweet_id": "1589965514243469313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965508983431170",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965506781798400",
   "Positive": "0.025072766467928886",
   "Negative": "7.570849993498996e-05",
   "Neutral": "0.974680483341217",
   "Mixed": "0.000170992745552212"
 },
 {
   "tweet_id": "1589965501983494144",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589965498145714176",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1589965496329601025",
   "Positive": "0.018319284543395042",
   "Negative": "0.004065549932420254",
   "Neutral": "0.9775953888893127",
   "Mixed": "1.9866925867972896e-05"
 },
 {
   "tweet_id": "1589965479107784704",
   "Positive": "0.028125934302806854",
   "Negative": "0.00336548313498497",
   "Neutral": "0.9684904217720032",
   "Mixed": "1.8075214029522613e-05"
 },
 {
   "tweet_id": "1589965476083691521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965475278360578",
   "Positive": "0.33794155716896057",
   "Negative": "0.018900129944086075",
   "Neutral": "0.6423512101173401",
   "Mixed": "0.0008071358315646648"
 },
 {
   "tweet_id": "1589965470098427904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965463387533317",
   "Positive": "0.08261996507644653",
   "Negative": "0.00015191393322311342",
   "Neutral": "0.9172008633613586",
   "Mixed": "2.723253783187829e-05"
 },
 {
   "tweet_id": "1589965456915705857",
   "Positive": "0.01951916143298149",
   "Negative": "0.003552874783053994",
   "Neutral": "0.9769144654273987",
   "Mixed": "1.3454912732413504e-05"
 },
 {
   "tweet_id": "1589965455451914241",
   "Positive": "0.9166577458381653",
   "Negative": "0.00029640147113241255",
   "Neutral": "0.0829770416021347",
   "Mixed": "6.887088966323063e-05"
 },
 {
   "tweet_id": "1589965452385492993",
   "Positive": "0.0018768180161714554",
   "Negative": "0.9056609272956848",
   "Neutral": "0.09202059358358383",
   "Mixed": "0.00044173019705340266"
 },
 {
   "tweet_id": "1589965444760629249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965440004280321",
   "Positive": "0.027357380837202072",
   "Negative": "0.003745370777323842",
   "Neutral": "0.9688818454742432",
   "Mixed": "1.5381072444142774e-05"
 },
 {
   "tweet_id": "1589965436694986753",
   "Positive": "0.07848798483610153",
   "Negative": "0.00017891281459014863",
   "Neutral": "0.9213113188743591",
   "Mixed": "2.176542329834774e-05"
 },
 {
   "tweet_id": "1589965433071108096",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965430629994497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965424414064640",
   "Positive": "0.016498642042279243",
   "Negative": "0.0030277641490101814",
   "Neutral": "0.9804559946060181",
   "Mixed": "1.7670241504674777e-05"
 },
 {
   "tweet_id": "1589965424023965698",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589965418919530496",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589965418294579200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965416184832005",
   "Positive": "0.16306129097938538",
   "Negative": "0.0006539169698953629",
   "Neutral": "0.8362677097320557",
   "Mixed": "1.706180046312511e-05"
 },
 {
   "tweet_id": "1589965410627358720",
   "Positive": "0.020901968702673912",
   "Negative": "0.004273883532732725",
   "Neutral": "0.9748097062110901",
   "Mixed": "1.4440114682656713e-05"
 },
 {
   "tweet_id": "1589965395884404737",
   "Positive": "0.026504535228013992",
   "Negative": "0.0032302713952958584",
   "Neutral": "0.9702453017234802",
   "Mixed": "1.9950111891375855e-05"
 },
 {
   "tweet_id": "1589965389119000579",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965384605925383",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965382814945280",
   "Positive": "0.031607840210199356",
   "Negative": "0.003035217523574829",
   "Neutral": "0.9653370380401611",
   "Mixed": "1.993856676563155e-05"
 },
 {
   "tweet_id": "1589965373302267906",
   "Positive": "0.0827164351940155",
   "Negative": "0.5013150572776794",
   "Neutral": "0.3665411174297333",
   "Mixed": "0.04942743852734566"
 },
 {
   "tweet_id": "1589965368650788865",
   "Positive": "0.021331893280148506",
   "Negative": "0.0029326402582228184",
   "Neutral": "0.9757133722305298",
   "Mixed": "2.2058618924347684e-05"
 },
 {
   "tweet_id": "1589965353198968833",
   "Positive": "0.029498811811208725",
   "Negative": "0.0031395526602864265",
   "Neutral": "0.9673432111740112",
   "Mixed": "1.84860618901439e-05"
 },
 {
   "tweet_id": "1589965341824024577",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589965337608716289",
   "Positive": "0.024061350151896477",
   "Negative": "0.004574687220156193",
   "Neutral": "0.9713472127914429",
   "Mixed": "1.6768613932072185e-05"
 },
 {
   "tweet_id": "1589965323973054465",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589965322953854976",
   "Positive": "0.03304137662053108",
   "Negative": "0.0002149673382518813",
   "Neutral": "0.9665148854255676",
   "Mixed": "0.000228701057494618"
 },
 {
   "tweet_id": "1589965322055913472",
   "Positive": "0.024376092478632927",
   "Negative": "0.0003009300562553108",
   "Neutral": "0.9753106832504272",
   "Mixed": "1.2351069926808123e-05"
 },
 {
   "tweet_id": "1589965321208987648",
   "Positive": "0.029259150847792625",
   "Negative": "0.002625850262120366",
   "Neutral": "0.9680936336517334",
   "Mixed": "2.1374871721491218e-05"
 },
 {
   "tweet_id": "1589965319946526721",
   "Positive": "0.903380811214447",
   "Negative": "0.00021797080989927053",
   "Neutral": "0.09633361548185349",
   "Mixed": "6.7617125750985e-05"
 },
 {
   "tweet_id": "1589965308789657600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965293996376067",
   "Positive": "0.027787212282419205",
   "Negative": "0.004007753450423479",
   "Neutral": "0.9681880474090576",
   "Mixed": "1.693239937594626e-05"
 },
 {
   "tweet_id": "1589965286668922881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965277479202816",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965268721500165",
   "Positive": "0.01558019034564495",
   "Negative": "4.970497320755385e-05",
   "Neutral": "0.9842514991760254",
   "Mixed": "0.00011866779823321849"
 },
 {
   "tweet_id": "1589965266372677634",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965262958522368",
   "Positive": "0.029043646529316902",
   "Negative": "0.0041970144957304",
   "Neutral": "0.9667224287986755",
   "Mixed": "3.6830278986599296e-05"
 },
 {
   "tweet_id": "1589965262245093380",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965245589884928",
   "Positive": "0.22705267369747162",
   "Negative": "0.0003581563651096076",
   "Neutral": "0.7725579738616943",
   "Mixed": "3.1257353839464486e-05"
 },
 {
   "tweet_id": "1589965244138651650",
   "Positive": "0.9833698272705078",
   "Negative": "0.00024338702496606857",
   "Neutral": "0.016370665282011032",
   "Mixed": "1.6138861610670574e-05"
 },
 {
   "tweet_id": "1589965243765379072",
   "Positive": "0.017133750021457672",
   "Negative": "0.001974994782358408",
   "Neutral": "0.9808715581893921",
   "Mixed": "1.9613687982200645e-05"
 },
 {
   "tweet_id": "1589965241689182208",
   "Positive": "0.9975165128707886",
   "Negative": "6.2352410168387e-05",
   "Neutral": "0.0023878884967416525",
   "Mixed": "3.333374479552731e-05"
 },
 {
   "tweet_id": "1589965240296689667",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965235355516931",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965234546307072",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965234445615106",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965233497419776",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965233329692673",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965233174740993",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965233023770625",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965232822112256",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965231719174144",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965231697977344",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965231643848705",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965231610101761",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965230997901313",
   "Positive": "0.03137117624282837",
   "Negative": "0.002649015747010708",
   "Neutral": "0.9659563899040222",
   "Mixed": "2.339893762837164e-05"
 },
 {
   "tweet_id": "1589965230846738432",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965217798434821",
   "Positive": "0.02891569398343563",
   "Negative": "0.0033056738320738077",
   "Neutral": "0.9677631855010986",
   "Mixed": "1.550213164591696e-05"
 },
 {
   "tweet_id": "1589965213637705729",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965213549592576",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965212190375936",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965211972546561",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965211443814401",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965210592636930",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965210101907456",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965209925459968",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965209493704704",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589965207723708416",
   "Positive": "0.019261682406067848",
   "Negative": "5.946428427705541e-05",
   "Neutral": "0.9805276393890381",
   "Mixed": "0.00015128943778108805"
 },
 {
   "tweet_id": "1589965207715123201",
   "Positive": "0.013180101290345192",
   "Negative": "0.002643635729327798",
   "Neutral": "0.9841703176498413",
   "Mixed": "6.038600986357778e-06"
 },
 {
   "tweet_id": "1589965200836689922",
   "Positive": "0.012203171849250793",
   "Negative": "0.00222016591578722",
   "Neutral": "0.9855502843856812",
   "Mixed": "2.629225309647154e-05"
 },
 {
   "tweet_id": "1589965184717950976",
   "Positive": "0.01598089560866356",
   "Negative": "0.0021475942339748144",
   "Neutral": "0.9818587303161621",
   "Mixed": "1.27024568428169e-05"
 },
 {
   "tweet_id": "1589965181425422337",
   "Positive": "0.004379658959805965",
   "Negative": "4.1162711568176746e-05",
   "Neutral": "0.9955390095710754",
   "Mixed": "4.006880408269353e-05"
 },
 {
   "tweet_id": "1589965173221380099",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965170432151555",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965169740124161",
   "Positive": "0.026921724900603294",
   "Negative": "0.003725835122168064",
   "Neutral": "0.9693365097045898",
   "Mixed": "1.595298817846924e-05"
 },
 {
   "tweet_id": "1589965169517813761",
   "Positive": "0.7841333150863647",
   "Negative": "0.000465793622424826",
   "Neutral": "0.21536128222942352",
   "Mixed": "3.964251300203614e-05"
 },
 {
   "tweet_id": "1589965158855892992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965153956958210",
   "Positive": "0.02887784130871296",
   "Negative": "0.0033589540980756283",
   "Neutral": "0.9677460789680481",
   "Mixed": "1.708329546090681e-05"
 },
 {
   "tweet_id": "1589965153638158340",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589965152870596609",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589965147598360576",
   "Positive": "0.202748104929924",
   "Negative": "0.0005870010936632752",
   "Neutral": "0.7963824272155762",
   "Mixed": "0.00028249286697246134"
 },
 {
   "tweet_id": "1589965140933619714",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965138576437252",
   "Positive": "0.01583140157163143",
   "Negative": "0.0035307069774717093",
   "Neutral": "0.9806234240531921",
   "Mixed": "1.4332305909192655e-05"
 },
 {
   "tweet_id": "1589965134600241155",
   "Positive": "0.004396131262183189",
   "Negative": "4.31063563155476e-05",
   "Neutral": "0.9955303072929382",
   "Mixed": "3.0470209821942262e-05"
 },
 {
   "tweet_id": "1589965131525812227",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589965128937918464",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589965122990379010",
   "Positive": "0.02926483377814293",
   "Negative": "0.00395524175837636",
   "Neutral": "0.9667636156082153",
   "Mixed": "1.621025694475975e-05"
 },
 {
   "tweet_id": "1589965119072899072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965113041510400",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589965107651829762",
   "Positive": "0.031023800373077393",
   "Negative": "0.0022443372290581465",
   "Neutral": "0.9667096138000488",
   "Mixed": "2.2216639990801923e-05"
 },
 {
   "tweet_id": "1589965095412846594",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589965093563142144",
   "Positive": "0.027384070679545403",
   "Negative": "6.448815111070871e-05",
   "Neutral": "0.9723771214485168",
   "Mixed": "0.00017432088498026133"
 },
 {
   "tweet_id": "1589965091939979266",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965091210137600",
   "Positive": "0.02541510760784149",
   "Negative": "0.0028718591202050447",
   "Neutral": "0.9716941118240356",
   "Mixed": "1.8882021322497167e-05"
 },
 {
   "tweet_id": "1589965079344480256",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1589965075343093760",
   "Positive": "0.029512543231248856",
   "Negative": "0.0029350589029490948",
   "Neutral": "0.9675346612930298",
   "Mixed": "1.777267607394606e-05"
 },
 {
   "tweet_id": "1589965073828974598",
   "Positive": "0.018730750307440758",
   "Negative": "5.7526383898220956e-05",
   "Neutral": "0.9810479879379272",
   "Mixed": "0.0001637144887354225"
 },
 {
   "tweet_id": "1589965069152313345",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589965060130365446",
   "Positive": "0.030534330755472183",
   "Negative": "0.0026455065235495567",
   "Neutral": "0.9667996168136597",
   "Mixed": "2.049006252491381e-05"
 },
 {
   "tweet_id": "1589965060084236289",
   "Positive": "0.029058635234832764",
   "Negative": "6.466495688073337e-05",
   "Neutral": "0.9705972671508789",
   "Mixed": "0.0002794161846395582"
 },
 {
   "tweet_id": "1589965055181090816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965049476833280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589965046284976128",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589965045152501760",
   "Positive": "0.03134435787796974",
   "Negative": "0.002853820100426674",
   "Neutral": "0.9657802581787109",
   "Mixed": "2.158286952180788e-05"
 },
 {
   "tweet_id": "1589965031810404352",
   "Positive": "0.3298708200454712",
   "Negative": "0.000630678900051862",
   "Neutral": "0.6694768071174622",
   "Mixed": "2.1703946913476102e-05"
 },
 {
   "tweet_id": "1589965031231619072",
   "Positive": "0.031782571226358414",
   "Negative": "0.0028440358582884073",
   "Neutral": "0.9653510451316833",
   "Mixed": "2.2296688257483765e-05"
 },
 {
   "tweet_id": "1589965029239312384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589965027137970176",
   "Positive": "0.019529996439814568",
   "Negative": "5.86991518503055e-05",
   "Neutral": "0.9802324771881104",
   "Mixed": "0.00017875693447422236"
 },
 {
   "tweet_id": "1589965022645858304",
   "Positive": "0.029273565858602524",
   "Negative": "6.440230208681896e-05",
   "Neutral": "0.9705252647399902",
   "Mixed": "0.00013675677473656833"
 },
 {
   "tweet_id": "1589965022012506112",
   "Positive": "0.017753247171640396",
   "Negative": "0.01263340748846531",
   "Neutral": "0.9695764780044556",
   "Mixed": "3.682533497340046e-05"
 },
 {
   "tweet_id": "1589965020825554945",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589965013397405696",
   "Positive": "0.025582317262887955",
   "Negative": "0.0010649270843714476",
   "Neutral": "0.9733372926712036",
   "Mixed": "1.5423829609062523e-05"
 },
 {
   "tweet_id": "1589965006598467585",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964998184701953",
   "Positive": "0.02359708398580551",
   "Negative": "0.0010528191924095154",
   "Neutral": "0.9753372073173523",
   "Mixed": "1.29547452161205e-05"
 },
 {
   "tweet_id": "1589964993617092609",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589964988659437570",
   "Positive": "0.08980730175971985",
   "Negative": "0.013984143733978271",
   "Neutral": "0.8943533897399902",
   "Mixed": "0.0018551721004769206"
 },
 {
   "tweet_id": "1589964981248069632",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964980514066432",
   "Positive": "0.029936622828245163",
   "Negative": "0.0029723048210144043",
   "Neutral": "0.9670722484588623",
   "Mixed": "1.875119596661534e-05"
 },
 {
   "tweet_id": "1589964965976625152",
   "Positive": "0.02812836319208145",
   "Negative": "0.002731126267462969",
   "Neutral": "0.9691229462623596",
   "Mixed": "1.755536868586205e-05"
 },
 {
   "tweet_id": "1589964962554064897",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964952122851329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964949560135681",
   "Positive": "0.025130603462457657",
   "Negative": "0.001110152923502028",
   "Neutral": "0.9737446904182434",
   "Mixed": "1.4468011613644194e-05"
 },
 {
   "tweet_id": "1589964949149069312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964942681477121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964936578416640",
   "Positive": "0.0043469322845339775",
   "Negative": "0.0001876342430477962",
   "Neutral": "0.9954551458358765",
   "Mixed": "1.0348305295337923e-05"
 },
 {
   "tweet_id": "1589964936477753344",
   "Positive": "0.5634710192680359",
   "Negative": "0.0026800448540598154",
   "Neutral": "0.4337520897388458",
   "Mixed": "9.690930892247707e-05"
 },
 {
   "tweet_id": "1589964933936349185",
   "Positive": "0.029472533613443375",
   "Negative": "0.0033869920298457146",
   "Neutral": "0.9671218395233154",
   "Mixed": "1.861380587797612e-05"
 },
 {
   "tweet_id": "1589964922985013251",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964917242634240",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964916819394563",
   "Positive": "0.002687866799533367",
   "Negative": "0.9041374325752258",
   "Neutral": "0.09190203249454498",
   "Mixed": "0.0012726588174700737"
 },
 {
   "tweet_id": "1589964916181864449",
   "Positive": "0.030649540945887566",
   "Negative": "0.0036670661065727472",
   "Neutral": "0.9656643271446228",
   "Mixed": "1.9122495359624736e-05"
 },
 {
   "tweet_id": "1589964913694609409",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589964898909687810",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964898607730688",
   "Positive": "0.02871716581285",
   "Negative": "0.002770810853689909",
   "Neutral": "0.9684929847717285",
   "Mixed": "1.9047834939556196e-05"
 },
 {
   "tweet_id": "1589964886934966273",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964885743767554",
   "Positive": "0.029210295528173447",
   "Negative": "0.008239970542490482",
   "Neutral": "0.9625223875045776",
   "Mixed": "2.7332076570019126e-05"
 },
 {
   "tweet_id": "1589964883076214784",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964867439820800",
   "Positive": "0.029429152607917786",
   "Negative": "0.0029108712915331125",
   "Neutral": "0.9676406979560852",
   "Mixed": "1.921352668432519e-05"
 },
 {
   "tweet_id": "1589964865153953792",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964864499220480",
   "Positive": "0.024696307256817818",
   "Negative": "0.48202401399612427",
   "Neutral": "0.1637585312128067",
   "Mixed": "0.329521119594574"
 },
 {
   "tweet_id": "1589964860326293505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964859818532865",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964856727592961",
   "Positive": "0.004230699967592955",
   "Negative": "0.00024719780776649714",
   "Neutral": "0.995514452457428",
   "Mixed": "7.603865469718585e-06"
 },
 {
   "tweet_id": "1589964856433774592",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964855817441282",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589964851753144320",
   "Positive": "0.022544946521520615",
   "Negative": "0.001377664040774107",
   "Neutral": "0.9760668873786926",
   "Mixed": "1.0497879884496797e-05"
 },
 {
   "tweet_id": "1589964848976171008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964848074756096",
   "Positive": "0.6380631327629089",
   "Negative": "0.0007863484788686037",
   "Neutral": "0.3611220419406891",
   "Mixed": "2.8575132091646083e-05"
 },
 {
   "tweet_id": "1589964839916445696",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964838247489537",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964837656096773",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964836435558402",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964835089166337",
   "Positive": "0.033513378351926804",
   "Negative": "0.004594738595187664",
   "Neutral": "0.9618607759475708",
   "Mixed": "3.11061303364113e-05"
 },
 {
   "tweet_id": "1589964834879045633",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964834657161216",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964834380034049",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964834132692993",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964832953880576",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964832475955201",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964828005011456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964824607350785",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964815472234496",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964815291666437",
   "Positive": "0.003734221216291189",
   "Negative": "0.00023819852503947914",
   "Neutral": "0.9960246086120605",
   "Mixed": "2.930755272245733e-06"
 },
 {
   "tweet_id": "1589964814947958784",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964814863912961",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964814478020608",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964813832425473",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964813786284033",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964813639335937",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964812368629761",
   "Positive": "0.028265122324228287",
   "Negative": "0.002679604571312666",
   "Neutral": "0.9690240621566772",
   "Mixed": "3.1243132980307564e-05"
 },
 {
   "tweet_id": "1589964811080982529",
   "Positive": "0.011638754047453403",
   "Negative": "0.6178584694862366",
   "Neutral": "0.2635466456413269",
   "Mixed": "0.10695617645978928"
 },
 {
   "tweet_id": "1589964792865120259",
   "Positive": "0.028175152838230133",
   "Negative": "0.007405356038361788",
   "Neutral": "0.964383602142334",
   "Mixed": "3.591334825614467e-05"
 },
 {
   "tweet_id": "1589964791061565443",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964789673259008",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589964781049778176",
   "Positive": "0.025280382484197617",
   "Negative": "5.726623203372583e-05",
   "Neutral": "0.974514365196228",
   "Mixed": "0.00014798107440583408"
 },
 {
   "tweet_id": "1589964774867357696",
   "Positive": "0.02678750827908516",
   "Negative": "0.0033696491736918688",
   "Neutral": "0.9698271155357361",
   "Mixed": "1.5670182619942352e-05"
 },
 {
   "tweet_id": "1589964771675492352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964770626932736",
   "Positive": "0.008677536621689796",
   "Negative": "0.02207891084253788",
   "Neutral": "0.9691869020462036",
   "Mixed": "5.668418816640042e-05"
 },
 {
   "tweet_id": "1589964766805909505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964765665067010",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589964760862580743",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964760782893057",
   "Positive": "0.023907026275992393",
   "Negative": "0.00300157954916358",
   "Neutral": "0.9730778336524963",
   "Mixed": "1.3614512681670021e-05"
 },
 {
   "tweet_id": "1589964753388335111",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964748782981120",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964746169937921",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589964744408334339",
   "Positive": "0.03916928917169571",
   "Negative": "6.0569927882170305e-05",
   "Neutral": "0.96035236120224",
   "Mixed": "0.0004177519876975566"
 },
 {
   "tweet_id": "1589964743976300544",
   "Positive": "0.028685128316283226",
   "Negative": "0.0025549668353050947",
   "Neutral": "0.968743085861206",
   "Mixed": "1.6884940123418346e-05"
 },
 {
   "tweet_id": "1589964743632379904",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589964739291287555",
   "Positive": "0.022218529134988785",
   "Negative": "5.715606675948948e-05",
   "Neutral": "0.9775655269622803",
   "Mixed": "0.00015882562729530036"
 },
 {
   "tweet_id": "1589964730692956160",
   "Positive": "0.023120854049921036",
   "Negative": "5.847185821039602e-05",
   "Neutral": "0.9766919612884521",
   "Mixed": "0.00012871141370851547"
 },
 {
   "tweet_id": "1589964728650301440",
   "Positive": "0.028876230120658875",
   "Negative": "0.002670275280252099",
   "Neutral": "0.968435525894165",
   "Mixed": "1.7961023331736214e-05"
 },
 {
   "tweet_id": "1589964715861872645",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589964715127865344",
   "Positive": "0.004606435541063547",
   "Negative": "0.000219303896301426",
   "Neutral": "0.9951704144477844",
   "Mixed": "3.7540858102147467e-06"
 },
 {
   "tweet_id": "1589964712711970818",
   "Positive": "0.028573336079716682",
   "Negative": "0.003050808794796467",
   "Neutral": "0.9683586359024048",
   "Mixed": "1.7192101950058714e-05"
 },
 {
   "tweet_id": "1589964706873507842",
   "Positive": "0.00144439737778157",
   "Negative": "0.0002754422021098435",
   "Neutral": "0.9982775449752808",
   "Mixed": "2.5788042421481805e-06"
 },
 {
   "tweet_id": "1589964705044795392",
   "Positive": "0.5316445827484131",
   "Negative": "0.00011772685684263706",
   "Neutral": "0.4680277705192566",
   "Mixed": "0.00020990383927710354"
 },
 {
   "tweet_id": "1589964704360718337",
   "Positive": "0.4950658977031708",
   "Negative": "0.0006028191419318318",
   "Neutral": "0.5037160515785217",
   "Mixed": "0.000615167838986963"
 },
 {
   "tweet_id": "1589964701781590016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964697864142849",
   "Positive": "0.03054141066968441",
   "Negative": "0.003004311351105571",
   "Neutral": "0.9664350748062134",
   "Mixed": "1.9293247532914393e-05"
 },
 {
   "tweet_id": "1589964696752623617",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589964685927141376",
   "Positive": "0.024040576070547104",
   "Negative": "6.0364265664247796e-05",
   "Neutral": "0.9757600426673889",
   "Mixed": "0.0001389706158079207"
 },
 {
   "tweet_id": "1589964684555587585",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589964679849598976",
   "Positive": "0.031707488000392914",
   "Negative": "0.0025602588430047035",
   "Neutral": "0.965711236000061",
   "Mixed": "2.1011186618125066e-05"
 },
 {
   "tweet_id": "1589964679723778048",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589964679425974276",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964675823054851",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964671079313409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964668814393345",
   "Positive": "0.0042814272455871105",
   "Negative": "0.004383608233183622",
   "Neutral": "0.9913263916969299",
   "Mixed": "8.522306416125502e-06"
 },
 {
   "tweet_id": "1589964665739935745",
   "Positive": "0.030939510092139244",
   "Negative": "0.003681342350319028",
   "Neutral": "0.965360701084137",
   "Mixed": "1.845521546783857e-05"
 },
 {
   "tweet_id": "1589964661973454848",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964661566640129",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964661503713285",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964659834355712",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589964650955018241",
   "Positive": "0.029094329103827477",
   "Negative": "0.0030604517087340355",
   "Neutral": "0.9678254723548889",
   "Mixed": "1.9724919184227474e-05"
 },
 {
   "tweet_id": "1589964649964765184",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589964645766684674",
   "Positive": "0.00838183518499136",
   "Negative": "0.00017841685621533543",
   "Neutral": "0.9914308190345764",
   "Mixed": "9.029622560774442e-06"
 },
 {
   "tweet_id": "1589964634920222721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964634257514498",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964632902758400",
   "Positive": "0.026000315323472023",
   "Negative": "0.004108256194740534",
   "Neutral": "0.9698722958564758",
   "Mixed": "1.905483441078104e-05"
 },
 {
   "tweet_id": "1589964632130977792",
   "Positive": "0.01950686052441597",
   "Negative": "5.138219057698734e-05",
   "Neutral": "0.98032146692276",
   "Mixed": "0.00012028480705339462"
 },
 {
   "tweet_id": "1589964631858384896",
   "Positive": "0.02625366300344467",
   "Negative": "6.276041676755995e-05",
   "Neutral": "0.9735451340675354",
   "Mixed": "0.0001384634233545512"
 },
 {
   "tweet_id": "1589964622861586436",
   "Positive": "0.0012675925390794873",
   "Negative": "0.00029991482733748853",
   "Neutral": "0.9984304308891296",
   "Mixed": "2.032714746746933e-06"
 },
 {
   "tweet_id": "1589964616142295043",
   "Positive": "0.025692477822303772",
   "Negative": "0.008521738462150097",
   "Neutral": "0.9657482504844666",
   "Mixed": "3.749774259631522e-05"
 },
 {
   "tweet_id": "1589964610668736512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964597649641473",
   "Positive": "0.0241983812302351",
   "Negative": "6.208772538229823e-05",
   "Neutral": "0.9756041169166565",
   "Mixed": "0.0001353280822513625"
 },
 {
   "tweet_id": "1589964596886245377",
   "Positive": "0.02704651653766632",
   "Negative": "0.0023661786690354347",
   "Neutral": "0.9705681800842285",
   "Mixed": "1.910520404635463e-05"
 },
 {
   "tweet_id": "1589964596852719616",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589964593807634432",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589964590506340352",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589964589676236802",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964586736037888",
   "Positive": "0.025727247819304466",
   "Negative": "6.360474799294025e-05",
   "Neutral": "0.9740779399871826",
   "Mixed": "0.00013121306255925447"
 },
 {
   "tweet_id": "1589964583669989376",
   "Positive": "0.030748549848794937",
   "Negative": "0.0028870124369859695",
   "Neutral": "0.9663445353507996",
   "Mixed": "1.990607415791601e-05"
 },
 {
   "tweet_id": "1589964582474641408",
   "Positive": "0.002212958876043558",
   "Negative": "0.3036307990550995",
   "Neutral": "0.693744421005249",
   "Mixed": "0.0004117971984669566"
 },
 {
   "tweet_id": "1589964576141234178",
   "Positive": "0.0006988820969127119",
   "Negative": "5.0458918849471956e-05",
   "Neutral": "0.9992392063140869",
   "Mixed": "1.1394959074095823e-05"
 },
 {
   "tweet_id": "1589964571422650369",
   "Positive": "0.006866788491606712",
   "Negative": "0.003144176211208105",
   "Neutral": "0.9899380207061768",
   "Mixed": "5.097970279166475e-05"
 },
 {
   "tweet_id": "1589964570424389637",
   "Positive": "0.028452573344111443",
   "Negative": "0.0029822303913533688",
   "Neutral": "0.968546450138092",
   "Mixed": "1.8725733752944507e-05"
 },
 {
   "tweet_id": "1589964566922162177",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589964553332625408",
   "Positive": "0.028853198513388634",
   "Negative": "0.0028946292586624622",
   "Neutral": "0.9682322144508362",
   "Mixed": "1.991492899833247e-05"
 },
 {
   "tweet_id": "1589964545434734592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964544239337473",
   "Positive": "0.2792460322380066",
   "Negative": "0.084811732172966",
   "Neutral": "0.6358740329742432",
   "Mixed": "6.82258905726485e-05"
 },
 {
   "tweet_id": "1589964540510638080",
   "Positive": "0.028607415035367012",
   "Negative": "0.0031849874649196863",
   "Neutral": "0.9681830406188965",
   "Mixed": "2.4527193090762012e-05"
 },
 {
   "tweet_id": "1589964536546996224",
   "Positive": "0.024214984849095345",
   "Negative": "6.162744102766737e-05",
   "Neutral": "0.9755850434303284",
   "Mixed": "0.000138412433443591"
 },
 {
   "tweet_id": "1589964532444958722",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964531287355392",
   "Positive": "0.018730750307440758",
   "Negative": "5.7526383898220956e-05",
   "Neutral": "0.9810479879379272",
   "Mixed": "0.0001637144887354225"
 },
 {
   "tweet_id": "1589964524823941122",
   "Positive": "0.027154546231031418",
   "Negative": "0.0028105725068598986",
   "Neutral": "0.9700175523757935",
   "Mixed": "1.738480568747036e-05"
 },
 {
   "tweet_id": "1589964514879246339",
   "Positive": "0.356320858001709",
   "Negative": "0.0006329444004222751",
   "Neutral": "0.6428913474082947",
   "Mixed": "0.0001548097061458975"
 },
 {
   "tweet_id": "1589964512190693376",
   "Positive": "0.026627246290445328",
   "Negative": "0.003555014031007886",
   "Neutral": "0.9698017835617065",
   "Mixed": "1.5946603525662795e-05"
 },
 {
   "tweet_id": "1589964500434063360",
   "Positive": "0.0019723759032785892",
   "Negative": "0.000140410935273394",
   "Neutral": "0.9978833794593811",
   "Mixed": "3.861177447106456e-06"
 },
 {
   "tweet_id": "1589964499108655104",
   "Positive": "0.024787863716483116",
   "Negative": "0.0028812550008296967",
   "Neutral": "0.9723151326179504",
   "Mixed": "1.581874857947696e-05"
 },
 {
   "tweet_id": "1589964484969639937",
   "Positive": "0.02419416233897209",
   "Negative": "0.0028499297332018614",
   "Neutral": "0.9729406833648682",
   "Mixed": "1.5174147847574204e-05"
 },
 {
   "tweet_id": "1589964476383920130",
   "Positive": "0.8781094551086426",
   "Negative": "0.00015618263569194824",
   "Neutral": "0.12169788032770157",
   "Mixed": "3.642403316916898e-05"
 },
 {
   "tweet_id": "1589964473481281536",
   "Positive": "0.7443104982376099",
   "Negative": "0.0013649427564814687",
   "Neutral": "0.2542630732059479",
   "Mixed": "6.146381929283962e-05"
 },
 {
   "tweet_id": "1589964468070797313",
   "Positive": "0.02910376898944378",
   "Negative": "0.002955723088234663",
   "Neutral": "0.9679186344146729",
   "Mixed": "2.1959935111226514e-05"
 },
 {
   "tweet_id": "1589964459581526016",
   "Positive": "0.019529996439814568",
   "Negative": "5.86991518503055e-05",
   "Neutral": "0.9802324771881104",
   "Mixed": "0.00017875693447422236"
 },
 {
   "tweet_id": "1589964456242839552",
   "Positive": "0.0019042403437197208",
   "Negative": "5.488057286129333e-05",
   "Neutral": "0.9980280995368958",
   "Mixed": "1.268037249246845e-05"
 },
 {
   "tweet_id": "1589964452358950912",
   "Positive": "0.02470475435256958",
   "Negative": "0.002909150905907154",
   "Neutral": "0.9723683595657349",
   "Mixed": "1.774086558725685e-05"
 },
 {
   "tweet_id": "1589964434604425216",
   "Positive": "0.024779945611953735",
   "Negative": "0.0029569172766059637",
   "Neutral": "0.9722460508346558",
   "Mixed": "1.700605935184285e-05"
 },
 {
   "tweet_id": "1589964430233608192",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964421241405441",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964419676934144",
   "Positive": "0.02503473311662674",
   "Negative": "0.003664931980893016",
   "Neutral": "0.9712863564491272",
   "Mixed": "1.40431047839229e-05"
 },
 {
   "tweet_id": "1589964419156836353",
   "Positive": "0.5990726947784424",
   "Negative": "0.003600073978304863",
   "Neutral": "0.3966524302959442",
   "Mixed": "0.0006747336010448635"
 },
 {
   "tweet_id": "1589964405776994304",
   "Positive": "0.021590759977698326",
   "Negative": "6.213292363099754e-05",
   "Neutral": "0.9782146215438843",
   "Mixed": "0.00013245885202195495"
 },
 {
   "tweet_id": "1589964404397084672",
   "Positive": "0.025133011862635612",
   "Negative": "0.0034102227073162794",
   "Neutral": "0.9714431762695312",
   "Mixed": "1.3543289242079481e-05"
 },
 {
   "tweet_id": "1589964403893358592",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964402052124672",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964397748932609",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964397094572034",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964396217790464",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964395991408641",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964395542900737",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964394741772288",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964394149969920",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964392845787136",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964392296226816",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964392187174912",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964392124358656",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964388337070082",
   "Positive": "0.02550552785396576",
   "Negative": "0.0036486024037003517",
   "Neutral": "0.9708316922187805",
   "Mixed": "1.4192483831720892e-05"
 },
 {
   "tweet_id": "1589964380145618944",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964378115563522",
   "Positive": "0.025982879102230072",
   "Negative": "6.506891804747283e-05",
   "Neutral": "0.9738108515739441",
   "Mixed": "0.00014123291475698352"
 },
 {
   "tweet_id": "1589964377691750402",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964375854841859",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964373937848323",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964373786664961",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964373497417729",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964372365168640",
   "Positive": "0.02564006857573986",
   "Negative": "0.003901687217876315",
   "Neutral": "0.9704444408416748",
   "Mixed": "1.375595365971094e-05"
 },
 {
   "tweet_id": "1589964368740958208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964368502226944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964362051354624",
   "Positive": "0.025260863825678825",
   "Negative": "6.26555920462124e-05",
   "Neutral": "0.9745262861251831",
   "Mixed": "0.00015023746527731419"
 },
 {
   "tweet_id": "1589964357634764801",
   "Positive": "0.026817796751856804",
   "Negative": "0.0037838208954781294",
   "Neutral": "0.969383180141449",
   "Mixed": "1.5181947674136609e-05"
 },
 {
   "tweet_id": "1589964354442911746",
   "Positive": "0.41626372933387756",
   "Negative": "0.0007051359280012548",
   "Neutral": "0.5829112529754639",
   "Mixed": "0.00011996934335911646"
 },
 {
   "tweet_id": "1589964351645315072",
   "Positive": "0.007442288566380739",
   "Negative": "0.0018079467117786407",
   "Neutral": "0.9907341003417969",
   "Mixed": "1.570681342855096e-05"
 },
 {
   "tweet_id": "1589964346318544896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964341067264002",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964340643655681",
   "Positive": "0.025304893031716347",
   "Negative": "0.0035111161414533854",
   "Neutral": "0.9711691737174988",
   "Mixed": "1.4763259969186038e-05"
 },
 {
   "tweet_id": "1589964337166561282",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964337078493184",
   "Positive": "0.579240620136261",
   "Negative": "0.0022963909432291985",
   "Neutral": "0.4181627929210663",
   "Mixed": "0.000300178537145257"
 },
 {
   "tweet_id": "1589964324252311552",
   "Positive": "0.02528326027095318",
   "Negative": "0.0035992374178022146",
   "Neutral": "0.9711035490036011",
   "Mixed": "1.4039349480299279e-05"
 },
 {
   "tweet_id": "1589964318342541313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964312520450050",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1589964308255240192",
   "Positive": "0.025315340608358383",
   "Negative": "0.0032508934382349253",
   "Neutral": "0.971419095993042",
   "Mixed": "1.4744206055183895e-05"
 },
 {
   "tweet_id": "1589964301292670978",
   "Positive": "0.0004380284226499498",
   "Negative": "0.986849308013916",
   "Neutral": "0.012546954676508904",
   "Mixed": "0.00016573617176618427"
 },
 {
   "tweet_id": "1589964299585622016",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589964292665004032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964291603836928",
   "Positive": "0.02354309894144535",
   "Negative": "0.0032222445588558912",
   "Neutral": "0.9732220768928528",
   "Mixed": "1.2557110494526569e-05"
 },
 {
   "tweet_id": "1589964291528364034",
   "Positive": "0.22485782206058502",
   "Negative": "0.014202671125531197",
   "Neutral": "0.7608784437179565",
   "Mixed": "6.10405913903378e-05"
 },
 {
   "tweet_id": "1589964267289448455",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964267234938880",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589964266710663169",
   "Positive": "0.013902632519602776",
   "Negative": "9.115489956457168e-05",
   "Neutral": "0.9847946763038635",
   "Mixed": "0.001211591181345284"
 },
 {
   "tweet_id": "1589964265234235392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964245026111488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964242673106945",
   "Positive": "0.012541413307189941",
   "Negative": "8.198154682759196e-05",
   "Neutral": "0.9865352511405945",
   "Mixed": "0.0008413440664298832"
 },
 {
   "tweet_id": "1589964240307486720",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589964237426012162",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964221621866496",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589964220749451264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964218970697729",
   "Positive": "0.013548087328672409",
   "Negative": "8.454071212327108e-05",
   "Neutral": "0.9853675961494446",
   "Mixed": "0.0009997934103012085"
 },
 {
   "tweet_id": "1589964211689783298",
   "Positive": "0.8263620734214783",
   "Negative": "0.0004392832925077528",
   "Neutral": "0.17283236980438232",
   "Mixed": "0.00036630217800848186"
 },
 {
   "tweet_id": "1589964209114480641",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964201153306624",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589964200910020609",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589964197966020614",
   "Positive": "0.015134448185563087",
   "Negative": "8.416092896368355e-05",
   "Neutral": "0.9846910834312439",
   "Mixed": "9.032301750266925e-05"
 },
 {
   "tweet_id": "1589964195071942658",
   "Positive": "0.01444788184016943",
   "Negative": "8.682781481184065e-05",
   "Neutral": "0.9845029711723328",
   "Mixed": "0.0009623220539651811"
 },
 {
   "tweet_id": "1589964193494892545",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589964179439751170",
   "Positive": "0.004902326036244631",
   "Negative": "0.004019385669380426",
   "Neutral": "0.9907429814338684",
   "Mixed": "0.0003352701314724982"
 },
 {
   "tweet_id": "1589964172091351044",
   "Positive": "0.013594469986855984",
   "Negative": "8.063725545071065e-05",
   "Neutral": "0.9852221608161926",
   "Mixed": "0.0011026632273569703"
 },
 {
   "tweet_id": "1589964165082656768",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589964161353932800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964158434693120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964148242120707",
   "Positive": "0.013147946447134018",
   "Negative": "8.403521496802568e-05",
   "Neutral": "0.9855343103408813",
   "Mixed": "0.0012337331427261233"
 },
 {
   "tweet_id": "1589964145147129856",
   "Positive": "0.7234653234481812",
   "Negative": "0.0006366824964061379",
   "Neutral": "0.27574488520622253",
   "Mixed": "0.00015317030192818493"
 },
 {
   "tweet_id": "1589964141972041728",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589964139858112512",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964132509708289",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589964125916233729",
   "Positive": "0.03012489527463913",
   "Negative": "6.41092992736958e-05",
   "Neutral": "0.9695303440093994",
   "Mixed": "0.00028076875605620444"
 },
 {
   "tweet_id": "1589964123382878211",
   "Positive": "0.013405369594693184",
   "Negative": "8.295636507682502e-05",
   "Neutral": "0.9856040477752686",
   "Mixed": "0.0009076194255612791"
 },
 {
   "tweet_id": "1589964121692581888",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589964116529393665",
   "Positive": "0.027384070679545403",
   "Negative": "6.448815111070871e-05",
   "Neutral": "0.9723771214485168",
   "Mixed": "0.00017432088498026133"
 },
 {
   "tweet_id": "1589964111282307076",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964104814714881",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964102054862848",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589964099550871552",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964098795888640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964097898311680",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589964095440027649",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1589964094857441280",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964094777757697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964092525379585",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964090486968321",
   "Positive": "0.013163035735487938",
   "Negative": "8.547810284653679e-05",
   "Neutral": "0.9857770800590515",
   "Mixed": "0.0009744419949129224"
 },
 {
   "tweet_id": "1589964090403094531",
   "Positive": "0.023928867653012276",
   "Negative": "0.0026067690923810005",
   "Neutral": "0.973450779914856",
   "Mixed": "1.3594477422884665e-05"
 },
 {
   "tweet_id": "1589964089203511297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964087861334018",
   "Positive": "0.002199381124228239",
   "Negative": "0.08242352306842804",
   "Neutral": "0.9152498245239258",
   "Mixed": "0.00012719520600512624"
 },
 {
   "tweet_id": "1589964087395393537",
   "Positive": "0.9844676852226257",
   "Negative": "0.0005747331888414919",
   "Neutral": "0.014636308886110783",
   "Mixed": "0.0003212644369341433"
 },
 {
   "tweet_id": "1589964080659722246",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964075848830976",
   "Positive": "0.02409929409623146",
   "Negative": "0.003116219537332654",
   "Neutral": "0.9727712273597717",
   "Mixed": "1.3176908396417275e-05"
 },
 {
   "tweet_id": "1589964066382307333",
   "Positive": "0.023132670670747757",
   "Negative": "6.142550410004333e-05",
   "Neutral": "0.9766550064086914",
   "Mixed": "0.00015092013927642256"
 },
 {
   "tweet_id": "1589964065530871808",
   "Positive": "0.013492995873093605",
   "Negative": "8.749228436499834e-05",
   "Neutral": "0.985382080078125",
   "Mixed": "0.001037467853166163"
 },
 {
   "tweet_id": "1589964061001023495",
   "Positive": "0.02440786361694336",
   "Negative": "0.0031143941450864077",
   "Neutral": "0.9724642634391785",
   "Mixed": "1.3434865650197025e-05"
 },
 {
   "tweet_id": "1589964058496995328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589964056949329927",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964050209067008",
   "Positive": "0.3301407992839813",
   "Negative": "0.00015837635146453977",
   "Neutral": "0.6696376204490662",
   "Mixed": "6.32317824056372e-05"
 },
 {
   "tweet_id": "1589964045733728259",
   "Positive": "0.02690739929676056",
   "Negative": "0.0029226457700133324",
   "Neutral": "0.9701552987098694",
   "Mixed": "1.464888464397518e-05"
 },
 {
   "tweet_id": "1589964043649159168",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589964040037552128",
   "Positive": "0.012109249830245972",
   "Negative": "8.381358202314004e-05",
   "Neutral": "0.9870061874389648",
   "Mixed": "0.0008007488795556128"
 },
 {
   "tweet_id": "1589964039194808320",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589964038980907008",
   "Positive": "0.6599288582801819",
   "Negative": "0.0008031455217860639",
   "Neutral": "0.33901694416999817",
   "Mixed": "0.0002510630583856255"
 },
 {
   "tweet_id": "1589964037575823360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589964029220761602",
   "Positive": "0.025354251265525818",
   "Negative": "0.0033622528426349163",
   "Neutral": "0.9712694883346558",
   "Mixed": "1.3962029697722755e-05"
 },
 {
   "tweet_id": "1589964028188971009",
   "Positive": "0.547311007976532",
   "Negative": "0.0009944610064849257",
   "Neutral": "0.45154064893722534",
   "Mixed": "0.0001539110962767154"
 },
 {
   "tweet_id": "1589964023612985344",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589964020660199424",
   "Positive": "0.0029822501819580793",
   "Negative": "0.003613025415688753",
   "Neutral": "0.9933542013168335",
   "Mixed": "5.055164365330711e-05"
 },
 {
   "tweet_id": "1589964014540718085",
   "Positive": "0.02523985505104065",
   "Negative": "0.0025369722861796618",
   "Neutral": "0.9722075462341309",
   "Mixed": "1.562259058118798e-05"
 },
 {
   "tweet_id": "1589964010648006656",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964007884357632",
   "Positive": "0.547311007976532",
   "Negative": "0.0009944610064849257",
   "Neutral": "0.45154064893722534",
   "Mixed": "0.0001539110962767154"
 },
 {
   "tweet_id": "1589964007347130368",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964004541304835",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589964003241230336",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589964001664176129",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589963999680286722",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589963996735864833",
   "Positive": "0.580203115940094",
   "Negative": "0.0011202221503481269",
   "Neutral": "0.4184589684009552",
   "Mixed": "0.0002176528359996155"
 },
 {
   "tweet_id": "1589963988041097217",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963987961401346",
   "Positive": "0.003288442734628916",
   "Negative": "0.0038504903204739094",
   "Neutral": "0.9927936792373657",
   "Mixed": "6.749222666257992e-05"
 },
 {
   "tweet_id": "1589963987168690176",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963986849894403",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963985247358976",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963984584966148",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963984496603136",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963983666450433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963982676275200",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963982382583808",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963981958967297",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963981690916865",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963981338419202",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963981112107008",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963980499750913",
   "Positive": "0.580203115940094",
   "Negative": "0.0011202221503481269",
   "Neutral": "0.4184589684009552",
   "Mixed": "0.0002176528359996155"
 },
 {
   "tweet_id": "1589963979765739520",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963979384029184",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963978855550977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963972815773698",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963971150614529",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589963967279267840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963966767591426",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963960823922688",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963960727609346",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963960564211714",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963960140255232",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963959565705221",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589963954931236864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963949671612417",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963946651713536",
   "Positive": "0.03498731181025505",
   "Negative": "0.003621745156124234",
   "Neutral": "0.9613616466522217",
   "Mixed": "2.932143434009049e-05"
 },
 {
   "tweet_id": "1589963945984798721",
   "Positive": "0.008677536621689796",
   "Negative": "0.02207891084253788",
   "Neutral": "0.9691869020462036",
   "Mixed": "5.668418816640042e-05"
 },
 {
   "tweet_id": "1589963941404610562",
   "Positive": "0.002907944144681096",
   "Negative": "0.004029429983347654",
   "Neutral": "0.9930076599121094",
   "Mixed": "5.4976175306364894e-05"
 },
 {
   "tweet_id": "1589963935687782404",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589963935259963392",
   "Positive": "0.03491797298192978",
   "Negative": "0.002762935357168317",
   "Neutral": "0.9622907638549805",
   "Mixed": "2.8314641895121895e-05"
 },
 {
   "tweet_id": "1589963931971653635",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963929018830850",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963926418370563",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589963925504012294",
   "Positive": "0.005703248083591461",
   "Negative": "0.24744334816932678",
   "Neutral": "0.5776506066322327",
   "Mixed": "0.1692027747631073"
 },
 {
   "tweet_id": "1589963920202436610",
   "Positive": "0.033505965024232864",
   "Negative": "0.0035089394077658653",
   "Neutral": "0.9629548788070679",
   "Mixed": "3.018569441337604e-05"
 },
 {
   "tweet_id": "1589963906776444930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963906315087872",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963903517462528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963903513292800",
   "Positive": "0.033533111214637756",
   "Negative": "0.0032843644730746746",
   "Neutral": "0.9631527662277222",
   "Mixed": "2.9728464141953737e-05"
 },
 {
   "tweet_id": "1589963897448329216",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963891341418496",
   "Positive": "0.02748679742217064",
   "Negative": "0.002893835073336959",
   "Neutral": "0.9696032404899597",
   "Mixed": "1.6083638911368325e-05"
 },
 {
   "tweet_id": "1589963889806311425",
   "Positive": "0.03507711738348007",
   "Negative": "0.003567649982869625",
   "Neutral": "0.961324155330658",
   "Mixed": "3.103874041698873e-05"
 },
 {
   "tweet_id": "1589963874618736640",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589963874207694849",
   "Positive": "0.03511879965662956",
   "Negative": "0.002977642696350813",
   "Neutral": "0.961875319480896",
   "Mixed": "2.822285023285076e-05"
 },
 {
   "tweet_id": "1589963873154912260",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589963870743203840",
   "Positive": "0.023699291050434113",
   "Negative": "0.002792079234495759",
   "Neutral": "0.973494827747345",
   "Mixed": "1.382828031637473e-05"
 },
 {
   "tweet_id": "1589963864342683648",
   "Positive": "0.013847173191606998",
   "Negative": "9.148150274995714e-05",
   "Neutral": "0.9848850965499878",
   "Mixed": "0.0011763639049604535"
 },
 {
   "tweet_id": "1589963863990362112",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589963857749237764",
   "Positive": "0.034238941967487335",
   "Negative": "0.0032012967858463526",
   "Neutral": "0.9625301361083984",
   "Mixed": "2.9615077437483706e-05"
 },
 {
   "tweet_id": "1589963851901988864",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1589963851105128449",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589963844767870977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963843195002885",
   "Positive": "0.03502553328871727",
   "Negative": "0.0033046617172658443",
   "Neutral": "0.9616394639015198",
   "Mixed": "3.0408709790208377e-05"
 },
 {
   "tweet_id": "1589963841928310785",
   "Positive": "0.0223798006772995",
   "Negative": "0.0030667658429592848",
   "Neutral": "0.974541425704956",
   "Mixed": "1.1985295714111999e-05"
 },
 {
   "tweet_id": "1589963840418373638",
   "Positive": "0.012424700893461704",
   "Negative": "8.532890933565795e-05",
   "Neutral": "0.9866002202033997",
   "Mixed": "0.000889774935785681"
 },
 {
   "tweet_id": "1589963835926253568",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589963828791738369",
   "Positive": "0.03684091940522194",
   "Negative": "0.0034193338360637426",
   "Neutral": "0.9597055912017822",
   "Mixed": "3.415248283999972e-05"
 },
 {
   "tweet_id": "1589963823129448453",
   "Positive": "0.02258698269724846",
   "Negative": "0.004132717382162809",
   "Neutral": "0.9732692837715149",
   "Mixed": "1.1037185686291195e-05"
 },
 {
   "tweet_id": "1589963823087484928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963823083294721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963819237150724",
   "Positive": "0.0056270030327141285",
   "Negative": "0.0001233499642694369",
   "Neutral": "0.994120180606842",
   "Mixed": "0.00012944384070578963"
 },
 {
   "tweet_id": "1589963817567793155",
   "Positive": "0.013485214672982693",
   "Negative": "8.843110845191404e-05",
   "Neutral": "0.9854071140289307",
   "Mixed": "0.0010193133493885398"
 },
 {
   "tweet_id": "1589963816447901697",
   "Positive": "0.014535819180309772",
   "Negative": "0.0001026080281008035",
   "Neutral": "0.9839339852333069",
   "Mixed": "0.0014275654684752226"
 },
 {
   "tweet_id": "1589963814518534145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963814057160706",
   "Positive": "0.03545556589961052",
   "Negative": "0.003542855614796281",
   "Neutral": "0.9609701037406921",
   "Mixed": "3.155883678118698e-05"
 },
 {
   "tweet_id": "1589963812471713793",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589963806507421698",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589963804448034816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963803869196288",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589963801662980097",
   "Positive": "0.02467179112136364",
   "Negative": "0.0031569006387144327",
   "Neutral": "0.9721588492393494",
   "Mixed": "1.2505851373134647e-05"
 },
 {
   "tweet_id": "1589963799536480259",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963796877307904",
   "Positive": "0.03432084619998932",
   "Negative": "0.0033375518396496773",
   "Neutral": "0.9623132944107056",
   "Mixed": "2.83246481558308e-05"
 },
 {
   "tweet_id": "1589963793836429312",
   "Positive": "0.013804946094751358",
   "Negative": "8.677126606926322e-05",
   "Neutral": "0.9851753115653992",
   "Mixed": "0.0009329773602075875"
 },
 {
   "tweet_id": "1589963789511708672",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1589963784990646273",
   "Positive": "0.016333209350705147",
   "Negative": "4.9772352213039994e-05",
   "Neutral": "0.9834910035133362",
   "Mixed": "0.00012608345423359424"
 },
 {
   "tweet_id": "1589963781098340356",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963779743547395",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963777386348548",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963771501367296",
   "Positive": "0.01299519743770361",
   "Negative": "8.528189937351272e-05",
   "Neutral": "0.9860237836837769",
   "Mixed": "0.0008957497775554657"
 },
 {
   "tweet_id": "1589963771187187712",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589963771120087040",
   "Positive": "0.024818968027830124",
   "Negative": "0.003057086607441306",
   "Neutral": "0.9721092581748962",
   "Mixed": "1.476874513173243e-05"
 },
 {
   "tweet_id": "1589963766384721924",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963763306074113",
   "Positive": "0.9952334761619568",
   "Negative": "5.8350953622721136e-05",
   "Neutral": "0.004693570081144571",
   "Mixed": "1.4613567145715933e-05"
 },
 {
   "tweet_id": "1589963759912890369",
   "Positive": "0.0067282565869390965",
   "Negative": "0.0001153806151705794",
   "Neutral": "0.9930063486099243",
   "Mixed": "0.00014998709957581013"
 },
 {
   "tweet_id": "1589963759447314433",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589963757769588736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963756947197953",
   "Positive": "0.016626376658678055",
   "Negative": "0.5480955839157104",
   "Neutral": "0.06872141361236572",
   "Mixed": "0.3665565848350525"
 },
 {
   "tweet_id": "1589963755227877376",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963753059418113",
   "Positive": "0.07266180217266083",
   "Negative": "0.005187597591429949",
   "Neutral": "0.9112500548362732",
   "Mixed": "0.010900484398007393"
 },
 {
   "tweet_id": "1589963750651887616",
   "Positive": "0.0229704100638628",
   "Negative": "0.002966909436509013",
   "Neutral": "0.9740501046180725",
   "Mixed": "1.2500198863563128e-05"
 },
 {
   "tweet_id": "1589963748273360896",
   "Positive": "0.013214793056249619",
   "Negative": "8.296328451251611e-05",
   "Neutral": "0.9858658313751221",
   "Mixed": "0.0008364600362256169"
 },
 {
   "tweet_id": "1589963748118523910",
   "Positive": "0.034801460802555084",
   "Negative": "0.0035799583420157433",
   "Neutral": "0.9615871906280518",
   "Mixed": "3.138084139209241e-05"
 },
 {
   "tweet_id": "1589963748013637635",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963740560384005",
   "Positive": "0.0013972234446555376",
   "Negative": "0.007554341107606888",
   "Neutral": "0.9910354614257812",
   "Mixed": "1.3036302334512584e-05"
 },
 {
   "tweet_id": "1589963733304250369",
   "Positive": "0.022540783509612083",
   "Negative": "0.002863810397684574",
   "Neutral": "0.9745832085609436",
   "Mixed": "1.2175905794720165e-05"
 },
 {
   "tweet_id": "1589963731081269248",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963724580081664",
   "Positive": "0.012622088193893433",
   "Negative": "8.12202415545471e-05",
   "Neutral": "0.9865569472312927",
   "Mixed": "0.0007398005109280348"
 },
 {
   "tweet_id": "1589963721518231553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963716262780931",
   "Positive": "0.024236366152763367",
   "Negative": "0.0034519019536674023",
   "Neutral": "0.9722990393638611",
   "Mixed": "1.271300880034687e-05"
 },
 {
   "tweet_id": "1589963708658483201",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589963704707448834",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589963702429974534",
   "Positive": "0.03181200847029686",
   "Negative": "7.05842103343457e-05",
   "Neutral": "0.9677372574806213",
   "Mixed": "0.0003801229177042842"
 },
 {
   "tweet_id": "1589963699997270016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963698239852545",
   "Positive": "0.014337578788399696",
   "Negative": "8.718750905245543e-05",
   "Neutral": "0.984681248664856",
   "Mixed": "0.000894047028850764"
 },
 {
   "tweet_id": "1589963697128374273",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589963695362228224",
   "Positive": "0.03277761861681938",
   "Negative": "0.002508133416995406",
   "Neutral": "0.9646795392036438",
   "Mixed": "3.468486465862952e-05"
 },
 {
   "tweet_id": "1589963690513944577",
   "Positive": "0.13311517238616943",
   "Negative": "0.06038222089409828",
   "Neutral": "0.6610114574432373",
   "Mixed": "0.14549116790294647"
 },
 {
   "tweet_id": "1589963686822961152",
   "Positive": "0.003010580549016595",
   "Negative": "0.003866615006700158",
   "Neutral": "0.9930625557899475",
   "Mixed": "6.026922710589133e-05"
 },
 {
   "tweet_id": "1589963676727283712",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963672125784065",
   "Positive": "0.015024295076727867",
   "Negative": "9.731245518196374e-05",
   "Neutral": "0.9834656715393066",
   "Mixed": "0.0014127646572887897"
 },
 {
   "tweet_id": "1589963671421489159",
   "Positive": "0.02511056885123253",
   "Negative": "0.0034092911519110203",
   "Neutral": "0.9714654684066772",
   "Mixed": "1.4736924640601501e-05"
 },
 {
   "tweet_id": "1589963669311746051",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963666900017152",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963662885679105",
   "Positive": "0.49087515473365784",
   "Negative": "0.000362094899173826",
   "Neutral": "0.5085501074790955",
   "Mixed": "0.00021260959329083562"
 },
 {
   "tweet_id": "1589963660247855106",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963654036074496",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963652781985793",
   "Positive": "0.016086099669337273",
   "Negative": "4.7909470595186576e-05",
   "Neutral": "0.9837682843208313",
   "Mixed": "9.769365715328604e-05"
 },
 {
   "tweet_id": "1589963651452403713",
   "Positive": "0.02294725552201271",
   "Negative": "0.0033055227249860764",
   "Neutral": "0.9737358689308167",
   "Mixed": "1.1370663742127363e-05"
 },
 {
   "tweet_id": "1589963646033010688",
   "Positive": "0.13957688212394714",
   "Negative": "0.0007814117707312107",
   "Neutral": "0.8596091866493225",
   "Mixed": "3.252217356930487e-05"
 },
 {
   "tweet_id": "1589963645156741120",
   "Positive": "0.0013972234446555376",
   "Negative": "0.007554341107606888",
   "Neutral": "0.9910354614257812",
   "Mixed": "1.3036302334512584e-05"
 },
 {
   "tweet_id": "1589963644338831360",
   "Positive": "0.012550462037324905",
   "Negative": "8.906703442335129e-05",
   "Neutral": "0.9864954948425293",
   "Mixed": "0.0008648857474327087"
 },
 {
   "tweet_id": "1589963625120542721",
   "Positive": "0.0077994572930037975",
   "Negative": "0.00014672156248707324",
   "Neutral": "0.9919577836990356",
   "Mixed": "9.596538438927382e-05"
 },
 {
   "tweet_id": "1589963624772431873",
   "Positive": "0.024508193135261536",
   "Negative": "0.0031764344312250614",
   "Neutral": "0.9723009467124939",
   "Mixed": "1.4525955521094147e-05"
 },
 {
   "tweet_id": "1589963624512380930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963620246769664",
   "Positive": "0.0019356098491698503",
   "Negative": "0.5580588579177856",
   "Neutral": "0.43944016098976135",
   "Mixed": "0.0005653034895658493"
 },
 {
   "tweet_id": "1589963613951123457",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589963607756124160",
   "Positive": "0.035843390971422195",
   "Negative": "0.0036087515763938427",
   "Neutral": "0.9605160355567932",
   "Mixed": "3.179563282174058e-05"
 },
 {
   "tweet_id": "1589963604195168256",
   "Positive": "0.024283068254590034",
   "Negative": "0.002709219930693507",
   "Neutral": "0.9729929566383362",
   "Mixed": "1.473070642532548e-05"
 },
 {
   "tweet_id": "1589963602660044801",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963600613216256",
   "Positive": "0.04613899067044258",
   "Negative": "0.0014096841914579272",
   "Neutral": "0.9524216651916504",
   "Mixed": "2.96254875138402e-05"
 },
 {
   "tweet_id": "1589963598721609729",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963592476291072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963590400094208",
   "Positive": "0.024640986695885658",
   "Negative": "6.256950291572139e-05",
   "Neutral": "0.975145697593689",
   "Mixed": "0.00015079086006153375"
 },
 {
   "tweet_id": "1589963587959017473",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963577854947330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963567964770304",
   "Positive": "0.02783573791384697",
   "Negative": "0.0024622660130262375",
   "Neutral": "0.9696827530860901",
   "Mixed": "1.932552368089091e-05"
 },
 {
   "tweet_id": "1589963567259738113",
   "Positive": "0.001306656515225768",
   "Negative": "0.0006357213133014739",
   "Neutral": "0.9980548620223999",
   "Mixed": "2.76892319561739e-06"
 },
 {
   "tweet_id": "1589963555927109633",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589963542928953344",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589963538856292358",
   "Positive": "0.013847173191606998",
   "Negative": "9.148150274995714e-05",
   "Neutral": "0.9848850965499878",
   "Mixed": "0.0011763639049604535"
 },
 {
   "tweet_id": "1589963536813678592",
   "Positive": "0.023677948862314224",
   "Negative": "5.7925910368794575e-05",
   "Neutral": "0.9759820699691772",
   "Mixed": "0.0002820003719534725"
 },
 {
   "tweet_id": "1589963534565527558",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589963531482718210",
   "Positive": "0.6873191595077515",
   "Negative": "0.0059546418488025665",
   "Neutral": "0.30635353922843933",
   "Mixed": "0.00037272044573910534"
 },
 {
   "tweet_id": "1589963529041297408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963527535865856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963520510418944",
   "Positive": "0.024940242990851402",
   "Negative": "0.00325742457062006",
   "Neutral": "0.9717883467674255",
   "Mixed": "1.4004130207467824e-05"
 },
 {
   "tweet_id": "1589963516433530881",
   "Positive": "0.023772165179252625",
   "Negative": "5.9505924582481384e-05",
   "Neutral": "0.9760391116142273",
   "Mixed": "0.00012930994853377342"
 },
 {
   "tweet_id": "1589963512369254400",
   "Positive": "0.003010580549016595",
   "Negative": "0.003866615006700158",
   "Neutral": "0.9930625557899475",
   "Mixed": "6.026922710589133e-05"
 },
 {
   "tweet_id": "1589963511660449792",
   "Positive": "0.012424700893461704",
   "Negative": "8.532890933565795e-05",
   "Neutral": "0.9866002202033997",
   "Mixed": "0.000889774935785681"
 },
 {
   "tweet_id": "1589963509110312960",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963505528377344",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589963503150198786",
   "Positive": "0.024808445945382118",
   "Negative": "0.0032205816823989153",
   "Neutral": "0.9719478487968445",
   "Mixed": "2.3087455701897852e-05"
 },
 {
   "tweet_id": "1589963498049896448",
   "Positive": "0.02246384136378765",
   "Negative": "6.021989247528836e-05",
   "Neutral": "0.9773573279380798",
   "Mixed": "0.00011860765516757965"
 },
 {
   "tweet_id": "1589963495369768963",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963492026908672",
   "Positive": "0.002692262642085552",
   "Negative": "0.0006261593080125749",
   "Neutral": "0.9966784715652466",
   "Mixed": "3.0726712338946527e-06"
 },
 {
   "tweet_id": "1589963488696619016",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589963488243642369",
   "Positive": "0.046956613659858704",
   "Negative": "0.0020516524091362953",
   "Neutral": "0.950961709022522",
   "Mixed": "3.0029736080905423e-05"
 },
 {
   "tweet_id": "1589963488105234432",
   "Positive": "0.013485214672982693",
   "Negative": "8.843110845191404e-05",
   "Neutral": "0.9854071140289307",
   "Mixed": "0.0010193133493885398"
 },
 {
   "tweet_id": "1589963485534126080",
   "Positive": "0.007924513891339302",
   "Negative": "0.00013226833834778517",
   "Neutral": "0.9917908906936646",
   "Mixed": "0.00015231239376589656"
 },
 {
   "tweet_id": "1589963476075970561",
   "Positive": "0.023656539618968964",
   "Negative": "6.063166802050546e-05",
   "Neutral": "0.9761469960212708",
   "Mixed": "0.0001357514556730166"
 },
 {
   "tweet_id": "1589963475383877633",
   "Positive": "0.02301989495754242",
   "Negative": "0.0036517444532364607",
   "Neutral": "0.9733162522315979",
   "Mixed": "1.201666600536555e-05"
 },
 {
   "tweet_id": "1589963471621615616",
   "Positive": "0.0012887113261967897",
   "Negative": "0.5609123706817627",
   "Neutral": "0.4374914765357971",
   "Mixed": "0.00030744620016776025"
 },
 {
   "tweet_id": "1589963468568170496",
   "Positive": "0.009857630357146263",
   "Negative": "0.00013428583042696118",
   "Neutral": "0.9898425936698914",
   "Mixed": "0.00016555603360757232"
 },
 {
   "tweet_id": "1589963465548238848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963461693706243",
   "Positive": "0.025612497702240944",
   "Negative": "6.272141763474792e-05",
   "Neutral": "0.9742012023925781",
   "Mixed": "0.00012352860358078033"
 },
 {
   "tweet_id": "1589963459550392320",
   "Positive": "0.013804946094751358",
   "Negative": "8.677126606926322e-05",
   "Neutral": "0.9851753115653992",
   "Mixed": "0.0009329773602075875"
 },
 {
   "tweet_id": "1589963455809093632",
   "Positive": "0.023948051035404205",
   "Negative": "0.0025654586497694254",
   "Neutral": "0.9734728336334229",
   "Mixed": "1.3679776202479843e-05"
 },
 {
   "tweet_id": "1589963455557414913",
   "Positive": "0.05441330373287201",
   "Negative": "0.06491842120885849",
   "Neutral": "0.8806111812591553",
   "Mixed": "5.7151766668539494e-05"
 },
 {
   "tweet_id": "1589963446116044800",
   "Positive": "0.023737110197544098",
   "Negative": "6.0093170759500936e-05",
   "Neutral": "0.9760673642158508",
   "Mixed": "0.00013538433995563537"
 },
 {
   "tweet_id": "1589963445272973312",
   "Positive": "0.008982936851680279",
   "Negative": "0.0001497493067290634",
   "Neutral": "0.9907364249229431",
   "Mixed": "0.00013082384248264134"
 },
 {
   "tweet_id": "1589963444266340352",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963442555097089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963434845958146",
   "Positive": "0.02445722371339798",
   "Negative": "0.0034298489335924387",
   "Neutral": "0.9720997214317322",
   "Mixed": "1.3222501365817152e-05"
 },
 {
   "tweet_id": "1589963430999781376",
   "Positive": "0.01299519743770361",
   "Negative": "8.528189937351272e-05",
   "Neutral": "0.9860237836837769",
   "Mixed": "0.0008957497775554657"
 },
 {
   "tweet_id": "1589963428231548930",
   "Positive": "0.06154573708772659",
   "Negative": "0.002228842116892338",
   "Neutral": "0.9362015128135681",
   "Mixed": "2.3913431505206972e-05"
 },
 {
   "tweet_id": "1589963428172791810",
   "Positive": "0.023120854049921036",
   "Negative": "5.847185821039602e-05",
   "Neutral": "0.9766919612884521",
   "Mixed": "0.00012871141370851547"
 },
 {
   "tweet_id": "1589963426981634049",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963425459077120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963422351134721",
   "Positive": "0.14526551961898804",
   "Negative": "0.030598578974604607",
   "Neutral": "0.8240435719490051",
   "Mixed": "9.235196921508759e-05"
 },
 {
   "tweet_id": "1589963416911085568",
   "Positive": "0.022674117237329483",
   "Negative": "0.002989828120917082",
   "Neutral": "0.9743236303329468",
   "Mixed": "1.2465073268685956e-05"
 },
 {
   "tweet_id": "1589963412737781761",
   "Positive": "0.024040576070547104",
   "Negative": "6.0364265664247796e-05",
   "Neutral": "0.9757600426673889",
   "Mixed": "0.0001389706158079207"
 },
 {
   "tweet_id": "1589963412007976963",
   "Positive": "0.02214127592742443",
   "Negative": "5.7807650591712445e-05",
   "Neutral": "0.9776598811149597",
   "Mixed": "0.00014104451111052185"
 },
 {
   "tweet_id": "1589963410279919616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963397642477570",
   "Positive": "0.02625366300344467",
   "Negative": "6.276041676755995e-05",
   "Neutral": "0.9735451340675354",
   "Mixed": "0.0001384634233545512"
 },
 {
   "tweet_id": "1589963397529231361",
   "Positive": "0.02481408417224884",
   "Negative": "0.003979338798671961",
   "Neutral": "0.9711876511573792",
   "Mixed": "1.9008790332009085e-05"
 },
 {
   "tweet_id": "1589963394173788163",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963390042386432",
   "Positive": "0.006949175149202347",
   "Negative": "0.00011194311082363129",
   "Neutral": "0.9927812218666077",
   "Mixed": "0.0001576400245539844"
 },
 {
   "tweet_id": "1589963388310151168",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963383457316865",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963380340953088",
   "Positive": "0.025727247819304466",
   "Negative": "6.360474799294025e-05",
   "Neutral": "0.9740779399871826",
   "Mixed": "0.00013121306255925447"
 },
 {
   "tweet_id": "1589963378898132994",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963378872590336",
   "Positive": "0.013214793056249619",
   "Negative": "8.296328451251611e-05",
   "Neutral": "0.9858658313751221",
   "Mixed": "0.0008364600362256169"
 },
 {
   "tweet_id": "1589963377870540802",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963374154371073",
   "Positive": "0.023132670670747757",
   "Negative": "6.142550410004333e-05",
   "Neutral": "0.9766550064086914",
   "Mixed": "0.00015092013927642256"
 },
 {
   "tweet_id": "1589963371105095680",
   "Positive": "0.11078359931707382",
   "Negative": "0.0010718230623751879",
   "Neutral": "0.8881286978721619",
   "Mixed": "1.5848972907406278e-05"
 },
 {
   "tweet_id": "1589963370832482304",
   "Positive": "0.022455278784036636",
   "Negative": "0.003244078950956464",
   "Neutral": "0.9742865562438965",
   "Mixed": "1.4155931239656638e-05"
 },
 {
   "tweet_id": "1589963368814718976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963364385837057",
   "Positive": "0.024214984849095345",
   "Negative": "6.162744102766737e-05",
   "Neutral": "0.9755850434303284",
   "Mixed": "0.000138412433443591"
 },
 {
   "tweet_id": "1589963360715833344",
   "Positive": "0.058842822909355164",
   "Negative": "0.0013918554177507758",
   "Neutral": "0.939698338508606",
   "Mixed": "6.701945676468313e-05"
 },
 {
   "tweet_id": "1589963357360394244",
   "Positive": "0.1634150892496109",
   "Negative": "0.014260662719607353",
   "Neutral": "0.8223084807395935",
   "Mixed": "1.5817482562852092e-05"
 },
 {
   "tweet_id": "1589963350947266560",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589963346534891525",
   "Positive": "0.025260863825678825",
   "Negative": "6.26555920462124e-05",
   "Neutral": "0.9745262861251831",
   "Mixed": "0.00015023746527731419"
 },
 {
   "tweet_id": "1589963345859579905",
   "Positive": "0.04958757013082504",
   "Negative": "0.03419385477900505",
   "Neutral": "0.9160254001617432",
   "Mixed": "0.00019319765851832926"
 },
 {
   "tweet_id": "1589963344806825984",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589963339383582727",
   "Positive": "0.024483367800712585",
   "Negative": "0.0028238084632903337",
   "Neutral": "0.9726743102073669",
   "Mixed": "1.8379270841251127e-05"
 },
 {
   "tweet_id": "1589963336372088834",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963335222845440",
   "Positive": "0.0036146759521216154",
   "Negative": "9.298189252149314e-05",
   "Neutral": "0.9962800145149231",
   "Mixed": "1.2342386980890296e-05"
 },
 {
   "tweet_id": "1589963328134459393",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963321725231106",
   "Positive": "0.003010580549016595",
   "Negative": "0.003866615006700158",
   "Neutral": "0.9930625557899475",
   "Mixed": "6.026922710589133e-05"
 },
 {
   "tweet_id": "1589963319167037441",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963318202335233",
   "Positive": "0.02573341690003872",
   "Negative": "0.0029707991052418947",
   "Neutral": "0.9712769389152527",
   "Mixed": "1.8930068108602427e-05"
 },
 {
   "tweet_id": "1589963316809838594",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589963315111133184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963301618081794",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963301039267841",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963299013394432",
   "Positive": "0.024149348959326744",
   "Negative": "0.0034679966047406197",
   "Neutral": "0.9723659753799438",
   "Mixed": "1.6746786059229635e-05"
 },
 {
   "tweet_id": "1589963295070752768",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589963287957229571",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589963284526268416",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963283330924546",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589963279883202561",
   "Positive": "0.025072766467928886",
   "Negative": "7.570849993498996e-05",
   "Neutral": "0.974680483341217",
   "Mixed": "0.000170992745552212"
 },
 {
   "tweet_id": "1589963276825546756",
   "Positive": "0.023714406415820122",
   "Negative": "0.004104143008589745",
   "Neutral": "0.9721642732620239",
   "Mixed": "1.710953438305296e-05"
 },
 {
   "tweet_id": "1589963267052810240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963257452048384",
   "Positive": "0.024038277566432953",
   "Negative": "0.0027137615252286196",
   "Neutral": "0.9732340574264526",
   "Mixed": "1.3886005035601556e-05"
 },
 {
   "tweet_id": "1589963251025993728",
   "Positive": "0.006310421507805586",
   "Negative": "0.0001079526700777933",
   "Neutral": "0.9934544563293457",
   "Mixed": "0.00012713867181446403"
 },
 {
   "tweet_id": "1589963249092812802",
   "Positive": "0.7744563817977905",
   "Negative": "0.03171314671635628",
   "Neutral": "0.19371286034584045",
   "Mixed": "0.00011765814269892871"
 },
 {
   "tweet_id": "1589963245443772416",
   "Positive": "0.016853049397468567",
   "Negative": "5.582447920460254e-05",
   "Neutral": "0.982982873916626",
   "Mixed": "0.0001083125316654332"
 },
 {
   "tweet_id": "1589963243036237824",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589963236929327105",
   "Positive": "0.02485721744596958",
   "Negative": "0.0034346282482147217",
   "Neutral": "0.9716947674751282",
   "Mixed": "1.3353929716686253e-05"
 },
 {
   "tweet_id": "1589963218902208513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963215039246337",
   "Positive": "0.023681018501520157",
   "Negative": "0.004152913112193346",
   "Neutral": "0.9721540212631226",
   "Mixed": "1.2096913451387081e-05"
 },
 {
   "tweet_id": "1589963209834131458",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963205388161024",
   "Positive": "0.029029082506895065",
   "Negative": "7.251521310536191e-05",
   "Neutral": "0.9707348346710205",
   "Mixed": "0.00016353184764739126"
 },
 {
   "tweet_id": "1589963202833809409",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963199037997056",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589963191639240704",
   "Positive": "0.027367165312170982",
   "Negative": "0.0039489599876105785",
   "Neutral": "0.9686702489852905",
   "Mixed": "1.3575210687122308e-05"
 },
 {
   "tweet_id": "1589963180994093056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963171221352448",
   "Positive": "0.027142265811562538",
   "Negative": "0.002811603946611285",
   "Neutral": "0.970030665397644",
   "Mixed": "1.5461600924027152e-05"
 },
 {
   "tweet_id": "1589963155920543747",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589963155064901635",
   "Positive": "0.29377996921539307",
   "Negative": "0.0009157704189419746",
   "Neutral": "0.7052551507949829",
   "Mixed": "4.910063216811977e-05"
 },
 {
   "tweet_id": "1589963152657383426",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589963149830410240",
   "Positive": "0.023076210170984268",
   "Negative": "0.0033003478311002254",
   "Neutral": "0.9736115336418152",
   "Mixed": "1.178838010673644e-05"
 },
 {
   "tweet_id": "1589963146919555072",
   "Positive": "0.038149867206811905",
   "Negative": "0.0037256006617099047",
   "Neutral": "0.9580881595611572",
   "Mixed": "3.6341967643238604e-05"
 },
 {
   "tweet_id": "1589963144390397952",
   "Positive": "0.023772165179252625",
   "Negative": "5.9505924582481384e-05",
   "Neutral": "0.9760391116142273",
   "Mixed": "0.00012930994853377342"
 },
 {
   "tweet_id": "1589963132222726144",
   "Positive": "0.02428213320672512",
   "Negative": "0.0027754015754908323",
   "Neutral": "0.9729276895523071",
   "Mixed": "1.4727611414855346e-05"
 },
 {
   "tweet_id": "1589963127449583616",
   "Positive": "0.02246384136378765",
   "Negative": "6.021989247528836e-05",
   "Neutral": "0.9773573279380798",
   "Mixed": "0.00011860765516757965"
 },
 {
   "tweet_id": "1589963121409822721",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589963112828239872",
   "Positive": "0.00746691832318902",
   "Negative": "0.0005962387658655643",
   "Neutral": "0.9919120073318481",
   "Mixed": "2.4816510631353594e-05"
 },
 {
   "tweet_id": "1589963110710136832",
   "Positive": "0.02408893220126629",
   "Negative": "0.002242211252450943",
   "Neutral": "0.9736508727073669",
   "Mixed": "1.7991844288189895e-05"
 },
 {
   "tweet_id": "1589963110336843779",
   "Positive": "0.023656539618968964",
   "Negative": "6.063166802050546e-05",
   "Neutral": "0.9761469960212708",
   "Mixed": "0.0001357514556730166"
 },
 {
   "tweet_id": "1589963094213931010",
   "Positive": "0.025612497702240944",
   "Negative": "6.272141763474792e-05",
   "Neutral": "0.9742012023925781",
   "Mixed": "0.00012352860358078033"
 },
 {
   "tweet_id": "1589963093555441664",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963089012994048",
   "Positive": "0.02287237159907818",
   "Negative": "0.003196218516677618",
   "Neutral": "0.9739186763763428",
   "Mixed": "1.2798383977496997e-05"
 },
 {
   "tweet_id": "1589963087842783235",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963083552002049",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589963080297230337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963078061654018",
   "Positive": "0.023737110197544098",
   "Negative": "6.0093170759500936e-05",
   "Neutral": "0.9760673642158508",
   "Mixed": "0.00013538433995563537"
 },
 {
   "tweet_id": "1589963077122166785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589963074479755264",
   "Positive": "0.4918563961982727",
   "Negative": "0.0004946920671500266",
   "Neutral": "0.5075528621673584",
   "Mixed": "9.602402133168653e-05"
 },
 {
   "tweet_id": "1589963069857615872",
   "Positive": "0.023658860474824905",
   "Negative": "0.0026595236267894506",
   "Neutral": "0.9736681580543518",
   "Mixed": "1.3472179489326663e-05"
 },
 {
   "tweet_id": "1589963064635723777",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589963061183778819",
   "Positive": "0.023120854049921036",
   "Negative": "5.847185821039602e-05",
   "Neutral": "0.9766919612884521",
   "Mixed": "0.00012871141370851547"
 },
 {
   "tweet_id": "1589963058423959552",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963048621871104",
   "Positive": "0.00600549066439271",
   "Negative": "0.0003629899874795228",
   "Neutral": "0.9936285614967346",
   "Mixed": "2.9669045034097508e-06"
 },
 {
   "tweet_id": "1589963047715868674",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963044599496704",
   "Positive": "0.024040576070547104",
   "Negative": "6.0364265664247796e-05",
   "Neutral": "0.9757600426673889",
   "Mixed": "0.0001389706158079207"
 },
 {
   "tweet_id": "1589963040589754373",
   "Positive": "0.0008925763540901244",
   "Negative": "0.06544925272464752",
   "Neutral": "0.9335356950759888",
   "Mixed": "0.00012241287913639098"
 },
 {
   "tweet_id": "1589963037188165632",
   "Positive": "0.03003207966685295",
   "Negative": "6.448526255553588e-05",
   "Neutral": "0.9696221947669983",
   "Mixed": "0.00028121451032347977"
 },
 {
   "tweet_id": "1589963032578625536",
   "Positive": "0.0008013202459551394",
   "Negative": "6.340415711747482e-05",
   "Neutral": "0.9991288781166077",
   "Mixed": "6.434021997847594e-06"
 },
 {
   "tweet_id": "1589963030649274370",
   "Positive": "0.01811813935637474",
   "Negative": "5.708634853363037e-05",
   "Neutral": "0.9816734790802002",
   "Mixed": "0.00015135345165617764"
 },
 {
   "tweet_id": "1589963028497588226",
   "Positive": "0.02625366300344467",
   "Negative": "6.276041676755995e-05",
   "Neutral": "0.9735451340675354",
   "Mixed": "0.0001384634233545512"
 },
 {
   "tweet_id": "1589963024731103232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589963017038725122",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589963011644862465",
   "Positive": "0.025727247819304466",
   "Negative": "6.360474799294025e-05",
   "Neutral": "0.9740779399871826",
   "Mixed": "0.00013121306255925447"
 },
 {
   "tweet_id": "1589963009463832577",
   "Positive": "0.021511390805244446",
   "Negative": "6.173575820866972e-05",
   "Neutral": "0.9782377481460571",
   "Mixed": "0.00018916722910944372"
 },
 {
   "tweet_id": "1589962998915137536",
   "Positive": "0.02288149669766426",
   "Negative": "0.010642901062965393",
   "Neutral": "0.9663677215576172",
   "Mixed": "0.00010798946459544823"
 },
 {
   "tweet_id": "1589962994519506946",
   "Positive": "0.024214984849095345",
   "Negative": "6.162744102766737e-05",
   "Neutral": "0.9755850434303284",
   "Mixed": "0.000138412433443591"
 },
 {
   "tweet_id": "1589962988043505667",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962986919460864",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589962985820553217",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962980292464640",
   "Positive": "0.017888763919472694",
   "Negative": "5.8347664889879525e-05",
   "Neutral": "0.9819093942642212",
   "Mixed": "0.00014346920943353325"
 },
 {
   "tweet_id": "1589962977243197440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962974420426753",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962972306120704",
   "Positive": "0.0032897181808948517",
   "Negative": "0.004049865994602442",
   "Neutral": "0.992615818977356",
   "Mixed": "4.459819683688693e-05"
 },
 {
   "tweet_id": "1589962971924795393",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962971757047811",
   "Positive": "0.0009281524689868093",
   "Negative": "6.18065896560438e-05",
   "Neutral": "0.9990001320838928",
   "Mixed": "9.938936273101717e-06"
 },
 {
   "tweet_id": "1589962967692431360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962966363168770",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962961866887168",
   "Positive": "0.021206650882959366",
   "Negative": "6.13575684837997e-05",
   "Neutral": "0.9785668849945068",
   "Mixed": "0.00016509952547494322"
 },
 {
   "tweet_id": "1589962956154220544",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589962954791096321",
   "Positive": "0.021102676168084145",
   "Negative": "0.00014750381524208933",
   "Neutral": "0.9787322878837585",
   "Mixed": "1.754801814968232e-05"
 },
 {
   "tweet_id": "1589962950370295808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962947102937090",
   "Positive": "0.01947743259370327",
   "Negative": "5.925233563175425e-05",
   "Neutral": "0.9803292751312256",
   "Mixed": "0.00013409610255621374"
 },
 {
   "tweet_id": "1589962940782108674",
   "Positive": "0.12108340114355087",
   "Negative": "0.01080276258289814",
   "Neutral": "0.8670505285263062",
   "Mixed": "0.0010632999474182725"
 },
 {
   "tweet_id": "1589962938370359296",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589962935149146112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962932896804864",
   "Positive": "0.01838192157447338",
   "Negative": "5.9216588851995766e-05",
   "Neutral": "0.9814173579216003",
   "Mixed": "0.00014147120236884803"
 },
 {
   "tweet_id": "1589962931806306305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962930283433984",
   "Positive": "0.3514038622379303",
   "Negative": "0.014111286029219627",
   "Neutral": "0.6344097256660461",
   "Mixed": "7.513481250498444e-05"
 },
 {
   "tweet_id": "1589962925871370241",
   "Positive": "0.0640578642487526",
   "Negative": "0.004173606634140015",
   "Neutral": "0.9317364692687988",
   "Mixed": "3.2079427910503e-05"
 },
 {
   "tweet_id": "1589962922213933057",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589962918128275456",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589962916576792578",
   "Positive": "0.024349598214030266",
   "Negative": "6.701870734104887e-05",
   "Neutral": "0.9753564596176147",
   "Mixed": "0.00022696818632539362"
 },
 {
   "tweet_id": "1589962910801231872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962903826092032",
   "Positive": "0.019331926479935646",
   "Negative": "5.852680988027714e-05",
   "Neutral": "0.9804632067680359",
   "Mixed": "0.0001463505905121565"
 },
 {
   "tweet_id": "1589962898297991169",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589962890609852417",
   "Positive": "0.019105862826108932",
   "Negative": "6.040968219167553e-05",
   "Neutral": "0.980647087097168",
   "Mixed": "0.00018664383969735354"
 },
 {
   "tweet_id": "1589962881646596096",
   "Positive": "0.020352698862552643",
   "Negative": "5.992206570226699e-05",
   "Neutral": "0.9794279932975769",
   "Mixed": "0.00015931742382235825"
 },
 {
   "tweet_id": "1589962863904374789",
   "Positive": "0.004725271835923195",
   "Negative": "9.716463682707399e-05",
   "Neutral": "0.9950764775276184",
   "Mixed": "0.00010105759429279715"
 },
 {
   "tweet_id": "1589962860389892099",
   "Positive": "0.018994014710187912",
   "Negative": "6.019118882250041e-05",
   "Neutral": "0.9807822704315186",
   "Mixed": "0.0001635553053347394"
 },
 {
   "tweet_id": "1589962856031997953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962852843933696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962846850658305",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962845244248065",
   "Positive": "0.019785765558481216",
   "Negative": "6.013047095621005e-05",
   "Neutral": "0.9800022840499878",
   "Mixed": "0.00015173287829384208"
 },
 {
   "tweet_id": "1589962830912294915",
   "Positive": "0.018730750307440758",
   "Negative": "5.7526383898220956e-05",
   "Neutral": "0.9810479879379272",
   "Mixed": "0.0001637144887354225"
 },
 {
   "tweet_id": "1589962827946942466",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962824226607105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962821919731712",
   "Positive": "0.02188790775835514",
   "Negative": "0.002630653791129589",
   "Neutral": "0.9754682183265686",
   "Mixed": "1.3203993148636073e-05"
 },
 {
   "tweet_id": "1589962821202477057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962816685232129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962815158489089",
   "Positive": "0.0066347429528832436",
   "Negative": "0.01629665680229664",
   "Neutral": "0.9769983887672424",
   "Mixed": "7.026793900877237e-05"
 },
 {
   "tweet_id": "1589962812008562689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962811203289088",
   "Positive": "0.019529996439814568",
   "Negative": "5.86991518503055e-05",
   "Neutral": "0.9802324771881104",
   "Mixed": "0.00017875693447422236"
 },
 {
   "tweet_id": "1589962807440965639",
   "Positive": "0.01633944921195507",
   "Negative": "0.40129682421684265",
   "Neutral": "0.582266092300415",
   "Mixed": "9.765806316863745e-05"
 },
 {
   "tweet_id": "1589962801929674753",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962797567602690",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962797131378688",
   "Positive": "0.021590759977698326",
   "Negative": "6.213292363099754e-05",
   "Neutral": "0.9782146215438843",
   "Mixed": "0.00013245885202195495"
 },
 {
   "tweet_id": "1589962796963622912",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962790185635841",
   "Positive": "0.02551141567528248",
   "Negative": "0.002589127281680703",
   "Neutral": "0.97188401222229",
   "Mixed": "1.5437297406606376e-05"
 },
 {
   "tweet_id": "1589962786838581250",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589962786834382849",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962786020655104",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962781486632960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962778391240704",
   "Positive": "0.9518555998802185",
   "Negative": "0.0005222808104008436",
   "Neutral": "0.0475429929792881",
   "Mixed": "7.910010754130781e-05"
 },
 {
   "tweet_id": "1589962775513944064",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962774473748480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962767163088899",
   "Positive": "0.1270349770784378",
   "Negative": "0.0016055661253631115",
   "Neutral": "0.8713376522064209",
   "Mixed": "2.1763689801446162e-05"
 },
 {
   "tweet_id": "1589962762146701317",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589962758355025924",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962756681502720",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962752109342722",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962749698011136",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962749567987713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962748716515329",
   "Positive": "0.025982879102230072",
   "Negative": "6.506891804747283e-05",
   "Neutral": "0.9738108515739441",
   "Mixed": "0.00014123291475698352"
 },
 {
   "tweet_id": "1589962747625603074",
   "Positive": "0.009857630357146263",
   "Negative": "0.00013428583042696118",
   "Neutral": "0.9898425936698914",
   "Mixed": "0.00016555603360757232"
 },
 {
   "tweet_id": "1589962745797292032",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589962739577155584",
   "Positive": "0.025260863825678825",
   "Negative": "6.26555920462124e-05",
   "Neutral": "0.9745262861251831",
   "Mixed": "0.00015023746527731419"
 },
 {
   "tweet_id": "1589962739203858432",
   "Positive": "0.024650659412145615",
   "Negative": "0.0028748540207743645",
   "Neutral": "0.9724588394165039",
   "Mixed": "1.5664765669498593e-05"
 },
 {
   "tweet_id": "1589962736427241473",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589962732211965952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962728965574660",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962725895335937",
   "Positive": "0.002064754953607917",
   "Negative": "7.086624100338668e-05",
   "Neutral": "0.9978563189506531",
   "Mixed": "8.06233765615616e-06"
 },
 {
   "tweet_id": "1589962724775075840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962723361972224",
   "Positive": "0.02304750680923462",
   "Negative": "0.0032409653067588806",
   "Neutral": "0.9737001657485962",
   "Mixed": "1.1332401300023776e-05"
 },
 {
   "tweet_id": "1589962710309306368",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962708958711812",
   "Positive": "0.2010888159275055",
   "Negative": "0.010915587656199932",
   "Neutral": "0.7879313826560974",
   "Mixed": "6.428000779123977e-05"
 },
 {
   "tweet_id": "1589962708585447426",
   "Positive": "0.022804927080869675",
   "Negative": "0.0033709716517478228",
   "Neutral": "0.9738127589225769",
   "Mixed": "1.1341356184857432e-05"
 },
 {
   "tweet_id": "1589962707847221249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962702285582336",
   "Positive": "0.030669203028082848",
   "Negative": "0.004695832263678312",
   "Neutral": "0.9646071791648865",
   "Mixed": "2.777427107503172e-05"
 },
 {
   "tweet_id": "1589962694131847168",
   "Positive": "0.024114267900586128",
   "Negative": "0.003106738906353712",
   "Neutral": "0.9727661609649658",
   "Mixed": "1.2853591215389315e-05"
 },
 {
   "tweet_id": "1589962686871539714",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589962685457657862",
   "Positive": "0.22029362618923187",
   "Negative": "0.0005601341836154461",
   "Neutral": "0.7791172862052917",
   "Mixed": "2.8982673029531725e-05"
 },
 {
   "tweet_id": "1589962679875403776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962679216910337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962679133011970",
   "Positive": "0.022200632840394974",
   "Negative": "0.003972773440182209",
   "Neutral": "0.9738156795501709",
   "Mixed": "1.0862697308766656e-05"
 },
 {
   "tweet_id": "1589962678155751429",
   "Positive": "0.018923111259937286",
   "Negative": "0.004702472127974033",
   "Neutral": "0.9763529896736145",
   "Mixed": "2.1417254174593836e-05"
 },
 {
   "tweet_id": "1589962672963223552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962665707057152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962662976557056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962660711665664",
   "Positive": "0.039450906217098236",
   "Negative": "0.0039846086874604225",
   "Neutral": "0.9565387964248657",
   "Mixed": "2.5664288841653615e-05"
 },
 {
   "tweet_id": "1589962659495305217",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1589962653166096387",
   "Positive": "0.02255283296108246",
   "Negative": "0.0033243237994611263",
   "Neutral": "0.9741112589836121",
   "Mixed": "1.15829070637119e-05"
 },
 {
   "tweet_id": "1589962648094810112",
   "Positive": "0.028527764603495598",
   "Negative": "6.302395195234567e-05",
   "Neutral": "0.971255362033844",
   "Mixed": "0.00015382702986244112"
 },
 {
   "tweet_id": "1589962644664250370",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962637424857089",
   "Positive": "0.021739399060606956",
   "Negative": "0.0030085949692875147",
   "Neutral": "0.9752395749092102",
   "Mixed": "1.2432405128492974e-05"
 },
 {
   "tweet_id": "1589962636938326016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962635575201793",
   "Positive": "0.03218746930360794",
   "Negative": "0.0036636069416999817",
   "Neutral": "0.9641178250312805",
   "Mixed": "3.11284966301173e-05"
 },
 {
   "tweet_id": "1589962622572826629",
   "Positive": "0.001497921533882618",
   "Negative": "0.00013581180246546865",
   "Neutral": "0.9983630776405334",
   "Mixed": "3.2049015317170415e-06"
 },
 {
   "tweet_id": "1589962621205512197",
   "Positive": "0.02287237159907818",
   "Negative": "0.003196218516677618",
   "Neutral": "0.9739186763763428",
   "Mixed": "1.2798383977496997e-05"
 },
 {
   "tweet_id": "1589962610254163971",
   "Positive": "0.03826744109392166",
   "Negative": "6.520775059470907e-05",
   "Neutral": "0.9614260792732239",
   "Mixed": "0.00024130698875524104"
 },
 {
   "tweet_id": "1589962605145518081",
   "Positive": "0.07567436993122101",
   "Negative": "0.004964413121342659",
   "Neutral": "0.9193134903907776",
   "Mixed": "4.7639026888646185e-05"
 },
 {
   "tweet_id": "1589962604331831296",
   "Positive": "0.02480318769812584",
   "Negative": "0.003129109740257263",
   "Neutral": "0.9720539450645447",
   "Mixed": "1.3744199350185227e-05"
 },
 {
   "tweet_id": "1589962601399996416",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962601152540674",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962598476546051",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962596308123653",
   "Positive": "0.03808559849858284",
   "Negative": "0.00365259125828743",
   "Neutral": "0.9582332968711853",
   "Mixed": "2.86138201772701e-05"
 },
 {
   "tweet_id": "1589962591861846016",
   "Positive": "0.006244209129363298",
   "Negative": "0.00019326811889186502",
   "Neutral": "0.99355548620224",
   "Mixed": "7.013007689238293e-06"
 },
 {
   "tweet_id": "1589962588125036544",
   "Positive": "0.025923823937773705",
   "Negative": "0.002757715294137597",
   "Neutral": "0.9713020324707031",
   "Mixed": "1.638915819057729e-05"
 },
 {
   "tweet_id": "1589962586367602691",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962581355409409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962576351240192",
   "Positive": "0.03916928917169571",
   "Negative": "6.0569927882170305e-05",
   "Neutral": "0.96035236120224",
   "Mixed": "0.0004177519876975566"
 },
 {
   "tweet_id": "1589962573725962240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962565534494720",
   "Positive": "0.03319726139307022",
   "Negative": "0.00466744601726532",
   "Neutral": "0.9620968699455261",
   "Mixed": "3.836537871393375e-05"
 },
 {
   "tweet_id": "1589962558978797570",
   "Positive": "0.028301622718572617",
   "Negative": "0.0024722476955503225",
   "Neutral": "0.9692062735557556",
   "Mixed": "1.9892499039997347e-05"
 },
 {
   "tweet_id": "1589962546299437060",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589962545527676928",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962543749296128",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962539571761153",
   "Positive": "0.02838864177465439",
   "Negative": "6.143203063402325e-05",
   "Neutral": "0.9712755680084229",
   "Mixed": "0.00027433561626821756"
 },
 {
   "tweet_id": "1589962532093317121",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962531829055488",
   "Positive": "0.03208475932478905",
   "Negative": "7.551674207206815e-05",
   "Neutral": "0.9676662683486938",
   "Mixed": "0.00017344079969916493"
 },
 {
   "tweet_id": "1589962528247119873",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962522287042570",
   "Positive": "0.028175152838230133",
   "Negative": "0.007405356038361788",
   "Neutral": "0.964383602142334",
   "Mixed": "3.591334825614467e-05"
 },
 {
   "tweet_id": "1589962521720795137",
   "Positive": "0.004439105745404959",
   "Negative": "0.00010690579802030697",
   "Neutral": "0.9953187108039856",
   "Mixed": "0.00013525363465305418"
 },
 {
   "tweet_id": "1589962515685199878",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962513105682432",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589962507929944067",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962502519296000",
   "Positive": "0.031115973368287086",
   "Negative": "0.005857380107045174",
   "Neutral": "0.9629974365234375",
   "Mixed": "2.93078956019599e-05"
 },
 {
   "tweet_id": "1589962498345938948",
   "Positive": "0.025791728869080544",
   "Negative": "6.506976205855608e-05",
   "Neutral": "0.9739720821380615",
   "Mixed": "0.00017116709204856306"
 },
 {
   "tweet_id": "1589962490850742272",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962488992649216",
   "Positive": "0.03491797298192978",
   "Negative": "0.002762935357168317",
   "Neutral": "0.9622907638549805",
   "Mixed": "2.8314641895121895e-05"
 },
 {
   "tweet_id": "1589962483904937985",
   "Positive": "0.007316607981920242",
   "Negative": "0.00016108031559269875",
   "Neutral": "0.9923436045646667",
   "Mixed": "0.00017874196055345237"
 },
 {
   "tweet_id": "1589962477043089409",
   "Positive": "0.029104728251695633",
   "Negative": "0.006454832386225462",
   "Neutral": "0.9644115567207336",
   "Mixed": "2.882067383325193e-05"
 },
 {
   "tweet_id": "1589962469182951424",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589962468843192327",
   "Positive": "0.033505965024232864",
   "Negative": "0.0035089394077658653",
   "Neutral": "0.9629548788070679",
   "Mixed": "3.018569441337604e-05"
 },
 {
   "tweet_id": "1589962467073200130",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589962461293481984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962460865654785",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962460739829760",
   "Positive": "0.0016188658773899078",
   "Negative": "0.00012613313447218388",
   "Neutral": "0.9982484579086304",
   "Mixed": "6.593058515136363e-06"
 },
 {
   "tweet_id": "1589962455425650688",
   "Positive": "0.033533111214637756",
   "Negative": "0.0032843644730746746",
   "Neutral": "0.9631527662277222",
   "Mixed": "2.9728464141953737e-05"
 },
 {
   "tweet_id": "1589962453479460865",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589962451084537858",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962450027192320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962449327120390",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962448614088704",
   "Positive": "0.5608309507369995",
   "Negative": "0.0012454716488718987",
   "Neutral": "0.437409371137619",
   "Mixed": "0.0005141634028404951"
 },
 {
   "tweet_id": "1589962440200314880",
   "Positive": "0.03507711738348007",
   "Negative": "0.003567649982869625",
   "Neutral": "0.961324155330658",
   "Mixed": "3.103874041698873e-05"
 },
 {
   "tweet_id": "1589962430934708225",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589962430029103104",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962426619162625",
   "Positive": "0.03511879965662956",
   "Negative": "0.002977642696350813",
   "Neutral": "0.961875319480896",
   "Mixed": "2.822285023285076e-05"
 },
 {
   "tweet_id": "1589962422286045184",
   "Positive": "0.008982936851680279",
   "Negative": "0.0001497493067290634",
   "Neutral": "0.9907364249229431",
   "Mixed": "0.00013082384248264134"
 },
 {
   "tweet_id": "1589962412769546242",
   "Positive": "0.034238941967487335",
   "Negative": "0.0032012967858463526",
   "Neutral": "0.9625301361083984",
   "Mixed": "2.9615077437483706e-05"
 },
 {
   "tweet_id": "1589962409778679809",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589962406742339586",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962404863291392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962399737864194",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962398584410113",
   "Positive": "0.03502553328871727",
   "Negative": "0.0033046617172658443",
   "Neutral": "0.9616394639015198",
   "Mixed": "3.0408709790208377e-05"
 },
 {
   "tweet_id": "1589962398093705218",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962393320181760",
   "Positive": "0.07274340093135834",
   "Negative": "0.0008368399576283991",
   "Neutral": "0.9264047741889954",
   "Mixed": "1.5032106603030115e-05"
 },
 {
   "tweet_id": "1589962389734428672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962384420245506",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962383799484417",
   "Positive": "0.03684091940522194",
   "Negative": "0.0034193338360637426",
   "Neutral": "0.9597055912017822",
   "Mixed": "3.415248283999972e-05"
 },
 {
   "tweet_id": "1589962379500322817",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589962379084767232",
   "Positive": "0.004698638338595629",
   "Negative": "0.00013186443538870662",
   "Neutral": "0.9951648712158203",
   "Mixed": "4.576395895128371e-06"
 },
 {
   "tweet_id": "1589962372013490177",
   "Positive": "0.006949175149202347",
   "Negative": "0.00011194311082363129",
   "Neutral": "0.9927812218666077",
   "Mixed": "0.0001576400245539844"
 },
 {
   "tweet_id": "1589962368226033665",
   "Positive": "0.03545556589961052",
   "Negative": "0.003542855614796281",
   "Neutral": "0.9609701037406921",
   "Mixed": "3.155883678118698e-05"
 },
 {
   "tweet_id": "1589962367101566977",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962365495570432",
   "Positive": "0.047415781766176224",
   "Negative": "0.005681770388036966",
   "Neutral": "0.9468724131584167",
   "Mixed": "3.007408304256387e-05"
 },
 {
   "tweet_id": "1589962360290439171",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962351327219713",
   "Positive": "0.03432084619998932",
   "Negative": "0.0033375518396496773",
   "Neutral": "0.9623132944107056",
   "Mixed": "2.83246481558308e-05"
 },
 {
   "tweet_id": "1589962340199727104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962337649569793",
   "Positive": "0.0009992980631068349",
   "Negative": "4.257322507328354e-05",
   "Neutral": "0.9988946318626404",
   "Mixed": "6.347579619614407e-05"
 },
 {
   "tweet_id": "1589962335061700611",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962327054757889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962320369057794",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962317688864768",
   "Positive": "0.009857630357146263",
   "Negative": "0.00013428583042696118",
   "Neutral": "0.9898425936698914",
   "Mixed": "0.00016555603360757232"
 },
 {
   "tweet_id": "1589962316602568705",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962314895482880",
   "Positive": "0.10986115783452988",
   "Negative": "0.36619535088539124",
   "Neutral": "0.5206537842750549",
   "Mixed": "0.003289635293185711"
 },
 {
   "tweet_id": "1589962306955653122",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962305240199168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962298306998272",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962296276967425",
   "Positive": "0.20502933859825134",
   "Negative": "0.0009213582961820066",
   "Neutral": "0.7939746975898743",
   "Mixed": "7.45982542866841e-05"
 },
 {
   "tweet_id": "1589962274982490113",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962265633386498",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962263645257728",
   "Positive": "0.0036506340838968754",
   "Negative": "0.9637523293495178",
   "Neutral": "0.020606786012649536",
   "Mixed": "0.011990179307758808"
 },
 {
   "tweet_id": "1589962260558274563",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962250307407872",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589962236474593282",
   "Positive": "0.006866788491606712",
   "Negative": "0.003144176211208105",
   "Neutral": "0.9899380207061768",
   "Mixed": "5.097970279166475e-05"
 },
 {
   "tweet_id": "1589962234733957123",
   "Positive": "0.014142644591629505",
   "Negative": "8.737193275010213e-05",
   "Neutral": "0.9847241640090942",
   "Mixed": "0.0010457854950800538"
 },
 {
   "tweet_id": "1589962234671042560",
   "Positive": "0.024614689871668816",
   "Negative": "6.214096356416121e-05",
   "Neutral": "0.9751787781715393",
   "Mixed": "0.00014433222531806678"
 },
 {
   "tweet_id": "1589962233005887488",
   "Positive": "0.04341399297118187",
   "Negative": "0.0059973313473165035",
   "Neutral": "0.9505501389503479",
   "Mixed": "3.85171988455113e-05"
 },
 {
   "tweet_id": "1589962231747588096",
   "Positive": "0.21623334288597107",
   "Negative": "0.0010877586901187897",
   "Neutral": "0.782656192779541",
   "Mixed": "2.2694111976306885e-05"
 },
 {
   "tweet_id": "1589962224571121666",
   "Positive": "0.16671454906463623",
   "Negative": "0.0016721420688554645",
   "Neutral": "0.8315338492393494",
   "Mixed": "7.95324012869969e-05"
 },
 {
   "tweet_id": "1589962213703700480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962206095241216",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589962200093188096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962198880657409",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1589962195693371393",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1589962191255793664",
   "Positive": "0.03388933464884758",
   "Negative": "0.0041350447572767735",
   "Neutral": "0.9619436264038086",
   "Mixed": "3.199771526851691e-05"
 },
 {
   "tweet_id": "1589962175661355009",
   "Positive": "0.8557835817337036",
   "Negative": "0.00028337290859781206",
   "Neutral": "0.14381226897239685",
   "Mixed": "0.00012076655548298731"
 },
 {
   "tweet_id": "1589962167855747073",
   "Positive": "0.007924513891339302",
   "Negative": "0.00013226833834778517",
   "Neutral": "0.9917908906936646",
   "Mixed": "0.00015231239376589656"
 },
 {
   "tweet_id": "1589962157957218304",
   "Positive": "0.14526551961898804",
   "Negative": "0.030598578974604607",
   "Neutral": "0.8240435719490051",
   "Mixed": "9.235196921508759e-05"
 },
 {
   "tweet_id": "1589962157802016770",
   "Positive": "0.006866788491606712",
   "Negative": "0.003144176211208105",
   "Neutral": "0.9899380207061768",
   "Mixed": "5.097970279166475e-05"
 },
 {
   "tweet_id": "1589962148352253953",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962141066760192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962136389754880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589962126315360257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962119608291328",
   "Positive": "0.2300948053598404",
   "Negative": "0.0001622762210899964",
   "Neutral": "0.7696569561958313",
   "Mixed": "8.599360444350168e-05"
 },
 {
   "tweet_id": "1589962095764066305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962085600964609",
   "Positive": "0.003250239882618189",
   "Negative": "4.9294543714495376e-05",
   "Neutral": "0.9966884255409241",
   "Mixed": "1.216820783156436e-05"
 },
 {
   "tweet_id": "1589962083969687553",
   "Positive": "0.08172097057104111",
   "Negative": "0.0022751104552298784",
   "Neutral": "0.9159864187240601",
   "Mixed": "1.751695162965916e-05"
 },
 {
   "tweet_id": "1589962045235265537",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589962043330727941",
   "Positive": "0.8543469309806824",
   "Negative": "0.00017709484382066876",
   "Neutral": "0.145407035946846",
   "Mixed": "6.893144745845348e-05"
 },
 {
   "tweet_id": "1589962032979533826",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589962026193162241",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589962021923348481",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589961997478920192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961993695657985",
   "Positive": "0.20898574590682983",
   "Negative": "0.00019238698587287217",
   "Neutral": "0.7906510233879089",
   "Mixed": "0.00017084201681427658"
 },
 {
   "tweet_id": "1589961991765884928",
   "Positive": "0.9092032313346863",
   "Negative": "0.0004738021525554359",
   "Neutral": "0.09029320627450943",
   "Mixed": "2.977580516017042e-05"
 },
 {
   "tweet_id": "1589961977723760641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961970064953344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961969855262728",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961948141346816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961936170803200",
   "Positive": "0.004131809808313847",
   "Negative": "0.0015544703928753734",
   "Neutral": "0.9943100214004517",
   "Mixed": "3.6956269013899146e-06"
 },
 {
   "tweet_id": "1589961923621421059",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961918437298178",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961912892395520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961908568064003",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589961899361570816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961892675846145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961874720030720",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589961858081259528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961849269026816",
   "Positive": "0.18886278569698334",
   "Negative": "0.06945198029279709",
   "Neutral": "0.7415570020675659",
   "Mixed": "0.0001281797740375623"
 },
 {
   "tweet_id": "1589961840335155202",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589961839856652290",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589961825357279233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961823574687745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961815672639488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961786161520643",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961763612938242",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589961752414162945",
   "Positive": "0.05478251352906227",
   "Negative": "0.2116786688566208",
   "Neutral": "0.7278738617897034",
   "Mixed": "0.005665014963597059"
 },
 {
   "tweet_id": "1589961748186288129",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961744814047232",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589961740283805696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961728787640320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961711347724288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961694629220354",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961693492150272",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961675691945984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961661032849408",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961657778069504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961645463580672",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961642527576065",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589961599556939781",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961596692205569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961575703908352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961572927303681",
   "Positive": "0.8637679219245911",
   "Negative": "0.012590240687131882",
   "Neutral": "0.1232980415225029",
   "Mixed": "0.0003437740379013121"
 },
 {
   "tweet_id": "1589961570624602112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961568124833792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961560977702913",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589961550714241024",
   "Positive": "0.0021257237531244755",
   "Negative": "0.6299188733100891",
   "Neutral": "0.3679293096065521",
   "Mixed": "2.610937190183904e-05"
 },
 {
   "tweet_id": "1589961548872978432",
   "Positive": "0.03420313447713852",
   "Negative": "0.1861468106508255",
   "Neutral": "0.7796192765235901",
   "Mixed": "3.075742642977275e-05"
 },
 {
   "tweet_id": "1589961537044611077",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961536738852864",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961517277261826",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589961506602774528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961504262356992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961502072918017",
   "Positive": "0.8617572784423828",
   "Negative": "0.00039855760405771434",
   "Neutral": "0.13775849342346191",
   "Mixed": "8.56549377203919e-05"
 },
 {
   "tweet_id": "1589961501883842560",
   "Positive": "0.0009659747593104839",
   "Negative": "8.727000385988504e-05",
   "Neutral": "0.9989376664161682",
   "Mixed": "9.062333447218407e-06"
 },
 {
   "tweet_id": "1589961500378435586",
   "Positive": "0.0012971835676580667",
   "Negative": "0.0002512679493520409",
   "Neutral": "0.9984489679336548",
   "Mixed": "2.459689312672708e-06"
 },
 {
   "tweet_id": "1589961457802022913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961455017021441",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589961454643728386",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589961453247041539",
   "Positive": "0.014413886703550816",
   "Negative": "0.5265725255012512",
   "Neutral": "0.4584198296070099",
   "Mixed": "0.0005937945679761469"
 },
 {
   "tweet_id": "1589961450860470273",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589961446485819396",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961444241838081",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589961441104527360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961432636194824",
   "Positive": "0.0007684982847422361",
   "Negative": "0.9742178320884705",
   "Neutral": "0.02443903684616089",
   "Mixed": "0.0005746046081185341"
 },
 {
   "tweet_id": "1589961410901331968",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589961410259595266",
   "Positive": "0.0018768180161714554",
   "Negative": "0.9056609272956848",
   "Neutral": "0.09202059358358383",
   "Mixed": "0.00044173019705340266"
 },
 {
   "tweet_id": "1589961397236301824",
   "Positive": "0.9615368247032166",
   "Negative": "0.00024108290381263942",
   "Neutral": "0.03815315291285515",
   "Mixed": "6.892756937304512e-05"
 },
 {
   "tweet_id": "1589961387895554048",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961387773923333",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961383223111680",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961382560034816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961366718545921",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589961363887370241",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961362230611968",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961350566260739",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961348561403905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961346627829760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961339455541250",
   "Positive": "0.00360693596303463",
   "Negative": "0.27621105313301086",
   "Neutral": "0.7200798988342285",
   "Mixed": "0.00010219241812592372"
 },
 {
   "tweet_id": "1589961326608388096",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961298519134209",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589961291816644613",
   "Positive": "0.00017094446229748428",
   "Negative": "0.04423337057232857",
   "Neutral": "0.9555795788764954",
   "Mixed": "1.6019734175642952e-05"
 },
 {
   "tweet_id": "1589961268571811842",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1589961267741347841",
   "Positive": "0.0006595358718186617",
   "Negative": "0.00021406654559541494",
   "Neutral": "0.9991200566291809",
   "Mixed": "6.293727892625611e-06"
 },
 {
   "tweet_id": "1589961258400632833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961250796376068",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589961249873620993",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961246241325059",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961238901297155",
   "Positive": "0.0018768180161714554",
   "Negative": "0.9056609272956848",
   "Neutral": "0.09202059358358383",
   "Mixed": "0.00044173019705340266"
 },
 {
   "tweet_id": "1589961222891663360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961217007026176",
   "Positive": "0.04307889565825462",
   "Negative": "0.009734888561069965",
   "Neutral": "0.9471182227134705",
   "Mixed": "6.794734508730471e-05"
 },
 {
   "tweet_id": "1589961210933706753",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961204239597570",
   "Positive": "0.7657310366630554",
   "Negative": "0.0004723134043160826",
   "Neutral": "0.23085083067417145",
   "Mixed": "0.00294584222137928"
 },
 {
   "tweet_id": "1589961203732086785",
   "Positive": "0.5108224153518677",
   "Negative": "0.0001746283523971215",
   "Neutral": "0.4889460504055023",
   "Mixed": "5.683834388037212e-05"
 },
 {
   "tweet_id": "1589961199009288194",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961169414270977",
   "Positive": "0.08712653815746307",
   "Negative": "0.0001444476074539125",
   "Neutral": "0.9126136898994446",
   "Mixed": "0.00011528246250236407"
 },
 {
   "tweet_id": "1589961164171411458",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589961153614344195",
   "Positive": "0.6423364281654358",
   "Negative": "0.002171176252886653",
   "Neutral": "0.3551313579082489",
   "Mixed": "0.00036101334262639284"
 },
 {
   "tweet_id": "1589961143745130496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961130289795073",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589961128461074434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961122861711362",
   "Positive": "0.2097710818052292",
   "Negative": "0.03812575340270996",
   "Neutral": "0.7518605589866638",
   "Mixed": "0.0002426366409054026"
 },
 {
   "tweet_id": "1589961122819760128",
   "Positive": "0.21584093570709229",
   "Negative": "0.00039861793629825115",
   "Neutral": "0.7833041548728943",
   "Mixed": "0.00045624212361872196"
 },
 {
   "tweet_id": "1589961106399035392",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589961101717938176",
   "Positive": "0.1878480613231659",
   "Negative": "0.0019746392499655485",
   "Neutral": "0.8094785213470459",
   "Mixed": "0.0006986900116316974"
 },
 {
   "tweet_id": "1589961094013255681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961092113260544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961087776325633",
   "Positive": "0.09223729372024536",
   "Negative": "0.1164853572845459",
   "Neutral": "0.7057566046714783",
   "Mixed": "0.08552075177431107"
 },
 {
   "tweet_id": "1589961087625019394",
   "Positive": "0.00017094446229748428",
   "Negative": "0.04423337057232857",
   "Neutral": "0.9555795788764954",
   "Mixed": "1.6019734175642952e-05"
 },
 {
   "tweet_id": "1589961077592576002",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589961067446534144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961060899237888",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589961054641324032",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589961034433187840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961033992785922",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589961016305418240",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589960999452704769",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960994834743296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960994121740291",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589960991894536193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960985829584896",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589960974047809536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960970457075713",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589960953353101313",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589960950328995840",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589960946306646018",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960945262301189",
   "Positive": "0.5848793983459473",
   "Negative": "0.0003485205816105008",
   "Neutral": "0.41471678018569946",
   "Mixed": "5.523844083654694e-05"
 },
 {
   "tweet_id": "1589960944704442368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960937666400260",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960936416477184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960928409571329",
   "Positive": "0.9016100764274597",
   "Negative": "0.000715221802238375",
   "Neutral": "0.09704292565584183",
   "Mixed": "0.0006318356608971953"
 },
 {
   "tweet_id": "1589960916334149632",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589960898747461634",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589960894712537088",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960888651378688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960878589628416",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589960874131095559",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960866166108160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960861212606464",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589960855428685829",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960846612271104",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589960839872016386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960832347443204",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960827746291712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960823405150208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960823384199169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960813322063875",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960790869946370",
   "Positive": "0.5428937077522278",
   "Negative": "0.02583020180463791",
   "Neutral": "0.4309980869293213",
   "Mixed": "0.0002780473150778562"
 },
 {
   "tweet_id": "1589960766433943555",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589960762705203203",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960732501676033",
   "Positive": "0.35407158732414246",
   "Negative": "0.00025443450431339443",
   "Neutral": "0.6456480622291565",
   "Mixed": "2.5857574655674398e-05"
 },
 {
   "tweet_id": "1589960727556935681",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589960720732786688",
   "Positive": "0.00015843971050344408",
   "Negative": "0.000490302627440542",
   "Neutral": "0.9993459582328796",
   "Mixed": "5.22445043316111e-06"
 },
 {
   "tweet_id": "1589960720279822336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960713942216704",
   "Positive": "0.00017094446229748428",
   "Negative": "0.04423337057232857",
   "Neutral": "0.9555795788764954",
   "Mixed": "1.6019734175642952e-05"
 },
 {
   "tweet_id": "1589960705402601472",
   "Positive": "0.403330534696579",
   "Negative": "0.0004902860382571816",
   "Neutral": "0.5961215496063232",
   "Mixed": "5.765626337961294e-05"
 },
 {
   "tweet_id": "1589960687752990721",
   "Positive": "0.0022970824502408504",
   "Negative": "0.7864004969596863",
   "Neutral": "0.21067790687084198",
   "Mixed": "0.0006245635449886322"
 },
 {
   "tweet_id": "1589960687060586497",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589960648200687616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960632115556352",
   "Positive": "0.6042302846908569",
   "Negative": "0.007651090156286955",
   "Neutral": "0.28242623805999756",
   "Mixed": "0.1056923195719719"
 },
 {
   "tweet_id": "1589960618815414278",
   "Positive": "0.0828905925154686",
   "Negative": "0.001650634570978582",
   "Neutral": "0.9151841402053833",
   "Mixed": "0.00027457630494609475"
 },
 {
   "tweet_id": "1589960610782986242",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960609621082113",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589960605808889856",
   "Positive": "0.0005133641534484923",
   "Negative": "8.27240728540346e-05",
   "Neutral": "0.9993600249290466",
   "Mixed": "4.387165972730145e-05"
 },
 {
   "tweet_id": "1589960605217460224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960598099726336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960591149793280",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589960589878566912",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1589960586217295875",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960585357103104",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589960578478804993",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960576842993664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960574200213504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960565275099136",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960561848352768",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589960557289181186",
   "Positive": "0.11502526700496674",
   "Negative": "0.0011457586660981178",
   "Neutral": "0.8837839961051941",
   "Mixed": "4.4945449189981446e-05"
 },
 {
   "tweet_id": "1589960555309150208",
   "Positive": "0.0039752149023115635",
   "Negative": "0.00012208115367684513",
   "Neutral": "0.9958986639976501",
   "Mixed": "4.002886726084398e-06"
 },
 {
   "tweet_id": "1589960550800162816",
   "Positive": "0.005217210855334997",
   "Negative": "0.0001307032653130591",
   "Neutral": "0.9946463704109192",
   "Mixed": "5.736772436648607e-06"
 },
 {
   "tweet_id": "1589960549466787840",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960549424848898",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960527350206466",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960525987053568",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960479371579392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960477412839424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960474359398401",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589960471142367233",
   "Positive": "0.9097551107406616",
   "Negative": "0.001395956496708095",
   "Neutral": "0.08880569040775299",
   "Mixed": "4.32603046647273e-05"
 },
 {
   "tweet_id": "1589960470693543944",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960459838705665",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589960455245922304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960427026673665",
   "Positive": "0.0010387981310486794",
   "Negative": "7.086254481691867e-05",
   "Neutral": "0.9988800883293152",
   "Mixed": "1.02106068879948e-05"
 },
 {
   "tweet_id": "1589960426603053057",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589960425713848320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960415039352832",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960409402216449",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960399528800257",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960389554745344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960386748764160",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960376871178242",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960376569167873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960375583510528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960371884130304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960369845710848",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960355039830016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960350992330752",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960345984323584",
   "Positive": "0.008783495053648949",
   "Negative": "0.00013365643098950386",
   "Neutral": "0.9910759925842285",
   "Mixed": "6.820967428211588e-06"
 },
 {
   "tweet_id": "1589960341743890432",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960328158511104",
   "Positive": "0.07138156145811081",
   "Negative": "0.00035619005211628973",
   "Neutral": "0.9282358884811401",
   "Mixed": "2.632631003507413e-05"
 },
 {
   "tweet_id": "1589960324161363968",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589960321808371715",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589960320059330561",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589960309401587712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960308655022081",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589960298953576450",
   "Positive": "0.00858029443770647",
   "Negative": "0.014788134023547173",
   "Neutral": "0.9766230583190918",
   "Mixed": "8.509012332069688e-06"
 },
 {
   "tweet_id": "1589960286081286144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960271958724609",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1589960250622636034",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960244020772866",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960224089477120",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589960211443638272",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589960207320633344",
   "Positive": "0.00020972166385035962",
   "Negative": "0.00950807984918356",
   "Neutral": "0.9902716279029846",
   "Mixed": "1.0511113941902295e-05"
 },
 {
   "tweet_id": "1589960192233725952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960182154788869",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960168225525760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960166241628160",
   "Positive": "0.5647937655448914",
   "Negative": "0.11409924924373627",
   "Neutral": "0.3196057081222534",
   "Mixed": "0.001501333201304078"
 },
 {
   "tweet_id": "1589960159602036736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960140677328897",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960137766498304",
   "Positive": "0.0012981138424947858",
   "Negative": "0.8457785844802856",
   "Neutral": "0.1521533727645874",
   "Mixed": "0.0007699377019889653"
 },
 {
   "tweet_id": "1589960128715173891",
   "Positive": "0.034090444445610046",
   "Negative": "0.026508983224630356",
   "Neutral": "0.27385208010673523",
   "Mixed": "0.6655485033988953"
 },
 {
   "tweet_id": "1589960120364302336",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589960118577528833",
   "Positive": "0.013847353868186474",
   "Negative": "0.00020252162357792258",
   "Neutral": "0.985927939414978",
   "Mixed": "2.220304850197863e-05"
 },
 {
   "tweet_id": "1589960107378774018",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960100944711681",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960099123978240",
   "Positive": "0.4455089867115021",
   "Negative": "0.0009277525241486728",
   "Neutral": "0.553506076335907",
   "Mixed": "5.7132237998303026e-05"
 },
 {
   "tweet_id": "1589960067142799364",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589960061543419906",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589960060851343361",
   "Positive": "0.0003101326583418995",
   "Negative": "9.934750414686278e-05",
   "Neutral": "0.9995860457420349",
   "Mixed": "4.508919118961785e-06"
 },
 {
   "tweet_id": "1589960060750663681",
   "Positive": "0.04508008435368538",
   "Negative": "0.00043527886737138033",
   "Neutral": "0.9544161558151245",
   "Mixed": "6.84473998262547e-05"
 },
 {
   "tweet_id": "1589960057415864320",
   "Positive": "0.004763742443174124",
   "Negative": "0.5309134125709534",
   "Neutral": "0.4607999622821808",
   "Mixed": "0.0035228237975388765"
 },
 {
   "tweet_id": "1589960045831524352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960044258656257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960034490155008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960031625445378",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589960020275662848",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960017289281536",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589960008350937088",
   "Positive": "0.13724856078624725",
   "Negative": "0.00020969158504158258",
   "Neutral": "0.8624894618988037",
   "Mixed": "5.222309846431017e-05"
 },
 {
   "tweet_id": "1589960003347419136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589960002764410880",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589959996875628548",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959970371825664",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959957956661248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959950209789953",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959949882621954",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959949328973825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959936317878272",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959931830370304",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589959922690973698",
   "Positive": "0.1194860190153122",
   "Negative": "0.00128331093583256",
   "Neutral": "0.8791645169258118",
   "Mixed": "6.610137643292546e-05"
 },
 {
   "tweet_id": "1589959921134886912",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959898120744963",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959897332191232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959895868055552",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589959887764627457",
   "Positive": "0.021071864292025566",
   "Negative": "0.0016375689301639795",
   "Neutral": "0.9772852659225464",
   "Mixed": "5.316572696756339e-06"
 },
 {
   "tweet_id": "1589959879938084864",
   "Positive": "0.0011570226633921266",
   "Negative": "8.825122495181859e-05",
   "Neutral": "0.9987499713897705",
   "Mixed": "4.7906673898978624e-06"
 },
 {
   "tweet_id": "1589959853883404288",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959838402236422",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959824347115521",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589959814863794177",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959811588042752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959807682760705",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589959806231904257",
   "Positive": "0.329174280166626",
   "Negative": "0.08621108531951904",
   "Neutral": "0.5844265222549438",
   "Mixed": "0.00018816014926414937"
 },
 {
   "tweet_id": "1589959803019087873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959800896753664",
   "Positive": "0.0021149206440895796",
   "Negative": "0.0006192754954099655",
   "Neutral": "0.9972630739212036",
   "Mixed": "2.7631774628389394e-06"
 },
 {
   "tweet_id": "1589959792382349312",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959778822164484",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959776641089537",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589959768827133953",
   "Positive": "0.0011375752510502934",
   "Negative": "0.0004978101351298392",
   "Neutral": "0.9983420372009277",
   "Mixed": "2.2645963326795027e-05"
 },
 {
   "tweet_id": "1589959766369251328",
   "Positive": "0.010468961670994759",
   "Negative": "0.7974798083305359",
   "Neutral": "0.19175651669502258",
   "Mixed": "0.00029474394978024065"
 },
 {
   "tweet_id": "1589959765408755712",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589959742092632065",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589959728012361728",
   "Positive": "0.007084292359650135",
   "Negative": "0.026222268119454384",
   "Neutral": "0.9666749238967896",
   "Mixed": "1.842792880779598e-05"
 },
 {
   "tweet_id": "1589959727613906946",
   "Positive": "0.004022052511572838",
   "Negative": "0.895476222038269",
   "Neutral": "0.06834118068218231",
   "Mixed": "0.03216054290533066"
 },
 {
   "tweet_id": "1589959713776893953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959709473529862",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959678238556160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959672005799938",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589959651269173249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959632038277120",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589959619014955013",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589959614178951168",
   "Positive": "0.5310097336769104",
   "Negative": "0.027933726087212563",
   "Neutral": "0.44073259830474854",
   "Mixed": "0.0003239865764044225"
 },
 {
   "tweet_id": "1589959612660580354",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959612421537794",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589959606331396096",
   "Positive": "0.13027465343475342",
   "Negative": "0.0022595725022256374",
   "Neutral": "0.8674123883247375",
   "Mixed": "5.339916970115155e-05"
 },
 {
   "tweet_id": "1589959606075523072",
   "Positive": "0.00486185634508729",
   "Negative": "0.32810741662979126",
   "Neutral": "0.6669448614120483",
   "Mixed": "8.583642193116248e-05"
 },
 {
   "tweet_id": "1589959601377939457",
   "Positive": "0.04030785337090492",
   "Negative": "0.004529625177383423",
   "Neutral": "0.9551290273666382",
   "Mixed": "3.3466971217421815e-05"
 },
 {
   "tweet_id": "1589959599003963395",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959598613889030",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959593954013186",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959592254922753",
   "Positive": "0.14979949593544006",
   "Negative": "0.03050241619348526",
   "Neutral": "0.8192892670631409",
   "Mixed": "0.00040881254244595766"
 },
 {
   "tweet_id": "1589959583237562368",
   "Positive": "0.022204814478754997",
   "Negative": "0.00030969627550803125",
   "Neutral": "0.9774503707885742",
   "Mixed": "3.509982707328163e-05"
 },
 {
   "tweet_id": "1589959578825142273",
   "Positive": "0.11549646407365799",
   "Negative": "0.0034765596501529217",
   "Neutral": "0.8809619545936584",
   "Mixed": "6.501904135802761e-05"
 },
 {
   "tweet_id": "1589959569375391745",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959563473989632",
   "Positive": "0.0008770053973421454",
   "Negative": "6.595942249987274e-05",
   "Neutral": "0.9990370273590088",
   "Mixed": "1.9964547391282395e-05"
 },
 {
   "tweet_id": "1589959562316378113",
   "Positive": "0.10569334030151367",
   "Negative": "0.052127860486507416",
   "Neutral": "0.8420013189315796",
   "Mixed": "0.00017751862469594926"
 },
 {
   "tweet_id": "1589959557140606978",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589959550647816193",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589959543454568449",
   "Positive": "0.008819462731480598",
   "Negative": "0.0014509682077914476",
   "Neutral": "0.9897207617759705",
   "Mixed": "8.860412890498992e-06"
 },
 {
   "tweet_id": "1589959543014174720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959538735648774",
   "Positive": "0.022261958569288254",
   "Negative": "0.22158513963222504",
   "Neutral": "0.2603256404399872",
   "Mixed": "0.49582725763320923"
 },
 {
   "tweet_id": "1589959464756842496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959463603433473",
   "Positive": "0.9900771379470825",
   "Negative": "0.00011904548591701314",
   "Neutral": "0.009760510176420212",
   "Mixed": "4.323651592130773e-05"
 },
 {
   "tweet_id": "1589959453071540225",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959442107617280",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589959432737533953",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959431881904137",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959425049395200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959424604786692",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959412730691585",
   "Positive": "0.18739449977874756",
   "Negative": "0.003917671740055084",
   "Neutral": "0.8086636066436768",
   "Mixed": "2.415894232399296e-05"
 },
 {
   "tweet_id": "1589959411749236736",
   "Positive": "0.9630157947540283",
   "Negative": "6.702927203150466e-05",
   "Neutral": "0.036888688802719116",
   "Mixed": "2.8478392778197303e-05"
 },
 {
   "tweet_id": "1589959410948141059",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959407567515648",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589959403700375552",
   "Positive": "0.01790369488298893",
   "Negative": "0.0008019059896469116",
   "Neutral": "0.981282114982605",
   "Mixed": "1.2225378668517806e-05"
 },
 {
   "tweet_id": "1589959399099236353",
   "Positive": "0.0036593489348888397",
   "Negative": "0.5807231664657593",
   "Neutral": "0.3849073350429535",
   "Mixed": "0.03071017563343048"
 },
 {
   "tweet_id": "1589959385878777859",
   "Positive": "0.5600337386131287",
   "Negative": "0.00018448261835146695",
   "Neutral": "0.4397217333316803",
   "Mixed": "5.999340646667406e-05"
 },
 {
   "tweet_id": "1589959366433976320",
   "Positive": "0.0007220162660814822",
   "Negative": "4.932368392474018e-05",
   "Neutral": "0.9992177486419678",
   "Mixed": "1.0830233804881573e-05"
 },
 {
   "tweet_id": "1589959357869199360",
   "Positive": "0.00044796272413805127",
   "Negative": "0.00022707157768309116",
   "Neutral": "0.9992851614952087",
   "Mixed": "3.982010457548313e-05"
 },
 {
   "tweet_id": "1589959357323628545",
   "Positive": "0.015845995396375656",
   "Negative": "0.002302295994013548",
   "Neutral": "0.9818395972251892",
   "Mixed": "1.202492512675235e-05"
 },
 {
   "tweet_id": "1589959356761505792",
   "Positive": "0.006263598799705505",
   "Negative": "0.000283034605672583",
   "Neutral": "0.9934493899345398",
   "Mixed": "4.0565964809502475e-06"
 },
 {
   "tweet_id": "1589959356283768832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959354526363648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959352701812736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959326655184897",
   "Positive": "0.4284946918487549",
   "Negative": "0.0003892118693329394",
   "Neutral": "0.5710760951042175",
   "Mixed": "4.000674016424455e-05"
 },
 {
   "tweet_id": "1589959324331573248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959309827674116",
   "Positive": "0.837407648563385",
   "Negative": "0.043093349784612656",
   "Neutral": "0.11863983422517776",
   "Mixed": "0.0008591776131652296"
 },
 {
   "tweet_id": "1589959296934350849",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959295055327232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959277363728386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959271563014147",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959265405796352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959259483435008",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959243289235456",
   "Positive": "0.1247275248169899",
   "Negative": "0.015354246832430363",
   "Neutral": "0.8573247194290161",
   "Mixed": "0.002593443263322115"
 },
 {
   "tweet_id": "1589959238105071618",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959234552463360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959224238276608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959215518343168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959212905680897",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959206685536257",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589959151081648128",
   "Positive": "0.0009758765809237957",
   "Negative": "0.4886142611503601",
   "Neutral": "0.5103737711906433",
   "Mixed": "3.618163100327365e-05"
 },
 {
   "tweet_id": "1589959150678999041",
   "Positive": "0.16382411122322083",
   "Negative": "0.00019331580551806837",
   "Neutral": "0.8357546329498291",
   "Mixed": "0.00022794817050453275"
 },
 {
   "tweet_id": "1589959115228708864",
   "Positive": "0.0007684982847422361",
   "Negative": "0.9742178320884705",
   "Neutral": "0.02443903684616089",
   "Mixed": "0.0005746046081185341"
 },
 {
   "tweet_id": "1589959111940050944",
   "Positive": "0.007104968652129173",
   "Negative": "0.0013482681242749095",
   "Neutral": "0.9915421009063721",
   "Mixed": "4.571264980768319e-06"
 },
 {
   "tweet_id": "1589959111806193666",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589959094584365056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959070735564800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959069691187207",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959064519598082",
   "Positive": "0.5976389050483704",
   "Negative": "0.015209252946078777",
   "Neutral": "0.38656309247016907",
   "Mixed": "0.0005887311417609453"
 },
 {
   "tweet_id": "1589959054134513665",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959044412080134",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959036140945408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959035339493376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589959032693231616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959031606874118",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589959006843723776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589959005514133508",
   "Positive": "0.0411798469722271",
   "Negative": "0.0489790253341198",
   "Neutral": "0.9095134139060974",
   "Mixed": "0.0003276195493526757"
 },
 {
   "tweet_id": "1589959000241897472",
   "Positive": "0.0013914312003180385",
   "Negative": "0.22337670624256134",
   "Neutral": "0.7752100229263306",
   "Mixed": "2.1854875740245916e-05"
 },
 {
   "tweet_id": "1589958999633723394",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958998761295872",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589958997574287366",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958995577823233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958991119253504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958986530705408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958978448285696",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958977949143040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958968797204480",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958967371132928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958947821490176",
   "Positive": "0.6226553916931152",
   "Negative": "0.07166916131973267",
   "Neutral": "0.30500516295433044",
   "Mixed": "0.000670366280246526"
 },
 {
   "tweet_id": "1589958943455215617",
   "Positive": "0.004562513902783394",
   "Negative": "0.0187954343855381",
   "Neutral": "0.9765356183052063",
   "Mixed": "0.0001063644522218965"
 },
 {
   "tweet_id": "1589958926690562048",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589958925801394176",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589958904993447937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958899612143616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958897057824768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958884638470144",
   "Positive": "0.008144847117364407",
   "Negative": "0.8315576314926147",
   "Neutral": "0.11533603072166443",
   "Mixed": "0.04496147856116295"
 },
 {
   "tweet_id": "1589958881052352514",
   "Positive": "0.001710031763650477",
   "Negative": "0.9431272745132446",
   "Neutral": "0.055022723972797394",
   "Mixed": "0.00013990052684675902"
 },
 {
   "tweet_id": "1589958877835296768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958849490210816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958838488551424",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958833354723330",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589958822004948992",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589958815201759233",
   "Positive": "0.0010459308978170156",
   "Negative": "4.711453948402777e-05",
   "Neutral": "0.9988961219787598",
   "Mixed": "1.0878351531573571e-05"
 },
 {
   "tweet_id": "1589958797711544320",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589958752937324545",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589958744385163264",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589958722067238912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958716966567936",
   "Positive": "0.00021102045138832182",
   "Negative": "0.9915385842323303",
   "Neutral": "0.008132983930408955",
   "Mixed": "0.00011747689131880179"
 },
 {
   "tweet_id": "1589958712437116928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958711405334528",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589958709538701313",
   "Positive": "0.004235651809722185",
   "Negative": "7.093466410879046e-05",
   "Neutral": "0.9956764578819275",
   "Mixed": "1.7013362594298087e-05"
 },
 {
   "tweet_id": "1589958703775895552",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589958703473909760",
   "Positive": "0.003710248740389943",
   "Negative": "0.0003490939852781594",
   "Neutral": "0.9959379434585571",
   "Mixed": "2.7347143713996047e-06"
 },
 {
   "tweet_id": "1589958700978302980",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589958691360747520",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589958690882609153",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589958673660784646",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589958673547554817",
   "Positive": "0.0007584012346342206",
   "Negative": "0.08993782103061676",
   "Neutral": "0.9092821478843689",
   "Mixed": "2.1654304873663932e-05"
 },
 {
   "tweet_id": "1589958672931000323",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958667558060033",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958667218341888",
   "Positive": "0.06326235085725784",
   "Negative": "0.14434772729873657",
   "Neutral": "0.772993266582489",
   "Mixed": "0.019396645948290825"
 },
 {
   "tweet_id": "1589958664508837889",
   "Positive": "0.017378851771354675",
   "Negative": "0.00020327663514763117",
   "Neutral": "0.9823978543281555",
   "Mixed": "2.0000652511953376e-05"
 },
 {
   "tweet_id": "1589958664361676802",
   "Positive": "0.033028047531843185",
   "Negative": "0.002185935154557228",
   "Neutral": "0.9647811651229858",
   "Mixed": "4.822520622838056e-06"
 },
 {
   "tweet_id": "1589958657579446273",
   "Positive": "0.002166729187592864",
   "Negative": "0.000342504441505298",
   "Neutral": "0.9974875450134277",
   "Mixed": "3.193162001480232e-06"
 },
 {
   "tweet_id": "1589958656531238912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958641909927936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958638189580288",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958629465391108",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589958624755200001",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958603691417602",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958594329718784",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958582908248068",
   "Positive": "0.5349380373954773",
   "Negative": "0.001185010769404471",
   "Neutral": "0.46377167105674744",
   "Mixed": "0.00010533187014516443"
 },
 {
   "tweet_id": "1589958568555737089",
   "Positive": "0.0015609957044944167",
   "Negative": "0.00020963775750715286",
   "Neutral": "0.9982215762138367",
   "Mixed": "7.793199984007515e-06"
 },
 {
   "tweet_id": "1589958567389716480",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958566286237696",
   "Positive": "0.04985741525888443",
   "Negative": "0.2366582751274109",
   "Neutral": "0.7120054960250854",
   "Mixed": "0.001478830468840897"
 },
 {
   "tweet_id": "1589958551912714240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958548133675009",
   "Positive": "0.06360246986150742",
   "Negative": "0.0006673376192338765",
   "Neutral": "0.9356966018676758",
   "Mixed": "3.359633046784438e-05"
 },
 {
   "tweet_id": "1589958547319947266",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589958543968727044",
   "Positive": "0.002462436445057392",
   "Negative": "0.00014802429359406233",
   "Neutral": "0.9973829388618469",
   "Mixed": "6.5460540099593345e-06"
 },
 {
   "tweet_id": "1589958523374702594",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589958518941319170",
   "Positive": "0.033014871180057526",
   "Negative": "0.00030205430812202394",
   "Neutral": "0.9666259288787842",
   "Mixed": "5.7099114201264456e-05"
 },
 {
   "tweet_id": "1589958515850113025",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589958502830977024",
   "Positive": "0.05335386469960213",
   "Negative": "0.0009735057246871293",
   "Neutral": "0.9451320171356201",
   "Mixed": "0.0005405487609095871"
 },
 {
   "tweet_id": "1589958494891175936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958493737725953",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589958483599716353",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589958482513780736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958470413189120",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589958469339123712",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589958468965781504",
   "Positive": "0.011570530012249947",
   "Negative": "0.0003427917545195669",
   "Neutral": "0.9880833029747009",
   "Mixed": "3.368765646882821e-06"
 },
 {
   "tweet_id": "1589958443175403520",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958442537881600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958442248466434",
   "Positive": "0.004698638338595629",
   "Negative": "0.00013186443538870662",
   "Neutral": "0.9951648712158203",
   "Mixed": "4.576395895128371e-06"
 },
 {
   "tweet_id": "1589958436338692097",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958433562071040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958431590338560",
   "Positive": "0.9590177536010742",
   "Negative": "0.00019630529277492315",
   "Neutral": "0.04075946658849716",
   "Mixed": "2.645442509674467e-05"
 },
 {
   "tweet_id": "1589958419070726145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958415945695232",
   "Positive": "0.024875177070498466",
   "Negative": "0.00041267790948040783",
   "Neutral": "0.9747018814086914",
   "Mixed": "1.0255445886286907e-05"
 },
 {
   "tweet_id": "1589958412904767488",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958402880720896",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1589958396325023747",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958385683681280",
   "Positive": "0.04465622827410698",
   "Negative": "0.25113075971603394",
   "Neutral": "0.07027260214090347",
   "Mixed": "0.6339404582977295"
 },
 {
   "tweet_id": "1589958384832643074",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1589958376989265921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958373893865472",
   "Positive": "0.07351019233465195",
   "Negative": "0.009625406935811043",
   "Neutral": "0.9167515635490417",
   "Mixed": "0.00011274300049990416"
 },
 {
   "tweet_id": "1589958367409504256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958347973091328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958334139879424",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958328171786240",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589958317665046528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958315291086853",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958304985649157",
   "Positive": "0.051056865602731705",
   "Negative": "0.00037426038761623204",
   "Neutral": "0.9485599398612976",
   "Mixed": "8.93425658432534e-06"
 },
 {
   "tweet_id": "1589958299818299392",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589958272127504384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958267018805251",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958257485172738",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589958239239950336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958227579764737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958222408216576",
   "Positive": "0.3798891305923462",
   "Negative": "0.00045570480870082974",
   "Neutral": "0.6196326613426208",
   "Mixed": "2.2450209144153632e-05"
 },
 {
   "tweet_id": "1589958216771072001",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958211217797122",
   "Positive": "0.005667698569595814",
   "Negative": "0.8941599130630493",
   "Neutral": "0.09565193206071854",
   "Mixed": "0.004520375281572342"
 },
 {
   "tweet_id": "1589958199062712323",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589958193106784257",
   "Positive": "0.6344233155250549",
   "Negative": "0.0030037446413189173",
   "Neutral": "0.3625296652317047",
   "Mixed": "4.318138599046506e-05"
 },
 {
   "tweet_id": "1589958176346345473",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958159132921856",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1589958156591169537",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958148143853569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958147242098689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958146386440193",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958142905192451",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589958128724234241",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589958114983698432",
   "Positive": "0.004613302648067474",
   "Negative": "0.9327468276023865",
   "Neutral": "0.05759790167212486",
   "Mixed": "0.0050420998595654964"
 },
 {
   "tweet_id": "1589958112748113920",
   "Positive": "0.352726012468338",
   "Negative": "0.014476684853434563",
   "Neutral": "0.6326621770858765",
   "Mixed": "0.00013517207116819918"
 },
 {
   "tweet_id": "1589958110273224704",
   "Positive": "0.7016639709472656",
   "Negative": "0.06506790965795517",
   "Neutral": "0.1926746815443039",
   "Mixed": "0.04059341549873352"
 },
 {
   "tweet_id": "1589958088203075585",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958075494326272",
   "Positive": "0.00862239208072424",
   "Negative": "0.833070695400238",
   "Neutral": "0.15619347989559174",
   "Mixed": "0.0021134240087121725"
 },
 {
   "tweet_id": "1589958073359433731",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589958070654107649",
   "Positive": "0.002292386954650283",
   "Negative": "0.7686894536018372",
   "Neutral": "0.22719454765319824",
   "Mixed": "0.0018235491588711739"
 },
 {
   "tweet_id": "1589958064144539649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958053985947649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958047761600513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958046524248064",
   "Positive": "0.3199232220649719",
   "Negative": "0.001774369040504098",
   "Neutral": "0.6782426238059998",
   "Mixed": "5.97698162891902e-05"
 },
 {
   "tweet_id": "1589958044095778816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589958010612637696",
   "Positive": "0.6802738904953003",
   "Negative": "0.0007798950537107885",
   "Neutral": "0.3189186155796051",
   "Mixed": "2.7526519261300564e-05"
 },
 {
   "tweet_id": "1589958007114600448",
   "Positive": "0.4809751510620117",
   "Negative": "0.06791166961193085",
   "Neutral": "0.4506457448005676",
   "Mixed": "0.0004673890070989728"
 },
 {
   "tweet_id": "1589957984066899970",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957978475859968",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589957945772892160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957940047335425",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589957939850510336",
   "Positive": "0.003633658867329359",
   "Negative": "0.00014366096002049744",
   "Neutral": "0.996217668056488",
   "Mixed": "4.9440545808465686e-06"
 },
 {
   "tweet_id": "1589957923819900929",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589957916865728512",
   "Positive": "0.04465622827410698",
   "Negative": "0.25113075971603394",
   "Neutral": "0.07027260214090347",
   "Mixed": "0.6339404582977295"
 },
 {
   "tweet_id": "1589957915360002048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957907399217154",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589957887291404288",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589957865070284804",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589957860989239298",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957850335711232",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589957848720584704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957806979186688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957802294128642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957792101986304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957785776971776",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589957770144776193",
   "Positive": "0.5503659844398499",
   "Negative": "0.004061078652739525",
   "Neutral": "0.4454198181629181",
   "Mixed": "0.00015319744125008583"
 },
 {
   "tweet_id": "1589957761160601600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957758388142081",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957752520347650",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589957716948443136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957693170909187",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957686304845826",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957678264385537",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957660509540359",
   "Positive": "0.025711722671985626",
   "Negative": "0.8091540932655334",
   "Neutral": "0.045719537883996964",
   "Mixed": "0.11941465735435486"
 },
 {
   "tweet_id": "1589957654897909761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957653476028416",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957647281029121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957646479929345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957642562449414",
   "Positive": "0.024720067158341408",
   "Negative": "0.0006302253459580243",
   "Neutral": "0.974643886089325",
   "Mixed": "5.855217295902548e-06"
 },
 {
   "tweet_id": "1589957638619791361",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957633532100608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957615953801217",
   "Positive": "0.4570380449295044",
   "Negative": "0.008917441591620445",
   "Neutral": "0.5340188145637512",
   "Mixed": "2.5634572011767887e-05"
 },
 {
   "tweet_id": "1589957613797924865",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589957613114261505",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957604171972608",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589957597544972289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957595988885542",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957583988985862",
   "Positive": "0.015704825520515442",
   "Negative": "0.04619704559445381",
   "Neutral": "0.9374240040779114",
   "Mixed": "0.0006740691023878753"
 },
 {
   "tweet_id": "1589957572689526787",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957550430380032",
   "Positive": "0.0022182664833962917",
   "Negative": "0.8047921061515808",
   "Neutral": "0.1923103928565979",
   "Mixed": "0.0006792152998968959"
 },
 {
   "tweet_id": "1589957544193462272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957541143842816",
   "Positive": "0.8729280233383179",
   "Negative": "0.00022609703592024744",
   "Neutral": "0.12682610750198364",
   "Mixed": "1.9752898879232816e-05"
 },
 {
   "tweet_id": "1589957533955162112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957532541685760",
   "Positive": "0.44110754132270813",
   "Negative": "0.0016147036803886294",
   "Neutral": "0.5554010272026062",
   "Mixed": "0.001876767142675817"
 },
 {
   "tweet_id": "1589957525885300742",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957523179982848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957513004605441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957478778748928",
   "Positive": "0.000540862325578928",
   "Negative": "6.074360862839967e-05",
   "Neutral": "0.9993738532066345",
   "Mixed": "2.4575752831879072e-05"
 },
 {
   "tweet_id": "1589957471350583296",
   "Positive": "0.03952694684267044",
   "Negative": "9.476159902988002e-05",
   "Neutral": "0.9603502750396729",
   "Mixed": "2.7924737878493033e-05"
 },
 {
   "tweet_id": "1589957460491923459",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957455383269378",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957453416128515",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589957448655597568",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589957431119208448",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589957415700955138",
   "Positive": "0.008819462731480598",
   "Negative": "0.0014509682077914476",
   "Neutral": "0.9897207617759705",
   "Mixed": "8.860412890498992e-06"
 },
 {
   "tweet_id": "1589957405173243904",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589957403780730881",
   "Positive": "0.004900228697806597",
   "Negative": "0.00010733093222370371",
   "Neutral": "0.9949880838394165",
   "Mixed": "4.415238436195068e-06"
 },
 {
   "tweet_id": "1589957402362724353",
   "Positive": "0.02009006217122078",
   "Negative": "0.00039676824235357344",
   "Neutral": "0.9795066118240356",
   "Mixed": "6.5776039264164865e-06"
 },
 {
   "tweet_id": "1589957381030834177",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957374072467460",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957357593055235",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589957351087357952",
   "Positive": "0.0866251140832901",
   "Negative": "0.016667349264025688",
   "Neutral": "0.8939545750617981",
   "Mixed": "0.0027529869694262743"
 },
 {
   "tweet_id": "1589957342161825792",
   "Positive": "0.016226164996623993",
   "Negative": "0.001753150369040668",
   "Neutral": "0.9820075631141663",
   "Mixed": "1.306916783505585e-05"
 },
 {
   "tweet_id": "1589957336982249472",
   "Positive": "0.00036080405698157847",
   "Negative": "7.229961192933843e-05",
   "Neutral": "0.9995146989822388",
   "Mixed": "5.2146322559565306e-05"
 },
 {
   "tweet_id": "1589957320653824001",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589957318317608960",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957304770002946",
   "Positive": "0.0024568636436015368",
   "Negative": "0.38426241278648376",
   "Neutral": "0.612537682056427",
   "Mixed": "0.0007430793484672904"
 },
 {
   "tweet_id": "1589957288181501958",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589957282632470528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957275963183104",
   "Positive": "0.006233692169189453",
   "Negative": "0.00023271834652405232",
   "Neutral": "0.9935237169265747",
   "Mixed": "9.837346624408383e-06"
 },
 {
   "tweet_id": "1589957266794434560",
   "Positive": "0.771948516368866",
   "Negative": "0.0003096391155850142",
   "Neutral": "0.22717450559139252",
   "Mixed": "0.0005673067062161863"
 },
 {
   "tweet_id": "1589957263623876608",
   "Positive": "0.13628613948822021",
   "Negative": "0.000339247053489089",
   "Neutral": "0.8628549575805664",
   "Mixed": "0.0005196975544095039"
 },
 {
   "tweet_id": "1589957256447422465",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957254392184832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957252827734016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957245479313409",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589957240957853696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957212944105472",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957190278082564",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957176516579328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957170334171136",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589957159932284929",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957142500741121",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957136087674882",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957132564434947",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957130458890241",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957124695949313",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957117989236738",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957087379230720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957084078297090",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957077753294848",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589957075664506881",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589957070047965186",
   "Positive": "0.004687196109443903",
   "Negative": "0.13481830060482025",
   "Neutral": "0.8590277433395386",
   "Mixed": "0.0014667590148746967"
 },
 {
   "tweet_id": "1589957066697080833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957060002996224",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1589957052427665409",
   "Positive": "0.0410253182053566",
   "Negative": "0.0007916000904515386",
   "Neutral": "0.9579705595970154",
   "Mixed": "0.00021247140830382705"
 },
 {
   "tweet_id": "1589957049827602433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957048099569664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957043594887171",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957042747641856",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589957031602970624",
   "Positive": "0.0014042290858924389",
   "Negative": "0.0002883142442442477",
   "Neutral": "0.9983049631118774",
   "Mixed": "2.551303623476997e-06"
 },
 {
   "tweet_id": "1589957027828142080",
   "Positive": "0.00354015058837831",
   "Negative": "0.057299111038446426",
   "Neutral": "0.9389134645462036",
   "Mixed": "0.000247217423748225"
 },
 {
   "tweet_id": "1589957024791425024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589957022027587594",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589957004348751874",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956963995365376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956958328852480",
   "Positive": "0.07409370690584183",
   "Negative": "0.0004872434074059129",
   "Neutral": "0.9253864884376526",
   "Mixed": "3.253372051403858e-05"
 },
 {
   "tweet_id": "1589956956768313346",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956918554284034",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589956915622465538",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956906701180929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956895464308736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956891790446592",
   "Positive": "0.02525136061012745",
   "Negative": "0.0482364259660244",
   "Neutral": "0.925643801689148",
   "Mixed": "0.0008684549829922616"
 },
 {
   "tweet_id": "1589956883120803841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956874409250817",
   "Positive": "0.04054044187068939",
   "Negative": "0.5896692872047424",
   "Neutral": "0.36857664585113525",
   "Mixed": "0.0012136617442592978"
 },
 {
   "tweet_id": "1589956871523545088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956870680109056",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589956869917126657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956857916928000",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589956847863500800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956845141381120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956844780650497",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589956844226650112",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956844121821184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956839558742016",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589956837121544193",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589956836874391552",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589956832168415232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956828938510336",
   "Positive": "0.04148794710636139",
   "Negative": "0.0007709917263127863",
   "Neutral": "0.9577334523200989",
   "Mixed": "7.623473265994107e-06"
 },
 {
   "tweet_id": "1589956825008713730",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956824207601666",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589956797766721538",
   "Positive": "0.005152729339897633",
   "Negative": "9.978794696507975e-05",
   "Neutral": "0.9947317838668823",
   "Mixed": "1.5650151908630505e-05"
 },
 {
   "tweet_id": "1589956796734935041",
   "Positive": "0.0017918564844876528",
   "Negative": "0.0002142928569810465",
   "Neutral": "0.9979915618896484",
   "Mixed": "2.3137149582908023e-06"
 },
 {
   "tweet_id": "1589956785401925632",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589956782197477377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956778829438976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956761259151360",
   "Positive": "0.17248134315013885",
   "Negative": "0.00019461095507722348",
   "Neutral": "0.8271415829658508",
   "Mixed": "0.00018245082173962146"
 },
 {
   "tweet_id": "1589956761154650113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956760642936835",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589956755655913472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956748743704577",
   "Positive": "0.9958605170249939",
   "Negative": "4.8903639253694564e-05",
   "Neutral": "0.004078440833836794",
   "Mixed": "1.2134770258853678e-05"
 },
 {
   "tweet_id": "1589956746625265664",
   "Positive": "0.001856092014349997",
   "Negative": "0.00023890481679700315",
   "Neutral": "0.9979020357131958",
   "Mixed": "3.00103329209378e-06"
 },
 {
   "tweet_id": "1589956741919539200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956737691713539",
   "Positive": "0.0008377031772397459",
   "Negative": "0.012397210113704205",
   "Neutral": "0.9867568016052246",
   "Mixed": "8.184240869013593e-06"
 },
 {
   "tweet_id": "1589956729160495104",
   "Positive": "0.004646751098334789",
   "Negative": "0.0007561644306406379",
   "Neutral": "0.9945738911628723",
   "Mixed": "2.318524639122188e-05"
 },
 {
   "tweet_id": "1589956722365698048",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589956706817433601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956705332645889",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956700295270400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956698760163331",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956697300533248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956686932213760",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589956684088504322",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956681009889280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956670419243011",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956655965667329",
   "Positive": "0.29958614706993103",
   "Negative": "0.022971810773015022",
   "Neutral": "0.6758121848106384",
   "Mixed": "0.0016297793481498957"
 },
 {
   "tweet_id": "1589956650743771137",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956646390075392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956628790792192",
   "Positive": "0.09847305715084076",
   "Negative": "0.45822861790657043",
   "Neutral": "0.41220590472221375",
   "Mixed": "0.031092369928956032"
 },
 {
   "tweet_id": "1589956626827870208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956624248352769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956621702410244",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589956615016706048",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956609509584899",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956608460984320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956603004203009",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956601867546624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956600005300224",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589956591604101120",
   "Positive": "0.5503659844398499",
   "Negative": "0.004061078652739525",
   "Neutral": "0.4454198181629181",
   "Mixed": "0.00015319744125008583"
 },
 {
   "tweet_id": "1589956576064176129",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589956572897497088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956565611982848",
   "Positive": "0.031086772680282593",
   "Negative": "0.04391846805810928",
   "Neutral": "0.92374187707901",
   "Mixed": "0.0012528860243037343"
 },
 {
   "tweet_id": "1589956560008232962",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589956554857492480",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589956544376242176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956538352836609",
   "Positive": "0.0010043455986306071",
   "Negative": "7.446230301866308e-05",
   "Neutral": "0.9989134073257446",
   "Mixed": "7.793782970111351e-06"
 },
 {
   "tweet_id": "1589956529243181056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956528823767043",
   "Positive": "0.001472811563871801",
   "Negative": "5.551874346565455e-05",
   "Neutral": "0.9984630346298218",
   "Mixed": "8.630841875856277e-06"
 },
 {
   "tweet_id": "1589956493558042626",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956486150914048",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1589956485605625857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956474910183429",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956471877677056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956454764929025",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956454530060288",
   "Positive": "0.0033479775302112103",
   "Negative": "0.8291003704071045",
   "Neutral": "0.16750884056091309",
   "Mixed": "4.281458313926123e-05"
 },
 {
   "tweet_id": "1589956450893582337",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589956449794654208",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956443171860481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956439740919810",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956425945522176",
   "Positive": "0.9960202574729919",
   "Negative": "0.00036415187059901655",
   "Neutral": "0.00354554271325469",
   "Mixed": "7.005083898548037e-05"
 },
 {
   "tweet_id": "1589956425748729857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956388499099648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956379661729793",
   "Positive": "0.003991235047578812",
   "Negative": "0.0020874198526144028",
   "Neutral": "0.9939192533493042",
   "Mixed": "2.119838882208569e-06"
 },
 {
   "tweet_id": "1589956367108182018",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956364851326977",
   "Positive": "0.06360246986150742",
   "Negative": "0.0006673376192338765",
   "Neutral": "0.9356966018676758",
   "Mixed": "3.359633046784438e-05"
 },
 {
   "tweet_id": "1589956357679353856",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589956350129639424",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589956346488979456",
   "Positive": "0.003184000262990594",
   "Negative": "0.35933494567871094",
   "Neutral": "0.6362100839614868",
   "Mixed": "0.0012709295842796564"
 },
 {
   "tweet_id": "1589956338750492677",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956331414380544",
   "Positive": "0.006981335114687681",
   "Negative": "0.0019204384880140424",
   "Neutral": "0.9910898208618164",
   "Mixed": "8.344248271896504e-06"
 },
 {
   "tweet_id": "1589956329145536512",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589956322166206465",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956319301156869",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1589956317128454144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956316071886848",
   "Positive": "0.7962803244590759",
   "Negative": "0.0014393245801329613",
   "Neutral": "0.2020353376865387",
   "Mixed": "0.0002449457242619246"
 },
 {
   "tweet_id": "1589956314478051328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956290029420544",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589956271469649921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956265312415744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956245582381056",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589956233658007552",
   "Positive": "0.029451103881001472",
   "Negative": "0.0002651886024978012",
   "Neutral": "0.9701710343360901",
   "Mixed": "0.00011262672342127189"
 },
 {
   "tweet_id": "1589956231351107585",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589956212199919617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956207049334788",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956187617124352",
   "Positive": "0.04148794710636139",
   "Negative": "0.0007709917263127863",
   "Neutral": "0.9577334523200989",
   "Mixed": "7.623473265994107e-06"
 },
 {
   "tweet_id": "1589956186723737604",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956175910797312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956166389760000",
   "Positive": "0.015976430848240852",
   "Negative": "0.001981345470994711",
   "Neutral": "0.9820306301116943",
   "Mixed": "1.1585319043661002e-05"
 },
 {
   "tweet_id": "1589956162325458944",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956160773181440",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589956118981545984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956115756122112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956102028165123",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956091584315395",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956085586485250",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589956062874304513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956058633883648",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589956055324561409",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589956048743727104",
   "Positive": "0.9483818411827087",
   "Negative": "0.00019974703900516033",
   "Neutral": "0.05139601230621338",
   "Mixed": "2.2414307750295848e-05"
 },
 {
   "tweet_id": "1589956047040843781",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589956012404277249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956008113491969",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589956006746132481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589956005575917573",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589956003923398658",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955998319796224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955994205171712",
   "Positive": "0.00041540045640431345",
   "Negative": "0.0001222793071065098",
   "Neutral": "0.999445378780365",
   "Mixed": "1.6937658074311912e-05"
 },
 {
   "tweet_id": "1589955988974497792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955987590504449",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955982679248896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955981324455936",
   "Positive": "0.004007012117654085",
   "Negative": "0.0007351094391196966",
   "Neutral": "0.9952293634414673",
   "Mixed": "2.85583155346103e-05"
 },
 {
   "tweet_id": "1589955978472349696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955974852653058",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955964421410816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955961636077568",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589955960227139585",
   "Positive": "0.43644675612449646",
   "Negative": "0.030981605872511864",
   "Neutral": "0.5321793556213379",
   "Mixed": "0.00039234510040841997"
 },
 {
   "tweet_id": "1589955955651153920",
   "Positive": "0.00041540045640431345",
   "Negative": "0.0001222793071065098",
   "Neutral": "0.999445378780365",
   "Mixed": "1.6937658074311912e-05"
 },
 {
   "tweet_id": "1589955952526360576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955949363892225",
   "Positive": "0.9146413803100586",
   "Negative": "0.00022268565953709185",
   "Neutral": "0.08506899327039719",
   "Mixed": "6.695411866530776e-05"
 },
 {
   "tweet_id": "1589955943793856513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955942661390338",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955929176674304",
   "Positive": "0.29377996921539307",
   "Negative": "0.0009157704189419746",
   "Neutral": "0.7052551507949829",
   "Mixed": "4.910063216811977e-05"
 },
 {
   "tweet_id": "1589955921308180482",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955912152010752",
   "Positive": "0.9983078241348267",
   "Negative": "0.0001434450241504237",
   "Neutral": "0.0015405932208523154",
   "Mixed": "8.22741822048556e-06"
 },
 {
   "tweet_id": "1589955908905623552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955882523430913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955875242127367",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589955867944054784",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955861900038144",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955858091634689",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589955846435663873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955839104012288",
   "Positive": "0.0007253463263623416",
   "Negative": "0.9859302639961243",
   "Neutral": "0.01326180249452591",
   "Mixed": "8.269050158560276e-05"
 },
 {
   "tweet_id": "1589955826051338240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955822377140224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955808284270592",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589955804006055942",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589955787920920576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955785395953667",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955784250904576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955782858412032",
   "Positive": "0.0030660931952297688",
   "Negative": "0.0035368362441658974",
   "Neutral": "0.9933891296386719",
   "Mixed": "7.942462616483681e-06"
 },
 {
   "tweet_id": "1589955781579149313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955776940232706",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589955776512040960",
   "Positive": "0.03280453756451607",
   "Negative": "0.12308984994888306",
   "Neutral": "0.8440093398094177",
   "Mixed": "9.626486280467361e-05"
 },
 {
   "tweet_id": "1589955760364335104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955758715981825",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955745898180609",
   "Positive": "0.1214313730597496",
   "Negative": "0.01720317453145981",
   "Neutral": "0.827625036239624",
   "Mixed": "0.03374039754271507"
 },
 {
   "tweet_id": "1589955738893312001",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589955737421086720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955727485198336",
   "Positive": "0.004007012117654085",
   "Negative": "0.0007351094391196966",
   "Neutral": "0.9952293634414673",
   "Mixed": "2.85583155346103e-05"
 },
 {
   "tweet_id": "1589955723714142208",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589955714877124608",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589955711517495303",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955710045290497",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955709705523200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955704500420609",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589955699744079874",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955696404987904",
   "Positive": "0.018866287544369698",
   "Negative": "0.0018518995493650436",
   "Neutral": "0.9792714715003967",
   "Mixed": "1.0276607099513058e-05"
 },
 {
   "tweet_id": "1589955691258998785",
   "Positive": "0.015807203948497772",
   "Negative": "0.002757168374955654",
   "Neutral": "0.9814234375953674",
   "Mixed": "1.2202756806800608e-05"
 },
 {
   "tweet_id": "1589955672418156545",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955670412914689",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955666265124864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955663106838533",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955654747590658",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955651543126017",
   "Positive": "0.09788991510868073",
   "Negative": "0.35997888445854187",
   "Neutral": "0.4796470105648041",
   "Mixed": "0.06248415261507034"
 },
 {
   "tweet_id": "1589955647218790402",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955646619029504",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589955644555251712",
   "Positive": "0.003661186434328556",
   "Negative": "0.0011362060904502869",
   "Neutral": "0.9951942563056946",
   "Mixed": "8.328804142365698e-06"
 },
 {
   "tweet_id": "1589955625827840001",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955623172837376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955615341760512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955614817779712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955610639945729",
   "Positive": "0.09598834812641144",
   "Negative": "0.0006332455086521804",
   "Neutral": "0.9033689498901367",
   "Mixed": "9.514022167422809e-06"
 },
 {
   "tweet_id": "1589955605778685953",
   "Positive": "0.0043084812350571156",
   "Negative": "0.00010121667583007365",
   "Neutral": "0.995585560798645",
   "Mixed": "4.698331849795068e-06"
 },
 {
   "tweet_id": "1589955601874169859",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955581301100545",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955568944689152",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955561914703873",
   "Positive": "0.3436771333217621",
   "Negative": "0.0064648897387087345",
   "Neutral": "0.6497897505760193",
   "Mixed": "6.813128129579127e-05"
 },
 {
   "tweet_id": "1589955556126519296",
   "Positive": "0.3312627077102661",
   "Negative": "0.0064794267527759075",
   "Neutral": "0.6621838212013245",
   "Mixed": "7.40426912670955e-05"
 },
 {
   "tweet_id": "1589955554851848192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955521410662402",
   "Positive": "0.1319178342819214",
   "Negative": "0.0006145751685835421",
   "Neutral": "0.8674569725990295",
   "Mixed": "1.0622603440424427e-05"
 },
 {
   "tweet_id": "1589955497914167298",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955477366255617",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955475038408704",
   "Positive": "0.09773555397987366",
   "Negative": "0.06425478309392929",
   "Neutral": "0.837915301322937",
   "Mixed": "9.441566362511367e-05"
 },
 {
   "tweet_id": "1589955473805299714",
   "Positive": "0.15286695957183838",
   "Negative": "0.012266479432582855",
   "Neutral": "0.8334705233573914",
   "Mixed": "0.001396043342538178"
 },
 {
   "tweet_id": "1589955473281028096",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955472844804096",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589955464690737152",
   "Positive": "0.04148794710636139",
   "Negative": "0.0007709917263127863",
   "Neutral": "0.9577334523200989",
   "Mixed": "7.623473265994107e-06"
 },
 {
   "tweet_id": "1589955460270297091",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589955446936567809",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955445765963777",
   "Positive": "0.004007012117654085",
   "Negative": "0.0007351094391196966",
   "Neutral": "0.9952293634414673",
   "Mixed": "2.85583155346103e-05"
 },
 {
   "tweet_id": "1589955437373587457",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955436010442752",
   "Positive": "0.00041324281482957304",
   "Negative": "0.00017240426677744836",
   "Neutral": "0.9994089603424072",
   "Mixed": "5.397556378738955e-06"
 },
 {
   "tweet_id": "1589955425394655232",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955425272991744",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955422391504896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955418092371969",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955416510803969",
   "Positive": "0.07433381676673889",
   "Negative": "0.027753395959734917",
   "Neutral": "0.8908335566520691",
   "Mixed": "0.007079250644892454"
 },
 {
   "tweet_id": "1589955413906460672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955411914149888",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955411729580033",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955392825696256",
   "Positive": "0.010710719041526318",
   "Negative": "0.3484482765197754",
   "Neutral": "0.5668010711669922",
   "Mixed": "0.07404004037380219"
 },
 {
   "tweet_id": "1589955387473948674",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589955377705066496",
   "Positive": "0.7677962183952332",
   "Negative": "0.0011906060390174389",
   "Neutral": "0.23097898066043854",
   "Mixed": "3.4258155210409313e-05"
 },
 {
   "tweet_id": "1589955372273782785",
   "Positive": "0.9849660396575928",
   "Negative": "0.0002503324649296701",
   "Neutral": "0.014683167450129986",
   "Mixed": "0.00010041129280580208"
 },
 {
   "tweet_id": "1589955365063778305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955363268624384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955362014498816",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955360307093504",
   "Positive": "0.5305147171020508",
   "Negative": "0.03771444410085678",
   "Neutral": "0.42688214778900146",
   "Mixed": "0.004888651892542839"
 },
 {
   "tweet_id": "1589955351025438727",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955322135052288",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589955305773101057",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589955303847919616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955285380386816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955279437070338",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955278522716164",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955274034806784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955262588555266",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955233899106305",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589955201972449280",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955195043467265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955179310239744",
   "Positive": "0.03403327614068985",
   "Negative": "0.00012271870218683034",
   "Neutral": "0.9658297300338745",
   "Mixed": "1.423900539521128e-05"
 },
 {
   "tweet_id": "1589955178404642816",
   "Positive": "0.16183289885520935",
   "Negative": "0.0029135490767657757",
   "Neutral": "0.8350536227226257",
   "Mixed": "0.00019988878921139985"
 },
 {
   "tweet_id": "1589955175095189504",
   "Positive": "0.20164990425109863",
   "Negative": "0.09382300078868866",
   "Neutral": "0.7012867331504822",
   "Mixed": "0.0032403687946498394"
 },
 {
   "tweet_id": "1589955157357645825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955155658932226",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589955149094875138",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955143889735681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955122909810688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955121467002880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955115653664770",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955099329433602",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955094648614914",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589955083906994176",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955082065702912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589955077288386561",
   "Positive": "0.019069382920861244",
   "Negative": "0.0012644834350794554",
   "Neutral": "0.9796181321144104",
   "Mixed": "4.795824861503206e-05"
 },
 {
   "tweet_id": "1589955073911947267",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955072221679616",
   "Positive": "0.00014489752356894314",
   "Negative": "0.9733464121818542",
   "Neutral": "0.02649204060435295",
   "Mixed": "1.6610247257631272e-05"
 },
 {
   "tweet_id": "1589955067867955201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955057583554560",
   "Positive": "0.006636766251176596",
   "Negative": "0.45511385798454285",
   "Neutral": "0.5347543358802795",
   "Mixed": "0.0034950412809848785"
 },
 {
   "tweet_id": "1589955057218617345",
   "Positive": "0.19323790073394775",
   "Negative": "0.04479365050792694",
   "Neutral": "0.7619266510009766",
   "Mixed": "4.18604067817796e-05"
 },
 {
   "tweet_id": "1589955048594817025",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589955047584329728",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589955037786095616",
   "Positive": "0.5764116048812866",
   "Negative": "0.0003233084571547806",
   "Neutral": "0.4231264293193817",
   "Mixed": "0.00013861761544831097"
 },
 {
   "tweet_id": "1589955037526052871",
   "Positive": "0.004131809808313847",
   "Negative": "0.0015544703928753734",
   "Neutral": "0.9943100214004517",
   "Mixed": "3.6956269013899146e-06"
 },
 {
   "tweet_id": "1589955032723894272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955029842423808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589955022481424384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954991082835968",
   "Positive": "0.18467411398887634",
   "Negative": "0.06198922172188759",
   "Neutral": "0.7528951168060303",
   "Mixed": "0.0004415449220687151"
 },
 {
   "tweet_id": "1589954981624688640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954974469206016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954965975728128",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954962054090752",
   "Positive": "0.04908469691872597",
   "Negative": "0.018057603389024734",
   "Neutral": "0.9320861101150513",
   "Mixed": "0.0007715997635386884"
 },
 {
   "tweet_id": "1589954959185182720",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954957272584193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954952637874178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954949999661058",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954946757455872",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589954940948316160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954932106752000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954929783078912",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589954922497609728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954916780748801",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589954906500497408",
   "Positive": "0.0016680042026564479",
   "Negative": "8.914378850022331e-05",
   "Neutral": "0.9982374906539917",
   "Mixed": "5.409838195191696e-06"
 },
 {
   "tweet_id": "1589954890478292993",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589954884975329281",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954882299400194",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954862657466368",
   "Positive": "0.02624652348458767",
   "Negative": "0.6532930731773376",
   "Neutral": "0.27258002758026123",
   "Mixed": "0.04788035526871681"
 },
 {
   "tweet_id": "1589954859297546241",
   "Positive": "0.04071804881095886",
   "Negative": "0.0009068323415704072",
   "Neutral": "0.9583061933517456",
   "Mixed": "6.887664494570345e-05"
 },
 {
   "tweet_id": "1589954857792049152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954850938200065",
   "Positive": "0.004698638338595629",
   "Negative": "0.00013186443538870662",
   "Neutral": "0.9951648712158203",
   "Mixed": "4.576395895128371e-06"
 },
 {
   "tweet_id": "1589954845842313216",
   "Positive": "0.15827254951000214",
   "Negative": "0.0006679449579678476",
   "Neutral": "0.8409426212310791",
   "Mixed": "0.00011693526903400198"
 },
 {
   "tweet_id": "1589954830206111746",
   "Positive": "0.6099430322647095",
   "Negative": "0.0005071742925792933",
   "Neutral": "0.3895109295845032",
   "Mixed": "3.889550862368196e-05"
 },
 {
   "tweet_id": "1589954825575596033",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954821980770304",
   "Positive": "0.003916545305401087",
   "Negative": "0.0002486163575667888",
   "Neutral": "0.9958250522613525",
   "Mixed": "9.808803042687941e-06"
 },
 {
   "tweet_id": "1589954821192568832",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589954813428924416",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954795296940032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954790679019522",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954778721030145",
   "Positive": "0.0007276571122929454",
   "Negative": "0.00019613717449828982",
   "Neutral": "0.9990726709365845",
   "Mixed": "3.493086751404917e-06"
 },
 {
   "tweet_id": "1589954768478572544",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589954768252080130",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589954766561742848",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954756965195776",
   "Positive": "0.11621544510126114",
   "Negative": "0.0007240255945362151",
   "Neutral": "0.8828727006912231",
   "Mixed": "0.00018789817113429308"
 },
 {
   "tweet_id": "1589954754876432385",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589954742666809344",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589954731577077766",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589954724992024578",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954723163287552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954717500964864",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589954716200767490",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589954710441979910",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954695644446722",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589954682428219392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954681715191808",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1589954674656169986",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954669685932032",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589954658625200128",
   "Positive": "0.03725896030664444",
   "Negative": "0.08606192469596863",
   "Neutral": "0.8765930533409119",
   "Mixed": "8.611232624389231e-05"
 },
 {
   "tweet_id": "1589954637892751360",
   "Positive": "0.04804642125964165",
   "Negative": "8.235562563640997e-05",
   "Neutral": "0.9517500400543213",
   "Mixed": "0.0001211608323501423"
 },
 {
   "tweet_id": "1589954637800435712",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589954634155950080",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954629244424193",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589954612634611713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954608876904448",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589954581760741377",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954569148432385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954562710179841",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954557828026368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954518678392833",
   "Positive": "0.0004452388093341142",
   "Negative": "0.00025870976969599724",
   "Neutral": "0.9992936849594116",
   "Mixed": "2.369056119277957e-06"
 },
 {
   "tweet_id": "1589954512340791298",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589954511791349761",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954511409664000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954501595004928",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954500907106312",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954496804712449",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954495487696897",
   "Positive": "0.00027322827372699976",
   "Negative": "0.00030858174432069063",
   "Neutral": "0.9994156360626221",
   "Mixed": "2.565545173638384e-06"
 },
 {
   "tweet_id": "1589954479394549760",
   "Positive": "0.00032623723382130265",
   "Negative": "0.0003066700592171401",
   "Neutral": "0.9993647933006287",
   "Mixed": "2.247869360871846e-06"
 },
 {
   "tweet_id": "1589954467277189120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954463997259776",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589954462306938882",
   "Positive": "0.0003649163991212845",
   "Negative": "0.00017580125131644309",
   "Neutral": "0.9994563460350037",
   "Mixed": "3.023221097464557e-06"
 },
 {
   "tweet_id": "1589954451548561408",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589954450252509184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954446879952897",
   "Positive": "0.0003595543676055968",
   "Negative": "0.0005465710419230163",
   "Neutral": "0.9990918636322021",
   "Mixed": "2.081680122500984e-06"
 },
 {
   "tweet_id": "1589954444422438912",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954437606674437",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954428794462208",
   "Positive": "0.00046691298484802246",
   "Negative": "0.00028127187397331",
   "Neutral": "0.9992493987083435",
   "Mixed": "2.3577019874210237e-06"
 },
 {
   "tweet_id": "1589954414970044419",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589954413053243392",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589954399358500865",
   "Positive": "0.015976430848240852",
   "Negative": "0.001981345470994711",
   "Neutral": "0.9820306301116943",
   "Mixed": "1.1585319043661002e-05"
 },
 {
   "tweet_id": "1589954399241019392",
   "Positive": "0.000908593472559005",
   "Negative": "0.00047646547318436205",
   "Neutral": "0.998612642288208",
   "Mixed": "2.3052516553434543e-06"
 },
 {
   "tweet_id": "1589954385278554117",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589954373869719552",
   "Positive": "0.012765125371515751",
   "Negative": "0.0017066440777853131",
   "Neutral": "0.9855220317840576",
   "Mixed": "6.173579095047899e-06"
 },
 {
   "tweet_id": "1589954368996274176",
   "Positive": "0.0004277437401469797",
   "Negative": "0.00035726206260733306",
   "Neutral": "0.9991843104362488",
   "Mixed": "3.0699164199177176e-05"
 },
 {
   "tweet_id": "1589954364596424704",
   "Positive": "0.0004142689285799861",
   "Negative": "0.00023119313118513674",
   "Neutral": "0.9993517994880676",
   "Mixed": "2.693223450478399e-06"
 },
 {
   "tweet_id": "1589954364336402432",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589954360871899137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954358216908801",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954350075744256",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589954347043291137",
   "Positive": "0.04148794710636139",
   "Negative": "0.0007709917263127863",
   "Neutral": "0.9577334523200989",
   "Mixed": "7.623473265994107e-06"
 },
 {
   "tweet_id": "1589954339246059521",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954334498131968",
   "Positive": "0.12126541137695312",
   "Negative": "0.0012040683068335056",
   "Neutral": "0.8775043487548828",
   "Mixed": "2.6148052711505443e-05"
 },
 {
   "tweet_id": "1589954323328663552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954320291999750",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954318219608064",
   "Positive": "0.020626820623874664",
   "Negative": "0.23080973327159882",
   "Neutral": "0.7474997639656067",
   "Mixed": "0.0010637002997100353"
 },
 {
   "tweet_id": "1589954317293088769",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954299018506244",
   "Positive": "0.011129715479910374",
   "Negative": "7.416371227009222e-05",
   "Neutral": "0.9884041547775269",
   "Mixed": "0.00039192609256133437"
 },
 {
   "tweet_id": "1589954296883609603",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954295763697666",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954291540033536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954242449920001",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589954232211628033",
   "Positive": "0.006519363261759281",
   "Negative": "0.042946141213178635",
   "Neutral": "0.9505242109298706",
   "Mixed": "1.0316950465494301e-05"
 },
 {
   "tweet_id": "1589954228910723072",
   "Positive": "0.021071864292025566",
   "Negative": "0.0016375689301639795",
   "Neutral": "0.9772852659225464",
   "Mixed": "5.316572696756339e-06"
 },
 {
   "tweet_id": "1589954225836285952",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1589954219016351746",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589954218735337472",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589954205145796608",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954203254140928",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589954190562189318",
   "Positive": "0.024047985672950745",
   "Negative": "0.0006542916526086628",
   "Neutral": "0.9752930998802185",
   "Mixed": "4.660065314965323e-06"
 },
 {
   "tweet_id": "1589954175236202496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954174556712961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954167157952512",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1589954165136318464",
   "Positive": "0.0009064404875971377",
   "Negative": "0.0010971665615215898",
   "Neutral": "0.9979612827301025",
   "Mixed": "3.514054697006941e-05"
 },
 {
   "tweet_id": "1589954161327869952",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589954155480686593",
   "Positive": "0.02869868464767933",
   "Negative": "8.773498848313466e-05",
   "Neutral": "0.9711934924125671",
   "Mixed": "2.0154620870016515e-05"
 },
 {
   "tweet_id": "1589954127207223296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954104088227840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954099839062017",
   "Positive": "0.10929631441831589",
   "Negative": "0.002888825023546815",
   "Neutral": "0.8876861929893494",
   "Mixed": "0.00012869256897829473"
 },
 {
   "tweet_id": "1589954097234735104",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589954092591616000",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954078200954880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954069019635712",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954068122075136",
   "Positive": "0.11477816104888916",
   "Negative": "0.00012088711810065433",
   "Neutral": "0.8850662112236023",
   "Mixed": "3.4760316339088604e-05"
 },
 {
   "tweet_id": "1589954067287375876",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589954062694641664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954062325514241",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589954053366484996",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589954012270702594",
   "Positive": "0.00360693596303463",
   "Negative": "0.27621105313301086",
   "Neutral": "0.7200798988342285",
   "Mixed": "0.00010219241812592372"
 },
 {
   "tweet_id": "1589954007019433985",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953997175402496",
   "Positive": "0.02869868464767933",
   "Negative": "8.773498848313466e-05",
   "Neutral": "0.9711934924125671",
   "Mixed": "2.0154620870016515e-05"
 },
 {
   "tweet_id": "1589953978712092675",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589953977978073088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953970776465408",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953965591912448",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1589953960689176577",
   "Positive": "0.005559242330491543",
   "Negative": "0.00022483454085886478",
   "Neutral": "0.9942106008529663",
   "Mixed": "5.370402959670173e-06"
 },
 {
   "tweet_id": "1589953959019827200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953952497692673",
   "Positive": "0.016911078244447708",
   "Negative": "0.0003249752044212073",
   "Neutral": "0.9827548861503601",
   "Mixed": "9.021735422720667e-06"
 },
 {
   "tweet_id": "1589953952032096256",
   "Positive": "0.0023834756575524807",
   "Negative": "0.037385180592536926",
   "Neutral": "0.9602105617523193",
   "Mixed": "2.0671104721259326e-05"
 },
 {
   "tweet_id": "1589953937448534016",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589953917567528961",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589953902145081347",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953898160484352",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589953896684089346",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589953896117858304",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953893643223042",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589953889063047175",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589953887901028353",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589953886433202176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953873716088833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953873032409088",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589953871828303872",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589953866065670145",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953864547340291",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953853604081664",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589953849954996225",
   "Positive": "0.09987984597682953",
   "Negative": "0.006145029794424772",
   "Neutral": "0.8910203576087952",
   "Mixed": "0.0029548227321356535"
 },
 {
   "tweet_id": "1589953847929495552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953833945690112",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953827020877826",
   "Positive": "0.2037329375743866",
   "Negative": "0.00020600171410478652",
   "Neutral": "0.7960326671600342",
   "Mixed": "2.8402613679645583e-05"
 },
 {
   "tweet_id": "1589953797019037699",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953784670978048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953760595697666",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953745101946880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953738667888641",
   "Positive": "0.962800920009613",
   "Negative": "0.0001680775749264285",
   "Neutral": "0.03699975088238716",
   "Mixed": "3.1268449674826115e-05"
 },
 {
   "tweet_id": "1589953727427145728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953705251852289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953704634900480",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589953699006550018",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953692392128513",
   "Positive": "0.19634230434894562",
   "Negative": "0.0029691278468817472",
   "Neutral": "0.8006343245506287",
   "Mixed": "5.4183590691536665e-05"
 },
 {
   "tweet_id": "1589953688961183744",
   "Positive": "0.2037329375743866",
   "Negative": "0.00020600171410478652",
   "Neutral": "0.7960326671600342",
   "Mixed": "2.8402613679645583e-05"
 },
 {
   "tweet_id": "1589953687652536320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953649849294848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953643029331968",
   "Positive": "0.0036510587669909",
   "Negative": "0.3087048828601837",
   "Neutral": "0.6849209666252136",
   "Mixed": "0.002723094541579485"
 },
 {
   "tweet_id": "1589953606215782405",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953600654303232",
   "Positive": "0.03298706188797951",
   "Negative": "0.01100494246929884",
   "Neutral": "0.955710768699646",
   "Mixed": "0.0002972373040392995"
 },
 {
   "tweet_id": "1589953576780333057",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589953571541618688",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589953562020577280",
   "Positive": "0.12126541137695312",
   "Negative": "0.0012040683068335056",
   "Neutral": "0.8775043487548828",
   "Mixed": "2.6148052711505443e-05"
 },
 {
   "tweet_id": "1589953561194278912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953556001366016",
   "Positive": "0.002165835117921233",
   "Negative": "0.000466247001895681",
   "Neutral": "0.9973655343055725",
   "Mixed": "2.3289414912142092e-06"
 },
 {
   "tweet_id": "1589953534992482305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953513668608001",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953510590013440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953497189187587",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589953491166187520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953484568539136",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589953483696144385",
   "Positive": "0.06522472202777863",
   "Negative": "0.00094770104624331",
   "Neutral": "0.9317177534103394",
   "Mixed": "0.002109914319589734"
 },
 {
   "tweet_id": "1589953481162788864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953477610188800",
   "Positive": "0.150732159614563",
   "Negative": "0.00015140394680202007",
   "Neutral": "0.8490886688232422",
   "Mixed": "2.775304892566055e-05"
 },
 {
   "tweet_id": "1589953470370832386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953466092654592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953429874823169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953426313859073",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589953422740131845",
   "Positive": "0.00016603979747742414",
   "Negative": "0.0003087225486524403",
   "Neutral": "0.9995025396347046",
   "Mixed": "2.2631826141150668e-05"
 },
 {
   "tweet_id": "1589953413588332546",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953386358910977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953383481618438",
   "Positive": "0.21297325193881989",
   "Negative": "0.015996797010302544",
   "Neutral": "0.7708055973052979",
   "Mixed": "0.000224336123210378"
 },
 {
   "tweet_id": "1589953379949678592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953376321622016",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589953373989900289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953368205987841",
   "Positive": "0.662065863609314",
   "Negative": "0.008363942615687847",
   "Neutral": "0.3290405869483948",
   "Mixed": "0.0005296397139318287"
 },
 {
   "tweet_id": "1589953365198635010",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953357007187969",
   "Positive": "0.07653924822807312",
   "Negative": "0.005725597031414509",
   "Neutral": "0.9170793294906616",
   "Mixed": "0.0006558602908626199"
 },
 {
   "tweet_id": "1589953353081303044",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953352548614144",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953347402207233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953342109016064",
   "Positive": "0.027070632204413414",
   "Negative": "0.08402857184410095",
   "Neutral": "0.87662672996521",
   "Mixed": "0.012274052016437054"
 },
 {
   "tweet_id": "1589953315441606657",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589953315169005568",
   "Positive": "0.0006925351917743683",
   "Negative": "0.00011546716996235773",
   "Neutral": "0.999186098575592",
   "Mixed": "5.84657936997246e-06"
 },
 {
   "tweet_id": "1589953306969116672",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589953304326705153",
   "Positive": "0.0036961492151021957",
   "Negative": "8.407588029513136e-05",
   "Neutral": "0.9961100220680237",
   "Mixed": "0.00010977269994327798"
 },
 {
   "tweet_id": "1589953300165951489",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589953293090160641",
   "Positive": "0.8670159578323364",
   "Negative": "0.0004152323817834258",
   "Neutral": "0.13253198564052582",
   "Mixed": "3.6902645661029965e-05"
 },
 {
   "tweet_id": "1589953287855693826",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953284999028736",
   "Positive": "0.12374121695756912",
   "Negative": "0.005444316193461418",
   "Neutral": "0.8707899451255798",
   "Mixed": "2.4459324777126312e-05"
 },
 {
   "tweet_id": "1589953278858899456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953277105696772",
   "Positive": "0.39191192388534546",
   "Negative": "0.04135982692241669",
   "Neutral": "0.5664921402931213",
   "Mixed": "0.00023618226987309754"
 },
 {
   "tweet_id": "1589953272882016257",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589953252489310208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953246063628289",
   "Positive": "0.06955856084823608",
   "Negative": "0.04091084748506546",
   "Neutral": "0.8873834609985352",
   "Mixed": "0.0021470910869538784"
 },
 {
   "tweet_id": "1589953239147220993",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589953239084331010",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589953236999745536",
   "Positive": "0.22897052764892578",
   "Negative": "0.005053626839071512",
   "Neutral": "0.7656244039535522",
   "Mixed": "0.00035146245500072837"
 },
 {
   "tweet_id": "1589953234558660609",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589953227038265344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953222768484352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953217169088513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953215436816384",
   "Positive": "0.001659694593399763",
   "Negative": "0.000521139067132026",
   "Neutral": "0.997799813747406",
   "Mixed": "1.939446883625351e-05"
 },
 {
   "tweet_id": "1589953213796876289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953203864764418",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589953195409014785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953195065081857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953167806300160",
   "Positive": "0.0007335412665270269",
   "Negative": "0.7127605080604553",
   "Neutral": "0.2862964868545532",
   "Mixed": "0.0002094675728585571"
 },
 {
   "tweet_id": "1589953166455382016",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589953162630533120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953150005678080",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589953136848162816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953101016223744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953099703418880",
   "Positive": "0.00016201530524995178",
   "Negative": "0.0069552878849208355",
   "Neutral": "0.9928725957870483",
   "Mixed": "1.0062017281597946e-05"
 },
 {
   "tweet_id": "1589953095278395395",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589953088944623617",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589953088781422594",
   "Positive": "0.014772113412618637",
   "Negative": "0.0022065879311412573",
   "Neutral": "0.9829573035240173",
   "Mixed": "6.401020073099062e-05"
 },
 {
   "tweet_id": "1589953072020992000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953068468432897",
   "Positive": "0.09188301861286163",
   "Negative": "0.09591776132583618",
   "Neutral": "0.8120437860488892",
   "Mixed": "0.00015545175119768828"
 },
 {
   "tweet_id": "1589953066677129217",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589953059903635458",
   "Positive": "0.011284681968390942",
   "Negative": "0.005083788186311722",
   "Neutral": "0.9836275577545166",
   "Mixed": "3.944202489947202e-06"
 },
 {
   "tweet_id": "1589953059190624256",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589953058691518465",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953036474277894",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589953002861133825",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589953002236149761",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952993566543875",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952991150624771",
   "Positive": "0.44850367307662964",
   "Negative": "0.04107491672039032",
   "Neutral": "0.5092495083808899",
   "Mixed": "0.00117190508171916"
 },
 {
   "tweet_id": "1589952980022792192",
   "Positive": "0.2882109582424164",
   "Negative": "0.011091657914221287",
   "Neutral": "0.7006394267082214",
   "Mixed": "5.795832475996576e-05"
 },
 {
   "tweet_id": "1589952977330384896",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952974163677184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952974000123904",
   "Positive": "0.29236742854118347",
   "Negative": "0.0010357856517657638",
   "Neutral": "0.706320583820343",
   "Mixed": "0.0002761403447948396"
 },
 {
   "tweet_id": "1589952967624384513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952963942182913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952961555632130",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952959945007104",
   "Positive": "0.0014768028631806374",
   "Negative": "7.926209218567237e-05",
   "Neutral": "0.9984366297721863",
   "Mixed": "7.221263786050258e-06"
 },
 {
   "tweet_id": "1589952953796169730",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952943754989573",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952940822839298",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952916307464193",
   "Positive": "0.002851632423698902",
   "Negative": "0.00020183280867058784",
   "Neutral": "0.9968997240066528",
   "Mixed": "4.67925492557697e-05"
 },
 {
   "tweet_id": "1589952913811836929",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952905033154560",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589952900977299456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952900192960512",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952897953169408",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1589952890046910464",
   "Positive": "0.2037329375743866",
   "Negative": "0.00020600171410478652",
   "Neutral": "0.7960326671600342",
   "Mixed": "2.8402613679645583e-05"
 },
 {
   "tweet_id": "1589952875605954560",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589952875031298054",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952873408126977",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952868135882752",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952853770407943",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952832723374080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952829925777411",
   "Positive": "0.009196453727781773",
   "Negative": "7.725899922661483e-05",
   "Neutral": "0.9906931519508362",
   "Mixed": "3.319463212392293e-05"
 },
 {
   "tweet_id": "1589952817409978368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952801807159299",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589952780298747904",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589952756273786880",
   "Positive": "0.0012382735731080174",
   "Negative": "0.00010563783871475607",
   "Neutral": "0.9986513257026672",
   "Mixed": "4.7099197217903566e-06"
 },
 {
   "tweet_id": "1589952745024663553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952742264819712",
   "Positive": "0.2037329375743866",
   "Negative": "0.00020600171410478652",
   "Neutral": "0.7960326671600342",
   "Mixed": "2.8402613679645583e-05"
 },
 {
   "tweet_id": "1589952702561546241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952699810082822",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952692209979392",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589952683414519809",
   "Positive": "0.02774553932249546",
   "Negative": "0.00023235633852891624",
   "Neutral": "0.9720126390457153",
   "Mixed": "9.542312000121456e-06"
 },
 {
   "tweet_id": "1589952670630035456",
   "Positive": "0.6576926708221436",
   "Negative": "0.004128767643123865",
   "Neutral": "0.336586058139801",
   "Mixed": "0.0015925696352496743"
 },
 {
   "tweet_id": "1589952663369969664",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952648748597250",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589952641383428096",
   "Positive": "0.0010760066797956824",
   "Negative": "9.656378824729472e-05",
   "Neutral": "0.9988136291503906",
   "Mixed": "1.3754913197772112e-05"
 },
 {
   "tweet_id": "1589952636182069248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952629169618945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952626992766977",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589952621217210369",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952620751618048",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589952618428002306",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952616209211395",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952603261403136",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952601067786240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952600782573573",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952593081798656",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589952589466308613",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589952589407588352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952581799120901",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952576099057665",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1589952572601008128",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952554804269056",
   "Positive": "0.21137036383152008",
   "Negative": "0.0002176530979340896",
   "Neutral": "0.7883850932121277",
   "Mixed": "2.6850269932765514e-05"
 },
 {
   "tweet_id": "1589952543136051201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952540116152320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952532126007297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952530699931649",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589952522382610434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952522139373568",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952514228883456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952506347782146",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952502153502721",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589952500228304898",
   "Positive": "0.8870518207550049",
   "Negative": "0.002509922720491886",
   "Neutral": "0.11039704084396362",
   "Mixed": "4.111160524189472e-05"
 },
 {
   "tweet_id": "1589952488421339137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952470146744320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952464216018946",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952461846245377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952459727699969",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952434608435200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952434381950981",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952427528458241",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952425758445569",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589952422692409349",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952395597217794",
   "Positive": "0.005703248083591461",
   "Negative": "0.24744334816932678",
   "Neutral": "0.5776506066322327",
   "Mixed": "0.1692027747631073"
 },
 {
   "tweet_id": "1589952394024341505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952390845059072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952380573224962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952378102767628",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952367667007488",
   "Positive": "0.008935618214309216",
   "Negative": "0.005582522135227919",
   "Neutral": "0.007923119701445103",
   "Mixed": "0.9775586724281311"
 },
 {
   "tweet_id": "1589952366996250624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952357819092992",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952355331870722",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589952337300578305",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589952328660291585",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952299228893186",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952298910121984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952263258517504",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952261207523329",
   "Positive": "0.0017910109600052238",
   "Negative": "0.00027540503651835024",
   "Neutral": "0.9978983402252197",
   "Mixed": "3.52490897057578e-05"
 },
 {
   "tweet_id": "1589952257789145088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952248104517635",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952241309712387",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952238398865410",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952230672605184",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589952226336075776",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952216886292480",
   "Positive": "0.3205885887145996",
   "Negative": "0.00020894152112305164",
   "Neutral": "0.6791378259658813",
   "Mixed": "6.459103315137327e-05"
 },
 {
   "tweet_id": "1589952214935928833",
   "Positive": "0.000778698711656034",
   "Negative": "9.675002365838736e-05",
   "Neutral": "0.9991140961647034",
   "Mixed": "1.0495673450350296e-05"
 },
 {
   "tweet_id": "1589952197684760579",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589952193347846144",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589952191313612800",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952186880258049",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1589952180521668609",
   "Positive": "0.00958477333188057",
   "Negative": "0.2291906774044037",
   "Neutral": "0.7611775994300842",
   "Mixed": "4.693469236372039e-05"
 },
 {
   "tweet_id": "1589952179183288320",
   "Positive": "0.12029978632926941",
   "Negative": "0.05579960718750954",
   "Neutral": "0.8225381970405579",
   "Mixed": "0.0013624017592519522"
 },
 {
   "tweet_id": "1589952172208578561",
   "Positive": "0.8830761313438416",
   "Negative": "0.00011093359353253618",
   "Neutral": "0.11666850000619888",
   "Mixed": "0.00014440745871979743"
 },
 {
   "tweet_id": "1589952154642829312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952143892484096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952135424114688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952125534339073",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589952118492139523",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589952112540385283",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589952111881900038",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589952108505497601",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952108316397568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952107993759744",
   "Positive": "0.0052163065411150455",
   "Negative": "0.00039872442721389234",
   "Neutral": "0.9943663477897644",
   "Mixed": "1.8647506294655614e-05"
 },
 {
   "tweet_id": "1589952103417794560",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589952101207375872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952090452787200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952077320843264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952065736146947",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952064259756034",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952049638432768",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589952017157398528",
   "Positive": "0.6172160506248474",
   "Negative": "0.00204926123842597",
   "Neutral": "0.3806644380092621",
   "Mixed": "7.020268822088838e-05"
 },
 {
   "tweet_id": "1589952006990725120",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589952005749215233",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589952004428029952",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589952003853389825",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589952003815354368",
   "Positive": "0.1281365603208542",
   "Negative": "0.0001167428563348949",
   "Neutral": "0.8716994524002075",
   "Mixed": "4.7203477151924744e-05"
 },
 {
   "tweet_id": "1589951998908334080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951995515129857",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951989151977472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951963499724800",
   "Positive": "0.000340359314577654",
   "Negative": "8.064664143603295e-05",
   "Neutral": "0.9995335340499878",
   "Mixed": "4.5545020839199424e-05"
 },
 {
   "tweet_id": "1589951961327357952",
   "Positive": "0.2534968852996826",
   "Negative": "0.015142472460865974",
   "Neutral": "0.7313179969787598",
   "Mixed": "4.260247078491375e-05"
 },
 {
   "tweet_id": "1589951948937400321",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589951946353692673",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951944919257088",
   "Positive": "0.00016250807675532997",
   "Negative": "0.0014322985662147403",
   "Neutral": "0.9984001517295837",
   "Mixed": "5.008122116123559e-06"
 },
 {
   "tweet_id": "1589951944646443010",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951943497388033",
   "Positive": "0.33484041690826416",
   "Negative": "0.3214414715766907",
   "Neutral": "0.33862483501434326",
   "Mixed": "0.005093296058475971"
 },
 {
   "tweet_id": "1589951941647699969",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951932562804736",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951925235363840",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951921380806656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951913008959494",
   "Positive": "0.002055400749668479",
   "Negative": "0.0045958757400512695",
   "Neutral": "0.9933295249938965",
   "Mixed": "1.9167689970345236e-05"
 },
 {
   "tweet_id": "1589951910735679490",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589951909926146052",
   "Positive": "0.6591901183128357",
   "Negative": "0.004327644594013691",
   "Neutral": "0.33601585030555725",
   "Mixed": "0.00046645314432680607"
 },
 {
   "tweet_id": "1589951903651483650",
   "Positive": "0.9537980556488037",
   "Negative": "9.729680459713563e-05",
   "Neutral": "0.04607956111431122",
   "Mixed": "2.507767203496769e-05"
 },
 {
   "tweet_id": "1589951902128967680",
   "Positive": "0.028481602668762207",
   "Negative": "0.0010879513574764132",
   "Neutral": "0.9704113006591797",
   "Mixed": "1.903453994600568e-05"
 },
 {
   "tweet_id": "1589951891160858624",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951876669538304",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589951876145254400",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1589951864501854208",
   "Positive": "0.0026830837596207857",
   "Negative": "0.0002032111951848492",
   "Neutral": "0.9971057772636414",
   "Mixed": "7.93585968494881e-06"
 },
 {
   "tweet_id": "1589951862828306434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951861674905600",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951849637253122",
   "Positive": "0.0005313207511790097",
   "Negative": "0.08233863115310669",
   "Neutral": "0.9170429110527039",
   "Mixed": "8.711781265446916e-05"
 },
 {
   "tweet_id": "1589951843869655042",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1589951841881968642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951841651298304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951833539170304",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589951831207481345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951830586720257",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951828384706560",
   "Positive": "0.019736425951123238",
   "Negative": "0.0009678052738308907",
   "Neutral": "0.9792901277542114",
   "Mixed": "5.620358479063725e-06"
 },
 {
   "tweet_id": "1589951826232631296",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951814216351744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951806414917634",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951797619470338",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951794930937856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951769504665601",
   "Positive": "0.5503659844398499",
   "Negative": "0.004061078652739525",
   "Neutral": "0.4454198181629181",
   "Mixed": "0.00015319744125008583"
 },
 {
   "tweet_id": "1589951766128660481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951757463212033",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951742103683072",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589951731726970880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951730342821888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951728874815488",
   "Positive": "0.003422686830163002",
   "Negative": "5.528534165932797e-05",
   "Neutral": "0.9964972138404846",
   "Mixed": "2.4857392418198287e-05"
 },
 {
   "tweet_id": "1589951715134279680",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951712886165505",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589951707693613056",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951692690558976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951689620336642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951689481916416",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589951686969548802",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951679226867714",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951653461254145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951648474202112",
   "Positive": "0.002702027792111039",
   "Negative": "0.0004809765669051558",
   "Neutral": "0.9968149065971375",
   "Mixed": "2.183821152357268e-06"
 },
 {
   "tweet_id": "1589951647266242566",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589951646280613888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951624398909440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951620481441792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951617310560256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951610964545538",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951604320800771",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951601212432384",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589951596884295681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951584045498369",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951581591511040",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951581109522433",
   "Positive": "0.5503659844398499",
   "Negative": "0.004061078652739525",
   "Neutral": "0.4454198181629181",
   "Mixed": "0.00015319744125008583"
 },
 {
   "tweet_id": "1589951558992932869",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589951554282721283",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951553674543105",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589951542949720064",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589951535672627200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951525878894592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951519763623938",
   "Positive": "0.9855958223342896",
   "Negative": "8.388231799472123e-05",
   "Neutral": "0.014302725903689861",
   "Mixed": "1.7554859368829057e-05"
 },
 {
   "tweet_id": "1589951491863113730",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951472649015296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951455137759234",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951454215016450",
   "Positive": "0.009461387991905212",
   "Negative": "0.21827548742294312",
   "Neutral": "0.7722086906433105",
   "Mixed": "5.4498290410265326e-05"
 },
 {
   "tweet_id": "1589951449811025922",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951442332581889",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951432958312448",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951428453634049",
   "Positive": "0.0014072443591430783",
   "Negative": "0.00022592709865421057",
   "Neutral": "0.9983550906181335",
   "Mixed": "1.1719416761479806e-05"
 },
 {
   "tweet_id": "1589951408409030656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951392286150656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951388276076544",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589951356705853440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951340289339395",
   "Positive": "0.03945690020918846",
   "Negative": "0.0001333086984232068",
   "Neutral": "0.9603624939918518",
   "Mixed": "4.726354745798744e-05"
 },
 {
   "tweet_id": "1589951322035752961",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951319347204098",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951304608382977",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589951295129096194",
   "Positive": "0.001221124199219048",
   "Negative": "0.00015630769485142082",
   "Neutral": "0.9986191987991333",
   "Mixed": "3.3720691590133356e-06"
 },
 {
   "tweet_id": "1589951273260154880",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589951266503131137",
   "Positive": "0.9988939166069031",
   "Negative": "2.6904868718702346e-05",
   "Neutral": "0.0010571179445832968",
   "Mixed": "2.210606908192858e-05"
 },
 {
   "tweet_id": "1589951264204341248",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589951261188968448",
   "Positive": "0.35755184292793274",
   "Negative": "0.03174424171447754",
   "Neutral": "0.5565134882926941",
   "Mixed": "0.054190438240766525"
 },
 {
   "tweet_id": "1589951257799962624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951242285244418",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951239210823680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951237495001088",
   "Positive": "0.0059144143015146255",
   "Negative": "0.0015340362442657351",
   "Neutral": "0.9925450682640076",
   "Mixed": "6.563127953995718e-06"
 },
 {
   "tweet_id": "1589951220424531969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951211159310337",
   "Positive": "0.13262569904327393",
   "Negative": "0.5510267615318298",
   "Neutral": "0.3158915042877197",
   "Mixed": "0.00045596170821227133"
 },
 {
   "tweet_id": "1589951203139784707",
   "Positive": "0.0003638961643446237",
   "Negative": "0.00015422092110384256",
   "Neutral": "0.9994518160820007",
   "Mixed": "3.0053315640543588e-05"
 },
 {
   "tweet_id": "1589951175386095618",
   "Positive": "0.1935875564813614",
   "Negative": "0.017508817836642265",
   "Neutral": "0.788261353969574",
   "Mixed": "0.0006422961596399546"
 },
 {
   "tweet_id": "1589951160504709122",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589951158625239040",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1589951147972132866",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951144985788416",
   "Positive": "0.2544512152671814",
   "Negative": "0.00022434949642047286",
   "Neutral": "0.7452548146247864",
   "Mixed": "6.963610940147191e-05"
 },
 {
   "tweet_id": "1589951143807188999",
   "Positive": "0.0838102474808693",
   "Negative": "0.2582918405532837",
   "Neutral": "0.6575784683227539",
   "Mixed": "0.00031939937616698444"
 },
 {
   "tweet_id": "1589951128946761729",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589951111892717580",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951105035010049",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951090896048129",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589951088043888641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951088022933505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589951085690904577",
   "Positive": "0.015003375709056854",
   "Negative": "0.41548171639442444",
   "Neutral": "0.5692512392997742",
   "Mixed": "0.00026368023827672005"
 },
 {
   "tweet_id": "1589951074949279744",
   "Positive": "0.011096528731286526",
   "Negative": "0.00022464696667157114",
   "Neutral": "0.9886621832847595",
   "Mixed": "1.662122849666048e-05"
 },
 {
   "tweet_id": "1589951064757121027",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951046688079873",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589951041420005378",
   "Positive": "0.9121055006980896",
   "Negative": "0.00010188875603489578",
   "Neutral": "0.08773694932460785",
   "Mixed": "5.5684147810097784e-05"
 },
 {
   "tweet_id": "1589951034323259392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951032175755264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951030355431424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589951025058033665",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589951007425204224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950999086923776",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950992308932609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950986671788033",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950970271711233",
   "Positive": "0.8157099485397339",
   "Negative": "0.00023207336198538542",
   "Neutral": "0.18395744264125824",
   "Mixed": "0.00010047784599009901"
 },
 {
   "tweet_id": "1589950959584940033",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950955277410305",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950952668532737",
   "Positive": "0.0006704591796733439",
   "Negative": "4.777050708071329e-05",
   "Neutral": "0.9992700219154358",
   "Mixed": "1.1812149750767276e-05"
 },
 {
   "tweet_id": "1589950939209011200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950926303137795",
   "Positive": "0.23547610640525818",
   "Negative": "0.0002831059682648629",
   "Neutral": "0.7642094492912292",
   "Mixed": "3.131431367364712e-05"
 },
 {
   "tweet_id": "1589950911727575041",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950910020870145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950878492262400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950877179457537",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589950877057441792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950876059328513",
   "Positive": "0.037213508039712906",
   "Negative": "0.00020115262304898351",
   "Neutral": "0.9623836278915405",
   "Mixed": "0.00020169625349808484"
 },
 {
   "tweet_id": "1589950874109235201",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950873337155584",
   "Positive": "0.16931818425655365",
   "Negative": "0.00128293433226645",
   "Neutral": "0.8293803334236145",
   "Mixed": "1.8540615201345645e-05"
 },
 {
   "tweet_id": "1589950872314052608",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950871755857921",
   "Positive": "0.3645491600036621",
   "Negative": "0.006382209714502096",
   "Neutral": "0.6290259957313538",
   "Mixed": "4.266158430255018e-05"
 },
 {
   "tweet_id": "1589950865435398150",
   "Positive": "0.0009173534926958382",
   "Negative": "0.00020539562683552504",
   "Neutral": "0.9988747239112854",
   "Mixed": "2.5335873488074867e-06"
 },
 {
   "tweet_id": "1589950854559563776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950848473661441",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1589950847282073600",
   "Positive": "0.00944232102483511",
   "Negative": "0.00036084497696720064",
   "Neutral": "0.9901935458183289",
   "Mixed": "3.3414630706829485e-06"
 },
 {
   "tweet_id": "1589950842274447366",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950838663176192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950816278183936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950800263987200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950792030879745",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950785856888832",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950784732819463",
   "Positive": "0.21746708452701569",
   "Negative": "0.0013213115744292736",
   "Neutral": "0.7811449766159058",
   "Mixed": "6.662842497462407e-05"
 },
 {
   "tweet_id": "1589950775266275329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950748913127424",
   "Positive": "0.13587266206741333",
   "Negative": "0.0016943819355219603",
   "Neutral": "0.8620107769966125",
   "Mixed": "0.0004221013805363327"
 },
 {
   "tweet_id": "1589950742080917505",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950741296603136",
   "Positive": "0.08984716981649399",
   "Negative": "0.00021785346325486898",
   "Neutral": "0.9098961353302002",
   "Mixed": "3.8822116039227694e-05"
 },
 {
   "tweet_id": "1589950740906545157",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589950739304308736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950735776907265",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950714503376896",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950704382541824",
   "Positive": "0.0036992006935179234",
   "Negative": "0.000658443896099925",
   "Neutral": "0.9956387877464294",
   "Mixed": "3.530027242959477e-06"
 },
 {
   "tweet_id": "1589950699760410624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950690268676099",
   "Positive": "0.7600420117378235",
   "Negative": "0.010159244760870934",
   "Neutral": "0.22954076528549194",
   "Mixed": "0.00025791997904889286"
 },
 {
   "tweet_id": "1589950686951002113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950673604718592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950670282850304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950645091831812",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950642730459137",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950635201687552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950632466993156",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589950626913742848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950621255602176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950610056818688",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950604931399682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950601152311297",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950597553610753",
   "Positive": "0.10490730404853821",
   "Negative": "0.003033190732821822",
   "Neutral": "0.8920440077781677",
   "Mixed": "1.5495943443966098e-05"
 },
 {
   "tweet_id": "1589950594902798337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950592113610752",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589950569522679809",
   "Positive": "0.0027120732702314854",
   "Negative": "0.00022149686992634088",
   "Neutral": "0.9970640540122986",
   "Mixed": "2.4266980744869215e-06"
 },
 {
   "tweet_id": "1589950561088339973",
   "Positive": "0.021752703934907913",
   "Negative": "0.6647875905036926",
   "Neutral": "0.3064969778060913",
   "Mixed": "0.006962769199162722"
 },
 {
   "tweet_id": "1589950555199528963",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950551827283969",
   "Positive": "0.3436771333217621",
   "Negative": "0.0064648897387087345",
   "Neutral": "0.6497897505760193",
   "Mixed": "6.813128129579127e-05"
 },
 {
   "tweet_id": "1589950540834041857",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950532223111168",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950522010001410",
   "Positive": "0.4752034544944763",
   "Negative": "0.0008169263019226491",
   "Neutral": "0.5239152312278748",
   "Mixed": "6.447720807045698e-05"
 },
 {
   "tweet_id": "1589950513818529794",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950498878423040",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950493799108612",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950489080532993",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589950484697481217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950484617433088",
   "Positive": "0.01869511418044567",
   "Negative": "0.0075295125134289265",
   "Neutral": "0.9737690091133118",
   "Mixed": "6.371857125486713e-06"
 },
 {
   "tweet_id": "1589950484475162624",
   "Positive": "0.24333275854587555",
   "Negative": "0.0005739236366935074",
   "Neutral": "0.7558591961860657",
   "Mixed": "0.0002341371582588181"
 },
 {
   "tweet_id": "1589950462396334080",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950458650845185",
   "Positive": "0.7299208045005798",
   "Negative": "0.0013352006208151579",
   "Neutral": "0.26860788464546204",
   "Mixed": "0.00013611261965706944"
 },
 {
   "tweet_id": "1589950458130726913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950450266435585",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950446353145858",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950442590834691",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950441592610816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950435405991937",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950430863581185",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589950427935969282",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950424416940034",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950414660993024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950406549196800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950406398218242",
   "Positive": "0.9426239132881165",
   "Negative": "0.023011859506368637",
   "Neutral": "0.03320010378956795",
   "Mixed": "0.0011642398312687874"
 },
 {
   "tweet_id": "1589950401616707584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950391193858056",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950390904426497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950390430486531",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950371325440000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950364845248513",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950320654036992",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950316711407616",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950309832744960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950303784538113",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589950297707012097",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950294594834438",
   "Positive": "0.24834848940372467",
   "Negative": "0.21254411339759827",
   "Neutral": "0.5389158725738525",
   "Mixed": "0.0001915999746415764"
 },
 {
   "tweet_id": "1589950292640280576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950290484150273",
   "Positive": "0.0010700382990762591",
   "Negative": "0.9166958928108215",
   "Neutral": "0.0820397138595581",
   "Mixed": "0.0001943686220329255"
 },
 {
   "tweet_id": "1589950280023822336",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950278736171008",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950278606131200",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950271274487808",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950266841133056",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950266216181762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950255948517378",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589950250059706371",
   "Positive": "0.08778993785381317",
   "Negative": "0.00017441379895899445",
   "Neutral": "0.9120116233825684",
   "Mixed": "2.405085069767665e-05"
 },
 {
   "tweet_id": "1589950247803183105",
   "Positive": "0.8049787878990173",
   "Negative": "0.00045789973228238523",
   "Neutral": "0.19439680874347687",
   "Mixed": "0.00016650224279146641"
 },
 {
   "tweet_id": "1589950244216705024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950243264942080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950238399561728",
   "Positive": "0.0010774385882541537",
   "Negative": "4.707983316620812e-05",
   "Neutral": "0.998865008354187",
   "Mixed": "1.0425686923554167e-05"
 },
 {
   "tweet_id": "1589950230513872896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950207919546369",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950206061465600",
   "Positive": "0.4754277467727661",
   "Negative": "0.012288304977118969",
   "Neutral": "0.511905312538147",
   "Mixed": "0.00037863836041651666"
 },
 {
   "tweet_id": "1589950192765513729",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950192258019330",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589950188155981825",
   "Positive": "0.00047892166185192764",
   "Negative": "0.00010252156062051654",
   "Neutral": "0.9994058609008789",
   "Mixed": "1.272430472454289e-05"
 },
 {
   "tweet_id": "1589950180165824512",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950178341318656",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589950170938355712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950167939092480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950165234094081",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950157394960384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950150495330304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950143436320768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950131641909248",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589950117960101889",
   "Positive": "0.003861453616991639",
   "Negative": "0.7296587824821472",
   "Neutral": "0.26234275102615356",
   "Mixed": "0.004137038253247738"
 },
 {
   "tweet_id": "1589950117930741762",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950116114608128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950101304537088",
   "Positive": "0.29377996921539307",
   "Negative": "0.0009157704189419746",
   "Neutral": "0.7052551507949829",
   "Mixed": "4.910063216811977e-05"
 },
 {
   "tweet_id": "1589950099035410436",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950090743279618",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950083306442752",
   "Positive": "0.002253254409879446",
   "Negative": "0.3903059959411621",
   "Neutral": "0.6074010133743286",
   "Mixed": "3.968485543737188e-05"
 },
 {
   "tweet_id": "1589950078051299331",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589950075652145152",
   "Positive": "0.1194545179605484",
   "Negative": "0.004098998848348856",
   "Neutral": "0.8759117126464844",
   "Mixed": "0.0005347859696485102"
 },
 {
   "tweet_id": "1589950074205110273",
   "Positive": "0.0019905604422092438",
   "Negative": "0.00019509390403982252",
   "Neutral": "0.9978116154670715",
   "Mixed": "2.755926061581704e-06"
 },
 {
   "tweet_id": "1589950065942355969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950053774680064",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950047747465217",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589950034467962880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589950030676623360",
   "Positive": "0.0030157179571688175",
   "Negative": "0.00015735928900539875",
   "Neutral": "0.9968236684799194",
   "Mixed": "3.1676959224569146e-06"
 },
 {
   "tweet_id": "1589950028604649473",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589950028558512133",
   "Positive": "0.8681623339653015",
   "Negative": "0.0006889556534588337",
   "Neutral": "0.13111533224582672",
   "Mixed": "3.3400261600036174e-05"
 },
 {
   "tweet_id": "1589950027019194369",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589950025777688579",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950016785092608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589950006425169921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949986061811713",
   "Positive": "0.4885912835597992",
   "Negative": "0.0020036171190440655",
   "Neutral": "0.4987347722053528",
   "Mixed": "0.01067033689469099"
 },
 {
   "tweet_id": "1589949975467003906",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949971289473024",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589949966604468224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949964607967232",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949955355332609",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589949948631535621",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949936346333184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949926535856128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949907682881537",
   "Positive": "0.8263427019119263",
   "Negative": "0.0017656446434557438",
   "Neutral": "0.17158234119415283",
   "Mixed": "0.0003093107370659709"
 },
 {
   "tweet_id": "1589949906621722626",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949905145319424",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949899357184000",
   "Positive": "0.9035783410072327",
   "Negative": "0.00024240186030510813",
   "Neutral": "0.09612805396318436",
   "Mixed": "5.120166315464303e-05"
 },
 {
   "tweet_id": "1589949885700534280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949877374812160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949870538096642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949864913534976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949864313761795",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949862057246720",
   "Positive": "0.06987040489912033",
   "Negative": "0.00018446013564243913",
   "Neutral": "0.9299347996711731",
   "Mixed": "1.0300081157765817e-05"
 },
 {
   "tweet_id": "1589949861365178370",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949856952774658",
   "Positive": "0.4081053137779236",
   "Negative": "0.0010707348119467497",
   "Neutral": "0.5907937288284302",
   "Mixed": "3.0249037081375718e-05"
 },
 {
   "tweet_id": "1589949856164229120",
   "Positive": "0.011297594755887985",
   "Negative": "0.00022386395721696317",
   "Neutral": "0.9884555339813232",
   "Mixed": "2.2982967493589967e-05"
 },
 {
   "tweet_id": "1589949847662387202",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949836685873152",
   "Positive": "0.09094826132059097",
   "Negative": "0.0038152532652020454",
   "Neutral": "0.9052071571350098",
   "Mixed": "2.9370063202804886e-05"
 },
 {
   "tweet_id": "1589949832499965953",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949832382533634",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949831682064384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949824962818049",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949816536432642",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949815739543552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949804184227840",
   "Positive": "0.002387461019679904",
   "Negative": "8.621613960713148e-05",
   "Neutral": "0.9975186586380005",
   "Mixed": "7.671173079870641e-06"
 },
 {
   "tweet_id": "1589949784735248384",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949780155043841",
   "Positive": "0.0043107676319777966",
   "Negative": "0.3494541645050049",
   "Neutral": "0.6461803317070007",
   "Mixed": "5.4806463595014066e-05"
 },
 {
   "tweet_id": "1589949778623799297",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589949771636084738",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1589949761989185536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949760085323778",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589949754154582017",
   "Positive": "0.8943974375724792",
   "Negative": "0.000639785488601774",
   "Neutral": "0.10484246909618378",
   "Mixed": "0.00012028891069348902"
 },
 {
   "tweet_id": "1589949727386521600",
   "Positive": "0.7414828538894653",
   "Negative": "0.0005356663605198264",
   "Neutral": "0.2579091489315033",
   "Mixed": "7.240327977342531e-05"
 },
 {
   "tweet_id": "1589949715017527296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949712349949953",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589949710399582211",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1589949685535756289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949676941631489",
   "Positive": "0.6442556381225586",
   "Negative": "0.0037320733536034822",
   "Neutral": "0.34454408288002014",
   "Mixed": "0.007468237541615963"
 },
 {
   "tweet_id": "1589949676488650757",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949670885060610",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949656934780929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949645895372803",
   "Positive": "0.33277857303619385",
   "Negative": "0.001561822253279388",
   "Neutral": "0.6656321883201599",
   "Mixed": "2.7429687179392204e-05"
 },
 {
   "tweet_id": "1589949640501530624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949607010013192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949603209936896",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589949602169778180",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949590467645441",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949587800100870",
   "Positive": "0.00215247948653996",
   "Negative": "0.527758777141571",
   "Neutral": "0.46991515159606934",
   "Mixed": "0.00017352316353935748"
 },
 {
   "tweet_id": "1589949574810304514",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589949571920433153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949566841139202",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949548889251840",
   "Positive": "0.01836620829999447",
   "Negative": "0.0004548006400000304",
   "Neutral": "0.9811733365058899",
   "Mixed": "5.728339147026418e-06"
 },
 {
   "tweet_id": "1589949537342607360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949527188189184",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589949524877115394",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949517776179200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949498268471297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949496930504704",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589949477795729409",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589949470288277504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949468631523330",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589949463883546624",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1589949461769617408",
   "Positive": "0.019554927945137024",
   "Negative": "0.0019481665221974254",
   "Neutral": "0.978477954864502",
   "Mixed": "1.8858521798392758e-05"
 },
 {
   "tweet_id": "1589949425052680195",
   "Positive": "0.005423258990049362",
   "Negative": "0.9326051473617554",
   "Neutral": "0.061629150062799454",
   "Mixed": "0.00034248357405886054"
 },
 {
   "tweet_id": "1589949404756471808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949393675104257",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949385596895232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949382807670784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949380014247937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949367414558720",
   "Positive": "0.5199272632598877",
   "Negative": "0.08919092267751694",
   "Neutral": "0.3887826204299927",
   "Mixed": "0.0020991978235542774"
 },
 {
   "tweet_id": "1589949365837496320",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949356009848832",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589949342827569153",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949341602455552",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1589949332778020864",
   "Positive": "0.612194299697876",
   "Negative": "0.007023602724075317",
   "Neutral": "0.3521840274333954",
   "Mixed": "0.028598159551620483"
 },
 {
   "tweet_id": "1589949332673134592",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589949318945210370",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949311277797382",
   "Positive": "0.02230459824204445",
   "Negative": "0.0003602947690524161",
   "Neutral": "0.977327823638916",
   "Mixed": "7.333125267905416e-06"
 },
 {
   "tweet_id": "1589949290511990784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949287303368706",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949287177547776",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589949277295767552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949275194404865",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949261588103170",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949257867722752",
   "Positive": "0.8263427019119263",
   "Negative": "0.0017656446434557438",
   "Neutral": "0.17158234119415283",
   "Mixed": "0.0003093107370659709"
 },
 {
   "tweet_id": "1589949253799272448",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589949246694117376",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1589949230290173952",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589949228511809539",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949226716319745",
   "Positive": "0.03609731048345566",
   "Negative": "0.000777117908000946",
   "Neutral": "0.9631208181381226",
   "Mixed": "4.762364824273391e-06"
 },
 {
   "tweet_id": "1589949222040002560",
   "Positive": "0.09180375188589096",
   "Negative": "0.0010035973973572254",
   "Neutral": "0.9071768522262573",
   "Mixed": "1.5752975741634145e-05"
 },
 {
   "tweet_id": "1589949218915246080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949216914563075",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949216256045061",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589949216205725697",
   "Positive": "0.5005313158035278",
   "Negative": "0.008966071531176567",
   "Neutral": "0.4895435571670532",
   "Mixed": "0.0009589994442649186"
 },
 {
   "tweet_id": "1589949209570029568",
   "Positive": "0.03609731048345566",
   "Negative": "0.000777117908000946",
   "Neutral": "0.9631208181381226",
   "Mixed": "4.762364824273391e-06"
 },
 {
   "tweet_id": "1589949208425287680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949203685736448",
   "Positive": "0.009461387991905212",
   "Negative": "0.21827548742294312",
   "Neutral": "0.7722086906433105",
   "Mixed": "5.4498290410265326e-05"
 },
 {
   "tweet_id": "1589949193195778049",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589949190830174209",
   "Positive": "0.0035066232085227966",
   "Negative": "0.0001322468597209081",
   "Neutral": "0.9963580965995789",
   "Mixed": "2.9713544336118503e-06"
 },
 {
   "tweet_id": "1589949183620177921",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949180826759170",
   "Positive": "0.0012412386713549495",
   "Negative": "0.04768083989620209",
   "Neutral": "0.9510629177093506",
   "Mixed": "1.501292354078032e-05"
 },
 {
   "tweet_id": "1589949178620219392",
   "Positive": "0.03609731048345566",
   "Negative": "0.000777117908000946",
   "Neutral": "0.9631208181381226",
   "Mixed": "4.762364824273391e-06"
 },
 {
   "tweet_id": "1589949165043580929",
   "Positive": "0.005423258990049362",
   "Negative": "0.9326051473617554",
   "Neutral": "0.061629150062799454",
   "Mixed": "0.00034248357405886054"
 },
 {
   "tweet_id": "1589949131019423746",
   "Positive": "0.005651878193020821",
   "Negative": "0.5025880932807922",
   "Neutral": "0.48671481013298035",
   "Mixed": "0.0050452337600290775"
 },
 {
   "tweet_id": "1589949124363051008",
   "Positive": "0.00893351435661316",
   "Negative": "0.544778048992157",
   "Neutral": "0.4436318874359131",
   "Mixed": "0.0026565201114863157"
 },
 {
   "tweet_id": "1589949121787330560",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589949113399152643",
   "Positive": "0.0017674039117991924",
   "Negative": "0.00025068552349694073",
   "Neutral": "0.9979792237281799",
   "Mixed": "2.773671440081671e-06"
 },
 {
   "tweet_id": "1589949101936095232",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589949101378240513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949100916871169",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949084810772480",
   "Positive": "0.08898843824863434",
   "Negative": "0.023981917649507523",
   "Neutral": "0.887001097202301",
   "Mixed": "2.8569204005179927e-05"
 },
 {
   "tweet_id": "1589949077307129856",
   "Positive": "0.0032776198349893093",
   "Negative": "0.9387543797492981",
   "Neutral": "0.057710982859134674",
   "Mixed": "0.000257092498941347"
 },
 {
   "tweet_id": "1589949067001749507",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949017450254337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589949016334159872",
   "Positive": "0.09645162522792816",
   "Negative": "0.7268868088722229",
   "Neutral": "0.16817888617515564",
   "Mixed": "0.008482757955789566"
 },
 {
   "tweet_id": "1589949012689682433",
   "Positive": "0.0018306716810911894",
   "Negative": "0.0004070565046276897",
   "Neutral": "0.9977595806121826",
   "Mixed": "2.622398369567236e-06"
 },
 {
   "tweet_id": "1589949002514325505",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589949001872576513",
   "Positive": "0.03696759417653084",
   "Negative": "0.00025461750919930637",
   "Neutral": "0.9625725746154785",
   "Mixed": "0.00020528241293504834"
 },
 {
   "tweet_id": "1589949001155383303",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589948955944972289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948912500355072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948907265851394",
   "Positive": "0.0002220566093456",
   "Negative": "0.0025009331293404102",
   "Neutral": "0.9972708821296692",
   "Mixed": "6.03621629124973e-06"
 },
 {
   "tweet_id": "1589948892262854656",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948868934144000",
   "Positive": "0.005423258990049362",
   "Negative": "0.9326051473617554",
   "Neutral": "0.061629150062799454",
   "Mixed": "0.00034248357405886054"
 },
 {
   "tweet_id": "1589948860058996739",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948858330939392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948853448761347",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948852710559745",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589948851901050880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948829948051456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948829822242817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948798154964997",
   "Positive": "0.6312297582626343",
   "Negative": "0.002327172551304102",
   "Neutral": "0.36410072445869446",
   "Mixed": "0.002342281863093376"
 },
 {
   "tweet_id": "1589948794195816451",
   "Positive": "0.5388897061347961",
   "Negative": "0.05707014724612236",
   "Neutral": "0.40174752473831177",
   "Mixed": "0.00229261489585042"
 },
 {
   "tweet_id": "1589948791754731520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948788453814274",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948769919197185",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948766949617664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948766261743617",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948758250643458",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948754970693633",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589948746380750849",
   "Positive": "0.08562758564949036",
   "Negative": "0.07244743406772614",
   "Neutral": "0.8171030879020691",
   "Mixed": "0.024821925908327103"
 },
 {
   "tweet_id": "1589948734536028162",
   "Positive": "0.7216961979866028",
   "Negative": "0.00027487409533932805",
   "Neutral": "0.2778659164905548",
   "Mixed": "0.00016309462080243975"
 },
 {
   "tweet_id": "1589948733302898688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948729729363969",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1589948721038757889",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589948717444263937",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948702042771456",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948691485708290",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948688826523648",
   "Positive": "0.021071864292025566",
   "Negative": "0.0016375689301639795",
   "Neutral": "0.9772852659225464",
   "Mixed": "5.316572696756339e-06"
 },
 {
   "tweet_id": "1589948681591345153",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589948666085007362",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948663186743296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948649601372160",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589948648372465664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948646040403976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948641502195715",
   "Positive": "0.0028418321162462234",
   "Negative": "0.4140241742134094",
   "Neutral": "0.5816166400909424",
   "Mixed": "0.001517393859103322"
 },
 {
   "tweet_id": "1589948640814329858",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948625215688707",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948620107051008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948619188187136",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589948611903000577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948594236272640",
   "Positive": "0.23547610640525818",
   "Negative": "0.0002831059682648629",
   "Neutral": "0.7642094492912292",
   "Mixed": "3.131431367364712e-05"
 },
 {
   "tweet_id": "1589948585558552583",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948585072033793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948567904747520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948564113084417",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589948559885234176",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948545612009472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948544018182145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948540809531393",
   "Positive": "0.0032904797699302435",
   "Negative": "4.004417496616952e-05",
   "Neutral": "0.9966402053833008",
   "Mixed": "2.9274629923747852e-05"
 },
 {
   "tweet_id": "1589948539018543110",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589948534715191297",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948534673272833",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948534371266560",
   "Positive": "0.7428883910179138",
   "Negative": "0.0006479154690168798",
   "Neutral": "0.2563895583152771",
   "Mixed": "7.415829895762727e-05"
 },
 {
   "tweet_id": "1589948529312952320",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948520827875328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948499935625217",
   "Positive": "0.023634757846593857",
   "Negative": "0.00025093863951042295",
   "Neutral": "0.9761050939559937",
   "Mixed": "9.212110853695776e-06"
 },
 {
   "tweet_id": "1589948496605765632",
   "Positive": "0.7428883910179138",
   "Negative": "0.0006479154690168798",
   "Neutral": "0.2563895583152771",
   "Mixed": "7.415829895762727e-05"
 },
 {
   "tweet_id": "1589948490394013697",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1589948487055343617",
   "Positive": "0.002194340107962489",
   "Negative": "0.6268274188041687",
   "Neutral": "0.36478114128112793",
   "Mixed": "0.0061971284449100494"
 },
 {
   "tweet_id": "1589948478712877057",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948476196257795",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948472819863558",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589948471561555970",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948470764261376",
   "Positive": "0.021102676168084145",
   "Negative": "0.00014750381524208933",
   "Neutral": "0.9787322878837585",
   "Mixed": "1.754801814968232e-05"
 },
 {
   "tweet_id": "1589948467191123969",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948464036601858",
   "Positive": "0.08460062742233276",
   "Negative": "0.0019927204120904207",
   "Neutral": "0.9133991003036499",
   "Mixed": "7.5656557783077005e-06"
 },
 {
   "tweet_id": "1589948451315671040",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589948441308065792",
   "Positive": "0.7428883910179138",
   "Negative": "0.0006479154690168798",
   "Neutral": "0.2563895583152771",
   "Mixed": "7.415829895762727e-05"
 },
 {
   "tweet_id": "1589948439559041024",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948438434938880",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948437935845377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948423985573889",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589948397938937862",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948397049778176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948390754115584",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589948373859467265",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948370587877376",
   "Positive": "0.08688318729400635",
   "Negative": "0.0030696503818035126",
   "Neutral": "0.9100332260131836",
   "Mixed": "1.3887090972275473e-05"
 },
 {
   "tweet_id": "1589948363914760192",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948360882278400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948350945976320",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948339780714497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948338245599232",
   "Positive": "0.4901551902294159",
   "Negative": "0.13120999932289124",
   "Neutral": "0.33435171842575073",
   "Mixed": "0.044283077120780945"
 },
 {
   "tweet_id": "1589948335917772802",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948327298469889",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589948322349223936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948313801232385",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948302447243265",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948297762189320",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589948290040463360",
   "Positive": "0.005423258990049362",
   "Negative": "0.9326051473617554",
   "Neutral": "0.061629150062799454",
   "Mixed": "0.00034248357405886054"
 },
 {
   "tweet_id": "1589948289797218304",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948286383063040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948285002821632",
   "Positive": "0.014188529923558235",
   "Negative": "0.7214627265930176",
   "Neutral": "0.25648123025894165",
   "Mixed": "0.007867499254643917"
 },
 {
   "tweet_id": "1589948276924874753",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589948240673509376",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948235464216576",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948234658873345",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948201255469056",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589948198583291904",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948197015019520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948189482049536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948177272012800",
   "Positive": "0.9869465827941895",
   "Negative": "9.73241330939345e-05",
   "Neutral": "0.012942231260240078",
   "Mixed": "1.3877452147426084e-05"
 },
 {
   "tweet_id": "1589948172797083649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948169185824768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948152874176513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948144242282497",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948142405156864",
   "Positive": "0.004710924345999956",
   "Negative": "9.947185026248917e-05",
   "Neutral": "0.995183527469635",
   "Mixed": "6.109372861828888e-06"
 },
 {
   "tweet_id": "1589948134582816769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948133416775680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948132015886336",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589948130438840320",
   "Positive": "0.06956763565540314",
   "Negative": "0.0016003890195861459",
   "Neutral": "0.9288201928138733",
   "Mixed": "1.1689204256981611e-05"
 },
 {
   "tweet_id": "1589948129616752641",
   "Positive": "0.006171129178255796",
   "Negative": "0.0006831256323494017",
   "Neutral": "0.9931433200836182",
   "Mixed": "2.341041181352921e-06"
 },
 {
   "tweet_id": "1589948116224335872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948108846559232",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948106195730434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948103318458370",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948098209808386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948095005351936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948084897087488",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589948083353563136",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589948075979968514",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948074217992192",
   "Positive": "0.018340984359383583",
   "Negative": "0.3561003804206848",
   "Neutral": "0.6253169178962708",
   "Mixed": "0.00024173784186132252"
 },
 {
   "tweet_id": "1589948062717612035",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948060058419200",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948053938925570",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948047261593602",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948044577222657",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948042236813313",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948041666109442",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589948040525119488",
   "Positive": "0.007332177367061377",
   "Negative": "0.00010799732990562916",
   "Neutral": "0.9925526976585388",
   "Mixed": "7.053406989143696e-06"
 },
 {
   "tweet_id": "1589948037698580480",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948031226769412",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589948026701099008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948022959804420",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589948019977248768",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948011173793793",
   "Positive": "0.018080947920680046",
   "Negative": "0.4689452350139618",
   "Neutral": "0.5128276944160461",
   "Mixed": "0.00014614101382903755"
 },
 {
   "tweet_id": "1589948006102863875",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948004743925760",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589948003959590912",
   "Positive": "0.011284681968390942",
   "Negative": "0.005083788186311722",
   "Neutral": "0.9836275577545166",
   "Mixed": "3.944202489947202e-06"
 },
 {
   "tweet_id": "1589947992022609920",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947972191932418",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947966047277057",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947949420724224",
   "Positive": "0.03385873883962631",
   "Negative": "0.16444899141788483",
   "Neutral": "0.7998729348182678",
   "Mixed": "0.0018192959250882268"
 },
 {
   "tweet_id": "1589947948770922496",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589947939073712129",
   "Positive": "0.037234749644994736",
   "Negative": "0.3997379541397095",
   "Neutral": "0.5605097413063049",
   "Mixed": "0.0025174824986606836"
 },
 {
   "tweet_id": "1589947938255810560",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947936636801025",
   "Positive": "0.9875643849372864",
   "Negative": "4.278023698134348e-05",
   "Neutral": "0.012365633621811867",
   "Mixed": "2.7255706299911253e-05"
 },
 {
   "tweet_id": "1589947931649789953",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589947918366412801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947905519276032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947902340001793",
   "Positive": "0.005879160948097706",
   "Negative": "0.009870011359453201",
   "Neutral": "0.9842053055763245",
   "Mixed": "4.547011849354021e-05"
 },
 {
   "tweet_id": "1589947899777286145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947887374725121",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589947879090970629",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947867275616258",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947864322641920",
   "Positive": "0.00036080405698157847",
   "Negative": "7.229961192933843e-05",
   "Neutral": "0.9995146989822388",
   "Mixed": "5.2146322559565306e-05"
 },
 {
   "tweet_id": "1589947856252985348",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947855242158080",
   "Positive": "0.06946290284395218",
   "Negative": "0.0031988341361284256",
   "Neutral": "0.9272063374519348",
   "Mixed": "0.00013201779802329838"
 },
 {
   "tweet_id": "1589947854977929218",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947851244998656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947850523566082",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947837860970496",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947834195140608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947811701084160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947803509620736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947783276302338",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947783154663426",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947754998296576",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947754000044032",
   "Positive": "0.9806711673736572",
   "Negative": "3.2642288715578616e-05",
   "Neutral": "0.019246716052293777",
   "Mixed": "4.9466954806121066e-05"
 },
 {
   "tweet_id": "1589947748887171072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947745028407296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947733993222145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947722073010176",
   "Positive": "0.9841465353965759",
   "Negative": "3.0174256608006544e-05",
   "Neutral": "0.015775667503476143",
   "Mixed": "4.764361437992193e-05"
 },
 {
   "tweet_id": "1589947703106359296",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947701184995329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947699226619904",
   "Positive": "0.9081459641456604",
   "Negative": "0.00010629640746628866",
   "Neutral": "0.09168874472379684",
   "Mixed": "5.896458606002852e-05"
 },
 {
   "tweet_id": "1589947684844371970",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947683099529219",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589947681925128195",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589947681866395650",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947679999942657",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947663650525184",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589947658315395076",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947647087214595",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947646537773059",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947644360921088",
   "Positive": "0.000391790788853541",
   "Negative": "6.804774602642283e-05",
   "Neutral": "0.9994611144065857",
   "Mixed": "7.914466550573707e-05"
 },
 {
   "tweet_id": "1589947643857633281",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947634852454400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947617689370627",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947602195578880",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947597833506817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947588908036096",
   "Positive": "0.955447256565094",
   "Negative": "0.0003442315210122615",
   "Neutral": "0.04414626583456993",
   "Mixed": "6.228886195458472e-05"
 },
 {
   "tweet_id": "1589947574806798337",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947570667028481",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947568930582529",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589947559891849222",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589947548751781888",
   "Positive": "0.11631159484386444",
   "Negative": "0.0018262171652168036",
   "Neutral": "0.8816229701042175",
   "Mixed": "0.00023923891421873122"
 },
 {
   "tweet_id": "1589947547908739072",
   "Positive": "0.0033907967153936625",
   "Negative": "0.9358211159706116",
   "Neutral": "0.060639165341854095",
   "Mixed": "0.000148850551340729"
 },
 {
   "tweet_id": "1589947547585777665",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947543072301059",
   "Positive": "0.0038853378500789404",
   "Negative": "0.000276160251814872",
   "Neutral": "0.9958357810974121",
   "Mixed": "2.7254336600890383e-06"
 },
 {
   "tweet_id": "1589947531433476097",
   "Positive": "0.02454180084168911",
   "Negative": "4.507240737439133e-05",
   "Neutral": "0.9752888679504395",
   "Mixed": "0.0001242463622475043"
 },
 {
   "tweet_id": "1589947530510741504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947526261911553",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947524386721796",
   "Positive": "0.018806206062436104",
   "Negative": "0.28899556398391724",
   "Neutral": "0.6707414388656616",
   "Mixed": "0.021456843242049217"
 },
 {
   "tweet_id": "1589947520146616321",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947508981399553",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589947507442069504",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947504199897088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947490337697792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947453675298816",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947450860924933",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947450202402817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947438881976323",
   "Positive": "0.14461222290992737",
   "Negative": "0.01181353535503149",
   "Neutral": "0.8435441851615906",
   "Mixed": "3.0086643164395355e-05"
 },
 {
   "tweet_id": "1589947431772618752",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947429767753730",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947412264914945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947394594344960",
   "Positive": "0.0014788932166993618",
   "Negative": "0.0001360527821816504",
   "Neutral": "0.9983811378479004",
   "Mixed": "3.8809389479865786e-06"
 },
 {
   "tweet_id": "1589947377603207168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947375799267328",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589947373161443330",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947368124088320",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947359961952256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947354601639937",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947353100058626",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589947345248010240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947342035484675",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947340877881344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947337497280514",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947333961469953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947326822776832",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947325849669634",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947320518311936",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589947317121343488",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947310863450114",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947309332508673",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589947301984088066",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947281415229440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947279389380608",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947272347136002",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947271894159361",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947269423706112",
   "Positive": "0.01955387182533741",
   "Negative": "0.17467746138572693",
   "Neutral": "0.8050087094306946",
   "Mixed": "0.000759990478400141"
 },
 {
   "tweet_id": "1589947265262977026",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947263702700035",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947262700236800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947261223829505",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947253514698752",
   "Positive": "0.4961756765842438",
   "Negative": "0.0012711785966530442",
   "Neutral": "0.5025368332862854",
   "Mixed": "1.63500735652633e-05"
 },
 {
   "tweet_id": "1589947246099181568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947244748615680",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947242634711040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947223324123138",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947212293111809",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589947205682892800",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589947198355431424",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947197420089344",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589947189325094912",
   "Positive": "0.002166729187592864",
   "Negative": "0.000342504441505298",
   "Neutral": "0.9974875450134277",
   "Mixed": "3.193162001480232e-06"
 },
 {
   "tweet_id": "1589947188767227905",
   "Positive": "0.6964404582977295",
   "Negative": "0.0008403691463172436",
   "Neutral": "0.30223920941352844",
   "Mixed": "0.0004799910238943994"
 },
 {
   "tweet_id": "1589947184149336064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947181745995776",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1589947178675744769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947161906933763",
   "Positive": "0.009461387991905212",
   "Negative": "0.21827548742294312",
   "Neutral": "0.7722086906433105",
   "Mixed": "5.4498290410265326e-05"
 },
 {
   "tweet_id": "1589947161676251140",
   "Positive": "0.9777741432189941",
   "Negative": "6.459659925894812e-05",
   "Neutral": "0.022096341475844383",
   "Mixed": "6.492132524726912e-05"
 },
 {
   "tweet_id": "1589947153883238401",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947152197111809",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947150112534531",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947148346474498",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589947145465257984",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589947141635862530",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947135058853888",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947135029809154",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589947128767709186",
   "Positive": "0.03266004100441933",
   "Negative": "0.000530614925082773",
   "Neutral": "0.9667843580245972",
   "Mixed": "2.4998193111969158e-05"
 },
 {
   "tweet_id": "1589947124094947329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947110795116544",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947104277192704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947099772121088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589947093996933121",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589947091291635713",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589947082366136321",
   "Positive": "0.0023629628121852875",
   "Negative": "0.0003296071954537183",
   "Neutral": "0.9973025321960449",
   "Mixed": "4.885486760031199e-06"
 },
 {
   "tweet_id": "1589947074690551809",
   "Positive": "0.24121679365634918",
   "Negative": "0.022570831701159477",
   "Neutral": "0.736108660697937",
   "Mixed": "0.00010375509009463713"
 },
 {
   "tweet_id": "1589947065421148163",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589947064947208192",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589947051844177926",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947050367815683",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947034605584386",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589947034106466304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589947033267630080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946986509148160",
   "Positive": "0.7016639709472656",
   "Negative": "0.06506790965795517",
   "Neutral": "0.1926746815443039",
   "Mixed": "0.04059341549873352"
 },
 {
   "tweet_id": "1589946983149899777",
   "Positive": "0.809009850025177",
   "Negative": "0.04000472649931908",
   "Neutral": "0.10335174202919006",
   "Mixed": "0.04763367399573326"
 },
 {
   "tweet_id": "1589946978104139776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946929617985536",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589946929479569409",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946929147887618",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946919581028353",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589946915894198273",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946904590196736",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946904439590912",
   "Positive": "0.41659480333328247",
   "Negative": "0.009355074726045132",
   "Neutral": "0.572929859161377",
   "Mixed": "0.001120298751629889"
 },
 {
   "tweet_id": "1589946899406389248",
   "Positive": "0.018625928089022636",
   "Negative": "0.4960883557796478",
   "Neutral": "0.4852088391780853",
   "Mixed": "7.694050873396918e-05"
 },
 {
   "tweet_id": "1589946888887111690",
   "Positive": "0.03000505641102791",
   "Negative": "0.17124143242835999",
   "Neutral": "0.3916877508163452",
   "Mixed": "0.407065749168396"
 },
 {
   "tweet_id": "1589946887683313664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946879135346688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946876870430722",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946868871888896",
   "Positive": "0.004066518973559141",
   "Negative": "0.0003969300305470824",
   "Neutral": "0.9955289959907532",
   "Mixed": "7.5467310125532094e-06"
 },
 {
   "tweet_id": "1589946852597583874",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1589946851482025989",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946849347391490",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946844951777280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946835376181250",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946820888711168",
   "Positive": "0.08172097057104111",
   "Negative": "0.0022751104552298784",
   "Neutral": "0.9159864187240601",
   "Mixed": "1.751695162965916e-05"
 },
 {
   "tweet_id": "1589946816623439872",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946816594087936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946811158269952",
   "Positive": "0.13264748454093933",
   "Negative": "0.0017128472682088614",
   "Neutral": "0.8656186461448669",
   "Mixed": "2.1010933778597973e-05"
 },
 {
   "tweet_id": "1589946805361733632",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946803373617153",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946784079831042",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946783152902145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946780502085633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946767663325184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946756972044288",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589946744397508608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946735606263809",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946733085478913",
   "Positive": "0.00040678124059922993",
   "Negative": "6.765280704712495e-05",
   "Neutral": "0.9995177984237671",
   "Mixed": "7.788354196236469e-06"
 },
 {
   "tweet_id": "1589946730023645189",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946721060401153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946716744474629",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946710629158914",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946709907738624",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946708943048705",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946706447466497",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946695009599489",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946690920124417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946690802368513",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589946690014171138",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946688793612289",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589946681076092930",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946680283365379",
   "Positive": "0.14417849481105804",
   "Negative": "0.0001968426222447306",
   "Neutral": "0.855593204498291",
   "Mixed": "3.143189678667113e-05"
 },
 {
   "tweet_id": "1589946672481964033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946669134938114",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946659215400961",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946651795689472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946650084380672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946647878209536",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946634099904513",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946620954955778",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946584867143680",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946578584096769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946572628164608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946565120385025",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946562041769987",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946559139282944",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589946548389285888",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589946544576679936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946541900718081",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946531054227456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946521751269379",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946518492000257",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946509432610816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946505355735041",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1589946495067099138",
   "Positive": "0.12374121695756912",
   "Negative": "0.005444316193461418",
   "Neutral": "0.8707899451255798",
   "Mixed": "2.4459324777126312e-05"
 },
 {
   "tweet_id": "1589946488167141376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946478864519168",
   "Positive": "0.001217476325109601",
   "Negative": "0.00012227438855916262",
   "Neutral": "0.9986562728881836",
   "Mixed": "3.932483195967507e-06"
 },
 {
   "tweet_id": "1589946466390663168",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946464951668736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946460732559363",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946453820309505",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589946451815436289",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946436804042755",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946432622333954",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946429300436994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946423654907904",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946416923037697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946406844104707",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946398103175168",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589946385558011904",
   "Positive": "0.8284680247306824",
   "Negative": "0.0003416806284803897",
   "Neutral": "0.17115844786167145",
   "Mixed": "3.182638101861812e-05"
 },
 {
   "tweet_id": "1589946371851059215",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946360606117889",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946348304207872",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946340137918465",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946339856879616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946339202592768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946322807058432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946317975224321",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589946312916897793",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589946303471321091",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946297355993088",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946293824401408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946287314829312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946281396686850",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946280876584965",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946276262842369",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946274660618240",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946271347118087",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589946269715554304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946267320602625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946265726779393",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589946254225985536",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946241257197568",
   "Positive": "0.03193483129143715",
   "Negative": "0.15670672059059143",
   "Neutral": "0.8112761378288269",
   "Mixed": "8.230778621509671e-05"
 },
 {
   "tweet_id": "1589946240930058242",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946234873483264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946223103856640",
   "Positive": "0.16178438067436218",
   "Negative": "0.005840865895152092",
   "Neutral": "0.8323495984077454",
   "Mixed": "2.5229795937775634e-05"
 },
 {
   "tweet_id": "1589946221950795777",
   "Positive": "0.13628613948822021",
   "Negative": "0.000339247053489089",
   "Neutral": "0.8628549575805664",
   "Mixed": "0.0005196975544095039"
 },
 {
   "tweet_id": "1589946213792894976",
   "Positive": "0.053596921265125275",
   "Negative": "0.039010342210531235",
   "Neutral": "0.9073604345321655",
   "Mixed": "3.238154386053793e-05"
 },
 {
   "tweet_id": "1589946211309858821",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210978508800",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210953334791",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210877857792",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210470989824",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210416492544",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210357751808",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210227736576",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210181611520",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210160611330",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210148044800",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210135465986",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210118668288",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210114502656",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946210080952320",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946209997049858",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946209942540288",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946197154099200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946195811905536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946188941635584",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589946188778078210",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946186349555713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946180024561666",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946160323911682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946153503981568",
   "Positive": "0.0011065007420256734",
   "Negative": "0.0007447197567671537",
   "Neutral": "0.998145580291748",
   "Mixed": "3.173790901200846e-06"
 },
 {
   "tweet_id": "1589946150408589313",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946148173021185",
   "Positive": "0.5909238457679749",
   "Negative": "0.0041438015177845955",
   "Neutral": "0.4047803282737732",
   "Mixed": "0.00015210524725262076"
 },
 {
   "tweet_id": "1589946141231415298",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589946136550608896",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589946136479293440",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589946134793158656",
   "Positive": "0.04297512769699097",
   "Negative": "0.0002550190547481179",
   "Neutral": "0.9567549228668213",
   "Mixed": "1.4926209587429184e-05"
 },
 {
   "tweet_id": "1589946127289548813",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946127201468416",
   "Positive": "0.022435197606682777",
   "Negative": "0.0020105785224586725",
   "Neutral": "0.9755439758300781",
   "Mixed": "1.0194592505285982e-05"
 },
 {
   "tweet_id": "1589946120632905728",
   "Positive": "0.29377996921539307",
   "Negative": "0.0009157704189419746",
   "Neutral": "0.7052551507949829",
   "Mixed": "4.910063216811977e-05"
 },
 {
   "tweet_id": "1589946115847516160",
   "Positive": "0.8343119621276855",
   "Negative": "0.0007083681412041187",
   "Neutral": "0.1649019420146942",
   "Mixed": "7.77734603616409e-05"
 },
 {
   "tweet_id": "1589946111636439043",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946101410705408",
   "Positive": "0.46124380826950073",
   "Negative": "0.0005662901676259935",
   "Neutral": "0.5381180644035339",
   "Mixed": "7.184803689597175e-05"
 },
 {
   "tweet_id": "1589946094628540416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946091356946432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946090216116226",
   "Positive": "0.0031272785272449255",
   "Negative": "0.0006497721187770367",
   "Neutral": "0.9962204098701477",
   "Mixed": "2.5926406124199275e-06"
 },
 {
   "tweet_id": "1589946084721594371",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946079898107907",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946078446878722",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946076337143816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946074977824768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589946072939384833",
   "Positive": "0.1454382836818695",
   "Negative": "0.0005851380410604179",
   "Neutral": "0.8531830310821533",
   "Mixed": "0.0007935497560538352"
 },
 {
   "tweet_id": "1589946069345239047",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946061237661698",
   "Positive": "0.009112056344747543",
   "Negative": "0.3412063419818878",
   "Neutral": "0.649506151676178",
   "Mixed": "0.00017545379523653537"
 },
 {
   "tweet_id": "1589946053675323394",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946051422978048",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589946038013788160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946036390629381",
   "Positive": "0.009604713879525661",
   "Negative": "0.06714583933353424",
   "Neutral": "0.9232232570648193",
   "Mixed": "2.6181645807810128e-05"
 },
 {
   "tweet_id": "1589946032439570433",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589946030845734913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946018485145603",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589946002337062913",
   "Positive": "0.027023116126656532",
   "Negative": "0.0002810445730574429",
   "Neutral": "0.9726870656013489",
   "Mixed": "8.81438154465286e-06"
 },
 {
   "tweet_id": "1589945999614967808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945996515348480",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1589945994120089602",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589945993164132353",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945992778244097",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945990655905798",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945980937728000",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945975455744000",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945959743905792",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945957697060865",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945956933718016",
   "Positive": "0.7216961979866028",
   "Negative": "0.00027487409533932805",
   "Neutral": "0.2778659164905548",
   "Mixed": "0.00016309462080243975"
 },
 {
   "tweet_id": "1589945956585598976",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589945936633073665",
   "Positive": "0.015975771471858025",
   "Negative": "0.0362280011177063",
   "Neutral": "0.9477549195289612",
   "Mixed": "4.1397670429432765e-05"
 },
 {
   "tweet_id": "1589945920849747968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945915808559104",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589945915162636290",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945911568117761",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945910544732160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945898662268928",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589945883650850816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945859198038019",
   "Positive": "0.002545424969866872",
   "Negative": "0.2116471827030182",
   "Neutral": "0.07053987681865692",
   "Mixed": "0.715267539024353"
 },
 {
   "tweet_id": "1589945854580133888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945847873409024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945845684015104",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589945835215015936",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945833419857920",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945816571342848",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945808665071616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945804848238594",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945797978001409",
   "Positive": "0.041904740035533905",
   "Negative": "0.00474395789206028",
   "Neutral": "0.9532907605171204",
   "Mixed": "6.053742617950775e-05"
 },
 {
   "tweet_id": "1589945797822451713",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1589945796413489153",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945785571237888",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945777954381826",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945776113061888",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589945759927250945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945743733043201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945690465402881",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945688905121795",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589945676074717185",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589945669124780035",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945657863081985",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945650321719297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945648161619968",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945645842202627",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945638388895749",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945637650386945",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589945615123120128",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945603978858499",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945591194619905",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945584894750721",
   "Positive": "0.00044355355203151703",
   "Negative": "6.93691908963956e-05",
   "Neutral": "0.9994186162948608",
   "Mixed": "6.846228643553331e-05"
 },
 {
   "tweet_id": "1589945555555614725",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945554884517889",
   "Positive": "0.003156573511660099",
   "Negative": "0.00032454446773044765",
   "Neutral": "0.9965143799781799",
   "Mixed": "4.5402707655739505e-06"
 },
 {
   "tweet_id": "1589945550241398786",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945529869688833",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945522214776838",
   "Positive": "0.005677780136466026",
   "Negative": "0.0007307586492970586",
   "Neutral": "0.993582546710968",
   "Mixed": "8.854402040014975e-06"
 },
 {
   "tweet_id": "1589945509128867844",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589945497640632320",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589945495635767302",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945492880109568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945491759865857",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945490892009474",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945487435923457",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945483350667264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945443802570752",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945443458637824",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589945435728531456",
   "Positive": "0.9976296424865723",
   "Negative": "6.00123566982802e-05",
   "Neutral": "0.002297318773344159",
   "Mixed": "1.2906215488328598e-05"
 },
 {
   "tweet_id": "1589945435065847808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945431446155264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945419400085505",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945398570778626",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945377931022338",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945350932299777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945349216829440",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945334540939267",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945310402732033",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589945304467795972",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589945291532546049",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589945282120540162",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945276655030273",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945269919318016",
   "Positive": "0.013471408747136593",
   "Negative": "0.0008541900897398591",
   "Neutral": "0.9856420159339905",
   "Mixed": "3.244628169341013e-05"
 },
 {
   "tweet_id": "1589945267142656000",
   "Positive": "0.0152978440746665",
   "Negative": "0.00032485974952578545",
   "Neutral": "0.9843723773956299",
   "Mixed": "4.93713514515548e-06"
 },
 {
   "tweet_id": "1589945263124545538",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945259756511235",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945258452078592",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945250176733184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945246997413888",
   "Positive": "0.06939531862735748",
   "Negative": "0.008436555974185467",
   "Neutral": "0.9221188426017761",
   "Mixed": "4.9309655878460035e-05"
 },
 {
   "tweet_id": "1589945239342526464",
   "Positive": "0.001657408196479082",
   "Negative": "0.01240011490881443",
   "Neutral": "0.9859235286712646",
   "Mixed": "1.895500099635683e-05"
 },
 {
   "tweet_id": "1589945236872040448",
   "Positive": "0.0006373744690790772",
   "Negative": "9.056831913767383e-05",
   "Neutral": "0.999267041683197",
   "Mixed": "5.017300736653851e-06"
 },
 {
   "tweet_id": "1589945236620709889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945236503298048",
   "Positive": "0.6576926708221436",
   "Negative": "0.004128767643123865",
   "Neutral": "0.336586058139801",
   "Mixed": "0.0015925696352496743"
 },
 {
   "tweet_id": "1589945213275246593",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589945205318627328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945188491104257",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945181776023554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945177766252545",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589945145356881920",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945139048640513",
   "Positive": "0.05794372409582138",
   "Negative": "0.022078324109315872",
   "Neutral": "0.9194478392601013",
   "Mixed": "0.0005301809869706631"
 },
 {
   "tweet_id": "1589945133486989312",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589945131624693761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945130768732161",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945114755227648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945110619652099",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589945097160118273",
   "Positive": "0.07349544018507004",
   "Negative": "0.009613814763724804",
   "Neutral": "0.9132004380226135",
   "Mixed": "0.0036903265863656998"
 },
 {
   "tweet_id": "1589945097030094848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945088599523329",
   "Positive": "0.0006147176027297974",
   "Negative": "0.8089212775230408",
   "Neutral": "0.1897827684879303",
   "Mixed": "0.0006811965140514076"
 },
 {
   "tweet_id": "1589945085915181056",
   "Positive": "0.0036091499496251345",
   "Negative": "0.00018072177772410214",
   "Neutral": "0.9962061643600464",
   "Mixed": "3.8721077544323634e-06"
 },
 {
   "tweet_id": "1589945082744311808",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589945080085114880",
   "Positive": "0.012339561246335506",
   "Negative": "0.06214273348450661",
   "Neutral": "0.9254393577575684",
   "Mixed": "7.828533125575632e-05"
 },
 {
   "tweet_id": "1589945077790830597",
   "Positive": "0.0008377031772397459",
   "Negative": "0.012397210113704205",
   "Neutral": "0.9867568016052246",
   "Mixed": "8.184240869013593e-06"
 },
 {
   "tweet_id": "1589945058673201154",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589945038918004738",
   "Positive": "0.03449837863445282",
   "Negative": "0.0003893934772349894",
   "Neutral": "0.9651005864143372",
   "Mixed": "1.160335432359716e-05"
 },
 {
   "tweet_id": "1589945032114860032",
   "Positive": "0.0032115706708282232",
   "Negative": "0.00012723557301796973",
   "Neutral": "0.9966570138931274",
   "Mixed": "4.1266016523877624e-06"
 },
 {
   "tweet_id": "1589945028855889922",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945021222227968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945020098170880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945015799021568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945014561693698",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589945007825645569",
   "Positive": "0.46124380826950073",
   "Negative": "0.0005662901676259935",
   "Neutral": "0.5381180644035339",
   "Mixed": "7.184803689597175e-05"
 },
 {
   "tweet_id": "1589945004088496130",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944998573015041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944988628316160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944984572399616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944974099255296",
   "Positive": "0.00040025089401751757",
   "Negative": "8.031575271161273e-05",
   "Neutral": "0.9995028972625732",
   "Mixed": "1.6524723832844757e-05"
 },
 {
   "tweet_id": "1589944972891291649",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944969405792258",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944960295788545",
   "Positive": "0.0008781427750363946",
   "Negative": "4.995079507352784e-05",
   "Neutral": "0.9990517497062683",
   "Mixed": "2.0155037418589927e-05"
 },
 {
   "tweet_id": "1589944954578960387",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944953605861376",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589944947268280323",
   "Positive": "0.0022901829797774553",
   "Negative": "0.0001642764691496268",
   "Neutral": "0.9975415468215942",
   "Mixed": "4.076275217812508e-06"
 },
 {
   "tweet_id": "1589944927538249728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944926128611330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944920085008391",
   "Positive": "0.14461222290992737",
   "Negative": "0.01181353535503149",
   "Neutral": "0.8435441851615906",
   "Mixed": "3.0086643164395355e-05"
 },
 {
   "tweet_id": "1589944909959921664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944901441294336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944884496330754",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944882453708800",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944880373321728",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944878599139329",
   "Positive": "0.0037993951700627804",
   "Negative": "0.00016101449728012085",
   "Neutral": "0.9960342049598694",
   "Mixed": "5.371727638703305e-06"
 },
 {
   "tweet_id": "1589944871145525248",
   "Positive": "0.2956930994987488",
   "Negative": "0.00037415369297377765",
   "Neutral": "0.703904926776886",
   "Mixed": "2.7822843549074605e-05"
 },
 {
   "tweet_id": "1589944867492597765",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944865441214464",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589944863600132096",
   "Positive": "0.7259858250617981",
   "Negative": "0.000420347903855145",
   "Neutral": "0.2734859585762024",
   "Mixed": "0.000107871979707852"
 },
 {
   "tweet_id": "1589944858374201344",
   "Positive": "0.2431105077266693",
   "Negative": "0.008411556482315063",
   "Neutral": "0.748443067073822",
   "Mixed": "3.484173066681251e-05"
 },
 {
   "tweet_id": "1589944852221157376",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589944850476302337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944844243587073",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944843375378433",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944820076019714",
   "Positive": "0.008256493136286736",
   "Negative": "0.0012240242213010788",
   "Neutral": "0.9904858469963074",
   "Mixed": "3.370838749106042e-05"
 },
 {
   "tweet_id": "1589944818821525504",
   "Positive": "0.002099724020808935",
   "Negative": "0.001205561333335936",
   "Neutral": "0.996692419052124",
   "Mixed": "2.27387249651656e-06"
 },
 {
   "tweet_id": "1589944811116965888",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944808684281857",
   "Positive": "0.0411798469722271",
   "Negative": "0.0489790253341198",
   "Neutral": "0.9095134139060974",
   "Mixed": "0.0003276195493526757"
 },
 {
   "tweet_id": "1589944804456431622",
   "Positive": "0.7259858250617981",
   "Negative": "0.000420347903855145",
   "Neutral": "0.2734859585762024",
   "Mixed": "0.000107871979707852"
 },
 {
   "tweet_id": "1589944804162818048",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944789608562690",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944789583409155",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944786525773824",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944784172748800",
   "Positive": "0.898429274559021",
   "Negative": "0.00010576495697023347",
   "Neutral": "0.10138963162899017",
   "Mixed": "7.52728883526288e-05"
 },
 {
   "tweet_id": "1589944783245824001",
   "Positive": "0.012695726938545704",
   "Negative": "0.5671111941337585",
   "Neutral": "0.4201558828353882",
   "Mixed": "3.71543355868198e-05"
 },
 {
   "tweet_id": "1589944765335773184",
   "Positive": "0.7752001285552979",
   "Negative": "0.00018821675621438771",
   "Neutral": "0.22445908188819885",
   "Mixed": "0.0001525274128653109"
 },
 {
   "tweet_id": "1589944764912533504",
   "Positive": "0.9666414260864258",
   "Negative": "0.00018131440447177738",
   "Neutral": "0.0331357941031456",
   "Mixed": "4.1555220377631485e-05"
 },
 {
   "tweet_id": "1589944756855267328",
   "Positive": "0.021865958347916603",
   "Negative": "0.0006651774165220559",
   "Neutral": "0.9774618744850159",
   "Mixed": "6.9381626417452935e-06"
 },
 {
   "tweet_id": "1589944751444594688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944741965475843",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944740946272256",
   "Positive": "0.4036422073841095",
   "Negative": "0.000766499200835824",
   "Neutral": "0.5953569412231445",
   "Mixed": "0.0002343559026485309"
 },
 {
   "tweet_id": "1589944737938952193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944734864531456",
   "Positive": "0.14190369844436646",
   "Negative": "7.492796430597082e-05",
   "Neutral": "0.8577812910079956",
   "Mixed": "0.00024008913896977901"
 },
 {
   "tweet_id": "1589944733191004160",
   "Positive": "0.010913635604083538",
   "Negative": "0.001191074145026505",
   "Neutral": "0.9878925085067749",
   "Mixed": "2.7871228667208925e-06"
 },
 {
   "tweet_id": "1589944730896732160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944722109628417",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944716212436994",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944693806493697",
   "Positive": "0.024464741349220276",
   "Negative": "0.0021388698369264603",
   "Neutral": "0.9733783602714539",
   "Mixed": "1.8029055354418233e-05"
 },
 {
   "tweet_id": "1589944691268923393",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944676484009986",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944671039819777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944667264946176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944662235971585",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944652991713280",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944636545851393",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589944626257199105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944617512087552",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589944615905660929",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589944609018646528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944603234689024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944602374868993",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589944601615699968",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589944596431507456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944589452210178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944567117545472",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589944545990836224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944544313102336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944532208357376",
   "Positive": "0.07863854616880417",
   "Negative": "0.000383490405511111",
   "Neutral": "0.9209082126617432",
   "Mixed": "6.97261857567355e-05"
 },
 {
   "tweet_id": "1589944512800903174",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944507789086721",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944494346362880",
   "Positive": "0.004902326036244631",
   "Negative": "0.004019385669380426",
   "Neutral": "0.9907429814338684",
   "Mixed": "0.0003352701314724982"
 },
 {
   "tweet_id": "1589944488990248960",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589944474670870528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944474012356608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944471948447745",
   "Positive": "0.19605877995491028",
   "Negative": "0.23853395879268646",
   "Neutral": "0.5433403849601746",
   "Mixed": "0.022066881880164146"
 },
 {
   "tweet_id": "1589944467645423616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944462419300355",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944460175364098",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944455398043648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944450637250565",
   "Positive": "0.0007642889977432787",
   "Negative": "6.217976624611765e-05",
   "Neutral": "0.9991669654846191",
   "Mixed": "6.533423857035814e-06"
 },
 {
   "tweet_id": "1589944427807920129",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589944423495835654",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944422623772673",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944418882441217",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589944410850361344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944407092264960",
   "Positive": "0.0019364510662853718",
   "Negative": "0.16150087118148804",
   "Neutral": "0.8362792730331421",
   "Mixed": "0.0002834330953191966"
 },
 {
   "tweet_id": "1589944406693793793",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944405787807745",
   "Positive": "0.0042641679756343365",
   "Negative": "0.17112819850444794",
   "Neutral": "0.8245776295661926",
   "Mixed": "3.0012715797056444e-05"
 },
 {
   "tweet_id": "1589944404189802497",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589944399253106689",
   "Positive": "0.10188665986061096",
   "Negative": "0.001171040115877986",
   "Neutral": "0.8969194889068604",
   "Mixed": "2.282447530888021e-05"
 },
 {
   "tweet_id": "1589944394907815936",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944393821487104",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944371969142786",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944371553927168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944368320118784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944362225786880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944359335915520",
   "Positive": "0.007451686542481184",
   "Negative": "0.00020886436686851084",
   "Neutral": "0.9923343062400818",
   "Mixed": "5.129272267367924e-06"
 },
 {
   "tweet_id": "1589944354030125056",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944332856963072",
   "Positive": "0.13404481112957",
   "Negative": "0.00240742857567966",
   "Neutral": "0.8635206818580627",
   "Mixed": "2.7042417059419677e-05"
 },
 {
   "tweet_id": "1589944319775240194",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944313949323266",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944305229402114",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944287508066304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944285834932225",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944276829765632",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944261239533568",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589944257384972293",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944242004430852",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944237990498304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944229161504770",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589944221674635265",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589944221188120577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944217484562439",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589944214049017857",
   "Positive": "0.003716107225045562",
   "Negative": "0.00014299187751021236",
   "Neutral": "0.9961330890655518",
   "Mixed": "7.884626938903239e-06"
 },
 {
   "tweet_id": "1589944196940824576",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589944176636231680",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944172152508416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944163868737537",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944146520743937",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944111473524736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944109548343299",
   "Positive": "0.7500593066215515",
   "Negative": "0.0014773472212255",
   "Neutral": "0.23068910837173462",
   "Mixed": "0.017774147912859917"
 },
 {
   "tweet_id": "1589944105949605890",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944098479538177",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589944082520240136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944082482475009",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944081081565184",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944066799988737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944058662699008",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944055936716800",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589944052879093760",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589944046449233921",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589944044800856064",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944043441901569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944036474896387",
   "Positive": "0.4534795582294464",
   "Negative": "0.002742049051448703",
   "Neutral": "0.543691098690033",
   "Mixed": "8.721363701624796e-05"
 },
 {
   "tweet_id": "1589944031345541120",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944003491147778",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589944001654063104",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943999871451137",
   "Positive": "0.07552531361579895",
   "Negative": "0.00011148081830469891",
   "Neutral": "0.9242138862609863",
   "Mixed": "0.00014930036559235305"
 },
 {
   "tweet_id": "1589943993227710464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943973044695042",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943955030151168",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589943954463928321",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589943953758904320",
   "Positive": "0.0653199851512909",
   "Negative": "0.016403745859861374",
   "Neutral": "0.9182475209236145",
   "Mixed": "2.8800344807677902e-05"
 },
 {
   "tweet_id": "1589943947451072513",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943947396546561",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943940433575936",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589943938432966656",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1589943937267290112",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589943929876537344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943927997882368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943916065083396",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943911673626624",
   "Positive": "0.001539492281153798",
   "Negative": "0.6096404790878296",
   "Neutral": "0.3886958956718445",
   "Mixed": "0.0001240969868376851"
 },
 {
   "tweet_id": "1589943904052596742",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943897576591360",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943896804855809",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943895676588032",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589943895017799684",
   "Positive": "0.012987390160560608",
   "Negative": "0.0026658866554498672",
   "Neutral": "0.9843404293060303",
   "Mixed": "6.310152457444929e-06"
 },
 {
   "tweet_id": "1589943885283078146",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943884947546112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943873727787008",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589943869969661952",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589943869428633600",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589943861983707137",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943860259848193",
   "Positive": "0.057149577885866165",
   "Negative": "0.5926494598388672",
   "Neutral": "0.3452366888523102",
   "Mixed": "0.004964249208569527"
 },
 {
   "tweet_id": "1589943857906855936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943833152098304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943819445088256",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943817448620033",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943800369074179",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943797064298498",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943785970364416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943776268939267",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943773580365824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943766164852737",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943764227063808",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943758233407488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943750301978626",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589943748582330368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943745713426432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943742978732032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943740189519872",
   "Positive": "0.02627798356115818",
   "Negative": "0.600878119468689",
   "Neutral": "0.37019655108451843",
   "Mixed": "0.0026473060715943575"
 },
 {
   "tweet_id": "1589943739770097666",
   "Positive": "0.16832667589187622",
   "Negative": "0.11202206462621689",
   "Neutral": "0.7194197177886963",
   "Mixed": "0.0002315154706593603"
 },
 {
   "tweet_id": "1589943731742212096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943727879254016",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943723760443393",
   "Positive": "0.04451413452625275",
   "Negative": "0.36218777298927307",
   "Neutral": "0.5808576345443726",
   "Mixed": "0.012440462596714497"
 },
 {
   "tweet_id": "1589943720182706178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943706911924224",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589943698246479873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943687668469760",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943680726884352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943671830769664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943669817487361",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943651232526336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943650859257856",
   "Positive": "0.9710630774497986",
   "Negative": "0.0013610355090349913",
   "Neutral": "0.027489807456731796",
   "Mixed": "8.610331860836595e-05"
 },
 {
   "tweet_id": "1589943636573458434",
   "Positive": "0.0031589309219270945",
   "Negative": "0.0002836497442331165",
   "Neutral": "0.9965536594390869",
   "Mixed": "3.858029231196269e-06"
 },
 {
   "tweet_id": "1589943603904016384",
   "Positive": "0.4063858091831207",
   "Negative": "0.0010524177923798561",
   "Neutral": "0.5922632217407227",
   "Mixed": "0.00029847651603631675"
 },
 {
   "tweet_id": "1589943599017656321",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943596794662913",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943593744990208",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943583561637889",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589943578977263616",
   "Positive": "0.003073975909501314",
   "Negative": "0.03818764537572861",
   "Neutral": "0.9587276577949524",
   "Mixed": "1.074024385161465e-05"
 },
 {
   "tweet_id": "1589943577706012672",
   "Positive": "0.012339561246335506",
   "Negative": "0.06214273348450661",
   "Neutral": "0.9254393577575684",
   "Mixed": "7.828533125575632e-05"
 },
 {
   "tweet_id": "1589943574799712256",
   "Positive": "0.0010815856512635946",
   "Negative": "0.00019715797679964453",
   "Neutral": "0.9987185001373291",
   "Mixed": "2.721423470575246e-06"
 },
 {
   "tweet_id": "1589943564598841344",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943560639774720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943541664747521",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943541073313792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943540469366785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943536757379073",
   "Positive": "0.03193483129143715",
   "Negative": "0.15670672059059143",
   "Neutral": "0.8112761378288269",
   "Mixed": "8.230778621509671e-05"
 },
 {
   "tweet_id": "1589943521636937729",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589943514607267841",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943512560439296",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589943511423778817",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943493799342085",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943491899330560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943487264616448",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943477391233024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943466620256257",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943465529704448",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589943464648900608",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943462283333632",
   "Positive": "0.0009433111408725381",
   "Negative": "0.0003716358041856438",
   "Neutral": "0.9986820816993713",
   "Mixed": "2.953611556222313e-06"
 },
 {
   "tweet_id": "1589943450761580544",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943442310037507",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943433107382272",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943428078768130",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589943419761483777",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943416929931270",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943410152329216",
   "Positive": "0.1340155303478241",
   "Negative": "0.0020756511949002743",
   "Neutral": "0.8638861775398254",
   "Mixed": "2.2638563677901402e-05"
 },
 {
   "tweet_id": "1589943403231711233",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943402665504769",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943397401624576",
   "Positive": "0.0411798469722271",
   "Negative": "0.0489790253341198",
   "Neutral": "0.9095134139060974",
   "Mixed": "0.0003276195493526757"
 },
 {
   "tweet_id": "1589943396185276417",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943395124117504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943388878802945",
   "Positive": "0.16871243715286255",
   "Negative": "0.04849807545542717",
   "Neutral": "0.7825578451156616",
   "Mixed": "0.00023166903702076524"
 },
 {
   "tweet_id": "1589943387758923776",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943378325966848",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589943353483096066",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589943348227633157",
   "Positive": "0.0055994573049247265",
   "Negative": "0.020861569792032242",
   "Neutral": "0.9735244512557983",
   "Mixed": "1.4541376913257409e-05"
 },
 {
   "tweet_id": "1589943339226640384",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943336928161797",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589943333765644288",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943314736123904",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943307391897601",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589943299821178883",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943292812496902",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943288974696449",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943268166733824",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589943255609008132",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589943247811809281",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589943244192100352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943240052166656",
   "Positive": "0.00428718002513051",
   "Negative": "0.00046979988110251725",
   "Neutral": "0.9952405691146851",
   "Mixed": "2.5074109544220846e-06"
 },
 {
   "tweet_id": "1589943224738942977",
   "Positive": "0.07905875891447067",
   "Negative": "0.22529350221157074",
   "Neutral": "0.6955870985984802",
   "Mixed": "6.059240331524052e-05"
 },
 {
   "tweet_id": "1589943202702045185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943194648989697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943192358891520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943181306900480",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943178836443137",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589943170028425216",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589943165787963393",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943159374876672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943147928645633",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943122414698497",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589943118933020672",
   "Positive": "0.3044956922531128",
   "Negative": "0.0009601443889550865",
   "Neutral": "0.694527804851532",
   "Mixed": "1.6407986549893394e-05"
 },
 {
   "tweet_id": "1589943116487831554",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943102512713729",
   "Positive": "0.035222720354795456",
   "Negative": "0.021510986611247063",
   "Neutral": "0.9432458281517029",
   "Mixed": "2.0419809516170062e-05"
 },
 {
   "tweet_id": "1589943096845869057",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943086444343296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943077879558144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943074226331649",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589943072577978368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943070338224129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943067791880192",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589943064164184064",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589943051660963841",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943047688945668",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943026264121345",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589943015468339206",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589943011722821633",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942995272757250",
   "Positive": "0.0017484166892245412",
   "Negative": "0.1901106983423233",
   "Neutral": "0.8080411553382874",
   "Mixed": "9.968625090550631e-05"
 },
 {
   "tweet_id": "1589942985533591553",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942983168000001",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942979405713408",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942972829028352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942966990565377",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942950616010753",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942947109568512",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942945687695360",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942944513290240",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942932383334400",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942926679117825",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942917560668160",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942914024869889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942897172185090",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942882097844224",
   "Positive": "0.296925812959671",
   "Negative": "0.00023777794558554888",
   "Neutral": "0.7027232050895691",
   "Mixed": "0.00011319683835608885"
 },
 {
   "tweet_id": "1589942862346846208",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942852163108867",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589942849176735744",
   "Positive": "0.41524839401245117",
   "Negative": "0.0029968456365168095",
   "Neutral": "0.5816164612770081",
   "Mixed": "0.00013823015615344048"
 },
 {
   "tweet_id": "1589942847444496386",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589942839399813120",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589942837059035136",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942836069531648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942830424035329",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942826259054592",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942822287060992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942819460120578",
   "Positive": "0.13724856078624725",
   "Negative": "0.00020969158504158258",
   "Neutral": "0.8624894618988037",
   "Mixed": "5.222309846431017e-05"
 },
 {
   "tweet_id": "1589942819049050115",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942813352787968",
   "Positive": "0.9783689975738525",
   "Negative": "0.0002797125489450991",
   "Neutral": "0.02131391316652298",
   "Mixed": "3.743115667020902e-05"
 },
 {
   "tweet_id": "1589942811117649924",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942795498057728",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942790548439042",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942781816221696",
   "Positive": "0.012339561246335506",
   "Negative": "0.06214273348450661",
   "Neutral": "0.9254393577575684",
   "Mixed": "7.828533125575632e-05"
 },
 {
   "tweet_id": "1589942778980880386",
   "Positive": "0.5002392530441284",
   "Negative": "0.0007300213328562677",
   "Neutral": "0.49748465418815613",
   "Mixed": "0.0015460493741557002"
 },
 {
   "tweet_id": "1589942765450067968",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589942752472870913",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942750031806464",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942748152725504",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942736601649152",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942735448211456",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942721472794624",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942716951326721",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942715319746560",
   "Positive": "0.03379867970943451",
   "Negative": "0.1656898409128189",
   "Neutral": "0.8003573417663574",
   "Mixed": "0.0001540792582090944"
 },
 {
   "tweet_id": "1589942712937381888",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589942711402258434",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942710819229698",
   "Positive": "0.004625371657311916",
   "Negative": "0.00011067361629102379",
   "Neutral": "0.995252251625061",
   "Mixed": "1.1779655324062333e-05"
 },
 {
   "tweet_id": "1589942685603094529",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942679756222464",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942666640633857",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942665273298945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942659959123971",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942654544281601",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942652040093696",
   "Positive": "0.004148687701672316",
   "Negative": "8.352856093551964e-05",
   "Neutral": "0.9957601428031921",
   "Mixed": "7.537771580246044e-06"
 },
 {
   "tweet_id": "1589942649154572288",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942644180144128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942642619875328",
   "Positive": "0.00044961474486626685",
   "Negative": "0.00023387429246213287",
   "Neutral": "0.9993093013763428",
   "Mixed": "7.251574515976245e-06"
 },
 {
   "tweet_id": "1589942635359539201",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942616694870016",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942604333953029",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942601519886336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942592137211911",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942568800116737",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942566900076545",
   "Positive": "0.0026983425486832857",
   "Negative": "0.5324102640151978",
   "Neutral": "0.4647727906703949",
   "Mixed": "0.00011858889774885029"
 },
 {
   "tweet_id": "1589942560486985728",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942556116516865",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942545223921665",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942527008071682",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942515809288193",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589942515440168960",
   "Positive": "0.0016950437566265464",
   "Negative": "7.472118159057572e-05",
   "Neutral": "0.9982231259346008",
   "Mixed": "7.137506145227235e-06"
 },
 {
   "tweet_id": "1589942515054309376",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942513217204225",
   "Positive": "0.34213316440582275",
   "Negative": "0.004904046189039946",
   "Neutral": "0.6514317393302917",
   "Mixed": "0.0015309841837733984"
 },
 {
   "tweet_id": "1589942513204613122",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942508964179973",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942498486812672",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1589942490303725568",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589942444204130307",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942440102105088",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942419046699008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942418719526914",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589942413363408902",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942394912641024",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942384322056192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942380429733888",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589942379867701248",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589942379762839552",
   "Positive": "0.002165835117921233",
   "Negative": "0.000466247001895681",
   "Neutral": "0.9973655343055725",
   "Mixed": "2.3289414912142092e-06"
 },
 {
   "tweet_id": "1589942370514370561",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942367184097281",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589942365720309761",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942360972341249",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942355725266946",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942340504137729",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589942321210359808",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942321034199040",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942317502586883",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942306458992642",
   "Positive": "0.0009611847344785929",
   "Negative": "0.00022348342463374138",
   "Neutral": "0.9988127946853638",
   "Mixed": "2.4604426016594516e-06"
 },
 {
   "tweet_id": "1589942300352077824",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942287324565507",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942280139350016",
   "Positive": "0.007186670321971178",
   "Negative": "0.0005029423628002405",
   "Neutral": "0.9923073649406433",
   "Mixed": "3.0498795240418985e-06"
 },
 {
   "tweet_id": "1589942270555324417",
   "Positive": "0.004902326036244631",
   "Negative": "0.004019385669380426",
   "Neutral": "0.9907429814338684",
   "Mixed": "0.0003352701314724982"
 },
 {
   "tweet_id": "1589942268622172161",
   "Positive": "0.9785692691802979",
   "Negative": "7.92767241364345e-05",
   "Neutral": "0.021307537332177162",
   "Mixed": "4.387179797049612e-05"
 },
 {
   "tweet_id": "1589942261940649985",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942261940297729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942252197253121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942243829248001",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942234769551360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942224934297600",
   "Positive": "0.02477995492517948",
   "Negative": "0.0002710697299335152",
   "Neutral": "0.9749348759651184",
   "Mixed": "1.4108120922173839e-05"
 },
 {
   "tweet_id": "1589942216851877890",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942214586949632",
   "Positive": "0.004562764894217253",
   "Negative": "0.0001016739770420827",
   "Neutral": "0.9953272342681885",
   "Mixed": "8.297331078210846e-06"
 },
 {
   "tweet_id": "1589942212737261568",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942190616109057",
   "Positive": "0.005703248083591461",
   "Negative": "0.24744334816932678",
   "Neutral": "0.5776506066322327",
   "Mixed": "0.1692027747631073"
 },
 {
   "tweet_id": "1589942181128998914",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942175974187008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942172526444547",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942164439830528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942160279113729",
   "Positive": "0.0002220566093456",
   "Negative": "0.0025009331293404102",
   "Neutral": "0.9972708821296692",
   "Mixed": "6.03621629124973e-06"
 },
 {
   "tweet_id": "1589942158345523200",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942157787688960",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942151345246209",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942135939248129",
   "Positive": "0.42822951078414917",
   "Negative": "0.002339592669159174",
   "Neutral": "0.5691885948181152",
   "Mixed": "0.00024234026204794645"
 },
 {
   "tweet_id": "1589942127857139713",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942122131886082",
   "Positive": "0.23547038435935974",
   "Negative": "0.0006930161616764963",
   "Neutral": "0.7615951895713806",
   "Mixed": "0.002241404727101326"
 },
 {
   "tweet_id": "1589942121494376449",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589942120806502403",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942118117945344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942110111027201",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589942107149545472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942084554801152",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942078649556992",
   "Positive": "0.3470117747783661",
   "Negative": "0.027775024995207787",
   "Neutral": "0.4667186737060547",
   "Mixed": "0.15849453210830688"
 },
 {
   "tweet_id": "1589942075415576578",
   "Positive": "0.8842697143554688",
   "Negative": "0.00023124940344132483",
   "Neutral": "0.11545516550540924",
   "Mixed": "4.382063343655318e-05"
 },
 {
   "tweet_id": "1589942075067596804",
   "Positive": "0.5680267214775085",
   "Negative": "0.0018391124904155731",
   "Neutral": "0.4300393760204315",
   "Mixed": "9.47788284975104e-05"
 },
 {
   "tweet_id": "1589942073545084928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942072299376640",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942072240242688",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589942070319681537",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589942069082337281",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589942064388927490",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589942053848616960",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589942035565666305",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942020730417152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942015453978625",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589942014908723208",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589942011557474304",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941998659985408",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589941997565247489",
   "Positive": "0.44690442085266113",
   "Negative": "0.0009065056219696999",
   "Neutral": "0.5521694421768188",
   "Mixed": "1.9647615772555582e-05"
 },
 {
   "tweet_id": "1589941997007036416",
   "Positive": "0.1522538959980011",
   "Negative": "0.0037573962472379208",
   "Neutral": "0.8439674973487854",
   "Mixed": "2.122242767654825e-05"
 },
 {
   "tweet_id": "1589941996923199490",
   "Positive": "0.00045004714047536254",
   "Negative": "0.00010173723421758041",
   "Neutral": "0.9994431138038635",
   "Mixed": "5.129124929226236e-06"
 },
 {
   "tweet_id": "1589941989646426113",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941984491634688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941957656477697",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941935170818048",
   "Positive": "0.9326335787773132",
   "Negative": "9.18694568099454e-05",
   "Neutral": "0.06721898913383484",
   "Mixed": "5.556753967539407e-05"
 },
 {
   "tweet_id": "1589941932595499011",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941925779755008",
   "Positive": "0.022728968411684036",
   "Negative": "0.0411364920437336",
   "Neutral": "0.9361079931259155",
   "Mixed": "2.6583729777485132e-05"
 },
 {
   "tweet_id": "1589941921233121280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941916015431680",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589941913289109504",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941903176630272",
   "Positive": "0.5914236307144165",
   "Negative": "0.0010093161836266518",
   "Neutral": "0.4073342978954315",
   "Mixed": "0.00023272857652045786"
 },
 {
   "tweet_id": "1589941897820536832",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941887896408064",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941867424415744",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941864974942211",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941861443305472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941855894253568",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941853436403713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941849762201601",
   "Positive": "0.0063577210530638695",
   "Negative": "0.00015614030417054892",
   "Neutral": "0.993476927280426",
   "Mixed": "9.203852641803678e-06"
 },
 {
   "tweet_id": "1589941848239673345",
   "Positive": "0.011532362550497055",
   "Negative": "0.00013138882059138268",
   "Neutral": "0.9882891178131104",
   "Mixed": "4.7083904064493254e-05"
 },
 {
   "tweet_id": "1589941845840519168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941840551505921",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941838349496322",
   "Positive": "0.04658491164445877",
   "Negative": "0.06519607454538345",
   "Neutral": "0.8876991868019104",
   "Mixed": "0.0005197818973101676"
 },
 {
   "tweet_id": "1589941827976957953",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941823006703616",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589941822716973057",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941812697104386",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941801414459393",
   "Positive": "0.002854415914043784",
   "Negative": "0.00024272182781714946",
   "Neutral": "0.9968982934951782",
   "Mixed": "4.570620149024762e-06"
 },
 {
   "tweet_id": "1589941801271848962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941796473536514",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941782854656001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941757768511489",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941756283715584",
   "Positive": "0.011532362550497055",
   "Negative": "0.00013138882059138268",
   "Neutral": "0.9882891178131104",
   "Mixed": "4.7083904064493254e-05"
 },
 {
   "tweet_id": "1589941751066025995",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941748595580928",
   "Positive": "0.002727766754105687",
   "Negative": "0.14892727136611938",
   "Neutral": "0.8476366996765137",
   "Mixed": "0.0007082694792188704"
 },
 {
   "tweet_id": "1589941746045419520",
   "Positive": "0.04658491164445877",
   "Negative": "0.06519607454538345",
   "Neutral": "0.8876991868019104",
   "Mixed": "0.0005197818973101676"
 },
 {
   "tweet_id": "1589941743512096770",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941740345360385",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589941726650986496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941726348980225",
   "Positive": "0.2956930994987488",
   "Negative": "0.00037415369297377765",
   "Neutral": "0.703904926776886",
   "Mixed": "2.7822843549074605e-05"
 },
 {
   "tweet_id": "1589941721198100480",
   "Positive": "0.8766727447509766",
   "Negative": "0.0005325977108441293",
   "Neutral": "0.12273678183555603",
   "Mixed": "5.79333609493915e-05"
 },
 {
   "tweet_id": "1589941716638830594",
   "Positive": "0.8389030694961548",
   "Negative": "0.0010108130518347025",
   "Neutral": "0.15985991060733795",
   "Mixed": "0.00022614054614678025"
 },
 {
   "tweet_id": "1589941713938055168",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589941705746546688",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941691951501315",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941681180545024",
   "Positive": "0.44626596570014954",
   "Negative": "0.03377930819988251",
   "Neutral": "0.5189307332038879",
   "Mixed": "0.0010239089606329799"
 },
 {
   "tweet_id": "1589941681155346432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941676520656898",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941675014909953",
   "Positive": "0.3001067042350769",
   "Negative": "0.0007227406604215503",
   "Neutral": "0.6990838050842285",
   "Mixed": "8.672998956171796e-05"
 },
 {
   "tweet_id": "1589941659407888384",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941658933948416",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589941649920360450",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941648817262592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941643717017602",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941632908263427",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941614885351424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941605955702785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941604361850884",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589941594836598786",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941594610077698",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589941581360267265",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941577169805312",
   "Positive": "0.00814485177397728",
   "Negative": "0.6043165922164917",
   "Neutral": "0.3869783282279968",
   "Mixed": "0.0005601695738732815"
 },
 {
   "tweet_id": "1589941571427962882",
   "Positive": "0.25174573063850403",
   "Negative": "0.0011304969666525722",
   "Neutral": "0.7470964789390564",
   "Mixed": "2.723750912991818e-05"
 },
 {
   "tweet_id": "1589941563098267648",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941560858509318",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941548384649218",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941541162057729",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941534853853185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941532920270851",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941532840587264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941528986021888",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589941526704304129",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589941511265095681",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941498929238017",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941487605010433",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589941486413807616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941483653967873",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941471310118912",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941471255613441",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589941468768382976",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941468650610688",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941468441247745",
   "Positive": "0.07860453426837921",
   "Negative": "0.006498741917312145",
   "Neutral": "0.9148793816566467",
   "Mixed": "1.7367443433613516e-05"
 },
 {
   "tweet_id": "1589941458710429697",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589941455774429184",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941454134472710",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941450833559552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941449797554176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941448627326976",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941430969315328",
   "Positive": "0.1111096516251564",
   "Negative": "0.3223457336425781",
   "Neutral": "0.565706193447113",
   "Mixed": "0.0008383673266507685"
 },
 {
   "tweet_id": "1589941430134661121",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941424145170432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941420148002816",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941401433018368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941395456151552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941386530664448",
   "Positive": "0.09663856774568558",
   "Negative": "0.0054983836598694324",
   "Neutral": "0.8941217064857483",
   "Mixed": "0.003741299267858267"
 },
 {
   "tweet_id": "1589941383594672128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941367530467330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941351759908867",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941345707118592",
   "Positive": "0.4262436032295227",
   "Negative": "0.02258390188217163",
   "Neutral": "0.5510089993476868",
   "Mixed": "0.00016353139653801918"
 },
 {
   "tweet_id": "1589941344382115840",
   "Positive": "0.4084622263908386",
   "Negative": "0.016541315242648125",
   "Neutral": "0.5749639868736267",
   "Mixed": "3.257462230976671e-05"
 },
 {
   "tweet_id": "1589941331736297472",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941329337135105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941326971162625",
   "Positive": "0.5015510320663452",
   "Negative": "0.000997473718598485",
   "Neutral": "0.49739181995391846",
   "Mixed": "5.957735265837982e-05"
 },
 {
   "tweet_id": "1589941297435258880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941292557271043",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941281236848641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941271548039169",
   "Positive": "0.003602382028475404",
   "Negative": "0.38332441449165344",
   "Neutral": "0.6130500435829163",
   "Mixed": "2.319694249308668e-05"
 },
 {
   "tweet_id": "1589941265772474368",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941263637573632",
   "Positive": "0.0003782654239330441",
   "Negative": "0.00024106130877044052",
   "Neutral": "0.9993730187416077",
   "Mixed": "7.63287243898958e-06"
 },
 {
   "tweet_id": "1589941257358708736",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589941254493986817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941253621559297",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941229323698176",
   "Positive": "0.0007228441536426544",
   "Negative": "0.00013648494496010244",
   "Neutral": "0.9991292357444763",
   "Mixed": "1.1463022019597702e-05"
 },
 {
   "tweet_id": "1589941225591050240",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589941223439339520",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941221082161152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941205064101888",
   "Positive": "0.006565578281879425",
   "Negative": "0.6250286102294922",
   "Neutral": "0.3680296242237091",
   "Mixed": "0.000376212497940287"
 },
 {
   "tweet_id": "1589941202341990400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941174294712321",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941163905413122",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589941155013488641",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589941151389605888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941133672878080",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941133005975552",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941124202106880",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941123984023552",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941123807875072",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941121354199047",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941107386945537",
   "Positive": "0.32714152336120605",
   "Negative": "0.00115483021363616",
   "Neutral": "0.671582818031311",
   "Mixed": "0.00012081631575711071"
 },
 {
   "tweet_id": "1589941106032381952",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941092971327488",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589941089452314626",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941089401974786",
   "Positive": "0.020580701529979706",
   "Negative": "0.00032122188713401556",
   "Neutral": "0.9790654182434082",
   "Mixed": "3.266062049078755e-05"
 },
 {
   "tweet_id": "1589941080501661696",
   "Positive": "0.057319775223731995",
   "Negative": "0.08048516511917114",
   "Neutral": "0.8620384931564331",
   "Mixed": "0.00015646983229089528"
 },
 {
   "tweet_id": "1589941067495149573",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941065783836673",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589941056636071937",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941047114993665",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941046095785984",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941039582019585",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941023777910785",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941023727230978",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589941018484674561",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589941015070527490",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589941012591702016",
   "Positive": "0.05324863642454147",
   "Negative": "0.00647079898044467",
   "Neutral": "0.9402709007263184",
   "Mixed": "9.602228601579554e-06"
 },
 {
   "tweet_id": "1589940992849084420",
   "Positive": "0.10324003547430038",
   "Negative": "0.13912208378314972",
   "Neutral": "0.7564481496810913",
   "Mixed": "0.001189777860417962"
 },
 {
   "tweet_id": "1589940992790392834",
   "Positive": "0.4261930584907532",
   "Negative": "0.008815368637442589",
   "Neutral": "0.5649672746658325",
   "Mixed": "2.4300790755660273e-05"
 },
 {
   "tweet_id": "1589940990764515329",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589940987128090625",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589940984804421632",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940979997773824",
   "Positive": "0.32742613554000854",
   "Negative": "0.0001596778747625649",
   "Neutral": "0.6723572015762329",
   "Mixed": "5.6992052122950554e-05"
 },
 {
   "tweet_id": "1589940963799339008",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589940960162897920",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589940933248028672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940912901492738",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940876780122112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940874880102400",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940840964968448",
   "Positive": "0.002651910064741969",
   "Negative": "0.0003647889825515449",
   "Neutral": "0.9969809651374817",
   "Mixed": "2.293916395501583e-06"
 },
 {
   "tweet_id": "1589940829199925248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940818122788864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940797545541632",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589940774871130112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940772056760326",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940755334037506",
   "Positive": "0.6856787204742432",
   "Negative": "0.001155958860181272",
   "Neutral": "0.31314483284950256",
   "Mixed": "2.0474320990615524e-05"
 },
 {
   "tweet_id": "1589940744886038529",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940737399193603",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940732445720577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940728385662978",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589940728138199040",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940713416175617",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589940713076445184",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589940712132734976",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940710593433600",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940681229078528",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940671603146754",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940663394906113",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940655283113985",
   "Positive": "0.0006865971954539418",
   "Negative": "7.194550562417135e-05",
   "Neutral": "0.9992361068725586",
   "Mixed": "5.303398211253807e-06"
 },
 {
   "tweet_id": "1589940649910243329",
   "Positive": "0.0013528885319828987",
   "Negative": "9.807893366087228e-05",
   "Neutral": "0.9985450506210327",
   "Mixed": "4.014802016172325e-06"
 },
 {
   "tweet_id": "1589940645598482433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940644973539330",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940632197689344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940623871995906",
   "Positive": "0.04445037990808487",
   "Negative": "7.164688577177003e-05",
   "Neutral": "0.9553816318511963",
   "Mixed": "9.63942293310538e-05"
 },
 {
   "tweet_id": "1589940616850722817",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940609678073858",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589940607342227456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940603428966401",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940597292691456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940595744989185",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589940594184687616",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940591710064641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940568574267392",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589940561162940418",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940552019365888",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940551524417536",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940522491482112",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589940514635526144",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940513490489344",
   "Positive": "0.4296351969242096",
   "Negative": "0.0022477952297776937",
   "Neutral": "0.5679575204849243",
   "Mixed": "0.00015945357154123485"
 },
 {
   "tweet_id": "1589940508805459968",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940506108526592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940506095943686",
   "Positive": "0.9702247977256775",
   "Negative": "0.0003104911302216351",
   "Neutral": "0.029297929257154465",
   "Mixed": "0.00016674131620675325"
 },
 {
   "tweet_id": "1589940501897433089",
   "Positive": "0.003452617209404707",
   "Negative": "0.6356093883514404",
   "Neutral": "0.36057496070861816",
   "Mixed": "0.00036300040665082633"
 },
 {
   "tweet_id": "1589940481299206144",
   "Positive": "0.2648065388202667",
   "Negative": "0.002311142161488533",
   "Neutral": "0.7328115105628967",
   "Mixed": "7.071831350913271e-05"
 },
 {
   "tweet_id": "1589940476991668226",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940471006396416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940465822232579",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940465456947200",
   "Positive": "0.0016922038048505783",
   "Negative": "0.0010219007963314652",
   "Neutral": "0.9972836971282959",
   "Mixed": "2.1947762434137985e-06"
 },
 {
   "tweet_id": "1589940465389883392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940453247705089",
   "Positive": "0.0411798469722271",
   "Negative": "0.0489790253341198",
   "Neutral": "0.9095134139060974",
   "Mixed": "0.0003276195493526757"
 },
 {
   "tweet_id": "1589940450001309696",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940445664378880",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589940440354394112",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589940427553402884",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589940426685157383",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940386956738560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940385497116672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940385291579392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940382343000066",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940380354904070",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940360226435075",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589940352404062211",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940342971076609",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940341641449473",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589940341171372033",
   "Positive": "0.0016806669300422072",
   "Negative": "6.834703526692465e-05",
   "Neutral": "0.9982396364212036",
   "Mixed": "1.1251066098338924e-05"
 },
 {
   "tweet_id": "1589940338369921024",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940335937216512",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589940328991449089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940325929594880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940322804678656",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589940322745851905",
   "Positive": "0.9567381143569946",
   "Negative": "0.00022302432626020163",
   "Neutral": "0.04300056770443916",
   "Mixed": "3.8320777093758807e-05"
 },
 {
   "tweet_id": "1589940316232384513",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589940300998668290",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589940296204578816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940295432826880",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940294438752256",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940289522716672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940288331853824",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940279653851142",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940273643409409",
   "Positive": "0.8973276019096375",
   "Negative": "0.00018056671251542866",
   "Neutral": "0.10243823379278183",
   "Mixed": "5.361407238524407e-05"
 },
 {
   "tweet_id": "1589940268291489798",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589940257478541312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940254504816641",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940239782797312",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940225786392576",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940208216444928",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589940196615016448",
   "Positive": "0.9448797106742859",
   "Negative": "0.0009148419485427439",
   "Neutral": "0.05416557192802429",
   "Mixed": "3.986064621130936e-05"
 },
 {
   "tweet_id": "1589940170786492418",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940159554150401",
   "Positive": "0.06066468730568886",
   "Negative": "0.0009143027709797025",
   "Neutral": "0.9383260607719421",
   "Mixed": "9.494916594121605e-05"
 },
 {
   "tweet_id": "1589940152079876098",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940120773591041",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940115698487296",
   "Positive": "0.07688906043767929",
   "Negative": "0.03326261416077614",
   "Neutral": "0.22193244099617004",
   "Mixed": "0.667915940284729"
 },
 {
   "tweet_id": "1589940114968711168",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940113987231745",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940100787736577",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940095091888128",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940095033147393",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940079132573696",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589940073864515585",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589940067925204995",
   "Positive": "0.026239929720759392",
   "Negative": "0.00019417058501858264",
   "Neutral": "0.9735571146011353",
   "Mixed": "8.769779014983214e-06"
 },
 {
   "tweet_id": "1589940059595501569",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940052179972096",
   "Positive": "0.05114184319972992",
   "Negative": "0.7373767495155334",
   "Neutral": "0.21057391166687012",
   "Mixed": "0.0009075287380255759"
 },
 {
   "tweet_id": "1589940050900680704",
   "Positive": "0.08476722985506058",
   "Negative": "0.0016269894549623132",
   "Neutral": "0.9135910868644714",
   "Mixed": "1.4687589100503828e-05"
 },
 {
   "tweet_id": "1589940045951438852",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940044592451587",
   "Positive": "0.0005631267558783293",
   "Negative": "0.00030314698233269155",
   "Neutral": "0.9991313815116882",
   "Mixed": "2.387071390330675e-06"
 },
 {
   "tweet_id": "1589940035448885250",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589940034555498496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940027898793985",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589940015525920773",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940012380217344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589940004591370241",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940002581979136",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589940000317411329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939999101026304",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939996366352385",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939969938055168",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939968017051648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939965214851072",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939957388693504",
   "Positive": "0.1879420280456543",
   "Negative": "0.00047391600674018264",
   "Neutral": "0.8115584850311279",
   "Mixed": "2.5544222808093764e-05"
 },
 {
   "tweet_id": "1589939955585159170",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589939951369871366",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939937012776960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939936161300480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939935544737792",
   "Positive": "0.003602382028475404",
   "Negative": "0.38332441449165344",
   "Neutral": "0.6130500435829163",
   "Mixed": "2.319694249308668e-05"
 },
 {
   "tweet_id": "1589939931723415552",
   "Positive": "0.002165835117921233",
   "Negative": "0.000466247001895681",
   "Neutral": "0.9973655343055725",
   "Mixed": "2.3289414912142092e-06"
 },
 {
   "tweet_id": "1589939927357485056",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939922932502528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939919346339840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939912547368962",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589939893790470145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939884646883329",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939872529534976",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589939866976268288",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939864212066306",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589939864057049088",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939840883511296",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939838295642115",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939836160548865",
   "Positive": "0.30439767241477966",
   "Negative": "0.0037228171713650227",
   "Neutral": "0.6915329098701477",
   "Mixed": "0.0003465853224042803"
 },
 {
   "tweet_id": "1589939834260721665",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939822910930946",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589939818641125376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939816850132993",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939810466410496",
   "Positive": "0.005011141300201416",
   "Negative": "0.8615722060203552",
   "Neutral": "0.12696605920791626",
   "Mixed": "0.0064507052302360535"
 },
 {
   "tweet_id": "1589939808453152768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939806859333632",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589939804523073543",
   "Positive": "0.29377996921539307",
   "Negative": "0.0009157704189419746",
   "Neutral": "0.7052551507949829",
   "Mixed": "4.910063216811977e-05"
 },
 {
   "tweet_id": "1589939797627670528",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589939793772744704",
   "Positive": "0.0015715914778411388",
   "Negative": "0.8297667503356934",
   "Neutral": "0.16860245168209076",
   "Mixed": "5.921868796576746e-05"
 },
 {
   "tweet_id": "1589939784822435840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939778556170240",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589939751062495232",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939748847890433",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939736675684352",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939732938915840",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939720452472832",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939720024625153",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939719978164224",
   "Positive": "0.00012474440154619515",
   "Negative": "0.007314616348594427",
   "Neutral": "0.9925537705421448",
   "Mixed": "6.8480708250717726e-06"
 },
 {
   "tweet_id": "1589939719441649664",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589939705352982528",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939686843510784",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939685400678408",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939670355697665",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939658884288514",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939654098563072",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939652269871105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939645936435206",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939644422320129",
   "Positive": "0.030534442514181137",
   "Negative": "0.515034556388855",
   "Neutral": "0.4539104104042053",
   "Mixed": "0.000520559202414006"
 },
 {
   "tweet_id": "1589939639024246790",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939631927492610",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939622582575104",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939614277861377",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939613795487749",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939605897633792",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939599224504324",
   "Positive": "0.004614624660462141",
   "Negative": "0.6230566501617432",
   "Neutral": "0.3619130551815033",
   "Mixed": "0.010415608994662762"
 },
 {
   "tweet_id": "1589939585877843968",
   "Positive": "0.9976296424865723",
   "Negative": "6.00123566982802e-05",
   "Neutral": "0.002297318773344159",
   "Mixed": "1.2906215488328598e-05"
 },
 {
   "tweet_id": "1589939579519668225",
   "Positive": "0.9356949329376221",
   "Negative": "0.00035355548607185483",
   "Neutral": "0.06387429684400558",
   "Mixed": "7.713113882346079e-05"
 },
 {
   "tweet_id": "1589939573190459392",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939567456825345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939551757537284",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589939538499026944",
   "Positive": "0.00011218783765798435",
   "Negative": "0.002653125673532486",
   "Neutral": "0.9972296357154846",
   "Mixed": "4.9501431931275874e-06"
 },
 {
   "tweet_id": "1589939536859398144",
   "Positive": "0.016689354553818703",
   "Negative": "0.00010996941273333505",
   "Neutral": "0.9831777215003967",
   "Mixed": "2.3001213776296936e-05"
 },
 {
   "tweet_id": "1589939525387943936",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939521126559745",
   "Positive": "0.016689354553818703",
   "Negative": "0.00010996941273333505",
   "Neutral": "0.9831777215003967",
   "Mixed": "2.3001213776296936e-05"
 },
 {
   "tweet_id": "1589939505540517889",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939499844308992",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589939493649657856",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939490738835456",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939480055537664",
   "Positive": "0.002727554179728031",
   "Negative": "0.9605790376663208",
   "Neutral": "0.03663139417767525",
   "Mixed": "6.210889114299789e-05"
 },
 {
   "tweet_id": "1589939477614833664",
   "Positive": "0.9790955781936646",
   "Negative": "0.00018329874728806317",
   "Neutral": "0.02068435586988926",
   "Mixed": "3.6846427974523976e-05"
 },
 {
   "tweet_id": "1589939476368920576",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589939476305952770",
   "Positive": "0.9567381143569946",
   "Negative": "0.00022302432626020163",
   "Neutral": "0.04300056770443916",
   "Mixed": "3.8320777093758807e-05"
 },
 {
   "tweet_id": "1589939460623728641",
   "Positive": "0.07023803144693375",
   "Negative": "0.00255954684689641",
   "Neutral": "0.9271785616874695",
   "Mixed": "2.3887378119979985e-05"
 },
 {
   "tweet_id": "1589939451589189633",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939450863579137",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939441900335105",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939431490080768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939425483841536",
   "Positive": "0.04553493484854698",
   "Negative": "0.09814038127660751",
   "Neutral": "0.28038501739501953",
   "Mixed": "0.5759396553039551"
 },
 {
   "tweet_id": "1589939422874959874",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939397822418945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939390192963584",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589939379245809664",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939360996397056",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939355116015626",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939343946584064",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939323322843138",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939306130739201",
   "Positive": "0.0021321377716958523",
   "Negative": "0.00044991218601353467",
   "Neutral": "0.9974121451377869",
   "Mixed": "5.852128197147977e-06"
 },
 {
   "tweet_id": "1589939301374390272",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939290406289408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939289911328770",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939279983435776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939264564756480",
   "Positive": "0.0007420938927680254",
   "Negative": "0.0001773984549799934",
   "Neutral": "0.9990776777267456",
   "Mixed": "2.8134288641012972e-06"
 },
 {
   "tweet_id": "1589939264032493568",
   "Positive": "0.3063705563545227",
   "Negative": "0.011211269535124302",
   "Neutral": "0.6823449730873108",
   "Mixed": "7.32159533072263e-05"
 },
 {
   "tweet_id": "1589939262937772032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939254742110209",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589939252124872706",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939248777801732",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939248631021571",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939197405958145",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939191408123905",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589939176384139265",
   "Positive": "0.02390901930630207",
   "Negative": "0.001700776512734592",
   "Neutral": "0.9743847846984863",
   "Mixed": "5.432473699329421e-06"
 },
 {
   "tweet_id": "1589939166590414848",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939164568776704",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939160865206273",
   "Positive": "0.04718605801463127",
   "Negative": "0.008436311036348343",
   "Neutral": "0.9442901611328125",
   "Mixed": "8.745824015932158e-05"
 },
 {
   "tweet_id": "1589939155756519425",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939148458459136",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939146457755648",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939144532594689",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939143400128513",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589939135841996807",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939129596678145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939125180043265",
   "Positive": "0.02458060532808304",
   "Negative": "7.053206354612485e-05",
   "Neutral": "0.975328266620636",
   "Mixed": "2.0585870515787974e-05"
 },
 {
   "tweet_id": "1589939107983429633",
   "Positive": "0.4318067133426666",
   "Negative": "0.0023549748584628105",
   "Neutral": "0.5656939148902893",
   "Mixed": "0.00014440332597587258"
 },
 {
   "tweet_id": "1589939104938352640",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939102773698561",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939100895051776",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939091969568769",
   "Positive": "0.6341686844825745",
   "Negative": "0.0003835672396235168",
   "Neutral": "0.3650750517845154",
   "Mixed": "0.00037267678999342024"
 },
 {
   "tweet_id": "1589939090774192130",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939089629138945",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939080506138624",
   "Positive": "0.04718605801463127",
   "Negative": "0.008436311036348343",
   "Neutral": "0.9442901611328125",
   "Mixed": "8.745824015932158e-05"
 },
 {
   "tweet_id": "1589939076891054080",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939071711064065",
   "Positive": "0.1652025729417801",
   "Negative": "0.0002337265614187345",
   "Neutral": "0.8344069123268127",
   "Mixed": "0.00015680320211686194"
 },
 {
   "tweet_id": "1589939055965667328",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939055210377216",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589939049254592515",
   "Positive": "0.07874500006437302",
   "Negative": "0.000956122123170644",
   "Neutral": "0.9202916026115417",
   "Mixed": "7.240206286951434e-06"
 },
 {
   "tweet_id": "1589939014718881792",
   "Positive": "0.003602382028475404",
   "Negative": "0.38332441449165344",
   "Neutral": "0.6130500435829163",
   "Mixed": "2.319694249308668e-05"
 },
 {
   "tweet_id": "1589939011740585984",
   "Positive": "0.004465526435524225",
   "Negative": "0.00011693209671648219",
   "Neutral": "0.995406985282898",
   "Mixed": "1.0524139725021087e-05"
 },
 {
   "tweet_id": "1589939010105139200",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589939005289742336",
   "Positive": "0.10863291472196579",
   "Negative": "0.19815801084041595",
   "Neutral": "0.6923751831054688",
   "Mixed": "0.000833848665934056"
 },
 {
   "tweet_id": "1589939002014314496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589939001485832194",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938998654689280",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938991461462016",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938982602739712",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589938978924335104",
   "Positive": "0.0022797281853854656",
   "Negative": "0.003714259248226881",
   "Neutral": "0.9940020442008972",
   "Mixed": "3.885696514771553e-06"
 },
 {
   "tweet_id": "1589938967511986176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938961325379584",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938960159375360",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938954996187142",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938950223060992",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938950000775170",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938946276196355",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938942853668867",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938941180153856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938938030198784",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938918694490112",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938908246478848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938906522583043",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938902403809281",
   "Positive": "0.014424572698771954",
   "Negative": "0.0008779193158261478",
   "Neutral": "0.9846827387809753",
   "Mixed": "1.4830430700385477e-05"
 },
 {
   "tweet_id": "1589938901032275971",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938899929161730",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938899643928577",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589938899261997056",
   "Positive": "0.9567381143569946",
   "Negative": "0.00022302432626020163",
   "Neutral": "0.04300056770443916",
   "Mixed": "3.8320777093758807e-05"
 },
 {
   "tweet_id": "1589938898624712704",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938897085423619",
   "Positive": "0.15587832033634186",
   "Negative": "0.001021243748255074",
   "Neutral": "0.8430783748626709",
   "Mixed": "2.1984014892950654e-05"
 },
 {
   "tweet_id": "1589938896410152962",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938892995981313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938889862828032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938889296601089",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938888893927424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938883126784001",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938879851028480",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938876906606597",
   "Positive": "0.9609607458114624",
   "Negative": "6.871515506645665e-05",
   "Neutral": "0.038935475051403046",
   "Mixed": "3.501295577734709e-05"
 },
 {
   "tweet_id": "1589938871898632193",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938868086001664",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938866823233537",
   "Positive": "0.0009029615321196616",
   "Negative": "7.301176083274186e-05",
   "Neutral": "0.9990190267562866",
   "Mixed": "4.9613818191573955e-06"
 },
 {
   "tweet_id": "1589938865263218690",
   "Positive": "0.0021553002297878265",
   "Negative": "0.0004163526464253664",
   "Neutral": "0.9974241256713867",
   "Mixed": "4.268358679837547e-06"
 },
 {
   "tweet_id": "1589938860532064257",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938852462223361",
   "Positive": "0.8192643523216248",
   "Negative": "0.0003615827008616179",
   "Neutral": "0.18031837046146393",
   "Mixed": "5.579240314546041e-05"
 },
 {
   "tweet_id": "1589938842328764416",
   "Positive": "0.040960367769002914",
   "Negative": "0.002085065934807062",
   "Neutral": "0.9569152593612671",
   "Mixed": "3.932846084353514e-05"
 },
 {
   "tweet_id": "1589938841959698432",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938829947174912",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938827850051584",
   "Positive": "0.09249694645404816",
   "Negative": "0.0014067101292312145",
   "Neutral": "0.8881305456161499",
   "Mixed": "0.017965782433748245"
 },
 {
   "tweet_id": "1589938811500625920",
   "Positive": "0.4317600131034851",
   "Negative": "0.0021114430855959654",
   "Neutral": "0.565954327583313",
   "Mixed": "0.00017418924835510552"
 },
 {
   "tweet_id": "1589938793334775808",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938788931088385",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938788553609217",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938778063646720",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938775274450951",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938771604439042",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938764180508672",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938759453540352",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938755108212737",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938749638840328",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938744240771073",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589938735189487616",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938731624337408",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938717506310147",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938717313372160",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938717208506368",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938712817078273",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938712234033153",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938707565797378",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938704327794690",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938702112870400",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938692017524736",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938690021011458",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938669431189506",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589938669053710338",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938662686572546",
   "Positive": "0.0008728852844797075",
   "Negative": "8.556780812796205e-05",
   "Neutral": "0.9990372657775879",
   "Mixed": "4.234647349221632e-06"
 },
 {
   "tweet_id": "1589938661973688322",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938654759505920",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938633657962496",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938631812194305",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938629212012544",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938628910002176",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938616050278401",
   "Positive": "0.0002584159083198756",
   "Negative": "0.0002340855571674183",
   "Neutral": "0.999504566192627",
   "Mixed": "2.9292077670106664e-06"
 },
 {
   "tweet_id": "1589938614880079876",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938607477125120",
   "Positive": "0.0010005560470744967",
   "Negative": "5.775091995019466e-05",
   "Neutral": "0.9989062547683716",
   "Mixed": "3.5520723031368107e-05"
 },
 {
   "tweet_id": "1589938606130745345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938601730924544",
   "Positive": "0.003602382028475404",
   "Negative": "0.38332441449165344",
   "Neutral": "0.6130500435829163",
   "Mixed": "2.319694249308668e-05"
 },
 {
   "tweet_id": "1589938598090280960",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938594327953410",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938577890504705",
   "Positive": "0.041858673095703125",
   "Negative": "0.005493226461112499",
   "Neutral": "0.9526176452636719",
   "Mixed": "3.048339749511797e-05"
 },
 {
   "tweet_id": "1589938575709450240",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938569774186496",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938560983261185",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938546671902721",
   "Positive": "0.0019881920889019966",
   "Negative": "0.0009171929559670389",
   "Neutral": "0.9970921277999878",
   "Mixed": "2.4826954359014053e-06"
 },
 {
   "tweet_id": "1589938538069753864",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938531459534848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938530637475840",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938528808763392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938523783647232",
   "Positive": "0.04937562718987465",
   "Negative": "0.0007777822320349514",
   "Neutral": "0.9498153924942017",
   "Mixed": "3.129208562313579e-05"
 },
 {
   "tweet_id": "1589938523628793856",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589938501889720322",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938491710140416",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938483141152769",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938452631801856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938438844751872",
   "Positive": "0.9114392399787903",
   "Negative": "0.0007490729331038892",
   "Neutral": "0.08778233081102371",
   "Mixed": "2.9397144317044877e-05"
 },
 {
   "tweet_id": "1589938430150316032",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938424143749120",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938419266113537",
   "Positive": "0.2615676820278168",
   "Negative": "0.0036219593603163958",
   "Neutral": "0.7347959876060486",
   "Mixed": "1.4334527804749086e-05"
 },
 {
   "tweet_id": "1589938395169841153",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589938392674226178",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938378937888768",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938376396111872",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938375649550336",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938360805629952",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589938360738807811",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938360725954560",
   "Positive": "0.9567381143569946",
   "Negative": "0.00022302432626020163",
   "Neutral": "0.04300056770443916",
   "Mixed": "3.8320777093758807e-05"
 },
 {
   "tweet_id": "1589938356712267778",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589938355890163714",
   "Positive": "0.00552815617993474",
   "Negative": "0.06882422417402267",
   "Neutral": "0.9256366491317749",
   "Mixed": "1.099282962968573e-05"
 },
 {
   "tweet_id": "1589938346931150848",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938342871072769",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938331651289088",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938328195182592",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938322021187587",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938321245208578",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938320750284801",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938317650722816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938314710515713",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938310642044929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938300009480192",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938298092662784",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938296461090819",
   "Positive": "0.07360765337944031",
   "Negative": "0.0005299237673170865",
   "Neutral": "0.9258416891098022",
   "Mixed": "2.074623444059398e-05"
 },
 {
   "tweet_id": "1589938295571910656",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938276454240259",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938272515813376",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938270485434368",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938264332701697",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938240965865472",
   "Positive": "0.0007228441536426544",
   "Negative": "0.00013648494496010244",
   "Neutral": "0.9991292357444763",
   "Mixed": "1.1463022019597702e-05"
 },
 {
   "tweet_id": "1589938234079215618",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589938233747865600",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938203905363968",
   "Positive": "0.02624318189918995",
   "Negative": "0.0003124070935882628",
   "Neutral": "0.973385751247406",
   "Mixed": "5.8679128414951265e-05"
 },
 {
   "tweet_id": "1589938203322372096",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938197047672832",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589938196175294465",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938189225324544",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938188746780672",
   "Positive": "0.0033604258205741644",
   "Negative": "0.00012175572919659317",
   "Neutral": "0.9964640736579895",
   "Mixed": "5.384796531870961e-05"
 },
 {
   "tweet_id": "1589938183113957377",
   "Positive": "0.8553121089935303",
   "Negative": "0.0006426891195587814",
   "Neutral": "0.14394772052764893",
   "Mixed": "9.746549039846286e-05"
 },
 {
   "tweet_id": "1589938175002411009",
   "Positive": "0.3155841827392578",
   "Negative": "0.0020950378384441137",
   "Neutral": "0.6822810173034668",
   "Mixed": "3.977137021138333e-05"
 },
 {
   "tweet_id": "1589938157310869506",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938154521628672",
   "Positive": "0.8030083179473877",
   "Negative": "0.002957276301458478",
   "Neutral": "0.19387640058994293",
   "Mixed": "0.00015798672393430024"
 },
 {
   "tweet_id": "1589938130161139714",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938124334936065",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938120736542721",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589938118769377280",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938112318541824",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938108950515713",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589938108078129152",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938105133723648",
   "Positive": "0.00044961474486626685",
   "Negative": "0.00023387429246213287",
   "Neutral": "0.9993093013763428",
   "Mixed": "7.251574515976245e-06"
 },
 {
   "tweet_id": "1589938097978236928",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938093972652032",
   "Positive": "0.8549116849899292",
   "Negative": "0.0009952194523066282",
   "Neutral": "0.14406877756118774",
   "Mixed": "2.4332444809260778e-05"
 },
 {
   "tweet_id": "1589938085743460353",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938084665516032",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938078466338818",
   "Positive": "0.004832979757338762",
   "Negative": "0.0008245519711636007",
   "Neutral": "0.9943089485168457",
   "Mixed": "3.3512267691548914e-05"
 },
 {
   "tweet_id": "1589938076771848192",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589938060858642432",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938060338556929",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938051660533764",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589938048594489345",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938045499084802",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589938042626007040",
   "Positive": "0.021696370095014572",
   "Negative": "0.0003329067840240896",
   "Neutral": "0.9779642820358276",
   "Mixed": "6.375965313054621e-06"
 },
 {
   "tweet_id": "1589938039908077569",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938024376590336",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589938017992855554",
   "Positive": "0.0014174242969602346",
   "Negative": "0.0002935491211246699",
   "Neutral": "0.9982860684394836",
   "Mixed": "2.9659640858881176e-06"
 },
 {
   "tweet_id": "1589938003912589313",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937999823147008",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589937973277360129",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937941027360768",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589937922014580737",
   "Positive": "0.2404346913099289",
   "Negative": "0.020869221538305283",
   "Neutral": "0.7385783791542053",
   "Mixed": "0.00011760179040720686"
 },
 {
   "tweet_id": "1589937917388263424",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937897998004224",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937894671908865",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937894419931137",
   "Positive": "0.8999335169792175",
   "Negative": "0.00011932850611628965",
   "Neutral": "0.09992225468158722",
   "Mixed": "2.4954715627245605e-05"
 },
 {
   "tweet_id": "1589937891278434304",
   "Positive": "0.18390929698944092",
   "Negative": "0.38955363631248474",
   "Neutral": "0.4264094829559326",
   "Mixed": "0.00012758394586853683"
 },
 {
   "tweet_id": "1589937886203629569",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589937878947491841",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937869686452225",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937867119558657",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937859292991488",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937837893652485",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937830456954891",
   "Positive": "0.002292978810146451",
   "Negative": "0.0001852238056017086",
   "Neutral": "0.9975185394287109",
   "Mixed": "3.361376002430916e-06"
 },
 {
   "tweet_id": "1589937829358239746",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937822685097987",
   "Positive": "0.021071864292025566",
   "Negative": "0.0016375689301639795",
   "Neutral": "0.9772852659225464",
   "Mixed": "5.316572696756339e-06"
 },
 {
   "tweet_id": "1589937817886810114",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937793089748992",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937792049876995",
   "Positive": "0.27000951766967773",
   "Negative": "0.0013465031515806913",
   "Neutral": "0.7286190986633301",
   "Mixed": "2.4881010176613927e-05"
 },
 {
   "tweet_id": "1589937789130641409",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937783384453121",
   "Positive": "0.8057265281677246",
   "Negative": "0.0003444243920966983",
   "Neutral": "0.19388027489185333",
   "Mixed": "4.881509448750876e-05"
 },
 {
   "tweet_id": "1589937775759228930",
   "Positive": "0.7894002795219421",
   "Negative": "0.0005248984089121222",
   "Neutral": "0.21002677083015442",
   "Mixed": "4.80460403196048e-05"
 },
 {
   "tweet_id": "1589937756947755008",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937751646167040",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937744369057792",
   "Positive": "0.05508262291550636",
   "Negative": "0.0009174130973406136",
   "Neutral": "0.9439937472343445",
   "Mixed": "6.176793249323964e-06"
 },
 {
   "tweet_id": "1589937743576301569",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589937728078368768",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937714870521856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937712261640194",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937699859083264",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937699691319298",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589937698906984448",
   "Positive": "0.0943690836429596",
   "Negative": "0.0019401315366849303",
   "Neutral": "0.9036723375320435",
   "Mixed": "1.8383498172624968e-05"
 },
 {
   "tweet_id": "1589937694842683394",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589937682519851008",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589937680905015296",
   "Positive": "0.027175500988960266",
   "Negative": "0.35360416769981384",
   "Neutral": "0.6173290014266968",
   "Mixed": "0.0018913022940978408"
 },
 {
   "tweet_id": "1589937676249370624",
   "Positive": "0.01672866940498352",
   "Negative": "0.00022943269868846983",
   "Neutral": "0.9830358028411865",
   "Mixed": "6.0824145293736365e-06"
 },
 {
   "tweet_id": "1589937671723708417",
   "Positive": "0.0008044489077292383",
   "Negative": "9.277420031139627e-05",
   "Neutral": "0.9990984201431274",
   "Mixed": "4.398695182317169e-06"
 },
 {
   "tweet_id": "1589937667625865220",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937667365826560",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937665860067328",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937660638146561",
   "Positive": "0.0019302773289382458",
   "Negative": "3.898482827935368e-05",
   "Neutral": "0.9979982972145081",
   "Mixed": "3.239008947275579e-05"
 },
 {
   "tweet_id": "1589937655038771200",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 },
 {
   "tweet_id": "1589937642858491905",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589937638194446337",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937632276283392",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937606556778497",
   "Positive": "0.033238377422094345",
   "Negative": "0.0006322163389995694",
   "Neutral": "0.9661160707473755",
   "Mixed": "1.3350677363632713e-05"
 },
 {
   "tweet_id": "1589937599850115072",
   "Positive": "0.43293389678001404",
   "Negative": "0.00022950646234676242",
   "Neutral": "0.5667877793312073",
   "Mixed": "4.881590939476155e-05"
 },
 {
   "tweet_id": "1589937592304340994",
   "Positive": "0.3197830319404602",
   "Negative": "0.0001541991368867457",
   "Neutral": "0.6799430847167969",
   "Mixed": "0.00011969251499976963"
 },
 {
   "tweet_id": "1589937592157470721",
   "Positive": "0.9567381143569946",
   "Negative": "0.00022302432626020163",
   "Neutral": "0.04300056770443916",
   "Mixed": "3.8320777093758807e-05"
 },
 {
   "tweet_id": "1589937586084409344",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937573123608576",
   "Positive": "0.007162020076066256",
   "Negative": "0.00014965930313337594",
   "Neutral": "0.9926767349243164",
   "Mixed": "1.1542676475073677e-05"
 },
 {
   "tweet_id": "1589937565800751105",
   "Positive": "0.006975109688937664",
   "Negative": "0.7748215794563293",
   "Neutral": "0.21089769899845123",
   "Mixed": "0.00730561837553978"
 },
 {
   "tweet_id": "1589937560507543552",
   "Positive": "0.23641455173492432",
   "Negative": "0.16533984243869781",
   "Neutral": "0.5971188545227051",
   "Mixed": "0.0011267481604591012"
 },
 {
   "tweet_id": "1589937557760245762",
   "Positive": "0.7016639709472656",
   "Negative": "0.06506790965795517",
   "Neutral": "0.1926746815443039",
   "Mixed": "0.04059341549873352"
 },
 {
   "tweet_id": "1589937545647132672",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937545215082502",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937542476234752",
   "Positive": "0.657357931137085",
   "Negative": "0.0003017353010363877",
   "Neutral": "0.3422887921333313",
   "Mixed": "5.159739521332085e-05"
 },
 {
   "tweet_id": "1589937519969570816",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937513325817856",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937511140577282",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937506749132802",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937502974246913",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937493981679618",
   "Positive": "0.045432571321725845",
   "Negative": "0.0003917215799447149",
   "Neutral": "0.9541534781455994",
   "Mixed": "2.2343581804307178e-05"
 },
 {
   "tweet_id": "1589937492421382145",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937491720949761",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937483395264514",
   "Positive": "0.9337385296821594",
   "Negative": "0.0001649889600230381",
   "Neutral": "0.06607164442539215",
   "Mixed": "2.4891629436751828e-05"
 },
 {
   "tweet_id": "1589937478148173827",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937477401587712",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937463849803778",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937463799451649",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937457587712002",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937454358097921",
   "Positive": "0.1496863216161728",
   "Negative": "0.0016906348755583167",
   "Neutral": "0.8486098051071167",
   "Mixed": "1.3235377082310151e-05"
 },
 {
   "tweet_id": "1589937451610804224",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937450264457216",
   "Positive": "0.1945146769285202",
   "Negative": "0.020097583532333374",
   "Neutral": "0.7851830124855042",
   "Mixed": "0.00020466855494305491"
 },
 {
   "tweet_id": "1589937440529166336",
   "Positive": "0.2671144902706146",
   "Negative": "0.007085744757205248",
   "Neutral": "0.7257093191146851",
   "Mixed": "9.049573418451473e-05"
 },
 {
   "tweet_id": "1589937433055232000",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589937426122043395",
   "Positive": "0.3236357271671295",
   "Negative": "0.003581198863685131",
   "Neutral": "0.6727404594421387",
   "Mixed": "4.2544721509329975e-05"
 },
 {
   "tweet_id": "1589937423358005248",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937396678012932",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937387471536128",
   "Positive": "0.004231874831020832",
   "Negative": "4.685661042458378e-05",
   "Neutral": "0.9955483675003052",
   "Mixed": "0.00017289833340328187"
 },
 {
   "tweet_id": "1589937387215679488",
   "Positive": "0.9061897993087769",
   "Negative": "0.0001031276406138204",
   "Neutral": "0.09364071488380432",
   "Mixed": "6.637745536863804e-05"
 },
 {
   "tweet_id": "1589937386930458625",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937384384184320",
   "Positive": "0.002169371349737048",
   "Negative": "0.00010479189950274304",
   "Neutral": "0.9977195858955383",
   "Mixed": "6.226986897672759e-06"
 },
 {
   "tweet_id": "1589937360560869376",
   "Positive": "0.05244430527091026",
   "Negative": "0.0001291946682613343",
   "Neutral": "0.9474014639854431",
   "Mixed": "2.505527481844183e-05"
 },
 {
   "tweet_id": "1589937350096064514",
   "Positive": "0.14468346536159515",
   "Negative": "0.0006286485004238784",
   "Neutral": "0.8544594645500183",
   "Mixed": "0.00022835702111478895"
 },
 {
   "tweet_id": "1589937344316321792",
   "Positive": "0.021493084728717804",
   "Negative": "5.6549095461377874e-05",
   "Neutral": "0.978408694267273",
   "Mixed": "4.160524986218661e-05"
 },
 {
   "tweet_id": "1589937339731939328",
   "Positive": "",
   "Negative": "",
   "Neutral": "",
   "Mixed": ""
 },
 {
   "tweet_id": "1589937329787260928",
   "Positive": "0.4694880545139313",
   "Negative": "0.00335165043361485",
   "Neutral": "0.5270669460296631",
   "Mixed": "9.327790030511096e-05"
 }
]