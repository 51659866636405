export const strokeColor = {
  orange: '#FF982F',
  blue: '#36D0FF',
}
export const outerStyles = {
    // Customize the path, i.e. the "completed progress"
    path: {
      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
      strokeLinecap: 'round',
      // Customize transition animation
      transition: 'stroke-dashoffset 0.5s ease 0s',
      // Rotate the path
      transform: 'rotate(0.25turn)',
      transformOrigin: 'center center',
    },
    // Customize the circle behind the path, i.e. the "total progress"
    trail: {
      stroke: '#ffff',
      // Rotate the trail
      transform: 'rotate(-77.67deg)',
      transformOrigin: 'center center',
    },
  };
  

export const innerStyles = {
    path: {
        stroke: '#ffff',
    },
    transform: {
        transform: 'rotate(0.25turn)',
    },
    trail: {
        stroke: 'transparent',
    },
    text: {
        fill: '#fff',
        fontSize: '30px',
    },
};