import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

//Navigation functions
import { navigateToPage } from "../../utils/handleNavigationFunctions";
import { useNavigate } from "react-router-dom";

//styles
import { outerStyles, innerStyles } from "./styles";

//Icons
import TwitterIcon from "../../SVG/TwitterIcon";
import YoutubeIcon from "../../SVG/YoutubeIcon";
import InstagramIcon from "../../SVG/InstagramIcon";


export default function CircleWithInnerCircles(props) {
  const { social_network } = props;

  const getStrokeColor = (social_network) => {
    let result =
    social_network === "youtube" ? (
      "#36D0FF"
    ) : social_network === "twitter" ? (
      "#FFC12F"
    ) : social_network === "instagram" ? (
      "#FFC12F"
    ) : null;
    let path = {
      path: {
        stroke: result
      }
    }
    return path;
  };

  const strokeColor = getStrokeColor(social_network)

  const percentage = 25;
  const styles = {...outerStyles, ...strokeColor}
 
  
  const navigate = useNavigate();

  const SocialIcon = ({ social_network }) => {
    return (
      <div>
        {social_network === "youtube" ? (
          <YoutubeIcon />
        ) : social_network === "twitter" ? (
          <TwitterIcon />
        ) : social_network === "instagram" ? (
          <InstagramIcon />
        ) : null}
      </div>
    );
  };

  return (
    <div
      id={social_network}
      className="tendency-wrap"
      onClick={() => navigateToPage(navigate, "/tendency")}
    >
      <div className="tendency-text-wrap">
        <div>
          <h3 className="tendency-h3">H3 Bold</h3>
          <p>Texto 1 destacado</p>
        </div>
        <div className="__icon-margin">
          <SocialIcon social_network={social_network} />
          <p className="details-text">Ver detalle</p>
        </div>
      </div>

      <div>
        <div className="tendency-pogress-wrap">
          <div style={{ position: "relative" }}>
            <div className="first-progressbar">
              <CircularProgressbar
                value={percentage}
                strokeWidth={5}
                styles={styles}
              />
            </div>
            <div
              className="second-progressbar"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgressbar
                value={percentage}
                strokeWidth={3}
                styles={styles}
              />
              <div
                className="third-progressbar"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgressbar
                  value={100}
                  strokeWidth={1.5}
                  text={percentage}
                  styles={innerStyles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>





     
     
      


    </div>
  );
}
