//Búsquedas
export const initialState_Search = {
    currentSearch: '',
};

export const searchReducer = (state, action) => {
    switch (action.type) {
        case 'saveSearch':
                return { ...state, currentSearch: action.payload };
        default:
            throw new Error();
    };
}

