export const localization = [
  { label: "España", value: "option-1" },
  { label: "Europa", value: "option-2" },
  { label: "Estados Unidos", value: "option-3" },
];

export const date = [
  { label: "Octubre", value: "Octubre" },
  { label: "Noviembre", value: "Noviembre" },
];

export const social_network = [
  { label: "Twitter", value: "option-1" },
  { label: "Youtube", value: "option-2" },
];

export const words = [
  {title: "Parturient Lorem", value: 300},
  {title: "Parturient Lorem", value: 280},
  {title: "Parturient Lorem", value: 260},
  {title: "Parturient Lorem", value: 240}
];

export const reports = [
  { id: 0, title: "Proyecto 1", date: "Wed, 19 Apr 2023 00:00:00 GMT" },
  { id: 1, title: "Proyecto 2", date: "Wed, 19 Apr 2023 00:00:00 GMT" },
  { id: 2, title: "Proyecto 3", date: "Wed, 19 Apr 2023 00:00:00 GMT" },
  { id: 3, title: "Proyecto 4", date: "Wed, 19 Apr 2023 00:00:00 GMT" },
];
