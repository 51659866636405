import React from 'react'

export default function Download(props) {

    const {fill} = props

    return (
        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.05792 1.92886C7.30367 0.805655 9.03868 0 11 0C14.6991 0 17.7695 2.87419 18.1028 6.58229C20.2924 6.9059 22 8.82131 22 11.1733C22 13.7561 19.9407 15.8125 17.4453 15.8125H13.75C13.3703 15.8125 13.0625 15.4907 13.0625 15.0938C13.0625 14.6968 13.3703 14.375 13.75 14.375H17.4453C19.2215 14.375 20.625 12.9209 20.625 11.1733C20.625 9.42573 19.2215 7.97159 17.4453 7.97159H16.7578V7.25284C16.7578 4.0618 14.2 1.4375 11 1.4375C9.40167 1.4375 7.97848 2.09548 6.95593 3.01744C5.91567 3.95538 5.37109 5.0863 5.37109 5.97216V6.61624L4.75871 6.68661C2.8376 6.90736 1.375 8.55694 1.375 10.5199C1.375 12.6283 3.06707 14.375 5.19922 14.375H8.25C8.6297 14.375 8.9375 14.6968 8.9375 15.0938C8.9375 15.4907 8.6297 15.8125 8.25 15.8125H5.19922C2.34786 15.8125 0 13.4636 0 10.5199C0 7.98548 1.74056 5.88673 4.04556 5.35468C4.24159 4.1137 5.00479 2.8784 6.05792 1.92886Z" fill={fill} />
            <path d="M10.5139 22.7895C10.7823 23.0702 11.2177 23.0702 11.4861 22.7895L15.6111 18.477C15.8796 18.1963 15.8796 17.7412 15.6111 17.4605C15.3427 17.1798 14.9073 17.1798 14.6389 17.4605L11.6875 20.546V7.90625C11.6875 7.5093 11.3797 7.1875 11 7.1875C10.6203 7.1875 10.3125 7.5093 10.3125 7.90625V20.546L7.36114 17.4605C7.09265 17.1798 6.65735 17.1798 6.38886 17.4605C6.12038 17.7412 6.12038 18.1963 6.38886 18.477L10.5139 22.7895Z" fill={fill}/>
        </svg>
    )
}
