import React from 'react'

export default function Edit(props) {

    const {fill} = props

    return (
        <svg width="14" height="14" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.7014 0.201364C16.9699 -0.0671214 17.4052 -0.0671214 17.6737 0.201364L21.7987 4.32636C22.0671 4.59485 22.0671 5.03015 21.7987 5.29864L8.04866 19.0486C7.98282 19.1145 7.9043 19.1662 7.81785 19.2008L0.942851 21.9508C0.687502 22.053 0.395852 21.9931 0.201384 21.7986C0.00691609 21.6042 -0.0529473 21.3125 0.0491923 21.0572L2.79919 14.1822C2.83377 14.0957 2.88555 14.0172 2.95138 13.9514L16.7014 0.201364ZM15.4098 3.4375L18.5625 6.59023L20.3402 4.8125L17.1875 1.65977L15.4098 3.4375ZM17.5902 7.5625L14.4375 4.40977L5.50002 13.3473V13.75H6.18752C6.56722 13.75 6.87502 14.0578 6.87502 14.4375V15.125H7.56252C7.94222 15.125 8.25002 15.4328 8.25002 15.8125V16.5H8.65275L17.5902 7.5625ZM4.16854 14.6788L4.02344 14.8239L1.92162 20.0784L7.17617 17.9766L7.32127 17.8315C7.06058 17.7338 6.87502 17.4823 6.87502 17.1875V16.5H6.18752C5.80782 16.5 5.50002 16.1922 5.50002 15.8125V15.125H4.81252C4.51771 15.125 4.26625 14.9394 4.16854 14.6788Z" fill={fill} />
        </svg>
    );
}
