import React from 'react'

export default function Dashboard() {
    return (
        <>
            <svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.728 4.2168H23.384V12.6504H14.728V4.2168Z" fill="#F6F6F6" />
                <path d="M4.32801 14.3496H12.984V22.7832H4.32801V14.3496Z" fill="#F6F6F6" />
                <path d="M14.728 14.3496H23.384V22.7832H14.728V14.3496Z" fill="#181A1E" />
                <path d="M4.32801 4.2168H12.984V12.6504H4.32801V4.2168Z" fill="#181A1E" />
            </svg>

        </>
    )
}
