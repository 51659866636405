export const validateForm = (user) => {

  let errors = {};

  if (user.user_email === "") {
    errors.email_error = "El campo del correo electrónico no puede estar vacío";
    errors.password_error = "El campo de la contraseña no puede estar vacío";
    errors.validate_password = "";

  } else if (user.user_password === "") {
    errors.email_error = "El campo del correo electrónico no puede estar vacío";
    errors.password_error = "El campo de la contraseña no puede estar vacío";
    errors.validate_password = "";
  } 

  return errors;
};


export const validatePassword = (user) => {

  let errors = {};

  if (user.validation_password != user.user_password) {
    errors.validate_password = "La contraseña de verificación no coincide";

  } 

  return errors;

}