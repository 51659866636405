//Informes del usuario
export const initialState_Report = {
    reports: [],
};

export const reportReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_REPORTS':
            return { ...state, reports: [...state.reports, action.payload] };
        default:
            throw new Error();
    };
}

