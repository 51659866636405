import { useContext, useTransition } from "react";
import { ProjectContext } from "../config/config";

import videos from "../json/videos.json";
import comments from "../json/comments.json";
import brands from "../json/brands.json";

import { mapJson } from "../utils/youtubeGraphicFunctions";

//Componentes
import SearchLabel from "../Components/SearchLabel";
import Sidebar from "../Components/Sidebar/Sidebar";
import List from "../Components/List";
import UploadButton from "../Components/UploadButton";

import { API_URL, GET_SEARCH_BY_CONTENT } from "../constants";

import { reports } from "../utils/data";
import { fetchData } from "../services/getSuspender";

import es from "../translations/es.json";
import YoutubeScreen from "./Youtube/YoutubeScreen";

export default function HomeSceen(props) {
  const { user, searchs, addSearch } = useContext(ProjectContext);
  const { lang } = props;
  const text = lang === "es" ? es.pages["home"] : null;

  const handleSearch = async () => {
    const url = `${API_URL}${GET_SEARCH_BY_CONTENT}${searchs.currentSearch}`;
    const res = fetchData(url);
    const result = await res.read();
  };

  const videosArray = mapJson(videos);

  return (
    // <div className='background-image'>
    //   <Sidebar defaultActive={1}/>
    //   <div className='container'>
    //       <div className='row my-lg-5  my-md-5 my-sm-5'>
    //         <div className='col-lg-1'></div>
    //         <div className='col-lg-11'>
    //           <h2><strong>{text.hello}, {user.user_name}</strong></h2>
    //         </div>
    //       </div>
    //       <div className='row'>
    //         <div className='col-lg-1'></div>
    //         <div className='col-lg-4 col-md-12 col-sm-12 m-lg-0 mb-md-3 mb-sm-5'>
    //           <h3 className='my-3'>{text['latest-searches']}</h3>
    //         {/* Solo aparecerá la lista si el usuario tiene hechas de antes las búsquedas */}
    //           <List data={reports}/>
    //         {/* Sino aparecerán el siguiente texto */}
    //         {/* <p>No tienes búsquedas recientes, haz una nueva búsqueda.</p> */}
    //         </div>
    //         <div className='col-lg-1'></div>
    //         <div className='col-lg-5 col-md-12 col-sm-12 m-lg-0 mb-md-3 mb-sm-5'>
    //           <div className='row'>
    //             <h3 className='my-3'>{text['new-search']}</h3>
    //             <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    //           </div>
    //           <div className='row'>
    //             <SearchLabel
    //             placeholder="Buscar..."
    //             />
    //           </div>
    //           <div className='row mt-5'>
    //             <UploadButton/>
    //           </div>
    //         </div>
    //         <div className='col-lg-1'></div>
    //       </div>
    //   </div>
    // </div>

    <div className="background-image">
      <Sidebar defaultActive={1} />
      <div className="container">
        <div className="row my-lg-5  my-md-5 my-sm-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-4 col-md-12 col-sm-12 m-lg-0 mb-md-3 mb-sm-5">
            <h5>Lista de vídeos</h5>
            <ol>
              {videosArray.map((video, index) => (
                <li key={index} style={{listStyle: 'none', padding: 0}}>
                  <a href= {`video/${index + 1}`} style={{textDecoration: 'none', color: 'black'}}>Video {index + 1}</a>
                </li>
              ))}
            </ol>
          </div>
        </div>
      </div>
    </div>
  );
}
