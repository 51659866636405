import React from 'react'

export default function Document(props) {
    
    return (
        <>
            <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.89164 9.1875C6.52345 9.1875 6.22498 9.48131 6.22498 9.84375C6.22498 10.2062 6.52345 10.5 6.89164 10.5H13.5583C13.9265 10.5 14.225 10.2062 14.225 9.84375C14.225 9.48131 13.9265 9.1875 13.5583 9.1875H6.89164Z" fill="#181A1E" />
                <path d="M6.22498 12.4688C6.22498 12.1063 6.52345 11.8125 6.89164 11.8125H13.5583C13.9265 11.8125 14.225 12.1063 14.225 12.4688C14.225 12.8312 13.9265 13.125 13.5583 13.125H6.89164C6.52345 13.125 6.22498 12.8312 6.22498 12.4688Z" fill="#181A1E" />
                <path d="M6.22498 15.0938C6.22498 14.7313 6.52345 14.4375 6.89164 14.4375H9.55831C9.9265 14.4375 10.225 14.7313 10.225 15.0938C10.225 15.4562 9.9265 15.75 9.55831 15.75H6.89164C6.52345 15.75 6.22498 15.4562 6.22498 15.0938Z" fill="#181A1E" />
                <path d="M12.225 0H4.89164C3.41888 0 2.22498 1.17525 2.22498 2.625V18.375C2.22498 19.8247 3.41888 21 4.89164 21H15.5583C17.0311 21 18.225 19.8247 18.225 18.375V5.90625L12.225 0ZM12.225 1.3125V3.9375C12.225 5.02481 13.1204 5.90625 14.225 5.90625H16.8916V18.375C16.8916 19.0999 16.2947 19.6875 15.5583 19.6875H4.89164C4.15526 19.6875 3.55831 19.0999 3.55831 18.375V2.625C3.55831 1.90013 4.15526 1.3125 4.89164 1.3125H12.225Z" fill="#181A1E" />
            </svg>

        </>
    )
}
