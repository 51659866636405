export default function User() {
  return (
    <>
      <svg
        width="81"
        height="81"
        viewBox="0 0 81 81"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40.3333 78.6667C61.4167 78.6667 78.6667 61.4167 78.6667 40.3333C78.6667 19.25 61.4167 2 40.3333 2C19.25 2 2 19.25 2 40.3333C2 61.4167 19.25 78.6667 40.3333 78.6667Z"
          stroke="url(#paint0_linear_859_638)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M61.0433 63.3332C61.0433 54.3032 52.06 46.9998 41 46.9998C29.94 46.9998 20.9567 54.3032 20.9567 63.3332M41 39.9998C44.0942 39.9998 47.0617 38.7707 49.2496 36.5828C51.4375 34.3948 52.6667 31.4274 52.6667 28.3332C52.6667 25.239 51.4375 22.2715 49.2496 20.0836C47.0617 17.8957 44.0942 16.6665 41 16.6665C37.9058 16.6665 34.9383 17.8957 32.7504 20.0836C30.5625 22.2715 29.3333 25.239 29.3333 28.3332C29.3333 31.4274 30.5625 34.3948 32.7504 36.5828C34.9383 38.7707 37.9058 39.9998 41 39.9998Z"
          stroke="url(#paint1_linear_859_638)"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_859_638"
            x1="40.3333"
            y1="2"
            x2="40.3333"
            y2="78.6667"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC12F" />
            <stop offset="1" stop-color="#FF982F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_859_638"
            x1="41"
            y1="16.6665"
            x2="41"
            y2="63.3332"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#FFC12F" />
            <stop offset="1" stop-color="#FF982F" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
