//Iconos que irán en el Sidebar
import Document from '../../SVG/Document';
import Dashboard from '../../SVG/Dashboard';
import Settings from '../../SVG/Settings';
import Helpcenter from "../../SVG/Helpcenter";
import Logout from "../../SVG/Logout";
import Logo from '../../SVG/Logo';


const SidebarItems = [
    {
        name: "Logo",
        route: '/',
        icon: <Logo/>
    },
    {
        name: "HomeScreen",
        route: '/home',
        icon: <Dashboard/>
    },
    {
        name: "LogInScreen",
        route: '/',
        icon: <Document/>
    },
    {
        name: "SignInScreen",
        route: '/signIn',
        icon: <Document/>
    },
    {
        name: "Settings",
        route: '/settings',
        icon: <Settings/>
    },
    {
        name: "Help Center",
        route: '/helpCenter',
        icon: <Helpcenter/>
    },
    {
        name: "Log out",
        route: '/logOut',
        icon: <Logout/>
    },
];

export default SidebarItems;