import React from 'react'

export default function Helpcenter() {
    return (
        <>
            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 20.625C5.68426 20.625 1.375 16.3157 1.375 11C1.375 5.68426 5.68426 1.375 11 1.375C16.3157 1.375 20.625 5.68426 20.625 11C20.625 16.3157 16.3157 20.625 11 20.625ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="black" />
                <path d="M7.22578 7.95595C7.21534 8.14325 7.36987 8.29498 7.55746 8.29498H8.69113C8.88098 8.29498 9.03193 8.14018 9.05744 7.95205C9.17975 7.04987 9.79894 6.39227 10.9027 6.39227C11.8453 6.39227 12.7094 6.86359 12.7094 7.99823C12.7094 8.87103 12.1944 9.27252 11.3827 9.88348C10.4575 10.5555 9.72439 11.3411 9.77676 12.6154L9.78084 12.9135C9.78342 13.1015 9.93655 13.2525 10.1246 13.2525H11.2397C11.4296 13.2525 11.5835 13.0986 11.5835 12.9088V12.7637C11.5835 11.7775 11.9588 11.4894 12.9712 10.7214C13.8091 10.0842 14.6819 9.37726 14.6819 7.89349C14.6819 5.81622 12.9276 4.8125 11.0074 4.8125C9.26518 4.8125 7.35575 5.62459 7.22578 7.95595ZM9.36654 15.8796C9.36654 16.6128 9.95132 17.1539 10.7543 17.1539C11.5922 17.1539 12.1682 16.6128 12.1682 15.8796C12.1682 15.1203 11.5922 14.5879 10.7543 14.5879C9.95132 14.5879 9.36654 15.1203 9.36654 15.8796Z" fill="black" />
            </svg>
        </>
    )
}
