import { useContext, useState, Suspense } from "react";
import { API_URL, SIGN_IN } from "../constants";
import { ProjectContext } from "../config/config";

import { encryptProjectStorage } from "../utils/encryptStorage";

//Components
import Label from "../Components/Label";
import PrimaryButton from '../Components/PrimaryButton';


//Navigation functions
import { navigateToPage } from "../utils/handleNavigationFunctions";
import { useNavigate } from "react-router-dom";

import { fetchPost } from "../services/postSuspender";
import { validateForm } from "../utils/auth";

import es from '../translations/es.json';


export default function SignInScreen(props) {
  const [shown, setShown] = useState(false);
  const [error, setError] = useState({
    email_error: "",
    password_error: "",
    message: "",
  });

  const {lang} = props;
  const text = lang === "es" ? es.pages["sign-in"] : null;

  const { user, dispatch_user } = useContext(ProjectContext);

  const navigate = useNavigate();

  encryptProjectStorage.encryptValue("user", user);
  localStorage.setItem("user", JSON.stringify(user));

  const switchShown = () => {
    setShown(!shown);
  };

  async function handleSignIn() {
    try {
      const url = `${API_URL}${SIGN_IN}`;
      const data = {
        user_email: user.user_email,
        user_password: user.user_password,
      };
      const response = fetchPost(url, data);
      const { id, user_name } = response;
      dispatch_user({ type: "SET_USER_ID", payload: id });
      dispatch_user({ type: "SET_NAME", payload: user_name });

    } catch (error) {
      return setError({
        email_error: "",
        password_error: "",
        message: "El correo electrónico o la contraseña son incorrectos",
      });
    }
  }

  const handleFormSubmit = () => {
    console.log('click')

    let errors = validateForm(user);
    setError(errors);

    navigateToPage(navigate, "/inicio");

    // if (Object.keys(errors).length === 0) {
    //   try {
    //     await handleSignIn();
    //     navigateToPage(navigate, "/inicio");
    //   } catch (error) {}
    // }
  };

  return (
    <div className="main-container background-image">
      <div className="row mt-3 justify-content-center">
        <img
          src={require("../assets/Logo-Smartketing-sobre-blanco.png")}
          alt="Logo de Smarketing en fondo blanco"
          className="logo-img"
        />
      </div>
      <div className="row p-4 justify-content-center">
        <div className="card card__signin card__primary">
          <div className="card-body">
            <div className="card-title text-start">
              <h1>{text.welcome}</h1>
            </div>
            <div className="card-text text-start">
              <p>to continue to Smartketing</p>
            </div>
            <div>
              <Label
                text="Correo electrónico"
                type="text"
                name="user_email"
                email_error={error.email_error}
                className="input-container__primary"
              />
              <Label
                text="Contraseña"
                type={shown ? "text" : "password"}
                name="user_password"
                shown={shown}
                onClick={switchShown}
                password_error={error.password_error}
                message={error.message}
                className="input-container__primary"
              />
            </div>
            <div className="pt-4 text-center">
              <PrimaryButton
                text="Acceder a la cuenta"
                id="primary"
                handleClick={handleFormSubmit}
              />

              <p className="signin-text">{text.message}</p>
              <hr></hr>
              <p className="terms-text">
                By creating a Smartketing account, you agree to our <br></br>{" "}
                <span>terms and conditions</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    
   
  );
}
