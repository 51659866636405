import React from "react";

export default function Tags_Graphic(props) {

  const {tag} = props;



  return (
    <div className="tags-graphic-container">
      <div className="row p-4">
        <p>Tags</p>
      </div>
      <div className="tags-row">

          <div className="tags-container">
            <div className="d-flex justify-content-start align-items-center p-2 m-2">
              <p className="tag-text">{tag}</p>
            </div>
          </div>
      </div>
    </div>
  );
}
