import {useState} from 'react'

export default function SelectLabel(props) {

  const {options, defaultValue, onChange} = props
  
  const [selectedValue, setSelectedValue] = useState(defaultValue);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    onChange && onChange(event.target.value);
  };

  return (
    <>
      <select value={selectedValue} onChange={handleChange} className='select' disabled>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </>
  )
}