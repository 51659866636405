import { useTransition } from "react";

export default function PrimaryButton(props) {
  const { text, handleClick, id, className } = props;

  const combinedClassName = `width-responsive ${className}`;

  console.log(handleClick)


  return (
    <div>
      <button
        onClick={handleClick}
        id={id}
        className={combinedClassName}
        >
        <p>{text}</p>
      </button>
    </div>
  );
}
