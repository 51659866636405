import { useContext, useState, useEffect, Suspense } from "react";

import { ProjectContext } from "../config/config";

//Graphics
import Tendency_Graphic from "../Graphics/Tendency_Graphic/Tendency_Graphic";
import Interest_Graphic from "../Graphics/Interest_Graphic";
import CircularProgressBar from "../animations/CircularProgressBar";
import Sentiment_Analysis_Graphic from "../Graphics/Sentiment_Analysis_Graphic";
import Related_Words_Graphic from "../Graphics/Related_Words_Graphic";
import Total_Graphic from "../Graphics/Total_Graphic";
import Table from "../Graphics/Table/Table";

import MainMenu from "../Components/MainMenu";

import { words } from "../utils/data";
import Sidebar from "../Components/Sidebar/Sidebar";

export default function Dashboard() {
  const { graphics } = useContext(ProjectContext);
  const [isLoaded, setLoaded] = useState(false);
  const [percentage, setPercentage] = useState(0);

  //Carga la pantalla para mostrar los datos de los gráficos
  useEffect(() => {
    setTimeout(() => {
      if (percentage < 100) {
        setPercentage(percentage + 1);
      }
    }, 300);
    setLoaded(true);
  }, [percentage]);

  function Loading() {
    return <h2>🌀 Loading...</h2>;
  }

  return (
    // <Suspense fallback={<Loading/>}>
    //   <Sidebar defaultActive={1} />
    // </Suspense>

    <>
      <Sidebar/>
      <div className="container mt-5">
        <MainMenu />
        <div className="row my-4">
          <div className="col-lg-1"></div>
          <div className="col d-flex justify-content-center column_margin">
            <Tendency_Graphic social_network="twitter" />
          </div>
          <div className="col d-flex justify-content-center column_margin">
            <Tendency_Graphic social_network="youtube" />
          </div>
          <div className="col d-flex justify-content-center column_margin">
            <Tendency_Graphic social_network="instagram" />
          </div>
        </div>
        <div className="row my-4">
          <div className="col-lg-1"></div>
          <div className="col d-flex justify-content-center column_margin">
            <Interest_Graphic />
          </div>
          <div className="col d-flex justify-content-center column_margin">
            <Sentiment_Analysis_Graphic />
          </div>
          <div className="col d-flex justify-content-center column_margin">
            <Related_Words_Graphic data={words} />
          </div>
        </div>
      </div>
    </>
  );
}
