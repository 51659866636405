//Cargar un archivo u otro
export const initialState_File = {
    file: null,
};

export const fileReducer = (state, action) => {
    switch (action.type) {
        case 'LOAD_FILE':
            return { ...state, file: action.payload };
        case 'RESET_FILE':
            return { ...state, file: null };
        default:
            throw new Error();
    };
}

