import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

import { nodes, links } from "../json/nodes_data";
import { BUBBLE } from "../constants";

import { getIconPath, targetNodes } from "../utils/networkGraphicFunctions";

const Network_Graphic = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    drawChart();
  }, [nodes, links]);

  const drawChart = () => {
    //Creamos la variable svg para poder pintar el gráfico
    const svg = d3.select(svgRef.current);

    // Crear una escala de colores para los nodos o círculos
    const color = d3
      .scaleOrdinal()
      .domain([0, 100])
      .range(["#FFC12F", "#486CFF", "#36D0FF"]);

    // Define los gradientes lineales
    const gradients = svg.append("defs");

    // Creación de los elementos visuales del grafo
    const link = svg
      .selectAll(".link")
      .data(links)
      .enter()
      .append("line")
      .attr("class", "link")
      .style("stroke-width", 0.75)
      .style("stroke", (d) => {
        if (d.gradient == true) {
          var id = "S" + d.source + "T" + d.target;
          var gradient1 = gradients.append("linearGradient").attr("id", id);
          gradient1
            .append("stop")
            .attr("offset", "0%")
            .attr("stop-color", color(d.source));
          gradient1
            .append("stop")
            .attr("offset", "100%")
            .attr("stop-color", color(d.target));
          return "url(#" + id + ")";
        } else {
          return color(d.source);
        }
      });

    const node = svg
      .append("g")
      .selectAll("g")
      .data(nodes)
      .join("g")
      .attr("transform", "translate(100, 100)");

    targetNodes.forEach((targetId) => {
      const targetGroup = node
        .filter((d) => d.id === targetId) // Filtra solo el nodo objetivo
        .append("g")
        .raise()
        .attr("class", "target-group")
        .attr("transform", "translate(5, -40)");

      targetGroup.raise();

      targetGroup.append("path").attr("d", BUBBLE).style("fill", "#F2F2F2");

      targetGroup
        .append("text")
        .text(targetId)
        .attr("x", 40)
        .attr("y", 12)
        .attr("text-anchor", "middle")
        .attr("alignment-baseline", "middle")
        .attr("fill", (d) => color(d.id))
        .attr("font-size", 10);

      targetGroup.raise();
    });

    node
      .append("circle")
      .attr("r", 18)
      .style("fill", (d) => color(d.group));

    node
      .append("path")
      .attr("d", (d) => getIconPath(d.icon))
      .attr("transform", "translate(-11.5,-11.5)")
      .attr("fill", "white");

    node.lower();

    // Función de actualización en cada tick de la simulación
    const ticked = () => {
      link
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);

      node.attr("transform", (d) => `translate(${d.x},${d.y})`);
    };

    const dragstart = () => {
      d3.select(this).classed("fixed", true);
    };

    const clamp = (value, min, max) => {
      return Math.max(min, Math.min(max, value));
    };

    const dragged = (event, d, simulation) => {
      const minX = 0;
      const maxX = 500;
      const minY = 0;
      const maxY = 300;

      d.fx = clamp(event.x, minX, maxX);
      d.fy = clamp(event.y, minY, maxY);
      simulation.alpha(1).restart();
    };

    // Configuración de la simulación de la fuerza
    const simulation = d3
      .forceSimulation(nodes)
      .force(
        "link",
        d3
          .forceLink(links)
          .id((d) => d.id)
          .distance(80)
      )
      .force("charge", d3.forceManyBody().strength(-200))
      .force("center", d3.forceCenter(320, 200))
      .force("y", d3.forceY(200 / 2).strength(0.1))
      .force("x", d3.forceX((d, i) => (200 / (nodes.length - 1)) * i))
      .on("tick", ticked);

    function click(event, d) {
      delete d.fx;
      delete d.fy;
      d3.select(this).classed("fixed", false);
      simulation.alpha(1).restart();
    }

    const drag = d3.drag().on("start", dragstart).on("drag", dragged);
    node.call(drag).on("click", click);
  };

  return (
    <div className="network-main-container">
      <div className="row text-row">
        <p>Relación entre usuarios</p>
      </div>
      <div className="row graphic-row">
        <svg ref={svgRef} width={300} height={400} />
      </div>
    </div>
  );
};

export default Network_Graphic;
