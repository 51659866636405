import { useState, useContext } from "react";
import { ProjectContext } from "../config/config";
import { API_URL, CREATE_USER } from "../constants";

//Components
import Label from "../Components/Label";
import PrimaryButton from "../Components/PrimaryButton";

//Navigation functions
import { navigateToPage } from "../utils/handleNavigationFunctions";
import { useNavigate } from "react-router-dom";

import { fetchPost } from "../services/postSuspender";
import { validateForm, validatePassword } from "../utils/auth";

export default function SignUpScreen() {
  const [shown, setShown] = useState({ password: false, verificate: false });
  const [error, setError] = useState({
    email_error: "",
    password_error: "",
    validate_password: "",
  });

  const navigate = useNavigate();
  const { user, dispatch_user } = useContext(ProjectContext);

  async function handleSignUp() {
    try {
      const url = `${API_URL}${CREATE_USER}`;
      const data = {
        user_email: user.user_email,
        user_password: user.user_password,
      };
      const response = fetchPost(url, data);
      const { id, user_name } = response;

      dispatch_user({ type: "SET_USER_ID", payload: id });
      dispatch_user({ type: "SET_NAME", payload: user_name });
    } catch (error) {
      //Manejar los errores
    }
  }

  const handleFormSubmit = async () => {
    const errors = validateForm(user);
    const validate = validatePassword(user);
    let result = {email_error: errors.email_error, password_error: errors.password_error, validate_password: validate.validate_password}
    setError(result)

    if (Object.keys(errors).length === 0 || Object.keys(result).length === 0) {
        await handleSignUp();
        navigateToPage(navigate, "/inicio");
    }
  };



  
  const handleClick = (event) => {
    const buttonId = event.currentTarget.id;
    if (buttonId == "password") {
      setShown((prevState) => ({
        ...prevState,
        password: !prevState.password,
      }));
    } else if (buttonId == "verificate") {
      setShown((prevState) => ({
        ...prevState,
        verificate: !prevState.verificate,
      }));
    }
  };


  return (
    <div className="main-container background-image">
      <div className="row mt-3 justify-content-center">
        <img
          src={require("../assets/Logo-Smartketing-sobre-blanco.png")}
          alt="Logo de Smarketing en fondo blanco"
          className="logo-img"
        />
      </div>
      <div className="row p-4 justify-content-center">
        <div className="card card__signup card__primary">
          <div className="card-body">
            <div className="card-title text-start">
              <h1>Sign Up</h1>
            </div>
            <div className="card-text text-start">
              <p>to continue to Smartketing</p>
            </div>
            <div>
              <Label
                text="Correo electrónico"
                type="text"
                name="user_email"
                email_error={error.email_error}
              />

              <Label
                text="Contraseña"
                type={shown.password ? "text" : "password"}
                name="user_password"
                shown={shown.password}
                password_error={error.password_error}
                id="password"
                onClick={handleClick}
              />

              <Label
                text="Repetir contraseña"
                type={shown.verificate ? "text" : "password"}
                name="validation_password"
                shown={shown.verificate}
                validate_password={error.validate_password}
                id="verificate"
                onClick={handleClick}
              />
            </div>
            <div className="pt-4 text-center">
              <PrimaryButton
                text="Crear cuenta"
                id="primary"
                onClick={handleFormSubmit}
              />
              <p className="pt-3 signin-text">
                ¿Already have an account?{" "}
                <a href="#" onClick={() => navigateToPage(navigate, "/")}>
                  Sign in
                </a>
              </p>
              <hr></hr>
              <p className="terms-text">
                By creating a Smartketing account, you agree to our <br></br>{" "}
                <span>terms and conditions</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
