import { useContext } from "react";
import { ProjectContext } from "../config/config";

//Icons
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function Label(props) {
  const {
    text,
    type,
    name,
    email_error,
    password_error,
    message,
    validate_password,
    shown,
    onClick,
    id,
    className,
  } = props;

  const { user, dispatch_user } = useContext(ProjectContext);

  //Insertar los datos del usuario en el objeto
  const handleData = (event) => {
    const { name, value } = event.target;
    dispatch_user({ type: `SET_${name.toUpperCase()}`, payload: value });
  };

  console.log(className)

  return (
    <>
      <div>
        <label> {text} </label>
        <div className={className}>
          <input type={type} name={name} onChange={handleData} />
          {name == "user_password" || name == "validation_password" ? (
            <button id={id} type="button" onClick={onClick}>
              {shown ? (
                <Visibility style={{ fontSize: "20px", marginBottom: 10 }}  />
              ) : (
                <VisibilityOff style={{ fontSize: "20px", marginBottom: 10 }} />
              )}
            </button>
          ) : null}
        </div>
        {user.user_email == "" && <p className="error-text">{email_error}</p>}
        {user.user_password == "" && (
          <p className="error-text">{password_error}</p>
        )}
        {message && (
          <p className="error-text error-secondary-text">{message}</p>
        )}
        {validate_password && (
          <p className="error-text error-secondary-text">{validate_password}</p>
        )}
      </div>
    </>
  );
}
