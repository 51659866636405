import React from "react";

import Save from "../SVG/Save";

export default function SaveButton(props) {

    const {id} = props;

  return (
    <button id={id} className="edit-button-container">
      <i className="fas fa-plus d-inline-block mr-2">
        <Save/>
      </i>
      <span className="d-inline-block">
        <p>Guardar usuario</p>
      </span>
    </button>
  );
}
