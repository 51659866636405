import { useRef, useEffect, useContext } from "react";
import * as d3 from "d3";

//Components
import SelectLabel from "../Components/SelectLabel";

import { ProjectContext } from "../config/config";

export default function Interest_Graphic() {

  const chartRef = useRef(null);
  const {searchs} = useContext(ProjectContext);

  const date = [
    { label: "Seis meses", value: "option-1" },
    { label: "Último mes", value: "option-2" },
    { label: "Último año", value: "option-3" },
  ];

  useEffect(() => {
    drawGraphic();
    
  }, []);


  const drawGraphic = () => {
    
    //Pintar el gráfico en base a la fecha y el valor
    const data = [
      { month: "Ene", value: 10 },
      { month: "Feb", value: 15 },
      { month: "Mar", value: 25 },
      { month: "Abr", value: 30 },
      { month: "May", value: 45 },
      { month: "Jun", value: 60 }
    ];

    //Definimos las dimensiones del gráfico
    const margin = { top: 20, right: 30, bottom: 30, left: 60 };
    const width = 350 - margin.left - margin.right;
    const height = 230 - margin.top - margin.bottom;


    //Creamos la escala X
    const x = d3.scaleBand().range([0, width]).domain(data.map((d) => d.month)).padding(0.2);

    //Creamos la escala Y
    const y = d3.scaleLinear().range([height, 5]).domain([5, 25]);

    //Creamos la linea del medio
    const line = d3.line().x((d) => x(d.month) + x.bandwidth() / 2).y((d) => y(d.value));

    //Creamos el elemento de SVG y lo pintamos en el contenedor del componente
    const svg = d3
      .select(chartRef.current)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);

    //Pintamos la línea del medio 
    svg.append("path").datum(data).attr("fill", "none").attr("stroke", "rgba(54, 208, 255, 1)").attr("stroke-width", 2).attr("d", line);
    
    //Añadimos el X-Axis
    svg.append("g").attr("transform", `translate(0,${height})`).style("font-size", "12px").call(d3.axisBottom(x))
    .call(g => g.select(".domain").remove()) .selectAll(".tick line") .style("stroke-opacity", 0)
    svg.selectAll(".tick text").attr("fill", "#000000").attr("style", "font-family:Open Sans")

    //Añadimos el Y-Axis
    svg.append("g").style("font-size", "12px").call(d3.axisLeft(y))
    .call(g => g.select(".domain").remove()).call(g => g.select(".domain").remove()) .selectAll(".tick line") .style("stroke-opacity", 0)
    
  }

 
  
  return (
    <div className="interest-container">

      <div className="text-column">
          <p>
            Interés de <br />
            {searchs.recentSearches}
          </p>
      </div>

      <div className="graphic-column">
        <div className="graphic" ref={chartRef} />
      </div>
    </div>

  );
}


