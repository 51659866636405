import { useState, useEffect, useReducer } from "react";
import { ProjectContext } from "./config/config";
import { useTranslation } from "react-i18next";

//Componente que carga las rutas y los componentes
import { AllRoutes } from "./routes/AllRoutes";

//Searchs
import { initialState_Search, searchReducer } from "./config/searchReducers";

//Users
import { initialState_User, userReducer } from "./config/userReducers";

//Reports
import { initialState_Report, reportReducer } from "./config/reportReducers";

//Graphics
import {
  initialState_Graphics,
  graphicsReducer,
} from "./config/graphicReducers";

//Files
import { initialState_File, fileReducer } from "./config/fileReducer";

function App() {
  //Usuarios
  const [isLoged, setLoged] = useState(false);
  const [users, setUsers] = useState();

  const [searchs, dispatch] = useReducer(searchReducer, initialState_Search);
  const [user, dispatch_user] = useReducer(userReducer, initialState_User);
  const [reports, dispatch_report] = useReducer(
    reportReducer,
    initialState_Report
  );
  const [graphics, dispatch_graphics] = useReducer(
    graphicsReducer,
    initialState_Graphics
  );
  const [file, dispatch_file] = useReducer(fileReducer, initialState_File);

  function addSearch(search) {
    dispatch({ type: "saveSearch", payload: search });
  }

  //Guardar la respuesta en un array de usuarios
  useEffect(() => {}, [users]);

  const handleLogin = (isLoged) => {
    setLoged(isLoged);
  };

  useEffect(() => {}, [isLoged]);

  function HeaderComponent() {
    const { t, i18n } = useTranslation("common");
    return <h1>{t("welcome.title")}</h1>;
  }

  return (
    <>
      <ProjectContext.Provider
        value={{
          user,
          dispatch_user,
          handleLogin,
          searchs,
          addSearch,
          reports,
          dispatch_report,
          graphics,
          dispatch_graphics,
          file,
          dispatch_file,
        }}
      >
        <AllRoutes lang='es'/>
        {/* <HeaderComponent/> */}
      </ProjectContext.Provider>
    </>
  );
}

export default App;
