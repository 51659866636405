import React from 'react'

import Sidebar from "../Components/Sidebar/Sidebar";
import EditButton from "../Components/EditButton";
import Label from "../Components/Label";
import SaveButton from '../Components/SaveButton';

import es from '../translations/es.json';

export default function CreationOfUserScreen(props) {

  const {lang} = props;
  const text = lang === "es" ? es.pages["create-new-user"] : null;
  
  return (
    <div className="background-image">
      <Sidebar defaultActive={1} />
      <div className="container content-container">
        <div className="row my-lg-5  my-md-5 my-sm-5">
          <div className="col-lg-1"></div>
          <div className="col-lg-11">
            <h1>{text.title}</h1>
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <h4 className="graphic-text">{text['general-data']}</h4>
          </div>
          <div className="col-lg-8 d-flex justify-content-end">
            <SaveButton id="third" />
          </div>
        </div>
        <div className="row my-lg-4">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Nombre" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Apellidos" className="input-container__secondary" />
            </div>
          </div>
          
        </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="col-lg-3">
            <h4 className="graphic-text">{text['personal-data']}</h4>
          </div>
        </div>
        <div className="row my-lg-4">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Nombre del usuario" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Correo electrónico" className="input-container__secondary"/>
            </div>
          </div>
        <div className="row my-lg-4">
          <div className="col-lg-1"></div>
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-3">
              <Label text="Contraseña" className="input-container__secondary"/>
            </div>
            <div className="col-lg-3">
              <Label text="Confirmar nueva contraseña" className="input-container__secondary"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
